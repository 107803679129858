<template>
  <div class="typePos">
    <div class="typePos-wrapper-caption">
      <div class="typePos-header">
        <div class="typePos-favorites">
          <div
              class="typePos-favorites-icon active"
              :class="{ favorites: isMenuUserMarked }"
              @click="onFavoriteClick"
          >
            즐겨찾기
          </div>
          <div class="typePos-favorites-title">매장 POS</div>
        </div>
        <div class="typePos-filed">
          <div class="typePos-filed-title">영업일자</div>
          <div class="typePos-filed-content">
            <div class="typePos-item">
              <input-date-unusual
                  v-model="salesDateValueModel"
                  :required="true"
                  :notClear="true"
                  :isGetDateInfo="true"
                  @change="onSalesDateChange"
              />
            </div>
            <div class="typePos-item">
              <div class="typePos-content">
                (<span
                  :style="{
                    color: dayOfWeekCaptionColor,
                  }"
              >{{ dayOfWeekCaption }}</span
              >) (<span
                  :style="{
                    color: weekdayWeekendCaptionColor,
                  }"
              >{{ weekdayWeekendCaption }}</span
              >)
              </div>
            </div>
          </div>
        </div>
        <div class="typePos-filed typePos-store">
          <div class="typePos-filed-title">매장</div>
          <div class="typePos-filed-content">
            <div class="typePos-item">
              <f-select
                  ref="select-store"
                  class="ui-select"
                  :items="storesForSelect"
                  :value="currentStoreCode"
                  @select=" (event, { item }) => onSelectStoreItemClick(event, { store: item })"
              />
            </div>
          </div>
        </div>
        <div class="typePos-filed typePos-button">
          <div class="typePos-filed-content">
            <div class="typePos-item typePos-refresh">
              <erp-button
                button-div="GET"
                class="ui-button"
                :use-syncfusion-component-style="false"
                :is-custom-shortcut-button="true"
                :shortcut-key="refreshShortcutButtonProps.shortcutKey"
                :shortcut="refreshShortcutButtonProps.shortcut"
                :visibleShortcutString="false"
                :ignore="isPopupOpened"
                @click.native="onRefreshClick"
              >
                <div class="i-item">
                  {{ toShortcutString(refreshShortcutButtonProps.shortcut) }}
                </div>
              </erp-button>
            </div>
            <div class="typePos-item typePos-shortcuts">
              <f-shortcut
                  class="ui-select"
                  :items="[
                  {
                    value: 'PosStatus',
                    name: 'POS 영업현황',
                    disabled: !currentStore,
                  },
                  { value: 'TeamOrganization', name: '팀 편성표' },
                  { value: 'TeamsSalesStatus', name: '팀별 매출 현황' },
                  {
                    value: 'CashReceipt',
                    name: '현금영수증 발급/취소',
                    disabled: !currentStore,
                  },
                  { value: 'Check', name: '수표조회', disabled: !currentStore },
                ]"
                  @select="
                  (event, { item }) => {
                    switch (item.value) {
                      case 'PosStatus':
                        onPosStatusShortCutClick();
                        break;
                      case 'TeamOrganization':
                        onTeamOrganizationShortCutClick();
                        break;
                      case 'TeamsSalesStatus':
                        onTeamsSalesStatusShortCutClick();
                        break;
                      case 'CashReceipt':
                        onCashReceiptShortCutClick();
                        break;
                      case 'Check':
                        onCheckShortCutClick();
                        break;
                    }
                  }
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div class="typePos-footer">
        <div class="typePos-filed">
          <div class="typePos-filed-content">
            <div class="typePos-item">
              <div class="typePos-title">
                POS프린터
              </div>
              <div class="typePos-content">
                <ejs-switch
                    v-model="printerOutput.posPrinterOutput"
                    :checked="printerOutput.posPrinterOutput"
                    @change="changeDeviceInfo(printerOutput);"
                ></ejs-switch>
              </div>
            </div>
          </div>
        </div>

        <div class="typePos-filed">
          <div class="typePos-filed-content">
            <div class="typePos-item">
              <div class="typePos-title">
                주방프린터
              </div>
              <div class="typePos-content">
                <ejs-switch
                    v-model="printerOutput.kitchenPrinterOutput"
                    :checked="printerOutput.kitchenPrinterOutput"
                    @change="changeDeviceInfo(printerOutput)"
                ></ejs-switch>
              </div>
            </div>
          </div>
        </div>

        <div class="typePos-filed">
          <div class="typePos-filed-content">
            <div class="typePos-item">
              <div class="typePos-title">
                주방 주문서 발행 매수
              </div>
              <div class="typePos-content">
                <input-number
                    :disabled="!printerOutput.kitchenPrinterOutput"
                    style="width: 20px; height: 20px; text-align: center; margin-top: -1px;"
                    v-model="kitchenPaperNumber"
                    :propMaxLength="kitchenPaperNumber > 0 ? 1 : 2"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="typePos-filed typePos-business">
          <div class="typePos-filed-content">
            <div class="typePos-item">
              <f-select
                  class="ui-select"
                  :items="businessCodesForSelect"
                  :value="currentBusinessCode"
                  @select="
                  (event, { item }) =>
                    onSelectBusinessCodeItemClick(event, {
                      businessCode: item.value,
                    })
                "
              />
            </div>
          </div>
        </div>
        <!--
        <div class="typePos-filed">
          <div class="typePos-filed-content">
            <div class="typePos-item">
              <div class="typePos-title">
                매출금액 :
              </div>
              <div class="typePos-content">
                {{ storeSalesAmount | numberWithCommas }}
              </div>
            </div>
          </div>
        </div>
        <div class="typePos-filed">
          <div class="typePos-filed-content">
            <div class="typePos-item">
              <div class="typePos-title">
                사용자 :
              </div>
              <div class="typePos-content">
                {{ username }}
              </div>
            </div>
          </div>
        </div>
        -->
        <div class="typePos-filed typePos-button">
          <div class="typePos-filed-content">
            <div class="typePos-item typePos-window">
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="GET"
                class="ui-button"
                :use-syncfusion-component-style="false"
                @click="onNewWindowClick"
              >
                <div class="i-item">새창</div>
              </erp-button>
            </div>
            <div class="typePos-item typePos-full">
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                  button-div="GET"
                  class="ui-button"
                  :use-syncfusion-component-style="false"
                  @click="onFullScreenClick"
              >
                <div class="i-item">전체</div>
              </erp-button>
            </div>
            <div class="typePos-item typePos-information">
              <f-shortcut
                  class="ui-select"
                  :items="[
                  { value: 'ChangePassword', name: '비밀번호 변경' },
                  { value: 'Logout', name: '로그아웃' },
                  { value: 'SettingDevice', name: '기기설정' },
                ]"
                  @select="
                  (event, { item }) => {
                    switch (item.value) {
                      case 'ChangePassword':
                        onChangePasswordShortCutClick();
                        break;
                      case 'Logout':
                        onLogoutShortCutClick();
                        break;
                      case 'SettingDevice':
                        onSettingDeviceShortCutClick();
                        break;
                    }
                  }
                "
              >
                {{ username }}
              </f-shortcut>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="typePos-wrapper-header">
      <div class="typePos-header-wrap">
        <div class="typePos-header">
          <div class="typePos-input typePos-number">
            <div class="ui-input">
              <!--  background-color(Style) : 상태에 따른 색상 값 -->
              <div
                  class="typePos-state"
                  :style="{ backgroundColor: slipStatusPaidColor }"
              >
                {{ slipStatusLabelInnerText }}
              </div>
              <!-- 비활성(Attribute) : disabled -->
              <input
                  type="text"
                  placeholder="입력하세요."
                  :value="inputSlipNumber"
                  readonly
              />
            </div>
          </div>
          <div class="typePos-button typePos-search">
            <!-- 비활성(Attribute) : disabled -->
            <erp-button
              button-div="GET"
              class="ui-button"
              :is-custom-shortcut-button="true"
              :shortcut-key="searchSlipsShortcutButtonProps.shortcutKey"
              :shortcut="searchSlipsShortcutButtonProps.shortcut"
              :visible-shortcut-string="false"
              :use-syncfusion-component-style="false"
              :disabled="!currentStoreCode"
              :ignore="isPopupOpened"
              @click.native="onSearchSlipsPopupClick"
            >
              <div class="i-item">
                전표찾기 ({{
                  toShortcutString(searchSlipsShortcutButtonProps.shortcut)
                }})
              </div>
            </erp-button>
          </div>
          <div class="typePos-button typePos-list">
            <!-- 비활성(Attribute) : disabled -->
            <erp-button
              button-div="GET"
              class="ui-button"
              :use-syncfusion-component-style="false"
              :disabled="!currentStoreCode"
              @click="onSlipListClick"
            >
              <div class="i-item">전표목록</div>
            </erp-button>
          </div>
          <div class="typePos-button typePos-list">
            <!-- 비활성(Attribute) : disabled -->
            <erp-button
              button-div="GET"
              class="ui-button"
              :use-syncfusion-component-style="false"
              :disabled="!currentStoreCode || mode !== 'MODIFY'"
              @click="onSlipHistoryClick"
            >
              <div class="i-item">변경이력</div>
            </erp-button>
          </div>
          <div class="typePos-button typePos-list">
            <!-- 비활성(Attribute) : disabled -->
            <erp-button
              button-div="GET"
              class="ui-button"
              :use-syncfusion-component-style="false"
              :disabled="!currentStoreCode"
              @click="onPosMemoClick"
            >
              <div :class="(!memosCheck && memosCheck!==null)?'memo-Check-img blinking':null"></div>
              <div class="i-item">메모</div>
            </erp-button>
          </div>
        </div>
        <div class="typePos-footer">
          <!-- 비활성(Class) : typePos-inactive -->
          <div
              class="typePos-button typePos-table"
              :class="{ 'typePos-inactive': !usingTable }"
          >
            <!-- 비활성(Attribute) : disabled -->
            <erp-button
              button-div="SAVE"
              class="ui-button"
              :use-syncfusion-component-style="false"
              :disabled="
                !currentStoreCode ||
                mode === 'EMPTY' ||
                !availableToUpdate
              "
              @click="onChangeTableClick"
            >
              <div class="i-item">{{ slipStoreTableName }}</div>
            </erp-button>
          </div>
          <div class="typePos-button typePos-visitor">
            <!-- 비활성(Attribute) : disabled -->
            <erp-button
              button-div="SAVE"
              class="ui-button"
              :use-syncfusion-component-style="false"
              :disabled="
                !currentStoreCode ||
                mode === 'EMPTY' ||
                !availableToUpdate
              "
              @click="onSettingTableMemberCountClick"
            >
              <div class="i-item">{{ visitorsCount }}명</div>
            </erp-button>
          </div>
          <div class="typePos-information">
            <div class="typePos-input typePos-locker">
              <div class="ui-input">
                <div class="typePos-title">락카번호</div>
                <!-- 비활성(Attribute) : disabled -->
                <input
                    type="text"
                    placeholder="000"
                    autocomplete="off"
                    v-model="inputVisitor.lockerNumber"
                    @keypress.enter="
                    onSearchVisitorsClick($event, {
                      lockerNumber: inputVisitor.lockerNumber,
                    })
                  "
                    :disabled="
                    commonMixinIsButtonDisableByAuth('salesMngPosGet') ||
                    !currentStoreCode ||
                    mode === 'EMPTY' ||
                    !availableToUpdate
                  "
                />
              </div>
            </div>
            <div class="typePos-input typePos-visitor">
              <div class="ui-input">
                <div class="typePos-title">내장객</div>
                <!-- 비활성(Attribute) : disabled -->
                <input
                    type="text"
                    placeholder="일반고객"
                    autocomplete="off"
                    v-model="inputVisitor.name"
                    @keypress.enter="
                      onSearchVisitorsClick($event, {
                        visitorName: inputVisitor.name,
                      })
                    "
                    :disabled="
                      commonMixinIsButtonDisableByAuth('salesMngPosGet') ||
                      !currentStoreCode ||
                      mode === 'EMPTY' ||
                      !availableToUpdate ||
                      currentStore.golfCabinDivision === 'C'
                    "
                />
                <!-- 비활성(Attribute) : disabled -->
                <erp-button
                  button-div="SAVE"
                  class="ui-button"
                  :is-custom-shortcut-button="true"
                  :shortcut-key="searchVisitorsShortcutButtonProps.shortcutKey"
                  :shortcut="searchVisitorsShortcutButtonProps.shortcut"
                  :visible-shortcut-string="false"
                  :ignore="isPopupOpened"
                  :use-syncfusion-component-style="false"
                  :disabled="
                    !currentStoreCode ||
                    mode === 'EMPTY' ||
                    !availableToUpdate ||
                    currentStore.golfCabinDivision === 'C'
                  "
                  @click="onSearchVisitorsClick"
                >
                  <div class="i-item">
                    {{ toShortcutString(searchVisitorsShortcutButtonProps.shortcut) }}
                  </div>
                </erp-button>
              </div>
            </div>
          </div>
          <!-- 비활성(Class) : typePos-inactive -->
          <div
              class="typePos-button typePos-postpaid"
              :class="{ 'typePos-inactive': mode === 'EMPTY' }"
          >
            <!-- 비활성(Attribute) : disabled -->
            <erp-button
              button-div="SAVE"
              class="ui-button"
              :use-syncfusion-component-style="false"
              :disabled=" !currentStoreCode || !slip || !slip.visitor || !availableToUpdate"
              @click="onCancelAssignmentPayerClick"
            >
              <div class="i-item">후불 취소</div>
            </erp-button>
          </div>
        </div>
      </div>
    </div>
    <div class="typePos-wrapper-main">
      <div class="typePos-main-box typePos-calculate">
        <div class="typePos-box-wrap">
          <div class="typePos-box-container">
            <!-- 열기 : typePos-open -->
            <div
                class="typePos-box-header"
                :class="{
                'typePos-open': mode !== 1 && visibleVisitorsTeamInformation,
              }"
            >
              <div class="typePos-accordion">
                <erp-button
                  button-div="GET"
                  :use-syncfusion-component-style="false"
                  :disabled="!currentStoreCode || mode === 'EMPTY'"
                  @click="visibleVisitorsTeamInformation = !visibleVisitorsTeamInformation"
                >
                  열기 / 닫기
                </erp-button>
              </div>
              <ul class="typePos-information">
                <li>
                  <div class="typePos-title">예약자</div>
                  <div class="typePos-content">
                    {{ reservationInformationString }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">캐디</div>
                  <div class="typePos-content">
                    {{ caddiesInformationString }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">내장객</div>
                  <div class="typePos-content">
                    {{ visitorsInformationString }}
                  </div>
                </li>
              </ul>
            </div>
            <div class="typePos-box-body">
              <div id="posSalesGrid" class="typePos-body-body">
                <ejs-grid-wrapper
                    ref="salesGrid"
                    v-bind="gridProps"
                    :columns="columns"
                    :isNOColumnDisplay="false"
                    @cellSave="onSalesGridCellSave"
                    @cellSaved="onSalesGridCellSaved"
                    @dataBound="onSalesGridDataBound"
                    @queryCellInfo="onSalesGridQueryCellInfo"
                    @recordClick="onSalesGridRecordClick"
                    @gridCheckboxChanged="onGridCheckboxChanged"
                />
              </div>
              <div class="typePos-body-footer">
                <div class="typePos-body">
                  <ul class="typePos-list">
                    <li>
                      <div class="typePos-title">시간</div>
                      <div class="typePos-content">
                        {{ maxTimeOfSales }}
                      </div>
                    </li>
                    <li>
                      <div class="typePos-title">할인</div>
                      <div class="typePos-content">
                        {{ totalSalesDiscount | numberWithCommas }}
                      </div>
                    </li>
                    <li>
                      <div class="typePos-title">총수량</div>
                      <div class="typePos-content">
                        {{ totalSalesQuantity | numberWithCommas }}
                      </div>
                    </li>
                  </ul>
                  <div class="typePos-total">
                    {{ totalSalesAmount | numberWithCommas }}
                  </div>
                </div>
                <div class="typePos-footer">
                  <div class="typePos-information">
                    {{ slipStatusMessage }}
                  </div>
                </div>
              </div>
            </div>
            <div class="typePos-box-footer">
              <ul class="typePos-button">
                <li class="typePos-plus">
                  <!-- 비활성(Attribute) : disabled -->
                  <erp-button
                    button-div="SAVE"
                    class="ui-button"
                    :use-syncfusion-component-style="false"
                    :disabled="
                      !currentStoreCode ||
                      mode === 'EMPTY' ||
                      !availableToUpdate
                    "
                    @click="onIncreaseQuantity"
                  >
                    <div class="i-item">더하기</div>
                  </erp-button>
                </li>
                <li class="typePos-minus">
                  <!-- 비활성(Attribute) : disabled -->
                  <erp-button
                    button-div="SAVE"
                    class="ui-button"
                    :use-syncfusion-component-style="false"
                    :disabled="
                      !currentStoreCode ||
                      mode === 'EMPTY' ||
                      !availableToUpdate
                    "
                    @click="onDecreaseQuantity"
                  >
                    <div class="i-item">빼기</div>
                  </erp-button>
                </li>
                <li class="typePos-cancelSelection">
                  <!-- 비활성(Attribute) : disabled -->
                  <erp-button
                    button-div="DELETE"
                    class="ui-button"
                    :use-syncfusion-component-style="false"
                    :disabled="
                      !currentStoreCode ||
                      mode === 'EMPTY' ||
                      !availableToUpdate
                    "
                    :shortcut-key="'posView.shortcut.selectCancel'"
                    :visible-shortcut-string="false"
                    :is-custom-shortcut-button="true"
                    :shortcut="{key: 'F9'}"
                    @click="onCancelSelectSaleClick"
                  >
                    <div class="i-item">선택취소 (F9)</div>
                  </erp-button>
                </li>
                <li class="typePos-cancelAll">
                  <!-- 비활성(Attribute) : disabled -->
                  <erp-button
                    button-div="DELETE"
                    class="ui-button"
                    :use-syncfusion-component-style="false"
                    :disabled="
                      !currentStoreCode ||
                      mode === 'EMPTY' ||
                      !availableToUpdate
                    "
                    @click="onCancelAllSalesClick"
                  >
                    <div class="i-item">전체취소</div>
                  </erp-button>
                </li>
                <li class="typePos-deleteDocument">
                  <!-- 비활성(Attribute) : disabled -->
                  <erp-button
                    button-div="DELETE"
                    class="ui-button"
                    :is-shortcut-button="true"
                    :visible-shortcut-string="false"
                    :use-syncfusion-component-style="false"
                    :disabled="
                      !currentStoreCode ||
                      mode === 'EMPTY' ||
                      !availableToUpdate
                    "
                    @click="onConfirmDeleteSlipPopupClick"
                  >
                    <div class="i-item">
                      전표삭제 ({{ deleteShortcutButtonShortcutString }})
                    </div>
                  </erp-button>
                </li>
                <li class="typePos-discountProcessing">
                  <!-- 비활성(Attribute) : disabled -->
                  <erp-button
                    button-div="SAVE"
                    class="ui-button"
                    :use-syncfusion-component-style="false"
                    :disabled="
                      !currentStoreCode ||
                      mode === 'EMPTY' ||
                      !availableToUpdate
                    "
                    @click="onSettingSaleDiscountsPopupClick"
                  >
                    <div class="i-item">할인(A쿠폰)</div>
                  </erp-button>
                </li>
                <li class="typePos-reset">
                  <!-- 비활성(Attribute) : disabled -->
                  <erp-button
                    button-div="GET"
                    class="ui-button"
                    :use-syncfusion-component-style="false"
                    :disabled="
                      !currentStoreCode ||
                      mode === 'EMPTY'
                    "
                    @click="onResetClick"
                  >
                    <div class="i-item">초기화</div>
                  </erp-button>
                </li>
                <li class="typePos-slipMemo">
                  <!-- 비활성(Attribute) : disabled -->
                  <erp-button
                    button-div="GET"
                    class="ui-button"
                    :use-syncfusion-component-style="false"
                    :disabled="mode !== 'MODIFY' || !availableToUpdate"
                    @click="onSlipMemoListPopupClick"
                  >
                    <div class="i-item">전표메모</div>
                  </erp-button>
                </li>
                <li class="typePos-unitPriceChange">
                  <!-- 비활성(Attribute) : disabled -->
                  <erp-button
                    button-div="SAVE"
                    class="ui-button"
                    :use-syncfusion-component-style="false"
                    :disabled="
                      !currentStoreCode ||
                      mode === 'EMPTY' ||
                      !availableToUpdate
                    "
                    @click="onSettingSaleAmountPopupClick"
                  >
                    <div class="i-item">단가변경</div>
                  </erp-button>
                </li>
                <!-- 비활성(Class) : typePos-inactive -->
                <li
                    class="typePos-galleryCreation"
                    :class="{ 'typePos-inactive': !creatableGallery }"
                >
                  <!-- 비활성(Attribute) : disabled -->
                  <erp-button
                    button-div="SAVE"
                    class="ui-button"
                    :use-syncfusion-component-style="false"
                    :disabled="
                      !currentStoreCode ||
                      !availableToUpdate
                    "
                    @click="onCreateGalleryPopupClick"
                  >
                    <div class="i-item">
                      <span>갤러리</span><span>생성</span>
                    </div>
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="typePos-main-box typePos-product">
        <div class="typePos-box-wrap">
          <div class="typePos-box-container">
            <div class="typePos-box-header">
              <div class="typePos-navigation">
                <categories
                    class="typePos-main"
                    :items="mediumCategories"
                    v-model="mediumCategoryCode"
                />
                <categories
                    class="typePos-sub"
                    :items="smallCategories"
                    v-model="smallCategoryCode"
                />
              </div>
              <div class="typePos-search">
                <!-- 비활성(Attribute) : disabled -->
                <erp-button
                  button-div="SAVE"
                  class="ui-button i-typePrimary"
                  :is-custom-shortcut-button="true"
                  :shortcut-key="searchProductsShortcutButtonProps.shortcutKey"
                  :shortcut="searchProductsShortcutButtonProps.shortcut"
                  :visible-shortcut-string="false"
                  :use-syncfusion-component-style="false"
                  :disabled="
                      !currentStoreCode ||
                      !availableToUpdate
                  "
                  :ignore="isPopupOpened"
                  @click="onSearchProductsClick"
                >
                  <div class="i-item">
                    메뉴<br/>
                    검색<br/>
                    ({{
                      toShortcutString(
                        searchProductsShortcutButtonProps.shortcut
                      )
                    }})
                  </div>
                </erp-button>
              </div>
            </div>
            <div class="typePos-box-body">
              <ul class="typePos-body-body">
                <template v-if="products">
                  <!-- 활성(Class) : typePos-active -->
                  <li
                      v-for="(product, index) in visibleProducts"
                      v-bind:key="`pos-products-${product.code}-${index}`"
                      :class="{
                      'typePos-active': selectProductCode === product.code,
                    }"
                  >
                    <!-- 비활성(Attribute) : disabled -->
                    <erp-button
                      button-div="SAVE"
                      :use-syncfusion-component-style="false"
                      :disabled="!availableToUpdate"
                      @click="onProductSelect($event, { product })"
                    >
                      <div class="typePos-name"
                           :class="{'badgeLabel' : product.badgeCode}"
                      >
                        <div class="label-header">
                          <div :class="product.badgeCode"></div>
                          <div v-if="product.soldoutFlag" :class="{'soldout': product.soldoutFlag}">SoldOut</div>
                        </div>
                        <span>{{ product.name }}</span>
                      </div>
                      <div class="typePos-price">
                        {{ product.price | numberWithCommas }}
                      </div>
                    </erp-button>
                  </li>
                </template>
              </ul>
              <div class="typePos-body-footer">
                <template
                    v-if="!products || products.length < productsSizePerPage"
                >
                  <div class="typePos-button typePos-first">
                    <!-- 비활성(Attribute) : disabled -->
                    <erp-button
                      button-div="GET"
                      :use-syncfusion-component-style="false"
                      :disabled="true"
                    >
                      처음
                    </erp-button>
                  </div>
                  <div class="typePos-button typePos-prev">
                    <!-- 비활성(Attribute) : disabled -->
                    <erp-button
                      button-div="GET"
                      :use-syncfusion-component-style="false"
                      :disabled="true"
                    >
                      이전
                    </erp-button>
                  </div>
                  <ul class="typePos-paging">
                    <!-- 활성(Class) : typePos-active -->
                    <li class="typePos-button typePos-active">
                      <erp-button
                        button-div="GET"
                        :use-syncfusion-component-style="false"
                      >
                        1
                      </erp-button>
                    </li>
                  </ul>
                  <div class="typePos-button typePos-next">
                    <!-- 비활성(Attribute) : disabled -->
                    <erp-button
                      button-div="GET"
                      :use-syncfusion-component-style="false"
                      :disabled="true"
                    >
                      다음
                    </erp-button>
                  </div>
                  <div class="typePos-button typePos-last">
                    <!-- 비활성(Attribute) : disabled -->
                    <erp-button
                      button-div="GET"
                      :use-syncfusion-component-style="false"
                      :disabled="true"
                    >
                      마지막
                    </erp-button>
                  </div>
                </template>
                <template v-else>
                  <div class="typePos-button typePos-first">
                    <!-- 비활성(Attribute) : disabled -->
                    <erp-button
                      button-div="GET"
                      :use-syncfusion-component-style="false"
                      :disabled="currentProductsPage === 1"
                      @click="currentProductsPage = 1"
                    >
                      처음
                    </erp-button>
                  </div>
                  <div class="typePos-button typePos-prev">
                    <!-- 비활성(Attribute) : disabled -->
                    <erp-button
                      button-div="GET"
                      :use-syncfusion-component-style="false"
                      :disabled="currentProductsPage === 1"
                      @click="currentProductsPage = currentProductsPage - 1"
                    >
                      이전
                    </erp-button>
                  </div>
                  <ul class="typePos-paging">
                    <!-- 활성(Class) : typePos-active -->
                    <li
                        v-for="(n, index) in lastProductsPage - offsetProductsPage"
                        v-bind:key="`pos-products-page-${index}`"
                        class="typePos-button"
                        :class="{
                          'typePos-active':
                            currentProductsPage === offsetProductsPage + n,
                        }"
                        @click="
                          onProductsPageClick($event, {
                            page: offsetProductsPage + n,
                          })
                        "
                    >
                      <erp-button
                        button-div="GET"
                        :use-syncfusion-component-style="false"
                      >
                        {{ offsetProductsPage + n }}
                      </erp-button>
                    </li>
                  </ul>
                  <div class="typePos-button typePos-next">
                    <!-- 비활성(Attribute) : disabled -->
                    <erp-button
                      button-div="GET"
                      :use-syncfusion-component-style="false"
                      :disabled="currentProductsPage === lastProductsPage"
                      @click="currentProductsPage = currentProductsPage + 1"
                    >
                      다음
                    </erp-button>
                  </div>
                  <div class="typePos-button typePos-last">
                    <!-- 비활성(Attribute) : disabled -->
                    <erp-button
                      button-div="GET"
                      :use-syncfusion-component-style="false"
                      :disabled="currentProductsPage === lastProductsPage"
                      @click="currentProductsPage = lastProductsPage"
                    >
                      마지막
                    </erp-button>
                  </div>
                </template>
              </div>
            </div>
            <div class="typePos-box-footer">
              <ul class="typePos-button">
                <!-- 비활성(Class) : typePos-inactive -->
                <li v-if="!usingTable" class="typePos-newSlip">
                  <!-- 비활성(Attribute) : disabled -->
                  <erp-button
                    button-div="SAVE"
                    class="ui-button"
                    :is-custom-shortcut-button="true"
                    :shortcut-key="createSlipShortcutButtonProps.shortcutKey"
                    :shortcut="createSlipShortcutButtonProps.shortcut"
                    :visible-shortcut-string="false"
                    :use-syncfusion-component-style="false"
                    :disabled="!currentStoreCode"
                    :ignore="isPopupOpened"
                    @click.native="onCreateSlipClick"
                  >
                    <div class="i-item">
                      신규전표<br/>({{
                        toShortcutString(
                          createSlipShortcutButtonProps.shortcut
                        )
                      }})
                    </div>
                  </erp-button>
                </li>
                <!-- 비활성(Class) : typePos-inactive -->
                <li v-else class="typePos-table">
                  <!-- 비활성(Attribute) : disabled -->
                  <erp-button
                    button-div="SAVE"
                    class="ui-button"
                    :is-custom-shortcut-button="true"
                    :shortcut-key="showTableStatusShortcutButtonProps.shortcutKey"
                    :shortcut="showTableStatusShortcutButtonProps.shortcut"
                    :visible-shortcut-string="false"
                    :use-syncfusion-component-style="false"
                    :disabled="!currentStoreCode"
                    :ignore="isPopupOpened"
                    @click.native="onTableStatusButtonClick"
                  >
                    <div class="i-item">
                      테이블<br/>({{
                        toShortcutString(
                          showTableStatusShortcutButtonProps.shortcut
                        )
                      }})
                    </div>
                  </erp-button>
                </li>
                <li class="typePos-save">
                  <!-- 비활성(Attribute) : disabled -->
                  <erp-button
                    button-div="SAVE"
                    class="ui-button"
                    :is-shortcut-button="true"
                    :visible-shortcut-string="false"
                    :use-syncfusion-component-style="false"
                    :disabled="
                      !currentStoreCode ||
                      mode === 'EMPTY' ||
                      !availableToUpdate
                    "
                    :ignore="isPopupOpened"
                    @click.native="onSaveClick(true)"
                  >
                    <div class="i-item">
                      저장<br/>({{ saveShortcutButtonShortcutString }})
                    </div>
                  </erp-button>
                </li>
                <li class="typePos-issuanceOfInvoice">
                  <!-- 비활성(Attribute) : disabled -->
                  <erp-button
                    button-div="GET"
                    class="ui-button"
                    :use-syncfusion-component-style="false"
                    :disabled="
                      !currentStoreCode ||
                      mode !== 'MODIFY'
                    "
                    @click.native="onPrintBillPopupClick"
                  >
                    <div class="i-item">
                      <span>계산서</span><span>발행</span>
                    </div>
                  </erp-button>
                </li>
                <li class="typePos-kitchenStatement">
                  <!-- 비활성(Attribute) : disabled -->
                  <erp-button
                    button-div="GET"
                    class="ui-button"
                    :use-syncfusion-component-style="false"
                    :disabled="
                      !currentStoreCode ||
                      mode !== 'MODIFY'
                    "
                    @click.native="onKitchenStatementReissueButtonClicked"
                  >
                    <div class="i-item">
                      <span>주방주문</span><span>재발행</span>
                    </div>
                  </erp-button>
                </li>
                <li class="typePos-settlementDetails">
                  <!-- 비활성(Attribute) : disabled -->
                  <erp-button
                    button-div="GET"
                    class="ui-button"
                    :use-syncfusion-component-style="false"
                    :disabled="!currentStoreCode"
                    @click.native="onPaymentListPopupClick"
                  >
                    <div class="i-item">정산내역</div>
                  </erp-button>
                </li>
                <li class="typePos-deferredPayment">
                  <!-- 비활성(Attribute) : disabled -->
                  <erp-button
                    button-div="SAVE"
                    class="ui-button"
                    :is-custom-shortcut-button="true"
                    :shortcut-key="postSettleAmountShortcutButtonProps.shortcutKey"
                    :shortcut="postSettleAmountShortcutButtonProps.shortcut"
                    :visible-shortcut-string="false"
                    :use-syncfusion-component-style="false"
                    :disabled="
                      !currentStoreCode ||
                      mode === 'EMPTY' ||
                      !availableToUpdate ||
                      currentStore.golfCabinDivision === 'C'
                    "
                    :ignore="isPopupOpened"
                    @click.native="onSettleAmountClick($event, { method: 'POST' })"
                  >
                    <div class="i-item">
                      후불정산<br/>({{
                        toShortcutString(
                          postSettleAmountShortcutButtonProps.shortcut
                        )
                      }})
                    </div>
                  </erp-button>
                </li>
                <li class="typePos-card">
                  <!-- 비활성(Attribute) : disabled -->
                  <erp-button
                    button-div="SAVE"
                    class="ui-button"
                    :is-custom-shortcut-button="true"
                    :shortcut-key="settleAmountUsingCardShortcutButtonProps.shortcutKey"
                    :shortcut="settleAmountUsingCardShortcutButtonProps.shortcut"
                    :visible-shortcut-string="false"
                    :use-syncfusion-component-style="false"
                    :disabled="
                      !currentStoreCode ||
                      mode === 'EMPTY' ||
                      !availableToUpdate
                    "
                    :ignore="isPopupOpened"
                    @click.native="onSettleAmountClick($event, { method: 'CARD' })"
                  >
                    <div class="i-item">
                      카드<br/>({{
                        toShortcutString(
                          settleAmountUsingCardShortcutButtonProps.shortcut
                        )
                      }})
                    </div>
                  </erp-button>
                </li>
                <li class="typePos-cash">
                  <!-- 비활성(Attribute) : disabled -->
                  <erp-button
                    button-div="SAVE"
                    class="ui-button"
                    :use-syncfusion-component-style="false"
                    :disabled="
                      !currentStoreCode ||
                      mode === 'EMPTY' ||
                      !availableToUpdate
                    "
                    :ignore="isPopupOpened"
                    @click.native="onSettleAmountClick($event, { method: 'CASH' })"
                  >
                    <div class="i-item">현금</div>
                  </erp-button>
                </li>
                <li class="typePos-etc">
                  <!-- 비활성(Attribute) : disabled -->
                  <erp-button
                    button-div="SAVE"
                    class="ui-button"
                    :use-syncfusion-component-style="false"
                    :disabled="
                      !currentStoreCode ||
                      mode === 'EMPTY' ||
                      !availableToUpdate
                    "
                    :ignore="isPopupOpened"
                    @click.native="onSettleAmountClick($event, { method: 'DEPOSIT' })"
                  >
                    <div class="i-item">기타</div>
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- POS 영업현황 팝업 -->
    <pos-status-popup
        ref="pos-status-popup"
        v-if="visiblePosStatusPopup"
        :sales-date="salesDate"
        :store="currentStore"
        @close="onPosStatusPopupClose"
    />
    <!-- 팀 편성표 팝업 -->
    <team-organization-popup
        ref="team-organization-popup"
        v-if="visibleTeamOrganizationPopup"
        :sales-date="salesDate"
        @close="onTeamOrganizationPopupClose"
    />
    <!-- 현금영수증 발행/취소 팝업 -->
    <cash-receipt-popup
        ref="cash-receipt-popup"
        v-if="visibleCashReceiptPopup"
        :sales-date="salesDate"
        :store="currentStore"
        @close="onCashReceiptPopupClose"
    />
    <!-- 수표조회 팝업 -->
    <check-popup
        ref="check-popup"
        v-if="visibleCheckPopup"
        :store="currentStore"
        @close="onCheckPopupClose"
    />
    <!-- 비밀번호 변경 팝업 -->
    <change-password-popup
        ref="change-password-popup"
        v-if="visibleChangePasswordPopup"
        @close="onChangePasswordPopupClose"
    />
    <!-- 기기설정 팝업 -->
    <setting-device-popup
        ref="setting-device-popup"
        v-if="visibleSettingDevicePopup"
        @close="onSettingDeviceClose"
    />
    <!-- 전표찾기 팝업 -->
    <search-slips-popup
        ref="search-slips-popup"
        v-if="visibleSearchSlipsPopup"
        :sales-date="salesDate"
        :store="currentStore"
        @submit="onSearchSlipsPopupSubmit"
        @close="onSearchSlipsPopupClose"
    />
    <!-- 전표목록 팝업 -->
    <slip-list-popup
        ref="slip-list-popup"
        v-if="visibleSlipListPopup"
        :sales-date="salesDate"
        :store="currentStore"
        @submit="onSearchSlipsPopupSubmit"
        @close="onSlipListPopupClose"
    />
    <!-- 테이블 인원 변경 팝업 -->
    <setting-table-member-count
        ref="setting-table-member-count-popup"
        v-if="visibleSettingTableMemberCountPopup"
        :slip="slip"
        @submit="onSettingTableMemberCountPopupSubmit"
        @close="onSettingTableMemberCountPopupClose"
    />
    <!-- 테이블 이동 팝업 -->
    <change-table-popup
        ref="change-table-popup"
        v-if="visibleChangeTablePopup"
        :sales-date="salesDate"
        :store="currentStore"
        :slip="slip"
        @close="onChangeTablePopupClose"
    />
    <!-- 내장객찾기 팝업 -->
    <search-visitors-popup
        ref="search-visitors-popup"
        v-if="visibleSearchVisitorsPopup"
        :sales-date="salesDate"
        :store="currentStore"
        @submit="onSearchSlipsPopupSubmit"
        @close="onSearchVisitorsPopupClose"
    />
    <!-- 상품찾기 팝업 -->
    <search-products-popup
        ref="search-products-popup"
        v-if="visibleSearchProductsPopup"
        :store="currentStore"
        @submit="onSearchProductsPopupSubmit"
        @close="onSearchProductsPopupClose"
    />
    <!-- 전표삭제 팝업 -->
    <confirm-delete-slip-popup
        ref="confirm-delete-slip-popup"
        v-if="visibleConfirmDeleteSlipPopup"
        @close="onConfirmDeleteSlipPopupClose"
    />
    <!-- 전표메모 팝업 -->
    <slip-memo-list-popup
        ref="slip-memo-list-popup"
        v-if="visibleSlipMemoListPopup"
        :slip="slip"
        @close="onSlipMemoListPopupClose"
    />
    <!-- 할인처리 팝업 -->
    <setting-sale-discounts-popup
        ref="setting-sale-discounts-popup"
        v-if="visibleSettingSaleDiscountsPopup"
        @close="onSettingSaleDiscountsPopupClose"
    />
    <!-- 단가변경 팝업 -->
    <setting-sale-amount-popup
        ref="setting-sale-amount-popup"
        v-if="visibleSettingSaleAmountPopup"
        :sales-date="salesDate"
        :store="currentStore"
        @close="onSettingSaleAmountPopupClose"
    />
    <!-- 갤러리생성 팝업 -->
    <create-gallery-popup
        ref="create-gallery-popup"
        v-if="visibleCreateGalleryPopup"
        :sales-date="salesDate"
        @close="onCreateGalleryPopupClose"
    />
    <!-- 테이블 현황 팝업 -->
    <table-status-popup
        ref="table-status-popup"
        v-if="visibleTableStatusPopup"
        :sales-date="salesDate"
        :store="currentStore"
        :slip="slip"
        @selectStoreTable="onTableStatusPopupSelectStoreTable"
        @postPayment="onTableStatusPopupPostPayment"
        @printBill="onTableStatusPopupPrintBill"
        @close="onTableStatusPopupClose"
        @posViewReset="onResetClick"
    />
    <!-- 계산서발행 팝업 -->
    <print-bill-popup
        ref="print-bill-popup"
        v-if="visiblePrintBillPopup"
        @close="onPrintBillPopupClose"
    />
    <!-- 정산내역 팝업 -->
    <payment-list-popup
        ref="payment-list-popup"
        v-if="visiblePaymentListPopup"
        :sales-date="salesDate"
        :store="currentStore"
        @submit="onPaymentListPopupSubmit"
        @close="onPaymentListPopupClose"
    />
    <!-- 정산 팝업 -->
    <settle-amount-popup
        ref="settle-amount-popup"
        v-if="visibleSettleAmountPopup"
        @close="onSettleAmountPopupClose"
    />
    <!-- 전표 변경이력 -->
    <pos-history-popup
        ref="pos-history-popup"
        v-if="visiblePosHistoryPopup"
        @close="onPosHistoryPopupClose"
    />
    <!-- 메모 팝업 -->
    <pos-memo-popup
        ref="pos-memo-popup"
        v-if="visiblePosMemoPopup"
        :sales-date="salesDate"
        :store-code="currentStoreCode"
        @close="onPosMemoPopupClose"
        @fetchMemo="fetchMemos"
    />
    <memo-view-popup
        ref="memoViewPopup"
        v-if="visibleMemoViewPopup"
        @close="onMemoViewPopupClose"
    />
  </div>
</template>

<style scoped>
.typePos .typePos-content >>> .e-switch-wrapper .e-switch-on,.typePos .typePos-content >>> .e-switch-wrapper .e-switch-handle.e-switch-active ,.typePos .typePos-content >>> .e-switch-wrapper:hover .e-switch-inner.e-switch-active .e-switch-on{
  background-color: skyblue;
}
.typePos .typePos-content >>> .e-switch-wrapper .e-ripple-check .e-ripple-element, .e-css.e-switch-wrapper .e-ripple-check .e-ripple-element {
  background-color: rgba(135, 206, 235, 0.57);
}
.typePos {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1260px;
  height: 100%;
  min-height: 860px;
}

.typePos .typePos-wrapper-caption {
  padding: 10px;
}

.typePos .typePos-wrapper-caption::after {
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  line-height: 0;
  content: '';
}

.typePos .typePos-wrapper-caption .typePos-filed {
  float: left;
  padding: 0 3px;
}

.typePos .typePos-wrapper-caption .typePos-filed::after {
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  line-height: 0;
  content: '';
}

.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-title,
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content {
  float: left;
  padding: 0 7px;
  color: #666;
  font-size: 13px;
  font-family: 'NanumSquare-Regular';
  line-height: 19px;
}

.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-title {
  padding-top: 10px;
  padding-bottom: 11px;
  color: #222;
  font-family: 'NanumSquare-Bold';
}

.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content::after {
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  line-height: 0;
  content: '';
}

.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item {
  float: left;
  padding-left: 10px;
}

.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item:first-child {
  padding-left: 0;
}

.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item::after {
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  line-height: 0;
  content: '';
}

.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-title,
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content {
  float: left;
  padding-top: 10px;
  padding-bottom: 11px;
}

.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-title {
  padding-right: 4px;
  color: #222;
  font-family: 'NanumSquare-Bold';
}

.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content strong {
  color: #18b3c9;
  font-weight: normal;
}

.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content em {
  color: #df2929;
  font-style: normal;
}

.typePos .typePos-wrapper-caption .typePos-favorites {
  float: left;
  padding: 0 10px;
}

.typePos .typePos-wrapper-caption .typePos-favorites::after {
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  line-height: 0;
  content: '';
}

.typePos .typePos-wrapper-caption .typePos-favorites .typePos-favorites-icon {
  display: none;
  float: left;
  overflow: hidden;
  width: 13px;
  height: 12px;
  margin-top: 13px;
  background: transparent url('../../../../assets/images/layout/header-favorites.png') no-repeat center top;
  text-indent: -1000px;
  cursor: pointer;
}

.typePos .typePos-wrapper-caption .typePos-favorites .typePos-favorites-icon:hover {
  background-position-y: bottom;
}

.typePos .typePos-wrapper-caption .typePos-favorites .typePos-favorites-icon.active {
  display: block;
}

.typePos .typePos-wrapper-caption .typePos-favorites .typePos-favorites-icon.favorites {
  background-position-y: bottom;
}

.typePos .typePos-wrapper-caption .typePos-favorites .typePos-favorites-icon.favorites:hover {
  background-position-y: top;
}

.typePos .typePos-wrapper-caption .typePos-favorites .typePos-favorites-title {
  float: left;
  padding-left: 9px;
  color: #000;
  font-size: 18px;
  font-family: 'NanumSquare-Bold', Arial, Verdana, sans-serif;
  line-height: 40px;
}

.typePos .typePos-wrapper-caption .typePos-lookup {
  float: left;
  padding: 0 10px;
}

.typePos .typePos-wrapper-caption .typePos-lookup >>> .ui-button .i-item::before {
  background-position-x: calc(-20px * 7);
}

.typePos .typePos-wrapper-caption .typePos-header {
  float: left;
}

.typePos .typePos-wrapper-caption .typePos-header::after {
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  line-height: 0;
  content: '';
}

.typePos .typePos-wrapper-caption .typePos-header .typePos-filed .ejs-date {
  width: 160px;
}

.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-store .typePos-filed-content .typePos-item .ui-select {
  width: 160px;
}

.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-refresh >>> .ui-button {
  height: 40px;
}

.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-refresh >>> .ui-button .i-item {
}

.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-refresh >>> .ui-button .i-item::before {
  left: 0;
  width: 14px;
  height: 14px;
  background-image: url('../../../../assets/images/ui/icon-type10.png');
  background-position-x: calc(-14px * 0);
}

.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-refresh >>> .ui-button:disabled .i-item::before {
  background-position-y: bottom;
}

.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-shortcuts .ui-select >>> .i-value {
  display: none;
}

.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-shortcuts .ui-select >>> .i-list {
  left: auto;
  right: 0;
  width: 180px;
}

.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-shortcuts .ui-select >>> .i-list .i-body > li label input:checked + .i-item {
  background-color: transparent;
}

.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-shortcuts .ui-select >>> .i-list .i-body > li label input:checked:hover + .i-item {
  background-color: #f9f9f9;
}

.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-shortcuts .ui-select >>> .ui-button {
  width: 40px;
  height: 40px;
  padding: 0;
}

.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-shortcuts .ui-select >>> .ui-button .i-item {
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: auto;
  padding-left: 0;
  line-height: 0;
  text-indent: -1000px;
}

.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-shortcuts .ui-select >>> .ui-button .i-item::before {
  left: 50%;
  width: 4px;
  height: 18px;
  margin-top: -9px;
  margin-left: -2px;
  background: transparent url('../../../../assets/images/ui/icon-type06.png') no-repeat center center;
}

.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-shortcuts .ui-select >>> .ui-button:disabled .i-item::before {
  opacity: 0.5;
}

.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-shortcuts .ui-select.i-active >>> .ui-button {
  border-color: #18b3c9;
  border-radius: 4px 4px 0 0;
}

.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-shortcuts .ui-select.i-active.i-disabled >>> .ui-button {
  box-shadow: none;
  border-color: #eee;
  border-radius: 4px;
  cursor: default;
}

.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-shortcuts .ui-select.i-active.i-disabled >>> .ui-button:hover {
  background-color: #fff;
}

.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-shortcuts .ui-select.i-active.i-disabled >>> .ui-button .i-item::before {
  opacity: 0.5;
}

.typePos .typePos-wrapper-caption .typePos-footer {
  float: right;
}

.typePos .typePos-wrapper-caption .typePos-footer::after {
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  line-height: 0;
  content: '';
}

.typePos .typePos-wrapper-caption .typePos-footer .typePos-filed.typePos-business .typePos-filed-content .typePos-item .ui-select {
  width: 170px;
}

.typePos .typePos-wrapper-caption .typePos-footer .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-window >>> .ui-button {
  width: 40px;
  height: 40px;
  padding: 0;
}

.typePos .typePos-wrapper-caption .typePos-footer .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-window >>> .ui-button .i-item {
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: auto;
  padding-left: 0;
  line-height: 0;
  text-indent: -1000px;
}

.typePos .typePos-wrapper-caption .typePos-footer .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-window >>> .ui-button .i-item::before {
  left: 50%;
  width: 14px;
  height: 14px;
  margin-left: -7px;
  background-image: url('../../../../assets/images/ui/icon-type10.png');
  background-position-x: calc(-14px * 1);
}

.typePos .typePos-wrapper-caption .typePos-footer .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-window >>> .ui-button:disabled .i-item::before {
  background-position-y: bottom;
}

.typePos .typePos-wrapper-caption .typePos-footer .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-full >>> .ui-button {
  width: 40px;
  height: 40px;
  padding: 0;
}

.typePos .typePos-wrapper-caption .typePos-footer .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-full >>> .ui-button .i-item {
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: auto;
  padding-left: 0;
  line-height: 0;
  text-indent: -1000px;
}

.typePos .typePos-wrapper-caption .typePos-footer .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-full >>> .ui-button .i-item::before {
  left: 50%;
  width: 14px;
  height: 14px;
  margin-left: -7px;
  background-image: url('../../../../assets/images/ui/icon-type10.png');
  background-position-x: calc(-14px * 2);
}

.typePos .typePos-wrapper-caption .typePos-footer .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-full >>> .ui-button:disabled .i-item::before {
  background-position-y: bottom;
}

.typePos .typePos-wrapper-caption .typePos-footer .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-information .ui-select >>> .i-value {
  display: none;
}

.typePos .typePos-wrapper-caption .typePos-footer .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-information .ui-select >>> .i-list {
  left: auto;
  right: 0;
  width: 180px;
}

.typePos .typePos-wrapper-caption .typePos-footer .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-information .ui-select >>> .i-list .i-body > li label input:checked + .i-item {
  background-color: transparent;
}

.typePos .typePos-wrapper-caption .typePos-footer .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-information .ui-select >>> .i-list .i-body > li label input:checked:hover + .i-item {
  background-color: #f9f9f9;
}

.typePos .typePos-wrapper-caption .typePos-footer .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-information .ui-select >>> .ui-button {
  max-width: 120px;
  height: 40px;
}

.typePos .typePos-wrapper-caption .typePos-footer .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-information .ui-select >>> .ui-button .i-item {
  box-sizing: border-box;
  width: 100%;
  padding-left: 24px;
  color: #18b3c9;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.typePos .typePos-wrapper-caption .typePos-footer .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-information .ui-select >>> .ui-button .i-item::before {
  width: 14px;
  height: 14px;
  background-image: url('../../../../assets/images/ui/icon-type10.png');
  background-position-x: calc(-14px * 3);
}

.typePos .typePos-wrapper-caption .typePos-footer .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-information .ui-select >>> .ui-button:disabled .i-item {
  color: #e0e0e0;
}

.typePos .typePos-wrapper-caption .typePos-footer .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-information .ui-select >>> .ui-button:disabled .i-item::before {
  opacity: 0.5;
}

.typePos .typePos-wrapper-caption .typePos-footer .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-information .ui-select.i-active >>> .ui-button {
  border-color: #18b3c9;
  border-radius: 4px 4px 0 0;
}

.typePos .typePos-wrapper-caption .typePos-footer .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-information .ui-select.i-active.i-disabled >>> .ui-button {
  box-shadow: none;
  border-color: #eee;
  border-radius: 4px;
  cursor: default;
}

.typePos .typePos-wrapper-caption .typePos-footer .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-information .ui-select.i-active.i-disabled >>> .ui-button:hover {
  background-color: #fff;
}

.typePos .typePos-wrapper-caption .typePos-footer .typePos-filed.typePos-button .typePos-filed-content .typePos-item.typePos-information .ui-select.i-active.i-disabled >>> .ui-button .i-item::before {
  opacity: 0.5;
}

.typePos .typePos-wrapper-header {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 15px 0;
  background-color: #2d2e3a;
  text-align: center;
  line-height: 0;
}

.typePos .typePos-wrapper-header .typePos-header-wrap {
  display: inline-block;
  line-height: 1.5;
}

.typePos .typePos-wrapper-header .typePos-header-wrap::after {
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  line-height: 0;
  content: '';
}

.typePos .typePos-wrapper-header .typePos-button {
  float: left;
  padding: 0 5px;
}

.typePos .typePos-wrapper-header .typePos-button >>> .ui-button {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  border-color: #1a1b23;
  background: url('../../../../assets/images/ui/button-type01.png') no-repeat right calc(-38px * 2), url('../../../../assets/images/ui/button-type01.png') no-repeat left 0, url('../../../../assets/images/ui/button-type01.png') repeat-x center calc(-38px * 1);
}

.typePos .typePos-wrapper-header .typePos-button >>> .ui-button:hover {
  background-position: right calc(-38px * 5), left calc(-38px * 3), center calc(-38px * 4);
}

.typePos .typePos-wrapper-header .typePos-button >>> .ui-button:disabled {
  border-color: #1d1d27;
  background-color: #1d1d27;
  background-image: none;
}

.typePos .typePos-wrapper-header .typePos-button >>> .ui-button:disabled:hover {
  background-color: #1d1d27;
}

.typePos .typePos-wrapper-header .typePos-button >>> .ui-button .i-item {
  color: #fff;
}

.typePos .typePos-wrapper-header .typePos-button >>> .ui-button .i-item::before {
  background-image: url('../../../../assets/images/ui/icon-type02.png');
  background-position-y: center;
}

.typePos .typePos-wrapper-header .typePos-button >>> .ui-button:disabled .i-item {
  color: #999;
}

.typePos .typePos-wrapper-header .typePos-button >>> .ui-button:disabled .i-item::before {
  background-position-y: center;
  opacity: 0.5;
}

.typePos .typePos-wrapper-header .typePos-button.typePos-inactive {
  display: none;
}

.typePos .typePos-wrapper-header .typePos-input {
  float: left;
  padding: 0 5px;
}

.typePos .typePos-wrapper-header .typePos-input .ui-input .typePos-state {
  box-sizing: border-box;
  position: absolute;
  left: 6px;
  top: 6px;
  width: 28px;
  height: 28px;
  padding: 4px 0 5px 0;
  border-radius: 2px;
  color: #fff;
  line-height: 19px;
  text-align: center;
  z-index: 10;
}

.typePos .typePos-wrapper-header .typePos-input .ui-input .typePos-title {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 15px 11px 15px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-family: 'NanumSquare-Regular';
  line-height: 19px;
  z-index: 10;
}

.typePos .typePos-wrapper-header .typePos-input .ui-input input {
  width: 100%;
  min-width: auto;
  border-color: #4c4d57;
  background-color: transparent;
  color: #fff;
  text-align: right;
}

.typePos .typePos-wrapper-header .typePos-input .ui-input input::placeholder {
  color: #999;
}

.typePos .typePos-wrapper-header .typePos-input .ui-input input::-webkit-input-placeholder {
  color: #999;
}

.typePos .typePos-wrapper-header .typePos-input .ui-input input:-ms-input-placeholder {
  color: #999;
}

.typePos .typePos-wrapper-header .typePos-input .ui-input input:focus {
  border-color: #18b3c9;
}

.typePos .typePos-wrapper-header .typePos-input .ui-input input:disabled,
.typePos .typePos-wrapper-header .typePos-input .ui-input input:disabled:focus {
  background-color: transparent;
  color: #999;
}

.typePos .typePos-wrapper-header .typePos-input >>> .ui-input button {
  display: block;
  box-sizing: border-box;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  height: 40px;
  padding: 10px 19px 9px 19px;
  margin: 0;
  border: 1px solid #1a1b23;
  border-radius: 0 4px 4px 0;
  background-color: transparent;
  background: url('../../../../assets/images/ui/button-type02.png') no-repeat right calc(-38px * 2), url('../../../../assets/images/ui/button-type02.png') no-repeat left 0, url('../../../../assets/images/ui/button-type02.png') repeat-x center calc(-38px * 1);
  line-height: 0;
  text-align: center;
}

.typePos .typePos-wrapper-header .typePos-input >>> .ui-input button:hover {
  background-position: right calc(-38px * 5), left calc(-38px * 3), center calc(-38px * 4);
}

.typePos .typePos-wrapper-header .typePos-input >>> .ui-input button:disabled {
  box-shadow: none;
  border-color: #1d1d27;
  background-color: #1d1d27;
  background-image: none;
}

.typePos .typePos-wrapper-header .typePos-input >>> .ui-input button:disabled:hover {
  background-color: #1d1d27;
}

.typePos .typePos-wrapper-header .typePos-input >>> .ui-input button .i-item {
  display: inline-block;
  position: relative;
  min-height: 19px;
  padding-left: 23px;
  color: #666;
  font-size: 13px;
  font-family: 'NanumSquare-Bold';
  line-height: 19px;
}

.typePos .typePos-wrapper-header .typePos-input >>> .ui-input button .i-item::before {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 13px;
  height: 13px;
  margin-top: -7px;
  background: transparent url('../../../../assets/images/ui/icon-type02.png') no-repeat calc(-20px * 1) center;
  content: '';
}

.typePos .typePos-wrapper-header .typePos-input >>> .ui-input button:disabled .i-item {
  color: #999;
}

.typePos .typePos-wrapper-header .typePos-input >>> .ui-input button:disabled .i-item::before {
  opacity: 0.5;
}


.typePos .typePos-wrapper-header .typePos-table {
  float: left;
  padding: 0 5px;
}

.typePos .typePos-wrapper-header .typePos-information {
  float: left;
  padding: 0 5px;
}

.typePos .typePos-wrapper-header .typePos-header {
  float: left;
  padding: 0 15px;
}

.typePos .typePos-wrapper-header .typePos-header::after {
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  line-height: 0;
  content: '';
}

.typePos .typePos-wrapper-header .typePos-header .typePos-input.typePos-number .ui-input {
  width: 130px;
}

.typePos .typePos-wrapper-header .typePos-header .typePos-input.typePos-number .ui-input input {
  padding: 0 15px 0 48px;
}

.typePos .typePos-wrapper-header .typePos-header .typePos-button.typePos-search >>> .ui-button .i-item::before {
  background-position-x: calc(-20px * 1);
}

.typePos .typePos-wrapper-header .typePos-header .typePos-button.typePos-list >>> .ui-button .i-item {
  padding-left: 0;
}

.typePos .typePos-wrapper-header .typePos-header .typePos-button.typePos-list >>> .ui-button .i-item::before {
  display: none;
  width: calc(13px + 3px);
  background-position-x: calc(-20px * 2);
}

.typePos .typePos-wrapper-header .typePos-footer {
  float: left;
  padding: 0 15px;
}

.typePos .typePos-wrapper-header .typePos-footer::after {
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  line-height: 0;
  content: '';
}

.typePos .typePos-wrapper-header .typePos-footer .typePos-button.typePos-table >>> .ui-button .i-item {
  padding-left: calc(23px + 2px);
}

.typePos .typePos-wrapper-header .typePos-footer .typePos-button.typePos-table >>> .ui-button .i-item::before {
  width: calc(13px + 2px);
  background-position-x: calc(-20px * 4);
}

.typePos .typePos-wrapper-header .typePos-footer .typePos-button.typePos-visitor >>> .ui-button .i-item::before {
  background-position-x: calc(-20px * 5);
}

.typePos .typePos-wrapper-header .typePos-footer .typePos-button.typePos-postpaid >>> .ui-button .i-item::before {
  background-position-x: calc(-20px * 6);
}

.typePos .typePos-wrapper-header .typePos-footer .typePos-information::after {
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  line-height: 0;
  content: '';
}

.typePos .typePos-wrapper-header .typePos-footer .typePos-information .typePos-input {
  padding: 0;
}

.typePos .typePos-wrapper-header .typePos-footer .typePos-information .typePos-input .ui-input input {
  position: relative;
  padding: 0 15px 0 76px;
}

.typePos .typePos-wrapper-header .typePos-footer .typePos-information .typePos-input .ui-input input:focus {
  z-index: 10;
}

.typePos .typePos-wrapper-header .typePos-footer .typePos-information .typePos-input.typePos-locker .ui-input {
  width: 150px;
}

.typePos .typePos-wrapper-header .typePos-footer .typePos-information .typePos-input.typePos-locker .ui-input input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.typePos .typePos-wrapper-header .typePos-footer .typePos-information .typePos-input.typePos-visitor {
  margin-left: -1px;
}

.typePos .typePos-wrapper-header .typePos-footer .typePos-information .typePos-input.typePos-visitor .ui-input {
  width: 230px;
}

.typePos .typePos-wrapper-header .typePos-footer .typePos-information .typePos-input.typePos-visitor .ui-input input {
  border-right-color: transparent;
  border-radius: 0;
}

.typePos .typePos-wrapper-header .typePos-footer .typePos-information .typePos-input.typePos-visitor .ui-input input:focus {
  border-color: #18b3c9;
}

.typePos .typePos-wrapper-main {
  flex: 1;
  overflow: hidden;
  padding: 10px;
  background-color: #23232f;
}

.typePos .typePos-wrapper-main::after {
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  line-height: 0;
  content: '';
}

.typePos .typePos-wrapper-main .typePos-main-box {
  float: left;
  width: 40.5%;
  height: 100%;
}

.typePos .typePos-wrapper-main .typePos-main-box .typePos-box-wrap {
  box-sizing: border-box;
  height: 100%;
  padding: 10px;
}

.typePos .typePos-wrapper-main .typePos-main-box .typePos-box-container {
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  height: 100%;
  border-radius: 8px;
  background-color: #2d2e3a;
}

.typePos .typePos-wrapper-main .typePos-main-box .typePos-box-body {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid #42434e;
  background-color: #23232f;
}

.typePos .typePos-wrapper-main .typePos-main-box .typePos-box-body .typePos-body-body {
  flex: 1;
  overflow: hidden;
}

.typePos .typePos-wrapper-main .typePos-main-box .typePos-box-footer {
  box-sizing: border-box;
  height: 150px;
  padding: 15px;
}

.typePos .typePos-wrapper-main .typePos-main-box .typePos-box-footer .typePos-button {
  list-style: none;
  position: relative;
  padding: 0;
  margin: 0;
}

.typePos .typePos-wrapper-main .typePos-main-box .typePos-box-footer .typePos-button > li {
  box-sizing: border-box;
  position: absolute;
  height: 120px;
  padding: 5px;
}

.typePos .typePos-wrapper-main .typePos-main-box .typePos-box-footer .typePos-button > li.typePos-inactive {
  display: none;
}

.typePos .typePos-wrapper-main .typePos-main-box .typePos-box-footer .typePos-button > li >>> .ui-button {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  padding: 4px;
  border-color: #1a1b23;
  background: url('../../../../assets/images/ui/button-type03.png') no-repeat right calc(-108px * 2), url('../../../../assets/images/ui/button-type03.png') no-repeat left 0, url('../../../../assets/images/ui/button-type03.png') repeat-x center calc(-108px * 1);
}

.typePos .typePos-wrapper-main .typePos-main-box .typePos-box-footer .typePos-button > li >>> .ui-button:hover {
  background-position: right calc(-108px * 5), left calc(-108px * 3), center calc(-108px * 4);
}

.typePos .typePos-wrapper-main .typePos-main-box .typePos-box-footer .typePos-button > li >>> .ui-button:disabled {
  border-color: #1d1d27 !important;
  background-color: #1d1d27 !important;
  background-image: none !important;
}

.typePos .typePos-wrapper-main .typePos-main-box .typePos-box-footer .typePos-button > li >>> .ui-button:disabled:hover {
  background-color: #1d1d27 !important;
}

.typePos .typePos-wrapper-main .typePos-main-box .typePos-box-footer .typePos-button > li >>> .ui-button .i-item {
  min-height: 24px;
  padding-left: 0;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
}

.typePos .typePos-wrapper-main .typePos-main-box .typePos-box-footer .typePos-button > li >>> .ui-button .i-item::before {
  display: none;
  height: 11px;
  margin-top: -5px;
  background-image: url('../../../../assets/images/ui/icon-type02.png');
  background-position-y: center;
}

.typePos .typePos-wrapper-main .typePos-main-box .typePos-box-footer .typePos-button > li >>> .ui-button:disabled .i-item {
  color: #999;
}

.typePos .typePos-wrapper-main .typePos-main-box .typePos-box-footer .typePos-button > li >>> .ui-button:disabled .i-item::before {
  background-position-y: center;
  opacity: 0.5;
}

@media (max-width: 1599px) {
  .typePos .typePos-wrapper-main .typePos-main-box .typePos-box-footer .typePos-button > li >>> .ui-button .i-item {
    min-height: 18px;
    font-size: 15px;
    line-height: 18px;
  }

  .typePos .typePos-wrapper-main .typePos-main-box .typePos-box-footer .typePos-button > li >>> .ui-button .i-item span {
    display: block;
  }
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate {
  width: 40.5%;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-header {
  position: relative;
  min-height: 50px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-header .typePos-accordion {
  position: absolute;
  top: 10px;
  right: 10px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-header .typePos-accordion >>> button {
  box-shadow: none;
  position: relative;
  overflow: hidden;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 50%;
  background-color: #1d1d27;
  text-indent: -1000px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-header .typePos-accordion >>> button:hover {
  background-color: #101016;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-header .typePos-accordion >>> button::before {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 9px;
  height: 5px;
  margin: -2px 0 0 -4px;
  background: transparent url('../../../../assets/images/ui/arrow-type02.png') no-repeat center top;
  content: '';
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-header .typePos-information {
  display: none;
  list-style: none;
  padding: 14px 35px 13px 5px;
  margin: 0;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-header .typePos-information > li::after {
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  line-height: 0;
  content: '';
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-header .typePos-information > li .typePos-title,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-header .typePos-information > li .typePos-content {
  box-sizing: border-box;
  float: left;
  padding: 4px 15px 5px 15px;
  font-size: 13px;
  line-height: 19px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-header .typePos-information > li .typePos-title {
  width: 68px;
  color: #fff;
  font-family: 'NanumSquare-Bold';
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-header .typePos-information > li .typePos-content {
  width: calc(100% - 68px);
  color: rgba(255, 255, 255, 0.7);
  font-family: 'NanumSquare-Regular';
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-header.typePos-open .typePos-accordion button::before {
  margin-top: -3px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-header.typePos-open .typePos-accordion button::before {
  background-position-y: bottom;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-header.typePos-open .typePos-information {
  display: block;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-body .typePos-body-footer {
  border-top: 1px solid #42434e;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-body .typePos-body-footer .typePos-body {
  position: relative;
  padding: 14px 20px 13px 20px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-body .typePos-body-footer .typePos-body .typePos-list {
  list-style: none;
  width: 287px;
  padding: 0;
  margin: 0;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-body .typePos-body-footer .typePos-body .typePos-list > li {
  padding: 5px 0 4px 0;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-body .typePos-body-footer .typePos-body .typePos-list > li::after {
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  line-height: 0;
  content: '';
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-body .typePos-body-footer .typePos-body .typePos-list > li .typePos-title,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-body .typePos-body-footer .typePos-body .typePos-list > li .typePos-content {
  float: left;
  width: 50%;
  font-size: 13px;
  line-height: 19px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-body .typePos-body-footer .typePos-body .typePos-list > li .typePos-title {
  color: #fff;
  font-family: 'NanumSquare-Bold';
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-body .typePos-body-footer .typePos-body .typePos-list > li .typePos-content {
  color: rgba(255, 255, 255, 0.7);
  font-family: 'NanumSquare-Regular';
  text-align: right;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-body .typePos-body-footer .typePos-body .typePos-total {
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -19px;
  color: #18b3c9;
  font-size: 26px;
  font-family: 'NanumSquare-Bold';
  line-height: 39px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-body .typePos-body-footer .typePos-footer {
  padding: 13px 20px;
  background-color: #2d2e3a;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-body .typePos-body-footer .typePos-footer .typePos-information {
  position: relative;
  padding-left: 21px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-family: 'NanumSquare-Regular';
  line-height: 19px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-body .typePos-body-footer .typePos-footer .typePos-information::before {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 13px;
  height: 13px;
  margin-top: -7px;
  background: transparent url('../../../../assets/images/ui/icon-type05.png') no-repeat center center;
  content: '';
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li {
  width: calc((100% - 60px) / 5);
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-plus {
  top: 0;
  left: 0;
  width: 60px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-minus {
  top: 60px;
  left: 0;
  width: 60px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-cancelSelection {
  top: 0;
  left: 60px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-cancelAll {
  top: 0;
  left: calc((((100% - 60px) / 5) * 1) + 60px);
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-deleteDocument {
  top: 0;
  left: calc((((100% - 60px) / 5) * 2) + 60px);
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-discountProcessing {
  top: 0;
  left: calc((((100% - 60px) / 5) * 3) + 60px);
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-reset {
  top: 0;
  left: calc((((100% - 60px) / 5) * 4) + 60px);
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-slipMemo {
  top: 60px;
  left: calc((((100% - 60px) / 5) * 2) + 60px);
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-unitPriceChange {
  top: 60px;
  left: calc((((100% - 60px) / 5) * 3) + 60px);
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-galleryCreation {
  top: 60px;
  left: calc((((100% - 60px) / 5) * 4) + 60px);
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-plus,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-minus,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-deleteDocument,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-discountProcessing,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-reset,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-slipMemo,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-unitPriceChange,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-galleryCreation {
  height: 60px
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-plus >>> .ui-button,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-minus >>> .ui-button,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-discountProcessing >>> .ui-button,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-reset >>> .ui-button,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-slipMemo >>> .ui-button,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-unitPriceChange >>> .ui-button,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-galleryCreation >>> .ui-button {
  background: url('../../../../assets/images/ui/button-type07.png') no-repeat right calc(-48px * 2), url('../../../../assets/images/ui/button-type07.png') no-repeat left 0, url('../../../../assets/images/ui/button-type07.png') repeat-x center calc(-48px * 1);
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-plus >>> .ui-button:hover,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-minus >>> .ui-button:hover,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-discountProcessing >>> .ui-button:hover,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-reset >>> .ui-button:hover,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-slipMemo >>> .ui-button:hover,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-unitPriceChange >>> .ui-button:hover,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-galleryCreation >>> .ui-button:hover {
  background-position: right calc(-48px * 5), left calc(-48px * 3), center calc(-48px * 4);
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-cancelSelection >>> .ui-button,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-cancelAll >>> .ui-button {
  border-color: #d11818;
  background-image: url('../../../../assets/images/ui/button-type04.png'), url('../../../../assets/images/ui/button-type04.png'), url('../../../../assets/images/ui/button-type04.png');
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-deleteDocument >>> .ui-button {
  border-color: #d11818;
  background: url('../../../../assets/images/ui/button-type08.png') no-repeat right calc(-48px * 2), url('../../../../assets/images/ui/button-type08.png') no-repeat left 0, url('../../../../assets/images/ui/button-type08.png') repeat-x center calc(-48px * 1);
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-deleteDocument >>> .ui-button:hover {
  background-position: right calc(-48px * 5), left calc(-48px * 3), center calc(-48px * 4);
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-plus >>> .ui-button,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-minus >>> .ui-button {
  padding: 0;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-plus >>> .ui-button .i-item,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-minus >>> .ui-button .i-item {
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: auto;
  padding-left: 0;
  line-height: 0;
  text-indent: -1000px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-plus >>> .ui-button .i-item::before,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-minus >>> .ui-button .i-item::before {
  display: block;
  left: 50%;
  width: 11px;
  margin-left: -5px;
  background-image: url('../../../../assets/images/ui/icon-type03.png');
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-calculate .typePos-box-footer .typePos-button > li.typePos-minus >>> .ui-button .i-item::before {
  background-position-x: -11px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product {
  width: 59.5%;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header {
  display: flex;
  flex-direction: row;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header::after {
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  line-height: 0;
  content: '';
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation {
  flex: 1;
  overflow: hidden;
  float: left;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-search {
  float: left;
  width: 111px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-button {
  position: absolute;
  top: 0;
  z-index: 10;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-button button {
  box-shadow: none;
  position: relative;
  overflow: hidden;
  width: 40px;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;
  background-color: #2d2e3a;
  text-indent: -1000px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-button button:hover {
  background-color: #1d1d27;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-button button:disabled:hover {
  background-color: #2d2e3a;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-button button::before {
  display: block;
  position: absolute;
  top: 50%;
  width: 5px;
  height: 9px;
  margin-top: -5px;
  background: transparent url('../../../../assets/images/ui/arrow-type03.png') no-repeat left center;
  content: '';
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-button button:disabled::before {
  opacity: 0.5;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-button.typePos-prev {
  left: 0;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-button.typePos-prev button::before {
  left: 20px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-button.typePos-next {
  right: 0;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-button.typePos-next button::before {
  right: 20px;
  background-position-x: right;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-list {
  overflow: hidden;
  height: 100%;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-list .typePos-wrap {
  list-style: none;
  padding: 0;
  margin: 0;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-list .typePos-wrap::after {
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  line-height: 0;
  content: '';
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-list .typePos-wrap > li {
  float: left;
  cursor: pointer;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-list .typePos-wrap > li .typePos-item {
  position: relative;
  color: #999;
  font-family: 'NanumSquare-Regular';
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-list .typePos-wrap > li:hover .typePos-item {
  color: #18b3c9;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-list .typePos-wrap > li.typePos-active {
  cursor: default;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-list .typePos-wrap > li.typePos-active .typePos-item {
  font-family: 'NanumSquare-Bold';
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-main {
  position: relative;
  height: 51px;
  padding: 0 40px;
  margin-bottom: -1px;
  z-index: 10;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-main .typePos-button button {
  height: 50px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-main .typePos-button.typePos-prev button {
  border-top-left-radius: 8px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-main .typePos-list .typePos-wrap > li {
  padding: 0 15px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-main .typePos-list .typePos-wrap > li .typePos-item {
  padding: 13px 0 14px 0;
  font-size: 16px;
  line-height: 24px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-main .typePos-list .typePos-wrap > li.typePos-active .typePos-item {
  color: #18b3c9;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-main .typePos-list .typePos-wrap > li.typePos-active .typePos-item::before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #18b3c9;
  content: '';
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-sub {
  position: relative;
  height: 60px;
  padding: 1px 35px 0 35px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-sub::before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #42434e;
  content: '';
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-sub .typePos-button {
  top: 1px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-sub .typePos-button button {
  height: 60px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-sub .typePos-list .typePos-wrap > li {
  padding: 10px 5px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-sub .typePos-list .typePos-wrap > li .typePos-item {
  padding: 10px 15px 9px 15px;
  border-radius: 20px;
  font-size: 14px;
  line-height: 21px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-navigation >>> .typePos-sub .typePos-list .typePos-wrap > li.typePos-active .typePos-item {
  color: #fff;
  background-color: #18b3c9;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-search >>> .ui-button {
  width: 100%;
  height: 100%;
  padding: 4px;
  border-radius: 0 8px 0 0;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-search >>> .ui-button .i-item {
  min-height: 21px;
  padding-left: 26px;
  font-size: 14px;
  line-height: 21px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-search >>> .ui-button .i-item::before {
  width: 16px;
  height: 16px;
  margin-top: -8px;
  background-image: url('../../../../assets/images/ui/icon-type04.png');
  background-position-y: center;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-search >>> .ui-button.i-typePrimary:disabled {
  border-color: #1d1d27;
  background-color: #1d1d27;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-search >>> .ui-button.i-typePrimary:disabled:hover {
  border-color: #1d1d27;
  background-color: #1d1d27;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-search >>> .ui-button.i-typePrimary:disabled .i-item {
  color: #999;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-header .typePos-search >>> .ui-button.i-typePrimary:disabled .i-item::before {
  background-position-y: center;
  opacity: 0.5;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-body {
  box-sizing: border-box;
  list-style: none;
  padding: 23px;
  margin: 0;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-body::after {
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  line-height: 0;
  content: '';
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-body > li {
  box-sizing: border-box;
  float: left;
  width: calc(100% / 5);
  height: calc(100% / 4);
  padding: 7px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-body >>> li button {
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: none;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 4px;
  background-color: #2d2e3a;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-body >>> li button:hover,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-body >>> li button:disabled {
  background-color: #1d1d27;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-body >>> li button:disabled .typePos-name,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-body >>> li button:disabled .typePos-price {
  color: #999;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-body >>> li button .typePos-name,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-body >>> li button .typePos-price {
  position: absolute;
  left: 20px;
  width: calc(100% - 40px);
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-body >>> li button .typePos-name {
  top: 10px;
  color: #fff;
  font-size: 14px;
  font-family: 'NanumSquare-Regular';
  line-height: 21px;
  text-align: left;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-body >>> li button .typePos-price {
  bottom: 5px;
  color: #fff;
  font-size: 20px;
  font-family: 'NanumSquare-Bold';
  line-height: 30px;
  text-align: right;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-body > li.typePos-active >>> button,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-body > li.typePos-active >>> button:hover {
  background-color: #18b3c9;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-body > li.typePos-active >>> button {
  cursor: default;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-body > li.typePos-active >>> button:disabled,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-body > li.typePos-active >>> button:disabled:hover {
  background-color: #1d1d27;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer {
  height: 45px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer::after {
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  line-height: 0;
  content: '';
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-button {
  box-sizing: border-box;
  float: left;
  width: calc((100% - 470px) / 4);
  height: 100%;
  padding: 0 1px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-button >>> button {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  box-shadow: none;
  width: 100%;
  height: 100%;
  padding: 4px;
  border: none;
  background-color: #2d2e3a;
  color: #999;
  font-size: 14px;
  font-family: 'NanumSquare-Regular';
  line-height: 21px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-button >>> button:hover {
  background-color: #1d1d27;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-button >>> button:disabled:hover {
  background-color: #2d2e3a;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-button >>> button::before {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 11px;
  height: 7px;
  margin: -4px 0 0 -5px;
  background: transparent url('../../../../assets/images/ui/arrow-type04.png') no-repeat 0 center;
  content: '';
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-button >>> button:disabled::before {
  opacity: 0.5;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-button.typePos-active >>> button {
  background-color: #1d1d27;
  color: #fff;
  font-family: 'NanumSquare-Bold';
  cursor: default;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-button.typePos-first {
  padding-left: 0;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-button.typePos-last {
  padding-right: 0;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-button.typePos-first >>> button,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-button.typePos-prev >>> button,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-button.typePos-next >>> button,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-button.typePos-last >>> button {
  text-indent: -1000px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-button.typePos-first >>> button::before,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-button.typePos-prev >>> button::before,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-button.typePos-next >>> button::before,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-button.typePos-last >>> button::before {
  display: block;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-button.typePos-prev >>> button::before,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-button.typePos-next >>> button::before {
  width: 4px;
  margin-left: -2px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-button.typePos-prev >>> button::before {
  background-position-x: -20px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-button.typePos-next >>> button::before {
  background-position-x: -40px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-button.typePos-last >>> button::before {
  background-position-x: -60px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-paging {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  list-style: none;
  float: left;
  width: 470px;
  height: 100%;
  padding: 0;
  margin: 0;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-paging::after {
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  line-height: 0;
  content: '';
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-footer .typePos-paging .typePos-button {
  flex: 1;
  width: auto;
  overflow: hidden;
}

@media (max-width: 1599px) {
  .typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-body >>> li button .typePos-name,
  .typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-body >>> li button .typePos-price {
    left: 15px;
    width: calc(100% - 30px);
  }

  .typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-body >>> li button .typePos-name {
    top: 12px;
  }

  .typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-body .typePos-body-body >>> li button .typePos-price {
    bottom: 7px;
  }
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-footer .typePos-button > li {
  width: calc(100% / 8);
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-footer .typePos-button > li.typePos-newSlip,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-footer .typePos-button > li.typePos-table {
  top: 0;
  left: 0;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-footer .typePos-button > li.typePos-save {
  top: 0;
  left: calc((100% / 8) * 1);
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-footer .typePos-button > li.typePos-issuanceOfInvoice {
  top: 0;
  left: calc((100% / 8) * 2);
  height: 60px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-footer .typePos-button > li.typePos-kitchenStatement {
  top: 60px;
  left: calc((100% / 8) * 2);
  height: 60px;
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-footer .typePos-button > li.typePos-settlementDetails {
  top: 0;
  left: calc((100% / 8) * 3);
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-footer .typePos-button > li.typePos-deferredPayment {
  top: 0;
  left: calc((100% / 8) * 4);
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-footer .typePos-button > li.typePos-card {
  top: 0;
  left: calc((100% / 8) * 5);
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-footer .typePos-button > li.typePos-cash {
  top: 0;
  left: calc((100% / 8) * 6);
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-footer .typePos-button > li.typePos-etc {
  top: 0;
  left: calc((100% / 8) * 7);
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-footer .typePos-button > li.typePos-newSlip >>> .ui-button,
.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-footer .typePos-button > li.typePos-table >>> .ui-button {
  border-color: #2a231f;
  background-image: url('../../../../assets/images/ui/button-type05.png'), url('../../../../assets/images/ui/button-type05.png'), url('../../../../assets/images/ui/button-type05.png');
}

.typePos .typePos-wrapper-main .typePos-main-box.typePos-product .typePos-box-footer .typePos-button > li.typePos-save >>> .ui-button {
  border-color: #182734;
  background-image: url('../../../../assets/images/ui/button-type06.png'), url('../../../../assets/images/ui/button-type06.png'), url('../../../../assets/images/ui/button-type06.png');
}

.blinking{
  -webkit-animation: blink 0.5s ease-in-out infinite alternate;
  -moz-animation: blink 0.5s ease-in-out infinite alternate;
  animation: blink 0.5s ease-in-out infinite alternate;
}

@-webkit-keyframes blink{
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@-moz-keyframes blink{
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes blink{
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.memo-Check-img {
  background:red;
  width: 5px;
  position: absolute;
  z-index: 1;
  right: 5px;
  top: 5px;
  height: 5px;
  border-radius: 100%;
}
.custom-position {
  position: relative;
}
</style>

<script>
import moment from "moment";
import InputDateUnusual from "@/components/common/datetime/InputDateUnusual";
import {numberWithCommas} from "@/utils/number";
import {
  DATE_FORMAT_YYYY_MM_DD,
  getDayOfWeekCaption,
  getDayOfWeekCaptionColor,
  getTodayDateTime,
} from "@/utils/date";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {
  sortBy as _sortBy,
  sumBy as _sumBy,
  maxBy as _maxBy,
  orderBy as _orderBy,
} from "lodash";
import {
  commonCodeGetComCodeDefaultValue,
  commonCodesGetColorValue, commonCodesGetCommonCodeAttrbByCodeAndIdx,
  commonCodesGetComName,
  commonCodesGetSortNo,
} from "@/utils/commonCodes";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {TIME_FORMAT_hh_mm, TIME_FORMAT_hh_mm_ss} from "@/utils/time";
import {Edit, ForeignKey, Resize} from "@syncfusion/ej2-vue-grids";
import TableStatusPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/TableStatusPopup";
import PosStatusPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/PosStatusPopup";
import TeamOrganizationPopup
  from "@/views/store-management/sales-registration/store-pos-renew/popups/TeamOrganizationPopup";
import CashReceiptPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/CashReceiptPopup";
import CheckPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/CheckPopup";
import SearchSlipsPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/SearchSlipsPopup";
import SlipListPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/SlipListPopup";
import SearchVisitorsPopup
  from "@/views/store-management/sales-registration/store-pos-renew/popups/SearchVisitorsPopup";
import SearchProductsPopup
  from "@/views/store-management/sales-registration/store-pos-renew/popups/SearchProductsPopup";
import ConfirmDeleteSlipPopup
  from "@/views/store-management/sales-registration/store-pos-renew/popups/ConfirmDeleteSlipPopup";
import SlipMemoListPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/SlipMemoListPopup";
import SettingSaleDiscountsPopup
  from "@/views/store-management/sales-registration/store-pos-renew/popups/SettingSaleDiscountsPopup";
import SettingSaleAmountPopup
  from "@/views/store-management/sales-registration/store-pos-renew/popups/SettingSaleAmountPopup";
import PrintBillPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/PrintBillPopup";
import CreateGalleryPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/CreateGalleryPopup";
import PaymentListPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/PaymentListPopup";
import SettleAmountPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/SettleAmountPopup";
import ChangeTablePopup from "@/views/store-management/sales-registration/store-pos-renew/popups/ChangeTablePopup";
import SettingTableMemberCount
  from "@/views/store-management/sales-registration/store-pos-renew/popups/SettingTableMemberCount";
import GolfERPService from "@/service/GolfERPService";
import Categories from "@/views/store-management/sales-registration/store-pos-renew/components/Categories";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import PosHistoryPopup from "@/views/common/PosHistoryPopup";
import PosMemoPopup from "@/views/common/PosMemoPopup";
import {MODES, SLIP_STATUS} from "@/store/modules/pos";
import MemoViewPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/MemoViewPopup";
import {
  openNewWindow,
} from "@/utils/appInfo";
import FShortcut from "@/views/store-management/sales-registration/store-pos-renew/components/FShortcut";
import FSelect from "@/views/store-management/sales-registration/store-pos-renew/components/FSelect";
import SettingDevicePopup from "@/views/store-management/sales-registration/store-pos-renew/popups/SettingDevicePopup";
import ChangePasswordPopup
  from "@/views/store-management/sales-registration/store-pos-renew/popups/ChangePasswordPopup";
import {
  getBizCodesObject,
  getCurrentBizCode,
  setCurrentBizCode,
} from "@/utils/pageKeyUtil";
import {
  getDeleteButtonShortcut,
  getSaveButtonShortcut,
  SHORTCUT_KEYS,
  toShortcutString,
} from "@/utils/KeyboardUtil";
import {changeDeviceInfo, getDeviceInfo, saveDeviceInfo} from "@/utils/device";
import ipBillPrinterMixin from "../../../../mixins/ipBillPrinterMixin";
import billPrintUtil from "../../../../utils/billPrintUtil";
import InputNumber from "@/components/common/InputNumber";
import ErpButton from "@/components/button/ErpButton.vue";

const documentBaseTitle = 'GreenIT ERP';

function getDefaultSaleOfProduct(product) {
  return {
    priceDivision: product.priceDivision || "NOR",
    discountAmount: 0,
    discountRate: 0,
    productCode: product.code,
    productName: product.name,
    quantity: 1,
    price: product.price || 0,
    charge: product.price || 0,
    isCaddieUsed: false,
    noTaxCode:
        product.noTaxCode || commonCodeGetComCodeDefaultValue("NOTAX_CODE"),
    insertTime: moment().format(TIME_FORMAT_hh_mm),
  };
}

function getVoidSaleOfSale(sale, order) {
  return {
    voidId: sale.id,
    priceDivision: sale.priceDivision,
    discountAmount: -sale.discountAmount,
    discountRate: -sale.discountRate,
    remarks: sale.remarks,
    productCode: sale.productCode,
    productName: sale.productName,
    quantity: -sale.quantity,
    price: sale.price,
    charge: -sale.charge,
    isCaddieUsed: sale.isCaddieUsed,
    noTaxCode: sale.noTaxCode,
    insertTime: moment().format(TIME_FORMAT_hh_mm),
    order: order,
    couponId: sale.couponId,
  };
}

function calculateDiscountRate(priceOfQuantity, discountAmount) {
  return (
      Math.floor(
          ((priceOfQuantity - (priceOfQuantity - discountAmount)) /
              priceOfQuantity) *
          100
      )
  );
}

function calculateDiscountAmount(priceOfQuantity, discountRate) {
  return (priceOfQuantity * discountRate) / 100;
}

export default {
  name: "PosView",
  components: {
    InputNumber,
    ChangePasswordPopup,
    SettingDevicePopup,
    FSelect,
    FShortcut,
    Categories,
    SettingTableMemberCount,
    ChangeTablePopup,
    SettleAmountPopup,
    PaymentListPopup,
    CreateGalleryPopup,
    PrintBillPopup,
    SettingSaleAmountPopup,
    SettingSaleDiscountsPopup,
    SlipMemoListPopup,
    ConfirmDeleteSlipPopup,
    SearchProductsPopup,
    SearchVisitorsPopup,
    SlipListPopup,
    SearchSlipsPopup,
    CheckPopup,
    CashReceiptPopup,
    TeamOrganizationPopup,
    PosStatusPopup,
    TableStatusPopup,
    EjsGridWrapper,
    InputDateUnusual,
    PosHistoryPopup,
    PosMemoPopup,
    MemoViewPopup,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin, ipBillPrinterMixin],
  filters: {
    numberWithCommas,
  },
  async created() {
    if (this.$route.query) {
      const {
        salesDate,
        storeCode,
        slipId,
      } = this.$route.query;
      if (slipId) {
        this.isFromPaymentHistory = true;
      }
      if (salesDate) {
        await this.setSalesDate({
          salesDate: moment(
              salesDate,
              `${DATE_FORMAT_YYYY_MM_DD} ${TIME_FORMAT_hh_mm_ss}`
          ).toDate(),
        });
      } else {
        await this.fetchNow();
      }
      await this.fetchStores();
      if (storeCode) {
        const store = this.storesForSelect.find(({code}) => code === storeCode);
        if (store) {
          await this.changeStore({store});
        }
      }
      if (slipId) {
        await this.fetchSlip({id: slipId});
      }
    } else {
      await this.fetchNow();
      await this.fetchStores();
    }

    document.onkeydown = async (event) => {
      if ((event.which || event.keyCode) === 116) {
        if (this.isPosSalesListIsChanged()) {
          event.preventDefault();
          this.$EventBus.$emit("pos-confirm", {
            message: "수정 내역이 있습니다. 진행하시겠습니까?",
            callback: async (confirmed) => {
              if (confirmed) {
                location.reload();
              }
            },
          });
        }
      }
    };
  },
  async mounted() {
    document.title = `매장 POS - ${documentBaseTitle}`;
    this.originalSales = this.gridProps.dataSource;
    this.$refs["select-store"].focus();
    const {
      isClientMonitorUse,
      clientMonitorInterval,
      clientMonitorEffect,
      isBillPrinterUse,
      isKitchenBillPrinterUse
    } = getDeviceInfo();

    this.addEposScriptAndConnect(isBillPrinterUse, isKitchenBillPrinterUse);
    if (isClientMonitorUse) {
      await this.fetch();
      this.openWebSocket({
        interval: clientMonitorInterval,
        effect: clientMonitorEffect,
        imageList: this.imageList,
      }, true);
    }
    if(!this.printerOutput.kitchenPrinterOutput) {
      this.kitchenPaperNumber = 0;
    }
  },
  beforeDestroy() {
    this.removeEposScript();
  },
  watch: {
    "printerOutput.kitchenPrinterOutput": function () {
      this.printerOutput.kitchenPrinterOutput
          ? this.kitchenPaperNumber = getDeviceInfo().kitchenPaperNumber
          : this.kitchenPaperNumber = 0;
    },
    salesDate(salesDate) {
      this.salesDateWaterfall(salesDate);
    },
    currentStore(store) {
      this.mediumCategories = [];
      this.mediumCategoryCode = "";
      this.smallCategories = [];
      this.smallCategoryCode = "";
      this.products = [];
      this.currentProductsPage = 1;

      this.storeWaterfall(store);
    },
    mediumCategoryCode(mediumCategoryCode) {
      this.currentProductsPage = 1;
      if (!mediumCategoryCode) {
        this.smallCategories = [];
        this.smallCategoryCode = "";
        this.products = [];
      } else {
        this.selectMediumCategoryWaterfall(mediumCategoryCode);
      }
    },
    smallCategoryCode(smallCategoryCode) {
      this.currentProductsPage = 1;
      if (!smallCategoryCode) {
        this.products = [];
      } else {
        this.selectSmallCategoryWaterFall(smallCategoryCode);
      }
    },

    slip(slip) {
      this.inputSlipNumber = slip?.number || "";
      if (slip?.visitor) {
        this.inputVisitor = slip.visitor;
        this.visibleVisitorsTeamInformation = true;
      } else {
        this.inputVisitor = {
          lockerNumber: "000",
          name: "",
        };
        this.visibleVisitorsTeamInformation = false;
      }
    },
  },
  data() {
    return {
      isSaveIng: false, // 저장 진행중인지 체크
      kitchenPaperNumber: getDeviceInfo().kitchenPaperNumber,
      printerOutput:{
        //pos 프린터 스위치
        posPrinterOutput: getDeviceInfo().posPrinterOutput,
        //주방 프린터 스위치
        kitchenPrinterOutput: getDeviceInfo().kitchenPrinterOutput,
      },
      isFromPaymentHistory: false,
      memos:null,
      memosCheck:null,

      calendarInfo: {
        bsnCode: null,
        dwCode: null,
      },

      inputSlipNumber: "",
      inputVisitor: {
        lockerNumber: "000",
        name: "",
      },

      columns: [
        {
          field: "_rid",
          headerText: "NO",
          width: 40,
          textAlign: "Center",
          type: "string",
        },
        {
          field: "id",
          visible: false,
        },
        {
          field: "voidId",
          visible: false,
        },
        {
          field: "order",
          visible: false,
        },
        {
          allowEditing: true,
          field: "discountAmount",
          visible: false,
        },
        {
          allowEditing: true,
          field: "discountRate",
          visible: false,
        },
        {
          allowEditing: true,
          field: "remarks",
          visible: false,
        },
        {
          field: "productCode",
          visible: false,
        },
        {
          allowEditing: false,
          field: "productName",
          headerText: "상품명",
          minWidth: 20,
          width: 120,
          textAlign: "Left",
        },
        {
          allowEditing: true,
          field: "quantity",
          headerText: "수량",
          minWidth: 20,
          width: 45,
          textAlign: "Center",
          type: "string",
          isNumericType: true,
        },
        {
          allowEditing: false ,
          field: "price",
          headerText: "단가",
          minWidth: 20,
          width: 70,
          textAlign: "Right",
          type: "string",
          isNumericType: true,
        },
        {
          allowEditing: false,
          groupCode: "PRICE_DIV",
          editType: "dropdownedit",
          field: "priceDivision",
          isCommonCodeField: true,
          headerText: "구분",
          minWidth: 20,
          width: 60,
          textAlign: "Center",
        },
        {
          allowEditing: false,
          field: "discount",
          headerText: "할인",
          minWidth: 20,
          width: 70,
          textAlign: "Right",
          type: "string",
        },
        {
          allowEditing: false,
          field: "charge",
          headerText: "금액",
          minWidth: 20,
          width: 80,
          textAlign: "Right",
          type: "string",
          isNumericType: true,
        },
        // {allowEditing: true, displayAsCheckBox: true, editType: "booleanedit", field: "isCaddieUsed", headerText: "캐디", minWidth: 20, width: 50, textAlign: "Center", type: "boolean",},
        {
          allowEditing: true,
          groupCode: "NOTAX_CODE",
          editType: "dropdownedit",
          field: "noTaxCode",
          isCommonCodeField: true,
          headerText: "면세",
          minWidth: 20,
          width: 60,
          textAlign: "Center",
        },
        {
          allowEditing: false,
          field: "insertTime",
          headerText: "시간",
          type: "string",
          minWidth: 20,
          width: 60,
          textAlign: "Center",
        },
        {
          allowEditing: true,
          minWidth: 120,
          width: 60,
          field: "kitchenRemarks",
          headerText: "주방메모",
          textAlign: "Center",
        },
        {
          allowEditing: false,
          field: "payerName",
          headerText: "지불자",
          textAlign: "Center",
        },

        {
          field: "couponId",
          visible: false,
        },
      ],

      totalSalesDiscount: 0,
      totalSalesAmount: 0,
      totalSalesQuantity: 0,
      maxTimeOfSales: "",

      stores: [],
      mediumCategories: [],
      mediumCategoryCode: "",
      smallCategories: [],
      smallCategoryCode: "",
      products: [],
      selectProductCode: "",

      originalSales: [],

      imageList: [],

      // product pagination
      productsSizePerPage: 20,
      currentProductsPage: 1,
      productsPaginationRange: 10,

      visibleVisitorsTeamInformation: false,
      visiblePosStatusPopup: false,
      visibleTeamOrganizationPopup: false,
      visibleCashReceiptPopup: false,
      visibleCheckPopup: false,
      visibleChangePasswordPopup: false,
      visibleSettingDevicePopup: false,
      visibleSearchSlipsPopup: false,
      visibleSettingTableMemberCountPopup: false,
      visibleSlipListPopup: false,
      visibleChangeTablePopup: false,
      visibleSearchVisitorsPopup: false,
      visibleSearchProductsPopup: false,
      visibleConfirmDeleteSlipPopup: false,
      visibleSlipMemoListPopup: false,
      visibleSettingSaleDiscountsPopup: false,
      visibleSettingSaleAmountPopup: false,
      visibleCreateGalleryPopup: false,
      visibleTableStatusPopup: false,
      visiblePrintBillPopup: false,
      visiblePaymentListPopup: false,
      visibleSettleAmountPopup: false,
      visiblePosHistoryPopup: false,
      visiblePosMemoPopup: false,
      visibleMemoViewPopup: false,
    };
  },
  computed: {
    ...mapGetters(["username", "menuUserMarkList", "currentMenu"]),
    ...mapGetters("pos", {
      isLoading: "isLoading",
      mode: "mode",
      salesDate: "salesDate",
      currentStore: "store",
      storeSalesAmount: "storeSalesAmount",
      slip: "slip",
      availableToUpdate: "availableToUpdate",
      slipStatus: "slipStatus",
      visibleConfirmDialog: "visibleConfirmDialog",
    }),
    storeCheck() {
      return commonCodesGetCommonCodeAttrbByCodeAndIdx('STORE_CODE',(this.currentStore.code),2) === 'FOOD' ? false : true;
    },
    currentRouterName() {
      return this.$router.currentRoute.name;
    },
    isMenuUserMarked() {
      return !!this.menuUserMarkList.find(
          (userMark) => userMark.routerName === this.currentRouterName
      );
    },
    isPopupOpened() {
      return (
          this.visiblePosStatusPopup ||
          this.visibleTeamOrganizationPopup ||
          this.visibleCashReceiptPopup ||
          this.visibleCheckPopup ||
          this.visibleChangePasswordPopup ||
          this.visibleSettingDevicePopup ||
          this.visibleSearchSlipsPopup ||
          this.visibleSettingTableMemberCountPopup ||
          this.visibleSlipListPopup ||
          this.visibleChangeTablePopup ||
          this.visibleSearchVisitorsPopup ||
          this.visibleSearchProductsPopup ||
          this.visibleConfirmDeleteSlipPopup ||
          this.visibleSlipMemoListPopup ||
          this.visibleSettingSaleDiscountsPopup ||
          this.visibleSettingSaleAmountPopup ||
          this.visibleCreateGalleryPopup ||
          this.visibleTableStatusPopup ||
          this.visiblePrintBillPopup ||
          this.visiblePaymentListPopup ||
          this.visibleSettleAmountPopup ||
          this.visibleConfirmDialog ||
          this.visiblePosHistoryPopup ||
          this.visiblePosMemoPopup  ||
          this.visibleMemoViewPopup
      );
    },
    saveShortcutButtonShortcutString() {
      return toShortcutString(getSaveButtonShortcut());
    },
    deleteShortcutButtonShortcutString() {
      return toShortcutString(getDeleteButtonShortcut());
    },
    refreshShortcutButtonProps() {
      return {
        shortcutKey: "PosView.shortcuts.refresh",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F2,
        },
        visibleShortcutString: false,
      };
    },
    searchSlipsShortcutButtonProps() {
      return {
        shortcutKey: "PosView.shortcuts.searchSlips",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F12,
        },
        visibleShortcutString: false,
      };
    },
    searchVisitorsShortcutButtonProps() {
      return {
        shortcutKey: "PosView.shortcuts.searchVisitors",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F10,
        },
        visibleShortcutString: false,
      };
    },
    searchProductsShortcutButtonProps() {
      return {
        shortcutKey: "PosView.shortcuts.searchProducts",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F3,
        },
        visibleShortcutString: false,
      };
    },
    createSlipShortcutButtonProps() {
      return {
        shortcutKey: "PosView.shortcuts.createSlip",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.Insert,
        },
        visibleShortcutString: false,
      };
    },
    showTableStatusShortcutButtonProps() {
      return {
        shortcutKey: "PosView.shortcuts.showTableStatus",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.Insert,
        },
        visibleShortcutString: false,
      };
    },
    postSettleAmountShortcutButtonProps() {
      return {
        shortcutKey: "PosView.shortcuts.postSettleAmount",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F8,
        },
        visibleShortcutString: false,
      };
    },
    settleAmountUsingCardShortcutButtonProps() {
      return {
        shortcutKey: "PosView.shortcuts.settleAmountUsingCard",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F7,
        },
        visibleShortcutString: false,
      };
    },
    salesDateValueModel: {
      get() {
        return moment(this.salesDate).format(DATE_FORMAT_YYYY_MM_DD);
      },
      async set(strSalesDate) {
        await this.changeSalesDate({
          salesDate: moment(strSalesDate, DATE_FORMAT_YYYY_MM_DD).toDate(),
        });
      },
    },
    dayOfWeekCaption() {
      return getDayOfWeekCaption(this.salesDate);
    },
    dayOfWeekCaptionColor() {
      const {bsnCode, dwCode} = this.calendarInfo;

      return getDayOfWeekCaptionColor(null, bsnCode, dwCode);
    },
    weekdayWeekendCaption() {
      const {bsnCode} = this.calendarInfo;

      return commonCodesGetComName("BSN_CODE", bsnCode);
    },
    weekdayWeekendCaptionColor() {
      const {bsnCode} = this.calendarInfo;

      return commonCodesGetColorValue("BSN_CODE", bsnCode);
    },
    storesForSelect() {
      return _sortBy(
          this.stores
              .filter((store) => !["FRONT","CART"].includes(store.code) ) // 카트대여 및 프런트 제외
              .map((store) => ({
                ...store,
                name: commonCodesGetComName("STORE_CODE", store.code),
                value: store.code,
              })),
          ({code}) => (!code ? -1 : commonCodesGetSortNo("STORE_CODE", code))
      );
    },
    businessCodesForSelect() {
      return (getBizCodesObject() || []).map((business) => ({
        name: business.bizName,
        value: business.bizCode,
      }));
    },
    currentBusinessCode() {
      return getCurrentBizCode();
    },
    currentStoreCode() {
      return this.currentStore?.code;
    },
    currentStoreName() {
      return this.currentStore?.name || "";
    },
    usingTable() {
      return this.currentStore?.usingTable || false;
    },
    creatableGallery() {
      return this.currentStore?.creatableGallery || false;
    },
    slipStatusMessage() {
      switch (this.slipStatus) {
        case SLIP_STATUS.SETTLEMENT_COMPLETE_PRE_PAY:
          return "정산(선불) 완료된 전표입니다.";
        case SLIP_STATUS.SETTLEMENT_COMPLETE_POST_PAY:
          return "정산(후불) 완료된 전표입니다.";
        case SLIP_STATUS.SETTLEMENT_COMPLETE_NA:
          return "정산 완료된 전표입니다.";
        case SLIP_STATUS.SETTLEMENT_INCOMPLETE_GROUP:
        case SLIP_STATUS.SETTLEMENT_INCOMPLETE_TEAM:
          return "부분합산 처리된 전표입니다.";
        case SLIP_STATUS.SETTLEMENT_INCOMPLETE_NA:
          return "전표 입력중입니다.";
        case SLIP_STATUS.SETTLEMENT_DELETED_NA:
          return "삭제된 전표입니다.";
        default:
          return "";
      }
    },
    slipStatusPaidColor() {
      if (this.slip?.isDeleted) {
        return "red";
      }

      return commonCodesGetColorValue(
          "PAY_FLAG",
          this.slip?.isPaid ? "1" : "0"
      );
    },
    slipStatusLabelInnerText() {
      if (this.slip?.isDeleted) {
        return "";
      }

      switch (this.slipStatus) {
        case SLIP_STATUS.SETTLEMENT_COMPLETE_PRE_PAY:
        case SLIP_STATUS.SETTLEMENT_COMPLETE_POST_PAY:
        case SLIP_STATUS.SETTLEMENT_COMPLETE_NA:
          return "√";
        case SLIP_STATUS.SETTLEMENT_INCOMPLETE_GROUP:
          return "G";
        case SLIP_STATUS.SETTLEMENT_INCOMPLETE_TEAM:
          return "T";
        default:
          return "";
      }
    },
    slipStoreTableName() {
      const storeUsersOrdered = _orderBy(
          this.slip?.storeTableUses,
          "insertDateTime"
      );
      if (storeUsersOrdered && 0 < storeUsersOrdered.length) {
        return (
            storeUsersOrdered[storeUsersOrdered.length - 1]?.storeTable?.name ||
            "N/A"
        );
      } else {
        return "N/A";
      }
    },
    slipStoreTableId() {
      const storeUsersOrdered = _orderBy(
          this.slip?.storeTableUses,
          "insertDateTime"
      );

      return storeUsersOrdered[storeUsersOrdered.length - 1]?.storeTable?.id;
    },
    visitorsCount() {
      return this.slip?.domesticCustomersCount || "0";
    },
    reservationInformationString() {
      const team = this.inputVisitor.team;
      if (!team) {
        return "";
      } else {
        return `${team.time} ${commonCodesGetComName(
            "COURSE_CODE",
            team.course
        )} ${team.reserveName}`;
      }
    },
    caddiesInformationString() {
      return (
          this.inputVisitor.team?.teamCaddies
              ?.map(({caddie: {name} = {}, cartNumber}) => `${name} (${cartNumber})`)
              .join(", ") || ""
      );
    },
    visitorsInformationString() {
      return (
          this.inputVisitor.team?.visitors
              ?.map(
                  ({name = "일반고객", lockerNumber}) =>
                      `${name}${!lockerNumber ? "" : ` (${lockerNumber})`}`
              )
              .join(", ") || ""
      );
    },
    gridProps() {
      const dataSource = (this.slip?.sales || []).map(
          (
              {
                id,
                voidId,
                order,
                quantity,
                price,
                productCode,
                productName,
                priceDivision,
                discountAmount = 0,
                discountRate = 0,
                noTaxCode,
                remarks = "",
                kitchenRemarks = "",
                isCaddieUsed = false,
                insertDateTime,
                amount,
                discounts,
                payerName
              },
              _rid
          ) => ({
            _rid: _rid + 1,
            id,
            voidId,
            order,
            productCode,
            productName,
            price,
            quantity,
            discountAmount,
            discountRate,
            remarks,
            kitchenRemarks,
            priceDivision,
            payerName,
            discount:
                (priceDivision !== "AMT")
                    ? discountRate
                        ? `${discountRate}%`
                        : ""
                    : discountAmount
                        ? numberWithCommas(discountAmount)
                        : "",
            charge:
                !priceDivision === "AMT"
                    ? (amount * discountRate) / 100
                    : amount -  discountAmount
            ,
            isCaddieUsed,
            noTaxCode: noTaxCode,
            insertTime: moment(
                insertDateTime,
                `${DATE_FORMAT_YYYY_MM_DD} ${TIME_FORMAT_hh_mm_ss}`
            ).format(TIME_FORMAT_hh_mm),
            discounts,
            couponId:
              discounts.filter(item => item.applyDivision === "COUPON").length > 0 ?
                Number(discounts.filter(item => item.applyDivision === "COUPON")[0]?.usingNumber) :
                null,
          })
      );

      return {
        allowFiltering: false,
        allowResizing: true,
        allowSelection: true,
        allowSorting: true,
        dataSource,
        provides: [Edit, ForeignKey, Resize],
        selectionSettings: {type: "Single", enableToggle: false},
        useCommonStyles: false,
      };
    },
    firstProductsPage() {
      return 1;
    },
    lastProductsPage() {
      return Math.ceil((this.products.length + 1) / this.productsSizePerPage);
    },
    offsetProductsPage() {
      return Math.floor(
          (this.currentProductsPage - 1) / this.productsPaginationRange
      );
    },
    startCurrentProductsPaginationRange() {
      return (
          Math.floor(this.currentProductsPage / this.productsPaginationRange) + 1
      );
    },
    endCurrentProductsPaginationRange() {
      const next =
          this.startCurrentProductsPaginationRange +
          this.productsPaginationRange -
          1;

      return next < this.lastProductsPage ? next : this.lastProductsPage;
    },
    offsetProducts() {
      return (this.currentProductsPage - 1) * this.productsSizePerPage;
    },
    visibleProducts() {
      return this.products.slice(
          this.offsetProducts,
          this.offsetProducts + this.productsSizePerPage
      );
    },
  },
  methods: {
    ...mapMutations(["SET_MENU_USER_MARKS"]),
    ...mapActions("pos", [
      "changeSalesDate",
      "changeStore",
      "intoCreateSlipMode",
      "changeSlipStoreTable",
      "changeSlipDomesticCustomersCount",
      "changeVisitor",
      "removeVisitor",
    ]),
    ...mapMutations("pos", [
      "setSalesDate",
      "setSlip",
      "setMode",
      "enterSlip",
      "clear",
    ]),
    changeDeviceInfo,
    getDayOfWeekCaptionColor,
    commonCodesGetComName,
    commonCodesGetColorValue,
    toShortcutString,
    async onFavoriteClick() {
      this.$EventBus.$emit("pos-confirm", {
        message: this.isMenuUserMarked
            ? "즐겨찾기에서 삭제하시겠습니까?"
            : "즐겨찾기에 추가하시겠습니까?",
        callback: async (confirmed) => {
          if (!confirmed) {
            return;
          }

          const currentMenu = this.currentMenu(this.currentRouterName);
          if (!currentMenu) {
            this.errorToast("메뉴 정보를 얻어오지 못했습니다");
            return;
          }

          const menuUserMarks = this.isMenuUserMarked
              ? await GolfErpAPI.deleteMenuUserMark(currentMenu.menuId)
              : await GolfErpAPI.postMenuUserMark(currentMenu.menuId);

          this.SET_MENU_USER_MARKS(menuUserMarks);
          if (this.isMenuUserMarked) {
            this.infoToast("추가하였습니다");
          } else {
            this.infoToast("삭제하였습니다");
          }
        },
      });
    },
    getTotalSalesAmount(excludes = []) {
      const records = this.$refs["salesGrid"]
          .getBatchCurrentViewRecords()
          .filter(({_rid}) => !excludes.includes(_rid));
      return _sumBy(
          records || [],
          ({quantity = 0, price = 0, discountAmount = 0}) =>
              quantity * price - discountAmount
      );
    },
    async fetchMemos() {
      this.memos = (await GolfErpAPI.fetchStorePosMemo({
        storeCode: this.currentStoreCode,
        bsnDate: moment(this.salesDate).format(DATE_FORMAT_YYYY_MM_DD)
      })) || [];
      this.memosCheck = this.memos.every(data=>
          data.isMemoConfirm === true
      );
    },
    calcAllSalesInfo() {
      const records = this.$refs["salesGrid"].getBatchCurrentViewRecords();
      console.log('records=========================================>',records);
      this.calcTotalSalesDiscount(records);
      this.calcTotalSalesAmount(records);
      this.calcTotalSalesQuantity(records);
      this.calcMaxTimeOfSales(records);
    },
    calcTotalSalesDiscount(records) {
      this.totalSalesDiscount = `${_sumBy(
          records || [],
          ({discountAmount = 0 , quantity ,priceDivision ,discountRate ,discount ,price}) => {
            const div = this.discountDiv(priceDivision, discountAmount, discountRate , discount) ;
            if(div === 'AMT') {
              return discountAmount;
            } else if(div === 'RATE') {
              return ((price * quantity) * discountRate)/100;
            }
          }
      )}`;
    },
    calcTotalSalesQuantity(records) {
      this.totalSalesQuantity = `${_sumBy(
          records || [],
          ({quantity = 0}) => quantity
      )}`;
    },
    calcTotalSalesAmount(records) {

      this.totalSalesAmount = `${_sumBy(
          records || [],
          ({charge = 0}) =>
              charge
      )}`;
    },
    calcMaxTimeOfSales(records) {
      const maxTime = _maxBy(records || [], ({insertTime}) => insertTime)
          ?.insertTime;

      if (!maxTime) {
        this.maxTimeOfSales = "";
        return;
      }

      this.maxTimeOfSales = maxTime;
    },
    async setVisitor({visitor, bsnDate}) {
      if (!this.availableToUpdate) {
        return;
      }

      await this.changeVisitor({visitor, bsnDate});
      this.infoToast("변경되었습니다.");

      this.inputVisitor = visitor;
      this.visibleVisitorsTeamInformation = true;
    },
    async clearVisitor() {
      await this.removeVisitor();

      this.inputVisitor = {
        lockerNumber: "000",
        name: "",
      };
      this.visibleVisitorsTeamInformation = false;
    },

    async createSale(product) {
      const salesGridRef = this.$refs["salesGrid"];
      const order =
          (_maxBy(salesGridRef.getBatchCurrentViewRecords(), "order")?.order ||
              0) + 1;
      let addRowIndex = null;
      if (product.isOpenPrice) {
        this.visibleSettingSaleAmountPopup = true;
        this.$nextTick(() => {
          this.$refs["setting-sale-amount-popup"].show({
            productCode: product.code,
            callback: ({priceDivision, price}) => {
              if (priceDivision) {
                product.priceDivision = priceDivision;
              }

              if (1000000000 < Math.abs(price)) {
                this.errorToast("매출액이 허용된 범위를 초과하였습니다.");
                return;
              } else if (
                  2000000000 < Math.abs(this.getTotalSalesAmount() + price)
              ) {
                this.errorToast("매출 총액이 허용된 범위를 초과하였습니다.");
                return;
              }

              addRowIndex = salesGridRef.addRecord({
                ...getDefaultSaleOfProduct(product),
                price,
                order,
              });
              this.calcAllSalesInfo();
            },
          });
        });
      } else {
        addRowIndex = salesGridRef.addRecord({
          ...getDefaultSaleOfProduct(product),
          order,
        });
        this.calcAllSalesInfo();
      }

      if (addRowIndex) {
        salesGridRef.editCell(addRowIndex - 1, "quantity");
      }
    },
    voidSale(targetSale) {
      // void 매출은 void 할 수 없음
      if (targetSale.voidId) {
        return;
      }

      const gridRef = this.$refs["salesGrid"];

      // 다른 void 매출 중에서, targetSale 을 이미 void 했는지 검사
      const batchCurrentViewRecords = gridRef.getBatchCurrentViewRecords();
      if (
          batchCurrentViewRecords.find(({voidId}) => voidId === targetSale.id)
      ) {
        return;
      }

      const order =
          (_maxBy(gridRef.getBatchCurrentViewRecords(), "order")?.order || 0) + 1;

      gridRef.addRecord(getVoidSaleOfSale(targetSale, order));
      this.calcAllSalesInfo();
    },
    removeSale(targetSale) {
      const salesGridRef = this.$refs["salesGrid"];
      salesGridRef.selectRow(
          salesGridRef
              .getBatchCurrentViewRecords()
              .findIndex(
                  (batchCurrentViewRecord) =>
                      batchCurrentViewRecord._rid === targetSale._rid
              )
      );
      salesGridRef.deleteRecord();
      this.calcAllSalesInfo();
    },
    // 변경 수량 (할인율 금액 등 관리)
    changeQuantity(targetSale, quantity) {
      const salesGridRef = this.$refs["salesGrid"];

      let {
        _rid,
        price,
        priceDivision,
        discountAmount,
        discountRate,
        discount
      } = targetSale;
      const newPriceOfQuantity = quantity * price;
      const rowIndex = salesGridRef
          .getBatchCurrentViewRecords()
          .findIndex(
              (batchCurrentViewRecord) =>
                  batchCurrentViewRecord._rid === targetSale._rid
          );

      priceDivision = this.discountDiv(priceDivision, discountAmount, discountRate , discount);
      if (1000000000 < Math.abs(newPriceOfQuantity)) {
        this.errorToast("매출액이 허용된 범위를 초과하였습니다.");
        return false;
      }

      if (priceDivision === "AMT") {
        if (
            2000000000 <
            Math.abs(
                this.getTotalSalesAmount([_rid]) +
                (newPriceOfQuantity - discountAmount)
            )
        ) {
          this.errorToast("매출 총액이 허용된 범위를 초과하였습니다.");
          return false;
        }

        salesGridRef.updateCell(
            rowIndex,
            "discountRate",
            calculateDiscountRate(newPriceOfQuantity, discountAmount)
        );

        salesGridRef.updateCell(
            rowIndex,
            "charge",
            newPriceOfQuantity - discountAmount
        );
      } else if (priceDivision === "RATE") {
        if (
            2000000000 <
            Math.abs(
                this.getTotalSalesAmount([_rid]) +
                (newPriceOfQuantity - (newPriceOfQuantity * discountRate) / 100)
            )
        ) {
          this.errorToast("매출 총액이 허용된 범위를 초과하였습니다.");
          return false;
        }

        salesGridRef.updateCell(
            rowIndex,
            "discountAmount",
            calculateDiscountAmount(newPriceOfQuantity, discountRate)
        );
        salesGridRef.updateCell(
            rowIndex,
            "charge",
            newPriceOfQuantity - (newPriceOfQuantity * discountRate) / 100
        );
      } else {
        if (
            2000000000 <
            Math.abs(this.getTotalSalesAmount([_rid]) + newPriceOfQuantity)
        ) {
          this.errorToast("매출 총액이 허용된 범위를 초과하였습니다.");
          return false;
        }

        salesGridRef.updateCell(rowIndex, "charge", newPriceOfQuantity);
      }

      salesGridRef.updateCell(rowIndex, "quantity", quantity);

      this.calcAllSalesInfo();

      salesGridRef.editCell(rowIndex, "quantity");

      return true;
    },
    changePrice(rid, propsPrice = 0 ,priceDivision) {
      const salesGridRef = this.$refs["salesGrid"];
      const {
        // charge,//금액
        discount,//할인 지표
        discountAmount,//할인 금액
        discountRate,//할인 %
        price,//단가
        // priceDivision,//구분
        quantity,//수량
      } = salesGridRef
          .getBatchCurrentViewRecords()
          .find(
              (batchCurrentViewRecord) =>
                  batchCurrentViewRecord._rid === rid
          );

      const _rid = salesGridRef
          .getBatchCurrentViewRecords()
          .findIndex(
              (batchCurrentViewRecord) =>
                  batchCurrentViewRecord._rid === rid
          );

      let newCharge = propsPrice ? (propsPrice * quantity) : (price * quantity) ;
      const selfPrice = propsPrice;
      const newSelfPrice = propsPrice * quantity;
      let totalCharge ;
      if (1000000000 < Math.abs(newCharge)) {
        this.errorToast("매출액이 허용된 범위를 초과하였습니다.");
        return false;
      }
      const discountDiv = this.discountDiv(priceDivision, discountAmount, discountRate , discount);

      if (discountDiv === "AMT") {
        totalCharge = newCharge - discountAmount;
          if (
              2000000000 <
              Math.abs(
                  this.getTotalSalesAmount([rid]) + totalCharge
              )
          ) {
            this.errorToast("매출 총액이 허용된 범위를 초과하였습니다.");
            return false;
          }
          if(priceDivision === 'SELF') {
            if(newSelfPrice !== 0) {
              salesGridRef.updateCell(
                  _rid,
                  "discount",
                  numberWithCommas(discountAmount)
              );
            } else {
              salesGridRef.updateCell(
                  _rid,
                  "discount",
                  ""
              );
            }
            // 단가 변경이 자가소비면 금액이 0원이여도 반영
            salesGridRef.updateCell(
                _rid,
                "discountRate",
                calculateDiscountRate(newSelfPrice, discountAmount)
            );

            salesGridRef.updateCell(
                _rid,
                "charge",
                (selfPrice * quantity) - discountAmount
            );
            salesGridRef.updateCell(_rid, "price", selfPrice);
            this.calcAllSalesInfo();
            return true;
          }
          if(totalCharge !== 0) {
            salesGridRef.updateCell(
                _rid,
                "discountRate",
                calculateDiscountRate(newCharge, discountAmount)
            );
            salesGridRef.updateCell(
                _rid,
                "charge",
                totalCharge
            );
          }
      } else if(discountDiv === "RATE") {
        totalCharge = newCharge - (newCharge * discountRate) / 100;
          if (
              2000000000 <
              Math.abs(
                  this.getTotalSalesAmount([rid]) +
                  ((propsPrice * quantity) - ((propsPrice * quantity) * discountRate) / 100)
              )
          ) {
            this.errorToast("매출 총액이 허용된 범위를 초과하였습니다.");
            return false;
          }
        if(priceDivision === 'SELF') {
          if(newSelfPrice!== 0) {
            salesGridRef.updateCell(
                _rid,
                "discount",
                `${numberWithCommas(discountRate)}%`
            );
          } else {
            salesGridRef.updateCell(
                _rid,
                "discount",
                ""
            );
          }
          // 단가 변경이 자가소비면 금액이 0원이여도 반영
          salesGridRef.updateCell(
              _rid,
              "discountAmount",
              calculateDiscountAmount(newSelfPrice, discountRate)
          );
          salesGridRef.updateCell(
              _rid,
              "charge",
              newSelfPrice - (newSelfPrice * discountRate) / 100
          );
          salesGridRef.updateCell(_rid, "price", selfPrice);
          console.log("propsPrice 단가",selfPrice);
          this.calcAllSalesInfo();
          return true;
        }
          if(totalCharge !== 0) {
            salesGridRef.updateCell(
                _rid,
                "discountAmount",
                calculateDiscountAmount(newCharge, discountRate)
            );
            // salesGridRef.updateCell(
            //     _rid,
            //     "discount",
            //     `${numberWithCommas(discountRate)}%`
            // );
            salesGridRef.updateCell(
                _rid,
                "charge",
                totalCharge
            );
          }
      }
      if( propsPrice !== 0 ){
        salesGridRef.updateCell(_rid, "price", propsPrice);
        this.calcAllSalesInfo();
      }
      return true;
    },
    categoryDiscountAmount(targetSale, discountRate = 0, remarks ,priceDivision) {

      const salesGridRef = this.$refs["salesGrid"];

      const {quantity, price, id} = targetSale;


      const newPriceOfQuantity = quantity * price;
      const rowIndex = salesGridRef
          .getBatchCurrentViewRecords()
          .findIndex(
              (batchCurrentViewRecord) =>
                  batchCurrentViewRecord._rid === targetSale._rid
          );

      if (discountRate === 0) {

        salesGridRef.updateCell(rowIndex, "priceDivision", priceDivision);
        salesGridRef.updateCell(rowIndex, "discountRate", 0);
        salesGridRef.updateCell(rowIndex, "discountAmount", 0);
        salesGridRef.updateCell(rowIndex, "charge", newPriceOfQuantity);
        salesGridRef.updateCell(rowIndex, "remarks", "");
        salesGridRef.updateCell(rowIndex, "discount", "");
        salesGridRef.updateCell(rowIndex, "couponId", id ? 0 : null);
      } else {

        salesGridRef.updateCell(rowIndex, "priceDivision", priceDivision);
        salesGridRef.updateCell(rowIndex, "discountRate", discountRate);
        salesGridRef.updateCell(
            rowIndex,
            "discountAmount",
            calculateDiscountAmount(newPriceOfQuantity, discountRate)
        );
        salesGridRef.updateCell(
            rowIndex,
            "charge",
            newPriceOfQuantity - (newPriceOfQuantity * discountRate) / 100
        );
        salesGridRef.updateCell(rowIndex, "remarks", remarks);
        salesGridRef.updateCell(
            rowIndex,
            "discount",
            `${numberWithCommas(discountRate)}%`
        );
        salesGridRef.updateCell(rowIndex, "couponId", id ? 0 : null);
      }

      this.calcAllSalesInfo();

    },
    // 적용할인금액
    applyDiscountCoupon(targetSale, discountAmount = 0, discountRate = 0, remarks, priceDivision, couponId) {
      const salesGridRef = this.$refs["salesGrid"];

      const {quantity, price} = targetSale;

      const priceDivAttrb = commonCodesGetCommonCodeAttrbByCodeAndIdx("PRICE_DIV", priceDivision, 2, false);

      const newPriceOfQuantity = quantity * price;
      const rowIndex = salesGridRef
        .getBatchCurrentViewRecords()
        .findIndex(
          (batchCurrentViewRecord) =>
            batchCurrentViewRecord._rid === targetSale._rid
        );

      if (discountAmount === 0) {
        if (priceDivAttrb !== "N") {
          salesGridRef.updateCell(rowIndex, "priceDivision", "NOR");
        }
        salesGridRef.updateCell(rowIndex, "discountRate", 0);
        salesGridRef.updateCell(rowIndex, "discountAmount", 0);
        salesGridRef.updateCell(rowIndex, "charge", newPriceOfQuantity);
        salesGridRef.updateCell(rowIndex, "remarks", "");
        salesGridRef.updateCell(rowIndex, "discount", "");
      } else {
        if (priceDivAttrb !== "N") {
          salesGridRef.updateCell(rowIndex, "priceDivision", priceDivision);
        }
        salesGridRef.updateCell(
          rowIndex,
          "discountRate",
          discountRate
        );
        salesGridRef.updateCell(
          rowIndex,
          "discountAmount",
          discountAmount
        );
        salesGridRef.updateCell(
          rowIndex,
          "charge",
          newPriceOfQuantity - discountAmount
        );
        salesGridRef.updateCell(rowIndex, "remarks", remarks);
        salesGridRef.updateCell(
          rowIndex,
          "discount",
          priceDivision === "RATE" ? `${numberWithCommas(discountRate)}%` : numberWithCommas(discountAmount)
        );
        salesGridRef.updateCell(rowIndex, "couponId", couponId);
      }

      this.calcAllSalesInfo();
    },
    applyDiscountAmount(targetSale, discountAmount = 0, remarks, priceDivision) {
      const salesGridRef = this.$refs["salesGrid"];

      const {quantity, price, id} = targetSale;

      const priceDivAttrb = commonCodesGetCommonCodeAttrbByCodeAndIdx("PRICE_DIV", priceDivision, 2, false);

      const newPriceOfQuantity = quantity * price;
      const rowIndex = salesGridRef
          .getBatchCurrentViewRecords()
          .findIndex(
              (batchCurrentViewRecord) =>
                  batchCurrentViewRecord._rid === targetSale._rid
          );

      if (discountAmount === 0) {
        if (priceDivAttrb !== "N") {
          salesGridRef.updateCell(rowIndex, "priceDivision", "NOR");
        }
        salesGridRef.updateCell(rowIndex, "discountRate", 0);
        salesGridRef.updateCell(rowIndex, "discountAmount", 0);
        salesGridRef.updateCell(rowIndex, "charge", newPriceOfQuantity);
        salesGridRef.updateCell(rowIndex, "remarks", "");
        salesGridRef.updateCell(rowIndex, "discount", "");
        salesGridRef.updateCell(rowIndex, "couponId", id ? 0 : null);
      } else {
        if (priceDivAttrb !== "N") {
          salesGridRef.updateCell(rowIndex, "priceDivision", "AMT");
        }
        salesGridRef.updateCell(
            rowIndex,
            "discountRate",
            calculateDiscountRate(newPriceOfQuantity, discountAmount)
        );
        salesGridRef.updateCell(rowIndex, "discountAmount", discountAmount);
        salesGridRef.updateCell(
            rowIndex,
            "charge",
            newPriceOfQuantity - discountAmount
        );
        salesGridRef.updateCell(rowIndex, "remarks", remarks);
        salesGridRef.updateCell(
            rowIndex,
            "discount",
            numberWithCommas(discountAmount)
        );
        salesGridRef.updateCell(rowIndex, "couponId", id ? 0 : null);
      }

      this.calcAllSalesInfo();
    },
    applyDiscountRate(targetSale, discountRate = 0, remarks ,priceDivision) {
      const salesGridRef = this.$refs["salesGrid"];

      const {quantity, price, id} = targetSale;

      const priceDivAttrb = commonCodesGetCommonCodeAttrbByCodeAndIdx("PRICE_DIV", priceDivision, 2, false);

      const newPriceOfQuantity = quantity * price;
      const rowIndex = salesGridRef
          .getBatchCurrentViewRecords()
          .findIndex(
              (batchCurrentViewRecord) =>
                  batchCurrentViewRecord._rid === targetSale._rid
          );

      if (discountRate === 0) {
        if (priceDivAttrb !== "N") {
          salesGridRef.updateCell(rowIndex, "priceDivision", "NOR");
        }
        salesGridRef.updateCell(rowIndex, "discountRate", 0);
        salesGridRef.updateCell(rowIndex, "discountAmount", 0);
        salesGridRef.updateCell(rowIndex, "charge", newPriceOfQuantity);
        salesGridRef.updateCell(rowIndex, "remarks", "");
        salesGridRef.updateCell(rowIndex, "discount", "");
        salesGridRef.updateCell(rowIndex, "couponId", id ? 0 : null);
      } else {
        if (priceDivAttrb !== "N") {
          salesGridRef.updateCell(rowIndex, "priceDivision", "RATE");
        }
        salesGridRef.updateCell(rowIndex, "discountRate", discountRate);
        salesGridRef.updateCell(
            rowIndex,
            "discountAmount",
            calculateDiscountAmount(newPriceOfQuantity, discountRate)
        );
        salesGridRef.updateCell(
            rowIndex,
            "charge",
            newPriceOfQuantity - (newPriceOfQuantity * discountRate) / 100
        );
        salesGridRef.updateCell(rowIndex, "remarks", remarks);
        salesGridRef.updateCell(
            rowIndex,
            "discount",
            `${numberWithCommas(discountRate)}%`
        );
        salesGridRef.updateCell(rowIndex, "couponId", id ? 0 : null);
      }

      this.calcAllSalesInfo();
    },
    // 수량 업
    increaseQuantity(increaseTargetSale) {
      this.changeQuantity(increaseTargetSale, increaseTargetSale.quantity + 1);
    },
    // 수량 다운
    decreaseQuantity(decreaseTargetSale) {
      this.changeQuantity(decreaseTargetSale, decreaseTargetSale.quantity - 1);
    },
    async processPostPayment() {
      const gridRef = this.$refs["salesGrid"];
      const salesAmount = (gridRef.getBatchCurrentViewRecords() || []).reduce(
          (acc, {charge}) => acc + charge,
          0
      );

      const task = async (visitor = this.slip?.visitor, slip = this.slip) => {
        await this.onSaveClick(false);

        const confirmMessage = `총금액: ${numberWithCommas(
            salesAmount
        )}원\n전표번호: ${this.slip.number}\n${
            visitor.lockerNumber ? `락카키: ${visitor.lockerNumber}` : ""
        }\n\n내장객 ${visitor.name}님 에게\n후불정산 처리하시겠습니까?`;

        this.$EventBus.$emit("pos-confirm", {
          message: confirmMessage,
          callback: async (confirmed) => {
            if (confirmed) {
              const bsnDate = moment(slip.date || this.salesDate).format(
                  DATE_FORMAT_YYYY_MM_DD
              );
              await GolfERPService.storeCheckout(
                  this.currentStore.code,
                  bsnDate,
                  visitor?.id,
                  this.slip.id
              );

              this.infoToast("처리되었습니다");
              if (this.usingTable) {
                this.resetAndActiveLatestTable();
              } else {
                this.onResetClick();
              }
              this.$refs["table-status-popup"].onRefreshClick();
            }
          },
        });
      };

      if (!this.slip.visitorId) {
        this.visibleSearchVisitorsPopup = true;
        this.$nextTick(() => {
          this.$refs["search-visitors-popup"].show({
            callback: async ({visitor}) => {
              this.$refs["search-visitors-popup"].hide();
              await task(visitor);
            },
          });
        });
      } else {
        await task();
      }
    },
    async processPrePayment(method) {
      if (
          !this.slip.id &&
          (this.$refs["salesGrid"].getBatchChanges().addedRecords || [])
              .length === 0
      ) {
        this.errorToast("발생한 매출이 없습니다.");
        return;
      }

      if (this.$refs.salesGrid.isGridModified()) {
        await this.onSaveClick();
      }

      this.visibleSettleAmountPopup = true;
      this.$nextTick(() => {
        this.$refs["settle-amount-popup"].show({
          salesDate: this.salesDateValueModel,
          type: "SLIP",
          data: this.slip,
          salesDivision: "SALES",
          payPreOrFutureDivision: "0",
          paymentMethod: method,
        });
      });
    },
    resetAndActiveLatestTable() {
      if (!this.usingTable || this.visibleTableStatusPopup) {
        return;
      }

      const latestTableId = this.slipStoreTableId;

      this.clear();

      this.visibleTableStatusPopup = true;
      this.$nextTick(() => {
        this.$refs["table-status-popup"].showAndActiveTable({
          activeTableId: latestTableId,
        });
      });
    },

    // event handlers
    onSalesDateChange(args) {
      this.calendarInfo = args;
      this.fetchMemos();
      if (!!this.salesDateValueModel && !!this.currentStoreCode) {
        // 일마감 여부 확인.
        GolfErpAPI.fetchCloseCheck({
          bsnDateFrom: this.salesDateValueModel,
          bsnDateTo: this.salesDateValueModel,
          storeCode: this.currentStoreCode,
        });
      }
    },
    async onSelectStoreItemClick(event, {store}) {
      await this.changeStore({store});
      await this.fetchMemos();
      this.columnsEditChange();
      if (!!this.salesDateValueModel && !!this.currentStoreCode) {
        // 일마감 여부 확인.
        await GolfErpAPI.fetchCloseCheck({
          bsnDateFrom: this.salesDateValueModel,
          bsnDateTo: this.salesDateValueModel,
          storeCode: this.currentStoreCode,
        });
      }
    },
    columnsEditChange() {
      this.columns.forEach((c)=> {
        if(c.field === "price") {
          c.allowEditing  = this.storeCheck;
        }
      });
    },
    onSelectBusinessCodeItemClick(event, {businessCode}) {
      setCurrentBizCode(businessCode);
      window.location.reload();
    },
    onPosStatusShortCutClick() {
      this.visibleShortcutsMenu = false;
      this.visiblePosStatusPopup = true;
      this.$nextTick(() => {
        this.$refs["pos-status-popup"].show();
      });
    },
    onTeamOrganizationShortCutClick() {
      this.visibleShortcutsMenu = false;
      this.visibleTeamOrganizationPopup = true;
      this.$nextTick(() => {
        this.$refs["team-organization-popup"].show();
      });
    },
    onTeamsSalesStatusShortCutClick() {
      this.visibleShortcutsMenu = false;
      let routeData = this.$router.resolve({
        name: "TeamSalesStatus",
        query: {
          bsnDate: this.salesDateValueModel,
          storeCode: this.currentStoreCode,
          searchKeyword: this.inputVisitor.team?.reserveName,
        },
      });
      openNewWindow(routeData);
    },
    onCashReceiptShortCutClick() {
      this.visibleShortcutsMenu = false;
      this.visibleCashReceiptPopup = true;
      this.$nextTick(() => {
        this.$refs["cash-receipt-popup"].show();
      });
    },
    onCheckShortCutClick() {
      this.visibleShortcutsMenu = false;
      this.visibleCheckPopup = true;
      this.$nextTick(() => {
        this.$refs["check-popup"].show();
      });
    },
    onChangePasswordShortCutClick() {
      this.visibleChangePasswordPopup = true;
      this.$nextTick(() => {
        this.$refs["change-password-popup"].show();
      });
    },
    onLogoutShortCutClick() {
      this.$EventBus.$emit("pos-confirm", {
        message: "로그아웃 하시겠습니까?",
        callback: async (confirmed) => {
          if (confirmed) {
            GolfERPService.logout({
              isSessionExpired: false,
            });
          }
        },
      });
    },
    onSettingDeviceShortCutClick() {
      this.visibleSettingDevicePopup = true;
      this.$nextTick(() => {
        this.$refs["setting-device-popup"].show();
      });
    },
    onNewWindowClick() {
      const query = {
        salesDate: moment(this.salesDate).format(
            `${DATE_FORMAT_YYYY_MM_DD} ${TIME_FORMAT_hh_mm_ss}`
        ),
      };

      if (this.currentStoreCode) {
        query.storeCode = this.currentStoreCode;
      }

      openNewWindow(this.$router.resolve({name: this.$route.name, query}));
    },
    onFullScreenClick() {
      this.toggleFullScreen();
    },
    onSearchSlipsPopupClick() {
      this.visibleSearchSlipsPopup = true;
      this.$nextTick(() => {
        this.$refs["search-slips-popup"].show();
      });
    },
    onSlipListClick() {
      this.visibleSlipListPopup = true;
      this.$nextTick(() => {
        this.$refs["slip-list-popup"].show();
      });
    },
    async onRefreshClick() {
      if (this.currentStoreCode) {
        const promises = [
          this.fetchMediumCategories({storeCode: this.currentStoreCode}),
        ];

        if (this.slip?.id) {
          promises.push(this.fetchSlip(this.slip));
        } else {
          this.clear();
        }

        await Promise.all(promises);

        await this.fetchSmallCategories({
          storeCode: this.currentStoreCode,
          categoryCode: this.mediumCategoryCode,
        });
        await this.fetchProducts({
          storeCode: this.currentStoreCode,
          categoryCode: this.smallCategoryCode,
          salesDate: moment(this.salesDate).format(DATE_FORMAT_YYYY_MM_DD),
        });
        await this.fetchMemos();
      }
    },
    onChangeTableClick() {
      this.visibleChangeTablePopup = true;
      this.$nextTick(() => {
        this.$refs["change-table-popup"].show({
          currentSlip: this.slip,
          callback: async ({storeTable}) => {
            await this.changeSlipStoreTable({storeTable});
            this.infoToast("변경되었습니다.");
            this.onTableStatusButtonClick();
          },
        });
      });
    },
    onSettingTableMemberCountClick() {
      this.visibleSettingTableMemberCountPopup = true;
      this.$nextTick(() => {
        this.$refs["setting-table-member-count-popup"].show();
      });
    },
    onSearchVisitorsClick(event, {lockerNumber, visitorName} = {}) {
      this.visibleSearchVisitorsPopup = true;
      this.$nextTick(() => {
        this.$refs["search-visitors-popup"].show({
          lockerNumber,
          visitorName,
          callback: async (payload) => {
            if (this.slip) {
              if (this.slip.isPaid) {
                this.errorToast(
                    "이미 정산이 완료된 전표입니다. 후불자 지정을 할 수 없습니다."
                );
              } else {
                await this.setVisitor({
                  visitor: payload.visitor,
                  bsnDate: this.slip.date,
                });
              }
            }
            this.$refs["search-visitors-popup"].hide();
          },
        });
      });
    },
    async onCancelAssignmentPayerClick() {
      if (this.mode === MODES.CREATE) {
        await this.clearVisitor();
      } else if (this.mode === MODES.MODIFY) {
        this.$EventBus.$emit("pos-confirm", {
          message: "후불자 지정을 취소 하시겠습니까?",
          callback: async (confirmed) => {
            if (confirmed) {
              await this.clearVisitor();
            }
          },
        });
      }
    },
    onGridCheckboxChanged(args) {
      const {
        columnName,
        rowData,
        value
      } = args;
      const seleteMenu = this.$refs.salesGrid;

      const changeIdx = seleteMenu.getBatchCurrentViewRecords()
          .findIndex((data) => data._rid === rowData._rid);

      if(columnName === "isCaddieUsed" && value) {
        seleteMenu.updateCell(changeIdx, "priceDivision", "CADDIE");
      } else if(columnName === "isCaddieUsed" && !value) {
        seleteMenu.updateCell(changeIdx, "priceDivision", "NOR");
      }
    },
    onSalesGridDataBound() {
      this.calcAllSalesInfo();
    },
    onSalesGridQueryCellInfo(args) {
      const {
        cell,
        column: {
          field,
        },
        data,
      } = args;

      if (this.slip?.isDeleted) {
        cell.style.color = "red";
        cell.style.textDecoration = "line-through";
      } else {
        if (args.column.allowEditing) {
          cell.classList.add(this.$t("className.grid.modifyArea"));
        }

        if (data.voidId) {
          cell.style.color = "red";
        }

        if (field === "discount") {
          cell.classList.add(this.$t("className.grid.clickArea")); // 클릭영역 셀 처리
        }

        if (data?.couponId) {
          if (["priceDivision","discount"].includes(field)) {
            // cell.style.setProperty("background-color", "#EB8A11", "important");
          }
        }
      }
    },
    onSalesGridCellSave(args) {
      const {
        rowData: {_rid, voidId, price, quantity, discountAmount = 0},
        columnName,
        value,
        previousValue,
      } = args;
      const salesGridRef = this.$refs["salesGrid"];
      salesGridRef;

      if (`${value}`.trim() === "" && columnName!="kitchenRemarks") {
        args.value = previousValue;
      } else if (columnName === "quantity" || columnName === "price") {
        const _value = Number(`${value}`.replace(/,/gi, ""));
        if (columnName === "quantity") {
          if (1000000000 < Math.abs(_value * price)) {
            this.errorToast("매출액이 허용된 범위를 초과하였습니다.");
            args.value = previousValue;
          } else if (
              2000000000 <
              Math.abs(
                  this.getTotalSalesAmount([_rid]) +
                  (_value * price - discountAmount)
              )
          ) {
            this.errorToast("매출 총액이 허용된 범위를 초과하였습니다.");
            args.value = previousValue;
          } else if (_value === 0) {
            this.errorToast("수량은 0이 될 수 없습니다.");
            args.value = previousValue;
          } else if (voidId) {
            const voidTarget = this.$refs["salesGrid"]
                .getBatchCurrentViewRecords()
                .find(
                    (batchCurrentViewRecord) => batchCurrentViewRecord.id === voidId
                );
            if (!voidTarget) {
              this.errorToast("취소 행을 찾을 수 없습니다");

              args.value = previousValue;
              return;
            }

            if (0 < voidTarget.quantity) {
              if (!(value < 0)) {
                this.errorToast("매출의 취소 수량은 양수가 될 수 없습니다");
                args.value = previousValue;
              }
            } else if (voidTarget.quantity < 0) {
              if (!(0 < value)) {
                this.errorToast("반품내역의 취소 수량은 음수가 될 수 없습니다");
                args.value = previousValue;
              }
            } else {
              this.errorToast("취소 행의 값이 유효하지 않습니다");

              args.value = previousValue;
            }
          }
          // else {
          //   if(discountAmount) {
          //     salesGridRef.updateCell(
          //         _rid,
          //         "discountAmount",
          //         discountAmount * value
          //     );
          //   }
          // }
        } else if (columnName === "price") {
          if (1000000000 < Math.abs(quantity * _value)) {
            this.errorToast("매출액이 허용된 범위를 초과하였습니다.");
            args.value = previousValue;
          } else if (
              2000000000 <
              Math.abs(
                  this.getTotalSalesAmount([_rid]) +
                  (quantity * _value - discountAmount)
              )
          ) {
            this.errorToast("매출 총액이 허용된 범위를 초과하였습니다.");
            args.value = previousValue;
          }
        }
      }
    },
    discountDiv(priceDivision, discountAmount, discountRate , discount) {
      if(!(priceDivision == 'RATE' || priceDivision == 'AMT')) {
          priceDivision = 'RATE';
        discountAmount; discountRate; discount;
        // const discountDiv = discount ? Number(discount.replaceAll(',','').replace('%','')) : '';
        // if(discountDiv == discountAmount) {
        //   priceDivision = 'AMT';
        // } else if(discountDiv == discountRate) {
        //   priceDivision = 'RATE';
        // }
      } else {
        return priceDivision;
      }
      return priceDivision;
    },
    onSalesGridCellSaved(args) {
      console.log('onSalesGridCellSaved',args);
      const {
        rowData: {_rid, quantity: previousQuantity, price: previousPrice },
        columnName,
        value,
        previousValue
      } = args;

      if (columnName === "quantity" || columnName === "price") {
        // 수량 단가
        const _value = Number(`${value}`.replace(/,/gi, ""));
        // 수량

        const salesGridRef = this.$refs["salesGrid"];
        const targetRowIndex = salesGridRef
            .getBatchCurrentViewRecords()
            .findIndex(
                (batchCurrentViewRecord) => batchCurrentViewRecord._rid === _rid
            );
        if (targetRowIndex < 0) {
          return;
        }

        const targetRow = salesGridRef.getBatchCurrentViewRecords()[
            targetRowIndex
            ];
        //
        let {priceDivision, discountAmount, discountRate , discount ,price ,quantity} = targetRow;
        // priceDivision = this.discountDiv(priceDivision, discountAmount, discountRate , discount);
        if (columnName === "quantity" && _value === previousQuantity) {
          return;
        }

        if (columnName === "price" && _value === previousPrice) {
          return;
        }

        const isReversed =
            columnName === "quantity" && _value * previousQuantity < 0;

        let newDiscountAmount = !isReversed
            ? (priceDivision === 'RATE')
                ? ((discountAmount / previousValue)  * quantity)
                : discountAmount
            : (priceDivision === 'RATE')
                ? -((discountAmount / previousValue)  * quantity)
                : -discountAmount;


        if(columnName === "price" && discount) {
          // 가격 수정이 가능하면
          newDiscountAmount = !isReversed
              ? (priceDivision === 'RATE')
                  ? ((price * quantity) * discountRate) / 100
                  : discountAmount
              : (priceDivision === 'RATE')
                  ? -((price * quantity) * discountRate) / 100
                  : -discountAmount;
        }


        console.log('전data 할인가',discountAmount,'전 데이터 수량',previousValue,'고정 할인율',discountRate,'현재 수량',quantity);
        console.log('newDiscountAmount===================>',newDiscountAmount);
        const newDiscountRate = !isReversed ? discountRate : -discountRate;

        const newQuantity =
            columnName === "quantity" ? _value : previousQuantity;
        const newPrice = columnName === "price" ? price : previousPrice;
        const priceOfQuantity = newQuantity * newPrice;

        const newCharge =
            priceDivision === "RATE"
                ? (priceOfQuantity / 100) * (100 - Math.abs(newDiscountRate))
                : priceDivision === "AMT"
                    ? priceOfQuantity - newDiscountAmount
                    : priceOfQuantity;
        const newDiscount =
            priceDivision === "RATE"
                ? `${newDiscountRate}%`
                : `${numberWithCommas(newDiscountAmount)}`;

        salesGridRef.updateCell(
            targetRowIndex,
            "discountAmount",
            newDiscountAmount
        );
        salesGridRef.updateCell(
            targetRowIndex,
            "discountRate",
            newDiscountRate
        );
        salesGridRef.updateCell(
            targetRowIndex,
            "discount",
            newDiscountAmount ? newDiscount : ""
        );
        if (newQuantity !== previousQuantity) {
          salesGridRef.updateCell(targetRowIndex, "quantity", newQuantity);
        }
        if (newPrice !== previousPrice) {
          salesGridRef.updateCell(targetRowIndex, "price", newPrice);
        }
        salesGridRef.updateCell(targetRowIndex, "charge", newCharge);

        this.calcAllSalesInfo();
      }
    },
    onSalesGridRecordClick(args) {
      const {column, rowData} = args;

      if (column && column.field === "discount") {
        this.visibleSettingSaleDiscountsPopup = true;
        this.$nextTick(() => {
          this.$refs["setting-sale-discounts-popup"].show({
            _rid: rowData?._rid,
            sales: this.$refs["salesGrid"].getBatchCurrentViewRecords(),
            callback: this.onSettingSaleDiscountsPopupCallback,
          });
        });
      }
    },
    onIncreaseQuantity() {
      const salesGridRef = this.$refs["salesGrid"];
      const [{_rid}] = salesGridRef.getSelectedRecords();

      this.increaseQuantity(
          salesGridRef
              .getBatchCurrentViewRecords()
              .find((record) => record._rid === _rid)
      );
    },
    onDecreaseQuantity() {
      const salesGridRef = this.$refs["salesGrid"];
      const [{_rid}] = salesGridRef.getSelectedRecords();

      this.decreaseQuantity(
          salesGridRef
              .getBatchCurrentViewRecords()
              .find((record) => record._rid === _rid)
      );
    },
    async onProductSelect(event, {product}) {
      this.selectProductCode = product.code;
      if (this.mode === MODES.EMPTY) {
        if (!this.usingTable) {
          await this.intoCreateSlipMode();
          // TODO !!critical Syncfusion 그리드 랜더링 후에 기능을 사용할 수 있음... Syncfusion 그리드 교체해야 함.
          setTimeout(() => {
            this.$nextTick(() => {
              this.createSale(product);
            });
          }, 250);
        } else {
          this.errorToast("테이블을 선택해주세요.");
        }
      } else {
        const salesGridRef = this.$refs["salesGrid"];

        const {addedRecords: addedSales} = salesGridRef.getBatchChanges();

        // 기존 품목을 제외한 새로 추가된 품목중 동일한 품목이 존재하면 확인 Confirm.
        if (addedSales.filter(sale => sale.productCode === product.code).length > 0) {
          this.$EventBus.$emit("pos-confirm", {
            message: "동일한 품목이 존재합니다!\n계속하시겠습니까?",
            callback: async (confirmed) => {
              if (confirmed) {
                const increaseTargetSale = addedSales
                    ?.filter((addedSale) => !addedSale.voidId)
                    ?.find((addedSale) => addedSale.productCode === product.code);
                if (increaseTargetSale) {
                  this.increaseQuantity(increaseTargetSale);
                } else {
                  this.createSale(product);
                }
              }
            },
          });
        } else {
          const increaseTargetSale = addedSales
              ?.filter((addedSale) => !addedSale.voidId)
              ?.find((addedSale) => addedSale.productCode === product.code);
          if (increaseTargetSale) {
            this.increaseQuantity(increaseTargetSale);
          } else {
            this.createSale(product);
          }
        }
      }
    },
    onSearchProductsClick() {
      this.visibleSearchProductsPopup = true;
      this.$nextTick(() => {
        this.$refs["search-products-popup"].show();
      });
    },
    onProductsPageClick(event, {page}) {
      this.currentProductsPage = page;
    },
    onCancelSelectSaleClick() {
      const salesGridRef = this.$refs["salesGrid"];
      const [{_rid}] = salesGridRef.getSelectedRecords();

      const batchCurrentViewRecords = salesGridRef.getBatchCurrentViewRecords();

      const targetSale = batchCurrentViewRecords.find(
          (record) => record._rid === _rid
      );

      if (
          targetSale.id &&
          batchCurrentViewRecords.find(({voidId}) => targetSale.id === voidId)
      ) {
        this.errorToast("이미 취소된 매출입니다.");
        return;
      }

      const {addedRecords: addedSales} = salesGridRef.getBatchChanges();
      if (addedSales?.find((addedSale) => addedSale._rid === _rid)) {
        this.removeSale(targetSale);
      } else {
        this.voidSale(targetSale);
      }

      this.$nextTick(() => {
        const excludedTarget = salesGridRef.getBatchCurrentViewRecords();
        salesGridRef.selectRow(excludedTarget.length);

        // const excludedVoid = excludedTarget.filter(({ voidId }) => !voidId);
        //
        // const excludedVoided = excludedVoid.filter(({ id }) =>
        //   id ? !excludedTarget.find(({ voidId }) => id === voidId) : true
        // );
        //
        // const nextSale = _maxBy(excludedVoided, "_rid");
        // if (nextSale) {
        //   const nextIndex = excludedTarget.findIndex(
        //     ({ _rid }) => _rid === nextSale._rid
        //   );
        //
        //   if (!(nextIndex < 0)) {
        //     salesGridRef.selectRow(nextIndex);
        //   }
        // } else {
        //   salesGridRef.selectRow(0);
        // }
      });
    },
    onCancelAllSalesClick() {
      const salesGridRef = this.$refs["salesGrid"];
      const {addedRecords: addedSales} = salesGridRef.getBatchChanges();
      salesGridRef.getBatchCurrentViewRecords().forEach((targetSale) => {
        if (targetSale.voidId) {
          return;
        }

        this.$nextTick(() => {
          if (
              addedSales?.find((addedSale) => addedSale._rid === targetSale._rid)
          ) {
            this.removeSale(targetSale);
          } else {
            this.voidSale(targetSale);
          }
        });
      });
    },
    onConfirmDeleteSlipPopupClick() {
      if (this.mode === MODES.CREATE) {
        this.onResetClick();
      } else if (this.mode === MODES.MODIFY) {
        this.visibleConfirmDeleteSlipPopup = true;
        this.$nextTick(() => {
          this.$refs["confirm-delete-slip-popup"].show({
            slip: this.slip,
            callback: async (payload) => {
              await this.deleteSlip(payload);
              this.infoToast(this.$t("main.popupMessage.deleted"));
              this.onResetClick();
              // await this.kitchenOrderStatementDeleteDataPrint(payload.slip.id);
            },
          });
        });
      }
    },
    onSettingSaleDiscountsPopupClick() {
      const batchCurrentViewRecords = this.$refs[
          "salesGrid"
          ].getBatchCurrentViewRecords();
      if (!batchCurrentViewRecords || batchCurrentViewRecords.length === 0) {
        this.errorToast("할인처리할 매출이 없습니다.");
        return;
      }
      const selectedRecords = this.$refs.salesGrid.getSelectedRecords();

      this.visibleSettingSaleDiscountsPopup = true;
      this.$nextTick(() => {
        this.$refs["setting-sale-discounts-popup"].show({
          _rid: selectedRecords[0]._rid,
          sales: batchCurrentViewRecords,
          callback: this.onSettingSaleDiscountsPopupCallback,
        });
      });
    },
    // 할인처리 팝업 콜백
    onSettingSaleDiscountsPopupCallback(discountChanges = []) {
      // 여기서 원본 수량이 내려와야함
      console.log('원본 수량===>',discountChanges);
      const salesGridRef = this.$refs["salesGrid"];

      discountChanges.forEach(
          ({_rid, priceDivision, discountAmount, discountRate, remarks, couponId, dcMethod}) => {
            const rowIndex = salesGridRef
                .getBatchCurrentViewRecords()
                .findIndex(
                    (batchCurrentViewRecord) => batchCurrentViewRecord._rid === _rid
                );

            const targetSale = salesGridRef.getBatchCurrentViewRecords()[
                rowIndex
                ];
            switch (priceDivision) {
              case "COUPON":
                this.applyDiscountCoupon(targetSale, discountAmount, discountRate, remarks, dcMethod, couponId);
                break;
              case "AMT":
                this.applyDiscountAmount(targetSale, discountAmount, remarks, priceDivision);
                break;
              case "RATE":
                this.applyDiscountRate(targetSale, discountRate, remarks, priceDivision);
                break;
              default:
                this.categoryDiscountAmount(targetSale, discountRate, remarks, priceDivision);
              break;
            }
          }
      );
    },
    onResetClick() {
      this.clear();

      if (this.usingTable && !this.visibleTableStatusPopup) {
        this.onTableStatusButtonClick();
      }
    },
    async onSlipMemoListPopupClick() {
      const memos = await GolfERPService.fetchSalesSlipMemos(this.slip.id);

      this.visibleSlipMemoListPopup = true;
      this.$nextTick(() => {
        this.$refs["slip-memo-list-popup"].show({
          memos,
        });
      });
    },
    onSettingSaleAmountPopupClick() {
      const salesGridRef = this.$refs["salesGrid"];
      const [sale] = salesGridRef.getSelectedRecords();
      const salesList = salesGridRef.getBatchCurrentViewRecords();
      if (!sale) {
        return;
      }

      this.visibleSettingSaleAmountPopup = true;
      this.$nextTick(() => {
        this.$refs["setting-sale-amount-popup"].show({
          productCode: sale.productCode,
          callback: ({priceDivision, price}, div = null) => {
            switch (div) {
              case "ALL":
                for(const sale of salesList) {
                    if (this.changePrice(sale._rid, price ,priceDivision)) {
                      if (priceDivision) {
                        salesGridRef.updateCell(
                            salesGridRef
                                .getBatchCurrentViewRecords()
                                .findIndex(
                                    (batchCurrentViewRecord) =>
                                        batchCurrentViewRecord._rid === sale._rid
                                ),
                            "priceDivision",
                            priceDivision
                        );
                      }
                    }
                  }
                break;
              default:
                if (this.changePrice(sale._rid, price ,priceDivision)) {
                  if (priceDivision) {
                    salesGridRef.updateCell(
                        salesGridRef
                            .getBatchCurrentViewRecords()
                            .findIndex(
                                (batchCurrentViewRecord) =>
                                    batchCurrentViewRecord._rid === sale._rid
                            ),
                        "priceDivision",
                        priceDivision
                    );
                  }
                }
                break;
            }
          },
        });
      });
    },
    onCreateGalleryPopupClick() {
      this.visibleCreateGalleryPopup = true;
      this.$nextTick(() => {
        this.$refs["create-gallery-popup"].show();
      });
    },
    onCreateSlipClick() {
      this.intoCreateSlipMode();
    },
    onTableStatusButtonClick() {
      this.visibleTableStatusPopup = true;
      this.$nextTick(() => {
        this.$refs["table-status-popup"].show();
      });
    },
    async onSaveClick(isTableStatusButtonClick = false) {
      if (this.isSaveIng) {
        this.errorToast("저장 중입니다");
        return;
      }

      this.isSaveIng = true;

      try {
        const saveDevice = {
          ...getDeviceInfo(),
          kitchenPaperNumber: this.kitchenPaperNumber
        };
        await saveDeviceInfo(saveDevice,true);
        if (this.mode === MODES.CREATE) {
          await this.createSlip({slip: this.slip});
        } else if (this.mode === MODES.MODIFY) {
          await this.updateSlipSales({slip: this.slip});
        }

        if (isTableStatusButtonClick && this.usingTable) {
          this.onTableStatusButtonClick();
        }
      } finally {
        this.isSaveIng = false;
      }
    },
    async onPrintBillPopupClick() {
      const gridRef = this.$refs["salesGrid"];
      const {addedRecords, changedRecords} = gridRef.getBatchChanges();

      const changesRecords = (addedRecords || []).concat(changedRecords || []);

      if (changesRecords.length !== 0) {
        this.errorToast("전표 저장 후 발행 가능합니다");
        return;
      }

      this.visiblePrintBillPopup = true;
      this.$nextTick(() => {
        this.$refs["print-bill-popup"].show({
          slip: this.slip,
          imageList: this.imageList,
          currentStore: this.currentStore,
        });
      });
    },
    onPaymentListPopupClick() {
      this.visiblePaymentListPopup = true;
      this.$nextTick(() => {
        this.$refs["payment-list-popup"].show();
      });
    },
    onSettleAmountClick($event, {method}) {
      switch (method) {
        case "POST":
          this.processPostPayment();
          break;
        case "CARD":
        case "CASH":
        case "DEPOSIT":
          this.processPrePayment(method);
          break;
      }
    },
    onPosStatusPopupClose() {
      this.visiblePosStatusPopup = false;
    },
    onTeamOrganizationPopupClose() {
      this.visibleTeamOrganizationPopup = false;
    },
    onCashReceiptPopupClose() {
      this.visibleCashReceiptPopup = false;
    },
    onCheckPopupClose() {
      this.visibleCheckPopup = false;
    },
    onChangePasswordPopupClose() {
      this.visibleChangePasswordPopup = false;
    },
    onSettingDeviceClose() {
      this.visibleSettingDevicePopup = false;
    },
    onSearchSlipsPopupSubmit({slip}) {
      this.fetchSlip(slip);
    },
    onSearchSlipsPopupClose() {
      this.visibleSearchSlipsPopup = false;
    },
    onSettingTableMemberCountPopupSubmit({domesticCustomersCount}) {
      this.changeSlipDomesticCustomersCount({domesticCustomersCount});
    },
    onSettingTableMemberCountPopupClose() {
      this.visibleSettingTableMemberCountPopup = false;
    },
    onSlipListPopupClose() {
      this.visibleSlipListPopup = false;
    },
    onChangeTablePopupClose() {
      this.visibleChangeTablePopup = false;
    },
    onSearchVisitorsPopupClose() {
      this.fetchMemos();
      this.visibleSearchVisitorsPopup = false;
    },
    onSearchProductsPopupSubmit({product}) {
      const selectProduct = ({
        ...product,
        noTaxCode: product.notaxCode
      });
      this.onProductSelect(null, {product: selectProduct});
    },
    onSearchProductsPopupClose() {
      this.visibleSearchProductsPopup = false;
    },
    onConfirmDeleteSlipPopupClose() {
      this.visibleConfirmDeleteSlipPopup = false;
    },
    onSlipMemoListPopupClose() {
      this.visibleSlipMemoListPopup = false;
    },
    onSettingSaleDiscountsPopupClose() {
      this.visibleSettingSaleDiscountsPopup = false;
    },
    onSettingSaleAmountPopupClose() {
      this.visibleSettingSaleAmountPopup = false;
    },
    onCreateGalleryPopupClose() {
      this.visibleCreateGalleryPopup = false;
    },
    onTableStatusPopupSelectStoreTable({storeTable}) {
      const {slip} = storeTable;
      this.fetchMemos();
      if (slip) {
        this.fetchSlip(slip);
      } else {
        this.intoCreateSlipMode({storeTable});
      }
    },
    onTableStatusPopupPostPayment() {
      if (this.mode === MODES.EMPTY || !this.availableToUpdate) {
        return;
      }

      this.processPostPayment();
    },
    onTableStatusPopupPrintBill() {
      if (this.mode !== MODES.MODIFY) {
        this.errorToast("저장된 전표에 대해서만 계산서를 발행할 수 있습니다.");
        return;
      }

      this.onPrintBillPopupClick();
    },
    onTableStatusPopupClose() {
      this.visibleTableStatusPopup = false;
    },
    onPrintBillPopupClose() {
      this.visiblePrintBillPopup = false;
    },
    onPaymentListPopupSubmit({slip}) {
      this.fetchSlip(slip);
    },
    onPaymentListPopupClose(args) {
      this.visiblePaymentListPopup = false;
      if (args !== undefined && args.isReload) {
        this.onRefreshClick();
      }
    },
    onSettleAmountPopupClose(
        {isPaid, notPaidAmount} = {isPaid: false, notPaidAmount: 0}
    ) {
      this.visibleSettleAmountPopup = false;
      if (isPaid) {
        if (notPaidAmount) {
          this.fetchSlip(this.slip);
        } else {
          this.onResetClick();
        }
      }
    },
    onPosHistoryPopupClose() {
      this.visiblePosHistoryPopup = false;
    },
    onPosMemoPopupClose() {
      this.visiblePosMemoPopup = false;
    },
    onMemoViewPopupClose() {
      this.visibleMemoViewPopup = false;
    },
    // Waterfall
    async salesDateWaterfall(salesDate) {
      if (!this.isFromPaymentHistory) {
        this.onResetClick();
      }

      const promises = [
        this.fetchCalenderInfo({salesDate}),
        this.changeSalesDate({salesDate}),
      ];

      if (this.smallCategoryCode) {
        promises.push(
            this.fetchProducts({
              storeCode: this.currentStoreCode,
              categoryCode: this.smallCategoryCode,
              salesDate: moment(salesDate).format(DATE_FORMAT_YYYY_MM_DD),
            })
        );
      }

      await Promise.all(promises);
    },
    async storeWaterfall(store) {
      if (!this.isFromPaymentHistory) {
        this.onResetClick();
      }

      const promises = [this.changeStore({store})];

      if (!store || !store.code) {
        this.mediumCategories = [];
        this.mediumCategoryCode = "";
        this.smallCategories = [];
        this.smallCategoryCode = "";
        this.products = [];
        this.currentProductsPage = 1;
      } else {
        promises.push(this.fetchMediumCategories({storeCode: store.code}));
      }

      await Promise.all(promises);
    },
    async selectMediumCategoryWaterfall(mediumCategoryCode) {
      await this.fetchSmallCategories({
        storeCode: this.currentStoreCode,
        categoryCode: mediumCategoryCode,
      });
    },
    async selectSmallCategoryWaterFall(smallCategoryCode) {
      await this.fetchProducts({
        storeCode: this.currentStoreCode,
        categoryCode: smallCategoryCode,
        salesDate: moment(this.salesDate).format(DATE_FORMAT_YYYY_MM_DD),
      });
    },

    // API
    async fetchNow() {
      await this.setSalesDate({
        salesDate: moment(
            await GolfErpAPI.fetchNow(),
            `${DATE_FORMAT_YYYY_MM_DD} ${TIME_FORMAT_hh_mm_ss}`
        ).toDate(),
      });
    },
    async fetchCalenderInfo({salesDate}) {
      if (!salesDate) {
        return;
      }

      const {data} = await GolfErpAPI.fetchCalenderInfo(
          moment(salesDate).format(DATE_FORMAT_YYYY_MM_DD),
          false
      );

      this.calendarInfo = data;
    },
    async fetchStores() {
      const stores = await GolfErpAPI.fetchStores(true);
      stores.unshift({
        name: "",
        code: "",
      });

      this.stores = stores;
    },
    async fetchMediumCategories({storeCode}) {
      if (!storeCode) {
        return;
      }

      this.mediumCategories = _sortBy(
          await GolfErpAPI.fetchStoreProductCategory(storeCode),
          "order"
      );

      if (
          !this.mediumCategoryCode ||
          this.mediumCategories.findIndex(
              ({code}) => code === this.mediumCategoryCode
          ) < 0
      ) {
        this.mediumCategoryCode = this.mediumCategories[0]?.code || "";
      }
    },
    async fetchSmallCategories({storeCode, categoryCode}) {
      if (!storeCode || !categoryCode) {
        return;
      }

      this.smallCategories = _sortBy(
          await GolfErpAPI.fetchStoreProductCategory(storeCode, categoryCode),
          "order"
      );

      if (
          !this.smallCategoryCode ||
          this.smallCategories.findIndex(
              ({code}) => code === this.smallCategoryCode
          ) < 0
      ) {
        this.smallCategoryCode = this.smallCategories[0]?.code || "";
      }
    },
    async fetchProducts({storeCode, categoryCode, salesDate}) {
      if (!storeCode || !categoryCode || !salesDate) {
        return;
      }

      this.products = _sortBy(await GolfERPService.fetchStoreProducts(
          storeCode,
          categoryCode,
          salesDate
      ),'order');
    },
    async fetchSlip({id}) {
      const slip = await GolfERPService.fetchSalesSlip(id);

      this.enterSlip({slip});
    },
    async createSlip({slip}) {
      const salesGridRef = this.$refs["salesGrid"];
      const {addedRecords} = salesGridRef.getBatchChanges();
      if (!addedRecords || addedRecords.length === 0) {
        this.errorToast("매출 없이 전표를 저장할 수 없습니다.");
        return;
      }
      const sales = addedRecords.map(
          ({
             order,
             productCode,
             noTaxCode,
             priceDivision,
             quantity: saleQuantity,
             price: salePrice,
             discountAmount,
             discountRate,
             remarks,
             kitchenRemarks,
             isCaddieUsed,
             // discount,
             // price
             couponId,
           }) => {
            // const div = this.discountDiv(priceDivision, discountAmount, discountRate , discount);
            return {
              order,
              productCode,
              noTaxCode,
              priceDivision,
              saleQuantity,
              salePrice,
              discountAmount,
              discountRate,
              remarks,
              kitchenRemarks,
              isCaddieUsed,
              couponId,
            };
          }
      );

      console.log('저장할 데이터 ===>',sales);

      const {data: newSlip} = await GolfERPService.createSalesSlip(
          slip.number,
          slip.storeTableId,
          slip.domesticCustomersCount,
          slip.visitor?.id,
          moment(this.salesDate).format(DATE_FORMAT_YYYY_MM_DD),
          this.currentStore.code,
          sales,
          getDeviceInfo().posNo
      );

      this.enterSlip({slip: newSlip});
      this.infoToast(this.$t("main.popupMessage.saved"));

      let slipIds = [];
      newSlip.sales.map((data) => {
        slipIds.push(data.id);
      });

      await this.kitchenOrderStatementSaveDataPrint(slipIds);
    },
    async updateSlipSales({slip}) {
      const salesGridRef = this.$refs["salesGrid"];
      const {addedRecords, changedRecords} = salesGridRef.getBatchChanges();

      const changesRecords = _orderBy(
          (addedRecords || []).concat(changedRecords || []),
          "order"
      );

      if (changesRecords.length === 0) {
        return;
      }
      console.log("update===>",changesRecords);

      const sales = changesRecords.map(
          ({
             id,
             voidId,
             order,
             productCode,
             noTaxCode,
             priceDivision,
             quantity: saleQuantity,
             price: salePrice,
             discountAmount,
             discountRate,
             remarks,
             kitchenRemarks,
             isCaddieUsed,
             couponId,
           }) => ({
            id: id || undefined,
            voidId: voidId || undefined,
            order,
            productCode,
            noTaxCode,
            priceDivision,
            saleQuantity,
            salePrice,
            discountAmount,
            discountRate,
            remarks,
            kitchenRemarks,
            isCaddieUsed,
            couponId,
          })
      );

      console.log('업데이트 할 데이터==>',sales);

      // const beforePayPreOrFutureDivision = slip.payPreOrFutureDivision;

      const slipIds = await GolfERPService.updateSalesSlipSales(
          slip.id,
          this.currentStore.code,
          moment(this.salesDate).format(DATE_FORMAT_YYYY_MM_DD),
          sales
      );
      console.log(slipIds);
      this.infoToast(this.$t("main.popupMessage.saved"));

      await this.kitchenOrderStatementSaveDataPrint(slipIds);

      const newSlip = await GolfERPService.fetchSalesSlip(slip.id);
      await this.enterSlip({slip: newSlip});

      // if (
      //   showTableStatusPopup &&
      //   beforePayPreOrFutureDivision === "1" &&
      //   newSlip.payPreOrFutureDivision === "2"
      // ) {
      //   this.onTableStatusButtonClick();
      // }
    },
    async deleteSlip({slip, reason}) {
      await GolfERPService.deleteSalesSlip(
          slip.id,
          reason,
          slip.date,
          slip.storeCode
      );
    },
    async onSlipHistoryClick() {
      if (!!this.slip && !!this.slip.id) {
        const rawHistories = await GolfErpAPI.fetchSlipHistory(this.slip.id);

        this.visiblePosHistoryPopup = true;
        this.$nextTick(() => {
          this.$refs["pos-history-popup"].show({rawHistories});
        });
      } else {
        this.errorToast("전표가 존재하지 않습니다.");
      }
    },
    async onPosMemoClick() {
      await this.fetchMemos();
      this.visiblePosMemoPopup = true;
      await this.$nextTick();
      this.$refs["pos-memo-popup"].show();
    },
    async onKitchenStatementReissueButtonClicked() {
      const kitchenOrderStatements = await GolfErpAPI.fetchKitchenOrderStatementsReissue(
          this.slip.id
      );

      if (!(kitchenOrderStatements.length > 0)) {
        this.errorToast("주방 주문 내역서 재발행 데이터가 존재하지 않습니다.");
        return;
      }

      const billNo =
          kitchenOrderStatements[0].slipNo +
          "-" +
          kitchenOrderStatements[0].kitchenPrtSno;
      let addSlips = [];
      let cancelSlips = [];

      kitchenOrderStatements.forEach((data) => {
        if (data.changeDiv === "추가내역") {
          addSlips.push({
            ...data,
            changeDiv: "추가내역",
          });
        } else {
          cancelSlips.push({
            ...data,
            changeDiv: "취소내역",
          });
        }
      });

      const printContents = {
        startTime: kitchenOrderStatements[0].startTime,
        visitName: kitchenOrderStatements[0].visitName,
        billNo: billNo,
        tableName: kitchenOrderStatements[0].tableName,
        nowDate: await getTodayDateTime(),
        addSlips: addSlips,
        cancelSlips: cancelSlips,
        slipMemos: kitchenOrderStatements[0].slipMemos,
      };

      // 주방주문서 출력.
      // POS에 1장, 주방에 1장 출력.
      // const deviceInfo = getDeviceInfo();

      await this.print(
          "KITCHEN_STATEMENT",
          printContents,
          null,
          {
            "useBillPrinter": this.printerOutput.posPrinterOutput,
            "useKitchenPrinter": this.printerOutput.kitchenPrinterOutput
          },
          this.kitchenPaperNumber
      );
    },
    async kitchenOrderStatementDeleteDataPrint(salesId) {
      let kitchenOrderStatements = await GolfErpAPI.fetchDeleteSlipKitchenOrderStatements(
          salesId
      );

      kitchenOrderStatements = kitchenOrderStatements.filter(data => data.saleQty !== 0);

      if (!(kitchenOrderStatements.length > 0)) {
        return;
      }

      const billNo =
          kitchenOrderStatements[0].slipNo +
          "-" +
          (kitchenOrderStatements[0].kitchenPrtSno + 1);
      let cancelSlips = [];

      kitchenOrderStatements.forEach((data) => {
        cancelSlips.push({
          ...data,
          kitchenPrtSno: kitchenOrderStatements[0].kitchenPrtSno + 1,
          changeDiv: "취소내역",
        });
      });

      const printContents = {
        startTime: kitchenOrderStatements[0].startTime,
        visitName: kitchenOrderStatements[0].visitName,
        billNo: billNo,
        tableName: kitchenOrderStatements[0].tableName,
        nowDate: await getTodayDateTime(),
        cancelSlips: cancelSlips,
      };

      await this.updateKitchenPrtSno(printContents, salesId);

      const deviceInfo = getDeviceInfo();

      if (!deviceInfo.isKitchenBillPrinterUse) {
        this.errorToast(
            this.$t("billPrinter.validationMessage.deviceBillPrintSet")
        );
        return;
      }

      await this.print("KITCHEN_STATEMENT", printContents, salesId, true);
    },
    async kitchenOrderStatementSaveDataPrint(slipIds) {
      const kitchenOrderStatements = _orderBy(
          await GolfErpAPI.fetchKitchenOrderStatements(slipIds),
          ["rowStart"],
          ["desc"]
      );

      if (!(kitchenOrderStatements.length > 0)) {
        return;
      }
      const billNo =
          kitchenOrderStatements[0].slipNo +
          "-" +
          (kitchenOrderStatements[0].kitchenPrtSno + 1);
      let addSlips = [];
      let cancelSlips = [];

      slipIds.forEach((slipId) => {
        const filterData = kitchenOrderStatements.filter(
            (data) => data.slipId === slipId
        );

        if (filterData.length > 0) {
          let amount = 0;
          let quantity = 0;

          if (filterData.length === 1) {
            amount = filterData[0].salePrice * filterData[0].saleQty;
            quantity = filterData[0].saleQty;
          } else {
            amount =
                filterData[0].salePrice * filterData[0].saleQty -
                filterData[1].salePrice * filterData[1].saleQty;
            quantity = filterData[0].saleQty - filterData[1].saleQty;
          }

          if (quantity < 0) {
            cancelSlips.push({
              ...filterData[0],
              kitchenPrtSno: filterData[0].kitchenPrtSno + 1,
              totAmt: amount,
              saleQty: quantity,
              changeDiv: "취소내역",
            });
          } else {
            if (quantity > 0) {
              addSlips.push({
                ...filterData[0],
                kitchenPrtSno: filterData[0].kitchenPrtSno + 1,
                totAmt: amount,
                saleQty: quantity,
                changeDiv: "추가내역",
              });
            }
          }
        }
      });

      if (!(addSlips.length > 0) && !(cancelSlips.length > 0)) {
        return;
      }

      const printContents = {
        startTime: kitchenOrderStatements[0].startTime,
        visitName: kitchenOrderStatements[0].visitName,
        billNo: billNo,
        tableName: kitchenOrderStatements[0].tableName,
        nowDate: await getTodayDateTime(),
        addSlips: addSlips,
        cancelSlips: cancelSlips,
        slipMemos: kitchenOrderStatements[0].slipMemos,
      };

      await this.updateKitchenPrtSno(printContents, kitchenOrderStatements[0].salesId);

      // const deviceInfo = getDeviceInfo();


      // 레이크힐스 제주는 주방주문서 자동 출력하지 않는다.
      // // 주방주문서 출력.
      // // POS에 1장, 주방에 1장 출력.
      // if (!deviceInfo.isBillPrinterUse) {
      //   this.errorToast(
      //     this.$t("billPrinter.validationMessage.deviceBillPrintSet")
      //   );
      // } else {
        await this.print(
          "KITCHEN_STATEMENT",
          printContents,
          kitchenOrderStatements[0].salesId,
            {
              "useBillPrinter": this.printerOutput.posPrinterOutput,
              "useKitchenPrinter": this.printerOutput.kitchenPrinterOutput
            },
            getDeviceInfo().kitchenPaperNumber
        );
      // }

      // if (!deviceInfo.isKitchenBillPrinterUse) {
      //   this.errorToast(
      //     this.$t("billPrinter.validationMessage.kitchenDeviceBillPrintSet")
      //   );
      // } else {
      //   await this.print(
      //     "KITCHEN_STATEMENT",
      //     printContents,
      //     kitchenOrderStatements[0].salesId,
      //       {
      //         "useBillPrinter": true,
      //         "useKitchenPrinter": true
      //       }
      //   );
      // }
    },
    async updateKitchenPrtSno(printData, salesId) {
      try {
        let kitchenOrderStatementRecords = [];
        printData.addSlips.map((data) =>
            kitchenOrderStatementRecords.push(data)
        );
        printData.cancelSlips.map((data) =>
            kitchenOrderStatementRecords.push(data)
        );

        await GolfErpAPI.updateKitchenPrtSno(
            salesId,
            kitchenOrderStatementRecords
        );
      } catch (e) {
        this.errorToast("주방 출력 순번 저장시 에러가 발생하였습니다.");
      }
    },
    async print(receiptCode, printData, salesId, config ,printCount = 1) {
      await billPrintUtil.getTsConfReceiptInfo.call(this, receiptCode, true, printData, config ,printCount);
    },
    isPosSalesListIsChanged() {
      let isModified =
          JSON.stringify(this.gridProps.dataSource) !==
          JSON.stringify(this.originalSales);
      if (isModified === false) {
        const batchChangesData = this.$refs.salesGrid.getBatchChanges();
        if (
            batchChangesData.addedRecords.length > 0 ||
            batchChangesData.changedRecords.length > 0 ||
            batchChangesData.deletedRecords.length > 0
        ) {
          isModified = true;
        }
      }
      return isModified;
    },

    async fetch() {
      const data = await GolfErpAPI.fetchClientMonitorImages(getDeviceInfo().clientMonitorImagePattern);
      this.imageList = _sortBy(data.map(item => {
        return {
          imageId: item.id,
          imageUrl: item.path,
          sortNo: item.sortNo,
        };
      }), ["sortNo"]);
    },
  },
};
</script>
<style scoped>
.typePos-name .label-header {
  display: flex; flex-flow: row nowrap; justify-content: space-between; align-items: center; width: 100%;
}
.typePos-name .label-header .soldout { width: 80px; height: 18px; margin: 0 -10px 5px 0; border: 1px solid black; border-radius: 10px; text-align: center}
.badgeLabel {
  display: flex;
  flex-flow: column wrap;
  align-items: start;
}
.soldout {

}
.NEW {width: 50px; height: 18px; margin: 0 5px 5px 0; background: url("../../../../assets/images/common/icon_new.png") no-repeat 100% 100%;}
.BEST {width: 50px; height: 18px; margin: 0 5px 5px 0; background: url("../../../../assets/images/common/icon_best.png") no-repeat 100% 100%;}
</style>
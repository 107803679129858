<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field creditReturnDate">
            <ul class="content">
              <li class="item select">
                <ejs-dropdownlist
                  v-model="searchConditions.dateDiv"
                  :dataSource="searchOptions.dateDiv"
                  :allowFiltering="false"
                  :fields="commonCodeFields"
                  cssClass="lookup-condition-dropdown"
                />
              </li>
              <li class="item dateRange">
                <input-date-range
                  v-model="inputDateRangeValue"
                  type="lookup-condition"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.visitName }}
            </div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="searchConditions.visitName"
                  @keydown.native="preventEnterEvent"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        value="true"
                        v-model="searchConditions.isApplyByDate"
                      />
                      <i></i>
                      <div class="label">일자적용</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              buttonDiv="GET"
              :isShortcutButton="true"
              :ignore="isPopupOpened"
              @click.native="onCreditReturnViewClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              buttonDiv="GET"
              :is-icon-custom="true"
              @click.native="onSearchDetailPopupOpen"
          >
            상세검색
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true" />
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">미수 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      buttonDiv="FILE"
                      @click.native="excel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
              <ul class="header-button">
                <li class="keyColor">
                  <erp-button
                      button-div="SAVE"
                      @click.native="payButtonClicked"
                  >
                    정산
                  </erp-button>
                </li>
                <li>
                  <erp-button
                      button-div="GET"
                      @click.native="creditReturnPayHistoryPopupOpenButtonClicked"
                  >
                    정산내역
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      buttonDiv="PRINT"
                      @click.native="print"
                      :isShortcutButton="true"
                      :ignore="isPopupOpened"
                  >
                    인쇄
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="creditReturnsGrid"
                :allowResizing="true"
                :dataSource="creditReturnInfoList"
                :selectionSettings="selectionSettings"
                :allowExcelExport="true"
                :columns="creditReturnGridColumn"
                :provides="grid"
                :aggregates="creditReturnGridAggregates"
                :allowGrouping="true"
                :allowPaging="true"
                :groupSettings="groupSettings"
                :pageSettings="pageSettings"
                @headerCellInfo="headerCellInfo"
                @queryCellInfo="queryCellInfo"
                @recordClick="onGridRecordClick"
                @actionComplete="creditReturnsGridActionComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <ejs-dialog
      ref="searchDetailPopup"
      :header="`상세검색`"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="false"
      :visible="false"
      width="387"
      v-show="isSearchDetailPopupOpen"
    >
      <div class="window lookupDetail-creditReturnRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">
                    {{ labels.creditReturnFlag }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchConditions.creditReturnFlag"
                        :dataSource="searchOptions.creditReturnFlagOptions"
                        :fields="commonCodeFields"
                        :allowFiltering="false"
                        cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.bsnId }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchConditions.bsnId"
                        :dataSource="searchOptions.tsBsnMstOptions"
                        :fields="commonCodeFields"
                        :allowFiltering="false"
                        cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.storeCode }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchConditions.storeCode"
                        :dataSource="searchOptions.tsStoreBsnOptions"
                        :fields="commonCodeFields"
                        :allowFiltering="false"
                        cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.remarks }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <input-text
                        v-model="searchConditions.remarks"
                        @keydown.native="preventEnterEvent"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  buttonDiv="GET"
                  :isShortcutButton="true"
                  :ignore="isPopupOpened"
                  @click.native="onCreditReturnDetailViewClicked"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  buttonDiv="GET"
                  @click.native="onInitButtonClicked"
              >
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="onSearchDetailPopupClose"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <credit-return-pay-history-popup
      v-if="isCreditReturnPayHistoryPopupOpen"
      ref="creditReturnPayHistoryPopup"
      @popupClosed="creditReturnPayHistoryPopupClosed"
    ></credit-return-pay-history-popup>
    <pay-popup
      v-if="isPayPopupOpen"
      ref="payPopup"
      @popupClosed="payPopupClosed"
    ></pay-popup>
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.creditReturnDate .content .item.select {width: 120px;}
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import ErpButton from "@/components/button/ErpButton.vue";
import {
  Edit,
  Aggregate,
  Filter,
  Resize,
  Group,
  ExcelExport,
  ForeignKey,
  Page,
} from "@syncfusion/ej2-vue-grids";

import {
  getDayColorValue,
  getFormattedDate,
} from '@/utils/date';
import { formPreventEnterEvent } from "@/utils/formUtil";
import { getCurrentBizCode } from "@/utils/pageKeyUtil";
import { numberWithCommas } from "@/utils/number";
import {
  commonCodesGetComName,
  commonCodesGetColorValue,
} from "@/utils/commonCodes";
import { getBsnInfo } from "@/api/bizCodeManagement";
import { getTableUseStore } from "@/api/storeTableRegistration";
import { getPaymentLineInfo } from "@/api/systemConfig";

import ReportView from "@/components/common/report/ReportView";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

import creditReturnPayHistoryPopup from "./popup/CreditReturnPayHistoryPopup";
import payPopup from "../common/PayPopup";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { mapGetters } from "vuex";


export default {
  name: "CreditReturnRegistration",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    InputText,
    InputDateRange,
    ejsGridWrapper,
    creditReturnPayHistoryPopup,
    payPopup,
    ReportView,
    ErpButton,
  },
  created() {
    this.bsnDate = getFormattedDate(new Date());
    this.inputDateRangeValue.from = getFormattedDate(new Date());
    this.inputDateRangeValue.to = getFormattedDate(new Date());
    this.currentBizCode = getCurrentBizCode();
  },
  async mounted() {
    await Promise.all([this.getBsnInfo(), this.getTableUseStore()]);
    if (
        this.searchOptions.tsStoreBsnOptions.findIndex(
            (obj) => obj.comCode === "ALL"
        ) === -1
    ) {
      this.searchOptions.tsStoreBsnOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
    this.searchConditions.storeCode = "ALL";
  },
  watch: {
    $route() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
  },
  data() {
    return {
      count: 0,
      creditReturnInfoListLength: 0,
      grid: [Edit, Aggregate, Filter, Resize, Group, ExcelExport, ForeignKey, Page],
      selectionSettings: { type: "Single", mode: "Both", enableToggle: false },
      inputDateRangeValue: { to: null, from: null },
      searchConditions: {
        dateDiv: "CREDIT_DATE",
        bsnStartDate: null,
        bsnEndDate: null,
        visitName: null,
        creditReturnFlag: "ALL",
        bsnId: "ALL",
        storeCode: null,
        remarks: null,
        isApplyByDate: false,
      },
      labels: {
        visitName: "성명",
        creditReturnFlag: "회입여부",
        bsnId: "영업장",
        storeCode: "매장",
        remarks: "비고",
      },
      searchOptions: {
        dateDiv: [
          { comCode: "CREDIT_DATE", comName: "미수일자" },
          { comCode: "RETURN_DATE", comName: "회입일자" },
        ],
        creditReturnFlagOptions: [
          { comCode: "ALL", comName: "전체" },
          { comCode: "FALSE", comName: "미회입" },
          { comCode: "TRUE", comName: "회입" },
        ],
        tsBsnMstOptions: [{ comCode: "ALL", comName: "전체" }],
        tsStoreBsnOptions: [],
      },
      commonCodeFields: { text: "comName", value: "comCode" },
      groupSettings: { showDropArea: false, columns: ["groupInfo"] },
      pageSettings: { pageSize: 50 },
      creditReturnGridColumn: [
        {
          field: "payId",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "groupInfo",
          headerText: "구분",
          visible: false,
        },
        {
          field: "isSelect",
          headerText: "",
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          isSelectAllColumn: true,
          minWidth: 16,
          width: 40,
          textAlign: "Center",
        },
        {
          field: "bsnName",
          headerText: "영업장",
          width: 120,
          type: "string",
          allowEditing: false,
        },
        {
          field: "storeCode",
          headerText: "매장",
          width: 120,
          textAlign: "center",
          type: "string",
          isCommonCodeField: true,
          groupCode: "STORE_CODE",
          allowEditing: false,
        },
        {
          headerText: "일자",
          field: "payDate",
          width: "90",
          textAlign: "center",
          type: "string",
          allowEditing: false,
        },
        {
          headerText: "요일",
          field: "dwCode",
          width: "70",
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "DW_CODE",
          type: "string",
          allowEditing: false,
        },
        {
          headerText: "구분",
          field: "bsnCode",
          width: "70",
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "BSN_CODE",
          type: "string",
          allowEditing: false,
        },
        // {
        //   field: 'payDate',
        //   headerText: '미수일자',
        //   textAlign: 'center',
        //   width: 120,
        //   allowEditing: false,
        // },
        // {
        //   field: 'bsnCode',
        //   headerText: '영업구분',
        //   textAlign: 'center',
        //   valueAccessor: this.bsnCodeValueAccess,
        //   width: 90,
        //   allowEditing: false,
        // },
        // {
        //   field: 'dwCode',
        //   headerText: '요일',
        //   textAlign: 'center',
        //   valueAccessor: this.dwCodeValueAccess,
        //   width: 60,
        //   allowEditing: false,
        // },
        {
          field: "visitName",
          headerText: "성명",
          width: 150,
          type: "string",
          allowEditing: false,
        },
        {
          field: "payId",
          headerText: "미수ID",
          textAlign: "right",
          width: 90,
          type: "number",
          format: "N",
          allowEditing: false,
        },
        {
          field: "creditReturnFlag",
          headerText: "회입완료 여부",
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: "true",
          width: 120,
          textAlign: "center",
          allowEditing: false,
        },
        {
          field: "payAmt",
          headerText: "미수금액",
          textAlign: "right",
          type: "number",
          format: "N",
          width: 150,
          allowEditing: false,
        },
        {
          field: "returnAmt",
          headerText: "회입금액",
          textAlign: "right",
          type: "number",
          format: "N",
          width: 150,
          allowEditing: false,
        },
        {
          field: "creditBalance",
          headerText: "미수잔액",
          textAlign: "right",
          type: "number",
          format: "N",
          width: 150,
          allowEditing: false,
        },
        {
          field: "remarks",
          headerText: "비고",
          type: "string",
          width: 200,
          allowEditing: false,
        },
        {
          field: "insertName",
          headerText: "등록자",
          width: 90,
          type: "string",
          allowEditing: false,
        },
        {
          field: "insertDt",
          headerText: "등록일시",
          textAlign: "center",
          width: 150,
          type: "string",
          allowEditing: false,
        },
      ],
      creditReturnGridAggregates: [
        {
          columns: [
            // {
            //   field: "bsnName",
            //   aggregationType: "GroupCaption",
            //   customAggregate: (aggregates) => {
            //     const sampleItem = aggregates.items[0];
            //     return sampleItem.groupInfo;
            //   }
            // },
            {
              field: "storeCode",
              aggregationType: "GroupCaption",
              customAggregate: "소계",
            },
            {
              field: "visitName",
              aggregationType: "GroupCount",
            },
            {
              field: "payAmt",
              aggregationType: "GroupSum",
            },
            {
              field: "returnAmt",
              aggregationType: "GroupSum",
            },
            {
              field: "creditBalance",
              aggregationType: "GroupSum",
            },
          ],
        },
        {
          columns: [
            {
              field: "storeCode",
              aggregationType: "TotalCaption",
              customAggregate: "합계",
            },
            {
              field: "visitName",
              aggregationType: "TotalCount",
            },
            {
              field: "payAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "returnAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "creditBalance",
              aggregationType: "TotalSum",
            },
          ],
        },
      ],
      creditReturnInfoList: [],
      isSearchDetailPopupOpen: false,
      isCreditReturnPayHistoryPopupOpen: false,
      isPayPopupOpen: false,
    };
  },
  computed: {
    ...mapGetters(["username"]),
    isPopupOpened() {
      return this.isCreditReturnPayHistoryPopupOpen || this.isPayPopupOpen ;
    },
  },
  methods: {
    numberWithCommas,
    preventEnterEvent: formPreventEnterEvent,
    storeCodeValueAccess(field, data) {
      let storeCode = data[field];
      if (storeCode != null) {
        storeCode = commonCodesGetComName("STORE_CODE", storeCode);
      }
      return storeCode;
    },
    bsnCodeValueAccess(field, data) {
      let bsnCode = data[field];
      if (bsnCode != null) {
        bsnCode = commonCodesGetComName("BSN_CODE", bsnCode);
      }
      return bsnCode;
    },
    dwCodeValueAccess(field, data) {
      let dwCode = data[field];
      if (dwCode != null) {
        dwCode = commonCodesGetComName("DW_CODE", dwCode);
      }
      return dwCode;
    },
    headerCellInfo(args) {
      if (
        args.cell.column.field === "returnAmt" ||
        args.cell.column.field === "creditBalance"
      ) {
        args.node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    queryCellInfo(args) {
      const {
        cell,
        column: { field },
        data,
      } = args;
      const value = data[field];
      if (field === "dwCode") {
        cell.style.color = getDayColorValue(data.dwCode, data.bsnCode);
      } else if (field === "bsnCode") {
        cell.style.color = commonCodesGetColorValue("BSN_CODE", value);
      } else if (field === "returnAmt") {
        if (value !== 0) {
          args.cell.classList.add(this.$t("className.grid.clickArea"));
        }
      } else if (field === "creditBalance") {
        if (value !== 0) {
          args.cell.classList.add(this.$t("className.grid.clickArea"));
        }
      }
      if (field === "isSelect") {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if ([
        "payAmt",
        "returnAmt",
        "creditBalance",
      ].includes(field) && data[field] === 0) {
        cell.innerText = "-";
      }
    },
    onGridRecordClick(args) {
      const field = args.column.field;
      // const data = args.rowData[field];

      if (field === "returnAmt") {
        const data = args.rowData[field];
        if (data !== 0) {
          this.onCreditReturnPayHistoryPopupOpenClicked(args.rowIndex);
        }
      } else if (field === "creditBalance") {
        this.$refs.creditReturnsGrid.endEdit();
        const data = args.rowData[field];
        if (data !== 0) {
          this.pay(args.rowIndex);
        }
      }
    },
    onSearchDetailPopupOpen() {
      this.isSearchDetailPopupOpen = true;
      this.$refs.searchDetailPopup.show();
    },
    onSearchDetailPopupClose() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
    onInitButtonClicked() {
      this.searchConditions.visitName = null;
      this.searchConditions.creditReturnFlag = "ALL";
      this.searchConditions.bsnId = "ALL";
      this.searchConditions.storeCode = "ALL";
      this.searchConditions.remarks = null;
    },
    setGroupInfo(creditReturnInfoList) {
      this.creditReturnInfoList = creditReturnInfoList.map((item) => {
        const dwCode = commonCodesGetComName("DW_CODE", item.dwCode.toString());
        const bsnCode = commonCodesGetComName("BSN_CODE", item.bsnCode);
        item.groupInfo = `${item.payDate} (${dwCode}) ${bsnCode}`;
        return item;
      });
    },
    async onCreditReturnDetailViewClicked() {
      await this.onCreditReturnViewClicked();
    },
    async onCreditReturnViewClicked() {
      this.searchConditions.bsnStartDate = this.inputDateRangeValue.from;
      this.searchConditions.bsnEndDate = this.inputDateRangeValue.to;

      if (!this.searchConditions.bsnStartDate) {
        const columnName = this.searchOptions.dateDiv.find(
          (item) => item.comCode === this.searchConditions.dateDiv
        );

        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", [
            columnName.comName,
          ])
        );
      }
      if (!this.searchConditions.bsnEndDate) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["종료일자"])
        );
      }

      let data = Object.assign({}, this.searchConditions);

      data.bsnId = data.bsnId === "ALL" ? null : data.bsnId;
      data.storeCode = data.storeCode === "ALL" ? null : data.storeCode;
      data.creditReturnFlag =
        data.creditReturnFlag !== null
          ? data.creditReturnFlag === "ALL"
            ? null
            : data.creditReturnFlag === "TRUE"
          : null;

      this.creditReturnInfoList =
        (await GolfErpAPI.fetchCreditReturns(data))
          .map(item => ({
            ...item,
            isSelect: false,
          }));

      this.creditReturnInfoListLength = this.creditReturnInfoList.length;

      this.setGroupInfo(this.creditReturnInfoList);
    },
    async creditReturnPayHistoryPopupClosed(args) {
      this.isCreditReturnPayHistoryPopupOpen = false;

      if (args !== undefined && args.isReload) {
        await this.onCreditReturnViewClicked();
      }
    },
    onCreditReturnPayHistoryPopupOpenClicked(rowIndex) {
      if (rowIndex < 0) {
        return this.errorToast("미수 내용을 선택해 주십시오");
      }

      const currentviewRecords = this.$refs.creditReturnsGrid.getBatchCurrentViewRecords();

      this.isCreditReturnPayHistoryPopupOpen = true;

      this.$nextTick(() => {
        this.$refs.creditReturnPayHistoryPopup.showCreditReturnPayHistoryPopup({
          payId: currentviewRecords[rowIndex].payId,
          payDate: currentviewRecords[rowIndex].payDate,
          storeCode: currentviewRecords[rowIndex].storeCode,
          visitId: currentviewRecords[rowIndex].visitId,
          visitName: currentviewRecords[rowIndex].visitName,
          payAmt: currentviewRecords[rowIndex].payAmt,
        });
      });
    },
    creditReturnPayHistoryPopupOpenButtonClicked() {
      const rowIndex = this.$refs.creditReturnsGrid.getSelectedRowIndexes();

      this.onCreditReturnPayHistoryPopupOpenClicked(rowIndex[0]);
    },
    payButtonClicked() {
      const rowIndex = this.$refs.creditReturnsGrid.getSelectedRowIndexes();

      this.pay(rowIndex[0]);
    },
    pay(rowIndex = -1) {
      const currentviewRecords = this.$refs.creditReturnsGrid.getBatchCurrentViewRecords();
      const selectedRecords = JSON.parse(JSON.stringify(currentviewRecords.filter(item => item.isSelect && !item.creditReturnFlag)));

      if (rowIndex < 0 && selectedRecords.length === 0) {
        return this.errorToast("미수회입 정보를 선택 후 진행 바랍니다");
      }

      if (currentviewRecords[rowIndex].creditBalance === 0) {
        return this.errorToast("미수잔액이 0원입니다");
      }

      const popupData = {
        payId: currentviewRecords[rowIndex].payId, // 정산ID
        payIds: selectedRecords.map(item => item.payId),
        bsnDate: this.bsnDate, // 정산일자
        salesDiv: "CREDIT", // 매출 구분(미수회입 화면에서 'CREDIT' 고정)
        storeCode: currentviewRecords[rowIndex].storeCode, // 매장코드
        visitId: currentviewRecords[rowIndex].visitId, // 내장객ID
        stayId: currentviewRecords[rowIndex].stayId,
        visitName: currentviewRecords[rowIndex].visitName, // 내장객명
        prpayFrpyDiv: "1", // 선후불 구분(미수회입 화면에서 '1(후불)' 고정)
        lockerNo: currentviewRecords[rowIndex].lockerNo, // 락카번호
      };

      this.isPayPopupOpen = true;

      this.$nextTick(() => {
        this.$refs.payPopup.showPopup(popupData);
      });
    },
    async payPopupClosed() {
      this.isPayPopupOpen = false;
      await this.onCreditReturnViewClicked();
    },
    async getBsnInfo() {
      const response = await getBsnInfo(this.currentBizCode);
      response.value.tsBsnMstList.forEach((bsnInfo) => {
        const data = {
          comCode: bsnInfo.bsnId.toString(),
          comName: bsnInfo.bsnName,
        };
        this.searchOptions.tsBsnMstOptions.push(data);
      });
    },
    async getTableUseStore() {
      const response = await getTableUseStore(null, this.bsnDate);
      this.searchOptions.tsStoreBsnOptions = response.value.comCodeDtlList;
    },
    creditReturnsGridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.creditReturnsGrid?.getGridBatchCount() || 0
      );
    },
    excel() {
      this.$refs.creditReturnsGrid.excelExport();
    },
    async print() {
      const options = [
        {
          field: "storeCode",
          comCode: "STORE_CODE",
          format: "comCode",
        },
      ];
      const [{ tsConfSanctnDetail }] = (await getPaymentLineInfo(true)).value.sanctnList.filter((data)=> data.sanctnLineId === 6  );
      let result = this.$refs.creditReturnsGrid.getGridBatchData(options);
      result = result.map((item) => {
        item.tempCount = 1;
        return item;
      });

      if (result.length < 1) {
        return this.errorToast(this.$t("report.popupMessage.noData"));
      }

      const searchOptionsList = [
        {
          key: this.searchOptions.dateDiv.find(
            (item) => item.comCode === this.searchConditions.dateDiv
          ).comName,
          value:
            this.inputDateRangeValue.from + " ~ " + this.inputDateRangeValue.to,
        },
        {
          key: this.labels.visitName,
          value: this.searchConditions.visitName,
        },
        {
          key: this.labels.creditReturnFlag,
          value:
            this.searchConditions.creditReturnFlag !== "ALL"
              ? this.searchOptions.creditReturnFlagOptions.find(
                  (item) =>
                    item.comCode === this.searchConditions.creditReturnFlag
                ).comName
              : null,
        },
        {
          key: this.labels.bsnId,
          value:
            this.searchConditions.bsnId !== "ALL"
              ? this.searchOptions.tsBsnMstOptions.find(
                  (item) => item.comCode === this.searchConditions.bsnId
                ).comName
              : null,
        },
        {
          key: this.labels.storeCode,
          value: commonCodesGetComName(
            "STORE_CODE",
            this.searchConditions.storeCode
          ),
        },
        {
          key: this.labels.remarks,
          value: this.searchConditions.remarks,
        },
      ];

      const searchOptions = searchOptionsList
        .filter((item) => !!item.value)
        .map((item) => item.key + ": " + item.value)
        .join(", ");

      const reportData = {
        reportJson: {
          jsonData: result,
          username: this.username,
          uniqueCode: this.$options.name,
          selectSanctnLineList: tsConfSanctnDetail,
          searchOptions,
        },
      };

      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
  },
};
</script>

import qs from "qs";

export const PATH_RESERVATION_TIME = `/reservation-times`;

export function fetchReservationTimes(args) {
  return this.http.get(`${PATH_RESERVATION_TIME}/${args.resveDate}`, {
    params: args,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

export function fetchReservationTimesByTimeIds(timeIds,resveIds) {
  console.log(timeIds,resveIds);
  return this.http.get(`${PATH_RESERVATION_TIME}`, {
    params: {
      timeIds: timeIds,
      resveIds: resveIds,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

export function fetchReservationVisitorFind(args) {
  return this.http.get(`${PATH_RESERVATION_TIME}/reservation-visitor-find`, {
    params: {
      resveDate: args.resveDate,
      partDiv: args.partDiv,
      courseCode: args.courseCode,
      resveVisitName: args.resveVisitName
    }
  });
}

export function fetchResveCmpns(resveId) {
  return this.http.get(`${PATH_RESERVATION_TIME}/cmpn-infos`, {
    params: {
      resveId
    }
  });
}

export function fetchReservationTimesDetail(args) {
  return this.http.get(`${PATH_RESERVATION_TIME}/detail`, {
    params: {
      timeId: args.timeId,
      resveNo: args.resveNo,
    },
  });
}

export function reservationTimesHoldCancel(timeIds) {
  return this.http.put(`${PATH_RESERVATION_TIME}/hold-cancel`, timeIds);
}

export function reservationTimeHoldResnModify(timeId, holdResn) {
  return this.http.patch(`${PATH_RESERVATION_TIME}/hold/${timeId}/hold-resn-modify`, {
    holdResn
  });
}

export function reservationTimeCancel(reservationCancelTimes) {
  return this.http.put(
    `${PATH_RESERVATION_TIME}/cancel`,
    reservationCancelTimes
  );
}

export function reservationTimeMove(reservationTimeMoveInfo) {
  return this.http.put(
    `${PATH_RESERVATION_TIME}/move`,
    reservationTimeMoveInfo
  );
}

export function reservationWebTimesSetting(reservationWebTimes) {
  return this.http.put(
    `${PATH_RESERVATION_TIME}/web-times-setting`,
    reservationWebTimes
  );
}

export function reservationTimesStatusSetting(reservationTimes) {
  return this.http.put(
    `${PATH_RESERVATION_TIME}/status-setting`,
    reservationTimes
  );
}

export function reservationSave(reservationRequest) {
  return this.http.put(
    `${PATH_RESERVATION_TIME}/reservation-save`,
    reservationRequest
  );
}

export function fetchOneOrMoreReservations(args) {
  return this.http.get(`${PATH_RESERVATION_TIME}/one-or-more-reservations`, {
    params: {
      year: args.year,
      month: args.month,
      resveChannels: args.resveChannels
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    }
  });
}

export function fetchOneOrMoreReservationInfos(args) {
  return this.http.get(`${PATH_RESERVATION_TIME}/one-or-more-reservation-infos`, {
    params: {
      dateFrom: args.dateFrom,
      dateTo: args.dateTo,
      bsnCode: args.bsnCode,
      membershipId: args.membershipId,
      resveChannels: args.resveChannels
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    }
  });
}

export function reservationUngroup(resveId) {
  return this.http.put(`${PATH_RESERVATION_TIME}/${resveId}/ungroup`);
}

export function fetchReservationStatusList(bsnDate, courseDiv) {
  return this.http.get(`${PATH_RESERVATION_TIME}/status-list`, {
    params: {
      bsnDate: bsnDate,
      courseDiv: courseDiv
    }
  });
}

export function fetchReservationCartCount(args) {
  return this.http.get(`${PATH_RESERVATION_TIME}/cart-count`, {
    params: args,
  });
}

export function batchPromotionRegistration(promtnId, timeIds) {
  return this.http.put(`${PATH_RESERVATION_TIME}/batch-promotion-registration/${promtnId}`, timeIds);
}

export function batchPromotionDelete(timeIds) {
  return this.http.put(`${PATH_RESERVATION_TIME}/batch-promotion-delete`, timeIds);
}

export function reservationRainCancel(resveId, isRainCancel) {
  return this.http.patch(`${PATH_RESERVATION_TIME}/rain-cancel/${resveId}/${isRainCancel}`);
}

export function reservationCopy(args) {
  return this.http.put(`${PATH_RESERVATION_TIME}/${args.copyTimeId}/${args.newTimeId}/${args.isSmsSend}/reservation-copy`);
}

export function reservationSelfTimeChange(args) {
  return this.http.put(`${PATH_RESERVATION_TIME}/status-setting/self-time/${args.timeId}/${args.optFlag}/${args.isSelfTime}`);
}

export function reservationHoleDivChange(args) {
  return this.http.put(`${PATH_RESERVATION_TIME}/status-setting/hole-div/${args.timeId}/${args.optFlag}/${args.holeDiv}`);
}

export function reservationTimeAgencySetting(request) {
  return this.http.patch(`${PATH_RESERVATION_TIME}/agency-setting`, request);
}

export function reservationTimeAgencyDelete(timeIds) {
  return this.http.delete(`${PATH_RESERVATION_TIME}/agency-setting/delete`, {
    data: timeIds,
  });
}
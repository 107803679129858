import request from "@/utils/request";

/**
 * @return Promise
 */
export function getLockerInfoList(
  bsnDate,
  lockerDiv,
  lockerNoFrom,
  lockerNoTo,
  lockerStatus,
  sector
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/locker-code/locker-info-list",
    method: "get",
    params: {
      bsnDate, // YYYY-MM-DD
      lockerDiv,
      lockerNoFrom,
      lockerNoTo,
      lockerStatus,
      sector,
    },
  });
}

/**
 * @return Promise
 */
export function getLockerDailyInfo(bsnDate, size, page) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/locker-code/locker-daily-info",
    method: "get",
    params: {
      bsnDate, // YYYY-MM-DD
      size,
      page,
    },
  });
}

/**
 * @return Promise
 */
export function getLockerCheckInStatus(bsnDate, lockerNo) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/locker-code/locker-check-in-status",
    method: "get",
    params: {
      bsnDate, // YYYY-MM-DD
      lockerNo,
    },
  });
}

/**
 * @return Promise
 */
export function getTsLockerDalyCount(bsnDate, isBackground = false) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/locker-code/locker-daily-count",
    isBackground: isBackground,
    method: "get",
    params: {
      bsnDate,
    },
  });
}

/**
 * @return Promise
 */
export function createLockerInfoInBatch(
  bsnDateFrom,
  bsnDateTo,
  isBackground = false
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/code-management/locker-code/locker-daily-batch`,
    isBackground: isBackground,
    method: "post",
    data: {
      bsnDateFrom,
      bsnDateTo,
    },
  });
}

/**
 * @return Promise
 */
export function updateLockerStatus(
  lockerStatus,
  lockerInfoList,
  remarks,
  resveName
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/code-management/locker-code/locker-daily-status/${lockerStatus}`,
    method: "put",
    data: {
      lockerInfoList,
      remarks,
      resveName,
    },
  });
}

/**
 * @return Promise
 */
export function updateLockerInfo(addedRecords, changedRecords, deletedRecords) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/code-management/locker-code/locker-info`,
    method: "put",
    data: {
      addedRecords,
      changedRecords,
      deletedRecords,
    },
  });
}

/**
 * @return Promise
 */
export function deleteLockerInfoInBatch(bsnDateFrom, bsnDateTo) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/code-management/locker-code/locker-daily-batch`,
    method: "delete",
    params: {
      bsnDateFrom,
      bsnDateTo,
    },
  });
}

<template>
  <div>
    <ejs-dialog ref="dialog" v-bind="dialogProps" @close="onDialogClose">
      <div
        class="typePos"
        :style="{
          minWidth: `${dialogProps.width}px`,
          minHeight: `${dialogProps.height - 66}px`,
        }"
      >
        <div class="typePos-wrapper-main">
          <div class="typePos-main-header">
            <ejs-grid-wrapper
              ref="slipsGrid"
              v-bind="slipGridProps"
              :noColumnWidth="50"
              @queryCellInfo="onSlipsGridQueryCellInfo"
              @rowSelected="onSlipsGridRowSelected"
              @onGridDialogDoubleClickedOrEnterKeyed="
                onSlipsGridDialogDoubleClickedOrEnterKeyed
              "
              @dataBound="onSlipsGridDataBound"
            />
          </div>
          <div class="typePos-main-footer">
            <div class="typePos-header">
              <div class="typePos-filed">
                <div class="ui-input">
                  <!-- 비활성(Attribute) : disabled -->
                  <input
                    ref="input-slip-number"
                    type="text"
                    placeholder="전표번호"
                    v-model="inputSlipNumber"
                    @focus="onInputSlipNumberFocus"
                    @keypress.enter="fetchSlips()"
                  />
                </div>
              </div>
              <div class="typePos-filed">
                <div class="ui-input">
                  <!-- 비활성(Attribute) : disabled -->
                  <input
                    ref="input-visitor-name"
                    type="text"
                    placeholder="내장객명"
                    v-model="inputVisitorName"
                    @focus="onInputVisitorNameFocus"
                    @keypress.enter="fetchSlips()"
                  />
                </div>
              </div>
              <div class="typePos-filed">
                <div class="ui-input">
                  <!-- 비활성(Attribute) : disabled -->
                  <input
                    ref="input-locker-number"
                    type="text"
                    placeholder="락카번호"
                    v-model="inputLockerNumber"
                    @focus="onInputLockerNumberFocus"
                    @keypress.enter="fetchSlips()"
                  />
                </div>
              </div>
              <div class="typePos-filed">
                <ul class="typePos-check">
                  <li>
                    <label>
                      <input type="radio" :value="false" v-model="isPaid" />
                      <div class="i-item">미정산</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="radio" :value="true" v-model="isPaid" />
                      <div class="i-item">정산</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="radio" :value="null" v-model="isPaid" />
                      <div class="i-item">전체</div>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div class="typePos-body">
              <ul class="typePos-list">
                <li class="typePos-1">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: '1' })"
                  >
                    1
                  </erp-button>
                </li>
                <li class="typePos-2">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: '2' })"
                  >
                    2
                  </erp-button>
                </li>
                <li class="typePos-3">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: '3' })"
                  >
                    3
                  </erp-button>
                </li>
                <li class="typePos-4">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: '4' })"
                  >
                    4
                  </erp-button>
                </li>
                <li class="typePos-5">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: '5' })"
                  >
                    5
                  </erp-button>
                </li>
                <li class="typePos-6">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: '6' })"
                  >
                    6
                  </erp-button>
                </li>
                <li class="typePos-7">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: '7' })"
                  >
                    7
                  </erp-button>
                </li>
                <li class="typePos-8">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: '8' })"
                  >
                    8
                  </erp-button>
                </li>
                <li class="typePos-9">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: '9' })"
                  >
                    9
                  </erp-button>
                </li>
                <li class="typePos-0">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: '0' })"
                  >
                    0
                  </erp-button>
                </li>
                <li class="typePos-00">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: '00' })"
                  >
                    00
                  </erp-button>
                </li>
                <li class="typePos-clear">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: 'Clear' })"
                  >
                    CL
                  </erp-button>
                </li>
                <li class="typePos-back">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: 'Backspace' })"
                  >
                    뒤로
                  </erp-button>
                </li>
                <li class="typePos-enter">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: 'Enter' })"
                  >
                    Enter
                  </erp-button>
                </li>
                <li class="typePos-input">
                  <erp-button
                    button-div="GET"
                    :is-custom-shortcut-button="true"
                    :shortcut-key="insertShortcutButtonProps.shortcutKey"
                    :shortcut="insertShortcutButtonProps.shortcut"
                    :visible-shortcut-string="false"
                    :use-syncfusion-component-style="false"
                    @click="onInputClick"
                  >
                    입력<br />({{ insertShortcutButtonShortcutString }})
                  </erp-button>
                </li>
                <li class="typePos-lookup">
                  <erp-button
                    button-div="GET"
                    :is-shortcut-button="true"
                    :visible-shortcut-string="false"
                    :use-syncfusion-component-style="false"
                    @click="onSearchSlipsClick"
                  >
                    조회<br />({{ searchShortcutButtonShortcutString }})
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="GET"
                class="ui-button i-typePrimary i-iconConfirm"
                :use-syncfusion-component-style="false"
                @click="onSubmitClick"
              >
                <div class="i-item">확인</div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click="onCloseClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-main {display: flex; flex-direction: row; flex: 1; overflow: hidden;}
.typePos .typePos-wrapper-main::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-header {flex: 1; float: left; overflow: hidden;}
.typePos .typePos-wrapper-main .typePos-main-footer {display: flex; flex-direction: column; float: left; width: 325px; border-left: 1px solid #bebebe;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header {padding: 15px 20px; border-bottom: 1px solid #e0e0e0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed {padding: 5px 0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check {list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li {float: left; width: calc((100% / 3) + 1px); margin-left: -1px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li:first-child {width: calc(100% / 3); margin-left: 0; border-radius: 4px 0 0 4px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li label {display: block; position: relative;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li label input {box-shadow: none; position: absolute; top: 0; left: 0; width: 100%; height: 100%; padding: 0; margin: 0; border-radius: 0; opacity: 0; z-index: 10;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li label input + .i-item {min-height: 19px; padding: 9px 0 10px 0; border: 1px solid #e0e0e0; background-color: #fff; color: #999; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px; text-align: center;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li label input:hover + .i-item {background-color: #f9f9f9;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li label input:checked + .i-item,
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li label input:checked:hover + .i-item {background-color: #18b3c9;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li label input:checked + .i-item {color: #fff; font-family: 'NanumSquare-Bold';}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li:first-child  label input + .i-item {border-radius: 4px 0 0 4px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li:last-child  label input + .i-item {border-radius: 0 4px 4px 0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body {flex: 1; position: relative; width: 325px; background-color: #f5f5f5;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list {list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li {position: absolute;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li >>> button {display: block; box-sizing: border-box; box-shadow: 0 2px 2px 0 rgba(0, 0 , 0, 0.05); width: 72px; height: 45px; padding: 5px; margin: 0; border: none; border-radius: 4px; background-color: #fff; color: #666; font-size: 16px; font-family: 'NanumSquare-Bold'; line-height: 24px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li >>> button:hover {background-color: #f9f9f9;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-1 {top: 116px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-2 {top: 116px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-3 {top: 116px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-4 {top: 68px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-5 {top: 68px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-6 {top: 68px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-7 {top: 20px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-8 {top: 20px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-9 {top: 20px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-0 {top: 164px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-00 {top: 164px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-clear {top: 164px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-back {top: 212px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-enter {top: 212px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-input {top: 20px; left: 245px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-lookup {top: 116px; left: 245px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-clear button {font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-back >>> button {overflow: hidden; text-indent: -1000px; background-image: url('../../../../../assets/images/ui/icon-type09.png'); background-repeat: no-repeat; background-position: center center;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-enter >>> button {width: 147px; font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-input >>> button {width: 60px; height: 93px; background-color: #4f5963; color: #fff; font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-input >>> button:hover {background-color: #333c44;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-lookup >>> button {width: 60px; height: 141px; background-color: #18b3c9; color: #fff; font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-lookup >>> button:hover {background-color: #0f9eb8;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
</style>

<script>
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { Aggregate, ForeignKey, Resize, Sort } from "@syncfusion/ej2-vue-grids";
import GolfERPService from "@/service/GolfERPService";
import moment from "moment";
import { DATE_FORMAT_YYYY_MM_DD } from "@/utils/date";
import { commonCodesGetColorValue } from "@/utils/commonCodes";
import Vue from "vue";
import { numberWithCommas } from "@/utils/number";
import { sumBy as _sumBy, maxBy as _maxBy } from "lodash";
import ErpButton from "@/components/button/ErpButton.vue";
import {
  getSearchButtonShortcut,
  SHORTCUT_KEYS,
  toShortcutString,
} from "@/utils/KeyboardUtil";

export default {
  name: "SearchSlipsPopup",
  components: { EjsGridWrapper, ErpButton },
  props: {
    salesDate: {
      type: Date,
      required: true,
    },
    store: {
      type: Object,
      required: true,
    },
  },
  watch: {
    isPaid() {
      this.fetchSlips();
    },
  },
  data() {
    return {
      inputSlipNumber: "",
      inputVisitorName: "",
      inputLockerNumber: "",
      isPaid: false,

      latestInputRefName: null,

      slips: [],

      selectRow: null,
    };
  },
  computed: {
    insertShortcutButtonProps() {
      return {
        shortcutKey: "SearchSlipsPopup.shortcuts.insert",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.Insert,
        },
      };
    },
    insertShortcutButtonShortcutString() {
      return toShortcutString(this.insertShortcutButtonProps.shortcut);
    },
    searchShortcutButtonShortcutString() {
      return toShortcutString(getSearchButtonShortcut());
    },
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: "전표 찾기",
        width: 1160,
        height: 760,
        allowDragging: true,
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
    slipGridProps() {
      return {
        aggregates: [
          {
            columns: [
              {
                field: "groupProductName",
                type: "Custom",
                footerTemplate() {
                  return {
                    template: Vue.component("footerTemplate", {
                      template: `<div style="text-align: center">합계</div>`,
                    }),
                  };
                },
              },
              {
                field: "totalSalesAmount",
                type: "Custom",
                customAggregate: (data) => {
                  return _sumBy(
                    data.result,
                    ({ totalSalesAmount = 0, deleted = false }) =>
                      deleted ? 0 : totalSalesAmount
                  );
                },
                footerTemplate() {
                  return {
                    template: Vue.component("footerTemplate", {
                      template: `<div style="text-align: right">{{ data.Custom | numberWithCommas }}</div>`,
                      filters: {
                        numberWithCommas,
                      },
                      data() {
                        return {
                          data: {},
                        };
                      },
                    }),
                  };
                },
              },
            ],
          },
        ],
        allowFiltering: true,
        allowResizing: true,
        allowSelection: true,
        allowSorting: true,
        columns: [
          {
            field: "id",
            visible: false,
            isPrimaryKey: true,
          },
          {
            field: "number",
            headerText: "전표번호",
            textAlign: "Center",
            width: 100,
          },
          {
            field: "storeTableName",
            headerText: "테이블",
            textAlign: "Center",
            width: 90,
          },
          {
            field: "groupProductName",
            headerText: "상품명",
            textAlign: "Left",
            width: 150,
          },
          {
            field: "totalSalesAmount",
            headerText: "금액",
            textAlign: "Right",
            type: "string",
            isNumericType: true,
            width: 90,
          },
          {
            displayAsCheckBox: true,
            editType: "booleanedit",
            field: "paid",
            headerText: "정산",
            textAlign: "Center",
            type: "boolean",
            width: 70,
          },
          {
            field: "visitorName",
            headerText: "내장객",
            textAlign: "Left",
            width: 90,
          },
          {
            field: "visitorLockerNumber",
            headerText: "락카",
            textAlign: "Center",
            width: 80,
          },
          {
            field: "groupName",
            headerText: "단체명",
            textAlign: "Center",
            width: 115,
          },
          {
            displayAsCheckBox: true,
            editType: "booleanedit",
            field: "deleted",
            headerText: "삭제",
            textAlign: "Center",
            type: "boolean",
            width: 70,
          },
          {
            field: "reasonDeleted",
            headerText: "삭제사유",
            textAlign: "Left",
            width: 250,
          },
        ],
        dataSource: this.slips.map(
          ({
            id,
            number,
            storeTableUses,
            sales,
            isPaid,
            visitor,
            isDeleted,
            deleteReason,
          }) => ({
            id,
            number,
            storeTableName:
              _maxBy(storeTableUses || [], "insertDateTime")?.storeTable
                ?.name || "N/A",
            groupProductName:
              !sales || sales.length === 0
                ? ""
                : sales.length === 1
                ? `${sales[0].productName}`
                : `${sales[0].productName} 외 ${sales.length - 1}건`,
            totalSalesAmount:
              sales
                ?.map(({ totalAmount }) => totalAmount || 0)
                .reduce((acc, cur) => acc + cur, 0) || 0,
            paid: isPaid,
            visitorName: !visitor || !visitor.name ? "일반고객" : visitor.name,
            visitorLockerNumber: !visitor?.lockerNumber
              ? ""
              : visitor?.lockerNumber,
            groupName: !visitor?.team?.groupName
              ? ""
              : visitor?.team?.groupName,
            deleted: isDeleted,
            reasonDeleted: deleteReason,
          })
        ),
        isInPopup: true,
        isSelectedRowRetain: false,
        provides: [Aggregate, ForeignKey, Resize, Sort],
        selectionSettings: { type: "Single", enableToggle: false },
        useCommonStyles: false,
      };
    },
  },
  methods: {
    show() {
      this.fetchSlips();
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.$emit("close");
    },
    onInputSlipNumberFocus() {
      this.latestInputRefName = "input-slip-number";
    },
    onInputVisitorNameFocus() {
      this.latestInputRefName = "input-visitor-name";
    },
    onInputLockerNumberFocus() {
      this.latestInputRefName = "input-locker-number";
    },
    onNumpadButtonClick(event, { value }) {
      switch (value) {
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
        case "0":
        case "00":
          if (this.latestInputRefName) {
            switch (this.latestInputRefName) {
              case "input-slip-number":
                this.inputSlipNumber = `${this.inputSlipNumber}${value}`;
                break;
              case "input-visitor-name":
                this.inputVisitorName = `${this.inputVisitorName}${value}`;
                break;
              case "input-locker-number":
                this.inputLockerNumber = `${this.inputLockerNumber}${value}`;
                break;
            }
          } else {
            this.inputSlipNumber = `${this.inputSlipNumber}${value}`;
          }
          break;
        case "Clear":
          if (this.latestInputRefName) {
            switch (this.latestInputRefName) {
              case "input-slip-number":
                this.inputSlipNumber = "";
                break;
              case "input-visitor-name":
                this.inputVisitorName = "";
                break;
              case "input-locker-number":
                this.inputLockerNumber = "";
                break;
            }
          } else {
            this.inputSlipNumber = "";
          }
          break;
        case "Backspace":
          if (this.latestInputRefName) {
            switch (this.latestInputRefName) {
              case "input-slip-number":
                if (this.inputSlipNumber) {
                  this.inputSlipNumber = this.inputSlipNumber.substr(
                    0,
                    this.inputSlipNumber.length - 1
                  );
                }
                break;
              case "input-visitor-name":
                if (this.inputVisitorName) {
                  this.inputVisitorName = this.inputVisitorName.substr(
                    0,
                    this.inputVisitorName.length - 1
                  );
                }
                break;
              case "input-locker-number":
                if (this.inputLockerNumber) {
                  this.inputLockerNumber = this.inputLockerNumber.substr(
                    0,
                    this.inputLockerNumber.length - 1
                  );
                }
                break;
            }
          } else {
            if (this.inputSlipNumber) {
              this.inputSlipNumber = this.inputSlipNumber.substr(
                0,
                this.inputSlipNumber.length - 1
              );
            }
          }
          break;
        case "Enter":
          this.fetchSlips();
          break;
      }
    },
    onSlipsGridRowSelected(args) {
      this.selectRow = args.data;
    },
    onSlipsGridQueryCellInfo(args) {
      const {
        cell,
        column: { field },
        data,
      } = args;

      if (field === "paid") {
        if (data[field]) {
          cell.style.backgroundColor = commonCodesGetColorValue(
            "PAY_FLAG",
            data[field] ? "1" : "0"
          );
        }
      }

      if (data["deleted"]) {
        cell.style.color = "red";
      }
    },
    onSlipsGridDialogDoubleClickedOrEnterKeyed() {
      this.onSubmitClick();
    },
    onSlipsGridDataBound() {
      const gridRef = this.$refs["slipsGrid"];
      if (this.slips && 0 < this.slips.length) {
        if (this.selectRow) {
          const { id } = this.selectRow;

          const targetIndex = gridRef
            .getBatchCurrentViewRecords()
            .findIndex((record) => record.id === id);

          if (targetIndex < 0) {
            gridRef.selectRow(0);
          } else {
            gridRef.selectRow(targetIndex);
          }
        } else {
          gridRef.selectRow(0);
        }
      } else {
        this.selectRow = null;
        this.$nextTick(() => {
          this.$refs[this.latestInputRefName]?.focus();
        });
      }
    },
    onInputClick() {
      if (this.latestInputRefName) {
        this.$refs[this.latestInputRefName]?.focus();
      } else {
        this.$refs["input-slip-number"].focus();
      }
    },
    onSearchSlipsClick() {
      this.fetchSlips();
    },
    onSubmitClick() {
      const [record] = this.$refs["slipsGrid"].getSelectedRecords();
      if (record) {
        const slip = this.slips.find((slip) => slip.id === record.id);
        if (slip) {
          this.$emit("submit", { slip: { id: slip.id } });
          this.hide();
        }
      }
    },
    onCloseClick() {
      this.hide();
    },

    // API
    async fetchSlips() {
      const filters = [];
      if (this.inputSlipNumber) {
        filters.push({
          field: "number",
          condition: "contains",
          values: [this.inputSlipNumber],
        });
      }
      if (this.inputVisitorName) {
        filters.push({
          field: "visitor.name",
          condition: "contains",
          values: [this.inputVisitorName],
        });
      }
      if (this.inputLockerNumber) {
        filters.push({
          field: "visitor.lockerNumber",
          condition: "contains",
          values: [this.inputLockerNumber],
        });
      }
      if (this.isPaid !== undefined && this.isPaid !== null) {
        filters.push({
          field: "isPaid",
          condition: `${this.isPaid}`,
          values: [this.isPaid],
        });
      }
      const orders = [{direction: "asc", field: "number"}];

      this.slips =
        (await GolfERPService.fetchSlips(
          this.store.code,
          moment(this.salesDate).format(DATE_FORMAT_YYYY_MM_DD),
          this.store.golfCabinDivision,
          filters,
          orders
        )) || [];
    },
  },
};
</script>

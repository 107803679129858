<template>
  <div class="content-wrapper">
    <!-- REPORT -->
    <report-view ref="reportViewComponent" :isPopup="true"/>
    <!-- 필터영역 -->
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">마감년월</div>
            <ul class="content">
              <li class="item date">
                <input-date
                    ref = "closeYearMonth"
                    :format="DATE_FORMAT_YYYY_MM"
                    :notClear="true"
                    depth="Year"
                    v-model="searchFilter.closeYm"
                    type="lookup-condition"
                    @change="onYearMonthChange"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button button-div="GET" :is-shortcut-button="true" :ignore="isPopupOpened" @click.native="onClickSearch">조회</erp-button>
        </div>
      </div>
      <div class="lookup-right"> <!-- 사용시 좌측이 없을 경우 생략-->
        <ul class="lookup-button"></ul>
      </div>
    </div>
    <!-- 본문 -->
    <div class="content-body">
      <article class="body-article">
        <!-- ######## 목록 리스트 ########-->
        <section class="article-section">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">{{gridField.title}}</div>
              <div class="header-caption">{{ searchFilter.closeYm }}</div>
              <div class="header-caption">[ {{ gridField.count }} 건 ]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button button-div="FILE" :ignore="isPopupOpened" @click.native="onClickExcel">Excel</erp-button>
                </li>
              </ul>
              <ul class="lookup-button">
                <li class="print"><erp-button button-div="PRINT" @click.native="onClickPrint" :is-shortcut-button="true">인쇄</erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="grid"
                  v-bind ="initGrid"
                  @actionComplete="gridComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <!-- 팝업 -->
  </div>
</template>

<script>

import commonMixin from "@/views/layout/mixin/commonMixin";
import ReportView from "@/components/common/report/ReportView.vue";
import ErpButton from "@/components/button/ErpButton.vue";
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {ExcelExport, Filter, ForeignKey, Page, Resize, Edit, Aggregate} from "@syncfusion/ej2-vue-grids";
import MessagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import moment from "moment/moment";
import {DATE_FORMAT_YYYY_MM, getDayOfWeekCaption} from "@/utils/date";
import InputDate from "@/components/common/datetime/InputDate";
import StockCommon from "@/api/v2/StockManage/StockCommon";
import StockProductStat from "@/api/v2/StockManage/StockProductStat";

export default {
  name: "ProductMonthlyInvntry",
  mixins: [commonMixin, MessagePopupDialogMixin],
  components: {
    ReportView,
    ErpButton,
    EjsGridWrapper,
    InputDate
  },

  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      DATE_FORMAT_YYYY_MM,
      //최근 마감월
      lastCloseYm : null,
      //조회 필드
      searchFilter: {
        closeYm:null
      },
      //드롭다운 코드
      commonCodeOptions: {
      },
      //그리드 필드
      gridField: {
        title: "월 수불부",
        count : 0,
        dataSource: [],
      },
    };
  },
  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    // 팝업시 메인화면 버튼의 단축키 조정 , 팝업이 없으면 false 리턴
    isPopupOpened() {
      return false;
    },
    initGrid() {
      return {
        columns: [
          { field: "pathDesc",      textAlign: 'left',   allowEditing: false, width: 150,  visible: true,  type: "string",  headerText: "분류", },
          { field: "productCode",  textAlign: 'center', allowEditing: false, width: 90,   visible: true,  type: "string",  headerText: "상품코드",isPrimaryKey: true, },
          { field: "productName",  textAlign: 'left',   allowEditing: false, width: 150,  visible: true,  type: "string",  headerText: "상품", },
          { field: "unit",          textAlign: 'center', allowEditing: false, width: 50,   visible: true,  type: "string",  headerText: "단위", },
          {
            headerText: "전월",
            columns: [
              { field: "lsmthStock",    textAlign: 'right',  allowEditing: false, width: 80,   visible: true,  type: "number",  headerText: "수량", isNumericType: true,},
              { field: "lsmthPrice",    textAlign: 'right',  allowEditing: false, width: 80,   visible: true,  type: "number",  headerText: "단가", isNumericType: true,},
              { field: "lsmthAmt",      textAlign: 'right',  allowEditing: false, width: 80,   visible: true,  type: "number",  headerText: "금액", isNumericType: true,},
            ],
          },
          {
            headerText: "입고",
            columns: [
              { field: "wrhousngStock",   textAlign: 'right',  allowEditing: false, width: 80,   visible: true,  type: "number",  headerText: "수량", isNumericType: true,},
              { field: "wrhousngPrice",   textAlign: 'right',  allowEditing: false, width: 80,   visible: true,  type: "number",  headerText: "단가", isNumericType: true,},
              { field: "wrhousngAmt",     textAlign: 'right',  allowEditing: false, width: 80,   visible: true,  type: "number",  headerText: "금액", isNumericType: true,},
            ],
          },
          {
            headerText: "반품",
            columns: [
              { field: "rtngudStock",   textAlign: 'right',  allowEditing: false, width: 80,   visible: true,  type: "number",  headerText: "수량", isNumericType: true,},
              { field: "rtngudPrice",   textAlign: 'right',  allowEditing: false, width: 80,   visible: true,  type: "number",  headerText: "단가", isNumericType: true,},
              { field: "rtngudAmt",     textAlign: 'right',  allowEditing: false, width: 80,   visible: true,  type: "number",  headerText: "금액", isNumericType: true,},
            ],
          },
          {
            headerText: "출고",
            columns: [
              { field: "dlivyStock",    textAlign: 'right',  allowEditing: false, width: 80,   visible: true,  type: "number",  headerText: "수량", isNumericType: true,},
              { field: "dlivyPrice",    textAlign: 'right',  allowEditing: false, width: 80,   visible: true,  type: "number",  headerText: "단가", isNumericType: true,},
              { field: "dlivyAmt",      textAlign: 'right',  allowEditing: false, width: 80,   visible: true,  type: "number",  headerText: "금액", isNumericType: true,},
            ],
          },
          {
            headerText: "페기",
            columns: [
              { field: "dsuseDlivyStock",   textAlign: 'right',  allowEditing: false, width: 80,   visible: true,  type: "number",  headerText: "수량", isNumericType: true,},
              { field: "dsuseDlivyPrice",   textAlign: 'right',  allowEditing: false, width: 80,   visible: true,  type: "number",  headerText: "단가", isNumericType: true,},
              { field: "dsuseDlivyAmt",     textAlign: 'right',  allowEditing: false, width: 80,   visible: true,  type: "number",  headerText: "금액", isNumericType: true,},
            ],
          },
          {
            headerText: "판매",
            columns: [
              { field: "saleStock",   textAlign: 'right',  allowEditing: false, width: 80,   visible: true,  type: "number",  headerText: "수량", isNumericType: true,},
              { field: "salePrice",   textAlign: 'right',  allowEditing: false, width: 80,   visible: true,  type: "number",  headerText: "단가", isNumericType: true,},
              { field: "saleAmt",     textAlign: 'right',  allowEditing: false, width: 80,   visible: true,  type: "number",  headerText: "금액", isNumericType: true,},
            ],
          },
          {
            headerText: "재고",
            columns: [
              { field: "closeStock",    textAlign: 'right',  allowEditing: false, width: 80,   visible: true,  type: "number",  headerText: "수량", isNumericType: true,},
              { field: "closePrice",    textAlign: 'right',  allowEditing: false, width: 80,   visible: true,  type: "number",  headerText: "단가", isNumericType: true,},
              { field: "closeAmt",      textAlign: 'right',  allowEditing: false, width: 80,   visible: true,  type: "number",  headerText: "금액", isNumericType: true,},
            ],
          }
        ],
        aggregates:[
          {
            columns: [
              { field: 'pathDesc',        aggregationType: 'TotalCaption', customAggregate: '합계',},
              { field: "lsmthStock",    	aggregationType: 'TotalSum',  },
              { field: "lsmthPrice",    	aggregationType: 'TotalSum',  },
              { field: "lsmthAmt",      	aggregationType: 'TotalSum',  },
              { field: "wrhousngStock",   aggregationType: 'TotalSum',  },
              { field: "wrhousngPrice",   aggregationType: 'TotalSum',  },
              { field: "wrhousngAmt",     aggregationType: 'TotalSum',  },
              { field: "rtngudStock",   	aggregationType: 'TotalSum',  },
              { field: "rtngudPrice",   	aggregationType: 'TotalSum',  },
              { field: "rtngudAmt",     	aggregationType: 'TotalSum',  },
              { field: "dlivyStock",    	aggregationType: 'TotalSum',  },
              { field: "dlivyPrice",    	aggregationType: 'TotalSum',  },
              { field: "dlivyAmt",      	aggregationType: 'TotalSum',  },
              { field: "dsuseDlivyStock", aggregationType: 'TotalSum',  },
              { field: "dsuseDlivyPrice", aggregationType: 'TotalSum',  },
              { field: "dsuseDlivyAmt",   aggregationType: 'TotalSum',  },
              { field: "saleStock",   		aggregationType: 'TotalSum',  },
              { field: "salePrice",   		aggregationType: 'TotalSum',  },
              { field: "saleAmt",     		aggregationType: 'TotalSum',  },
              { field: "closeStock",    	aggregationType: 'TotalSum',  },
              { field: "closePrice",    	aggregationType: 'TotalSum',  },
              { field: "closeAmt",      	aggregationType: 'TotalSum',  },
            ],
          },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit, Aggregate],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        pageSettings: { pageSize: 50 },
        resizeSettings: {mode:"Normal"},
      };
    },
  },
  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
  },
  async mounted() {
    //기초데이터 세팅
    await this.initData();
  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
    //region ******************************* 버튼 이벤트 *******************************
    //기초데이터 세팅
    async initData() {
      this.lastCloseYm =  await StockCommon.getLastProductClosingYm();
      this.searchFilter.closeYm = this.lastCloseYm ? moment(this.lastCloseYm).format(DATE_FORMAT_YYYY_MM) : moment().format(DATE_FORMAT_YYYY_MM);
      //await this.onClickSearch();
    },
    async onYearMonthChange(){
      await this.onClickSearch();
    },
    //조회
    async onClickSearch() {
      this.gridField.dataSource = await StockProductStat.getProductMonthlyInvntryList(this.searchFilter);
    },
    //엑셀 출력
    onClickExcel() {
      this.$refs.grid.excelExport({fileName: this.gridField.title+".xlsx"});
    },
    //리포트 출력
    async onClickPrint() {
      if(this.gridField.dataSource.length <1){
        this.errorToast("출력할 자료가 없습니다.");
        return;
      }
      const gridData = [];
      this.gridField.dataSource.forEach((item,index)=> {
        let tempParentCode = this.gridField.dataSource[index + 1]?.parentCode;
        let tempClassCode = this.gridField.dataSource[index + 1]?.classCode;
        const data = {
          ...item,
          pathDesc : item.pathDesc.toString().split(">")[2] + item.pathDesc.toString().split(">")[3]
        };
        gridData.push(data);
        // 소계 계산
        if (item.classCode !== tempClassCode) {
          const subTotal = {};
          subTotal.productCode = "소계";
          subTotal.lsmthStock =  this.gridField.dataSource.filter(it=> it.classCode == item.classCode).map(item => item.lsmthStock).reduce((acc, cur) => cur + acc, 0);
          subTotal.lsmthAmt   =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).map(item => item.lsmthAmt).reduce((acc, cur) => cur + acc, 0);
          subTotal.wrhousngStock =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).map(item => item.wrhousngStock).reduce((acc, cur) => cur + acc, 0);
          subTotal.wrhousngAmt =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).map(item => item.wrhousngAmt).reduce((acc, cur) => cur + acc, 0);
          subTotal.rtngudStock =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).map(item => item.rtngudStock).reduce((acc, cur) => cur + acc, 0);
          subTotal.rtngudAmt =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).map(item => item.rtngudAmt).reduce((acc, cur) => cur + acc, 0);
          subTotal.dlivyStock =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).map(item => item.dlivyStock).reduce((acc, cur) => cur + acc, 0);
          subTotal.dlivyAmt =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).map(item => item.dlivyAmt).reduce((acc, cur) => cur + acc, 0);
          subTotal.dsuseDlivyStock =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).map(item => item.dsuseDlivyStock).reduce((acc, cur) => cur + acc, 0);
          subTotal.dsuseDlivyAmt =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).map(item => item.dsuseDlivyAmt).reduce((acc, cur) => cur + acc, 0);
          subTotal.saleStock =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).map(item => item.saleStock).reduce((acc, cur) => cur + acc, 0);
          subTotal.saleAmt =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).map(item => item.saleAmt).reduce((acc, cur) => cur + acc, 0);
          subTotal.closeStock =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).map(item => item.closeStock).reduce((acc, cur) => cur + acc, 0);
          subTotal.closeAmt =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).map(item => item.closeAmt).reduce((acc, cur) => cur + acc, 0);
          gridData.push(subTotal);
        }
        // 합계 계산
        if (item.parentCode !== tempParentCode) {
          const total = {};
          total.productCode = "합계";
          total.lsmthStock =  this.gridField.dataSource.filter(it=> it.parentCode==item.parentCode).map(item => item.lsmthStock).reduce((acc, cur) => cur + acc, 0);
          total.lsmthAmt   =  this.gridField.dataSource.filter(it=> it.parentCode==item.parentCode).map(item => item.lsmthAmt).reduce((acc, cur) => cur + acc, 0);
          total.wrhousngStock =  this.gridField.dataSource.filter(it=> it.parentCode==item.parentCode).map(item => item.wrhousngStock).reduce((acc, cur) => cur + acc, 0);
          total.wrhousngAmt =  this.gridField.dataSource.filter(it=> it.parentCode==item.parentCode).map(item => item.wrhousngAmt).reduce((acc, cur) => cur + acc, 0);
          total.rtngudStock =  this.gridField.dataSource.filter(it=> it.parentCode==item.parentCode).map(item => item.rtngudStock).reduce((acc, cur) => cur + acc, 0);
          total.rtngudAmt =  this.gridField.dataSource.filter(it=> it.parentCode==item.parentCode).map(item => item.rtngudAmt).reduce((acc, cur) => cur + acc, 0);
          total.dlivyStock =  this.gridField.dataSource.filter(it=> it.parentCode==item.parentCode).map(item => item.dlivyStock).reduce((acc, cur) => cur + acc, 0);
          total.dlivyAmt =  this.gridField.dataSource.filter(it=> it.parentCode==item.parentCode).map(item => item.dlivyAmt).reduce((acc, cur) => cur + acc, 0);
          total.dsuseDlivyStock =  this.gridField.dataSource.filter(it=> it.parentCode==item.parentCode).map(item => item.dsuseDlivyStock).reduce((acc, cur) => cur + acc, 0);
          total.dsuseDlivyAmt =  this.gridField.dataSource.filter(it=> it.parentCode==item.parentCode).map(item => item.dsuseDlivyAmt).reduce((acc, cur) => cur + acc, 0);
          total.saleStock =  this.gridField.dataSource.filter(it=> it.parentCode==item.parentCode).map(item => item.saleStock).reduce((acc, cur) => cur + acc, 0);
          total.saleAmt =  this.gridField.dataSource.filter(it=> it.parentCode==item.parentCode).map(item => item.saleAmt).reduce((acc, cur) => cur + acc, 0);
          total.closeStock =  this.gridField.dataSource.filter(it=> it.parentCode==item.parentCode).map(item => item.closeStock).reduce((acc, cur) => cur + acc, 0);
          total.closeAmt =  this.gridField.dataSource.filter(it=> it.parentCode==item.parentCode).map(item => item.closeAmt).reduce((acc, cur) => cur + acc, 0);
          gridData.push(total);
        }

      });
      // 총계 계산
      const allTotal = {};
      allTotal.productCode = "총계";
      allTotal.lsmthStock =  gridData.map(item => item.lsmthStock).reduce((acc, cur) => cur + acc, 0);
      allTotal.lsmthAmt   =  gridData.map(item => item.lsmthAmt).reduce((acc, cur) => cur + acc, 0);
      allTotal.wrhousngStock =  gridData.map(item => item.wrhousngStock).reduce((acc, cur) => cur + acc, 0);
      allTotal.wrhousngAmt =  gridData.map(item => item.wrhousngAmt).reduce((acc, cur) => cur + acc, 0);
      allTotal.rtngudStock =  gridData.map(item => item.rtngudStock).reduce((acc, cur) => cur + acc, 0);
      allTotal.rtngudAmt =  gridData.map(item => item.rtngudAmt).reduce((acc, cur) => cur + acc, 0);
      allTotal.dlivyStock =  gridData.map(item => item.dlivyStock).reduce((acc, cur) => cur + acc, 0);
      allTotal.dlivyAmt =  gridData.map(item => item.dlivyAmt).reduce((acc, cur) => cur + acc, 0);
      allTotal.dsuseDlivyStock =  gridData.map(item => item.dsuseDlivyStock).reduce((acc, cur) => cur + acc, 0);
      allTotal.dsuseDlivyAmt =  gridData.map(item => item.dsuseDlivyAmt).reduce((acc, cur) => cur + acc, 0);
      allTotal.saleStock =  gridData.map(item => item.saleStock).reduce((acc, cur) => cur + acc, 0);
      allTotal.saleAmt =  gridData.map(item => item.saleAmt).reduce((acc, cur) => cur + acc, 0);
      allTotal.closeStock =  gridData.map(item => item.closeStock).reduce((acc, cur) => cur + acc, 0);
      allTotal.closeAmt =  gridData.map(item => item.closeAmt).reduce((acc, cur) => cur + acc, 0);


      gridData.push(allTotal);
      const reportData = {
        reportJson: {
          jsonData: {
            gridData: gridData,
          },
          username: this.username,
          uniqueCode: this.$options.name,
          searchOptions: moment(this.searchFilter.closeYm).format("yyyy년 MM월"),

        },
      };
      await this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    //endregion
    //region ******************************* 상세 이벤트 *******************************
    //endregion
    //region ******************************* 공통 이벤트 *******************************
    //endregion
    //region ******************************* 그리드 이벤트 *******************************
    //그리드 조회 완료시(카운트)
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    //endregion
  }
};
</script>

<!-- ##################################### CUSTOM STYLE ########################################* -->
<style scoped>
body .appContent .body-article .article-left{width:100%;}
body .appContent .body-article .article-right{margin-right: calc(-50% - 20px)}
body .appContent .body-article.detail-view-opened .article-left{width:calc(50% - 10px)}
body .appContent .body-article.detail-view-opened .article-right{width:calc(50% - 10px); margin:0}
body .body-footer .section-header {display: block;position: relative;margin: 0px 0px;}
.voc-section {width: 100% !important;}
</style>

<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="batchSmsSendPopup"
      header="SMS 일괄 전송"
      allowDragging="true"
      showCloseIcon="true"
      isModal="true"
      width="320"
      height="740"
      :enableResize="true"
      :animationSettings="{ effect: 'None' }"
      @close="onBatchSmsSendPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section" :style="{ height: `calc(100% - ${smsSendInfo.reservedFlag ? 201 : threeDepth === 'MembershipConditionsSearch' ? 158 : 153}px)` }">
                  <div class="sms-send-box">
                    <div class="preview-top">
                    <div class="speaker-box" />
                    <div class="speaker-small-box" />
                    </div>
                    <div class="preview-middle">
                      <div class="screen-box">
                        <div class="user-profile">
                          <div class="image">{{ username.substr(0, 1) }}</div>
                          <div class="name">{{ username }}</div>
                        </div>
                        <div class="message-box">
                          [Web발신]
                          <br />
                          <div
                            class="preview"
                            v-html="smsSendInfo.message.replace(/\n/g, '<br>')"
                            v-if="smsSendInfo.message"
                          />
                          <div
                            class="preview"
                            v-html="'\n'.concat(noneChkinSmsWords).replace(/\n/g, '<br>')"
                            v-if="noneChkinSmsWords"
                          />
                          <br
                            v-if="
                              smsSendInfo.message && smsSendInfo.aditWordsFlag
                            "
                          />
                          <textarea
                            ref="aditWords"
                            placeholder="메시지를 입력해주세요"
                            v-model="aditWords"
                            :maxlength="maxSmsByteLength"
                            @keyup="resize"
                            @keydown="resize"
                            @focus="resize"
                            spellcheck="false"
                            autofocus
                            v-if="smsSendInfo.aditWordsFlag"
                          />
                          <div
                            class="preview"
                            v-if="
                              bizNameFlag &&
                              smsSendInfo.bizNameLocation === 'DOWN' &&
                              smsSendInfo.bizName
                            "
                          >
                            [HEADER_MEMO]$
                          </div>
                        </div>
                        <div
                            class="limit"
                            :style="{
                            color:
                              getByteLengthSum(aditWords) >
                              maxSmsByteLength
                                ? 'red'
                                : '#333',
                          }"
                        >
                          {{
                            numberWithCommas(getByteLengthSum(aditWords))
                          }}
                          / {{ numberWithCommas(maxSmsByteLength) }} Byte ({{
                            getByteLengthSum(aditWords) >= 90
                                ? "LMS"
                                : "SMS"
                          }})
                        </div>
                      </div>
                    </div>
                    <div class="preview-bottom">
                      <div class="button-box" />
                    </div>
                  </div>
                </section>
                <section class="article-section" :style="{ height: `${smsSendInfo.reservedFlag ? 201 : threeDepth === 'MembershipConditionsSearch' ? 158 : 153}px` }">
                  <div class="section-body" style="border: none; overflow: hidden !important;">
                    <div class="body-data" v-if="isOptionsVisible">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field" style="width: calc(100% + 1px)">
                              <!-- 필수 : required -->
                              <div class="title">전송타입</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        v-model="smsSendInfo.type"
                                        :dataSource=" searchOptions.smsSendTypeOptions"
                                        :fields="commonCodeFields"
                                        :allowFiltering="false"
                                        cssClass="body-data-dropdown"
                                        @select="onSmsSendTypeSelect"
                                      />
                                    </div>
                                  </li>
                                </ul>
                                <ul class="row">
                                  <li class="item form-group check">
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="checkbox"
                                            id="aditWordsFlag"
                                            v-model="smsSendInfo.aditWordsFlag"
                                            value="true"
                                          />
                                          <i></i>
                                          <div class="label">추가문자</div>
                                        </label>
                                      </li>
                                      <li>
                                        <label>
                                          <input
                                            type="checkbox"
                                            id="bizNameFlag"
                                            v-model="bizNameFlag"
                                            value="true"
                                            @change="onBizNameFlagChanged"
                                          />
                                          <i></i>
                                          <div class="label">사업장명 출력</div>
                                        </label>
                                      </li>
                                      <li v-if="!isLocalClose && smsSendInfo.type !== 'CMPN_RESVE'">
                                        <label>
                                          <input
                                            type="checkbox"
                                            id="transSmsSendFlag"
                                            v-model="
                                              smsSendInfo.transSmsSendFlag
                                            "
                                            value="true"
                                          />
                                          <i></i>
                                          <div class="label">위임자 전송</div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" style="width: calc(100% + 1px)">
                              <!-- 필수 : required -->
                              <div class="title">발송구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            v-model="smsSendInfo.reservedFlag"
                                            :value="false"
                                          />
                                          <i></i>
                                          <div class="label">즉시발송</div>
                                        </label>
                                      </li>
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            v-model="smsSendInfo.reservedFlag"
                                            :value="true"
                                          />
                                          <i></i>
                                          <div class="label">예약발송</div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li
                              class="field"
                              style="width: calc(100% + 1px)"
                              v-if="smsSendInfo.reservedFlag"
                            >
                              <!-- 필수 : required -->
                              <div class="title">예약발송</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group date">
                                    <div class="form">
                                      <input-date
                                        format="YYYY-MM-DD"
                                        type="body-data"
                                        v-model="smsSendInfo.resveSendDate"
                                      />
                                    </div>
                                  </li>
                                  <li class="item text">
                                    (<span
                                      :style="{
                                        color: getDayOfWeekCaptionColor(
                                          new Date(smsSendInfo.resveSendDate)
                                        ),
                                      }"
                                      >{{
                                        getDayOfWeekCaption(
                                          new Date(smsSendInfo.resveSendDate)
                                        )
                                      }}</span
                                    >)
                                  </li>
                                </ul>
                                <ul class="row">
                                  <li
                                    class="item form-group"
                                    style="
                                      width: 113px;
                                      border-right: 1px solid #e0e0e0;
                                    "
                                  >
                                    <div class="form">
                                      <ejs-timepicker
                                        v-model="smsSendInfo.resveSendTime"
                                        format="HH:mm"
                                        floatLabelType="never"
                                        :strictMode="true"
                                        cssClass="body-data-timepicker"
                                        @change="onResveSendTimeChange"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                    <div
                      class="body-data"
                      :style="isOptionsVisible ? 'margin-top: -1px;' : ''"
                    >
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field" style="width: calc(100% + 1px)">
                              <!-- 필수 : required -->
                              <div class="title">발신번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="dsptchNo"
                                        v-model="smsSendInfo.dsptchNo"
                                        disabled
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" style="width: calc(100% + 1px)" v-show="threeDepth === 'MembershipConditionsSearch'">
                              <div class="title">파일첨부</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input" style="width: calc(100% - 42px);">
                                    <div class="form">
                                      <input-text
                                          ref="file"
                                          v-model="fileInfo.name"
                                          disabled="false"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group button" style="padding: 1px 1px 0 1px;">
                                    <ul class="button" style="list-style: none; padding: 0; margin: 0; display: flow-root;">
                                      <li class="registration" style="float: left; margin: 0px;">
                                        <div class="e-upload e-control-wrapper e-lib e-keyboard" style="position: relative; border: none; font-family: 'Roboto', 'Segoe UI', 'GeezaPro', 'DejaVu Serif', 'sans-serif', '-apple-system', 'BlinkMacSystemFont';">
                                          <div class="e-file-select-wrap file" style="padding: 0;">
                                            <label class="e-css e-btn" for="closeFileUploader" style="
                                                box-shadow: 0 3px 3px 0 rgb(0 0 0 / 3%);
                                                color: #666; border-color: #e0e0e0;
                                                border-color: rgb(224, 224, 224);
                                                font-weight: normal;
                                                font-family: '돋움', Dotum, Arial, Verdana, sans-serif;
                                                padding: 4px 7px 2px 7px;
                                                background-image: none;
                                                background-repeat: no-repeat;
                                                background-position: left -84px;
                                                border-radius: 3px;
                                                font-size: 12px;
                                              ">
                                              찾기
                                            </label>
                                            <span class="e-file-select" style="display: inline-block; width: 0; position: absolute; top: 0; left: 0;">
                                              <input
                                                  id="closeFileUploader"
                                                  name="closeFileUploader"
                                                  ref="closeFileUploader"
                                                  class="e-control e-uploader e-lib"
                                                  type="file"
                                                  aria-label="Uploader"
                                                  style="width: 0; display: inline-block; opacity: 0;"
                                                  maxlength="10000000"
                                                  hidden
                                                  @change="onCloseFileUploaderChange"
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div
                            class="border-top"
                            :style="isOptionsVisible ? 'display: none;' : ''"
                          ></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div
                        class="border-top"
                        :style="isOptionsVisible ? 'display: none;' : ''"
                      ></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="keyColor">
              <erp-button
                  button-div="SAVE"
                  :ignore="showTestSmsSendPopup"
                  :is-icon-custom="true"
                  :is-custom-shortcut-button="true"
                  shortcut-key="BatchSmsSendPopup.shortcuts.sendSms"
                  :shortcut="{key: 'F7'}"
                  @click.native="onSmsSendButtonClicked"
              >
                전송
              </erp-button>
            </li>
            <li>
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  @click.native="onTestButtonClicked"
              >
                검증
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="onInitButtonClicked">
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeBatchSmsSendPopup">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <test-sms-send-popup
      v-if="showTestSmsSendPopup"
      ref="testSmsSendPopup"
      @smsSend="smsSend"
      @popupClosed="onTestSmsSendPopup"
    />
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import InputDate from "@/components/common/datetime/InputDate";
import { numberWithCommas } from "@/utils/number";
import {getByteLength} from "@/utils/string";
import {
  commonCodesGetStandardInfo,
  commonCodesGetCommonCodeAttrbByCodeAndIdx,
} from "@/utils/commonCodes";
import {
  getFormattedDate,
  getDayOfWeekCaption,
  getDayOfWeekCaptionColor,
  getFormattedDateTimePicker,
} from "@/utils/date";
import { getFormattedTelNumber } from "@/utils/string";
import { validateFormRefs } from "@/utils/formUtil";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import InputText from "@/components/common/text/InputText";
import { mapGetters } from "vuex";
import ErpButton from "@/components/button/ErpButton.vue";

import TestSmsSendPopup from "@/components/popups/TestSmsSendPopup";
import moment from "moment";
import {encodeBase64} from "@/utils/ImageUtil";

export default {
  name: "BatchSmsSendPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputText,
    InputDate,
    TestSmsSendPopup,
    ErpButton,
  },
  props: {
    threeDepth: {
      type: String,
    },
  },
  created() {
    this.smsSendInfo.resveSendDate = getFormattedDate(new Date());
  },
  data() {
    return {
      maxSmsByteLength: 2000,
      commonCodeFields: { text: "smsSendTypeName", value: "smsSendType" },
      smsSendData: null,
      smsSendInfo: {
        message: null,
        bizName: commonCodesGetStandardInfo("bizName"),
        bizNameLocation: commonCodesGetStandardInfo("bizNameLocation"),
        aditWordsFlag: true,
        transSmsSendFlag: false,
        reservedFlag: false,
        resveSendDate: null,
        resveSendTime: "00:00",
        dsptchNo: null,
        kakaoSendFlag: false,
        smsKind: null,
        kakaoDispatchKey: commonCodesGetStandardInfo("kakaoDsptchKey"),
        kakaoTemplateCode: null,
        subject: null,
      },
      searchOptions: {
        smsSendTypeOptions: null,
      },
      smsWords: null,
      bizNameFlag: true,
      isOptionsVisible: true,
      isLocalClose: true,
      showTestSmsSendPopup: false,
      searchTotalMemberOptions: [],
      notOptionVisibleList: [
        "NOTE", // 일반공지
        "DPMNY", // 예치금 입금확인
        "DPMNY_RETURN", // 예치금 반환확인
        "DPMNY_DEDUC", // 예치금 차감확인
        "DPMNY_EXIT", // 예치금 소멸확인
      ],
      popupData: null,
      smsSendValidationRules: {
        message: {
          required: true,
        },
        dsptchNo: {
          required: true,
        },
      },
      aditWords: "",
      noneChkinSmsWords: "",
      fileInfo: {
        path: null,
        name: null,
        imageId: null,
        uploadDiv: "COUPON_MEMBER",
        url: null,
      }
    };
  },
  computed: {
    ...mapGetters(["username"]),
  },
  methods: {
    numberWithCommas,
    validateFormRefs,
    getDayOfWeekCaption,
    getDayOfWeekCaptionColor,
    getByteLength,
    getByteLengthSum(text) {
      const textContent = [this.smsSendInfo.message,this.noneChkinSmsWords,text];
      let byte = 0;
      textContent.forEach((text) => {
        if (text) {
          byte += this.getByteLength(text);
        }
      });
      return byte;
    },
    async showBatchSmsSendPopup(popupData, result = []) {
      /*
       * popupData 내용
       * popupData.smsSendType : SMS 전송타입
       * popupData.smsDiv : SMS 전송구분 ("MEMBER" / "DEEP_MEMBER" / "RESERVATION" / "DEPOSIT_MONEY" / "RESERVATION_BATCH")
       * popupData.smsSendList : SMS 전송 대상
       * [
       *   {
       *     sendKey : 전송 Key (예약번호(resveNo) 또는 회원ID(memberId) 또는 쿠폰ID(couponId) 또는 위약ID(penltyId) 또는 예치금입금ID(incomId))
       *     resveDate : 예약일자 (골프예약 SMS 전송시에만)
       *     dwName : 요일 (골프예약 SMS 전송시에만)
       *     resveCourse : 예약코스 (골프예약 SMS 전송시에만)
       *     resveTime : 예약시간 (골프예약 SMS 전송시에만)
       *     resveCourseTime : 예약코스/시간 (골프예약 SMS 전송시에만)
       *     name : 수신자명
       *     contactTel : 수신번호
       *   }
       * ]
       */
      console.log('popupData.===>', popupData);
      this.popupData = popupData;
      this.smsSendInfo.type = popupData.smsSendType;
      this.smsSendInfo.div = popupData.smsDiv;
      this.smsSendData = popupData.smsSendList;

      if (this.smsSendInfo.div !== "DEEP_MEMBER" && this.notOptionVisibleList.findIndex((data) => data === this.smsSendInfo.type) >= 0) {
        this.isOptionsVisible = false;
      }

      this.searchTotalMemberOptions = result;
      await this.fetchSmsWords();
    },
    onBatchSmsSendPopupClosed() {
      this.$emit("popupClosed");
    },
    closeBatchSmsSendPopup() {
      this.$refs.batchSmsSendPopup.hide();
    },
    onResveSendTimeChange(args) {
      this.smsSendInfo.resveSendTime = args
        ? getFormattedDateTimePicker(args, "HH:mm")
        : null;
    },
    onSmsSendTypeSelect(args) {
      this.smsSendInfo.type = args.itemData.smsSendType;
      this.smsSendTypeSetting();
    },
    smsSendTypeSetting() {
      const smsSendType = this.searchOptions.smsSendTypeOptions.find(
        (data) => data.smsSendType === this.smsSendInfo.type
      );
      this.smsSendInfo.message = smsSendType.smsWords;
      this.aditWords = smsSendType.aditWords || '';
      if (this.fileInfo.url) {
        this.aditWords = (smsSendType.aditWords || '') + '\n' + this.fileInfo.url;
      }
      if (
        (
          smsSendType.smsSendType === "G_RESVE" ||
          smsSendType.smsSendType === "G_RESVE_GROUP" ||
          smsSendType.smsSendType === "G_CNFIRM" ||
          smsSendType.smsSendType === "G_CNFIRM_GROUP" ||
          smsSendType.smsSendType === "CMPN_RESVE"
        ) && commonCodesGetStandardInfo("noneChkinUseFlag")
      ) {
        this.noneChkinSmsWords = commonCodesGetStandardInfo("noneChkinSmsWords");
      } else {
        this.noneChkinSmsWords = "";
      }
      if (this.smsSendInfo.bizNameLocation === "DOWN") {
        this.smsSendInfo.message =
          JSON.stringify(this.smsSendInfo.message).toString().indexOf("[HEADER_MEMO]$\\n") > 0 ?
            JSON.parse(JSON.stringify(this.smsSendInfo.message).replace("[HEADER_MEMO]$\\n", "")) :
            JSON.parse(JSON.stringify(this.smsSendInfo.message).replace("[HEADER_MEMO]$", ""));
      }
      this.smsSendInfo.dsptchNo = getFormattedTelNumber(smsSendType.dsptchNo ? smsSendType.dsptchNo : commonCodesGetStandardInfo("dsptchNo"));
      this.smsSendInfo.kakaoSendFlag = smsSendType.kakaoSendFlag;
      this.smsSendInfo.kakaoTemplateCode = smsSendType.kakaoTmplatCode;
      this.smsSendInfo.smsKind = smsSendType.smsKind;
      this.smsSendInfo.subject = smsSendType.smsSendTypeName;
      if (
        this.smsSendInfo.type === 'G_LOCAL_CLOSE' ||
        this.smsSendInfo.type === 'NOTE' ||
        this.smsSendInfo.type === 'CMPN_RESVE'
      ) {
        this.smsSendInfo.transSmsSendFlag = false;
      }
      this.isLocalClose = this.smsSendInfo.type === "G_LOCAL_CLOSE" || this.smsSendInfo.type === "NOTE";
      if (this.$refs.aditWords) {
        this.$refs.aditWords.focus();
      }
      this.$nextTick(() => {
        this.resize();
      });
    },
    async fetchSmsWords() {
      this.smsWords = this.isOptionsVisible
        ? await GolfErpAPI.fetchSmsWords()
        : await GolfErpAPI.fetchAllSmsWords();
      this.searchOptions.smsSendTypeOptions = this.smsWords
        .filter(item =>
          this.smsSendInfo.div === "DEEP_MEMBER" ?
            ["ANNUAL_FEE_NOTICE", "NOTE", "SIGNUP"].includes(item.smsSendType) :
            item.smsSendType !== "G_LOCAL_CLOSE"
        )
        .filter(item =>
          item.smsSendType
            ? !this.isOptionsVisible
              ? item
              : commonCodesGetCommonCodeAttrbByCodeAndIdx(
                  "SMS_SEND_TYPE",
                  item.smsSendType,
                  1
                ) === "Y"
            : item
        );

      const smsSendType = this.searchOptions.smsSendTypeOptions.find(
        (data) => data.smsSendType === this.smsSendInfo.type
      );
      if (smsSendType) {
        this.smsSendInfo.type = smsSendType.smsSendType;
        this.smsSendInfo.kakaoSendFlag = smsSendType.kakaoSendFlag;
        this.smsSendInfo.kakaoTemplateCode = smsSendType.kakaoTmplatCode;
        this.smsSendInfo.smsKind = smsSendType.smsKind;
        this.smsSendInfo.subject = smsSendType.smsSendTypeName;
      } else {
        this.errorToast("일치하는 SMS전송타입이 존재하지 않습니다");
        this.closeBatchSmsSendPopup();
        return;
      }

      this.smsSendTypeSetting();
    },
    async onSmsSendButtonClicked() {
      if (!this.validateFormRefs(this.smsSendValidationRules)) {
        return;
      }

      if (!(await this.confirm("SMS 전송하시겠습니까?"))) {
        return;
      }

      await this.smsSend();
    },
    onTestButtonClicked() {
      if (!this.validateFormRefs(this.smsSendValidationRules)) {
        return;
      }

      this.showTestSmsSendPopup = true;
      this.$nextTick(() => {
        this.$refs.testSmsSendPopup.showPopup();
      });
    },
    async smsSend(contactTel = null) {
      let message = [], noneChkinMessage = [];
      if (this.smsSendInfo.message) {
        message.push(this.smsSendInfo.message);
        noneChkinMessage.push(this.smsSendInfo.message);
        if (this.noneChkinSmsWords !== "") {
          noneChkinMessage.push(this.noneChkinSmsWords);
        }
      }
      if (this.smsSendInfo.aditWordsFlag) {
        message.push(this.aditWords);
        noneChkinMessage.push(this.aditWords);
      }
      if (this.smsSendInfo.bizNameLocation === "DOWN") {
        message.push("[HEADER_MEMO]$");
        noneChkinMessage.push("[HEADER_MEMO]$");
      }
      message = message.join("\n");
      noneChkinMessage = noneChkinMessage.join("\n");

      if (this.smsSendInfo.div === "RESERVATION_BATCH") {
        let smsRequestList = [];

        if (contactTel) {
          const item = this.smsSendData[0];
          const block = this.makeSmsInfoTemplate(
            item,
            item.grpNo || item.grpName
              ? message
              : noneChkinMessage
          );
          block.key = 0;
          block.receiverNumber = contactTel;
          smsRequestList.push(block);
          await GolfErpAPI.sendSMS(smsRequestList);
          this.infoToast(this.$t("sms.popupMessage.success"));
        } else {
          const resveNoList =
            this.smsSendData
              ?.filter(item => item.contactTel && item.contactTel !== "01000000000")
              .map(item => item.resveNo);

          const reservationBatchSmsSendData = {
            type: this.smsSendInfo.type,
            resveNoList: resveNoList,
            message: message,
            noneChkinMessage: noneChkinMessage,
            kakaoFlag: this.smsSendInfo.kakaoSendFlag,
            transSmsSendFlag: this.smsSendInfo.transSmsSendFlag,
            smsKind: this.smsSendInfo.kakaoSendFlag
              ? this.smsSendInfo.smsKind
              : null,
            kakaoDispatchKey: this.smsSendInfo.kakaoSendFlag
              ? this.smsSendInfo.kakaoDispatchKey
              : null,
            kakaoTemplateKey: this.smsSendInfo.kakaoSendFlag
              ? this.smsSendInfo.kakaoTemplateCode
              : null,
            reservedFlag: this.smsSendInfo.reservedFlag,
            reserveDateTime:
              this.smsSendInfo.reservedFlag === true
                ? this.smsSendInfo.resveSendDate +
                " " +
                this.smsSendInfo.resveSendTime +
                ":00"
                : null,
            subject: this.smsSendInfo.subject,
            bizNameFlag: this.bizNameFlag,
            dispatchNumber: this.smsSendInfo.dsptchNo.replace(/-/gi, ""),
          };

          console.log('reservationBatchSmsSendData.===>', reservationBatchSmsSendData);

          await GolfErpAPI.sendSmsByReservationBatch(reservationBatchSmsSendData);
        }
      } else if (this.smsSendInfo.div === "DEEP_MEMBER") {
        if (contactTel) {
          let smsRequestList = [];
          const item = this.makeSmsInfoTemplate(this.smsSendData[0], message);
          item.key = 0;
          item.receiverNumber = contactTel;
          smsRequestList.push(item);
          this.showTestSmsSendPopup = false;
          await GolfErpAPI.sendSMS(smsRequestList);
        } else {
          const body = {
            searchTotalMemberOptions: this.searchTotalMemberOptions,
            smsRequest: {
              type: this.smsSendInfo.type,
              message: this.smsSendInfo.aditWordsFlag ? this.aditWords : null,
              sender: "ERP",
              reserveDateTime: this.smsSendInfo.reservedFlag ? this.smsSendInfo.resveSendDate + " " + this.smsSendInfo.resveSendTime + ":00" : null,
              showBizName: this.bizNameFlag
            },
          };

          const {
            data: {
              excludeCount,
              totalCount
            }
          } = await GolfErpAPI.sendSMSByDeepSearchMembers(body);

          if ((totalCount - excludeCount) === 0) {
            this.infoToast("전송된 메세지가 없습니다");
          } else {
            this.infoToast("총 " + totalCount + "건 중 " + (totalCount - excludeCount) + "건의 메세지가 전송되었습니다");
          }
        }
      } else {
        let smsRequestList = [];

        if (contactTel) {
          const item = this.smsSendData[0];
          const block = this.makeSmsInfoTemplate(
            item,
            item.grpNo || item.grpName
              ? message
              : noneChkinMessage
          );
          block.key = 0;
          block.receiverNumber = contactTel;
          smsRequestList.push(block);
        } else {
          this.smsSendData
            ?.filter(item => item.contactTel && item.contactTel !== "01000000000")
            ?.forEach(item => {
              if (!this.smsSendInfo.transSmsSendFlag) {
                if (item.transFlag) {
                  return;
                }
              }
              const pushSmsSend = (block, message, anotherTimeId = null) =>
                smsRequestList.push(this.makeSmsInfoTemplate(block, message, anotherTimeId));
              if (this.smsSendInfo.type === 'CMPN_RESVE') {
                item
                  ?.cmpnInfos
                  ?.filter(i => i.contactTel && i.contactTel !== "01000000000")
                  ?.forEach(i => {
                    const block = JSON.parse(JSON.stringify(item));
                    block.receiverName = i.cmpnName;
                    block.contactTel = i.contactTel;
                    const anotherTimeId = i.reservationConfirm?.timeId || item.timeId;
                    pushSmsSend(block, noneChkinMessage, anotherTimeId);
                  });
              } else {
                pushSmsSend(
                  item,
                  item.grpNo || item.grpName
                    ? message
                    : noneChkinMessage
                );
              }
            });
        }

        await GolfErpAPI.sendSMS(smsRequestList);

        this.infoToast(this.$t("sms.popupMessage.success"));
      }
    },
    makeSmsInfoTemplate(data, message, anotherTimeId = null) {
      const weekdayCncde = commonCodesGetStandardInfo("weekdayCncde");
      const weekendCncde = commonCodesGetStandardInfo("weekendCncde");
      const weekdayCnctime = commonCodesGetStandardInfo("weekdayCnctime");
      const weekendCnctime = commonCodesGetStandardInfo("weekendCnctime");
      return {
        resveId: data.resveId,
        type: this.smsSendInfo.type,
        kakaoFlag: this.smsSendInfo.kakaoSendFlag,
        transSmsSendFlag: this.smsSendInfo.transSmsSendFlag,
        smsKind: this.smsSendInfo.kakaoSendFlag
          ? this.smsSendInfo.smsKind
          : null,
        kakaoDispatchKey: this.smsSendInfo.kakaoSendFlag
          ? this.smsSendInfo.kakaoDispatchKey
          : null,
        kakaoTemplateKey: this.smsSendInfo.kakaoSendFlag
          ? this.smsSendInfo.kakaoTemplateCode
          : null,
        key: data.sendKey,
        reservedFlag: this.smsSendInfo.reservedFlag,
        reserveDateTime:
          this.smsSendInfo.reservedFlag === true
            ? this.smsSendInfo.resveSendDate +
              " " +
              this.smsSendInfo.resveSendTime +
              ":00"
            : null,
        receiverName: data.receiverName,
        receiverNumber: data.contactTel,
        subject: this.smsSendInfo.subject,
        message,
        replaceDataMap: {
          name: data.name,
          resveDate: data.resveDate,
          dwName: data.dwName,
          resveCourse: data.resveCourse,
          resveTime: data.resveTime,
          resveCourseTime: data.resveCourseTime,
          transResveDiv: data.transFlag === true ? "위임예약" : "예약",
          headerMemo: this.bizNameFlag ? this.smsSendInfo.bizName : null,
          cancelDate: moment(data.resveDate).add(data.bsnCode === "WEEKDAY" ? weekdayCncde * -1 : weekendCncde * -1, "d").format("YYYY-MM-DD"),
          cancelDw: getDayOfWeekCaption(moment(data.resveDate).add(data.bsnCode === "WEEKDAY" ? weekdayCncde * -1 : weekendCncde * -1, "d").format("YYYY-MM-DD")),
          cancelTime: data.bsnCode === "WEEKDAY" ? weekdayCnctime : weekendCnctime,
          transInfo: data.transInfo || "",
          grpName: data.grpName,
          teamCnt: `${data.teamCnt}`,
          caddieSelfDiv: data.selfFlag ? "셀프" : "동반",
          day: data.day,
        },
        dispatchNumber: this.smsSendInfo.dsptchNo.replace(/-/gi, ""),
        sender: "ERP",
        timeId: (commonCodesGetStandardInfo("noneChkinUseFlag") && (!data.grpName || anotherTimeId) ? (anotherTimeId || data?.timeId) : null),
        urlName: (commonCodesGetStandardInfo("noneChkinUseFlag") && (!data.grpName || anotherTimeId) ? data?.receiverName : null),
        urlContactTel: (commonCodesGetStandardInfo("noneChkinUseFlag") && (!data.grpName || anotherTimeId) ? data?.contactTel : null),
      };
    },
    onInitButtonClicked() {
      const smsSendType = this.searchOptions.smsSendTypeOptions.find(
        (data) => data.smsSendType === this.popupData.smsSendType
      );

      if (smsSendType) {
        this.smsSendInfo.type = smsSendType.smsSendType;
        this.smsSendInfo.kakaoSendFlag = smsSendType.kakaoSendFlag;
        this.smsSendInfo.kakaoTemplateCode = smsSendType.kakaoTmplatCode;
        this.smsSendInfo.smsKind = smsSendType.smsKind;
        this.smsSendInfo.subject = smsSendType.smsSendTypeName;
      } else {
        this.alert("일치하는 SMS전송타입이 존재하지 않습니다.");
        this.closeBatchSmsSendPopup();
        return;
      }

      this.smsSendInfo.aditWordsFlag = true;
      this.smsSendInfo.transSmsSendFlag = false;
      this.smsSendInfo.reservedFlag = false;
      this.smsSendInfo.resveSendDate = getFormattedDate(new Date());
      this.smsSendInfo.resveSendTime = "00:00";

      this.smsSendTypeSetting();
    },
    onTestSmsSendPopup() {
      this.showTestSmsSendPopup = false;
    },
    async resize() {
      if (this.$refs.aditWords) {
        const container = document.querySelector(".screen-box");
        const saved = container.scrollTop;
        this.$refs.aditWords.style.height = 0;
        this.$refs.aditWords.style.height = `${this.$refs.aditWords?.scrollHeight}px`;
        await this.$nextTick();
        container.scrollTop = saved;
      }
    },
    async onCloseFileUploaderChange(args) {
      try {
        let aditWordsIndexOf = -1;
        if (this.fileInfo.imageId) {
          await GolfErpAPI.deleteImage(this.fileInfo.imageId);

          aditWordsIndexOf = this.aditWords.indexOf("https://");
          if (aditWordsIndexOf >= 0) {
            this.aditWords = this.aditWords.slice(0, aditWordsIndexOf);
          }
          this.fileInfoInit();
        }

        if (args.target.files.length > 0) {
          // 파일을 선택한 경우
          const file = args.target.files[0];

          // 파일사이즈(소수 2번째 자리까지 구함)
          const fileSize = Math.trunc(file.size * 100 / 1024 / 1024) / 100;

          if (fileSize > 10) {
            this.errorToast("파일은 10MB 미만만 첨부 가능합니다");
            return;
          }

          this.fileInfo.path = await encodeBase64(file);
          this.fileInfo.name = file.name;

          const fileUploadData = {
            imageFile: {
              name: this.fileInfo.name,
              path: this.fileInfo.path,
            },
            uploadDiv: this.fileInfo.uploadDiv,
          };

          const rtnData = await GolfErpAPI.putImageUpload(fileUploadData);

          this.fileInfo.imageId = rtnData.imageId;
          this.fileInfo.url = rtnData.imageURL;

          this.aditWords = this.aditWords || '';
          this.aditWords += (aditWordsIndexOf >= 0 ? "" : "\n") + this.fileInfo.url;
          this.$refs.aditWords.focus();
          this.$nextTick(() => {
            this.resize();
          });
        } else {
          // 파일을 선택 안하고 취소 버튼을 누른 경우
          this.fileInfoInit();
        }
      } catch (e) {
        console.error(e);
        this.errorToast("파일 첨부 중 오류가 발생하였습니다");
        this.fileInfoInit();
      }
    },
    fileInfoInit() {
      this.fileInfo.path = null;
      this.fileInfo.name = null;
      this.fileInfo.imageId = null;
      this.fileInfo.url = null;
    },
    onBizNameFlagChanged() {
      if (this.smsSendInfo.bizNameLocation === "UP") {
        if (this.bizNameFlag) {
          this.smsSendInfo.message = "[HEADER_MEMO]$\n" + this.smsSendInfo.message;
        } else {
          this.smsSendInfo.message =
            JSON.stringify(this.smsSendInfo.message).toString().indexOf("[HEADER_MEMO]$\\n") > 0 ?
              JSON.parse(JSON.stringify(this.smsSendInfo.message).replace("[HEADER_MEMO]$\\n", "")) :
              JSON.parse(JSON.stringify(this.smsSendInfo.message).replace("[HEADER_MEMO]$", ""));
        }
      }
    }
  },
};
</script>

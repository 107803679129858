<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="reservationSmsSendPopup"
      :header="`${this.smsSendInfo.type.startsWith('R') || this.smsSendInfo.type.startsWith('P') ? '객실' : '골프'}예약-SMS 전송`"
      allowDragging="true"
      showCloseIcon="true"
      isModal="true"
      width="320"
      height="740"
      :enableResize="true"
      :animationSettings="{ effect: 'None' }"
      @close="onReservationSmsSendPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section
                  class="article-section"
                  :style="{ height: `calc(100% - ${(smsSendContactTelFlag ? (smsSendData.length + (isSmsSendTypeView ? 2 : 1)) * 24 : isSmsSendTypeView ? 48 : 24) + 19}px)` }"
                >
                  <div class="sms-send-box">
                    <div class="preview-top">
                      <div class="speaker-box" />
                      <div class="speaker-small-box" />
                    </div>
                    <div class="preview-middle">
                      <div class="screen-box">
                        <div class="user-profile">
                          <div class="image">{{ username.substr(0, 1) }}</div>
                          <div class="name">{{ username }}</div>
                        </div>
                        <div class="message-box">
                          [Web발신]
                          <br />
                          <textarea
                            ref="aditWords"
                            placeholder="메시지를 입력해주세요"
                            v-model="smsSendInfo.message"
                            :maxlength="maxSmsByteLength"
                            @keyup="resize"
                            @keydown="resize"
                            @focus="resize"
                            spellcheck="false"
                            autofocus
                          />
                        </div>
                        <div
                            class="limit"
                            :style="{
                            color:
                              getByteLength(smsSendInfo.message) >
                              maxSmsByteLength
                                ? 'red'
                                : '#333',
                          }"
                        >
                          {{
                            numberWithCommas(getByteLength(smsSendInfo.message))
                          }}
                          / {{ numberWithCommas(maxSmsByteLength) }} Byte ({{
                            getByteLength(smsSendInfo.message) >= 90
                                ? "LMS"
                                : "SMS"
                          }})
                        </div>
                      </div>
                    </div>
                    <div class="preview-bottom">
                      <div class="button-box" />
                    </div>
                  </div>
                </section>
                <section
                  class="article-section"
                  :style="{ height: `${(smsSendContactTelFlag ? (smsSendData.length + (isSmsSendTypeView ? 2 : 1)) * 24 : isSmsSendTypeView ? 48 : 24) + 19}px` }"
                >
                  <div class="section-body" style="border: none; overflow: hidden !important;">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field" v-if="isSmsSendTypeView" style="width: calc(100% + 1px)">
                              <!-- 필수 : required -->
                              <div class="title">전송타입</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        v-model="smsSendInfo.type"
                                        :dataSource=" searchOptions.smsSendTypeOptions"
                                        :fields="commonCodeFields"
                                        :allowFiltering="false"
                                        cssClass="body-data-dropdown"
                                        @select="onSmsSendTypeSelect"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" style="width: calc(100% + 1px)">
                              <!-- 필수 : required -->
                              <div class="title">발신번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="dsptchNo"
                                        v-model="smsSendInfo.dsptchNo"
                                        disabled
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <div v-if="smsSendContactTelFlag" style="width: 100%;">
                              <li
                                class="field"
                                style="width: calc(100% + 1px)"
                                v-for="(data, idx) in smsSendData"
                                :key="idx"
                              >
                                <!-- 필수 : required -->
                                <div class="title">수신번호{{ idx + 1 }}</div>
                                <div class="content">
                                  <ul class="row">
                                    <li
                                      class="item form-group"
                                      style="width: calc(100% - 25px);"
                                    >
                                      <div class="form">
                                        <component-telephone
                                          ref="contactTel"
                                          v-model="data.contactTel"
                                          :max-length="11"
                                          @keypress.enter="onContactTelEnter($event, idx)"
                                        />
                                      </div>
                                    </li>
                                    <li class="item form-group check">
                                      <div class="form">
                                        <ul class="check">
                                          <li>
                                            <label>
                                              <input
                                                type="checkbox"
                                                v-model="data.smsSendFlag"
                                                value="true"
                                              />
                                              <i></i>
                                            </label>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </div>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  :is-key-color="true"
                  :is-custom-shortcut-button="true"
                  :shortcut-key="'ReservationSmsSendPopup.shortcuts.save'"
                  :shortcut="{key:'F7'}"
                  :ignore="showTestSmsSendPopup"
                  @click.native="onSmsSendButtonClicked"
              >
                전송
              </erp-button>
            </li>
            <li>
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  @click.native="onTestButtonClicked"
              >
                검증
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="onInitButtonClicked"
              >
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  :is-icon-custom="true"
                  @click.native="closeReservationSmsSendPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <test-sms-send-popup
      v-if="showTestSmsSendPopup"
      ref="testSmsSendPopup"
      @smsSend="smsSend"
      @popupClosed="onTestSmsSendPopup"
    />
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import {commonCodesGetCommonCode, commonCodesGetStandardInfo} from "@/utils/commonCodes";
import { getFormattedTelNumber, getByteLength } from "@/utils/string";
import TestSmsSendPopup from "@/components/popups/TestSmsSendPopup";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { mapGetters } from "vuex";
import { SHORTCUT_KEYS } from "@/utils/KeyboardUtil";
import ComponentTelephone from "@/components/ComponentTelephone";
import { numberWithCommas } from "@/utils/number";
export default {
  name: "ReservationSmsSendPopup",
  components: { ComponentTelephone, TestSmsSendPopup, InputText, ErpButton },
  mixins: [commonMixin, confirmDialogMixin],
  watch: {},
  created() {},
  data() {
    return {
      maxSmsByteLength: 2000,
      smsSendInfo: {
        type: "G_RESVE",
        message: null,
        bizName: commonCodesGetStandardInfo("bizName"),
        bizNameLocation: commonCodesGetStandardInfo("bizNameLocation"),
        dsptchNo: null,
        kakaoSendFlag: false,
        smsKind: null,
        kakaoDispatchKey: commonCodesGetStandardInfo("kakaoDsptchKey"),
        kakaoTemplateCode: null,
        subject: null,
      },
      searchOptions: {
        smsSendTypeOptions: [],
      },
      commonCodeFields: { text: "smsSendTypeName", value: "smsSendType" },
      smsWords: null,
      smsSendData: [],
      orgSmsSendData: [],
      smsSendContactTelFlag: null,
      showTestSmsSendPopup: false,
      aditWords: "",
      isSendOneSms: false,
      isSmsSendTypeView: false,
      smsSendTypes: [],
    };
  },
  computed: {
    ...mapGetters(["username"]),
    sendSmsButtonProps() {
      return {
        shortcutKey: "ReservationSmsSendPopup.shortcuts.sendSms",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F7,
        },
      };
    },
  },
  methods: {
    numberWithCommas,
    getByteLength,
    async showReservationSmsSendPopup(popupData, type, smsSendContactTelFlag = true, isSendOneSms = false, isSmsSendTypeView = false, smsSendTypes = []) {
      // 기본 세팅시 중복 연락처 제거
      if(smsSendContactTelFlag) { // 수신번호가 화면에 보일 경우에만 중복제거 처리 (단체 예약 현황 메뉴 같은 경우 중복제거 X)
        let duplicateContactTelCheckList = [];
        for(const data of popupData) {
          !duplicateContactTelCheckList.includes(data.contactTel)
              ? duplicateContactTelCheckList.push(data.contactTel)
              : delete data.contactTel;
        }
        popupData = popupData.filter((info) => info.contactTel);
      }

      // isSendOneSms : true이면 문자 치환이 아닌 보내는 문자 내용 그대로를 보여준다.
      this.smsSendData = popupData;
      this.smsSendInfo.type = type;
      this.orgSmsSendData = JSON.parse(JSON.stringify(popupData));
      this.smsSendContactTelFlag = smsSendContactTelFlag;
      this.isSendOneSms = isSendOneSms;
      this.isSmsSendTypeView = isSmsSendTypeView;
      this.smsSendTypes = smsSendTypes;

      this.smsSendData.forEach(data => {
        data.headerMemo = this.smsSendInfo.bizName;
      });

      await this.fetchAllSmsWords();
    },
    onReservationSmsSendPopupClosed() {
      this.$emit("popupClosed");
    },
    closeReservationSmsSendPopup() {
      this.$refs.reservationSmsSendPopup.hide();
    },
    async smsSendTypeSetting() {
      const smsSendType = this.smsWords.find(
        (data) => data.smsSendType === this.smsSendInfo.type
      );
      this.smsSendInfo.message = smsSendType.smsWords;
      this.aditWords = smsSendType.aditWords || "";
      this.smsSendInfo.kakaoSendFlag = smsSendType.kakaoSendFlag;
      this.smsSendInfo.kakaoTemplateCode = smsSendType.kakaoTmplatCode;
      this.smsSendInfo.smsKind = smsSendType.smsKind;
      this.smsSendInfo.subject = smsSendType.smsSendTypeName;
      this.smsSendInfo.dsptchNo = getFormattedTelNumber(smsSendType.dsptchNo);
      if (this.$refs.aditWords) {
        this.$refs.aditWords.focus();
      }

      if (this.isSendOneSms) {
        // 화면에 전체 내용 편집 가능하도록 기존 message 내용에 aditWords 및 하단 사업장명을 합쳐서 보여준다.
        if (this.smsSendInfo.bizNameLocation === "DOWN") {
          this.smsSendInfo.message =
            JSON.stringify(this.smsSendInfo.message).toString().indexOf("[HEADER_MEMO]$\\n") > 0 ?
            JSON.parse(JSON.stringify(this.smsSendInfo.message).replace("[HEADER_MEMO]$\\n", "")) :
            JSON.parse(JSON.stringify(this.smsSendInfo.message).replace("[HEADER_MEMO]$", ""));
          this.aditWords += "\n" + this.smsSendInfo.bizName;
        }
        const smsVariables = commonCodesGetCommonCode("SMS_VRIABL", true);
        smsVariables.forEach(smsVariable => {
          if (smsVariable.comName) {
            this.smsSendInfo.message =
              this.smsSendInfo.message.replace(smsVariable.comCode, this.smsSendData[0][smsVariable.comName] ? this.smsSendData[0][smsVariable.comName] : "");

            if (smsVariable.comName === "headerMemo") {
              if (!this.smsSendData[0][smsVariable.comName]) {
                this.smsSendInfo.message = JSON.parse(JSON.stringify(this.smsSendInfo.message).replace("\\n", ""));
              }
            }
          }
        });

        if (this.smsSendInfo.type.startsWith("G")) {
          this.smsSendInfo.message +=
            "\n" + (commonCodesGetStandardInfo("noneChkinUseFlag") ? commonCodesGetStandardInfo("noneChkinSmsWords") + "\n" : "") + this.aditWords;
        } else {
          this.smsSendInfo.message += this.aditWords ? "\n" + this.aditWords : this.aditWords;
        }

        if (
          commonCodesGetStandardInfo("noneChkinUseFlag") &&
          this.smsSendData[0].urlName &&
          this.smsSendData[0].urlResveId
        ) {
          const selfCheckInUrl =
            await GolfErpAPI.fetchSelfCheckInUrl({
              name: this.smsSendData[0].urlName,
              resveId: this.smsSendData[0].urlResveId,
              contactTel: this.smsSendData[0].contactTel
            });

          this.smsSendInfo.message = this.smsSendInfo.message.replace("[SELF_CHECKIN]$", selfCheckInUrl);
          this.smsSendInfo.message = this.smsSendInfo.message.replaceAll("[FRONT_NAME]$", commonCodesGetStandardInfo("noneChkinName"));
        }
      } else {
        // 화면에 전체 내용 편집 가능하도록 기존 message 내용에 aditWords 및 하단 사업장명을 합쳐서 보여준다.
        if (this.smsSendInfo.bizNameLocation === "DOWN") {
          this.smsSendInfo.message =
            JSON.stringify(this.smsSendInfo.message).toString().indexOf("[HEADER_MEMO]$\\n") > 0 ?
            JSON.parse(JSON.stringify(this.smsSendInfo.message).replace("[HEADER_MEMO]$\\n", "")) :
            JSON.parse(JSON.stringify(this.smsSendInfo.message).replace("[HEADER_MEMO]$", ""));
          this.aditWords += "\n" + "[HEADER_MEMO]$";
        }
        this.smsSendInfo.message += "\n" + (commonCodesGetStandardInfo("noneChkinUseFlag") ? commonCodesGetStandardInfo("noneChkinSmsWords") + "\n" : "") + this.aditWords;
      }

      this.$nextTick(() => {
        this.resize();
      });
    },
    async fetchAllSmsWords() {
      this.smsWords = await GolfErpAPI.fetchAllSmsWords();

      this.searchOptions.smsSendTypeOptions = this.smsWords.filter(item => this.smsSendTypes.length > 0 ? this.smsSendTypes.includes(item.smsSendType) : true);

      this.smsSendTypeSetting();
    },
    async onSmsSendButtonClicked() {
      let smsSendCount = 0;
      this.smsSendData.forEach((data) => {
        if (data.smsSendFlag && data.contactTel) {
          smsSendCount++;
        }
      });

      if (smsSendCount <= 0) {
        return this.errorToast(
          this.$t("sms.popupMessage.checkSmsSendNotExists")
        );
      }

      if (!(await this.confirm("SMS 전송하시겠습니까?"))) {
        return;
      }

      await this.smsSend();
    },
    async onTestButtonClicked() {
      let smsSendCount = 0;
      this.smsSendData.forEach((data) => {
        if (data.smsSendFlag) {
          smsSendCount++;
        }
      });

      if (smsSendCount <= 0) {
        return this.errorToast(
          this.$t("sms.popupMessage.checkSmsSendNotExists")
        );
      }

      this.showTestSmsSendPopup = true;
      this.$nextTick(() => {
        this.$refs.testSmsSendPopup.showPopup();
      });
    },
    async smsSend(contactTel = null) {
      let message = [];
      if (this.smsSendInfo.message) {
        message.push(this.smsSendInfo.message);
      }
      // 화면에 전체 내용 편집 가능하도록 기존 message내용에 aditWords를 합쳐서 보여주고 있어 aditWords는 message에 push하지 않는다.
      // message.push(this.aditWords);
      // if (this.smsSendInfo.bizName) {
      //   if (this.smsSendInfo.bizNameLocation !== "UP") {
      //     message.push(this.smsSendInfo.bizName);
      //   }
      // }
      message = message.join("\n\n");

      let smsRequestList = [];

      if (contactTel) {
        const item = this.makeSmsInfoTemplate(this.smsSendData[0], message);
        item.key = 0;
        item.receiverNumber = contactTel;
        smsRequestList.push(item);
        this.showTestSmsSendPopup = false;
      } else {
        this.smsSendData.forEach((data) => {
          if (data.smsSendFlag && data.contactTel) {
            smsRequestList.push(this.makeSmsInfoTemplate(data, message));
          }
        });
      }

      await GolfErpAPI.sendSMS(smsRequestList);
      this.infoToast(this.$t("sms.popupMessage.success"));
    },
    makeSmsInfoTemplate(data, message) {
      return {
        type: this.smsSendInfo.type,
        kakaoFlag: this.smsSendInfo.kakaoSendFlag,
        smsKind: this.smsSendInfo.kakaoSendFlag
          ? this.smsSendInfo.smsKind
          : null,
        kakaoDispatchKey: this.smsSendInfo.kakaoSendFlag
          ? this.smsSendInfo.kakaoDispatchKey
          : null,
        kakaoTemplateKey: this.smsSendInfo.kakaoSendFlag
          ? this.smsSendInfo.kakaoTemplateCode
          : null,
        key: data.sendKey,
        reservedFlag: false,
        reserveDateTime: null,
        receiverName: data.receiverName,
        receiverNumber: data.contactTel,
        subject: this.smsSendInfo.subject,
        message,
        replaceDataMap: {
          name: data.name,
          resveDate: data.resveDate,
          dwName: data.dwName,
          resveCourse: data.resveCourse,
          resveTime: data.resveTime,
          resveCourseTime: data.resveCourseTime,
          transResveDiv: data.transFlag === true ? "위임예약" : "예약",
          cancelDate: data.cancelDate,
          cancelTime: data.cancelTime,
          headerMemo: data.headerMemo,
          grpName: data.grpName,
          teamCnt: `${data.teamCnt}팀`,
          holeDiv: data.holeDiv,
          visitCnt: data.visitCnt,
        },
        dispatchNumber: this.smsSendInfo.dsptchNo.replace(/-/gi, ""),
        sender: "ERP",
        timeId: data.timeId,
        urlName: data.urlName,
        urlContactTel: data.urlContactTel,
        urlResveId: data.urlResveId,
      };
    },
    onInitButtonClicked() {
      this.smsSendData = JSON.parse(JSON.stringify(this.orgSmsSendData));
      this.smsSendTypeSetting();
    },
    onTestSmsSendPopup() {
      this.showTestSmsSendPopup = false;
    },
    async resize() {
      if (this.$refs.aditWords) {
        const container = document.querySelector(".screen-box");
        const saved = container.scrollTop;
        this.$refs.aditWords.style.height = 0;
        this.$refs.aditWords.style.height = `${this.$refs.aditWords?.scrollHeight}px`;
        await this.$nextTick();
        container.scrollTop = saved;
      }
    },
    onSmsSendTypeSelect(args) {
      this.smsSendInfo.type = args.itemData.smsSendType;
      this.smsSendTypeSetting();
    },
    async onContactTelEnter(args, idx) {
      const isLastIdx = (this.smsSendData.length-1 == idx);
      const isBlankContactTel = (this.smsSendData.filter((data) => !data.contactTel).length > 0);
      if(isLastIdx && !isBlankContactTel) {
        // 추가된 수신정보는 첫번째 값으로 세팅
        this.smsSendData.push({
          ...this.smsSendData[0],
          contactTel: "",
          smsSendFlag: true,
        });
      }

      if(this.smsSendData.length-1 != idx) {
        await this.$nextTick();
        this.$refs["contactTel"][idx + 1].focus();
      }
    },
  },
};
</script>

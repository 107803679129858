import { v4 as createUUID } from "uuid";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import moment from "moment";
import {
  clearLoginEmail,
  removeAccessToken,
  saveAccessToken,
  saveLoginEmail,
} from "@/utils/session/SessionUtil";
import router from "@/router";

class GolfERPService {
  #api;

  constructor(api) {
    this.#api = api;
  }

  async login(email, password, {
    keepLoginEmail = false,
  }) {
    const { status, accessToken } = await this.#api.login(email, password);

    saveAccessToken(accessToken);
    if (keepLoginEmail) {
      saveLoginEmail(email);
    } else {
      clearLoginEmail();
    }

    return status;
  }

  async logout(
    { redirect, isSessionExpired } = {
      redirect: router.currentRoute.path,
      isSessionExpired: false,
    }
  ) {
    try {
      await this.#api.logout();
    } finally {
      sessionStorage.removeItem("Standard-Infos");
      sessionStorage.removeItem("Biz-Mst-Info");
      sessionStorage.removeItem("Common-Codes");
      sessionStorage.removeItem("Biz-Codes");
      sessionStorage.removeItem("User-Infos");
      sessionStorage.removeItem("Current-Biz-Code");
      removeAccessToken();

      if (router.currentRoute.name !== "login") {
        router.push({
          name: isSessionExpired ? "SessionExpired" : "login",
          query: {
            redirect,
          },
        });
      }
    }
  }

  async fetchNow() {
    return moment(await this.#api.fetchNow());
  }

  async fetchBusinessPlaces() {
    return await this.#api.fetchBusinessPlaces();
  }

  async fetchWorkPlaces() {
    return await this.#api.fetchWorkPlaces();
  }

  async fetchSystemVersion() {
    return await this.#api.fetchSystemVersion();
  }

  async fetchUsers({ q, bizCode, authGroupId, filters, orders }) {
    return await this.#api.fetchUsers({
      q,
      bizCode,
      authGroupId,
      filters,
      orders,
    });
  }

  async fetchAuthorities() {
    return await this.#api.fetchAuthorities();
  }

  async fetchAuthorityGroupAuthorities(groupId) {
    return await this.#api.fetchAuthorityGroupAuthorities(groupId);
  }

  async fetchAuthorityGroupUsers(groupId) {
    return await this.#api.fetchAuthorityGroupUsers(groupId);
  }

  async createAuthorityGroup(id, name, description, order) {
    return await this.#api.createAuthorityGroup(id, name, description, order);
  }

  async updateAuthorityGroup(groupId, name, description, order) {
    return await this.#api.updateAuthorityGroup(
      groupId,
      name,
      description,
      order
    );
  }

  async updateAuthorityGroupAuthorities(groupId, authorizations) {
    return await this.#api.updateAuthorityGroupAuthorities(
      groupId,
      authorizations
    );
  }

  async updateAuthorityGroupUsers(groupId, users) {
    return await this.#api.updateAuthorityGroupUsers(groupId, users);
  }

  async deleteAuthorityGroup(groupId) {
    return await this.#api.deleteAuthorityGroup(groupId);
  }

  async fetchProductClasses(depth, parentCode) {
    return await this.#api.fetchProductClasses(depth, parentCode);
  }

  async fetchStores(usingPos) {
    return await this.#api.fetchStores(usingPos);
  }

  async fetchStoreRootProductCategory(storeCode) {
    return await this.#api.fetchStoreProductCategory(storeCode);
  }

  async fetchStoreChildrenProductCategory(storeCode, parentCategoryCode) {
    return await this.#api.fetchStoreProductCategory(
      storeCode,
      parentCategoryCode
    );
  }

  async fetchStoreProducts(storeCode, categoryCode, salesDate) {
    return await this.#api.fetchStoreProducts(
      storeCode,
      categoryCode,
      salesDate
    );
  }

  async fetchPosTables(storeCode, salesDate) {
    return await this.#api.fetchPosTables(storeCode, salesDate);
  }

  async fetchNextSlipNo(salesDate, storeCode) {
    return await this.#api.fetchNextSlipNo(salesDate, storeCode);
  }

  async fetchSalesSlipMemos(salesId) {
    return await this.#api.fetchSalesSlipMemos(salesId);
  }

  async fetchSalesSlipPayments(salesId, gcDiv) {
    return await this.#api.fetchSalesSlipPayments(salesId, gcDiv);
  }

  async createSalesSlipMemo(salesId, memoContents) {
    return await this.#api.createSalesSlipMemo(salesId, memoContents);
  }

  async updateSalesSlipStoreTable(salesId, storeCode, salesDate, tableId) {
    return await this.#api.updateSalesSlipStoreTable(
      salesId,
      storeCode,
      salesDate,
      tableId
    );
  }

  async updateSalesSlipSales(salesId, storeCode, salesDate, sales) {
    return await this.#api.updateSalesSlipSales(
      salesId,
      storeCode,
      salesDate,
      sales
    );
  }

  async updateSalesSlipVisitorsCount(salesId, visitorsCount) {
    return await this.#api.updateSalesSlipVisitorsCount(salesId, visitorsCount);
  }

  async updateSalesSlipVisitor(salesId, visitorId, bsnDate) {
    return await this.#api.updateSalesSlipVisitor(salesId, visitorId, bsnDate);
  }

  async deleteSalesSlip(salesId, deleteReason, bsnDate, storeCode) {
    return await this.#api.deleteSalesSlip(
      salesId,
      deleteReason,
      bsnDate,
      storeCode
    );
  }

  async deleteSalesSlipMemo(salesId, memoId) {
    return await this.#api.deleteSalesSlipMemo(salesId, memoId);
  }

  async createSalesSlip(
    slipNo,
    tableId,
    visitorsCount,
    visitorId,
    salesDate,
    storeCode,
    sales,
    posNo
  ) {
    return await this.#api.createSalesSlip(
      slipNo,
      tableId,
      visitorsCount,
      visitorId,
      salesDate,
      storeCode,
      sales,
      posNo
    );
  }

  async fetchSlips(storeCode, salesDate, gcDiv, filters, orders) {
    return await this.#api.fetchSlips(storeCode, salesDate, gcDiv, filters, orders);
  }

  async storeCheckout(storeCode, salesDate, visitorId, salesId) {
    await this.#api.storeCheckout(storeCode, salesDate, visitorId, salesId);
  }

  async registerStoreProducts(storeCode, products) {
    await this.#api.registerStoreProducts(storeCode, products);
  }

  async removeStoreProducts(storeCode, products) {
    await this.#api.removeStoreProducts(storeCode, products);
  }

  async fetchSalesSlip(salesId) {
    return await this.#api.fetchSalesSlip(salesId);
  }

  async fetchProductPriceUnits(productCode, salesDate, storeCode) {
    return await this.#api.fetchProductPriceUnits(
      productCode,
      salesDate,
      storeCode
    );
  }

  async fetchTeams(
    salesDate,
    storeCode,
    searchText,
    isCheckOut,
    courseCode,
    partDiv
  ) {
    return await this.#api.fetchTeams(
      salesDate,
      storeCode,
      searchText,
      isCheckOut,
      courseCode,
      partDiv
    );
  }

  async fetchVisitorBills(salesDate, storeCode, visitorId, gcDiv) {
    return await this.#api.fetchVisitorBills(salesDate, storeCode, visitorId, gcDiv);
  }

  async getPlacesOfCardPurchase(vanKind) {
    return await this.#api.getPlacesOfCardPurchase(vanKind);
  }
  async fetchAccountCardCompanies() {
    return await this.#api.fetchAccountCardCompanies();
  }
  async sendSMS(newSMSRequestList) {
    return await this.#api.sendSMS(newSMSRequestList);
  }

  async fetchCreditCardReports(payerName, dateDivision, dateFrom, dateTo, filterOptions) {
    return await this.#api.fetchCreditCardReports(
      payerName,
      dateDivision,
      dateFrom,
      dateTo,
      filterOptions
    );
  }

  async fetchCashReceiptsReports(
    payerName,
    dateDivision,
    dateFrom,
    dateTo,
    filterOptions
  ) {
    return await this.#api.fetchCashReceiptsReports(
      payerName,
      dateDivision,
      dateFrom,
      dateTo,
      filterOptions
    );
  }

  async postPay(payRequest) {
    return await this.#api.postPay(payRequest);
  }

  async fetchDeposit(args) {
    return await this.#api.fetchDeposit(args);
  }

  async fetchDepositsBalanceStatus(condition, showEmptyBalanceAmount) {
    return await this.#api.fetchDepositsBalanceStatus(
      condition,
      showEmptyBalanceAmount
    );
  }

  async postPayTransaction(payTransactionRequest) {
    return await this.#api.postPayTransaction(payTransactionRequest);
  }

  async getPayTransaction(tid) {
    return await this.#api.getPayTransaction(tid);
  }

  async fetchCashReceipts(payDateFrom, payDateTo, approvalNo, proofNo) {
    return await this.#api.fetchCashReceipts(
      payDateFrom,
      payDateTo,
      approvalNo,
      proofNo
    );
  }

  async fetchCashReceipt(id) {
    return await this.#api.fetchCashReceipt(id);
  }

  async postPayCashReceipt(payCashReceiptRequest) {
    return await this.#api.postPayCashReceipt(payCashReceiptRequest);
  }

  async patchPayCashReceipt(cashId, tid) {
    return await this.#api.patchPayCashReceipt(cashId, tid);
  }

  async fetchPayTax(taxRequest) {
    return await this.#api.fetchPayTax(taxRequest);
  }

  /**
   *
   * @param notaxCode
   * @param totalAmount
   * @return {Promise<*>}
   */
  async fetchDepositIncomeTax(notaxCode, totalAmount) {
    return await this.#api.fetchDepositIncomeTax(notaxCode, totalAmount);
  }

  downloadExcelMemberRegistration({
    q,
    corporationDivision,
    memberDivision,
    memberGrade,
    corporationName,
    endOfHpNumber,
    hpNumber,
    webMemberId,
    hasWebInfo,
    groupCompanyCode,
    affltsCompanyCode,
    smsReceiptDivision,
    membershipStatus,
    acquireDateFrom,
    acquireDateTo,
    expireDateFrom,
    expireDateTo,
    birthday,
    gender,
    isOut,
    filters,
    orders,
    columns,
  }) {
    const uuid = createUUID();

    const promise = GolfErpAPI.downloadExcelMemberRegistration(
      {
        q,
        corporationDivision,
        memberDivision,
        memberGrade,
        corporationName,
        endOfHpNumber,
        hpNumber,
        webMemberId,
        hasWebInfo,
        groupCompanyCode,
        affltsCompanyCode,
        smsReceiptDivision,
        membershipStatus,
        acquireDateFrom,
        acquireDateTo,
        expireDateFrom,
        expireDateTo,
        birthday,
        gender,
        isOut,
        filters,
        orders,
        columns,
      },
      { interruptTokenId: uuid }
    );

    const interrupt = (message) => {
      GolfErpAPI.interrupt(uuid, message);
    };

    return {
      promise,
      interrupt,
    };
  }
  downloadExcelWebMemberRegistration({
    q,
    endOfHpNumber,
    webMemberId,
    acquireDateFrom,
    acquireDateTo,
    gender,
    filters,
    orders,
    columns,
  }) {
    const uuid = createUUID();

    const promise = GolfErpAPI.downloadExcelWebMemberRegistration(
      {
        q,
        endOfHpNumber,
        webMemberId,
        acquireDateFrom,
        acquireDateTo,
        gender,
        filters,
        orders,
        columns,
      },
      { interruptTokenId: uuid }
    );

    const interrupt = (message) => {
      GolfErpAPI.interrupt(uuid, message);
    };

    return {
      promise,
      interrupt,
    };
  }
  downloadExcelMembers({
    memberDivisions,
    memberGrades,
    annualFeeFlag,
    memberName,
    memberNumber,
    gender,
    birthYear,
    birthMonth,
    birthDay,
    ageCodes,
    endOfHpNumber,
    allowSmsReceiptDivision,
    allowReceiptEmail,
    corporationDivision,
    corporationName,
    workPlace,
    acquireDateFrom,
    acquireDateTo,
    loginCount,
    expireDate,
    isOut,
    membershipStatus,
    entryOrders,
    nearbyCodes,
    areaCodes,

    reservationDateFrom,
    reservationDateTo,
    reservationTimeFrom,
    reservationTimeTo,
    reservationBsnCode,
    reservationDayOfWeeks,
    reservationCount,
    reservationChannels,
    excludeExistReservationDateFrom,
    excludeExistReservationDateTo,
    excludePenaltyReservationDateFrom,
    excludePenaltyReservationDateTo,
    reservationKinds,

    visitDateFrom,
    visitDateTo,
    visitTimeFrom,
    visitTimeTo,
    visitBsnCode,
    visitDayOfWeeks,
    visitCount,
    paymentCodes,

    salesDateFrom,
    salesDateTo,
    salesBsnCode,
    storeCodes,
    fitOrTeamSalesDivision,
    salesAmount,
    unitPrice,

    excludeMembersByMembershipId,

    filters,
    orders,
    columns,
  }) {
    const uuid = createUUID();

    const promise = GolfErpAPI.downloadExcelMembers(
      {
        memberDivisions,
        memberGrades,
        annualFeeFlag,
        memberName,
        memberNumber,
        gender,
        birthYear,
        birthMonth,
        birthDay,
        ageCodes,
        endOfHpNumber,
        allowSmsReceiptDivision,
        allowReceiptEmail,
        corporationDivision,
        corporationName,
        workPlace,
        acquireDateFrom,
        acquireDateTo,
        loginCount,
        expireDate,
        isOut,
        membershipStatus,
        entryOrders,
        nearbyCodes,
        areaCodes,

        reservationDateFrom,
        reservationDateTo,
        reservationTimeFrom,
        reservationTimeTo,
        reservationBsnCode,
        reservationDayOfWeeks,
        reservationCount,
        reservationChannels,
        excludeExistReservationDateFrom,
        excludeExistReservationDateTo,
        excludePenaltyReservationDateFrom,
        excludePenaltyReservationDateTo,
        reservationKinds,

        visitDateFrom,
        visitDateTo,
        visitTimeFrom,
        visitTimeTo,
        visitBsnCode,
        visitDayOfWeeks,
        visitCount,
        paymentCodes,

        salesDateFrom,
        salesDateTo,
        salesBsnCode,
        storeCodes,
        fitOrTeamSalesDivision,
        salesAmount,
        unitPrice,

        excludeMembersByMembershipId,

        filters,
        orders,
        columns,
      },
      { interruptTokenId: uuid }
    );

    const interrupt = (message) => {
      GolfErpAPI.interrupt(uuid, message);
    };

    return {
      promise,
      interrupt,
    };
  }
  downloadExcelMemberGradeManagement({
    bsnDateFrom,
    bsnDateTo,
    visitCnt,
    memberGrades,
    membershipId,
    memberName,
    memberGradeChanges,
    filters,
    orders,
    columns,
  }) {
    const uuid = createUUID();

    const promise = GolfErpAPI.downloadExcelMemberGradeManagement(
      {
        bsnDateFrom,
        bsnDateTo,
        visitCnt,
        memberGrades,
        membershipId,
        memberName,
        memberGradeChanges,
        filters,
        orders,
        columns,
      },
      { interruptTokenId: uuid }
    );

    const interrupt = (message) => {
      GolfErpAPI.interrupt(uuid, message);
    };

    return {
      promise,
      interrupt,
    };
  }
  downloadExcelMemberGradeChanges({
    changeDateFrom,
    changeDateTo,
    memberGrades,
    changeMemberGradeDivisions,
    memberName,
    membershipId,
    isCouponPub,
    filters,
    orders,
    columns,
  }) {
    const uuid = createUUID();

    const promise = GolfErpAPI.downloadExcelMemberGradeChanges(
      {
        changeDateFrom,
        changeDateTo,
        memberGrades,
        changeMemberGradeDivisions,
        memberName,
        membershipId,
        isCouponPub,
        filters,
        orders,
        columns,
      },
      { interruptTokenId: uuid }
    );

    const interrupt = (message) => {
      GolfErpAPI.interrupt(uuid, message);
    };

    return {
      promise,
      interrupt,
    };
  }

  downloadExcelSearchReservationConditions(args) {
    const uuid = createUUID();

    const promise = GolfErpAPI.downloadExcelSearchReservationConditions(
      args,
      { interruptTokenId: uuid }
    );

    const interrupt = (message) => {
      GolfErpAPI.interrupt(uuid, message);
    };

    return {
      promise,
      interrupt,
    };
  }
}

export default new GolfERPService(GolfErpAPI);

<template>
  <div>
    <ejs-dialog
        ref="dialog"
        header="상품 재고 검색"
        width="1500"
        height="800"
        :allowDragging="true"
        :showCloseIcon="true"
        :isModal="true"
        :close="onClickClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">상품명</div>
                    <ul class="content">
                      <li class="item">
                        <input-text ref="productName" id="productName" v-model="searchFilterOptions.productName" @keydown.enter="onClickSearch()"/>
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title"> 출고 창고</div>
                    <ul class="content">
                      <li class="item">
                        <ejs-dropdownlist ref="wrhous" v-model="searchFilterOptions.wrhousCode"
                                         :dataSource="commonCodeOptions.wrhousData" :fields="commonCodeOptions.fields"
                                         :allowFiltering="false" cssClass="lookup-condition-multiselect" width="250" :readonly="true"/>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button button-div="GET" :is-shortcut-button="true" @click.native="onClickSearch()">조회</erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <div class="article-section" style="height: 360px;">
                  <div class="section-body" style="border: none;">
                    <div class="article-left" style="width: 20%;">
                      <div class="section-body">
                      <div class="body-box control_wrapper">
                        <ejs-treeview
                            ref="treeView"
                            id="treeView"
                            :fields="treeField.fields"
                            :showCheckBox='false'
                            :selectedNodes="treeField.selectTreeView"
                            :nodeSelected = "onNodeSelected"
                            :loadOnDemand="false"
                        />
                      </div>
                      </div>
                    </div>
                    <div class="article-right" style="width: 79%;">
                      <div class="body-grid">
                        <ejs-grid-wrapper
                            ref="grid"
                            v-bind ="initGrid"
                            @queryCellInfo="onQueryCellInfo"
                            @headerCellInfo="onHeaderCellInfo"
                            @actionComplete="gridComplete"
                            @onGridDialogDoubleClickedOrEnterKeyed="onDoubleClickedOrEnterKeyed"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="article-section" style="margin-top: 30px; height:calc(100% - 390px)">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">선택된 상품</div>
                        <div class="header-caption">[ {{ gridField2.count }} 건 ]</div>
                      </div>
                      <div class ="header-right">
                        <ul class="header-button">
                          <li class="add">
                            <erp-button button-div="SAVE" :is-icon-custom="true" @click.native="onClickAdd">추가</erp-button>
                          </li>
                          <li class="delete">
                            <erp-button button-div="DELETE" @click.native="onClickDel">삭제</erp-button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="section-body">
                      <div class="body-grid">
                        <ejs-grid-wrapper
                            ref="grid2"
                            v-bind ="initGrid2"
                            @queryCellInfo="onQueryCellInfo2"
                            @headerCellInfo="onHeaderCellInfo2"
                            @actionComplete="gridComplete2"
                        />
                      </div>
                    </div>
                  </div>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button button-div="SAVE" :is-icon-custom="true" @click.native="onClickConfirm">확인</erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="onClickClose">닫기</erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {ExcelExport, Filter, ForeignKey, Page, Resize, Edit} from "@syncfusion/ej2-vue-grids";
import InputText from "@/components/common/text/InputText.vue";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton.vue";
import {commonCodesGetCommonCode} from "@/utils/commonCodes";
import StockCommon from "@/api/v2/StockManage/StockCommon";

export default {
  name: "productStockSearchPopup",
  mixins: [confirmDialogMixin],
  components: {
    InputText,
    ejsGridWrapper,
    ErpButton,
  },
  props:["wrhousCode"] ,//부모창에서 받아온 출고창고 코드

  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
    // 콤보 갖고오기....
  },

  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      gridField: {
        title: "상품 목록",
        count : 0,
        dataSource: [],
      },
      gridField2: {
        title: "선택된 상품",
        count : 0,
        dataSource: [],
      },
      searchFilterOptions: {
        productName: null,
        classCode: null,
        useFlag: 1,
        wrhousCode: null,
        closeYm: null,
      },
      //드롭다운 코드
      commonCodeOptions: {
        taxDiv: commonCodesGetCommonCode("NOTAX_CODE",false,true,"",""),//NOTAX_CODE,TAX_DIV
        wrhousData: [],
        fields: { text: "comName", value: "comCode" },
      },

      selectedRowData: {},
      treeField: {
        fields:{},
        selectTreeView: [],
      },
      treeData: [],
    };
  },

  async mounted() {
    //창고콤보(권한있는 상품창고만)
    this.commonCodeOptions.wrhousData  = await StockCommon.getWrhous({useFlag:'Y',authoFlag:'Y',suppliesFlag:'N'});
    //마감월 정보
    this.searchFilterOptions.closeYm =  await StockCommon.getLastProductClosingYm();
    //창고코드 세팅
    this.searchFilterOptions.wrhousCode =  this.wrhousCode;


    //상품 분류 트리 조회
    await this.treeviewSearch();
    //상품 재고 리스트 조회
    await this.onClickSearch();
    this.$refs["productName"].focus();
  },

  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    //그리드 세팅
    initGrid(){
      return{
        columns:  [
          {field: 'pathDesc',       textAlign: 'left',      width: 150,  visible: true,   allowEditing : false,  type: 'string',   headerText: '분류'  },
          {field: 'productName',    textAlign: 'center',    width: 150,  visible: true,   allowEditing : false,  type: 'string',   headerText: '상품명'  },
          {field: 'productCode',    textAlign: 'center',    width: 80,   visible: true,   allowEditing : false,  type: 'string',   headerText: '상품코드' },
          {field: 'standard',       textAlign: 'center',    width: 60,   visible: true,   allowEditing : false,  type: 'string',   headerText: '규격'  },
          {field: 'unit',           textAlign: 'left',      width: 50,   visible: true,   allowEditing : false,  type: 'string',   headerText: '단위' },
          {field: 'price',          textAlign: 'right',     width: 80,   visible: true,   allowEditing : false,  type: 'number',   headerText: '최근입고가', isNumericType: true, },
          {field: 'wrhousCode',     textAlign: 'center',    width: 80,   visible: true,   allowEditing : false,  type: 'string',   headerText: '창고', isCommonCodeField: true, dataSource: this.commonCodeOptions.wrhousData,  },
          {field: 'wrhousName',     textAlign: 'center',    width: 80,   visible: false,  allowEditing : false,  type: 'string',   headerText: '창고'},
          {field: 'stockQty',       textAlign: 'right',     width: 60,   visible: true,   allowEditing : false,  type: 'number',   headerText: '현재고', isNumericType: true,  },
          {field: 'taxDiv',         textAlign: 'center',    width: 60,   visible: true,   allowEditing : false,  type: 'string',   headerText: '면/과세', isCommonCodeField: true, dataSource: this.commonCodeOptions.taxDiv,},
          {field: 'autoIeFlag',     textAlign: 'center',    width: 60,   visible: true,   allowEditing : false,  type: 'boolean',  headerText: '자동입출',  displayAsCheckBox: true, editType: "booleanedit" },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:true,
        noColumnWidth: 35,
        isSelectedRowRetain: false,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: true,
        isInPopup:true,
        pageSettings: { pageSize: 12 },
      };
    },
    //그리드 세팅
    initGrid2(){
      return{
        columns:  [
          {field: 'pathDesc',       textAlign: 'left',      width: 150,  visible: true,   allowEditing : false,  type: 'string',   headerText: '분류'  },
          {field: 'productName',    textAlign: 'center',    width: 150,  visible: true,   allowEditing : false,  type: 'string',   headerText: '상품명'  },
          {field: 'productCode',    textAlign: 'center',    width: 80,   visible: true,   allowEditing : false,  type: 'string',   headerText: '상품코드' },
          {field: 'standard',       textAlign: 'center',    width: 60,   visible: true,   allowEditing : false,  type: 'string',   headerText: '규격'  },
          {field: 'unit',           textAlign: 'left',      width: 50,   visible: true,   allowEditing : false,  type: 'string',   headerText: '단위' },
          {field: 'price',          textAlign: 'right',     width: 80,   visible: true,   allowEditing : false,  type: 'number',   headerText: '최근입고가', isNumericType: true, },
          {field: 'wrhousCode',     textAlign: 'center',    width: 80,   visible: true,   allowEditing : false,  type: 'string',   headerText: '창고', isCommonCodeField: true, dataSource: this.commonCodeOptions.wrhousData,  },
          {field: 'wrhousName',     textAlign: 'center',    width: 80,   visible: false,  allowEditing : false,  type: 'string',   headerText: '창고',  },
          {field: 'stockQty',       textAlign: 'right',     width: 60,   visible: true,   allowEditing : false,  type: 'number',   headerText: '현재고', isNumericType: true,},
          {field: 'dlivyQty',       textAlign: 'right',     width: 60,   visible: true,   allowEditing : true,   type: 'number',   headerText: '수량',
            isNumericType: true,  inputNumberProperty: {allowDot:true ,maxUnderDotLength: 3 },}, // editType:'numericedit', format: 'N2', // 그리드에서 버튼으로 숫자 조정시 세팅
          {field: 'taxDiv',         textAlign: 'center',    width: 60,   visible: true,   allowEditing : false,  type: 'string',   headerText: '면/과세', isCommonCodeField: true, dataSource: this.commonCodeOptions.taxDiv,},
          //{field: 'useFlag',        textAlign: 'center',    width: 60,   visible: true,   allowEditing : false,  type: 'boolean',  headerText: '사용여부',
          //  displayAsCheckBox: true, editType: "booleanedit" },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField2.dataSource,

        allowResizing: true,
        //allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        isInPopup:true,
        //allowPaging: true,
        //pageSettings: { pageSize: 50 },
      };
    },

  },

  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
    //region ******************************* init  *******************************

    //상품 분류 조회
    async treeviewSearch() {
      this.treeData = await StockCommon.getProductClassList({useFlag:'Y'});
      this.treeData.forEach((item)=>{
        item.hasChild = true; //무조건 자식 있음, 실제 하위데이터 없으면 개행안됨.
      });
      //최상위 분류 추가
      this.treeData.push({classCode:'0', parentCode:null, classCodeName:'상품 분류', hasChild:true, expanded: true});
      this.treeField.fields = { dataSource: this.treeData, id: 'classCode', parentID: 'parentCode', text: 'classCodeName', hasChildren: 'hasChild', selected: 'isSelected'};
    },
    //******************************* 트리뷰 이벤트 *******************************
    onNodeSelected(args){
      this.searchFilterOptions.classCode = args.nodeData.id;
      this.onClickSearch();
    },
    //endregion
    //region ******************************* 버튼 이벤트 *******************************
    //조회
    async onClickSearch() {
      this.gridField.dataSource = await StockCommon.getProductStockList(this.searchFilterOptions);
    },

    //확인
    onClickConfirm() {
      const {addedRecords} = this.$refs.grid2.getBatchChanges();
      if (addedRecords.length > 0) {
        this.$emit("popupConfirmed", addedRecords);
      } else {
        this.errorToast("상품을 선택해 주십시오");
      }
    },
    //추가
    onClickAdd(){
      const selData = this.$refs.grid.getSelectedRecords();
      if(selData?.length === 0){
        this.errorToast("선택된 상품이 없습니다.");
      }else{
        this.gridDuplicationCheck(selData[0]);
      }
    },
    //삭제
    onClickDel() {
      this.$refs.grid2.deleteRecord();
      //ROW- COUNT
      this.gridField2.count = this.$refs.grid2.getBatchCurrentViewRecords().length;
    },
    //팝업 닫기
    onClickClose(){
      this.$emit('popupConfirmed', null);
    },
    //endregion

    //region ******************************* 그리드 이벤트 *******************************
    //그리드 헤더 이미지적용
    onHeaderCellInfo(args){
      const {cell: {column:{field}}, node} = args;
      if(this.$refs.grid.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    onHeaderCellInfo2(args){
      const {cell: {column:{field}}, node} = args;
      if(this.$refs.grid2.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    //그리드 셀 이미지 적용
    onQueryCellInfo(args){
      const {column:{field,allowEditing}, cell} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },
    onQueryCellInfo2(args){
      const {column:{field,allowEditing}, cell} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },
    //그리드 조회 완료시(카운트)
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },

    //그리드 조회 완료시(카운트)
    gridComplete2(){
      this.gridField2.count = this.$refs.grid2.getGridBatchCount();
    },


    //그리드 더블클릭 or 엔터
    onDoubleClickedOrEnterKeyed(){
      const rowData = this.$refs.grid.getSelectedRecords();
      this.gridDuplicationCheck(rowData[0]);
      //ROW- COUNT
      this.gridField2.count = this.$refs.grid2.getBatchCurrentViewRecords().length;
    },


    //중복 체크 함수
    gridDuplicationCheck(inputData){
      const {addedRecords} = this.$refs.grid2.getBatchChanges();
      let duplicate = null ;
      if(addedRecords.length !== 0){
        duplicate = addedRecords.filter((data) => data.productCode === inputData.productCode && data.wrhousCode === inputData.wrhousCode);
      }
      if(inputData.length !== 0){
        if(duplicate == null || duplicate?.length === 0){
          this.$refs.grid2.addRecord(Object.assign(inputData,{dlivyQty:0}));
        } else{
          this.errorToast("이미 존재하는 상품입니다.");
        }
      }
    },
//endregion

  },
};
</script>
<!-- ##################################### CUSTOM STYLE ########################################* -->
<style scoped>
.control_wrapper {
  display: block;
  min-width: 250px;
  max-width: 350px;
  min-height: 338px;
  max-height: 338px;
  margin: auto;
  overflow: auto;
  border: 1px solid #dddddd;
  border-radius: 3px;
}
/*트리 왼쪽 정렬*/
body .e-popup.e-lib::v-deep .e-list-parent .e-list-item {text-align: left;}
/*트리 레벨별 들여쓰기*/
body .e-popup.e-lib::v-deep .e-list-parent {padding: 0 0 0 24px;}
/*트리 1레벨별 들여쓰기*/
body .e-popup.e-lib::v-deep .e-treeview > .e-list-parent {padding: 0 0 0 6px;}
/*트리 내부행 스크롤 삭제*/
body .e-treeview::v-deep .e-ul { overflow-y:hidden ;}

</style>

<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field reservationDate">
            <div class="title">
              {{ labels.resveDate }}
            </div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                  v-model="inputDateRangeValue"
                  type="lookup-condition"
                />
              </li>
            </ul>
          </li>
          <li class="field partDivision">
            <div class="title">
              {{ labels.partDiv }}
            </div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  ref="partDiv"
                  v-model="partDiv"
                  :dataSource="searchOptions.partDivOptions"
                  :allowFiltering="false"
                  :fields="commonCodeFields"
                  cssClass="body-data-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field reservationNameRemarksFourDigitContactNumber">
            <div class="title">이름</div>
            <ul class="content">
              <li class="item input" style="width: 180px">
                <input-text
                    ref="searchValue"
                    id="searchValueInputTextBox"
                    v-model="searchConditions.searchValue"
                    placeholder="예약자명, 내장객명, 락카번호"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">성별</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  ref="sexCode"
                  v-model="sexCode"
                  :dataSource="searchOptions.sexCodeOptions"
                  :allowFiltering="false"
                  :fields="commonCodeFields"
                  cssClass="body-data-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.resveRemarks }}
            </div>
            <ul class="content">
              <li class="item">
                <input-text v-model="searchConditions.resveRemarks" />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.grpName }}
            </div>
            <ul class="content">
              <li class="item">
                <input-text v-model="searchConditions.searchGrpName" />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              @click.native="onVisitorStatementViewClicked"
          >
            조회
          </erp-button>
        </div>
        <ul class="lookup-condition sub">
          <li class="field">
            <ul class="content">
              <li class="item check">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="radio"
                        value="LOCKER"
                        v-model="searchConditions.lockerKeyPaymtCodeDiv"
                        name="lockerKeyPaymtCodeDiv"
                        @change="gridRefresh"
                      />
                      <i></i>
                      <div class="label">락카번호</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="radio"
                        value="PAYMT"
                        v-model="searchConditions.lockerKeyPaymtCodeDiv"
                        name="lockerKeyPaymtCodeDiv"
                        @change="gridRefresh"
                      />
                      <i></i>
                      <div class="label">요금구분</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="radio"
                        value="MEMBER_NO"
                        v-model="searchConditions.lockerKeyPaymtCodeDiv"
                        name="lockerKeyPaymtCodeDiv"
                        @change="gridRefresh"
                      />
                      <i></i>
                      <div class="label">회원번호</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="checkbox"
                          v-model="visitAmtFlag"
                          :value="true"
                          @change="gridRefresh"
                      />
                      <i></i>
                      <div class="label">{{ labels.visitAmt }}</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true" />
      <article :class="['body-article',
               {
               'dev-body-article-active-team__information': isTeamInformationVisible,
               },
      ]">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">내장 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li>
                  <erp-button
                      button-div="PRINT"
                      :is-shortcut-button="true"
                      @click.native="print"
                  >
                    인쇄
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body" style="border: none">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="visitorStatementListGrid"
                :allowResizing="true"
                :dataSource="visitorStatementList"
                :selectionSettings="selectionSettings"
                :allowExcelExport="true"
                :columns="visitorStatementListGridColumn"
                :provides="grid"
                :allowPaging="true"
                :pageSettings="visitorStatementPageSettings"
                @queryCellInfo="visitorStatementListGridQueryCellInfo"
                @actionComplete="visitorStatementListGridActionComplete"
                @rowSelected="handleCourseReserveTimeGridRowSelected"
                @dataBound="onDataBound"
              />
            </div>
          </div>
          <div class="visitor-report">
            <div class="rows">
              <div class="left">
                <div class="item">
                  총인원 :
                  {{
                    numberWithCommas(
                      visitorStatementReportList.totPersCnt || 0
                    )
                  }}명
                  (모바일 : {{ getCountAutoCheckedIn }}명)
                </div>
                <div class="item">
                  남자 :
                  {{
                    numberWithCommas(
                      visitorStatementReportList.totPersCntM || 0
                    )
                  }}명
                </div>
                <div class="item">
                  여자 :
                  {{
                    numberWithCommas(
                      visitorStatementReportList.totPersCntF || 0
                    )
                  }}명
                </div>
              </div>
              <div
                class="right"
                v-if="visitorStatementPaymtCodeList.length > 0"
              >
                <div
                  class="item"
                  v-for="(item, index) in visitorStatementPaymtCodeList"
                  :key="index"
                >
                  {{ item.paymtCode }}:
                  {{ numberWithCommas(item.paymtCodeCount || 0) }}명
                </div>
              </div>
            </div>
            <div class="rows">
              <div class="left">
                18홀 :
                {{
                  numberWithCommas(
                    visitorStatementReportList.tot18HoleTeamCnt || 0
                  )
                }}팀
                {{
                  numberWithCommas(
                    visitorStatementReportList.tot18HoleCnt || 0
                  )
                }}명
                <span style="margin-left: 5px" v-if="!visitAmtFlag">
                  (카트대여료 :
                  {{
                    numberWithCommas(
                      visitorStatementReportList.tot18HoleCartAmt || 0
                    )
                  }})
                </span>
              </div>
              <div class="right" v-if="!visitAmtFlag">
                <div class="item">
                  (카트대여료 총계 :
                  {{
                    numberWithCommas(
                      visitorStatementReportList.totCartAmt || 0
                    )
                  }})
                </div>
                <div class="item">
                  (입장료 총계 :
                  {{
                    numberWithCommas(
                      visitorStatementReportList.totGreenFeeAmt || 0
                    )
                  }})
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
            :class="[
              'article-section',
              'section-02',
              'accordion',
              { close: !isTeamInformationVisible },
            ]"
        >
          <div class="section-header">
            <div class="header-left">
              <div
                  class="header-title"
                  @click="handleTeamInformationHeaderClick"
              >
                팀 정보
              </div>
            </div>
            <div class="header-caption">
              [{{
                getCourseNameFromCommonCode(teamInformation.courseCode)
              }}코스 <strong>{{ teamInformation.startTime }}</strong
            ><span v-if="teamInformation.grpName !== undefined"
            >, 단체명 - {{ teamInformation.grpName }}</span
            >]
            </div>
          </div>
          <div class="section-body">
            <div class="body-card" v-if="isTeamSelected">
              <ul class="box" :style="{ width: memberLinkCardWidth + 'px' }">
                <!-- 활성 : active -->
                <li
                    class="card"
                    v-for="visitor in teamInformation.visitors"
                    :key="`visitor-${visitor.visitId}`"
                    :style="visitor.argmtAditFlag === '1' && addVisitTeamItalicStyle"
                >
                  <div class="photo">
                    <div
                        v-if="visitor['imagePath']"
                        class="item"
                        :style="{
                          backgroundImage: `url(${visitor['imagePath']})`,
                        }"
                    ></div>
                  </div>
                  <ul class="content">
                    <li class="item">
                      <strong>{{ visitor.visitName }}</strong>
                      ({{
                        getSexLocaleStringFromCommonCode(visitor.sexCode)
                      }}
                      {{ visitor.lockerPrtNo }})
                    </li>
                    <li class="item">
                      {{ memberNoFormatter(visitor.memberNo) + " " + getMemberDivLocaleStringFromCommonCode(visitor.memberDiv,) }}
                    </li>
                    <li class="item">
                      확정 :
                      <span v-if="visitor.visitConfirmFlag">
                          <strong>√</strong>
                      </span>&nbsp;
                        <span>{{
                            getHoleDivLocaleStringFromCommonCode(visitor.holeDiv)
                          }}</span>
                    </li>
                    <li class="item" v-if="visitor.insertDt">
                      체크인 : <strong>{{visitor.insertDt}}</strong>
                    </li>
                    <li class="item" v-if="visitor.contactTel">
                      연락처 : <strong>{{getFormattedPhoneNumber(visitor.contactTel)}}</strong>
                    </li>
                    <li class="item" v-if="visitor.bagName">
                      백네임 : {{visitor.bagName}}
                    </li>
                    <li class="item">
                      VOC :
                      <span
                          :style="visitor.vocCount && 'cursor: pointer'"
                          @click="visitor.vocCount && fastVocMove(visitor)"
                      >
                          <strong>{{visitor.vocCount?visitor.vocCount+'건':'-'}}</strong>
                        </span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.partDivision .content .item {width: 90px;}
.visitor-report {border: 1px solid #ccc;border-top: 0;background: #fff;}
.visitor-report > .rows {display: flex;padding: 0.25rem 0.5rem;border-top: 1px solid #ccc;}
.visitor-report > .rows:first-child {border-top: 0;}
.visitor-report > .rows > .left {display: flex;}
.visitor-report > .rows > .right {display: flex;flex: 1;align-content: flex-end;justify-content: flex-end;}
.visitor-report > .rows > .left > .item:not(:last-child),
.visitor-report > .rows > .right > .item:not(:last-child) {margin-right: 1rem;}

body .appContent .article-section.section-01 {height: calc(100% - 47px);}
body .appContent .article-section.section-02 {height: 47px;}

body .appContent .body-article.dev-body-article-active-team__information .article-section.section-01 {height: calc(100% - 210px);}
body .appContent .body-article.dev-body-article-active-team__information .article-section.section-02 {height: 208px;}

body .appContent .article-section.section-02 .section-body {overflow: visible; border: none;}
body .appContent .article-section.section-02 .body-card {overflow-x: scroll; overflow-y: hidden; height: 100%; border: 1px solid #ccc;}
body .appContent .article-section.section-02 .body-card .box {display: block; list-style: none; padding: 0px; margin: 0;}
@supports (display: flow-root) {
  body .appContent .article-section.section-02 .body-card .box {display: flow-root;}
}
body .appContent .article-section.section-02 .body-card .box:after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
body .appContent .article-section.section-02 .body-card .card {display: block; box-sizing: border-box; float: left; width: 240px; height: 131px; padding: 9px; margin: 6px 0 6px 6px; border: 1px solid #ccc; border-radius: 3px; background-color: #f9f9f9;}
@supports (display: flow-root) {
  body .appContent .article-section.section-02 .body-card .card {display: flow-root;}
}
body .appContent .article-section.section-02 .body-card .card:after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
body .appContent .article-section.section-02 .body-card .photo {box-sizing: border-box; float: left; width: 82px; height: 100%; border: 1px solid #e0e0e0; background: #fff url(../../assets/images/common/photo-default.png) no-repeat center center; background-size: 40%;}
body .appContent .article-section.section-02 .body-card .photo .item {width: 100%; height: 100%; background-color: #fff; background-repeat: no-repeat; background-position: center center; background-size: contain;}
body .appContent .article-section.section-02 .body-card .content {box-sizing: border-box; list-style: none; float: left; width: calc(100% - 82px); padding: 5px 0 5px 12px; margin: 0;}
body .appContent .article-section.section-02 .body-card .content .item {overflow: hidden; width: 100%; min-height: 18px; text-overflow: ellipsis; white-space: nowrap;}
body .appContent .article-section.section-02 .body-card .content .item.hole {text-decoration: underline; cursor: pointer;}
body .appContent .article-section.section-02 .body-card .content .item.dev-hole__changed {color: #f82f2f;}

</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {
  Aggregate,
  Filter,
  Resize,
  Group,
  ExcelExport,
  ForeignKey,
  VirtualScroll,
  Page,
} from "@syncfusion/ej2-vue-grids";

import { getFormattedDate } from "@/utils/date";
import {
  commonCodesGetColorValue,
  commonCodesGetCommonCode,
  commonCodesGetComName, commonCodesGetSortNo,
} from '@/utils/commonCodes';
import { numberWithCommas } from "@/utils/number";
import { orderBy as _orderBy } from "lodash";

import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";

import ReportView from "@/components/common/report/ReportView";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { mapGetters } from "vuex";

import {gridUtilGetMemberNoColumnAccess} from "@/utils/gridUtil";
import { memberNoFormatter } from "@/utils/formatter";
import {getFormattedPhoneNumber} from "../../utils/string";
import {getCaddieArgmtInfoDetaill} from "@/api/caddieArgmt";

export default {
  name: "VisitorStatement",
  components: {
    InputText,
    InputDateRange,
    ejsGridWrapper,
    ReportView,
    ErpButton
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  created() {
    this.inputDateRangeValue.from = getFormattedDate(new Date());
    this.inputDateRangeValue.to = getFormattedDate(new Date());

    this.searchOptions.partDivOptions = commonCodesGetCommonCode("PART_DIV");
    if (
      this.searchOptions.partDivOptions.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchOptions.partDivOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
    this.searchOptions.sexCodeOptions = commonCodesGetCommonCode("SEX_CODE");
    if (
      this.searchOptions.sexCodeOptions.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchOptions.sexCodeOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
  },
  data() {
    return {
      count: 0,
      visitorStatementListLength: 0,
      grid: [
        Aggregate,
        Filter,
        Resize,
        Group,
        ExcelExport,
        ForeignKey,
        VirtualScroll,
        Page,
      ],
      partDiv: "ALL",
      sexCode: "ALL",
      filteredFoundIndexList: [],
      savedSearchValue: null,
      savedVisitDate: null,
      selectionSettings: { enableToggle: true },
      commonCodeFields: { text: "comName", value: "comCode" },
      inputDateRangeValue: { to: null, from: null },
      searchConditions: {
        searchValue: null,
        lockerKeyPaymtCodeDiv: "LOCKER",
        searchGrpName: null,
      },
      visitAmtFlag: true,
      visibleTeamInformation: true,
      searchOptions: {
        partDivOptions: null,
        sexCodeOptions: null,
      },
      labels: {
        resveDate: "내장기간",
        partDiv: "부 구분",
        resveRemarks: "예약비고",
        grpName: "단체명",
        visitAmt: "락카 현황",
        lockerKeyPaymtCodeDiv: "락카/요금/회원번호",
      },
      visitorStatementPageSettings: { pageSize: 50 },
      visitorStatementList: [],
      teamInformation: {
        checkInId: '',
        visitors: [],
        timeId: '',
        timeStatus: '',
        partDiv: '',
        startTime: '',
        startCourse: '',
        holeDiv: '',
        resveName: '',
        resveKind: '',
        resveChannel: '',
        grpNo: '',
        GrpKind: '',
        GrpName: '',
        visitConfirmFlag: '',
        optFlag: '',
      },
      visitorStatementListGridColumn: [
        {
          field: "resveNo",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "visitDate",
          headerText: "내장일자",
          textAlign: "center",
          width: 100,
          type: "string",
        },
        {
          field: "startTime",
          headerText: "시간",
          textAlign: "center",
          width: 80,
          type: "string",
        },
        {
          field: "startCourse",
          headerText: "코스",
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "COURSE_CODE",
          width: 80,
          type: "string",
        },
        {
          field: "teamHoleDiv",
          headerText: "홀",
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "HOLE_DIV",
          width: 70,
          type: "string",
        },
        {
          field: "selfFlag",
          headerText: "셀프",
          minWidth: 16,
          width: 50,
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          textAlign: "Center",
        },
        {
          field: "resveName",
          headerText: "예약자명",
          width: 100,
          type: "string",
        },
        {
          field: "memberNo",
          headerText: "회원번호",
          textAlign: "center",
          width: 90,
          type: "string",
          valueAccessor: (field, data) =>
            gridUtilGetMemberNoColumnAccess(field, data),
        },
        {
          field: "grpName",
          headerText: "단체명",
          width: 100,
          type: "string",
        },
        {
          field: "resveRemarks",
          headerText: "예약비고",
          width: 150,
          type: "string",
        },
        {
          field: "caddieName",
          headerText: "캐디",
          width: 100,
          type: "string"
        },
        {
          headerText: "내장객1",
          columns: [
            {
              field: "visitName1",
              headerText: "내장객명",
              textAlign: "center",
              width: 100,
              type: "string",
            },
            {
              field: "visitLockerNo1",
              headerText: "락카번호",
              textAlign: "center",
              width: 100,
              type: "string",
            },
            {
              field: "visitPaymtCode1",
              headerText: "요금구분",
              textAlign: "center",
              isCommonCodeField: true,
              groupCode: "PAYMT_CODE",
              width: 100,
              type: "string",
              visible: false
            },
            {
              field: "visitMemberNo1",
              headerText: "회원번호",
              textAlign: "center",
              width: 100,
              type: "string",
              visible: false,
              valueAccessor: (field, data) =>
                gridUtilGetMemberNoColumnAccess(field, data),
            },
            {
              field: "visitHoleDiv1",
              headerText: "홀",
              textAlign: "center",
              isCommonCodeField: true,
              groupCode: "HOLE_DIV",
              width: 60,
              type: "string",
            },
            {
              field: "visitTotAmt1",
              headerText: "입장료",
              textAlign: "right",
              type: "number",
              format: "N",
              width: 90,
              visible: false,
            },
          ],
        },
        {
          headerText: "내장객2",
          columns: [
            {
              field: "visitName2",
              headerText: "내장객명",
              textAlign: "center",
              width: 100,
              type: "string",
            },
            {
              field: "visitLockerNo2",
              headerText: "락카번호",
              textAlign: "center",
              width: 100,
              type: "string",
            },
            {
              field: "visitPaymtCode2",
              headerText: "요금구분",
              textAlign: "center",
              isCommonCodeField: true,
              groupCode: "PAYMT_CODE",
              width: 100,
              type: "string",
              visible: false
            },
            {
              field: "visitMemberNo2",
              headerText: "회원번호",
              textAlign: "center",
              width: 100,
              type: "string",
              visible: false,
              valueAccessor: (field, data) =>
                gridUtilGetMemberNoColumnAccess(field, data),
            },
            {
              field: "visitHoleDiv2",
              headerText: "홀",
              textAlign: "center",
              isCommonCodeField: true,
              groupCode: "HOLE_DIV",
              width: 60,
              type: "string",
            },
            {
              field: "visitTotAmt2",
              headerText: "입장료",
              textAlign: "right",
              type: "number",
              format: "N",
              width: 90,
              visible: false,
            },
          ],
        },
        {
          headerText: "내장객3",
          columns: [
            {
              field: "visitName3",
              headerText: "내장객명",
              textAlign: "center",
              width: 100,
              type: "string",
            },
            {
              field: "visitLockerNo3",
              headerText: "락카번호",
              textAlign: "center",
              width: 100,
              type: "string",
            },
            {
              field: "visitPaymtCode3",
              headerText: "요금구분",
              textAlign: "center",
              isCommonCodeField: true,
              groupCode: "PAYMT_CODE",
              width: 100,
              type: "string",
              visible: false
            },
            {
              field: "visitMemberNo3",
              headerText: "회원번호",
              textAlign: "center",
              width: 100,
              type: "string",
              visible: false,
              valueAccessor: (field, data) =>
                gridUtilGetMemberNoColumnAccess(field, data),
            },
            {
              field: "visitHoleDiv3",
              headerText: "홀",
              textAlign: "center",
              isCommonCodeField: true,
              groupCode: "HOLE_DIV",
              width: 60,
              type: "string",
            },
            {
              field: "visitTotAmt3",
              headerText: "입장료",
              textAlign: "right",
              type: "number",
              format: "N",
              width: 90,
              visible: false,
            },
          ],
        },
        {
          headerText: "내장객4",
          columns: [
            {
              field: "visitName4",
              headerText: "내장객명",
              textAlign: "center",
              width: 100,
              type: "string",
            },
            {
              field: "visitLockerNo4",
              headerText: "락카번호",
              textAlign: "center",
              width: 100,
              type: "string",
            },
            {
              field: "visitPaymtCode4",
              headerText: "요금구분",
              textAlign: "center",
              isCommonCodeField: true,
              groupCode: "PAYMT_CODE",
              width: 100,
              type: "string",
              visible: false
            },
            {
              field: "visitMemberNo4",
              headerText: "회원번호",
              textAlign: "center",
              width: 100,
              type: "string",
              visible: false,
              valueAccessor: (field, data) =>
                gridUtilGetMemberNoColumnAccess(field, data),
            },
            {
              field: "visitHoleDiv4",
              headerText: "홀",
              textAlign: "center",
              isCommonCodeField: true,
              groupCode: "HOLE_DIV",
              width: 60,
              type: "string",
            },
            {
              field: "visitTotAmt4",
              headerText: "입장료",
              textAlign: "right",
              type: "number",
              format: "N",
              width: 90,
              visible: false,
            },
          ],
        },
        {
          headerText: "내장객5",
          columns: [
            {
              field: "visitName5",
              headerText: "내장객명",
              textAlign: "center",
              width: 100,
              type: "string",
            },
            {
              field: "visitLockerNo5",
              headerText: "락카번호",
              textAlign: "center",
              width: 100,
              type: "string",
            },
            {
              field: "visitPaymtCode5",
              headerText: "요금구분",
              textAlign: "center",
              isCommonCodeField: true,
              groupCode: "PAYMT_CODE",
              width: 100,
              type: "string",
              visible: false
            },
            {
              field: "visitMemberNo5",
              headerText: "회원번호",
              textAlign: "center",
              width: 100,
              type: "string",
              visible: false,
              valueAccessor: (field, data) =>
                gridUtilGetMemberNoColumnAccess(field, data),
            },
            {
              field: "visitHoleDiv5",
              headerText: "홀",
              textAlign: "center",
              isCommonCodeField: true,
              groupCode: "HOLE_DIV",
              width: 60,
              type: "string",
            },
            {
              field: "visitTotAmt5",
              headerText: "입장료",
              textAlign: "right",
              type: "number",
              format: "N",
              width: 90,
              visible: false,
            },
          ],
        },
      ],
      visitorStatementReportList: [],
      visitorStatementPaymtCodeList: [],
    };
  },
  computed: {
    ...mapGetters(["username"]),
    getCountAutoCheckedIn() {
      let count = 0;
      for (let i = 1; i <= 5; i++) {
        count += this.visitorStatementList
          ?.map(item => item[`autoChkinFlag${i}`])
          ?.filter(item => !!item)
          ?.reduce((acc, cur) => cur + acc, 0);
      }
      return numberWithCommas(count);
    },
    courseNames() {
      return commonCodesGetCommonCode('COURSE_CODE');
    },
    isTeamSelected() {
      return !!this.teamInformation && !!this.teamInformation.checkInId;
    },
    memberLinkCardWidth() {
      if (!this.teamInformation || !this.teamInformation.visitors) {
        return 252;
      }
      return this.teamInformation.visitors.length * 252;
    },
    addVisitTeamItalicStyle() {
      return {
        'background-color': commonCodesGetColorValue(
            'COLOR_TIME_STATUS',
            'ADD_VISIT_TEAM',
        ),
      };
    },
    sexLocaleStrings() {
      return commonCodesGetCommonCode('SEX_CODE');
    },
    memberDivLocaleStrings() {
      return commonCodesGetCommonCode('MEMBER_DIV');
    },
    holeDivLocaleStrings() {
      return commonCodesGetCommonCode('HOLE_DIV');
    },
    isTeamInformationVisible() {
      return this.visibleTeamInformation && this.isTeamSelected;
    },
  },
  methods: {
    getFormattedPhoneNumber,
    numberWithCommas,
    memberNoFormatter,
    async onVisitorStatementViewClicked() {
      await this.getVisitorStatementList();
      this.teamInformation = [];
    },
    async getVisitorStatementList() {
      if (!this.inputDateRangeValue.from) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["시작일자"])
        );
      }
      if (!this.inputDateRangeValue.to) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["종료일자"])
        );
      }

      let params = {
        resveDateStart: this.inputDateRangeValue.from,
        resveDateEnd: this.inputDateRangeValue.to,
        partDiv: this.partDiv === "ALL" ? undefined : this.partDiv,
        resveRemarks: this.searchConditions.resveRemarks || undefined,
        name: this.searchConditions.searchValue || undefined,
        grpName: this.searchConditions.searchGrpName || undefined,
        sexCode: this.sexCode === "ALL" ? null : this.sexCode,
      };

      const data = await GolfErpAPI.fetchVisitorStatement(params);

      this.visitorStatementList = _orderBy(data.visitorStatement.map((item, index) => {
        item.visitDateLineFlag = false;
        if (index > 0) {
          if (this.savedVisitDate !== item.visitDate) {
            this.savedVisitDate = item.visitDate;
            item.visitDateLineFlag = true;
          }
        } else {
          this.savedVisitDate = item.visitDate;
        }
        item.courseCodeSortNo = commonCodesGetSortNo("COURSE_CODE", item.startCourse);
        return item;
      }), ["visitDate", "startTime", "courseCodeSortNo"]);
      this.visitorStatementListLength = data.visitorStatement.length;

      this.visitorStatementReportList = data.visitorStatementReport;
      this.visitorStatementPaymtCodeList = data.visitorStatementPaymtCode.map(
        (item) => {
          item.paymtCode = commonCodesGetComName("PAYMT_CODE", item.paymtCode);
          return item;
        }
      );

      this.savedSearchValue = null;
      this.filteredFoundIndexList = [];
    },
    async handleCourseReserveTimeGridRowSelected(args) {
      this.selectedRow = args.data;
      await this.fetchTeamInformationAndMemo(args.data, args.field);
    },
    async fetchTeamInformationAndMemo(rowData) {
      const {
        timeId,
        timeStatus,
        partDiv,
        startTime,
        startCourse,
        holeDiv,
        resveName,
        resveKind,
        resveChannel,
        grpNo,
        GrpKind,
        GrpName,
        visitConfirmFlag,
        optFlag,
        visitDate,
        dwCode,
      } = rowData;
      const courseCode = rowData.startCourse;

        this.fetchTeamInformation(timeId).then(
            ({checkInId, visitors}) => {
              this.teamInformation = {
                visitDate,
                courseCode,
                checkInId,
                visitors,
                timeId,
                timeStatus,
                partDiv,
                startTime,
                startCourse,
                holeDiv,
                resveName,
                resveKind,
                resveChannel,
                grpNo,
                GrpKind,
                GrpName,
                visitConfirmFlag,
                optFlag,
                dwCode,
              };
            },
        );
    },
    async fetchTeamInformation(timeId) {
      const {teamVisitInfoList,} = await this.getCaddieArgmtInfoDetaill(timeId);
      const checkInId = this.selectedRow.chkinId;
      return {
        checkInId,
        visitors: teamVisitInfoList.map(data=>{
          return {
            ...data,
            lockerPrtNo: data.lockerNo === '000' || data.lockerNo === '999' ? "미배정" : data.lockerPrtNo,
          };
        }) || [],
      };
    },
    async getCaddieArgmtInfoDetaill(timeId) {
      try {
        const {
          value: {teamVisitInfoList},
        } = await getCaddieArgmtInfoDetaill(timeId);
        return {
          teamVisitInfoList,
        };
      } catch (e) {
        console.error(e);
      }
    },
    // async findResveNameVisitName() {
    //   const searchValue = this.searchConditions.searchValue;
    //
    //   if (!searchValue) {
    //     this.infoToast(this.$t("main.popupMessage.noSearchValue"));
    //   } else {
    //     await this.findAndSelectRowProcess(searchValue);
    //   }
    // },
    getVisitorFindIndex(trimedSearchValue) {
      const gridInstance = this.$refs.visitorStatementListGrid;

      const filteredVisitorStatementList = gridInstance
        .getDataRows()
        .filter((_, index) => this.filteredFoundIndexList.indexOf(index) < 0);

      let foundItem = null;
      let rowIndex = -1;

      this.clearCellHighlight();

      filteredVisitorStatementList.some(({ cells }) => {
        const cell = Array.prototype.find.call(cells, (item) =>
          item.innerText.includes(trimedSearchValue)
        );

        if (cell) {
          foundItem = cell;
        }

        return cell;
      });

      gridInstance.getDataRows().some(({ cells }, index) => {
        const cell = Array.prototype.find.call(
          cells,
          (item) => item === foundItem
        );

        if (cell) {
          rowIndex = index;
        }

        return cell;
      });

      if (rowIndex === -1 && this.filteredFoundIndexList.length > 0) {
        rowIndex = this.filteredFoundIndexList[0];

        this.filteredFoundIndexList = [];
      }

      this.addCellHighlight(rowIndex, trimedSearchValue);

      return rowIndex;
    },
    addCellHighlight(rowIndex, trimedSearchValue) {
      this.$refs.visitorStatementListGrid
        .getDataRows()
        .some(({ cells }, index) => {
          if (rowIndex === index) {
            Array.prototype.some.call(cells, (cell) => {
              cell.classList.add(this.$t("className.grid.cellHighlight"));

              const regex = new RegExp(trimedSearchValue, "gim");
              const text = cell.innerText.replace(
                regex,
                `<span class="on">$&</span>`
              );

              cell.innerText = null;
              cell.insertAdjacentHTML("afterbegin", text);
            });
          }

          return rowIndex === index;
        });
    },
    clearCellHighlight() {
      this.$refs.visitorStatementListGrid
        .getDataRows()
        .map(({ cells }) =>
          Array.prototype.map.call(cells, (cell) =>
            cell.classList.remove(this.$t("className.grid.cellHighlight"))
          )
        );
    },
    handleTeamInformationHeaderClick() {
      this.collapseTeamInformation();
    },
    collapseTeamInformation() {
      this.visibleTeamInformation = !this.visibleTeamInformation;
    },
    getCourseNameFromCommonCode(courseCode) {
      const comCode = this.courseNames.find(
          (code) => code.comCode === courseCode,
      );

      return comCode ? comCode.comName : '';
    },
    getSexLocaleStringFromCommonCode(sexCode) {
      return this.sexLocaleStrings
          .find(code => code.comCode === sexCode)?.comName;
    },
    getMemberDivLocaleStringFromCommonCode(memberDiv) {
      return this.memberDivLocaleStrings
          .find(code => code.comCode === memberDiv)?.comName;
    },
    getHoleDivLocaleStringFromCommonCode(holeDiv) {
      return this.holeDivLocaleStrings
          .find(code => code.comCode === holeDiv)?.comName;
    },
    // async findAndSelectRowProcess(searchValue) {
    //   if (Array.isArray(this.visitorStatementList)) {
    //     if (searchValue) {
    //       const trimedSearchValue = searchValue.trim();
    //
    //       if (this.savedSearchValue !== trimedSearchValue) {
    //         this.savedSearchValue = trimedSearchValue;
    //         this.filteredFoundIndexList = [];
    //       }
    //
    //       const findIdx = this.getVisitorFindIndex(trimedSearchValue);
    //
    //       if (findIdx === -1) {
    //         this.errorToast(
    //           trimedSearchValue + "으로 검색된 예약 정보가 없습니다"
    //         );
    //         document.getElementById("searchValueInputTextBox").select();
    //       } else {
    //         this.filteredFoundIndexList.push(findIdx);
    //         await this.visitorStatementRowSelectProcess(findIdx);
    //       }
    //     } else {
    //       if (
    //         this.visitorStatementList.length > 0 &&
    //         this.visitorStatementList[0].length > 0
    //       ) {
    //         await this.visitorStatementRowSelectProcess(0);
    //       }
    //     }
    //   }
    //
    //   /* TODO : 포커싱 안잡히는 오류. GRID에 SELECT되는 시점때문에 input-text로 포커스가 안감.
    //      TODO : 임시로 setTimeout 사용.
    //    */
    //   setTimeout(() => {
    //     this.$refs.searchValue.focusIn();
    //   }, 1);
    // },
    visitorStatementRowSelectProcess(findIdx) {
      this.$refs.visitorStatementListGrid.selectRow(findIdx);
    },
    visitorStatementListGridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.visitorStatementListGrid?.getGridBatchCount() || 0
      );
    },
    visitorStatementListGridQueryCellInfo(args) {
      const {
        column: { field },
        cell,
        data,
      } = args;
      if (data.visitDateLineFlag) {
        cell.classList.add(this.$t("className.grid.devReservationPartDivLine"));
      } else if (field === "teamHoleDiv") {
        cell.style.backgroundColor = commonCodesGetColorValue(
          "HOLE_DIV",
          data.teamHoleDiv
        );
      }
      for (let i = 1; i <= 5; i++) {
        if (field === `visitTotAmt${i}`) {
          const id = field.replace(/[^0-9]+/, "");
          const fieldName = `visitId${id}`;
          if (
            (data[fieldName] === null || data[fieldName] === undefined) &&
            data[field] === 0
          ) {
            cell.innerText = null;
          }
        }
        if (field === `visitName${i}`) {
          const sexCode = data[`visitSexCode${i}`];
          const colorValue = commonCodesGetColorValue('SEX_CODE', sexCode);
          if (colorValue) {
            cell.style.color = colorValue;
          }
          // 동반자 내장 여부 아이콘 표시
          const autoChkinFlag = data[`autoChkinFlag${i}`];
          if (autoChkinFlag) {
            cell.classList.add(this.$t("className.grid.cmpnConfirmArea"));
          }
        }
        if (field === `visitLockerNo${i}`) {
          const visitLockerReturnFlag = data[`visitLockerReturnFlag${i}`];
          if (visitLockerReturnFlag) {
            cell.style.backgroundColor = "rgb(222, 222, 222)";
          }
        }
      }
    },
    print() {
      const options = [
        {
          field: "startCourse",
          comCode: "COURSE_CODE",
          format: "comCode",
        },
        {
          field: "teamHoleDiv",
          comCode: "HOLE_DIV",
          format: "comCode",
        },
        {
          field: "visitPaymtCode1",
          comCode: "PAYMT_CODE",
          format: "comCode",
        },
        {
          field: "visitPaymtCode2",
          comCode: "PAYMT_CODE",
          format: "comCode",
        },
        {
          field: "visitPaymtCode3",
          comCode: "PAYMT_CODE",
          format: "comCode",
        },
        {
          field: "visitPaymtCode4",
          comCode: "PAYMT_CODE",
          format: "comCode",
        },
        {
          field: "visitPaymtCode5",
          comCode: "PAYMT_CODE",
          format: "comCode",
        },
        {
          field: "visitHoleDiv1",
          comCode: "HOLE_DIV",
          format: "comCode",
        },
        {
          field: "visitHoleDiv2",
          comCode: "HOLE_DIV",
          format: "comCode",
        },
        {
          field: "visitHoleDiv3",
          comCode: "HOLE_DIV",
          format: "comCode",
        },
        {
          field: "visitHoleDiv4",
          comCode: "HOLE_DIV",
          format: "comCode",
        },
        {
          field: "visitHoleDiv5",
          comCode: "HOLE_DIV",
          format: "comCode",
        },
      ];

      let result = this.$refs.visitorStatementListGrid.getGridBatchData(
        options
      );
      result = result.map((item) => {
        item.totGroupPerson =
          (item.visitId1 ? 1 : 0) +
          (item.visitId2 ? 1 : 0) +
          (item.visitId3 ? 1 : 0) +
          (item.visitId4 ? 1 : 0) +
          (item.visitId5 ? 1 : 0);
        item.totGroupAmt =
          (item.visitTotAmt1 || 0) +
          (item.visitTotAmt2 || 0) +
          (item.visitTotAmt3 || 0) +
          (item.visitTotAmt4 || 0) +
          (item.visitTotAmt5 || 0);
        item.totGroupCartAmt =
          (item.visitTotCartAmt1 || 0) +
          (item.visitTotCartAmt2 || 0) +
          (item.visitTotCartAmt3 || 0) +
          (item.visitTotCartAmt4 || 0) +
          (item.visitTotCartAmt5 || 0);
        item.tempCount = 1;

        item.selfFlag = item.selfFlag ? "√" : "";
        item.visitLockerReturnFlag1 = this.searchConditions.lockerKeyPaymtCodeDiv === "LOCKER" && item.visitLockerReturnFlag1;
        item.visitLockerReturnFlag2 = this.searchConditions.lockerKeyPaymtCodeDiv === "LOCKER" && item.visitLockerReturnFlag2;
        item.visitLockerReturnFlag3 = this.searchConditions.lockerKeyPaymtCodeDiv === "LOCKER" && item.visitLockerReturnFlag3;
        item.visitLockerReturnFlag4 = this.searchConditions.lockerKeyPaymtCodeDiv === "LOCKER" && item.visitLockerReturnFlag4;
        item.visitLockerReturnFlag5 = this.searchConditions.lockerKeyPaymtCodeDiv === "LOCKER" && item.visitLockerReturnFlag5;
        return item;
      });
      console.log("result.===>", result);

      if (result.length < 1) {
        return this.errorToast(this.$t("report.popupMessage.noData"));
      }

      const searchOptionsList = [
        {
          key: this.labels.resveDate,
          value:
            this.inputDateRangeValue.from + " ~ " + this.inputDateRangeValue.to,
        },
        {
          key: this.labels.partDiv,
          value: commonCodesGetComName("PART_DIV", this.partDiv),
        },
        {
          key: this.labels.resveRemarks,
          value: this.searchConditions.resveRemarks,
        },
        {
          key: this.labels.visitAmt,
          value: this.searchConditions.visitAmtFlag ? null : "예",
        },
        {
          key: this.labels.lockerKeyPaymtCodeDiv,
          value: this.searchConditions.lockerKeyPaymtCodeDiv === "LOCKER"
            ? "락카번호"
            : this.searchConditions.lockerKeyPaymtCodeDiv === "PAYMT"
            ? "요금구분"
            : "회원번호"
        },
      ];

      const searchOptions = searchOptionsList
        .filter((item) => !!item.value)
        .map((item) => item.key + ": " + item.value)
        .join(", ");

      let paymtList = [];

      this.visitorStatementPaymtCodeList.map((item) => {
        paymtList.push(`${item.paymtCode}: ${item.paymtCodeCount}명`);
      });

      const reportData = {
        reportJson: {
          jsonData: result,
          visitorStatementReportList: this.visitorStatementReportList,
          visitorStatementPaymtCodeList: paymtList.join(", "),
          lockerKeyPaymtCodeDiv: this.searchConditions.lockerKeyPaymtCodeDiv,
          username: this.username,
          uniqueCode: this.$options.name,
          searchOptions,
        },
      };

      this.$refs.reportViewComponent.postReport(reportData, this.$options.name + (this.visitAmtFlag ? "WithoutAmt" : ""));
    },
    onClickExcel() {
      this.$refs.visitorStatementListGrid.excelExport();
    },
    gridRefresh() {
      this.$refs.visitorStatementListGrid.refresh();
    },
    onDataBound() {
      const autoFill = field => [...Array(5).keys()].map((_, i) => `${field}${i + 1}`);
      const visitLockerNoFields = autoFill('visitLockerNo');
      const visitPaymtCodeFields = autoFill('visitPaymtCode');
      const visitMemberNoFields = autoFill("visitMemberNo");
      const visitHoleDivFields = autoFill('visitHoleDiv');
      const visitTotAmtFields = autoFill('visitTotAmt');
      if (this.searchConditions.lockerKeyPaymtCodeDiv === "LOCKER") {
        this.$refs.visitorStatementListGrid.showColumns(visitLockerNoFields, "field");
        this.$refs.visitorStatementListGrid.hideColumns(visitPaymtCodeFields, "field");
        this.$refs.visitorStatementListGrid.hideColumns(visitMemberNoFields, "field");
      } else if (this.searchConditions.lockerKeyPaymtCodeDiv === "PAYMT") {
        this.$refs.visitorStatementListGrid.showColumns(visitPaymtCodeFields, "field");
        this.$refs.visitorStatementListGrid.hideColumns(visitLockerNoFields, "field");
        this.$refs.visitorStatementListGrid.hideColumns(visitMemberNoFields, "field");
      } else {
        this.$refs.visitorStatementListGrid.showColumns(visitMemberNoFields, "field");
        this.$refs.visitorStatementListGrid.hideColumns(visitPaymtCodeFields, "field");
        this.$refs.visitorStatementListGrid.hideColumns(visitLockerNoFields, "field");
      }
      if (this.visitAmtFlag) {
        this.$refs.visitorStatementListGrid.hideColumns(['resveRemarks', 'caddieName'], "field");
        this.$refs.visitorStatementListGrid.hideColumns(visitHoleDivFields, "field");
        this.$refs.visitorStatementListGrid.hideColumns(visitTotAmtFields, "field");
      } else {
        this.$refs.visitorStatementListGrid.showColumns(['resveRemarks', 'caddieName'], "field");
        this.$refs.visitorStatementListGrid.showColumns(visitHoleDivFields, "field");
        this.$refs.visitorStatementListGrid.showColumns(visitTotAmtFields, "field");
      }
    }
  },
};
</script>

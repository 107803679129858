import request from "@/utils/request";

/**
 * @return Promise
 */
export function getPriceUnitDate(applyDate) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/payment-code/payment-mst",
    method: "get",
    params: {
      applyDate,
    },
  });
}

/**
 * @return Promise
 */
export function getPriceUnitDatas(applyDate, bsnCode, paymtCode) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/payment-code/payment-mst/detail",
    method: "get",
    params: {
      applyDate,
      bsnCode,
      paymtCode,
    },
  });
}

/**
 * @return Promise
 */
export function getPriceUnitConf() {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/common/conf",
    method: "get",
  });
}

/**
 * @return Promise
 */
export function getDetailPriceDivHole(price,bsnCode) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/payment-code/payment-mst/detail-price-div-hole",
    method: "get",
    params: {
      price,
      bsnCode
    },
  });
}

/**
 * @return Promise
 */
export function putPriceUnitConf(paymentConfRequest) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/payment-code/payment-mst/paymt-div-conf",
    method: "put",
    data: paymentConfRequest,
  });
}

/**
 * @return Promise
 */
export function addPriceUnitDatas(applyDate) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/payment-code/payment-mst/detail",
    method: "post",
    params: {
      applyDate,
    },
    data: {},
  });
}

/**
 * @return Promise
 */
export function copyPriceUnit(applyDate, copyDate) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/payment-code/payment-mst/detail-copy",
    method: "post",
    params: {
      applyDate,
      copyDate,
    },
    data: {},
  });
}

/**
 * @return Promise
 */
export function savePriceUnitDatas(priceUnitRequest) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/payment-code/payment-mst/detail",
    method: "put",
    data: priceUnitRequest,
  });
}

/**
 * @return Promise
 */
export function deletePriceUnitDatas(applyDate) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/payment-code/payment-mst/detail",
    method: "delete",
    params: {
      applyDate,
    },
    data: {},
  });
}

/**
 * @return Promise
 */
export function getProductCodes() {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/payment-code/payment-product",
    method: "get",
  });
}

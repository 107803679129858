import GolfErpAPI from "@/api/v2/GolfErpAPI";
export const ROOT_PATH = '/front-postponement';

class Postponement {

    /** 연기권 내역 조회 */
    async getPostponement(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/list` ,{params} );
        return data;
    }
    /** 연기권 상세 조회 VO */
    async getPostponementDtl(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/detail` ,{params} );
        return data;
    }
    /** 연기권 삭제 */
    async deletePostponement(params) {
        const {data} = await  GolfErpAPI.http.delete(`${ROOT_PATH}/list` ,params);
        return data;
    }
    /** 연기권 수정 */
    async patchPostponement(params) {
        const {data} = await  GolfErpAPI.http.patch(`${ROOT_PATH}/detail` ,params);
        return data;
    }
}

export default new Postponement();
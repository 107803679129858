<template>
  <div
    class="inputBirthday"
    :class="[{ focus: active }, { disabled: disabled }]"
    @focusin="handleFocusin"
    @focusout="handleFocusout"
  >
    <div class="inputBirthday-input years">
      <ejs-dropdownlist
        ref="years"
        :cssClass="`inputBirthday-${type ? type : 'grid'}`"
        :enabled="!disabled"
        v-bind="dropdownlistYearsProps"
        v-model="year"
      />
    </div>
    <div class="inputBirthday-input months">
      <ejs-dropdownlist
        ref="months"
        :cssClass="`inputBirthday-${type ? type : 'grid'}`"
        :enabled="!disabled"
        v-bind="dropdownlistMonthsProps"
        v-model="month"
      />
    </div>
    <div class="inputBirthday-input dates">
      <ejs-dropdownlist
        ref="dates"
        :cssClass="`inputBirthday-${type ? type : 'grid'}`"
        :enabled="!disabled"
        v-bind="dropdownlistDatesProps"
        v-model="date"
      />
    </div>
    <input type="hidden" :id="id" :value="value" />
  </div>
</template>

<style scoped>
@supports (display: flow-root) {
  .inputBirthday {display: flow-root;}
}
.inputBirthday {display: block;}
.inputBirthday:after {display: block;clear: both;height: 0;font-size: 0;line-height: 0;content: "";}
.inputBirthday .inputBirthday-input {box-sizing: border-box;float: left;width: 31%;padding-left: 6px;}
.inputBirthday .inputBirthday-input.years {width: 38%;padding-left: 0;}
</style>

<script>
import moment from "moment";

export default {
  name: "InputBirthday",
  props: {
    id: {
      type: String,
    },
    type: String,
    value: {
      type: String,
      default: () => moment().format("YYYY-MM-DD"),
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    value() {
      const birthdayMoment = moment(this.value, "YYYY-MM-DD");
      this.year = birthdayMoment.get("year");
      this.month = birthdayMoment.get("month") + 1;
      this.date = birthdayMoment.get("date");

      this.$emit("change", {
        data: convertYearMonthDateToString(this.year, this.month, this.date),
      });
    },
    year() {
      const maxDay = moment(
        convertYearMonthDateToString(this.year, this.month, 1),
        "YYYY-MM-DD"
      )
        .endOf("month")
        .get("date");

      if (this.date > maxDay) {
        return;
      }

      this.$emit(
        "input",
        convertYearMonthDateToString(this.year, this.month, this.date)
      );
    },
    month() {
      const maxDay = moment(
        convertYearMonthDateToString(this.year, this.month, 1),
        "YYYY-MM-DD"
      )
        .endOf("month")
        .get("date");

      if (this.date > maxDay) {
        return;
      }

      this.$emit(
        "input",
        convertYearMonthDateToString(this.year, this.month, this.date)
      );
    },
    date() {
      const maxDay = moment(
        convertYearMonthDateToString(this.year, this.month, 1),
        "YYYY-MM-DD"
      )
        .endOf("month")
        .get("date");

      if (this.date > maxDay) {
        return;
      }

      this.$emit(
        "input",
        convertYearMonthDateToString(this.year, this.month, this.date)
      );
    },
    dropdownlistDatesProps() {
      if (this.dropdownlistDatesProps.dataSource.indexOf(this.date) < 0) {
        this.date = 1;
      }
    },
  },
  data() {
    return {
      active: false,
      year: moment(this.value, "YYYY-MM-DD").get("year"),
      month: moment(this.value, "YYYY-MM-DD").get("month") + 1,
      date: moment(this.value, "YYYY-MM-DD").get("date"),
    };
  },
  computed: {
    dropdownlistYearsProps() {
      const thisYear = moment().get("year");
      const startYear = thisYear - 95;

      return {
        dataSource: [...Array(thisYear - startYear).keys()].map(
          (n) => n + 1 + startYear
        ),
      };
    },
    dropdownlistMonthsProps() {
      return {
        dataSource: [...Array(12).keys()].map((n) => n + 1),
      };
    },
    dropdownlistDatesProps() {
      const maxDay = moment(
        convertYearMonthDateToString(this.year, this.month, 1),
        "YYYY-MM-DD"
      )
        .endOf("month")
        .get("date");

      return {
        dataSource: [...Array(maxDay).keys()].map((n) => n + 1),
      };
    },
  },
  methods: {
    handleFocusin() {
      this.active = true;
    },
    handleFocusout() {
      this.active = false;
    },
  },
};

function convertYearMonthDateToString(year, month, date) {
  const strYear =
    year < 10
      ? `000${year}`
      : year < 100
      ? `00${year}`
      : year < 1000
      ? `0${year}`
      : year;
  const strMonth = month < 10 ? `0${month}` : `${month}`;
  const strDate = date < 10 ? `0${date}` : `${date}`;

  return `${strYear}-${strMonth}-${strDate}`;
}
</script>

<style scoped></style>

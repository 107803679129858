<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="lockerIssuePopup"
      :header="`락카 발행 (${lockerIssueList.length}인)`"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :close="onLockerIssuePopupClosed"
      width="450"
      height="580"
    >
      <div class="window" @keydown.capture="onSelectKeydown">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section section-02">
                  <div class="section-body" style="border-top-color: #000; overflow: hidden !important;">
                    <div class="body-box" style="padding: 0 18px 18px 18px; height:100%">
                      <div
                        v-for="lockerIssueInfo in lockerIssueList"
                        :key="lockerIssueInfo._rid"
                      >
                        <div style="color: #000; height: 712px" :id="lockerIssueInfo._rid">
                          <div
                            style="padding: 46px 68px 30px 68px"
                          >
                            <img src="../../../assets/images/logo/logo_400px.png" style="width: 100%; height: 100%; object-fit: cover">
                          </div>
                          <div
                            style="
                              position: relative;
                              border-bottom: 0px dashed #000;
                            "
                          >
                            <div style="text-align: center; line-height: 0;">
                              <div
                                style="display: flex; flex-direction: column; align-items: center; line-height: 1.5"
                              >
                                <div style="text-align: center;">
                                  <div
                                    style="
                                      float: left;
                                      font-size: 48px;
                                      font-weight: bold;
                                      margin-bottom: 26px;
                                      letter-spacing: 2px;
                                    "
                                  >
                                    <!-- {{ lockerIssueInfo.sexCode === "M" ? lockerIssueInfo.sectorPrtMale : lockerIssueInfo.sectorPrtFemale }} <span style="font-size: 28px">-</span> {{ lockerIssueInfo.lockerPrtNo }}-->
                                    {{commonCodesGetComName("SEX_CODE", lockerIssueInfo.sexCode)}} {{ lockerIssueInfo.lockerPrtNo }}
                                  </div>
                                </div>
                                <div style="margin-top: 6px;">
                                  <div style="font-size: 30px; font-weight: bold; letter-spacing: 1px">
                                    {{ isMaskingName ? lockerIssueInfo.maskingName : lockerIssueInfo.visitName }} 님 | {{ lockerIssueInfo.startTime }}
                                  </div>
                                </div>
                                <div style="clear: both; height: 0"></div>
                              </div>
                            </div>
                            <div>
                              <div class="f-move" style="position: absolute; top: 15px; right: 0" @click="onMovePage('prev')">▲</div>
                            </div>
                            <div>
                              <div class="f-move" style="position: absolute; top: 40px; right: 0" @click="onMovePage('next')">▼</div>
                            </div>
                            <div class="button" style="top: 65px;">
                              <erp-button button-div="PRINT" :is-icon-custom="true" :id="lockerIssueInfo._rid + 'LockerIssueButton'" @click.native=" onLockerIssuePopup(lockerIssueInfo)">발행</erp-button>
                            </div>
                          </div>
                          <div
                            style="
                              position: relative;
                              border-bottom: 0px dashed #000;
                              padding: 26px 0 14px 0;
                              font-size: 16px;
                            "
                          >
                            <div style="font-weight: bold;">락커 사용법</div>
                            <div style="padding-left: 16px">
                              - 잠김 / 열림은 비밀번호 4자리를 입력해 주시기 바랍니다.
                            </div>
                          </div>
                          <div style=" text-align: center; padding-top: 4px;">
                            <div style="padding-top: 4px;">
                              {{ moment(lockerIssueInfo.visitDate).format("YYYY.MM.DD") }}({{ moment(lockerIssueInfo.visitDate).format("dd")}})
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li style="margin-top: 6px; margin-right: 9px;">
              <label>
                <input
                  :class="[
                    'f-check',
                    isSmsSend ? 'active' : undefined,
                  ]"
                  style="float: left;"
                  type="checkbox"
                  v-model="isSmsSend"
                  @change="sessionSave('lockerIsSmsSend')"
                >
                <div style="float: left; user-select: none;">
                  SMS 발송
                </div>
              </label>
              <label>
                <input
                    :class="[
                    'f-check',
                    isLockerSend ? 'active' : undefined,
                  ]"
                    style="float: left;"
                    type="checkbox"
                    v-model="isLockerSend"
                    @change="sessionSave('lockerIsLockerSend')"
                >
                <div style="float: left; user-select: none;">
                  용지 인쇄
                </div>
              </label>
              <label>
                <input
                    :class="[
                    'f-check',
                    isMaskingName ? 'active' : undefined,
                  ]"
                    style="float: left;"
                    type="checkbox"
                    v-model="isMaskingName"
                >
                <div style="float: left; user-select: none;">
                  마스킹표시
                </div>
              </label>
            </li>
            <li class="print keyColor">
              <erp-button
                  button-div="PRINT"
                  :is-icon-custom="true"
                  :is-custom-shortcut-button="true"
                  :shortcut-key="'LockerIssuePopup.shortcut.print'"
                  :shortcut="{key:'F6'}"
                  @click.native="onBatchLockerIssuePopup"
              >
                일괄발행
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeLockerIssuePopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>



<script>
import ErpButton from "@/components/button/ErpButton.vue";
import {
  commonCodesGetCommonCodeAttrbNameByCodeAndIdx,
  commonCodesGetComName,
  commonCodesGetStandardInfo,
} from "@/utils/commonCodes";
import BillPrintUtil from "@/utils/billPrintUtil";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {getFormattedTelNumber} from "@/utils/string";
import {SHORTCUT_KEYS} from "@/utils/KeyboardUtil";
import moment from "moment";
import {maskingNameFormat} from "@/utils/formatter";
export default {
  name: "LockerIssuePopup",
  components: {
    ErpButton
  },
  mixins: [confirmDialogMixin],
  data() {
    return {
      lockerIssueList: [],
      lockerIssueInfoObj: {},
      lockerIssueIndex: 0,
      smsWords: null,
      smsSendInfo: {
        type: "LOCKER_ASSIGN",
        message: null,
        bizName: commonCodesGetStandardInfo("bizName"),
        bizNameLocation: commonCodesGetStandardInfo("bizNameLocation"),
        dsptchNo: null,
        kakaoSendFlag: false,
        kakaoKind: null,
        kakaoDispatchKey: commonCodesGetStandardInfo("kakaoDsptchKey"),
        kakaoTemplateCode: null,
        subject: null,
        aditWords: null
      },
      isMaskingName: false,
      isSmsSend: this.changeSessionToBoolean(sessionStorage.getItem("lockerIsSmsSend")),
      isLockerSend: this.changeSessionToBoolean(sessionStorage.getItem("lockerIsLockerSend")),
    };
  },
  computed: {
    lockerIssueShortcutButtonProps() {
      return {
        shortcutKey: "LockerIssuePopup.shortcuts.batchLockerIssue",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F6
        }
      };
    },
  },
  methods: {
    maskingNameFormat,
    moment,
    commonCodesGetComName,
    sessionSave(div) {
      const boolean = this.changeSessionToBoolean(sessionStorage.getItem(div));
      const sessionBoolean = (boolean === true) ? "false" : "true";
      sessionStorage.setItem(div,sessionBoolean);
    },

    changeSessionToBoolean(sessionName) {
      if(sessionName===null){
        return false;
      }else {
        return !!(sessionName === "true");
      }
    },
    async showLockerIssuePopup(lockerIssueSelectedRows, lockerIssueInfoObj) {
      this.lockerIssueList = lockerIssueSelectedRows.map((m)=> ({
        ...m,
        maskingName: this.maskingNameFormat(m.visitName)
      }));
      this.lockerIssueInfoObj = lockerIssueInfoObj;
      this.lockerIssueIndex = lockerIssueSelectedRows
          ?.findIndex(item => item._rid === lockerIssueInfoObj._rid);

      this.smsWords = await GolfErpAPI.fetchAllSmsWords();

      const smsSendType = this.smsWords.find(
        (data) => data.smsSendType === this.smsSendInfo.type
      );
      this.smsSendInfo.message = smsSendType.smsWords;
      this.smsSendInfo.aditWords = smsSendType.aditWords;
      this.smsSendInfo.kakaoSendFlag = smsSendType.kakaoSendFlag;
      this.smsSendInfo.kakaoTemplateCode = smsSendType.kakaoTmplatCode;
      this.smsSendInfo.kakaoKind = smsSendType.smsKind;
      this.smsSendInfo.subject = smsSendType.smsSendTypeName;
      this.smsSendInfo.dsptchNo = getFormattedTelNumber(smsSendType.dsptchNo);

      // 해당 내장객 락카 발행 영역으로 스크롤 이동.
      const rid = lockerIssueInfoObj._rid;
      if (rid) {
        this.toScrollIntoView(rid);
      }
    },
    toScrollIntoView(rid) {
      const visitElement = document.getElementById(rid);
      if (visitElement) {
        visitElement.scrollIntoView();
        // 해당 내장객의 락카 발행 버튼으로 포커스 이동.
        const lockerIssueButtonElement = document.getElementById(rid + "LockerIssueButton");
        if (lockerIssueButtonElement) {
          lockerIssueButtonElement.focus();
        }
      }
    },
    onLockerIssuePopupClosed() {
      this.$emit("popupClosed");
    },
    closeLockerIssuePopup() {
      this.$refs.lockerIssuePopup.hide();
    },
    async onLockerIssuePopup(lockerIssueInfo) { // 발행
      if(!this.isLockerSend && !this.isSmsSend){
        this.errorToast("락커 발행은 SMS 발송, 용지 인쇄 중 한가지는 필수 입니다.");
        return;
      }
      const printSetData = {
        ...lockerIssueInfo,
        visitName: this.isMaskingName ? lockerIssueInfo.maskingName : lockerIssueInfo.visitName,
        originName: lockerIssueInfo.visitName,
        lockerNo: lockerIssueInfo.lockerPrtNo,
        startCourse: commonCodesGetComName(
          "COURSE_CODE",
          lockerIssueInfo.startCourse
        ),
        sexName: commonCodesGetComName("SEX_CODE", lockerIssueInfo.sexCode),
        dwName: moment(lockerIssueInfo.visitDate).format("dd"),
        // 성별 변경 후 저장 안하고 락카 발행시 해당 성별로 락카 구역 발행되기 위함
        sectorPrt: lockerIssueInfo.sexCode === "M" ? lockerIssueInfo.sectorPrtMale : lockerIssueInfo.sectorPrtFemale,
      };

      if (this.isSmsSend) {
        try{
          await this.smsSend([printSetData]);
          this.infoToast("SMS전송이 완료되었습니다");
        } catch (e) {
          this.errorToast("SMS전송이 실패했습니다");
        }
      }
      if (this.isLockerSend){
        // 디바이스 설정의 Bill 프린터 사용여부가 false이면 Bill 프린터 출력 안됨.
        const config = {
          "useBillPrinter": true,
          "useKitchenPrinter": false,
        };
        await BillPrintUtil.getTsConfReceiptInfo.call(this, "LOCKER_KEY", true, printSetData, config);
      }
    },
    onMovePage(from) {
      const len = this.lockerIssueList.length;
      if (from === 'prev') {
        this.lockerIssueIndex = this.lockerIssueIndex === 0
            ? len - 1
            : this.lockerIssueIndex - 1;
      } else {
        this.lockerIssueIndex = len <= this.lockerIssueIndex + 1
            ? 0
            : this.lockerIssueIndex + 1;
      }
      const rid = this.lockerIssueList[this.lockerIssueIndex]?._rid;
      this.toScrollIntoView(rid);
    },
    onSelectKeydown(event) {
      if (event.key === "ArrowUp") {
        this.onMovePage('prev');
      } else if (event.key === "ArrowDown") {
        this.onMovePage('next');
      }
    },
    async onBatchLockerIssuePopup() {
      // 디바이스 설정의 Bill 프린터 사용여부가 false이면 Bill 프린터 출력 안됨.
      if(!this.isLockerSend && !this.isSmsSend){
        this.errorToast("락커 발행은 SMS 발송, 용지 인쇄 중 한가지는 필수 입니다.");
        return;
      }
      let smsSendData = [];
      let printSetDataReq = [];

      this.lockerIssueList.forEach((data) => {
        const printSetData = {
          visitId: data.visitId,
          visitDate: data.visitDate,
          startCourse: commonCodesGetComName("COURSE_CODE", data.startCourse),
          startTime: data.startTime,
          lockerNo: data.lockerPrtNo,
          // 성별 변경 후 저장 안하고 락카 발행시 해당 성별로 락카 구역 발행되기 위함
          sectorPrt: data.sexCode === "M" ? data.sectorPrtMale : data.sectorPrtFemale,
          sexName: commonCodesGetComName("SEX_CODE", data.sexCode),
          originName: data.visitName,
          visitName: this.isMaskingName ? data.maskingName : data.visitName,
          contactTel: data.contactTel,
          dwName: moment(data.visitDate).format("dd"),
          memberDiv: data.memberDiv,
        };


        if (this.isSmsSend) {
          smsSendData.push(printSetData);
        }
        if (this.isLockerSend){
          printSetDataReq.push(printSetData);
        }
      });
      if (this.isSmsSend) {
        await this.smsSend(smsSendData);
      }
      if(this.isLockerSend){
        const config = {
          "useBillPrinter": true,
          "useKitchenPrinter": false,
        };
        await BillPrintUtil.getTsConfReceiptInfo.call(this, "LOCKER_KEY" , true , printSetDataReq , config);
      }
    },
    async smsSend(smsSendData) {
      let message = [];
      message.push(this.smsSendInfo.message);
      if (this.smsSendInfo.aditWords) {
        message.push(this.smsSendInfo.aditWords);
      }
      if (this.smsSendInfo.bizNameLocation === "DOWN") {
        message.push(this.smsSendInfo.bizName);
      }
      message = message.join("\n");
      let smsRequestList = [];
      for (const data of smsSendData) {
        if (!data.contactTel) {
          continue;
        }
        let finalMessage = message;
        // 무인 체크인 내용 추가
        if (
          commonCodesGetStandardInfo("noneChkinUseFlag") &&
          commonCodesGetStandardInfo("noneChkinSmsVisitWords") &&
          this.lockerIssueInfoObj.resveId &&
          this.lockerIssueInfoObj.chkinId &&
          data.visitId
        ) {
          const selfCheckOutUrl =
            await GolfErpAPI.fetchSelfCheckOutUrl({
              resveId: this.lockerIssueInfoObj.resveId,
              chkinId: this.lockerIssueInfoObj.chkinId,
              visitId: data.visitId,
            });
          finalMessage += `\n${commonCodesGetStandardInfo("noneChkinSmsVisitWords")}\n`;
          finalMessage = finalMessage.replace("[SELF_PAYMENT]$", selfCheckOutUrl);
          finalMessage = finalMessage.replaceAll("[FRONT_NAME]$", commonCodesGetStandardInfo("noneChkinName"));
        }
        const smsInfo = {
          type: this.smsSendInfo.type,
          kakaoFlag: this.smsSendInfo.kakaoSendFlag,
          smsKind: this.smsSendInfo.kakaoSendFlag
            ? this.smsSendInfo.kakaoKind
            : null,
          kakaoDispatchKey: this.smsSendInfo.kakaoSendFlag
            ? this.smsSendInfo.kakaoDispatchKey
            : null,
          kakaoTemplateKey: this.smsSendInfo.kakaoSendFlag
            ? this.smsSendInfo.kakaoTemplateCode
            : null,
          key: data.visitId,
          reservedFlag: false,
          reserveDateTime: null,
          receiverName: data.originName,
          receiverNumber: data.contactTel,
          subject: this.smsSendInfo.subject,
          message: finalMessage,
          replaceDataMap: {
            resveDate: data.visitDate,
            resveCourse: data.startCourse + "코스",
            resveTime: data.startTime,
            resveCourseTime: data.startCourse + " " + data.startTime + " (1팀)",
            visitName: data.visitName + " " + commonCodesGetCommonCodeAttrbNameByCodeAndIdx("MEMBER_DIV", data.memberDiv, 1),
            lockerNo: data.lockerNo,
            headerMemo: this.smsSendInfo.bizNameLocation === "UP" ? (this.smsSendInfo.bizName ? this.smsSendInfo.bizName : null) : null,
            dwName: commonCodesGetComName("DW_CODE", this.lockerIssueInfoObj.dwCode),
            sexName: data.sexName,
          },
          dispatchNumber: this.smsSendInfo.dsptchNo.replace(/-/gi, ""),
          sender: "ERP",
        };
        smsRequestList.push(smsInfo);
      }
      if (smsRequestList.length > 0) {
        await GolfErpAPI.sendSMS(smsRequestList);
      }
    },
  },
};
</script>
<style scoped>
@supports (display: flow-root) {
  body .window .windowContent .article-section.section-01 .body-box ul {display: flow-root;}
}
body .window .windowContent .article-section.section-01 {height: auto;}
body .window .windowContent .article-section.section-01 .section-body {overflow: visible;border: none;}
body .window .windowContent .article-section.section-01 .body-box {padding: 6px 0;color: #000;background-color: #f9f9f9;}
body .window .windowContent .article-section.section-01 .body-box ul {display: block;list-style: none;padding: 0;margin: 0;}
body .window .windowContent .article-section.section-01 .body-box ul:after {display: block;clear: both;height: 0;font-size: 0;line-height: 0;content: "";}
body .window .windowContent .article-section.section-01 .body-box ul li {float: left;padding: 3px 12px 3px 13px;margin: 0 0 -1px 0;background: transparent url("../../../assets/images/common/division.png") no-repeat left center;}
body .window .windowContent .article-section.section-01 .body-box ul li:first-child {padding: 3px 12px;background: none;}
body .window .windowContent .article-section.section-02 {height: 100%;}
body .window .windowContent .article-section.section-02 .button {position: absolute;right: 0;top: 62px;}
body .window .windowContent .article-section.section-02 .button >>> button {height: 36px;}
body .window .windowContent .article-section.section-02 .button >>>.e-btn,
body .window .windowContent .article-section.section-02 .button >>>.e-btn:hover,
body .window .windowContent .article-section.section-02 .button >>>.e-btn:focus {box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.03);}
body .window .windowContent .article-section.section-02 .button >>>.e-btn {padding: 4px 7px 2px 7px;border-color: #e0e0e0;border-radius: 3px;background-color: #fff;color: #666;font-size: 12px;}
body .window .windowContent .article-section.section-02 .button >>>.e-btn:hover,
body .window .windowContent .article-section.section-02 .button >>>.e-btn:focus {background-color: #f0f0f0;}
/* 임시 퍼블리싱 */
body .f-check {width: 13px;height: 13px;-webkit-appearance: none;-moz-appearance: none;border-color: #398a6a;background-color: #398a6a;cursor: pointer;}
body .f-check:not(.active) {border: 1px solid #e0e0e0;background-color: #fff;}
body .f-check.active:before {display: block;width: 7px;height: 7px;margin: 3px;background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAA/CAYAAAAse9ITAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA25pVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo1YmQyNTVjNC1kODhlLWJiNDItOTI4MC03NGM1YjllNGM5NmMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NzZDQTUyRTFDNTg4MTFFOThBMjU4RTkxRDYyODBDMEUiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NzZDQTUyRTBDNTg4MTFFOThBMjU4RTkxRDYyODBDMEUiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTggKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6Qjg5NEYzMkJDNTg0MTFFOThENTc5MUY1MDFDNzIwMUYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6Qjg5NEYzMkNDNTg0MTFFOThENTc5MUY1MDFDNzIwMUYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7ftzCXAAAAaUlEQVR42mL8//8/Ay7AxIAb/Mcl+R+m8z82CSBgZEITgEvAdDJik0B2ECO6BLprGdFdxXLQwmK4eEVC//dw8QqX3ffh4hWfC/rDxSvMf6KHi1c0z/4cLl7R//d/uHjlrvbuoeMVgAADAJBhTr8wER99AAAAAElFTkSuQmCC) no-repeat center 0;content: '';}
body .f-move {display: flex;align-items: center;justify-content: center;padding-right: 1px;width: 39px;height: 23px;border-color: #2e7458;border-radius: 3px;background-color: #2e7458;background-position: left 0;color: #fff;user-select: none;cursor: pointer;}
body .f-move:hover {border-color: #174e35;background-color: #174e35;}
</style>
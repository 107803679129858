<template>
  <div id="popupWrapper">
    <ejs-dialog
        ref="dialog"
        header="디바이스 설정"
        v-bind="dialogProps"
        :width="500"
        :isModal="true"
        :showCloseIcon="true"
        :allowDragging="true"
        :animationSettings="{ effect: 'None' }"
        @close="handleDialogClose"
        @overlayClick="handleDialogOverlayClick"
    >
      <div class="window deviceSetting">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">POS</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <div class="title">
                                POS 번호
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-textbox
                                          ref="posNo"
                                          autocomplete="off"
                                          width="200"
                                          v-model="deviceSettingInfo.posNo"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">
                                사용코드
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div>
                                      {{ commonCodesGetComName('PC_USE_CODE',deviceSettingInfo.pcUseCode) }}
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-02">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">
                        VAN 설정
                      </div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <div class="title">
                                사용여부
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                              type="checkbox"
                                              v-model="deviceSettingInfo.isCardUse"
                                          />
                                          <i />
                                          <div class="label">
                                            사용
                                          </div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">
                                포트
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                          style="width: 200px"
                                          v-model="deviceSettingInfo.cardDaemonPort"
                                          :disabled="!deviceSettingInfo.isCardUse"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-03">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">
                        Bill 프린터 통신 설정
                      </div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <div class="title">
                                사용여부
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                              type="checkbox"
                                              v-model="deviceSettingInfo.isSerialUse"
                                          />
                                          <i />
                                          <div class="label">
                                            사용
                                          </div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field port">
                              <div class="title">
                                포트
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input" style="width: 54px">
                                    <div class="form">
                                      <input-text
                                          v-model="deviceSettingInfo.serialDaemonPort"
                                          :disabled="!deviceSettingInfo.isSerialUse"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group button">
                                    <ul class="button">
                                      <li>
                                        <erp-button
                                            button-div="GET"
                                            :disabled="!deviceSettingInfo.isSerialUse"
                                            :menu-id="397"
                                            @click.native="getSerialPortList"
                                        >
                                          시리얼포트 가져오기
                                        </erp-button>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
                <div class="article-left">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-04">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">
                          Bill 프린터 사용 설정
                        </div>
                      </div>
                    </div>
                    <div class="section-body">
                      <div class="body-data">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul class="data-content">
                              <li class="field">
                                <div class="title">
                                  사용여부
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group check">
                                      <!-- 포커스 : focus -->
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                                type="checkbox"
                                                v-model="deviceSettingInfo.isBillPrinterUse"
                                                @change="printerUseClickCheckBox($event,deviceSettingInfo.isBillPrinterUse,'port')"
                                            />
                                            <i />
                                            <div class="label">
                                              사용
                                            </div>
                                          </label>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field">
                                <div class="title">
                                  통신 방법
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <div class="form">
                                        <ejs-dropdownlist
                                            ref="portBillPrinterCommunication"
                                            cssClass="body-data-dropdown"
                                            v-model="deviceSettingInfo.billPrinterCommunication"
                                            :dataSource="billPrinterCommunicationList"
                                            :fields="billPrinterCommunicationFields"
                                            :enabled="deviceSettingInfo.isBillPrinterUse"
                                            :allowFiltering="false"
                                            @change="onBillPrinterCommunicationChanged"
                                        />
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field">
                                <div class="title">
                                  통신포트
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <div class="form">
                                        <ejs-dropdownlist
                                            ref="portSerialPortListDropdownList"
                                            cssClass="body-data-dropdown"
                                            v-model="deviceSettingInfo.billPrinterComPort"
                                            :dataSource="serialPortList"
                                            :fields="serialPortFields"
                                            :enabled="deviceSettingInfo.isBillPrinterUse && deviceSettingInfo.billPrinterCommunication === 'PORT'"
                                            :allowFiltering="false"
                                            @click.native="getSerialPortList('billPrinter')"
                                        />
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field">
                                <div class="title">
                                  비트/초
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <div class="form">
                                        <ejs-dropdownlist
                                            cssClass="body-data-dropdown"
                                            v-model="deviceSettingInfo.billPrinterBitRate"
                                            :dataSource="bitRateList"
                                            :enabled="deviceSettingInfo.isBillPrinterUse && deviceSettingInfo.billPrinterCommunication === 'PORT'"
                                            :allowFiltering="false"
                                        />
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field">
                                <div class="title">
                                  IP주소
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <div class="form">
                                        <input-text
                                            v-model="deviceSettingInfo.billPrinterIpAddress"
                                            :enabled="deviceSettingInfo.isBillPrinterUse && deviceSettingInfo.billPrinterCommunication !== 'PORT'"
                                        />
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field">
                                <div class="title">
                                  포트
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <div class="form">
                                        <input-text
                                            v-model="deviceSettingInfo.billPrinterIpPort"
                                            :enabled="deviceSettingInfo.isBillPrinterUse && deviceSettingInfo.billPrinterCommunication !== 'PORT'"
                                        />
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field">
                                <div class="title">
                                  디바이스ID
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <div class="form">
                                        <input-text
                                            v-model="deviceSettingInfo.billPrinterDeviceId"
                                            :enabled="deviceSettingInfo.isBillPrinterUse && deviceSettingInfo.billPrinterCommunication !== 'PORT'"
                                        />
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <!-- 빅솔론 테스트 버튼 -->
                              <li class="field">
                                <div class="title">
                                  테스트
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group button">
                                      <ul class="button">
                                        <li>
                                          <erp-button button-div="GET" :disabled = "deviceSettingInfo.billPrinterCommunication!=='WEBSDK'"
                                                      :menu-id="397" @click.native="onClickConnectWebSdk('bill')">
                                            접속확인
                                          </erp-button>
                                        </li>
                                        <li>
                                          <erp-button button-div="GET" :disabled = "deviceSettingInfo.billPrinterCommunication!=='WEBSDK'"
                                                      :menu-id="397" @click.native="onClickTestPrint('bill')">
                                            프린트
                                          </erp-button>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                  </section>
                </div>
                <div class="article-right">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-05">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">
                          네트워크 (IP) 프린터 사용 설정
                        </div>
                      </div>
                    </div>
                    <div class="section-body">
                      <div class="body-data">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul class="data-content">
                              <li class="field">
                                <div class="title">
                                  사용여부
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group check">
                                      <!-- 포커스 : focus -->
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                                type="checkbox"
                                                v-model="deviceSettingInfo.isKitchenBillPrinterUse"
                                                @change="printerUseClickCheckBox($event,deviceSettingInfo.isKitchenBillPrinterUse,'ip')"
                                            />
                                            <i />
                                            <div class="label">
                                              사용
                                            </div>
                                          </label>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field">
                                <div class="title">
                                  통신 방법
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <div class="form">
                                        <ejs-dropdownlist
                                            ref="ipBillPrinterCommunication"
                                            cssClass="body-data-dropdown"
                                            v-model="deviceSettingInfo.kitchenBillPrinterCommunication"
                                            :dataSource="billPrinterCommunicationList"
                                            :fields="billPrinterCommunicationFields"
                                            :enabled="deviceSettingInfo.isKitchenBillPrinterUse"
                                            :allowFiltering="false"
                                            @change="onKitchenBillPrinterCommunicationChanged"
                                        />
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field">
                                <div class="title">
                                  통신포트
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <div class="form">
                                        <ejs-dropdownlist
                                            ref="ipSerialPortListDropdownList"
                                            cssClass="body-data-dropdown"
                                            v-model="deviceSettingInfo.kitchenBillPrinterComPort"
                                            :dataSource="serialPortList"
                                            :fields="serialPortFields"
                                            :enabled="deviceSettingInfo.isKitchenBillPrinterUse && deviceSettingInfo.kitchenBillPrinterCommunication === 'PORT'"
                                            :allowFiltering="false"
                                            @click.native="getSerialPortList('kitchenBillPrinter')"
                                        />
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field">
                                <div class="title">
                                  비트/초
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <div class="form">
                                        <ejs-dropdownlist
                                            cssClass="body-data-dropdown"
                                            v-model="deviceSettingInfo.kitchenBillPrinterBitRate"
                                            :dataSource="bitRateList"
                                            :enabled="deviceSettingInfo.isKitchenBillPrinterUse && deviceSettingInfo.kitchenBillPrinterCommunication === 'PORT'"
                                            :allowFiltering="false"
                                        />
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field">
                                <div class="title">
                                  IP주소
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <div class="form">
                                        <input-text
                                            v-model="deviceSettingInfo.kitchenBillPrinterIpAddress"
                                            :enabled="deviceSettingInfo.isKitchenBillPrinterUse && deviceSettingInfo.kitchenBillPrinterCommunication !== 'PORT'"
                                        />
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field">
                                <div class="title">
                                  포트
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <div class="form">
                                        <input-text
                                            v-model="deviceSettingInfo.kitchenBillPrinterIpPort"
                                            :enabled="deviceSettingInfo.isKitchenBillPrinterUse && deviceSettingInfo.kitchenBillPrinterCommunication !== 'PORT'"
                                        />
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field">
                                <div class="title">
                                  디바이스ID
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <div class="form">
                                        <input-text
                                            v-model="deviceSettingInfo.kitchenBillPrinterDeviceId"
                                            :enabled="deviceSettingInfo.isKitchenBillPrinterUse && deviceSettingInfo.kitchenBillPrinterCommunication !== 'PORT'"
                                        />
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field">
                                <div class="title">
                                  테스트
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group button">
                                      <ul class="button">
                                        <li>
                                          <erp-button button-div="GET" :disabled = "deviceSettingInfo.kitchenBillPrinterCommunication!=='WEBSDK'"
                                                      :menu-id="397" @click.native="onClickConnectWebSdk('kitchen')">
                                            접속확인
                                          </erp-button>
                                        </li>
                                        <li>
                                          <erp-button button-div="GET" :disabled = "deviceSettingInfo.kitchenBillPrinterCommunication!=='WEBSDK'"
                                                      :menu-id="397" @click.native="onClickTestPrint('kitchen')">
                                            프린트
                                          </erp-button>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                  </section>
                </div>
                <section
                    class="article-section section-06"
                    :disabled="commonMixinIsButtonDisableByAuth('commonClientMonitorImageGet')"
                >
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">
                        고객 모니터 사용 설정
                      </div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <div class="title">
                                사용여부
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                              type="checkbox"
                                              v-model="deviceSettingInfo.isClientMonitorUse"
                                              @change="onChangeClientMonitorUse"
                                          />
                                          <i />
                                          <div class="label">
                                            사용
                                          </div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                  <li class="item form-group button">
                                    <ul class="button">
                                      <li>
                                        <erp-button button-div="GET" :menu-id="397" @click.native="onClickClientMonitorPatterImageConfig()">
                                          이미지 삽입
                                        </erp-button>
                                      </li>
                                      <li>
                                        <erp-button
                                            button-div="FILE"
                                            :disabled="!deviceSettingInfo.isClientMonitorUse"
                                            :menu-id="397"
                                            @click.native="onClickClientMonitorInstallOrOpen(true)"
                                        >
                                          고객 모니터 설치
                                        </erp-button>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">
                                전환 간격
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input" style="width: 54px">
                                    <div class="form">
                                      <input-number
                                          id="interval"
                                          ref="interval"
                                          placeholder="초 단위"
                                          v-model="deviceSettingInfo.clientMonitorInterval"
                                          :disabled="!deviceSettingInfo.isClientMonitorUse"
                                          :propMaxLength="3"
                                          :propMaxUnderDotLength="1"
                                          :allowDot="false"
                                          :allowMinus="true"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">
                                    초 간격
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">
                                전환 방식
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          id="effect"
                                          ref="effect"
                                          cssClass="body-data-dropdown"
                                          v-model="deviceSettingInfo.clientMonitorEffect"
                                          :dataSource="effectOptions"
                                          :fields="fields"
                                          :enabled="deviceSettingInfo.isClientMonitorUse"
                                          :allowFiltering="false"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">
                                패턴 타입
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          id="imagePattern"
                                          ref="imagePattern"
                                          cssClass="body-data-dropdown"
                                          v-model="deviceSettingInfo.clientMonitorImagePattern"
                                          :dataSource="imagePatternOptions"
                                          :fields="fields"
                                          :enabled="deviceSettingInfo.isClientMonitorUse"
                                          :allowFiltering="false"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
                <div class="article-left">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-07">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">
                          ERP 홀드 이미지 설정
                        </div>
                      </div>
                    </div>
                    <div class="section-body">
                      <div class="body-data">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul class="data-content">
                              <li class="field">
                                <div class="title">
                                  이미지
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group button">
                                      <ul class="button">
                                        <li>
                                          <erp-button button-div="SAVE" :menu-id="397" @click.native="onClickClientMonitorPatterImageConfig('erp-hold')">
                                            이미지 삽입
                                          </erp-button>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                  </section>
                </div>
                <div class="article-right">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-08">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">
                          키오스크 이미지 설정
                        </div>
                      </div>
                    </div>
                    <div class="section-body">
                      <div class="body-data">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul class="data-content">
                              <li class="field">
                                <div class="title">
                                  이미지
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group button">
                                      <ul class="button">
                                        <li>
                                          <erp-button button-div="SAVE" :menu-id="397" @click.native="onClickClientMonitorPatterImageConfig('kiosk')">
                                            이미지 삽입
                                          </erp-button>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                  </section>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button button-div="SAVE" :menu-id="397" @click.native="saveDeviceInfo">
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" :menu-id="397" @click.native="handleDialogCloseClick">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <client-monitor-pattern-image-config-popup
        v-if="isClientMonitorConfigPatternImagePopupOpened"
        ref="clientMonitorConfigPatternImagePopup"
        @popupClosed="onClientMonitorPatternImageConfigPopupClosed"
        @popupConfirmed="onClientMonitorPatternImageConfigPopupConfirmed"
    />
  </div>
</template>

<script>
import {sortBy as _sortBy} from 'lodash';
import {saveDeviceInfo, getDeviceInfo} from '@/utils/device';
import {commonCodesGetCommonCodeByIdxAttrb,commonCodesGetComName} from '@/utils/commonCodes';
import BillPrintUtil from '@/utils/billPrintUtil';
import InputText from '@/components/common/text/InputText';
import InputNumber from '@/components/common/InputNumber';
import ClientMonitorPatternImageConfigPopup from '@/views/store-management/sales-registration/store-pos-renew/popups/ClientMonitorPatternImageConfigPopup';
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import ErpButton from "@/components/button/ErpButton";

export default {
  name: 'DeviceSettingPopup',
  components: {
    InputText,
    InputNumber,
    ClientMonitorPatternImageConfigPopup,
    ErpButton,
  },
  mixins: [
    commonMixin,
    confirmDialogMixin,
  ],
  data() {
    return {
      deviceSettingInfo: getDeviceInfo(), // device.js 로부터 바로 설정
      serialPortList: [],
      billPrinterCommunicationList: [
        {
          text: "포트",
          value: "PORT"
        },
        {
          text: "IP",
          value: "IP"
        },
        {
          text: "BIXOLON WEB",
          value: "WEBSDK"
        }
      ],
      billPrinterCommunicationFields: {
        text: "text",
        value: "value",
      },
      serialPortFields: {
        text: 'path',
        value: 'path',
      },
      bitRateList: [9600, 19200, 38400, 57600, 115200],
      fields: {text: 'comName', value: 'comCode'},
      effectOptions: [
        {comCode: 'NONE', comName: 'N/A'},
        {comCode: 'FADE', comName: '페이드'},
        {comCode: 'SLIDE', comName: '슬라이드'},
      ],
      imagePatternOptions: commonCodesGetCommonCodeByIdxAttrb(
          'IMAGE_DIV',
          1,
          'MONTR',
      )
          ?.filter(item =>
              item.comName !== 'Hold' &&
              item.comName !== 'Kiosk'
          ),
      imageList: [],
      isClientMonitorConfigPatternImagePopupOpened: false,
    };
  },
  watch: {
    'deviceSettingInfo.isSerialUse': function(newVal) {
      if (!newVal) {
        this.clearComPorts();
      }
    },
    'deviceSettingInfo.billPrinterCommunication': function(newVal,oldVal) {
      this.printerCommunicationChange(newVal,oldVal,"port");
    },
    'deviceSettingInfo.kitchenBillPrinterCommunication': function(newVal,oldVal) {
      this.printerCommunicationChange(newVal,oldVal,"ip");
    },
    'deviceSettingInfo.billPrinterComPort': function(newVal,oldVal) {
      this.serialPortFieldsChange(newVal,oldVal,"port");
    },
    'deviceSettingInfo.kitchenBillPrinterComPort': function(newVal,oldVal) {
      this.serialPortFieldsChange(newVal,oldVal,"ip");
    },
  },
  async mounted() {
    if (
        this.deviceSettingInfo.isSerialUse &&
        this.deviceSettingInfo.billPrinterCommunication === "PORT"
    ) {
      await this.getSerialPortList("billPrinter");
    }
    if (
        this.deviceSettingInfo.isKitchenBillPrinterUse &&
        this.deviceSettingInfo.kitchenBillPrinterCommunication === "PORT"
    ) {
      await this.getSerialPortList("kitchenBillPrinter");
    }
    if (this.deviceSettingInfo.isClientMonitorUse) {
      await this.fetchClientMonitorImages();
    }
  },
  computed: {
    isDevelopment() {
      return process.env.NODE_ENV === 'development';
    },
    dialogProps() {
      return {
        // target: "#popupWrapper",
        isModal: true,
        animationSettings: {effect: 'None'},
        position: {x: 'center', y: 'center'},
        visible: true,
      };
    },
  },
  methods: {
    commonCodesGetComName,
    printerUseClickCheckBox(event,val,state) {
      if(
          val
          && (this.deviceSettingInfo.billPrinterComPort === this.deviceSettingInfo.kitchenBillPrinterComPort)
          && (this.deviceSettingInfo.billPrinterComPort && this.deviceSettingInfo.kitchenBillPrinterComPort)
          && (this.deviceSettingInfo.isBillPrinterUse && this.deviceSettingInfo.isKitchenBillPrinterUse)
          && (this.deviceSettingInfo.billPrinterCommunication === "PORT" && this.deviceSettingInfo.kitchenBillPrinterCommunication === "PORT")
      ) {
        this.errorToast("같은 통신포트를 이용하실수 없습니다");
        state==='port' ? this.deviceSettingInfo.isBillPrinterUse = false : this.deviceSettingInfo.isKitchenBillPrinterUse = false;
        event.target.checked = false;
      }
    },
    handleDialogClose() {
      this.$emit('popupClosed');
    },
    handleDialogOverlayClick() {
      this.$refs.dialog.hide();
    },
    async saveDeviceInfo() {
      if(!this.deviceSettingInfo.posNo) {
        this.$refs.posNo.focusIn();
        return this.errorToast("POS 번호는 필수입니다");
      }
      await saveDeviceInfo(this.deviceSettingInfo);
      if(this.deviceSettingInfo.isClientMonitorUse) {
        this.sendWebSocket('config', {
          interval: this.deviceSettingInfo.clientMonitorInterval,
          effect: this.deviceSettingInfo.clientMonitorEffect,
          imageList: this.imageList,
        });
      }
      this.onOffClientMonitor();
      this.$emit('popupClosed');
      location.reload();
    },
    onOffClientMonitor() {
      this.deviceSettingInfo.isClientMonitorUse
          ? this.clientMonitorOpen()
          : this.closeWebSocket();
    },
    clientMonitorOpen() {
      this.openWebSocket({
        interval: this.deviceSettingInfo.clientMonitorInterval,
        effect: this.deviceSettingInfo.clientMonitorEffect,
        imageList: this.imageList,
      });
    },
    onChangeClientMonitorUse(args) {
      if (!args.target.checked) {
        this.closeWebSocket();
      }
    },
    async onClickClientMonitorPatterImageConfig(type = 'client-monitor') {
      const imagePattern = type === 'client-monitor'
          ? (this.deviceSettingInfo.clientMonitorImagePattern || 'MONTR_TYPE1')
          : type === 'erp-hold'
              ? 'HOLD_TYPE'
              : 'KIOSK_TYPE';
      this.isClientMonitorConfigPatternImagePopupOpened = true;
      await this.$nextTick();
      this.$refs.clientMonitorConfigPatternImagePopup.showPopup(imagePattern);
    },
    async onClickClientMonitorInstallOrOpen(isInstall) {
      const fallback = '/golf_erp_client_monitor.zip';
      const url = 'erp-monitor:open';
      if (isInstall) {
        if (!(await this.confirm('ERP 고객 모니터 프로그램을 설치하시겠습니까?'))) {
          return;
        }
      } else {
        this.clientMonitorOpen();
      }
      document.location = isInstall
          ? fallback
          : url;
    },
    async onClickClientMonitorClose() {
      if (!(await this.confirm('정말로 종료하시겠습니까?'))) {
        return;
      }
      this.closeWebSocket();
    },
    onClientMonitorPatternImageConfigPopupClosed() {
      this.isClientMonitorConfigPatternImagePopupOpened = false;
    },
    onClientMonitorPatternImageConfigPopupConfirmed(imageList) {
      this.sendWebSocket('config', imageList);
    },
    handleDialogCloseClick() {
      this.$refs.dialog.hide();
    },
    printerCommunicationChange(newVal,oldVal,state) {
      if(state === 'port') {
        if(
            this.deviceSettingInfo.isKitchenBillPrinterUse &&
            this.deviceSettingInfo.kitchenBillPrinterCommunication === 'PORT' &&
            (this.deviceSettingInfo.kitchenBillPrinterComPort === this.deviceSettingInfo.billPrinterComPort)
        ) {
          if(newVal === 'PORT'){
            this.errorToast("서로 같은 통신포트를 이용하실수 없습니다");
            this.deviceSettingInfo.billPrinterCommunication = oldVal;
            this.$refs.portBillPrinterCommunication.ej2Instances.value = oldVal;
            this.$refs.portBillPrinterCommunication.ej2Instances.text = oldVal;
            setTimeout(()=>{
              this.$refs.portBillPrinterCommunication.focusOut();
            },1);
          }
        }

      } else if (state === 'ip') {
        if(
            this.deviceSettingInfo.isBillPrinterUse &&
            this.deviceSettingInfo.billPrinterCommunication === "PORT" &&
            (this.deviceSettingInfo.billPrinterComPort === this.deviceSettingInfo.kitchenBillPrinterComPort)
        ) {
          if(newVal === 'PORT') {
            this.errorToast("서로 같은 통신포트를 이용하실수 없습니다");
            this.deviceSettingInfo.kitchenBillPrinterCommunication = oldVal;
            this.$refs.ipBillPrinterCommunication.ej2Instances.value = oldVal;
            this.$refs.ipBillPrinterCommunication.ej2Instances.text = oldVal;
            setTimeout(()=>{
              this.$refs.ipBillPrinterCommunication.focusOut();
            },1);
          }

        }
      }
    },
    async serialPortFieldsChange (newVal,oldVal,state) {
      if(
          this.deviceSettingInfo.isBillPrinterUse &&
          this.deviceSettingInfo.isKitchenBillPrinterUse &&
          this.deviceSettingInfo.billPrinterCommunication === "PORT" &&
          this.deviceSettingInfo.kitchenBillPrinterCommunication === "PORT"
      ) {
        if(state === "port") {
          if(
              (newVal === this.deviceSettingInfo.kitchenBillPrinterComPort)
          ) {
            await this.errorToast("서로 같은 통신포트를 이용하실수 없습니다");
            this.deviceSettingInfo.billPrinterComPort = oldVal;
            this.$refs.portSerialPortListDropdownList.ej2Instances.value = oldVal;
            this.$refs.portSerialPortListDropdownList.ej2Instances.text = oldVal;
            setTimeout(()=>{
              this.$refs.portSerialPortListDropdownList.focusOut();
            },1);
          }
        } else if(state === "ip") {
          if(
              (this.deviceSettingInfo.billPrinterComPort === newVal)
          ) {
            await this.errorToast("서로 같은 통신포트를 이용하실수 없습니다");
            this.deviceSettingInfo.kitchenBillPrinterComPort = oldVal;
            this.$refs.ipSerialPortListDropdownList.ej2Instances.value = oldVal;
            this.$refs.ipSerialPortListDropdownList.ej2Instances.text = oldVal;
            setTimeout(()=>{
              this.$refs.ipSerialPortListDropdownList.focusOut();
            },1);
          }
        }
      }

    },
    async getSerialPortList(div = null) {
      try {
        this.serialPortList = await BillPrintUtil.getPortList(
            this.deviceSettingInfo.serialDaemonPort,
        );
        // 조회한 serialPortList 중에 billPrinterComPort 값이 없으면 billPrinterComPort 값을 초기화한다
        try {
          if (div === 'billPrinter' || div === null) {
            if (
                this.serialPortList.findIndex(
                    (port) =>
                        port.path === this.deviceSettingInfo.billPrinterComPort,
                ) === -1
            ) {
              this.deviceSettingInfo.billPrinterComPort = null;
            }
          }
          if (div === 'kitchenBillPrinter' || div === null) {
            if (
                this.serialPortList.findIndex(
                    (port) =>
                        port.path === this.deviceSettingInfo.kitchenBillPrinterComPort,
                ) === -1
            ) {
              this.deviceSettingInfo.kitchenBillPrinterComPort = null;
            }
          }
        } catch (e) {
          if (div === 'billPrinter' || div === null) {
            this.deviceSettingInfo.billPrinterComPort = null;
          }
          if (div === 'kitchenBillPrinter' || div === null) {
            this.deviceSettingInfo.kitchenBillPrinterComPort = null;
          }
        }
      } catch (e) {
        console.error(e);
        this.errorToast('시리얼포트 정보 조회에 실패하였습니다. Bill 프린터 정보를 초기화합니다.');
        this.clearComPorts();
      }
    },
    async fetchClientMonitorImages() {
      const data = await GolfErpAPI.fetchClientMonitorImages(this.deviceSettingInfo.clientMonitorImagePattern);
      this.imageList = _sortBy(data.map(item => {
        return {
          imageId: item.id,
          imageUrl: item.path,
          sortNo: item.sortNo,
        };
      }), ["sortNo"]);
    },
    clearComPorts() {
      this.serialPortList = [];
      //this.deviceSettingInfo.isBillPrinterUse = false;
      this.deviceSettingInfo.billPrinterComPort = null;
      this.deviceSettingInfo.billPrinterBitRate = null;
      //this.deviceSettingInfo.isKitchenBillPrinterUse = false;
      this.deviceSettingInfo.kitchenBillPrinterComPort = null;
      this.deviceSettingInfo.kitchenBillPrinterBitRate = null;
    },
    async onBillPrinterCommunicationChanged() {
      if (this.deviceSettingInfo.billPrinterCommunication === "PORT") {
        await this.getSerialPortList("billPrinter");
      }
    },
    async onKitchenBillPrinterCommunicationChanged() {
      if (this.deviceSettingInfo.kitchenBillPrinterCommunication === "PORT") {
        await this.getSerialPortList("kitchenBillPrinter");
      }
    },

    //빅솔론 테스트 버튼
    async onClickConnectWebSdk(type){
      let requestURL = `https://${this.deviceSettingInfo.billPrinterIpAddress}:${this.deviceSettingInfo.billPrinterIpPort}/WebPrintSDK/${this.deviceSettingInfo.billPrinterDeviceId}/checkStatus`;
      if(type=="kitchen") {
        requestURL = `https://${this.deviceSettingInfo.kitchenBillPrinterIpAddress}:${this.deviceSettingInfo.kitchenBillPrinterIpPort}/WebPrintSDK/${this.deviceSettingInfo.kitchenBillPrinterDeviceId}/checkStatus`;
      }
      let xmlHttpCheck = false;
      let thisVue = this;
      xmlHttpCheck = new XMLHttpRequest();
      xmlHttpCheck.timeout = 4000; // time in milliseconds
      xmlHttpCheck.open('GET', requestURL, true);
      xmlHttpCheck.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xmlHttpCheck.ontimeout = (e) => {
        thisVue.errorToast("[타임아웃]프린터 IP 확인이 필요합니다.");
      };
      xmlHttpCheck.send();
      xmlHttpCheck.onreadystatechange = async function () {
        if (xmlHttpCheck.readyState == 4 && xmlHttpCheck.status == 200) {
          // let res = JSON.parse(xmlHttpCheck.responseText);
          thisVue.infoToast("접속확인 되었습니다.");
        } else if (xmlHttpCheck.readyState == 4 && xmlHttpCheck.status == 404) {
          thisVue.errorToast("디바이스ID을 확인하세요.");
        } else if (xmlHttpCheck.readyState == 4) {
          thisVue.errorToast("IP 및 안전하지않은 연결이 필요합니다.");
          window.open(requestURL);
        }
      };
    },
    async onClickTestPrint(type) {
      await BillPrintUtil.testWebSdkPrint(type);
    },

  },
};
</script>
<style scoped>
body .body-data .data-content .content {
  width: calc((100% - 85px) + 1px) !important;
}
body .body-data .data-content .title {
  width: 85px !important;
}
</style>
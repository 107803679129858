<template>
  <div>
    <ejs-dialog
      ref="dialog"
      allowDragging="true"
      showCloseIcon="true"
      width="500"
      height="400"
      v-bind="dialogProps"
      @beforeClose="onDialogBeforeClose"
      @close="onDialogClose"
    >
      <div class="window webMemberInformationRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <!-- 필수 : required -->
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">이메일</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input">
                                    <div
                                      v-if="
                                        !commonMixinIsButtonDisableByAuth(
                                          'userReg'
                                        )
                                      "
                                      class="form"
                                    >
                                      <input-text v-model="inputUser.email" />
                                    </div>
                                    <template v-else>
                                      {{ inputUser.email }}
                                    </template>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field password">
                              <!-- 필수 : required -->
                              <div
                                class="title"
                                :class="{ required: mode === 'CREATE' }"
                              >
                                비밀번호
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input">
                                    <div class="form">
                                      <input
                                        type="password"
                                        class="e-input"
                                        placeholder="******"
                                        maxlength="50"
                                        autocomplete="off"
                                        v-model="inputUser.password"
                                        :disabled="
                                          mode === 'CREATE' && isAutoPassword
                                        "
                                      />
                                    </div>
                                  </li>
                                  <li
                                    v-if="mode === 'CREATE'"
                                    class="item form-group check"
                                  >
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="checkbox"
                                            v-model="isAutoPassword"
                                          />
                                          <i></i>
                                          <div class="label">자동생성</div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">사용자명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input">
                                    <div
                                      v-if="
                                        !commonMixinIsButtonDisableByAuth(
                                          'userReg'
                                        )
                                      "
                                      class="form"
                                    >
                                      <input-text v-model="inputUser.name" />
                                    </div>
                                    <template v-else>
                                      {{ inputUser.name }}
                                    </template>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">기본사업장</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div
                                      v-if="
                                        !commonMixinIsButtonDisableByAuth(
                                          'userReg'
                                        )
                                      "
                                      class="form"
                                    >
                                      <ejs-dropdownlist
                                        v-model="inputUser.baseBizCode"
                                        :dataSource="
                                          businessPlaceDropdownListDatasource
                                        "
                                        :allowFiltering="false"
                                        :fields="{
                                          text: 'text',
                                          value: 'value',
                                        }"
                                        cssClass="body-data-dropdown"
                                      />
                                    </div>
                                    <template v-else>
                                      {{ displayBaseBusinessName }}
                                    </template>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li v-if="mode === 'CREATE'" class="field">
                              <!-- 필수 : required -->
                              <div class="title required">권한그룹</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-multiselect
                                        placeHolder="권한그룹 선택"
                                        v-model="inputUserAuthGroupIds"
                                        :dataSource="
                                          authGroupsDropdownListDataSource
                                        "
                                        :fields="{
                                          text: 'text',
                                          value: 'value',
                                        }"
                                        :disabled="
                                          commonMixinIsButtonDisableByAuth(
                                            'userReg'
                                          )
                                        "
                                        :showDropDownIcon="true"
                                        cssClass="lookupDetail-condition-multiselect"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">업무/부서</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input">
                                    <div
                                      v-if="
                                        !commonMixinIsButtonDisableByAuth(
                                          'userReg'
                                        )
                                      "
                                      class="form"
                                    >
                                      <input-text v-model="inputUser.remarks" />
                                    </div>
                                    <template v-else>
                                      {{ inputUser.remarks }}
                                    </template>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">부서 관리자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="checkbox"
                                            v-model="inputUser.deptAdminFlag"
                                            :disabled="
                                                commonMixinIsButtonDisableByAuth(
                                                  'userReg'
                                                )
                                              "
                                          />
                                          <i></i>
                                          <div class="label">
                                            {{
                                              inputUser.deptAdminFlag
                                                ? "부여"
                                                : "미부여"
                                            }}
                                          </div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">핸드폰</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div
                                      v-if="
                                        mode === 'CREATE' ||
                                        !commonMixinIsButtonDisableByAuth(
                                          'userReg'
                                        ) ||
                                        commonMixinHasCiperTextGet ||
                                        !inputUser.phone
                                      "
                                      class="form"
                                    >
                                      <component-telephone
                                        v-model="inputUser.phone"
                                        :max-length="11"
                                      />
                                    </div>
                                    <template v-else>
                                      {{ inputUser.phone }}
                                    </template>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">게시판관리자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="checkbox"
                                            v-model="inputUser.isBoardManager"
                                            :disabled="
                                              commonMixinIsButtonDisableByAuth(
                                                'userReg'
                                              )
                                            "
                                          />
                                          <i></i>
                                          <div class="label">
                                            {{
                                              inputUser.isBoardManager
                                                ? "부여"
                                                : "미부여"
                                            }}
                                          </div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">접속차단</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="checkbox"
                                            v-model="inputUser.isBlocked"
                                            :disabled="
                                              commonMixinIsButtonDisableByAuth(
                                                'userReg'
                                              )
                                            "
                                          />
                                          <i></i>
                                          <div class="label">
                                            {{
                                              inputUser.isBlocked
                                                ? "차단"
                                                : "허용"
                                            }}
                                          </div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">사용제한</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="checkbox"
                                            v-model="inputUser.useLimit"
                                            :disabled="
                                              commonMixinIsButtonDisableByAuth(
                                                'userReg'
                                              )
                                            "
                                          />
                                          <i></i>
                                          <div class="label">
                                            {{
                                              inputUser.useLimit
                                                ? "제한"
                                                : "허용"
                                            }}
                                          </div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li
              v-if="!commonMixinIsButtonDisableByAuth('userReg')"
              class="save keyColor"
            >
              <erp-button
                  :disabled="!isModified"
                  button-div="SAVE"
                  :is-key-color = "true"
                  :is-shortcut-button ="true"
                  @click.native="onSaveClick"
              >
                저장
              </erp-button>
            </li>
            <li
              v-if="
                !commonMixinIsButtonDisableByAuth('userReg') &&
                mode === 'MODIFY'
              "
              class="save"
            >
              <ErpButton
                  button-div="SAVE"
                  :disabled="isEmailChanged"
                  v-on:click.native="onResetPasswordClick"
              >
                비밀번호 자동생성
              </ErpButton>

            </li>
            <li
              v-if="
                !commonMixinIsButtonDisableByAuth('userReg') &&
                mode === 'MODIFY'
              "
              class="reset"
            >
              <ErpButton
                button-div="SAVE"
                :disabled="isEmailChanged"
                v-on:click.native="onInitPasswordButtonClicked"
              >
                비밀번호 초기화
              </ErpButton>

            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  v-on:click.native="onCloseClick"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { compactObjectValues, deepDiffs } from "@/utils/ObjectUtil";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import InputText from "@/components/common/text/InputText";
import ErpButton from "@/components/button/ErpButton";
import { getBizCodesObject } from "@/utils/pageKeyUtil";
import ComponentTelephone from "@/components/ComponentTelephone";
import {mapGetters} from "vuex";

export default {
  name: "UserDetailsPopup",
  components: { ComponentTelephone,
    ErpButton,
    InputText,
  },
  mixins: [commonMixin, messagePopupDialogMixin],
  data() {
    return {
      isLoading: false,
      mode: "CREATE",

      authGroups: [],

      originUser: {},
      inputUser: {},
      inputUserAuthGroupIds: [],

      isAutoPassword: true,
    };
  },
  computed: {
    ...mapGetters(["userId"]),
    dialogProps() {
      return {
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        enableResize: false,
        header: this.mode === "CREATE" ? "사용자 추가" : "사용자 상세",
      };
    },
    authGroupsDropdownListDataSource() {
      return this.authGroups.map(({ id, name }) => ({
        text: name,
        value: id,
      }));
    },
    businessPlaceDropdownListDatasource() {
      return (getBizCodesObject() || []).map(({ bizName, bizCode }) => ({
        text: bizName,
        value: bizCode,
      }));
    },
    displayBaseBusinessName() {
      return (
        this.businessPlaceDropdownListDatasource.find(
          ({ value }) => value === this.inputUser.baseBizCode
        )?.text || "-"
      );
    },

    isModified() {
      return deepDiffs(this.originUser, this.inputUser);
    },
    isEmailChanged() {
      return this.originUser.email !== this.inputUser.email;
    },
  },
  methods: {
    async onDialogBeforeClose(args) {
      if (this.isModified) {
        args.cancel = true;

        if (
          await this.confirm(this.$t("main.popupMessage.thereIsModifiedData"))
        ) {
          this.hideForcing();
        }
      }
    },
    async create() {
      try {
        await this.fetchAuthorityGroups();
        this.mode = "CREATE";
        this.$refs["dialog"].show();
      } catch (e) {
        this.$emit("close");
        throw e;
      }
    },
    async modify({ id }) {
      try {
        await Promise.all([this.fetchUser(id), this.fetchAuthorityGroups()]);
        this.mode = "MODIFY";
        this.$refs["dialog"].show();
      } catch (e) {
        this.$emit("close");
        throw e;
      }
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    hideForcing() {
      this.$emit("close");
    },

    onDialogClose() {
      this.$emit("close");
    },
    async onResetPasswordClick() {
      if (this.mode === "MODIFY") {
        if (!(await this.confirm("비밀번호를 재발급하시겠습니까?"))) {
          return;
        }

        await this.reIssuancePassword(this.inputUser.id);
        this.infoToast("비밀번호가 재발급되었습니다.");
      }
    },
    async onSaveClick() {
      try {
        if (this.mode === "CREATE") {
          if (!this.inputUser.email) {
            this.errorToast("이메일을 입력해주세요.");
            return;
          }
          if (!this.isAutoPassword && !this.inputUser.password) {
            this.errorToast("비밀번호를 입력해주세요.");
            return;
          }
          if (!this.inputUser.name) {
            this.errorToast("이름을 입력해주세요.");
            return;
          }
          if (!this.inputUser.baseBizCode) {
            this.errorToast("기본사업장을 선택해주세요.");
            return;
          }
          if (this.inputUserAuthGroupIds.length === 0) {
            this.errorToast("권한그룹을 1개이상 선택해주세요.");
            return;
          }

          await this.createUser();
        } else if (this.mode === "MODIFY") {
          await this.patchUser();
        } else {
          // TODO
        }

        this.infoToast(this.$t("main.popupMessage.saved"));
        this.hideForcing();
      } catch (e) {
        console.error(e);
      }
    },
    onCloseClick() {
      this.hide();
    },

    async fetchAuthorityGroups() {
      const user = await GolfErpAPI.fetchUser(this.userId);
      this.authGroups = await GolfErpAPI.fetchAuthorityGroups();
      if(!user.authGroups.map(it=> it.id.toString()).includes('SYSTEM')){
        this.authGroups = this.authGroups.filter(it=> it.id != 'SYSTEM');
      }
    },
    async fetchUser(id) {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      try {
        const user = await GolfErpAPI.fetchUser(id);
        this.originUser = user;
        this.inputUser = JSON.parse(JSON.stringify(user));
      } finally {
        this.isLoading = false;
      }
    },
    async createUser() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      try {
        const inputUser = JSON.parse(JSON.stringify(this.inputUser));
        inputUser.authGroupIds = JSON.parse(
          JSON.stringify(this.inputUserAuthGroupIds)
        );

        await GolfErpAPI.createUser(
          compactObjectValues(inputUser, true, false)
        );
      } finally {
        this.isLoading = false;
      }
    },
    async patchUser() {
      if (this.isLoading) {
        return;
      }

      if (
        !compactObjectValues(
          deepDiffs(this.originUser, this.inputUser),
          true,
          false
        )
      ) {
        this.errorToast("변경된 데이터가 존재하지 않습니다");
        throw Error("변경된 데이터가 존재하지 않습니다");
      }

      this.isLoading = true;
      try {
        await GolfErpAPI.patchUser(
          this.inputUser.id,
          compactObjectValues(
            deepDiffs(this.originUser, this.inputUser),
            true,
            false
          )
        );
      } finally {
        this.isLoading = false;
      }
    },
    async reIssuancePassword(id) {
      await GolfErpAPI.reIssuancePassword(id);
    },
    async onInitPasswordButtonClicked() {
      if (this.mode === "MODIFY") {
        if (!(await this.confirm("비밀번호를 초기화하시겠습니까?"))) {
          return;
        }

        await GolfErpAPI.initPasswordToUser(this.inputUser.id);
        this.infoToast("비밀번호가 초기화되었습니다.");
      }
    },
  },
};
</script>

<style scoped></style>

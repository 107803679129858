<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="dailyStatusPopup"
      :header="`일일 종합 현황 [${bsnDate}(${getDayOfWeekCaption(
        new Date(bsnDate)
      )})]`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="600"
      height="730"
      :isModal="true"
      :close="onDailyStatusPopupClosed"
    >
      <div class="window dailyStatus">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <div class="article-left">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-01">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">팀/내장 현황</div>
                      </div>
                    </div>
                    <div class="section-body">
                      <div class="body-data">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul class="data-content">
                              <li
                                class="field"
                                v-for="team in teamVisitStatus"
                                :key="team.id"
                              >
                                <!-- 필수 : required -->
                                <div class="title">
                                  {{ team.title }}
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      {{ team.contents }}
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                  </section>
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-02">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">업장별 매출 현황</div>
                      </div>
                    </div>
                    <div class="section-body">
                      <div class="body-data">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul class="data-content">
                              <li
                                class="field"
                                v-for="team in storeSaleStatus"
                                :key="team.id"
                              >
                                <!-- 필수 : required -->
                                <div class="title">
                                  {{ team.title }}
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <div
                                        v-if="
                                          team.amt !== undefined &&
                                          team.amt !== 0
                                        "
                                      >
                                        {{ team.amt.toLocaleString() }}
                                      </div>
                                      <div v-else>-</div>
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                  </section>
                </div>
                <div class="article-right">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-03">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">정산 현황</div>
                      </div>
                    </div>
                    <div class="section-body">
                      <div class="body-data">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul class="data-content">
                              <li
                                class="field"
                                v-for="team in payStatus"
                                :key="team.id"
                              >
                                <!-- 필수 : required -->
                                <div class="title">
                                  {{ team.title }}
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <div
                                        v-if="
                                          team.amt !== undefined &&
                                          team.amt !== 0
                                        "
                                      >
                                        {{ team.amt.toLocaleString() }}
                                      </div>
                                      <div v-else>-</div>
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                  </section>
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-04">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">예약 구분 현황</div>
                      </div>
                    </div>
                    <div class="section-body">
                      <div class="body-data">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul class="data-content">
                              <li
                                class="field"
                                v-for="team in resveDivStatus"
                                :key="team.id"
                              >
                                <!-- 필수 : required -->
                                <div class="title">
                                  {{ team.title }}
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <div
                                        v-if="
                                          team.amt !== undefined &&
                                          team.amt !== 0
                                        "
                                      >
                                        {{ team.amt.toLocaleString() }}
                                      </div>
                                      <div v-else>-</div>
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                  </section>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeDailyStatusPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .window.dailyStatus .windowContent .body-article .article-section.section-01 .section-body .body-data .data-outer { height: 337px; }
body .window.dailyStatus .windowContent .body-article .article-section.section-03 .section-body .body-data .data-outer { height: 337px; }
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import { getDailyStatus } from "@/api/frontManagement";
import { getDayOfWeekCaption } from "@/utils/date";

export default {
  name: "dailyStatusPopup",
  components: { ErpButton },
  data() {
    return {
      bsnDate: null,
      teamVisitStatus: [],
      payStatus: [],
      storeSaleStatus: [],
      paymtGreenFeeStatus: [],
      resveDivStatus: [],
    };
  },
  methods: {
    getDayOfWeekCaption,
    async showDailyStatusPopup(bsnDate) {
      this.bsnDate = bsnDate;

      const response = await getDailyStatus(this.bsnDate);

      this.teamVisitStatus = response.value.teamVisitStatuse;
      this.payStatus = response.value.payStatus;
      this.storeSaleStatus = response.value.storeSaleStatus;
      this.paymtGreenFeeStatus = response.value.paymtGreenFeeStatus;

      for (const [key, value] of Object.entries(response.value.resveDivStatus)) {
        let title = "";
        let sortNo = 0;
        switch (key) {
          case "yearGrpTeamCnt":
            title = "연단체 예약팀";
            sortNo = 1;
            break;
          case "personResve":
            title = "개인 예약";
            sortNo = 2;
            break;
          case "proMemberCnt":
            title = "프로 회원 대우";
            sortNo = 3;
            break;
          case "teamCntBy2P":
            title = "2인";
            sortNo = 4;
            break;
          case "teamCntBy3P":
            title = "3인";
            sortNo = 5;
            break;
          case "teamCntBy4P":
            title = "4인";
            sortNo = 6;
            break;
          case "memberVisitCnt":
            title = "회원";
            sortNo = 7;
            break;
        }

        this.resveDivStatus.push({
          id: key,
          title: title,
          amt: value,
          sortNo: sortNo,
        });
      }

      this.resveDivStatus.sort((a, b) => a.sortNo - b.sortNo);
    },
    onDailyStatusPopupClosed() {
      this.$emit("popupClosed");
    },
    closeDailyStatusPopup() {
      this.$refs.dailyStatusPopup.hide();
    },
  },
};
</script>

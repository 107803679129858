<template>
  <div class="content-wrapper">
    <div class="content-lookup">  <!-- 필터 -->
      <div class="lookup-left">
        <ul class="lookup-condition">
          <!-- 범위 날짜 검색 -->
          <li class="field">
            <div class="title">출역 일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range type="lookup-condition" v-model="searchFilterOptions.dlivyDate"/>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">장비구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    ref="searchEqpDiv"
                    v-model="searchFilterOptions.eqpDiv"
                    :dataSource="commonCodeOptions.eqpDiv"
                    :allowFiltering="false"
                    :fields="commonCodeOptions.fields"
                    cssClass="lookup-condition-dropdown"
                >
                </ejs-dropdownlist>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">장비명</div>
            <ul class="content">
              <li class="item">
                <input-text ref="searchEqpName" v-model="searchFilterOptions.eqpName" @keydown.enter="onClickSearch"/>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button button-div="GET" :is-shortcut-button="true" :ignore="isPopupOpened" @click.native="onClickSearch">
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">{{ gridField.title }}</div>
              <div class="header-caption">[ {{ gridField.count }} 건 ]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button button-div="FILE" @click.native="onClickExcel">Excel</erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <ejs-grid-wrapper
                ref="grid"
                v-bind="initGrid"
                @actionComplete="gridComplete"/>
          </div>
        </section>
      </article>
    </div>
    <!-- 각종팝업 -->
  </div>
</template>
<style scoped>

</style>

<script>

import InputText from "@/components/common/text/InputText.vue";
import inputDateRange from "@/components/common/datetime/InputDateRange.vue";
import ErpButton from "@/components/button/ErpButton.vue";
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {commonCodesGetCommonCode} from "@/utils/commonCodes";
import {Edit, ExcelExport, Filter, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import StockCommon from "@/api/v2/StockManage/StockCommon";
import StockEquipmentStat from "@/api/v2/StockManage/StockEquipmentStat";
import moment from "moment";

export default {
  name: "EquipmentWorkStatus",
  components: {EjsGridWrapper, ErpButton, inputDateRange, InputText},
  created() {
  },
  mounted() {
    this.initData();
  },
  data() {
    return {
      //조회 필드
      //조회 필드
      searchFilterOptions: {
        dlivyDate: {
          from: moment().startOf('month').format('YYYY-MM-DD'),
          to: moment().format('YYYY-MM-DD'),
        },
        eqpName: "",
        eqpDiv: "",
      },
      //드롭다운 코드
      commonCodeOptions: {
        wrhousData: [],
        eqpDiv: commonCodesGetCommonCode("EQP_DIV", false, true, "", ""),
        fields: {text: "comName", value: "comCode"},
      },
      // 그리드 데이타
      gridField:{
        title: "출역 목록",
        count : 0,
        dataSource: [],
      },
    };
  },

  computed: {
    //그리드 해더 세팅
    initGrid(){
      return{
        columns:  [
          {field: "eqpDlivyId",     textAlign: 'center', allowEditing: false, width: 100, visible: true, type: "string", headerText: "출역번호", isPrimaryKey: true,},
          {field: "dlivyDate",      textAlign: 'center', allowEditing: false, width: 100, visible: true, type: "string", headerText: "출역일자",},
          {field: "eqpDiv",         textAlign: 'center', allowEditing: false, width: 100, visible: true, type: "string", headerText: "장비구분", isCommonCodeField: true, dataSource: this.commonCodeOptions.eqpDiv},
          {field: "eqpName",        textAlign: 'center', allowEditing: false, width: 200, visible: true, type: "string", headerText: "장비명",},
          {field: "startTime",      textAlign: 'center', allowEditing: false, width: 100, visible: true, type: "string", headerText: "출고시간",},
          {field: "endTime",        textAlign: 'center', allowEditing: false, width: 100, visible: true, type: "string", headerText: "입고시간",},
          {field: "workName",       textAlign: 'center', allowEditing: false, width: 200, visible: true, type: "string", headerText: "작업명",},
          {field: "wrhousCode",     textAlign: 'center', allowEditing: false, width: 100, visible: true, type: "string",  headerText: "출고창고", isCommonCodeField: true, dataSource: this.commonCodeOptions.wrhousData,},
          {field: "suppliesName",   textAlign: 'center', allowEditing: false, width: 200, visible: true, type: "string", headerText: "저장품명",},
          {field: "unit",           textAlign: 'center', allowEditing: false, width: 100, visible: true, type: "string",  headerText: "단위", },
          {field: "qty",            textAlign: 'right',  allowEditing: false, width: 100, visible: true, type: "string", headerText: "수량", isNumericType: true,},
          {field: "remarks",        textAlign: 'center', allowEditing: false, width: 250, visible: true, type: "string", headerText: "비고",},
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,
        allowResizing: true,
        allowSorting: false,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay: true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: true,
        pageSettings: {pageSize: 50},
      };
    },
  },
  methods: {
    //초기화
    async initData() {
      //창고콤보(권한있는 저장품창고만)--이력이니 삭제된것도
      this.commonCodeOptions.wrhousData  = await StockCommon.getWrhous({useFlag:'N' ,authoFlag:'N',suppliesFlag:'Y'});
    },
    //region ============================ API ==========================================
    async apiGetList() {
      let args = {
        fromDate: this.searchFilterOptions.dlivyDate.from ,
        toDate:this.searchFilterOptions.dlivyDate.to,
        eqpName: this.searchFilterOptions.eqpName,
        eqpDiv: this.searchFilterOptions.eqpDiv,
      };
      this.gridField.dataSource = await StockEquipmentStat.getEqpDlivyStatus(args);
    },
    //endregion ========================================================================
    //region ============================ Button Click ==========================================
    onClickSearch() {
      this.apiGetList();
    },
    onClickExcel() {
      this.$refs.grid.excelExport({fileName: this.gridField.title + ".xlsx"});
    },
    //endregion ========================================================================
    //region ============================ 그리드 ==========================================
    //그리드 조회 완료시(카운트)
    gridComplete() {
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    //endregion ========================================================================
  }
};
</script>
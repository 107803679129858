<template>
  <div class="content-wrapper">
    <!-- REPORT -->
    <report-view ref="reportViewComponent" :isPopup="true"/>
    <!-- 필터영역 -->
    <div class="content-lookup">
    </div>
    <!-- 본문 -->
    <div class="content-body">
      <article class="body-article">
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">창고</div>
              <div class="header-caption">[{{ gridField.count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="add">
                  <erp-button button-div="SAVE" @click.native="openPopup" >추가</erp-button>
                </li>
                <li class="print">
                  <erp-button button-div="FILE" @click.native="onClickExcel">Excel</erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="grid"
                  v-bind ="initGrid"
                  @recordClick="onGridClick"
                  @queryCellInfo="onQueryCellInfo"
                  @headerCellInfo="onHeaderCellInfo"
                  @actionComplete="gridComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <!-- 팝업 -->
    <wrhous-code-registration-popup
        ref="codePopup"
        v-if="isWrhousCodePopupOpen"
        @popupClosed="closePopup"
        @popupSaved="savePopup"
    />
  </div>
</template>

<script>
import CommonMixin from "@/views/layout/mixin/commonMixin";
import ReportView from "@/components/common/report/ReportView.vue";
import ErpButton from "@/components/button/ErpButton.vue";
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {ExcelExport, Filter, ForeignKey, Page, Resize, Edit} from "@syncfusion/ej2-vue-grids";
import MessagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import WrhousCodeRegistrationPopup from "@/views/stock-management/supplies/popup/WrhousCodeRegistrationPopup.vue";
import WrhousCd from "@/api/v2/StockManage/WrhousCd";
import {commonCodesGetCommonCode} from "@/utils/commonCodes";

export default
{
  name: "WrhousCodeRegistration",
  mixins : [CommonMixin, MessagePopupDialogMixin],
  components:{EjsGridWrapper, ErpButton, ReportView, WrhousCodeRegistrationPopup },

  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data(){
    return {
      isWrhousCodePopupOpen : false,
      //콤보, 드랍다운리스트 필드
      commonCodeOptions:{
        storeCode: commonCodesGetCommonCode("STORE_CODE",true,false,"",""),
        fields: { text: "comName", value: "comCode" },
      },
      gridField: {
        count : 0,
        dataSource: [],
      },
    };
  },
  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed:{
    //모든 팝업 오픈 여부
    isPopupOpened(){
      return this.isWrhousCodePopupOpen;
    },
    initGrid(){
      return{
        columns: [
          {field: 'wrhousCode',     textAlign: 'center',    width: 80,    visible: true,   allowEditing : false,  type: 'string',   headerText: '창고번호'  },
          {field: 'wrhousDivName',  textAlign: 'center',    width: 100,   visible: true,   allowEditing : false,  type: 'string',   headerText: '창고구분'  },
          {field: 'wrhousName',     textAlign: 'center',    width: 150,   visible: true,   allowEditing : false,  type: 'string',   headerText: '창고명' },
          {field: 'useFlag',        textAlign: 'center',    width: 80,    visible: true,   allowEditing : false,  type: 'boolean',  headerText: '사용여부',   displayAsCheckBox: true, editType: "booleanedit" },
          {field: 'mainWrhousFlag', textAlign: 'center',    width: 80,    visible: true,   allowEditing : false,  type: 'boolean',  headerText: '메인창고',   displayAsCheckBox: true, editType: "booleanedit" },
          {field: 'suppliesFlag',   textAlign: 'center',    width: 80,    visible: true,   allowEditing : false,  type: 'boolean',  headerText: '저장품용도',   displayAsCheckBox: true, editType: "booleanedit" },
          {field: "storeCode",      textAlign: 'center',    width: 80,    visible: true,   allowEditing : false,  type: "string",   headerText: "매장", isCommonCodeField: true, dataSource: this.commonCodeOptions.storeCode,},
          {field: 'authUsers',      textAlign: 'left',      width: 200,   visible: true,   allowEditing : false,  type: 'string',   headerText: '담당자' },
          {field: 'authCnt',        textAlign: 'right',     width: 80,    visible: true,   allowEditing : false,  type: 'number',   headerText: '담당자수'    },
          {field: 'remarks',        textAlign: 'center',    width: 200,   visible: true,   allowEditing : false,  type: 'string',   headerText: '비고'  },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: true,
        pageSettings: { pageSize: 50 },
      };
    },

  },
  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
  },
  mounted() {
    //리스트조회
    this.onClickSearch();
  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods:{
    //******************************* 공통 버튼 이벤트 *******************************
    //조회
    onClickSearch(){
      this.findWrhousList();
    },
    //추가
    onClickAdd() {
    },
    //저장
    onClickSave() {
    },
    //삭제
    onClickDelete() {
    },
    //엑셀 출력
    onClickExcel() {
      this.$refs.grid.excelExport({fileName: "창고코드.xlsx"});
    },
    //리포트 출력
    onClickPrint() {
    },

    //******************************* 공통 이벤트 *******************************
    //팝업닫기
    closePopup(){
      this.isWrhousCodePopupOpen = false;
    },
    //팝업저장후 본문조회
    async savePopup(){
      this.isWrhousCodePopupOpen = false;
      await this.findWrhousList();
    },
    //팝업열기
    openPopup(isNew=true,searchCode=null){
      this.isWrhousCodePopupOpen = true;
      this.$nextTick(() => {
        this.$refs.codePopup.initShow(isNew, searchCode);
      });
    },
    //창고데이터 조회
    async findWrhousList(){
      this.gridField.dataSource = await WrhousCd.getWrhousList();
    },

    //******************************* 그리드 이벤트 *******************************
    //그리드 헤더 이미지적용
    onHeaderCellInfo(args){
      const {cell: {column:{field}}, node} = args;
      //필수 입력
      if(this.$refs.grid.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
      if (['wrhousName'].includes(field)) {
        node.classList.add(this.$t('className.grid.clickArea'));
      }
    },
    //그리드 셀 이미지 적용
    onQueryCellInfo(args){
      const {column:{field,allowEditing}, cell} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
      if (['wrhousName'].includes(field)) {
        cell.classList.add(this.$t('className.grid.clickArea'));
      }
    },
    //그리드 셀 클릭
    onGridClick(args){
      const {column:{field}, rowData} = args;
      if(field === "wrhousName"){
        this.openPopup(false, rowData.wrhousCode);
      }
    },
    //그리드 조회 완료시(카운트)
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
  },
};
</script>

<!-- ##################################### CUSTOM STYLE ########################################* -->
<style scoped>
</style>

<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="dialog"
      v-bind="dialogProps"
      @close="onDialogClose"
    >
      <div
        class="typePos"
        :style="{
          minWidth: `${dialogProps.width}px`,
          minHeight: `${dialogProps.height - 66}px`,
        }"
      >
        <div class="typePos-wrapper-header">
          <div class="typePos-body">
            <ul class="typePos-list">
              <li>
                {{ slip.number }}
              </li>
              <li>
                {{ slipVisitorLockerNumber }}
              </li>
              <li>
                {{ slipVisitorName }}
              </li>
              <li>
                {{ slipSalesProductNames }} :
                {{ slipSalesTotalAmounts | numberWithCommas }}원
              </li>
            </ul>
          </div>
        </div>
        <div class="typePos-wrapper-main">
          <div class="typePos-main-header">
            <div class="typePos-header-header">
              <div class="typePos-header-left">
                <div class="typePos-title">매출 목록</div>
                <div class="typePos-caption">
                  [<strong>{{ sales.length }}</strong> 건]
                </div>
              </div>
              <div class="typePos-header-right">
                <ul class="typePos-button">
                  <li>
                    <erp-button
                      button-div="GET"
                      class="ui-button"
                      :class="isSalesGridAllSelected ? 'i-typePrimary' : ''"
                      :use-syncfusion-component-style="false"
                      @click="onSalesGridSelected"
                    >
                      <div class="i-item">{{!isSalesGridAllSelected ? "전체선택" : "전체해제"}}</div>
                    </erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="typePos-header-body"
              :class="{ 'typePos-open': isVisibleCoupon }"
            >
              <ejs-grid-wrapper
                ref="salesGrid"
                v-bind="salesGridProps"
                @queryCellInfo="onSalesGridQueryCellInfo"
                @rowSelected="onSalesGridRowSelected"
              />
            </div>
            <div
              class="typePos-header-footer"
              @click="onCouponHeaderClicked"
            >
              <div class="typePos-header-left">
                <div class="typePos-title">할인 쿠폰</div>
                <div class="typePos-caption">[<strong>{{ coupons.length || 0}}</strong> 건]</div>
                <div
                  class="typePos-accordion-coupon"
                  :class="{ 'typePos-open': isVisibleCoupon }"
                >
                  열기 / 닫기
                </div>
              </div>
            </div>
            <div
              class="typePos-header-body coupon"
              :class="{ 'typePos-open': isVisibleCoupon }"
            >
              <ejs-grid-wrapper
                ref="couponGrid"
                v-bind="couponGridProps"
                :dataSource="coupons"
                @recordDoubleClick="onCouponGridRecordDoubleClicked"
              />
            </div>
          </div>
          <div class="typePos-main-footer">
            <div class="typePos-header">
              <div class="typePos-filed">
                <f-select
                  class="ui-select"
                  :items="[
                    // { name : '임직원', value: 'STFF' },
                    { name: '할인율', value: 'RATE' },
                    { name: 'A쿠폰금액', value: 'AMT' },
                  ]"
                  v-model="priceDivision"
                />
              </div>
              <div class="typePos-filed discount">
                <div class="typePos-filed-discount" v-if="priceDivision === 'RATE' || priceDivision === 'STFF'">
                  <erp-button
                    button-div="SAVE"
                    :use-syncfusion-component-style="false"
                    @click="onForceDiscountClick(30)"
                  >
                    30%
                  </erp-button>
                </div>
                <div class="typePos-filed-discount" v-if="priceDivision === 'RATE' || priceDivision === 'STFF'">
                  <erp-button
                    button-div="SAVE"
                    :use-syncfusion-component-style="false"
                    @click="onForceDiscountClick(50)"
                  >
                    50%
                  </erp-button>
                </div>
                <div class="content">
                  <input-number-unusual
                      ref="input-amount"
                      :align="'right'"
                      v-model="amount"
                      :min=" (priceDivision === 'RATE'|| priceDivision === 'STFF')  ? -100 : -1000000000"
                      :max=" (priceDivision === 'RATE'|| priceDivision === 'STFF')  ? 100 : 1000000000"
                  />
                </div>
              </div>
            </div>
            <div class="typePos-body">
              <ul class="typePos-list">
                <li class="typePos-1">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, '1')"
                  >
                    1
                  </erp-button>
                </li>
                <li class="typePos-2">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, '2')"
                  >
                    2
                  </erp-button>
                </li>
                <li class="typePos-3">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, '3')"
                  >
                    3
                  </erp-button>
                </li>
                <li class="typePos-4">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, '4')"
                  >
                    4
                  </erp-button>
                </li>
                <li class="typePos-5">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, '5')"
                  >
                    5
                  </erp-button>
                </li>
                <li class="typePos-6">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, '6')"
                  >
                    6
                  </erp-button>
                </li>
                <li class="typePos-7">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, '7')"
                  >
                    7
                  </erp-button>
                </li>
                <li class="typePos-8">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, '8')"
                  >
                    8
                  </erp-button>
                </li>
                <li class="typePos-9">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, '9')"
                  >
                    9
                  </erp-button>
                </li>
                <li class="typePos-0">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, '0')"
                  >
                    0
                  </erp-button>
                </li>
                <li class="typePos-00">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, '00')"
                  >
                    00
                  </erp-button>
                </li>
                <li class="typePos-clear">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, 'Clear')"
                  >
                    CL
                  </erp-button>
                </li>
                <li class="typePos-back">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, 'Backspace')"
                  >
                    뒤로
                  </erp-button>
                </li>
                <li class="typePos-enter">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onSubmitClick"
                  >
                    Enter
                  </erp-button>
                </li>
                <li class="typePos-input">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onInputClick"
                  >
                    입력
                  </erp-button>
                </li>
                <li class="typePos-lookup">
                  <erp-button
                    button-div="SAVE"
                    :use-syncfusion-component-style="false"
                    @click="onSubmitClick"
                  >
                    적용
                  </erp-button>
                </li>
              </ul>
            </div>
            <div class="typePos-footer">
              <div class="typePos-filed">
                <textarea
                  placeholder="할인사유를 입력하세요."
                  v-model="remarks"
                  autocomplete="off"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li class="typePos-save">
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="SAVE"
                class="ui-button i-typePrimary"
                :use-syncfusion-component-style="false"
                :visible-shortcut-string="false"
                @click.native="onSubmitClick"
              >
                <div class="i-item">
                  저장 ({{ saveShortcutButtonShortcutString }})
                </div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click="onCloseClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-header {padding: 20px; border-bottom: 1px solid #bebebe;}
.typePos .typePos-wrapper-header::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-header .typePos-body {border-radius: 4px; background-color: #f5f5f5; text-align: center; line-height: 0;}
.typePos .typePos-wrapper-header .typePos-body .typePos-list {display: inline-block; list-style: none; padding: 17px 20px 16px 20px; margin: 0;}
.typePos .typePos-wrapper-header .typePos-body .typePos-list::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-header .typePos-body .typePos-list > li {position: relative; float: left; padding: 0 15px 0 16px; color: #222; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-header .typePos-body .typePos-list > li::before {display: block; position: absolute; top: 50%; left: 0; width: 1px; height: 8px; margin-top: -4px; background-color: #e0e0e0; content: '';}
.typePos .typePos-wrapper-header .typePos-body .typePos-list > li:first-child {padding: 0 15px;}
.typePos .typePos-wrapper-header .typePos-body .typePos-list > li:first-child::before {display: none;}

.typePos .typePos-wrapper-main {display: flex; flex-direction: row; flex: 1; overflow: hidden;}
.typePos .typePos-wrapper-main::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-header {display: flex; flex-direction: column; flex: 1; float: left; overflow: hidden;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header {padding: 10px 20px 2px 20px; border-bottom: 1px solid #e0e0e0;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header .typePos-header-left {position: relative; float: left;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header .typePos-header-left::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header .typePos-header-left .typePos-title {float: left; padding: 6px 0 7px 0; color: #222; font-size: 18px; font-family: 'NanumSquare-Regular'; line-height: 27px;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header .typePos-header-left .typePos-caption {float: left; padding: 12px 0 9px 10px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header .typePos-header-left .typePos-caption strong {color: #18b3c9; font-weight: normal;}

.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header .typePos-header-right {position: relative; float: right;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header .typePos-header-right::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header .typePos-header-right .typePos-title {float: left; padding: 6px 0 7px 0; color: #222; font-size: 18px; font-family: 'NanumSquare-Regular'; line-height: 27px;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header .typePos-header-right .typePos-caption {float: left; padding: 12px 0 9px 10px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header .typePos-header-right .typePos-caption strong {color: #18b3c9; font-weight: normal;}

.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header .typePos-footer {float: right;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header .typePos-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-body {overflow: hidden; height: calc(100% - 106px);}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-body.typePos-open {overflow: hidden; height: calc(100% - 286px);}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-body.coupon {display: none;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-body.coupon.typePos-open {display: block; overflow: hidden; height: 180px;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-accordion-coupon.typePos-open {background-position-x: right;}

.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer {padding: 10px 20px 2px 20px; border-bottom: 1px solid #e0e0e0; cursor: pointer;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-header-left {position: relative; float: left;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-header-left::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-header-left .typePos-title {float: left; padding: 6px 0 7px 0; color: #222; font-size: 18px; font-family: 'NanumSquare-Regular'; line-height: 27px;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-header-left .typePos-caption {float: left; padding: 12px 0 9px 10px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-header-left .typePos-caption strong {color: #18b3c9; font-weight: normal;}

.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-header-right {position: relative; float: right;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-header-right::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-header-right .typePos-title {float: left; padding: 6px 0 7px 0; color: #222; font-size: 18px; font-family: 'NanumSquare-Regular'; line-height: 27px;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-header-right .typePos-caption {float: left; padding: 12px 0 9px 10px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-header-right .typePos-caption strong {color: #18b3c9; font-weight: normal;}

.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-footer {float: right;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}

.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-accordion-coupon {overflow: hidden; margin: 16px 0 0 120px; width: 9px; height: 5px; background: transparent url('../../../../../assets/images/ui/arrow-type08.png') no-repeat left top; text-indent: -1000px;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer:hover .typePos-accordion-coupon {background-position-y: bottom;}

.typePos .typePos-wrapper-main .typePos-main-footer {display: flex; flex-direction: column; float: left; width: 325px; border-left: 1px solid #bebebe;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header {padding: 15px 20px; border-bottom: 1px solid #e0e0e0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed {padding: 5px 0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed >>> .ejs-number input {width: 100%;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed.discount {display: flex;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed.discount > .typePos-filed-discount {width: 25%}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed.discount > .typePos-filed-discount button {width: calc(100% - 10px); height: 40px; border: 0; border-radius: 4px; background-color: #18b3c9; color: #fff; font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed.discount > .typePos-filed-discount button:hover {background-color: #0f9eb8;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed.discount > .content {flex: 1}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body {position: relative; width: 325px; height: 278px; border-bottom: 1px solid #e0e0e0; background-color: #f5f5f5;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list {list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li {position: absolute;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li button {display: block; box-sizing: border-box; box-shadow: 0 2px 2px 0 rgba(0, 0 , 0, 0.05); width: 72px; height: 45px; padding: 5px; margin: 0; border: none; border-radius: 4px; background-color: #fff; color: #666; font-size: 16px; font-family: 'NanumSquare-Bold'; line-height: 24px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li button:hover {background-color: #f9f9f9;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-1 {top: 116px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-2 {top: 116px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-3 {top: 116px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-4 {top: 68px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-5 {top: 68px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-6 {top: 68px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-7 {top: 20px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-8 {top: 20px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-9 {top: 20px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-0 {top: 164px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-00 {top: 164px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-clear {top: 164px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-back {top: 212px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-enter {top: 212px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-input {top: 20px; left: 245px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-lookup {top: 116px; left: 245px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-clear button {font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-back button {overflow: hidden; text-indent: -1000px; background-image: url('../../../../../assets/images/ui/icon-type09.png'); background-repeat: no-repeat; background-position: center center;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-enter button {width: 147px; font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-input button {width: 60px; height: 93px; background-color: #4f5963; color: #fff; font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-input button:hover {background-color: #333c44;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-lookup button {width: 60px; height: 141px; background-color: #18b3c9; color: #fff; font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-lookup button:hover {background-color: #0f9eb8;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer {flex: 1; overflow: hidden; padding: 15px 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer .typePos-filed {box-sizing: border-box; height: 100%; padding: 5px 0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer .typePos-filed textarea {box-sizing: border-box; box-shadow: none; width: 100%; height: 100%; padding: 12px 15px 11px 15px; margin: 0; border: 1px solid #e0e0e0; border-radius: 4px; background-color: #fff; color: #222; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px; resize: none;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer .typePos-filed textarea::-ms-clear {display: none;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer .typePos-filed textarea::placeholder {color: #aaa;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer .typePos-filed textarea::-webkit-input-placeholder {color: #aaa;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer .typePos-filed textarea:-ms-input-placeholder {color: #aaa;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer .typePos-filed textarea:focus {border-color: #18b3c9;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer .typePos-filed textarea:disabled,
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer .typePos-filed textarea:disabled:focus {background-color: #eee; color: #aaa; cursor: default;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
.typePos .typePos-wrapper-footer .typePos-button > li.typePos-save .ui-button .i-item::before {background-position-x: calc(-20px * 8);}

.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header .typePos-header-right .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header .typePos-header-right .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header .typePos-header-right .typePos-button > li {float: left; padding: 0 5px;}
</style>

<script>
import InputNumberUnusual from "@/components/common/InputNumberUnusual";
import { mapGetters } from "vuex";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { numberWithCommas } from "@/utils/number";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { Aggregate, Edit, ForeignKey, Resize } from "@syncfusion/ej2-vue-grids";
import {commonCodesGetColorValue, commonCodesGetComName} from "@/utils/commonCodes";
import Vue from "vue";
import { sumBy as _sumBy } from "lodash";
import FSelect from "@/views/store-management/sales-registration/store-pos-renew/components/FSelect";
import { getSaveButtonShortcut, toShortcutString } from "@/utils/KeyboardUtil";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "SettingSaleDiscountsPopup",
  components: {
    FSelect,
    EjsGridWrapper,
    InputNumberUnusual,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  filters: {
    numberWithCommas,
  },
  watch: {
    priceDivision() {
      this.amount = 0;
    },
  },
  data() {
    return {
      isVisibleCoupon: true,
      isSalesGridAllSelected: false,
      select_rid: null,
      sales: [],
      coupons: [],
      callback: () => {},

      priceDivision: "AMT",
      amount: 0,
      remarks: "",
    };
  },
  computed: {
    ...mapGetters("pos", ["slip", "store"]),
    saveShortcutButtonShortcutString() {
      return toShortcutString(getSaveButtonShortcut());
    },
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: "할인",
        width: 1160,
        height: 760,
        allowDragging: true,
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
    slipVisitorLockerNumber() {
      return this.slip?.visitor?.lockerNumber || "000";
    },
    slipVisitorName() {
      return this.slip?.visitor?.name || "일반고객";
    },
    slipSalesProductNames() {
      if (!this.slip?.sales || this.slip.sales.length === 0) {
        return "";
      } else if (this.slip.sales.length === 1) {
        return this.slip.sales[0].productName;
      } else {
        return `${this.slip.sales[0].productName} 외 ${
          this.slip.sales.length - 1
        }건`;
      }
    },
    slipSalesTotalAmounts() {
      if (!this.slip?.sales || this.slip.sales.length === 0) {
        return 0;
      }

      return this.slip.sales.reduce(
        (acc, { totalAmount = 0 }) => acc + totalAmount,
        0
      );
    },
    couponGridProps() {
      return {
        provides: [Edit, ForeignKey, Resize],
        allowResizing: true,
        allowFiltering: false,
        allowSorting: false,
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        isInPopup: true,
        selectionSettings: { type: "Single", enableToggle: false },
        useCommonStyles: false,
        noColumnWidth: 40,
        columns: [
          {
            field: "couponId",
            visible: false,
            isPrimaryKey: true,
          },
          {
            allowEditing: false,
            field: "couponName",
            headerText: "쿠폰명",
            textAlign: "Left",
            width: 120,
          },
          {
            allowEditing: false,
            field: "pubDate",
            headerText: "발행일자",
            textAlign: "center",
            width: 80,
          },
          {
            allowEditing: false,
            field: "startDate",
            headerText: "시작일자",
            textAlign: "center",
            width: 80,
          },
          {
            allowEditing: false,
            field: "expirDate",
            headerText: "만료일자",
            textAlign: "center",
            width: 80,
          },
          {
            allowEditing: false,
            field: "dcMethod",
            headerText: "할인방법",
            width: 80,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "DC_METHOD",
          },
          {
            allowEditing: false,
            field: "dcAmt",
            headerText: "할인금액(율)",
            textAlign: "center",
            width: 80,
            isNumericType: true,
          },
          {
            allowEditing: false,
            field: "bsnName",
            headerText: "영업코드",
            textAlign: "center",
            width: 80,
          },
          {
            allowEditing: false,
            field: "useStartTime",
            headerText: "시작시간",
            textAlign: "center",
            width: 80,
          },
          {
            allowEditing: false,
            field: "useEndTime",
            headerText: "종료시간",
            textAlign: "center",
            width: 80,
          },
          {
            allowEditing: false,
            field: "memberName",
            headerText: "회원명",
            textAlign: "center",
            width: 80,
          },
          {
            allowEditing: false,
            field: "memberDiv",
            headerText: "회원구분",
            textAlign: "center",
            width: 80,
            isCommonCodeField: true,
            groupCode: "MEMBER_DIV",
          },
        ],
      };
    },
    salesGridProps() {
      const dataSource = this.sales.map((record) => {
        const {
          price,
          quantity,
          priceDivision,
          discountAmount,
          discountRate,
        } = record;

        const priceOfQuantity = price * quantity;
        const calcDiscountAmount =
            (priceDivision === "RATE" || priceDivision === 'STFF')
            ? (priceOfQuantity * (100 - discountRate)) / 100
            : discountAmount;
        const calcDiscountRate =
            (priceDivision === "RATE" || priceDivision === 'STFF')
            ? discountRate
            : Math.floor((discountAmount / priceOfQuantity) * 100 * 100) / 100;

        return {
          ...record,
          discountAmount: calcDiscountAmount,
          discountRate: calcDiscountRate,
          priceOfQuantity: price * quantity,
        };
      });

      const selectedRowIndex = dataSource.findIndex(
        ({ _rid }) => _rid === this.select_rid
      );

      return {
        allowResizing: true,
        provides: [Aggregate, Edit, ForeignKey, Resize],
        aggregates: [
          {
            columns: [
              {
                field: "productName",
                type: "Custom",
                footerTemplate() {
                  return {
                    template: Vue.component("footerTemplate", {
                      template: `<div style="text-align: center">합계</div>`,
                    }),
                  };
                },
              },
              {
                field: "charge",
                type: "Custom",
                customAggregate: (data) => {
                  return _sumBy(
                    data.result,
                    ({ charge = 0, deleted = false }) => (deleted ? 0 : charge)
                  );
                },
                footerTemplate() {
                  return {
                    template: Vue.component("footerTemplate", {
                      template: `<div style="text-align: right">{{ data.Custom | numberWithCommas }}</div>`,
                      filters: {
                        numberWithCommas,
                      },
                      data() {
                        return {
                          data: {},
                        };
                      },
                    }),
                  };
                },
              },
            ],
          },
        ],
        columns: [
          {
            allowEditing: false,
            field: "_rid",
            visible: false,
            isPrimaryKey: true,
          },
          {
            field: "priceDivision",
            visible: false,
          },
          {
            field: "noTaxCode",
            visible: false,
          },
          {
            allowEditing: false,
            field: "productName",
            headerText: "상품명",
            textAlign: "Left",
            width: 115,
          },
          {
            allowEditing: false,
            field: "price",
            headerText: "단가",
            textAlign: "Right",
            type: "string",
            isNumericType: true,
            width: 104,
          },
          {
            allowEditing: false,
            field: "quantity",
            headerText: "수량",
            textAlign: "Center",
            width: 104,
          },
          {
            allowEditing: false,
            field: "priceOfQuantity",
            headerText: "판매금액",
            textAlign: "Right",
            type: "string",
            isNumericType: true,
            width: 127,
          },
          {
            allowEditing: false,
            field: "discountAmount",
            headerText: "할인금액",
            textAlign: "Right",
            type: "string",
            isNumericType: true,
            width: 127,
          },
          {
            allowEditing: false,
            field: "discountRate",
            headerText: "할인율",
            textAlign: "Right",
            type: "string",
            isNumericType: true,
            width: 115,
          },
          {
            allowEditing: false,
            field: "charge",
            headerText: "적용금액",
            textAlign: "Right",
            type: "string",
            isNumericType: true,
            width: 127,
          },
          {
            allowEditing: false,
            field: "remarks",
            type: "string",
            headerText: "할인사유",
            textAlign: "Left",
            width: 127,
          },
        ],
        dataSource,
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        isInPopup: true,
        selectedRowIndex: selectedRowIndex < 0 ? undefined : selectedRowIndex,
        selectionSettings: { type: "Multiple", enableToggle: false },
        useCommonStyles: false,
      };
    },
  },
  methods: {
    async show({ _rid, sales, callback }) {
      console.log('sales.===>', sales);
      this.select_rid = _rid;
      this.sales = sales;
      this.callback = callback;
      this.$refs["dialog"].show();

      if (this.slip?.visitor) {
        this.getCoupon();
      } else {
        this.isVisibleCoupon = false;
      }
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.$emit("close");
    },
    onSalesGridQueryCellInfo(args) {
      if (args.column.field === "charge") {
        const { noTaxCode } = args.data;

        args.cell.style.color = commonCodesGetColorValue(
          "NOTAX_CODE",
          noTaxCode
        );
      }
    },
    onNumpadButtonClick(event, code) {
      if (this.amount === 0 && !isNaN(Number(code))) {
        this.amount = Number(code);
        return;
      }

      switch (code) {
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
        case "0":
        case "00":
          this.amount = Number(`${this.amount}${code}`);
          break;
        case "Clear":
          this.amount = 0;
          break;
        case "Backspace":
          if (this.amount < 10) {
            this.amount = 0;
            return;
          }

          this.amount = Number(
            `${this.amount}`.substr(0, `${this.amount}`.length - 1)
          );
          break;
      }
    },
    onForceDiscountClick(amount) {
      this.amount = amount;
      this.onSubmitClick();
    },
    onSubmitClick() {
      const gridRef = this.$refs["salesGrid"];

      const selectedRecords = gridRef.getSelectedRecords() || [];
      if (!selectedRecords || selectedRecords.length === 0) {
        this.errorToast("할인을 적용할 항목을 선택해주세요");
        return;
      }

      const discountChanges = selectedRecords.map((sale) => {
        const { _rid, quantity, priceOfQuantity } = sale;
        const targetIndex = (
          gridRef.getBatchCurrentViewRecords() || []
        ).findIndex((r) => r._rid === _rid);
        if (targetIndex < 0) {
          return;
        }

        const newPriceDivision = this.priceDivision;

        const inputAmount =
          this.priceDivision === "AMT"
            ? priceOfQuantity < this.amount
              ? priceOfQuantity
              : this.amount
            : 100 < Math.abs(this.amount)
            ? 100 * (-this.amount / this.amount)
            : this.amount;

        const newDiscountAmount =
          this.priceDivision === "AMT"
            ? inputAmount * (quantity < 0 ? -1 : 1)
            : (priceOfQuantity * inputAmount) / 100;
        const newDiscountRate =
          Math.floor(
            Math.abs(
                (this.priceDivision === "RATE" || this.priceDivision === "STFF" )
                ? inputAmount
                : (inputAmount / priceOfQuantity) * 100
            ) *
              (quantity < 0 ? -1 : 1) *
              100
          ) / 100;
        const newDiscountRemarks = this.remarks;

        return {
          _rid: sale._rid,
          priceDivision: newPriceDivision,
          discountAmount: newDiscountAmount,
          discountRate: newDiscountRate,
          remarks: newDiscountRemarks,
        };
      });

      this.callback(discountChanges);
      this.hide();
    },
    onInputClick() {
      this.$refs["input-amount"].focus();
    },
    onCloseClick() {
      this.hide();
    },
    onSalesGridSelected() {
      if (!this.isSalesGridAllSelected) {
        const batchCurrentViewRecords = this.$refs[
          "salesGrid"
          ].getBatchCurrentViewRecords();
        this.$refs.salesGrid.selectRowsByRange(0, batchCurrentViewRecords.length);
      } else {
        this.$refs.salesGrid.selectRowsByRange(0);
      }

      this.isSalesGridAllSelected = !this.isSalesGridAllSelected;
    },
    onSalesGridRowSelected() {
      this.isSalesGridAllSelected = false;
    },
    onCouponHeaderClicked() {
      this.isVisibleCoupon = !this.isVisibleCoupon;
    },
    async getCoupon() {
      const data = await GolfErpAPI.fetchStoreCoupon({
        storeCode: this.store?.code,
        chkinId: this.slip?.visitor?.teamId,
      });

      console.log('data.===>', data);
      this.coupons =
        data
          .filter(item => !this.sales.filter(sale => sale.couponId).map(sale => sale.couponId).includes(item.couponId))
          .map(item => ({
          ...item,
          couponName: item.tgCouponKind.couponName,
          bsnName: item.bsnCode === "%" ? "전체" : commonCodesGetComName("BSN_CODE", item.bsnCode),
        }));

      if (!(this.coupons.length > 0)) {
        this.isVisibleCoupon = false;
      }
    },
    onCouponGridRecordDoubleClicked(args) {
      console.log('onCouponGridRecordDoubleClicked.args.===>', args);

      const gridRef = this.$refs["salesGrid"];

      const selectedRecords = gridRef.getSelectedRecords() || [];
      if (!selectedRecords || selectedRecords.length === 0) {
        this.errorToast("쿠폰을 적용할 항목을 선택해주세요");
        return;
      } else if (selectedRecords.length > 1) {
        this.errorToast("쿠폰을 적용할 항목을 하나만 선택해주세요");
        return;
      }

      const {
        rowData: {
          couponId,
          dcMethod,
          dcAmt,
        },
      } = args;

      console.log('couponId.===>', couponId);
      console.log('dcMethod.===>', dcMethod);
      console.log('dcAmt.===>', dcAmt);

      const discountChanges = selectedRecords.map((sale) => {
        console.log('sale.===>', sale);
        const { _rid, quantity, priceOfQuantity } = sale;
        const targetIndex = (
          gridRef.getBatchCurrentViewRecords() || []
        ).findIndex((r) => r._rid === _rid);
        if (targetIndex < 0) {
          return;
        }

        const newPriceDivision = "COUPON";

        const inputAmount =
          dcMethod === "AMT"
            ? priceOfQuantity < dcAmt
              ? priceOfQuantity
              : dcAmt
            : 100 < Math.abs(dcAmt)
              ? 100 * (-dcAmt / dcAmt)
              : dcAmt;

        const newDiscountAmount =
          dcMethod === "AMT"
            ? inputAmount * (quantity < 0 ? -1 : 1)
            : (priceOfQuantity * inputAmount) / 100;
        const newDiscountRate =
          Math.floor(
            Math.abs(
              dcMethod === "RATE"
                ? inputAmount
                : (inputAmount / priceOfQuantity) * 100
            ) *
            (quantity < 0 ? -1 : 1) *
            100
          ) / 100;
        const newDiscountRemarks = this.remarks;

        return {
          _rid: sale._rid,
          priceDivision: newPriceDivision,
          discountAmount: newDiscountAmount,
          discountRate: newDiscountRate,
          remarks: newDiscountRemarks,
          couponId: couponId,
          dcMethod: dcMethod,
        };
      });

      console.log('discountChanges.===>', discountChanges);

      this.callback(discountChanges);
      this.hide();
    },
  },
};
</script>

<template>
  <div>
    <ejs-dialog
      ref="lotteryReservationPopup"
      header="추첨예약 정보"
      width="832"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :cssClass="
        lotteryReservation.lotteryId
          ? $t('className.popup.productCodePopupModify')
          : $t('className.popup.productCodePopupAdd')
      "
      @close="onLotteryReservationPopupClosed"
    >
      <div class="window reservationInformation">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">추첨예약 정보</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <article class="body-article">
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0101">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">예약자</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field memberNameNumber">
                                    <!-- 필수 : required -->
                                    <div class="title required">
                                      회원명/번호
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group input memberName">
                                          <div class="form">
                                            <input-text
                                              id="memberName"
                                              ref="memberName"
                                              v-model="
                                                lotteryReservation.memberName
                                              "
                                              @change="onMemberNameChanged"
                                              placeholder="회원명"
                                              @keydown.enter="
                                                memberNameInputKeyEnter
                                              "
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                        <li class="item form-group text memberNo">
                                          {{ lotteryReservation.memberNo }}
                                        </li>
                                        <li class="item form-group button">
                                          <ul class="button">
                                            <li class="search">
                                              <erp-button
                                                button-div="GET"
                                                ref="memberPopupOpenButton"
                                                @click.native="
                                                  onMemberPopupOpen(
                                                    true,
                                                    memberPopupType.RESV,
                                                    lotteryReservation.memberName,
                                                    null
                                                  )
                                                "
                                              >
                                                조회
                                              </erp-button>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">회원구분</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="memberDiv"
                                              v-model="
                                                lotteryReservation.memberDiv
                                              "
                                              :dataSource="
                                                lotteryReservationOptions.memberDivOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="commonCodeFields"
                                              cssClass="body-data-dropdown"
                                              @change="
                                                onLotteryReservationMemberDivDropDownListChanged
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">회원등급</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="memberGrade"
                                              v-model="
                                                lotteryReservation.memberGrade
                                              "
                                              :dataSource="
                                                lotteryReservationOptions.memberGradeOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="commonCodeFields"
                                              cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">연락처</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <component-telephone
                                              id="contactTel"
                                              name="contactTel"
                                              ref="contactTel"
                                              v-model="
                                                lotteryReservation.contactTel
                                              "
                                              :max-length="11"
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">성별</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="popupSexCodeDropdown"
                                              v-model="
                                                lotteryReservation.sexCode
                                              "
                                              :dataSource="
                                                lotteryReservationOptions.sexCodeOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="commonCodeFields"
                                              cssClass="body-data-dropdown"
                                            ></ejs-dropdownlist>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">지역</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="popupAreaCodeDropdown"
                                              v-model="
                                                lotteryReservation.areaCode
                                              "
                                              :dataSource="
                                                lotteryReservationOptions.areaCodeOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="commonCodeFields"
                                              cssClass="body-data-dropdown"
                                            ></ejs-dropdownlist>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <section class="article-section section-0102">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">추첨 신청</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">추첨일자</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-date
                                              id="lotteryDate"
                                              ref="lotteryDate"
                                              v-model="
                                                lotteryReservation.lotteryDate
                                              "
                                              type="body-data"
                                              format="YYYY-MM-DD"
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">
                                      희망시간대 1
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="hopeTimeZone1"
                                              v-model="
                                                lotteryReservation.hopeTimeZone1
                                              "
                                              :dataSource="
                                                lotteryReservationOptions.hopeTimeZoneOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="commonCodeFields"
                                              cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">희망시간대 2</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="hopeTimeZone2"
                                              v-model="
                                                lotteryReservation.hopeTimeZone2
                                              "
                                              :dataSource="
                                                lotteryReservationOptions.partDivOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="commonCodeFields"
                                              cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">희망코스</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="popupHopeCourseDropdown"
                                              v-model="
                                                lotteryReservation.hopeCourse
                                              "
                                              :dataSource="
                                                lotteryReservationOptions.hopeCourseOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="commonCodeFields"
                                              cssClass="body-data-dropdown"
                                            ></ejs-dropdownlist>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">희망홀 구분</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="popupHopeHoleDivDropdown"
                                              v-model="
                                                lotteryReservation.hopeHoleDiv
                                              "
                                              :dataSource="
                                                lotteryReservationOptions.hopeHoleDivOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="commonCodeFields"
                                              cssClass="body-data-dropdown"
                                            ></ejs-dropdownlist>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">희망팀수</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-number
                                              v-model="
                                                lotteryReservation.hopeTeamCnt
                                              "
                                              v-on:keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">셀프라운드</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group check">
                                          <!-- 포커스 : focus -->
                                          <ul class="check">
                                            <li>
                                              <label>
                                                <input
                                                  type="radio"
                                                  id="selfFlagTrue"
                                                  v-model="
                                                    lotteryReservation.selfFlag
                                                  "
                                                  value="true"
                                                />
                                                <i></i>
                                                <div class="label">
                                                  노캐디
                                                </div>
                                              </label>
                                            </li>
                                            <li>
                                              <label>
                                                <input
                                                  type="radio"
                                                  id="selfFlagFalse"
                                                  v-model="
                                                    lotteryReservation.selfFlag
                                                  "
                                                  value="false"
                                                />
                                                <i></i>
                                                <div class="label">캐디</div>
                                              </label>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">조인 신청</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group check">
                                          <!-- 포커스 : focus -->
                                          <ul class="check">
                                            <li>
                                              <label>
                                                <input
                                                  type="radio"
                                                  id="joinReqstFlagTrue"
                                                  v-model="
                                                    lotteryReservation.joinReqstFlag
                                                  "
                                                  value="true"
                                                />
                                                <i></i>
                                                <div class="label">예</div>
                                              </label>
                                            </li>
                                            <li>
                                              <label>
                                                <input
                                                  type="radio"
                                                  id="joinReqstFlagFalse"
                                                  v-model="
                                                    lotteryReservation.joinReqstFlag
                                                  "
                                                  value="false"
                                                />
                                                <i></i>
                                                <div class="label">
                                                  아니오
                                                </div>
                                              </label>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field requestContent">
                                    <!-- 필수 : required -->
                                    <div class="title">요청 내용</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-textarea
                                              v-model="
                                                lotteryReservation.requstContents
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field requestContent">
                                    <!-- 필수 : required -->
                                    <div class="title">메모</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-textarea
                                              v-model="
                                                lotteryReservation.memoContents
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0103">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">접수상태</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">접수상태</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          {{
                                            commonCodesGetComName(
                                              "LOTTERY_RCEPT_STATUS",
                                              lotteryReservation.lotteryRceptStatus
                                            )
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">확정시간</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          {{ lotteryReservation.resveTime }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">확정코스</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          {{
                                            commonCodesGetComName(
                                              "COURSE_CODE",
                                              lotteryReservation.resveCourse
                                            )
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0104">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">접수자</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">접수자</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          {{ lotteryReservation.insertName }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">접수일시</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          {{ lotteryReservation.insertDt }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                    </article>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  @click.native="onSaveButtonClicked"
              >
                저장
              </erp-button>
            </li>
            <li>
              <erp-button
                  button-div="DELETE"
                  :is-icon-custom="true"
                  @click.native="onRceptCancelButtonClicked"
              >
                접수취소
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="lotteryReservationPopupClose"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <member-select-popup
      v-if="isMemberSelectPopupOpen"
      ref="memberSelectPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClosed="memberSelectPopupClosed"
      @popupConfirmed="memberSelectPopupConfirmed"
    />
  </div>
</template>

<style scoped>
body .windowContent .content-body .section-body .article-section.section-0101 .field.memberNameNumber .content .item.input.memberName {width:129px}
body .windowContent .content-body .section-body .article-section.section-0101 .field.memberNameNumber .content .item.text.memberNo {width:123px}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import {formPreventEnterEvent} from "@/utils/formUtil";
import {
  commonCodesGetCommonCode,
  commonCodesGetJsonData,
  commonCodesGetComName
} from "@/utils/commonCodes";

import InputText from "@/components/common/text/InputText";
import InputDate from "@/components/common/datetime/InputDate";
import InputNumber from "@/components/common/InputNumber";
import InputTextarea from "@/components/common/text/InputTextarea";
import ComponentTelephone from "@/components/ComponentTelephone";
import MemberSelectPopup from "@/views/common/MemberSelectPopup";

import GolfErpAPI from "@/api/v2/GolfErpAPI";

export default {
  name: "LotteryReservationPopup",
  components: {
    ErpButton,
    InputText,
    InputDate,
    InputNumber,
    InputTextarea,
    ComponentTelephone,
    MemberSelectPopup,
  },
  mixins: [commonMixin, confirmDialogMixin],
  filters: {},
  data() {
    return {
      isMemberSelectPopupOpen: false,
      memberPopupType: {
        RESV: "RESV", // 예약자
        TRANS: "TRANS", // 위임자
        COMP: "COMP", // 동반자
      },
      commonCodeFields: { text: "comName", value: "comCode" },
      lotteryReservation: {
        lotteryId: null,
        lotteryDate: null,
        insertSno: null,
        lotteryRank: null,
        lotteryRceptStatus: null,
        resveId: null,
        memberName: null,
        membershipId: null,
        memberNo: null,
        memberDiv: null,
        memberGrade: null,
        sexCode: null,
        areaCode: null,
        contactTel: null,
        hopeTimeZone1: null,
        hopeTimeZone2: null,
        hopeCourse: null,
        hopeHoleDiv: null,
        hopeTeamCnt: null,
        selfFlag: false,
        joinReqstFlag: false,
        requstContents: null,
        memoContents: null,
        insertName: null,
        insertDt: null,
        resveTime: null,
        resveCourse: null,
      },
      lotteryReservationOptions: {
        memberDivOptions: [],
        memberGradeOptions: [],
        sexCodeOptions: [],
        areaCodeOptions: [],
        hopeTimeZoneOptions: [],
        partDivOptions: [], // 자유CC는 희망시간대2를 부구분으로 변경
        hopeCourseOptions: [],
        hopeHoleDivOptions: [],
      },
      isSave: false
    };
  },
  created() {},
  destroyed() {},
  computed: {},
  mounted() {
    this.lotteryReservationOptions.memberDivOptions = commonCodesGetCommonCode(
      "MEMBER_DIV"
    );
    this.lotteryReservationOptions.memberGradeOptions = commonCodesGetCommonCode(
      "MEMBER_GRADE"
    );
    this.lotteryReservationOptions.sexCodeOptions = commonCodesGetCommonCode(
      "SEX_CODE"
    );
    this.lotteryReservationOptions.areaCodeOptions = commonCodesGetCommonCode(
      "AREA_CODE"
    );
    this.lotteryReservationOptions.hopeTimeZoneOptions = commonCodesGetCommonCode(
      "HOPE_TIME_ZONE",
      true
    );
    this.lotteryReservationOptions.partDivOptions = commonCodesGetCommonCode(
      "PART_DIV",
      true
    );
    if (
      this.lotteryReservationOptions.partDivOptions.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.lotteryReservationOptions.partDivOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
    this.lotteryReservationOptions.hopeCourseOptions = commonCodesGetCommonCode(
      "COURSE_CODE"
    );
    this.lotteryReservationOptions.hopeHoleDivOptions = commonCodesGetCommonCode(
      "HOLE_DIV"
    );
  },
  methods: {
    commonCodesGetComName,
    preventEnterEvent: formPreventEnterEvent,
    async showPopup(popupData) {
      console.log('popupData.===>', popupData);
      if (popupData?.lotteryId) {
        this.lotteryReservation = await GolfErpAPI.fetchReservationLotteryByLotteryId(popupData.lotteryId);
      } else {
        this.lotteryReservation.lotteryDate = popupData.fromDate;
        this.lotteryReservation.hopeHoleDiv = "18";
        this.lotteryReservation.hopeTeamCnt = "1";
      }
    },
    onLotteryReservationPopupClosed() {
      this.$emit("popupClosed", this.isSave);
    },
    lotteryReservationPopupClose() {
      this.$refs.lotteryReservationPopup.hide();
    },
    async onRceptCancelButtonClicked() {
      if (
        !this.lotteryReservation.lotteryId ||
        this.lotteryReservation.lotteryRceptStatus !== "RCEPT"
      ) {
        this.infoToast("취소 불가능한 접수상태 입니다");
        return;
      }

      const saveData = JSON.parse(JSON.stringify(this.lotteryReservation));
      saveData.lotteryRceptStatus = "RCEPT_CANCEL";

      const lotteryId = await GolfErpAPI.reservationLotterySave(saveData);

      if (lotteryId) {
        this.lotteryReservation = await GolfErpAPI.fetchReservationLotteryByLotteryId(lotteryId);
      }

      this.isSave = true;

      this.infoToast("접수취소되었습니다");
    },
    async onSaveButtonClicked() {
      if (
        this.lotteryReservation.lotteryId &&
        this.lotteryReservation.lotteryRceptStatus !== "RCEPT"
      ) {
        this.infoToast("수정 불가능한 접수상태 입니다");
        return;
      }

      const saveData = JSON.parse(JSON.stringify(this.lotteryReservation));

      saveData.lotteryRceptStatus = null;

      const lotteryId = await GolfErpAPI.reservationLotterySave(saveData);

      if (lotteryId) {
        this.lotteryReservation = await GolfErpAPI.fetchReservationLotteryByLotteryId(lotteryId);
      }

      this.isSave = true;

      this.infoToast("저장되었습니다");
    },
    onMemberNameChanged(args) {
      if (args.event && !!args.value) {
        // console.log('## 예약자명을 바꾼 후 Focus out. 예약자 조회 후 결과에 따라 예약 팝업을 띄운다. 예약자명 검색어 :' + args.value)
        // alert(args.value + '로 검색한 건이 0건이면 비회원 처리, 1건이면 해당 회원을 예약자로 설정, 2건 이상이면 회원정보 팝업을 띄운다')

        // [예약자 Case 분기(항목은 ReservationInfo.java 참고)]
        // #1. 수정인 경우: 예약번호 설정. 추가인 경우 : 예약번호 없음
        // #2. 회원/비회원 위임자의 경우
        // - 회원인 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우) : 예약자명, 회원권 ID, 회원번호, 회원구분, 회원등급, 성별코드, 연락처 설정. 연락자명을 예약자명과 동일하게 설정
        // - 비회원인 경우(검색한 건이 0건이거나, 띄운 회원정보 팝업을 닫는다
        //      : 비회원 선택 시 회원권 ID, 회원번호 Clear, 성별코드(M). 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정.
        //      : 연락자명은 현재 남아있는 예약자명과 동일하게 설정. 나머지는 그대로 놔 둠
        // 회원정보에 없는 비회원이라도 무조건 회원 검색 팝업 호출. 모든 화면 공통 조건.
        this.onMemberPopupOpen(
          false,
          this.memberPopupType.RESV,
          args.value,
          null
        );
      }
    },
    onMemberPopupOpen(
      isOpenedWithButton,
      popupType,
      memberNameNo,
      timeInfoIdx,
      selectedIdx,
      memberDivChanged = false,
      memberGradeChanged = false
    ) {
      this.isMemberSelectPopupOpen = true;

      let memberData = {};
      memberData.memberNameNo = memberNameNo;
      memberData.isOpenedWithButton = isOpenedWithButton;
      memberData.popupType = popupType;
      memberData.memberDivChanged = memberDivChanged;
      memberData.memberGradeChanged = memberGradeChanged;
      if (timeInfoIdx !== null && timeInfoIdx !== undefined) {
        memberData.timeInfoIdx = timeInfoIdx;
      }
      if (selectedIdx !== null && selectedIdx !== undefined) {
        memberData.selectedIdx = selectedIdx;
      }
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(memberData);
      });
    },
    memberSelectPopupConfirmed(data) {
      console.log(JSON.stringify(data, null, 2));
      this.isMemberSelectPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기

      if (data.popupData.popupType === this.memberPopupType.RESV) {
        // 예약자명
        if (data.selectedRowData) {
          if (data.selectedRowData.memberDiv == "NOM") {
            this.setResvNonMemberInfos();
          } else {
            this.setResvMemberInfos(data.selectedRowData);
          }
        }
      }
    },
    memberSelectPopupClosed(data) {
      console.log(JSON.stringify(data, null, 2));
      this.isMemberSelectPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기

      if (data.popupData.popupType === this.memberPopupType.RESV) {
        // 예약자명
        if (data.popupData.memberDivChanged) {
          let commonCodeJsonDataStr = this.lotteryReservationOptions.memberDivOptions.find(
            (commonCode) =>
              commonCode.comCode === this.lotteryReservation.memberDiv
          ).jsonData;
          if (commonCodeJsonDataStr) {
            let commonCodeJsonDataArray = JSON.parse(commonCodeJsonDataStr);
            if (Array.isArray(commonCodeJsonDataArray)) {
              let memberGradeDefaultValue = commonCodeJsonDataArray.find(
                (jsonData) => jsonData.IDX === 4
              );
              console.log(memberGradeDefaultValue);
              if (memberGradeDefaultValue && memberGradeDefaultValue.ATTRB) {
                // 회원구분 변경시 동명이인 회원 검색 팝업 호출후 팝업 그냥 닫으면
                // 회원구분 변경한 값은 그대로 유지.
                // MEMBERSHIP_ID, MEMBER_NO = 기존값 그대로 유지.
                // MEMBER_DIV = 변경한 값
                // MEMBER_GRADE = 회원구분 변경에 따른 값 셋팅.
                this.lotteryReservation.memberGrade =
                  memberGradeDefaultValue.ATTRB;
              }
            }
          }
        } else if (data.popupData.memberGradeChanged) {
          // 회원등급 변경시 동명이인 회원 검색 팝업 호출후 팝업 그냥 닫으면
          // 회원등급 변경한 값은 그대로 유지.
          // MEMBERSHIP_ID, MEMBER_NO, MEMBER_DIV = 기존값 그대로 유지.
          // MEMBER_GRADE = 변경한 값
        } else {
          if (!data.popupData.isOpenedWithButton) {
            // textbox를 통해 팝업을 띄웠는데, 선택하지 않고 닫은 경우
            this.setResvNonMemberInfos();
          }
        }
      }
    },
    setResvMemberInfos(memberInfo) {
      if (this.lotteryReservation) {
        this.lotteryReservation.memberName = memberInfo.memberName;
        this.lotteryReservation.membershipId = memberInfo.membershipId;
        this.lotteryReservation.memberNo = memberInfo.memberNo;
        this.lotteryReservation.memberDiv = memberInfo.memberDiv;
        this.lotteryReservation.memberGrade = memberInfo.memberGrade;
        this.lotteryReservation.sexCode = memberInfo.sexCode;
        this.lotteryReservation.contactTel = memberInfo.hpNo;
        this.lotteryReservation.areaCode = memberInfo.areaCode;
      }
    },
    setResvNonMemberInfos() {
      if (this.lotteryReservation) {
        this.lotteryReservation.membershipId = "";
        this.lotteryReservation.memberNo = "";
        this.lotteryReservation.sexCode = "M";
        this.lotteryReservation.memberDiv = "NOM";
        this.lotteryReservation.memberGrade = commonCodesGetJsonData(
          "MEMBER_DIV",
          "NOM"
        ).find((codeJson) => codeJson.IDX === 4).ATTRB;
      }
    },
    memberNameInputKeyEnter() {
      this.$refs.memberPopupOpenButton.focusIn();
    },
    onLotteryReservationMemberDivDropDownListChanged(args) {
      if (
        args.e instanceof MouseEvent ||
        args.e instanceof KeyboardEvent ||
        args.e instanceof TouchEvent
      ) {
        if (args.value === "NOM") {
          let commonCodeJsonDataStr = this.lotteryReservationOptions.memberDivOptions.find(
            (commonCode) =>
              commonCode.comCode === this.lotteryReservation.memberDiv
          ).jsonData;
          if (commonCodeJsonDataStr) {
            let commonCodeJsonDataArray = JSON.parse(commonCodeJsonDataStr);
            if (Array.isArray(commonCodeJsonDataArray)) {
              let memberGradeDefaultValue = commonCodeJsonDataArray.find(
                (jsonData) => jsonData.IDX === 4
              );
              console.log(memberGradeDefaultValue);
              if (memberGradeDefaultValue && memberGradeDefaultValue.ATTRB) {
                this.lotteryReservation.membershipId = "";
                this.lotteryReservation.memberNo = "";
                this.lotteryReservation.memberGrade =
                  memberGradeDefaultValue.ATTRB;
              }
            }
          }
        } else {
          // 회원구분이 비회원이 아니면 동명이인 회원 검색 팝업 호출.
          this.onMemberPopupOpen(
            false,
            this.memberPopupType.RESV,
            this.lotteryReservation.memberName,
            null,
            null,
            true
          );
        }
      }
    }
  }
};
</script>

<template>
  <div id="holll">
    <ejs-dialog
      ref="blacklistPopup"
      :header="`위약/블랙리스트 목록`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="1180"
      height="600"
      :animationSettings="{ effect: 'None' }"
      :isModal="true"
      :close="onPopupClosed"
      @open="onPopupOpend"
    >
      <div class="window placeboBlacklistList">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section
                  v-if="isWaitingReserveRegistration"
                  class="article-section section-01"
                >
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">대기예약 정보</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="waitingReservationGrid"
                        :dataSource="waitingReservationList"
                        :allowSelection="false"
                        :columns="waitingReservationGridColumn"
                        :provides="grid"
                        :isSelectedRowRetain="false"
                        :allowSorting="false"
                        :allowFiltering="false"
                      />
                    </div>
                  </div>
                </section>
                <section
                  v-else-if="!isCheckIn"
                  class="article-section section-01"
                >
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">예약접수 정보</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="reservationTimeGrid"
                        :dataSource="reservationTimeList"
                        :allowSelection="false"
                        :columns="reservationTimeGridColumn"
                        :provides="grid"
                        :isSelectedRowRetain="false"
                        :allowSorting="false"
                        :allowFiltering="false"
                      />
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-02">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">위약 목록</div>
                      <div class="header-caption">
                        [{{ numberWithCommas(blacklistList.length) }}건]
                      </div>
                       <div
                         v-if="minPunishStartDate && maxPunishEndDate"
                         class="header-caption sanctionPeriod"
                       >
                        제재기간 : {{ minPunishStartDate }} ~
                        {{ maxPunishEndDate }}
                      </div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="blacklistGrid"
                        :dataSource="blacklistList"
                        :allowSelection="false"
                        :columns="blacklistGridColumn"
                        :provides="grid"
                        :isSelectedRowRetain="false"
                        :allowSorting="false"
                        :allowFiltering="false"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                ref="confirmDialogOkay"
                button-div="CLOSE"
                id="confirmDialogOkay"
                @click.native="$refs.blacklistPopup.hide()"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="$refs.blacklistPopup.hide()">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { ForeignKey, Resize } from "@syncfusion/ej2-vue-grids";

import { gridUtilGetTelColumnAccess } from "@/utils/gridUtil";

import { commonCodesGetComName } from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { DATE_FORMAT_YYYY_MM_DD } from "@/utils/date";
import {getBlacklist} from '@/api/blacklist';
import {orderBy as _orderBy} from 'lodash';
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "BlacklistPopup",
  props: [],
  components: {
    EjsGridWrapper,
    ErpButton,
  },
  data: function () {
    return {
      grid: [Resize, ForeignKey],
      reservationTimeList: [],
      waitingReservationList: [],
      blacklistList: [],
      isCheckIn: false,
      minPunishStartDate: null,
      maxPunishEndDate: null,
      reservationTimeGridColumn: [
        {
          field: "resveDate",
          headerText: "예약일자",
          width: 120,
          textAlign: "center",
        },
        {
          field: "resveTime",
          headerText: "시간",
          width: 90,
          textAlign: "center",
        },
        {
          field: "resveCourse",
          headerText: "코스",
          width: 120,
          textAlign: "center",
          comCode: "COURSE_CODE",
          valueAccessor: this.valueAccessor,
        },
        {
          field: "holeDiv",
          headerText: "홀",
          width: 90,
          textAlign: "center",
          comCode: "HOLE_DIV",
          valueAccessor: this.valueAccessor,
        },
        {
          field: "resveName",
          headerText: "예약자명",
          width: 120,
        },
        {
          field: "contactTel",
          headerText: "연락처",
          width: 120,
          textAlign: "center",
          valueAccessor: (field, data) =>
            gridUtilGetTelColumnAccess(field, data),
        },
        {
          field: "resveKind",
          headerText: "예약종류",
          comCode: "RESVE_KIND",
          valueAccessor: this.valueAccessor,
        },
        {
          field: "timeDiv",
          headerText: "타임구분",
          comCode: "TIME_DIV",
          valueAccessor: this.valueAccessor,
        },
      ],
      waitingReservationGridColumn: [
        {
          field: "waitingDate",
          headerText: "대기일자",
          width: 120,
          textAlign: "center",
        },
        {
          field: "hopeTime1",
          headerText: "희망시간1",
          width: 90,
          textAlign: "center",
        },
        {
          field: "hopeTime2",
          headerText: "희망시간2",
          width: 90,
          textAlign: "center",
        },
        {
          field: "hopeCourse",
          headerText: "희망코스",
          width: 120,
          textAlign: "center",
          comCode: "COURSE_CODE",
          valueAccessor: this.valueAccessor,
        },
        {
          field: "hopeHoleDiv",
          headerText: "희망홀",
          width: 90,
          textAlign: "center",
          comCode: "HOLE_DIV",
          valueAccessor: this.valueAccessor,
        },
        {
          field: "memberName",
          headerText: "회원명",
          width: 120,
        },
        {
          field: "contactTel",
          headerText: "연락처",
          width: 120,
          textAlign: "center",
          valueAccessor: (field, data) =>
            gridUtilGetTelColumnAccess(field, data),
        },
      ],
      blacklistGridColumn: [
        {
          field: "occurDate",
          headerText: "발생일자",
          width: 90,
          textAlign: "center",
        },
        {
          field: "memberName",
          headerText: "위약자명",
          width: 90,
        },
        {
          field: "memberNo",
          headerText: "회원번호",
          width: 90,
        },
        {
          field: "punshStartDate",
          headerText: "제재 시작일",
          width: 90,
          textAlign: "center",
        },
        {
          field: "punshEndDate",
          headerText: "제재 종료일",
          width: 90,
          textAlign: "center",
        },
        {
          field: "bsnCode",
          headerText: "영업구분",
          width: 90,
          textAlign: "center",
          comCode: "BSN_CODE",
          valueAccessor: this.valueAccessor,
        },
        {
          field: "penltyCode",
          headerText: "위약코드",
          width: 120,
          comCode: "PENLTY_CODE",
          valueAccessor: this.valueAccessor,
        },
        {
          field: "penltyResn",
          headerText: "위약사유",
        },
        {
          field: "penltyLmttScope",
          headerText: "위약제한 구분",
          width: 120,
          comCode: "PENLTY_LMTT_SCOPE",
          valueAccessor: this.valueAccessor,
        },
      ],
      isWaitingReserveRegistration: false,
    };
  },
  mounted: function () {},
  methods: {
    numberWithCommas,
    valueAccessor(field, data, column) {
      let code = data[field];
      let returnCode = null;
      if (code != null) {
        returnCode = commonCodesGetComName(column.comCode, code);
      }
      return returnCode;
    },
    async showBlackListPopup(args, isWaitingReserveRegistration = false) {
      this.isWaitingReserveRegistration = isWaitingReserveRegistration;
      if (isWaitingReserveRegistration) {
        const { waitingReservationInfo } = args;
        this.waitingReservationList = [];
        this.waitingReservationList.push({
          waitingDate: waitingReservationInfo.waitingDate,
          hopeTime1: waitingReservationInfo.hopeTime1,
          hopeTime2: waitingReservationInfo.hopeTime2,
          hopeCourse: waitingReservationInfo.hopeCourse,
          hopeHoleDiv: waitingReservationInfo.hopeHoleDiv,
          memberName: waitingReservationInfo.memberName,
          contactTel: waitingReservationInfo.contactTel,
        });
        const { value } = await getBlacklist({
          membershipId: waitingReservationInfo?.membershipId,
          linkMemberSelect: false,
          isNowDate: true,
          isPenltyLmttScopeNa: false,
        });
        this.blacklistList = _orderBy(value?.blacklistList, "occurDate", ["desc"]);
      } else {
        const {
          reservationTimeList,
          reservationInfo,
          blacklistList,
        } = args;
        if (Array.isArray(reservationTimeList) && reservationInfo) {
          this.reservationTimeList = reservationTimeList;
          // 예약자명, 연락처, 예약종류 설정
          this.reservationTimeList.forEach((timeInfo) => {
            timeInfo.resveName = reservationInfo.resveName;
            timeInfo.contactTel = reservationInfo.contactTel;
            timeInfo.resveKind = reservationInfo.resveKind;
          });
          this.blacklistList = blacklistList;
          this.$refs.blacklistPopup.show();
          } else if(reservationTimeList === null && reservationInfo === null) {
            this.blacklistList = blacklistList;
            this.isCheckIn = true;

            console.log("위약 리스트" ,blacklistList);
          }
        }
      if (
        Array.isArray(this.blacklistList) &&
        this.blacklistList.length > 0
      ) {
        this.minPunishStartDate = moment().format(DATE_FORMAT_YYYY_MM_DD);
        this.maxPunishEndDate = this.blacklistList.reduce(
          (max, blackList) => {
            if (blackList.punshEndDate) {
              if (max) {
                return blackList.punshEndDate > max
                    ? blackList.punshEndDate
                    : max;
              } else {
                return blackList.punshEndDate;
              }
            } else {
              return max;
            }
          },
          this.blacklistList[0].punshEndDate
        );
      }
    },
    onPopupClosed: function () {
      this.$emit("popupClosed");
    },
    async onPopupOpend() {
      await this.$nextTick();
      this.$refs.confirmDialogOkay.focus();
    },
  },
};
</script>

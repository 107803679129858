<template>
  <div>
    <ejs-dialog
        ref="storeCodeSelectPopupDialog"
        header="매장코드 검색"
        :animationSettings="animationSettings"
        :showCloseIcon="true"
        width="890"
        :close="dialogClose"
        :position="{ x: 'center', y: 'center' }"
        :enableResize="false"
        :isModal="true"
    >
      <div class="window storeCodeSearch">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field codename">
                    <div class="title">코드명</div>
                    <ul class="content">
                      <li class="item">
                        <input-text
                            v-model="searchCodeName"
                            @keydown.enter="searchCode"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button button-div="GET" v-on:click.native="searchCode">
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article" style="display: flex">
                <div class="article-left">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-01">
                    <div class="section-body">
                      <div class="body-grid">
                        <ejs-grid-wrapper
                            :id="'Grid'"
                            ref="Grid"
                            height="423"
                            :allowResizing="true"
                            :allowSelection="true"
                            :allowPaging="true"
                            :pageSettings="{ pageCount: 3, pageSize: 15 }"
                            :dataSource="srcData"
                            :selectionSettings="selectionOptions"
                            :columns="columns"
                            :provides="grid"
                        />
                      </div>
                    </div>
                  </section>
                </div>
                <div class="article-center" style="width: 29px; height: 423px">
                  <section class="article-section section-03">
                    <div
                        class="section-body"
                        style="overflow: hidden;border: none;background-color: transparent;"
                    >
                      <ul class="body-button">
                        <li class="add">
                          <erp-button
                              button-div="SAVE"
                              @click.native="onRegisterClick('add')"
                          >
                            추가
                          </erp-button>
                        </li>
                        <li class="delete">
                          <erp-button
                              button-div="Delete"
                              @click.native="onRegisterClick('delete')"
                          >
                            제거
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </section>
                </div>
                <div class="article-right">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-02">
                    <div class="section-body">
                      <div class="body-grid">
                        <ejs-grid-wrapper
                            :id="'DestGrid'"
                            ref="DestGrid"
                            height="423"
                            :allowResizing="true"
                            :allowSelection="true"
                            :allowPaging="true"
                            :dataSource="destData"
                            :selectionSettings="selectionOptions"
                            :columns="columns"
                            :provides="grid"
                        />
                      </div>
                    </div>
                  </section>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button button-div="SAVE" v-on:click.native="confirmCode">
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" v-on:click.native="dialogClose"> 닫기 </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import { RowDD, Selection, Page } from "@syncfusion/ej2-vue-grids";

import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import InputText from "@/components/common/text/InputText";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "storeCodeSelectPopup",
  components: {
    InputText,
    EjsGridWrapper,
    ErpButton,
  },
  data() {
    return {
      grid: [RowDD, Selection, Page],
      selectedCodes: [],
      animationSettings: { effect: "None" },
      searchCodeName: "",
      srcData: commonCodesGetCommonCode("STORE_CODE"),
      destData: [],
      selectionOptions: { type: "Multiple" },
      columns: [
        {
          field: "comCode",
          headerText: "상품코드",
          allowEditing: false,
          isPrimaryKey: true,
          visible: false,
        },
        {
          field: "comName",
          headerText: "코드명",
          allowEditing: false,
          minWidth: 16,
          textAlign: "left",
        },
      ],
    };
  },
  methods: {
    showPopup(selectedCodes) {
      this.$refs.storeCodeSelectPopupDialog.show();
      this.selectedCodes = selectedCodes;

      this.loadData();
    },
    loadData() {
      this.destData = this.selectedCodes;

      // 선택된 것 제거
      this.filteringSrcData(this.destData);
    },
    searchCode() {
      this.srcData = commonCodesGetCommonCode("STORE_CODE");
      this.srcData = this.srcData.filter((code) =>
          code.comName.includes(this.searchCodeName.trim())
      );

      // 선택된 것 제거
      this.filteringSrcData(this.$refs.DestGrid.getCurrentViewRecords());
    },
    confirmCode() {
      this.$emit("popupConfirmed", this.$refs.DestGrid.getCurrentViewRecords());
      this.$refs.storeCodeSelectPopupDialog.hide();
    },
    dialogClose() {
      this.$emit("popupClosed", null);
    },
    filteringSrcData(currnetDestGridRecords) {
      this.srcData = this.srcData.filter(
          (item) =>
              currnetDestGridRecords.findIndex(
                  (destItem) => destItem.comCode === item.comCode
              ) === -1
      );
    },
    onRegisterClick(div) {
      const leftGrid = this.$refs.Grid;
      const rightGrid = this.$refs.DestGrid;
      const selectRecords = div === 'add' ? leftGrid.getSelectedRecords() : rightGrid.getSelectedRecords();
      const addGrid =  div === 'add' ? rightGrid : leftGrid;
      const deleteGrid = div === 'add' ? leftGrid : rightGrid;
      selectRecords.forEach((rowData) => {
        delete rowData._rid;
        const idx = deleteGrid.dataSource.findIndex((f) => f.comCode === rowData.comCode);
        if(idx !== -1) {
          deleteGrid.dataSource.splice(idx,1);
        }
        addGrid.dataSource.push(rowData);
      });
    }
  },
};
</script>
<style scoped>
.article-section.section-03 {padding: 9px 3px;}
.article-section.section-03 .section-body {display: flex; align-items: center;}
.article-section.section-03 .body-button {display: block; list-style: none; float: left; padding: 1px 0 0 0; margin: 0;}
.article-section.section-03 .body-button li {padding: 4px 0 5px 0;}
.article-section.section-03 .body-button li .e-btn,
.article-section.section-03 .body-button li .e-btn:hover,
.article-section.section-03 .body-button li .e-btn:focus {box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.06);}
.article-section.section-03 .body-button li .e-btn {display: block; overflow: hidden; width: 23px; height: 23px; padding: 0; border-color: #ccc; border-radius: 3px; background: #fff url('../../assets/images/common/body-button.png') no-repeat center 0; text-indent: -1000px;}
.article-section.section-03 .body-button li .e-btn:hover,
.article-section.section-03 .body-button li .e-btn:focus {background-color: #f0f0f0;}
.article-section.section-03 .body-button li.delete .e-btn {background-position: center -21px;}
</style>
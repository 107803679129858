import layout from '@/views/layout/layout';

const checkinRoute = {
  path: '/front',
  component: layout,
  redirect: '/front/visit',
  children: [
    {
      path: 'visit',
      name: 'frontCheckinVisit',
      component: () => import('@/views/checkin/FrontCheckinVisit'),
      meta: {
        enableMemo: true,
        currentView: 'FRONT',
        useUsualStyles: true,
      },
    },
    {
      path: 'group-conf',
      name: 'groupConf',
      component: () => import('@/views/checkin/GroupConf'),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: 'visitor-statement',
      name: 'VisitorStatement',
      component: () => import('@/views/checkin/VisitorStatement'),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: 'payment-history',
      name: 'PaymentHistory',
      component: () => import('@/views/checkin/PaymentHistory'),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "paymt-code-by-visit-statements",
      name: "paymtCodeByVisitStatements",
      component: () => import('@/views/checkin/PaymtCodeByVisitStatements'),
      meta: {
        useUsualStyles: true
      }
    },
    {
      path: "payment-settlements-by-hole",
      name: "PaymentSettlementsByHole",
      component: () => import('@/views/checkin/PaymentSettlementsByHole'),
      meta: {
        useUsualStyles: true
      }
    },
    {
      path: "postponement",
      name: "Postponement",
      component: () => import('@/views/checkin/Postponement'),
      meta: {
        useUsualStyles: true
      }
    },
  ],
};

export default checkinRoute;

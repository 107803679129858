<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">영업일자</div>
            <ul class="content">
              <li class="item date">
                <input-date
                  ref="searchDatePicker"
                  format="YYYY-MM-DD"
                  type="lookup-condition"
                  v-model="bsnDateValueModel"
                />
              </li>
              <li class="item text">
                (<span
                  :style="{ color: getDayOfWeekCaptionColor(filter.bsnDate) }"
                >
                  {{ getDayOfWeekCaption(filter.bsnDate) }} </span
                >)
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              v-on:click.native="handleSearchClick"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <ul class="lookup-button">
          <li class="lookup keyColor">
            <erp-button
                button-div="GET"
                :is-icon-custom="true"
                :is-custom-shortcut-button="true"
                shortcut-key="lockerNumberRegistration.shortcuts.searchLockerInfo"
                :shortcut="{key: 'F12'}"
                :ignore="isPopupOpened"
                v-on:click.native="openLockerStatusPopup"
            >
              락카상황판 조회
            </erp-button>
          </li>
        </ul>
        <div class="lookup-detail">
          <erp-button
              button-div="GET"
              :is-icon-custom="true"
              v-on:click.native="showSearchDetailPopup"
          >
            상세검색
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <section class="article-section section-01">
          <locker-information-header
            v-if="getLockerTabKey(selectedTabIndex) === 'LockerInformationList'"
            :totalSize="lockerInformationGridDataSourceLength"
            :isPopupOpened="isPopupOpened"
            :isModifyMode.sync="isModifyMode"
            @addLockerClick="handleAddLockerClick"
            @deleteLockerClick="handleDeleteLockerClick"
            @saveLockerInformationClick="handleSaveLockerInformationClick"
            @postOrderSettingsClick="handlePostOrderSettingsClick"
            @postOrderResetClick="handlePostOrderResetClick"
            @excelClick="handleExcelClick"
          />
          <locker-daily-information-header
            v-else-if="getLockerTabKey(selectedTabIndex) === 'LockerDailyInformation'"
            @setLockerStatusClick="handleSetLockerStatusClick"
            @openCreateLockerInfoInBatchPopupClick="handleOpenCreateLockerInfoInBatchPopupClick"
          />
          <div class="section-caption">
            <div class="caption-navigation">
              <ejs-tab
                heightAdjustMode="Auto"
                :showCloseButton="false"
                :selected="handleTabSelected"
              >
                <e-tabitems>
                  <template v-for="{ key, text } in getLockerTabs()">
                    <e-tabitem v-bind:key="key" :header="{ text }" />
                  </template>
                </e-tabitems>
              </ejs-tab>
            </div>
          </div>
          <div class="section-body">
            <article class="body-article">
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section class="article-section section-0101">
                <div class="section-body" style="border: none">
                  <div class="body-grid">
                    <locker-information-grid
                      class="info"
                      v-if="getLockerTabKey(selectedTabIndex) === 'LockerInformationList'"
                      :ref="getLockerTabGridRef(selectedTabIndex)"
                      :dataSource="lockerInformationGridDataSource"
                      :isModifyMode="isModifyMode"
                      @gridActionComplete="onGridActionComplete"
                      @editMultipleColumnsPopupOpen="onEditMultipleColumnsPopupOpen"
                    />
                    <locker-daily-information-grid
                      class="daily"
                      v-else-if="getLockerTabKey(selectedTabIndex) === 'LockerDailyInformation'"
                      :ref="getLockerTabGridRef(selectedTabIndex)"
                      :bsnDate="lockerDailyInformationGridBsnDate"
                      :dataSource="lockerDailyInformationGridDataSource"
                      :dailyInfos="dailyInfos"
                      @beforeWindowClick="handleBeforeWindowClick"
                      @nextWindowClick="handleNextWindowClick"
                      @checkInCellClick="handleCheckInCellClick"
                      @resveOutOfCellClick="onResveOutOfCellClick"
                      style="
                        border-top: 1px solid #ccc;
                        border-bottom: 1px solid #ccc;
                      "
                    />
                  </div>
                </div>
              </section>
            </article>
          </div>
        </section>
      </article>
    </div>
    <search-locker-information-options-details-popup
      v-if="visibleSearchOptionsDetailsPopup"
      v-model="filter"
      @submit="handleSearchClick"
      @reset="handleResetSearchOptionsDetailsClick"
      @close="handleSearchOptionsDetailsPopupClose"
    />
    <create-locker-information-in-batch-popup
      v-if="visibleCreateLockerInfoInBatchPopup"
      :bsnDate="filter.bsnDate"
      @create="handleCreateLockerInfoInBatchClick"
      @delete="handleDeleteLockerInfoInBatchClick"
      @close="handleCreateLockerInfoInBatchPopupClose"
    />
    <confirm-set-locker-status-popup
      v-if="visibleConfirmSetLockerStatusPopup"
      :targetLockersCount="selectedLockerInfoList.length"
      :lockerStatus="selectLockerStatus"
      @submit="handleSetLockerStatusSubmit"
      @close="handleConfirmSetLockerStatusPopupClose"
    />
    <set-locker-status-reserved-popup
      v-if="visibleSetLockerStatusReservedPopup"
      :targetLockersCount="selectedLockerInfoList.length"
      @submit="handleSetLockerStatusSubmit"
      @close="handleSetLockerStatusReservedPopupClose"
    />
    <set-locker-status-out-of-popup
      v-if="visibleSetLockerStatusOutOfPopup"
      :targetLockersCount="selectedLockerInfoList.length"
      @submit="handleSetLockerStatusSubmit"
      @close="handleSetLockerStatusOutOfPopupClose"
    />
    <locker-check-in-status-popup
      v-if="visibleLockerCheckInStatusPopup"
      :bsnDate="checkInStatusPopupBsnDate"
      :lockerNo="checkInStatusPopupLockerNo"
      @close="handleLockerCheckInStatusPopupClose"
    />
    <locker-status-popup
      v-if="isLockerStatusPopupOpen"
      ref="lockerStatusPopup"
      @popupClosed="onLockerStatusPopupClose"
    />
    <locker-resve-and-out-of-status-popup
      ref="lockerResveAndOutOfStatusPopup"
      v-if="isLockerResveAndOutOfStatusPopup"
      :bsnDate="resveAndOutOfStatusPopupBsnDate"
      :lockerNo="resveAndOutOfStatusPopupLockerNo"
      :lockerStatus="resveAndOutOfStatusPopupLockerStatus"
      @popupClosed="onLockerResveAndOutOfStatusPopupClose"
    />
    <edit-multiple-columns-popup
      v-if="isEditMultipleColumnsPopupOpen"
      ref="editMultipleColumnsPopup"
      @popupConfirm="onEditMultipleColumnsPopupConfirm"
      @popupClosed="onEditMultipleColumnsPopupClose"
    />
  </div>
</template>

<script>
import {
  getDayOfWeekCaption,
  getDayOfWeekCaptionColor,
  getFormattedDate,
} from "@/utils/date";
import commonMixin from "@/views/layout/mixin/commonMixin";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import { getCurrentBizCode } from "@/utils/pageKeyUtil";
import {
  createLockerInfoInBatch,
  deleteLockerInfoInBatch,
  getLockerDailyInfo,
  getLockerInfoList,
  updateLockerInfo,
  updateLockerStatus,
} from "@/api/lockerInfoList";
import moment from "moment";
import CreateLockerInformationInBatchPopup from "./CreateLockerInformationInBatchPopup";
import ConfirmSetLockerStatusPopup from "./ConfirmSetLockerStatusPopup";
import LockerCheckInStatusPopup from "./LockerCheckInStatusPopup";
import LockerDailyInformationGrid from "./LockerDailyInformationGrid";
import LockerDailyInformationHeader from "./LockerDailyInformationHeader";
import LockerInformationGrid from "./LockerInformationGrid";
import LockerInformationHeader from "./LockerInformationHeader";
import SearchLockerInformationOptionsDetailsPopup from "./SearchLockerInformationOptionsDetailsPopup";
import SetLockerStatusOutOfPopup from "./SetLockerStatusOutOfPopup";
import SetLockerStatusReservedPopup from "./SetLockerStatusReservedPopup";
import lockerStatusPopup from "@/views/checkin/popup/LockerStatusPopup";
import LockerResveAndOutOfStatusPopup from "./LockerResveAndOutOfStatusPopup";
import editMultipleColumnsPopup from '@/views/common/EditMultipleColumnsPopup';
import InputDate from "@/components/common/datetime/InputDate";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

import { SHORTCUT_KEYS } from "@/utils/KeyboardUtil";

const LOCKER_TABS = [
  {
    gridRef: "lockerInformationGrid",
    key: "LockerInformationList",
    text: "락카정보",
  },
  {
    gridRef: "lockerDailyInformationGrid",
    key: "LockerDailyInformation",
    text: "일별 락카정보",
  },
];

export default {
  name: "LockerNumberRegistration",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    InputDate,
    CreateLockerInformationInBatchPopup,
    ConfirmSetLockerStatusPopup,
    LockerCheckInStatusPopup,
    LockerDailyInformationGrid,
    LockerDailyInformationHeader,
    LockerInformationGrid,
    LockerInformationHeader,
    SearchLockerInformationOptionsDetailsPopup,
    SetLockerStatusOutOfPopup,
    SetLockerStatusReservedPopup,
    lockerStatusPopup,
    LockerResveAndOutOfStatusPopup,
    editMultipleColumnsPopup,
    ErpButton,
  },
  async created() {
    await this.fetch();
  },
  data() {
    return {
      lockerInformationGridDataSourceLength: 0,
      lockerStatusCommonCodes: commonCodesGetCommonCode("LOCKER_STATUS", true),

      isLockerResveAndOutOfStatusPopup: false,
      isLockerStatusPopupOpen: false,
      isEditMultipleColumnsPopupOpen: false,
      visibleSearchOptionsDetailsPopup: false,
      visibleCreateLockerInfoInBatchPopup: false,
      visibleConfirmSetLockerStatusPopup: false,
      visibleSetLockerStatusReservedPopup: false,
      visibleSetLockerStatusOutOfPopup: false,
      visibleLockerCheckInStatusPopup: false,

      filter: {
        bsnDate: new Date(),
        lockerDiv: "",
        lockerNoFrom: "",
        lockerNoTo: "",
        lockerStatus: "",
      },
      selectedTabIndex: 0,

      lockerInformationGridDataSource: [],
      lockerDailyInformationGridBsnDate: new Date(),
      lockerDailyInformationGridDataSource: [],
      dailyInfos: [],

      selectedLockerInfoList: [],
      selectLockerStatus: "",

      checkInStatusPopupBsnDate: new Date(),
      checkInStatusPopupLockerNo: 0,

      resveAndOutOfStatusPopupBsnDate: new Date(),
      resveAndOutOfStatusPopupLockerNo: 0,
      resveAndOutOfStatusPopupLockerStatus: null,

      isModifyMode: false,
    };
  },
  computed: {
    isPopupOpened() {
      return (
        this.isLockerResveAndOutOfStatusPopup ||
        this.isLockerStatusPopupOpen ||
        this.isEditMultipleColumnsPopupOpen ||
        this.visibleSearchOptionsDetailsPopup ||
        this.visibleCreateLockerInfoInBatchPopup ||
        this.visibleConfirmSetLockerStatusPopup ||
        this.visibleSetLockerStatusReservedPopup ||
        this.visibleSetLockerStatusOutOfPopup ||
        this.visibleLockerCheckInStatusPopup
      );
    },
    lockerStatusButtonProps() {
      return {
        shortcutKey: "ReservationSmsSendPopup.shortcuts.lockerStatus",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F12,
        },
      };
    },
    bsnDateValueModel: {
      get() {
        return getFormattedDate(this.filter.bsnDate);
      },
      set(bsnDateValueModel) {
        const bsnDate = moment(bsnDateValueModel);
        if (bsnDate.isValid()) {
          this.filter.bsnDate = bsnDate.toDate();
        }
      },
    },
  },
  methods: {
    getDayOfWeekCaptionColor,
    getDayOfWeekCaption,
    getCurrentBizCode,
    getLockerTabs() {
      return LOCKER_TABS;
    },
    getLockerTabGridRef(index) {
      return LOCKER_TABS[index].gridRef;
    },
    getLockerTabKey(index) {
      return LOCKER_TABS[index].key;
    },

    showSearchDetailPopup() {
      this.visibleSearchOptionsDetailsPopup = true;
    },
    hideSearchDetailPopup() {
      this.visibleSearchOptionsDetailsPopup = false;
    },
    showCreateLockerInfoInBatchPopup() {
      this.visibleCreateLockerInfoInBatchPopup = true;
    },
    hideCreateLockerInfoInBatchPopup() {
      this.visibleCreateLockerInfoInBatchPopup = false;
    },
    showConfirmSetLockerStatusPopup() {
      this.visibleConfirmSetLockerStatusPopup = true;
    },
    hideConfirmSetLockerStatusPopup() {
      this.visibleConfirmSetLockerStatusPopup = false;
    },
    showSetLockerStatusReservedPopup() {
      this.visibleSetLockerStatusReservedPopup = true;
    },
    hideSetLockerStatusReservedPopup() {
      this.visibleSetLockerStatusReservedPopup = false;
    },
    showSetLockerStatusOutOfPopup() {
      this.visibleSetLockerStatusOutOfPopup = true;
    },
    hideSetLockerStatusOutOfPopup() {
      this.visibleSetLockerStatusOutOfPopup = false;
    },
    showLockerCheckInStatusPopup() {
      this.visibleLockerCheckInStatusPopup = true;
    },
    hideLockerCheckInStatusPopup() {
      this.visibleLockerCheckInStatusPopup = false;
    },

    async handleResetSearchOptionsDetailsClick() {
      this.filter.lockerDiv = "";
      this.filter.lockerNoFrom = "";
      this.filter.lockerNoTo = "";
      this.filter.lockerStatus = "";

      await this.fetch();
    },
    handleSearchOptionsDetailsPopupClose() {
      this.hideSearchDetailPopup();
    },
    handleCreateLockerInfoInBatchPopupClose() {
      this.hideCreateLockerInfoInBatchPopup();
    },
    handleConfirmSetLockerStatusPopupClose() {
      this.hideConfirmSetLockerStatusPopup();
    },
    handleSetLockerStatusReservedPopupClose() {
      this.hideSetLockerStatusReservedPopup();
    },
    handleSetLockerStatusOutOfPopupClose() {
      this.hideSetLockerStatusOutOfPopup();
    },
    handleLockerCheckInStatusPopupClose() {
      this.hideLockerCheckInStatusPopup();
    },
    openLockerStatusPopup() {
      this.isLockerStatusPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.lockerStatusPopup.showLockerStatusPopup(
          this.bsnDateValueModel
        );
      });
    },
    handleTabSelected({ selectedIndex }) {
      this.selectedTabIndex = selectedIndex;
    },
    async handleSearchClick() {
      this.lockerDailyInformationGridBsnDate = this.filter.bsnDate;
      await this.fetch();
    },
    async handleCreateLockerInfoInBatchClick({ bsnDateFrom, bsnDateTo }) {
      await createLockerInfoInBatch(bsnDateFrom, bsnDateTo);
      this.infoToast("생성되었습니다.");
      await this.fetch();
    },
    async handleDeleteLockerInfoInBatchClick({ bsnDateFrom, bsnDateTo }) {
      await deleteLockerInfoInBatch(bsnDateFrom, bsnDateTo);
      this.infoToast(this.$t("main.popupMessage.deleted"));
      await this.fetch();
    },
    async handleBeforeWindowClick({ currBsnDate }) {
      const bsnDate = new Date(
        moment(currBsnDate).add(-7, "days").format("YYYY-MM-DD")
      );
      this.filter.bsnDate = bsnDate;
      this.lockerDailyInformationGridBsnDate = bsnDate;
      await this.fetch();
    },
    async handleNextWindowClick({ currBsnDate }) {
      const bsnDate = new Date(
        moment(currBsnDate).add(7, "days").format("YYYY-MM-DD")
      );
      this.filter.bsnDate = bsnDate;
      this.lockerDailyInformationGridBsnDate = bsnDate;
      await this.fetch();
    },
    handleCheckInCellClick({ bsnDate, lockerNo }) {
      this.checkInStatusPopupBsnDate = bsnDate;
      this.checkInStatusPopupLockerNo = lockerNo;

      this.showLockerCheckInStatusPopup();
    },
    onResveOutOfCellClick({ bsnDate, lockerNo, lockerStatus }) {
      this.resveAndOutOfStatusPopupBsnDate = moment(bsnDate).format(
        "YYYY-MM-DD"
      );
      this.resveAndOutOfStatusPopupLockerNo = lockerNo;
      this.resveAndOutOfStatusPopupLockerStatus = lockerStatus;

      this.isLockerResveAndOutOfStatusPopup = true;
      this.$nextTick(() => {
        this.$refs.lockerResveAndOutOfStatusPopup.showLockerResveAndOutOfStatusPopup();
      });
    },

    async fetch() {
      await Promise.all([
        this.fetchLockerInformationGridDataSource(),
        this.fetchLockerDailyInformationGridDataSource(),
      ]).catch((e) => {
        console.error(e);
      });
    },
    async fetchLockerInformationGridDataSource() {
      if (this.$refs.lockerInformationGrid) {
        this.$refs.lockerInformationGrid.gridClearFiltering();
      }

      const {
        bsnDate,
        lockerDiv,
        lockerNoFrom,
        lockerNoTo,
        lockerStatus,
      } = this.filter;

      const {
        value: { lockerInfoList: dataSource },
      } = await getLockerInfoList(
        moment(bsnDate).format("YYYY-MM-DD"),
        lockerDiv || undefined,
        lockerNoFrom || undefined,
        lockerNoTo || undefined,
        lockerStatus || undefined
      );

      dataSource.forEach((data) => {
        !data["appnLocker"] && (data["appnLocker"] = "");
        !data["remarks"] && (data["remarks"] = "");
      });

      this.lockerInformationGridDataSource = dataSource.map((item, index) => {
        return {
          ...item,
          _rid: index + 1,
        };
      });

      this.lockerInformationGridDataSourceLength = this.lockerInformationGridDataSource.length;
    },
    async fetchLockerDailyInformationGridDataSource() {
      const { bsnDate } = this.filter;
      const {
        value: { lockerDailyInfo, dailyInfos },
      } = await getLockerDailyInfo(moment(bsnDate).format("YYYY-MM-DD"));

      this.lockerDailyInformationGridDataSource = lockerDailyInfo
        .filter(({ dailyInfoList }) => {
          return dailyInfoList && dailyInfoList.length !== 0;
        })
        .map(({ lockerNo, dailyInfoList }) => {
          const others = dailyInfoList.reduce(
            (accumulator, currentValue, currentIndex) => {
              if (currentIndex === 1) {
                const next = {};
                next[currentValue["bsnDate"]] = currentValue["lockerStatus"];
                next[accumulator["bsnDate"]] = accumulator["lockerStatus"];
                return next;
              } else {
                const next = { ...accumulator };
                next[currentValue["bsnDate"]] = currentValue["lockerStatus"];
                return next;
              }
            }
          );

          return {
            lockerNo,
            ...others,
          };
        });

      this.dailyInfos = dailyInfos;
    },

    // LockerDailyInformation
    handleAddLockerClick() {
      this.$nextTick(async () => {
        this.$refs.lockerInformationGrid.addRecord({
          lockerNo: "",
          lockerDiv: "",
          lockerPrtNo: "",
          lockerStatus: "",
          argmtSno: "",
          sector: "",
          memberFlag: false,
          vipFlag: false,
          appnLocker: "",
          remarks: "",
        });
      });
    },
    handleDeleteLockerClick() {
      this.$nextTick(async () => {
        this.$refs.lockerInformationGrid.deleteRecord();
      });
    },
    validateRecords(records) {
      for (let i = 0; i < records.length; i++) {
        const { lockerNo, lockerDiv, lockerPrtNo, sortNo, sortNoMin } = records[i];

        if (
          !lockerNo ||
          lockerNo === undefined ||
          lockerNo === null ||
          lockerNo === ""
        ) {
          this.errorToast(
            this.$t("main.validationMessage.requiredMessage", ["락카번호"])
          );
          return false;
        }
        if (!lockerDiv) {
          this.errorToast(
            this.$t("main.validationMessage.requiredMessage", ["락카구분"])
          );
          return false;
        }
        if (sortNo !== 0 && !sortNo) {
          this.errorToast(
            this.$t("main.validationMessage.requiredMessage", ["정렬순서"])
          );
          return false;
        }
        if (sortNoMin !== 0 && !sortNoMin) {
          this.errorToast(
            this.$t("main.validationMessage.requiredMessage", ["정렬순서(최소)"])
          );
          return false;
        }
        if (
          !lockerPrtNo ||
          lockerPrtNo === undefined ||
          lockerPrtNo === null ||
          lockerPrtNo === ""
        ) {
          this.errorToast(
            this.$t("main.validationMessage.requiredMessage", ["출력번호"])
          );
          return false;
        }
      }

      return true;
    },
    handleSaveLockerInformationClick() {
      this.$nextTick(async () => {
        if (!this.$refs.lockerInformationGrid.$refs.grid.validate()) {
          return;
        }
        const {
          addedRecords,
          changedRecords,
          deletedRecords,
        } = this.$refs.lockerInformationGrid.getChangedData();

        if (
          addedRecords.length === 0 &&
          changedRecords.length === 0 &&
          deletedRecords.length === 0
        ) {
          this.errorToast("변경사항이 없습니다");
          return;
        }

        if (
          !this.validateRecords(addedRecords) ||
          !this.validateRecords(changedRecords)
        ) {
          return;
        }

        await updateLockerInfo(
            addedRecords || undefined,
            changedRecords || undefined,
            deletedRecords || undefined
        );

        this.infoToast(this.$t("main.popupMessage.saved"));

        await this.fetch();
      });
    },
    handlePostOrderSettingsClick({ postOrderUnit }) {
      this.$nextTick(async () => {
        this.$refs.lockerInformationGrid.setPostOrderByOrderUnit(postOrderUnit);
      });
    },
    handlePostOrderResetClick() {
      this.$nextTick(async () => {
        this.$refs.lockerInformationGrid.resetPostOrder();
      });
    },
    handleExcelClick() {
      this.$nextTick(async () => {
        this.$refs[this.getLockerTabGridRef(this.selectedTabIndex)].gridExcelExport();
      });
    },

    // LockerMonthlyInformation
    ejectSelectedLockerInfoList() {
      return this.$refs.lockerDailyInformationGrid.getSelectedLockerInfoList();
    },
    async handleSetLockerStatusClick(lockerStatus) {
      const selectedLockerInfoList = this.ejectSelectedLockerInfoList();
      if (selectedLockerInfoList.length === 0) {
        this.errorToast("변경할 락카를 선택해주세요");
        return;
      }

      let hasCheckIn = false;
      let hasAssign = false;
      let hasNot = false;
      selectedLockerInfoList.forEach(({ lockerStatus }) => {
        hasCheckIn |= lockerStatus === "CHECKIN";
        hasAssign |= lockerStatus === "ASSIGN";
        hasNot |= lockerStatus === "NOT";
      });

      if (hasCheckIn) {
        this.errorToast("체크인 중인 락카는 상태를 변경할 수 없습니다");
        return;
      }

      if (hasAssign && lockerStatus !== "EMPTY" && lockerStatus !== "ASSIGN") {
        this.errorToast(
          "배정인 락카는 고정/예약/고장으로 상태를 변경할 수 없습니다"
        );
        return;
      }

      if (hasNot) {
        this.errorToast(
          "일별 락카정보가 생성되지 않은 락카는 상태를 변경할 수 없습니다."
        );
        return;
      }

      this.selectLockerStatus = lockerStatus;
      this.selectedLockerInfoList = selectedLockerInfoList;

      if (lockerStatus === "RESVE") {
        this.showSetLockerStatusReservedPopup();
      } else if (lockerStatus === "OUTOF") {
        this.showSetLockerStatusOutOfPopup();
      } else {
        this.showConfirmSetLockerStatusPopup();
      }
    },
    async handleSetLockerStatusSubmit({ remarks, resveName }) {
      await this.updateLockerStatus({
        selectedLockerInfoList: this.selectedLockerInfoList,
        lockerStatus: this.selectLockerStatus,
        remarks,
        resveName,
      });

      this.infoToast(this.$t("main.popupMessage.saved"));

      await this.fetch();
    },
    handleOpenCreateLockerInfoInBatchPopupClick() {
      this.showCreateLockerInfoInBatchPopup();
    },

    getLockerStatusCodeByStatus(lockerStatus) {
      return this.lockerStatusCommonCodes.find(
        ({ comCode }) => comCode === lockerStatus
      );
    },
    async updateLockerStatus({
      selectedLockerInfoList,
      lockerStatus,
      remarks,
      resveName,
    }) {
      const lockerInfoList = selectedLockerInfoList.map((lockerInfo) => {
        const {
          comCode: dwCode,
          comName: dwName,
        } = this.getLockerStatusCodeByStatus(lockerStatus);

        return {
          dwCode,
          dwName,
          ...lockerInfo,
        };
      });

      await updateLockerStatus(
        lockerStatus,
        lockerInfoList,
        remarks,
        resveName
      );
    },
    onLockerStatusPopupClose() {
      this.isLockerStatusPopupOpen = false;
    },
    onGridActionComplete(args) {
      this.lockerInformationGridDataSourceLength = args;
    },
    async onEditMultipleColumnsPopupOpen({column, selectedRowIndexes}) {
      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
        column,
        selectedRowIndexes
      );
    },
    onLockerResveAndOutOfStatusPopupClose() {
      this.isLockerResveAndOutOfStatusPopup = false;
    },
    onEditMultipleColumnsPopupConfirm(args) {
      const {
        field,
        value,
        rowIndexes,
      } = args;
      this.isEditMultipleColumnsPopupOpen = false;
      if (rowIndexes.length < 1) {
        return;
      }
      rowIndexes.forEach(index => {
        this.$refs.lockerInformationGrid.onUpdateCell({
          index,
          field,
          value,
        });
      });
    },
    onEditMultipleColumnsPopupClose() {
      this.isEditMultipleColumnsPopupOpen = false;
    },
  },
};
</script>

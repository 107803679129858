<template>
  <div>
    <ejs-dialog
      ref="caddieFeePopup"
      :animationSettings="{ effect: 'None' }"
      :header="`체크인-캐디피 영수증`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="360"
      :isModal="true"
      :close="onCaddieFeePopupClosed"
    >
      <div class="window caddieFeeReceipt">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section">
                  <div class="section-body" style="border-top-color: #000">
                    <div
                      class="body-box"
                      v-if="
                        caddieFeePopupInfo.caddieId != null &&
                        caddieFeePopupInfo.visitName != null
                      "
                      style="color: #000; padding: 18px"
                    >
                      <div
                        style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 0 0 7px 0;
                        "
                      >
                        <div
                          style="
                            font-size: 16px;
                            font-weight: bold;
                            text-align: center;
                          "
                        >
                          COUNTRY CLUB
                        </div>
                        <div style="padding-top: 9px">
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 50%;
                              padding-right: 6px;
                            "
                          >
                            캐디피 영수증
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: right;
                              width: 50%;
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ caddieFeePopupInfo.visitName }} 귀하
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div
                        style="
                          position: relative;
                          border-bottom: 1px dashed #ccc;
                          padding: 9px 0 8px 0;
                        "
                      >
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 50%;
                              padding-right: 6px;
                            "
                          >
                            캐디피
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: right;
                              width: 50%;
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{
                              Number(
                                caddieFeePopupInfo.caddieFee
                              ).toLocaleString()
                            }}
                            원정
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div style="padding-top: 11px; text-align: center">
                          상기 금액을 캐디피로 정히 영수함
                        </div>
                        <div style="padding-top: 11px; text-align: center">
                          {{ getTodayDateStr }}
                        </div>
                        <div style="padding-top: 11px; text-align: center">
                          캐디명 : {{ caddieFeePopupInfo.caddieName }}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="print keyColor">
              <erp-button
                  button-div="PRINT"
                  :is-icon-custom="true"
                  @click.native="printCaddieFee"
              >
                출력
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeCaddieFeePopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import BillPrintUtil from "@/utils/billPrintUtil";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

export default {
  name: "caddieFeePopup",
  mixins: [confirmDialogMixin],
  components: {
    ErpButton
  },
  data() {
    return {
      caddieFeePopupInfo: {
        visitName: null,
        visitDate: null,
        caddieId: null,
        caddieName: null,
        caddieFee: null,
        cartNo: null,
        startCourse: null,
        startTime: null,
      },
    };
  },
  computed: {
    getTodayDateStr() {
      const date = new Date();

      return `${date.getFullYear()}년 ${
        date.getMonth() + 1
      }월 ${date.getDate()}일`;
    },
  },
  created() {},
  methods: {
    showCaddieFeePopup(resveName, caddieInfo, visitDate) {
      this.caddieFeePopupInfo.visitName = resveName;
      this.caddieFeePopupInfo.visitDate = visitDate;
      this.caddieFeePopupInfo.caddieId = caddieInfo.caddieId;
      this.caddieFeePopupInfo.caddieName = caddieInfo.caddieName;
      this.caddieFeePopupInfo.caddieFee = caddieInfo.caddieFee;
      this.caddieFeePopupInfo.cartNo = caddieInfo.cartNo;
      this.caddieFeePopupInfo.startCourse = caddieInfo.startCourse;
      this.caddieFeePopupInfo.startTime = caddieInfo.startTime;
    },
    onCaddieFeePopupClosed() {
      this.$emit("popupClosed");
    },
    async printCaddieFee() {
      // 디바이스 설정의 Bill 프린터 사용여부가 false이면 Bill 프린터 출력 안됨.

      const config = {
        "useBillPrinter": true,
        "useKitchenPrinter": false,
      };
      await BillPrintUtil.getTsConfReceiptInfo.call(this, "CADDIE_FEE" , true , this.caddieFeePopupInfo , config);
    },
    closeCaddieFeePopup() {
      this.$refs.caddieFeePopup.hide();
    },
  },
};
</script>

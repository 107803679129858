<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              기간
            </div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                    type="lookup-condition"
                    v-model="dateRange"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              @click.native="onViewPaymentHole"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true"/>
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">홀별 정산 현황</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      :is-shortcut-button="true"
                      :ignore="isPopupOpened"
                      @click.native="print"
                  >
                    인쇄
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      :ignore="isPopupOpened"
                      @click.native="excel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="paymentSettlementsByHoleGrid"
                  :allowExcelExport="true"
                  :allowPaging="true"
                  :provides="grid"
                  :pageSettings="pageSettings"
                  :columns="paymentSettlementsByHoleGridColumn"
                  :dataSource="paymentSettlementsByHoleList"
                  @actionComplete="paymentHoleGridActionComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import {DATE_FORMAT_YYYY_MM_DD} from '@/utils/date';
import {numberWithCommas} from '@/utils/number';
import {ExcelExport, Filter, ForeignKey, Page, Resize} from '@syncfusion/ej2-vue-grids';
import ejsGridWrapper from '@/components/common/EjsGridWrapper.vue';
import InputDateRange from '@/components/common/datetime/InputDateRange';
import ReportView from '@/components/common/report/ReportView';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';
import GolfERPService from '@/service/GolfERPService';
import {
  commonCodesGetComName, commonCodesGetSortNo,
} from "@/utils/commonCodes";
import {mapGetters} from "vuex";
import {orderBy as _orderBy} from "lodash";

export default {
  name: 'PaymentSettlementsByHole',
  components: {
    ReportView,
    ejsGridWrapper,
    InputDateRange,
    ErpButton
  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  data() {
    return {
      count: 0,
      now: null,
      dateRange: {
        from: null,
        to: null,
      },
      grid: [
        Filter,
        Resize,
        Page,
        ExcelExport,
        ForeignKey,
      ],
      paymentSettlementsByHoleGridColumn: [
        {
          field: 'holeDiv',
          headerText: '홀',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'HOLE_DIV',
          width: 90,
          type: 'string',
        },
        {
          field: 'rainFlag',
          headerText: '우천(강제)',
          textAlign: 'center',
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          width: 60,
        },
        {
          field: 'perCnt',
          headerText: '인원',
          width: 70,
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          field: "totAmt",
          headerText: "총액",
          type: "number",
          format: "N",
          width: 150,
          textAlign: 'right'
        },
        {
          field: "netAmt",
          headerText: "그린피",
          type: "number",
          format: "N",
          width: 150,
          textAlign: 'right'
        },
        {
          field: "vatAmt",
          headerText: "부가세",
          type: "number",
          format: "N",
          width: 150,
          textAlign: 'right'
        },
        {
          field: "specialTax",
          headerText: "개별소비세",
          type: "number",
          format: "N",
          width: 120,
          textAlign: 'right'
        },
        {
          field: "eduTax",
          headerText: "교육세",
          type: "number",
          format: "N",
          width: 120,
          textAlign: 'right'
        },
        {
          field: "vilTax",
          headerText: "농특세",
          type: "number",
          format: "N",
          width: 120,
          textAlign: 'right'
        },
        {
          field: "fund",
          headerText: "체육기금",
          type: "number",
          format: "N",
          width: 120,
          textAlign: 'right'
        },
      ],
      paymentSettlementsByHoleList: [],
      pageSettings: {pageSize: 50},
    };
  },
  mounted() {
     this.initialize();
  },
  computed: {
    ...mapGetters(['username']),
    isPopupOpened() {
      return false; // TODO : 팝업 있을 경우, 삽입
    },
  },
  methods: {
    numberWithCommas,
    async initialize() {
      const nowMoment = await GolfERPService.fetchNow();
      this.now = nowMoment.toDate();
      const from = nowMoment.format(DATE_FORMAT_YYYY_MM_DD);
      const to = nowMoment.format(DATE_FORMAT_YYYY_MM_DD);
      this.dateRange = {
        from,
        to,
      };
      await this.fetch();
    },
    async onViewPaymentHole() {
      await this.fetch();
    },
    async fetch() {
      const responseData = await GolfErpAPI.fetchSettlementStatusByHole(this.dateRange);
      this.paymentSettlementsByHoleList = _orderBy(responseData.map((holeData) => ({
        ...holeData,
        sortNo: commonCodesGetSortNo("HOLE_DIV", holeData.holeDiv),
      })),["rainFlag","sortNo"]);
    },
    paymentHoleGridActionComplete() {
      this.count = numberWithCommas(
          this.$refs.paymentSettlementsByHoleGrid?.getGridBatchCount() || 0,
      );
    },
    excel() {
      this.$refs.paymentSettlementsByHoleGrid.excelExport();
    },
    print() {
      const searchOptionsList = [
        {
          key: '기간',
          value: this.dateRange.from + ' ~ ' + this.dateRange.to,
        }
    ];

      const searchOptions = searchOptionsList
          .filter((item) => !!item.value)
          .map((item) => item.key + ": " + item.value)
          .join(", ");

      const list = this.paymentSettlementsByHoleList.map((rowData) => {
        return {
          ...rowData,
            holeDiv: commonCodesGetComName("HOLE_DIV",rowData.holeDiv)
        };
      });

      const reportData = {
        reportJson: {
          jsonData: {
            paymentHoleList : list
          },
          userName: this.username,
          uniqueCode: this.$options.name,
          searchOptions
        }
      };
      console.log(this.$options.name);
      console.log(JSON.stringify(reportData));
      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    }
  },
};
</script>

<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">기준일자</div>
            <ul class="content">
              <li class="item date">
                <!-- TODO : strictMode 프로퍼티 설정 시 change 이벤트가 최초/특정 상황에 2번 발생하는 버그가 있어 제외 -->
                <input-date
                  v-model="reservationOptions.bsnDate"
                  type="lookup-condition"
                  :format="'YYYY-MM-DD'"
                  :notClear="true"
                  :isGetDateInfo="true"
                  @change="onBsnDateChange"
                />
              </li>
              <li class="item text">
                <!-- TODO : getDayOfWeekCaptionColor의 첫번째 arg인 date값이 null이면 bsnCode와 dwCode로 색상 가져오도록 해놓았음 -->
                (<span
                  :style="{
                    color: getDayOfWeekCaptionColor(
                      null,
                      bsnDateInfo.bsnCode,
                      bsnDateInfo.dwCode
                    ),
                  }"
                  >{{
                    getDayOfWeekCaption(
                      moment(reservationOptions.bsnDate).toDate()
                    )
                  }}</span
                >) (<span
                  :style="{
                    color: commonCodesGetColorValue(
                      'BSN_CODE',
                      bsnDateInfo.bsnCode
                    ),
                  }"
                  >{{
                    commonCodesGetComName("BSN_CODE", bsnDateInfo.bsnCode)
                  }}</span
                >)
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        id="blankFlag"
                        :tabindex="-1"
                        v-model="reservationOptions.blankFlag"
                        value="true"
                        @change="onBlankFlagChanged"
                      />
                      <i></i>
                      <div class="label">숨김</div>
                    </label>
                  </li>
                </ul>
              </li>
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        id="aditTeamFlag"
                        :tabindex="-1"
                        v-model="reservationOptions.aditTeamFlag"
                        value="true"
                        @change="onAditTeamFlagChanged"
                      />
                      <i></i>
                      <div class="label">추가팀 보기</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <!-- 조회 버튼 추가 -->
        <div class="lookup-lookup">
          <erp-button button-div="GET" :is-shortcut-button="true" :ignore="isPopupOpened" @click.native="searchReservation">조회</erp-button>
        </div>
        <ul class="lookup-condition sub">
          <li class="field reservationNameRemarksFourDigitContactNumber">
            <ul class="content">
              <li class="item input">
                <input-text
                  ref="searchValue"
                  id="reservationOptionsSearchValueInputTextBox"
                  v-model="reservationOptions.searchValue"
                  placeholder="예약자명, 비고"
                  @keydown.enter="findReservation"
                />
              </li>
            </ul>
            <ul class="content">
              <div class="lookup-lookup">
                <erp-button button-div="GET" :disabled="isSearchValueFocus" @click.native="findReservation">찾기</erp-button>
              </div>
            </ul>
          </li>
        </ul>
        <ul v-if="false" class="lookup-condition">
          <li class="field">
            <div class="title">코스구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  ref="courseDiv"
                  v-model="reservationOptions.courseDiv"
                  :dataSource="reservationOptions.courseDivOptions"
                  :fields="commonCodeFields"
                  @change="onCourseDivChanged"
                />
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="lookup-right">
        <ul class="lookup-button">
          <li class="shortcut">
            <ejs-dropdownbutton
              ref="shortcutMenuDropdownButton"
              cssClass="lookup-button-dropdown"
              :items="shortcutMenuItems"
              @select="shortcutMenuSelected"
            >
              바로가기
            </ejs-dropdownbutton>
          </li>
          <!--
          <li class="etc">
            <ejs-dropdownbutton
              ref='etcMenuDropdownButton'
              cssClass='lookup-button-dropdown'
              :items='etcMenuItems'>
              기타
            </ejs-dropdownbutton>
          </li>
          -->
        </ul>
      </div>
    </div>
    <div class="content-body">
      <!-- 예약정보 상세 View 출력 시 'reservationDetailViewShowed' class 추가 -->
      <article
        class="body-article"
        :class="[
          isReservationDetailViewOpened
            ? $t('className.reservation.reservationDetailViewOpened')
            : '',
          isReservationDetailViewFixed
            ? $t('className.reservation.reservationListViewFixed')
            : '',
        ]"
      >
        <div class="article-left">
          <!-- Tee-Off 그리드 부분 -->
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section
            :class="[
              'article-section',
              'section-01',
              'reservation-accordion',
              { 'reservation-close': !isReservationDailyStatusVisible },
            ]"
          >
            <div class="section-header">
              <div class="header-left">
                <div class="header-title" @click="someMethod">
                  Tee-Off 목록 (예약일자 : {{reservationOptions.resveDate}})
                </div>
                <div class="header-caption">
                  [{{ numberWithCommas(reservationCourseCount) }}건]
                </div>
                <ul class="header-label">
                  <li class="field">
                    <div class="title">NO</div>
                    <ul class="content">
                      <li class="item">
                        <i v-bind:style="{ 'background-color': commonCodesGetColorValue( 'COLOR_TIME_STATUS', 'WEB_OPEN_FLAG' ), }"></i>
                        <div class="label">웹</div>
                      </li>
                      <li v-if="false" class="item">
                        <i v-bind:style="{ 'background-color': commonCodesGetColorValue( 'COLOR_TIME_STATUS', 'COPRTN_OPEN_FLAG' ), }"/>
                        <div class="label">제휴</div>
                      </li>
                      <li class="item">
                        <i v-bind:style="{ 'background-color': commonCodesGetColorValue( 'COLOR_TIME_STATUS', 'AGNCY_OPEN_FLAG' ), }"/>
                        <div class="label">대행</div>
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">시간</div>
                    <ul class="content">
                      <li class="item">
                        <i v-bind:style="{ 'background-color': commonCodesGetColorValue( 'TIME_STATUS', 'BLOCK' ), }"></i>
                        <div class="label">블럭</div>
                      </li>
                      <li class="item">
                        <i v-bind:style="{ 'background-color': commonCodesGetColorValue( 'TIME_STATUS', 'HOLD' ), }"></i>
                        <div class="label">홀딩</div>
                      </li>
<!--                      <li class="item">-->
<!--                        <i v-bind:style="{ 'background-color': commonCodesGetColorValue( 'CHKIN_STATUS', 'CHKIN' ), }"></i>-->
<!--                        <div class="label">체크인</div>-->
<!--                      </li>-->
                      <li class="item">
                        <i v-bind:style="{ 'background-color': commonCodesGetColorValue( 'CANCEL_DIV', 'NOSHOW' ), }"></i>
                        <div class="label">노쇼</div>
                      </li>
                      <li class="item">
                        <i v-bind:style="{ 'background-color': commonCodesGetColorValue( 'CANCEL_DIV', 'RAIN' ), }"></i>
                        <div class="label">우천</div>
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">예약자</div>
                    <ul class="content">
                      <li class="item">
                        <i v-bind:style="{ 'background-color': commonCodesGetColorValue( 'COLOR_MEMBER_DIV', 'MEMBER' ), }"></i>
                        <div class="label">회원</div>
                      </li>
                      <li class="item">
                        <i v-bind:style="{ 'background-color': commonCodesGetColorValue( 'COLOR_MEMBER_DIV', 'NOM' ), }"></i>
                        <div class="label">비회원</div>
                      </li>
                      <li class="item">
                        <i v-bind:style="{ 'background-color': commonCodesGetColorValue( 'GRP_KIND', 'YEAR' ), }"></i>
                        <div class="label">연단체</div>
                      </li>
                      <li v-if="false" class="item">
                        <i v-bind:style="{ 'background-color': commonCodesGetColorValue( 'GRP_KIND', 'NOR' ), }"></i>
                        <div class="label">일반</div>
                      </li>
                      <li class="item">
                        <i v-bind:style="{ 'background-color': commonCodesGetColorValue( 'GRP_KIND', 'TEMP' ), }"></i>
                        <div class="label">임시</div>
                      </li>
                      <li class="item">
                        <i v-bind:style="{ 'background-color': commonCodesGetColorValue( 'COLOR_TIME_STATUS', 'WEB_OPEN_FLAG' ), }"></i>
                        <div class="label">인터넷예약</div>
                      </li>
                      <li class="item">
                        <i v-bind:style="{ 'background-color': commonCodesGetColorValue( 'COLOR_TIME_STATUS', 'COPRTN_OPEN_FLAG' ), }"></i>
                        <div class="label">대행</div>
                      </li>
                      <li v-if="false" class="item">
                        <i v-bind:style="{ 'background-color': commonCodesGetColorValue( 'COLOR_MEMBER_DIV', 'ROOM_PKG' ), }"
                        ></i>
                        <div class="label">객실PKG</div>
                      </li>
                    </ul>
                  </li>
                  <li v-if="false" class="field">
                    <div class="title">S</div>
                    <ul class="content">
                      <li class="item">
                        <i v-bind:style="{ 'background-color': commonCodesGetColorValue( 'TIME_DIV', 'CADDIE' ), }"></i>
                        <div class="label">캐디동반</div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="create">
                    <erp-button button-div="SAVE" :is-icon-custom="true" @click.native="timeGeneration">타임 생성</erp-button>
                  </li>
                  <li class="select-button">
                    <erp-button button-div="SAVE" :is-icon-custom="true" @click.native="commonTimeSelectOption('BLANK')">숨김</erp-button>
                  </li>
                  <li class="select-button">
                    <erp-button button-div="SAVE" :is-icon-custom="true" @click.native="commonTimeSelectOption('BLOCK')">블럭</erp-button>
                  </li>
                  <li class="select-button">
                    <erp-button button-div="SAVE" :is-icon-custom="true" @click.native="commonTimeSelectOption('HOLD')">홀딩</erp-button>
                  </li>
                  <li v-if="false" class="select-button">
                    <erp-button button-div="SAVE" :is-icon-custom="true" @click.native="selfTimeSettingPopup">셀프</erp-button>
                  </li>
                  <li class="select-button">
                    <erp-button button-div="SAVE" :is-icon-custom="true" @click.native="commonTimeSelectOption('JOIN')">조인</erp-button>
                  </li>
                  <li class="settings">
                    <erp-button button-div="SAVE" :is-icon-custom="true" @click.native="webTimeSetting">웹타임</erp-button>
                  </li>
                  <li>
                    <erp-button button-div="GET" :is-icon-custom="true" @click.native="reservationHistory">히스토리</erp-button>
                  </li>
                  <li>
                    <erp-button button-div="GET" :is-icon-custom="true" @click.native="viewCalendar">캘린더</erp-button>
                  </li>
                  <li class="lookup">
                    <erp-button
                        button-div="GET"
                        :is-icon-custom="true"
                        :ignore="isPopupOpened"
                        :is-custom-shortcut-button="true"
                        shortcut-key="ReservationReceiptRegistration.shortcuts.reservationInquiry"
                        :shortcut="{key: 'F8'}"
                        @click.native="viewReservationInfo"
                    >
                      예약조회
                    </erp-button>
                  </li>
                  <li v-if="false">
                    <erp-button button-div="GET" :is-icon-custom="true" :ignore="isPopupOpened" :is-custom-shortcut-button="true"
                                shortcut-key="ReservationReceiptRegistration.shortcuts.reservationStatus" :shortcut="{key: 'F10'}"
                                @click.native="viewDailyReservation">예약현황</erp-button>
                  </li>
                  <li v-if="false">
                    <erp-button button-div="SAVE" :is-icon-custom="true"
                                @click.native="waitingReservationListPopupOpen({resveDate :reservationOptions.resveDate})">대기예약</erp-button>
                  </li>
                  <li>
                    <erp-button button-div="SAVE" :is-icon-custom="true"
                                @click.native="onPromotionButtonClicked">프로모션</erp-button>
                  </li>
                  <li>
                    <erp-button button-div="GET" :is-icon-custom="true"
                        @click.native="onMemberPopupOpen(true
                        , null
                        , null
                        , null
                        , null
                        , false
                        , false
                        , false)"
                    >
                      회원검색
                    </erp-button>
                  </li>
                </ul>
                <div class="header-switch">
                  <div class="title">단체모드</div>
                  <div class="switch">
                    <ejs-switch
                      v-model="isReservationDetailModifyMode"
                      :checked="isReservationDetailModifyMode"
                      @change="reservationDetailModifySwitchChanged"
                    ></ejs-switch>
                  </div>
                </div>
                <div class="header-switch fixed">
                  <div class="title">고정</div>
                  <div class="switch">
                    <ejs-switch
                      v-model="isReservationDetailViewFixed"
                      :checked="isReservationDetailViewFixed"
                    ></ejs-switch>
                  </div>
                </div>
                <div class="header-switch fixed" style="right: 90px">
                  <div class="title">그린피보기</div>
                  <div class="switch">
                    <ejs-switch
                        v-model="reservationOptions.paymtPrice"
                        :checked="reservationOptions.paymtPrice"
                        @change="searchReservation"
                    ></ejs-switch>
                  </div>
                </div>
              </div>
            </div>
            <div class="section-body">
              <div class="body-box dev-reservation-daily-status" ref="leftWidth">
                <ejs-grid-wrapper
                  ref="reservationDailyStatus"
                  :provides="reservationDailyStatusGridOptions.provides"
                  :columns="reservationDailyStatusGridOptions.columns"
                  :dataSource="reservationDailyStatusGridOptions.data"
                  :isSelectedRowRetain="false"
                  :allowSorting="false"
                  :allowFiltering="false"
                  :selectionSettings="reservationDailyStatusGridOptions.selectionSettings"
                  :isShowProgress="false"
                  :noColumnWidth="40"
                  @queryCellInfo="reservationDailyStatusQueryCellInfo"
                  @rowSelected="reservationDailyStatusGridRowSelected"
                  @actionComplete="reservationDailyStatusGridActionComplete"
                  @recordClick="reservationDailyStatusRecordClick"
                />
              </div>
              <!-- Active div는 class에 'reservationActive' 추가됨 -->
              <div
                :ref="`resveCourseCode${reservationCourse.resveCourseCode}`"
                class="body-box"
                v-for="( reservationCourse, reservationCourseIdx ) in reservationCourseLists"
                :key="reservationCourse.resveCourseCode"
                :id="reservationCourseIdx"
                :style="courseGridByReservationStyle() ? widthBigBox : null"
                :class="[
                  'flexbox',
                  courseGridByReservationActive(reservationCourse.resveCourseCode)
                  // reservationCourseIdx === reservationCourseActiveIndex
                    ? $t('className.reservation.reservationActive')
                    : '',
                  // reservationCourseSectionBodyClassName  G수정
                ]"
                v-show="courseCodeFilter.includes(reservationCourse.resveCourseCode)"
                @click="reservationCourseDivClicked(reservationCourseIdx)"
              >
                <div class="body-header">{{ reservationCourse.resveCourseName }}</div>
                <!--코스 예약 리스트 -->
                <ejs-grid-wrapper
                  ref="reservationGrid"
                  :provides="reservationViewOptions.provides"
                  :columns="reservationViewOptions.columns"
                  :dataSource=" reservationCourseLists[reservationCourseIdx] .reservationLists || []"
                  :isSelectedRowRetain="false"
                  :allowSorting="false"
                  :allowFiltering="false"
                  :isNOColumnDisplay="false"
                  @headerCellInfo="reservationHeaderCellInfo"
                  @queryCellInfo="reservationQueryCellInfo"
                  @recordClick=" reservationGridRecordClicked($event, reservationCourseIdx)"
                  @rowDataBound="reservationGriRowDataBound"
                  @dataBound="onDataBound($event, reservationCourseIdx)"
                  @gridCheckboxChanged="onGridCheckboxChanged"
                  :selectionSettings="reservationViewOptions.selectionSettings"
                  :isShowProgress="false"
                  @cellSaved="reservationCellSaved"
                >
                </ejs-grid-wrapper>
                <div v-if="reservationCourse.reservationTeamCount" class="body-footer">
                  <ul>
                    <li><strong>전체</strong>{{ reservationCourse.reservationTeamCount.totalTeamCnt }}</li>
                    <li><strong>예약</strong>{{ reservationCourse.reservationTeamCount.resveTeamCnt }}</li>
                    <li><em><strong>잔여</strong>{{ reservationCourse.reservationTeamCount.restTeamCnt }}</em></li>
                    <li v-if="partDiv.filter(item => item.comCode === '1').length > 0"><strong>1부</strong>{{ reservationCourse.reservationTeamCount.partOneTeamCnt }}({{ reservationCourse.reservationTeamCount.partOneAllTeamCnt }})</li>
                    <li v-if="partDiv.filter(item => item.comCode === '2').length > 0"><strong>2부</strong>{{ reservationCourse.reservationTeamCount.partTwoTeamCnt }}({{ reservationCourse.reservationTeamCount.partTwoAllTeamCnt }})</li>
                    <li v-if="partDiv.filter(item => item.comCode === '3').length > 0"><strong>3부</strong>{{ reservationCourse.reservationTeamCount.partThreeTeamCnt }}({{ reservationCourse.reservationTeamCount.partThreeAllTeamCnt }})</li>
<!--                    <li><strong>개인</strong>{{ reservationCourse.reservationTeamCount.personTeamCnt }}</li>-->
<!--                    <li><strong>단체</strong>{{ reservationCourse.reservationTeamCount.groupTeamCnt }}</li>-->
                  </ul>
                </div>
              </div>
            </div>

          </section>
        </div>
        <div class="article-right">
          <!-- 예약정보 상세 -->
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section class="article-section section-02">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">예약정보 상세</div>
              </div>
            </div>
            <div class="section-caption">
              <ul class="caption-button">
                <li class="save keyColor">
                  <erp-button button-div="SAVE" :is-key-color="true" :ignore=" !isReservationDetailViewOpened || isPopupOpened" :is-shortcut-button="true" @click.native="reservationSaveButtonClicked">저장</erp-button>
                </li>
                <li class="cancel">
                  <erp-button button-div="SAVE" :ignore=" !isReservationDetailViewOpened || isPopupOpened" @click.native="cancelReservation" :is-custom-shortcut-button="true" shortcut-key="reservationReceiptRegistration.shortcuts.delete" :shortcut="{key: 'F4'}" :is-icon-custom="true">취소</erp-button>
                </li>
                <li>
                  <erp-button button-div="SAVE" :ignore=" !isReservationDetailViewOpened || isPopupOpened" @click.native="sendSMS" :is-custom-shortcut-button="true" shortcut-key="reservationReceiptRegistration.shortcuts.sms" :shortcut="{key: 'F7'}" :is-icon-custom="true">SMS</erp-button>
                </li>
                <li>
                  <erp-button button-div="GET" @click.native="viewHistory" :is-icon-custom="true">변경이력</erp-button>
                </li>
                <li class="close">
                  <erp-button button-div="CLOSE" :ignore="!isReservationDetailViewOpened || isPopupOpened" @click.native="closeDetailView" :is-custom-shortcut-button="true" shortcut-key="reservationReceiptRegistration.shortcuts.close" :shortcut="{key:'Escape',keyName:'Esc'}" :is-icon-custom="true">닫기</erp-button>
                </li>
              </ul>
            </div>
            <div class="section-body">
              <article class="body-article" v-if="isReservationDetailViewOpened">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-0202">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">예약정보</div>
                      <div class="header-caption" v-if="reservationDetailInfo.reservationInfo.resveNo">
                        [예약번호<strong>{{ reservationDetailInfo.reservationInfo.resveNo }}</strong>]
                      </div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field groupName">
                              <!-- 필수 : required -->
                              <div class="title" :class="isReservationDetailModifyMode ? 'required' : ''">단체명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input">
                                    <div class="form">
                                      <input-text ref="grpName" v-model=" reservationDetailInfo.reservationInfo .grpName" @change="onGrpNameChanged"/>
                                    </div>
                                  </li>
                                  <li class="item form-group button">
                                    <ul class="button">
                                      <li class="search">
                                        <erp-button button-div="GET" v-on:click.native=" onGroupPopupOpen(true)">검색</erp-button>
                                      </li>
                                    </ul>
                                  </li>
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        v-model=" reservationDetailInfo.reservationInfo .grpKind"
                                        :dataSource=" reservationViewOptions.grpKindOptions"
                                        :allowFiltering="false"
                                        :fields=" reservationViewOptions.grpKindFields"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                  <li class="item form-group text">
                                    {{ reservationDetailInfo.reservationInfo.grpNo }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field reservationName">
                              <!-- 필수 : required -->
                              <div class="title required">예약자명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input">
                                    <div class="form">
                                      <input-text ref="resveName" v-model=" reservationDetailInfo.reservationInfo .resveName" :maxlength="10" @change="onResveNameChanged"/>
                                    </div>
                                  </li>
                                  <li class="item form-group button">
                                    <ul class="button">
                                      <li class="search">
                                        <erp-button button-div="GET" v-on:click.native=" onMemberPopupOpen( true, memberPopupType.RESV, reservationDetailInfo .reservationInfo.resveName, null )">검색</erp-button>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">회원번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ reservationDetailInfo.reservationInfo.grpKind === 'FIT' ? memberNoFormatter(reservationDetailInfo.reservationInfo.memberNo) : reservationDetailInfo.reservationInfo.memberNo }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">회원구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="memberDiv"
                                        v-model=" reservationDetailInfo.reservationInfo .memberDiv"
                                        :dataSource=" reservationViewOptions.memberDivOptions"
                                        :allowFiltering="false"
                                        :fields=" reservationViewOptions.memberDivFields"
                                        cssClass="body-data-dropdown"
                                        @change=" onReservationInfoMemberDivDropdownListChanged"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">회원등급</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="memberGrade"
                                        v-model=" reservationDetailInfo.reservationInfo .memberGrade"
                                        :dataSource=" reservationViewOptions.memberGradeOptions"
                                        :allowFiltering="false"
                                        :fields=" reservationViewOptions.memberGradeFields"
                                        cssClass="body-data-dropdown"
                                        @change=" onReservationInfoMemberGradeDropdownListChanged"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">연락자명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text ref="contactName" v-model=" reservationDetailInfo.reservationInfo .contactName" :maxlength="10" @change="onContactNameChanged"/>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title" :class="{ required : (this.isReservationDetailModifyMode || reservationDetailInfo.reservationInfo.grpKind !== 'FIT')}">
                                연락처
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div v-if="commonMixinHasCiperTextGet" class="form">
                                      <component-telephone ref="contactTelInfo" v-model=" reservationDetailInfo.reservationInfo .contactTel" :max-length="11" @blur="onContactTelInfoBlur" @change="onContactTelInfoChange"/>
                                    </div>
                                    <template v-else>
                                      {{ reservationDetailInfo.reservationInfo.contactTel }}
                                    </template>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">예약종류</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="resveKind"
                                        v-model=" reservationDetailInfo.reservationInfo .resveKind"
                                        :dataSource=" reservationViewOptions.resveKindOptions"
                                        :allowFiltering="false"
                                        :fields=" reservationViewOptions.resveKindFields"
                                        cssClass="body-data-dropdown"
                                        @change="onResveKindChanged"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">예약채널</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="resveChannel"
                                        v-model=" reservationDetailInfo.reservationInfo .resveChannel"
                                        :dataSource=" reservationViewOptions.resveChannelOptions"
                                        :allowFiltering="false"
                                        :fields=" reservationViewOptions.resveChannelFields"
                                        cssClass="body-data-dropdown"
                                        @change="onResveChannelChanged"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field remarks">
                              <!-- 필수 : required -->
                              <div class="title">비고</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-textarea ref="resveRemarks" v-model=" reservationDetailInfo.reservationInfo .resveRemarks"/>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field sendSMS">
                              <!-- 필수 : required -->
                              <div class="title">SMS 전송</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group text-01">
                                    {{ reservationDetailInfo.reservationInfo.smsSendFlag ? "전송" : "미전송" }}
                                  </li>
                                  <li class="item form-group text-02">
                                    {{ reservationDetailInfo.reservationInfo.smsSendCnt }}건
                                  </li>
                                  <li class="item form-group text">
                                    {{ reservationDetailInfo.reservationInfo.smsSendDt }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field enrollment">
                              <!-- 필수 : required -->
                              <div class="title">등록</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group text-01">
                                    {{ reservationDetailInfo.reservationInfo.resveInfo }}
                                  </li>
                                  <li class="item form-group text">
                                    {{ reservationDetailInfo.reservationInfo.insertDt }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field modified">
                              <!-- 필수 : required -->
                              <div class="title">수정</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group text-01">
                                    {{ reservationDetailInfo.reservationInfo.updateName }}
                                  </li>
                                  <li class="item form-group text">
                                    {{ reservationDetailInfo.reservationInfo.updateDt }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">지역</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        v-model=" reservationDetailInfo.reservationInfo .areaCode"
                                        :dataSource=" reservationViewOptions.areaCodeOptions"
                                        :allowFiltering="false"
                                        :fields=" reservationViewOptions.areaCodeFields"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">성별</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        v-model=" reservationDetailInfo.reservationInfo .sexCode"
                                        :dataSource=" reservationViewOptions.sexCodeOptions"
                                        :allowFiltering="false"
                                        :fields=" reservationViewOptions.sexCodeFields"
                                        cssClass="body-data-dropdown"
                                        @change="onSexCodeChanged"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">추천인</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text ref="recomendr" v-model="reservationDetailInfo.reservationInfo.recomendr"/>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li v-if="false" class="field">
                              <!-- 필수 : required -->
                              <div class="title">VIP 여부</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <ul class="check">
                                      <li>
                                        <label><input name="vipFlag" type="radio" v-model="reservationDetailInfo.reservationInfo.vipFlag" :value="true"/><i></i><div class="label">예</div></label>
                                      </li>
                                      <li>
                                        <label><input name="vipFlag" type="radio" v-model="reservationDetailInfo.reservationInfo.vipFlag" :value="false"/><i></i><div class="label">아니오</div></label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section
                    :class="[
                    'article-section',
                    'section-0206',
                    'accordion',
                    { close: !isPaymentListVisible },
                  ]"
                >
                  <div class="section-header">
                    <div class="header-left">
                      <div
                          class="header-title"
                          @click="isPaymentListVisible = !isPaymentListVisible"
                      >
                        결제내역
                      </div>
                    </div>
                    <div class="header-right" v-if="reservationDetailInfo.reservationInfo.depositIncoms && reservationDetailInfo.reservationInfo.depositIncoms.length > 0">
                      <ul class="header-button">
                        <li><erp-button button-div="SAVE" :is-icon-custom="true" @click="depositIncomPGCancelButtonClicked">정산취소</erp-button></li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <ejs-grid-wrapper
                        ref="paymentListGrid"
                        :provides="paymentListGrid.provides"
                        :columns="paymentListGrid.columns"
                        :selectionSettings="paymentListGrid.selectionSettings"
                        :noColumnWidth="40"
                        :dataSource="reservationDetailInfo.reservationInfo.depositIncoms"
                        :isShowProgress="false"
                        @queryCellInfo="depositIncomListGridqueryCellInfo"
                        @dataBound="onDepositIncomListGridDataBound"
                    />
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section :class="[ 'article-section', 'section-0201', 'accordion', { close: !isReservationDetailTimeInfoVisible }, ]">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title" @click=" isReservationDetailTimeInfoVisible = !isReservationDetailTimeInfoVisible">예약타임 목록</div>
                    </div>
                    <div class="header-right" v-if="isReservationDetailTimeInfoVisible">
                      <ul class="header-button">
                        <li><erp-button button-div="SAVE" :is-icon-custom="true" @click="moveReservation">예약이동</erp-button></li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data header">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field accordion">
                              <div class="title">접기/펼치기</div>
                            </li>
                            <li class="field check">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label><input type="checkbox" v-model=" reservationViewOptions.reservationDetailCancelTimeIdCheckboxAll" @change=" reservationCancelCheckAllChecked"/><i></i></label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field date">
                              <div class="title">일자</div>
                            </li>
                            <li v-if="false" class="field course">
                              <div class="title">코스</div>
                            </li>
                            <li class="field time">
                              <div class="title">시간</div>
                            </li>
                            <li class="field hole">
                              <div class="title">홀</div>
                            </li>
                            <li class="field visitCnt">
                              <div class="title">인원</div>
                            </li>
                            <li class="field reservedChannel">
                              <div class="title">프로모션</div>
                            </li>
                            <li class="field fee">
                              <div class="title">요금</div>
                            </li>
                            <li class="field etcButton">
                              <div class="title">버튼</div>
                            </li>
                            <li class="field scroll">
                              <div class="title">스크롤</div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                    <div class="body-data body">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul :ref="`reservationDetailViewSelect${timeInfoIdx}`" class="data-content" v-for="( timeInfo, timeInfoIdx ) in reservationDetailInfo.reservationTimeLists" :key="timeInfo.timeId">
                            <li class="field accordion" @click=" onReservationDetailViewSelect( timeInfo, timeInfoIdx, $event )">
                              <div class="content">
                                <ul class="button">
                                  <li class="open" v-if=" !currentActiveTimeIds.includes( timeInfo.timeId )">
                                    <erp-button button-div="CLOSE" @click.native="addCurrentActiveTimeId(timeInfo.timeId)">펼치기</erp-button>
                                  </li>
                                  <li class="close" v-if=" currentActiveTimeIds.includes( timeInfo.timeId )">
                                    <erp-button button-div="CLOSE" @click.native="removeCurrentActiveTimeId( timeInfo.timeId )">접기</erp-button>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field check" @click=" onReservationDetailViewSelect( timeInfo, timeInfoIdx, $event, 'check' )">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input v-if="timeInfo.resveNo" type="checkbox" v-model=" reservationDetailCancelTimeIds" :value="timeInfo.timeId" @change=" reservationDetailCancelChecked"/>
                                          <i></i>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field date" @click=" onReservationDetailViewSelect( timeInfo, timeInfoIdx, $event )">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ timeInfo.resveDate.substr(5, 5) }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field course" v-if="false" @click=" onReservationDetailViewSelect( timeInfo, timeInfoIdx, $event )">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ commonCodesGetComName("COURSE_CODE", timeInfo.resveCourse) }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field time" @click=" onReservationDetailViewSelect( timeInfo, timeInfoIdx, $event )">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ timeInfo.resveTime }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field hole" @click=" onReservationDetailViewSelect( timeInfo, timeInfoIdx, $event )">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ commonCodesGetComName("HOLE_DIV", timeInfo.holeDiv) }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field visitCnt">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          v-model="timeInfo.visitCnt"
                                          :dataSource="[{text:'1인',value:1},{text:'2인',value:2},{text:'3인',value:3},{text:'4인',value:4},{text:'5인',value:5},]"
                                          :allowFiltering="false"
                                          :fields="{ text: 'text', value: 'value' }"
                                          cssClass="body-data-dropdown"
                                          @change="onVisitCntChanged($event,timeInfo, timeInfoIdx)"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field reservedChannel">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="promtnId"
                                        v-model="timeInfo.promtnId"
                                        :dataSource="promotionCodesByStartCourse(timeInfo)"
                                        :fields="{ text: 'promtnNameAsText', value: 'promtnId' }"
                                        :allowFiltering="false"
                                        popupWidth="256"
                                        cssClass="body-data-dropdown"
                                        @change="onPromtnIdChanged($event, timeInfo)"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field fee" @click=" onReservationDetailViewSelect( timeInfo, timeInfoIdx, $event )">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ numberWithCommas(timeInfo.applyPrice) }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field etcButton" @click=" onReservationDetailViewSelect( timeInfo, timeInfoIdx, $event )">
                              <div class="content">
                                <ul class="button">
                                  <li>
                                    <ejs-dropdownbutton
                                      :items="
                                        [
                                          // { text: '예약 복사', menuId: 1, timeId: timeInfo.timeId, disabled: commonMixinIsButtonDisableByAuth('resveReceiptResveReg'), },
                                          {
                                            text: '예약 취소',
                                            menuId: 2,
                                            timeId: timeInfo.timeId,
                                            disabled: commonMixinIsButtonDisableByAuth('resveReceiptResveReg'),
                                          },
                                          {
                                            text: '단체 해제',
                                            menuId: 3,
                                            timeId: timeInfo.timeId,
                                            disabled: !timeInfo.grpNo || commonMixinIsButtonDisableByAuth('resveReceiptResveReg'),
                                          },
                                          {
                                            text: '우천취소',
                                            menuId: 4,
                                            timeId: timeInfo.timeId,
                                            disabled: commonMixinIsButtonDisableByAuth('resveReceiptResveReg'),
                                          },
                                        ].filter( ({ menuId }) => !!timeInfo.resveNo || menuId === 2 )
                                      "
                                      @select=" onTimeMenuSelected($event, timeInfo)"
                                      cssClass="body-data-dropdown"
                                    >
                                    </ejs-dropdownbutton>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <!-- 활성 : active -->
                            <li v-show="currentActiveTimeIds.includes(timeInfo.timeId)" class="field body" :class=" currentActiveTimeIds.includes(timeInfo.timeId) ? 'active' : ''">
                              <div class="content">
                                <article class="body-article">
                                  <!-- 아코디언 : accordion / 닫힘 : close -->
                                  <section class="article-section section-020101">
                                    <div class="section-body">
                                      <div class="body-data">
                                        <div class="data-outer">
                                          <div class="data-inner">
                                            <ul class="data-content">
                                              <li class="field memberName">
                                                <!-- 필수 : required -->
                                                <div class="title">위임자명</div>
                                                <div class="content">
                                                  <ul class="row">
                                                    <li class="item form-group input">
                                                      <div class="form">
                                                        <input-text v-model=" timeInfo.transName" @change=" onTransNameChanged( $event, timeInfoIdx )"/>
                                                      </div>
                                                    </li>
                                                    <li class="item form-group button">
                                                      <ul class="button">
                                                        <li class="search">
                                                          <erp-button button-div="GET" @click.native=" onMemberPopupOpen( true, memberPopupType.TRANS, timeInfo.transName, timeInfoIdx )">검색</erp-button>
                                                        </li>
                                                      </ul>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </li>
                                              <li class="field">
                                                <!-- 필수 : required -->
                                                <div class="title">위임 연락처</div>
                                                <div class="content">
                                                  <ul class="row">
                                                    <li class="item form-group">
                                                      <div class="form" v-if=" commonMixinHasCiperTextGet">
                                                        <component-telephone ref="timeInfoContactTel" v-model=" timeInfo.contactTel" :max-length="11" @change="onTransContactTelInfoChange"/>
                                                      </div>
                                                      <template v-else>{{ timeInfo.contactTel }}</template>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </li>
                                            </ul>
                                            <div class="border-left"></div>
                                            <div class="border-right"></div>
                                            <div class="border-top"></div>
                                            <div class="border-bottom"></div>
                                          </div>
                                        </div>
                                        <div class="border-left"></div>
                                        <div class="border-right"></div>
                                        <div class="border-top"></div>
                                        <div class="border-bottom"></div>
                                      </div>
                                    </div>
                                  </section>
                                  <!-- 아코디언 : accordion / 닫힘 : close -->
                                  <section class="article-section section-020102">
                                    <div class="section-header">
                                      <div class="header-left">
                                        <div class="header-title">동반자정보</div>
                                      </div>
                                      <div class="header-right">
                                        <ul class="header-button">
                                          <li class="add">
                                            <erp-button button-div="SAVE" :is-icon-custom="true" @click.native="reservationCompanionAdd(timeInfo,timeInfoIdx)">추가</erp-button>
                                          </li>
                                          <li class="delete">
                                            <erp-button button-div="DELETE" :is-icon-custom="true" @click.native="reservationCompanionsGridDelete(timeInfoIdx)">삭제</erp-button>
                                          </li>
                                          <li class="reset">
                                            <erp-button button-div="GET" :is-icon-custom="true" @click.native="reservationCompanionsGridCancel(timeInfoIdx)">초기화</erp-button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div class="section-body">
                                      <ejs-grid-wrapper
                                        :ref="`reservationCompanionsGrid${timeInfoIdx}`"
                                        :provides="reservationCompanionOptions.provides"
                                        :dataSource=" timeInfo.reservationCompanions"
                                        :columns="reservationCompanionOptions.columns"
                                        :height="113"
                                        :enableHover="false"
                                        :validationRules=" reservationCompanionOptions.validationRules"
                                        :validationModification="false"
                                        :allowSorting="false"
                                        :allowFiltering="false"
                                        :noColumnWidth="40"
                                        :selectionSettings="reservationCompanionOptions.selectionSettings"
                                        @keyPressed=" onKeyPressed($event, timeInfoIdx)"
                                        @queryCellInfo=" reservationCompanionGridCustomiseCell( $event, timeInfoIdx )"
                                        @recordClick=" reservationCompanionGridClicked( $event, timeInfoIdx )"
                                        @cellEdit=" reservationCompanionGridCellEdit"
                                        @cellSaved="onReservationCompanionGridCellSaved( $event, timeInfoIdx )"
                                        :isShowProgress="false"
                                      >
                                      </ejs-grid-wrapper>
                                    </div>
                                  </section>
                                  <!-- 아코디언 : accordion / 닫힘 : close -->
                                  <section class="article-section section-020103">
                                    <div class="section-body">
                                      <div class="body-data">
                                        <div class="data-outer">
                                          <div class="data-inner">
                                            <ul class="data-content">
<!--                                              <li class="field promotion" style="width: calc(100% + 2px);">-->
                                              <li class="field promotion">
                                                <!-- 필수 : required -->
                                                <div class="title">프로모션</div>
                                                <div class="content">
                                                  <ul class="row">
                                                    <li class="item form-group input">
                                                      <div class="form">
                                                        <input-text v-model=" timeInfo.promtnName" @change=" onPromtnNameChanged( $event, timeInfoIdx )"/>
                                                      </div>
                                                    </li>
                                                    <li
                                                      class="item form-group button"
                                                    >
                                                      <ul class="button">
                                                        <li class="search">
                                                          <erp-button button-div="GET" @click.native=" onPromotionPopupOpen( true, timeInfoIdx )">검색</erp-button>
                                                        </li>
                                                      </ul>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </li>
                                              <li class="field">
                                                <!-- 필수 : required -->
                                                <div class="title">팀그린피</div>
                                                <div class="content">
                                                  <ul class="row">
                                                    <li class="item form-group">
                                                      <div class="form"><input-number v-model=" timeInfo.teamGreenFee" :min="0"/></div>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </li>
                                              <!-- roy 에서 올림픽 에서 가림 셀프여부,조인여부,카트종류-->
                                              <li class="field" v-if="false">
                                                <!-- 필수 : required -->
                                                <div class="title">셀프캐디</div>
                                                <div class="content">
                                                  <ul class="row"><li class="item form-group check">
                                                      <!-- 포커스 : focus -->
                                                      <ul class="check">
                                                        <li>
                                                          <label>
                                                            <input type="radio" id="selfFlagTrue" v-model=" timeInfo.selfFlag" :value="true"/>
                                                            <i></i>
                                                            <div class="label">예</div>
                                                          </label>
                                                        </li>
                                                        <li>
                                                          <label><input type="radio" id="selfFlagFalse" v-model=" timeInfo.selfFlag" :value="false"/><i></i><div class="label">아니오</div></label>
                                                        </li>
                                                      </ul>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </li>
                                              <li class="field" v-if="false">
                                                <!-- 필수 : required -->
                                                <div class="title">조인 신청</div>
                                                <div class="content">
                                                  <ul class="row">
                                                    <li class="item form-group check">
                                                      <!-- 포커스 : focus -->
                                                      <ul class="check">
                                                        <li>
                                                          <label><input type="radio" id="joinFlagTrue" v-model=" timeInfo.joinFlag" :value="true"/><i></i><div class="label">예</div></label>
                                                        </li>
                                                        <li>
                                                          <label><input type="radio" id="joinFlagFalse" v-model=" timeInfo.joinFlag" :value="false"/><i></i><div class="label">아니오</div></label>
                                                        </li>
                                                      </ul>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </li>
                                              <li class="field" v-if="false">
                                                <!-- 필수 : required -->
                                                <div class="title">카트종류</div>
                                                <div class="content">
                                                  <ul class="row">
                                                    <li class="item form-group">
                                                      <div class="form">
                                                        <ejs-dropdownlist
                                                            v-model="timeInfo.cartKind"
                                                            :dataSource="reservationViewOptions.cartKindCodeOptions"
                                                            :allowFiltering="false"
                                                            :fields="reservationViewOptions.cartKindCodeFields"
                                                            cssClass="body-data-dropdown"
                                                            @change="onCartKindChanged($event, timeInfo)"
                                                        ></ejs-dropdownlist>
                                                      </div>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </li>
                                              <li v-if="false" class="field">
                                                <!-- 필수 : required -->
                                                <div class="title">라운드 인원</div>
                                                <div class="content">
                                                  <ul class="row">
                                                    <li class="item form-group check">
                                                      <div class="form">
                                                        <input-number :id="`visitCnt_${timeInfoIdx}`" v-model=" timeInfo.visitCnt" :min="1" :max="5" @change="onVisitCntChanged($event,timeInfo, timeInfoIdx)"/>
                                                      </div>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </li>
                                              <li v-if="false" class="field">
                                                <!-- 필수 : required -->
                                                <div class="title">룸 예약</div>
                                                <div class="content">
                                                  <ul class="row">
                                                    <li class="item form-group check">
                                                      <div class="form">
<!--                                                        여기-->
                                                        <ejs-dropdownlist
                                                            :dataSource="reservationViewOptions.roomCodeOptions"
                                                            :fields="reservationViewOptions.roomCodeFields"
                                                            cssClass="body-data-dropdown"
                                                            v-model="timeInfo.roomUseDiv"
                                                        ></ejs-dropdownlist>
                                                      </div>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </li>
                                              <li v-if="false" class="field">
                                                <div class="title">
                                                  룸 갯수
                                                </div>
                                                <div class="content">
                                                  <ul class="row">
                                                    <li
                                                        class="item form-group check"
                                                    >
                                                      <div class="form">
                                                        <input-number
                                                            :min="0"
                                                            v-model="timeInfo.roomUseCnt"
                                                        />
                                                      </div>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </li>
                                            </ul>
                                            <div class="border-left"></div>
                                            <div class="border-right"></div>
                                            <div class="border-top"></div>
                                            <div class="border-bottom"></div>
                                          </div>
                                        </div>
                                        <div class="border-left"></div>
                                        <div class="border-right"></div>
                                        <div class="border-top"></div>
                                        <div class="border-bottom"></div>
                                      </div>
                                    </div>
                                  </section>
                                </article>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section v-if="false" :class="[ 'article-section', 'section-0205', 'accordion', { close: !isRoomReservationListVisible }, ]">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title" @click=" isRoomReservationListVisible = !isRoomReservationListVisible">객실예약 목록</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button" style="display: flow-root;">
                        <li>
                          <erp-button button-div="GET" :is-icon-custom="true" @click.native="onRoomReservationButtonClicked">객실 예약</erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <ejs-grid-wrapper
                        ref="roomReservationListGrid"
                        :provides="roomReservationListGridOptions.provides"
                        :columns="roomReservationListGridOptions.columns"
                        :selectionSettings="roomReservationListGridOptions.selectionSettings"
                        :noColumnWidth="40"
                        :dataSource="reservationDetailInfo.roomStayRecords"
                        :isSelectedRowRetain="false"
                        :isShowProgress="false"
                        :isAutoSelectRow="false"
                        :isAutoSelectCell="false"
                        @headerCellInfo="roomReservationGridHeaderCellInfo"
                    />
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section
                    :class="[
                    'article-section',
                    'section-0204',
                    'accordion',
                    { close: !isReservationDetailReservationListFromTomorrowVisible },
                  ]"
                >
                  <div class="section-header">
                    <div class="header-left">
                      <div
                        class="header-title"
                        @click="isReservationDetailReservationListFromTomorrowVisible = !isReservationDetailReservationListFromTomorrowVisible"
                      >
                        예약리스트
                      </div>
                    </div>
                    <div
                        class="header-right"
                        v-if="commonCodesGetStandardInfo('courseClass') === 'R'"
                    >
                      <ul class="header-check">
                        <li>
                          <label>
                            <input
                                type="checkbox"
                                v-model="isLinkMember"
                                @change="isLinkMemberChanged"
                            />
                            <i></i>
                            <div class="label">연계회원</div>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <ejs-grid-wrapper
                        ref="reservationListFromTomorrowGrid"
                        :provides="reservationListFromTomorrowGridOptions.provides"
                        :columns="reservationListFromTomorrowGridOptions.columns"
                        :selectionSettings="reservationListFromTomorrowGridOptions.selectionSettings"
                        :noColumnWidth="40"
                        :dataSource="reservationListFromTomorrowGridData"
                        :isSelectedRowRetain="false"
                        :isShowProgress="false"
                        :isAutoSelectRow="false"
                        :isAutoSelectCell="false"
                    />
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section
                    :class="[
                    'article-section',
                    'section-0203',
                    'accordion',
                    { close: !isReservationDetailMembershipInfoVisible },
                  ]"
                >
                  <div class="section-header">
                    <div class="header-left">
                      <div
                          class="header-title"
                          @click="
                          isReservationDetailMembershipInfoVisible = !isReservationDetailMembershipInfoVisible
                        "
                      >
                        회원권정보
                      </div>
                    </div>
                  </div>
                  <link-members
                      :member-link-list="memberLinkList"
                  ></link-members>
                </section>
              </article>
            </div>
          </section>
        </div>
      </article>
    </div>
    <reservation-cancel-popup
      v-if="isReservationCancelPopupOpen"
      ref="reservationCancelPopup"
      @popupClosed="onReservationCancelPopupClosed"
    />
    <group-popup
      v-if="isGroupPopupOpen"
      ref="groupPopup"
      @popupClosed="onGroupPopupClosed"
    />
    <promotion-search-popup
      v-if="isPromotionSearchPopupOpen"
      ref="promotionSearchPopup"
      @popupClosed="onPromotionSearchPopupClose"
    />
    <member-select-popup
      v-if="isMemberSelectPopupOpen"
      ref="memberSelectPopup"
      :position="memberPopupPosition"
      :isModal="true"
      @popupClosed="memberSelectPopupClosed"
      @popupConfirmed="memberSelectPopupConfirmed"
    />
    <reservation-move-popup
      v-if="isReservationMovePopupOpen"
      ref="reservationMovePopup"
      @popupClosed="reservationMovePopupClosed"
    />
    <reservation-copy-popup
      v-if="isReservationCopyPopupOpen"
      ref="reservationCopyPopup"
      @popupClosed="reservationCopyPopupClosed"
    />
    <reservation-time-create-popup
      v-if="isReservationTimeCreatePopupOpen"
      ref="reservationTimeCreatePopup"
      :filterCourse="courseCodeFilter"
      :courseDiv="reservationOptions.courseDiv"
      @popupClosed="onReservationTimeCreatePopupClose"
    />
    <self-time-setting-popup
      v-if="isSelfTimeSettingPopupOpen"
      :filterCourse="courseCodeFilter"
      ref="selfTimeSettingPopup"
      @popupClosed="onSelfTimeSettingPopupClose"
    />
    <web-time-setting-popup
      v-if="isWebTimeSettingPopupOpen"
      ref="webTimeSettingPopup"
      :filterCourse="courseCodeFilter"
      @popupClosed="onWebTimeSettingPopupClose"
    />
    <agency-setting-popup
      v-if="isAgencySettingPopupOpen"
      ref="agencySettingPopup"
      @popupClosed="onAgencySettingPopupOpenClose"
    />
    <blacklist-popup
      v-if="isBlacklistPopupOpen"
      ref="blacklistPopup"
      @popupClosed="onBlacklistPopupClose"
    />
    <daily-reservation-popup
      v-if="isDailyReservationPopupOpen"
      ref="dailyReservationPopup"
      @popupConfirmed="onDailyReservationPopupConfirm"
      @popupClosed="onDailyReservationPopupClose"
    />
    <reservation-info-popup
      v-if="isReservationInfoPopupOpen"
      ref="reservationInfoPopup"
      @popupConfirmed="onReservationInfoPopupConfirm"
      @popupClosed="onReservationInfoPopupClose"
    />
    <reservation-calendar-popup
      v-if="isReservationCalendarPopupOpen"
      ref="reservationCalendarPopup"
      @popupClosed="onReservationCalendarPopupClosed"
    />
    <reservation-history-popup
      v-if="isReservationHistoryPopupOpen"
      ref="reservationHistoryPopup"
      @popupClosed="onReservationHistoryPopupClosed"
    />
    <history-popup
      v-if="isHistoryPopupOpen"
      ref="historyPopup"
      @popupClosed="historyPopupClose"
    />
    <reservation-sms-send
      v-if="isReservationSmsSendPopupOpen"
      ref="reservationSmsSendPopup"
      @popupClosed="reservationSmsSendPopupClose"
    />
    <hold-time-view-popup
      v-if="isHoldTimeViewPopupOpen"
      ref="holdTimeViewPopup"
      @popupClosed="holdTimeViewPopupClose"
    />
    <waiting-reservation-popup
      v-if="isWaitingReservationPopupOpen"
      ref="waitingReservationPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      popupKey=""
      @popupEvent="onWaitingReservationPopupEvent"
    />
    <waiting-reservation-list-popup
      v-if="isWaitingReservationListPopupOpen"
      ref="waitingReservationListPopup"
      @popupClosed="waitingReservationListPopupClosed"
      @dataRefresh="searchReservation"
    />
    <reservation-time-promotion-popup
      v-if="isReservationTimePromotionPopupOpen"
      ref="reservationTimePromotionPopup"
      @popupClosed="reservationTimePromotionPopupClosed"
    />
    <delete-confirm-Popup
        v-if="isDeleteConfirmPopup"
        ref="deleteConfirmPopup"
        :propsDeleteButtonClicked="postDeleteRoomPkg"
        @popupClosed="deleteConfirmPopupClosed"
    />
    <available-room-popup
      v-if="isAvailableRoomPopup"
      ref="availableRoomPopup"
      @popupClosed="onAvailableRoomPopupClosed"
      @popupConfirm="onAvailableRoomPopupConfirm"
    />
    <apply-and-cancel-select-popyup
        v-if="isApplyAndCancelSelectPopup"
        ref="applyAndCancelSelectPopup"
        @popupConfirm="settingCommonButtonStatus"
        @popupClosed="onApplyAndCancelSelectPopupClose"
    >
    </apply-and-cancel-select-popyup>
    <p-g-cancel-popup
        v-if="isPGCancelPopupOpen"
        ref="pgCancelPopup"
        @popupClosed="pgCancelPopupClosed"
    />
  </div>
</template>



<script>
import { Edit, Resize, ForeignKey } from "@syncfusion/ej2-vue-grids";

import _, { sortBy as _sortBy, remove as _remove, orderBy as _orderBy, minBy as _minBy } from "lodash";
import moment from "moment";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import InputDate from "@/components/common/datetime/InputDate";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { deepDiffs } from "@/utils/ObjectUtil";
import ErpButton from "@/components/button/ErpButton.vue";
import ApplyAndCancelSelectPopyup from "@/views/golf-reservation/popup/ApplyAndCancelSelectPopyup";
import {
  commonCodesGetCommonCode,
  commonCodesGetColorValue,
  commonCodesGetComName,
  commonCodeGetComCodeDefaultValue,
  commonCodesGetJsonData,
  commonCodesGetStandardInfo,
  commonCodesGetCommonCodeAttrbByCodeAndIdx,
  commonCodesGetCodeAbrv,
  commonCodesGetCommonCodeAttrbNameByCodeAndIdx,
  commonCodesGetSortNo,
  commonCodesGetCommonCodeByIdx,
} from "@/utils/commonCodes";
import {
  getDayOfWeekCaptionColor,
  getDayOfWeekCaption,
  getTodayNavigationDate,
  getFormattedDate,
} from "@/utils/date";
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  gridUtilGetMemberNoColumnAccess,
  gridUtilGetTelColumnAccess,
  reservationCourseGridSectionBodyClassName,
} from "@/utils/gridUtil";
import {formNotPassedRequiredCheck, validateFormRefs, ValidType} from "@/utils/formUtil";
import { numberWithCommas } from "@/utils/number";
import { memberNoFormatter } from "@/utils/formatter";

import DeleteConfirmPopup from "@/components/popups/DeleteConfirmPopup" ;
import reservationCancelPopup from "./popup/ReservationCancelPopup";
import groupPopup from "./popup/GroupPopup";
import promotionSearchPopup from "./popup/PromotionSearchPopup";
import memberSelectPopup from "@/views/common/MemberSelectPopup";
import reservationMovePopup from "./popup/ReservationMovePopup";
import reservationTimeCreatePopup from "./popup/ReservationTimeCreatePopup";
import webTimeSettingPopup from "./popup/WebTimeSettingPopup";
import blacklistPopup from "./popup/BlacklistPopup";
import dailyReservationPopup from "./popup/DailyReservationPopup";
import reservationInfoPopup from "@/views/common/ReservationInfoPopup";
import reservationCalendarPopup from "./popup/ReservationCalendarPopup";
import reservationHistoryPopup from "./popup/ReservationHistoryPopup";
import reservationSmsSend from "./popup/ReservationSmsSendPopup";
import holdTimeViewPopup from "./popup/HoldTimeViewPopup";
import reservationCopyPopup from "./popup/ReservationCopyPopup";
import WaitingReservationPopup from "./popup/WaitingReservationPopup";
import WaitingReservationListPopup from "@/views/golf-reservation/popup/WaitingReservationListPopup";
import ReservationTimePromotionPopup from "@/views/golf-reservation/popup/ReservationTimePromotionPopup";
import AvailableRoomPopup from "@/views/room-reservation-management/popup/AvailableRoomPopup";
import AgencySettingPopup from "@/views/golf-reservation/popup/AgencySettingPopup";
import SelfTimeSettingPopup from "@/views/golf-reservation/popup/SelfTimeSettingPopup.vue";

import {getReservationCheck, getReservationInfoTable} from "@/api/reservation";
import { getGroupList, getGroupInfoDetail } from "@/api/group";
import { getPromotionList } from "@/api/promotion";
import {
  saveTimeStatusSetting,
  webTimeCancel,
  getTimeStatusCount,
} from '@/api/timeTable';
import { mapActions } from "vuex/dist/vuex.esm.browser";
import InputNumber from "@/components/common/InputNumber";
import HistoryPopup from "@/views/common/HistoryPopup";

import GolfErpAPI from "@/api/v2/GolfErpAPI";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import { mapGetters } from "vuex";
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";

import { openNewWindow } from "@/utils/appInfo";
import {addShortcut, keypressEventEqualsShortcut, removeShortcut, SHORTCUT_KEYS} from "@/utils/KeyboardUtil";

// 연계회원 component
import LinkMembers from "@/views/common/LinkMembers";
import ComponentTelephone from "@/components/ComponentTelephone";
import {getPaymentPrice} from "@/api/common";
import Vue from "vue";
import _groupBy from "lodash/groupBy";
import PGCancelPopup from "@/components/popups/PGCancelPopup.vue";

const SHORTCUT_INS = {
  key: "reservationReceiptRegistration.shortcuts.ins",
  shortcut: {
    ctrlKey: false,
    altKey: false,
    shiftKey: false,
    key: SHORTCUT_KEYS.Insert,
  },
};

export default {
  name: "reservationReceiptRegistration",
  components: {
    PGCancelPopup,
    SelfTimeSettingPopup,
    DeleteConfirmPopup,
    ComponentTelephone,
    InputTextarea,
    InputText,
    InputNumber,
    reservationCancelPopup,
    groupPopup,
    promotionSearchPopup,
    memberSelectPopup,
    reservationMovePopup,
    reservationTimeCreatePopup,
    webTimeSettingPopup,
    blacklistPopup,
    dailyReservationPopup,
    reservationInfoPopup,
    reservationCalendarPopup,
    reservationHistoryPopup,
    ejsGridWrapper,
    InputDate,
    HistoryPopup,
    reservationSmsSend,
    holdTimeViewPopup,
    LinkMembers,
    WaitingReservationPopup,
    WaitingReservationListPopup,
    ReservationTimePromotionPopup,
    AvailableRoomPopup,
    ErpButton,
    reservationCopyPopup,
    ApplyAndCancelSelectPopyup,
    AgencySettingPopup,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  computed: {
    ...mapGetters(["userId", "isRightMemberPopupOpened"]),
    isGroupReservation() {
      return this.reservationDetailInfo.reservationInfo.grpKind !== "FIT" && this.reservationDetailInfo.reservationInfo.grpName;
    },
    courseCodeFilter() {
      let courseCodeIdx2 = [];
      commonCodesGetCommonCodeByIdx("COURSE_CODE", 2).map(item => {
        item.comCodeAttrbList.map(attrb => {
          courseCodeIdx2.push(attrb);
        });
      });
      return this.reservationOptions.courseDiv === "%" ?
          courseCodeIdx2.map(item => item.comCode) :
          courseCodeIdx2.filter(item => item.attrb === this.reservationOptions.courseDiv).map(item => item.comCode);
    },
    isPopupOpened() {
      return (
        this.isReservationCancelPopupOpen ||
        this.isGroupPopupOpen ||
        this.isPromotionSearchPopupOpen ||
        this.isMemberSelectPopupOpen ||
        this.isReservationMovePopupOpen ||
        this.isReservationCopyPopupOpen ||
        this.isReservationTimeCreatePopupOpen ||
        this.isWebTimeSettingPopupOpen ||
        this.isAgencySettingPopupOpen ||
        this.isBlacklistPopupOpen ||
        this.isDailyReservationPopupOpen ||
        this.isReservationInfoPopupOpen ||
        this.isReservationCalendarPopupOpen ||
        this.isReservationHistoryPopupOpen ||
        this.isHistoryPopupOpen ||
        this.isReservationSmsSendPopupOpen ||
        this.isHoldTimeViewPopupOpen ||
        this.isRightMemberPopupOpened ||
        this.isWaitingReservationPopupOpen ||
        this.isWaitingReservationListPopupOpen ||
        this.isReservationTimePromotionPopupOpen ||
        this.isDeleteConfirmPopup ||
        this.isAvailableRoomPopup ||
        this.isSelfTimeSettingPopupOpen ||
        this.isPGCancelPopupOpen
      );
    },
    widthBigBox(){
      return `flex: 1;` ;
    },
    shortcutMenuItems() {
      return [
        //  올림픽 대기 예약 사용안함
        // {id: 1, text: "대기예약 등록",},
        // {id: 2, text: "예약 방명록 출력",},
      ];
    },
    etcMenuItems() {
      return [
        {
          text: "다운로드",
        },
        {
          text: "인쇄하기",
        },
      ];
    },
    reservationCourseSectionBodyClassName() {
      return reservationCourseGridSectionBodyClassName(
        this.reservationCourseLists,
        this.$t("className.reservation.reservationCourseNumPrefix")
      );
    },
    sendSmsButtonProps() {
      return {
        shortcutKey: "ReservationReceiptRegistration.shortcuts.sendSms",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F7,
        },
      };
    },
    viewReservationInfoButtonProps() {
      return {
        shortcutKey:
          "ReservationReceiptRegistration.shortcuts.viewReservationInfo",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F8,
        },
      };
    },
    viewDailyReservationButtonProps() {
      return {
        shortcutKey:
          "ReservationReceiptRegistration.shortcuts.viewDailyReservation",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F10,
        },
      };
    },
    closeDetailViewButtonProps() {
      return {
        shortcutKey: "ReservationReceiptRegistration.shortcuts.closeDetailView",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.Esc,
          keyName: "Esc",
        },
      };
    },
  },
  data() {
    return {
      //무인체크인 설정  || 예약 동반자 자동 등록
      noneChkinUseFlag : commonCodesGetStandardInfo("noneChkinUseFlag"),
      resveCmpnAuto: commonCodesGetStandardInfo("noneChkinUseFlag") || commonCodesGetStandardInfo("resveCmpnAuto"),
      partDiv: commonCodesGetCommonCode("PART_DIV", true),
      isReservationDailyStatusVisible: true,
      isfetchReservationTimesDetailError: false,
      moment: moment,
      memberPopupType: {
        RESV: "RESV", // 예약자
        TRANS: "TRANS", // 위임자
        COMP: "COMP", // 동반자
      },
      isApplyAndCancelSelectPopup: false,
      isRoomReservationListVisible: false,
      isHoldTimeViewPopupOpen: false,
      isReservationSmsSendPopupOpen: false,
      isHistoryPopupOpen: false,
      isReservationDetailTimeInfoVisible: true,
      isReservationDetailMembershipInfoVisible: commonCodesGetStandardInfo("courseClass") === "R", // 시스템 설정의 회원제/퍼블릭 항목이 "정규홀"이면 기본 펼치기.
      isReservationDetailReservationListFromTomorrowVisible: true,
      isPaymentListVisible: true,
      isPGCancelPopupOpen: false,
      isSelfTimeSettingPopupOpen: false,
      selectedDataForHistory: { resveId: null, timeId: null },
      memberPopupPosition: { x: "center", y: "center" },
      selectedField: null,
      reservationInfoValidationRules: {
        resveName: {
          required: true,
          maxLength: 50,
        },
        memberDiv: {
          required: true,
        },
        memberGrade: {
          required: true,
        },
        contactName: {
          required: true,
          maxLength: 50,
        },
        contactTelInfo: {
          required: false,
          type: ValidType.HP
        },
        resveKind: {
          required: true,
        },
        resveChannel: {
          required: true,
        },
        grpName: {
          maxLength: 50,
          required: true
        },
        resveRemarks: {
          maxLength: 400,
        },
        recomendr: {
          maxLength: 50,
        },
      },
      reservationCourseLists: [],
      reservationCourseActiveIndex: 0,
      reservationCourseSpecifyIndex: null,
      reservationCourseCount: 0,
      reservationOptions: {
        paymtPrice: true,
        bsnDate: null,
        resveDate: null,
        preResveDate: null,
        blankFlag: false,
        searchValue: null,
        aditTeamFlag: false,
        courseDiv: "%",
        courseDivOptions: null,
      },
      isSetInTheMenuOnTheRight: false, // 우측 사이드 회원 예약/내장에서 화면 이동 여부.
      isReservationDailyStatusGridAutoSelectRow: true, // 재조회시 첫번째 Row 자동 Select 여부.
      isQuestionReservationDetailModify: false, // "수정 내역이 있습니다. 진행하시겠습니까?" 두번 질문 방지용.
      isPreResveDateReturnFlag: false,
      autoSelectStackInfo: null, // 예약타임 목록 Row 클릭 후 날짜 다른 경우, 재조회한 다음 자동 Cell 포커싱되도록 하는 Time 정보
      reservationDetailInfoOriginal: {}, // 비교를 위한 정보
      prevModifyReservationDetailInfo: {}, // 수정된 데이터 비교를 위한 정보
      reservationDetailInfo: {},
      isLinkMember: commonCodesGetStandardInfo("courseClass") === "R", // 시스템 설정의 회원제/퍼블릭 항목이 "정규홀"이면 기본 체크.
      reservationDailyStatusGridOptions: {
        provides: [Resize, ForeignKey],
        data: [],
        columns: [
          {field: "resveDate", type: "string", textAlign: "center", width: 70, isPrimaryKey: true, visible: false,},
          {field: "resveDateYYMMDD", headerText: "일자", type: "string", textAlign: "center", width: 50, isPrimaryKey: true,},
          {field: "dwCode", headerText: "요일", type: "string", textAlign: "center", width: 50, isCommonCodeField: true, groupCode: "DW_CODE",},
          {
            headerText: "인원",
            columns: [
              {field: "totalTimeCount", headerText: "T", type: "number", textAlign: "center", width: 30, customAttributes: {style: {padding: 0}},},
              {field: "reservationCount", headerText: "R", type: "number", textAlign: "center", width: 30, customAttributes: {style: {padding: 0}},},
              // {field: "waitingCount",     visible:false, headerText: "W", type: "number", textAlign: "center", width: 30, customAttributes: {style: {padding: 0}},},
            ]
          },
          {field: "resvrvationTeamCount", headerText: "예약타임", type: "number", textAlign: "center", width: 70, customAttributes: {style: {padding: 0}},},
          // {
          //   headerText: "팀",
          //   columns: [
          //     {field: "remaind4", headerText: "4", type: "number", textAlign: "center", width: 30, customAttributes: {style: {padding: 0}},},
          //     {field: "remaind3", headerText: "3", type: "number", textAlign: "center", width: 30, customAttributes: {style: {padding: 0}},},
          //     {field: "remaind2", headerText: "2", type: "number", textAlign: "center", width: 30, customAttributes: {style: {padding: 0}},},
          //     {field: "remaind1", headerText: "1", type: "number", textAlign: "center", width: 30, customAttributes: {style: {padding: 0}},},
          //     // {field: "waitingCount",     visible:false, headerText: "W", type: "number", textAlign: "center", width: 30, customAttributes: {style: {padding: 0}},},
          //   ]
          // },
        ],
        selectionSettings: {
          type: "Single",
          mode: "Row",
          enableToggle: false,
        },
      },
      roomReservationListGridOptions:{
        provides: [Resize, ForeignKey],
        selectionSettings: {allowEditing: false, allowAdding: false, type: "Single", mode: "Both", enableToggle: false,},
        columns: [
          {field: "resveNo", type: "string", isPrimaryKey: true, visible: false,},
          {field: "rresveNo", type: "string", isPrimaryKey: true, visible: false,},
          {field: "arrivalDate", headerText: "입실", type: "string", textAlign: "center", width: 60, valueAccessor : (field, data) => moment(data[field]).format('MM-DD')},
          {field: "departureDate", type: "string", isPrimaryKey: true, visible: false,},
          {field: "stayCnt", headerText: "박", type: "string", textAlign: "center", width: 40, valueAccessor: (field, data) => `${data[field]}박`},
          {field: "roomType", headerText: "객실타입", type: "string", textAlign: "center", width: 80, isCommonCodeField: true, groupCode: "ROOM_TYPE",},
          {field: "roomDcKind", headerText: "판매유형", type: "string", textAlign: "center", width: 80, isCommonCodeField: true, groupCode: "ROOM_DC_KIND",},
          {field: "roomAmt", headerText: "객실료", type: "string", textAlign: "Right", width: 80, valueAccessor: (field, data)=> numberWithCommas(data[field])},
          {field: "roomSaleKind", headerText: "할인내용", type: "string", textAlign: "Left", width: 100, isCommonCodeField: true, groupCode: "ROOM_SALE_KIND",},
          {field: "resveRemarks", headerText: "비고", type: "string", textAlign: "Left", width: 100,},
          {field: "dropdownMenu", headerText: "", width: 24,
            template: () => {
              return {
                template: {
                  extends: Vue.component("roomReservationGridDropdownTemplate", {
                    template:
                        "<div class='dropdownbutton' style=''><ejs-dropdownbutton :items='meunItems' :select='onMenuSelect'></ejs-dropdownbutton></div>",
                    props: ["roomReservationGridMenuSelectedCallback"],
                    data() {
                      return {
                        meunItems: [],
                        data: {
                          data: {},
                        },
                      };
                    },
                    mounted() {
                      this.meunItems = [
                        {
                          text: "객실예약 이동",
                          value: "moveRoom",
                        },
                        {
                          text: "객실PKG 취소",
                          value: "cancelRoom",
                        }
                      ];
                    },
                    methods: {
                      onMenuSelect: function (args) {
                        let eventParam = {
                          menu: args.item.value,
                          data: this.data,
                        };
                        this.roomReservationGridMenuSelectedCallback(eventParam);
                        // this.$EventBus.$emit('payInfoGridMenuSelected', eventParam);
                      },
                    },
                  }),
                  propsData: {
                    roomReservationGridMenuSelectedCallback: this
                        .onRoomReservationGridMenuSelected,
                  },
                },
              };
            },
            type: "string",
            allowFiltering: false,
          },
        ]
      },
      reservationListFromTomorrowGridData: [],
      reservationListFromTomorrowGridOptions: {
        provides: [Resize, ForeignKey],
        selectionSettings: {type: "Single", mode: "Both", enableToggle: false,},
        columns: [
          {field: "timeId", type: "number", isPrimaryKey: true, visible: false,},
          {field: "resveDate", headerText: "일자", type: "string", textAlign: "center", minWidth: 16, width: 80, visible: false,},
          {field: "monthDayLabel", headerText: "일자", type: "string", textAlign: "center", minWidth: 16, width: 60,},
          {field: "dwCode", headerText: "요일", type: "string", textAlign: "center", minWidth: 16, width: 60, isCommonCodeField: true, groupCode: "DW_CODE",},
          {field: "bsnCode", headerText: "영업", type: "string", textAlign: "center", minWidth: 16, width: 60, isCommonCodeField: true, groupCode: "BSN_CODE",},
          {field: "resveTime", headerText: "시간", type: "string", textAlign: "center", minWidth: 16, width: 60,},
          {field: "resveCourse", headerText: "코스", type: "string", textAlign: "center", minWidth: 16, width: 80, isCommonCodeField: true, groupCode: "COURSE_CODE",},
          {field: "resveName", headerText: "예약자명", type: "string", textAlign: "left", minWidth: 16, width: 80,},
          {field: "memberNo", headerText: "회원번호", type: "string", textAlign: "center", minWidth: 16, width: 90,},
          {field: "memberDiv", headerText: "회원구분", type: "string", textAlign: "left", minWidth: 16, width: 90, isCommonCodeField: true, groupCode: "MEMBER_DIV",},
          {field: "memberGrade", headerText: "회원등급", type: "string", textAlign: "left", minWidth: 16, width: 90, isCommonCodeField: true, groupCode: "MEMBER_GRADE",},
          {field: "contactTel", headerText: "연락처", type: "string", textAlign: "left", minWidth: 16, width: 100,},
          {field: "grpName", headerText: "단체명", type: "string", textAlign: "left", minWidth: 16, width: 90,},
          {field: "resveRemarks", headerText: "비고", type: "string", textAlign: "left", minWidth: 16, width: 150,},
          {field: "insertUserName", headerText: "등록자", type: "string", textAlign: "left", minWidth: 16, width: 90,},
          {field: "insertDt", headerText: "등록일시", type: "string", textAlign: "left", minWidth: 16, width: 130,},
        ],
      },
      reservationViewOptions: {
        provides: [Resize, ForeignKey, Edit],
        columns: [
          {field: "timeId", type: "number", width: 40,  isPrimaryKey: true, visible: false, allowEditing: false,},
          {field: "no",         headerText: "NO", type: "number", width: 40, minWidth: 16, allowEditing: false, textAlign: "Center",},
          {field: "select",     headerText: "", allowEditing: true, type: "boolean", editType: "booleanedit", displayAsCheckBox: true, minWidth: 16, width: 30, textAlign: "center", isSelectAllColumn: true, selectAllCheckFunction: (record) => record.timeStatus !== 'RESVE' && record.resveStatus !== "RESVE",},
          {field: "joinOpenFlag", visible:false, headerText: "J", editType: "booleanedit", type: "boolean", displayAsCheckBox: true, textAlign: "Center", minWidth: 16, width: 30, allowEditing:false},
          {field: "timeStatus", headerText: "타임상태", type: "string", width: 90, visible: false, allowEditing: false,},
          {field: "holeDiv", headerText: "홀", type: "string", textAlign: "center", minWidth: 50, width: 50, isCommonCodeField: true, groupCode: "HOLE_DIV", allowEditing: true, editType: 'dropdownedit'},
          {field: "resveTime",  headerText: "시간",  type: "string", textAlign: "center", minWidth: 16, width: 50, allowEditing: false,},
          {field: "resveName0", headerText: "예약4", type: "string", textAlign: "center", minWidth: 16, width: 80, allowEditing: false,},
          {field: "memo0",      headerText: "",     type: "string", minWidth: 16, maxWidth: 20, width: 20, allowEditing: false,},
          {field: "resveName1", headerText: "예약3", type: "string",textAlign: "center", minWidth: 16, width: 80, allowEditing: false,},
          {field: "memo1",      headerText: "",     type: "string", minWidth: 16, maxWidth: 20, width: 20, allowEditing: false,},
          {field: "resveName2", headerText: "예약2", type: "string", textAlign: "center", minWidth: 16, width: 80, allowEditing: false,},
          {field: "memo2",      headerText: "",     type: "string", minWidth: 16, maxWidth: 20, width: 20, allowEditing: false,},
          {field: "resveName3", headerText: "예약1", type: "string", textAlign: "center",minWidth: 16, width: 80, allowEditing: false,},
          {field: "memo3",      headerText: "", type: "string", minWidth: 16, maxWidth: 20, width: 20, allowEditing: false,},
          {field: "grpName",    headerText: "단체명", type: "string", minWidth: 16, width: 80, allowEditing: false,},
          {field: "resveRemarks", headerText: "비고", type: "string", minWidth: 16, width: 90, allowEditing: false,},
          //   selfOpenFlag,cart 올림픽 사용 안함
          {field: "selfOpenFlag", visible:false, headerText: "S", editType: "booleanedit", type: "boolean", displayAsCheckBox: true, textAlign: "Center", minWidth: 16, width: 30, allowEditing: true,},
          {field: "cart", visible:false, headerText: "", type: "string", minWidth: 16, maxWidth: 20, width: 20, allowEditing: false,},
          {field: "paymtPrice", headerText: "입장료", type: "string", isNumericType: true, minWidth: 16, width: 60, textAlign: "Right", allowEditing: false,},
          {field: "promtnName", headerText: "프로모션", type: "string", minWidth: 16, width: 100, allowEditing: false,},

          {field: "comfirmVisitCnt", headerText: "확정", type: "string", minWidth: 16, width: 36, textAlign: "right", allowEditing: false,},
          {field: "visitCnt", headerText: "인원", type: "string", minWidth: 16, width: 36, textAlign: "right", allowEditing: false,},
        /* Roy 올림픽 조인 예약 변경으로 ... 주석처리
          { field: "resveChannel", headerText: "채널", type: "string", textAlign: "center", minWidth: 16, width: 90, isCommonCodeField: true, groupCode: "RESVE_CHANNEL", allowEditing: false, },
          { field: "resveInfo", headerText: "접수자", type: "string", minWidth: 16, width: 90, allowEditing: false, },
          { field: "memberNo", headerText: "회원번호", type: "string", textAlign: "center", minWidth: 16, width: 80, allowEditing: false, valueAccessor: (field, data) => gridUtilGetMemberNoColumnAccess(field, data), },
          */
        ],
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
        },
        grpKindOptions: commonCodesGetCommonCode("GRP_KIND"),
        grpKindFields: { text: "comName", value: "comCode" },
        memberDivOptions: commonCodesGetCommonCode("MEMBER_DIV", true),
        memberDivFields: { text: "comName", value: "comCode" },
        memberGradeOptions: commonCodesGetCommonCode("MEMBER_GRADE", true),
        memberGradeFields: { text: "comName", value: "comCode" },
        resveKindOptions: commonCodesGetCommonCode("RESVE_KIND"),
        resveKindFields: { text: "comName", value: "comCode" },
        resveChannelOptions: commonCodesGetCommonCode("RESVE_CHANNEL", true),
        resveChannelFields: { text: "comName", value: "comCode" },
        areaCodeOptions: commonCodesGetCommonCode("AREA_CODE"),
        areaCodeFields: { text: "comName", value: "comCode" },
        sexCodeOptions: commonCodesGetCommonCode("SEX_CODE"),
        sexCodeFields: { text: "comName", value: "comCode" },
        roomCodeOptions: commonCodesGetCommonCode("ROOM_USE_DIV"),
        roomCodeFields:{ text: "comName", value: "comCode" },
        cartKindCodeOptions: commonCodesGetCommonCode("CART_KIND"),
        cartKindCodeFields: { text: "comName", value: "comCode" },
        reservationDetailCancelTimeIdCheckboxAll: false, // 예약취소 checkall 체크박스 binding value
      },
      reservationCompanionOptions: {
        provides: [Edit, Resize, ForeignKey],
        selectionSettings: {
          type: 'Single',
          checkboxMode: 'Default',
          checkboxOnly: true,
        },
        columns: [
          {field: "checkbox", type: "checkbox", width: 40, textAlign: "center"},
          {field: "reprsntFlag", headerText: "대표", minWidth: 16, width: 50, editType: "booleanedit", type: "boolean", displayAsCheckBox: true, textAlign: "Center", visible: false},
          {field: "cmpnName", headerText: "동반자명", minwidth: 16, width: 70, type: "string",},
          {field: "cmpnNameIcon", allowEditing: false, headerText: "", minwidth: 16, width: 24, type: "string",},
          {field: "contactTel", headerText: "연락처", minwidth: 16, width: 100, editType: "telephoneedit", valueAccessor: (field, data) => gridUtilGetTelColumnAccess(field, data), type: "string",},
          {field: "sexCode", headerText: "성별", minwidth: 16, width: 50, editType: "dropdownedit", isCommonCodeField: true, groupCode: "SEX_CODE", edit: { params: { sortOrder: "none" } }, type: "string",},
          {field: "joinGroupNo", headerText: "조인", minwidth: 16, width: 50, type: "string",},
          {field: "memberNo", allowEditing: false, headerText: "회원번호", minwidth: 16, width: 90, type: "string",},
          {field: "lockerNo", headerText: "락카번호", minwidth: 16, width: 90, type: "string",},
          {field: "membershipId", visible: false, type: "string",},
          {field: "memberDiv", visible: false, type: "string",},
          {field: "memberGrade", visible: false, type: "string",},
          {field: "sexCode", visible: false, type: "string",},
        ],
        validationRules: {
          cmpnName: {required: true, maxLength: 50,},
          joinGroupNo: {maxLength: 50,},
          lockerNo: {maxLength: 50,},
          contactTel: {
            type: ValidType.HP,
            custom: {
              method: (data) => {
                let rtn = false;
                if (this.resveCmpnAuto && !this.reservationDetailInfo.reservationInfo.grpNo) {
                  if (data.sno === 1) {
                    rtn = true;
                  } else {
                    rtn = !formNotPassedRequiredCheck(data.contactTel);
                  }
                } else {
                  if (this.resveCmpnAuto) {
                    rtn = !formNotPassedRequiredCheck(data.contactTel);
                  } else {
                    rtn = true;
                  }
                }

                return rtn;
              },
              message: this.$t("main.validationMessage.requiredMessage", ["동반자 연락처"])
            }
          }
        },
      },
      paymentListGrid: {
        provides: [Resize, ForeignKey],
        selectionSettings: {type: "Single", mode: "Both", enableToggle: false, persistSelection: false,},
        columns: [
          { field: "incomId", visible: false, isPrimaryKey: true, },
          { field: "incomDate", headerText: "결제일자", width: 90, textAlign: "center", },
          { field: "trnscDiv", headerText: "거래구분", width: 90, valueAccessor: (field, data) => commonCodesGetComName("TRNSC_DIV", data[field]), },
          { field: "incomDiv", headerText: "결제구분", width: 90, valueAccessor: (field, data) => commonCodesGetComName("INCOM_DIV", data[field]), },
          { field: "depositAmt", headerText: "결제금액", textAlign: "right", width: 90, type: "number", format: "N", },
          { field: "delStatus", headerText: "상태", width: 120, },
          { field: "approvalNo", headerText: "승인번호", width: 120, },
          { field: "purcName", headerText: "매입처", width: 120, },
          { field: "bankCode", headerText: "은행", width: 120, valueAccessor: (field, data) => commonCodesGetComName("BANK_CODE", data[field]), },
          { field: "acnutNo", headerText: "계좌번호", width: 120, },
          { field: "insertName", headerText: "등록자", width: 90, },
          { field: "insertDt", headerText: "등록일시", width: 140, textAlign: "center", },
        ],
      },
      isReservationDetailViewOpened: false, // 예약정보 상세 view 출력 여부
      isReservationDetailModifyMode: false, // 예약정보 수정 모드 ON 여부
      isReservationDetailViewFixed: true, // 예약 목록 고정 여부
      timeMenus: [
        {text: "메모 등록", timeId: 3000,},
        {text: "예약 이동", timeId: 2000,},
        {text: "예약 취소", timeId: 4000,},
      ],
      currentActiveTimeIds: [], // 현재 클릭한 시간의 timeId값. 상세 화면에서 '펼치기' 용도로 사용,
      reservationDetailCancelTimeIds: [], // 예약취소 선택한 timeId 값들,
      isReservationCancelPopupOpen: false, // 예약취소 팝업 Open여부
      isGroupPopupOpen: false, // 그룹팝업 Open 여부
      isPromotionSearchPopupOpen: false, // 프로모션 팝업 Open 여부
      isMemberSelectPopupOpen: false, // 유저 선택 팝업 Open 여부
      isReservationMovePopupOpen: false,
      isReservationCopyPopupOpen: false,
      isReservationTimeCreatePopupOpen: false,
      isWebTimeSettingPopupOpen: false,
      isAgencySettingPopupOpen: false,
      isBlacklistPopupOpen: false,
      isDailyReservationPopupOpen: false,
      isReservationInfoPopupOpen: false,
      isReservationCalendarPopupOpen: false,
      isReservationHistoryPopupOpen: false,
      isWaitingReservationPopupOpen: false,
      isWaitingReservationListPopupOpen: false,
      isReservationTimePromotionPopupOpen: false,
      isDeleteConfirmPopup:false,
      isAvailableRoomPopup: false,
      isSearchValueFocus: false,
      memberLinkList: [],
      openedTimeId: null, // 현재 상세화면의 time id
      selectedRowData: null, // 현재 rowSelected된 data를 임시 저장하는 데이터
      isSameRow: false,
      resveDateChangeApiFlag: false,
      reservationInfoPopupConfirmTimeId: null,
      bsnDateInfo: {
        bsnCode: null,
        dwCode: null,
      },
      orgContactTel: null,
      changeContactTel: null,
      filteredFoundIndexList: [],
      savedSearchValue: null,
      promotionCodes: [],
      commonCodeFields: { text: "comName", value: "comCode" },
      resveNamefield : "resveName0",
    };
  },
  async created() {
    addShortcut(SHORTCUT_INS.key, SHORTCUT_INS.shortcut);
    window.addEventListener("keydown", this.onWindowKeypress, true);

    const queryBsnDate = this.$route.query.bsnDate;
    const queryTimeId = this.$route.query.timeId;
    const todayDate = await getTodayNavigationDate("YYYY-MM-DD");
    this.reservationCourseSpecifyIndex = this.$route.query.resveCourse;

    // 예약일자 설정
    if (queryBsnDate && moment(queryBsnDate, "YYYY-MM-DD", true).isValid()) {
      this.reservationOptions.bsnDate = queryBsnDate;
    } else {
      this.reservationOptions.bsnDate = todayDate;
    }

    // timeId가 querystring 파라미터로 오면, 해당 row 선택 처리
    if (queryTimeId && !isNaN(queryTimeId)) {
      this.openedTimeId = parseInt(queryTimeId);
    }

    this.reservationOptions.courseDivOptions = commonCodesGetCommonCode("COURSE_DIV", true);
    this.reservationOptions.courseDivOptions.unshift({ comCode: "%", comName: "전체" });
    this.reservationViewOptions.roomCodeOptions.unshift({comCode: "", comName: ""});

    document.onkeydown = async (event) => {
      if ((event.which || event.keyCode) === 116) {
        if (this.isDetailDataModified()) {
          event.preventDefault();
          if (
            !this.isReservationDetailModifyMode &&
            (await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))
          ) {
            location.reload();
          }
        }
      }
    };

    // 상세 데이터 초기화
    this.DetailDataInitAndSyncWithOriginal();

    this.$EventBus.$on("setInTheMenuOnTheRight", (args) =>
      this.setInTheMenuOnTheRight(args)
    );

    this.$EventBus.$on("memoConfirm", (args) => {
      this.memoConfirm(args);
    });
  },
  async beforeDestroy() {
    window.removeEventListener("keydown", this.onWindowKeypress, true);
    removeShortcut(SHORTCUT_INS.key);

    // 화면 종료시 홀딩 해제.
    await this.detailHoldingTimesToBlank();

    this.$EventBus.$off("functionKeyPress");
    this.$EventBus.$off("setInTheMenuOnTheRight");
    this.$EventBus.$off("memoConfirm");

    const elements = document.querySelectorAll('.body-box[id]');
    elements.forEach(element => {
      element.removeEventListener('scroll', this.onScl, true);
      element.removeEventListener('wheel', this.onWhl, true);
    });

    document.onkeydown = null;

  },
  mounted() {
    // 동반자 그리드의 연락처 컬럼은 암호화데이터 열람 권한이 있어야 편집 가능
    let contactTelColumn = this.reservationCompanionOptions.columns.find(
      (column) => column.field === "contactTel"
    );
    contactTelColumn.allowEditing = this.commonMixinHasCiperTextGet;

    this.fetchResvePromtnAll();
  },
  async beforeRouteLeave(to, from, next) {
    if (this.isDetailDataModified()) {
      if (
        !this.isReservationDetailModifyMode &&
        !(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))
      ) {
        return;
      }
    }
    next();
  },
  methods: {
    ...mapActions("memoView", ["clearMemo", "setResveData"]),
    numberWithCommas: numberWithCommas,
    commonCodesGetStandardInfo,
    validateFormRefs,
    commonCodesGetColorValue,
    commonCodesGetComName,
    getDayOfWeekCaptionColor,
    getDayOfWeekCaption,
    memberNoFormatter,
    someMethod() {
      this.isReservationDailyStatusVisible = !this.isReservationDailyStatusVisible;

    },
    commonTimeSelectOption(buttonDiv) {
      const selectArrayLists = this.$refs.reservationGrid.map((grid) => {
        // 현재 grid select 후 바로 이벤트 발생 시 grid.getBatchCurrentViewRecords() 에서
        // 직전에 변경된데이터를 못가져와서 한번 saveCell 처리를 함
        grid.saveCell();

        return grid.getBatchCurrentViewRecords().filter((dataSource) => dataSource.select).map((row) => ({
          timeId: row.timeId,
          optFlag: row.optFlag,
          resveDate: row.resveDate,
          settingTimeStatus: buttonDiv,
          settingTimeStatusRemarks: null,
          timeSelectCheck: null,
          timeStatus: row.timeStatus,
        }));
      });

      const concatArray = [].concat(...selectArrayLists);

      if(!concatArray.length) {
        this.errorToast("선택된 항목이 없습니다");
        return;
      }
      const validation = concatArray.some((timeList) => timeList.timeStatus === 'EMPTY' || timeList.timeStatus === buttonDiv);

      if(!validation) {
        this.errorToast("변경 가능한 타임이 없습니다");
        return;
      }

      let requiredRemarks;
      switch (buttonDiv) {
        case "HOLD":
        case "BLOCK":
          requiredRemarks = true;
          break;
        default:
          requiredRemarks = false;
          break;
      }
      this.isApplyAndCancelSelectPopup = true;
      this.$nextTick(() => {
        this.$refs.applyAndCancelSelectPopup.show({
          buttonDiv,
          selectArray:concatArray,
          requiredRemarks,
        });
      });
    },
    async onGridCheckboxChanged(args) {
      const {columnName,rowData} = args;
      if(columnName === "selfOpenFlag") {
        const putData = {timeId: rowData.timeId,optFlag: rowData.optFlag, isSelfTime: args.value };
        await GolfErpAPI.reservationSelfTimeChange(putData);
        await this.getReservationStatusList();
      }
    },
    async reservationCellSaved(args) {
      if (this.isReservationDetailModifyMode) {
        this.errorToast("추가 중에는 진행하실 수 없습니다");
        return;
      }
      if (this.isDetailDataModified()) {
        // 수정여부 체크
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }

      if (args.columnName === "holeDiv") {
        if (args.previousValue !== args.value) {
          const putData = { timeId: args.rowData.timeId, optFlag: args.rowData.optFlag, holeDiv: args.value };
          await GolfErpAPI.reservationHoleDivChange(putData);

          await this.getReservationStatusList();
          if (this.isReservationDetailViewOpened && (this.selectedRowData.timeId === args.rowData.timeId)) {
            await this.refreshDetailInfo(
                this.reservationDetailInfo.reservationInfo.resveNo,
                args.rowData.timeId,
                true,
                args.rowData.timeStatus,
                args.rowData.insertId,
                args.rowData.resveDate,
                true,
                this.selectedField
            );
          }
        }
      }
    },
    async settingCommonButtonStatus(seleteTimeList) {
      await GolfErpAPI.reservationTimesStatusSetting(seleteTimeList);
      await this.getReservationStatusList();
    },
    setInTheMenuOnTheRight(args) {
      this.isSetInTheMenuOnTheRight = true;
      this.reservationOptions.bsnDate = args.resveDate;

      this.searchReservation();

      this.openedTimeId = args.timeId;
      const courseIdx = Number(this.$refs[`resveCourseCode${args.resveCourse}`][0].id);
      this.reservationCourseDivClicked(courseIdx);
    },
    timePopupOpenMappingOption() {
      return {
        resveDateFrom: this.reservationOptions.resveDate,
        resveDateTo: this.reservationOptions.resveDate,
      };
    },
    async selfTimeSettingPopup() {
      if (this.isReservationDetailModifyMode) {
        this.errorToast("추가 중에는 진행하실 수 없습니다");
        return;
      }
      if (this.isDetailDataModified()) {
        // 수정여부 체크
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }

      const timeOption = this.timePopupOpenMappingOption();
      this.isSelfTimeSettingPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.selfTimeSettingPopup.showSelfTimeSettingPopup(
            'SELF',
            timeOption
        );
      });
    },
    async moveReservation() {
      const timeInfo = this.selectedRowData;
        // 예약 이동 popup open
        if (this.isReservationDetailModifyMode) {
          this.errorToast("추가 중에는 예약 이동을 하실 수 없습니다");
          return;
        }
        if (this.isDetailDataModified()) {
          // 수정여부 체크
          if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
            return;
          }
        }

        if (timeInfo?.reservationConfirm?.resveStatus !== "RESVE") {
          this.infoToast("이동 가능한 예약상태가 아닙니다");
          return;
        }

        let popupData = {};
        popupData.timeId = timeInfo.timeId;
        popupData.resveDate = timeInfo.resveDate;
        popupData.resveTime = timeInfo.resveTime;
        popupData.resveCourse = timeInfo.resveCourse;
        popupData.resveName = this.reservationDetailInfo.reservationInfo.resveName;
        popupData.resveContactTel = this.reservationDetailInfo.reservationInfo.contactTel;
        popupData.resveMemberDiv = this.reservationDetailInfo.reservationInfo.memberDiv;
        popupData.grpName = this.reservationDetailInfo.reservationInfo.grpName;
        popupData.optFlag = timeInfo.optFlag;
        popupData.resveNo = this.reservationDetailInfo.reservationInfo.resveNo;
        popupData.smsRecptnDiv = this.reservationDetailInfo.reservationInfo.smsRecptnDiv;
        popupData.resveMngrName = this.reservationDetailInfo.reservationInfo.resveMngrName;
        popupData.resveMngrHp = this.reservationDetailInfo.reservationInfo.resveMngrHp;
        popupData.transName = timeInfo.transName;
        popupData.transContactTel = timeInfo.contactTel;
        popupData.transMemberDiv = timeInfo.memberDiv;
        popupData.visitCnt = timeInfo.visitCnt;

        const reservationTimes = await GolfErpAPI.fetchReservationTimesByTimeIds([popupData.timeId]);
        const reservationTimeFind = reservationTimes.find((reservationTime) => !["RESVE","JOIN"].includes(reservationTime.timeStatus));

        if (!!reservationTimeFind && reservationTimeFind.timeStatus !== "RESVE") {
          this.errorToast("자료가 변경되었습니다. 자료를 조회 후 다시 작업 바랍니다.");
          return;
        }

        this.isReservationMovePopupOpen = true;

        this.$nextTick(() => {
          this.$refs.reservationMovePopup.popupOpen(
              popupData,
              this.reservationOptions.resveDate,
              this.reservationOptions.resveDate
          );
        });
    },
    onDataBound(args, reservationCourseIdx) {
      // 예약정보 조회 팝업의 확인 버튼 클릭후 예약일자가 달라서 재조회후 해당 데이터 찾아가는 부분
      // onReservationInfoPopupConfirm 에서 this.openedTimeId 를 설정하는 것으로 해결. onDataBound 에서는 데이터를 찾아갈 필요 없음

      // 예약 상세화면의 time id가 현재 그리드에 있으면 selectrow
      // 새창띄우기 시, 맨 처음 그리드가 loading 될 때에는 actionComplete 이벤트가 호출되지 않으므로, actionComplete 이벤트의 코드를 여기로 옮겨옴
      const currentViewRecords = this.$refs.reservationGrid[reservationCourseIdx].getCurrentViewRecords();
      const idxToSelect = currentViewRecords.findIndex((record) => record.timeId === this.openedTimeId);
      if (idxToSelect >= 0) {
        this.$refs.reservationGrid[reservationCourseIdx].selectRow(idxToSelect);
      }

      // 추가모드인 경우 종료
      if (this.isReservationDetailModifyMode) {
        return;
      }

      // 예약 상세화면의 time id가 타임 목록 전체에 없으면 상세화면 닫고 종료
      let isOpenedTimeIdIsSearched = false;
      this.reservationCourseLists.every((course) => {
        if (course.reservationLists.findIndex((reservation) => reservation.timeId === this.openedTimeId) >= 0) {
          isOpenedTimeIdIsSearched = true;
          return false;
        }
        return true;
      });

      if (!this.openedTimeId) {
        isOpenedTimeIdIsSearched = true;
      }

      if (!isOpenedTimeIdIsSearched) {
        this.closeDetailView();
      }
      if (idxToSelect >= 0) {
        const selectedRecords = this.$refs.reservationGrid[reservationCourseIdx].getSelectedRecords();
        const eventData = {column: {field: this.resveNamefield,}, rowData: selectedRecords[0],};

        this.reservationGridRecordClicked(eventData, reservationCourseIdx, true);
      }
    },
    async searchReservation() {
      if (this.isPreResveDateReturnFlag) {
        this.isPreResveDateReturnFlag = false;
        return;
      }

      if (!this.isReservationDetailModifyMode && this.isDetailDataModified()) {
        // 수정여부 체크
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          // 진행 취소할 경우. 예약일자를 이전 예약일자로 돌리기 위한 작업.
          // this.isPreResveDateReturnFlag = true이면 재조회작업 안하고 return.
          this.changeReservationDateToPreviousReservationDate();
          return;
        }

        this.isQuestionReservationDetailModify = true;
      }

      await this.getReservationStatusList();

      this.clearSearch(true);
    },
    findReservation() {
      const searchValue = this.reservationOptions.searchValue;
      if (!searchValue) {
        this.infoToast(this.$t("main.popupMessage.noSearchValue"));
      } else {
        this.findAndSelectRowProcess(searchValue);
      }
    },
    getReservationFindIndex(trimedSearchValue) {
      const courseLength = this.reservationCourseLists.length;

      let result = {
        courseIdx: 0,
        findIdx: 0,
      };

      for (let i = 0; i < courseLength; i++) {
        this.$refs.reservationGrid[i].clearSelection();
      }

      for (let i = 0; i < courseLength; i++) {
        const filteredReservationCourseLists = this.filteredFoundIndexList[i]
                                              ? this.reservationCourseLists[i].reservationLists.filter((item, index) => this.filteredFoundIndexList[i].indexOf(index) < 0)
                                              : this.reservationCourseLists[i].reservationLists;
        console.log(filteredReservationCourseLists);
        const findItem = filteredReservationCourseLists.find(
          (item) =>
            (item.resveName0 && item.resveName0.includes(trimedSearchValue)) ||
              (item.resveName1 && item.resveName1.includes(trimedSearchValue)) ||
              (item.resveName2 && item.resveName2.includes(trimedSearchValue)) ||
              (item.resveName3 && item.resveName3.includes(trimedSearchValue)) ||
            (item.resveRemarks && item.resveRemarks.includes(trimedSearchValue))
        );

        const findIdx = this.reservationCourseLists[
          i
        ].reservationLists.findIndex((item) => item === findItem);

        result.courseIdx = i;
        result.findIdx = findIdx;

        if (result.findIdx >= 0) {
          if (!this.filteredFoundIndexList[i]) {
            this.filteredFoundIndexList[i] = [];
          }
          this.filteredFoundIndexList[i].push(findIdx);

          return result;
        }
      }

      if (result.findIdx === -1) {
        for (let i = 0; i < this.filteredFoundIndexList.length; i++) {
          if (this.filteredFoundIndexList[i]) {
            result.courseIdx = i;
            result.findIdx = this.filteredFoundIndexList[i][0];

            this.filteredFoundIndexList = [];
            this.filteredFoundIndexList[i] = [result.findIdx];
          }
        }
      }

      return result;
    },
    clearSearch(claerSavedSearchValue = false) {
      if (claerSavedSearchValue) {
        this.savedSearchValue = null;
      }
      this.filteredFoundIndexList = [];

      for (let i = 0; i < this.reservationCourseLists.length; i++) {
        this.$refs.reservationGrid[i].clearSelection();
      }
    },
    findAndSelectRowProcess(searchValue) {
      if (Array.isArray(this.reservationCourseLists)) {
        this.isSearchValueFocus = true;

        if (searchValue) {
          const trimedSearchValue = searchValue.trim();

          if (this.savedSearchValue !== trimedSearchValue) {
            this.savedSearchValue = trimedSearchValue;
            this.clearSearch();
          }

          const { courseIdx, findIdx } = this.getReservationFindIndex(trimedSearchValue);

          if (findIdx === -1) {
            this.errorToast(
              trimedSearchValue + "으로 검색된 예약 정보가 없습니다"
            );
            document
              .getElementById("reservationOptionsSearchValueInputTextBox")
              .select();
            this.isSearchValueFocus = false;
          } else {
            this.courseTimeRowSelectProcess(courseIdx, findIdx);
          }
        } else {
          if (
            this.reservationCourseLists.length > 0 &&
            Array.isArray(this.reservationCourseLists[0].reservationLists) &&
            this.reservationCourseLists[0].reservationLists.length > 0
          ) {
            this.courseTimeRowSelectProcess(0, 0);
          }
        }
      }
    },
    courseTimeRowSelectProcess(courseIdx, findIdx) {
      this.reservationCourseDivClicked(courseIdx);
      this.$refs.reservationGrid[courseIdx].selectRow(findIdx);

      const selectedRecords = this.$refs.reservationGrid[courseIdx].getSelectedRecords();
      const eventData = {
        column: {field: "resveName",},
        rowData: selectedRecords[0],
      };

      this.reservationGridRecordClicked(eventData, courseIdx, true);
    },
    onScl(evnt) {
      const top = this.$refs.reservationGrid[evnt.currentTarget.id].getContent().children[0].scrollTop;
      this.$refs.reservationGrid.forEach((data, idx) => {
        if (idx !== Number.parseInt(evnt.currentTarget.id) && idx !== Number.parseInt(this.currentWheel)) {
          this.$refs.reservationGrid[idx].getContent().children[0].scrollTop = top;
        }
      });
    },
    onWhl(evt) {
      //scroll의 순환참조를 막기위해 휠 이벤트도 체크
      this.currentWheel = evt.currentTarget.id;
    },
    async onBlankFlagChanged() {
      if (this.isDetailDataModified()) {
        // 수정여부 체크
        if (
          !this.isReservationDetailModifyMode &&
          !(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))
        ) {
          return;
        }
      }
      // 좌측 grid update
      await this.getReservationStatusList();
    },
    async onAditTeamFlagChanged() {
      if (this.isDetailDataModified()) {
        // 수정여부 체크
        if (
          !this.isReservationDetailModifyMode &&
          !(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))
        ) {
          return;
        }
      }
      // 좌측 grid update
      await this.fetchReservationTimes();
    },
    reservationCourseDivClicked(reservationCourseIdx) {
      console.log('## 그리드 둘러싼 div 영역 클릭됨. IDX:' + reservationCourseIdx);
      this.reservationCourseActiveIndex = reservationCourseIdx;
    },
    reservationHeaderCellInfo(args) {
      const {cell: {column: {field}}, node: {classList, style}} = args;
      if (field.substring(0,9)=="resveName") {
        classList.add(this.$t("className.grid.clickAreaLeft"));
      }
      // else if (field === "resveRemarks") {
      //   classList.add(this.$t("className.grid.clickAreaLeft"));
      // }
      else if (field.substring(0,4) === "memo") {
        classList.add(this.$t("className.grid.memoArea"));
      } else if (field === "cart") {
        classList.add(this.$t("className.grid.cartImage"));
      } else if (["visitCnt","comfirmVisitCnt"].includes(field)) {
        style.paddingLeft = "0";
        style.paddingRight = "0";
      }
    },
    reservationQueryCellInfo(args) {
      const {column: {field}, cell, data,} = args;
      if (data.partDivLineFlag) {
        cell.classList.add(this.$t("className.grid.devReservationPartDivLine"));
      }

      if (field === "no") {
        if (data.agncyOpenFlag) {
          cell.style.backgroundColor = this.commonCodesGetColorValue("COLOR_TIME_STATUS", "AGNCY_OPEN_FLAG");
        } else if (data.coprtnOpenFlag) {
          cell.style.backgroundColor = this.commonCodesGetColorValue("COLOR_TIME_STATUS", "COPRTN_OPEN_FLAG");
        } else if (data.webOpenFlag) {
            cell.style.backgroundColor = this.commonCodesGetColorValue("COLOR_TIME_STATUS", "WEB_OPEN_FLAG");
        }
      } else if(field === 'select') {
        if(["RESVE","JOIN"].includes(data.timeStatus)) {
          args.cell.style.pointerEvents = "none";
        }else if(["HOLD"].includes(data.timeStatus) ) {
          if( data.holdResn.length > 0) {
            cell.classList.add(this.$t("className.grid.modifyArea"));
          }else{
            args.cell.style.pointerEvents = "none";
          }
        }else {
          cell.classList.add(this.$t("className.grid.modifyArea"));
        }
      } else if (field === "resveTime") {
        // 시간 cell styl e
        cell.classList.add(this.$t("className.grid.selectedCellBold"));
        // timeStatus 값이 Black/Block/Holding이면 background 설정 / text color는 흰색으로 설정
        if (data.timeStatus === "BLANK" || data.timeStatus === "BLOCK" || data.timeStatus === "HOLD") {
          args.cell.style.backgroundColor = this.commonCodesGetColorValue("TIME_STATUS", args.data.timeStatus);
          args.cell.style.color = "#FFFFFF";
        }
        // Roy 올림픽에서는 사용 못함 예약별 취소,체크인 다름...
/*        else if (args.data.cancelDiv === "RAIN" || args.data.cancelDiv === "NOSHOW") {
          args.cell.style.backgroundColor = this.commonCodesGetColorValue("CANCEL_DIV", args.data.cancelDiv);
          args.cell.style.color = "#FFFFFF";
        } else if (args.data.chkinStatus === "CHKIN" || args.data.chkinStatus === "PAYING" || args.data.chkinStatus === "CHKOUT") {
          args.cell.style.backgroundColor = commonCodesGetColorValue("CHKIN_STATUS", "CHKIN" /!*args.data.chkinStatus*!/);
        }
*/
      } else if(args.column.field === "selfOpenFlag") {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      } else if (args.column.field === "holeDiv") {
        // 홀 column
          if(data.timeStatus === "EMPTY" ) {
            cell.classList.add(this.$t("className.grid.modifyArea"));
          } else {
            args.cell.style.pointerEvents = "none";
          }
        args.cell.style.backgroundColor = commonCodesGetColorValue("HOLE_DIV", args.data.holeDiv);
      } else if (["resveName0","resveName1","resveName2","resveName3","resveName4"].includes(args.column.field)) {

        const resveNameIdx = args.column.field.substring(9);

        var color ="#000000",backgroundColor="#FFFFFF";

        // Roy 올림픽에서는 사용
        // if(!!args.data.resveId && args.data.roomResveGroupCount > 0) {
        //   args.cell.style.backgroundColor = this.commonCodesGetColorValue("COLOR_MEMBER_DIV", "ROOM_PKG");
        // } else
        if (!!args.data[`resveId${resveNameIdx}`] && ["AGENT", "COPRTN"].includes(commonCodesGetCommonCodeAttrbByCodeAndIdx("RESVE_CHANNEL", args.data.resveChannel, 1, false))) {
          backgroundColor = this.commonCodesGetColorValue("COLOR_TIME_STATUS", "COPRTN_OPEN_FLAG");
        } else if (!!args.data[`resveId${resveNameIdx}`] && args.data.webOpenFlag) {
          backgroundColor = this.commonCodesGetColorValue("COLOR_TIME_STATUS", "WEB_OPEN_FLAG");
        } else if (!!args.data[`grpKind${resveNameIdx}`] && args.data[`grpKind${resveNameIdx}`] !== "FIT") {
          backgroundColor = this.commonCodesGetColorValue("GRP_KIND", args.data[`grpKind${resveNameIdx}`]);
        } else if (args.data[`memberDiv${resveNameIdx}`] !== null && args.data[`memberDiv${resveNameIdx}`] !== undefined) {
          // MEMBER_DIV 의 3번째 index Attribute 로 COLOR_MEMBER_DIV의 colorValue 조회 후 백그라운드 등록
          const attrMemberDiv = commonCodesGetCommonCodeAttrbByCodeAndIdx("MEMBER_DIV", args.data[`memberDiv${resveNameIdx}`], 3);
          backgroundColor= this.commonCodesGetColorValue("COLOR_MEMBER_DIV", attrMemberDiv);
          // 예약자 글자색 설정
          color = this.commonCodesGetColorValue("MEMBER_DIV", args.data[`memberDiv${resveNameIdx}`]);
        }
       let roundVisit = 4 - (args.column.field.toString().substring(9));

        if (args.data[args.column.field]) {
          args.cell.classList.add(this.$t("className.grid.clickAreaLeft"));
        } else {
          if (args.data.joinOpenFlag && ["EMPTY","JOIN","HOLD"].includes(args.data.timeStatus)) {
            if (!this.commonMixinIsButtonDisableByAuth('resveReceiptResveReg')) {
              args.cell.classList.add(this.$t("className.grid.reservArea"));
              if(args.data.joinOpenFlag) {
                //초과 인원 예약은 안보이게..
                if(args.data.visitCnt >= roundVisit) {
                  args.cell.innerText = roundVisit + " 인 예약";
                }
              }else{
                args.cell.innerText = "예약";
              }
            }
          } else if (args.data.timeStatus === "HOLD") {
            if (!args.data.resveRemarks) {
              args.cell.classList.remove(this.$t("className.grid.reservArea"));
              args.cell.style.textAlign = "center";
              args.cell.innerHTML =
                '<div class="e-checkbox-wrapper e-css e-checkbox-disabled"><span class="e-frame e-icons e-check"></span><span class="e-label"></span></div>';
            }
          }
        }

        if (args.data.resveCmpnFlag) {
          args.cell.classList.add(this.$t("className.grid.incomeDeduction"));
        }
        // args.cell.style.backgroundColor = backgroundColor;
        // args.cell.style.color = color;
        if(args.data[`resveName${resveNameIdx}`]) {
          args.colSpan = args.data[`colSpan${resveNameIdx}`];
          // args.cell.innerText = args.data[`resveName${resveNameIdx}`] + ` (${(args.colSpan + 1) / 2}명)`;
          // args.cell.style.borderBottom = "1px solid #F00";
          args.cell.innerHTML = `
            <div
              style="
                width: 100%;
                text-align: center;
                border: 1px solid #ccc;
                /*border-radius: 9px;*/
                line-height: 18px;
                font-weight: bold;
                background-color: ${backgroundColor};
                color:${color};
              "
            >${args.data[`resveName${resveNameIdx}`]}  (${(args.colSpan + 1) / 2}명) </div>`;
        }
      }
      // else if (args.column.field === "resveRemarks") {
      //   if (args.data.resveRemarks) {
      //     args.cell.classList.add(this.$t("className.grid.clickAreaLeft"));
      //   }
      // }
      else if(args.column.field === "selfOpenFlag") {
        const selfFlag = args.data?.reservationConfirm ? args.data.reservationConfirm.selfFlag : args.data.selfOpenFlag;
        if(args.data.timeDiv === "CADDIE" && selfFlag) {
          args.cell.style.backgroundColor = this.commonCodesGetColorValue("TIME_DIV", args.data.timeDiv);
        }
      } else if (args.column.field.substring(0,4) === "memo") {
        const memoIdx = args.column.field.substring(4);
        // 메모가 있는 경우에는 클래스 추가
        if (args.data[`memoFlag${memoIdx}`]) {
          if (args.data[`newMemoFlag${memoIdx}`] && commonCodesGetStandardInfo("memoConfirmFlag")) {
            args.cell.classList.add(this.$t("className.grid.newMemoArea"));
          } else {
            args.cell.classList.add(this.$t("className.grid.memoArea"));
          }
        }
      }
      //roy 올림픽 리무진 없음...
      // else if (args.column.field === "cart") {
      //   // 리무진일때만 보여주기
      //   if(args.data.cartKind === "LIMOUSINE"){
      //     args.cell.classList.add(this.$t("className.grid.cartImage"));
      //   }
      // }
      else if (args.column.field === "paymtPrice") {
        if(args.data.colorPaymt) {
          args.cell.style.color = "rgb(200,0,0)";
        }
      } else if (["comfirmVisitCnt","visitCnt"].includes(args.column.field)) {
        let color = "#222";
        if (args.data.comfirmVisitCnt <= 3) {
          color = "#f82f2f";
        }

        args.cell.style.padding = "0px 12px 0px 8px"; // Grid Width 값 변경되는 경우 Box가 글자보다 Width 값이 작아지는 것 때문에 padding 값 조절.

        // 인원 항목에 Box 생성.
        args.cell.innerHTML = `
            <div
              style="
                width: 100%;
                text-align: center;
                border: 1px solid #ccc;
                /*border-radius: 9px;*/
                line-height: 18px;
                font-weight: bold;
                color: ${color};
              "
            >${args.data[args.column.field]||0}인</div>
        `;
      }
      // else if (args.column.field === "G") {
      //   // G(단체)필드 배경색 설정
      //   args.cell.style.backgroundColor = this.commonCodesGetColorValue(
      //     "GRP_KIND",
      //     args.data.grpKind
      //   );
      // } else if (args.column.field === "T") {
      //   // 팀 배경색 설정 우천취소여부
      //   args.cell.style.backgroundColor = this.commonCodesGetColorValue(
      //     "CANCEL_DIV",
      //     args.data.cancelDiv
      //   );
      // }
    },
    async reservationGridRecordClicked(args, reservationCourseIdx, refreshFlag = false) {
      // TODO : timeStatus 가 EMPTY 일 때 (빈 타임 삭제)

      const {timeId, timeStatus } = args.rowData;
        if (args.column.field.substring(0,4) === "memo") {
          const memoIdx = args.column.field.substring(4);
          // console.log(args.rowData[`resveId${memoIdx}`]);
          if (args.rowData[`resveId${memoIdx}`]) {
            await this.setResveData(args.rowData[`resveId${memoIdx}`]);
            this.$EventBus.$emit("memoShortcutButtonClick", true); // 메모창 열기
          }
          return;
        }

        // 다른 그리드 selection 해제
        const courseLength = this.reservationCourseLists.length;
        for (let loopCourseIdx = 0; loopCourseIdx < courseLength; loopCourseIdx++) {
          if (loopCourseIdx !== reservationCourseIdx) {
            this.$refs.reservationGrid[loopCourseIdx].clearSelection();
          }
        }

        if (["resveName0","resveName1","resveName2","resveName3","resveName4"].includes(args.column.field)) {  //|| (args.column.field === "resveRemarks" && resveRemarks)
          this.resveNamefield = args.column.field;
          if (!this.isReservationDetailModifyMode && this.isDetailDataModified() && !refreshFlag) {
            // 수정여부 체크
            if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
              return;
            }
          }
          // 초과 인원은 예약을 하지 못한다.
          if (args.cell.innerText.length==0) {
              return;
          }
          await this.getReservationStatusList();
          this.selectedField = args.column.field;
          await this.openDetailView(args.rowData, false, args.column.field);
          // }
        } else if (args.column.field === "grpName") {
          if (timeStatus === "HOLD") {
            this.isHoldTimeViewPopupOpen = true;
            this.$nextTick(() => {
              this.$refs.holdTimeViewPopup.showHoldTimeViewPopup(timeId);
            });
          }
        }
    },
    cancelReservation() {
      if (Array.isArray(this.reservationDetailInfo.reservationTimeLists)) {
        const notSavedTimeIdx = this.reservationDetailInfo.reservationTimeLists.findIndex((timeInfo) => !timeInfo.resveId);
        if (notSavedTimeIdx !== -1) {
          this.errorToast("저장하지 않은 예약 타임이 있습니다. 먼저 저장해 주세요");
        } else {
          // 예약건이 1건인 경우에는 해당 건을 선택하여 바로 예약 팝업을 Open. 그 외에는 예약을 선택해야 함
          if (this.reservationDetailInfo.reservationTimeLists.length === 1 || this.reservationDetailCancelTimeIds.length > 0) {
            if (this.reservationDetailInfo.reservationTimeLists.length === 1) {
              if (this.reservationDetailInfo?.reservationTimeLists[0]?.reservationConfirm?.resveStatus !== "RESVE") {
                this.infoToast("취소 가능한 예약상태가 아닙니다");
                return;
              }
              this.reservationCancelPopupOpen([this.reservationDetailInfo.reservationTimeLists[0].timeId,]);
            } else {
              this.reservationCancelPopupOpen(this.reservationDetailCancelTimeIds);
            }
          } else {
            this.errorToast("취소하실 예약 타임을 선택해 주세요");
          }
        }
      }
    },
    roomReservationGridHeaderCellInfo(args) {
      const {cell: {column: {field}}, node} = args;
      if (field === "dropdownMenu") {
        node.classList.add(this.$t("className.grid.contextMenuArea"));
      }
    },
    onRoomReservationGridMenuSelected(args) {
      if(args.menu === "moveRoom") {
        let routeData = this.$router.resolve({
          name: "RoomReservationRegistration",
          query: {
            stayId:args.data.stayId,
            bsnDate:args.data.arrivalDate,
          },
        });
        openNewWindow(routeData);
      } else if(args.menu === "cancelRoom") {
        this.isDeleteConfirmPopup = true;
        this.$nextTick(()=>{
          this.$refs.deleteConfirmPopup.showPopup(args,{
                headerMessage: "객실 PKG 취소",
                isDeleteButton: true,
                buttonAuthNameByReg: "resveReceiptResveDel",
          });
        });
      }
    },
    async postDeleteRoomPkg( { data:list } ,delReason) {
      const args = {...list, delReason};
      await GolfErpAPI.deleteGolfPackageLink(args);
      this.infoToast("삭제되었습니다");
      await this.fetchReservationTimes();
    },
    deleteConfirmPopupClosed (){
      this.isDeleteConfirmPopup = false;
    },
    getReservationCompanionsGridRefs(timeInfoIdx) {
      return this.$refs[`reservationCompanionsGrid${timeInfoIdx}`][0];
    },
    async reservationCompanionSmsSend(timeInfo, timeInfoIdx) {
      const gridRefs = this.getReservationCompanionsGridRefs(timeInfoIdx);
      const {addedRecords, changedRecords} = gridRefs.getBatchChanges();

      if (addedRecords.length > 0 || changedRecords.length > 0) {
        this.infoToast("동반자정보가 저장되지 않았습니다.<br>저장후 이용해주시기 바랍니다");
        return;
      }

      const selectedCmpns = gridRefs.getSelectedRecords();

      if (!(selectedCmpns.length > 0)) {
        this.infoToast("동반자를 선택해 주시기 바랍니다");
        return;
      }

      if (!selectedCmpns[0].contactTel) {
        this.infoToast("선택한 동반자의 연락처가 존재하지 않습니다");
        return;
      }

      if (!(await this.confirm("동반자 SMS를 전송하시겠습니까?"))) {
        return;
      }

      const smsSendInfo = {
        type: "CMPN_RESVE",
        bizName: commonCodesGetStandardInfo("bizName"),
        bizNameLocation: commonCodesGetStandardInfo("bizNameLocation"),
        kakaoSendFlag: false,
        smsKind: null,
        kakaoDispatchKey: commonCodesGetStandardInfo("kakaoDsptchKey"),
        kakaoTemplateCode: null,
        subject: null,
      };

      const smsWords = await GolfErpAPI.fetchAllSmsWords();

      const smsSendType = smsWords.find(
        (data) => data.smsSendType === smsSendInfo.type
      );

      let message =
        (smsSendType.smsWords != null ? smsSendType.smsWords : "") +
        (this.noneChkinUseFlag? "\n" + commonCodesGetStandardInfo("noneChkinSmsWords") : "") +
        (smsSendType.aditWords != null ? "\n" + smsSendType.aditWords : "");

      if (smsSendInfo.bizNameLocation === "DOWN") {
        message =
          message +
          (smsSendInfo.bizName != null ? "\n" + smsSendInfo.bizName : "");
      }

      smsSendInfo.kakaoSendFlag = smsSendType.kakaoSendFlag;
      smsSendInfo.kakaoTemplateCode = smsSendType.kakaoTmplatCode;
      smsSendInfo.smsKind = smsSendType.smsKind;
      smsSendInfo.subject = smsSendType.smsSendTypeName;

      let smsRequestList = [];
      selectedCmpns.forEach((data) => {
        if (this.reservationDetailInfo.reservationInfo.grpNo || (data.cmpnName !== this.reservationDetailInfo.reservationInfo.resveName && data.contactTel !== this.reservationDetailInfo.reservationInfo.contactTel)) {
          let smsInfo = {
            type: smsSendInfo.type,
            kakaoFlag: smsSendInfo.kakaoSendFlag,
            smsKind: smsSendInfo.kakaoSendFlag ? smsSendInfo.smsKind : null,
            kakaoDispatchKey: smsSendInfo.kakaoSendFlag ? smsSendInfo.kakaoDispatchKey : null,
            kakaoTemplateKey: smsSendInfo.kakaoSendFlag ? smsSendInfo.kakaoTemplateCode : null,
            key: data.pkData,
            reservedFlag: false,
            reserveDateTime: null,
            receiverName: data.cmpnName,
            receiverNumber: data.contactTel,
            subject: smsSendInfo.subject,
            message: message,
            replaceDataMap: {
              name: this.reservationDetailInfo.reservationInfo.resveName + " " + commonCodesGetCommonCodeAttrbNameByCodeAndIdx("MEMBER_DIV", this.reservationDetailInfo.reservationInfo.memberDiv, 1),
              resveDate: timeInfo.resveDate,
              dwName: commonCodesGetComName("DW_CODE", timeInfo.dwCode),
              resveCourseTime: commonCodesGetComName("COURSE_CODE", timeInfo.resveCourse) + " " + timeInfo.resveTime + " (1팀)",
              resveCourse: commonCodesGetComName("COURSE_CODE", timeInfo.resveCourse) + "코스",
              resveTime: timeInfo.resveTime,
              transResveDiv: "예약",
              headerMemo: smsSendInfo.bizNameLocation === "UP" ? (smsSendInfo.bizName ? smsSendInfo.bizName : null) : null,
              transInfo: data.transInfo || "",
              grpName: data.grpName,
            },
            dispatchNumber: smsSendType.dsptchNo,
            sender: "ERP",
            timeId: (this.noneChkinUseFlag  ? timeInfo.timeId : null),
            urlName: (this.noneChkinUseFlag  ? data.cmpnName : null),
            urlContactTel: (this.noneChkinUseFlag  ? data.contactTel : null),
          };

          smsRequestList.push(smsInfo);
        }
      });

      smsRequestList = smsRequestList.filter(data => data.receiverNumber !== "01000000000");

      if (!(smsRequestList.length > 0)) {
        this.infoToast("SMS 전송이 가능한 데이터가 없습니다");
        return;
      }

      await GolfErpAPI.sendSMS(smsRequestList);

      this.infoToast("SMS전송이 완료되었습니다");
    },
    async autoSendSms(isSmsSendFlag) {
      if (isSmsSendFlag) {
        let smsSendList = [];
        await this.setSendSmsData()
          .then((response) => {
            smsSendList = response;
          })
          .catch((error) => {
            console.log("setSendSmsData.===>", error);
          });

        if (!(smsSendList.length > 0)) {
          this.errorToast("SMS 전송할 연락처가 존재하지 않습니다");
          return;
        }

        const smsSendInfo = {
          type: !["FIT"].includes(this.reservationDetailInfo.reservationInfo.grpKind) ? "G_RESVE_GROUP" : "G_RESVE",
          bizName: commonCodesGetStandardInfo("bizName"),
          bizNameLocation: commonCodesGetStandardInfo("bizNameLocation"),
          kakaoSendFlag: false,
          smsKind: null,
          kakaoDispatchKey: commonCodesGetStandardInfo("kakaoDsptchKey"),
          kakaoTemplateCode: null,
          subject: null,
        };
        const smsWords = await GolfErpAPI.fetchAllSmsWords();

        const smsSendType = smsWords.find(
          (data) => data.smsSendType === smsSendInfo.type
        );

        let message =
          (smsSendType.smsWords != null ? smsSendType.smsWords : "") +
          (this.noneChkinUseFlag  ? "\n" + commonCodesGetStandardInfo("noneChkinSmsWords") : "") +
          (smsSendType.aditWords != null ? "\n" + smsSendType.aditWords : "");

        if (smsSendInfo.bizNameLocation === "DOWN") {
          message = message + (smsSendInfo.bizName != null ? "\n" + smsSendInfo.bizName : "");
        }

        smsSendInfo.kakaoSendFlag = smsSendType.kakaoSendFlag;
        smsSendInfo.kakaoTemplateCode = smsSendType.kakaoTmplatCode;
        smsSendInfo.smsKind = smsSendType.smsKind;
        smsSendInfo.subject = smsSendType.smsSendTypeName;

        let smsRequestList = [];

        let timeId;
        if(this.isGroupReservation){
          this.reservationDetailInfo.reservationTimeLists.map((time) => {
            return {
              companions: time.reservationCompanions,
              timeId: time.timeId,
            };
          }).map(item => {
            if(item.companions?.find(cp =>
                cp.cmpnName === this.reservationDetailInfo.reservationInfo.contactName &&
                cp.membershipId === this.reservationDetailInfo.reservationInfo.membershipId
            )) {
              timeId = item.timeId;
            }
          });
        }
        if(!timeId) {
          timeId = this.reservationDetailInfo.reservationTimeLists[0].timeId;
        }
        smsSendList.forEach((data) => {
          if (data.smsSendFlag) {
            let smsInfo = {
              type: smsSendInfo.type,
              kakaoFlag: smsSendInfo.kakaoSendFlag,
              smsKind: smsSendInfo.kakaoSendFlag ? smsSendInfo.smsKind : null,
              kakaoDispatchKey: smsSendInfo.kakaoSendFlag ? smsSendInfo.kakaoDispatchKey : null,
              kakaoTemplateKey: smsSendInfo.kakaoSendFlag ? smsSendInfo.kakaoTemplateCode : null,
              key: data.sendKey,
              reservedFlag: false,
              reserveDateTime: null,
              receiverName: data.receiverName,
              receiverNumber: data.contactTel,
              subject: smsSendInfo.subject,
              message: message,
              replaceDataMap: {
                name: data.name,
                resveDate: data.resveDate,
                dwName: data.dwName,
                resveCourse: data.resveCourse,
                resveTime: data.resveTime,
                resveCourseTime: data.resveCourseTime,
                transResveDiv: "예약",
                headerMemo: smsSendInfo.bizNameLocation === "UP" ? (smsSendInfo.bizName ? smsSendInfo.bizName : null) : null,
                cancelDate: data.cancelDate,
                cancelTime: data.cancelTime,
                cancelDw: data.cancelDw,
                transInfo: data.transInfo || "",
                grpName: data.grpName,
                caddieSelfDiv: data.caddieSelfDiv,
                teamCnt: data.teamCnt,
                holeDiv: data.holeDiv,
                visitCnt: data.visitCnt,
              },
              dispatchNumber: smsSendType.dsptchNo,
              sender: "ERP",
              //TODO: SMS
              timeId: (this.noneChkinUseFlag  ? timeId : null),
              urlName: (this.noneChkinUseFlag  ? this.reservationDetailInfo?.reservationInfo?.resveName : null),
              urlContactTel: (this.noneChkinUseFlag  ? this.reservationDetailInfo?.reservationInfo?.contactTel : null),
            };
            smsRequestList.push(smsInfo);
          }
        });

        smsRequestList = smsRequestList.filter(data => data.receiverNumber !== "01000000000");

        if (!(smsRequestList.length > 0)) {
          return;
        }

        await GolfErpAPI.sendSMS(smsRequestList);

        this.infoToast("SMS전송이 완료되었습니다");
      }
    },
    async setSendSmsData() {
      const reservationDetailInfo = JSON.parse(JSON.stringify(this.reservationDetailInfo));
      const selectedRowData = JSON.parse(JSON.stringify(this.selectedRowData));
      const reservationCourseLists = JSON.parse(JSON.stringify(this.reservationCourseLists));
      const groupInfo = reservationDetailInfo.reservationInfo.grpNo ? (await getGroupInfoDetail(reservationDetailInfo.reservationInfo.grpNo))?.value : null;

      return new Promise(function (resolve) {
        const reservationInfo = reservationDetailInfo.reservationInfo;
        const reservationTimeList = reservationDetailInfo.reservationTimeLists;

        let smsSendList = [];
        let sendData = {};

        sendData.sendKey = reservationInfo.resveNo;
        sendData.resveId = reservationInfo.resveId;
        sendData.grpNo = reservationInfo.grpNo;
        sendData.resveCourse = `${commonCodesGetComName("COURSE_CODE", selectedRowData.resveCourse)}코스`;
        sendData.resveTime = selectedRowData.resveTime;
        sendData.transFlag = false;
        sendData.transResveDiv = "예약";
        sendData.grpName = reservationInfo.grpName;
        sendData.caddieSelfDiv = reservationTimeList[0]?.selfFlag ? "셀프" : "동반";

        const weekdayCncde = commonCodesGetStandardInfo("weekdayCncde");
        const weekendCncde = commonCodesGetStandardInfo("weekendCncde");
        const weekdayCnctime = commonCodesGetStandardInfo("weekdayCnctime");
        const weekendCnctime = commonCodesGetStandardInfo("weekendCnctime");

        if (reservationInfo.grpKind === "FIT") {
          sendData.resveDate = selectedRowData.resveDate;
          sendData.dwName = getDayOfWeekCaption(
            moment(selectedRowData.resveDate).toDate()
          );
          sendData.resveCourseTime =
            commonCodesGetComName("COURSE_CODE", selectedRowData.resveCourse) + " " + selectedRowData.resveTime + " (1팀)";
          sendData.cancelDate = moment(selectedRowData.resveDate).add(selectedRowData.bsnCode === "WEEKDAY" ? weekdayCncde * -1 : weekendCncde * -1, "d").format("YYYY-MM-DD");
          sendData.cancelTime = selectedRowData.bsnCode === "WEEKDAY" ? weekdayCnctime : weekendCnctime;
          sendData.cancelDw = getDayOfWeekCaption(moment(sendData.cancelDate).toDate());
          sendData.holeDiv = selectedRowData.holeDiv;
          sendData.visitCnt = selectedRowData.visitCnt;
        } else {
          let resveDateArr = [];
          let dwnameArr = [];
          reservationTimeList.forEach(data => {
            if (!resveDateArr.includes(data.resveDate)) {
              resveDateArr.push(data.resveDate);
              dwnameArr.push(getDayOfWeekCaption(moment(data.resveDate).toDate()));
            }
          });

          const minResveDate = resveDateArr.reduce((previous, current) => {
            return previous > current ? current : previous;
          });
          const minBsnCode = reservationTimeList.find(data => data.resveDate === minResveDate)?.bsnCode;

          sendData.resveDate = resveDateArr.join(", ");
          sendData.dwName = dwnameArr.join(", ");
          // sendData.cancelDate = moment(selectedRowData.resveDate).subtract(2, "weeks").subtract(1, "days").format("YYYY-MM-DD");
          sendData.cancelDate = moment(selectedRowData.resveDate).add(selectedRowData.bsnCode === "WEEKDAY" ? weekdayCncde * -1 : weekendCncde * -1, "d").format("YYYY-MM-DD");
          sendData.cancelTime = minBsnCode === "WEEKDAY" ? weekdayCnctime : weekendCnctime;
          sendData.cancelDw = getDayOfWeekCaption(
            moment(sendData.cancelDate).toDate()
          );
          sendData.resveCourseTime = `${reservationCourseLists
            .map(({resveCourseCode, resveCourseName}) => ({
              resveCourseName,
              reservationTimeList: reservationTimeList.filter((timeInfo) => timeInfo.resveCourse === resveCourseCode),
            }))
            .filter(({reservationTimeList = []}) => reservationTimeList.length !== 0)
            .map(({resveCourseName, reservationTimeList}) => `${resveCourseName} ${reservationTimeList.map(({resveTime}) => resveTime).join(", ")}`)
            .join(", ")}`;
          sendData.resveTime = reservationTimeList.map(item => `[${item.resveTime}]`).join(" ");
          sendData.teamCnt = `총 ${reservationTimeList.length}팀`;
        }

        if (reservationInfo.smsRecptnDiv === "B") {
          if (reservationInfo.resveMngrHp) {
            sendData.receiverName = reservationInfo.resveName;
            sendData.name = reservationInfo.resveName + " " + commonCodesGetCommonCodeAttrbNameByCodeAndIdx("MEMBER_DIV", reservationInfo.memberDiv, 1);
            sendData.contactTel = reservationInfo.resveMngrHp;
            sendData.smsSendFlag = true;

            smsSendList.push(JSON.parse(JSON.stringify(sendData)));
          }
        } else if (reservationInfo.smsRecptnDiv === "A") {
          sendData.receiverName = reservationInfo.resveName;
          sendData.name = reservationInfo.resveName + " " + commonCodesGetCommonCodeAttrbNameByCodeAndIdx("MEMBER_DIV", reservationInfo.memberDiv, 1);

          if (reservationInfo.contactTel) {
            sendData.contactTel = reservationInfo.contactTel;
            sendData.smsSendFlag = true;

            smsSendList.push(JSON.parse(JSON.stringify(sendData)));
          }

          if (reservationInfo.resveMngrHp) {
            sendData.contactTel = reservationInfo.resveMngrHp;
            sendData.smsSendFlag = true;

            smsSendList.push(JSON.parse(JSON.stringify(sendData)));
          }
        } else {
          if (reservationInfo.contactTel) {
            sendData.receiverName = reservationInfo.resveName;
            if(reservationInfo.memberDiv === "NOM"){
              sendData.name = "고객" + commonCodesGetCommonCodeAttrbNameByCodeAndIdx("MEMBER_DIV", reservationInfo.memberDiv, 1);
            }else {
              sendData.name = reservationInfo.resveName + " " + commonCodesGetCommonCodeAttrbNameByCodeAndIdx("MEMBER_DIV", reservationInfo.memberDiv, 1);
            }
            sendData.contactTel = reservationInfo.contactTel;
            sendData.smsSendFlag = true;

            smsSendList.push(JSON.parse(JSON.stringify(sendData)));
          }

          if (reservationInfo.resveMngrHp) {
            sendData.contactTel = reservationInfo.resveMngrHp;
            sendData.smsSendFlag = true;

            smsSendList.push(JSON.parse(JSON.stringify(sendData)));
          }
        }

        if (groupInfo) {
          if (
            groupInfo.chairContactTel
            && smsSendList.findIndex(item => item.contactTel === groupInfo.chairContactTel) === -1
          ) {
            sendData.contactTel = groupInfo.chairContactTel;
            sendData.smsSendFlag = true;

            smsSendList.push(JSON.parse(JSON.stringify(sendData)));
          }
          if (
            groupInfo.generContactTel
            && smsSendList.findIndex(item => item.contactTel === groupInfo.generContactTel) === -1
          ) {
            sendData.contactTel = groupInfo.generContactTel;
            sendData.smsSendFlag = true;

            smsSendList.push(JSON.parse(JSON.stringify(sendData)));
          }
          if (groupInfo.grpMemberList.length > 0) {
            for (const grpMember of groupInfo.grpMemberList) {
              if (
                grpMember.hpNo
                && grpMember.smsRecptnFlag
                && smsSendList.findIndex(item => item.contactTel === grpMember.hpNo) === -1
              ) {
                sendData.contactTel = grpMember.hpNo;
                sendData.smsSendFlag = true;

                smsSendList.push(JSON.parse(JSON.stringify(sendData)));
              }
            }
          }
        }

        reservationTimeList.forEach((timeInfo) => {
          if (
            timeInfo.transName !== null &&
            timeInfo.transName !== undefined &&
            timeInfo.transName.trim() !== ""
          ) {
            if (timeInfo.contactTel) {
              sendData.receiverName = timeInfo.transName;
              sendData.name = reservationInfo.resveName + " " + commonCodesGetCommonCodeAttrbNameByCodeAndIdx("MEMBER_DIV", reservationInfo.memberDiv, 1);
              sendData.contactTel = timeInfo.contactTel;
              sendData.smsSendFlag = true;
              sendData.transFlag = true;

              smsSendList.push(JSON.parse(JSON.stringify(sendData)));
            }

            smsSendList.forEach(item => {
              item.transInfo = `◎ 위임자 : ${timeInfo.transName}`;
              item.name = item.name + "(" + timeInfo.transName + " " + commonCodesGetCommonCodeAttrbNameByCodeAndIdx("MEMBER_DIV", timeInfo.memberDiv, 1) + ")";
            });
          }
        });

        resolve(smsSendList);
      });
    },
    async sendSMS() {
      if (this.reservationDetailInfo.reservationInfo.resveNo) {
        if (this.reservationDetailInfo.reservationTimeLists.filter(item => !item.resveId).length > 0) {
          this.errorToast("저장되지 않은 예약타임이 존재합니다. 저장 후 SMS를 전송해주시기 바랍니다");
          return;
        }
        let smsSendList = [];
        await this.setSendSmsData()
          .then((response) => {
            smsSendList = response;
          })
          .catch((error) => {
            console.log("setSendSmsData.===>", error);
          });

        if (!(smsSendList.length > 0)) {
          this.errorToast("SMS 전송할 연락처가 존재하지 않습니다");
          return;
        }

        if (this.noneChkinUseFlag  && !this.isReservationDetailModifyMode) {
          smsSendList[0].urlName = this.reservationDetailInfo.reservationInfo.resveName;

          let urlResveId;
          if(this.isGroupReservation){
            urlResveId = this.reservationDetailInfo.reservationTimeLists.map(time => time.reservationCompanions)?.flat().find(
                cp => cp.cmpnName === this.reservationDetailInfo.reservationInfo.contactName &&
                      cp.membershipId === this.reservationDetailInfo.reservationInfo.membershipId
            )?.resveId;
          }
          if(!urlResveId) {
            urlResveId = this.reservationDetailInfo.reservationTimeLists[0].resveId;
          }
          smsSendList[0].urlResveId = urlResveId;
        }

        this.isReservationSmsSendPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.reservationSmsSendPopup.showReservationSmsSendPopup(
            smsSendList,
            !["FIT"].includes(this.reservationDetailInfo.reservationInfo.grpKind) ? "G_RESVE_GROUP" : "G_RESVE",
            true,
            true
          );
        });
      } else {
        this.errorToast("SMS 전송할 자료가 존재하지 않습니다");
        return;
      }
    },
    reservationSmsSendPopupClose() {
      this.isReservationSmsSendPopupOpen = false;
    },
    holdTimeViewPopupClose(args) {
      this.isHoldTimeViewPopupOpen = false;

      if (args.isSaved) {
        this.searchReservation();
      }
    },
    async viewHistory() {
      if (this.selectedDataForHistory.resveId) {
        const { resveId: reservationId } = this.selectedDataForHistory;

        const rawHistories = await GolfErpAPI.fetchReservationHistory(
          reservationId
        );

        this.isHistoryPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.historyPopup.show({
            rawHistories,
            fields: {
              Reservation: {
                __name__: "예약확정정보",
                reservationCourse: {
                  name: "코스",
                  type: "commonCode",
                  groupCode: "COURSE_CODE",
                  methods: ["create", "update", "delete"],
                },
                reservationTime: {
                  name: "예약타임",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                promotionName: {
                  name: "프로모션",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                number: {
                  name: "예약번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                status: {
                  name: "예약상태",
                  type: "commonCode",
                  groupCode: "RESVE_STATUS",
                  methods: ["create", "update", "delete"],
                },
                visitorCount: {
                  name: "내장인원수",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                discountPaymentCode: {
                  name: "할인요금코드",
                  type: "commonCode",
                  groupCode: "DC_PAYMT_CODE",
                  methods: ["create", "update", "delete"],
                },
                teamGreenFee: {
                  name: "팀그린피",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                couponNumber: {
                  name: "쿠폰번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                isSelfCaddie: {
                  name: "셀프캐디",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
                isJoined: {
                  name: "조인신청",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
                applyPenalty: {
                  name: "위약적용여부",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
                penaltyCode: {
                  name: "위약코드",
                  type: "commonCode",
                  groupCode: "PENLTY_CODE",
                  methods: ["create", "update", "delete"],
                },
                cancelDivision: {
                  name: "취소구분",
                  type: "commonCode",
                  groupCode: "CANCEL_DIV",
                  methods: ["create", "update", "delete"],
                },
                cancelChannel: {
                  name: "취소채널",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                cancelDate: {
                  name: "취소일자",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                cancelDayCount: {
                  name: "취소일수",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                cancelReason: {
                  name: "취소사유",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
              },
              Booker: {
                __name__: "예약인적정보",
                reservationNumber: {
                  name: "예약번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                reservationKind: {
                  name: "예약종류",
                  type: "commonCode",
                  groupCode: "RESVE_KIND",
                  methods: ["create", "update", "delete"],
                },
                reservationChannel: {
                  name: "예약채널",
                  type: "commonCode",
                  groupCode: "RESVE_CHANNEL",
                  methods: ["create", "update", "delete"],
                },
                name: {
                  name: "예약자명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                membershipId: {
                  name: "회원권ID",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                memberNumber: {
                  name: "회원번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                memberDivision: {
                  name: "회원구분",
                  type: "commonCode",
                  groupCode: "MEMBER_DIV",
                  methods: ["create", "update", "delete"],
                },
                memberGrade: {
                  name: "회원등급",
                  type: "commonCode",
                  groupCode: "MEMBER_GRADE",
                  methods: ["create", "update", "delete"],
                },
                gender: {
                  name: "성별",
                  type: "commonCode",
                  groupCode: "SEX_CODE",
                  methods: ["create", "update", "delete"],
                },
                areaCode: {
                  name: "지역코드",
                  type: "commonCode",
                  groupCode: "AREA_CODE",
                  methods: ["create", "update", "delete"],
                },
                contactName: {
                  name: "연락자명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                contactTel: {
                  name: "연락처",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                groupNumber: {
                  name: "단체번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                groupKind: {
                  name: "단체종류",
                  type: "commonCode",
                  groupCode: "GRP_KIND",
                  methods: ["create", "update", "delete"],
                },
                groupName: {
                  name: "단체명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                reservationRemarks: {
                  name: "예약비고",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                recommender: {
                  name: "추천인",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                bsnEmployeeName: {
                  name: "영업사원명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
              },
              ReservationCompanion: {
                __name__: (type, history) =>
                  `예약동반자정보 (${history?.record?.companionName})`,
                number: {
                  name: "순번",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                isRepresentative: {
                  name: "대표여부",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
                companionName: {
                  name: "동반자명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                joinGroupNumber: {
                  name: "조인그룹번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                membershipId: {
                  name: "회원권ID",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                memberNumber: {
                  name: "회원번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                memberDivision: {
                  name: "회원구분",
                  type: "commonCode",
                  groupCode: "MEMBER_DIV",
                  methods: ["create", "update", "delete"],
                },
                memberGrade: {
                  name: "회원등급",
                  type: "commonCode",
                  groupCode: "MEMBER_GRADE",
                  methods: ["create", "update", "delete"],
                },
                gender: {
                  name: "성별",
                  type: "commonCode",
                  groupCode: "SEX_CODE",
                  methods: ["create", "update", "delete"],
                },
                contactTel: {
                  name: "연락처",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                lockerNumber: {
                  name: "락카번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
              },
              ReservationDelegator: {
                __name__: "예약위임자정보",
                name: {
                  name: "위임자명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                membershipId: {
                  name: "회원권ID",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                memberNumber: {
                  name: "회원번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                memberDivision: {
                  name: "회원구분",
                  type: "commonCode",
                  groupCode: "MEMBER_DIV",
                  methods: ["create", "update", "delete"],
                },
                memberGrade: {
                  name: "회원등급",
                  type: "commonCode",
                  groupCode: "MEMBER_GRADE",
                  methods: ["create", "update", "delete"],
                },
                gender: {
                  name: "성별",
                  type: "commonCode",
                  groupCode: "SEX_CODE",
                  methods: ["create", "update", "delete"],
                },
                contactTel: {
                  name: "연락처",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
              },
            },
          });
        });
      } else {
        this.errorToast("예약정보가 없습니다");
      }
    },
    historyPopupClose() {
      this.isHistoryPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기
    },
    reservationGriRowDataBound(args) {
      if (this.isReservationDetailModifyMode && this.isTimeCanAdd(args.data.timeStatus, args.data.holdInsertId) && !this.isTimeIdInReservationDetailInfo(args.data.timeId)) {
        // args.row.classList.add(this.$t('className.grid.reservationAddModeRow')) --> 이렇게 하면 Grid row가 모두 사라져버리므로 어쩔 수 없이 상수를 박았음
        args.row.classList.add("dev-reservation-add-mode-row");
      }
    },
    isTimeIdInReservationDetailInfo(timeId) {
      if (
        Array.isArray(this.reservationDetailInfo.reservationTimeLists) &&
        this.reservationDetailInfo.reservationTimeLists.findIndex(
          (time) => time.timeId === timeId
        ) >= 0
      ) {
        return true;
      }
      return false;
    },
    async openDetailView(data, isReservationListRefresh = false, clickField) {
      // 체크박스 체크상태 clear
      this.reservationDetailCancelTimeIds = [];
      this.reservationViewOptions.reservationDetailCancelTimeIdCheckboxAll = false;

      if (this.isReservationDetailModifyMode) {
        if (!this.commonMixinIsButtonDisableByAuth('resveReceiptResveReg')) {
          // 추가 On 모드
          await this.addReservationTime(data.timeId,null, clickField);
        }
      } else {
        // refresh "resveName","resveName1","resveName2","resveName3","resveName4"
        let resveNo = null;
        if(clickField == "resveName0"){
          resveNo = data.resveNo0;
        }else if(clickField == "resveName1"){
          resveNo = data.resveNo1;
        }else if(clickField == "resveName2"){
          resveNo = data.resveNo2;
        }else if(clickField == "resveName3"){
          resveNo = data.resveNo3;
        }else if(clickField == "resveName4"){
          resveNo = data.resveNo4;
        }
        await this.refreshDetailInfo(resveNo, data.timeId, true, data.timeStatus, data.holdInsertId, data.resveDate, true, clickField);
      }

      // 현재 selected된 row를 임시저장
      this.selectedRowData = data;

      if (isReservationListRefresh) {
        this.fetchReservationTimes();
      }

      // 예약타임 목록 자동 Select 포커싱
      if (this.reservationDetailInfo.reservationTimeLists.length > 0) {
        const findIndex = this.reservationDetailInfo.reservationTimeLists.findIndex(
          (item) => item.timeId === data.timeId
        );
        this.onReservationDetailViewSelect(this.reservationDetailInfo.reservationTimeLists[findIndex], findIndex);
      }

      // 예약자명 focusing
      this.$nextTick(() => {
        if (this.isReservationDetailViewOpened) {
          if (!this.isReservationDetailModifyMode) {
            setTimeout(() => {
              if (this.isSearchValueFocus) {
                this.isSearchValueFocus = false;
                this.$refs.searchValue.focusIn();
              } else {
                this.$refs.resveName.focusIn();
              }
            }, 500);
          }
        }
      });
    },
    async addReservationTime(timeId,resveNo = null, clickField) {
      if (this.reservationDetailInfo.reservationTimeLists.findIndex((reservation) => reservation.timeId === timeId) !== -1) {
        this.errorToast("이미 추가하셨습니다");
        return;
      }
      try {
         const responseValue = await this.fetchReservationTimesDetail(timeId, resveNo, clickField);
        if (!responseValue) {
          for (let i = 0; i < this.reservationCourseLists.length; i++) {
            this.$refs.reservationGrid[i].clearSelection();
          }
          return;
        }
        // console.log(responseValue);
        if (this.reservationDetailInfo.reservationTimeLists && Array.isArray(this.reservationDetailInfo.reservationTimeLists)) {
          // 처음으로 추가하는 경우임. 초기화 작업
          // if (this.reservationDetailInfo.reservationTimeLists.length === 0) {
            this.reservationDetailInfoOriginal = {}; // 비교를 위한 original data 초기화
            this.prevModifyReservationDetailInfo = {
              // 수정된 데이터 비교를 위한 초기화
              reservationInfo: {},
              reservationTimeLists: [],
            };
            this.setDetailActiveTimeAndSetDetailView(timeId); // 상세화면 view 설정 및 validator 설정
          // }

          // selfFlag, joinFlag 추가(둘 다 false), 라운드 인원 default 값 설정
          responseValue.reservationTimeLists[0].selfFlag = responseValue.reservationTimeLists[0].selfOpenFlag;
          responseValue.reservationTimeLists[0].joinFlag = responseValue.reservationTimeLists[0].joinOpenFlag;

          if (!this.reservationDetailInfo.reservationInfo.resveRemarks) {
            this.reservationDetailInfo.reservationInfo.resveRemarks = responseValue.reservationInfo.resveRemarks;
          }

          this.setReservationDefaultInfo();

          this.reservationDetailInfo.reservationTimeLists = _sortBy(
            [
              ...this.reservationDetailInfo.reservationTimeLists,
              responseValue.reservationTimeLists[0],
            ],
            ["resveDate", "resveTime"]
          );

          // 전체 그리드 refresh - 추가된 정보 표시를 위함
          this.$refs.reservationGrid.forEach((grid) => {
            grid.refresh();
          });

          this.$nextTick(() => {
            // TODO grid props computed 전환 후 타이밍을 맞출 수 있음.
            setTimeout(() => {
              this.$refs.grpName.focusIn();
            }, 500);
          });
        } else {
          // 데이터 초기화가 잘못된 것
          this.errorToast("상세 데이터를 다시 확인해 주십시오");
        }
      } catch (e) {
        console.log("fetchReservationTimesDetail.err.===>", e);
      }
    },
    async getTimeStatusCount(timeId, timeStatus) {
      let timeStatusCount = 0;

      await getTimeStatusCount(timeId, timeStatus)
        .then((response) => {
          timeStatusCount = response.value;
        })
        .catch((error) => {
          console.log("getTimeStatusCont.err.===>", error);
        });

      return timeStatusCount;
    },
    async refreshDetailInfo(resveNo, timeId, isCheckDataModification, timeStatus, holdInsertId, resveDate, setDetailActiveTimeAndSetDetailViewFlag = true, clickField) {
      if (resveNo) {
        // 기 예약된 데이터인 경우 예약 상세 조회
        try {
          const responseValue = await this.fetchReservationTimesDetail(timeId, resveNo);

          if (!responseValue) {
            for (let i = 0; i < this.reservationCourseLists.length; i++) {
              this.$refs.reservationGrid[i].clearSelection();
            }
            return;
          }

          // 마스킹 된 데이터는 보여주지 않음
          responseValue?.reservationTimeLists?.forEach((reservationTime) => {
            if (!(reservationTime.transContactTel?.indexOf("*") < 0)) {
              delete reservationTime.transContactTel;
            }
            /*if (!(reservationTime.contactTel?.indexOf("*") < 0)) {
              delete reservationTime.contactTel;
            }*/
          });
          /*if (!(responseValue?.reservationInfo?.contactTel?.indexOf("*") < 0)) {
            delete responseValue?.reservationInfo.contactTel;
          }*/
          if (
            !(responseValue?.reservationInfo?.resveMngrHp?.indexOf("*") < 0)
          ) {
            delete responseValue?.reservationInfo.resveMngrHp;
          }

          // TODO : 상세조회 성공. 예약아이디 COMMIT (SET_RESVE_ID) & this.$EventBus.$emit('memoShortcutButtonClick');
          const selectedTimeInfo = responseValue.reservationTimeLists.find(
            (time) => time.timeId === timeId
          );

          if (selectedTimeInfo) {
            try {
              this.selectedDataForHistory = selectedTimeInfo;
              await this.setResveData(selectedTimeInfo.resveId);
              // 메모 자동오픈이 아닌 사용자가 클릭해서 열리는 방식으로 변경.
              // 메모 항목 클릭시에는 메모 자동 오픈.
              if (clickField === "memo") {
                this.$EventBus.$emit("memoShortcutButtonClick", true); // 메모창 열기
              }
            } catch (e) {
              // this.$EventBus.$emit('memoShortcutButtonClick'); // 메모창 열기
            }
          } else {
            this.errorToast("데이터가 잘못되었습니다. 다시 확인해 주십시오");
            return;
          }

          this.supportResponse(responseValue); // 특정 property가 있는 상세 건을 조회 --> 다른 건을 조회하면 undefined로 뜸. 이것을 방지하기 위해 초기화 해주기 TODO : API에서 정리하면 ??
          // console.log(responseValue);


          await this.fetchMemberReservationVisitSearch();

          this.reservationDetailInfo = responseValue;
          this.reservationDetailInfoOriginal = JSON.parse(
              JSON.stringify(this.reservationDetailInfo)
          ); // 비교를 위한 original data 저장
          this.prevModifyReservationDetailInfo = JSON.parse(
              JSON.stringify(this.reservationDetailInfo)
          );

          let activeTimeIds = [];

          if (this.currentActiveTimeIds.length > 0) {
            const orgCurrentActiveTimeIds = JSON.parse(JSON.stringify(this.currentActiveTimeIds));
            orgCurrentActiveTimeIds.forEach(currentActiveTimeId => {
              if (this.reservationDetailInfo.reservationTimeLists.filter(timeInfo => timeInfo.timeId === currentActiveTimeId).length > 0) {
                activeTimeIds.push(currentActiveTimeId);
              }
            });
          }
          if(this.reservationDetailInfo.reservationTimeLists.length ==1) {
            activeTimeIds.push(timeId);
          }


          // refreshDetailInfo Method의 setDetailActiveTimeAndSetDetailViewFlag 값을 설정함.
          // 하드코딩으로 setDetailActiveTimeAndSetDetailView Method에 false값을 주면
          // 다른 타임을 조회했다가 해당 타임으로 이동할 경우 예약타임 목록의 정보가 펼쳐진 상태로 조회됨.
          // 따라서 refreshDetailInfo Method를 사용할 때 예약타임 목록의 정보를 상태 유지할 것인지 셋팅해야함.
          this.setDetailActiveTimeAndSetDetailView(timeId, setDetailActiveTimeAndSetDetailViewFlag); // 상세화면 view 설정 및 validator 설정

          this.orgContactTel = responseValue.reservationInfo.contactTel;
          this.changeContactTel = responseValue.reservationInfo.contactTel;

          if (activeTimeIds.length > 0) {
            activeTimeIds.forEach(activeTimeId => {
              this.addCurrentActiveTimeId(activeTimeId);
            });
          }
        } catch (e) {
          console.log("getReservationDetail.err.===>", e);
          return;
        }
      } else {
        if (!this.commonMixinIsButtonDisableByAuth('resveReceiptResveReg')) {
          // 변경이력 조회를 위한 데이터 초기화.
          this.selectedDataForHistory = {resveId: null, timeId: null};
          // 기 예약된 time이 아니면, 시간 조회하여 새로운 예약건으로 설정함
          try {
            const responseValue = await this.fetchReservationTimesDetail(timeId, resveNo, clickField);

            // 웹타임 등록된 row를 예약자 항목 이외를 클릭시 웹타임 해지를 묻지 않고 rowSelect 유지되도록 수정.
            if (!responseValue || responseValue?.isWebTimeSelect) {
              if (!responseValue?.isWebTimeSelect) {
                for (let i = 0; i < this.reservationCourseLists.length; i++) {
                  this.$refs.reservationGrid[i].clearSelection();
                }
              } else {
                this.closeDetailView();
              }
              return;
            }

            // 마스킹 된 데이터는 보여주지 않음 - 이 코드가 필요한가?
            responseValue?.reservationTimeLists?.forEach((reservationTime) => {
              if (!(reservationTime.transContactTel?.indexOf("*") < 0)) {
                delete reservationTime.transContactTel;
              }
              if (!(reservationTime.contactTel?.indexOf("*") < 0)) {
                delete reservationTime.contactTel;
              }
            });

            // 메모 자동오픈이 아닌 사용자가 클릭해서 열리는 방식으로 변경.
            // this.$EventBus.$emit("closeRightMenu");
            this.clearMemo();

            // time 조회 api에서는 reservationInfo 값이 없으므로, 예약정보 쪽에 binding이 문제가 생김. 문제 해결을 위해 설정
            responseValue.reservationInfo = this.getDefaultReservationInfo(responseValue.reservationInfo);

            // selfFlag, joinFlag 추가(둘 다 false), 라운드 인원 default 값 설정
            if (responseValue.reservationTimeLists && responseValue.reservationTimeLists.length === 1) {
              responseValue.reservationTimeLists[0].selfFlag = responseValue.reservationTimeLists[0].selfOpenFlag;
              responseValue.reservationTimeLists[0].joinFlag = responseValue.reservationTimeLists[0].joinOpenFlag;

              // greenFee 기본값 설정
              if (responseValue.reservationTimeLists[0].teamGreenFee === null || responseValue.reservationTimeLists[0].teamGreenFee === undefined) {
                responseValue.reservationTimeLists[0].teamGreenFee = 0;
              }
              // 라운드 인원 설정 clickField
              if(responseValue.reservationTimeLists[0].joinFlag) {
                if (clickField == "resveName0") {
                  responseValue.reservationTimeLists[0].visitCnt = 4;
                } else if (clickField == "resveName1") {
                  responseValue.reservationTimeLists[0].visitCnt = 3;
                } else if (clickField == "resveName2") {
                  responseValue.reservationTimeLists[0].visitCnt = 2;
                } else if (clickField == "resveName3") {
                  responseValue.reservationTimeLists[0].visitCnt = 1;
                }
              }
              // 타임 선택시 예약자를 동반자로 셋팅하기 위한 작업.
              // 환경설정의 무인 체크인 설정 사용 여부가 true이고 단체모드가 아닐때만 셋팅.
              if (this.resveCmpnAuto && !responseValue.reservationInfo.grpNo) {
                responseValue.reservationTimeLists[0].reservationCompanions.push({
                  _rid: 1,
                  sno: 1,
                  reprsntFlag: true,
                  cmpnName: "",
                  joinGroupNo: "",
                  contactTel: "",
                  sexCode: commonCodeGetComCodeDefaultValue("SEX_CODE"),
                  lockerNo: "",
                  memberNo: "",
                  membershipId: "",
                  memberDiv: "",
                  memberGrade: "",
                });
              }
            }

            this.reservationDetailInfo = responseValue;
            await this.setReservationDefaultInfo(); // 예약정보 default 설정. 새로 시간 조회하는 데이터인 경우, default 값 설정하는 것은 수정 전 초기화이므로 Original 값 설정 이전에 넣음
            this.reservationDetailInfoOriginal = JSON.parse(
              JSON.stringify(this.reservationDetailInfo)
            ); // original 값과 동기화
            this.prevModifyReservationDetailInfo = {
              reservationInfo: {},
              reservationTimeLists: [],
            }; // 수정된 데이터 비교를 위한 초기화.

            this.setDetailActiveTimeAndSetDetailView(timeId); // 상세화면 view 설정 및 validator 설정

            this.orgContactTel = null;
            this.changeContactTel = null;
          } catch (e) {
            console.log("fetchReservationTimesDetail.err.===>", e);
            return;
          }
        }
      }
    },
    async setReservationDefaultInfo() {
      if (this.reservationDetailInfo.reservationInfo.resveKind === null) {
        if (this.reservationDetailInfo.reservationTimeLists[0].resveDate === await getTodayNavigationDate("YYYY-MM-DD")) {
          this.reservationDetailInfo.reservationInfo.resveKind = "DAY";
        } else {
          this.reservationDetailInfo.reservationInfo.resveKind = commonCodeGetComCodeDefaultValue(
            "RESVE_KIND"
          );
        }
      }
      if (this.reservationDetailInfo.reservationInfo.resveChannel === null) {
        this.reservationDetailInfo.reservationInfo.resveChannel = commonCodeGetComCodeDefaultValue(
          "RESVE_CHANNEL"
        );
      }
    },
    supportResponse(responseValue) {
      if (responseValue.reservationInfo) {
        // 그룹 이름이 있다가 없으면 undefined가 뜨기 떄문
        if (!responseValue.reservationInfo.grpName) {
          responseValue.reservationInfo.grpName = null;
        }
        if (!responseValue.reservationInfo.resveRemarks) {
          responseValue.reservationInfo.resveRemarks = null;
        }
        if (!responseValue.reservationInfo.recomendr) {
          responseValue.reservationInfo.recomendr = null;
        }
      }
    },
    setDetailActiveTimeAndSetDetailView(timeId, clearCurrentActiveTimeFlag = true) {
      // currentActiveTimeIds 클리어. 시간을 펼치지 않는다.
      if (clearCurrentActiveTimeFlag) {
        // clearCurrentActiveTimeFlag === ture 인 경우만 예약타임 목록의 정보를 닫는다.
        // 예약 저장시 예약타임 목록의 정보를 닫지않고 상태 유지하기 위한 값.
        this.clearCurrentActiveTime();
      }
      // this.addCurrentActiveTimeId(timeId);

      // validator 메시지 clear를 위해서 창을 닫았다가 다시 띄움
      this.isReservationDetailViewOpened = false;
      this.$nextTick(() => {
        this.isReservationDetailViewOpened = true;
      });
    },
    isDetailDataModified() {
      // 상세 데이터 수정 여부 확인
      // #1. 바인딩 데이터 변경 여부 확인
      let isModified = JSON.stringify(this.reservationDetailInfo) !== JSON.stringify(this.reservationDetailInfoOriginal);
      // #2. 만약 바뀌지 않았다면, 각 Time의 Grid에 변경 내역이 있는지 확인
      let isReservationDetailDataOk = true;
      if (!isModified && Array.isArray(this.reservationDetailInfo.reservationTimeLists)) {
        this.reservationDetailInfo.reservationTimeLists.every(
          (timeInfo, timeInfoIdx) => {
            const gridRefs = this.getReservationCompanionsGridRefs(timeInfoIdx);
            if (!gridRefs) {
              isReservationDetailDataOk = false;
              return false;
            }
            if (gridRefs.isGridModified()) {
              isModified = true;
              return false;
            }
            return true;
          }
        );
      }

      if (!isReservationDetailDataOk) {
        this.errorToast("데이터가 맞지 않습니다. 개발팀에게 문의하세요");
      }

      return isModified;
    },
    holdTimeColumnSetting(timeId, holdingFlag = false, filed = "resveName0") {
      for (let i = 0; i < this.reservationCourseLists.length; i++) {
        const rowIndex = this.$refs.reservationGrid[i].getRowIndexByPrimaryKey(timeId);

        if (rowIndex !== -1) {
          const cellIndex = this.$refs.reservationGrid[i].getColumnIndexByField("resveTime");
          const rowsData = this.$refs.reservationGrid[i].getRows();
          const currentViewRecords = this.$refs.reservationGrid[i].getCurrentViewRecords();

          if (rowsData.length > 0) {
            if (rowsData[rowIndex].cells.length > 0) {
              if (rowsData[rowIndex].cells[cellIndex]) {
                const resveNameIdx = this.$refs.reservationGrid[i].getColumnIndexByField(filed);
                const resveNameIdx1 = this.$refs.reservationGrid[i].getColumnIndexByField("resveName1");
                const resveNameIdx2 = this.$refs.reservationGrid[i].getColumnIndexByField("resveName2");
                const resveNameIdx3 = this.$refs.reservationGrid[i].getColumnIndexByField("resveName3");

                if (holdingFlag) {
                  this.$refs.reservationGrid[i].setCellValue(timeId, "timeStatus", "HOLD");

                  rowsData[rowIndex].cells[resveNameIdx].style.textAlign = "center";
                  rowsData[rowIndex].cells[resveNameIdx].innerHTML =
                    '<div class="e-checkbox-wrapper e-css e-checkbox-disabled"><span class="e-frame e-icons e-check"></span><span class="e-label"></span></div>';

                  rowsData[rowIndex].cells[cellIndex].style.backgroundColor = this.commonCodesGetColorValue("TIME_STATUS", "HOLD");
                  rowsData[rowIndex].cells[cellIndex].style.color = "#ffffff";
                } else {
                  if (!currentViewRecords[rowIndex].holdResn) {
                    if(currentViewRecords[rowIndex].joinOpenFlag && currentViewRecords[rowIndex].resveName0 !=""){
                      this.$refs.reservationGrid[i].setCellValue(timeId, "timeStatus", "JOIN");
                    }else {
                      this.$refs.reservationGrid[i].setCellValue(timeId, "timeStatus", "EMPTY");
                    }
                    if (!currentViewRecords[rowIndex].resveId) {
                      this.$refs.reservationGrid[i].setCellValue(timeId, "resveName0", "");
                    }
                    rowsData[rowIndex].cells[cellIndex].style.backgroundColor = this.commonCodesGetColorValue("TIME_STATUS", "EMPTY");
                    rowsData[rowIndex].cells[cellIndex].style.color = "#000000";
                  }
                  // 홀딩 설정 팝업에서 홀딩한 타임을 선택후 다른 타임 선택시 체크 표시가 안없어지는 버그 수정.
                  // (아래의 조건이 없을 경우 모든 innerHTML이 "" 처리됨)

                  if (rowsData[rowIndex].cells[resveNameIdx].innerHTML ===
                    '<div class="e-checkbox-wrapper e-css e-checkbox-disabled"><span class="e-frame e-icons e-check"></span><span class="e-label"></span></div>') {
                    rowsData[rowIndex].cells[resveNameIdx].innerHTML = "";
                  }
                  if (rowsData[rowIndex].cells[resveNameIdx1].innerHTML ===
                      '<div class="e-checkbox-wrapper e-css e-checkbox-disabled"><span class="e-frame e-icons e-check"></span><span class="e-label"></span></div>') {
                    rowsData[rowIndex].cells[resveNameIdx1].innerHTML = "";
                  }
                  if (rowsData[rowIndex].cells[resveNameIdx2].innerHTML ===
                      '<div class="e-checkbox-wrapper e-css e-checkbox-disabled"><span class="e-frame e-icons e-check"></span><span class="e-label"></span></div>') {
                    rowsData[rowIndex].cells[resveNameIdx2].innerHTML = "";
                  }
                  if (rowsData[rowIndex].cells[resveNameIdx3].innerHTML ===
                      '<div class="e-checkbox-wrapper e-css e-checkbox-disabled"><span class="e-frame e-icons e-check"></span><span class="e-label"></span></div>') {
                    rowsData[rowIndex].cells[resveNameIdx3].innerHTML = "";
                  }
                }
              }
            }
          }

          return;
        }
      }
    },
    getDefaultReservationInfo(data) {
      // 상세 데이터의 default 값 return
      // data 초기화
      return {
        resveNo: null,
        resveKind: null,
        resveChannel: null,
        resveName: null,
        membershipId: null,
        memberNo: null,
        memberDiv: null,
        memberGrade: null,
        sexCode: null,
        areaCode: null,
        contactName: null,
        contactTel: "", // 값이 없어도 자동으로 '' 로 값이 바뀜(maskedTextBox 떄문인 듯). 따라서, 수정여부 체크 시 문제가 있기 때문에 '' 로 초기화함
        grpNo: null,
        grpKind: commonCodeGetComCodeDefaultValue("GRP_KIND"),
        grpName: null,
        smsSendFlag: false,
        smsSendCnt: 0,
        resveRemarks: data?.resveRemarks || null,
        recomendr: null,
        insertDt: null,
        updateDt: null,
        vipFlag: false
      };
    },
    DetailDataInitAndSyncWithOriginal() {
      // 상세 데이터 초기화 - reservationTimeLists와 reservationInfo를 한꺼번에 초기화하여야 바인딩에 문제가 없다
      let detailInfo = {
        reservationTimeLists: [],
        reservationInfo: this.getDefaultReservationInfo(),
      };
      this.reservationDetailInfo = detailInfo;

      // original 값과 동기화
      this.reservationDetailInfoOriginal = JSON.parse(
        JSON.stringify(this.reservationDetailInfo)
      );
      this.prevModifyReservationDetailInfo = JSON.parse(
        JSON.stringify(this.reservationDetailInfo)
      );
    },
    reservationDetailModifySwitchChanged(args) {
      if (!args.checked) {
        // 추가 끄면 우측 예약 상세 닫기
        this.closeDetailView();
      }
      if (Array.isArray(this.$refs.reservationGrid)) {
        this.$refs.reservationGrid.forEach((grid) => {
          grid.refresh();
        });
      } else {
        // Grid가 1개일 경우에도 array로 넘어오므로 이 block을 타진 않지만, 혹시나 해서 남겨둠
        this.$refs.reservationGrid.refresh();
      }

      // 단체모드 클릭시 최초 셋팅된 동반자 제거.(연락처 없을때만)
      if (args.checked && this.reservationDetailInfo.reservationTimeLists.length > 0) {
        if (this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions.length > 0
            && !this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].contactTel) {
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions.shift();
        }
      }
    },
    async onTimeMenuSelected(args, timeInfo) {
      const {item,} = args;

      if (!timeInfo.resveNo && item.menuId === 2) {
        this.releaseHoldTimeStatus(timeInfo).then(() => {
          this.reservationDetailInfo.reservationTimeLists = _remove(
            this.reservationDetailInfo.reservationTimeLists,
            ({ timeId }) => timeId !== timeInfo.timeId
          );
        });

        return;
      }

      if (args.item.menuId === 1) {
        // 예약 복사 popup open
        if (this.isReservationDetailModifyMode) {
          this.errorToast("추가 중에는 예약 복사를 하실 수 없습니다");
          return;
        }
        if (this.isDetailDataModified()) {
          // 수정여부 체크
          if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
            return;
          }
        }

        if (timeInfo?.reservationConfirm?.resveStatus !== "RESVE") {
          this.infoToast("복사 가능한 예약상태가 아닙니다");
          return;
        }

        this.reservationCheck(
          this.reservationDetailInfo.reservationInfo.membershipId ? null : this.reservationDetailInfo.reservationInfo.contactTel,
          this.reservationDetailInfo.reservationInfo.grpNo,
          this.reservationDetailInfo.reservationInfo.membershipId,
          null
        );

        let popupData = {};
        popupData.timeId = timeInfo.timeId;
        popupData.resveDate = timeInfo.resveDate;
        popupData.resveTime = timeInfo.resveTime;
        popupData.resveCourse = timeInfo.resveCourse;
        popupData.resveName = this.reservationDetailInfo.reservationInfo.resveName;
        popupData.resveContactTel = this.reservationDetailInfo.reservationInfo.contactTel;
        popupData.resveMemberDiv = this.reservationDetailInfo.reservationInfo.memberDiv;
        popupData.grpName = this.reservationDetailInfo.reservationInfo.grpName;
        popupData.optFlag = timeInfo.optFlag;
        popupData.resveNo = this.reservationDetailInfo.reservationInfo.resveNo;
        popupData.smsRecptnDiv = this.reservationDetailInfo.reservationInfo.smsRecptnDiv;
        popupData.resveMngrName = this.reservationDetailInfo.reservationInfo.resveMngrName;
        popupData.resveMngrHp = this.reservationDetailInfo.reservationInfo.resveMngrHp;
        popupData.transName = timeInfo.transName;
        popupData.transContactTel = timeInfo.contactTel;
        popupData.transMemberDiv = timeInfo.memberDiv;

        this.isReservationCopyPopupOpen = true;

        this.$nextTick(() => {
          this.$refs.reservationCopyPopup.popupOpen(
            popupData,
            this.reservationOptions.resveDate,
            this.reservationOptions.resveDate
          );
        });
      } else if (item.menuId === 2) {
        if (this.isReservationDetailModifyMode) {
          this.errorToast("추가 중에는 예약 취소를 하실 수 없습니다");
          return;
        }
        if (this.isDetailDataModified()) {
          // 수정여부 체크
          if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
            return;
          }
        }
        this.reservationCancelPopupOpen([item.timeId],[item.resveId]);
      } else if (item.menuId === 3) {
        if (this.reservationDetailInfo?.reservationTimeLists?.length <= 1) {
          this.infoToast("예약타임이 하나인 경우 단체 해제를 하실 수 없습니다");
          return;
        }

        await GolfErpAPI.reservationUngroup(timeInfo.resveId);

        this.searchReservation();
      } else if (item.menuId === 4) {
        // 우천취소
        if (this.isReservationDetailModifyMode) {
          this.errorToast("추가 중에는 우천취소를 하실 수 없습니다");
          return;
        }
        if (this.isDetailDataModified()) {
          // 수정여부 체크
          if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
            return;
          }
        }

        if (timeInfo?.reservationConfirm?.resveStatus !== "RESVE") {
          this.infoToast("우천취소 가능한 예약상태가 아닙니다");
          return;
        }

        const isRainCancel = timeInfo?.reservationConfirm?.cancelDiv !== "RAIN";

        if (!(await this.confirm(`${isRainCancel ? "우천취소" : "우천취소 해제"} 하시겠습니까?`))) {
          return;
        }

        try {
          await GolfErpAPI.reservationRainCancel(timeInfo.resveId, isRainCancel);
          this.infoToast(`${isRainCancel ? "우천취소" : "우천취소 해제"}가 완료되었습니다`);
          this.searchReservation();
        } catch (e) {
          console.error(e);
        }
      }
    },
    onTransNameChanged(args, timeInfoIdx) {

      if (args.event && args.previousValue !== args.value) {
        // console.log('## 위임자 명을 바꾼 후 Focus out. 회원 API 조회 후 결과에 따라 회원정보 팝업을 띄운다. 이름 검색어 :' + args.value)
        // alert(args.value + '로 검색한 건이 0건이면 비회원 처리, 1건이면 해당 회원을 위임자로 설정, 2건 이상이면 회원정보 팝업을 띄운다')

        // [위임자 Case 분기(항목은 ReservationTrans.java - ReservationTimeInfo.java 참고)]
        // #1. 수정인 경우: 예약 ID, 예약번호 설정. 추가인 경우 : 예약 ID, 예약번호 없음
        // #2. 회원/비회원 위임자의 경우
        // - 회원인 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우) : 위임자명, ,회원권 ID, 회원번호 회원구분, 회원등급, 성별코드, 연락처 설정
        // - 비회원인 경우(검색한 건이 0건이거나, 띄운 회원정보 팝업을 닫는다
        //      : 비회원 선택 시 회원권 ID, 회원번호 Clear, 성별코드(M). 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정. 위임자명과 연락처는 그대로 놔 둠
        // 참고) 아이콘 클릭으로 팝업을 띄운 경우에는 검색하여 선택한 경우에만 위의 회원 설정으로 처리. 선택하지 않은 경우에는 아무것도 안함(이하 모두 적용)
        // 참고) 회원명을 모두 지운 후 focus out --> 예약 ID, 예약 번호 제외한 모든 정보 clear(이하 모두 적용)
        if (args.value.trim() === "") {
          // 빈 칸인 경우에는 미 설정으로
          this.setTransNonMemberInfos(timeInfoIdx);
          this.$refs.timeInfoContactTel[timeInfoIdx].setEmpty();
          return;
        }

        // 회원정보에 없는 비회원이라도 무조건 회원 검색 팝업 호출. 모든 화면 공통 조건
        this.onMemberPopupOpen(
          false,
          this.memberPopupType.TRANS,
          args.value,
          timeInfoIdx
        );
      }
    },
    setTransMemberInfos(memberInfo, timeInfoIdx) {

      if (this.reservationDetailInfo.reservationTimeLists[timeInfoIdx]) {

        this.reservationDetailInfo.reservationTimeLists[timeInfoIdx].transName =  memberInfo.memberName;
        this.reservationDetailInfo.reservationTimeLists[timeInfoIdx].membershipId = memberInfo.membershipId ? memberInfo.membershipId : "";
        this.reservationDetailInfo.reservationTimeLists[timeInfoIdx].memberNo = memberInfo.memberNo ? memberInfo.memberNo : "";
        this.reservationDetailInfo.reservationTimeLists[timeInfoIdx].memberDiv = memberInfo.memberDiv;
        this.reservationDetailInfo.reservationTimeLists[timeInfoIdx].memberGrade = memberInfo.memberGrade;
        this.reservationDetailInfo.reservationTimeLists[timeInfoIdx].sexCode = memberInfo.sexCode;
        this.reservationDetailInfo.reservationTimeLists[timeInfoIdx].contactTel = memberInfo.hpNo ? memberInfo.hpNo : "";

        //위임자 있을 시 동행자 1순위
        this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].cmpnName = memberInfo.memberName;
        this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].memberNo = memberInfo.memberNo ? memberInfo.memberNo : "";
        this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].membershipId = memberInfo.membershipId ? memberInfo.membershipId : "";
        this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].memberDiv = memberInfo.memberDiv;
        this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].memberGrade = memberInfo.memberGrade;
        this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].sexCode = memberInfo.sexCode;
        this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].contactTel = memberInfo.hpNo ? memberInfo.hpNo : "";

        if(memberInfo.hpNo === undefined){
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].contactTelIdx = null;
        }

        // 암호화데이터 열람 권한이 있어야 설정 가능
        // if (this.commonMixinHasCiperTextGet) {
        //   this.reservationDetailInfo.reservationTimeLists[timeInfoIdx].contactTel = memberInfo.hpNo;
        // }
      }
    },
    setTransNonMemberInfos(timeInfoIdx) {

      if (this.reservationDetailInfo.reservationTimeLists[timeInfoIdx]) {
        this.reservationDetailInfo.reservationTimeLists[timeInfoIdx].membershipId = null;
        this.reservationDetailInfo.reservationTimeLists[timeInfoIdx].memberNo = null;
        this.reservationDetailInfo.reservationTimeLists[timeInfoIdx].sexCode = "M";
        this.reservationDetailInfo.reservationTimeLists[timeInfoIdx].memberDiv = "NOM";
        this.reservationDetailInfo.reservationTimeLists[timeInfoIdx].memberGrade =
            commonCodesGetJsonData("MEMBER_DIV", "NOM").find((codeJson) => codeJson.IDX === 4).ATTRB;
        // 암호화데이터 열람 권한이 있어야 설정 가능
        // 연락처는 입력된 값 그대로 유지.
        // if (this.commonMixinHasCiperTextGet) {
        //   this.reservationDetailInfo.reservationTimeLists[
        //     timeInfoIdx
        //     ].contactTel = null;
        // }
      }

      if (this.resveCmpnAuto && !this.reservationDetailInfo.reservationInfo.grpNo && !this.isReservationDetailModifyMode) {
        if(this.reservationDetailInfo.reservationInfo.resveName != null){
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].cmpnName = this.reservationDetailInfo.reservationInfo.resveName;
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].memberNo = this.reservationDetailInfo.reservationInfo.memberNo;
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].membershipId = this.reservationDetailInfo.reservationInfo.membershipId;
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].memberDiv = this.reservationDetailInfo.reservationInfo.memberDiv;
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].memberGrade = this.reservationDetailInfo.reservationInfo.memberGrade;
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].sexCode = this.reservationDetailInfo.reservationInfo.sexCode;
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].contactTel = this.reservationDetailInfo.reservationInfo.contactTel;
        }else {
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].cmpnName = "";
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].memberNo = "";
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].membershipId = "";
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].memberDiv = "NOM";
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].memberGrade = commonCodesGetCommonCodeAttrbByCodeAndIdx("MEMBER_DIV", "NOM", 4);
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].sexCode = "M";
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].contactTel = "";
        }

      }

    },
    onVisitCntChanged(args, timeInfo, timeInfoIdx) {
      const gridRefs = this.getReservationCompanionsGridRefs(timeInfoIdx);
      const getBatchCurrentViewRecords = gridRefs.getBatchCurrentViewRecords();

      if (getBatchCurrentViewRecords.length > timeInfo.visitCnt) {
        this.infoToast("라운드 인원을 동반자정보 인원 미만으로 변경하실 수 없습니다.");
        timeInfo.visitCnt = getBatchCurrentViewRecords.length;
      }
    },
    reservationCompanionAdd(timeInfo, timeInfoIdx) {
      const gridRefs = this.getReservationCompanionsGridRefs(timeInfoIdx);
      const getBatchCurrentViewRecords = gridRefs.getBatchCurrentViewRecords();
      if (getBatchCurrentViewRecords.length >= timeInfo.visitCnt) {
        this.infoToast("라운드 인원보다 초과하여 추가하실 수 없습니다");
        return;
      }
      let _rid;
      if (timeInfo.length > 0) {
        const currentViewRecords = gridRefs.getBatchCurrentViewRecords();
        _rid = _.maxBy(currentViewRecords, "_rid")._rid;
      } else {
        const { addedRecords } = gridRefs.getBatchChanges();
        _rid = addedRecords.length;
      }
      const viewRecordsData = gridRefs.getBatchCurrentViewRecords();
      const addRecordData = {
        _rid: _rid + 1,
        reprsntFlag: false,
        cmpnName: viewRecordsData.length === 0 && !this.isGroupReservation ? this.reservationDetailInfo.reservationInfo.contactName : null,
        joinGroupNo: null,
        contactTel: viewRecordsData.length === 0 && !this.isGroupReservation ? this.reservationDetailInfo.reservationInfo.contactTel : null,
        sexCode: viewRecordsData.length === 0 && !this.isGroupReservation ? this.reservationDetailInfo.reservationInfo.sexCode ? this.reservationDetailInfo.reservationInfo.sexCode : commonCodeGetComCodeDefaultValue("SEX_CODE") : commonCodeGetComCodeDefaultValue("SEX_CODE"),
        lockerNo: null,
        memberNo: viewRecordsData.length === 0 && !this.isGroupReservation ? this.reservationDetailInfo.reservationInfo.memberNo : null,
        membershipId: viewRecordsData.length === 0 && !this.isGroupReservation ? this.reservationDetailInfo.reservationInfo.membershipId : null,
        memberDiv: viewRecordsData.length === 0 && !this.isGroupReservation ? this.reservationDetailInfo.reservationInfo.memberDiv : null,
        memberGrade: viewRecordsData.length === 0 && !this.isGroupReservation ? this.reservationDetailInfo.reservationInfo.memberGrade : null,
      };
      gridRefs.addRecord(addRecordData);
      const currentviewRecords = gridRefs.getBatchCurrentViewRecords();
      const idxToSelect = currentviewRecords.findIndex((record) => record._rid === addRecordData._rid);
      gridRefs.editCell(idxToSelect, "cmpnName");
    },
    reservationCompanionsGridDelete(timeInfoIdx) {
      const gridRefs = this.getReservationCompanionsGridRefs(timeInfoIdx);
      const selectedRecords = gridRefs.getSelectedRecords();
      if (this.resveCmpnAuto && !this.isGroupReservation) {
        if (selectedRecords.length > 0 && selectedRecords[0].sno === 1) {
          this.infoToast("동반자 정보의 예약자 데이터는 삭제하실 수 없습니다");
          return;
        }
      }
      if (selectedRecords.length === 0) {
        return;
      }
      gridRefs.deleteRecord();
    },
    reservationCompanionsGridCancel(timeInfoIdx) {
      const gridRefs = this.getReservationCompanionsGridRefs(timeInfoIdx);
      gridRefs.batchCancel();
    },
    onReservationCompanionGridCellSaved(args, timeInfoIdx) {
      if (args.columnName === "cmpnName") {
        if (args.previousValue !== args.value) {
          const gridRefs = this.getReservationCompanionsGridRefs(timeInfoIdx);

          // 선택된 row index 가져오기
          let selectedIdx = gridRefs.getSelectedRowIndexes()[0];

          if (args.value.trim() === "") {
            // 빈 칸인 경우에는 비회원 설정
            this.setCompNonMemberInfos(timeInfoIdx, selectedIdx);
            return;
          }
          // 회원정보에 없는 비회원이라도 무조건 회원 검색 팝업 호출. 모든 화면 공통 조건.
          this.onMemberPopupOpen(
            false,
            this.memberPopupType.COMP,
            args.value,
            timeInfoIdx,
            selectedIdx // TODO : selectedIdx를 넘기면 syncFusion : parentElement 오류 발생, selectedIdx를 member popup에 전달하지만 정작 해당 팝업에서는 쓰이지 않음.
          );
        }
      }
    },
    onReservationCompanionGridCellEdit(args, timeInfoIdx) {
      if (args.columnName === "cmpnName") {
        if (args.previousValue !== args.value) {
          // alert(args.value + '로 검색한 건이 0건이면 비회원 처리, 1건이면 해당 회원을 동반자로 설정, 2건 이상이면 회원정보 팝업을 띄운다')

          // 동반자 Case 분기(항목은 ReservationCompanion.java 참고)
          // #1. 수정인 경우 : 예약 ID, 순번, 예약ID + 순번, 예약번호 설정됨. 추가인 경우 : 없음
          // #2. 회원/비회원 동반자의 경우
          //  - 회원인 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우) : 동반자명, 회원권 ID, 회원번호, 회원구분, 회원등급, 성별코드, 연락처 설정
          //  - 비회원인 경우(검색한 건이 0건이거나, 띄운 회원정보 팝업을 닫는다
          //      : 비회원 선택 시 회원권 ID, 회원번호 Clear, 성별코드(M) . 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정. 나머지는 그대로 놔 둠
          const gridRefs = this.getReservationCompanionsGridRefs(timeInfoIdx);
          const gridBatchCurrentViewRecords = gridRefs.getBatchCurrentViewRecords();

          // 선택된 row index 가져오기
          let selectedIdx;
          if (args.rowData.pkData) {
            selectedIdx = gridBatchCurrentViewRecords.findIndex(
              (record) => record.pkData === args.rowData.pkData
            );
          } else {
            selectedIdx = gridBatchCurrentViewRecords.findIndex(
              (record) => record.index === args.rowData.index
            );
          }

          if (args.value.trim() === "") {
            // 빈 칸인 경우에는 비회원 설정
            this.setCompNonMemberInfos(timeInfoIdx, selectedIdx);
            return;
          }

          // 회원정보에 없는 비회원이라도 무조건 회원 검색 팝업 호출. 모든 화면 공통 조건.
          this.onMemberPopupOpen(
            false,
            this.memberPopupType.COMP,
            args.value,
            timeInfoIdx,
            null // TODO : selectedIdx를 넘기면 syncFusion : parentElement 오류 발생, selectedIdx를 member popup에 전달하지만 정작 해당 팝업에서는 쓰이지 않음.
          );
        }
      }
    },
    onKeyPressed(args, timeInfoIdx) {
      if (
        !(
          args.target.id === "cmpnName" &&
          (args.key === "Escape" || args.key === "Enter")
        )
      ) {
        return;
      }
      const gridRefs = this.getReservationCompanionsGridRefs(timeInfoIdx);
      const selectedRows = gridRefs.getSelectedRowIndexes();
      if (selectedRows.length < 1) {
        return;
      }
      const selectedRow = selectedRows[0];
      if (args.key === "Escape") {
        gridRefs.updateCell(selectedRow, "cmpnName", "");
        gridRefs.clearSelection();
      } else if (args.key === "Enter") {
        this.onMemberPopupOpen(
          false,
          this.memberPopupType.COMP,
          args.target._value,
          timeInfoIdx,
          selectedRow
        );
      }
      args.cancel = true;
    },
    setCompMemberInfos(memberInfo, timeInfoIdx, selectedIdx) {
      const gridRefs = this.getReservationCompanionsGridRefs(timeInfoIdx);
      if (gridRefs) {
        if (selectedIdx >= 0) {
          gridRefs.updateCell(selectedIdx, "cmpnName", memberInfo.memberName);
          gridRefs.updateCell(
            selectedIdx,
            "membershipId",
            memberInfo.membershipId
          );
          gridRefs.updateCell(selectedIdx, "memberNo", memberInfo.memberNo);
          gridRefs.updateCell(selectedIdx, "memberDiv", memberInfo.memberDiv);
          gridRefs.updateCell(
            selectedIdx,
            "memberGrade",
            memberInfo.memberGrade
          );
          gridRefs.updateCell(selectedIdx, "sexCode", memberInfo.sexCode);
          // 암호화데이터 열람 권한이 있어야 설정 가능
          if (this.commonMixinHasCiperTextGet) {
            gridRefs.updateCell(selectedIdx, "contactTel", memberInfo.hpNo);
          }
          gridRefs.editCell(selectedIdx, "cmpnName");
        }
      }
    },
    setCompNonMemberInfos(timeInfoIdx, selectedIdx) {
      const gridRefs = this.getReservationCompanionsGridRefs(timeInfoIdx);
      if (gridRefs) {
        if (selectedIdx >= 0) {
          gridRefs.updateCell(selectedIdx, "membershipId", null);
          gridRefs.updateCell(selectedIdx, "memberNo", null);
          gridRefs.updateCell(selectedIdx, "sexCode", "M");
          gridRefs.updateCell(selectedIdx, "memberDiv", "NOM");
          // 암호화데이터 열람 권한이 있어야 설정 가능
          if (this.commonMixinHasCiperTextGet) {
            gridRefs.updateCell(selectedIdx, "contactTel", null);
          }
          gridRefs.updateCell(
            selectedIdx,
            "memberGrade",
            commonCodesGetJsonData("MEMBER_DIV", "NOM").find(
              (codeJson) => codeJson.IDX === 4
            ).ATTRB
          );
        }
      }
    },
    reservationCompanionGridCustomiseCell(args) {
      // 수정영역 셀 처리
      if (
        args.column.field === "reprsntFlag" ||
        args.column.field === "cmpnName" ||
        args.column.field === "joinGroupNo" ||
        args.column.field === "contactTel" ||
        args.column.field === "sexCode" ||
        args.column.field === "lockerNo" ||
        args.column.field === "check" ||
        args.column.field === "checkbox"
      ) {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }

      // 검색 아이콘 영역
      if (args.column.field === "cmpnNameIcon") {
        args.cell.classList.add(this.$t("className.grid.searchIconArea"));
      }
    },
    onPromtnNameChanged(args, timeInfoIdx) {
      if (args.event && args.previousValue !== args.value) {
        console.log(
          "## 프로모션 명을 바꾼 후 Focus out. 프로모션 조회 후 결과에 따라 프로모션 팝업을 띄운다. 프로모션명 검색어 :" +
            args.value
        );
        // alert(args.value + '로 검색한 건이 0건이면 관련 정보 Clear, 1건이면 해당 건을 프로모션으로 설정, 2건 이상이면 프로모션 팝업을 띄운다')
        // [프로모션 Case 분기(항목은 ReservationTimeInfo.java 참고)]
        // 프로모션 검색된 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우) : 프로모션ID, 프로모션명 설정
        // 프로모션 검색되지 않은 경우(검색한 건이 0건이거나, 띄운 프로모션 팝업을 선택하지 않고 닫는다) : 프로모션ID, 프로모션명 Clear
        if (args.value.trim() === "") {
          // 빈 칸인 경우에는 미 설정으로
          this.clearPromotionInfos(timeInfoIdx);
          return;
        }
        getPromotionList(args.value, null, null, null, null, null, true)
          .then((response) => {
            const promotionList = response.value.promotionList;
            if (promotionList && promotionList.length > 1) {
              this.onPromotionPopupOpen(false, timeInfoIdx);
            } else if (promotionList && promotionList.length === 1) {
              this.setPromotionInfos(promotionList[0], timeInfoIdx);
            } else {
              this.clearPromotionInfos(timeInfoIdx);
            }
          })
          .catch((error) => {
            console.log("getPromotionList.err.===>", error);
          });
      }
    },
    setPromotionInfos(promotionInfo, timeInfoIdx) {
      if (this.reservationDetailInfo.reservationTimeLists[timeInfoIdx]) {
        this.reservationDetailInfo.reservationTimeLists[timeInfoIdx].promtnId =
          promotionInfo.promtnId;
        this.reservationDetailInfo.reservationTimeLists[
          timeInfoIdx
        ].promtnName = promotionInfo.promtnName;
      }
    },
    clearPromotionInfos(timeInfoIdx) {
      if (this.reservationDetailInfo.reservationTimeLists[timeInfoIdx]) {
        this.reservationDetailInfo.reservationTimeLists[
          timeInfoIdx
        ].promtnId = null;
        this.reservationDetailInfo.reservationTimeLists[
          timeInfoIdx
        ].promtnName = null;
      }
    },
    /*
        getPromotionList(promtnName) {
          let promotionList = [
            {
              'promtnId': 10,
              'promtnName': '10번 프로모션',
              'bsnCode': 'WEEKDAY',
              'startDate': '2019-02-01'
            },
            {
              'promtnId': 20,
              'promtnName': '20번 프로모션!!!!',
              'bsnCode': 'WEEKDAY',
              'startDate': '2019-02-01'
            }
          ]
          return promotionList
        },
        */
    onPromotionPopupOpen(isOpenedWithButton, timeInfoIdx) {
      this.isPromotionSearchPopupOpen = true;

      let promotionPopupData = {};
      if (
        this.reservationDetailInfo.reservationTimeLists[timeInfoIdx] &&
        this.reservationDetailInfo.reservationTimeLists[timeInfoIdx]
          .promtnName !== undefined
      ) {
        promotionPopupData.promtnName = this.reservationDetailInfo.reservationTimeLists[
          timeInfoIdx
        ].promtnName;
      } else {
        promotionPopupData.promtnName = null;
      }
      if (isOpenedWithButton) {
        promotionPopupData.isOpenedWithButton = isOpenedWithButton;
      }
      promotionPopupData.timeInfoIdx = timeInfoIdx;

      this.$nextTick(() => {
        this.$refs.promotionSearchPopup.showPromotionSearchPopup(
          promotionPopupData
        );
      });
    },
    onGrpNameChanged(args) {
      if (args.event && args.previousValue !== args.value) {
        console.log(
          "## 단체명을 바꾼 후 Focus out. 단체 조회 후 결과에 따라 단체 팝업을 띄운다. 단체 검색어 :" +
            args.value
        );
        // alert(args.value + '로 검색한 건이 0건이면 관련 정보 Clear, 1건이면 해당 건을 단체로 설정, 2건 이상이면 단체 팝업을 띄운다')
        // [단체 Case 분기(항목은 ReservationInfo.java 참고)]
        // 단체 검색된 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우)
        //      : 단체번호, 단체종류, 단체명 설정. 예약자명 = 단체명. 연락자명, 연락처 설정(총무 정보로)
        //      : 회원권 ID, 회원번호 Clear, 성별코드(M)
        //      : 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정
        // 단체 검색되지 않은 경우(검색한 건이 0건이거나, 띄운 단체 팝업을 선택하지 않고 닫는다)
        //      : 단체번호, 단체종류, 단체명 Clear
        //      : 나머지는 그대로 놔둠
        if (args.value.trim() === "") {
          // 빈 칸인 경우에는 미 설정으로
          this.clearGrpInfo();
          return;
        }
        getGroupList({ searchValue: args.value })
          .then((response) => {
            const groupList = response.value.groupInfoList;
            if (groupList && groupList.length > 1) {
              this.onGroupPopupOpen();
            } else if (groupList && groupList.length === 1) {
              // 그룹정보 설정
              this.setGrpInfos(groupList[0]);
              this.reservationCheck(null, groupList[0].grpNo, null); // 위약 체크
            } else {
              this.onGroupPopupOpen(false, "TEMP");
            }
          })
          .catch((error) => {
            console.log("getGroupList.err.===>", error);
          });
      }
    },
    async setGrpInfos(grpInfo) {
      // 같은 이름 입력시 change 이벤트 작동안하는 부분을 위한 input-text 초기화.
      this.$refs.grpName.setEmpty();
      if (this.reservationDetailInfo.reservationInfo) {
        this.reservationDetailInfo.reservationInfo.grpNo = grpInfo.grpNo;
        this.reservationDetailInfo.reservationInfo.grpKind = grpInfo.grpKind;
        this.reservationDetailInfo.reservationInfo.grpName = grpInfo.grpName;
        this.reservationDetailInfo.reservationInfo.resveChannel = grpInfo.resveChannel;
        if (grpInfo.grpKind !== "TEMP") {
          this.reservationDetailInfo.reservationInfo.resveName =
            grpInfo.grpName;
          if (grpInfo.generName) {
            this.reservationDetailInfo.reservationInfo.contactName =
              grpInfo.generName;
          } else {
            this.reservationDetailInfo.reservationInfo.contactName = null;
          }
          if (grpInfo.generContactTel) {
            this.reservationDetailInfo.reservationInfo.contactTel =
              grpInfo.generContactTel;
          } else {
            this.reservationDetailInfo.reservationInfo.contactTel = null;
          }
          this.reservationDetailInfo.reservationInfo.membershipId = grpInfo.generMembershipId || null;
          this.reservationDetailInfo.reservationInfo.memberNo = grpInfo.generMemberNo || null;
          this.reservationDetailInfo.reservationInfo.sexCode = grpInfo.generSexCode || "M";
          this.reservationDetailInfo.reservationInfo.memberDiv = grpInfo.generMemberDiv || "NOM";
          this.reservationDetailInfo.reservationInfo.memberGrade = grpInfo.generMemberGrade || "NOR";
        }
        // this.reNewValidateMessage() // validator message 갱신

        // 단체 설정시 최초 셋팅된 동반자 제거.(연락처 없을때만)
        if (this.reservationDetailInfo.reservationTimeLists.length > 0) {
          if (this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions.length > 0 &&
            !this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].contactTel) {
            this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions.shift();
          }
        }
        this.$refs.resveName.focus();
      }
    },
    clearGrpInfo() {
      if (this.reservationDetailInfo.reservationInfo) {
        // data 초기화후 같은 이름 입력시 change 이벤트 작동안하는 부분을 위한 input-text 초기화.
        this.$refs.grpName.setEmpty();
        this.reservationDetailInfo.reservationInfo.grpNo = "";
        this.reservationDetailInfo.reservationInfo.grpKind = "FIT";
        this.reservationDetailInfo.reservationInfo.grpName = "";
      }
    },
    onGroupPopupOpen(isOpenedWithButton = false, grpKind) {
      this.isGroupPopupOpen = true;

      let groupPopupData = {};
      groupPopupData.grpName = this.reservationDetailInfo.reservationInfo
        .grpName
        ? (groupPopupData.grpName = this.reservationDetailInfo.reservationInfo.grpName)
        : null;
      groupPopupData.resveDates = this.reservationDetailInfo?.reservationTimeLists?.map(item => item.resveDate) || [];
      if (isOpenedWithButton) {
        groupPopupData.isOpenedWithButton = isOpenedWithButton;
      }
      groupPopupData.grpKind = grpKind;
      if (grpKind) {
        groupPopupData.grpName = null;
        groupPopupData.tempGrpName = this.reservationDetailInfo.reservationInfo.grpName;
      }

      this.$nextTick(() => {
        this.$refs.groupPopup.openPopup(groupPopupData);
      });
    },
    onResveNameChanged(args) {
      if (args.event && args.previousValue !== args.value) {
        this.$EventBus.$emit("loaderOn");
        try {
          // console.log('## 예약자명을 바꾼 후 Focus out. 예약자 조회 후 결과에 따라 예약 팝업을 띄운다. 예약자명 검색어 :' + args.value)
          // alert(args.value + '로 검색한 건이 0건이면 비회원 처리, 1건이면 해당 회원을 예약자로 설정, 2건 이상이면 회원정보 팝업을 띄운다')

          // [예약자 Case 분기(항목은 ReservationInfo.java 참고)]
          // #1. 수정인 경우: 예약번호 설정. 추가인 경우 : 예약번호 없음
          // #2. 회원/비회원 위임자의 경우
          // - 회원인 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우) : 예약자명, 회원권 ID, 회원번호, 회원구분, 회원등급, 성별코드, 연락처 설정. 연락자명, 지역을 예약자명과 동일하게 설정
          // - 비회원인 경우(검색한 건이 0건이거나, 띄운 회원정보 팝업을 닫는다
          //      : 비회원 선택 시 회원권 ID, 회원번호 Clear, 성별코드(M). 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정.
          //      : 연락자명은 현재 남아있는 예약자명과 동일하게 설정. 나머지는 그대로 놔 둠
          if (args.value.trim() === "") {
            // 빈 칸인 경우에는 미 설정으로
            this.setResvNonMemberInfos();
            return;
          }

          this.setResvNonMemberInfos();

          // 회원정보에 없는 비회원이라도 무조건 회원 검색 팝업 호출. 모든 화면 공통 조건.
          this.onMemberPopupOpen(
            false,
            this.memberPopupType.RESV,
            args.value,
            null
          );
        } catch (error) {
          console.log("getMemberList.err.===>", error);
        } finally {
          this.$EventBus.$emit("loaderOff");
        }
      }
    },
    setResvMemberInfos(memberInfo) {
      if (this.reservationDetailInfo.reservationInfo) {
        this.reservationDetailInfo.reservationInfo.resveName = memberInfo.memberName;
        this.reservationDetailInfo.reservationInfo.membershipId = memberInfo.membershipId;
        this.reservationDetailInfo.reservationInfo.memberNo = memberInfo.memberNo;
        this.reservationDetailInfo.reservationInfo.memberDiv = memberInfo.memberDiv;
        this.reservationDetailInfo.reservationInfo.memberGrade = memberInfo.memberGrade;
        this.reservationDetailInfo.reservationInfo.sexCode = memberInfo.sexCode;
        if (this.commonMixinHasCiperTextGet) {
          // commonMixinHasCiperTextGet 권한이 있는 경우에만
          this.reservationDetailInfo.reservationInfo.contactTel = memberInfo.hpNo;
        }
        this.reservationDetailInfo.reservationInfo.contactName = memberInfo.memberName;
        this.reservationDetailInfo.reservationInfo.areaCode = memberInfo.areaCode ? memberInfo.areaCode : commonCodeGetComCodeDefaultValue("AREA_CODE");

        // 회원권정보 조회
        this.getReservationDetailMemberLinkList(memberInfo.linkMemberId);

        this.fetchMemberReservationVisitSearch();

        // validator message 갱신
        // this.reNewValidateMessage()

        if (
          this.resveCmpnAuto &&
          !this.reservationDetailInfo.reservationInfo.grpNo &&
          !this.isReservationDetailModifyMode &&
          !this.reservationDetailInfo.reservationTimeLists[0].transName
        ) {
          // 예약자 본인 동반자 셋팅.
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].cmpnName = memberInfo.memberName;
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].memberNo = memberInfo.memberNo;
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].membershipId = memberInfo.membershipId;
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].memberDiv = memberInfo.memberDiv;
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].memberGrade = memberInfo.memberGrade;
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].sexCode = memberInfo.sexCode;
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].contactTel = memberInfo.hpNo;
        }
      }
    },
    async getReservationDetailMemberLinkList(linkMemberId) {
      try {
        const data = await GolfErpAPI.fetchLinkMembership(linkMemberId);
        this.memberLinkList = data;
      } catch (e) {
        console.log("getReservationDetailMemberLinkList.err.===>", e);
      }
    },
    setResvNonMemberInfos() {
      // 연계회원 clear
      this.memberLinkList = [];

      if (this.reservationDetailInfo.reservationInfo) {
        this.reservationDetailInfo.reservationInfo.membershipId = null;
        this.reservationDetailInfo.reservationInfo.memberNo = null;
        this.reservationDetailInfo.reservationInfo.sexCode = "M";
        this.reservationDetailInfo.reservationInfo.memberDiv = "NOM";
        this.reservationDetailInfo.reservationInfo.memberGrade = commonCodesGetJsonData(
          "MEMBER_DIV",
          "NOM"
        ).find((codeJson) => codeJson.IDX === 4).ATTRB;
        this.reservationDetailInfo.reservationInfo.contactName = this.reservationDetailInfo.reservationInfo.resveName;
        this.reservationDetailInfo.reservationInfo.areaCode = commonCodeGetComCodeDefaultValue("AREA_CODE");

        this.fetchMemberReservationVisitSearch();

        // validator message 갱신
        // this.reNewValidateMessage()

        if (
          this.resveCmpnAuto &&
          !this.reservationDetailInfo.reservationInfo.grpNo &&
          !this.isReservationDetailModifyMode &&
          !this.reservationDetailInfo.reservationTimeLists[0].transName
        ) {
          // 예약자 본인 동반자 셋팅.
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].cmpnName = this.reservationDetailInfo.reservationInfo.resveName;
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].memberNo = "";
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].membershipId = "";
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].memberDiv = "NOM";
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].memberGrade = commonCodesGetCommonCodeAttrbByCodeAndIdx("MEMBER_DIV", "NOM", 4);
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].sexCode = "M";
        }
      }
    },
    onMemberPopupOpen(
      isOpenedWithButton,
      popupType,
      memberNameNo,
      timeInfoIdx,
      selectedIdx,
      memberDivChanged = false,
      memberGradeChanged = false,
      nomDataViewFlag = true,
    ) {

      this.isMemberSelectPopupOpen = true;

      let memberData = {};
      memberData.memberNameNo = memberNameNo;
      memberData.isOpenedWithButton = isOpenedWithButton;
      memberData.popupType = popupType;
      memberData.memberDivChanged = memberDivChanged;
      memberData.memberGradeChanged = memberGradeChanged;
      if (timeInfoIdx !== null && timeInfoIdx !== undefined) {
        memberData.timeInfoIdx = timeInfoIdx;
      }
      if (selectedIdx !== null && selectedIdx !== undefined) {
        memberData.selectedIdx = selectedIdx;
      }
      memberData.nomDataViewFlag = nomDataViewFlag;
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(memberData);
      });
    },
    onSexCodeChanged(args) {
      if (
        this.resveCmpnAuto &&
        !this.reservationDetailInfo.reservationInfo.grpNo &&
        !this.isReservationDetailModifyMode
      ) {
        this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].sexCode = args.value;
      }
    },
    async onCartKindChanged(args, timeInfo) {
      // 리무진 카트일때만
      if (
        args.value === "LIMOUSINE" &&
        args.value !== this.reservationDetailInfoOriginal?.reservationTimeLists?.find(item => item.timeId === timeInfo.timeId)?.cartKind
      ) {
        const cartAbleCnt = await GolfErpAPI.fetchReservationCartCount({
          resveDate: timeInfo.resveDate,
          cartKind: args.value,
        });

        if (!(cartAbleCnt > 0)) {
          this.infoToast("당일 예약 카트가 총 카트 양을 넘었습니다");
        }
      }
    },
    onReservationInfoMemberGradeDropdownListChanged (args) {
      if (
        args.e instanceof MouseEvent ||
        args.e instanceof KeyboardEvent ||
        args.e instanceof TouchEvent
      ) {
        if (
          this.resveCmpnAuto &&
          !this.reservationDetailInfo.reservationInfo.grpNo &&
          !this.isReservationDetailModifyMode
        ) {
          // 예약자 본인 동반자 셋팅.
          this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].memberGrade = args.value;
        }
      }
    },
    onReservationInfoMemberDivDropdownListChanged(args) {
      if (
        args.e instanceof MouseEvent ||
        args.e instanceof KeyboardEvent ||
        args.e instanceof TouchEvent
      ) {
        if (args.value === "NOM") {
          let commonCodeJsonDataStr = this.reservationViewOptions.memberDivOptions.find((commonCode) => commonCode.comCode === this.reservationDetailInfo.reservationInfo.memberDiv).jsonData;
          if (commonCodeJsonDataStr) {
            let commonCodeJsonDataArray = JSON.parse(commonCodeJsonDataStr);
            if (Array.isArray(commonCodeJsonDataArray)) {
              let memberGradeDefaultValue = commonCodeJsonDataArray.find((jsonData) => jsonData.IDX === 4);
              if (memberGradeDefaultValue && memberGradeDefaultValue.ATTRB) {
                this.reservationDetailInfo.reservationInfo.membershipId = "";
                this.reservationDetailInfo.reservationInfo.memberNo = "";
                this.reservationDetailInfo.reservationInfo.memberGrade = memberGradeDefaultValue.ATTRB;

                if (this.resveCmpnAuto && !this.reservationDetailInfo.reservationInfo.grpNo && !this.isReservationDetailModifyMode) {
                  // 예약자 본인 동반자 셋팅.
                  this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].memberGrade = memberGradeDefaultValue.ATTRB;
                  this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].membershipId = "";
                  this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].memberNo = "";
                  this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].memberDiv = args.value;
                }
              }
            }
          }
        } else {
          // 회원구분이 비회원이 아니면 동명이인 회원 검색 팝업 호출.
          this.onMemberPopupOpen(false, this.memberPopupType.RESV, this.reservationDetailInfo.reservationInfo.resveName, null, null, true);
        }
      }
    },
    onContactNameChanged() {},
    onResveKindChanged(args) {
      if (args.e instanceof MouseEvent || args.e instanceof KeyboardEvent || args.e instanceof TouchEvent) {
        this.reservationCheck(null, null, this.reservationDetailInfo.reservationInfo.membershipId, this.reservationDetailInfo.reservationInfo.resveNo);
      }
    },
    onResveChannelChanged() {},
    addCurrentActiveTimeId(timeId) {
      this.currentActiveTimeIds.push(timeId);
    },
    clearCurrentActiveTime() {
      this.currentActiveTimeIds = [];
    },
    removeCurrentActiveTimeId(timeId) {
      const idx = this.currentActiveTimeIds.indexOf(timeId);
      if (idx > -1) {
        this.currentActiveTimeIds.splice(idx, 1);
      }
    },
    reservationDetailCancelChecked() {
      if (Array.isArray(this.reservationDetailInfo.reservationTimeLists)) {
        if (this.reservationDetailInfo.reservationTimeLists.filter((timeInfo) => timeInfo.resveId).length === this.reservationDetailCancelTimeIds.length) {
          this.reservationViewOptions.reservationDetailCancelTimeIdCheckboxAll = true;
        } else {
          this.reservationViewOptions.reservationDetailCancelTimeIdCheckboxAll = false;
        }
      }
    },
    reservationCancelCheckAllChecked() {
      this.reservationDetailCancelTimeIds = [];
      this.reservationDetailCancelResveNos = [];

      // check all 체크한 경우, 모두 다 넣어 주기
      if (this.reservationViewOptions.reservationDetailCancelTimeIdCheckboxAll) {
        if (Array.isArray(this.reservationDetailInfo.reservationTimeLists)) {
          this.reservationDetailInfo.reservationTimeLists
            .filter((timeInfo) => timeInfo.resveId)
            .forEach((filteredTimeInfo) => {
              this.reservationDetailCancelTimeIds.push(filteredTimeInfo.timeId);
            });
        }
      }
    },
    reservationCompanionGridClicked(args, timeInfoIdx) {
      if (args.column && args.column.field === "cmpnNameIcon") {
        if (
          (this.resveCmpnAuto &&
          !this.reservationDetailInfo.reservationInfo.grpNo &&
          !this.isReservationDetailModifyMode &&
          args.rowData.sno !== 1) ||
          (!this.resveCmpnAuto)
        ) {
          // 시간 셀 클릭 시 팝업 Open
          // alert('회원정보 팝업을 띄운다. 동반자명 입력을 통해 띄운 것과 같은 팝업이나, 선택하지 않고 닫았을 때의 처리는 다르다')
          const gridRefs = this.getReservationCompanionsGridRefs(timeInfoIdx);
          const batchCurrentViewRecords = gridRefs.getBatchCurrentViewRecords();
          if (
            batchCurrentViewRecords[args.rowIndex] &&
            batchCurrentViewRecords[args.rowIndex].cmpnName
          ) {
            this.onMemberPopupOpen(
              true,
              this.memberPopupType.COMP,
              batchCurrentViewRecords[args.rowIndex].cmpnName,
              timeInfoIdx,
              args.rowIndex
            );
          } else {
            this.errorToast("데이터를 다시 확인해 주십시오");
          }
        }
      }
    },
    reservationCompanionGridCellEdit(args) {
      if (
        this.resveCmpnAuto &&
        !this.reservationDetailInfo.reservationInfo.grpNo &&
        !this.isReservationDetailModifyMode
      ) {
        if (args.rowData.sno === 1) {
          args.cancel = true;
        }
      }

      if (args.columnName === "checkbox") {
        args.cancel = true;
      }
    },
    isTimeCanAdd(timeStatus, holdInsertId) {
      if (
        timeStatus === "EMPTY" ||
        (timeStatus === "HOLD" && holdInsertId === parseInt(this.userId)) ||
        (timeStatus === "HOLD" &&
          (holdInsertId === null || holdInsertId === undefined))
      ) {
        return true;
      } else {
        return false;
      }
    },
    isTimeCanView(timeStatus, holdInsertId) {
      if (
        timeStatus === "EMPTY" ||
        timeStatus === "RESVE" ||
        (timeStatus === "HOLD" && holdInsertId === parseInt(this.userId))
      ) {
        return true;
      } else {
        return false;
      }
    },
    async reservationCancelPopupOpen(timeIds,resveIds=null) {
      let cancelPopupData = {};
      cancelPopupData.resveDate = this.selectedRowData.resveDate;
      cancelPopupData.resveCourse = this.selectedRowData.resveCourse;
      cancelPopupData.resveTime = this.selectedRowData.resveTime;
      cancelPopupData.resveNo = this.reservationDetailInfo.reservationInfo.resveNo;
      cancelPopupData.resveName = this.reservationDetailInfo.reservationInfo.resveName;
      cancelPopupData.resveContactTel = this.reservationDetailInfo.reservationInfo.contactTel;
      cancelPopupData.resveMemberDiv = this.reservationDetailInfo.reservationInfo.memberDiv;
      cancelPopupData.smsRecptnDiv = this.reservationDetailInfo.reservationInfo.smsRecptnDiv;
      cancelPopupData.resveMngrName = this.reservationDetailInfo.reservationInfo.resveMngrName;
      cancelPopupData.resveMngrHp = this.reservationDetailInfo.reservationInfo.resveMngrHp;
      cancelPopupData.timeIds = timeIds;
      cancelPopupData.resveIds = resveIds == null?this.reservationDetailInfo.reservationTimeLists.filter(it => timeIds.includes(it.timeId)).map(item=> item.resveId):resveIds;
      cancelPopupData.reservationCourseList = this.reservationCourseLists;
      cancelPopupData.roomStayRecords = this.reservationDetailInfo.roomStayRecords;
      cancelPopupData.depositIncoms = this.reservationDetailInfo.reservationInfo.depositIncoms;
      cancelPopupData.holeDiv = this.reservationDetailInfo.reservationTimeLists[0].holeDiv;
      cancelPopupData.visitCnt = this.reservationDetailInfo.reservationTimeLists[0].visitCnt;
console.log(cancelPopupData);
      const reservationTimes = await GolfErpAPI.fetchReservationTimesByTimeIds(cancelPopupData.timeIds,cancelPopupData.resveIds);
      const reservationTimeFind = reservationTimes.find((reservationTime) => !["RESVE","JOIN","HOLD"].includes(reservationTime.timeStatus));

      if (!!reservationTimeFind && reservationTimeFind.timeStatus !== "RESVE") {
        this.errorToast("자료가 변경되었습니다. 자료를 조회 후 다시 작업 바랍니다.");
        return;
      }

      this.isReservationCancelPopupOpen = true;

      this.$nextTick(() => {
        this.$refs.reservationCancelPopup.openPopup(cancelPopupData);
      });
    },
    onReservationCancelPopupClosed(event) {
      this.isReservationCancelPopupOpen = false;

      if (event && event.isCanceled === true && event.popupData) {
        this.reservationDetailCancelTimeIds = []; // 체크박스 상태 초기화
        this.processCancelTimes(event.popupData.timeIds);
        this.clearMemo();
      }
    },
    async onGroupPopupClosed(event) {
      this.isGroupPopupOpen = false;

      // [단체 Case 분기(항목은 ReservationInfo.java 참고)]
      // 단체 검색된 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우)
      //      : 단체번호, 단체종류, 단체명 설정. 예약자명 = 단체명. 연락자명, 연락처 설정(총무 정보로)
      //      : 회원권 ID, 회원번호 Clear, 성별코드(M)
      //      : 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정
      // 단체 검색되지 않은 경우(검색한 건이 0건이거나, 띄운 단체 팝업을 선택하지 않고 닫는다)
      //      : 단체번호, 단체종류, 단체명 Clear
      //      : 나머지는 그대로 놔둠
      // console.log(JSON.stringify(event, null, 2))
      if (event.popupData.isOpenedWithButton) {
        // 단체검색으로 팝업을 Open 한 경우 : 검색하여 선택한 경우에만 처리함
        if (event.selectedGroup) {
          await this.setGrpInfos(event.selectedGroup);
          this.reservationCheck(null, event.selectedGroup.grpNo, null); // 위약 체크
        }
      } else {
        // 단체 text 창 입력으로 팝업을 Open 한 경우
        if (event.selectedGroup) {
          await this.setGrpInfos(event.selectedGroup);
          this.reservationCheck(null, event.selectedGroup.grpNo, null); // 위약 체크
        } else {
          this.clearGrpInfo();
        }
      }
    },
    onPromotionSearchPopupClose(event) {
      this.isPromotionSearchPopupOpen = false;

      // [프로모션 Case 분기(항목은 ReservationTimeInfo.java 참고)]
      // 프로모션 검색된 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우) : 프로모션ID, 프로모션명 설정
      // 프로모션 검색되지 않은 경우(검색한 건이 0건이거나, 띄운 프로모션 팝업을 선택하지 않고 닫는다) : 프로모션ID, 프로모션명 Clear
      if (event.popupData.isOpenedWithButton) {
        // 버튼으로 팝업 Open : 검색하여 선택한 경우에만 처리
        if (event.selectedPromotion) {
          this.setPromotionInfos(
            event.selectedPromotion,
            event.popupData.timeInfoIdx
          );
        }
      } else {
        // text 창 입력으로 팝업을 Open
        if (event.selectedPromotion) {
          this.setPromotionInfos(
            event.selectedPromotion,
            event.popupData.timeInfoIdx
          );
        } else {
          this.clearPromotionInfos(event.popupData.timeInfoIdx);
        }
      }
    },
    async memberSelectPopupClosed(data) {
      console.log(JSON.stringify(data, null, 2));
      this.isMemberSelectPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기

      if (data.popupData.popupType === this.memberPopupType.RESV) {
        // 예약자명
        if (data.popupData.memberDivChanged) {
          let commonCodeJsonDataStr = this.reservationViewOptions.memberDivOptions.find(
            (commonCode) =>
              commonCode.comCode ===
              this.reservationDetailInfo.reservationInfo.memberDiv
          ).jsonData;
          if (commonCodeJsonDataStr) {
            let commonCodeJsonDataArray = JSON.parse(commonCodeJsonDataStr);
            if (Array.isArray(commonCodeJsonDataArray)) {
              let memberGradeDefaultValue = commonCodeJsonDataArray.find(
                (jsonData) => jsonData.IDX === 4
              );
              if (memberGradeDefaultValue && memberGradeDefaultValue.ATTRB) {
                // 회원구분 변경시 동명이인 회원 검색 팝업 호출후 팝업 그냥 닫으면
                // 회원구분 변경한 값은 그대로 유지.
                // MEMBERSHIP_ID, MEMBER_NO = 기존값 그대로 유지.
                // MEMBER_DIV = 변경한 값
                // MEMBER_GRADE = 회원구분 변경에 따른 값 셋팅.
                this.reservationDetailInfo.reservationInfo.memberGrade =
                  memberGradeDefaultValue.ATTRB;

                if (
                  this.resveCmpnAuto &&
                  !this.reservationDetailInfo.reservationInfo.grpNo &&
                  !this.isReservationDetailModifyMode
                ) {
                  // 예약자 본인 동반자 셋팅.
                  this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].memberGrade = memberGradeDefaultValue.ATTRB;
                  this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].memberDiv = this.reservationDetailInfo.reservationInfo.memberDiv;
                }
              }
            }
          }
        } else if (data.popupData.memberGradeChanged) {
          // 회원등급 변경시 동명이인 회원 검색 팝업 호출후 팝업 그냥 닫으면
          // 회원등급 변경한 값은 그대로 유지.
          // MEMBERSHIP_ID, MEMBER_NO, MEMBER_DIV = 기존값 그대로 유지.
          // MEMBER_GRADE = 변경한 값
        } else {
          const response = await getReservationInfoTable(
            this.reservationOptions.resveDate,
            this.reservationOptions.resveDate,
            null,
            null,
            this.reservationDetailInfo.reservationInfo.resveName,
            null,
            null,
            null
          );
          if(response?.value?.reservationInfoLists) {
            response.value.reservationInfoLists = response.value.reservationInfoLists.filter(item => item.resveStatus == 'RESVE');
          }

          if (response?.value?.reservationInfoLists && response?.value?.reservationInfoLists.length > 0) {
            this.infoToast("해당일자에 같은 예약자명이 있습니다");
          }
          if (!data.popupData.isOpenedWithButton) {
            // textbox를 통해 팝업을 띄웠는데, 선택하지 않고 닫은 경우
            this.setResvNonMemberInfos();
          }
        }
      } else if (data.popupData.popupType === this.memberPopupType.TRANS) {
        // 위임자명
        if (!data.popupData.isOpenedWithButton) {
          // textbox를 통해 팝업을 띄웠는데, 선택하지 않고 닫은 경우
          this.setTransNonMemberInfos(data.popupData.timeInfoIdx);
        }
      } else if (data.popupData.popupType === this.memberPopupType.COMP) {
        // 동반자명
        if (!data.popupData.isOpenedWithButton) {
          // textbox를 통해 팝업을 띄웠는데, 선택하지 않고 닫은 경우)
          this.setCompNonMemberInfos(
            data.popupData.timeInfoIdx,
            data.popupData.selectedIdx
          );
        }
      }
    },
    reservationMovePopupClosed(data) {
      console.log(JSON.stringify(data, null, 2));
      this.isReservationMovePopupOpen = false;

      // data를 체크하여 화면 재조회 여부 결정 TODO 새로 조회해야 할 수도??
      if (data && data.isReload && data.time) {
        this.refreshDetailInfo(
          this.reservationDetailInfo.reservationInfo.resveNo,
          data.time.timeId,
          false,
          data.time.timeStatus,
          data.time.holdInsertId,
          data.time.resveDate
        );

        // 좌측 grid update
        this.getReservationStatusList();
      }
    },
    reservationCopyPopupClosed(data) {
      this.isReservationCopyPopupOpen = false;

      if (data && data.isReload && data.time) {
        // 좌측 grid update
        this.getReservationStatusList();
      }
    },
    async memberSelectPopupConfirmed(data) {
      this.isMemberSelectPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기

      if (data.popupData.popupType === this.memberPopupType.RESV) {

        // 예약자명
        if (data.selectedRowData) {
          if (!data.selectedRowData.membershipId) {
            if (data.selectedRowData.memberName) {
              this.reservationDetailInfo.reservationInfo.resveName = data.selectedRowData.memberName;
            }
            this.setResvNonMemberInfos();
          } else {
            this.setResvMemberInfos(data.selectedRowData);

            // 예약일자가 비수기일 경우 위약 체크를 하지 않음
            if(!this.reservationDetailInfo.reservationTimeLists[0]?.offSeasonFlag) {
              // 위약 체크
              this.reservationCheck(
                  null,
                  null,
                  data.selectedRowData.membershipId
              );
            }
          }

          const response = await getReservationInfoTable(
            this.reservationOptions.resveDate,
            this.reservationOptions.resveDate,
            null,
            null,
            data.selectedRowData.memberName,
            null,
            null,
            null
          );

          if (response?.value?.reservationInfoLists && response?.value?.reservationInfoLists.length > 0) {
            this.infoToast("해당일자에 같은 예약자명이 있습니다");
          }

          await this.$nextTick();
          this.$refs.resveName.focusIn();
        }
      } else if (data.popupData.popupType === this.memberPopupType.TRANS) {

        // 위임자명
        if (data.selectedRowData) {
          this.setTransMemberInfos(
            data.selectedRowData,
            data.popupData.timeInfoIdx
          );
        }
      } else if (data.popupData.popupType === this.memberPopupType.COMP) {

        // 동반자명
        if (data.selectedRowData) {
          this.setCompMemberInfos(
            data.selectedRowData,
            data.popupData.timeInfoIdx,
            data.popupData.selectedIdx
          );
        }
      }
    },
    processCancelTimes(canceledTimeIds) {
      if (
        Array.isArray(this.reservationDetailInfo.reservationTimeLists) &&
        Array.isArray(canceledTimeIds)
      ) {
        // 취소하고 남은 time 목록이 있는지 확인하고, 있으면 재조회 / 없으면 상세 view 끄기
        let remainedTimeList = this.reservationDetailInfo.reservationTimeLists.filter(
          (time) => !canceledTimeIds.includes(time.timeId)
        );
        if (remainedTimeList.length > 0) {
          let firstRemainTime = remainedTimeList[0];
          this.refreshDetailInfo(
            firstRemainTime.resveNo,
            firstRemainTime.timeId,
            false,
            firstRemainTime.timeStatus,
            firstRemainTime.holdInsertId,
            firstRemainTime.resveDate
          );
        } else {
          this.closeDetailView();
        }

        // 좌측 grid update
        this.isQuestionReservationDetailModify = true;

        this.getReservationStatusList();
      }
    },
    changeOpenedTimeId(timeId) {
      // TODO : Holding 풀기, isTimeAdd가 true인 경우에는 풀지 않는다
      this.openedTimeId = timeId;
    },
    closeDetailView(isHoldCancel = true) {
      // 메모 자동오픈이 아닌 사용자가 클릭해서 열리는 방식으로 변경.
      // this.$EventBus.$emit("closeRightMenu"); // Memo 창 닫기
      this.isReservationDetailViewOpened = false;
      this.changeOpenedTimeId(null);

      // holding 해제
      if (isHoldCancel) {
        this.detailHoldingTimesToBlank();
      }

      // 상세 데이터 초기화
      this.DetailDataInitAndSyncWithOriginal();
    },
    async detailHoldingTimesToBlank() {
      let setToBlankTimeList = this.reservationDetailInfo.reservationTimeLists.filter(
        (time) => time.resveNo === null || time.resveNo === undefined
      );

      if (setToBlankTimeList.length > 0) {
        let timeIds = [];
        let holdCancelTimeIds = [];

        setToBlankTimeList.forEach((time) => {
          timeIds.push(time.timeId);
          if (!time?.reservationHold?.holdResn) {
            holdCancelTimeIds.push(time.timeId);
          }
        });

        // 홀딩 사유가 없는 홀딩타임만 홀딩 해제 API 실행되야함.
        if (holdCancelTimeIds.length > 0) {
          const data = await GolfErpAPI.reservationTimesHoldCancel(holdCancelTimeIds);

          if (data.status !== "OK") {
            if (data.status === "HOLD_TIME_EXIST") {
              // 홀딩 사유가 없어도 무조건 홀딩 팝업과 infoToast 뜨도록 수정.
              this.isHoldTimeViewPopupOpen = true;
              this.$nextTick(() => {
                this.$refs.holdTimeViewPopup.showHoldTimeViewPopup(timeIds[0]);
              });
              for (let i = 0; i < this.reservationCourseLists.length; i++) {
                this.$refs.reservationGrid[i].clearSelection();
              }
              this.infoToast("홀딩된 시간입니다.");
              this.closeDetailView(false);
            }
            throw data.status;
          }
        }

        timeIds.forEach((timeId) => {
          this.holdTimeColumnSetting(timeId, false);
        });
      }

      return true;
    },
    async releaseHoldTimeStatus(time) {
      let timeTableRequest = {timeTableRequestList: [],};
      timeTableRequest.timeTableRequestList.push({
        optFlag: time.optFlag,
        orgTimeStatus: time.orgTimeStatus,
        timeId: time.timeId,
        timeStatus: time.timeStatus,
        timeStatusFlag: false,
      });

      try {
        await saveTimeStatusSetting("HOLD", timeTableRequest);

        timeTableRequest.timeTableRequestList.forEach((time) => {
          this.holdTimeColumnSetting(time.timeId, false);
        });
      } catch (e) {
        console.log("saveTimeStatusSetting.err.===>", e);
      }
    },
    onContactTelInfoChange() {
      if (
        this.resveCmpnAuto &&
        !this.reservationDetailInfo.reservationInfo.grpNo &&
        !this.isReservationDetailModifyMode &&
        !this.reservationDetailInfo.reservationTimeLists[0].transName
      ) {
        // 예약자 본인 동반자 셋팅.
        this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].contactTel =
          this.reservationDetailInfo.reservationInfo.contactTel !== null
            ? this.reservationDetailInfo.reservationInfo.contactTel
            : "";
        this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions =
          JSON.parse(JSON.stringify(this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions));
      }
    },
    onTransContactTelInfoChange() {
      if (
          this.resveCmpnAuto &&
          !this.reservationDetailInfo.reservationInfo.grpNo &&
          !this.isReservationDetailModifyMode
      ) {
        // 예약자 본인 동반자 셋팅.
        this.reservationDetailInfo.reservationTimeLists[0].reservationCompanions[0].contactTel =
            this.reservationDetailInfo.reservationInfo.contactTel !== null
                ? this.reservationDetailInfo.reservationTimeLists[0].contactTel
                : "";
      }
    },
    onContactTelInfoBlur() {
      const value = this.reservationDetailInfo.reservationInfo.contactTel;
      if ((this.changeContactTel === null ? "" : this.changeContactTel) !== value) {
        // TODO : maskedTextBox의 위약 처리는 blur 이벤트에서 함(textbox와 달리, @change 이벤트에서 focus out 되는 상황을 잡아낼 수 없기 때문)
        this.reservationCheck(value, null, null);
      }
      this.changeContactTel = value;
    },
    async reservationCheck(contactTel, grpNo, membershipId, resveNo) {
      if (!contactTel && !grpNo && !membershipId) {
        return;
      }

      const logParam = {
        contactTel : contactTel,
        grpNo : grpNo,
        membershipId : membershipId,
        resveDate : this.reservationOptions.resveDate,
        resveKind : this.reservationDetailInfo.reservationInfo.resveKind,
        resveNo : resveNo,
      };

      console.log("위약 체크 내용", logParam);

      // 위약 체크
      const {
        value: {
          penalties = [],
          resveMessage
        }
      } = await getReservationCheck(
        contactTel,
        grpNo,
        membershipId,
        this.reservationOptions.resveDate,
        this.reservationDetailInfo.reservationInfo.resveKind,
        resveNo,
      );

      if (resveMessage) {
        this.infoToast(resveMessage);
      }

      if (0 < penalties.length) {
        await this.blacklistPopupOpen(penalties);
      }
    },
    /*
        getReservationCheckAPI(contactTel, grpNo, membershipId) {
          console.log(`contactTel: ${contactTel}, grpNo: ${grpNo}, membershipId:${membershipId}`)
          return {
            'penaltyMessage': '√위약으로 인하여 예약정지 상태입니다. 예약정지기간 : 20190101 ~ 20190431\r\r확인해 주십시오.',
            'resveMessage': '√동일한 일자에 예약된 타임이 있습니다'
          }
        },
        */
    async viewDailyReservation() {
      if (this.isReservationDetailModifyMode) {
        this.errorToast("추가 중에는 진행하실 수 없습니다");
        return;
      }
      if (this.isDetailDataModified()) {
        // 수정여부 체크
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      this.isDailyReservationPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.dailyReservationPopup.showDailyReservationPopup(
          this.reservationOptions.resveDate,
          this.reservationOptions.aditTeamFlag
        );
      });
    },
    onDailyReservationPopupConfirm(data) {
      this.reservationOptions.bsnDate = data.selectRowData.resveDate;
    },
    onDailyReservationPopupClose(event) {
      this.isDailyReservationPopupOpen = false;
      if (event && event.isReload === true) {
        // 좌측 grid update
        this.getReservationStatusList();
      }
    },
    async viewCalendar() {
      if (this.isReservationDetailModifyMode) {
        this.errorToast("추가 중에는 진행하실 수 없습니다");
        return;
      }
      if (this.isDetailDataModified()) {
        // 수정여부 체크
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      this.viewReservationCalendarPopup();
    },
    async timeGeneration() {
      if (this.isReservationDetailModifyMode) {
        this.errorToast("추가 중에는 진행하실 수 없습니다");
        return;
      }
      if (this.isDetailDataModified()) {
        // 수정여부 체크
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      this.isReservationTimeCreatePopupOpen = true;
      this.$nextTick(() => {
        this.$refs.reservationTimeCreatePopup.showReservationTimeCreatePopup(
          this.reservationOptions.resveDate,
          this.reservationOptions.resveDate
        );
      });
    },
    onReservationTimeCreatePopupClose(event) {
      this.isReservationTimeCreatePopupOpen = false;
      if (event && event.isReload === true) {
        // 좌측 grid update
        this.getReservationStatusList();
      }
    },
    async webTimeSetting() {
      if (this.isReservationDetailModifyMode) {
        this.errorToast("추가 중에는 진행하실 수 없습니다");
        return;
      }
      if (this.isDetailDataModified()) {
        // 수정여부 체크
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      this.isWebTimeSettingPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.webTimeSettingPopup.showWebTimeSettingPopup(
          this.reservationOptions.resveDate,
          this.reservationOptions.resveDate
        );
      });
    },
    async agencySetting() {
      const selectRecords = this.$refs.reservationGrid.map(grid => {
        return grid.getBatchCurrentViewRecords()
          .filter(item => item.select);
      }).flat();
      console.log("selectRecords.===>", selectRecords);

      if (!(selectRecords.length > 0)) {
        this.errorToast("선택된 예약 타임이 없습니다");
        return;
      }

      const agncyOpenTimes = selectRecords.filter(record => record.agncyOpenFlag);

      if (agncyOpenTimes.length > 0) {
        if (!(await this.confirm("이미 설정된 대행사는 초기화 후 재설정됩니다.<br>진행하시겠습니까?"))) {
          return;
        }
      }

      this.isAgencySettingPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.agencySettingPopup.showPopup({
          timeIds: selectRecords.map(record => record.timeId),
          isAgencyOpenTimes: agncyOpenTimes.length > 0,
        });
      });
    },
    async blacklistPopupOpen(blacklistList) {
      this.isBlacklistPopupOpen = true;
      await this.$nextTick();
      await this.$refs.blacklistPopup.showBlackListPopup({
        reservationTimeList: JSON.parse(JSON.stringify(this.reservationDetailInfo.reservationTimeLists)),
        reservationInfo: Object.assign({}, this.reservationDetailInfo.reservationInfo),
        blacklistList
      });
    },
    onWebTimeSettingPopupClose(event) {
      this.isWebTimeSettingPopupOpen = false;
      if (event && event.isReload === true) {
        // 좌측 grid update
        this.getReservationStatusList();
      }
    },
    onAgencySettingPopupOpenClose(event) {
      this.isAgencySettingPopupOpen = false;
      if (event && event.isReload === true) {
        // 좌측 grid update
        this.getReservationStatusList();
      }
    },
    onBlacklistPopupClose() {
      this.isBlacklistPopupOpen = false;
    },
    async reservationHistory() {
      if (this.isReservationDetailModifyMode) {
        this.errorToast("추가 중에는 진행하실 수 없습니다");
        return;
      }
      if (this.isDetailDataModified()) {
        // 수정여부 체크
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      if (!this.selectedRowData) {
        // 선택여부 체크
        this.errorToast("먼저 데이터를 선택해 주세요");
        return;
      }
      this.viewReservationHistoryPopup();
    },
    async viewReservationInfo() {
      if (this.isReservationDetailModifyMode) {
        this.errorToast("추가 중에는 진행하실 수 없습니다");
        return;
      }
      if (this.isDetailDataModified()) {
        // 수정여부 체크
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      this.isReservationInfoPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.reservationInfoPopup.showReservationInfoPopup(
          moment(this.reservationOptions.resveDate).format("YYYY-MM-01"),
          moment(this.reservationOptions.resveDate).add(3, "M").subtract(1, "days").format("YYYY-MM-DD"),
          this.reservationDetailInfo.reservationInfo.resveName,
          this.reservationDetailInfo.reservationInfo.memberNo
        );
      });
    },
    viewReservationCalendarPopup() {
      this.isReservationCalendarPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.reservationCalendarPopup.showReservationCalendarPopup(
          this.reservationOptions.resveDate,
          this.reservationOptions.aditTeamFlag
        );
      });
    },
    viewReservationHistoryPopup() {
      this.isReservationHistoryPopupOpen = true;
      const popupData = {
        timeId: this.selectedRowData.timeId,
        resveDate: this.selectedRowData.resveDate,
        resveCourse: this.selectedRowData.resveCourse,
        resveTime: this.selectedRowData.resveTime,
      };
      this.$nextTick(() => {
        this.$refs.reservationHistoryPopup.showReservationHistoryPopup(
          popupData
        );
      });
    },
    onReservationInfoPopupClose(event) {
      this.isReservationInfoPopupOpen = false;
      if (event && event.isReload === true) {
        // 좌측 grid update
        this.getReservationStatusList();
      }
    },
    onReservationCalendarPopupClosed(argDate) {
      this.isReservationCalendarPopupOpen = false;

      if (argDate) {
        this.reservationOptions.resveDate = getFormattedDate(argDate);

        this.getReservationStatusList();
      }
    },
    onReservationHistoryPopupClosed() {
      this.isReservationHistoryPopupOpen = false;
    },
    depositIncomListGridqueryCellInfo(args) {
      const column = args.column.field;
      const cellData = args.data;
      if (column === "delStatus") {
        if (cellData.returnFlag) {
          args.cell.style.color = commonCodesGetColorValue("APPROVAL_DIV", "CANCEL");
        }
      }

      if (cellData.delFlag) {
        args.cell.style.setProperty("text-decoration", "line-through");
      }
    },
    onDepositIncomListGridDataBound() {
      if (this.reservationDetailInfo.reservationInfo.depositIncoms?.length > 0) {
        this.$refs.paymentListGrid.selectRow(0);
      }
    },
    shortcutMenuSelected(args) {
      if (args.item.id === 1) {
        let routeData = this.$router.resolve({
          name: "waitingReservationRegistration",
          query: {
            resveDate: this.reservationOptions.resveDate,
          },
        });
        openNewWindow(routeData);
      } else if(args.item.id === 2) {

       const [data] =  Object.values(this.courseCodeFilter).map((data)=>{
          return this.$refs["reservationGrid"][data-1].getSelectedRecords();
        }).filter((data)=> data.length )[0];

        let routeData = this.$router.resolve({
          name: "ReservationGuestBookPrint",
          query: {
            bsnDate: data.resveDate,
            timeId: data.timeId,
            resveName: data.orgResveName
          },
        });
        openNewWindow(routeData);
      }
    },
    async onReservationInfoPopupConfirm(data) {
      if (data.timeId) {
        let dataBoundFlag = false;

        this.resveDateChangeApiFlag = true;

        this.reservationInfoPopupConfirmTimeId = data.timeId;

        if (this.reservationOptions.resveDate !== data.resveDate) {
          dataBoundFlag = true;

          // 그리드 선택을 위함. onDataBound() 에서 그리드 rowSelect 처리
          this.openedTimeId = data.timeId;

          // 일자별 예약 현황 리스트에 해당 일자가 없으면 재조회 한다.
          if (
            !(this.reservationDailyStatusGridOptions.data.filter(filterData => getFormattedDate(filterData.resveDate, "YYYYMMDD") === getFormattedDate(data.resveDate, "YYYYMMDD")).length > 0)
          ) {
            this.reservationOptions.bsnDate = data.resveDate;
            this.reservationOptions.resveDate = data.resveDate;

            await this.searchReservation();
          } else {
            this.reservationOptions.resveDate = data.resveDate;

            // 일자별 예약 현황 Grid Refresh 후 해당 일자 selectRow() 하기 위함.
            this.isReservationDailyStatusGridAutoSelectRow = false;

            this.$refs.reservationDailyStatus.refresh();
          }
        }

        if (!dataBoundFlag) {
          // 예약정보 조회 팝업의 확인 버튼 클릭후 예약일자가 같아서 재조회하지 않고 기존 그리드에서 해당 데이터 찾아가는 부분.
          this.resveDateChangeApiFlag = false;

          for (let i = 0; i < this.reservationCourseLists.length; i++) {
            const rowIndex = this.$refs.reservationGrid[i].getRowIndexByPrimaryKey(data.timeId);

            if (rowIndex !== -1) {
              this.$refs.reservationGrid[i].selectRow(rowIndex);
              const selectedRecords = this.$refs.reservationGrid[i].getSelectedRecords();
              const eventData = {
                column: {field: "resveName0",},
                rowData: selectedRecords[0],
              };

              this.reservationGridRecordClicked(eventData, i, true);
              return;
            }
          }
        }
      }
    },
    async fetchReservationTimes() {
      // 예약접수 등록 > Tee-Off 목록 조회.
      this.reservationCourseCount = 0;

      const courseCodes = commonCodesGetCommonCode("COURSE_CODE", true);

      const reservationTimesTemp = await GolfErpAPI.fetchReservationTimes({
        resveDate: this.reservationOptions.resveDate,
        blankFlag: this.reservationOptions.blankFlag,
        aditTeamFlag: this.reservationOptions.aditTeamFlag,
        isPriceView: this.reservationOptions.paymtPrice
      });
// roy 조인 예약 시스템으로 한 타임에 여려 예약이 들어옴.. api 에서 수정보다 프런트에서 내용을 방아서 다시 정리하여 그리드에 바인딩함...
      reservationTimesTemp.filter(it=>it.reservationConfirm).forEach(it=>{
        it.timeIdResevId = it.timeId;
        it.timeId = it.reservationConfirm.timeId;
      });
      // 타임아이디로 그룹핑
      const reservationTimesGroupByTiemId = _groupBy(reservationTimesTemp,'timeId');
      const reservationTimes = [];
      // 그룹핑된 데이타를 그리드 데이타소스에 push
      // console.log(reservationTimesGroupByTiemId);
      Object.keys(reservationTimesGroupByTiemId).forEach(timeId=>{
        const item = reservationTimesGroupByTiemId[timeId][0];
          let indexResve = 0;
          let resveRemarks = "",grpName ="",comfirmVisitCnt =0;

          reservationTimesGroupByTiemId[timeId].forEach((data)=>{
              for (let i = 0; i < data.reservationConfirm?.visitCnt; i++) {
                if(i==0) {
                  item[`colSpan${indexResve}`] = data.reservationConfirm.visitCnt * 2-1;
                }
                  item[`resveName${indexResve}`] = (commonCodesGetCodeAbrv("MEMBER_DIV", data.reservationConfirm.reservationMember.memberDiv)
                      ? commonCodesGetCodeAbrv("MEMBER_DIV", data.reservationConfirm.reservationMember.memberDiv) + " "
                      : "") + data.reservationConfirm.reservationMember.resveName;
                  item[`resveNo${indexResve}`] = data.reservationConfirm.resveNo;
                item[`memoFlag${indexResve}`] = data.memoFlag;
                item[`newMemoFlag${indexResve}`] = data.newMemoFlag;
                item[`resveId${indexResve}`] = data.reservationConfirm.resveId;
                item[`grpKind${indexResve}`] = data.reservationConfirm.reservationMember?.grpKind||"";
                item[`memberDiv${indexResve}`] = data.reservationConfirm.reservationMember?.memberDiv;

                indexResve ++;
              }
              comfirmVisitCnt+=data.reservationConfirm?.visitCnt;
              if(item.reservationConfirm?.reservationMember.resveRemarks) {
                resveRemarks += data.reservationConfirm?.reservationMember.resveRemarks||"" +" ";
              }
              if(item.reservationConfirm?.reservationMember.grpName) {
                grpName += data.reservationConfirm?.reservationMember.grpName||"" +" ";
              }
          });
          item[`comfirmVisitCnt`] = comfirmVisitCnt;
          item[`resveRemarks`] = resveRemarks;
          item[`grpName`] = grpName;
          reservationTimes.push(item);
      });

      let reservationCourseLists = [];

      courseCodes.forEach((courseCode) => {
        const reservationCourseList = _sortBy(reservationTimes.filter((data) => data.resveCourse === courseCode.comCode), "resveTime");

        const partOneTeamList = reservationCourseList.filter(
          (data) => data.partDiv === "1" && !!(data.reservationConfirm ? data.reservationConfirm.resveId : null)
        );
        const partTwoTeamList = reservationCourseList.filter(
          (data) => data.partDiv === "2" && !!(data.reservationConfirm ? data.reservationConfirm.resveId : null)
        );
        const partThreeTeamList = reservationCourseList.filter(
          (data) => data.partDiv === "3" && !!(data.reservationConfirm ? data.reservationConfirm.resveId : null)
        );
        //올림픽에서 계산이 이상해서 뺌 roy 단체 객수 수정 필요
        // const groupTeamList = reservationCourseList.filter(
        //   (data) => !!(data.reservationConfirm ? data.reservationConfirm.resveId : null) && (data.reservationConfirm.reservationMember ? data.reservationConfirm.reservationMember.grpKind : null) !== "FIT"
        // );
        // const personTeamList = reservationCourseList.filter(
        //   (data) =>
        //     !!(data.reservationConfirm ? data.reservationConfirm.resveId : null) &&
        //     (data.reservationConfirm.reservationMember ? data.reservationConfirm.reservationMember.grpKind : null) === "FIT"
        // );
        const resveTeamList = reservationCourseList.filter(
          (data) =>
            !!(data.reservationConfirm ? data.reservationConfirm.resveId : null)
        );

        let partDiv = null;
        let beforeChangePay = null;
        let no = 0;

        const reservationCourse = {
          resveCourseCode: courseCode.comCode,
          resveCourseName: courseCode.comName,
          reservationLists: _sortBy(
            reservationCourseList.map((reservationTime) => {
              const partDivLinFlag = partDiv === null ? false : partDiv !== reservationTime.partDiv ? true : false;
              no = partDivLinFlag ? 1 : no + 1;
              partDiv = reservationTime.partDiv;
              return {
                ...reservationTime,
                partDivLineFlag: partDivLinFlag,
                no: no,
                resveId: reservationTime.reservationConfirm ? reservationTime.reservationConfirm.resveId : null,
                resveStatus: reservationTime.reservationConfirm ? reservationTime.reservationConfirm.resveStatus : null,
                orgResveName: !!reservationTime.reservationConfirm && !!reservationTime.reservationConfirm.reservationMember ? reservationTime.reservationConfirm.reservationMember.resveName : null,
                grpKind: !!reservationTime.reservationConfirm && !!reservationTime.reservationConfirm.reservationMember ? reservationTime.reservationConfirm.reservationMember.grpKind : null,
                insertId: !!reservationTime.reservationConfirm && !!reservationTime.reservationConfirm.reservationMember ? reservationTime.reservationConfirm.reservationMember.insertId : null,
                insertName: reservationTime.insertName,
                memberDiv: !!reservationTime.reservationConfirm && !!reservationTime.reservationConfirm.reservationMember ? reservationTime.reservationConfirm.reservationMember.memberDiv : null,
                memberNo: !!reservationTime.reservationConfirm && !!reservationTime.reservationConfirm.reservationMember ? reservationTime.reservationConfirm.reservationMember.memberNo : null,
                resveRemarks: reservationTime.timeStatus === "HOLD" ? !!reservationTime.reservationHold && !!reservationTime.reservationHold.holdResn ? reservationTime.reservationHold.holdResn : null : reservationTime.timeStatus === "BLOCK" ? reservationTime.remarks ? reservationTime.remarks : null : !!reservationTime.reservationConfirm && !!reservationTime.reservationConfirm.reservationMember ? reservationTime.resveRemarks : null,
                holdResn: !!reservationTime.reservationHold && !!reservationTime.reservationHold.holdResn ? reservationTime.reservationHold.holdResn : null,
                promtnName: reservationTime.reservationPromotion ? reservationTime.reservationPromotion.promtnName : null,
                comfirmVisitCnt:  reservationTime.comfirmVisitCnt,
                visitCnt:  reservationTime.visitCnt, //reservationTime.reservationConfirm ? reservationTime.reservationConfirm.visitCnt :

                // resveChannel: reservationTime?.reservationConfirm?.reservationMember?.resveChannel ? reservationTime?.reservationConfirm?.reservationMember?.resveChannel : null,
                // resveNo: reservationTime.reservationConfirm ? reservationTime.reservationConfirm.resveNo : null,
                // resveName:
                //   !!reservationTime.reservationConfirm && !!reservationTime.reservationConfirm.reservationMember
                //     ? (commonCodesGetCodeAbrv("MEMBER_DIV", reservationTime.reservationConfirm.reservationMember.memberDiv)
                //         ? commonCodesGetCodeAbrv("MEMBER_DIV", reservationTime.reservationConfirm.reservationMember.memberDiv) + " " : "") + reservationTime.reservationConfirm.reservationMember.resveName
                //     : reservationTime.agncyOpenFlag || reservationTime.coprtnOpenFlag ? reservationTime.agencyAbrvs : null,
                // selfOpenFlag: reservationTime?.reservationConfirm ? reservationTime?.reservationConfirm?.selfFlag : reservationTime.selfOpenFlag,
                // resveInfo: !!reservationTime.reservationConfirm && !!reservationTime.reservationConfirm.reservationMember ? reservationTime.reservationConfirm.reservationMember.resveInfo : null,
                // grpName: !!reservationTime.reservationConfirm && !!reservationTime.reservationConfirm.reservationMember ? reservationTime.reservationConfirm.reservationMember.grpName : null,
                // cancelDiv: reservationTime.reservationConfirm ? reservationTime.reservationConfirm.cancelDiv : null,
                // contactTel: !!reservationTime.reservationConfirm && !!reservationTime.reservationConfirm.reservationMember ? reservationTime.reservationConfirm.reservationMember.contactTel : null,
                // resveCmpnFlag: reservationTime?.reservationConfirm?.reservationCmpns?.filter(data => !(data.cmpnName === reservationTime?.reservationConfirm?.reservationMember?.resveName && data.contactTel === reservationTime?.reservationConfirm?.reservationMember?.contactTel)).length > 0 ? true : false,
                // vipFlag: reservationTime?.reservationConfirm?.reservationMember?.vipFlag ? reservationTime?.reservationConfirm?.reservationMember?.vipFlag : false,
              };
            }),
            "resveTime"
          ).map((item) => {
            if(!this.reservationOptions.paymtPrice) {
              return item;
            }
            const colorPaymt = (beforeChangePay && beforeChangePay !== item.paymtPrice);
            beforeChangePay = JSON.parse(JSON.stringify(item.paymtPrice));
            return {
              ...item,
              select: false,
              colorPaymt
            };
          }),

          reservationTeamCount: {
            partOneTeamCnt: partOneTeamList.length,
            partOneAllTeamCnt: reservationCourseList?.filter(item => item.partDiv === '1' && item.timeStatus !== 'BLANK')?.length,
            partTwoTeamCnt: partTwoTeamList.length,
            partTwoAllTeamCnt: reservationCourseList?.filter(item => item.partDiv === '2' && item.timeStatus !== 'BLANK')?.length,
            partThreeTeamCnt: partThreeTeamList.length,
            partThreeAllTeamCnt: reservationCourseList?.filter(item => item.partDiv === '3' && item.timeStatus !== 'BLANK')?.length,
            // groupTeamCnt: groupTeamList.length,
            // personTeamCnt: personTeamList.length,
            resveTeamCnt: resveTeamList?.filter(item => item.chkinStatus !== 'RAIN')?.length,
            totalTeamCnt: reservationCourseList.length,
            restTeamCnt: reservationCourseList?.filter(item => item.timeStatus !== 'BLANK')?.length - resveTeamList.length,
          },
        };

        reservationCourseLists.push(reservationCourse);
      });

      this.reservationCourseLists = reservationCourseLists;

      const sameList = this.reservationCourseLists.filter((data) => {
        if (data.reservationLists.length === 0) {
          return false;
        }
        if (this.reservationCourseLists[0]) {
          if (this.reservationCourseLists[0].reservationLists.length === data.reservationLists.length) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }).length;
      this.isSameRow = sameList === 0 ? false : sameList === this.reservationCourseLists.length ? true : false;
      this.$nextTick(() => {
        this.reservationCourseLists.forEach((data, idx) => {
          const div = document.getElementById(`${idx}`);

          if (div) {
            div.removeEventListener("wheel", this.onWhl, true);
            div.removeEventListener("scroll", this.onScl, true);
            div.addEventListener("wheel", this.onWhl, true);
            div.addEventListener("scroll", this.onScl, true);
          }
        });

        const getCourseIdx = course => Number(this.$refs[`resveCourseCode${course}`][0].id);
        if (this.autoSelectStackInfo) {
          const courseIdx = getCourseIdx(this.autoSelectStackInfo.timeInfo.resveCourse);
          this.reservationCourseDivClicked(courseIdx);
          this.changeOpenedTimeId(this.autoSelectStackInfo.timeInfo.timeId);

          const currentViewRecords = this.$refs.reservationGrid[this.reservationCourseActiveIndex].getCurrentViewRecords();
          const idxToSelect = currentViewRecords.findIndex((record) => record.timeId === this.autoSelectStackInfo.timeInfo.timeId);
          if (idxToSelect >= 0) {
            this.$refs.reservationGrid[this.reservationCourseActiveIndex].selectRow(idxToSelect);
            this.$refs.reservationGrid[this.reservationCourseActiveIndex].selectCell({ rowIndex: idxToSelect,  field : "resveName0" }, false);
          }
        }
        if (this.reservationCourseSpecifyIndex) {
          const courseIdx = getCourseIdx(this.reservationCourseSpecifyIndex);
          this.reservationCourseDivClicked(courseIdx);
          this.resveName0 = null;
        }
      });
      // totalCount 계산
      if (this.reservationCourseLists && this.reservationCourseLists.length > 0) {
        this.reservationCourseCount = 0;
        this.reservationCourseLists.forEach((reservationCourse) => {
          if (reservationCourse.reservationLists && reservationCourse.reservationLists.length > 0) {
            this.reservationCourseCount += reservationCourse.reservationLists.length;
          }
        });
      }

      // 일마감 여부 확인.
      await GolfErpAPI.fetchCloseCheck({
        bsnDateFrom: this.reservationOptions.resveDate,
        bsnDateTo: this.reservationOptions.resveDate,
        storeCode: "FRONT",
      });

      this.clearMemoBySelectedRowIndex();
    },
    clearMemoBySelectedRowIndex() {
      for (let i = 0; i < this.reservationCourseLists.length; i++) {
        const selecteRowIndexes = this.$refs.reservationGrid[i].getSelectedRowIndexes();
        if (selecteRowIndexes.length < 1) {
          this.clearMemo();
        }
      }
    },
    async fetchReservationTimesDetail(timeId, resveNo, field) {
      const prevTimeId = this.openedTimeId;
      const prevResveNo = this.reservationDetailInfo.reservationInfo.resveNo;

      // 어떠한 타이밍으로 인해 이전 openedTimeId로 예약정보 상세가 오픈되는 현상을 막기 위함.
      this.changeOpenedTimeId(null);

      if (!this.isReservationDetailModifyMode) {
        try {
          await this.detailHoldingTimesToBlank();
        } catch (e) {
          console.log("fetchReservationTimesDetail.detailHoldingTimesToBlank.err.===>", e);
          this.changeOpenedTimeId(prevTimeId);
          return;
        }
      }

      const args = {timeId: timeId, resveNo: resveNo,};

      const data = await GolfErpAPI.fetchReservationTimesDetail(args);

      let depositIncoms = [];
      // PG 결제 정보 조회
      if(resveNo) {
        depositIncoms = (await GolfErpAPI.fetchDepositIncomsByResveNo(resveNo)).map(
            (data) => ({
              ...data,
              delStatus: data.delFlag ? "취소" : "정상",
            })
        );
      }

      // 아래 주석처리 부분 때문에 홀딩 사유 팝업이 안뜨고 있음.
      // 내가 작업했는데 왜 이렇게 되어있는지 확인 안됨.(한재민)
      // if (!(data.data.length > 0)) {
      //   this.errorToast("자료가 변경되었습니다. 자료를 조회 후 다시 작업 바랍니다.");
      //   if (!!prevTimeId && !prevResveNo) {
      //     this.fetchReservationTimesDetail(prevTimeId, prevResveNo);
      //   }
      //   return;
      // }

      if (data.status === "FAIL") {
        if (!!prevTimeId && !prevResveNo) {
          this.isfetchReservationTimesDetailError = true;
          this.fetchReservationTimesDetail(prevTimeId, prevResveNo);
        } else {
          this.closeDetailView();
        }
        return;
      }

      if (data.status === "RESVE_TIME_EXIST") {
        // 이전 선택한 타임이 있으면 다시 Holding을 걸기위해 재조회함.
        // 빈타임만 홀딩이 필요. 예약번호가 있으면 재조회 안함.
        if (!!prevTimeId && !prevResveNo) {
          this.isfetchReservationTimesDetailError = true;
          this.fetchReservationTimesDetail(prevTimeId, prevResveNo);
        } else {
          this.closeDetailView();
        }
        return;
      }

      // 홀딩된 타임을 모든 사용자가 예약 가능
      // 홀딩 사유가 없는 홀딩은 예약 불가(API에선 홀딩 사유가 없는 홀딩인 경우에만 status = "HOLD_TIME_EXIST" 발생)
      if (data.status === "HOLD_TIME_EXIST") {
        // 홀딩 사유가 없어도 무조건 홀딩 팝업과 infoToast 뜨도록 수정.
        this.isHoldTimeViewPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.holdTimeViewPopup.showHoldTimeViewPopup(timeId);
        });
        for (let i = 0; i < this.reservationCourseLists.length; i++) {
          this.$refs.reservationGrid[i].clearSelection();
        }
        this.infoToast("홀딩된 시간입니다.");

        if (!!prevTimeId && !prevResveNo) {
          this.isfetchReservationTimesDetailError = true;
          this.fetchReservationTimesDetail(prevTimeId, prevResveNo);
        } else {
          this.closeDetailView();
        }

        return;
      }

      if (data.status === "BLANK_TIME_EXIST") {
        if (!!prevTimeId && !prevResveNo) {
          this.isfetchReservationTimesDetailError = true;
          this.fetchReservationTimesDetail(prevTimeId, prevResveNo);
        } else {
          this.closeDetailView();
        }
        return;
      }

      if (data.status === "BLOCK_TIME_EXIST") {
        if (!!prevTimeId && !prevResveNo) {
          this.isfetchReservationTimesDetailError = true;
          this.fetchReservationTimesDetail(prevTimeId, prevResveNo);
        } else {
          this.closeDetailView();
        }
        return;
      }

      if (data.status === "WEB_TIME_EXIST") {
        // 웹타임 등록된 row를 예약자 항목 이외를 클릭시 웹타임 해지를 묻지 않고 rowSelect 유지되도록 수정.
        if (field.substring(0,9) == "resveName") {
          const resveNameIdx = field.substring(9);
          if (!(await this.confirm("웹타임을 해지하시겠습니까?"))) {
            if (!!prevTimeId && !prevResveNo) {
              this.isfetchReservationTimesDetailError = true;
              this.fetchReservationTimesDetail(prevTimeId, prevResveNo);
            } else {
              this.closeDetailView();
            }
            return;
          }
          try {
            const webTimes = data.data.filter(
              (reservationTime) => reservationTime.webOpenFlag
            );
            await webTimeCancel(webTimes[0].timeId, webTimes[0].optFlag);
            this.openDetailView(webTimes[0], true, `resveName${resveNameIdx}`);
            return;
          } catch (e) {
            console.error(e);
            this.errorToast("웹타임 해지에 실패하였습니다");
            return;
          }

          // // 웹타임 해지후 +1된 OPT_FLAG와 같은 값으로 갱신해준다.
          // if (data.data[0].optFlag) {
          //   data.data[0].optFlag = data.data[0].optFlag + 1;
          // }
        } else {
          return {
            isWebTimeSelect: true
          };
        }
      }

      //Roy 조인 이고  예약번호가 없을때는 신규 인원 추가로 하단 데이타는 없어야 된다.
      if(["JOIN","HOLD"].includes(data.data.reservationDetails[0]["timeStatus"]) && !resveNo){
        delete data.data.reservationDetails[0]["reservationConfirm"];
        delete data.data.reservationDetails[0]["reservationHold"];
      }

      // 현재 상세화면의 time id 갱신
      this.changeOpenedTimeId(timeId);

      // 어떠한 타이밍으로 인해 이전 openedTimeId로 예약정보 상세가 오픈되는 현상을 막고 나서 해당 예약에 RowSelect가 안되는 현상 조치. (START)
      let courseIdx = null;
      this.reservationCourseLists.every((course, idx) => {
        if (course.reservationLists.findIndex((reservation) => reservation.timeId === timeId) >= 0) {
          courseIdx = idx;
          return false;
        }
        return true;
      });

      if (courseIdx !== null) {
        const rowIndex = this.$refs.reservationGrid[courseIdx].getRowIndexByPrimaryKey(timeId);
        if (rowIndex >= 0) {
          this.$refs.reservationGrid[courseIdx].selectRow(rowIndex);
        }
      }
      // 어떠한 타이밍으로 인해 이전 openedTimeId로 예약정보 상세가 오픈되는 현상을 막고 나서 해당 예약에 RowSelect가 안되는 현상 조치. (END)

      const reservationTimes = data.data?.reservationDetails
          ?.map((reservationTime) => ({
        ...reservationTime,
        applyPrice: reservationTime.paymtPrice ? reservationTime.paymtPrice : null,
        orgTimeStatus: reservationTime.timeStatus,
        resveChannel: !!reservationTime.reservationConfirm && !!reservationTime.reservationConfirm.reservationMember ? reservationTime.reservationConfirm.reservationMember.resveChannel : null,
        resveId: reservationTime.reservationConfirm ? reservationTime.reservationConfirm.resveId : null,
        resveNo: reservationTime.reservationConfirm ? reservationTime.reservationConfirm.resveNo : null,
        joinFlag: reservationTime.reservationConfirm ? reservationTime.reservationConfirm.joinFlag : null,
        selfFlag: reservationTime.reservationConfirm ? reservationTime.reservationConfirm.selfFlag : null,
        cartKind: reservationTime.reservationConfirm ? reservationTime.reservationConfirm.cartKind :'GENERAL',
        penltyApplyFlag: reservationTime.reservationConfirm ? reservationTime.reservationConfirm.penltyApplyFlag : null,
        teamGreenFee: reservationTime.reservationConfirm ? reservationTime.reservationConfirm.teamGreenFee : null,
        visitCnt: reservationTime.reservationConfirm ? reservationTime.reservationConfirm.visitCnt : reservationTime.visitCnt,
        roomUseCnt: reservationTime.reservationConfirm ? reservationTime.reservationConfirm.roomUseCnt :null,
        roomUseDiv: reservationTime.reservationConfirm ? reservationTime.reservationConfirm.roomUseDiv :null,
        contactTel: !!reservationTime.reservationConfirm && !!reservationTime.reservationConfirm.reservationTrans ? reservationTime.reservationConfirm.reservationTrans.contactTel : null,
        memberDiv: !!reservationTime.reservationConfirm && !!reservationTime.reservationConfirm.reservationTrans ? reservationTime.reservationConfirm.reservationTrans.memberDiv : null,
        memberGrade: !!reservationTime.reservationConfirm && !!reservationTime.reservationConfirm.reservationTrans ? reservationTime.reservationConfirm.reservationTrans.memberGrade : null,
        holdInsertId: reservationTime.reservationHold ? reservationTime.reservationHold.insertId : null,
        promtnId: reservationTime.reservationPromotion ? reservationTime.reservationPromotion.promtnId : null,
        promtnName: reservationTime.reservationPromotion ? reservationTime.reservationPromotion.promtnName : null,
        transName: !!reservationTime.reservationConfirm && !!reservationTime.reservationConfirm.reservationTrans ? reservationTime.reservationConfirm.reservationTrans.transName : null,
        sexCode: !!reservationTime.reservationConfirm && !!reservationTime.reservationConfirm.reservationTrans ? reservationTime.reservationConfirm.reservationTrans.sexCode : null,
        reservationCompanions:
          !!reservationTime.reservationConfirm && !!reservationTime.reservationConfirm.reservationCmpns
            ? reservationTime.reservationConfirm.reservationCmpns.map(
                (reservationCmpn) => {
                  return {
                    ...reservationCmpn,
                    contactTel: reservationCmpn.contactTel || null,
                    pkData: reservationCmpn.resveId
                      .toString()
                      .concat(reservationCmpn.sno.toString()),
                  };
                }
              )
            : [],
        vipFlag: reservationTime?.reservationConfirm?.reservationMember?.vipFlag ? reservationTime?.reservationConfirm?.reservationMember?.vipFlag : false,
        grpNo: reservationTime?.reservationConfirm?.reservationMember?.grpNo,
        courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE", reservationTime.resveCourse),
      }));

      const reservationInfos = reservationTimes.filter(
        (data) => data.timeId === timeId
      );

      let reservationInfo = null;

      if (reservationInfos.length > 0) {
        reservationInfo = {
          ...(reservationInfos[0].reservationConfirm
            ? reservationInfos[0].reservationConfirm.reservationMember
            : null),
          smsRecptnDiv:
            reservationInfos[0].reservationConfirm &&
            reservationInfos[0].reservationConfirm.reservationMember &&
            reservationInfos[0].reservationConfirm.reservationMember.reservationLinkMembers.length > 0 &&
            reservationInfos[0].reservationConfirm.reservationMember.reservationLinkMembers.filter(linkMember => linkMember.id === reservationInfos[0].reservationConfirm.reservationMember.membershipId).length > 0 ?
              reservationInfos[0].reservationConfirm.reservationMember.reservationLinkMembers.filter(linkMember => linkMember.id === reservationInfos[0].reservationConfirm.reservationMember.membershipId)[0].member?.smsReceiptDivision
              : null,
          resveMngrName:
            reservationInfos[0].reservationConfirm &&
            reservationInfos[0].reservationConfirm.reservationMember &&
            reservationInfos[0].reservationConfirm.reservationMember.reservationLinkMembers.length > 0 &&
            reservationInfos[0].reservationConfirm.reservationMember.reservationLinkMembers.filter(linkMember => linkMember.id === reservationInfos[0].reservationConfirm.reservationMember.membershipId).length > 0 ?
              reservationInfos[0].reservationConfirm.reservationMember.reservationLinkMembers.filter(linkMember => linkMember.id === reservationInfos[0].reservationConfirm.reservationMember.membershipId)[0].member?.reserveManagerName
              : null,
          resveMngrHp:
            reservationInfos[0].reservationConfirm &&
            reservationInfos[0].reservationConfirm.reservationMember &&
            reservationInfos[0].reservationConfirm.reservationMember.reservationLinkMembers.length > 0 &&
            reservationInfos[0].reservationConfirm.reservationMember.reservationLinkMembers.filter(linkMember => linkMember.id === reservationInfos[0].reservationConfirm.reservationMember.membershipId).length > 0 ?
              reservationInfos[0].reservationConfirm.reservationMember.reservationLinkMembers.filter(linkMember => linkMember.id === reservationInfos[0].reservationConfirm.reservationMember.membershipId)[0].member?.reserveManagerPhoneNumber
              : null,
          insertName: reservationInfos[0].insertName,
          updateName: reservationInfos[0].updateName,
          vipFlag: reservationInfos[0]?.vipFlag ? reservationInfos[0]?.vipFlag : false,
          resveRemarks: reservationInfos[0]?.reservationConfirm?.reservationMember.resveRemarks ? reservationInfos[0]?.reservationConfirm?.reservationMember.resveRemarks || null : reservationInfos[0]?.reservationHold?.holdResn || null,
          depositIncoms: depositIncoms
        };

        let memberLinkInfos = null;

        if (!!reservationInfo && !!reservationInfo.reservationLinkMembers && reservationInfo.reservationLinkMembers.length > 0) {
          memberLinkInfos = reservationInfo.reservationLinkMembers.map(
            (linkMember) => ({
              ...linkMember,
              memberName: linkMember.memberInfo ? linkMember.memberInfo.memberName : null,
              memberImagePath: linkMember.memberInfo ? linkMember.memberInfo.memberImagePath : null,
            })
          );
        } else {
          memberLinkInfos = [];
        }

        this.memberLinkList = memberLinkInfos;
      }

      this.reservationListFromTomorrowGridData = [];

      if (!reservationInfo.resveNo) {
        this.holdTimeColumnSetting(timeId, true, field);
      }

      // 예약 상세 조회시 Error(홀딩, 블럭, 예약 등등인 상태로 인해 data.status가 OK가 아닐때 상세 정보 재조회시)일 때
      // 예약 타임 Grid에 이전 선택된 타임을 SelectRow 해주기 위함.
      if (this.isfetchReservationTimesDetailError) {
        for (let i = 0; i < this.$refs.reservationGrid.length; i++) {
          const rowIndex = this.$refs.reservationGrid[i].getRowIndexByPrimaryKey(timeId);
          if (rowIndex >= 0) {
            this.$refs.reservationGrid[i].selectRow(rowIndex);
            break;
          }
        }
      }

      this.isfetchReservationTimesDetailError = false;

      return {
        reservationTimeLists: _orderBy(reservationTimes, ["resveDate", "resveTime", "courseCodeSortNo"]),
        reservationInfo: reservationInfo,
        roomStayRecords : data.data.roomStayRecords
      };
    },
    async reservationSaveButtonClicked() {
      // 단체모드일 때만 단체명 필수 입력 체크.
      if (this.isReservationDetailModifyMode || this.isGroupReservation) {
        this.reservationInfoValidationRules.grpName.required = true;
        this.reservationInfoValidationRules.contactTelInfo.required = true;
      } else {
        this.reservationInfoValidationRules.grpName.required = false;
        this.reservationInfoValidationRules.contactTelInfo.required = false;
      }
      if (!this.validateFormRefs(this.reservationInfoValidationRules)) {
        return;
      }

      // 동반자정보 그리드 validation(그리드 validate() 사용) 및 위임정보 위임 연락처(직접) validation
/*      const timeListValidationResult = this.reservationDetailInfo.reservationTimeLists.every(
          (timeInfo, timeInfoIdx) => {
            const gridRefs = this.getReservationCompanionsGridRefs(timeInfoIdx);
            if (!gridRefs.validate()) {
              return false;
            } else {
              const currentViewCmpnInfos = gridRefs.getBatchCurrentViewRecords();

              let duplicateCmpn = false;
              currentViewCmpnInfos.forEach((data, idx) => {
                if (currentViewCmpnInfos.filter((info, infoIdx) => idx !== infoIdx && info.cmpnName === data.cmpnName && info.contactTel === data.contactTel).length > 0) {
                  duplicateCmpn = true;
                }
              });

              if (duplicateCmpn) {
                this.errorToast("동반자명 및 연락처가 중복됩니다");
                return false;
              }

              const value = timeInfo.contactTel;
              if (value === null || value === undefined || value === "") {
                // null, undefined, empty string은 regexp 에서는 체크하지 않는다
                return true;
              } else {
                // 연락처 형식 체크 삭제.
                // if(formNotPassedRegularExpressionCheck(value, hpRegExp)) {
                //   this.$EventBus.$emit(
                //     "errToast",
                //     this.$t("main.validationMessage.regexMessage", ["위임 연락처"])
                //   );
                //   this.$refs.timeInfoContactTel[timeInfoIdx].focusIn();
                //   return false;
                // }
              }
            }
            return true;
          }
      );

      if (!timeListValidationResult) {
        return;
      }

 */
      // 수정 데이터 존재 여부 체크
      if (!Array.isArray(this.reservationDetailInfo.reservationTimeLists) || this.reservationDetailInfo.reservationTimeLists.length === 0) {
        this.errorToast("수정된 데이터가 없습니다");
        return null;
      }

      if (this.reservationDetailInfo?.reservationTimeLists?.filter(data => data?.reservationConfirm && data?.reservationConfirm?.resveStatus !== "RESVE").length > 0) {
        this.infoToast("저장 가능한 예약상태가 아닙니다");
        return;
      }

      this.reservationDetailInfo.reservationTimeLists.forEach((timeInfo, timeInfoIdx) => {
            // 그리드 체크. 그리드 있으면 변경 데이터 가져옴
            const gridRefs = this.getReservationCompanionsGridRefs(timeInfoIdx);
            if (!gridRefs) {
              return;
            }
            // grid에서 뽑아낸 값
            let reservationCompanionsGridChangeData = gridRefs.getBatchCurrentViewRecords();

            timeInfo.reservationCompanions = reservationCompanionsGridChangeData.map(
                (changeData) => {
                  delete changeData._rid;
                  delete changeData._no;
                  return changeData;
                }
            );
          }
      );

      let reservationTimeListsChanged = deepDiffs(
          this.prevModifyReservationDetailInfo.reservationTimeLists,
          this.reservationDetailInfo.reservationTimeLists
      );

      let reservationInfoChanged = deepDiffs(
          this.prevModifyReservationDetailInfo.reservationInfo,
          this.reservationDetailInfo.reservationInfo
      );

      if (!reservationTimeListsChanged && !reservationInfoChanged) {
        this.errorToast("수정된 데이터가 없습니다");
        return null;
      }
      this.reservationDetailInfo.reservationTimeLists.forEach(
          (timeInfo, timeInfoIdx) => {
            const gridRefs = this.getReservationCompanionsGridRefs(timeInfoIdx);
            if (!gridRefs) {
              return;
            }

            const batchChangesData = gridRefs.getBatchChanges();
            // 수정여부 체크
            if (!(batchChangesData.addedRecords.length > 0) && !(batchChangesData.changedRecords.length > 0) && !(batchChangesData.deletedRecords.length > 0)) {
              return;
            }

            timeInfo.reservationCompanions = [];

            if (batchChangesData.addedRecords.length > 0) {
              batchChangesData.addedRecords.forEach((data, idx) => {
                const pushData = {...data, sno: (idx + 1) * 10,};
                pushData.contactTel = pushData.contactTel ? pushData.contactTel : null;
                pushData.lockerNo = pushData.lockerNo ? pushData.lockerNo : null;
                pushData.joinGroupNo = pushData.joinGroupNo ? pushData.joinGroupNo : null;
                timeInfo.reservationCompanions.push(pushData);
              });
            }

            if (batchChangesData.changedRecords.length > 0) {
              batchChangesData.changedRecords.forEach((data) => {
                if (data.memberDiv === "NOM") {data.membershipId = "";data.memberNo = "";}
                timeInfo.reservationCompanions.push(data);
              });
            }

            if (batchChangesData.deletedRecords.length > 0) {
              batchChangesData.deletedRecords.forEach((data) => {data.cmpnName = "";
                timeInfo.reservationCompanions.push(data);
              });
            }

            if (this.resveCmpnAuto && !this.isGroupReservation && !this.isReservationDetailModifyMode) {
              const gridRefs = this.getReservationCompanionsGridRefs(timeInfoIdx);
              const currentViewRecords = gridRefs.getBatchCurrentViewRecords();
              const filterCompanions = currentViewRecords.filter(data => !data.pkData && data.sno === 1);
              if (filterCompanions.length > 0) {
                timeInfo.reservationCompanions.push(filterCompanions[0]);
              }
            }
          }
      );

      const forSize = this.reservationDetailInfo.reservationTimeLists.length;
      let forData = [];

      for (let i = 0; i < forSize; i++) {
        const prevModifyReservationTimeList = this.reservationDetailInfo.reservationTimeLists[i].timeId ? this.prevModifyReservationDetailInfo.reservationTimeLists.filter(
                (data) => data.timeId === this.reservationDetailInfo.reservationTimeLists[i].timeId) : [];

        const deepDiffsData = deepDiffs(
            prevModifyReservationTimeList.length > 0 ? prevModifyReservationTimeList[0] : {},
            this.reservationDetailInfo.reservationTimeLists[i]
        );

        forData.push({
          orgRoomUseDiv: this.reservationDetailInfo.reservationTimeLists[i].roomUseDiv,
          orgTimeId: this.reservationDetailInfo.reservationTimeLists[i].timeId,
          orgOptFlag: this.reservationDetailInfo.reservationTimeLists[i].optFlag,
          orgTimeStatus: this.reservationDetailInfo.reservationTimeLists[i].timeStatus,
          orgResveId: this.reservationDetailInfo.reservationTimeLists[i].resveId,
          orgResveNo: this.reservationDetailInfo.reservationTimeLists[i].resveNo,
          orgContactTel: this.reservationDetailInfo.reservationInfo.contactTel,
          resveDate: this.reservationDetailInfo.reservationTimeLists[i].resveDate,
          tgResveConfirmOptFlag: this.reservationDetailInfo.reservationTimeLists[i].reservationConfirm ? this.reservationDetailInfo.reservationTimeLists[i].reservationConfirm.optFlag : null,
          tgResveTransResveId: !!this.reservationDetailInfo.reservationTimeLists[i].reservationConfirm && !!this.reservationDetailInfo.reservationTimeLists[i].reservationConfirm.reservationTrans ?
              this.reservationDetailInfo.reservationTimeLists[i].reservationConfirm.reservationTrans.resveId : null,
          ...deepDiffsData,
        });
      }

      reservationTimeListsChanged = forData;

      if (reservationInfoChanged) {
        reservationInfoChanged.resveNo = this.reservationDetailInfo.reservationInfo.resveNo;
        reservationInfoChanged.optFlag = this.reservationDetailInfo.reservationInfo.optFlag;
        reservationInfoChanged.grpNo = reservationInfoChanged.resveNo
            ? reservationInfoChanged.grpNo : reservationInfoChanged.grpNo ? reservationInfoChanged.grpNo : null;
        reservationInfoChanged.grpKind = reservationInfoChanged.resveNo
            ? reservationInfoChanged.grpKind : reservationInfoChanged.grpKind ? reservationInfoChanged.grpKind : null;
        reservationInfoChanged.grpName = reservationInfoChanged.resveNo
            ? reservationInfoChanged.grpName : reservationInfoChanged.grpName ? reservationInfoChanged.grpName : null;
        reservationInfoChanged.contactTel = reservationInfoChanged.resveNo
            ? reservationInfoChanged.contactTel : reservationInfoChanged.contactTel ? reservationInfoChanged.contactTel : null;
        reservationInfoChanged.resveRemarks = reservationInfoChanged.resveNo
            ? reservationInfoChanged.resveRemarks : reservationInfoChanged.resveRemarks ? reservationInfoChanged.resveRemarks : null;
        if (!!reservationInfoChanged.resveNo && !!reservationInfoChanged.memberDiv && reservationInfoChanged.memberDiv === "NOM") {
          reservationInfoChanged.membershipId = "";
          reservationInfoChanged.memberNo = "";
        }
      }
      const saveData = {
        reservationTimes: reservationTimeListsChanged.map((reservationTime) => ( {
              timeId: reservationTime.orgTimeId,
              optFlag: reservationTime.orgOptFlag,
              timeStatus: reservationTime.orgTimeStatus,
              resveNo: ["EMPTY","HOLD"].includes(reservationTime.orgTimeStatus) ? this.reservationDetailInfo.reservationInfo.resveNo ? this.reservationDetailInfo.reservationInfo.resveNo : reservationTime.orgResveNo : reservationTime.orgResveNo,
              resveDate: reservationTime.resveDate,
              reservationConfirm: {
                resveId: reservationTime.orgResveId,
                optFlag: reservationTime.tgResveConfirmOptFlag,
                timeId: reservationTime.orgTimeId,
                resveNo: reservationTime.visitCnt !== undefined || reservationTime.promtnId !== undefined || reservationTime.teamGreenFee !== undefined || !!reservationTime.selfFlag || !!reservationTime.joinFlag ? reservationTime.orgResveNo : null,
                visitCnt: reservationTime.visitCnt,
                roomUseDiv: reservationTime.roomUseDiv,
                roomUseCnt: (reservationTime.roomUseDiv  || reservationTime.orgRoomUseDiv) ? reservationTime.roomUseCnt : 0,
                promtnId: reservationTime.promtnId === null ? reservationTime.orgResveId ? -1 : null : reservationTime.promtnId,
                teamGreenFee: reservationTime.teamGreenFee,
                selfFlag: reservationTime.selfFlag,
                cartKind: reservationTime.cartKind,
                // 올림픽cc 관련 수정 조인예약시  무조건
                joinFlag: this.reservationDetailInfo.reservationTimeLists[0].joinFlag,
                contactTel: reservationTime.orgTimeStatus === "EMPTY" ? reservationTime.orgContactTel ? reservationTime.orgContactTel : "" : reservationTime.orgContactTel,
                reservationTrans:
                    !!reservationTime.transName || reservationTime.transName === "" || !!reservationTime.membershipId || !!reservationTime.memberNo
                    || !!reservationTime.memberDiv || !!reservationTime.memberGrade || !!reservationTime.sexCode || !!reservationTime.contactTel
                    || reservationTime.contactTel === "" ? {
                          resveId: reservationTime.tgResveTransResveId,
                          transName: reservationTime.transName,
                          membershipId: reservationTime.memberDiv === "NOM" ? reservationTime.tgResveTransResveId ? "" : null : reservationTime.membershipId,
                          memberNo: reservationTime.memberDiv === "NOM" ? reservationTime.tgResveTransResveId ? "" : null : reservationTime.memberNo,
                          memberDiv: reservationTime.memberDiv,
                          memberGrade: reservationTime.memberGrade,
                          sexCode: reservationTime.sexCode,
                          contactTel: reservationTime.tgResveTransResveId ? reservationTime.contactTel : reservationTime.contactTel ? reservationTime.contactTel : null,
                        } : null,
                reservationCmpns: reservationTime.reservationCompanions,
              },
            })
        ),
        reservationMember: reservationInfoChanged,
      };

      //중복자 확인
      let result;
      try {
        result = await GolfErpAPI.reservationSave(saveData);
      } catch (e) {
        switch(e.message) {
          case "DUPLICATE_MEMBER_IN_RESERVATION_NO" :
            await this.errorToast(this.$t("golfReservation.errorCodes.DUPLICATE_MEMBER_IN_RESERVATION_NO"));
            return;
        }
      }

      const {resveNo: newResveNo, resveMngrName, resveMngrHp, smsRecptnDiv} = result;

      let isSmsSendFlag = false;

      let emptyCount = 0;

      saveData.reservationTimes.forEach((reservationTime) => {
        if (reservationTime.timeStatus === "EMPTY") {
          emptyCount++;
        }
      });

      const contactTel = this.reservationDetailInfo.reservationInfo.contactTel;

      if (contactTel) {
        if (!this.reservationDetailInfo.reservationInfo.resveNo || emptyCount > 0 || (this.orgContactTel !== null && this.orgContactTel !== contactTel)) {
          if (await this.confirm("예약 문자를 전송하시겠습니까?")) {
            isSmsSendFlag = true;
            if (!this.reservationDetailInfo.reservationInfo.resveNo && !!newResveNo) {
              this.reservationDetailInfo.reservationInfo.resveNo = newResveNo;
              this.reservationDetailInfo.reservationInfo.resveMngrName = resveMngrName;
              this.reservationDetailInfo.reservationInfo.resveMngrHp = resveMngrHp;
              this.reservationDetailInfo.reservationInfo.smsRecptnDiv = smsRecptnDiv;
            }
          }
        }
      }

      // 좌측 grid update
      if (this.isReservationDetailModifyMode) {
        this.isReservationDetailModifyMode = false;
      }

      await this.autoSendSms(isSmsSendFlag);

      this.isQuestionReservationDetailModify = true;
      await this.getReservationStatusList();
    },
    onReservationDetailViewSelect(timeInfo, timeInfoIdx, event, field = null) {
      this.$nextTick(() => {
        event;
        this.selectedRowData = timeInfo;
        this.reservationDetailInfo.reservationTimeLists.map((_, i) => {
          const el = this.$refs[`reservationDetailViewSelect${i}`];
          if (el) {
            el[0]?.classList.remove("selected");
          }
        });

        const el = this.$refs[`reservationDetailViewSelect${timeInfoIdx}`];
        if (el) {
          el[0]?.classList.add("selected");
        }
        if(timeInfo.resveDate !== this.reservationOptions.resveDate && field === 'check') {
          return;
        }

        if (this.reservationOptions.resveDate !== timeInfo.resveDate) {
          this.reservationOptions.resveDate = timeInfo.resveDate;
          this.autoSelectStackInfo = {timeInfo, timeInfoIdx,};
          return this.searchReservation();
        }

        this.$refs.reservationGrid.forEach(reservationGrid => {
          const currentViewRecords = reservationGrid.getCurrentViewRecords();
          const idxToSelect = currentViewRecords.findIndex((record) => record.timeId === timeInfo.timeId);
          if (idxToSelect >= 0) {
            reservationGrid.selectRow(idxToSelect);
            reservationGrid.selectCell({rowIndex: idxToSelect, cellIndex: 4,}, false);
          } else {
            reservationGrid.clearSelection();
            if (reservationGrid?.$refs?.grid?.ej2Instances?.focusModule?.currentInfo?.element?.classList) {
              reservationGrid.$refs.grid.ej2Instances.focusModule.currentInfo.element.classList.remove("e-focus");
            }
          }
        });
        const currentViewRecords = this.$refs.reservationGrid[this.reservationCourseActiveIndex].getCurrentViewRecords();
        const idxToSelect = currentViewRecords.findIndex((record) => record.timeId === timeInfo.timeId);
        if (idxToSelect >= 0) {
          this.$refs.reservationGrid[this.reservationCourseActiveIndex].selectRow(idxToSelect);
          this.$refs.reservationGrid[this.reservationCourseActiveIndex].selectCell({rowIndex: idxToSelect, cellIndex: 4,}, false);
        }
      });
    },
    async fetchMemberReservationVisitSearch() {
      if (this.reservationDetailInfo.reservationInfo.membershipId) {
        const data = await GolfErpAPI.fetchMemberReservationVisitSearch({
          bsnDate: this.reservationOptions.resveDate,
          membershipId: this.reservationDetailInfo.reservationInfo.membershipId,
          isLinkMember: this.isLinkMember,
          searchDiv: "RESVE",
          isMemberInfoView: false,
        });

        this.reservationListFromTomorrowGridData = data?.reservations.map(item => ({
          ...item,
          monthDayLabel: moment(item.resveDate).format('MM-DD')
        })) || [];
      } else {
        this.reservationListFromTomorrowGridData = [];
      }
    },
    isLinkMemberChanged() {
      if (this.reservationDetailInfo.reservationInfo.membershipId) {
        this.fetchMemberReservationVisitSearch();
      }
    },
    async onBsnDateChange(args) {
      if (this.isPreResveDateReturnFlag) {
        this.isPreResveDateReturnFlag = false;
        return;
      }

      // TODO : onChange 내에서 binding 값 변경시 발생하는 버그가 수정되면, 수정 내역 확인 팝업에서 진행하지 않을 경우, 이 값을 사용하여 원복 처리
      if (this.isDetailDataModified()) {
        // 수정여부 체크
        if (
          !this.isReservationDetailModifyMode &&
          !(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))
        ) {
          // 진행 취소할 경우. 예약일자를 이전 예약일자로 돌리기 위한 작업.
          // this.isPreResveDateReturnFlag = true이면 재조회작업 안하고 return.
          this.changeReservationDateToPreviousReservationDate();
          return;
        }
      }

      // 좌측 grid update
      if (!this.resveDateChangeApiFlag) {
        await this.getReservationStatusList(true);
      }

      this.bsnDateInfo = args;
    },
    async getReservationStatusList( autoSelectRow = false ) {
      if (this.reservationOptions.resveDate) {
        if (!this.isSetInTheMenuOnTheRight) {
          this.isReservationDailyStatusGridAutoSelectRow = autoSelectRow;
        }
      }
      this.reservationDailyStatusGridOptions.data =
        (await GolfErpAPI.fetchReservationStatusList(this.reservationOptions.bsnDate, this.reservationOptions.courseDiv === "%" ? null : this.reservationOptions.courseDiv)).map(data => ({
          ...data,
          resveDateYYMMDD: getFormattedDate(data.resveDate, "MM/DD")
        }));
      // 예약일자 리스트에 현재 선택된 Row의 예약일자가 없으면 자동으로 첫번째 Row를 Select하기 위함.
      if (!(this.reservationDailyStatusGridOptions.data.filter(data => getFormattedDate(data.resveDate, "YYYYMMDD") === getFormattedDate(this.reservationOptions.resveDate, "YYYYMMDD")).length > 0)) {
        this.isReservationDailyStatusGridAutoSelectRow = true;
      }
    },
    async reservationDailyStatusGridRowSelected(args) {
      const {data: {resveDate}} = args;

      if (this.isDetailDataModified()) {
        // 수정여부 체크
        if (!this.isQuestionReservationDetailModify && !this.isReservationDetailModifyMode && !(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          this.changeReservationDateToPreviousReservationDate();
          return;
        }
        this.isQuestionReservationDetailModify = false;
      }

      this.selectedRowData = null;

      this.reservationOptions.resveDate = getFormattedDate(resveDate, "YYYY-MM-DD");

      // 코스별 grid update
      // 수정 내역 확인후 진행 안하겠다는데 예약정보 상세 재조회되는 것 방지.
      if (!this.isPreResveDateReturnFlag) {
        await this.fetchReservationTimes();
      } else {
        this.isPreResveDateReturnFlag = false;
      }

      this.reservationOptions.preResveDate = getFormattedDate(resveDate, "YYYY-MM-DD");
      this.isReservationDailyStatusGridAutoSelectRow = true;
    },
    reservationDailyStatusGridActionComplete(args) {
      const {rows} = args;
      // console.log(args);
      if (rows.length > 0 && this.isReservationDailyStatusGridAutoSelectRow) {
        this.$refs.reservationDailyStatus.selectRow(0);
      } else if(this.reservationOptions.resveDate && !this.isReservationDailyStatusGridAutoSelectRow) {
        const rowIndex = this.$refs.reservationDailyStatus.getRowIndexByPrimaryKey(getFormattedDate(this.reservationOptions.resveDate, "YYYYMMDD"));

        this.$refs.reservationDailyStatus.selectRow(rowIndex);
      }

      this.isSetInTheMenuOnTheRight = false;
    },
    changeReservationDateToPreviousReservationDate() {
      // 진행 취소할 경우. 예약일자를 이전 예약일자로 돌리기 위한 작업.
      // this.isPreResveDateReturnFlag = true이면 재조회작업 안하고 return.
      this.reservationOptions.resveDate = JSON.parse(
        JSON.stringify(this.reservationOptions.preResveDate)
      );
      this.isPreResveDateReturnFlag = true;

      const rowIndex = this.$refs.reservationDailyStatus.getRowIndexByPrimaryKey(getFormattedDate(this.reservationOptions.resveDate, "YYYYMMDD"));

      this.isQuestionReservationDetailModify = true;

      this.$refs.reservationDailyStatus.selectRow(rowIndex);
    },
    reservationDailyStatusQueryCellInfo(args) {
      const {cell, column: {field,}, data,} = args;

      if (field === "dwCode") {
        if (data.bsnCode === "CLOSE") {
          cell.style.color = commonCodesGetColorValue("BSN_CODE", data.bsnCode);
        } else {
          cell.style.color = commonCodesGetColorValue("DW_CODE", data.dwCode);
          if (data.hldyFlag) {
            cell.style.color = commonCodesGetColorValue("DW_CODE", "1");
          }
        }
      }

      if (["resveDateYYMMDD", "dwCode"].includes(field) && data.bsnCode === "CLOSE") {
        cell.style.textDecoration = "line-through";
      }

      if (["totalTimeCount", "reservationCount", "waitingCount"].includes(field)) {
        if (data[field] === 0) {
          cell.innerText = "-";
        }
      }

      if (field === "waitingCount") {
        cell.classList.add(this.$t("className.grid.clickAreaNone"));
      }
    },
    memoConfirm(args) {
      if (this.reservationCourseLists.length > 0) {
        let isBreak = false;
        for (let i = 0, j = this.reservationCourseLists.length; i<j; i++) {
          if (isBreak) {
            break;
          }

          for (let ii = 0, jj = this.reservationCourseLists[i].reservationLists.length; ii<jj; ii++) {
            if (this.reservationCourseLists[i].reservationLists[ii].resveId === args.resveId) {
              this.reservationCourseLists[i].reservationLists[ii].newMemoFlag = args.newMemoFlag;
              this.reservationCourseLists[i].reservationLists[ii].memoFlag = args.memoViews.length >= 1;
              isBreak = true;
              break;
            }
          }
        }
      }
    },
    courseGridByReservationActive(reservationCourseCode) {
      const resveCourseList = this.reservationCourseLists.map((item, idx) => {
        return {
          code: item.resveCourseCode,
          idx: idx
        };
      });

      const code = resveCourseList.filter(item => item.idx === this.reservationCourseActiveIndex)[0].code;

      let courseCodeIdx2 = [];
      commonCodesGetCommonCodeByIdx("COURSE_CODE", 2).map(item => {
        item.comCodeAttrbList.map(attrb => {
          courseCodeIdx2.push(attrb);
        });
      });
      courseCodeIdx2 = this.reservationOptions.courseDiv === "%" ?
        courseCodeIdx2.map(item => item.comCode) :
        courseCodeIdx2.filter(item => item.attrb === this.reservationOptions.courseDiv).map(item => item.comCode);

      return courseCodeIdx2.includes(reservationCourseCode) && reservationCourseCode === code;
    },
    courseGridByReservationStyle() {
      const resveCourseList = this.reservationCourseLists.map((item, idx) => {
        return {
          code: item.resveCourseCode,
          idx: idx
        };
      });

      const code = resveCourseList.filter(item => item.idx === this.reservationCourseActiveIndex)[0].code;

      let courseCodeIdx2 = [];
      commonCodesGetCommonCodeByIdx("COURSE_CODE", 2).map(item => {
        item.comCodeAttrbList.map(attrb => {
          courseCodeIdx2.push(attrb);
        });
      });
      courseCodeIdx2 = this.reservationOptions.courseDiv === "%" ?
        courseCodeIdx2.map(item => item.comCode) :
        courseCodeIdx2.filter(item => item.attrb === this.reservationOptions.courseDiv).map(item => item.comCode);

      return this.isReservationDetailViewFixed || (!this.isReservationDetailViewFixed && !courseCodeIdx2.includes(code));
    },
    onWaitingReservationPopupEvent(eventId, popupKey) {
      if (eventId === "popupClosed") {
        this.isWaitingReservationPopupOpen = false;
        if (popupKey) {
          this.getReservationStatusList();
        }
      }
    },
    waitingReservationListPopupOpen(data) {
      this.isWaitingReservationListPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.waitingReservationListPopup.showPopup(data);
      });
    },
    waitingReservationListPopupClosed() {
      this.isWaitingReservationListPopupOpen = false;
    },
    reservationDailyStatusRecordClick(args) {
      const {
        column: {
          field
        },
        rowData,
      } = args;
      if (field === "waitingCount") {
        this.waitingReservationListPopupOpen(rowData);
      }
    },
    onCourseDivChanged(args) {
      if (args.previousItem) {
        this.getReservationStatusList();
      }
    },
    onPromotionButtonClicked() {
      this.isReservationTimePromotionPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.reservationTimePromotionPopup.showPopup({
          resveDate: this.reservationOptions.resveDate,
        });
      });
    },
    reservationTimePromotionPopupClosed() {
      this.isReservationTimePromotionPopupOpen = false;
    },
    async fetchResvePromtnAll() {
      this.promotionCodes = _sortBy(await GolfErpAPI.fetchResvePromtnAll(), ["promtnName"]);
    },
    async onPromtnIdChanged(args, timeInfo) {
      const {value,} = args;

      const {
        value: paymentPriceJson
      } = await getPaymentPrice(
        timeInfo.resveDate,
        timeInfo.resveTime,
        timeInfo.holeDiv,
        this.reservationDetailInfo.reservationInfo.memberGrade,
        null,
        value,
        false,
        this.reservationDetailInfo.reservationInfo.membershipId,
      );

      if (paymentPriceJson) {
        timeInfo.applyPrice = paymentPriceJson.applyPrice;
      }
    },
    promotionCodesByStartCourse(timeInfo) {
      const rtnData = this.promotionCodes
          .filter(item => item.tgResvePromtnCourses.map(course => course.resveCourse).includes(timeInfo.resveCourse))
          .map(item => ({
            ...item,
            promtnNameAsText: item.promtnName + "[" + item.promtnId + "]",
          }));
      rtnData.unshift({
        promtnNameAsText: "",
        promtnId: null,
      });
      return rtnData;
    },
    async onRoomReservationButtonClicked() {
      if (this.reservationDetailInfo.reservationInfo.resveNo) {
        this.isAvailableRoomPopup = true;

        this.$nextTick(() => {
          this.$refs.availableRoomPopup.showPopup({
            bsnDate: _minBy(this.reservationDetailInfo.reservationTimeLists.map(item => item.resveDate)),
          });
        });
      } else {
        this.errorToast("저장된 예약 정보가 없습니다");
      }
    },
    onAvailableRoomPopupClosed() {
      this.isAvailableRoomPopup = false;
    },
    onAvailableRoomPopupConfirm(data) {
      let routeData = this.$router.resolve({
        name: "RoomReservationRegistration",
        query: {
          resveNo: this.reservationDetailInfo.reservationInfo.resveNo,
          roomType: data.roomType,
          bsnDate: data.bsnDate,
        },
      });
      openNewWindow(routeData);
    },
    onApplyAndCancelSelectPopupClose() {
      this.isApplyAndCancelSelectPopup = false;
    },
    onSelfTimeSettingPopupClose(event) {
      this.isSelfTimeSettingPopupOpen = false;
      if (event && event.isReload === true) {
        this.searchReservation();
        // alert('Self 타임설정 완료되었음. 조회API 호출.')
      }
    },
    onWindowKeypress(event) {
      if (keypressEventEqualsShortcut(event, SHORTCUT_INS.shortcut)) {
        this.$refs.searchValue.focus();
      }
    },
    depositIncomPGCancelButtonClicked() {
      const selectedRecords = this.$refs.paymentListGrid.getSelectedRecords();
      if (!(selectedRecords.length > 0)) {
        this.errorToast("선수금을 선택해 주세요");
        return;
      }

      this.incomPGCancel(selectedRecords[0]);
    },
    incomPGCancel(data) {
      this.isPGCancelPopupOpen = true;

      let popupData = {
        resveNo: this.reservationDetailInfo.reservationInfo.resveNo,
        incomId: data.incomId,
      };

      this.$nextTick(() => {
        this.$refs.pgCancelPopup.showPGCancelPopup(popupData);
      });
    },
    async pgCancelPopupClosed(args) {
      this.isPGCancelPopupOpen = false;

      if (args !== undefined && args.isReload) {
        let remainedTimeList = this.reservationDetailInfo.reservationTimeLists;
        if (remainedTimeList.length > 0) {
          await this.refreshDetailInfo(
              remainedTimeList[0].resveNo,
              remainedTimeList[0].timeId,
              false,
              remainedTimeList[0].timeStatus,
              remainedTimeList[0].holdInsertId,
              remainedTimeList[0].resveDate
          );
        }
      }
    },
  },
};
</script>

<style scoped>
body .appContent .lookup-condition .field.reservationNameRemarksFourDigitContactNumber .content .item.input {width: 240px;}

body .appContent .body-article .article-left,
body .appContent .body-article .article-right {transition: all 0.3s ease-out;}
body .appContent .body-article .article-left {width: 100%;}
body .appContent .body-article .article-right {width: 509px; margin: 0 -509px 0 0;}
body .appContent .body-article.dev-reservation-detail-view-opened .article-left,
body .appContent .body-article.dev-reservation-detail-view-fixed .article-left {width: calc(100% - 509px);}
body .appContent .body-article.dev-reservation-detail-view-opened .article-right,
body .appContent .body-article.dev-reservation-detail-view-fixed .article-right {margin: 0;}

body .appContent .article-section.section-01 .body-box {width: 229px;}
/*@media (min-width: 1920px) {*/
/*  body .appContent .article-section.section-01 .body-box {width: 250px;}*/
/*}*/
/*body .appContent .body-article.dev-reservation-detail-view-opened .article-section.section-01 .body-box,*/
/*body .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .body-box {width: 164px;}*/
/*@media (min-width: 1920px) {*/
/*  body .appContent .body-article.dev-reservation-detail-view-opened .article-section.section-01 .body-box,*/
/*  body .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .body-box {width: 229px;}*/
/*}*/

body .appContent .article-section.section-01.reservation-accordion > .section-header .header-title {padding: 0 0 0 24px; background: transparent url('../../assets/images/common/accordion-left.png') no-repeat left center; cursor: pointer;}
body .appContent .article-section.section-01.reservation-accordion.reservation-close > .section-header .header-title {padding: 0 0 0 24px; background: transparent url('../../assets/images/common/accordion-right.png') no-repeat left center; cursor: pointer;}
body .appContent .article-section.section-01.reservation-accordion.reservation-close .section-body .body-box.dev-reservation-daily-status {
  width: 110px;
}
body .appContent .article-section.section-01.reservation-accordion.reservation-close .section-body {
  border-left: 1px dotted #ccc !important;
}
body .appContent .article-section.section-01 .section-body .body-box.dev-reservation-daily-status {transition: all 0.3s ease-out; width: 350px; margin: 0; border: 1px solid #000000;}
body .appContent .article-section.section-01 .section-body .body-box.dev-reservation-daily-status .body-grid {height: 100%;}

/*body .appContent .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box {width: 229px;}*/
/*@media (min-width: 1920px) {*/
/*  body .appContent .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box {width: 250px;}*/
/*}*/
/*body .appContent .body-article.dev-reservation-detail-view-opened .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box,*/
/*body .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box {width: 164px;}*/
/*@media (min-width: 1920px) {*/
/*  body .appContent .body-article.dev-reservation-detail-view-opened .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box,*/
/*  body .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box {width: 229px;}*/
/*}*/

/*body .appContent .article-section.section-01 .section-body .body-box.dev-reservation-course-num-3 {width: 229px;}*/
/*@media (min-width: 1920px) {*/
/*  body .appContent .article-section.section-01 .section-body .body-box.dev-reservation-course-num-3 {width: 374px;}*/
/*  body .appContent .article-section.section-01:not(.reservation-close) .section-body .body-box.dev-reservation-course-num-3 {width: 374px;}*/
/*}*/
/*body .appContent .body-article.dev-reservation-detail-view-opened .article-section.section-01 .section-body .body-box.dev-reservation-course-num-3,*/
/*body .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .section-body .body-box.dev-reservation-course-num-3 {width: 229px;}*/
/*@media (min-width: 1920px) {*/
/*  body .appContent .body-article.dev-reservation-detail-view-opened .article-section.section-01 .section-body .body-box.dev-reservation-course-num-3,*/
/*  body .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .section-body .body-box.dev-reservation-course-num-3 {width: 229px;}*/
/*}*/

/*body .appContent .article-section.section-01 .section-body .body-box.dev-reservation-course-num-2 {width: 426px;}*/
/*@media (min-width: 1920px) {*/
/*  body .appContent .article-section.section-01 .section-body .body-box.dev-reservation-course-num-2 {width: 547px;}*/
/*  body .appContent .article-section.section-01:not(.reservation-close) .section-body .body-box.dev-reservation-course-num-2 {width: 547px;}*/
/*}*/
/*body .appContent .body-article.dev-reservation-detail-view-opened .article-section.section-01 .section-body .body-box.dev-reservation-course-num-2,*/
/*body .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .section-body .body-box.dev-reservation-course-num-2 {width: 229px;}*/
/*@media (min-width: 1920px) {*/
/*  body .appContent .body-article.dev-reservation-detail-view-opened .article-section.section-01 .section-body .body-box.dev-reservation-course-num-2,*/
/*  body .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .section-body .body-box.dev-reservation-course-num-2 {width: 237px;}*/
/*}*/

/*body .wrapper.dev-layout-left-no-right-no .appContent .article-section.section-01 .body-box:not(.dev-reservation-daily-status),*/
/*body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .article-section.section-01 .body-box:not(.dev-reservation-daily-status) {width: 239px;}*/
/*@media (min-width: 1920px) {*/
/*  body .wrapper.dev-layout-left-no-right-no .appContent .article-section.section-01 .body-box:not(.dev-reservation-daily-status),*/
/*  body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .article-section.section-01 .body-box:not(.dev-reservation-daily-status) {width: 346px;}*/
/*}*/
/*body .wrapper.dev-layout-left-no-right-no .appContent .body-article.dev-reservation-detail-view-opened .article-section.section-01 .body-box:not(.dev-reservation-daily-status),*/
/*body .wrapper.dev-layout-left-no-right-no .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .body-box:not(.dev-reservation-daily-status),*/
/*body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .body-article.dev-reservation-detail-view-opened .article-section.section-01 .body-box:not(.dev-reservation-daily-status),*/
/*body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .body-box:not(.dev-reservation-daily-status) {width: 229px;}*/
/*@media (min-width: 1920px) {*/
/*  body .wrapper.dev-layout-left-no-right-no .appContent .body-article.dev-reservation-detail-view-opened .article-section.section-01 .body-box:not(.dev-reservation-daily-status),*/
/*  body .wrapper.dev-layout-left-no-right-no .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .body-box:not(.dev-reservation-daily-status),*/
/*  body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .body-article.dev-reservation-detail-view-opened .article-section.section-01 .body-box:not(.dev-reservation-daily-status),*/
/*  body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .body-box:not(.dev-reservation-daily-status) {width: 229px;}*/
/*}*/

/*body .wrapper.dev-layout-left-no-right-no .appContent .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box,*/
/*body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box {width: 239px;}*/
/*@media (min-width: 1920px) {*/
/*  body .wrapper.dev-layout-left-no-right-no .appContent .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box,*/
/*  body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box {width: 346px;}*/
/*}*/
/*body .wrapper.dev-layout-left-no-right-no .appContent .body-article.dev-reservation-detail-view-opened .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box,*/
/*body .wrapper.dev-layout-left-no-right-no .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box,*/
/*body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .body-article.dev-reservation-detail-view-opened .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box,*/
/*body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box {width: 229px;}*/
/*@media (min-width: 1920px) {*/
/*  body .wrapper.dev-layout-left-no-right-no .appContent .body-article.dev-reservation-detail-view-opened .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box,*/
/*  body .wrapper.dev-layout-left-no-right-no .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box,*/
/*  body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .body-article.dev-reservation-detail-view-opened .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box,*/
/*  body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box {width: 229px;}*/
/*}*/

/*body .wrapper.dev-layout-left-no-right-no .appContent .article-section.section-01 .section-body .body-box.dev-reservation-course-num-3,*/
/*body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .article-section.section-01 .section-body .body-box.dev-reservation-course-num-3 {width: 358px;}*/
/*@media (min-width: 1920px) {*/
/*  body .wrapper.dev-layout-left-no-right-no .appContent .article-section.section-01 .section-body .body-box.dev-reservation-course-num-3,*/
/*  body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .article-section.section-01 .section-body .body-box.dev-reservation-course-num-3 {width: 374px;}*/
/*}*/
/*body .wrapper.dev-layout-left-no-right-no .appContent .body-article.dev-reservation-detail-view-opened .article-section.section-01 .section-body .body-box.dev-reservation-course-num-3,*/
/*body .wrapper.dev-layout-left-no-right-no .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .section-body .body-box.dev-reservation-course-num-3,*/
/*body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .body-article.dev-reservation-detail-view-opened .article-section.section-01 .section-body .body-box.dev-reservation-course-num-3,*/
/*body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .section-body .body-box.dev-reservation-course-num-3 {width: 229px;}*/
/*@media (min-width: 1920px) {*/
/*  body .wrapper.dev-layout-left-no-right-no .appContent .body-article.dev-reservation-detail-view-opened .article-section.section-01 .section-body .body-box.dev-reservation-course-num-3,*/
/*  body .wrapper.dev-layout-left-no-right-no .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .section-body .body-box.dev-reservation-course-num-3,*/
/*  body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .body-article.dev-reservation-detail-view-opened .article-section.section-01 .section-body .body-box.dev-reservation-course-num-3,*/
/*  body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .section-body .body-box.dev-reservation-course-num-3 {width: 251px;}*/
/*}*/

/*body .wrapper.dev-layout-left-no-right-no .appContent .article-section.section-01 .section-body .body-box.dev-reservation-course-num-2,*/
/*body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .article-section.section-01 .section-body .body-box.dev-reservation-course-num-2 {width: 714px;}*/
/*@media (min-width: 1920px) {*/
/*  body .wrapper.dev-layout-left-no-right-no .appContent .article-section.section-01 .section-body .body-box.dev-reservation-course-num-2,*/
/*  body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .article-section.section-01 .section-body .body-box.dev-reservation-course-num-2 {width: 547px;}*/
/*}*/
/*body .wrapper.dev-layout-left-no-right-no .appContent .body-article.dev-reservation-detail-view-opened .article-section.section-01 .section-body .body-box.dev-reservation-course-num-2,*/
/*body .wrapper.dev-layout-left-no-right-no .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .section-body .body-box.dev-reservation-course-num-2,*/
/*body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .body-article.dev-reservation-detail-view-opened .article-section.section-01 .section-body .body-box.dev-reservation-course-num-2,*/
/*body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .section-body .body-box.dev-reservation-course-num-2 {width: 229px;}*/
/*@media (min-width: 1920px) {*/
/*  body .wrapper.dev-layout-left-no-right-no .appContent .body-article.dev-reservation-detail-view-opened .article-section.section-01 .section-body .body-box.dev-reservation-course-num-2,*/
/*  body .wrapper.dev-layout-left-no-right-no .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .section-body .body-box.dev-reservation-course-num-2,*/
/*  body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .body-article.dev-reservation-detail-view-opened .article-section.section-01 .section-body .body-box.dev-reservation-course-num-2,*/
/*  body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .body-article.dev-reservation-detail-view-fixed .article-section.section-01 .section-body .body-box.dev-reservation-course-num-2 {width: 525px;}*/
/*}*/
/*body .appContent .body-article.dev-reservation-list-view-fixed .article-section.section-01 .section-body .body-box:not(.dev-reservation-daily-status),*/
/*body .appContent .body-article.dev-reservation-list-view-fixed .article-section.section-01 .section-body .body-box.dev-reservation-active {flex: none; width: calc(100% + 2px) }*/
/*body .appContent .body-article.dev-reservation-list-view-fixed .article-section.section-01:not(.reservation-close) .section-body.dev-reservation-course-num-4 .body-box,*/
/*body .appContent .body-article.dev-reservation-list-view-fixed .article-section.section-01:not(.reservation-close) .section-body.dev-reservation-course-num-4 .body-box.dev-reservation-active {flex: none; width: calc(((100% - 270px) / 4) + 2px) !important;}*/
/*body .appContent .article-section.section-01.reservation-accordion.reservation-close .section-body .body-box.dev-reservation-course-num-4,*/
/*body .appContent .article-section.section-01.reservation-accordion.reservation-close .section-body .body-box.dev-reservation-course-num-4 .dev-reservation-active {width: calc((100% / 4) + 2px) !important;}*/
/*body .appContent .body-article.dev-reservation-list-view-fixed .article-section.section-01:not(.reservation-close) .section-body .body-box.dev-reservation-course-num-3,*/
/*body .appContent .body-article.dev-reservation-list-view-fixed .article-section.section-01:not(.reservation-close) .section-body .body-box.dev-reservation-course-num-3.dev-reservation-active {flex: none; width: calc(((100% - 270px) / 3) + 1px) !important;}*/
/*body .appContent .body-article.dev-reservation-list-view-fixed .article-section.section-01.reservation-accordion.reservation-close .section-body .body-box.dev-reservation-course-num-3,*/
/*body .appContent .body-article.dev-reservation-list-view-fixed .article-section.section-01.reservation-accordion.reservation-close .section-body .body-box.dev-reservation-course-num-3 .dev-reservation-active {width: calc(((100% - 130px) / 3) + 1px) !important;}*/
/*body .appContent .body-article.dev-reservation-list-view-fixed .article-section.section-01:not(.reservation-close) .section-body .body-box.dev-reservation-course-num-2,*/
/*body .appContent .body-article.dev-reservation-list-view-fixed .article-section.section-01:not(.reservation-close) .section-body .body-box.dev-reservation-course-num-2.dev-reservation-active {flex: none; width: calc(((100% - 270px) / 2) + 1px) !important;}*/
/*body .appContent .body-article.dev-reservation-list-view-fixed .article-section.section-01.reservation-accordion.reservation-close .section-body .body-box.dev-reservation-course-num-2,*/
/*body .appContent .body-article.dev-reservation-list-view-fixed .article-section.section-01.reservation-accordion.reservation-close .section-body .body-box.dev-reservation-course-num-2.dev-reservation-active {width: calc(((100% - 130px) / 2) + 1px) !important;}*/
body .appContent .body-article .article-section.section-01 .section-body .body-box .body-grid >>> .dev-income-deduction {background: url(../../assets/images/common/income_front_visit.png) no-repeat right bottom;}
body .appContent .body-article .article-section.section-01 .section-body .body-box .body-grid >>> .roomConfirm {
  width: 13px;
  height: 13px;
  display: block;
  position: absolute;
  right: 0;
  top:0;
  filter: brightness(0.5);
  background-image: url(../../assets/images/ui/icon-type05.png);
}
/*.dev-active{width=""}*/
/*
body .appContent .article-section.section-01 .section-header {width: calc(100% - 76px - 21px);}
body .appContent .article-section.section-01 .header-switch {right: calc(0px - 76px - 18px);}
*/
body .appContent .article-section.section-01 .header-left {width: calc(100% - 100px);}
body .appContent .article-section.section-01 .header-right {float: left; width: calc(100% - 100px);}
body .appContent .article-section.section-01 .header-label .title {color: #666; font-family: "돋움", Dotum, Arial, Verdana, sans-serif; font-weight: bold;}
body .appContent .article-section.section-01 .header-label .content {margin: 0;}
body .appContent .article-section.section-01 .header-label .field {padding: 0 2px;}
body .appContent .article-section.section-01 .header-switch.fixed {top: calc(17px + 21px + 7px);}
body .appContent .article-section.section-01 .section-body {display: flex; flex-direction: row; overflow: hidden; padding: 0 1px; border: none;}
body .appContent .article-section.section-01 .section-body:after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
body .appContent .article-section.section-01 .body-box:not(.dev-reservation-daily-status) {overflow: hidden; float: left; height: 100%; margin: 0 -1px;}
body .appContent .article-section.section-01 .body-grid {height: calc(100% - 54px);}
body .appContent .article-section.section-01 .body-grid >>> .e-grid.e-lib {border-bottom-color: #e0e0e0;}
body .appContent .article-section.section-01 .body-grid >>> .e-grid.e-lib .e-gridheader {border-top-color: #e0e0e0;}
body .appContent .article-section.section-01 .body-header {box-sizing: border-box; height: 24px; padding: 3px 8px 2px 8px; border: 1px solid #ccc; border-bottom: none; background-color: #f9f9f9; color: #000; text-align: center;}
body .appContent .article-section.section-01 .body-footer {overflow: hidden; box-sizing: border-box; height: 30px; padding: 0 8px; border: 1px solid #ccc; border-top: none; background-color: #f9f9f9; color: #000;}
body .appContent .article-section.section-01 .body-footer ul {display: block; list-style: none; width: 2000px; padding: 0; margin: 3px -8px;}
@supports (display: flow-root) {body .appContent .article-section.section-01 .body-footer ul {display: flow-root;}}
body .appContent .article-section.section-01 .body-footer ul:after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
body .appContent .article-section.section-01 .body-footer ul li {float: left; padding: 3px 9px 3px 10px; margin: 0 0 -1px 0; background: transparent url('../../assets/images/common/division.png') no-repeat left center;}
body .appContent .article-section.section-01 .body-footer ul li strong {font-weight: bold;}
body .appContent .article-section.section-01 .body-footer ul li:first-child {padding: 3px 9px; background: none;}
body .appContent .article-section.section-01 .body-box.dev-reservation-active {flex: 1; width: auto !important; z-index: 10;}
body .appContent .article-section.section-01 .body-box.dev-reservation-active .body-grid >>> .e-grid.e-lib {border-left-color: #000; border-right-color: #000;}
body .appContent .article-section.section-01 .body-box.dev-reservation-active .body-grid >>> .dev-reservation-add-mode-row .e-rowcell:first-child {background-image: url('../../assets/images/common/focus-strong.png'); background-repeat: no-repeat; background-position: left top;}
body .appContent .article-section.section-01 .body-box.dev-reservation-active .body-header,
body .appContent .article-section.section-01 .body-box.dev-reservation-active .body-footer {border-color: #000;}

body .appContent .article-section.section-02 .section-header {width: calc(100% - 76px - 21px);}
body .appContent .article-section.section-02 .header-switch {right: calc(0px - 76px - 18px);}
body .appContent .article-section.section-02 .section-body {overflow-y: scroll;}
body .appContent .article-section.section-02 .body-article {height: auto;}
body .appContent .article-section.section-02 .body-article .article-section {height: auto;}
body .appContent .article-section.section-02 .body-article .section-header {width: auto;}
body .appContent .article-section.section-02 .body-article .section-body {overflow: visible; border: none;}

body .appContent .article-section.section-0201 .body-data .field {width: calc((100% / 10) + 1px);}
body .appContent .article-section.section-0201 .body-data .field .title {padding-left: 6px; padding-right: 6px; text-align: center; display: inline-block;}
body .appContent .article-section.section-0201 .body-data .field .content .item {overflow: hidden; text-overflow:ellipsis; white-space: nowrap; padding-left: 3px; padding-right: 3px;}
body .appContent .article-section.section-0201 .body-data .field.check {width: 28px;}
body .appContent .article-section.section-0201 .body-data .field.date {width: 50px; text-align: center;}
body .appContent .article-section.section-0201 .body-data .field.course {width: 70px;}
body .appContent .article-section.section-0201 .body-data .field.time {width: 40px; text-align: center;}
body .appContent .article-section.section-0201 .body-data .field.hole {width: 40px; text-align: center;}
body .appContent .article-section.section-0201 .body-data .field.visitCnt {width: 60px; text-align: center;}
body .appContent .article-section.section-0201 .body-data .field.fee {width: 50px; text-align: right;}
body .appContent .article-section.section-0201 .body-data .field.body {background-color: #f9f9f9;}
body .appContent .article-section.section-0201 .body-data.header .field.reservedChannel {width: calc((100% + 1px) - (328px - 1px));}
body .appContent .article-section.section-0201 .body-data.body .field.reservedChannel {width: calc((100% + 1px) - (328px - 1px - 17px));}
body .appContent .article-section.section-0201 .body-data.body .data-outer {max-height: 361px; overflow-y: scroll;}

body .appContent .article-section.section-0201 .body-data.body .article-section.section-020101 .body-data .field .content .item {overflow: visible; text-overflow: clip; white-space: normal;}
body .appContent .article-section.section-0201 .body-data.body .article-section.section-020101 .body-data .field .title {width: 90px;}
body .appContent .article-section.section-0201 .body-data.body .article-section.section-020101 .body-data .field .content {width: calc((100% - 90px) + 1px);}
body .appContent .article-section.section-0201 .body-data.body .article-section.section-020101 .body-data .field.memberName .content .item.input {width: calc(100% - 23px);}

body .appContent .article-section.section-0201 .body-data.body .article-section.section-020102 .body-grid {height: 140px;}

body .appContent .article-section.section-0201 .body-data.body .article-section.section-020103 .body-data .field .content .item {overflow: visible; text-overflow: clip; white-space: normal;}
body .appContent .article-section.section-0201 .body-data.body .article-section.section-020103 .body-data .field .title {width: 90px;}
body .appContent .article-section.section-0201 .body-data.body .article-section.section-020103 .body-data .field .content {width: calc((100% - 90px) + 1px);}
body .appContent .article-section.section-0201 .body-data.body .article-section.section-020103 .body-data .field.promotion .content .item.input {width: calc(100% - 23px);}
body .appContent .article-section.section-0201 .body-data ul.data-content.selected {background: #ecfaf0;}

body .appContent .article-section.section-0202 .body-data .field .title {width: 90px;}
body .appContent .article-section.section-0202 .body-data .field .content {width: calc((100% - 90px) + 1px);}
body .appContent .article-section.section-0202 .body-data .field.groupName {width: calc(100% + 1px);}
body .appContent .article-section.section-0202 .body-data .field.groupName .content .item.input {width: 105px;}
body .appContent .article-section.section-0202 .body-data .field.groupName .content .item.select {width: 89px;}
body .appContent .article-section.section-0202 .body-data .field.reservationName .content .item.input {width: calc(100% - 23px);}
body .appContent .article-section.section-0202 .body-data .field.remarks {width: calc(100% + 1px);}
body .appContent .article-section.section-0202 .body-data .field.sendSMS {width: calc(100% + 1px);}
body .appContent .article-section.section-0202 .body-data .field.sendSMS .content .item.text-01 {width: 86px;}
body .appContent .article-section.section-0202 .body-data .field.sendSMS .content .item.text-02 {width: 43px;}
body .appContent .article-section.section-0202 .body-data .field.enrollment {width: calc(100% + 1px);}
body .appContent .article-section.section-0202 .body-data .field.enrollment .content .item.text-01 {width: 129px;}
body .appContent .article-section.section-0202 .body-data .field.modified {width: calc(100% + 1px);}
body .appContent .article-section.section-0202 .body-data .field.modified .content .item.text-01 {width: 129px;}

body .appContent .article-section.section-0203 .section-body {display: block;}
@supports (display: flow-root) {body .appContent .article-section.section-0203 .section-body {display: flow-root;}}

body .appContent .article-section.section-0204 .section-body:after {display: block;clear: both;height: 0;font-size: 0;line-height: 0;content: "";}
body .appContent .article-section.section-0204 .section-body .body-grid {height: 176px;}
body .appContent .article-section.section-0205 .section-body .body-grid {height: 165px;}
body .appContent .article-section.section-0205 .section-body:after {display: block;clear: both;height: 0;font-size: 0;line-height: 0;content: "";}
body .appContent .article-section.section-0206 .section-body .body-grid {height: 100px;}
body .appContent .article-section.section-0206 .section-body:after {display: block;clear: both;height: 0;font-size: 0;line-height: 0;content: "";}
.flexbox{flex: 0.33;}

body .e-grid.e-lib .e-gridheader .e-headercell.dev-context-menu-area {background-image: url('../../assets/images/common/context-menu.png'); background-repeat: no-repeat; background-position: center calc(50% - 1px);}
body .content-body .article-section.section-0205 .section-body {overflow: hidden;border: none;}
body .content-body .article-section.section-0205 .section-body >>> .e-grid.e-lib .dropdownbutton {margin: 0 -8px;}
body .content-body .article-section.section-0205 .section-body >>> .e-grid.e-lib .dropdownbutton .e-btn {overflow: hidden;box-shadow: none;width: 23px;height: 23px;padding: 0;border: none;border-radius: 0;background: transparent url(../../assets/images/common/button-icon-etc.png) no-repeat center center;text-indent: -1000px;}
</style>
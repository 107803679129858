var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ejs-dialog',{ref:"agencyCodeDetailPopup",attrs:{"header":"대행사 등록 상세","animationSettings":_vm.animationSettings,"showCloseIcon":"true","width":"1180","height":"800","position":_vm.popupPosition,"enableResize":"true","isModal":true}},[_c('div',{staticClass:"window agencyCodeDetails"},[_c('div',{staticClass:"windowContent"},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"content-body"},[_c('article',{staticClass:"body-article"},[_c('section',{staticClass:"article-section section-01"},[_c('div',{staticClass:"section-header"},[_c('div',{staticClass:"header-left"},[_c('div',{staticClass:"header-title"},[_vm._v(" 대행사 등록 상세 ["+_vm._s(this.agencyCode.agncyCode)+"] ")])]),_c('div',{staticClass:"header-right"},[_c('ul',{staticClass:"header-button"})])]),_c('div',{staticClass:"section-body"},[_c('article',{staticClass:"body-article"},[_c('div',{staticClass:"section-header"},[_c('div',{staticClass:"header-left"},[_c('div',{staticClass:"header-title"},[_vm._v(" 대행사 예약 타임 오픈 설정 ")])])]),_c('div',{staticClass:"section-body"},[_c('article',{staticClass:"body-article"},[_c('section',{staticClass:"article-section section-010101"},[_c('div',{staticClass:"section-header"},[_c('div',{staticClass:"header-right"},[_c('ul',{staticClass:"header-button"},[_c('li',{staticClass:"add"},[_c('ejs-button',{attrs:{"disabled":_vm.commonMixinIsButtonDisableByAuth(
                                              'agencyRegistrationReg')},nativeOn:{"click":function($event){return _vm.onAddAgncyResveTime.apply(null, arguments)}}},[_vm._v(" 추가 ")])],1),_c('li',{staticClass:"delete"},[_c('ejs-button',{attrs:{"disabled":_vm.commonMixinIsButtonDisableByAuth(
                                              'agencyRegistrationReg'
                                            )},nativeOn:{"click":function($event){return _vm.onDeleteAgncyResveTime.apply(null, arguments)}}},[_vm._v(" 삭제 ")])],1)])])]),_c('div',{staticClass:"section-body"},[_c('ejs-grid-wrapper',_vm._b({ref:"agncyResveTimeGrid",attrs:{"id":"agncyResveTimeGrid","allowResizing":true,"allowSelection":true,"allowPaging":true,"position":_vm.popupPosition},on:{"headerCellInfo":_vm.resveTimeHeaderCellInfo,"queryCellInfo":_vm.resveTimeQueryCellInfo,"cellEdit":_vm.onGridCellEdit}},'ejs-grid-wrapper',_vm.agncyResveTimegridOption,false))],1)])])]),_c('p'),_c('div',{staticClass:"section-header"},[_c('div',{staticClass:"header-left"},[_c('div',{staticClass:"header-title"},[_vm._v(" 대행사 IP 정보 ")])])]),_c('div',{staticClass:"section-body"},[_c('article',{staticClass:"body-article"},[_c('section',{staticClass:"article-section section-010201"},[_c('div',{staticClass:"section-header"},[_c('div',{staticClass:"header-right"},[_c('ul',{staticClass:"header-button"},[_c('li',{staticClass:"add"},[_c('ejs-button',{attrs:{"disabled":_vm.commonMixinIsButtonDisableByAuth(
                                            'agencyRegistrationReg')},nativeOn:{"click":function($event){return _vm.onAddAgncyIp.apply(null, arguments)}}},[_vm._v(" 추가 ")])],1),_c('li',{staticClass:"delete"},[_c('ejs-button',{attrs:{"disabled":_vm.commonMixinIsButtonDisableByAuth(
                                            'agencyRegistrationReg'
                                          )},nativeOn:{"click":function($event){return _vm.onDeleteAgncyIp.apply(null, arguments)}}},[_vm._v(" 삭제 ")])],1)])])]),_c('div',{staticClass:"section-body"},[_c('ejs-grid-wrapper',_vm._b({ref:"agncyIpGrid",attrs:{"provides":_vm.agncyIpgridOption.provides,"columns":_vm.agncyIpgridOption.columns,"position":_vm.popupPosition,"allowResizing":true,"allowSelection":true,"allowPaging":true,"pageSettings":_vm.agncyIpgridOption.pageSettings,"editSettings":_vm.agncyIpgridOption.editSettings},on:{"headerCellInfo":_vm.ipHeaderCellInfo,"queryCellInfo":_vm.ipQueryCellInfo,"cellEdit":_vm.onGridCellEdit}},'ejs-grid-wrapper',_vm.agncyIpgridOption,false))],1)])])])])])])])])])]),_c('div',{staticClass:"windowFooter"},[_c('ul',{staticClass:"button"},[_c('li',{staticClass:"reset"},[_c('ejs-button',{attrs:{"disabled":_vm.commonMixinIsButtonDisableByAuth(
                                'agencyRegistrationReg'
                              )},nativeOn:{"click":function($event){return _vm.onInitClicked.apply(null, arguments)}}},[_vm._v(" 새로고침 ")])],1),_c('li',{staticClass:"save keyColor"},[_c('erp-button',{attrs:{"disabled":_vm.commonMixinIsButtonDisableByAuth('agencyRegistrationReg')},nativeOn:{"click":function($event){return _vm.onSaveAgencyDetail.apply(null, arguments)}}},[_vm._v(" 저장 ")])],1),_c('li',{staticClass:"close"},[_c('ejs-button',{nativeOn:{"click":function($event){return _vm.onCloseClicked.apply(null, arguments)}}},[_vm._v(" 닫기 ")])],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/common/delete.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/* Custom */\n\n/* 주소 찾기 팝업 우측 스크롤 표시 (@/views/common/DaumPostcodePopup.vue) */\ndiv#daumPostcodePopupDialog_dialog-content.e-dlg-content {overflow-y: auto;}\n\n/* 객실 예약 등록 */\nbody .appContent .content-wrapper.roomReservationRegistration .article-section.section-0202 .section-body .e-grid.e-lib .e-gridcontent {height: calc(100% - 26px) !important;}\n\n/* 객실 체크인 */\nbody .appContent .content-wrapper.roomCheckIn .article-section.section-020201.checkin .section-body .e-grid.e-lib .e-gridcontent {height: calc(100% - 51px) !important;}\n\n/* 예약 취소 팝업 */\nbody .window.cancelReservation .content-body .body-article .article-section.section-02 .section-body .body-grid {max-height: 507px;}\nbody .window.cancelReservation .content-body .body-article .article-section.section-02 .section-body .body-grid .e-grid.e-lib .e-gridcontent {max-height: 481px;}\nbody .window.cancelReservation .content-body .body-article .article-section.section-02 .section-body .body-grid .e-grid.e-lib .e-gridcontent .e-content {max-height: 481px;}\n\n/* 자체 개발 Grid */\nbody .f-grid-wrapper .f-grid .f-grid-body .f-grid-row .f-grid-cell:hover.dev-delete-hover-area {color: transparent !important; background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:center;cursor: pointer;}\n\n/* CKEDITOR5 */\nbody .window.postRegistrationPopup.news .ck-editor__editable {min-height: 570px;}\nbody .window.postRegistrationPopup.event .ck-editor__editable {min-height: 445px;}\n\n/* Grid 사용시 부모 클래스 section-body의 border 없애기 */\nbody .section-body:has(>.body-grid) {border: none;}", ""]);
// Exports
module.exports = exports;

<template>
  <div>
    <ejs-dialog
      ref="agencySettingPopup"
      header="대행사 설정"
      :allowDragging="true"
      :showCloseIcon="true"
      :width="800"
      height="595"
      :animationSettings="{ effect: 'None' }"
      :isModal="true"
      @close="onPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">대행사 설정 목록</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li class="add">
                          <erp-button
                            button-div="SAVE"
                            :is-icon-custom="true"
                            @click.native="onAddButtonClicked"
                          >
                            추가
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <ejs-grid-wrapper
                      ref="grid"
                      v-bind="gridProps"
                      :dataSource="agencys"
                      @headerCellInfo="onGridHeaderCellInfo"
                      @queryCellInfo="onGridQueryCellInfo"
                    />
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <div class="lookup-condition">
            <ul class="button">
              <li class="delete">
                <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  :disabled="!isAgencyOpenTimes"
                  @click.native="onAgencyDeleteButtonClicked"
                >
                  대행사 제거
                </erp-button>
              </li>
            </ul>
          </div>
          <ul class="button">
            <li class="keyColor confirm">
              <erp-button
                button-div="SAVE"
                :is-icon-custom="true"
                :is-shortcut-button="true"
                @click.native="onSaveButtonClicked"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                button-div="CLOSE"
                @click.native="onCloseButtonClicked"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .window .windowContent .article-section .section-body {overflow: visible; border: none;}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import EjsGridWrapper from "@/components/common/EjsGridWrapper";

import {
  Edit,
  ForeignKey,
} from '@syncfusion/ej2-vue-grids';

import {
  commonCodeGetComCodeDefaultValue,
  commonCodesGetCommonCodeByIdxAttrb,
} from "@/utils/commonCodes";
// import {numberWithCommas} from "@/utils/number";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import _groupBy from "lodash/groupBy";

export default {
  name: "AgencySettingPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    ErpButton
  },
  data() {
    return {
      agencyCodes: commonCodesGetCommonCodeByIdxAttrb("RESVE_CHANNEL", 1, "AGENT").concat(commonCodesGetCommonCodeByIdxAttrb("RESVE_CHANNEL", 1, "COPRTN")),
      agencys: [],
      timeIds: [],
      isSaved: false,
      isAgencyOpenTimes: false,
    };
  },
  computed: {
    gridProps() {
      return {
        provides: [
          Edit,
          ForeignKey,
        ],
        allowFiltering: false,
        allowSorting: false,
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
          persistSelection: false,
        },
        columns: [
          {
            field: "resveChannel",
            headerText: "대행사",
            type: "string",
            minWidth: 16,
            width: 90,
            editType: "dropdownedit",
            isCommonCodeField: true,
            dataSource: this.agencyCodes,
          },
          {
            field: "perOption",
            headerText: "인원옵션",
            type: "string",
            minWidth: 16,
            width: 90,
            editType: "dropdownedit",
            isCommonCodeField: true,
            groupCode: "PER_OPTION",
          },
          {
            field: "setleOption",
            headerText: "결제옵션",
            type: "string",
            minWidth: 16,
            width: 90,
            editType: "dropdownedit",
            isCommonCodeField: true,
            groupCode: "SETLE_OPTION",
          },
          {
            field: "norPaymtCode",
            headerText: "정상그린피",
            type: "string",
            minWidth: 16,
            width: 90,
            editType: "dropdownedit",
            isCommonCodeField: true,
            groupCode: "PAYMT_CODE",
            isDropdowneditInit: true,
            dropdowneditInitValue: "",
          },
          {
            field: "dcPaymtCode",
            headerText: "할인그린피",
            type: "string",
            minWidth: 16,
            width: 90,
            editType: "dropdownedit",
            isCommonCodeField: true,
            groupCode: "PAYMT_CODE",
            isDropdowneditInit: true,
            dropdowneditInitValue: "",
          },
          {
            field: "purcPaymtCode",
            headerText: "매입가",
            type: "string",
            minWidth: 16,
            width: 90,
            editType: "dropdownedit",
            isCommonCodeField: true,
            groupCode: "PAYMT_CODE",
            isDropdowneditInit: true,
            dropdowneditInitValue: "",
          },
        ],
      };
    },
  },
  created() {},
  methods: {
    showPopup(args) {
      this.timeIds = args.timeIds;
      this.isAgencyOpenTimes = args.isAgencyOpenTimes;

      this.agencys = this.agencyCodes.map(item => {
        return {
          resveChannel: null,
          perOption: commonCodeGetComCodeDefaultValue("PER_OPTION"),
          setleOption: commonCodeGetComCodeDefaultValue("SETLE_OPTION"),
        };
      });
    },
    onPopupClosed() {
      this.$emit("popupClosed", { isReload: this.isSaved });
    },
    onCloseButtonClicked() {
      this.$refs.agencySettingPopup.hide();
    },
    onAddButtonClicked() {
      this.$refs.grid.addRecord({
        perOption: commonCodeGetComCodeDefaultValue("PER_OPTION"),
        setleOption: commonCodeGetComCodeDefaultValue("SETLE_OPTION"),
      });
    },
    async onSaveButtonClicked() {
      if (!this.$refs.grid.validate()) {
        return;
      }

      if (!(await this.confirm("저장하시겠습니까?"))) {
        return;
      }

      const data = this.$refs.grid.getBatchCurrentViewRecords().filter(item => item.resveChannel);
      console.log("data.===>", data);

      if (!(this.timeIds.length > 0)) {
        this.errorToast("선택된 타임이 없습니다");
        return;
      }

      if (!(data.length > 0)) {
        this.errorToast("대행사 설정이 없습니다");
        return;
      }

      if (Object.values(_groupBy(data, "resveChannel")).filter(item => item.length > 1).length > 0) {
        this.errorToast("대행사는 중복하여 등록하실 수 없습니다");
        return;
      }

      await GolfErpAPI.reservationTimeAgencySetting({
        timeIds: this.timeIds,
        tgResveChannels: data,
      });

      this.infoToast("저장되었습니다");
      this.isSaved = true;
    },
    async onAgencyDeleteButtonClicked() {
      if (!(await this.confirm("선택된 타임들의 대행사 설정이 제거됩니다.<br>진행하시겠습니까?"))) {
        return;
      }

      await GolfErpAPI.reservationTimeAgencyDelete(this.timeIds);

      this.infoToast("삭제되었습니다");
      this.isSaved = true;
    },
    onGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field
          },
        },
        node: {
          classList
        },
      } = args;

      if (field === "resveChannel") {
        classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    onGridQueryCellInfo(args) {
      const {
        column: {
          allowEditing,
        },
        cell: {
          classList,
        },
      } = args;

      if (allowEditing) {
        classList.add(this.$t("className.grid.modifyArea"));
      }
    },
  },
};
</script>

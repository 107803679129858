<template>
  <div>
    <ejs-dialog
      ref="createPriceByHoleDividerPopup"
      :header="`홀별 요금 분할설정`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="301"
      :isModal="true"
      :close="onPriceByHoleDividerPopupClosed"
    >
      <div class="window holePriceSetting">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">홀별 요금 분할설정</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">주중인프라요금</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group number">
                                    <div class="form">
                                      <input-number
                                        :min="0"
                                        v-model="priceData.infrPaymt"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">주말인프라요금</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group number">
                                    <div class="form">
                                      <input-number
                                          :min="0"
                                          v-model="priceData.infrPaymtWeekend"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">분할단위</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group number">
                                    <div class="form">
                                      <input-number
                                        :min="100"
                                        v-model="priceData.paymtDivideUnit"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">분할기준</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="paymtDivisionStdCodes"
                                        v-model="priceData.paymtDivideStd"
                                        :dataSource="paymtDivisionStdCodes"
                                        :allowFiltering="false"
                                        :fields="fields"
                                        cssClass="lookup-condition-dropdown"
                                      >
                                      </ejs-dropdownlist>
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">제세금</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group number">
                                    <div class="form">
                                      <input-number
                                        :min="0"
                                        v-model="priceData.myTax"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                    <div class="body-caption">※ 회원제 골프장만 입력</div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  @click.native="onSavePriceByHoleDivider"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onPriceByHoleDividerPopupClosed"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import InputNumber from "@/components/common/InputNumber";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "priceByHoleDividerPopup",
  mixins: [commonMixin],
  components: {
    InputNumber,
    ErpButton,
  },
  mounted() {
    if (this.paymtDivisionStdCodes.length > 0) {
      this.priceData.paymtDivideStd = this.paymtDivisionStdCodes[0].comCode;
    }
  },
  data() {
    return {
      paymtDivisionStdCodes: commonCodesGetCommonCode("PAYMT_DIVIDE_STD"),
      fields: { text: "comName", value: "comCode" },
      priceData: {
        infrPaymt: 0,
        infrPaymtWeekend: 0,
        paymtDivideUnit: 0,
        paymtDivideStd: null,
        myTax: 0,
      },
    };
  },
  methods: {
    preventEnterEvent(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
    onShowPriceByHoleDivider(data) {
      this.priceData = data;
      this.$refs.createPriceByHoleDividerPopup.show();
    },
    onSavePriceByHoleDivider() {
      this.$emit("setPriceDivData", this.priceData);
    },
    onPriceByHoleDividerPopupClosed() {
      this.$refs.createPriceByHoleDividerPopup.hide();
      this.$emit("popupClosed");
    },
  },
};
</script>

<template>
  <div>
    <ejs-dialog ref="dialog" v-bind="dialogProps" @close="onDialogClose">
      <div
        class="typePos"
        :style="{
          minWidth: `${dialogProps.width}px`,
          minHeight: `${dialogProps.height - 48}px`,
        }"
      >
        <div
          class="typePos-wrapper-caption"
          :class="tableId.start ? 'draggable' : ''"
        >
          <div class="typePos-header">
            <div class="typePos-filed">
              <div class="typePos-filed-content">
                <div class="typePos-item">
                  <div class="typePos-caption">테이블 현황</div>
                </div>
              </div>
            </div>
            <div class="typePos-filed">
              <div class="typePos-filed-content">
                <div class="typePos-item">
                  <div class="typePos-content">
                    {{ bsnDateLocaleString }}
                  </div>
                </div>
              </div>
            </div>
            <div class="typePos-filed typePos-store">
              <div class="typePos-filed-content">
                <div class="typePos-item">
                  <div class="typePos-title">{{ storeName }} 테이블</div>
                </div>
              </div>
            </div>
            <div class="typePos-filed">
              <div class="typePos-filed-content">
                <div class="typePos-item">
                  <div class="typePos-title">테이블 :</div>
                  <div class="typePos-content">
                    <strong>{{ usingTableCount }}개</strong> 사용 /
                    {{ tableCount }}개
                  </div>
                </div>
              </div>
            </div>
            <div class="typePos-filed">
              <div class="typePos-filed-content">
                <div class="typePos-item">
                  <div class="typePos-title">매출금액 :</div>
                  <div class="typePos-content">
                    {{ storeSalesAmount | numberWithCommas }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="typePos-footer">
            <div class="typePos-filed">
              <div class="typePos-filed-content">
                <div class="typePos-item">
                  <div class="typePos-title">사용자 :</div>
                  <div class="typePos-content">
                    {{ username }}
                  </div>
                </div>
              </div>
            </div>
            <div class="typePos-filed">
              <erp-button
                button-div="GET"
                class="ui-button"
                :use-syncfusion-component-style="false"
                @click="onToggleScreenSizeClick"
              >
                <div class="i-item">{{ screenSizeLabel }}</div>
              </erp-button>
            </div>
          </div>
        </div>
        <div
          id="table-status-popup-typePos-body"
          class="typePos-wrapper-main"
          :class="tableId.start ? 'draggable' : ''"
        >
          <ul
            class="typePos-main-body"
            :class="tableId.start ? 'draggable' : ''"
          >
            <!-- 전표 활성(Class) : typePos-chit / 활성(Class) : typePos-active -->
            <!-- 가로크기(Style) : 100% / 가로나열 개수 -->
            <li
              v-for="(storeTable, index) in explodedStoreTablesBySlips"
              v-bind:key="`pos-tableStatus-${storeTable.id}-${index}`"
              class="typePos-chit"
              :class="{
                'typePos-active':
                  activeExplodedStoreTable &&
                  storeTable._rid === activeExplodedStoreTable_rid,
                'draggable': tableId.start
              }"
              :style="{ width: `calc(100% / ${maxColIndexTable})` }"
            >
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="GET"
                ref="exploded-store-tables-by-slips"
                :class="{
                  'typePos-unused': !storeTable.isUse,
                  'draggable': tableId.start
                }"
                :disabled="!storeTable.isUse"
                :use-syncfusion-component-style="false"
                @click="onStoreTableClick($event, { storeTable })"
                @dblclick="onStoreTableDoubleClick($event, { storeTable })"
                @keydown="onExplodedStoreTableKeydown($event, { storeTable })"
              >
                <div
                  class="typePos-header table-header memo-box"
                  :class="tableId.start ? 'draggable' : ''"
                >
                  <div class="table-name" v-if="storeTable.isUse">
                    {{ storeTable.name }}
                  </div>
                  <div class="memo-box-img">
                    <div
                        :class="memoCheck(storeTable)"
                        @click="openMemoPopup(storeTable)"
                    ></div>
                    <div class="caddie-name">
                      {{ getCaddieName(storeTable.slip) }}
                    </div>
                  </div>
                </div>
                <div
                  class="typePos-body"
                  :class="tableId.start ? 'draggable' : ''"
                  :style="{
                    backgroundColor: storeTable.isUse
                      ? storeTable.colorValue
                      : undefined,
                    boxShadow:
                      storeTable.isUse &&
                      storeTable === activeExplodedStoreTable
                        ? '0 0 0 500rem rgba(24, 179, 201, .5) inset'
                        : undefined,
                    outline:
                      storeTable.isUse &&
                      storeTable === activeExplodedStoreTable
                        ? '3px solid #18B3C9'
                        : undefined,
                  }"
                  :table-data-id="storeTable.id"
                  @mousedown="onPosTableDivMouseDown"
                >
                  <div
                    class="typePos-content"
                    :table-data-id="storeTable.id"
                    :class="tableId.start ? 'draggable' : ''"
                  >
                    <template v-if="storeTable.isUse && storeTable.slip">
                      {{ getStartCourseAndStartTime(storeTable.slip) }}<br />
                      {{ getVisitorName(storeTable.slip) }}
                      {{ getLockerNumber(storeTable.slip) }}<br />
                      {{ getSalesSummary(storeTable.slip) }}
                    </template>
                  </div>
                  <div
                    class="typePos-price"
                    :table-data-id="storeTable.id"
                  >
                    <template v-if="storeTable.isUse && storeTable.slip">
                      {{
                        getTotalSalesAmount(storeTable.slip) | numberWithCommas
                      }}
                    </template>
                  </div>
                  <div
                    class="typePos-state"
                    :table-data-id="storeTable.id"
                  >
                    <template v-if="storeTable.isUse && !storeTable.slip">
                      신규전표
                    </template>
                  </div>
                </div>
              </erp-button>
            </li>
          </ul>
        </div>
        <div
          class="typePos-wrapper-footer"
          :class="tableId.start ? 'draggable' : ''"
        >
          <ul
            class="typePos-button"
            :class="tableId.start ? 'draggable' : ''"
          >
            <li class="typePos-refresh">
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="GET"
                class="ui-button"
                :class="tableId.start ? 'draggable' : ''"
                :is-shortcut-button="true"
                :visible-shortcut-string="false"
                :ignore="isPopupOpened"
                :use-syncfusion-component-style="false"
                @click="onRefreshClick"
              >
                <div class="i-item">
                  새로고침 ({{
                    toShortcutString(refreshShortcutButtonProps.shortcut)
                  }})
                </div>
              </erp-button>
            </li>
            <li class="typePos-move">
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="SAVE"
                class="ui-button"
                :class="tableId.start ? 'draggable' : ''"
                :use-syncfusion-component-style="false"
                :disabled="!activeExplodedStoreTableSlip"
                @click="onChangeTableClick"
              >
                <div class="i-item">테이블이동</div>
              </erp-button>
            </li>
            <li class="typePos-publish">
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="GET"
                class="ui-button"
                :class="tableId.start ? 'draggable' : ''"
                :use-syncfusion-component-style="false"
                @click="onPrintBillPopupClick"
                :disabled="!activeExplodedStoreTableSlip"
              >
                <div class="i-item">계산서발행</div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="GET"
                class="ui-button"
                :class="tableId.start ? 'draggable' : ''"
                :use-syncfusion-component-style="false"
                @click="onPaymentListPopupClick"
              >
                <div class="i-item">정산내역</div>
              </erp-button>
            </li>
            <li class="typePos-delete">
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="DELETE"
                class="ui-button"
                :class="tableId.start ? 'draggable' : ''"
                :disabled="!activeExplodedStoreTableSlip"
                :ignore="isPopupOpened"
                :use-syncfusion-component-style="false"
                :is-shortcut-button="true"
                :visible-shortcut-string="false"
                @click.native="onConfirmDeleteSlipPopupClick"
              >
                <div class="i-item">
                  전표삭제 ({{ deleteShortcutButtonShortcutString }})
                </div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="SAVE"
                class="ui-button"
                :class="tableId.start ? 'draggable' : ''"
                :is-custom-shortcut-button="true"
                :shortcut-key="postSettleAmountShortcutButtonProps.shortcutKey"
                :shortcut="postSettleAmountShortcutButtonProps.shortcut"
                :visible-shortcut-string="false"
                :disabled="!activeExplodedStoreTableSlip"
                :ignore="isPopupOpened"
                :use-syncfusion-component-style="false"
                @click.native="onSettleAmountClick($event, { method: 'POST' })"
              >
                <div class="i-item">
                  후불정산 ({{
                    toShortcutString(
                      postSettleAmountShortcutButtonProps.shortcut
                    )
                  }})
                </div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="SAVE"
                class="ui-button"
                :class="tableId.start ? 'draggable' : ''"
                @click="onSettleAmountClick($event, { method: 'CASH' })"
                :disabled="!activeExplodedStoreTableSlip"
                :use-syncfusion-component-style="false"
              >
                <div class="i-item">선불정산</div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="GET"
                class="ui-button"
                :class="tableId.start ? 'draggable' : ''"
                @click="onSubmitClick"
                :disabled="!activeExplodedStoreTableSlip"
                :use-syncfusion-component-style="false"
              >
                <div class="i-item">전표선택</div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :class="tableId.start ? 'draggable' : ''"
                @click="onCloseClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="table fixed"
        :style="{
          width: `calc((100% - 160px) / ${maxColIndexTable})`,
          left: `${pos.x + 10}px`,
          top: `${pos.y + 10}px`,
          backgroundColor: getTableInfo().colorValue
            ? getTableInfo().colorValue
            : '#ffffff',
        }"
        v-if="tableId.start"
      >
        <div
          class="typePos-header"
          style="
            width: 100%;
            min-height: 19px;
            padding: 8px 0 9px 0;
            border-bottom: 1px solid #e0e0e0;
            border-radius: 3px 3px 0 0;
            color: #666;
            text-align: center;
            font-size: 13px;
            font-family: 'NanumSquare-Bold';
            line-height: 19px;
            background-color: #f9f9f9;"
        >
          {{ getTableInfo().name }}
        </div>
        <div
          style="
            display: block;
            position: absolute;
            left: 15px;
            top: 49px;
            width: calc(100% - 30px);
            color: #666;
            font-size: 13px;
            font-family: 'NanumSquare-Regular';
            line-height: 19px;
            text-align: left;
            text-rendering: auto;
          "
        >
          <template v-if="getTableInfo().slip">
            {{ getStartCourseAndStartTime(getTableInfo().slip) }}<br />
            {{ getVisitorName(getTableInfo().slip) }}
            {{ getLockerNumber(getTableInfo().slip) }}<br />
            {{ getSalesSummary(getTableInfo().slip) }}
          </template>
        </div>
        <div
          style="
            display: block;
            position: absolute;
            left: 15px;
            width: calc(100% - 30px);
            bottom: 7px;
            color: #222;
            font-size: 18px;
            font-family: 'NanumSquare-Bold';
            line-height: 27px;
            text-align: right;
          "
        >
          <template v-if="getTableInfo().slip">
            {{
              getTotalSalesAmount(getTableInfo().slip) | numberWithCommas
            }}
          </template>
        </div>
      </div>
    </ejs-dialog>
    <!-- 테이블 이동 팝업 -->
    <change-table-popup
      ref="change-table-popup"
      v-if="visibleChangeTablePopup"
      :sales-date="salesDate"
      :store="store"
      :slip="slip"
      @close="onChangeTablePopupClose"
    />
    <!-- 계산서발행 팝업 -->
    <print-bill-popup
      ref="print-bill-popup"
      v-if="visiblePrintBillPopup"
      @close="onPrintBillPopupClose"
    />
    <!-- 정산내역 팝업 -->
    <payment-list-popup
      ref="payment-list-popup"
      v-if="visiblePaymentListPopup"
      :sales-date="salesDate"
      :store="store"
      @submit="onPaymentListPopupSubmit"
      @close="onPaymentListPopupClose"
    />
    <!-- 전표삭제 팝업 -->
    <confirm-delete-slip-popup
      ref="confirm-delete-slip-popup"
      v-if="visibleConfirmDeleteSlipPopup"
      @close="onConfirmDeleteSlipPopupClose"
    />
    <!-- 정산 팝업 -->
    <settle-amount-popup
      ref="settle-amount-popup"
      v-if="visibleSettleAmountPopup"
      @close="onSettleAmountPopupClose"
    />
    <!-- 내장객찾기 팝업 -->
    <search-visitors-popup
      ref="search-visitors-popup"
      v-if="visibleSearchVisitorsPopup"
      :sales-date="salesDate"
      :store="store"
      @close="onSearchVisitorsPopupClose"
    />
    <!--   메모창  팝업   -->
    <memo-view-popup
        ref="memoViewPopup"
        v-if="visibleMemoViewPopup"
        @close="onMemoViewPopupClose"
        @memoChange="fetchStoreTables"
    />
  </div>
</template>

<style scoped>
.memo-box {flex-flow: row nowrap ; justify-content: space-between ; }
.memo-box-img { display: flex ; padding-right: 10px ; }
.not-memo { padding-right:23px;background-image:url('../../../../../assets/images/common/memoNot.png');background-repeat:no-repeat;background-position:center;cursor:pointer;  }
.normal-memo { padding-right:23px;background-image:url('../../../../../assets/images/common/memo.png');background-repeat:no-repeat;background-position:center;cursor:pointer; }
.new-memo { padding-right:23px;background-image:url('../../../../../assets/images/common/memoNewPoint.png');background-repeat:no-repeat;background-position:center;cursor:pointer; }
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 36px); margin: -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-caption {padding: 10px; border-bottom: 1px solid #bebebe;}
.typePos .typePos-wrapper-caption::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed,
.typePos .typePos-wrapper-caption .typePos-lookup {float: left;}
.typePos .typePos-wrapper-caption .typePos-filed {padding: 0 3px;}
.typePos .typePos-wrapper-caption .typePos-lookup {padding: 0 10px;}
.typePos .typePos-wrapper-caption .typePos-filed::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-title,
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content {float: left; padding: 0 7px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-title {padding-top: 10px; padding-bottom: 11px; color: #222; font-family: 'NanumSquare-Bold';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item {float: left; padding-left: 10px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item:first-child {padding-left: 0;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-caption,
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-title,
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content {float: left; padding-top: 10px; padding-bottom: 11px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-caption,
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-title {padding-right: 4px; color: #222; font-family: 'NanumSquare-Bold';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-caption {font-size: 18px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content strong {color: #18b3c9; font-weight: normal;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content em {color: #df2929; font-style: normal;}
.typePos .typePos-wrapper-caption .typePos-lookup >>> .ui-button .i-item::before {background-position-x: calc(-20px * 7);}
.typePos .typePos-wrapper-caption .typePos-header {float: left;}
.typePos .typePos-wrapper-caption .typePos-header::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-store .typePos-filed-content .typePos-item .typePos-title {padding-right: 0;}
.typePos .typePos-wrapper-caption .typePos-footer {float: right;}
.typePos .typePos-wrapper-caption .typePos-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}

.typePos .typePos-wrapper-main {flex: 1; overflow: auto;}
.typePos .typePos-wrapper-main .typePos-main-body {box-sizing: border-box; list-style: none; padding: 13px; margin: 0;}
.typePos .typePos-wrapper-main .typePos-main-body::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-body > li {box-sizing: border-box; float: left; padding: 7px;}
.typePos .typePos-wrapper-main .typePos-main-body > li button {display: flex; flex-direction: column; box-sizing: border-box; box-shadow: none; width: 100%; height: 153px; padding: 0; margin: 0; border: 1px solid #e0e0e0; border-radius: 4px; background-color: #fff;}
.typePos .typePos-wrapper-main .typePos-main-body > li button:hover {background-color: #f9f9f9;}
.typePos .typePos-wrapper-main .typePos-main-body > li button:disabled {opacity: 0.5;}
.typePos .typePos-wrapper-main .typePos-main-body > li button:disabled:hover {background-color: #fff;}
.typePos .typePos-wrapper-main .typePos-main-body > li.typePos-active button,
.typePos .typePos-wrapper-main .typePos-main-body > li.typePos-active button:hover {background-color: #18b3c9;}
.typePos .typePos-wrapper-main .typePos-main-body > li.typePos-active button:disabled,
.typePos .typePos-wrapper-main .typePos-main-body > li.typePos-active button:disabled:hover {background-color: #fff;}
.typePos .typePos-wrapper-main .typePos-main-body > li button .typePos-header {width: 100%; min-height: 19px; padding: 8px 0 9px 0; border-bottom: 1px solid #e0e0e0; border-radius: 3px 3px 0 0; color: #666; font-size: 13px; font-family: 'NanumSquare-Bold'; line-height: 19px; background-color: #f9f9f9;}
.typePos .typePos-wrapper-main .typePos-main-body > li button .typePos-header.table-header {display: flex;}
.typePos .typePos-wrapper-main .typePos-main-body > li button .typePos-header.table-header > .table-name {padding-left: 1rem;}
.typePos .typePos-wrapper-main .typePos-main-body > li button .typePos-header.table-header > .caddie-name {display: block; flex: 1; justify-content: flex-end; padding-left: 5px; padding-right: 1rem; color: #999; text-align: right; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;}
.typePos .typePos-wrapper-main .typePos-main-body > li button .typePos-body {flex: 1; overflow: hidden; position: relative; width: 100%;}
.typePos .typePos-wrapper-main .typePos-main-body > li button .typePos-body .typePos-content,
.typePos .typePos-wrapper-main .typePos-main-body > li button .typePos-body .typePos-price {display: none; position: absolute; left: 15px; width: calc(100% - 30px);}
.typePos .typePos-wrapper-main .typePos-main-body > li button .typePos-body .typePos-content {top: 12px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px; text-align: left;}
.typePos .typePos-wrapper-main .typePos-main-body > li button .typePos-body .typePos-price {bottom: 7px; color: #222; font-size: 18px; font-family: 'NanumSquare-Bold'; line-height: 27px; text-align: right;}
.typePos .typePos-wrapper-main .typePos-main-body > li button .typePos-body .typePos-state {position: absolute; top: 50%; left: 0; width: 100%; margin-top: -10px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px; text-align: center;}
.typePos .typePos-wrapper-main .typePos-main-body > li.typePos-active button .typePos-body .typePos-content,
.typePos .typePos-wrapper-main .typePos-main-body > li.typePos-active button .typePos-body .typePos-price,
.typePos .typePos-wrapper-main .typePos-main-body > li.typePos-active button .typePos-body .typePos-state {color: #fff;}
.typePos .typePos-wrapper-main .typePos-main-body > li.typePos-active button:disabled .typePos-body .typePos-content {color: #666;}
.typePos .typePos-wrapper-main .typePos-main-body > li.typePos-active button:disabled .typePos-body .typePos-price {color: #222;}
.typePos .typePos-wrapper-main .typePos-main-body > li.typePos-active button:disabled .typePos-body .typePos-state {color: #666;}
.typePos .typePos-wrapper-main .typePos-main-body > li.typePos-chit button .typePos-body .typePos-content,
.typePos .typePos-wrapper-main .typePos-main-body > li.typePos-chit button .typePos-body .typePos-price {display: block;}
.typePos .typePos-wrapper-main .typePos-main-body > li.typePos-chit button .typePos-body .typePos-state {display: none;}
.typePos .typePos-wrapper-main .typePos-main-body > li button.typePos-unused .typePos-header {display: none;}
.typePos .typePos-wrapper-main .typePos-main-body > li button.typePos-unused .typePos-body {height: 100%; background-color: #f9f9f9;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
.typePos .typePos-wrapper-footer .typePos-button > li.typePos-move .ui-button .i-item::before {background-position-x: calc(-20px * 3);}
.typePos .typePos-wrapper-footer .typePos-button > li.typePos-publish .ui-button .i-item::before {background-position-x: calc(-20px * 4);}
.typePos .typePos-wrapper-footer .typePos-button > li.typePos-delete .ui-button .i-item::before {background-position-x: calc(-20px * 5);}

.draggable {cursor: move !important;}
.table {display: flex; flex-direction: column; box-sizing: border-box; box-shadow: none; height: 153px; padding: 0px; margin: 0; border: 1px solid #e0e0e0; border-radius: 4px; background-color: #fff;}
.table.fixed {position: fixed;left: 0;top: 0;box-sizing: border-box;background: #ffffff;opacity: 0.5;cursor: move; z-index: 9999;}
</style>

<script>
import { numberWithCommas } from "@/utils/number";
import moment from "moment";
import {
  commonCodesGetCommonCodeAttrbByCodeAndIdx,
  commonCodesGetComName
} from "@/utils/commonCodes";
import { mapActions, mapGetters, mapMutations } from "vuex";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ChangeTablePopup from "@/views/store-management/sales-registration/store-pos-renew/popups/ChangeTablePopup";
import PrintBillPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/PrintBillPopup";
import PaymentListPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/PaymentListPopup";
import ConfirmDeleteSlipPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/ConfirmDeleteSlipPopup";
import SettleAmountPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/SettleAmountPopup";
import GolfERPService from "@/service/GolfERPService";
import { DATE_FORMAT_YYYY_MM_DD } from "@/utils/date";
import {
  orderBy as _orderBy,
  flatten as _flatten,
  maxBy as _maxBy,
} from "lodash";
import SearchVisitorsPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/SearchVisitorsPopup";
import { MODES } from "@/store/modules/pos";
import {
  getDeleteButtonShortcut,
  SHORTCUT_KEYS,
  toShortcutString,
} from "@/utils/KeyboardUtil";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import MemoViewPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/MemoViewPopup";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "TableStatusPopup",
  components: {
    SearchVisitorsPopup,
    SettleAmountPopup,
    ConfirmDeleteSlipPopup,
    PaymentListPopup,
    PrintBillPopup,
    ChangeTablePopup,
    MemoViewPopup,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  filters: {
    numberWithCommas,
  },
  watch: {
    activeExplodedStoreTable(activeExplodedStoreTable) {
      if (activeExplodedStoreTable) {
        this.$nextTick(() => {
          const targetIndex = this.explodedStoreTablesBySlips.findIndex(
            ({ _rid }) => _rid === activeExplodedStoreTable._rid
          );
          if (!(targetIndex < 0)) {
            const target = this.$refs["exploded-store-tables-by-slips"][
              targetIndex
            ];
            target.focus();
            target.scrollIntoView({ block: "nearest" });
          }
        });
      }
    },
  },
  data() {
    return {
      storeTables: [],
      activeExplodedStoreTable: null,

      visibleMemoViewPopup:false,
      visibleChangeTablePopup: false,
      visiblePrintBillPopup: false,
      visiblePaymentListPopup: false,
      visibleConfirmDeleteSlipPopup: false,
      visibleSettleAmountPopup: false,
      visibleSearchVisitorsPopup: false,

      isFullscreen: true,

      tableId: {
        start: null,
        end: null
      },
      pos: {
        x: 0,
        y: 0
      }
    };
  },
  computed: {
    ...mapGetters(["username"]),
    ...mapGetters("pos", [
      "salesDate",
      "store",
      "slip",
      "storeSalesAmount",
      "mode",
      "visibleConfirmDialog",
    ]),
    storeCodeIdx3(){
      return commonCodesGetCommonCodeAttrbByCodeAndIdx('STORE_CODE', this.store.code, 3);
    },
    isPopupOpened() {
      return (
        this.visibleChangeTablePopup ||
        this.visiblePrintBillPopup ||
        this.visiblePaymentListPopup ||
        this.visibleConfirmDeleteSlipPopup ||
        this.visibleSettleAmountPopup ||
        this.visibleConfirmDialog ||
        this.visibleSearchVisitorsPopup ||
        this.visibleMemoViewPopup
      );
    },
    deleteShortcutButtonShortcutString() {
      return toShortcutString(getDeleteButtonShortcut());
    },
    refreshShortcutButtonProps() {
      return {
        shortcutKey: "TableStatusPopup.shortcuts.refresh",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F2,
        },
        visibleShortcutString: false,
      };
    },
    postSettleAmountShortcutButtonProps() {
      return {
        shortcutKey: "TableStatusPopup.shortcuts.postSettleAmount",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F8,
        },
        visibleShortcutString: false,
      };
    },
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        width: this.isFullscreen ? 'calc(100% - 20px)' : 1200,
        height: this.isFullscreen ? '100%' : 760,
        allowDragging: true,
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
    bsnDateLocaleString() {
      const bsnDateMoment = moment(this.salesDate);
      return bsnDateMoment.format(
        `YYYY/MM/DD (${this.toLocaleStringDayOfWeek(
          bsnDateMoment.isoWeekday()
        )})`
      );
    },
    tableCount() {
      return this.storeTables.filter(({ isUse }) => !!isUse).length;
    },
    usingTableCount() {
      return this.storeTables.filter(
        ({ isUse, slips = [] }) => !!isUse && 0 < slips.length
      ).length;
    },
    screenSizeLabel() {
      return this.isFullscreen
        ? "최소화"
        : "최대화";
    },
    storeName() {
      return commonCodesGetComName("STORE_CODE", this.store.code);
    },
    maxRowIndexTable() {
      return _maxBy(this.storeTables, "rowIndex")?.rowIndex || 1;
    },
    maxColIndexTable() {
      return _maxBy(this.storeTables, "columnIndex")?.columnIndex || 1;
    },
    explodedStoreTablesBySlips() {
      const storeTables = this.storeTables;

      const slips = _flatten(storeTables.map(({ slips = [] }) => slips));
      const noSlipsStoreTables = storeTables.filter(
        ({ slips }) => !slips || slips.length === 0
      );

      const storeTablesWithoutSlips = JSON.parse(JSON.stringify(storeTables));
      storeTablesWithoutSlips.forEach((storeTables) => {
        delete storeTables["slips"];
      });

      const tables = [];
      tables.push(...noSlipsStoreTables);
      tables.push(
        ...slips.map((slip) => {
          const tableOfSlip = storeTablesWithoutSlips.find(
            ({ id }) => id === slip.storeTableId
          );

          return {
            ...tableOfSlip,
            slip,
          };
        })
      );

      return _orderBy(tables, "order").map((table, index) => ({
        ...table,
        _rid: index + 1,
      }));
    },
    activeExplodedStoreTable_rid() {
      return this.activeExplodedStoreTable?._rid;
    },
    activeExplodedStoreTableSlip() {
      return this.activeExplodedStoreTable?.slip;
    },
  },
  methods: {
    ...mapActions('memoView', ['setResveId','setCurrentView','clearMemo']),
    ...mapActions("pos", [
      "changeSlipStoreTable",
      "getStoreSalesAmount"
    ]),
    ...mapMutations("pos", ["enterSlip"]),
    async show() {
      await this.fetchStoreTables();
      this.$refs["dialog"].show();
      await this.getStoreSalesAmount();

      this.$nextTick(() => {
        const activeExplodedStoreTable = this.explodedStoreTablesBySlips.filter(
          ({ isUse = true }) => !!isUse
        )[0];
        if (this.slip) {
          this.activeExplodedStoreTable =
            this.explodedStoreTablesBySlips.find(
              ({ slip }) => slip && slip.id === this.slip.id
            ) || activeExplodedStoreTable;
        } else {
          this.activeExplodedStoreTable = activeExplodedStoreTable;
        }
      });
    },
    memoCheck(data) {
      if(data.slip) {
        const { slip:{ visitor:{ team: {reservationId ,  memoConfirmCount , recptnMemoCount , memoCount  }  } } } = data;
        if(!reservationId){
          return null;
        } else if(memoCount > 0){

          if(recptnMemoCount > memoConfirmCount){

            return "new-memo";
          }
          return "normal-memo";
        }
        return "not-memo";
      }
      return null;
    },
    async openMemoPopup(data) {
      const {slip:{ visitor:{ team: {reservationId}  } }} = data;
      this.clearMemo();

      if(reservationId){
        this.setCurrentView(this.storeCodeIdx3);
        this.setResveId(reservationId);
        this.visibleMemoViewPopup = true;
      }
    },
    onMemoViewPopupClose() {
      this.visibleMemoViewPopup = false;
    },
    async showAndActiveTable({ activeTableId }) {
      await this.fetchStoreTables();
      this.$refs["dialog"].show();

      this.$nextTick(() => {
        const activeExplodedStoreTable = this.explodedStoreTablesBySlips.filter(
          ({ isUse = true }) => !!isUse
        )[0];
        if (activeTableId) {
          this.activeExplodedStoreTable =
            this.explodedStoreTablesBySlips.find(
              ({ id }) => id === activeTableId
            ) || activeExplodedStoreTable;
        } else {
          this.activeExplodedStoreTable = activeExplodedStoreTable;
        }
      });
    },
    hide() {
      this.$refs["dialog"].hide();
    },

    toLocaleStringDayOfWeek(dayOfWeek) {
      switch (dayOfWeek) {
        case 1:
          return "월";
        case 2:
          return "화";
        case 3:
          return "수";
        case 4:
          return "목";
        case 5:
          return "금";
        case 6:
          return "토";
        case 7:
          return "일";
      }

      return "";
    },
    toShortcutString,
    getCaddieName(slip) {
      return slip?.visitor?.team?.teamCaddies?.map(caddie => `${caddie.caddie.name} ${caddie.cartNumber}`)?.join(', ');
    },
    getStartCourseAndStartTime(slip) {
      const startCourse = this.getStartCourse(slip);
      const startTime = this.getStartTime(slip);

      if (startCourse && startTime) {
        return `${startCourse} / ${startTime}`;
      } else if (startCourse) {
        return `${startCourse} / ?`;
      } else if (startTime) {
        return `? / ${startTime}`;
      } else {
        return "";
      }
    },
    getStartCourse(slip) {
      if (slip?.visitor?.team?.course) {
        return commonCodesGetComName("COURSE_CODE", slip.visitor.team.course);
      } else {
        return "";
      }
    },
    getStartTime(slip) {
      return slip?.visitor?.team?.time || "";
    },
    getVisitorName(slip) {
      return slip?.visitor?.name || "일반고객";
    },
    getLockerNumber(slip) {
      return slip?.visitor?.lockerNumber || "000";
    },
    getSalesSummary(slip) {
      const sales = slip?.sales || [];
      if (sales.length === 0) {
        return "";
      } else if (sales.length === 1) {
        return sales[0].productName;
      } else {
        return `${sales[0].productName} 외 ${sales.length - 1}건`;
      }
    },
    getTotalSalesAmount(slip) {
      return (
        slip?.sales?.reduce(
          (prev, { totalAmount = 0 }) => prev + totalAmount,
          0
        ) || 0
      );
    },
    async processPostPayment() {
      const targetSlip = this.activeExplodedStoreTableSlip;
      if (this.mode === MODES.MODIFY && this.slip.id === targetSlip.id) {
        this.$emit("postPayment");
        return;
      }

      const salesAmount = targetSlip.sales.reduce(
        (prev, curr) => prev + (curr.totalAmount || 0),
        0
      );

      const task = async (visitor = targetSlip?.visitor, slip = targetSlip) => {
        const confirmMessage = `총금액: ${numberWithCommas(
          salesAmount
        )}원\n전표번호: ${targetSlip.number}\n${
          visitor?.lockerNumber ? `락카키: ${visitor.lockerNumber}` : ""
        }\n\n내장객 ${visitor?.name}님 에게\n후불정산 처리하시겠습니까?`;

        this.$EventBus.$emit("pos-confirm", {
          message: confirmMessage,
          callback: async (confirmed) => {
            if (confirmed) {
              try {
                const bsnDate = moment(slip.date || this.salesDate).format(
                  DATE_FORMAT_YYYY_MM_DD
                );
                await GolfERPService.storeCheckout(
                  this.store.code,
                  bsnDate,
                  visitor?.id,
                  targetSlip.id
                );

                this.infoToast("처리되었습니다");
              } catch (e) {
                console.error(e);
                this.errorToast("Error");
              } finally {
                this.activeExplodedStoreTable = null;
                await this.fetchStoreTables();
              }
            }
          },
        });
      };

      if (!targetSlip.visitorId) {
        this.visibleSearchVisitorsPopup = true;
        this.$nextTick(() => {
          this.$refs["search-visitors-popup"].show({
            callback: async ({ visitor }) => {
              this.$refs["search-visitors-popup"].hide();
              await task(visitor);
            },
          });
        });
      } else {
        await task();
      }
    },
    onDialogClose() {
      this.$emit("close");
    },
    onExplodedStoreTableKeydown(event, { storeTable }) {
      if (
        ["ArrowDown", "ArrowUp", "ArrowLeft", "ArrowRight", "Tab"].includes(
          event.key
        )
      ) {
        event.preventDefault();

        let step = 0;
        switch (event.key) {
          case "ArrowDown":
            step = this.maxColIndexTable;
            break;
          case "ArrowUp":
            step = -this.maxColIndexTable;
            break;
          case "ArrowLeft":
            step = -1;
            break;
          case "ArrowRight":
          case "Tab":
            step = 1;
            break;
        }

        const fetchNextIndex = (explodedStoreTable) => {
          let nextIndex = this.explodedStoreTablesBySlips.findIndex(
            (explodedStoreTablesBySlip) =>
              explodedStoreTablesBySlip === explodedStoreTable
          );
          if (nextIndex < 0) {
            return -1;
          }
          nextIndex += step;
          nextIndex =
            (nextIndex + this.explodedStoreTablesBySlips.length) %
            this.explodedStoreTablesBySlips.length;

          if (!this.explodedStoreTablesBySlips[nextIndex].isUse) {
            const _nextIndex =
              (nextIndex + step + this.explodedStoreTablesBySlips.length) %
              this.explodedStoreTablesBySlips.length;
            if (
              this.explodedStoreTablesBySlips[_nextIndex] ===
              this.activeExplodedStoreTable
            ) {
              return -1;
            }

            return fetchNextIndex(this.explodedStoreTablesBySlips[nextIndex]);
          } else {
            return nextIndex;
          }
        };

        const nextIndex = fetchNextIndex(this.activeExplodedStoreTable);
        if (!(nextIndex < 0)) {
          this.activeExplodedStoreTable = this.explodedStoreTablesBySlips[
            nextIndex
          ];
        }
      } else if (event.key === "Enter") {
        event.preventDefault();

        this.$emit("selectStoreTable", { storeTable });
        this.hide();
      }
    },
    onStoreTableClick(event, { storeTable }) {
      if (this.activeExplodedStoreTable) {
        if (this.activeExplodedStoreTable._rid === storeTable._rid) {
          this.activeExplodedStoreTable = null;
        } else {
          this.activeExplodedStoreTable = storeTable;
        }
      } else {
        this.activeExplodedStoreTable = storeTable;
      }
    },
    onStoreTableDoubleClick(event, { storeTable }) {
      this.$emit("selectStoreTable", { storeTable });
      this.hide();
    },
    onRefreshClick() {
      this.fetchStoreTables();
    },
    onToggleScreenSizeClick() {
      this.isFullscreen = !this.isFullscreen;
    },
    onChangeTableClick() {
      this.visibleChangeTablePopup = true;
      this.$nextTick(() => {
        this.$refs["change-table-popup"].show({
          currentSlip: this.activeExplodedStoreTableSlip,
          callback: async ({ storeTable }) => {
            try {
              if (this.activeExplodedStoreTableSlip.id === this.slip?.id) {
                await this.changeSlipStoreTable({ storeTable });
              } else {
                await this.updateSalesSlipStoreTable({
                  slip: this.activeExplodedStoreTableSlip,
                  storeTable,
                });
              }

              this.infoToast("변경되었습니다.");
            } catch (e) {
              this.errorToast("Error");
            } finally {
              await this.fetchStoreTables();
              this.activeExplodedStoreTable = this.explodedStoreTablesBySlips.find(
                ({ id }) => id === storeTable.id
              );
            }
          },
        });
      });
    },
    async onPrintBillPopupClick() {
      const targetSlip = this.activeExplodedStoreTableSlip;

      // if (this.mode === MODES.MODIFY && this.slip?.id === targetSlip?.id) {
      //   this.$emit("printBill");
      //   return;
      // }

      this.visiblePrintBillPopup = true;
      this.$nextTick(() => {
        this.$refs["print-bill-popup"].show({
          slip: targetSlip
        });
      });
    },
    onPaymentListPopupClick() {
      this.visiblePaymentListPopup = true;
      const tables = this.explodedStoreTablesBySlips.filter(table => table._rid === this.activeExplodedStoreTable_rid);
      this.$nextTick(() => {
        this.$refs["payment-list-popup"].show({
          tableName: tables.length > 0 ? tables[0].name : null
        });
      });
    },
    onConfirmDeleteSlipPopupClick() {
      this.visibleConfirmDeleteSlipPopup = true;
      this.$nextTick(() => {
        this.$refs["confirm-delete-slip-popup"].show({
          slip: this.activeExplodedStoreTableSlip,
          callback: async (payload) => {
            try {
              await this.deleteSlip(payload);
              this.infoToast(this.$t("main.popupMessage.deleted"));
            } catch (e) {
              // this.errorToast("Error");
              console.error(e);
            } finally {
              this.activeExplodedStoreTable = null;
              await this.fetchStoreTables();
            }
          },
        });
      });
    },
    onSettleAmountClick($event, { method }) {
      switch (method) {
        case "POST":
          this.processPostPayment();
          break;
        case "CARD":
        case "CASH":
        case "ETC":
          this.visibleSettleAmountPopup = true;
          this.$nextTick(() => {
            this.$refs["settle-amount-popup"].show({
              salesDate: this.salesDate,
              type: "SLIP",
              data: this.activeExplodedStoreTableSlip,
              salesDivision: "SALES",
              payPreOrFutureDivision: "0",
              paymentMethod: method,
            });
          });
          break;
      }
    },

    onSubmitClick() {
      if (!this.activeExplodedStoreTable) {
        return;
      }

      this.$emit("selectStoreTable", {
        storeTable: this.activeExplodedStoreTable,
      });
      this.hide();
    },
    onCloseClick() {
      this.hide();
    },
    onChangeTablePopupClose() {
      this.visibleChangeTablePopup = false;
    },
    onPrintBillPopupClose() {
      this.visiblePrintBillPopup = false;
    },
    onPaymentListPopupSubmit({ slip }) {
      this.fetchSlip(slip);
      this.hide();
    },
    onPaymentListPopupClose() {
      this.visiblePaymentListPopup = false;
    },
    onConfirmDeleteSlipPopupClose() {
      this.visibleConfirmDeleteSlipPopup = false;
    },
    onSettleAmountPopupClose(args) {
      this.visibleSettleAmountPopup = false;
      if (args.isPaid) {
        this.onRefreshClick();
      }
    },
    onSearchVisitorsPopupClose() {
      this.visibleSearchVisitorsPopup = false;
    },

    async fetchSlip({ id }) {
      const slip = await GolfERPService.fetchSalesSlip(id);

      await this.enterSlip({ slip });
    },
    async fetchStoreTables() {
      this.storeTables =
        (await GolfERPService.fetchPosTables(
          this.store.code,
          moment(this.salesDate).format(DATE_FORMAT_YYYY_MM_DD)
        )) || [];
    },
    async updateSalesSlipStoreTable({ slip, storeTable }) {
      await GolfERPService.updateSalesSlipStoreTable(
        slip.id,
        this.store.code,
        moment(this.salesDate).format(DATE_FORMAT_YYYY_MM_DD),
        storeTable.id
      );
    },
    async deleteSlip({ slip, reason }) {
      await GolfERPService.deleteSalesSlip(
        slip.id,
        reason,
        slip.date,
        slip.storeCode
      );
    },
    getTableInfo() {
      return this.explodedStoreTablesBySlips.find((item) => item.id === this.tableId.start);
    },
    onPosTableDivMouseDown(event) {
      event.preventDefault();
      document.onmousemove = this.onPosTableDivMouseMove;
      document.onmouseup = this.onPosTableDivMouseUp;
    },
    scrollToTop(el, distance) {
      el.scrollTop += distance;
    },
    onPosTableDivMouseMove(event) {
      event.preventDefault();
      const $el = document.getElementById("table-status-popup-typePos-body");
      const rect = $el.getBoundingClientRect();
      if (event.clientY < rect.top + 100) {
        this.scrollToTop($el, -20);
      } else if (event.clientY > rect.bottom - 100) {
        this.scrollToTop($el, 20);
      }

      const id = event.target.getAttribute("table-data-id");
      const isUse = this.explodedStoreTablesBySlips.find((item) => item.id === Number(id))?.isUse;

      if (this.tableId.start === null && isUse !== undefined && !isUse) {
        document.onmouseup = null;
        document.onmousemove = null;
        return;
      }

      if (this.tableId.start === null) {
        this.tableId.start = Number(id);
      }
      this.tableId.end = Number(id);
      this.pos = {
        x: event.clientX,
        y: event.clientY,
      };
    },
    async onPosTableDivMouseUp() {
      if (
        this.tableId.start &&
        this.tableId.end &&
        this.tableId.start !== this.tableId.end
      ) {
        const startTableSlip = this.explodedStoreTablesBySlips.find((item) => item.id === this.tableId.start)?.slip;
        const endTableSlip = this.explodedStoreTablesBySlips.find((item) => item.id === this.tableId.end)?.slip;

        const tableIdInfo = this.tableId;

        this.$EventBus.$emit("pos-confirm", {
          message: "합산 또는 이동 작업을 선택하여 주십시오.",
          confirmBtnLabel: "테이블 합산",
          cancelBtnLabel: "테이블 이동",
          callback: async (confirm) => {
            const dialogResult = await confirm;
            if (dialogResult) {
              if (startTableSlip?.id && endTableSlip?.id) {
                this.$EventBus.$emit("pos-confirm", {
                  message: startTableSlip?.storeTableName + "번 -> " + endTableSlip?.storeTableName + "번 테이블 합산 하시겠습니까?",
                  callback: async (confirm) => {
                    if (confirm) {
                      try {
                        await GolfErpAPI.putSalesSlipByTableAddUp({
                          existSalesId: endTableSlip?.id,
                          salesId: startTableSlip?.id,
                        });
                      } finally {
                        this.tableId = {
                          start: null,
                          end: null
                        };

                        document.onmouseup = null;
                        document.onmousemove = null;
                      }

                      this.$emit("posViewReset");
                      this.onRefreshClick();
                      this.infoToast("전표 합산 완료하였습니다.");
                    }
                  }
                });
              } else {
                this.errorToast("빈 테이블은 전표 합산을 하실 수 없습니다");
              }
            } else {
              const storeTable = this.storeTables.find(item => item.id === tableIdInfo?.end);
              try {
                if (this.activeExplodedStoreTableSlip.id === this.slip?.id) {
                  await this.changeSlipStoreTable({storeTable});
                } else {
                  await this.updateSalesSlipStoreTable({
                    slip: this.activeExplodedStoreTableSlip,
                    storeTable,
                  });
                }
                this.infoToast("변경되었습니다.");
              } catch (e) {
                this.errorToast("Error");
              } finally {
                await this.fetchStoreTables();
                this.activeExplodedStoreTable = this.explodedStoreTablesBySlips.find(
                    ({ id }) => id === storeTable.id
                );
              }
            }
          }
        });
      }

      this.tableId = {
        start: null,
        end: null
      };

      document.onmouseup = null;
      document.onmousemove = null;
    }
  },
};
</script>

<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">추첨일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                  v-model="searchDateInputDateRange"
                  type="lookupDetail-condition"
                />
              </li>
            </ul>
          </li>
          <li class="field memberNameNumber">
            <div class="title">회원명/번호</div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="searchOptions.memberNameNumber"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              buttonDiv="GET"
              :isShortcutButton="true"
              :ignore="isPopupOpened"
              @click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              buttonDiv="GET"
              :is-icon-custom="true"
              @click.native="searchDetailPopupOpen"
          >
            상세검색
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article
        class="body-article"
        :class="
          isTimeListViewOpened
            ? $t('className.reservation.reservationDetailViewOpened')
            : ''
        "
      >
        <div class="article-left">
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section class="article-section section-01">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">추첨예약 목록</div>
                <div class="header-caption">[{{ lotteryReservationsTotalCount | numberWithCommas }}건]</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li>
                    <erp-button
                        buttonDiv="GET"
                        :ignore="isPopupOpened"
                        :isIconCustom="true"
                        @click.native="fetchAggregationButtonClicked"
                    >
                      일자별 통계
                    </erp-button>
                  </li>
                  <li class="add">
                    <erp-button
                        buttonDiv="SAVE"
                        :ignore="isPopupOpened"
                        :is-custom-shortcut-button="true"
                        :shortcut-key="'LotteryReservationRegistration.shortcuts.save'"
                        :shortcut="{key:'F3'}"
                        :isIconCustom="true"
                        @click.native="addLotteryReservationButtonClick"
                    >
                      추가
                    </erp-button>
                  </li>
                  <li>
                    <erp-button
                        buttonDiv="SAVE"
                        :is-icon-custom="true"
                        @click.native="scoreAggregationButtonClicked"
                    >
                      점수집계
                    </erp-button>
                  </li>
                  <li v-if="false">
                    <erp-button
                        buttonDiv="SAVE"
                        :is-icon-custom="true"
                        @click.native="provisionalAssignmentButtonClicked"
                    >
                      배정
                    </erp-button>
                  </li>
                  <li>
                    <erp-button
                        buttonDiv="DELETE"
                        :is-icon-custom="true"
                        @click.native="provisionalAssignmentCancelButtonClicked"
                    >
                      취소
                    </erp-button>
                  </li>
                  <li>
                    <erp-button
                        buttonDiv="SAVE"
                        :is-icon-custom="true"
                        @click.native="provisionalAssignmentReservationConfirmButtonClicked"
                    >
                      예약확정
                    </erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <grid
                ref="lotteryReservationGrid"
                id="lotteryReservationGrid"
                v-bind="lotteryReservationGridProps"
                @cellClick="onLotteryReservationGridCellClick"
                @filtered="onLotteryReservationGridFiltered"
                @sorted="onLotteryReservationGridFiltered"
                @paged="onLotteryReservationGridFiltered"
              />
            </div>
          </section>
        </div>
        <div class="article-right">
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section class="article-section section-02">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">타임 목록</div>
                <div class="header-caption">
                  <!-- 토요일 : saturday / 일요일 : sunday -->
                  [{{ timeCount.resveDate }}
                  <span class="saturday">{{ timeCount.dwName }}</span> 잔여
                  <strong>{{ timeCount.resvePossibleTime }}</strong
                  >팀]
                </div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="close">
                    <erp-button
                        buttonDiv="CLOSE"
                        @click.native="onTimeListViewClosed"
                        :isIconCustom="true"
                    >
                      닫기
                    </erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body" v-show="isTimeListViewOpened">
              <ejs-grid-wrapper
                ref="timeListGrid"
                v-bind="timeListGridOptions"
                :dataSource="timeList"
                @headerCellInfo="onTimeListGridHeaderCellInfo"
                @queryCellInfo="onTimeListGridQueryCellInfo"
                @actionComplete="timeListGridActionComplete"
                @recordClick="timeListGridRecordClick"
              />
            </div>
          </section>
        </div>
      </article>
    </div>
    <ejs-dialog
      ref="searchDetailPopup"
      :header="`상세검색`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="387"
      :isModal="false"
      :visible="false"
      enableResize="true"
      v-show="isSearchDetailPopupOpen"
    >
      <div class="window lookupDetail-waitReservationRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">영업 구분</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        ref="bsnCodeDropdown"
                        v-model="searchOptions.bsnCode"
                        :dataSource="
                          searchOptions.bsnCodeOptions
                        "
                        :allowFiltering="false"
                        :fields="commonCodeFields"
                        cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">접수 상태</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-multiselect
                        ref="rceptStatusDropdown"
                        cssClass="lookupDetail-condition-multiselect"
                        placeHolder="전체"
                        v-model="searchOptions.lotteryRceptStatus"
                        :dataSource="
                          searchOptions.lotteryRceptStatusOptions
                        "
                        :fields="commonCodeFields"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  buttonDiv="GET"
                  :isShortcutButton="true"
                  :ignore="isPopupOpened"
                  @click.native="onSearchDetailViewButtonClick"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  buttonDiv="GET"
                  @click.native="onSearchDetailPopupInitClicked"
                  :isIconCustom="true"
              >
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="searchDetailPopupClose"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <lottery-reservation-popup
      v-if="isLotteryReservationPopupOpen"
      ref="lotteryReservationPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClosed="onLotteryReservationPopupClosed"
    />
    <lottery-aggregates-popup
      v-if="isLotteryAggregatesPopupOpen"
      ref="lotteryAggregatesPopup"
      @popupClosed="onLotteryAggregatesPopupClosed"
    />
    <lottery-reservation-confirm-popup
      v-if="isLotteryReservationConfirmPopup"
      ref="lotteryReservationConfirmPopup"
      @popupClosed="onLotteryReservationConfirmPopupClosed"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.memberNameNumber .content .item{width:120px}
body .appContent .body-article .article-left,body .appContent .body-article .article-right{transition:all .3s ease-out}
body .appContent .body-article .article-left{width:100%;}
body .appContent .body-article .article-right{width:347px;margin: 0 -347px 0 0;}
body .appContent .body-article.dev-reservation-detail-view-opened .article-left{width:calc(100% - 347px)}
body .appContent .body-article.dev-reservation-detail-view-opened .article-right{margin:0}
body .appContent .body-article .section-body{overflow:hidden;border:none}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import {mapActions, mapGetters} from 'vuex';
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import Grid from "@/components/grid/Grid";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputText from "@/components/common/text/InputText";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import LotteryReservationPopup from "@/views/golf-reservation/popup/LotteryReservationPopup";
import LotteryAggregatesPopup from '@/views/golf-reservation/popup/LotteryAggregatesPopup';
import LotteryReservationConfirmPopup from "@/views/golf-reservation/popup/LotteryReservationConfirmPopup";

import {
  Aggregate,
  Resize,
  ForeignKey,
  ExcelExport,
  Page,
} from "@syncfusion/ej2-vue-grids";
import {
  commonCodesGetColorValue,
  commonCodesGetCommonCode,
  commonCodesGetCommonCodeAttrbByCodeAndIdx,
  commonCodesGetComName,
} from '@/utils/commonCodes';
import {getFormattedDate} from "@/utils/date";
import moment from "moment";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {getReservationWaitTimeList} from "@/api/waitingReservation";
import { numberWithCommas } from "@/utils/number";
import {FORMAT_MEMBER_NUMBER, FORMAT_TEL_CELLPHONE_NUMBER} from "@/components/grid/GridCellDataFormatUtil";

export default {
  name: "LotteryReservationRegistration",
  components: {
    Grid,
    EjsGridWrapper,
    InputText,
    InputDateRange,
    LotteryReservationPopup,
    LotteryAggregatesPopup,
    LotteryReservationConfirmPopup,
    ErpButton
  },
  mixins: [commonMixin, confirmDialogMixin],
  filters: {
    numberWithCommas
  },
  data() {
    return {
      isLoading: false,
      isSearched: false,
      isSearchDetailPopupOpen: false,
      isTimeListViewOpened: false,
      isLotteryReservationPopupOpen: false,
      isLotteryAggregatesPopupOpen: false,
      isLotteryReservationConfirmPopup: false,
      commonCodeFields: { text: "comName", value: "comCode" },
      timeCount: {
        resveDate: null,
        dwName: null,
        resvePossibleTime: 0
      },
      timeList: [],
      searchOptions: {
        fromDate: null,
        toDate: null,
        memberNameNumber: null,
        bsnCode: null,
        lotteryRceptStatus: null,
        bsnCodeOptions: [],
        lotteryRceptStatusOptions: []
      },
      timeListGridOptions: {
        provides: [Aggregate, Resize, ForeignKey, ExcelExport, Page],
        columns: [
          {
            field: "resveTime",
            headerText: "시간",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
          },
          {
            field: "resveCourse",
            headerText: "코스",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "COURSE_CODE",
          },
          {
            field: "resveStatus",
            headerText: "예약자명",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            textAlign: "left",
          },
        ],
      },
      lotteryReservations: [],
      lotteryReservationsTotalCount: 0,
    };
  },
  created() {
    this.searchOptions.bsnCodeOptions = commonCodesGetCommonCode(
      "BSN_CODE",
      true
    ).filter(data => data.comCode !== "CLOSE");

    if (
      this.searchOptions.bsnCodeOptions.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchOptions.bsnCodeOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.searchOptions.bsnCode = "ALL";

    this.searchOptions.lotteryRceptStatusOptions = commonCodesGetCommonCode(
      "LOTTERY_RCEPT_STATUS",
      true
    );

    this.searchOptions.lotteryRceptStatus = ["RCEPT", "RCEPT_TEMP", "RESVE", "RESVE_OUT"];

    this.searchOptions.fromDate = getFormattedDate(moment().format());
    this.searchOptions.toDate = getFormattedDate(moment().format());
  },
  destroyed() {},
  computed: {
    ...mapGetters(["isStopProgress"]),
    isPopupOpened() {
      return (
        this.isLotteryReservationPopupOpen ||
        this.isLotteryAggregatesPopupOpen ||
        this.isLotteryReservationConfirmPopup
      );
    },
    searchDateInputDateRange: {
      get: function () {
        return {
          from: this.searchOptions.fromDate,
          to: this.searchOptions.toDate
        };
      },
      set: function (dateRange) {
        this.searchOptions.fromDate = dateRange.from;
        this.searchOptions.toDate = dateRange.to;
      }
    },
    lotteryReservationGridProps() {
      return {
        columns: [
          {
            field: "lotteryDate",
            name: "추첨일자",
            minWidth: 16,
            type: Date,
            width: 90,
            cellStyle: ({ lotteryDateLineFlag }) => ({
              borderTop: lotteryDateLineFlag
                  ? '1px solid #000'
                  : null,
              textAlign: "center",
            }),
          },
          {
            field: "dwName",
            name: "요일",
            minWidth: 16,
            type: String,
            width: 60,
            cellStyle: ({ lotteryDateLineFlag }) => ({
              borderTop: lotteryDateLineFlag
                ? '1px solid #000'
                : null,
              textAlign: "center",
            }),
          },
          {
            field: "memberName",
            name: "회원명",
            minWidth: 16,
            type: String,
            width: 80,
            class: () => [this.$t("className.grid.clickArea")],
            cellClass: () => [this.$t("className.grid.clickArea")],
            cellStyle: ({ lotteryDateLineFlag }) => ({
              borderTop: lotteryDateLineFlag
                ? '1px solid #000'
                : null,
            }),
          },
          {
            field: "memberNo",
            name: "회원번호",
            minWidth: 16,
            type: String,
            width: 80,
            format: FORMAT_MEMBER_NUMBER,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            field: "memberGradeComName",
            name: "회원등급",
            minWidth: 16,
            type: String,
            width: 80,
            cellStyle: ({ lotteryDateLineFlag }) => ({
              borderTop: lotteryDateLineFlag
                ? '1px solid #000'
                : null,
            }),
          },
          {
            field: "contactTel",
            name: "연락처",
            minWidth: 16,
            type: String,
            width: 100,
            format: FORMAT_TEL_CELLPHONE_NUMBER,
            cellStyle: ({ lotteryDateLineFlag }) => ({
              borderTop: lotteryDateLineFlag
                ? '1px solid #000'
                : null,
              textAlign: "center",
            }),
          },
          {
            name: "예약횟수",
            columns: [
              {
                field: "weekdayCount",
                name: "주중",
                minWidth: 16,
                type: Number,
                width: 60,
                format: (data) => {
                  return data === 0 ? "-" : numberWithCommas(data);
                },
                cellStyle: ({ lotteryDateLineFlag }) => ({
                  borderTop: lotteryDateLineFlag
                    ? '1px solid #000'
                    : null,
                  textAlign: "center",
                }),
              },
              {
                field: "weekendCount",
                name: "주말",
                minWidth: 16,
                type: Number,
                format: (data) => {
                  return data === 0 ? "-" : numberWithCommas(data);
                },
                width: 60,
                cellStyle: ({ lotteryDateLineFlag }) => ({
                  borderTop: lotteryDateLineFlag
                    ? '1px solid #000'
                    : null,
                  textAlign: "center",
                }),
              },
            ]
          },
          {
            field: "resveInfo",
            name: "예약상세",
            minWidth: 16,
            type: String,
            width: 150,
            cellStyle: ({ lotteryDateLineFlag }) => ({
              borderTop: lotteryDateLineFlag
                ? '1px solid #000'
                : null,
            }),
          },
          {
            field: "lotteryRceptStatusComName",
            name: "접수상태",
            minWidth: 16,
            type: String,
            width: 90,
            cellStyle: (data) => {
              return {
                borderTop: data.lotteryDateLineFlag
                  ? '1px solid #000'
                  : null,
                color: commonCodesGetColorValue("LOTTERY_RCEPT_STATUS", data.lotteryRceptStatus),
                textAlign: "center",
              };
            }
          },
          {
            field: "hopeTimeZone1ComName",
            name: "희망시간1",
            minWidth: 16,
            type: String,
            width: 100,
            cellStyle: ({ lotteryDateLineFlag }) => ({
              borderTop: lotteryDateLineFlag
                ? '1px solid #000'
                : null,
            }),
          },
          {
            field: "hopeTimeZone2ComName",
            name: "희망시간2",
            minWidth: 16,
            type: String,
            width: 100,
            cellStyle: ({ lotteryDateLineFlag }) => ({
              borderTop: lotteryDateLineFlag
                ? '1px solid #000'
                : null,
            }),
          },
          {
            field: "requstContents",
            name: "요청내용",
            minWidth: 16,
            type: String,
            width: 120,
            cellStyle: ({ lotteryDateLineFlag }) => ({
              borderTop: lotteryDateLineFlag
                ? '1px solid #000'
                : null,
            }),
          },
          {
            field: "lotteryRank",
            name: "추첨순위",
            minWidth: 16,
            width: 80,
            type: Number,
            format: (data) => {
              return data === 0 ? "-" : numberWithCommas(data);
            },
            cellStyle: ({ lotteryDateLineFlag }) => ({
              borderTop: lotteryDateLineFlag
                ? '1px solid #000'
                : null,
              textAlign: "center",
            }),
          },
          {
            name: "예약확정",
            columns: [
              {
                field: "resveCourseComName",
                name: "코스",
                minWidth: 16,
                type: String,
                width: 70,
                cellStyle: ({ lotteryDateLineFlag }) => ({
                  borderTop: lotteryDateLineFlag
                    ? '1px solid #000'
                    : null,
                  textAlign: "center",
                }),
              },
              {
                field: "resveTime",
                name: "시간",
                minWidth: 16,
                type: String,
                width: 70,
                cellStyle: ({ lotteryDateLineFlag }) => ({
                  borderTop: lotteryDateLineFlag
                    ? '1px solid #000'
                    : null,
                  textAlign: "center",
                }),
              },
            ]
          },
          {
            field: "totalScore",
            name: "총점수",
            minWidth: 16,
            type: Number,
            format: (data) => {
              return data === 0 ? "-" : numberWithCommas(data);
            },
            width: 70,
            cellStyle: ({ lotteryDateLineFlag }) => ({
              borderTop: lotteryDateLineFlag
                ? '1px solid #000'
                : null,
              textAlign: "center",
            }),
          },
          {
            field: "resveScore",
            name: "예약점수",
            minWidth: 16,
            type: Number,
            format: (data) => {
              return data === 0 ? "-" : numberWithCommas(data);
            },
            width: 90,
            cellStyle: ({lotteryDateLineFlag}) => ({
              borderTop: lotteryDateLineFlag
                ? '1px solid #000'
                : null,
              textAlign: "center",
            }),
          },
          {
            field: "resveCancelScore",
            name: "예약취소점수",
            minWidth: 16,
            type: Number,
            format: (data) => {
              return data === 0 ? "-" : numberWithCommas(data);
            },
            width: 100,
            cellStyle: ({lotteryDateLineFlag}) => ({
              borderTop: lotteryDateLineFlag
                ? '1px solid #000'
                : null,
              textAlign: "center",
            }),
          },
          {
            field: "salesAmt",
            name: "매출총액",
            minWidth: 16,
            type: Number,
            format: (data) => {
              return data === 0 ? "-" : numberWithCommas(data);
            },
            width: 100,
            cellStyle: ({lotteryDateLineFlag}) => ({
              borderTop: lotteryDateLineFlag
                ? '1px solid #000'
                : null,
              textAlign: "right",
            }),
          },
          {
            field: "unPrzwinResn",
            name: "미당첨사유",
            minWidth: 16,
            type: String,
            width: 300,
            cellStyle: ({ lotteryDateLineFlag }) => ({
              borderTop: lotteryDateLineFlag
                ? '1px solid #000'
                : null,
            }),
          },
          {
            field: "insertName",
            name: "등록자",
            minWidth: 16,
            type: String,
            width: 90,
            cellStyle: ({ lotteryDateLineFlag }) => ({
              borderTop: lotteryDateLineFlag
                ? '1px solid #000'
                : null,
            }),
          },
          {
            field: "insertDt",
            name: "등록일시",
            minWidth: 16,
            type: String,
            width: 150,
            cellStyle: ({ lotteryDateLineFlag }) => ({
              borderTop: lotteryDateLineFlag
                ? '1px solid #000'
                : null,
              textAlign: "center",
            }),
          },
          {
            field: "lotteryId",
            type: Number,
            visible: false,
          }
        ],
        rowIdentifier: "lotteryId",
        records: this.lotteryReservations,
        visibleRowNumberColumn: true,
        sortable: this.isSearched,
        filterable: this.isSearched,
        sizeOfRecordPerPage: 50,
        countOfAllRecords: this.lotteryReservationsTotalCount,
        rangeOfPages: 10,
      };
    },
  },
  methods: {
    ...mapActions(["setLoaderProgress", "clearLoaderProgress"]),
    async onViewButtonClicked() {
      this.$refs["lotteryReservationGrid"].resetPage();
      await this.searchLotteryReservations();

      // if (this.$refs["lotteryReservationGrid"].getSelectedRowIndexes().length < 1) {
      //   this.$refs["lotteryReservationGrid"].selectRow(0);
      // }
    },
    onLotteryReservationGridFiltered(event, { page, filters, orders }) {
      if (!event) {
        return;
      }

      this.searchLotteryReservations({
        offset: (page - 1) * 50,
        filters,
        orders
      });
    },
    async searchLotteryReservations({ offset, filters, orders } = {}) {
      const {
        orders: gridOrders,
        filters: gridFilters,
        page: gridPage,
      } = this.$refs["lotteryReservationGrid"].getOrdersAndFiltersAndPage();

      const limit = this.lotteryReservationGridProps.sizeOfRecordPerPage;

      if (this.isLoading) {
        this.errorToast("데이터를 불러오는 중입니다.");
        return;
      }

      const _orders = JSON.parse(JSON.stringify(orders || gridOrders || []));
      if (_orders.findIndex(({ field }) => field === "lotteryDate") < 0) {
        _orders.push({
          field: "lotteryDate",
          direction: "asc",
        });
      }

      if (_orders.findIndex(({ field }) => field === "lotteryRank") < 0) {
        _orders.push({
          field: "lotteryRank",
          direction: "asc",
        });
      }

      if (_orders.findIndex(({ field }) => field === "insertSno") < 0) {
        _orders.push({
          field: "hopeTimeZone1",
          direction: "asc",
        });
      }

      if (_orders.findIndex(({ field }) => field === "insertSno") < 0) {
        _orders.push({
          field: "memberName",
          direction: "asc",
        });
      }

      this.isLoading = true;
      try {
        const {
          total: lotteryReservationsTotalCount,
          records: lotteryReservations,
        } = await GolfErpAPI.fetchReservationLottery({
          fromDate: this.searchOptions.fromDate || undefined,
          toDate: this.searchOptions.toDate || undefined,
          memberNameNumber:
            this.searchOptions.memberNameNumber || undefined,
          bsnCode: (this.searchOptions.bsnCode === "ALL" ? null : this.searchOptions.bsnCode) || undefined,
          lotteryRceptStatusList: (this.searchOptions.lotteryRceptStatus.length > 0 ? this.searchOptions.lotteryRceptStatus : null) || undefined,
          limit: limit,
          offset: offset || (gridPage - 1) * limit,
          filters: filters || gridFilters,
          orders: _orders,
        });

        let savedLotteryDate = null;
        this.lotteryReservationsTotalCount = lotteryReservationsTotalCount;
        this.lotteryReservations = lotteryReservations
          ?.map((item, index) => {
            item.lotteryDateLineFlag = false;
            if (index > 0) {
              if (savedLotteryDate !== item.lotteryDate) {
                savedLotteryDate = item.lotteryDate;
                item.lotteryDateLineFlag = true;
              }
            } else {
              savedLotteryDate = item.lotteryDate;
            }
            return item;
          });

        console.log(
            this.lotteryReservations
        );

        this.isSearched = true;
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    onTimeListViewClosed() {
      this.isTimeListViewOpened = false;
    },
    onSearchDetailViewButtonClick() {
      this.onViewButtonClicked();
    },
    onSearchDetailPopupInitClicked() {
      this.searchOptions.fromDate = getFormattedDate(moment().format());
      this.searchOptions.toDate = getFormattedDate(moment().format());
      this.searchOptions.bsnCode= "ALL";
      this.searchOptions.lotteryRceptStatus = ["RCEPT"];
    },
    searchDetailPopupOpen() {
      this.isSearchDetailPopupOpen = true;
      this.$refs.searchDetailPopup.show();
    },
    searchDetailPopupClose() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
    timeListGridInit() {
      this.timeList = [];
      this.timeCount = {
        resveDate: null,
        dwName: null,
        resvePossibleTime: 0
      };
      this.isTimeListViewOpened = false;
    },
    getReservationWaitTimeList(data) {
      const lotteryDate = data.lotteryDate;
      const dwName = commonCodesGetComName("DW_CODE", data.dwCode);
      getReservationWaitTimeList(lotteryDate)
        .then((response) => {
          this.timeList = response.value.reservationWaitTimeLists;

          this.timeCount.resvePossibleTime = this.timeList.filter(data => data.timeStatus === "EMPTY" && !data.webOpenFlag).length;
          this.timeCount.resveDate = lotteryDate;
          this.timeCount.dwName = dwName;
          // if (response.value.reservationPossibleTimeCounts.length > 0) {
          //   this.timeCount = response.value.reservationPossibleTimeCounts[0];
          // } else {
          //   this.timeCount.resveDate = lotteryDate;
          //   this.timeCount.resvePossibleTime = 0;
          //   this.timeCount.dwName = dwName;
          // }
        })
        .catch((error) => {
          console.log("getReservationWaitTimeList.err.===>", error);
        });
    },
    onTimeListGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field
          }
        }
      } = args;

      if (field === "resveStatus") {
        args.node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    onTimeListGridQueryCellInfo(args) {
      const {
        column: {field},
        cell,
        data
      } = args;
      if (field === undefined) {
        if (data.agncyOpenFlag) {
          cell.style.backgroundColor = commonCodesGetColorValue(
            "COLOR_TIME_STATUS",
            "AGNCY_OPEN_FLAG"
          );
        } else if (data.webOpenFlag) {
          cell.style.backgroundColor = commonCodesGetColorValue(
            "COLOR_TIME_STATUS",
            "WEB_OPEN_FLAG"
          );
        }
      } else if (field === "resveTime") {
        cell.classList.add(this.$t("className.grid.selectedCellBold"));
        if (
          data.timeStatus === "BLANK" ||
          data.timeStatus === "BLOCK" ||
          data.timeStatus === "HOLD"
        ) {
          cell.style.backgroundColor = commonCodesGetColorValue(
            "TIME_STATUS",
            data.timeStatus
          );
          cell.style.color = "#FFFFFF";
        } else if (
          data.cancelDiv === "RAIN" ||
          data.cancelDiv === "NOSHOW"
        ) {
          cell.style.backgroundColor = commonCodesGetColorValue(
            "CANCEL_DIV",
            data.cancelDiv
          );
          cell.style.color = "#FFFFFF";
        }
      } else if (field === "resveStatus") {
        cell.classList.add(this.$t("className.grid.clickArea"));
        if (
          !!data.resveId &&
          data.webOpenFlag
        ) {
          cell.style.backgroundColor = commonCodesGetColorValue(
            "COLOR_TIME_STATUS",
            "WEB_OPEN_FLAG"
          );
        } else if (
          !!data.grpKind &&
          data.grpKind !== "FIT"
        ) {
          cell.style.backgroundColor = commonCodesGetColorValue(
            "GRP_KIND",
            data.grpKind
          );
        } else if (
          data.memberDiv !== null &&
          data.memberDiv !== undefined
        ) {
          // MEMBER_DIV 의 3번째 index Attribute 로 COLOR_MEMBER_DIV의 colorValue 조회 후 백그라운드 등록
          const attrMemberDiv = commonCodesGetCommonCodeAttrbByCodeAndIdx(
            "MEMBER_DIV",
            data.memberDiv,
            3
          );
          cell.style.backgroundColor = commonCodesGetColorValue(
            "COLOR_MEMBER_DIV",
            attrMemberDiv
          );
          cell.style.color = commonCodesGetColorValue(
            "MEMBER_DIV",
            data.memberDiv
          );
        }
      }
    },
    timeListGridActionComplete(args) {
      const {
        requestType
      } = args;
      if (requestType === "refresh") {
        this.$refs.timeListGrid.filterByColumn(
          "resveStatus",
          "equal",
          "예약가능"
        );
      }
      this.timeCount.resvePossibleTime = this.$refs.timeListGrid.getCurrentViewRecords().filter(data => data.timeStatus === "EMPTY" && !data.webOpenFlag).length;
    },
    async fetchAggregationButtonClicked() {
      this.isLotteryAggregatesPopupOpen = true;
      await this.$nextTick();
      await this.$refs.lotteryAggregatesPopup.showPopup(this.searchOptions.fromDate, this.searchOptions.toDate);
    },
    addLotteryReservationButtonClick() {
      this.isLotteryReservationPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.lotteryReservationPopup.showPopup({
          fromDate: this.searchOptions.fromDate
        });
      });
    },
    onLotteryReservationPopupClosed(isSaveFlag) {
      this.isLotteryReservationPopupOpen = false;

      if (isSaveFlag) {
        this.onViewButtonClicked();
      }
    },
    onLotteryAggregatesPopupClosed() {
      this.isLotteryAggregatesPopupOpen = false;
    },
    onLotteryReservationGridCellClick(
      event,
      { column: { field }, record }
    ) {
      // 회원명을 제외한 나머지 항목을 클릭시 타임 목록 조회되도록.
      if (field !== "memberName") {
        this.getReservationWaitTimeList(record);

        this.isTimeListViewOpened = true;
      } else {
        this.timeListGridInit();
        this.isLotteryReservationPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.lotteryReservationPopup.showPopup(record);
        });
      }
    },
    async timeListGridRecordClick(args) {
      const {
        column: {
          field
        },
        rowData
      } = args;

      if (field === "resveStatus" && rowData.timeStatus === "EMPTY" && !rowData.webOpenFlag) {
        const lotteryReservationGridSelectedRows = this.$refs.lotteryReservationGrid.getSelectedRows();

        if (!(lotteryReservationGridSelectedRows.length > 0)) {
          this.infoToast("선택된 추첨예약 데이터가 없습니다");
          return;
        }

        if (!(await this.confirm(lotteryReservationGridSelectedRows[0].memberName + " 님을 가배정 하시겠습니까?"))) {
          return;
        }

        const lotteryId = lotteryReservationGridSelectedRows[0].lotteryId;
        const timeId = rowData.timeId;

        try {
          await GolfErpAPI.reservationLotteryProvisionalAssignmentByLotteryIdAndTimeId(lotteryId, timeId);

          await this.onViewButtonClicked();
          this.getReservationWaitTimeList(lotteryReservationGridSelectedRows[0]);
        } catch (e) {
          console.error("reservationLotteryProvisionalAssignmentByLotteryIdAndTimeId.===>", e);
        }
      } else if (field === "resveStatus" && (rowData.timeStatus !== "EMPTY" || rowData.webOpenFlag)) {
        this.infoToast("가배정 가능한 타임이 아닙니다");
      }
    },
    async provisionalAssignmentButtonClicked() {
      // 라데나는 수동 배정한다고 함
      // 자동 배정 API는 수정 안된 상태(타골프장 기준으로 되어 있음)
      // 추후 자동 배정 요청시 API부터 수정해야함
      this.errorToast("자동 배정은 불가합니다");
      // if (!(this.lotteryReservationsTotalCount > 0)) {
      //   this.infoToast("예약 가배정 작업을 위한 추첨예약 리스트가 없습니다");
      //   return;
      // }
      //
      // const count = await this.fetchReservationLotteryAggregates(["RESVE_OUT", "RESVE"]);
      //
      // console.log('count.===>', count);
      //
      // if (count > 0) {
      //   this.infoToast("배정 가능한 상태가 아닙니다");
      //   return;
      // }
      //
      // const {
      //   filters: gridFilters,
      // } = this.$refs["lotteryReservationGrid"].getOrdersAndFiltersAndPage();
      //
      // const rceptTempCount = await GolfErpAPI.fetchCountByRceptStatusCheck({
      //   fromDate: this.searchOptions.fromDate || undefined,
      //   toDate: this.searchOptions.toDate || undefined,
      //   memberNameNumber:
      //     this.searchOptions.memberNameNumber || undefined,
      //   bsnCode: (this.searchOptions.bsnCode === "ALL" ? null : this.searchOptions.bsnCode) || undefined,
      //   lotteryRceptStatusList: (this.searchOptions.lotteryRceptStatus.length > 0 ? this.searchOptions.lotteryRceptStatus : null) || undefined,
      //   lotteryRceptStatus: "RCEPT_TEMP",
      //   filters: gridFilters,
      // });
      //
      // if (rceptTempCount > 0) {
      //   if (!(await this.confirm(
      //     this.searchOptions.fromDate + " ~ " + this.searchOptions.toDate + " 기간의 자료 중 가배정 자료가 존재합니다.<br>" +
      //     "이미 배정된 자료는 제외됩니다.<br>" +
      //     "추첨 예약 가배정 작업을 진행하시겠습니까?"
      //   ))) {
      //     return;
      //   }
      // } else {
      //   if (!(await this.confirm(
      //     this.searchOptions.fromDate + " ~ " + this.searchOptions.toDate + " 의 추첨 예약 가배정 작업을 진행하시겠습니까?"
      //   ))) {
      //     return;
      //   }
      // }
      //
      // const _orders = [];
      //
      // _orders.push({
      //   field: "lotteryDate",
      //   direction: "ASC"
      // });
      //
      // _orders.push({
      //   field: "lotteryRank",
      //   direction: "ASC"
      // });
      //
      // _orders.push({
      //   field: "insertSno",
      //   direction: "asc",
      // });
      //
      // // const lastPageNumber = this.$refs["lotteryReservationGrid"].lastPageNumber;
      // // const sizeOfRecordPerPage = this.$refs["lotteryReservationGrid"].sizeOfRecordPerPage;
      // //
      // // const startNow = moment.now();
      // // for (let i = 0; i < lastPageNumber; i++) {
      // //   await GolfErpAPI.reservationLotteryProvisionalAssignment({
      // //     fromDate: this.searchOptions.fromDate || undefined,
      // //     toDate: this.searchOptions.toDate || undefined,
      // //     memberNameNumber:
      // //       this.searchOptions.memberNameNumber || undefined,
      // //     bsnCode: (this.searchOptions.bsnCode === "ALL" ? null : this.searchOptions.bsnCode) || undefined,
      // //     lotteryRceptStatusList: (this.searchOptions.lotteryRceptStatus.length > 0 ? this.searchOptions.lotteryRceptStatus : null) || undefined,
      // //     filters: gridFilters,
      // //     orders: _orders,
      // //     limit: sizeOfRecordPerPage,
      // //     offset: i * sizeOfRecordPerPage
      // //   });
      // // }
      // // const endNow = moment.now();
      // //
      // // console.log('start.===>', startNow);
      // // console.log('end.===>', endNow);
      // // console.log('차이.1.===>', moment(endNow - startNow));
      // // console.log('차이.2.===>', moment(endNow - startNow).format("mm:s"));
      // // console.log('========================================');
      //
      // const loopData = Math.ceil(this.lotteryReservationsTotalCount / 1000);
      //
      // const startNow2 = moment.now();
      // for (let i = 0; i < loopData; i++) {
      //   if (this.isStopProgress) {
      //     this.infoToast("작업이 취소되었습니다");
      //     await this.clearLoaderProgress();
      //     return;
      //   }
      //   await this.setLoaderProgress({
      //     progress: i,
      //     maxProgress: loopData
      //   });
      //   await GolfErpAPI.reservationLotteryProvisionalAssignment({
      //     fromDate: this.searchOptions.fromDate || undefined,
      //     toDate: this.searchOptions.toDate || undefined,
      //     memberNameNumber:
      //       this.searchOptions.memberNameNumber || undefined,
      //     bsnCode: (this.searchOptions.bsnCode === "ALL" ? null : this.searchOptions.bsnCode) || undefined,
      //     lotteryRceptStatusList: (this.searchOptions.lotteryRceptStatus.length > 0 ? this.searchOptions.lotteryRceptStatus : null) || undefined,
      //     filters: gridFilters,
      //     orders: _orders,
      //     limit: 1000,
      //     offset: i * 1000
      //   });
      // }
      // await this.clearLoaderProgress();
      //
      // const endNow2 = moment.now();
      //
      // console.log('start.===>', startNow2);
      // console.log('end.===>', endNow2);
      // console.log('차이.1.===>', moment(endNow2 - startNow2));
      // console.log('차이.2.===>', moment(endNow2 - startNow2).format("mm:s"));
      //
      // this.infoToast("가배정이 완료되었습니다");
      //
      // await this.onViewButtonClicked();
    },
    async provisionalAssignmentCancelButtonClicked() {
      if (!(this.lotteryReservationsTotalCount > 0)) {
        this.infoToast("예약 가배정 취소 작업을 위한 추첨예약 리스트가 없습니다");
        return;
      }

      const count = await this.fetchReservationLotteryAggregates(["RESVE_OUT", "RESVE"]);

      console.log('count.===>', count);

      if (count > 0) {
        this.infoToast("취소 가능한 상태가 아닙니다");
        return;
      }

      const {
        filters: gridFilters,
      } = this.$refs["lotteryReservationGrid"].getOrdersAndFiltersAndPage();

      if (!(await this.confirm(
        "가배정된 추첨자료의 취소 작업을 진행하시겠습니까?"
      ))) {
        return;
      }

      const _orders = [];

      _orders.push({
        field: "lotteryDate",
        direction: "ASC"
      });

      _orders.push({
        field: "lotteryRank",
        direction: "ASC"
      });

      const loopData = Math.ceil(this.lotteryReservationsTotalCount / 1000);
      // const lastPageNumber = this.$refs["lotteryReservationGrid"].lastPageNumber;
      // const sizeOfRecordPerPage = this.$refs["lotteryReservationGrid"].sizeOfRecordPerPage;

      for (let i = 0; i < loopData; i++) {
        if (this.isStopProgress) {
          this.infoToast("작업이 취소되었습니다");
          await this.clearLoaderProgress();
          return;
        }
        await this.setLoaderProgress({
          progress: i,
          maxProgress: loopData
        });
        await GolfErpAPI.reservationLotteryProvisionalAssignmentCancel({
          fromDate: this.searchOptions.fromDate || undefined,
          toDate: this.searchOptions.toDate || undefined,
          memberNameNumber:
            this.searchOptions.memberNameNumber || undefined,
          bsnCode: (this.searchOptions.bsnCode === "ALL" ? null : this.searchOptions.bsnCode) || undefined,
          lotteryRceptStatusList: (this.searchOptions.lotteryRceptStatus.length > 0 ? this.searchOptions.lotteryRceptStatus : null) || undefined,
          filters: gridFilters,
          orders: _orders,
          limit: 1000,
          offset: i * 1000
        });
      }
      await this.clearLoaderProgress();

      this.infoToast("가배정 취소가 완료되었습니다");

      await this.onViewButtonClicked();
    },
    onLotteryReservationConfirmPopupClosed(isSaveFlag) {
      this.isLotteryReservationConfirmPopup = false;

      if (isSaveFlag) {
        this.onViewButtonClicked();
      }
    },
    async provisionalAssignmentReservationConfirmButtonClicked() {
      if (!(this.lotteryReservationsTotalCount > 0)) {
        this.infoToast("예약 확정 작업을 위한 추첨예약 리스트가 없습니다");
        return;
      }

      const {
        filters: gridFilters,
      } = this.$refs["lotteryReservationGrid"].getOrdersAndFiltersAndPage();

      // 예약 확정 Confirm Popup Open.
      this.isLotteryReservationConfirmPopup = true;
      this.$nextTick(() => {
        this.$refs.lotteryReservationConfirmPopup.showPopup({
          searchOptions: {
            fromDate: this.searchOptions.fromDate || undefined,
            toDate: this.searchOptions.toDate || undefined,
            memberNameNumber:
              this.searchOptions.memberNameNumber || undefined,
            bsnCode: (this.searchOptions.bsnCode === "ALL" ? null : this.searchOptions.bsnCode) || undefined,
            lotteryRceptStatusList: (this.searchOptions.lotteryRceptStatus.length > 0 ? this.searchOptions.lotteryRceptStatus : null) || undefined,
            filters: gridFilters,
          }
        });
      });
    },
    async scoreAggregationButtonClicked() {
      if (!(this.lotteryReservationsTotalCount > 0)) {
        this.infoToast("점수집계 작업을 위한 추첨예약 리스트가 없습니다");
        return;
      }

      const count = await this.fetchReservationLotteryAggregates(["RCEPT_TEMP", "RESVE_OUT", "RESVE"]);

      console.log('count.===>', count);

      if (count > 0) {
        this.infoToast("접수집계 가능한 상태가 아닙니다");
        return;
      }

      const {
        filters: gridFilters,
      } = this.$refs["lotteryReservationGrid"].getOrdersAndFiltersAndPage();

      if (!(await this.confirm(
        "접수상태가 [접수등록]인 데이터만 점수집계됩니다.<br>" +
        "점수집계 작업을 진행하시겠습니까?"
      ))) {
        return;
      }

      await GolfErpAPI.reservationLotteryScoreAggregation({
        fromDate: this.searchOptions.fromDate || undefined,
        toDate: this.searchOptions.toDate || undefined,
        memberNameNumber:
          this.searchOptions.memberNameNumber || undefined,
        bsnCode: (this.searchOptions.bsnCode === "ALL" ? null : this.searchOptions.bsnCode) || undefined,
        lotteryRceptStatusList: (this.searchOptions.lotteryRceptStatus.length > 0 ? this.searchOptions.lotteryRceptStatus : null) || undefined,
        filters: gridFilters,
      });

      this.infoToast("점수집계 작업이 완료되었습니다");

      await this.onViewButtonClicked();
    },
    async fetchReservationLotteryAggregates(lotteryRceptStatusList) {
      console.log('lotteryRceptStatusList.===>', lotteryRceptStatusList);

      return (await GolfErpAPI.fetchReservationLotteryAggregates(
        this.searchOptions.fromDate, this.searchOptions.toDate, this.searchOptions.bsnCode === "ALL" ? null : this.searchOptions.bsnCode
      )).chartData
        .filter(data => lotteryRceptStatusList.includes(data.lotteryRceptStatus))
        .map(data => data.count)
        .reduce((acc, cur) => acc + cur, 0);
    },
  }
};
</script>

<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <ul class="content">
              <li class="item select">
                <ejs-dropdownlist
                    v-model="searchConditions.dateDiv"
                    :dataSource="searchOptions.dateDiv"
                    :allowFiltering="false"
                    :fields="commonCodeFields"
                    cssClass="lookup-condition-dropdown"
                />
              </li>
              <li class="item dateRange">
                <input-date-range
                    type="lookup-condition"
                    v-model="dateRange"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              선수금 종류
            </div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect
                  v-model="searchConditions.depositKinds"
                  cssClass="lookup-condition-multiselect"
                  :dataSource="searchOptions.depositKinds"
                  :fields="commonCodeFields"
                  placeHolder="전체"
                  width="180"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              입금자명
            </div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="searchConditions.memberName"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              입금구분
            </div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect
                  v-model="searchConditions.incomDivs"
                  cssClass="lookup-condition-multiselect"
                  :dataSource="searchOptions.incomDivs"
                  :fields="commonCodeFields"
                  placeHolder="전체"
                  width="180"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="checkbox"
                          value="true"
                          v-model="searchConditions.isPGFlag"
                      />
                      <i></i>
                      <div class="label">PG결제</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              buttonDiv="GET"
              :isShortcutButton="true"
              :ignore="isPopupOpened"
              @click.native="searchButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">선수금입금현황</div>
              <div class="header-caption">[{{numberWithCommas(depositIncomStatusLength)}}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      buttonDiv="FILE"
                      :ignore="isPopupOpened"
                      @click.native="excel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="depositIncomStatusGrid"
                v-bind="depositIncomStatusGridOptions"
                :dataSource="depositIncomStatusList"
                @queryCellInfo="depositIncomStatusGridQueryCellInfo"
                @actionComplete="depositIncomStatusGridActionComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';
import ErpButton from "@/components/button/ErpButton.vue";

import InputText from "@/components/common/text/InputText";
import InputDateRange from '@/components/common/datetime/InputDateRange';
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {Aggregate, ExcelExport, Filter, ForeignKey, Page, Group, Resize} from '@syncfusion/ej2-vue-grids';
import {commonCodesGetCommonCode, commonCodesGetComName} from "@/utils/commonCodes";
import {getTodayDate, getFormattedDate} from "@/utils/date";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {gridUtilGetMemberNoColumnAccess} from "@/utils/gridUtil";
import { orderBy as _orderBy } from "lodash";
import {numberWithCommas} from "@/utils/number";

export default {
  name: "DepositIncomStatus",
  components: {
    InputText,
    InputDateRange,
    ErpButton,
    EjsGridWrapper,
  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  data() {
    return {
      commonCodeFields: {text: "comName", value: "comCode"},
      dateRange: {
        from: null,
        to: null,
      },
      searchConditions: {
        depositKinds: [],
        incomDivs: [],
        memberName: null,
        dateDiv:"RESVE_DATE",
        isPGFlag:true,
      },
      searchOptions: {
        depositKinds: commonCodesGetCommonCode("DEPOSIT_KIND", true),
        incomDivs: commonCodesGetCommonCode("INCOM_DIV", true),
        dateDiv: [
          { comCode: "RESVE_DATE", comName: "예약일자" },
          { comCode: "INCOM_DATE", comName: "입금일자" },
        ],
      },
      depositIncomStatusList: [],
      depositIncomStatusLength: 0,
      depositIncomStatusGridOptions: {
        provides: [
          Aggregate,
          Filter,
          Resize,
          Page,
          Group,
          ExcelExport,
          ForeignKey,
        ],
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
          persistSelection: false,
        },
        groupSettings: {
          columns: ["incomDateByGroup"],
          showDropArea: false,
        },
        allowExcelExport: true,
        allowGrouping: true,
        allowPaging: true,
        pageSettings: {pageSize: 50},
        columns: [
          {
            field: "incomId",
            visible: false,
            isPrimaryKey: true,
          },
          {
            field: "incomDateByGroup",
            visible: false,
          },
          {
            field: "incomDate",
            headerText: "입금일자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "resveDate",
            headerText: "예약일자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "depositKind",
            headerText: "선수금종류",
            type: "string",
            minWidth: 16,
            width: 100,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "DEPOSIT_KIND",
          },
          {
            field: "memberName",
            headerText: "회원명",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "memberNo",
            headerText: "회원번호",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            valueAccessor: (field, data) =>
              gridUtilGetMemberNoColumnAccess(field, data),
          },
          {
            field: "memberDiv",
            headerText: "회원구분",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "MEMBER_DIV",
          },
          {
            field: "incomDiv",
            headerText: "입금구분",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "INCOM_DIV",
          },
          {
            field: "cardAndBankName",
            headerText: "은행명/카드사",
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "center",
          },
          {
            field: "acnutNoAndCardNo",
            headerText: "계좌번호/카드번호",
            type: "string",
            minWidth: 16,
            width: 150,
            textAlign: "center",
          },
          {
            field: "depositAmt",
            headerText: "선수금",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "insertName",
            headerText: "등록자",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
          },
          {
            field: "insertDt",
            headerText: "등록일시",
            type: "string",
            minWidth: 16,
            width: 150,
            textAlign: "center",
          },
        ],
        aggregates: [
          {
            columns: [
              {
                field: "memberDiv",
                aggregationType: "GroupCaption",
                customAggregate: () => {
                  let rtnData = "";
                  _orderBy(commonCodesGetCommonCode("INCOM_DIV", true), "sortNo")
                    .map((item, idx) => {
                      rtnData += (idx === 0 ? item.comName : "<br>" + item.comName);
                    });
                  return rtnData;
                }
              },
              {
                field: "incomDiv",
                aggregationType: "GroupCaption",
                textAlign: "right",
                customAggregate: (args) => {
                  const {
                    items
                  } = args;
                  let rtnData = "";
                  _orderBy(commonCodesGetCommonCode("INCOM_DIV", true), "sortNo")
                    .map((item, idx) => {
                      const sumData =
                        items
                          .filter(filterItem => filterItem.incomDiv === item.comCode)
                          .map(mapItem => mapItem.depositAmt)
                          .reduce((acc, cur) => acc + cur, 0);
                      rtnData += (idx === 0 ? (sumData === 0 ? "-" : numberWithCommas(sumData)) : "<br>" + (sumData === 0 ? "-" : numberWithCommas(sumData)));
                    });
                  return rtnData;
                }
              },
              {
                field: "acnutNoAndCardNo",
                aggregationType: "GroupCaption",
                customAggregate: "총계",
              },
              {
                field: "depositAmt",
                aggregationType: "GroupSum",
              }
            ],
          },
          {
            columns: [
              {
                field: "memberDiv",
                aggregationType: "TotalCaption",
                customAggregate: () => {
                  let rtnData = "";
                  _orderBy(commonCodesGetCommonCode("INCOM_DIV", true), "sortNo")
                    .map((item, idx) => {
                      rtnData += (idx === 0 ? item.comName : "<br>" + item.comName);
                    });
                  return rtnData;
                }
              },
              {
                field: "incomDiv",
                aggregationType: "TotalCaption",
                textAlign: "right",
                customAggregate: (args) => {
                  const records = args?.result ? args.result : args;
                  let rtnData = "";
                  _orderBy(commonCodesGetCommonCode("INCOM_DIV", true), "sortNo")
                    .map((item, idx) => {
                      const sumData =
                        records
                          .filter(filterItem => filterItem.incomDiv === item.comCode)
                          .map(mapItem => mapItem.depositAmt)
                          .reduce((acc, cur) => acc + cur, 0);
                      rtnData += (idx === 0 ? (sumData === 0 ? "-" : numberWithCommas(sumData)) : "<br>" + (sumData === 0 ? "-" : numberWithCommas(sumData)));
                    });
                  return rtnData;
                }
              },
              {
                field: "acnutNoAndCardNo",
                aggregationType: "TotalCaption",
                customAggregate: "총계",
              },
              {
                field: "depositAmt",
                aggregationType: "TotalSum",
              }
            ],
          }
        ],
      },
    };
  },
  created() {
    this.dateRange = {
      from: getFormattedDate(getTodayDate(), "YYYY-MM-01"),
      to: getTodayDate(),
    };
  },
  computed: {
    isPopupOpened() {
      return false;
    }
  },
  methods: {
    numberWithCommas,
    async searchButtonClicked() {
      this.depositIncomStatusList = [];

      const data = await GolfErpAPI.fetchDepositIncomStatus({
        depositKinds: this.searchConditions.depositKinds,
        memberName: this.searchConditions.memberName,
        incomDivs: this.searchConditions.incomDivs,
        dateFrom: this.dateRange.from,
        dateTo: this.dateRange.to,
        dateDiv : this.searchConditions.dateDiv
      });

      let depositIncomStatus = [];

      data.map(deposit => {
        deposit.depositIncoms.map(incom => {
          depositIncomStatus.push({
            incomId: incom.incomId,
            incomDate: incom.incomDate,
            incomDateByGroup: incom.incomDate,
            depositKind: deposit.depositKind,
            memberName: deposit.memberName,
            memberDiv: deposit.memberDiv,
            membershipId: deposit.membershipId,
            memberNo: deposit.memberNo,
            incomDiv: incom.incomDiv,
            cardAndBankName: (incom.incomDiv === "CARD" ? incom.payCard?.purchaseName : (incom.incomDiv === "TRANS" ? commonCodesGetComName("BANK_CODE", incom.bankCode) : null)),
            acnutNoAndCardNo: (incom.incomDiv === "CARD" ? incom.payCard?.cardNo : (incom.incomDiv === "TRANS" ? incom.acnutNo : null)),
            depositAmt: incom.depositAmt,
            insertName: incom.insertName,
            insertDt: incom.insertDt,
            resveDate : deposit.resveDate,
            pgTid : incom.pgTid,
          });
        });
      });

      if(this.searchConditions.isPGFlag){
        depositIncomStatus = depositIncomStatus.filter(item => item.pgTid !== undefined);
      }

      // Grid에서 그룹화하면 데이터 orderBy와 상관없이 Grid 내에서 자동 정렬됨.
      this.depositIncomStatusList = _orderBy(depositIncomStatus, "incomDate", ["desc"]);
    },
    excel() {
      this.$refs.depositIncomStatusGrid.excelExport();
    },
    depositIncomStatusGridQueryCellInfo() {},
    depositIncomStatusGridActionComplete(args) {
      const {
        requestType,
        action,
      } = args;

      if (["refresh"].includes(requestType)) {
        this.depositIncomStatusLength = this.depositIncomStatusList.length;
      } else if (["filtering"].includes(requestType)) {
        if (action === "clearFilter") {
          this.depositIncomStatusLength = this.depositIncomStatusList.length;
        } else {
          this.depositIncomStatusLength = args?.rows?.length || 0;
        }
      }
    }
  },
};
</script>

<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="divideByNPopup"
      :header="`1/N 분할`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="862"
      height="600"
      :isModal="true"
      :close="onDivideByNPopupClosed"
    >
      <div class="window divideByN">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <div class="article-left">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-01">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">분할 방법</div>
                      </div>
                    </div>
                    <div class="section-body">
                      <div class="body-data body">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul class="data-content">
                              <li class="field">
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <!-- 포커스 : focus -->
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="radio"
                                              name="divideByNPopupDivideDiv"
                                              v-model="popupData.divideDiv"
                                              value="T"
                                              @change="divideDivChanged"
                                            />
                                            <i></i>
                                            <div class="label">팀</div>
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                              type="radio"
                                              name="divideByNPopupDivideDiv"
                                              v-model="popupData.divideDiv"
                                              value="G"
                                              :disabled="
                                                !(popupData && popupData.grpNo)
                                              "
                                              @change="divideDivChanged"
                                            />
                                            <i></i>
                                            <div class="label">단체</div>
                                          </label>
                                        </li>
                                      </ul>
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                  </section>
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-02">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">분할 매장</div>
                      </div>
                    </div>
                    <div class="section-body">
                      <div class="body-data header">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul class="data-content">
                              <li class="field checkbox">
                                <div class="title">
                                  <ul class="row">
                                    <li class="item form-group check">
                                      <!-- 포커스 : focus -->
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              :checked="
                                                storeCode.length ===
                                                totalStoreCodes.length
                                              "
                                              @change="storeCodeCheckAllChecked"
                                            />
                                            <i></i>
                                          </label>
                                        </li>
                                      </ul>
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field store">
                                <div class="title">매장</div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                      <div class="body-data body">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul
                              class="data-content"
                              v-for="code in totalStoreCodes"
                              :key="code.comCode"
                            >
                              <li class="field checkbox">
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group check">
                                      <!-- 포커스 : focus -->
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              v-model="storeCode"
                                              :value="code.comCode"
                                              @change="storeCodeCheckBoxChecked"
                                            />
                                            <i></i>
                                          </label>
                                        </li>
                                      </ul>
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field store">
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group text">
                                      {{ code.comName }}
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                  </section>
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-03">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">분할 단위</div>
                      </div>
                    </div>
                    <div class="section-body">
                      <div class="body-data header">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul class="data-content">
                              <li class="field radio">
                                <div class="title"></div>
                              </li>
                              <li class="field unit">
                                <div class="title">단위</div>
                              </li>
                              <li class="field scroll">
                                <div class="title"></div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                      <div class="body-data body">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul
                              class="data-content"
                              v-for="code in totalDivideUnit"
                              :key="code.comCode"
                            >
                              <li class="field radio">
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group check">
                                      <!-- 포커스 : focus -->
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="radio"
                                              id="divideUnit"
                                              v-model="divideUnit"
                                              :value="code.comCode"
                                              @change="divideUnitRadioChanged"
                                            />
                                            <i></i>
                                          </label>
                                        </li>
                                      </ul>
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field unit">
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group text">
                                      {{ code.comName }}
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                  </section>
                </div>
                <div class="article-right">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-04">
                    <div class="section-body">
                      <ejs-tab
                        id="element"
                        :animation="divideTabAnimation"
                        :selected="divideTabSelected"
                      >
                        <div class="e-tab-header">
                          <div>전체</div>
                          <!--<div>품목별</div>-->
                        </div>
                        <div class="e-content">
                          <div>
                            <article class="body-article article-0101">
                              <!-- 아코디언 : accordion / 닫힘 : close -->
                              <section class="article-section section-0401">
                                <div class="section-body">
                                  <div class="body-data header">
                                    <div class="data-outer">
                                      <div class="data-inner">
                                        <ul class="data-content">
                                          <li class="field team">
                                            <div class="title">팀</div>
                                          </li>
                                          <li class="field time">
                                            <div class="title">시간</div>
                                          </li>
                                          <li class="field course">
                                            <div class="title">코스</div>
                                          </li>
                                          <li class="field checkbox">
                                            <div
                                              class="title"
                                              style="background-color: #edfaf0"
                                            >
                                              <ul class="row">
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="checkbox"
                                                          :checked="
                                                            visitsCheckAllChecked(
                                                              allDivideList
                                                            )
                                                          "
                                                          @change="
                                                            visitsCheckAllChanged(
                                                              $event,
                                                              allDivideList
                                                            )
                                                          "
                                                        />
                                                        <i></i>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                  <div class="validation">
                                                    <div
                                                      class="e-tip-content"
                                                    ></div>
                                                    <div
                                                      class="e-tip-top"
                                                    ></div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field visitor">
                                            <div
                                              class="title"
                                              style="background-color: #edfaf0"
                                            >
                                              내장객
                                            </div>
                                          </li>
                                          <li class="field locker">
                                            <div class="title">락카</div>
                                          </li>
                                          <li class="field checkbox">
                                            <div
                                              class="title"
                                              style="background-color: #edf6fa"
                                            >
                                              <ul class="row">
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="checkbox"
                                                          :checked="
                                                            amtsCheckAllChecked(
                                                              allDivideList
                                                            )
                                                          "
                                                          @change="
                                                            amtsCheckAllChanged(
                                                              $event,
                                                              allDivideList
                                                            )
                                                          "
                                                        />
                                                        <i></i>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                  <div class="validation">
                                                    <div
                                                      class="e-tip-content"
                                                    ></div>
                                                    <div
                                                      class="e-tip-top"
                                                    ></div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field total">
                                            <div
                                              class="title"
                                              style="background-color: #edf6fa"
                                            >
                                              이용총액
                                            </div>
                                          </li>
                                          <li class="field divide">
                                            <div class="title">
                                              분할 예상금액
                                            </div>
                                          </li>
                                          <li class="field scroll">
                                            <!-- 필수 : required -->
                                            <div class="title">스크롤</div>
                                          </li>
                                        </ul>
                                        <div class="border-left"></div>
                                        <div class="border-right"></div>
                                        <div class="border-top"></div>
                                        <div class="border-bottom"></div>
                                      </div>
                                    </div>
                                    <div class="border-left"></div>
                                    <div class="border-right"></div>
                                    <div class="border-top"></div>
                                    <div class="border-bottom"></div>
                                  </div>
                                  <div class="body-data body">
                                    <div class="data-outer">
                                      <div class="data-inner">
                                        <ul
                                          class="data-content"
                                          v-for="(
                                            divideInfo, divideInfoIdx
                                          ) in allDivideList"
                                          :key="divideInfo.visitId"
                                        >
                                          <li class="field team">
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li
                                                      v-if="
                                                        divideInfoIdx === 0 ||
                                                        (divideInfoIdx > 0 &&
                                                          divideInfo.chkinId !==
                                                            allDivideList[
                                                              divideInfoIdx - 1
                                                            ].chkinId)
                                                      "
                                                    >
                                                      <label>
                                                        <input
                                                          type="checkbox"
                                                          :checked="
                                                            teamCheckAllChecked(
                                                              allDivideList,
                                                              divideInfo
                                                            )
                                                          "
                                                          @change="
                                                            teamCheckAllChanged(
                                                              $event,
                                                              allDivideList,
                                                              divideInfo
                                                            )
                                                          "
                                                        />
                                                        <i></i>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                  <div class="validation">
                                                    <div
                                                      class="e-tip-content"
                                                    ></div>
                                                    <div
                                                      class="e-tip-top"
                                                    ></div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field time">
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group text"
                                                  v-if="
                                                    divideInfoIdx === 0 ||
                                                    (divideInfoIdx > 0 &&
                                                      divideInfo.chkinId !==
                                                        allDivideList[
                                                          divideInfoIdx - 1
                                                        ].chkinId)
                                                  "
                                                >
                                                  {{ divideInfo.startTime }}
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field course">
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group text"
                                                  v-if="
                                                    divideInfoIdx === 0 ||
                                                    (divideInfoIdx > 0 &&
                                                      divideInfo.chkinId !==
                                                        allDivideList[
                                                          divideInfoIdx - 1
                                                        ].chkinId)
                                                  "
                                                >
                                                  {{
                                                    commonCodesGetComName(
                                                      "COURSE_CODE",
                                                      divideInfo.startCourse
                                                    )
                                                  }}
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field checkbox">
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="checkbox"
                                                          v-model="
                                                            divideInfo.visitDivideFlag
                                                          "
                                                          @change="
                                                            visitIdCheckboxChanged
                                                          "
                                                        />
                                                        <i></i>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                  <div class="validation">
                                                    <div
                                                      class="e-tip-content"
                                                    ></div>
                                                    <div
                                                      class="e-tip-top"
                                                    ></div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field visitor">
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group text"
                                                >
                                                  {{ divideInfo.visitName }}
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field locker">
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group text"
                                                >
                                                  {{ divideInfo.lockerNo }}
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field checkbox">
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="checkbox"
                                                          v-model="
                                                            divideInfo.amtDivideFlag
                                                          "
                                                          @change="
                                                            amtVisitIdCheckboxChanged
                                                          "
                                                        />
                                                        <i></i>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                  <div class="validation">
                                                    <div
                                                      class="e-tip-content"
                                                    ></div>
                                                    <div
                                                      class="e-tip-top"
                                                    ></div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field total">
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group text"
                                                >
                                                  {{
                                                    divideInfo.totAmt
                                                      | numberWithCommas
                                                  }}
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field divide">
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group text"
                                                >
                                                  {{
                                                    divideInfo.divideAmt
                                                      | numberWithCommas
                                                  }}
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                        </ul>
                                        <div class="border-left"></div>
                                        <div class="border-right"></div>
                                        <div class="border-top"></div>
                                        <div class="border-bottom"></div>
                                      </div>
                                    </div>
                                    <div class="border-left"></div>
                                    <div class="border-right"></div>
                                    <div class="border-top"></div>
                                    <div class="border-bottom"></div>
                                  </div>
                                  <div
                                    class="body-footer"
                                    style="
                                      border: 1px solid #ccc;
                                      border-top: none;
                                    "
                                  >
                                    <ul>
                                      <li>
                                        {{
                                          totalTeamCount(allDivideList)
                                            .totalCount
                                        }}팀
                                      </li>
                                      <li>{{ allDivideList.length }}명</li>
                                      <li>
                                        이용총액:
                                        {{
                                          totalTotAmt(allDivideList)
                                            | numberWithCommas
                                        }}
                                      </li>
                                      <!-- 분할 예상금액 표시 보류. -->
                                      <!--                                      <li>-->
                                      <!--                                        분할 예상금액 : {{totalDivideAmt(allDivideList)}}-->
                                      <!--                                      </li>-->
                                    </ul>
                                  </div>
                                </div>
                              </section>
                            </article>
                          </div>
                        </div>
                      </ejs-tab>
                    </div>
                  </section>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  :is-shortcut-button="true"
                  @click.native="divideByN"
              >
                적용
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeDivideByNPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  commonCodesGetComName,
  commonCodeGetComCodeDefaultValue,
  commonCodesGetCommonCode, commonCodesGetSortNo,
} from "@/utils/commonCodes";
import { orderBy as _orderBy } from "lodash";

import {
  saveDivideList,
  getDivideListByPut
} from "@/api/frontManagementCheckin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { numberWithCommas } from "@/utils/number";

export default {
  name: "divideByNPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ErpButton
  },
  filters: {
    numberWithCommas,
  },
  computed: {
    visitsCheckAllChecked() {
      return (divideList) => {
        return (
          divideList.filter((divide) => divide.visitDivideFlag).length ===
          divideList.length
        );
      };
    },
    amtsCheckAllChecked() {
      return (divideList) => {
        return (
          divideList.filter((divide) => divide.amtDivideFlag).length ===
          divideList.length
        );
      };
    },
    productCodeCheckAllChecked() {
      return (divideList) => {
        const countingObject = divideList.reduce(
          (countInfo, divideInfo) => {
            divideInfo.prodInfoList.forEach((prodInfo) => {
              countInfo.totalCount += 1;
              if (prodInfo.prodDivideFlag) {
                countInfo.prodDivideFlagTrueCount += 1;
              }
            });
            return countInfo;
          },
          { totalCount: 0, prodDivideFlagTrueCount: 0 }
        );
        return (
          countingObject.totalCount === countingObject.prodDivideFlagTrueCount
        );
      };
    },
    teamCheckAllChecked() {
      return (divideList, divideInfo, isProd) => {
        const teamList = divideList.filter(
          (divide) => divide.chkinId === divideInfo.chkinId
        );
        const teamCount = teamList.length;
        const isVisitDivideFlagAndAmtDivideFlagAllClicked =
          teamCount ===
            teamList.filter((divide) => divide.visitDivideFlag).length &&
          teamCount ===
            teamList.filter((divide) => divide.amtDivideFlag).length;

        if (!isProd) {
          return isVisitDivideFlagAndAmtDivideFlagAllClicked;
        } else {
          let prodDivideFlagAllTrue = true;
          teamList.some((teamInfo) => {
            if (
              teamInfo.prodInfoList.filter((prod) => prod.prodDivideFlag)
                .length !== teamInfo.prodInfoList.length
            ) {
              prodDivideFlagAllTrue = false;
              return false;
            }
          });
          return (
            isVisitDivideFlagAndAmtDivideFlagAllClicked && prodDivideFlagAllTrue
          );
        }
      };
    },
    /**
     * 팀 count 계산. chkinId 종류를 counting 한다
     */
    totalTeamCount() {
      return (divideList) => {
        return divideList.reduce(
          (accumulator, divideInfo) => {
            if (accumulator.currentChkinId !== divideInfo.chkinId) {
              accumulator.totalCount = accumulator.totalCount + 1;
              accumulator.currentChkinId = divideInfo.chkinId;
            }
            return accumulator;
          },
          { totalCount: 0, currentChkinId: null }
        );
      };
    },
    /**
     * 이용총액 합계
     */
    totalTotAmt() {
      return (divideList) => {
        return divideList.reduce((total, divideInfo) => {
          total += divideInfo.totAmt;
          return total;
        }, 0);
      };
    },
    /**
     * 분할 예상금액 합계
     */
    totalDivideAmt() {
      return (divideList) => {
        return divideList.reduce((total, divideInfo) => {
          total += divideInfo.divideAmt;
          return total;
        }, 0);
      };
    },
  },
  data() {
    return {
      // 공통
      divideFlag: "ALL",
      popupData: {},
      divideUnit: commonCodeGetComCodeDefaultValue("DIVIDE_UNIT"),
      storeCode: commonCodesGetCommonCode("STORE_GROUP").map(
        (code) => code.comCode
      ),
      totalStoreCodes: commonCodesGetCommonCode("STORE_GROUP"),
      totalDivideUnit: commonCodesGetCommonCode("DIVIDE_UNIT"),

      // ALL
      allDivideList: [], // API 조회 결과를 담아놓음

      // PROD
      prodDivideList: [], // API 조회 결과를 담아놓음

      // 기타
      divideTabAnimation: {
        previous: { effect: "None" },
        next: { effect: "None" },
      },
      isSaved: false,
    };
  },
  methods: {
    commonCodesGetComName,
    async divideByN() {
      // Validation
      let sumList = [];
      let chkinIdList = [];
      if (this.divideFlag === "ALL") {
        sumList = this.allDivideList;
      } else if (this.divideFlag === "PROD") {
        sumList = this.prodDivideList;
      }

      if (this.popupData.divideDiv === "T") {
        // 팀인 경우 팀 별 하나씩 체크 필요

        // #1. 팀 별 체크를 위해서 존재하는 chkinId 목록(= 팀 목록)을 뽑아 냄
        chkinIdList = sumList.reduce((chkinIds, sumInfo) => {
          if (!chkinIds.includes(sumInfo.chkinId)) {
            chkinIds.push(sumInfo.chkinId);
          }
          return chkinIds;
        }, []);

        // #2. 팀 내 한명씩 선택되었는지 확인 필요
        let isSelectedPerTeam = true;
        let isSelectedAmt = true;
        if (chkinIdList.length > 0) {
          chkinIdList.some((chkinId) => {
            if (
              sumList.filter(
                (sum) => sum.chkinId === chkinId && sum.visitDivideFlag
              ).length === 0
            ) {
              isSelectedPerTeam = false;
              return false;
            }
            if (
              sumList.filter(
                (sum) => sum.chkinId === chkinId && sum.amtDivideFlag
              ).length === 0
            ) {
              isSelectedAmt = false;
              return false;
            }
          });
        }
        if (!isSelectedPerTeam) {
          this.errorToast("팀 당 최소 1명의 내장객을 선택해 주셔야 합니다");
          return;
        }
        if (!isSelectedAmt) {
          this.errorToast("팀 당 최소 1건의 이용총액을 선택해 주셔야 합니다");
          return;
        }
      } else {
        // 단체인 경우 하나만 체크하면 됨
        if (sumList.filter((sum) => sum.visitDivideFlag).length === 0) {
          this.errorToast("최소 1명의 내장객을 선택해 주셔야 합니다");
          return;
        }
        if (sumList.filter((sum) => sum.amtDivideFlag).length === 0) {
          this.errorToast("최소 1건의 이용총액을 선택해 주셔야 합니다");
          return;
        }
      }

      let divideByNObject = {
        bsnDate: this.popupData.bsnDate,
        chkinId: this.popupData.chkinId,
        grpNo: this.popupData.divideDiv === "G" ? this.popupData.grpNo : null,
        divideDiv: this.popupData.divideDiv,
        storeCode: this.storeCode,
        divideUnit: this.divideUnit,
        tabDiv: this.divideFlag,
        divideInfoList: [],
      };

      if (this.divideFlag === "ALL") {
        this.allDivideList.forEach((divide) => {
          divideByNObject.divideInfoList.push({
            chkinId: divide.chkinId,
            amtDivideFlag: divide.amtDivideFlag,
            visitDivideFlag: divide.visitDivideFlag,
            visitId: divide.visitId,
            totAmt: divide.totAmt,
            storeDivideInfoList: divide.storeDivideInfoList,
            paymtCode: divide.paymtCode,
          });
        });
      } else if (this.divideFlag === "PROD") {
        this.prodDivideList.forEach((divide) => {
          let divideInfoObject = {
            chkinId: divide.chkinId,
            amtDivideFlag: divide.amtDivideFlag,
            prodInfoList: [],
            visitDivideFlag: divide.visitDivideFlag,
            visitId: divide.visitId,
            totAmt: divide.totAmt,
            storeDivideInfoList: divide.storeDivideInfoList,
            paymtCode: divide.paymtCode,
          };
          divide.prodInfoList.forEach((prod) => {
            divideInfoObject.prodInfoList.push({
              prodDivideFlag: prod.prodDivideFlag,
              productCode: prod.productCode,
              slipId: prod.slipId,
            });
          });
          divideByNObject.divideInfoList.push(divideInfoObject);
        });
      }

      // 1/N 분할 저장 API 호출
      try {
        await saveDivideList(divideByNObject);

        this.infoToast(this.$t("main.popupMessage.saved"));
        this.isSaved = true;
        // await this.getDivideListApi("ALL");
        // await this.getDivideListApi("PROD");
        this.closeDivideByNPopup();
      } catch (e) {
        console.error(e);
      }
    },
    closeDivideByNPopup() {
      this.$refs.divideByNPopup.hide();
    },
    onDivideByNPopupClosed() {
      if (this.isSaved) {
        this.$emit("popupClosed", { isReload: true });
      } else {
        this.$emit("popupClosed");
      }
    },
    async showDivideByNPopup(popupData) {
      this.popupData = popupData;

      await this.getDivideListApi("ALL", true);
      // await this.getDivideListApi("PROD", true);
    },
    /**
     * 분할정보 조회 API
     * @param isFirst 첫 번째 호출 여부. 만약 첫 번째 호출인 경우, openFlag 파라미터 값 넣기
     */
    async getDivideListApi(divideFlag, openFlag = false) {
      let paramData = {};

      if (divideFlag === "ALL") {
        paramData = {
          divideFlag: divideFlag,
          divideDiv: this.popupData.divideDiv,
          bsnDate: this.popupData.bsnDate,
          chkinId: this.popupData.chkinId,
          grpNo: this.popupData.divideDiv === "G" ? this.popupData.grpNo : null,
          divideUnit: this.divideUnit,
          storeCode: this.storeCode,
          visitIdList: this.getVisitIdList(
            this.allDivideList,
            "visitDivideFlag"
          ),
          amtVisitIdList: this.getVisitIdList(
            this.allDivideList,
            "amtDivideFlag"
          ),
          openFlag: openFlag,
        };
      } else if (divideFlag === "PROD") {
        let slipIdList = [];
        this.prodDivideList.forEach((divideInfo) => {
          divideInfo.prodInfoList.forEach((prodInfo) => {
            if (prodInfo.prodDivideFlag) {
              slipIdList.push(prodInfo.slipId);
            }
          });
        });

        paramData = {
          divideFlag: divideFlag,
          divideDiv: this.popupData.divideDiv,
          bsnDate: this.popupData.bsnDate,
          chkinId: this.popupData.chkinId,
          grpNo: this.popupData.divideDiv === "G" ? this.popupData.grpNo : null,
          divideUnit: this.divideUnit,
          storeCode: this.storeCode,
          visitIdList: this.getVisitIdList(
            this.prodDivideList,
            "visitDivideFlag"
          ),
          amtVisitIdList: this.getVisitIdList(
            this.prodDivideList,
            "amtDivideFlag"
          ),
          slipIdList: slipIdList,
          openFlag: openFlag,
        };
      }

      const response = await getDivideListByPut(divideFlag, paramData);

      if (divideFlag === "ALL") {
        this.allDivideList = _orderBy(response.value.allDivideList.map(item => ({
          ...item,
          courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE", item.startCourse),
        })), ["startTime", "courseCodeSortNo", "visitSno"]);
      } else if (divideFlag === "PROD") {
        this.prodDivideList = response.value.prodDivideList;
      }
    },
    /**
     * 특정 flag 값이 true 인 visitId 값을 가져온다
     * @param divideList 검색할 array list
     * @param fieldName 체크할 flag 필드 명. visitDivideFlag 또는 amtDivideFlag
     */
    getVisitIdList(divideList, fieldName) {
      return divideList
        .filter((divideInfo) => divideInfo[fieldName] === true)
        .map((trueDivideInfo) => trueDivideInfo.visitId);
    },
    /**
     * 분할 매장 전체 선택 체크박스 값이 바뀐 경우
     */
    async storeCodeCheckAllChecked(args) {
      if (args.target.checked) {
        this.storeCode = commonCodesGetCommonCode("STORE_GROUP").map(
          (code) => code.comCode
        );
      } else {
        this.storeCode = [];
      }
      await this.getDivideListApi(this.divideFlag);
    },
    async storeCodeCheckBoxChecked() {
      await this.getDivideListApi(this.divideFlag);
    },
    async divideUnitRadioChanged() {
      await this.getDivideListApi(this.divideFlag);
    },
    async divideTabSelected(args) {
      if (args.selectedIndex === 0) {
        this.divideFlag = "ALL";
        await this.getDivideListApi(this.divideFlag);
      } else if (args.selectedIndex === 1) {
        this.divideFlag = "PROD";
        await this.getDivideListApi(this.divideFlag);
      }
    },
    async visitIdCheckboxChanged() {
      await this.getDivideListApi(this.divideFlag);
    },
    async amtVisitIdCheckboxChanged(args, divideInfo) {
      if (divideInfo !== undefined) {
        divideInfo.prodInfoList.forEach((info) => {
          info.prodDivideFlag = args.target.checked;
        });
      }
      await this.getDivideListApi(this.divideFlag);
    },
    async visitsCheckAllChanged(args, divideList) {
      divideList.forEach((divide) => {
        if (args.target.checked) {
          divide.visitDivideFlag = true;
        } else {
          divide.visitDivideFlag = false;
        }
      });
      // api 재조회
      await this.getDivideListApi(this.divideFlag);
    },
    async amtsCheckAllChanged(args, divideList) {
      divideList.forEach((divide) => {
        divide.amtDivideFlag = args.target.checked;
        if (divide.prodInfoList !== undefined) {
          divide.prodInfoList.forEach((prod) => {
            prod.prodDivideFlag = args.target.checked;
          });
        }
      });
      // api 재조회
      await this.getDivideListApi(this.divideFlag);
    },
    async teamCheckAllChanged(args, divideList, divideInfo, isProd) {
      const teamList = divideList.filter(
        (divide) => divide.chkinId === divideInfo.chkinId
      );
      teamList.forEach((team) => {
        if (args.target.checked) {
          // team의 visitDivideFlag, amtDivideFlag true로 설정
          team.visitDivideFlag = true;
          team.amtDivideFlag = true;
        } else {
          // team의 visitDivideFlag, amtDivideFlag false로 설정
          team.visitDivideFlag = false;
          team.amtDivideFlag = false;
        }
      });

      // PROD 모드인 경우 상품 checkbox도 모두 처리
      if (isProd) {
        teamList.forEach((divideInfo) => {
          divideInfo.prodInfoList.forEach((prod) => {
            if (args.target.checked) {
              prod.prodDivideFlag = true;
            } else {
              prod.prodDivideFlag = false;
            }
          });
        });
      }

      // api 재조회
      await this.getDivideListApi(this.divideFlag);
    },
    async divideDivChanged() {
      // api 재조회
      await this.getDivideListApi(this.divideFlag);
    },
  },
};
</script>

<template>
  <div class="content-wrapper">
    <!-- REPORT -->
    <report-view ref="reportViewComponent" :isPopup="true"/>
    <!-- 필터영역 -->
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">발행일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range v-model="searchFilterOptions.dateRange"/>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">고객명</div>
            <ul class="content">
              <li class="item">
                <input-text ref="searchUser" v-model="searchFilterOptions.user" @keydown.enter="onClickSearch"/>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">연락처</div>
            <ul class="content">
              <li class="item">
                <input-text ref="searchTelNo" v-model="searchFilterOptions.telNo" @keydown.enter="onClickSearch"/>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">비고</div>
            <ul class="content">
              <li class="item">
                <input-text ref="searchRemarks" v-model="searchFilterOptions.remarks" @keydown.enter="onClickSearch"/>
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input type="checkbox" v-model="searchFilterOptions.useFlag"/>
                      <i></i>
                      <div class="label">사용완료</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input type="checkbox" v-model="searchFilterOptions.delFlag"/>
                      <i></i>
                      <div class="label">삭제여부</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button button-div="GET" :is-shortcut-button="true" :ignore="isPopupOpened" @click.native="onClickSearch">조회</erp-button>
        </div>
      </div>

    </div>
    <!-- 본문 -->
    <div class="content-body">
      <article class="body-article" :class="isOpen ? 'detail-view-opened' : ''">
        <!-- ######## 목록 리스트 ########-->
        <div class="article-left">
          <section class="article-section">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">{{gridField.title}}</div>
                <div class="header-caption">[ {{ gridField.count }} 건 ]</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="add">
                    <erp-button button-div="GET" :is-icon-custom="true" @click.native="onClickAdd">추가</erp-button>
                  </li>
                  <li class="print">
                    <erp-button button-div="FILE" @click.native="onClickExcel">Excel</erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <div class="body-grid">
                <ejs-grid-wrapper
                    ref="grid"
                    v-bind ="initGrid"
                    @recordClick="onGridClick"
                    @queryCellInfo="onQueryCellInfo"
                    @headerCellInfo="onHeaderCellInfo"
                    @actionComplete="gridComplete"
                />
              </div>
            </div>
          </section>
        </div>
        <!-- ######## 상세본문 ########-->
        <div class="article-right">
          <section class="article-section" >
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">연기권 상세</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="save keyColor">
                    <erp-button button-div="SAVE" :is-key-color="true" :is-shortcut-button="true" :ignore="!isOpen || isPopupOpened" @click.native="onClickSave">저장</erp-button>
                  </li>
                  <li class="close">
                    <erp-button button-div="CLOSE" @click.native="onClickClose">닫기</erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <div class="article-section" style="height: 180px;">
                <div class="section-body" style="border: none;">
                  <article class="article body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <div class="title">연기권 번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text ref="postponementId" id="postponementId" v-model="detailData.postponementId" :readonly="true"/>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">발행일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-date format="YYYY-MM-DD" ref="pubDate" id="pubDate"  v-model="detailData.pubDate" :isBindingEmptyString="true" type="body-data"/>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">고객명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input">
                                    <div class="form">
                                      <input-text ref="user" id="user" v-model="detailData.user"/>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>


                            <li class="field">
                              <div class="title">연락처</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <component-telephone ref="telNo" v-model=" detailData.telNo" :max-length="11" name="phoneNumber"/>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">발행금액</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number ref="amt" id="amt"   autocomplete="off" v-model="detailData.amt"/>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">남은금액</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number ref="remainAmt" id="remainAmt" autocomplete="off" v-model="detailData.remainAmt" :disabled="true"/>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">사용완료</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input
                                          type="checkbox" ref="useFlag" id="useFlag"  v-model="detailData.useFlag"/>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">삭제여부</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input
                                          type="checkbox" ref="delFlag" id="delFlag"  v-model="detailData.delFlag"/>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field remarks voc-section">
                              <div class="title">비고</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-textarea ref="remarks" name="remarks" v-model="detailData.remarks"/>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
              <div class="article-section" style="height:calc(100% - 180px)">
                <div class="section-body" style="border: none;">
                  <div class="article-section">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">사용내역</div>
                        <div class="header-caption">[ {{ gridField2.count }} 건 ]</div>
                      </div>
                      <div class="header-right">
                        <ul class="header-button">
                          <li class="add">
                            <erp-button button-div="SAVE" :is-icon-custom="true" @click.native="onClickAddRow">행추가</erp-button>
                          </li>
                          <li class="delete">
                            <erp-button button-div="DELETE" @click.native="onClickDelRow">행삭제</erp-button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="section-body" style="border: none;">
                      <div class="body-grid table-side">
                        <ejs-grid-wrapper
                            ref="grid2"
                            v-bind ="initGrid2"
                            @queryCellInfo="onQueryCellInfo2"
                            @headerCellInfo="onHeaderCellInfo2"
                            @actionComplete="gridComplete2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </article>
    </div>
  </div>
</template>

<script>

import commonMixin from "@/views/layout/mixin/commonMixin";
import ReportView from "@/components/common/report/ReportView.vue";
import ErpButton from "@/components/button/ErpButton.vue";
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {ExcelExport, Filter, ForeignKey, Page, Resize, Edit} from "@syncfusion/ej2-vue-grids";
import MessagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import InputText from "@/components/common/text/InputText.vue";
import InputDate from "@/components/common/datetime/InputDate.vue";
import InputTextarea from "@/components/common/text/InputTextarea.vue";
import InputDateRange from "@/components/common/datetime/InputDateRange.vue";
import moment from "moment/moment";
import { getFormattedDate} from "@/utils/date";
import {validateFormRefs} from "@/utils/formUtil";
import Postponement from "@/api/v2/front/Postponement";

import {mapGetters} from "vuex";
import ComponentTelephone from "@/components/ComponentTelephone.vue";
import InputNumber from "@/components/common/InputNumber.vue";
import {gridUtilGetTelColumnAccess} from "@/utils/gridUtil";

export default {
  name: "Postponement",
  mixins: [commonMixin, MessagePopupDialogMixin],
  components: {
    InputNumber,
    ComponentTelephone,
    ReportView,
    ErpButton,
    EjsGridWrapper,
    InputText,
    InputDate,
    InputTextarea,
    InputDateRange,
  },

  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      //신규,수정 여부
      isNew: false,
      //팝업 오픈 여부
      isOpen: false,

      //정합성 체크 항목
      validateRefField: {
        "pubDate": { required: true },
        "user": { required: true },
        "amt": { required: true },
      },
      //조회 필드
      searchFilterOptions: {
        user: this.$route.query.visitName||'',
        telNo: "",
        useFlag: false,
        delFlag: false,
        remarks: "",
        dateRange: {
          from: moment().startOf('year').format('2004-MM-DD'),
          to: moment().format('YYYY-MM-DD'),
        },
      },

      //그리드 필드
      gridField: {
        title: "연기권목록",
        count : 0,
        dataSource: [],
      },
      gridField2: {
        title: "사용내역",
        count : 0,
        dataSource: [],
      },

      //상세 데이터 필드
      detailData: {
        postponementId: null,
        pubDate: null,
        user: null,
        telNo: null,
        amt: 0,
        remainAmt: 0,
        useFlag: false,
        remarks: null,
        delFlag: false,
        dtlList: null,
        // 사용내역 저장용 리스트 구조체
        dtlListD: null,
        dtlListU: null,
        dtlListI: null,
      },
    };
  },

  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    ...mapGetters(["userIp","userId","username"]),

    // 팝업시 메인화면 버튼의 단축키 조정 , 팝업이 없으면 false 리턴
    isPopupOpened() {
      return (
          this.isShowSearchPopup || this.isShowSearchPopup2
      );
    },
    initGrid() {
      return {
        columns: [
          { field: "postponementId", textAlign: 'center', allowEditing: false, width: 70,   visible: true,  type: "string",  headerText: "연기권번호", isPrimaryKey: true, },
          { field: "pubDate",        textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "발행일자",
            valueAccessor: (field,data) => getFormattedDate(data[field]) },
          { field: "user",           textAlign: 'center', allowEditing: false, width: 200,  visible: true,  type: "string",  headerText: "고객명", },
          { field: "telNo",          textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "연락처",
            valueAccessor: (field, data) => gridUtilGetTelColumnAccess(field, data),},

          { field: "amt",            textAlign: 'right',  allowEditing: false, width: 100,  visible: true,  type: "number",  headerText: "발행금액",  isNumericType: true,  inputNumberProperty: {allowDot:true ,maxUnderDotLength: 3 },},
          { field: "remainAmt",      textAlign: 'right',  allowEditing: false, width: 100,  visible: true,  type: "number",  headerText: "남은금액",  isNumericType: true,  inputNumberProperty: {allowDot:true ,maxUnderDotLength: 3 },},
          { field: "remarks",        textAlign: 'left',   allowEditing: false, width: 300,  visible: true, type:  "string",  headerText: "비고", },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: true,
        pageSettings: { pageSize: 50 },
        resizeSettings: {mode:"Normal"},
      };
    },
    initGrid2() {
      return {
        columns: [
          { field: "dtlId",        textAlign: 'center', allowEditing: false, width: 30,  visible: false, type: "string",  headerText: "상세ID", isPrimaryKey: true,  },
          { field: "postponementId",     textAlign: 'center', allowEditing: false, width: 30,  visible: false, type: "string",  headerText: "연기권ID",  },
          { field: "useDate",       textAlign: 'center', allowEditing: true, width: 100,  visible: true, type: "string",  headerText: "사용일자",  isDateType: true,
            dateProperty: {
              format: "YYYY-MM-DD",
            },},
          { field: "user",   textAlign: 'left',   allowEditing: true, width: 100, visible: true,  type: "string",  headerText: "사용자", },
          { field: "useAmt", textAlign: 'right', allowEditing: true, width: 100, visible: true,  type: "number",  headerText: "사용금액",
            isNumericType: true,  inputNumberProperty: {allowDot:true ,maxUnderDotLength: 3 },},
          { field: "remarks",     textAlign: 'left',   allowEditing: true,  width: 400, visible: true,  type: "string",  headerText: "비고", },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField2.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:false,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        pageSettings: { pageSize: 50 },
        //그리드 정합성 체크
        validationRules: {
          useDate:  {required: true,},
          user: {required: true,},
          useAmt: {required: true,},
        },
      };
    },
  },
  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
    // 기초 데이터 초기화
    console.log(this.$route.query.visitName);
    this.clearDetailData();
  },
  async mounted() {
    //기초데이터 세팅
    await this.initData();
  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
    //필수입력체크
    validateFormRefs,
    //기초데이터 세팅
    async initData() {
      //달력 min,max 설정
    },
    //region ******************************* 버튼 이벤트 *******************************
    //조회
    async onClickSearch() {
      // await this.onClickClose();

      const args = {
        user: this.searchFilterOptions.user,
        telNo: this.searchFilterOptions.telNo,
        remarks: this.searchFilterOptions.remarks,
        useFlag: this.searchFilterOptions.useFlag==true?1:0,
        delFlag: this.searchFilterOptions.delFlag==true?1:0,
        fromDate: this.searchFilterOptions.dateRange.from,
        toDate: this.searchFilterOptions.dateRange.to,
      };

      this.gridField.dataSource = await Postponement.getPostponement(args);
    },
    //추가
    onClickAdd() {
      this.isOpen = true;
      this.isNew = true;
      this.clearDetailData();
    },
    //저장
    async onClickSave() {
      //1.필수입력체크
      if (!this.validateFormRefs(this.validateRefField)) {
        return;
      }

      //2. 그리드 필수 입력체크
      if (this.$refs.grid2.isGridModified() && !this.$refs.grid2.validate()) {
        return;
      }

      //3. 저장처리
      const {addedRecords, changedRecords, deletedRecords,} = this.$refs.grid2.getBatchChanges();
      this.detailData.dtlListD = deletedRecords;
      this.detailData.dtlListU = changedRecords;
      this.detailData.dtlListI = addedRecords;

      if (this.isNew) {
        await Postponement.patchPostponement(this.detailData);
      } else {
        await Postponement.patchPostponement(this.detailData);
      }

      //4. 메세지 호출
      this.infoToast(this.$t('main.popupMessage.saved'));
      await this.onClickSearch();
    },
    //삭제
    async onClickDelete() {
      //1. 확인
      if (!(await this.confirm(this.$t('main.popupMessage.confirmDelete')))) {
        return;
      }

      //2. 삭제처리
      const args = {deleteKey:this.detailData.postponementId};
      await Postponement.deletePostponement(args);

      //3. 메세지 호출
      this.infoToast(this.$t('main.popupMessage.deleted'));
      await this.onClickSearch();
    },
    //엑셀 출력
    onClickExcel() {
      this.$refs.grid.excelExport({fileName: this.gridField.title+".xlsx"});
    },
    //닫기
    async onClickClose() {
      this.isOpen = false;
      await this.clearDetailData();
    },
    //리포트 출력
    onClickPrint() {
      //
    },
    //endregion
    //region ******************************* 상세 이벤트 *******************************
    //수리 상세 조회
    async searchDetail(code) {
      const args = {postponementId:code};
      this.detailData = await Postponement.getPostponementDtl(args);
      this.gridField2.dataSource = this.detailData.dtlList;
    },
    onClickAddRow() {
      this.$refs.grid2.addRecord({
        dtlId: null,
        postponementId: this.detailData.postponementId,
        useDate: getFormattedDate(new Date()),
        remarks: ""
      });
    },
    onClickDelRow() {
      this.$refs.grid2.deleteRecord();
      //ROW- COUNT
      this.gridField2.count = this.$refs.grid2.getBatchCurrentViewRecords().length;
    },
    //endregion
    //region ******************************* 공통 이벤트 *******************************
    //기초 데이터 초기화
    async clearDetailData() {
      this.detailData.postponementId = null;
      this.detailData.pubDate = getFormattedDate(new Date());
      this.detailData.user = null;
      this.detailData.telNo = null;
      this.detailData.amt = null;
      this.detailData.remarks = null;
      this.detailData.remainAmt = null;
      this.detailData.useFlag = null;
      this.detailData.delFlag = false;
      this.detailData.dtlListD = [];
      this.detailData.dtlListU = [];
      this.detailData.dtlListI = [];
      this.gridField2.dataSource = [];
    },
    //장비 검색 팝업
    onClickPopupSearch() {
      this.isShowSearchPopup = true;
    },
    //장비 검색 팝업 완료
    onPopupConfirmed(args) {
      this.isShowSearchPopup = false;
      if(args != null){
        this.detailData.eqpCode = args.eqpCode;
        this.detailData.eqpName = args.eqpName;
        this.detailData.eqpDiv = args.eqpDiv;
      }
    },


    //endregion
    //region ******************************* 그리드 이벤트 *******************************
    //그리드 헤더 이미지적용
    onHeaderCellInfo(args){
      const {cell: {column:{field}}, node} = args;
      if(this.$refs.grid.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    onHeaderCellInfo2(args){
      const {cell: {column:{field}}, node} = args;
      if(this.$refs.grid2.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },

    //그리드 셀 이미지 적용
    onQueryCellInfo(args){
      const {column:{field,allowEditing}, cell} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },
    onQueryCellInfo2(args){
      const {column:{field,allowEditing}, cell} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },

    //그리드 셀 클릭
    async onGridClick(args){
      //const {column:{field}, rowData} = args;
      //if(field === "eqpName"){
      this.isOpen = true;
      this.isNew = false;

      //상세 clear
      await this.clearDetailData();
      //상세 표시
      await this.searchDetail(args.rowData.postponementId);
    },
    //그리드 조회 완료시(카운트)
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    gridComplete2(){
      this.gridField2.count = this.$refs.grid2.getGridBatchCount();
    },
    //endregion
  }
};
</script>

<!-- ##################################### CUSTOM STYLE ########################################* -->
<style scoped>
body .appContent .body-article .article-left,body .appContent .body-article .article-right{transition:all .3s ease-out}
body .appContent .body-article .article-left{width:100%;}
body .appContent .body-article .article-right{margin-right: calc(-50% - 0px)}
body .appContent .body-article.detail-view-opened .article-left{width:calc(50% - 0px)}
body .appContent .body-article.detail-view-opened .article-right{width:calc(50% - 0px); margin:0}
body .body-footer .section-header {display: block;position: relative;margin: 0px 0px;}
.voc-section {width: 100% !important;}
</style>

<template>
  <div>
    <ejs-dialog
      ref="memberSelectPopupDialog"
      header="회원 검색"
      :animationSettings="animationSettings"
      :allowDragging="true"
      :showCloseIcon="true"
      width="1400"
      height="800"
      :close="dialogClose"
      :position="{ x: 'center' }"
      :enableResize="true"
      :isModal="isModal"
    >
      <div class="window memberSearch">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field memberNameNumber">
                    <div class="title">회원명</div>
                    <ul class="content">
                      <li class="item">
                        <input-text
                          ref="searchMemberName"
                          id="searchMemberName"
                          v-model="searchConditions.memberNameNo"
                          @keydown.enter="onSearchMemberNameKeyDownEnter"
                        />
                      </li>
                      <li class="item">
                        <input-text
                          ref="searchCprName"
                          id="searchCprName"
                          v-model="searchConditions.cprName"
                          placeHolder="법인명"
                          @keydown.enter="onSearchMemberNameKeyDownEnter"
                        />
                      </li>
                      <li class="item">
                        <input-text
                          ref="searchContactTelIdx"
                          id="searchContactTelIdx"
                          v-model="searchConditions.contactTelIdx"
                          placeHolder="핸드폰 뒷 4자리"
                          @keydown="onSearchMemberNameKeyDown"
                        />
                      </li>
                      <li class="item" v-if="popupData.isTaker">
                        <ul class="check">
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                id="outFlag"
                                v-model="searchConditions.outFlag"
                              />
                              <i></i>
                              <div class="label">탈회회원 포함</div>
                            </label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      button-div="GET"
                      :is-shortcut-button="true"
                     v-on:click.native="onViewButtonClicked"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <!-- 활성 : active -->
              <article
                class="body-article"
                :class="memberLinkCardFlag ? 'active' : ''"
              >
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <ejs-grid-wrapper
                      ref="memberSelectGrid"
                      id="memberSelectGrid"
                      :provides="memberSelectGridProvides"
                      :columns="memberSelectGridColumns"
                      :isInPopup="true"
                      :allowPaging="true"
                      :noColumnWidth="40"
                      :dataSource="memberList"
                      :editSettings="memberSelectGridEditSettings"
                      :pageSettings="memberSelectGridPageSettings"
                      @keyPressed="onMemberPopupKeyPressed"
                      @rowSelected="memberGridRecordClicked"
                      @queryCellInfo="memberGridQueryCellInfo"
                      @onGridDialogDoubleClickedOrEnterKeyed="onMemberGridDialogDoubleClickedOrEnterKeyed"
                      @dataBound="onMemberSelectGridDataBound"
                      @headerCellInfo="onHeaderCellInfo"
                      @recordClick="onRecordClick"
                    />
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-02">
                  <div class="layer-button" @click="memberLinkCardFlag = !memberLinkCardFlag"/>
                  <div class="section-body">
                    <article class="body-article">
                      <div class="article-left">
                        <section class="article-section section-0201">
                          <div class="section-body">
                            <div class="body-card">
                              <ul
                                class="box"
                                :style="{ width: memberLinkCardWidth + 'px' }"
                              >
                                <!-- 활성 : active -->
                                <li
                                  class="card"
                                  v-bind:class="{
                                    active: selectedRowData.memberId == member.memberId,
                                  }"
                                  v-for="member in memberLinkList"
                                  :key="member.memberId"
                                  v-on:click="memberLinkClicked(member)"
                                >
                                  <div class="photo">
                                    <div
                                      v-if="member.profileImagePath != null"
                                      class="item"
                                      :style="{
                                        backgroundImage:
                                          'url(' + member.profileImagePath + ')',
                                      }"
                                    ></div>
                                  </div>
                                  <ul class="content">
                                    <li class="item">
                                      <strong>{{ member.memberName }}</strong
                                      >({{ member.memberNo }})
                                    </li>
                                    <li class="item">
                                      {{
                                        memberListOptions.memberDivOptions.find(
                                          (code) => code.comCode === member.memberDiv
                                        ).comName
                                      }}/{{
                                        memberListOptions.cprDivOptions.find(
                                          (code) => code.comCode === member.cprDiv
                                        ).comName
                                      }}
                                    </li>
                                    <li class="item">
                                      {{ member.cprName }}
                                    </li>
                                    <li class="item">
                                      {{ getFormattedPhoneNumber(member.hpNo) }}
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </section>
                      </div>
                      <div class="article-right">
                        <div class="section-caption">
                          <div class="caption-navigation">
                            <ejs-tab
                                ref="reservationStatusDivTap"
                                :items="memberStatusDivTapList"
                                :showCloseButton="false"
                                heightAdjustMode="Auto"
                                overflowMode="Popup"
                                @selected="tabSelected"
                            />
                          </div>
                        </div>
                        <section class="article-section section-0202">
                          <div v-show="statusDiv === 'BENEFIT'">
                            <div class="section-body " style="padding-top: 9px">
                            {{ benContents }}
                            </div>
                          </div>
                          <div  v-show="statusDiv === 'PENLTY'">
                            <div  v-if="gridPenltyField.minPunishStartDate && gridPenltyField.maxPunishEndDate" class="section-body">
                                <div style="color:#ff0000;padding-top: 2px">
                                  제재기간 : {{ gridPenltyField.minPunishStartDate }} ~ {{ gridPenltyField.maxPunishEndDate }}
                                </div>
                                <div class="body-grid" style="height: 96px">
                                  <ejs-grid-wrapper
                                     ref="penltyGrid"
                                      v-bind ="initPenltyGrid"
                                  />
                                </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </article>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  v-on:click.native="onConfirmClicked">
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  v-on:click.native="onCloseClicked">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { v4 as createUUID } from "uuid";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {
  commonCodesGetColorValue,
  commonCodesGetCommonCode,
  commonCodesGetStandardInfo,
  // commonCodesGetCommonCodeAttrbByCodeAndIdx
} from '@/utils/commonCodes';
import { getMemberList } from "@/api/member";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {Resize, ForeignKey, Page, ExcelExport, Filter, Edit} from "@syncfusion/ej2-vue-grids";
import {
  gridUtilGetMemberNoColumnAccess,
  gridUtilGetTelColumnAccess,
} from "@/utils/gridUtil";
import { getFormattedPhoneNumber } from "@/utils/string";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton.vue";
import GolfERPService from '@/service/GolfERPService';
import sortBy from "lodash/sortBy";
import cloneDeep from "lodash/cloneDeep";
import {mapMutations} from "vuex";
import {getBlacklist} from "@/api/blacklist";
import {orderBy as _orderBy} from "lodash";
import {DATE_FORMAT_YYYY_MM_DD} from "@/utils/date";
import {addShortcut, keypressEventEqualsShortcut, removeShortcut, SHORTCUT_KEYS} from "@/utils/KeyboardUtil";

const SHORTCUT_INS = {
  key: "MemberSelectPopup.shortcuts.ins",
  shortcut: {
    ctrlKey: false,
    altKey: false,
    shiftKey: false,
    key: SHORTCUT_KEYS.Insert,
  },
};

export default {
  name: "memberSelectPopup",
  mixins: [confirmDialogMixin],
  props: ["isModal"],
  components: {
    InputText,
    ejsGridWrapper,
    ErpButton,
  },
  async created() {
    addShortcut(SHORTCUT_INS.key, SHORTCUT_INS.shortcut);
    window.addEventListener("keydown", this.onWindowKeypress, true);

    // 개인/법인구분
    this.memberListOptions.cprDivOptions = commonCodesGetCommonCode("CPR_DIV");
    // 회원구분
    this.memberListOptions.memberDivOptions = commonCodesGetCommonCode(
      "MEMBER_DIV"
    );
    await this.initialize();
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.onWindowKeypress, true);
    removeShortcut(SHORTCUT_INS.key);
  },
  data() {
    return {
      benContents: null,
      popupData: {},
      selectedRowData: {},
      searchConditions: {
        memberNameNo: null, // 회원명/회원코드
        contactTelIdx: null,
        cprName: null,
        memberFlag: true,
        outFlag: false,
        bsnDate: null,
      },
      memberListOptions: {
        cprDivOptions: [],
        memberDivOptions: [],
      },
      //그리드 관련
      gridPenltyField: {
        title: "위약 목록",
        count : 0,
        dataSource: [],
        minPunishStartDate: null,
        maxPunishEndDate: null,
      },
      memberStatusDivTapList: [
        {
          header: {text: '특전 내용'},
          statusDiv: 'BENEFIT',
        },
        {
          header: {text: '위약 목록' },
          statusDiv: 'PENLTY',
        },
      ],
      statusDiv: 'BENEFIT',
      animationSettings: { effect: "None" },
      classCodeFields: { text: "className", value: "classCode" },
      memberSelectGridPageSettings: { pageSize: 100 },
      boolParams: { params: { checked: true } },
      isInit: true,
      memberList: [],
      memberLinkList: [],
      memberLinkCardFlag: commonCodesGetStandardInfo('courseClass') === 'R',
      memberLinkCardWidth: 223,
      memberSelectGridProvides: [Resize, ForeignKey, Page],
      memberSelectGridColumns: [
        {
          field: "membershipId",
          visible: false,
        },
        {
          field: "_rid",
          isPrimaryKey: true,
          visible: false,
        },
        {
          field: "memberName",
          headerText: "회원명",
          type: "string",
          minWidth: 16,
          width: 90,
        },
        {
          field: "memberNo",
          headerText: "회원번호",
          type: "string",
          minWidth: 16,
          width: 90,
          textAlign: "Center",
          valueAccessor: (field, data) =>
              gridUtilGetMemberNoColumnAccess(field, data),
        },
        {
          field: "sexCode",
          headerText: "성별",
          type: "string",
          minWidth: 16,
          width: 60,
          textAlign: "Center",
          isCommonCodeField: true,
          groupCode: "SEX_CODE",
        },
        {
          field: "memberDiv",
          headerText: "회원구분",
          type: "string",
          minWidth: 16,
          width: 90,
          isCommonCodeField: true,
          groupCode: "MEMBER_DIV",
        },
        {
          field: "memberGrade",
          headerText: "회원등급",
          type: "string",
          minWidth: 16,
          width: 90,
          isCommonCodeField: true,
          groupCode: "MEMBER_GRADE",
        },
        {
          field: "birthDay",
          headerText: "생년월일",
          type: "string",
          minWidth: 16,
          width: 90,
          isDateType: true,
        },
        {
          field: "hpNo",
          headerText: "핸드폰번호",
          type: "string",
          minWidth: 16,
          width: 100,
          textAlign: "Center",
          valueAccessor: (field, data) =>
            gridUtilGetTelColumnAccess(field, data),
        },
        {
          field: "vocCount",
          headerText: "VOC",
          type: "string",
          minWidth: 16,
          width: 60,
          textAlign: "Center",
        },
        {
          field: "cprDiv",
          headerText: "개인/법인",
          type: "string",
          isCommonCodeField: true,
          groupCode: "CPR_DIV",
          minWidth: 16,
          width: 90,
        },
        {
          field: "cprName",
          headerText: "법인명",
          type: "string",
          minWidth: 16,
          width: 120,
        },
        {
          field: "grpName",
          headerText: "단체명",
          type: "string",
          minWidth: 16,
          width: 120,
        },
        {
          field: "grpPosition",
          headerText: "단체직위",
          type: "string",
          minWidth: 16,
          width: 120,
        },
        {
          field: "wrkplc",
          headerText: "근무처",
          type: "string",
          minWidth: 16,
          width: 180,
        },
        {
          field: "entryOrder",
          headerText: "가입",
          type: "string",
          minWidth: 16,
          width: 60,
          isCommonCodeField: true,
          groupCode: "ENTRY_ORDER",
        },
        {
          field: "benContents",
          headerText: "특전내용",
          type: "string",
          minWidth: 16,
          width: 120,
        },
        {
          field: "benResve",
          headerText: "예약비고",
          type: "string",
          minWidth: 16,
          width: 240,
        },
        {
          field: "memberDivAbrv",
          headerText: "구분",
          type: "string",
          minWidth: 16,
          width: 65,
        },
        {
          field: "nearbyCode",
          headerText: "지역민구분",
          type: "string",
          minWidth: 16,
          width: 90,
          isCommonCodeField: true,
          groupCode: "NEARBY_CODE",
        },
        {
          field: "acqsDate",
          headerText: "취득일자",
          type: "string",
          minWidth: 16,
          width: 90,
          textAlign: "Center",
          isDateType: true,
        },
        {
          field: "expireDate",
          headerText: "만기일자",
          type: "string",
          minWidth: 16,
          width: 90,
          textAlign: "Center",
          isDateType: true,
        },
        {
          field: "membershipStatus",
          headerText: "회원 상태",
          type: "string",
          minWidth: 16,
          width: 110,
          isCommonCodeField: true,
          groupCode: "MEMBERSHIP_STATUS",
        },
        {
          field: "groupCompanyCode",
          headerText: "그룹사",
          type: "string",
          minWidth: 16,
          width: 120,
          isCommonCodeField: true,
          groupCode: "GROUP_COMPANY_CODE",
        },
        {
          field: "levelName",
          headerText: "직급",
          type: "string",
          minWidth: 16,
          width: 90,
        },
        // {
        //   field: "acqsAmt",
        //   headerText: "취득금액",
        //   type: "number",
        //   format: "N",
        //   minWidth: 16,
        //   width: 90,
        //   textAlign: "Right",
        // },
        // {
        //   field: "payAmt",
        //   headerText: "입금액",
        //   type: "number",
        //   format: "N",
        //   minWidth: 16,
        //   width: 90,
        //   textAlign: "Right",
        // },
        // {
        //   field: "unpayAmt",
        //   headerText: "잔금",
        //   type: "number",
        //   format: "N",
        //   minWidth: 16,
        //   width: 90,
        //   textAlign: "Right",
        // },
        {
          field: "rwrtDate",
          headerText: "개서일자",
          type: "string",
          minWidth: 16,
          width: 90,
          textAlign: "Center",
          isDateType: true,
        },
      ],
      memberSelectGridEditSettings: {
        allowEditing: false,
        allowAdding: false,
        allowDeleting: false,
        mode: "Batch",
        showConfirmDialog: false,
      },
      memberLength: 0,
      memberNo: "",
      now: null,
      isLoading: false,

    };
  },
  computed:{
    //그리드 해더 세팅
    initPenltyGrid(){
      return{
        columns : [
          {field: "occurDate",       headerText: "발생일자",      width: 90,  allowEditing : false, textAlign: "center",},
          {field: "memberName",      headerText: "위약자명",      width: 90,  allowEditing : false,},
          {field: "memberNo",        headerText: "회원번호",      width: 90,  allowEditing : false,},
          {field: "punshStartDate",  headerText: "제재 시작일",   width: 90,  allowEditing : false, textAlign: "center",},
          {field: "punshEndDate",    headerText: "제재 종료일",   width: 90,  allowEditing : false, textAlign: "center",},
          {field: "bsnCode",         headerText: "영업구분",      width: 90,  allowEditing : false, textAlign: "center", isCommonCodeField: true,groupCode: "BSN_CODE", valueAccessor: this.valueAccessor,},
          {field: "penltyCode",      headerText: "위약코드",      width: 120, allowEditing : false, isCommonCodeField: true,groupCode: "PENLTY_CODE", valueAccessor: this.valueAccessor,},
          {field: "penltyResn",      headerText: "위약사유",      width: 120, allowEditing : false,},
          {field: "penltyLmttScope", headerText: "위약제한 구분", width: 120, allowEditing : false, isCommonCodeField: true,groupCode: "PENLTY_LMTT_SCOPE", valueAccessor: this.valueAccessor,},
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridPenltyField.dataSource,
        allowResizing: true,
        allowSorting: false, // 정렬
        allowFiltering: false, //필터
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:true,
        noColumnWidth: 35,
        isSelectedRowRetain: false,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false, //페이징
        pageSettings: { pageSize: 50 },
      };
    },
  },
  methods: {
    ...mapMutations([
      'SET_IS_RIGHT_VOC_ACTIVE',
      'SET_IS_LEFT_MENU_ACTIVE'
    ]),
    getFormattedPhoneNumber,
    async showPopup(popupData = {}) {
      const { nomDataViewFlag, memberNameNo, contactTelIdx, cprName, bsnDate, outFlag } = popupData;
      if(nomDataViewFlag === undefined || nomDataViewFlag === null) {
        popupData.nomDataViewFlag = true;
      }

      this.popupData = cloneDeep(popupData);

      memberNameNo && (this.searchConditions.memberNameNo = memberNameNo);
      contactTelIdx && (this.searchConditions.contactTelIdx = contactTelIdx);
      cprName && (this.searchConditions.cprName = cprName);
      bsnDate && (this.searchConditions.bsnDate = bsnDate);
      outFlag && (this.searchConditions.outFlag = outFlag);

      await this.setMemberList();
      this.$refs.memberSelectPopupDialog.show();
    },
    async initialize() {
      this.isLoading = true;
      try {
        this.now = await GolfERPService.fetchNow();
      } finally {
        this.isLoading = false;
      }
    },
    async tabSelected(args) {
      this.statusDiv = this.memberStatusDivTapList[args.selectedIndex].statusDiv;
    },


    memberLinkClicked(args) {
      this.selectedRowData = args;
    },
    async memberGridRecordClicked(args) {
      //위약목록 있을경우... 위약탭으로 이동
      if(args.data.penltyCount > 0){
        this.$refs.reservationStatusDivTap.select(1);
      }else{
        this.$refs.reservationStatusDivTap.select(0);
      }
      //위약리스트 초기화
      if(args.previousRowIndex !== args.rowIndex) {
        this.gridPenltyField.dataSource = [];
        this.gridPenltyField.minPunishStartDate = "";
        this.gridPenltyField.maxPunishEndDate = "";
      }
      //위약목록 조회
      if((args.data.penltyCount > 0 && args.previousRowIndex !== args.rowIndex) ) { //|| this.$refs["memberSelectGrid"].getSelectedRecords().length ===1
        await this.getBlackList(args);
      }
      if (!args.data.membershipId) {
        this.selectedRowData = args.data;
        this.memberLinkList = [];
        this.memberLinkCardWidth = 223;
        this.benContents = null;
        // this.memberLinkCardFlag = false;
        return;
      }
      //기본 특약 탭
      this.selectedRowData = args.data;
      this.benContents = args.data.benContents;
      await this.setMemberLinkList(this.selectedRowData.linkMemberId);
      // this.memberLinkCardFlag = true;
    },
    //위약목록 갖고오기...
    async getBlackList(args) {
      const {value} = await getBlacklist({
        membershipId: args.data.membershipId,
        linkMemberSelect: false,
        isNowDate: true,
        isPenltyLmttScopeNa: false,
      });
      this.gridPenltyField.dataSource = _orderBy(value?.blacklistList, "occurDate", ["desc"]);
      this.gridPenltyField.minPunishStartDate = moment().format(DATE_FORMAT_YYYY_MM_DD);
      this.gridPenltyField.maxPunishEndDate = value.blacklistList.reduce(
          (max, blackList) => {
            if (blackList.punshEndDate) {
              if (max) {
                return blackList.punshEndDate > max
                    ? blackList.punshEndDate
                    : max;
              } else {
                return blackList.punshEndDate;
              }
            } else {
              return max;
            }
          },
          value.blacklistList[0].punshEndDate
      );
    },
    onHeaderCellInfo(args) {
      const {
        cell: {
          column:{field}
        },
        node
      } = args;
      if(field === "vocCount"){
        node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    memberGridQueryCellInfo(args) {
      const {
        column: {field},
        cell,
        data,
      } = args;
      if(field === 'vocCount'){
        if(data.vocCount>0){
          cell.innerText = data.vocCount + "건";
          cell.classList.add(this.$t("className.grid.clickArea"));
        }else {
          cell.innerText = "-";
        }
      }
      if (field === 'nearbyCode') {
        cell.style.color = commonCodesGetColorValue("NEARBY_CODE", data.nearbyCode);
      } else if (field === 'expireDate' && data.nearbyCode && data.expireDate) {
        const diff = moment(data.expireDate).diff(this.now, 'days');
        if (diff <= 0) {
          cell.style.color = 'red';
        }
      } else if (field === "memberDivAbrv" && data.memberDivAbrv === "만기") {
        cell.style.color = "red";
        cell.style.fontWeight = "bold";
      }
      if (field === "memberName" && data.penltyCount > 0) {
        cell.style.background = "red";
        cell.style.color = "white";
        cell.style.fontWeight = "bold";
      }
    },
    async onRecordClick(args) {
      const {
        column: {field},
        rowData,
      } = args;
      if(field === "vocCount" && rowData.vocCount > 0){
        
        await this.SET_IS_LEFT_MENU_ACTIVE(false);
        await this.SET_IS_RIGHT_VOC_ACTIVE(true);
        await this.$EventBus.$emit('vocButtonClick');
        this.$nextTick(() => {
          this.$EventBus.$emit("VOC",{
            id:rowData.membershipId,
            memberName:rowData.memberName
          });
        });
      }
    },
    onMemberGridDialogDoubleClickedOrEnterKeyed() {
      this.onConfirmClicked();
    },
    async onViewButtonClicked() {
      // this.memberLinkCardFlag = false;
      await this.setMemberList();
    },
    async setMemberLinkList(linkMemberId) {
      try {
        const data = await GolfErpAPI.fetchLinkMembership(linkMemberId, true);

        // V2 데이터이지만, 기존 팝업 사용과 포맷을 맞추기 위하여 data를 맞춰 준다
        this.memberLinkList = data.map((memberLink) => ({
          membershipId: memberLink.id,
          memberId: memberLink.memberId,
          memberName: memberLink.member?.name,
          memberNo: memberLink.memberNumber,
          sexCode: memberLink.member?.gender,
          memberDiv: memberLink.memberDivision,
          memberGrade: memberLink.memberGrade,
          hpNo: memberLink.member?.phoneNumber,
          nativeDiv: memberLink.member?.details?.nativeDivision,
          areaCode: memberLink.member?.details?.areaCode,
          webId: memberLink.member?.webIdInformation?.id,
          acqsDate: memberLink.acquireDate,
          profileImagePath: memberLink.member?.imageFile?.path,
          birthDay: memberLink.member?.birthday,
          cprDiv: memberLink.corporateDivision,
          cprName: memberLink.corporationName,
          linkMemberId: memberLink.linkId,
          homePostNo: memberLink.member?.details?.homePostNumber,
          homeAddr1: memberLink.member?.details?.homeAddress,
          homeAddr2: memberLink.member?.details?.homeAdditionalAddress,
          companyPostNo: memberLink.member?.details?.companyPostNumber,
          companyAddr1: memberLink.member?.details?.companyAddress,
          companyAddr2: memberLink.member?.details?.companyAdditionalAddress,
        }));
        this.memberLinkCardWidth = 223 * data.length;
      } catch (e) {
        console.log("getMemberLinkList.err.===>");
      }
    },
    async setMemberList() {
      const { memberNameNo, contactTelIdx, cprName } = this.searchConditions;
      if (!memberNameNo && !contactTelIdx && !cprName) { return; }
      this.selectedRowData = {}; // 검색 시 선택 데이터 초기화

      const response = await getMemberList(this.searchConditions);
      const addUUID = member => ({ ...member, _rid: createUUID() });
      const sortedByMemberName = members => sortBy(members, "memberName");

      this.memberList = response.value.memberList;
      !this.memberList.length && (this.memberLinkList.length = 0);

      if (this.popupData.isGiver) {
        // const isUserChange = this.popupData.renameDiv === "USER";
        // const renameDivOption = isUserChange ? ["G2", "G3"] : ["G1"];
        const renameDivOption = ["G1", "G2", "G3"];
        this.memberList = this.memberList.filter((member) => {
          return renameDivOption.includes(member.memberDivAttrb);
              // && member.memberGrade !== "WEB"
              // && member.memberGrade !== "NOR";
        });
      }

      if (this.popupData.nomDataViewFlag) {
        const initialMember = {
          memberName: this.searchConditions.memberNameNo || "비회원",
          cprDiv: "FIT",
          sexCode: "M",
          memberDiv: "NOM",
          memberGrade: "NOR",
        };
        this.memberList = sortedByMemberName([initialMember, ...this.memberList]).map(addUUID);
      } else {
        this.memberList = sortedByMemberName(this.memberList.map(addUUID));
      }

      // if (this.popupData.nomDataViewFlag) {
      //   const initialMember = {
      //     memberName: this.searchConditions.memberNameNo || "비회원",
      //     cprDiv: "FIT",
      //     sexCode: "M",
      //     memberDiv: "NOM",
      //     memberGrade: "NOR",
      //   };
      //   const sortedMembers = sortBy(response.value.memberList, "memberName");
      //   this.memberList = [initialMember, ...sortedMembers].map(member => ({ ...member, _rid: createUUID() }));
      //
      //   } else {
      //     this.memberList = sortBy(response.value.memberList.map((member) => ({
      //       ...member,
      //       _rid: createUUID(),
      //     })), "memberName");
      //   }



      this.memberLength = this.memberList.length;
      this.isInit = false;
    },
    dialogClose() {
      this.$emit("popupClosed", { popupData: this.popupData });
    },
    onConfirmClicked() {
      const isRowSelected = 0 < this.$refs["memberSelectGrid"].getSelectedRecords().length;

      if (isRowSelected) {
        let selectedRowData = this.selectedRowData;
        if (selectedRowData?.memberDiv) {
          if (
            !commonCodesGetCommonCode("MEMBER_DIV")
              .map(({ comCode }) => comCode)
              .includes(selectedRowData.memberDiv)
          ) {
            selectedRowData.memberDiv = null; // commonCodeGetComCodeDefaultValue("MEMBER_DIV");
          }
        }
        if (selectedRowData?.memberGrade) {
          if (
            !commonCodesGetCommonCode("MEMBER_GRADE")
              .map(({ comCode }) => comCode)
              .includes(selectedRowData.memberGrade)
          ) {
            selectedRowData.memberGrade = null; // commonCodeGetComCodeDefaultValue("MEMBER_GRADE");
          }
        }
        selectedRowData.memberDiv === 'NOM' && (selectedRowData.lunarCode = 'SOLAR');
        this.$emit("popupConfirmed", {
          popupData: this.popupData,
          selectedRowData,
        });
        // this.$refs.memberSelectPopupDialog.hide();
      } else {
        this.errorToast("회원을 선택해 주십시오");
      }
    },
    onCloseClicked() {
      this.$refs.memberSelectPopupDialog.hide();
    },
    async onSearchMemberNameKeyDownEnter() {
      await this.setMemberList();
    },
    async onSearchMemberNameKeyDown(e) {
      if(e.key === "ArrowDown"){
        setTimeout(() => {
          this.$refs.memberSelectGrid.selectRow(0);
        }, 1);
      } else if(e.key === "Enter") {
        await this.setMemberList();
      }
    },
    onMemberSelectGridDataBound() {
      if (this.memberList.length > 0) {
        if(this.searchConditions.contactTelIdx) {
          this.$refs.memberSelectGrid.selectRow(1);
        } else {
          this.$refs.memberSelectGrid.selectRow(0);
        }
      } else {
        document.getElementById("searchMemberName").select();
      }
    },
    onMemberPopupKeyPressed(args) {
      if(args.key === "ArrowUp" && this.$refs.memberSelectGrid.getSelectedRowIndexes()[0] === 0){
        setTimeout(() => {
          this.$refs.searchContactTelIdx.focus();
        }, 1);
      }
    },
    onWindowKeypress(event) {
      if (keypressEventEqualsShortcut(event, SHORTCUT_INS.shortcut)) {
        this.$refs.searchContactTelIdx.focus();
      }
    },
  },
};
</script>

<style scoped>
body .body-article .article-section.section-02 {position: relative}
body .body-article .article-section.section-02 .layer-button {display: block;position: absolute;top: -7px;left: calc(50% - 16.5px);overflow: hidden;box-shadow: none;width: 33px;height: 17px;padding: 0;border: none;background: transparent url(../../assets/images/common/layer-button.png) no-repeat center -17px;cursor: pointer;text-indent: -1000px}
body .body-article:not(.active) .article-section.section-02 .layer-button {background-position:center 0}
body .body-article:not(.active) .article-section.section-02 .body-article {display: none;}

body .body-article .article-section.section-02 .section-body {height: 100%;}
body .body-article .article-section.section-02 .section-body .body-article {padding: 0 0 0 0;}
body .body-article .article-section.section-02 .section-body .body-article .article-left {width: 684px;}
body .body-article .article-section.section-02 .section-body .body-article .article-right {width: calc(100% - 684px);}
body .body-article .article-section.section-02 .section-body .body-article .article-left .article-section.section-0201,
body .body-article .article-section.section-02 .section-body .body-article .article-right .article-section.section-0202 {padding: 0 0 0 0;}
body .body-article .article-section.section-02 .section-body .body-article .article-right .article-section.section-0202 .section-body {
  white-space: pre-wrap;
  border: 2px solid #1f5b41;
  /*border-left: none;*/
  height: 121px;
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
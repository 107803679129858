<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="reIssueReceiptPopup"
      header="영수증 재발행"
      allowDragging="true"
      showCloseIcon="true"
      isModal="true"
      @close="onReIssueReceiptPopupClosed"
      width="360"
      height="600"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section
                    class="article-section"
                    v-if="payDiv === 'POINT'"
                >
                  <div class="section-body" style="border-top-color: #000">
                    <div class="body-box" style="padding: 18px; color: #000">
                      <div
                          style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 0 0 8px 0;
                        "
                      >
                        <div
                            style="
                            font-size: 16px;
                            font-weight: bold;
                            text-align: center;
                          "
                        >
                          COUNTRY CLUB
                        </div>
                        <div style="padding-top: 9px">
                          포인트 {{receiptInfo.approvalDiv === "OK" ? "사용" : "취소"}} 내역서
                        </div>
                        <div style="padding-top: 11px">
                          <div
                              style="
                            box-sizing: border-box;
                            float: left;
                            width: 103px;
                            padding-right: 6px;
                          "
                          >
                            발행일시
                          </div>
                          <div
                              style="
                            box-sizing: border-box;
                            float: left;
                            width: calc(100% - 103px);
                            padding-left: 6px;
                          "
                          >
                            {{ receiptInfo.printDt }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            거래일시
                          </div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.approvalDt }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div
                          style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 9px 0 8px 0;
                        "
                      >
                        <div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            사용자명
                          </div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ receiptInfo.memberName }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div v-if="receiptInfo.contactTel">
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            연락처
                          </div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ receiptInfo.contactTel }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div
                          style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 9px 0 8px 0;
                        "
                      >
                        <div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            사용금액
                          </div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ numberWithCommas(receiptInfo.payAmt) }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div v-if="receiptInfo.giftNo">
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            상품권번호
                          </div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ receiptInfo.giftNo }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div style="position: relative; padding: 15px 0 0 0">
                        <div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            가맹점 명
                          </div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.bizName }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            사업자번호
                          </div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.bizNo }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            대표자 명
                          </div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.rprsntvName }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          {{ receiptInfo.addr }}
                        </div>
                        <div>
                          TEL : {{ receiptInfo.telNo }} / FAX :
                          {{ receiptInfo.faxNo }}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                    class="article-section"
                    v-if="payDiv === 'TICKET'"
                >
                  <div class="section-body" style="border-top-color: #000">
                    <div class="body-box" style="padding: 18px; color: #000">
                      <div
                          style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 0 0 8px 0;
                        "
                      >
                        <div
                            style="
                            font-size: 16px;
                            font-weight: bold;
                            text-align: center;
                          "
                        >
                          COUNTRY CLUB
                        </div>
                        <div style="padding-top: 9px">
                          이용권 {{receiptInfo.approvalDiv === "OK" ? "사용" : "취소"}} 내역서
                        </div>
                        <div style="padding-top: 11px">
                          <div
                              style="
                            box-sizing: border-box;
                            float: left;
                            width: 103px;
                            padding-right: 6px;
                          "
                          >
                            발행일시
                          </div>
                          <div
                              style="
                            box-sizing: border-box;
                            float: left;
                            width: calc(100% - 103px);
                            padding-left: 6px;
                          "
                          >
                            {{ receiptInfo.printDt }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            거래일시
                          </div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.approvalDt }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div
                          style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 9px 0 8px 0;
                        "
                      >
                        <div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            사용자명
                          </div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ receiptInfo.memberName }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div v-if="receiptInfo.contactTel">
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            연락처
                          </div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ receiptInfo.contactTel }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div
                          style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 9px 0 8px 0;
                        "
                      >
                        <div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            사용금액
                          </div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ numberWithCommas(receiptInfo.payAmt) }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div style="position: relative; padding: 15px 0 0 0">
                        <div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            가맹점 명
                          </div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.bizName }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            사업자번호
                          </div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.bizNo }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            대표자 명
                          </div>
                          <div
                              style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.rprsntvName }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          {{ receiptInfo.addr }}
                        </div>
                        <div>
                          TEL : {{ receiptInfo.telNo }} / FAX :
                          {{ receiptInfo.faxNo }}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  class="article-section"
                  v-if="payDiv === 'GIFT'"
                >
                  <div class="section-body" style="border-top-color: #000">
                    <div class="body-box" style="padding: 18px; color: #000">
                      <div
                        style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 0 0 8px 0;
                        "
                      >
                        <div
                          style="
                            font-size: 16px;
                            font-weight: bold;
                            text-align: center;
                          "
                        >
                          COUNTRY CLUB
                        </div>
                        <div style="padding-top: 9px">
                          상품권 {{receiptInfo.approvalDiv === "OK" ? "사용" : "취소"}} 내역서
                        </div>
                        <div style="padding-top: 11px">
                          <div
                            style="
                            box-sizing: border-box;
                            float: left;
                            width: 103px;
                            padding-right: 6px;
                          "
                          >
                            발행일시
                          </div>
                          <div
                            style="
                            box-sizing: border-box;
                            float: left;
                            width: calc(100% - 103px);
                            padding-left: 6px;
                          "
                          >
                            {{ receiptInfo.printDt }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            거래일시
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.approvalDt }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div
                        style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 9px 0 8px 0;
                        "
                      >
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            사용자명
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ receiptInfo.memberName }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div v-if="receiptInfo.contactTel">
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            연락처
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ receiptInfo.contactTel }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div
                        style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 9px 0 8px 0;
                        "
                      >
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            사용금액
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ numberWithCommas(receiptInfo.payAmt) }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div v-if="receiptInfo.giftNo">
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            상품권번호
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ receiptInfo.giftNo }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div style="position: relative; padding: 15px 0 0 0">
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            가맹점 명
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.bizName }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            사업자번호
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.bizNo }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            대표자 명
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.rprsntvName }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          {{ receiptInfo.addr }}
                        </div>
                        <div>
                          TEL : {{ receiptInfo.telNo }} / FAX :
                          {{ receiptInfo.faxNo }}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  class="article-section"
                  v-else-if="payDiv === 'CASH' || payDiv === 'TRANS'"
                >
                  <div class="section-body" style="border-top-color: #000">
                    <div class="body-box" style="padding: 18px; color: #000">
                      <div
                        style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 0 0 8px 0;
                        "
                      >
                        <div
                          style="
                            font-size: 16px;
                            font-weight: bold;
                            text-align: center;
                          "
                        >
                          COUNTRY CLUB
                        </div>
                        <div style="padding-top: 9px">
                          {{
                            salesDiv === "DEPOSIT"
                              ? "영수증(" +
                                commonCodesGetComName(
                                  "PAY_DIV",
                                  depositPayDiv
                                ) +
                                ")"
                              : commonCodesGetComName("PAY_DIV", payDiv) +
                                (receiptInfo.approvalDiv === "OK"
                                  ? "승인 "
                                  : "취소 ") +
                                (!!receiptInfo.proofName
                                  ? "(" + receiptInfo.proofName + ")"
                                  : "(미발급)")
                          }}
                        </div>
                        <div style="padding-top: 11px">
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            거래 일시
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.approvalDt }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            재발행일시
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.rePrintDt }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div
                        style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 9px 0 8px 0;
                        "
                      >
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            판매 금액
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ receiptInfo.taxAmt }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            면 세
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ receiptInfo.notaxAmt }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            부 가 세
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ receiptInfo.vatAmt }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div
                          v-if="
                            receiptInfo.svcAmt === '0'
                              ? null
                              : receiptInfo.svcAmt
                          "
                        >
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            봉 사 료
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ receiptInfo.svcAmt }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div
                        style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 9px 0 8px 0;
                        "
                      >
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            합 계
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ receiptInfo.approvalAmt }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div style="position: relative; padding: 15px 0 0 0">
                        <div v-if="receiptInfo.proofNo">
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            고객 확인
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.proofNo }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div v-if="receiptInfo.approvalNo">
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            승인 번호
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.approvalNo }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            가맹점 명
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.bizName }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            사업자번호
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.bizNo }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            대표자 명
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.rprsntvName }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          {{ receiptInfo.addr }}
                        </div>
                        <div>
                          TEL : {{ receiptInfo.telNo }} / FAX :
                          {{ receiptInfo.faxNo }}
                        </div>
                        <div
                          v-if="receiptInfo.proofName"
                          style="padding-top: 11px"
                        >
                          국세청 세미래콜센터 국번없이 126
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="article-section" v-else>
                  <div class="section-body" style="border-top-color: #000">
                    <div class="body-box" style="padding: 18px; color: #000">
                      <div
                        style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 0 0 8px 0;
                        "
                      >
                        <div
                          style="
                            font-size: 16px;
                            font-weight: bold;
                            text-align: center;
                          "
                        >
                          카드사 제출용
                        </div>
                        <div style="padding-top: 9px">
                          {{
                            receiptInfo.approvalDiv === "OK" ||
                            receiptInfo.approvalDiv === "T_OK"
                              ? "매출전표 (신용승인)"
                              : "매출전표 (신용취소)"
                          }}
                        </div>
                        <div style="padding-top: 11px">
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            매 장 명
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.storeName }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            영업 일자
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.payDate }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            락커 번호
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.lockerNo }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            전표 번호
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.slipNo }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            카드 종류
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.issuCompanyName }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            카드 번호
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.cardNo }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            유효 기간
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.validTerm }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            승인 일시
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.approvalDt }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            전표매입사
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.purcName }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div style="padding-top: 11px">
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            재발행일시
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.rePrintDt }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div
                        style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 9px 0 8px 0;
                        "
                      >
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            과 세
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ receiptInfo.taxAmt }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            면 세
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ receiptInfo.notaxAmt }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            부 가 세
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ receiptInfo.vatAmt }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div
                          v-if="
                            receiptInfo.svcAmt === '0'
                              ? null
                              : receiptInfo.svcAmt
                          "
                        >
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            봉 사 료
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ receiptInfo.svcAmt }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div
                        style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 9px 0 8px 0;
                        "
                      >
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            합 계
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ receiptInfo.approvalAmt }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div style="position: relative; padding: 15px 0 0 0">
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            승인 번호
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.approvalNo }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            할부 개월
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.divideTerm }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            가맹점번호
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.merchantNo }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            가맹점 명
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.bizName }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            사업자번호
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.bizNo }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 103px;
                              padding-right: 6px;
                            "
                          >
                            대표자 명
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 103px);
                              padding-left: 6px;
                            "
                          >
                            {{ receiptInfo.rprsntvName }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                        <div>
                          {{ receiptInfo.addr }}
                        </div>
                        <div>TEL : {{ receiptInfo.telNo }}</div>
                        <div style="padding-top: 11px">
                          결제취소시 영수증을 지참해 주시기 바랍니다.
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="print keyColor">
              <erp-button
                  button-div="PRINT"
                  :is-icon-custom="true"
                  @click.native="print"
              >
                출력
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeReIssueReceiptPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>
<script>
import ErpButton from "@/components/button/ErpButton.vue";
import { getReceiptInfo } from "@/api/common";
import BillPrintUtil from "@/utils/billPrintUtil";
import { getTodayDateTime } from "@/utils/date";
import { commonCodesGetComName } from "@/utils/commonCodes";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { numberWithCommas } from "@/utils/number";
import {getFormattedTelNumber} from "@/utils/string";
import {phoneNumberFormatter} from "@/utils/formatter";

export default {
  name: "ReIssueReceiptPopup",
  components: {
    ErpButton
  },
  mixins: [confirmDialogMixin],
  data() {
    return {
      salesDiv: null,
      payId: null,
      payDiv: null,
      receiptInfo: {},
      animationSettings: { effect: "None" },
      depositPayDiv: null,
      isPayCash: false,
      isCashCancel: false
    };
  },
  computed: {
    dialogProps() {
      return {
        target: "#popupWrapper",
        isModal: true,
        animationSettings: { effect: "None" },
        position: { x: "center", y: "center" },
        visible: true,
      };
    },
  },
  created: function () {},
  methods: {
    numberWithCommas,
    commonCodesGetComName: commonCodesGetComName,
    async showReIssueReceiptPopup(popupData) {
      this.salesDiv = popupData.salesDiv;
      this.payId = popupData.payId;
      this.payDiv = popupData.payDiv === "GROUP_GIFT" ? "GIFT" : popupData.payDiv === "EASY_PAY" ? "CARD" : popupData.payDiv;
      this.isPayCash = popupData.isPayCash;
      this.isCashCancel = popupData.isCashCancel;
      if (
        !["EASY_PAY","CARD", "CASH", "TRANS", "GIFT", "POINT","TICKET"].includes(this.payDiv)
      ) {
        this.payDiv = "CASH";
      }

      this.depositPayDiv = popupData.payDiv;

      await this.getReceiptInfo();
    },
    onReIssueReceiptPopupClosed() {
      this.$emit("popupClosed");
    },
    closeReIssueReceiptPopup() {
      this.$refs.reIssueReceiptPopup.hide();
    },
    async print() {
      // 디바이스 설정의 Bill 프린터 사용여부가 false이면 Bill 프린터 출력 안됨.

      const receiptCode = this.payDiv.toUpperCase() + "_RECEIPT";


      const printSetData = {
        storeName: this.receiptInfo.storeName,
        bsnDate: this.receiptInfo.payDate,
        lockerNo: this.receiptInfo.lockerNo,
        slipNo: this.receiptInfo.slipNo,
        cardKind: this.receiptInfo.issuCompanyName,
        cardNo: this.receiptInfo.cardNo,
        validTerm: this.receiptInfo.validTerm,
        approvalDt: this.receiptInfo.approvalDt,
        purcName: this.receiptInfo.purcName,
        rePrintDt: this.receiptInfo.rePrintDt,
        totAmt: this.receiptInfo.approvalAmt,
        taxAmt: this.receiptInfo.taxAmt,
        noTaxAmt: this.receiptInfo.notaxAmt,
        svcAmt:
          this.receiptInfo.svcAmt === "0" ? null : this.receiptInfo.svcAmt,
        vatAmt: this.receiptInfo.vatAmt,
        approvalNo: this.receiptInfo.approvalNo,
        divideTerm: this.receiptInfo.divideTerm,
        merchantNo: this.receiptInfo.merchantNo,
        merchantName: this.receiptInfo.bizName,
        bizNo: this.receiptInfo.bizNo,
        rprsntvName: this.receiptInfo.rprsntvName,
        addr: this.receiptInfo.addr,
        telNo: this.receiptInfo.telNo,
        faxNo: this.receiptInfo.faxNo,
        proofName: this.receiptInfo.proofName,
        proofNo: this.receiptInfo.proofNo,
        cardTitle:
          this.receiptInfo.approvalDiv === "OK" ||
          this.receiptInfo.approvalDiv === "T_OK"
            ? "매출전표 (신용승인)"
            : "매출전표 (신용취소)",
        cashTitle:
          (this.receiptInfo.approvalDiv === "OK" ? "현금승인 " : "현금취소 ") +
          (this.receiptInfo.proofName
            ? "(" + this.receiptInfo.proofName + ")"
            : "(미발급)"),
        transTitle:
          (this.receiptInfo.approvalDiv === "OK"
            ? "계좌이체승인 "
            : "계좌이체취소 ") +
          (this.receiptInfo.proofName
            ? "(" + this.receiptInfo.proofName + ")"
            : "(미발급)"),
        giftNo: this.receiptInfo.giftNo,
        payAmt: this.receiptInfo.payAmt,
        memberName: this.receiptInfo.memberName,
        contactTel: getFormattedTelNumber(
          this.receiptInfo.contactTel
        ),
        printDt: this.receiptInfo.printDt,
        giftTitle: this.receiptInfo.approvalDiv === "OK" ? "상품권 사용 내역서" : "상품권 취소 내역서",
      };

      const config = {
        "useBillPrinter": true,
        "useKitchenPrinter": false,
      };
      await BillPrintUtil.getTsConfReceiptInfo.call(this, receiptCode, true, printSetData, config);

    },
    async getReceiptInfo() {
      await getReceiptInfo(
        this.payId,
        this.payDiv,
        this.salesDiv,
        this.isPayCash,
        this.isCashCancel
      )
        .then(async (response) => {
          let receiptInfo = response.value.receiptInfo;
          receiptInfo.rePrintDt = await getTodayDateTime();
          receiptInfo.telNo = phoneNumberFormatter(receiptInfo.telNo);
          receiptInfo.faxNo = phoneNumberFormatter(receiptInfo.faxNo);
          receiptInfo.storeName = commonCodesGetComName(
            "STORE_CODE",
            receiptInfo.storeCode
          );
          receiptInfo.proofName = commonCodesGetComName(
            "PROOF_KIND",
            receiptInfo.proofKind
          );
          this.receiptInfo = receiptInfo;
        })
        .catch((error) => {
          console.error("getReceiptInfo.err.===>", error);
        });
    },
  },
};
</script>

<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="depositIncomPopup"
      header="선수금 입금 정보"
      width="422"
      allowDragging="true"
      showCloseIcon="true"
      isModal="false"
      :cssClass="
        depositIncomInfo.incomId
          ? $t('className.popup.productCodePopupModify')
          : $t('className.popup.productCodePopupAdd')
      "
      @close="onDepositIncomPopupClosed"
    >
      <div class="window advancePaymentDepositInformation">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">선수금 입금 정보</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li>
                          <erp-button
                              buttonDiv="GET"
                              @click.native="onChangeHistoryButtonClicked"
                              :isIconCustom="true"
                          >
                            변경이력
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">입금일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group date">
                                    <div class="form">
                                      <input-date
                                        ref="incomDate"
                                        format="YYYY-MM-DD"
                                        type="lookup-condition"
                                        v-model="depositIncomInfo.incomDate"
                                        :disabled="isExistData"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field incomDiv">
                              <!-- 필수 : required -->
                              <div class="title required">입금구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="incomDiv"
                                        v-model="depositIncomInfo.incomDiv"
                                        :dataSource="
                                          inputOptions.incomDivOptions
                                        "
                                        :fields="commonCodeFields"
                                        :allowFiltering="false"
                                        :enabled="!isExistData"
                                        cssClass="body-data-dropdown"
                                        @change="onIncomDivChange"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li class="item form-group check">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="checkbox"
                                            v-model="depositIncomInfo.indctTrgetFlag"
                                            :disabled="['CARD','CASH','TRANS'].includes(depositIncomInfo.incomDiv)"
                                          />
                                          <i></i>
                                          <div class="label">소득공제</div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">면/과세</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="notaxCode"
                                        v-model="depositIncomInfo.notaxCode"
                                        :dataSource="
                                          inputOptions.notaxCodeOptions
                                        "
                                        :fields="commonCodeFields"
                                        :allowFiltering="false"
                                        :enabled="
                                          !(
                                            isExistData &&
                                            depositIncomInfo.incomDiv === 'CARD'
                                          )
                                        "
                                        cssClass="body-data-dropdown"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">선수금</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group number">
                                    <div class="form">
                                      <input-number
                                        ref="depositAmt"
                                        v-model="depositIncomInfo.depositAmt"
                                        :disabled="
                                          isExistData &&
                                          ['CARD','CASH','TRANS'].includes(depositIncomInfo.incomDiv)
                                        "
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">은행</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        v-model="depositIncomInfo.bankCode"
                                        :dataSource="
                                          inputOptions.bankCodeOptions
                                        "
                                        :fields="commonCodeFields"
                                        :allowFiltering="false"
                                        cssClass="body-data-dropdown"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">계좌번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        v-model="depositIncomInfo.acnutNo"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">등록자/일시</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group text">
                                    {{ depositIncomInfo.insertName }}
                                  </li>
                                  <li class="item form-group text">
                                    {{ depositIncomInfo.insertDt }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  buttonDiv="SAVE"
                  :is-shortcut-button="true"
                  :isIconCustom="true"
                  :ignore="isPopupOpened"
                  @click.native="onSaveButtonClicked"
              >
                저장
              </erp-button>
            </li>
            <li>
              <ejs-dropdownbutton
                :items="cancelDropDownButtonItems"
                :disabled="
                  commonMixinIsButtonDisableByAuth('depositReg') ||
                  !depositIncomInfo.incomId ||
                  depositIncomInfo.trnscDiv == 'PG'
                "
                :beforeItemRender="onBeforeItemRender"
                @select="onCancelDropDownButtonSelect"
                cssClass="lookup-button-dropdown"
              >
                입금취소/영수증 재발행
              </ejs-dropdownbutton>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="closeDepositIncomPopup"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <pay-popup
      v-if="isPayPopupOpen"
      ref="payPopup"
      @popupClosed="payPopupClosed"
    />
    <pay-cancel-popup
      v-if="isPayCancelPopupOpen"
      ref="payCancelPopup"
      @popupClosed="payCancelPopupClosed"
    />
    <re-issue-receipt-popup
      v-if="isReIssueReceiptPopupOpen"
      ref="reIssueReceiptPopup"
      @popupClosed="reIssueReceiptPopupClosed"
    />
    <history-popup
      v-if="isHistoryPopupOpen"
      ref="historyPopup"
      @popupClosed="historyPopupClosed"
    />
  </div>
</template>

<style scoped>
body .article-section.section-01 .body-data .field.incomDiv{width:calc(100% + 1px)}
body .article-section.section-01 .body-data .field.incomDiv .content .item.select{width:calc((100% - 70px) / 2 + 1px)}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import InputDate from "@/components/common/datetime/InputDate";
import InputNumber from "@/components/common/InputNumber";
import payCancelPopup from "@/components/popups/PayCancelPopup";
import reIssueReceiptPopup from "@/components/popups/ReIssueReceiptPopup";

import {
  commonCodesGetCommonCode,
  commonCodeGetComCodeDefaultValue,
} from "@/utils/commonCodes";
import { getFormattedDate } from "@/utils/date";
import { formPreventEnterEvent, validateFormRefs } from "@/utils/formUtil";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import { getCurrentBizCode } from "@/utils/pageKeyUtil";
import { getTaxAmount } from "@/api/common";
import PayPopup from "@/views/common/PayPopup";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import HistoryPopup from "../../common/HistoryPopup";

export default {
  name: "DepositIncomPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputText,
    PayPopup,
    InputDate,
    InputNumber,
    payCancelPopup,
    reIssueReceiptPopup,
    HistoryPopup,
    ErpButton
  },
  async created() {
    this.depositIncomInfo.incomDate = getFormattedDate(new Date());

    this.currentBizCode = getCurrentBizCode();

    await this.fetchStores();
  },
  data() {
    return {
      isHistoryPopupOpen: false,
      isReIssueReceiptPopupOpen: false,
      isPayCancelPopupOpen: false,
      isPayPopupOpen: false,
      cancelDropDownButtonItems: [
        {
          id: "cashCancel",
          text: "현금/계좌이체/판촉 입금취소",
          isComplexArraySetter: true,
        },
        {
          id: "cardCancel",
          text: "카드 입금취소",
        },
        {
          id: "receiptRePrint",
          text: "영수증 재발행",
        },
      ],
      isExistData: false,
      currentBizCode: null,
      storeCode: null,
      storeBsnList: [],
      commonCodeFields: { text: "comName", value: "comCode" },
      inputOptions: {
        incomDivOptions: commonCodesGetCommonCode("INCOM_DIV"),
        notaxCodeOptions: commonCodesGetCommonCode("NOTAX_CODE"),
        bankCodeOptions: commonCodesGetCommonCode("BANK_CODE"),
      },
      depositIncomInfo: {
        incomId: null,
        incomDate: null,
        depositId: null,
        incomDiv: commonCodeGetComCodeDefaultValue("INCOM_DIV"),
        indctTrgetFlag: false,
        notaxCode: commonCodeGetComCodeDefaultValue("NOTAX_CODE"),
        approvalNo: null,
        depositAmt: 0,
        taxAmt: 0,
        notaxAmt: 0,
        vatAmt: 0,
        svcAmt: 0,
        bankCode: null,
        acnutNo: null,
        bsnId: null,
        posNo: null,
        termId: null,
        insertName: null,
        insertDt: null,
        delFlag: false,
        cardId: null,
        approvalDiv: null,
        trnscDiv: null,
      },
      depositIncomValidationRules: {
        incomDate: {
          required: true,
        },
        incomDiv: {
          required: true,
        },
        notaxCode: {
          required: true,
        },
        depositAmt: {
          required: true,
        },
      },
      vanModule: null,
      confCardList: null,
      van: null,
      isSave: false,
      saveDepositId: null,
      popupData: null,
    };
  },
  computed: {
    isPopupOpened() {
      return (
          this.isPayPopupOpen ||
          this.isPayCancelPopupOpen ||
          this.isReIssueReceiptPopupOpen
      );
    },
  },
  methods: {
    validateFormRefs,
    preventEnterEvent: formPreventEnterEvent,
    async showDepositIncomPopup(popupData) {
      const incomId =
        !!popupData && !!popupData.incomId ? popupData.incomId : null;

      this.storeCode = popupData.storeCode;

      this.popupData = popupData;

      if (incomId) {
        this.isExistData = true;

        await this.fetchDepositIncom(incomId);
      } else {
        this.isExistData = false;

        this.depositIncomInfo.depositId = popupData.depositId;
      }
    },
    onDepositIncomPopupClosed() {
      console.log("Closed===>");
      this.$emit("popupClosed", {saveId: this.saveDepositId,isSaveFlag: this.isSave});
    },
    closeDepositIncomPopup() {
      this.$refs.depositIncomPopup.hide();
    },
    async fetchDepositIncom(incomId) {
      const data = await GolfErpAPI.fetchDepositIncom(incomId);

      this.depositIncomInfo.incomId = data.incomId ? data.incomId : null;
      this.depositIncomInfo.incomDate = data.incomDate ? data.incomDate : null;
      this.depositIncomInfo.depositId = data.depositId ? data.depositId : null;
      this.depositIncomInfo.incomDiv = data.incomDiv ? data.incomDiv : null;
      this.depositIncomInfo.indctTrgetFlag = data.indctTrgetFlag ? data.indctTrgetFlag : false;
      this.depositIncomInfo.notaxCode = data.notaxCode ? data.notaxCode : null;
      this.depositIncomInfo.approvalNo = data.approvalNo
        ? data.approvalNo
        : null;
      this.depositIncomInfo.depositAmt = data.depositAmt ? data.depositAmt : 0;
      this.depositIncomInfo.taxAmt = data.taxAmt ? data.taxAmt : 0;
      this.depositIncomInfo.notaxAmt = data.notaxAmt ? data.notaxAmt : 0;
      this.depositIncomInfo.vatAmt = data.vatAmt ? data.vatAmt : 0;
      this.depositIncomInfo.svcAmt = data.svcAmt ? data.svcAmt : 0;
      this.depositIncomInfo.bankCode = data.bankCode ? data.bankCode : null;
      this.depositIncomInfo.acnutNo = data.acnutNo ? data.acnutNo : null;
      this.depositIncomInfo.bsnId = data.bsnId ? data.bsnId : null;
      this.depositIncomInfo.posNo = data.posNo ? data.posNo : null;
      this.depositIncomInfo.termId = data.termId ? data.termId : null;
      this.depositIncomInfo.insertName = data.insertName
        ? data.insertName
        : null;
      this.depositIncomInfo.insertDt = data.insertDt ? data.insertDt : null;
      this.depositIncomInfo.delFlag =
        data.delFlag !== undefined ? data.delFlag : null;
      this.depositIncomInfo.cardId = data.cardId ? data.cardId : null;
      this.depositIncomInfo.approvalDiv = data.approvalDiv
        ? data.approvalDiv
        : null;
      this.depositIncomInfo.trnscDiv = data.trnscDiv
          ? data.trnscDiv
          : null;
      this.depositIncomInfo.cashId = data.cashId
          ? data.cashId
          : null;
    },
    async onSaveButtonClicked() {
      try {
        if (!this.validateFormRefs(this.depositIncomValidationRules)) {
          return;
        }

        if (
          (this.depositIncomInfo.incomDiv === "CARD" || this.depositIncomInfo.incomDiv === "CASH" || this.depositIncomInfo.incomDiv === "TRANS") &&
          this.depositIncomInfo.incomId === null
        ) {
          this.payPopupOpen();
          return;
        }

        let depositIncom = JSON.parse(JSON.stringify(this.depositIncomInfo));
        depositIncom.storeCode = this.storeCode;
        depositIncom.incomDate =
          !depositIncom.incomDate || depositIncom.incomDate.trim() === ""
            ? null
            : depositIncom.incomDate;
        depositIncom.incomDiv =
          !depositIncom.incomDiv || depositIncom.incomDiv.trim() === ""
            ? null
            : depositIncom.incomDiv;
        depositIncom.indctTrgetFlag =
          !depositIncom.indctTrgetFlag
            ? false
            : depositIncom.indctTrgetFlag;
        depositIncom.notaxCode =
          !depositIncom.notaxCode || depositIncom.notaxCode.trim() === ""
            ? null
            : depositIncom.notaxCode;
        depositIncom.approvalNo =
          !depositIncom.approvalNo || depositIncom.approvalNo.trim() === ""
            ? null
            : depositIncom.approvalNo;
        depositIncom.bankCode =
          !depositIncom.bankCode || depositIncom.bankCode.trim() === ""
            ? null
            : depositIncom.bankCode;
        depositIncom.acnutNo =
          !depositIncom.acnutNo || depositIncom.acnutNo.trim() === ""
            ? null
            : depositIncom.acnutNo;
        depositIncom.posNo =
          !depositIncom.posNo || depositIncom.posNo.trim() === ""
            ? null
            : depositIncom.posNo;
        depositIncom.termId =
          !depositIncom.termId || depositIncom.termId.trim() === ""
            ? null
            : depositIncom.termId;

        ({
          netAmt: depositIncom.taxAmt,
          vatAmt: depositIncom.vatAmt,
          svcAmt: depositIncom.svcAmt,
        } = await getTaxAmount(
          depositIncom.notaxCode,
          depositIncom.depositAmt
        ));
        depositIncom.notaxAmt =
          depositIncom.notaxCode === "NOTAX" ? depositIncom.taxAmt : 0;
        depositIncom.taxAmt =
          depositIncom.notaxCode === "NOTAX" ? 0 : depositIncom.taxAmt;
        depositIncom.bsnId = this.storeBsnList.find(
          (data) => data.code === this.storeCode
        ).bsnId;

        if (!this.depositIncomInfo.depositId) {
          const depositPostRequest = {
            deposit: this.popupData.deposit,
            incomRequest: depositIncom,
          };

          const data  = await GolfErpAPI.postDeposit(depositPostRequest);
          console.log('if',data);
          this.isSave = true;
          this.saveDepositId = data;
          this.infoToast(this.$t("main.popupMessage.saved"));

          this.closeDepositIncomPopup();
        } else {
          console.log("else");
          await this.patchDepositIncom(depositIncom);
        }
      } catch (e) {
        if (e.message !== "CONFIRM") {
          this.errorToast(e.message);
        } else {
          throw e;
        }
      }
    },
    async patchDepositIncom(depositIncom) {
      this.isSave = true;
      console.log("depositIncom",depositIncom);
      await GolfErpAPI.patchDepositIncom(depositIncom);

      this.saveDepositId = depositIncom.depositId;
      this.infoToast(this.$t("main.popupMessage.saved"));
      this.closeDepositIncomPopup();
    },
    async fetchStores() {
      this.storeBsnList = await GolfErpAPI.fetchStores();
    },
    onCancelDropDownButtonSelect(args) {
      const itemId = args.item.id;

      if (!this.depositIncomInfo.incomId) {
        this.infoToast(
          this.$t("creditManagement.popupMessage.notExistDepositIncom")
        );
        return;
      }

      if (itemId === "cardCancel" || itemId === "cashCancel") {
        // 카드 및 현금/계좌이체/판촉 입금취소
        this.incomCancel();
      } else if (itemId === "receiptRePrint") {
        // 영수증 재발행
        this.receiptRePrint();
      }
    },
    onBeforeItemRender(args) {
      if (this.depositIncomInfo.delFlag) {
        if (args.item.id === "cashCancel" || args.item.id === "cardCancel") {
          args.element.style.display = "none";
        }
      } else {
        if (this.depositIncomInfo.incomDiv === "CARD") {
          if (args.item.id === "cashCancel") {
            args.element.style.display = "none";
          }
        } else {
          if (args.item.id === "cardCancel") {
            args.element.style.display = "none";
          }
        }
      }
    },
    incomCancel() {
      this.isPayCancelPopupOpen = true;

      let popupData = {
        salesDiv: "DEPOSIT",
        payDiv: this.depositIncomInfo.incomDiv,
        incomId: this.depositIncomInfo.incomId,
        payDate: this.depositIncomInfo.incomDate,
        payAmt: this.depositIncomInfo.depositAmt,
        cardId: this.depositIncomInfo.cardId,
        storeCode: this.storeCode,
        isOnlyPayDataCancel:
          this.depositIncomInfo.approvalDiv === "CANCEL",
        cashId: !this.depositIncomInfo.delFlag && this.depositIncomInfo.approvalDiv !== "CANCEL" ? this.depositIncomInfo.cashId : null,
      };

      this.$nextTick(() => {
        this.$refs.payCancelPopup.showPayCancelPopup(popupData);
      });
    },
    receiptRePrint() {
      const incomId = this.depositIncomInfo.incomId;
      const incomDiv = this.depositIncomInfo.incomDiv;
      const cardId = this.depositIncomInfo.cardId;

      if (incomId === null || incomId === undefined) {
        this.infoToast("선수금 ID가 존재하지 않습니다");
        return;
      }

      this.isReIssueReceiptPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.reIssueReceiptPopup.showReIssueReceiptPopup({
          salesDiv: "DEPOSIT",
          payId: incomDiv === "CARD" ? cardId : incomId,
          payDiv: incomDiv,
        });
      });
    },
    onChangeHistoryButtonClicked() {
      // alert("변경이력 개발 예정");
      if (!this.depositIncomInfo.incomId) {
        return;
      }

      GolfErpAPI.fetchDepositIncomHistory(this.depositIncomInfo.incomId).then((rawHistories) => {
        this.isHistoryPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.historyPopup.show({
            rawHistories,
            fields: {
              DepositIncom: {
                __name__: "선수금 입금 정보",
                incomDate: {
                  name: "입금일자",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                incomDiv: {
                  name: "입금구분",
                  type: "commonCode",
                  groupCode: "INCOM_DIV",
                  methods: ["create", "update", "delete"],
                },
                trnscDiv: {
                  name: "거래구분",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                indctTrgetFlag: {
                  name: "소득공제대상여부",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
                notaxCode: {
                  name: "면세코드",
                  type: "commonCode",
                  groupCode: "NOTAX_CODE",
                  methods: ["create", "update", "delete"],
                },
                approvalNo: {
                  name: "승인번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                depositAmt: {
                  name: "선수금액",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                taxAmt: {
                  name: "과세금액",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                notaxAmt: {
                  name: "면세금액",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                vatAmt: {
                  name: "부가세금액",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                svcAmt: {
                  name: "봉사료",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                bankCode: {
                  name: "은행코드",
                  type: "commonCode",
                  groupCode: "BANK_CODE",
                  methods: ["create", "update", "delete"],
                },
                acnutNo: {
                  name: "계좌번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                bsnId: {
                  name: "영업장ID",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                posNo: {
                  name: "POS번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                termId: {
                  name: "단말ID",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
              }
            }
          });
        });
      });
    },
    historyPopupClosed() {
      this.isHistoryPopupOpen = false;
    },
    payCancelPopupClosed(args) {
      this.isPayCancelPopupOpen = false;

      if (args !== undefined && args.isReload) {
        this.isSave = true;
        this.closeDepositIncomPopup();
      }
    },
    turnOnDimming() {
      this.$refs["depositIncomPopup"].$el.nextElementSibling.style.zIndex = `${
        parseInt(
          this.$refs["depositIncomPopup"].$el.nextElementSibling.style.zIndex
        ) + 1
      }`;
    },
    turnOffDimming() {
      this.$refs["depositIncomPopup"].$el.nextElementSibling.style.zIndex = `${
        parseInt(
          this.$refs["depositIncomPopup"].$el.nextElementSibling.style.zIndex
        ) - 1
      }`;
    },
    payPopupOpen() {
      this.turnOnDimming();

      this.isPayPopupOpen = true;

      let popupData = {
        bsnDate: this.depositIncomInfo.incomDate,
        storeCode: this.storeCode,
        salesDiv: "DEPOSIT",
        payDiv: this.depositIncomInfo.incomDiv,
        depositId: this.depositIncomInfo.depositId,
        depositAmt: this.depositIncomInfo.depositAmt,
        notaxCode: this.depositIncomInfo.notaxCode,
        deposit: this.popupData.deposit,
        indctTrgetFlag: this.depositIncomInfo.indctTrgetFlag
      };

      this.$nextTick(() => {
        this.$refs["payPopup"].showPopup(popupData);
      });
    },
    payPopupClosed(payload) {
      this.isPayPopupOpen = false;
      this.turnOffDimming();

      if (payload?.isPaid) {
        this.isSave = true;
        this.closeDepositIncomPopup();
      }
    },
    reIssueReceiptPopupClosed() {
      this.isReIssueReceiptPopupOpen = false;
    },
    onIncomDivChange(args) {
      if (!this.depositIncomInfo.incomId) {
        if (args.value === "CARD" || args.value === "PROMTN" || args.value === "CASH" || args.value === "TRANS") {
          this.depositIncomInfo.indctTrgetFlag = false;
        } else {
          this.depositIncomInfo.indctTrgetFlag = true;
        }
      }
    }
  },
};
</script>

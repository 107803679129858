import qs from "qs";
import request from "@/utils/request";

/**
 * @return Promise
 */
export function getWords(wordsDiv) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/common/words",
    method: "get",
    params: {
      wordsDiv: wordsDiv,
    },
  });
}

/**
 * @return Promise
 */
export function saveWords(words) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/common/words",
    method: "put",
    data: words,
  });
}

/**
 * @return Promise
 */
export function getMemos(resveId, dept, stayId) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/common/memo",
    isBackground: true,
    method: "get",
    params: {
      dept,
      resveId,
      stayId,
    },
  });
}

/**
 * @return Promise
 */
export function saveMemo(data) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/common/memo",
    isBackground: true,
    method: "post",
    data: data,
  });
}

/**
 * @return Promise
 */
export function deleteMemo(memoId, resveId, stayId) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/common/memo",
    isBackground: true,
    method: "delete",
    params: {
      memoId: memoId,
      resveId: resveId,
      stayId: stayId,
    },
  });
}

/**
 * 정산내역서 팝업의 정보 조회
 * @param data
 * @return Promise
 */
export function getPayReceipt(data) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/common/pay-receipt",
    method: "get",
    params: {
      bsnDate: data.bsnDate,
      chkinId: data.chkinId,
      grpNo: data.grpNo,
      visitId: data.visitId,
      printFlag: data.printFlag,
      storeGroup: data.storeGroup,
      isHideCancelSlip: data.isHideCancelSlip,
      isDcListView: data.isDcListView,
      isDateGroup: data.isDateGroup,
    },
  });
}

/**
 * 요금 및 promotion 조회
 * @return Promise
 */
export function getPaymentPrice(
  bsnDate,
  startTime,
  holeDivision,
  memberGrade,
  paymentCode,
  promotionId = null,
  isBackground = false,
  membershipId
) {
  return request({
    url: `/common/payment-price`,
    isBackground: isBackground,
    method: "get",
    params: {
      bsnDate: bsnDate,
      startTime: startTime,
      holeDivision: holeDivision,
      memberGrade: memberGrade,
      paymentCode: paymentCode,
      promotionId: promotionId,
      membershipId: membershipId,
    },
  });
}

/**
 * @return Promise
 */
export function getFixedPaymentPrice(
  bsnDate,
  holeDivision,
  paymentCode,
  isBackground = false
) {
  return request({
    url: `/common/fixed-payment-price`,
    isBackground: isBackground,
    method: "get",
    params: {
      bsnDate: bsnDate,
      holeDivision: holeDivision,
      paymentCode: paymentCode,
    },
  });
}

/**
 * DB 스키마 정보 조회
 * @return Promise
 */
export function getSchemaInfo() {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/common/schema-info",
    method: "get",
  });
}

/**
 * @return Promise
 */
export function saveFcm(token) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/common/fcm-token",
    method: "post",
    isBackground: true,
    data: token,
  });
}

/**
 * @return Promise
 */
export function getReceiptInfo(payId, payDiv, salesDiv, isPayCash = false, isCashCancel = false) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/common/receipt-info/${payDiv}`,
    method: "get",
    params: {
      payId: payId,
      salesDiv: salesDiv,
      isPayCash: isPayCash,
      isCashCancel: isCashCancel
    },
  });
}

/**
 * @return Promise
 */
export function getDateTime() {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/common/dateTime",
    method: "get",
  });
}

/**
 * @return Promise
 */
export function getTsConfReceiptInfo(receiptCode, useFlag) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/common/receipt-print-info",
    method: "get",
    params: {
      receiptCode: receiptCode,
      useFlag: useFlag,
    },
  });
}

/**
 * cardId를 이용해 카드 결제 정보 호출
 * @param {number} cardId - 카드 ID
 * @return Promise
 */
export function getTgPayCardByCardId(cardId) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/common/tg-pay-card/${cardId}`,
    method: "get",
  });
}

/**
 * cashId를 이용해 카드 결제 정보 호출
 * @param {number} cashId - 현금 ID
 * @return Promise
 */
export function getTgPayCashByCashId(cashId) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/common/tg-pay-cash/${cashId}`,
    method: "get",
  });
}

/**
 * 정산 정보 조회
 * @return Promise
 */
export function getPayInfo(paramData) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/common/pay-info`,
    method: "get",
    params: {
      salesDiv: paramData.salesDiv, // 매출구분
      personFlag: paramData.personFlag, // 개인정산여부
      bsnDate: paramData.bsnDate, // 영업일자
      chkinId: paramData.chkinId, // 체크인ID
      grpNo: paramData.grpNo, // 단체번호
      visitId: paramData.visitId, // 내장객ID
      storeCode: paramData.storeCode, // 매장코드
      salesId: paramData.salesId, // 매출ID (매장코드가 FRONT일 경우엔 null)
      payId: paramData.payId,
      payIds: paramData.payIds,
      isPrepayment: paramData.isPrepayment,
      rresveNo: paramData.rresveNo,
      defDiv: paramData.defDiv,
      isGolfLink: paramData.isGolfLink,
      creditDefByVisitDate: paramData.creditDefByVisitDate,
      creditDefByVisitName: paramData.creditDefByVisitName,
      gcDiv: paramData.gcDiv,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

/**
 * 정산 팝업의 대내후불 이월 대상 조회
 */
export function getCreditDefTrans(args) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/common/pay-info/credit-def-trans",
    method: "get",
    params: {
      defDiv: args.defDiv,
      isGolfLink: args.isGolfLink,
      rresveNo: args.rresveNo,
      visitDate: args.visitDate,
      visitName: args.visitName,
    },
  });
}

/**
 * 정산 정보 취소
 * @param payCancelInfo
 * @return Promise
 */
export function cancelPayInfo(payCancelInfo) {
  return request({
    url: `/common/pay-info`,
    method: "delete",
    data: payCancelInfo,
  });
}

/**
 * @return Promise
 */
export function getTotalInfo(pcUseCode) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/common/total-info",
    isBackground: true,
    method: "get",
    params: {
      pcUseCode
    }
  });
}

/**
 * @return Promise
 */
export function getCommonCodesInfo() {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/common/common-codes-info",
    isBackground: true,
    method: "get",
  });
}

export function getCommonCodeUsePc() {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/common/common-codes/pc-use-code",
    isBackground: true,
    method: "get",
  });
}

/**
 * @return Promise
 */
export function getBizMstInfo() {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/common/biz-mst-info",
    isBackground: true,

    method: "get",
  });
}

/**
 *
 * @param taxType
 * @param totalAmount
 * @return Promise
 */
export function getTaxAmount(taxType, totalAmount, isServiceAmount) {
  return request({
    url: `/common/tax-amt`,
    method: "GET",
    params: {
      taxType: taxType,
      totAmt: totalAmount,
      isServiceAmount: isServiceAmount,
    },
  });
}

import layout from "@/views/layout/layout";

const StoreManagementRoute = {
  path: "/store-management",
  component: layout,
  redirect: "/store-management/sales-registration/store-pos",
  children: [
    {
      path: "sales-registration/store-sales-status",
      name: "StoreSalesStatus",
      component: () =>
        import(
          "@/views/store-management/sales-registration/store-sales-status/StoreSalesStatus"
        ),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "sales-registration/group-sales-status",
      name: "GroupSalesStatus",
      component: () =>
        import(
          "@/views/store-management/sales-registration/group-sales-status/GroupSalesStatus"
        ),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "sales-registration/team-sales-status",
      name: "TeamSalesStatus",
      component: () =>
        import(
          "@/views/store-management/sales-registration/team-sales-status/TeamSalesStatus"
        ),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "sales-registration/sales-details-by-item",
      name: "SalesDetailsByItem",
      component: () =>
          import(
              "@/views/store-management/sales-registration/sales-details-by-item/SalesDetailsByItem"
              ),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "sales-registration/product-sales-summary-table-by-period",
      name: "ProductSalesSummaryTableByPeriod",
      component: () =>
        import("@/views/store-management/sales-registration/product-sales-summary-table-by-period/ProductSalesSummaryTableByPeriod"),
      meta: {
        useUsualStyles: true
      }
    }
  ],
};

export default StoreManagementRoute;

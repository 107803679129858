<template>
  <div>
    <ejs-dialog
      ref="dialog"
      header="창고 등록"
      width="1000"
      height="auto"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :cssClass=" isNew ? $t('className.popup.productCodePopupAdd') : $t('className.popup.productCodePopupModify')"
      @close="onPopupClose"
    >
      <div class="window"><div class="windowContent"><div class="content-wrapper">
        <!-- 팝업 컨텐츠 영역 -->
        <div class = "content-body">
          <article class="body-article">
            <!-- SECTION 1 -->
            <div class="article-left" style="width: 50%;height:350px">
              <section class="article-section">
                <!-- 타이틀 -->
                <div class="section-header">
                  <div class="header-left"><div class="header-title">기본 정보</div></div>
                </div>
                <!-- 상세 필드 -->
                <div class="section-body">
                  <div class="body-data"><div class="data-outer"><div class="data-inner">
                    <ul class="data-content">
                      <li class="field voc-section">
                        <div class="title required">창고 구분</div>
                        <div class="content">
                          <ul class="row">
                            <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          ref="wrhousDiv"
                                          v-model="popupInputField.wrhousDiv"
                                          :dataSource="commonCodeOptions.division"
                                          :fields="commonCodeOptions.fields"
                                          cssClass="body-data-dropdown"
                                      />
                                    </div>
                                  </li>
                          </ul>
                        </div>
                      </li>
                      <li class="field voc-section">
                        <div class="title required">창고명</div>
                        <div class="content">
                          <ul class="row">
                            <li class="item form-group">
                              <div class="form">
                                <input-text id="wrhousName" ref="wrhousName" v-model="popupInputField.wrhousName"/>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="field voc-section">
                        <div class="title">코드</div>
                        <div class="content">
                          <ul class="row">
                            <li class="item form-group">
                              <div class="form">
                                <input-text id="wrhousCode" ref="wrhousCode" v-model="popupInputField.wrhousCode" :readonly="true"/>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="field voc-section">
                        <div class="title">사용 여부</div>
                        <div class="content">
                          <ul class="row">
                            <li class="item form-group">
                              <ul class="check">
                                <li>
                                  <label>
                                    <input type="checkbox" id="useFlag" ref="useFlag" v-model="popupInputField.useFlag" @change="checkStockCnt" /><i/>
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="field voc-section">
                        <div class="title">저장품 용도</div>
                        <div class="content">
                          <ul class="row">
                            <li class="item form-group">
                              <ul class="check">
                                <li>
                                  <label>
                                    <input type="checkbox" id="suppliesFlag" ref="suppliesFlag" v-model="popupInputField.suppliesFlag" @change="checkStockCnt2" /><i/>
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="field voc-section">
                        <div class="title">메인창고</div>
                        <div class="content">
                          <ul class="row">
                            <li class="item form-group">
                              <ul class="check">
                                <li>
                                  <label>
                                    <input type="checkbox" id="mainWrhousFlag" ref="mainWrhousFlag" v-model="popupInputField.mainWrhousFlag" /><i/>
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="field voc-section" v-show="isProductSub">
                        <div class="title">매장 구분</div>
                        <div class="content">
                          <ul class="row">
                            <li class="item form-group">
                              <div class="form">
                                <ejs-dropdownlist
                                    ref="storeCode"
                                    v-model="popupInputField.storeCode"
                                    :dataSource="commonCodeOptions.storeCode"
                                    :fields="commonCodeOptions.fields"
                                    cssClass="body-data-dropdown"
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="field voc-section">
                        <div class="title">비고</div>
                        <div class="content">
                          <ul class="row">
                            <li class="item form-group">
                              <div class="form">
                                <input-text id="remarks" ref="remarks" v-model="popupInputField.remarks"/>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div></div></div>
              </section>
            </div>
            <!-- SECTION 2 -->
            <div class="article-right" style="width: 50%;height:350px">
              <section class="article-section">
                <div class="section-header"><div class="header-left"><div class="header-title">담당자</div></div></div>
                <div class="section-body">
                  <ejs-grid-wrapper
                      ref="popupGrid"
                      v-bind="initGridPop"
                      @headerCellInfo="onHeaderCellInfo"
                      @queryCellInfo="onQueryCellInfo"
                  />
                </div>
              </section>
            </div>
          </article>
        </div>

        <!-- 팝업 버튼 영역 -->
        <div class="windowFooter">
          <ul class="button">
            <li class="create keyColor">
              <erp-button button-div="SAVE" :is-shortcut-button="true" :is-key-color="true" @click.native="onSubmitClick">저장</erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="onPopupClose">닫기</erp-button>
            </li>
          </ul>
        </div>

      </div></div></div>
    </ejs-dialog>
  </div>
</template>

<script>
import CommonMixin from "@/views/layout/mixin/commonMixin";
import ErpButton from "@/components/button/ErpButton.vue";
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {ExcelExport, Filter, ForeignKey, Page, Resize, Edit} from "@syncfusion/ej2-vue-grids";
import MessagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import InputText from "@/components/common/text/InputText.vue";

import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import { validateFormRefs } from '@/utils/formUtil';
import WrhousCd from "@/api/v2/StockManage/WrhousCd";


export default {
  name:'WrhousCodeRegistrationPopup',
  mixins : [CommonMixin, MessagePopupDialogMixin],
  components:{EjsGridWrapper, ErpButton, InputText},

  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data(){
    return{
      //신규팝업여부
      isNew: false,
      //상세저장필드
      popupInputField : {
        wrhousDivName: null,
        wrhousDiv:null,
        wrhousName : null,
        wrhousCode : null,
        useFlag : true,
        suppliesFlag : false,
        mainWrhousFlag : true,
        storeCode : null,
        remarks : null,
        authUserList: null,// 유저 권한 저장 list
        stockCnt : 0 //창고 재고 테이블 건수
      },
      //validate 체크 항목
      validateRefField: {
        wrhousDiv: { required: true },
        wrhousName: { required: true },
      },
      //그리드 데이터 필드
      popupGridField:{
        gridData: [],
      },
      //콤보, 드랍다운리스트 필드
      commonCodeOptions:{
        division: commonCodesGetCommonCode("WRHOUS_DIV",false,false,"",""),
        storeCode: commonCodesGetCommonCode("STORE_CODE",true,false,"",""),
        fields: { text: "comName", value: "comCode" },
      },
    };
  },
  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    isProductSub(){
      //저장품이 아니면서 메인창고가 아니면 매장 매핑 가능
      return this.popupInputField.suppliesFlag === false && this.popupInputField.mainWrhousFlag === false;
    },
    initGridPop() {
      return {
        columns:  [
          {field: 'userId',    textAlign: 'center',    width: 30,   visible: true,   allowEditing : false,  type: 'number',   headerText: 'ID',   isPrimaryKey : true  },
          {field: 'userName',  textAlign: 'center',    width: 30,   visible: true,   allowEditing : false,  type: 'string',   headerText: '유저명'  },
          {field: 'authFlag',  textAlign: 'center',    width: 20,   visible: true,   allowEditing : true,   type: 'boolean',  headerText: '권한',   displayAsCheckBox: true, editType: "booleanedit" },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.popupGridField.gridData,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:false,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        pageSettings: { pageSize: 50 },
      };
    },
  },
  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
  },
  mounted(){
  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
    //******************************* 공통 버튼 이벤트 *******************************

    //******************************* 공통 이벤트 *******************************
    //필수입력체크
    validateFormRefs,

    //팝업열기(신규,수정)
    async initShow(isNew, searchCode) {
      this.isNew = isNew;
      //기존 정보 일 때 상세정보 조회
      if(!isNew){
        this.popupInputField = await WrhousCd.getWrhousDetail({wrhousCode:searchCode});
      }
      //창고 담당자 리스트 호출
      this.popupGridField.gridData = await WrhousCd.getWrhousAuthList({wrhousCode:searchCode});
    },

    //팝업 저장
    async onSubmitClick() {
      //0.필수입력체크
      if (!this.validateFormRefs(this.validateRefField)) {
        return;
      }
      //1.상품 서브창고가 아닐경우 매장매핑 클리어
      if(!this.isProductSub){
        this.popupInputField.storeCode = null ;
      }

      //2.변경데이터 수집
      this.popupInputField.authUserList = this.$refs.popupGrid.getBatchChanges().changedRecords;
      //3. Save Api
      if(this.isNew){
        await WrhousCd.postWrhousDetail(this.popupInputField);
      } else{
        await WrhousCd.patchWrhousDetail(this.popupInputField);
      }
      //4. 메세지 호출
      this.infoToast(this.$t('main.popupMessage.saved'));
      //5. 부모창 저장후 이벤트 호출
      this.$emit('popupSaved');
    },

    //팝업 닫기
    onPopupClose(){
      this.$emit('popupClosed');
    },
    //사용유무 정합성 체크
    checkStockCnt(){
      if(this.popupInputField.stockCnt > 0 && !this.popupInputField.useFlag ){
        this.errorToast("해당창고의 재고가 존재하여 사용 해제 불가합니다.");
        this.popupInputField.useFlag = true;
        this.$refs["useFlag"].checked = this.popupInputField.useFlag;
      }
    },
    //용도 변경 체크 TODO - 재고테이블에 데이터 존재건수로 해야함.(과거이력에서 보여야 하므로) 향후 변경해야함. 현재는 재고 수량건수로 함
    checkStockCnt2(){
      if(this.popupInputField.stockCnt > 0){
        this.errorToast("해당창고의 재고가 존재하여 용도 변경 불가합니다.");
        this.popupInputField.suppliesFlag = this.popupInputField.suppliesFlag ? 0 : 1;
        this.$refs["suppliesFlag"].checked = this.popupInputField.suppliesFlag;
      }
    },

    //******************************* 그리드 이벤트 *******************************
    //그리드 헤더 이미지적용
    onHeaderCellInfo(args){
      const {cell: {column:{field}}, node} = args;
      //필수 입력
      if(this.$refs.popupGrid.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    //그리드 셀 이미지 적용
    onQueryCellInfo(args){
      const {column:{field,allowEditing}, cell} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },
    //그리드 셀 클릭
    /*onGridClick(args){},*/
    //그리드 조회 완료시(카운트)
    gridComplete(){
    },
  }
};
</script>

<!-- ##################################### CUSTOM STYLE ########################################* -->
<style scoped>
  .voc-section {
    width: 100% !important;
  }
</style>
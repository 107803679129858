import Vue from "vue";
import ClientMonitorMixin from '@/mixins/clientMonitorMixin';

// Global Vue Instance Event Bus
Vue.prototype.$EventBus = new Vue();

Vue.mixin(ClientMonitorMixin);

// use syncfusion
import("./libs/syncfusion");

// Fonts
import "./assets/fonts/NanumSquare-Light.css";
import "./assets/fonts/NanumSquare-Regular.css";
import "./assets/fonts/NanumSquare-Bold.css";

import App from "./App.vue";

import router from "./router";
import store from "./store";
import i18n from "./i18n";

import BaseError from "@/error/BaseError";
import { GOLF_ERP_STATUS_CODE_MESSAGES } from "@/api/v2/GolfErpAPI";
import {initializeFirebase} from "@/utils/pushNofication";
import {createRegExpForUrl} from "@/utils/string";

initializeFirebase();

// Turn off to display production tip
Vue.config.productionTip = false;
Vue.config.devtools = true;

// default error handler
Vue.config.errorHandler = function (err, vm) {
  if (err instanceof BaseError) {
    const { status } = err.getData();

    if (GOLF_ERP_STATUS_CODE_MESSAGES[status]) {
      vm.$EventBus.$emit("alert", {
        message: GOLF_ERP_STATUS_CODE_MESSAGES[status],
      });
    }
  } else {
    console.error(err);
  }
};

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

import layout from "@/views/layout/layout";

const dailyCloseRoute = {
  path: "/daily-close",
  component: layout,
  redirect: "/daily-close/front-daily-close",
  children: [
    {
      path: "daily-report",
      name: "dailyReport",
      component: () => import("@/views/daily-close/DailyReport"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "daily-store-report",
      name: "dailyStoreReport",
      component: () => import("@/views/daily-close/DailyStoreReport"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "front-daily-close",
      name: "frontDailyClose",
      component: () => import("@/views/daily-close/FrontDailyClose"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "sales-status-by-part",
      name: "SalesStatusByPart",
      component: () => import("@/views/daily-close/SalesStatusByPart"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "incheon-airport-data-interface",
      name: "IncheonAirportDataInterface",
      component: () => import("@/views/daily-close/IncheonAirportDataInterface"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "reservation-front-desk-weekly-business-report",
      name: "ReservationFrontDeskWeeklyBusinessReport",
      component: () => import("@/views/daily-close/ReservationFrontDeskWeeklyBusinessReport"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "income-amount-aggregation-table",
      name: "IncomeAmountAggregationTable",
      component: () => import("@/views/daily-close/IncomeAmountAggregationTable"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "daily-report-fee-division",
      name: "DailyReportFeeDivision",
      component: () => import("@/views/daily-close/DailyReportFeeDivision"),
      meta: {
        useUsualStyles: true,
      },
    },
    {
      path: "member-annual-fee-report",
      name: "MemberAnnualFeeReport",
      component: () => import("@/views/daily-close/MemberAnnualFeeReport"),
      meta: {
        useUsualStyles: true,
      },
    },
  ],
};

export default dailyCloseRoute;

<template>
  <div>
    <ejs-dialog ref="dialog" v-bind="dialogProps" @close="onDialogClose">
      <div
          class="typePos"
          style="width: calc(100% + 36px);">
        <div class="typePos-wrapper-main">
          <div class="typePos-caption">POS</div>
          <div class="typePos-body">
            <ul class="typePos-list">
              <li>
                <div class="typePos-title">POS 번호</div>
                <div class="typePos-content">
                  <div class="ui-input">
                    <!-- 비활성(Attribute) : disabled -->
                    <input
                        ref="posNo"
                        type="text"
                        placeholder="입력하세요."
                        v-model="deviceSettingInfo.posNo"
                    />
                  </div>
                </div>
              </li>
              <li>
                <div class="typePos-title">사용코드</div>
                <div class="typePos-title">
                  {{ commonCodesGetComName('PC_USE_CODE',deviceSettingInfo.pcUseCode)}}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="typePos-wrapper-main">
          <div class="typePos-caption">VAN 설정</div>
          <div class="typePos-body">
            <ul class="typePos-list">
              <li>
                <div class="typePos-title">사용여부</div>
                <div class="typePos-content">
                  <ul class="ui-check">
                    <li>
                      <label>
                        <!-- 비활성(Attribute) : disabled -->
                        <input
                            type="checkbox"
                            v-model="deviceSettingInfo.isCardUse"
                        />
                        <div class="i-item">사용</div>
                      </label>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div class="typePos-title">포트</div>
                <div class="typePos-content">
                  <div class="ui-input">
                    <!-- 비활성(Attribute) : disabled -->
                    <input
                        type="text"
                        placeholder="입력하세요."
                        v-model="deviceSettingInfo.cardDaemonPort"
                        :disabled="!deviceSettingInfo.isCardUse"
                    />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="typePos-wrapper-main">
          <div class="typePos-caption">Bill 프린터 통신 설정</div>
          <div class="typePos-body">
            <ul class="typePos-list">
              <li>
                <div class="typePos-title">사용여부</div>
                <div class="typePos-content">
                  <ul class="ui-check">
                    <li>
                      <label>
                        <!-- 비활성(Attribute) : disabled -->
                        <input
                            type="checkbox"
                            v-model="deviceSettingInfo.isSerialUse"
                        />
                        <div class="i-item">사용</div>
                      </label>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="typePos-billCommunication">
                <div class="typePos-title">포트</div>
                <div class="typePos-content">
                  <div class="typePos-item typePos-port">
                    <div class="ui-input">
                      <!-- 비활성(Attribute) : disabled -->
                      <input
                          type="text"
                          placeholder="입력하세요."
                          v-model="deviceSettingInfo.serialDaemonPort"
                          :disabled="!deviceSettingInfo.isSerialUse"
                      />
                    </div>
                  </div>
                  <div class="typePos-item typePos-button">
                    <!-- 비활성(Attribute) : disabled -->
                    <erp-button
                        button-div="GET"
                        class="ui-button"
                        :disabled="!deviceSettingInfo.isSerialUse"
                        :use-syncfusion-component-style="false"
                        @click.native="getSerialPortList"
                    >
                      <div class="i-item">시리얼포트 가져오기</div>
                    </erp-button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="typePos-wrapper-main">
          <div class="typePos-caption">Bill 프린터 일반 설정</div>
          <div class="typePos-body">
            <ul class="typePos-list">
              <li>
                <div class="typePos-title">사용여부</div>
                <div class="typePos-content">
                  <ul class="ui-check">
                    <li>
                      <label>
                        <!-- 비활성(Attribute) : disabled -->
                        <input
                            type="checkbox"
                            v-model="deviceSettingInfo.isBillPrinterUse"
                            @change="printerUseClickCheckBox($event,deviceSettingInfo.isBillPrinterUse,'port')"
                        />
                        <div class="i-item">사용</div>
                      </label>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div class="typePos-title">통신 방법</div>
                <div class="typePos-content">
                  <f-select
                      class="ui-select"
                      :items="billPrinterCommunicationList"
                      v-model="deviceSettingInfo.billPrinterCommunication"
                      :disabled="!(deviceSettingInfo.isBillPrinterUse)"
                      @change="onBillPrinterCommunicationChanged"
                  />
                </div>
              </li>
              <li>
                <div class="typePos-title">
                  통신 포트
                </div>
                <div class="typePos-content">
                  <f-select
                      class="ui-select"
                      :items="serialPortsForSelect"
                      v-model="deviceSettingInfo.billPrinterComPort"
                      :disabled="!(deviceSettingInfo.isBillPrinterUse && deviceSettingInfo.billPrinterCommunication === 'PORT')"
                      @select="getSerialPortList('billPrinter')"
                  />
                </div>
              </li>
              <li>
                <div class="typePos-title">비트/초</div>
                <div class="typePos-content">
                  <f-select
                      class="ui-select"
                      :items="billPrinterBitRatesForSelect"
                      v-model="deviceSettingInfo.billPrinterBitRate"
                      :disabled="!(deviceSettingInfo.isBillPrinterUse && deviceSettingInfo.billPrinterCommunication === 'PORT')"
                  />
                </div>
              </li>
              <li>
                <div class="typePos-title">IP주소</div>
                <div class="typePos-content">
                  <div class="ui-input">
                    <input
                        type="text"
                        placeholder="입력하세요."
                        v-model="deviceSettingInfo.billPrinterIpAddress"
                        :disabled="!(deviceSettingInfo.isBillPrinterUse && deviceSettingInfo.billPrinterCommunication !== 'PORT')"
                    />
                  </div>
                </div>
              </li>
              <li>
                <div class="typePos-title">포트</div>
                <div class="typePos-content">
                  <div class="ui-input">
                    <input
                        type="text"
                        placeholder="입력하세요."
                        v-model="deviceSettingInfo.billPrinterIpPort"
                        :disabled="!(deviceSettingInfo.isBillPrinterUse && deviceSettingInfo.billPrinterCommunication !== 'PORT')"
                    />
                  </div>
                </div>
              </li>
              <li>
                <div class="typePos-title">디바이스ID</div>
                <div class="typePos-content">
                  <div class="ui-input">
                    <input
                        type="text"
                        placeholder="입력하세요."
                        v-model="deviceSettingInfo.billPrinterDeviceId"
                        :disabled="!(deviceSettingInfo.isBillPrinterUse && deviceSettingInfo.billPrinterCommunication !== 'PORT')"
                    />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="typePos-wrapper-main">
          <div class="typePos-caption">네트워크 (IP) 프린터 사용 설정</div>
          <div class="typePos-body">
            <ul class="typePos-list">
              <li>
                <div class="typePos-title">사용여부</div>
                <div class="typePos-content">
                  <ul class="ui-check">
                    <li>
                      <label>
                        <!-- 비활성(Attribute) : disabled -->
                        <input
                            type="checkbox"
                            v-model="deviceSettingInfo.isKitchenBillPrinterUse"
                            @change="printerUseClickCheckBox($event,deviceSettingInfo.isKitchenBillPrinterUse,'ip')"
                        />
                        <div class="i-item">사용</div>
                      </label>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div class="typePos-title">통신 방법</div>
                <div class="typePos-content">
                  <f-select
                      class="ui-select"
                      :items="billPrinterCommunicationList"
                      v-model="deviceSettingInfo.kitchenBillPrinterCommunication"
                      :disabled="!(deviceSettingInfo.isKitchenBillPrinterUse)"
                      @change="onKitchenBillPrinterCommunicationChanged"
                  />
                </div>
              </li>
              <li>
                <div class="typePos-title">
                  통신 포트
                </div>
                <div class="typePos-content">
                  <f-select
                      class="ui-select"
                      :items="serialPortsForSelect"
                      v-model="deviceSettingInfo.kitchenBillPrinterComPort"
                      :disabled="!(deviceSettingInfo.isKitchenBillPrinterUse && deviceSettingInfo.kitchenBillPrinterCommunication === 'PORT')"
                      @select="getSerialPortList('kitchenBillPrinter')"
                  />
                </div>
              </li>
              <li>
                <div class="typePos-title">비트/초</div>
                <div class="typePos-content">
                  <f-select
                      class="ui-select"
                      :items="billPrinterBitRatesForSelect"
                      v-model="deviceSettingInfo.kitchenBillPrinterBitRate"
                      :disabled="!(deviceSettingInfo.isKitchenBillPrinterUse && deviceSettingInfo.kitchenBillPrinterCommunication === 'PORT')"
                  />
                </div>
              </li>
              <li>
                <div class="typePos-title">IP주소</div>
                <div class="typePos-content">
                  <div class="ui-input">
                    <input
                        type="text"
                        placeholder="입력하세요."
                        v-model="deviceSettingInfo.kitchenBillPrinterIpAddress"
                        :disabled="!(deviceSettingInfo.isKitchenBillPrinterUse && deviceSettingInfo.kitchenBillPrinterCommunication !== 'PORT')"
                    />
                  </div>
                </div>
              </li>
              <li>
                <div class="typePos-title">포트</div>
                <div class="typePos-content">
                  <div class="ui-input">
                    <input
                        type="text"
                        placeholder="입력하세요."
                        v-model="deviceSettingInfo.kitchenBillPrinterIpPort"
                        :disabled="!(deviceSettingInfo.isKitchenBillPrinterUse && deviceSettingInfo.kitchenBillPrinterCommunication !== 'PORT')"
                    />
                  </div>
                </div>
              </li>
              <li>
                <div class="typePos-title">디바이스ID</div>
                <div class="typePos-content">
                  <div class="ui-input">
                    <input
                        type="text"
                        placeholder="입력하세요."
                        v-model="deviceSettingInfo.kitchenBillPrinterDeviceId"
                        :disabled="!(deviceSettingInfo.isKitchenBillPrinterUse && deviceSettingInfo.kitchenBillPrinterCommunication !== 'PORT')"
                    />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="typePos-wrapper-main typePos-end">
          <div class="typePos-caption">고객 모니터 사용 설정</div>
          <div class="typePos-body">
            <ul class="typePos-list">
              <li class="typePos-clientMonitor">
                <div class="typePos-title">사용여부</div>
                <div class="typePos-content">
                  <div class="typePos-item typePos-check">
                    <ul class="ui-check">
                      <li>
                        <label>
                          <!-- 비활성(Attribute) : disabled -->
                          <input
                              type="checkbox"
                              v-model="deviceSettingInfo.isClientMonitorUse"
                              @change="onChangeClientMonitorUse"
                          />
                          <div class="i-item">사용</div>
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div class="typePos-item typePos-button pattern">
                    <!-- 비활성(Attribute) : disabled -->
                    <erp-button
                        button-div="SAVE"
                        class="ui-button"
                        :disabled="!deviceSettingInfo.isClientMonitorUse || true"
                        :use-syncfusion-component-style="false"
                        @click.native="onClickClientMonitorPatterImageConfig"
                    >
                      <div class="i-item">패턴 이미지 업로드</div>
                    </erp-button>
                  </div>
                </div>
              </li>
              <li class="typePos-clientMonitor">
                <div class="typePos-title">프로그램</div>
                <div class="typePos-content">
                  <div class="typePos-item typePos-button">
                    <!-- 비활성(Attribute) : disabled -->
                    <erp-button
                        button-div="SAVE"
                        class="ui-button"
                        :disabled="!deviceSettingInfo.isClientMonitorUse"
                        :use-syncfusion-component-style="false"
                        @click.native="onClickClientMonitorSetting"
                    >
                      <div class="i-item">설정</div>
                    </erp-button>
                  </div>
                  <div class="typePos-item typePos-button">
                    <!-- 비활성(Attribute) : disabled -->
                    <erp-button
                        button-div="GET"
                        class="ui-button"
                        :disabled="!deviceSettingInfo.isClientMonitorUse"
                        :use-syncfusion-component-style="false"
                        @click="onClickClientMonitorInstallOrOpen(true)"
                    >
                      <div class="i-item">설치</div>
                    </erp-button>
                  </div>
                  <div class="typePos-item typePos-button">
                    <!-- 비활성(Attribute) : disabled -->
                    <erp-button
                        button-div="GET"
                        class="ui-button"
                        :disabled="!deviceSettingInfo.isClientMonitorUse"
                        :use-syncfusion-component-style="false"
                        @click="onClickClientMonitorInstallOrOpen(false)"
                    >
                      <div class="i-item">실행</div>
                    </erp-button>
                  </div>
                  <div class="typePos-item typePos-button">
                    <!-- 비활성(Attribute) : disabled -->
                    <erp-button
                        button-div="CLOSE"
                        class="ui-button"
                        :disabled="!deviceSettingInfo.isClientMonitorUse"
                        :use-syncfusion-component-style="false"
                        @click="onClickClientMonitorClose"
                    >
                      <div class="i-item">종료</div>
                    </erp-button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li class="typePos-save">
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                  button-div="SAVE"
                  class="ui-button i-typePrimary"
                  :is-shortcut-button="true"
                  :visible-shortcut-string="false"
                  :use-syncfusion-component-style="false"
                  @click="onSaveClick"
              >
                <div class="i-item">
                  저장 ({{ saveShortcutButtonShortcutString }})
                </div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                  button-div="CLOSE"
                  class="ui-button i-iconClose"
                  :use-syncfusion-component-style="false"
                  @click="onCloseClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <pos-client-monitor-config-popup
        v-if="isClientMonitorConfigPopupOpened"
        ref="clientMonitorConfigPopup"
        @popupClosed="onClientMonitorConfigPopupClosed"
        @popupConfirmed="onClientMonitorConfigPopupConfirmed"
    />
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-main {display: flex; flex-direction: column;}
.typePos .typePos-wrapper-main .typePos-caption {padding: 10px 20px 5px 20px; border-bottom: 1px solid #e0e0e0; color: #222; font-size: 16px; font-family: 'NanumSquare-Regular'; line-height: 24px;}
.typePos .typePos-wrapper-main .typePos-body {border-bottom: 1px solid #bebebe;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list {list-style: none; padding: 5px 13px; margin: 0;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li {padding: 5px 0;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-title,
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content {padding: 0 7px;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-title {position: relative; box-sizing: border-box; float: left; width: 92px; padding-top: 10px; padding-bottom: 11px; color: #222; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content {box-sizing: border-box; float: left; width: calc(100% - 92px);}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content .typePos-item {box-sizing: border-box; float: left; padding-left: 10px;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content .typePos-item:first-child {padding-left: 0;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content .typePos-hr {display: block; clear: both; height: 10px;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content .typePos-item.typePos-text {padding-top: 10px; padding-bottom: 11px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content .typePos-item.typePos-select .ui-select {width: 160px;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content .ui-input {width: 100%;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content .ui-input input {width: 100%;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content >>> .ejs-number input {width: 180px;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content textarea {box-sizing: border-box; box-shadow: none; width: 100%; height: 82px; padding: 12px 15px 11px 15px; margin: 0; border: 1px solid #e0e0e0; border-radius: 4px; background-color: #fff; color: #222; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px; resize: none;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content textarea::-ms-clear {display: none;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content textarea::placeholder {color: #aaa;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content textarea::-webkit-input-placeholder {color: #aaa;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content textarea:-ms-input-placeholder {color: #aaa;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content textarea:focus {border-color: #18b3c9;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content textarea:disabled,
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content textarea:disabled:focus {background-color: #eee; color: #aaa; cursor: default;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li.typePos-serial .typePos-title {padding-top: 1px; padding-bottom: 1px;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li.typePos-billCommunication .typePos-item.typePos-port {width: calc(100% - 160px);}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li.typePos-billCommunication .typePos-item.typePos-button {width: 160px;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li.typePos-billCommunication .typePos-item.typePos-button >>> .ui-button {width: 100%;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li.typePos-billCommunication .typePos-item.typePos-button >>> .ui-button .i-item {padding-left: 0;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li.typePos-billCommunication .typePos-item.typePos-button >>> .ui-button .i-item::before {display: none;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li.typePos-clientMonitor .typePos-item {display: flex;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li.typePos-clientMonitor .typePos-item.typePos-check {width: calc(100% - 144px);}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li.typePos-clientMonitor .typePos-item.typePos-button {width: 73px; flex-direction: column;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li.typePos-clientMonitor .typePos-item.typePos-button:not(:first-child) {padding-left: 3px;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li.typePos-clientMonitor .typePos-item.typePos-button.pattern {padding: 0; width: 144px;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li.typePos-clientMonitor .typePos-item.typePos-button >>> .ui-button {width: 100%;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li.typePos-clientMonitor .typePos-item.typePos-button >>> .ui-button .i-item {padding-left: 0;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li.typePos-clientMonitor .typePos-item.typePos-button >>> .ui-button .i-item::before {display: none;}
.typePos .typePos-wrapper-main.typePos-end {flex: 1;}
.typePos .typePos-wrapper-main.typePos-end .typePos-body {flex: 1; border-bottom: none;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
.typePos .typePos-wrapper-footer .typePos-button > li.typePos-save .ui-button .i-item::before {background-position-x: calc(-20px * 8);}
</style>

<script>
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import {getDeviceInfo, saveDeviceInfo} from '@/utils/device';
import BillPrintUtil from '@/utils/billPrintUtil';
import FSelect from '@/views/store-management/sales-registration/store-pos-renew/components/FSelect';
import {getSaveButtonShortcut, toShortcutString} from '@/utils/KeyboardUtil';
import PosClientMonitorConfigPopup from './PosClientMonitorConfigPopup';
import ErpButton from "@/components/button/ErpButton.vue";
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import {sortBy as _sortBy} from 'lodash';
import {commonCodesGetComName} from '@/utils/commonCodes';

export default {
  name: 'SettingDevicePopup',
  components: {
    FSelect,
    PosClientMonitorConfigPopup,
    ErpButton,
  },
  mixins: [confirmDialogMixin],
  async mounted() {
    if (
        this.deviceSettingInfo.isSerialUse &&
        this.deviceSettingInfo.billPrinterCommunication === "PORT"
    ) {
      await this.getSerialPortList("billPrinter");
    }
    if (
        this.deviceSettingInfo.isKitchenBillPrinterUse &&
        this.deviceSettingInfo.kitchenBillPrinterCommunication === "PORT"
    ) {
      await this.getSerialPortList("kitchenBillPrinter");
    }
    if (this.deviceSettingInfo.isClientMonitorUse) {
      await this.fetchClientMonitorImages();
    }
  },
  watch: {
    'deviceSettingInfo.isSerialUse': function(newVal) {
      if (!newVal) {
        this.clearComPorts();
      }
    },
    'deviceSettingInfo.billPrinterCommunication': function(newVal,oldVal) {
      this.printerCommunicationChange(newVal,oldVal,"port");
    },
    'deviceSettingInfo.kitchenBillPrinterCommunication': function(newVal,oldVal) {
      this.printerCommunicationChange(newVal,oldVal,"ip");
    },
    'deviceSettingInfo.billPrinterComPort': function(newVal,oldVal) {
      this.serialPortFieldsChange(newVal,oldVal,"port");
    },
    'deviceSettingInfo.kitchenBillPrinterComPort': function(newVal,oldVal) {
      this.serialPortFieldsChange(newVal,oldVal,"ip");
    },
  },
  data() {
    return {
      deviceSettingInfo: getDeviceInfo(), // device.js 로부터 바로 설정
      serialPortList: [],
      serialPortfields: {
        text: 'path',
        value: 'path',
      },
      bitRateList: [9600, 19200, 38400, 57600, 115200],
      imageList: [],
      isClientMonitorConfigPopupOpened: false,
      isClientMonitorConfigPatternImagePopupOpened: false,
      billPrinterCommunicationList: [
        {
          name: "포트",
          value: "PORT"
        },
        {
          name: "IP",
          value: "IP"
        },
        {
          name: "BIXOLON WEB",
          value: "WEBSDK"
        }
      ],
    };
  },
  computed: {
    saveShortcutButtonShortcutString() {
      return toShortcutString(getSaveButtonShortcut());
    },
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: '디바이스 설정',
        width: 445,
        height: 741,
        allowDragging: true,
        animationSettings: {effect: 'None'},
        isModal: true,
        position: {x: 'center', y: 'center'},
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = 'dialog-non-height';
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
    serialPortsForSelect() {
      return this.serialPortList.map(({path}) => ({
        name: path,
        value: path,
      }));
    },
    billPrinterBitRatesForSelect() {
      return this.bitRateList.map((value) => ({
        name: value,
        value,
      }));
    },
  },
  methods: {
    commonCodesGetComName,
    show() {
      this.$refs['dialog'].show();
    },
    hide() {
      this.$refs['dialog'].hide();
    },
    onDialogClose() {
      this.$emit('close');
    },
    onSaveClick() {
      if(!this.deviceSettingInfo.posNo) {
        this.$refs.posNo.focus();
        return this.errorToast("POS 번호는 필수입니다");
      }
      this.saveDeviceInfo();
      this.onOffClientMonitor();
      this.infoToast('저장되었습니다.');
      this.hide();
    },
    onCloseClick() {
      this.hide();
    },
    saveDeviceInfo() {
      saveDeviceInfo(this.deviceSettingInfo);
      this.onOffClientMonitor();
      location.reload();
    },
    printerUseClickCheckBox(event,val,state) {
      if(
          val
          && (this.deviceSettingInfo.billPrinterComPort === this.deviceSettingInfo.kitchenBillPrinterComPort)
          && (this.deviceSettingInfo.billPrinterComPort && this.deviceSettingInfo.kitchenBillPrinterComPort)
          && (this.deviceSettingInfo.isBillPrinterUse && this.deviceSettingInfo.isKitchenBillPrinterUse)
          && (this.deviceSettingInfo.billPrinterCommunication === "PORT" && this.deviceSettingInfo.kitchenBillPrinterCommunication === "PORT")
      ) {
        this.errorToast("같은 통신포트를 이용하실수 없습니다");
        state==='port' ? this.deviceSettingInfo.isBillPrinterUse = false : this.deviceSettingInfo.isKitchenBillPrinterUse = false;
        event.target.checked = false;
      }
    },
    printerCommunicationChange(newVal,oldVal,state) {
      if(state === 'port') {
        if(
            this.deviceSettingInfo.isKitchenBillPrinterUse &&
            this.deviceSettingInfo.kitchenBillPrinterCommunication === 'PORT' &&
            (this.deviceSettingInfo.kitchenBillPrinterComPort === this.deviceSettingInfo.billPrinterComPort)
        ) {
          if(newVal === 'PORT'){
            this.errorToast("서로 같은 통신포트를 이용하실수 없습니다");
            this.deviceSettingInfo.billPrinterCommunication = oldVal;
          }
        }

      } else if (state === 'ip') {
        if(
            this.deviceSettingInfo.isBillPrinterUse &&
            this.deviceSettingInfo.billPrinterCommunication === "PORT" &&
            (this.deviceSettingInfo.billPrinterComPort === this.deviceSettingInfo.kitchenBillPrinterComPort)
        ) {
          if(newVal === 'PORT') {
            this.errorToast("서로 같은 통신포트를 이용하실수 없습니다");
            this.deviceSettingInfo.kitchenBillPrinterCommunication = oldVal;
          }

        }
      }
    },
    async serialPortFieldsChange(newVal,oldVal,state) {
      if(
          this.deviceSettingInfo.isBillPrinterUse &&
          this.deviceSettingInfo.isKitchenBillPrinterUse &&
          this.deviceSettingInfo.billPrinterCommunication === "PORT" &&
          this.deviceSettingInfo.kitchenBillPrinterCommunication === "PORT"
      ) {
        if(state === "port") {
          if(
              (newVal === this.deviceSettingInfo.kitchenBillPrinterComPort)
          ) {
            await this.errorToast("서로 같은 통신포트를 이용하실수 없습니다");
            this.deviceSettingInfo.billPrinterComPort = oldVal;
          }
        } else if(state === "ip") {
          if(
              (this.deviceSettingInfo.billPrinterComPort === newVal)
          ) {
            await this.errorToast("서로 같은 통신포트를 이용하실수 없습니다");
            this.deviceSettingInfo.kitchenBillPrinterComPort = oldVal;
          }
        }
      }

    },
    async getSerialPortList(div = null) {
      try {
        this.serialPortList = await BillPrintUtil.getPortList(
            this.deviceSettingInfo.serialDaemonPort,
        );

        // 조회한 serialPortList 중에 billPrinterComPort 값이 없으면 billPrinterComPort 값을 초기화한다
        try {
          if (div === 'billPrinter' || div === null) {
            if (
                this.serialPortList.findIndex(
                    (port) =>
                        port.path === this.deviceSettingInfo.billPrinterComPort,
                ) === -1
            ) {
              this.deviceSettingInfo.billPrinterComPort = null;
            }
          }
          if (div === 'kitchenBillPrinter' || div === null) {
            if (
                this.serialPortList.findIndex(
                    (port) =>
                        port.path === this.deviceSettingInfo.kitchenBillPrinterComPort,
                ) === -1
            ) {
              this.deviceSettingInfo.kitchenBillPrinterComPort = null;
            }
          }
        } catch (e) {
          if (div === 'billPrinter' || div === null) {
            this.deviceSettingInfo.billPrinterComPort = null;
          }
          if (div === 'kitchenBillPrinter' || div === null) {
            this.deviceSettingInfo.kitchenBillPrinterComPort = null;
          }
        }
      } catch (e) {
        console.error(e);
        this.errorToast(
            '시리얼포트 정보 조회에 실패하였습니다. Bill 프린터 정보를 초기화합니다',
        );
        this.clearComPorts();
      }
    },
    onOffClientMonitor() {
      this.deviceSettingInfo.isClientMonitorUse
          ? this.clientMonitorOpen()
          : this.closeWebSocket();
    },
    clientMonitorOpen() {
      this.openWebSocket({
        interval: this.deviceSettingInfo.clientMonitorInterval,
        effect: this.deviceSettingInfo.clientMonitorEffect,
        imageList: this.imageList,
      });
    },
    onChangeClientMonitorUse(args) {
      if (!args.target.checked) {
        this.closeWebSocket();
      }
    },
    async onClickClientMonitorPatterImageConfig() {
      if (!this.deviceSettingInfo.clientMonitorImagePattern) {
        this.errorToast('패턴을 설정하지 않았습니다');
        return;
      }
      this.isClientMonitorConfigPatternImagePopupOpened = true;
      await this.$nextTick();
      this.$refs.clientMonitorConfigPatternImagePopup.show(this.deviceSettingInfo.clientMonitorImagePattern);
    },
    async onClickClientMonitorSetting() {
      this.isClientMonitorConfigPopupOpened = true;
      await this.$nextTick();
      this.$refs.clientMonitorConfigPopup.show(
          this.deviceSettingInfo.clientMonitorInterval,
          this.deviceSettingInfo.clientMonitorEffect,
          this.deviceSettingInfo.clientMonitorImagePattern,
      );
    },
    async onClickClientMonitorInstallOrOpen(isInstall) {
      const fallback = '/golf_erp_client_monitor.zip';
      const url = 'erp-monitor:open';
      if (isInstall) {
        this.$EventBus.$emit('pos-confirm', {
          message: 'ERP 고객 모니터 프로그램을 설치하시겠습니까?',
          callback: async (confirmed) => {
            if (confirmed) {
              document.location = fallback;
            }
          },
        });
      } else {
        this.clientMonitorOpen();
        document.location = url;
      }
    },
    onClickClientMonitorClose() {
      this.$EventBus.$emit('pos-confirm', {
        message: '정말로 종료하시겠습니까?',
        callback: async (confirmed) => {
          if (confirmed) {
            this.closeWebSocket();
          }
        },
      });
    },
    onClientMonitorConfigPopupClosed() {
      this.isClientMonitorConfigPopupOpened = false;
    },
    onClientMonitorConfigPopupConfirmed(args) {
      this.deviceSettingInfo.clientMonitorInterval = args.interval;
      this.deviceSettingInfo.clientMonitorEffect = args.effect;
      this.deviceSettingInfo.clientMonitorImagePattern = args.imagePattern;
      if(this.deviceSettingInfo.isClientMonitorUse) {
        this.sendWebSocket('config', {
          interval: args.interval,
          effect: args.effect,
          imageList: args.imageList,
        });
      }
      saveDeviceInfo(this.deviceSettingInfo);
    },
    async fetchClientMonitorImages() {
      const data = await GolfErpAPI.fetchClientMonitorImages(this.deviceSettingInfo.clientMonitorImagePattern);
      this.imageList = _sortBy(data.map(item => {
        return {
          imageId: item.id,
          imageUrl: item.path,
          sortNo: item.sortNo,
        };
      }), ["sortNo"]);
    },
    clearComPorts() {
      this.serialPortList = [];
      //this.deviceSettingInfo.isBillPrinterUse = false;
      this.deviceSettingInfo.billPrinterComPort = null;
      this.deviceSettingInfo.billPrinterBitRate = null;
      //this.deviceSettingInfo.isKitchenBillPrinterUse = false;
      this.deviceSettingInfo.kitchenBillPrinterComPort = null;
      this.deviceSettingInfo.kitchenBillPrinterBitRate = null;
      this.deviceSettingInfo.isClientMonitorUse = false;
      this.deviceSettingInfo.clientMonitorInterval = 10;
      this.deviceSettingInfo.clientMonitorEffect = 'FADE';
      this.deviceSettingInfo.clientMonitorImagePattern = null;
    },
    async onBillPrinterCommunicationChanged() {
      if (this.deviceSettingInfo.billPrinterCommunication === "PORT") {
        await this.getSerialPortList("billPrinter");
      }
    },
    async onKitchenBillPrinterCommunicationChanged() {
      if (this.deviceSettingInfo.kitchenBillPrinterCommunication === "PORT") {
        await this.getSerialPortList("kitchenBillPrinter");
      }
    }
  },
};
</script>

<template>
  <div>
    <ejs-dialog
        ref="memberAnnualFeeDtlPopup"
        header="회원권 사용 등록"
        :allowDragging="true"
        :showCloseIcon="true"
        width="1280"
        height="800"
        :isModal="true"
        @close="onMemberAnnualFeeDtlPopupClosed"
        @popupConfirm="onMemberAnnualFeeDtlPopupConfirm"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <div class="title">
                   회원명
                  </div>
                  <ul class="content">
                    <li class="item">
                      <input-text ref="searchMemberName" v-model="searchMembersFilterOptions.memberName" @keydown.enter="onClickMembershipAnnualFeeSearch"/>
                    </li>
                  </ul>
                  <li class="field memberNameNumber">
                    <div class="title">
                      핸드폰 뒤자리
                    </div>
                    <ul class="content">
                      <li class="item">
                        <input-text
                            v-model="searchMembersFilterOptions.endOfHpNumber"
                            placeholder="핸드폰 뒤 4자리"
                            maxlength="4"
                            @keydown.enter="onClickMembershipAnnualFeeSearch"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul class="lookup-button">
                  <li class="lookup keyColor">
                    <erp-button buttonDiv="GET" @click.native="onClickMembershipAnnualFeeSearch">조회</erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article" :class="isMemberAnnualFeeDetailViewOpened ? $t('className.reservation.reservationDetailViewOpened'): '' ">
                <div class="article-left">
                  <section class="article-section section-01">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">회비권 내역</div>
                        <div class="header-caption">
                          [ {{ totalMembershipFeeDeposit | numberWithCommas }} 건 ]
                        </div>
                      </div>
                      <div class="header-right">
                        <ul class="header-button">
                          <li class="print">
                            <erp-button button-div="FILE" @click.native="onClickExcel">Excel</erp-button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="section-body">
                      <ejs-grid-wrapper
                          ref="MembershipFeeDepositDetailsGrid"
                          :columns="membershipFeeDepositListGridColumn"
                          :dataSource="membershipFeeDepositList"
                          :selectionSettings="selectionSettings"
                          :provides="grid"
                          :allowPaging="true"
                          :allowExcelExport="true"
                          :pageSettings="membershipFeeDepositListPageSettings"
                          :isAutoSelectRow="false"
                          :isSelectedRowRetain="false"
                          @rowSelected="onMembershipFeeDepositGridRowSelected"
                      />
                    </div>
                  </section>
                </div>
                <div class="article-right">
                  <section class="article-section section-01">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">회비권 상세 정보</div>
                      </div>
                    </div>

                    <div class="section-body" v-show="isMemberAnnualFeeDetailViewOpened">
                      <article class="body-article">
                        <!-- 아코디언 : accordion / 닫힘 : close -->
                        <section class="article-section section-0101">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <div class="title required">입금일자</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-date v-model="annualFeeDepositInfo.depositDate"/>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">회원권종류</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                ref="annualFeeDiv"
                                                placeholder="선택"
                                                v-model="annualFeeDepositInfo.annualFeeDiv"
                                                :dataSource="commonCodeOptions.annualFeeDiv"
                                                :fields="commonCodeOptions.fields"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">입금액</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-number
                                                ref="depositAmt"
                                                v-model="annualFeeDepositInfo.depositAmt"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">지불방법</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                ref="payDiv"
                                                placeholder="선택"
                                                v-model="annualFeeDepositInfo.payDiv"
                                                :dataSource="commonCodeOptions.payDiv"
                                                :fields="commonCodeOptions.fields"
                                                @change="onChangePayDiv"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">입금은행</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                ref="bankCode"
                                                placeholder="선택"
                                                v-model="annualFeeDepositInfo.bankCode"
                                                :dataSource="commonCodeOptions.bankCode"
                                                :fields="commonCodeOptions.fields"
                                                :enabled="annualFeeDepositInfo.payDiv === PAY_DIV.TRANS"
                                                cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">계좌번호</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text ref="acnutNo" v-model="annualFeeDepositInfo.acnutNo" :disabled="true"/>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">카드사</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                ref="purcId"
                                                placeholder="선택"
                                                v-model="annualFeeDepositInfo.purcId"
                                                :dataSource="commonCodeOptions.purcId"
                                                :fields="commonCodeOptions.purcIdFields"
                                                :enabled="annualFeeDepositInfo.payDiv === PAY_DIV.CARD"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">카드번호</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text ref="cardNo" v-model="annualFeeDepositInfo.cardNo" :disabled="true"/>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">카드유효기간</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-maskedtextbox
                                                ref="validTerm"
                                                class="e-input"
                                                type="text"
                                                v-model="annualFeeDepositInfo.validTerm"
                                                placeholder="MM/YY"
                                                mask="00/00"
                                                :enabled="annualFeeDepositInfo.payDiv === PAY_DIV.CARD"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">승인번호</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text ref="approvalNo" v-model="annualFeeDepositInfo.approvalNo" :disabled="true"/>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>

                                  <li class="field">
                                    <div class="title">주중라운딩</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-number :disabled="true" ref="weekdayGolf" v-model="annualFeeDepositInfo.weekdayGolf"/>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">주말라운딩</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-number :disabled="true" ref="weekendGolf" v-model="annualFeeDepositInfo.weekendGolf"/>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">주중숙박</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-number :disabled="true" ref="weekdayStay" v-model="annualFeeDepositInfo.weekdayStay"/>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">주말숙박</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-number :disabled="true" ref="weekendStay" v-model="annualFeeDepositInfo.weekendStay"/>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field" style="width: calc(100% + 1px)">
                                    <div class="title">유효기간</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-date-range disabled="true" ref="expirDateRange" type="lookup-condition" v-model="annualFeeDepositInfo.expirDateRange"/>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field" style="width: calc(100% + 1px)">
                                    <div class="title">비고</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form"><input-textarea ref="remarks" :rows="2" v-model="annualFeeDepositInfo.remarks"/></div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </section>
                      </article>
                    </div>
                    <div class="section-body" >
                      <section class="article-section section-01" >
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">{{ gridField2.title }}</div>
                            <div class="header-caption">[{{ gridField2.count }}건]</div>
                          </div>
                          <div class="header-right">
                            <ul class="header-button">
                              <li class="save keyColor">
                                <erp-button button-div="SAVE" :is-key-color="true" :ignore="!isMemberAnnualFeeDetailViewOpened" @click.native="onClickDetailSMS">SMS전송</erp-button>
                              </li>
                              <li class="add">
                                <erp-button button-div="SAVE" @click.native="onClickAddRow">행추가</erp-button>
                              </li>
                              <li class="del">
                                <erp-button button-div="SAVE" @click.native="onClickDelRow">행삭제</erp-button>
                              </li>
                              <li class="file">
                                <erp-button button-div="SAVE" @click.native="onClickExcel2">Excel</erp-button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="section-body">
                          <ejs-grid-wrapper style="height: 90%"
                                            ref="grid2"
                                            v-bind ="initGrid2"
                                            @actionComplete="gridComplete2"
                          />
                        </div>
                      </section>
                    </div>
                  </section>
                </div>
              </article>

            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button button-div="SAVE" :is-key-color="true" v-if="isMemberAnnualFeeDetailViewOpened" @click.native="onClickMemberAnnualFeeDetailSave">저장</erp-button>
            </li>
            <li class="confirm keyColor">
              <erp-button buttonDiv="SAVE" v-if="isMemberAnnualFeeDetailViewOpened" @click.native="onMemberAnnualFeeDtlPopupConfirm" :isIconCustom="true">확인</erp-button>
            </li>
            <li class="close">
              <erp-button buttonDiv="CLOSE" @click.native="onMemberAnnualFeeDtlPopupClosed" :isIconCustom="true">닫기</erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <sms-send-popup
        v-if="isSmsSendPopupOpen"
        ref="smsSendPopup"
        @popupClosed="smsSendPopupClosed"
    />
  </div>
</template>
<script>
import ErpButton from "@/components/button/ErpButton.vue";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import commonMixin from "@/views/layout/mixin/commonMixin";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {numberWithCommas} from "@/utils/number";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {
  commonCodesGetCommonCode,
  commonCodesGetCommonCodeByIdxAttrb,
  commonCodesGetComName,
  commonCodesGetStandardInfo
} from "@/utils/commonCodes";
import {
  Aggregate, Edit,
  ExcelExport,
  Filter,
  ForeignKey,
  Group,
  Page,
  Resize,
  VirtualScroll
} from "@syncfusion/ej2-vue-grids";
import {gridUtilGetMemberNoColumnAccess} from "@/utils/gridUtil";
import InputText from "@/components/common/text/InputText.vue";
import InputDateRange from "@/components/common/datetime/InputDateRange.vue";
import InputNumber from "@/components/common/InputNumber.vue";
import InputDate from "@/components/common/datetime/InputDate.vue";
import {getFormattedDate} from "@/utils/date";
import {getStoreBsnMstInfo} from "@/api/bizCodeManagement";
import GolfERPService from "@/service/GolfERPService";
import { validateFormRefs } from "@/utils/formUtil";
import InputTextarea from "@/components/common/text/InputTextarea.vue";
import SmsSendPopup from "@/views/common/SmsSendPopup.vue";

export default {
  name: "MemberAnnualFeeRegistrationPopup",
  components: {
    SmsSendPopup,
    InputTextarea,
    InputDate,
    InputNumber,
    InputDateRange,
    InputText,
    ejsGridWrapper,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  filters: {
    numberWithCommas,
  },
  created() {
  },
  data() {
    return {
      isSmsSendPopupOpen: false,
      PAY_DIV: {
        CARD: "CARD",
        TRANS: "TRANS",
        CASH: "CASH",
      },
      selectRow:{},
      searchMembersFilterOptions:{
        memberNo:"",
        memberName:"",
        endOfHpNumber:""
      },
      isMemberAnnualFeeDetailViewOpened: false,
      grid: [Aggregate, Filter, Resize, Group, ExcelExport, ForeignKey, VirtualScroll, Page,],
      membershipFeeDepositListPageSettings: {pageSize: 25},
      selectionSettings: {
        type: "Single",
        mode: "Both",
        enableToggle: false,
        persistSelection: false,
      },
      membershipFeeDepositList: [],
      membershipFeeDepositListGridColumn: [
        {field: 'annualFeeId', type: 'string', isPrimaryKey: true, visible: false,},
        {field: 'memberNo', headerText: '회원번호', type: 'string', valueAccessor: (field, data) => gridUtilGetMemberNoColumnAccess(field, data), width: 95, textAlign: 'center',},
        {field: 'memberName', headerText: '회원명', type: 'string',width: 95, textAlign: 'center',},
        {
          headerText: "라운딩",
          columns: [
            {field: 'weekdayGolf2', textAlign: 'right', width: 50, visible: true, allowEditing: true, type: 'number', headerText: '주중', isNumericType: true, inputNumberProperty: {allowDot: false, maxUnderDotLength: 0},},
            {field: 'weekendGolf2', textAlign: 'right', width: 50, visible: true, allowEditing: true, type: 'number', headerText: '주말', isNumericType: true, inputNumberProperty: {allowDot: false, maxUnderDotLength: 0},},
          ]
        },
        {
          headerText: "숙박",
          columns: [
            {field: 'weekdayStay2', textAlign: 'right', width: 50, visible: true, allowEditing: true, type: 'number', headerText: '주중', isNumericType: true, inputNumberProperty: {allowDot: false, maxUnderDotLength: 0},},
            {field: 'weekendStay2', textAlign: 'right', width: 50, visible: true, allowEditing: true, type: 'number', headerText: '주말', isNumericType: true, inputNumberProperty: {allowDot: false, maxUnderDotLength: 0},},
          ]
        },
        {field: 'annualFeeDiv', headerText: '회원권종류', type: 'string', width: 110, textAlign: 'center', isCommonCodeField: true, groupCode: "ANNUAL_FEE_DIV",},
        {field: 'depositDate', headerText: '입금일자', type: Date, width: 110, textAlign: 'center',},
        {field: 'depositAmt', headerText: '입금액', type: Number, width: 110, textAlign: 'right', isNumericType: true,},
        {field: 'expirDateFrom', headerText: '유효기간(From)', type: Date, width: 125, textAlign: 'center',},
        {field: 'expirDateTo', headerText: '유효기간(to)', type: Date, width: 110, textAlign: 'center',},
        {field: 'payDiv', visible:false, headerText: '지불방법', type: 'string', width: 85, textAlign: 'center', isCommonCodeField: true, groupCode: "PAY_DIV",},
        {field: 'remarks', headerText: '비고', type: String, width: 260, textAlign: 'center',},
      ],

      annualFeeDepositInfo: {
        annualFeeId: null,     // 연회비ID
        membershipId: null, // 회원권ID
        depositDate: null,  // 입금일자
        annualFeeDiv: null, // 회원권구분
        depositAmt: 0,   // 입금액
        payDiv: null,       // 지불방법
        bankCode: null,     // 입금은행
        acnutNo: null,      // 계좌번호
        purcId: 0,          // 카드사 (매입처ID)
        cardNo: null,       // 카드번호
        validTerm: "",    // 카드유효기간
        approvalNo: null,   // 승인번호
        weekdayGolf: 0,
        weekendGolf: 0,
        weekdayStay: 0,
        weekendStay: 0,
        remarks: null,      // 비고
        expirDateRange: {
          from: null, // 유효기간 From (expirDateFrom)
          to: null,  // 유효기간 To (expirDateTo)
        },
        tgMembershipAnnualFeeDtlList:[],
      },
      //드롭다운 코드
      commonCodeOptions: {
        annualFeeDiv: commonCodesGetCommonCode("ANNUAL_FEE_DIV"),
        payDiv: commonCodesGetCommonCodeByIdxAttrb("PAY_DIV", 2, "Y"),
        bankCode: commonCodesGetCommonCode("BANK_CODE"),
        purcId: null,
        fields: {text: "comName", value: "comCode"},
        purcIdFields: {text: "purchaseName", value: "purchaseId"},
      },

      gridField2: {
        title: "사용상세",
        count: 0,
        dataSource:[]
      },
      popupData:{
        rowIndex:-1,
        annualFeeId:null,
      }
    };
  },
  computed: {
    initGrid2() {
      return {
        columns: [
          {field: 'dtlId', textAlign: 'center', width: 0, visible: false, allowEditing: false, type: 'number', headerText: 'dtlId', isPrimaryKey: true},
          {field: 'annualFeeId', type: 'string', visible: false,},
          {field: 'useDate', textAlign: 'center', width: 80, visible: true, allowEditing: true, isDateType: true, headerText: '사용일', dateProperty: {format: "YYYY-MM-DD",},},
          {
            headerText: "사용 라운딩",
            columns: [
              {field: 'weekdayGolf', textAlign: 'right', width: 50, visible: true, allowEditing: true, type: 'number', headerText: '주중', isNumericType: true, inputNumberProperty: {allowDot: false, maxUnderDotLength: 0},},
              {field: 'weekendGolf', textAlign: 'right', width: 50, visible: true, allowEditing: true, type: 'number', headerText: '주말', isNumericType: true, inputNumberProperty: {allowDot: false, maxUnderDotLength: 0},},
            ]
          },
          {
            headerText: "사용 숙박",
            columns: [
              {field: 'weekdayStay', textAlign: 'right', width: 50, visible: true, allowEditing: true, type: 'number', headerText: '주중', isNumericType: true, inputNumberProperty: {allowDot: false, maxUnderDotLength: 0},},
              {field: 'weekendStay', textAlign: 'right', width: 50, visible: true, allowEditing: true, type: 'number', headerText: '주말', isNumericType: true, inputNumberProperty: {allowDot: false, maxUnderDotLength: 0},},
            ]
          },
          {field: "remarks", textAlign: 'left', allowEditing: true, width: 250, visible: true, type: "string", headerText: "비고",},
        ],

        provides: [Aggregate, ExcelExport, Filter, Resize, Page, ForeignKey, Edit,],
        dataSource:  this.gridField2.dataSource,

        allowResizing: true,
        allowSorting: false,
        allowFiltering: false,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay: false,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        pageSettings: {pageSize: 50},
        resizeSettings: {mode: "Normal"},
        //그리드 정합성 체크
        validationRules: {
          qty: {required: true, min: 0},
        },
        aggregates: [
          {
            columns: [
              {field: 'useDate', aggregationType: 'TotalCaption', customAggregate: '사용횟수',},
              {field: "weekdayGolf", aggregationType: 'TotalSum',},
              {field: "weekendGolf", aggregationType: 'TotalSum',},
              {field: "weekdayStay", aggregationType: 'TotalSum',},
              {field: "weekendStay", aggregationType: 'TotalSum',},
            ],
          },
          {
            columns: [
              {field: 'useDate', aggregationType: 'TotalCaption', customAggregate: '남은횟수',},
              {field: "weekdayGolf", key:"remaind", aggregationType: 'TotalCaption',   customAggregate: () => {
                  const gridData = this.$refs.grid2.getBatchCurrentViewRecords();
                  const sum = gridData
                      .map(item => item.weekdayGolf||0 )
                      .reduce((acc, cur) => cur + acc, 0);
                  return this.annualFeeDepositInfo.weekdayGolf - sum;
                },
              },
              {
                field: "weekendGolf", aggregationType: 'TotalCaption', customAggregate: () => {
                  const gridData = this.$refs.grid2.getBatchCurrentViewRecords();
                  const sum = gridData
                      .map(item => item.weekendGolf || 0)
                      .reduce((acc, cur) => cur + acc, 0);
                  return this.annualFeeDepositInfo.weekendGolf - sum;
                },
              },
              {
                field: "weekdayStay", aggregationType: 'TotalCaption', customAggregate: () => {
                  const gridData = this.$refs.grid2.getBatchCurrentViewRecords();
                  const sum = gridData
                      .map(item => item.weekdayStay || 0)
                      .reduce((acc, cur) => cur + acc, 0);
                  return this.annualFeeDepositInfo.weekdayStay - sum;
                },
              },
              {
                field: "weekendStay", aggregationType: 'TotalCaption', customAggregate: () => {
                  const gridData = this.$refs.grid2.getBatchCurrentViewRecords();
                  const sum = gridData
                      .map(item => item.weekendStay || 0)
                      .reduce((acc, cur) => cur + acc, 0);
                  return this.annualFeeDepositInfo.weekendStay - sum;
                },
              },
            ],
          },
        ]
      };

    },
  },
  methods: {
    validateFormRefs,
    async showPopup(popupData = {}) {
      this.popupData = popupData;
      await this.fetchMembershipAnnualFee();
      await this.setCommonCodeOptionsPurcId();
    },
    async setCommonCodeOptionsPurcId() {
      const {value: bsnInfo} = await getStoreBsnMstInfo("FRONT");
      const placesOfCardPurchase = await GolfERPService.getPlacesOfCardPurchase(bsnInfo.vanKind);
      this.commonCodeOptions.purcId = placesOfCardPurchase.map(
          ({purchaseId, purchaseName, vanMappingList}) => {
            return {
              purchaseId: purchaseId,
              purchaseName: purchaseName,
              vanMapCode: vanMappingList[0].vanMapCode,
            };
          }
      );
    },
    init() {
      const currentYearDate = new Date();
      const nextYearDate = new Date(currentYearDate.setFullYear(currentYearDate.getFullYear() + 1));

      this.annualFeeDepositInfo.annualFeeId = null;
      this.annualFeeDepositInfo.membershipId = null;
      this.annualFeeDepositInfo.depositDate = getFormattedDate(new Date());
      this.annualFeeDepositInfo.annualFeeDiv = null;
      this.annualFeeDepositInfo.depositAmt = 0;
      this.annualFeeDepositInfo.payDiv = null;
      this.annualFeeDepositInfo.bankCode = null;
      this.annualFeeDepositInfo.acnutNo = null;
      this.annualFeeDepositInfo.purcId = null;
      this.annualFeeDepositInfo.cardNo = null;
      this.annualFeeDepositInfo.validTerm = "";
      this.annualFeeDepositInfo.approvalNo = null;
      this.annualFeeDepositInfo.remarks = null;
      this.annualFeeDepositInfo.expirDateRange.from = getFormattedDate(new Date());
      this.annualFeeDepositInfo.expirDateRange.to = getFormattedDate(nextYearDate);
      this.annualFeeDepositInfo.tgMembershipAnnualFeeDtlList = [];
      this.gridField2.dataSource=this.annualFeeDepositInfo.tgMembershipAnnualFeeDtlList;

    },
    async fetchMembershipAnnualFee() {
      const dataSource = await GolfErpAPI.fetchMembershipAnnualFeeList({
        memberNo: this.searchMembersFilterOptions.memberNo,
        memberName: this.searchMembersFilterOptions.memberName,
        endOfHpNumber: this.searchMembersFilterOptions.endOfHpNumber,
      });
      dataSource.map(item =>{
        item.weekdayGolf2 = item.useWeekdayGolf +"/" + item.weekdayGolf;
        item.weekendGolf2 = item.useWeekendGolf +"/" + item.weekendGolf;
        item.weekdayStay2 = item.useWeekdayStay +"/" + item.weekdayStay;
        item.weekendStay2 = item.useWeekendStay +"/" + item.weekendStay;
      });
      this.membershipFeeDepositList = dataSource;
    },
    async fetchMembershipAnnualFeeDetail(annualFeeId) {
      const {
        annualFeeDiv,
        depositAmt,
        depositDate,
        bankCode,
        acnutNo,
        purcId,
        cardNo,
        validTerm,
        approvalNo,
        remarks,
        expirDateFrom,
        expirDateTo,
        membershipId,
        payDiv,
        weekdayGolf,
        weekendGolf,
        weekdayStay,
        weekendStay,
        tgMembershipAnnualFeeDtlList,
      } = await GolfErpAPI.fetchMembershipAnnualFeeDetail({annualFeeId: annualFeeId});

      this.annualFeeDepositInfo.annualFeeId = annualFeeId;
      this.annualFeeDepositInfo.membershipId = membershipId === undefined ? null : membershipId;
      this.annualFeeDepositInfo.depositDate = depositDate === undefined ? null : depositDate;
      this.annualFeeDepositInfo.annualFeeDiv = annualFeeDiv === undefined ? null : annualFeeDiv;
      this.annualFeeDepositInfo.depositAmt = depositAmt === undefined ? null : depositAmt;
      this.annualFeeDepositInfo.payDiv = payDiv === undefined ? null : payDiv;
      this.annualFeeDepositInfo.bankCode = bankCode === undefined ? null : bankCode;
      this.annualFeeDepositInfo.acnutNo = acnutNo === undefined ? null : acnutNo;
      this.annualFeeDepositInfo.purcId = purcId === undefined ? null : purcId;
      this.annualFeeDepositInfo.cardNo = cardNo === undefined ? null : cardNo;
      this.annualFeeDepositInfo.validTerm = validTerm === undefined ? "" : validTerm;
      this.annualFeeDepositInfo.approvalNo = approvalNo === undefined ? null : approvalNo;
      this.annualFeeDepositInfo.remarks = remarks === undefined ? null : remarks;
      this.annualFeeDepositInfo.expirDateRange.from = expirDateFrom === undefined ? null : getFormattedDate(expirDateFrom);
      this.annualFeeDepositInfo.expirDateRange.to = expirDateTo === undefined ? null : getFormattedDate(expirDateTo);
      this.annualFeeDepositInfo.weekdayGolf = weekdayGolf === undefined ? 0 : weekdayGolf;
      this.annualFeeDepositInfo.weekendGolf = weekendGolf === undefined ? 0 : weekendGolf;
      this.annualFeeDepositInfo.weekdayStay = weekdayStay === undefined ? 0 : weekdayStay;
      this.annualFeeDepositInfo.weekendStay = weekendStay === undefined ? 0 : weekendStay;
      this.annualFeeDepositInfo.tgMembershipAnnualFeeDtlList = tgMembershipAnnualFeeDtlList === undefined ? [] : tgMembershipAnnualFeeDtlList;
      this.gridField2.dataSource=this.annualFeeDepositInfo.tgMembershipAnnualFeeDtlList;
      this.orgAnnualFeeDepositInfo = JSON.parse(JSON.stringify(this.annualFeeDepositInfo));
    },
    async onClickMembershipAnnualFeeSearch() {
      const dataSource = await GolfErpAPI.fetchMembershipAnnualFeeList({
        memberNo: this.searchMembersFilterOptions.memberNo,
        memberName: this.searchMembersFilterOptions.memberName,
        endOfHpNumber:this.searchMembersFilterOptions.endOfHpNumber,
      });
      dataSource.map(item =>{
        item.weekdayGolf2 = item.useWeekdayGolf +"/" + item.weekdayGolf;
        item.weekendGolf2 = item.useWeekendGolf +"/" + item.weekendGolf;
        item.weekdayStay2 = item.useWeekdayStay +"/" + item.weekdayStay;
        item.weekendStay2 = item.useWeekendStay +"/" + item.weekendStay;
      });
      this.totalMembershipFeeDeposit = dataSource.length ? dataSource.length : 0;
      this.membershipFeeDepositList = dataSource;
      this.init();
      this.isMemberAnnualFeeDetailViewOpened = false;
    },

    onChangePayDiv() {
      if (this.annualFeeDepositInfo.payDiv === this.PAY_DIV.CARD) {
        this.annualFeeDepositInfo.bankCode = null;
        this.annualFeeDepositInfo.acnutNo = null;
      }
      if (this.annualFeeDepositInfo.payDiv === this.PAY_DIV.TRANS) {
        this.annualFeeDepositInfo.cardNo = null;
        this.annualFeeDepositInfo.purcId = null;
        this.annualFeeDepositInfo.validTerm = null;
        this.annualFeeDepositInfo.approvalNo = null;
      }
      if (this.annualFeeDepositInfo.payDiv === this.PAY_DIV.CASH) {
        this.annualFeeDepositInfo.bankCode = null;
        this.annualFeeDepositInfo.acnutNo = null;
        this.annualFeeDepositInfo.cardNo = null;
        this.annualFeeDepositInfo.purcId = null;
        this.annualFeeDepositInfo.validTerm = null;
        this.annualFeeDepositInfo.approvalNo = null;
      }
    },
    onMemberAnnualFeeDtlPopupClosed() {
      this.$emit("popupClosed",this.popupData);
      this.$refs.memberAnnualFeeDtlPopup.hide();
    },
    onMemberAnnualFeeDtlPopupConfirm() {
      this.popupData.annualFeeId =  this.annualFeeDepositInfo.annualFeeId;
      this.$emit("popupConfirm",this.popupData);
      this.$refs.memberAnnualFeeDtlPopup.hide();
    },
    async onMembershipFeeDepositGridRowSelected(args) {
      this.selectRow = args.data;
      const annualFeeId = args.data.annualFeeId;
      await this.fetchMembershipAnnualFeeDetail(annualFeeId);
      this.isMemberAnnualFeeDetailViewOpened = true;
    },
    async onClickMemberAnnualFeeDetailSave() {
      const gridData = this.$refs.grid2.getBatchCurrentViewRecords();
      const weekSum = gridData.reduce((acc, cur) => {
        acc["weekdayGolf"] += cur["weekdayGolf"];
        acc["weekendGolf"] += cur["weekendGolf"];
        acc["weekdayStay"] += cur["weekdayStay"];
        acc["weekendStay"] += cur["weekendStay"];
      return acc;
      }, {weekdayGolf:0,weekendGolf:0,weekdayStay:0,weekendStay:0});
      if(this.annualFeeDepositInfo.weekdayGolf - weekSum["weekdayGolf"] <0
          ||this.annualFeeDepositInfo.weekendGolf - weekSum["weekendGolf"] <0
          ||this.annualFeeDepositInfo.weekdayStay - weekSum["weekdayStay"] <0
          ||this.annualFeeDepositInfo.weekendStay - weekSum["weekendStay"] <0){
        this.errorToast("남은 수량이 없습니다.");
        return;
      }

      try {
        const savedAnnualFeeId = await GolfErpAPI.membershipAnnualFeeSave({
          membershipId: !this.annualFeeDepositInfo.membershipId ? this.membershipId : this.annualFeeDepositInfo.membershipId,
          annualFeeId: !this.annualFeeDepositInfo.annualFeeId ? null : this.annualFeeDepositInfo.annualFeeId,
          depositDate: this.annualFeeDepositInfo.depositDate,
          annualFeeDiv: this.annualFeeDepositInfo.annualFeeDiv,
          depositAmt: this.annualFeeDepositInfo.depositAmt,
          payDiv: this.annualFeeDepositInfo.payDiv,
          bankCode: !this.annualFeeDepositInfo.bankCode ? null : this.annualFeeDepositInfo.bankCode,
          acnutNo: !this.annualFeeDepositInfo.acnutNo ? null : this.annualFeeDepositInfo.acnutNo,
          purcId: !this.annualFeeDepositInfo.purcId ? null : this.annualFeeDepositInfo.purcId,
          cardNo: !this.annualFeeDepositInfo.cardNo ? null : this.annualFeeDepositInfo.cardNo,
          validTerm: !this.annualFeeDepositInfo.validTerm ? null : this.annualFeeDepositInfo.validTerm,
          approvalNo: !this.annualFeeDepositInfo.approvalNo ? null : this.annualFeeDepositInfo.approvalNo,
          weekdayGolf: !this.annualFeeDepositInfo.weekdayGolf ? null : this.annualFeeDepositInfo.weekdayGolf,
          weekendGolf: !this.annualFeeDepositInfo.weekendGolf ? null : this.annualFeeDepositInfo.weekendGolf,
          weekdayStay: !this.annualFeeDepositInfo.weekdayStay ? null : this.annualFeeDepositInfo.weekdayStay,
          weekendStay: !this.annualFeeDepositInfo.weekendStay ? null : this.annualFeeDepositInfo.weekendStay,
          remarks: !this.annualFeeDepositInfo.remarks ? null : this.annualFeeDepositInfo.remarks,
          expirDateTo: this.annualFeeDepositInfo.expirDateRange.to,
          expirDateFrom: this.annualFeeDepositInfo.expirDateRange.from,
          dtlListD:this.$refs.grid2.getBatchChanges().deletedRecords,
          dtlListU:this.$refs.grid2.getBatchChanges().changedRecords,
          dtlListI:this.$refs.grid2.getBatchChanges().addedRecords,
        });
        if (this.annualFeeDepositInfo.annualFeeId) {
          await this.infoToast("수정되었습니다.");
        } else {
          this.annualFeeDepositInfo.annualFeeId = savedAnnualFeeId;
          await this.infoToast("저장되었습니다.");
        }

        this.orgAnnualFeeDepositInfo = JSON.parse(JSON.stringify(this.annualFeeDepositInfo));
        await this.fetchMembershipAnnualFee();
        // this.onClickDetailSMS();
      } catch (e) {
        console.log(e);
      }
    },
    async onClickDetailSMS() {
      console.log(this.selectRow);
      if (!this.selectRow.hpNo) {
        this.errorToast("전화번호가 없습니다");
        return;
      }

      this.isSmsSendPopupOpen = true;
      try {
        const smsSendData = {
          smsSendType: "ANNUAL_FEE_INFO",
          messageContents: await this.makeSmsSendMessage(this.selectRow),
          rcverName: this.selectRow.memberName,
          rcverInfo: this.selectRow.hpNo,
          sendKey: "0",
        };

        this.$nextTick(() => {
          this.$refs.smsSendPopup.showSmsSendPopup(smsSendData);
        });
      } catch (e) {
        console.error('e.===>', e);
        this.errorToast(e.message);
      }
    },
    async makeSmsSendMessage(data) {
      console.log('data.===>', data);
      const smsInfo = (await GolfErpAPI.fetchAllSmsWords()).filter(item => item.smsSendType === "ANNUAL_FEE_INFO");

      if (!(smsInfo.length > 0)) {
        throw new Error("SMS 문구가 존재하지 않습니다");
      }
      let smsWords = smsInfo[0].smsWords;
      const aditWords = (smsInfo[0].aditWords ? smsInfo[0].aditWords : "");

      smsWords = smsWords.replaceAll("[HEADER_MEMO]$", commonCodesGetStandardInfo("bizNameLocation") === "UP" ? commonCodesGetStandardInfo("bizName") : "");
      smsWords = smsWords.replaceAll("[NAME]$", data.memberName);
      smsWords = smsWords.replaceAll("[DATE]$", this.annualFeeDepositInfo.expirDateRange.from+" ~ "+ this.annualFeeDepositInfo.expirDateRange.to);
      smsWords = smsWords.replaceAll("[WEEKDAY_GOLF]$", `사용:${data.weekdayGolf2.split('/')[0]}/총:${data.weekdayGolf2.split('/')[1]}`); //weekdayGolf2
      smsWords = smsWords.replaceAll("[WEEKEND_GOLF]$", `사용:${data.weekendGolf2.split('/')[0]}/총:${data.weekendGolf2.split('/')[1]}`); //weekendGolf2
      smsWords = smsWords.replaceAll("[WEEKDAY_STAY]$", `사용:${data.weekdayStay2.split('/')[0]}/총:${data.weekdayStay2.split('/')[1]}`); //data.weekdayStay2
      smsWords = smsWords.replaceAll("[WEEKEND_STAY]$", `사용:${data.weekendStay2.split('/')[0]}/총:${data.weekendStay2.split('/')[1]}`); //data.weekendStay2

      let rtnMessage = smsWords + "\n" + aditWords;

      return rtnMessage;
    },
    smsSendPopupClosed() {
      this.isSmsSendPopupOpen = false;
    },
    async onClickMemberAnnualFeeDetailDelete() {
      if (!this.annualFeeDepositInfo.annualFeeId) {
        await this.errorToast(`회비입금 상세 정보가 없습니다. 신규 저장 또는 재조회 해주시기 바랍니다.`);
        return;
      }

      if (!await this.confirm("정말로 삭제하시겠습니까?")) {
        return;
      }
      try {
        await GolfErpAPI.deleteMembershipAnnualFee({
          annualFeeId: this.annualFeeDepositInfo.annualFeeId
        });
        await this.infoToast("삭제되었습니다.");
        await this.init();
        this.isMemberAnnualFeeDetailViewOpened = false;
        await this.fetchMembershipAnnualFee();
      } catch (e) {
        console.log(e);
      }

    },
    onMemberAnnualFeeDetailViewClosed() {
      this.init();
      this.isMemberAnnualFeeDetailViewOpened = false;
    },
    //엑셀
    onClickExcel(){
      this.$refs.MembershipFeeDepositDetailsGrid.excelExport({fileName: "회원권 내역.xlsx"});
    },
    //엑셀
    onClickExcel2(){
      this.$refs.grid2.excelExport({fileName: this.gridField2.title+".xlsx"});
    },
    //region 올림픽CC 1248 회원 사용 내역 상세그리드 관련
    onClickAddRow() {
      this.$refs.grid2.addRecord({
        dtlId: null,
        annualFeeId: this.annualFeeDepositInfo.annualFeeId,
        useDate: getFormattedDate(new Date()),
        weekdayGolf: 0,
        weekendGolf: 0,
        weekdayStay: 0,
        weekendStay: 0,
        remarks: ""
      });
      this.gridField2.count = this.$refs.grid2.getBatchCurrentViewRecords().length;
    },
    onClickDelRow() {
      this.$refs["grid2"].deleteRecord();
      this.gridField2.count = this.$refs.grid2.getBatchCurrentViewRecords().length;
    },
    gridComplete2() {
      this.gridField2.count = this.$refs.grid2.getGridBatchCount();
    },
    //endregion
  },
};
</script>

<style scoped>
.body-article .article-left, .body-article .article-right {
  transition: all .3s ease-out
}

.body-article .article-left {
  width: 100%
}

.body-article .article-right {
  width: 600px;
  margin: 0 -600px 0 0
}

.body-article.dev-reservation-detail-view-opened .article-left {
  width: calc(100% - 600px)
}

.body-article.dev-reservation-detail-view-opened .article-right {
  margin: 0;
}

.article-section.section-02 .section-body {
  overflow: hidden;
  border: none;
}

.article-section.section-01 .body-article {
  height: auto
}

.article-section.section-01 .body-article .article-section {
  height: auto
}

.article-section.section-01 .body-article .section-header {
  width: auto
}

.article-section.section-01 .body-article .section-body {
  overflow: visible;
  border: none
}

.article-section.section-01 .body-article {
  height: auto;
}

.article-section.section-01 .body-article .article-section {
  height: auto
}

.article-section.section-01 .body-article .section-header {
  width: auto
}

.article-section.section-01 .body-article .section-body {
  overflow: visible
}
</style>
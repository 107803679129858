<template>
  <div>
    <ejs-dialog
      ref="realtimeWaitReservationPopup"
      header="실시간예약 설정"
      :width="600"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :animationSettings="{ effect: 'None' }"
      :close="onRealtimeWaitReservationPopupClosed"
    >
      <div class="window realTimeReservation">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">실시간 오픈기간 설정</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field creationDate">
                              <!-- 필수 : required -->
                              <div class="title">생성일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group dateRange">
                                    <div class="form">
                                      <input-date-range
                                        ref="realtimeWaitDateRange"
                                        v-model="dateRange"
                                        type="body-data"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group button">
                                    <ul class="button">
                                      <li>
                                        <erp-button
                                          button-div="GET"
                                          v-on:click.native="
                                            settingStartEndDe(1)
                                          "
                                        >
                                          1개월
                                        </erp-button>
                                      </li>
                                      <li>
                                        <erp-button
                                          button-div="GET"
                                          v-on:click.native="
                                            settingStartEndDe(3)
                                          "
                                        >
                                          3개월
                                        </erp-button>
                                      </li>
                                      <li>
                                        <erp-button
                                          button-div="GET"
                                          v-on:click.native="
                                            settingStartEndDe(6)
                                          "
                                        >
                                          6개월
                                        </erp-button>
                                      </li>
                                      <li>
                                        <erp-button
                                          button-div="GET"
                                          v-on:click.native="
                                            settingStartEndDe(12)
                                          "
                                        >
                                          1년
                                        </erp-button>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field weekdaysWeekends">
                              <!-- 필수 : required -->
                              <div class="title">주중/주말</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-multiselect
                                        id="bsnCodeDropdown"
                                        ref="bsnCodeDropdown"
                                        v-model="realtimeWait.bsnCode"
                                        :dataSource=" realtimeWaitOptions.bsnCodeOptions"
                                        placeHolder="전체"
                                        :fields="realtimeWaitOptions.bsnCodeField"
                                        :showSelectAll="true"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-02">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">오픈방법/일정</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data header">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field howToOpen">
                              <!-- 필수 : required -->
                              <div class="title">오픈방법</div>
                            </li>
                            <li class="field openSchedule">
                              <!-- 필수 : required -->
                              <div class="title">오픈일정</div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                    <div class="body-data body">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field howToOpen">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            id="resveOpenMethod_1"
                                            v-model="
                                              realtimeWait.resveOpenMethod
                                            "
                                            value="DAY"
                                            @click="onClickedResveOpenMethodDay"
                                          />
                                          <i></i>
                                          <div class="label">일자별 오픈</div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field openSchedule">
                              <!-- 필수 : required -->
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group number">
                                    <div class="form">
                                      <input-number
                                        ref="resveOpenDaycnt"
                                        v-model="realtimeWait.resveOpenDaycnt"
                                        :allowDot="false"
                                        :allowMinus="false"
                                        :displayComma="false"
                                        :propMaxLength="3"
                                        :disabled="
                                          realtimeWait.resveOpenMethod !== 'DAY'
                                        "
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">일전</li>
                                  <li class="item form-group time">
                                    <div class="form">
                                      <input-time
                                        ref="resveOpenTime_day"
                                        format="HH:mm"
                                        v-model="realtimeWait.resveOpenTime"
                                        :disabled="
                                          realtimeWait.resveOpenMethod !== 'DAY'
                                        "
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">오픈</li>
                                </ul>
                                <ul class="row">
                                  <li class="item form-group number">
                                    <div class="form">
                                      <input-number
                                        ref="resveClosdeCnt_day"
                                        v-model="realtimeWait.resveClosdeCnt"
                                        :allowDot="false"
                                        :allowMinus="false"
                                        :displayComma="false"
                                        :propMaxLength="3"
                                        :disabled="
                                          realtimeWait.resveOpenMethod !== 'DAY'
                                        "
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">일전</li>
                                  <li class="item form-group time">
                                    <div class="form">
                                      <input-time
                                        ref="resveClostm_day"
                                        format="HH:mm"
                                        v-model="realtimeWait.resveClostm"
                                        :disabled="
                                          realtimeWait.resveOpenMethod !== 'DAY'
                                        "
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">마감</li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <ul class="data-content">
                            <li class="field howToOpen">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            id="resveOpenMethod_2"
                                            v-model="
                                              realtimeWait.resveOpenMethod
                                            "
                                            value="WEEK"
                                            @click="
                                              onClickedResveOpenMethodWeek
                                            "
                                          />
                                          <i></i>
                                          <div class="label">주단위 오픈</div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field openSchedule">
                              <!-- 필수 : required -->
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group date" style="width: 120px">
                                    <div class="form">
                                      <input-date
                                        ref="resveOpenDate"
                                        format="YYYY-MM-DD"
                                        v-model="realtimeWait.resveOpenDate"
                                        type="body-data"
                                        :disabled="
                                          realtimeWait.resveOpenMethod !==
                                          'WEEK'
                                        "
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group time">
                                    <div class="form">
                                      <input-time
                                        ref="resveOpenTime_week"
                                        format="HH:mm"
                                        v-model="realtimeWait.resveOpenTime"
                                        :disabled="
                                          realtimeWait.resveOpenMethod !==
                                          'WEEK'
                                        "
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">오픈</li>
                                </ul>
                                <ul class="row">
                                  <li class="item form-group number">
                                    <div class="form">
                                      <input-number
                                        ref="resveClosdeCnt_week"
                                        v-model="realtimeWait.resveClosdeCnt"
                                        :allowDot="false"
                                        :allowMinus="false"
                                        :displayComma="false"
                                        :propMaxLength="3"
                                        :disabled="
                                          realtimeWait.resveOpenMethod !==
                                          'WEEK'
                                        "
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">일전</li>
                                  <li class="item form-group time">
                                    <div class="form">
                                      <input-time
                                        ref="resveClostm_week"
                                        format="HH:mm"
                                        v-model="realtimeWait.resveClostm"
                                        :disabled="
                                          realtimeWait.resveOpenMethod !==
                                          'WEEK'
                                        "
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">마감</li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  ref="confirm"
                  buttonDiv="SAVE"
                  :is-shortcut-button="true"
                  :isIconCustom="true"
                  @click.native="saveRealtimeWaitReservationPopup"
              >
                확인
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  buttonDiv="GET"
                  @click.native="initRealtimeWaitReservationPopup"
                  :isIconCustom="true"
              >
                설정 초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="closeRealtimeWaitReservationPopup"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import { MultiSelect, CheckBoxSelection } from "@syncfusion/ej2-dropdowns";
import moment from "moment";

import {
  getFormattedDate,
  getTodayNavigationDate,
  DATE_FORMAT_YYYY_MM_DD,
} from "@/utils/date";
import { commonCodesGetStandardInfo } from "@/utils/commonCodes";
import {
  saveCalendarRealtimeSetting,
  initCalendarRealtimeSetting,
} from "@/api/calendar";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import InputTime from "@/components/common/datetime/InputTime";
import InputDate from "@/components/common/datetime/InputDate";
import InputNumber from "@/components/common/InputNumber";
import { validateFormRefs, validateFormRefsClear } from "@/utils/formUtil";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

MultiSelect.Inject(CheckBoxSelection);

export default {
  name: "RealtimeWaitReservationPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputNumber,
    InputDate,
    InputTime,
    InputDateRange,
    ErpButton
  },
  data() {
    return {
      realtimeWait: {
        startDe: null,
        endDe: null,
        bsnCode: null,
        resveOpenMethod: commonCodesGetStandardInfo("resveOpenMethod"),
        resveOpenDaycnt: commonCodesGetStandardInfo("resveOpenDayCnt"),
        resveOpenTime: commonCodesGetStandardInfo("resveOpenTime"),
        resveClosdeCnt: commonCodesGetStandardInfo("resveClosdeCnt"),
        resveClostm: commonCodesGetStandardInfo("resveClostm"),
        resveOpenDate: null,
      },
      realtimeWaitOptions: {
        bsnCodeOptions: null, // commonCodesGetCommonCode('BSN_CODE')
        bsnCodeField: { text: "comName", value: "comCode" },
      },
      validateRefListCommon: {
        realtimeWaitDateRange: {
          custom: {
            method: () => {
              return this.$refs.realtimeWaitDateRange.validate();
            },
            message: "main.validationMessage.invalidateDateRange",
          },
        },
        bsnCodeDropdown: {
          required: true,
        },
      },
      validateRefListForDay: {
        resveOpenDaycnt: { required: true },
        resveOpenTime_day: { required: true },
        resveClosdeCnt_day: { required: true },
        resveClostm_day: {
          required: true,
          custom: {
            method: () => {
              if (
                this.realtimeWait.resveOpenDaycnt <
                this.realtimeWait.resveClosdeCnt
              ) {
                // 마감 일자가 오픈 일자보다 크다면 에러
                return false;
              } else if (
                this.realtimeWait.resveOpenDaycnt ===
                this.realtimeWait.resveClosdeCnt
              ) {
                // 마감일자 == 오픈일자이면, 오픈/마감 시간을 비교
                debugger;
                const openTimeMoment = moment(
                  this.realtimeWait.resveOpenTime,
                  "HH:mm"
                );
                const closeTimeMoment = moment(
                  this.realtimeWait.resveClostm,
                  "HH:mm"
                );
                return !openTimeMoment.isAfter(closeTimeMoment);
              }
              return true;
            },
            message: "main.validationMessage.openCloseDateTimeValidate",
          },
        },
      },
      validateRefListForWeek: {
        resveOpenDate: { required: true },
        resveOpenTime_week: { required: true },
        resveClosdeCnt_week: { required: true },
        resveClostm_week: {
          required: true,
          custom: {
            method: () => {
              const openDateTimeMoment = moment(
                this.realtimeWait.resveOpenDate +
                  " " +
                  this.realtimeWait.resveOpenTime,
                "YYYY-MM-DD HH:mm"
              );
              const closeDateTimeMoment = moment(
                this.realtimeWait.startDe + " " + this.realtimeWait.resveClostm,
                "YYYY-MM-DD HH:mm"
              ).subtract(this.realtimeWait.resveClosdeCnt, "day");
              if (openDateTimeMoment.isAfter(closeDateTimeMoment)) {
                return false;
              }
              return true;
            },
            message: "main.validationMessage.openCloseDateTimeValidate",
          },
        },
      },
    };
  },
  async created() {
    this.realtimeWait.resveOpenDate = await getTodayNavigationDate(
      DATE_FORMAT_YYYY_MM_DD
    );

    this.realtimeWaitOptions.bsnCodeOptions = [
      { comCode: "WEEKDAY", comName: "주중" },
      { comCode: "WEEKEND", comName: "주말" },
    ];
    this.realtimeWait.bsnCode =  ["WEEKDAY", "WEEKEND"];
  },
  computed: {
    dateRange: {
      get() {
        return {
          from: this.realtimeWait.startDe,
          to: this.realtimeWait.endDe,
        };
      },
      set(dateRange) {
        this.realtimeWait.startDe = dateRange.from;
        this.realtimeWait.endDe = dateRange.to;
      },
    },
  },
  mounted() {
    setTimeout(() => {
      this.$refs.confirm.$el.focus();
    }, 1);
  },
  methods: {
    validateFormRefs,
    validateFormRefsClear,
    async showRealtimeWaitReservationPopup(resveDateFrom, resveDateTo) {
      this.$refs.realtimeWaitReservationPopup.show();
      this.realtimeWait.startDe = resveDateFrom;
      if (resveDateTo) {
        // resveDateTo 값이 있으면 그 값으로, 없으면 resveDateFrom 으로부터 3개월 후로 설정
        this.realtimeWait.endDe = resveDateTo;
      } else {
        this.settingStartEndDe(3);
      }
      await this.$nextTick();
      setTimeout(() => this.$refs.realtimeWaitDateRange?.focus(), 100);
    },
    closeRealtimeWaitReservationPopup() {
      this.$refs.realtimeWaitReservationPopup.hide();
    },
    settingStartEndDe(month) {
      if (!this.realtimeWait.startDe) {
        this.errorToast("시작 날짜를 입력해 주십시오");
      } else {
        const newEndDeDate = new Date(this.realtimeWait.startDe);
        newEndDeDate.setMonth(newEndDeDate.getMonth() + month);

        this.realtimeWait.endDe = getFormattedDate(newEndDeDate);
      }
    },
    saveRealtimeWaitReservationPopup: async function () {
      if (!this.validate()) {
        return;
      }

      if (
        await this.confirm(
          this.realtimeWait.startDe +
            " ~ " +
            this.realtimeWait.endDe +
            " 실시간 오픈기간을 설정하시겠습니까?"
        )
      ) {
        saveCalendarRealtimeSetting(this.realtimeWait)
          .then(() => {
            this.$emit("popupClosed", { isReload: true });
            this.$refs.realtimeWaitReservationPopup.hide();
          })
          .catch((error) => {
            console.log("saveCalendarRealtimeSetting.err.===>", error);
          });
      }
    },
    onRealtimeWaitReservationPopupClosed() {
      this.$emit("popupClosed");
    },
    initRealtimeWaitReservationPopup: async function () {
      if (!this.validateFormRefs(this.validateRefListCommon)) {
        return;
      }

      if (
        await this.confirm(
          this.realtimeWait.startDe +
            " ~ " +
            this.realtimeWait.endDe +
            " 실시간 오픈기간을 초기화 하시겠습니까?"
        )
      ) {
        initCalendarRealtimeSetting(this.realtimeWait)
          .then(() => {
            this.$emit("popupClosed", { isReload: true });
            this.$refs.realtimeWaitReservationPopup.hide();
          })
          .catch((error) => {
            console.log("initCalendarRealtimeSetting.err.===>", error);
          });
      }
    },
    onClickedResveOpenMethodDay() {
      this.validateFormRefsClear(this.validateRefListForDay);
      this.validateFormRefsClear(this.validateRefListForWeek);
    },
    onClickedResveOpenMethodWeek() {
      this.validateFormRefsClear(this.validateRefListForDay);
      this.validateFormRefsClear(this.validateRefListForWeek);
    },
    validate() {
      return this.realtimeWait.resveOpenMethod === "DAY"
        ? this.validateFormRefs({
            ...this.validateRefListCommon,
            ...this.validateRefListForDay,
          })
        : this.validateFormRefs({
            ...this.validateRefListCommon,
            ...this.validateRefListForWeek,
          });
    },
  },
};
</script>

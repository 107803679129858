<template>
  <div>
    <ejs-dialog
      ref="reservationHistoryPopup"
      :header="`골프 예약 히스토리`"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :close="onReservationHistoryPopupClosed"
      width="1180"
      height="800"
      :animationSettings="{ effect: 'None' }"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">예약일자</div>
                    <ul class="content">
                      <li class="item text">
                        {{ searchConditions.resveDate }}
                      </li>
                      <li class="item text">
                        ({{ searchConditions.resveDayOfTheWeek }})
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">코스</div>
                    <div class="content">
                      <div class="item text">
                        {{
                          commonCodesGetComName(
                            "COURSE_CODE",
                            searchConditions.resveCourse
                          )
                        }}
                      </div>
                    </div>
                  </li>
                  <li class="field">
                    <div class="title">시간</div>
                    <div class="content">
                      <div class="item text">
                        {{ searchConditions.resveTime }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <section class="article-section section-01">
                  <div class="section-body" style="border: none">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="reservHistoryGrid"
                        :provides="provides"
                        :dataSource="resveHistoryInfoList"
                        :columns="resveHistoryColumns"
                        @queryCellInfo="resveHistoryQueryCellInfo"
                      >
                      </ejs-grid-wrapper>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button
                button-div="CLOSE"
                @click.native="closeReservationInfoPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>
<script>
import ErpButton from "@/components/button/ErpButton.vue";
import { getReservationTimeHistoryPage } from "@/api/reservation";
import { commonCodesGetComName } from "@/utils/commonCodes";
import { ForeignKey, Resize } from "@syncfusion/ej2-vue-grids";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import { getFormattedPhoneNumber } from "@/utils/string";
import {gridUtilGetMemberNoColumnAccess} from '@/utils/gridUtil';

export default {
  name: "ReservationHistoryPopup",
  components: { ejsGridWrapper,ErpButton },
  data() {
    return {
      provides: [Resize, ForeignKey],
      commonCodeField: { text: "comName", value: "comCode" },
      resveHistoryInfoList: [],
      resveHistoryColumns: [
        {
          field: "contentResveNo",
          headerText: "예약번호",
          allowEditing: false,
          type: "string",
          textAlign: "center",
          allowSorting: true,
          isPrimaryKey: true,
          width: 100,
        },
        {
          field: "resveName",
          headerText: "예약자명",
          allowEditing: false,
          type: "string",
          allowSorting: true,
          width: 90,
        },
        {
          field: "contentMemberNo",
          headerText: "회원번호",
          allowEditing: false,
          type: "string",
          textAlign: "center",
          allowSorting: true,
          width: 90,
          valueAccessor: (field, data) =>
            gridUtilGetMemberNoColumnAccess(field, data),
        },
        {
          field: "channelName",
          headerText: "예약채널",
          allowEditing: false,
          type: "string",
          textAlign: "center",
          allowSorting: true,
          width: 90,
          valueAccessor: (field, data) => {
            if (data["resveChangeDiv"] === "CANCEL") {
              return commonCodesGetComName("RESVE_CHANNEL", data["contentCancelChannel"]);
            } else {
              return commonCodesGetComName("RESVE_CHANNEL", data["contentResveChannel"]);
            }
          },
        },
        {
          field: "contentGrpName",
          headerText: "단체명",
          allowEditing: false,
          type: "string",
          allowSorting: true,
          width: 90,
        },
        {
          field: "contentMemberDiv",
          headerText: "회원구분",
          allowEditing: false,
          type: "string",
          textAlign: "center",
          allowSorting: true,
          isCommonCodeField: true,
          groupCode: "MEMBER_DIV",
          width: 90,
        },
        {
          field: "contentContactTel",
          headerText: "연락처",
          allowEditing: false,
          type: "string",
          textAlign: "center",
          allowSorting: true,
          width: 100,
        },
        {
          field: "contentTimeDiv",
          headerText: "타임구분",
          allowEditing: false,
          type: "string",
          textAlign: "center",
          allowSorting: true,
          isCommonCodeField: true,
          groupCode: "TIME_DIV",
          width: 90,
        },
        {
          field: "resveChangeDiv",
          headerText: "변경구분",
          allowEditing: false,
          type: "string",
          textAlign: "center",
          allowSorting: true,
          isCommonCodeField: true,
          groupCode: "RESVE_CHANGE_DIV",
          width: 90,
        },
        {
          field: "insertName",
          headerText: "변경자",
          allowEditing: false,
          type: "string",
          allowSorting: true,
          width: 90,
        },
        {
          field: "insertIp",
          headerText: "변경자IP",
          allowEditing: false,
          type: "string",
          allowSorting: true,
          width: 120,
        },
        {
          field: "insertDt",
          headerText: "변경일시",
          allowEditing: false,
          format: { type: "date", format: "yyyy-MM-dd HH:mm" },
          type: "date",
          textAlign: "center",
          allowSorting: true,
          width: 120,
        },
        {
          field: "resveChangeResn",
          headerText: "변경사유",
          allowEditing: false,
          type: "string",
          width: 120,
        },
        {
          field: "contentPromtnName",
          headerText: "프로모션",
          allowEditing: false,
          type: "string",
          allowSorting: true,
          width: 90,
        },
        {
          field: "contentTeamGreenFee",
          headerText: "팀그린피",
          allowEditing: false,
          type: "number",
          format: "N0",
          textAlign: "right",
          allowSorting: true,
          width: 90,
        },
        {
          field: "contentResveRemarks",
          headerText: "예약비고",
          allowEditing: false,
          type: "string",
          allowSorting: true,
          width: 90,
        },
        {
          field: "contentSmsSendFlag",
          headerText: "SMS전송",
          allowEditing: false,
          type: "boolean",
          textAlign: "center",
          displayAsCheckBox: "true",
          allowSorting: true,
          width: 90,
        },
        {
          field: "contentWebOpenFlag",
          headerText: "웹오픈",
          allowEditing: false,
          type: "boolean",
          textAlign: "center",
          displayAsCheckBox: "true",
          allowSorting: true,
          width: 90,
        },
        {
          field: "contentAgncyOpenFlag",
          headerText: "대행사오픈",
          allowEditing: false,
          type: "boolean",
          textAlign: "center",
          displayAsCheckBox: "true",
          allowSorting: true,
          width: 90,
        },
      ],
      searchConditions: {
        timeId: null, // id
        resveDate: null, // 예약일자
        resveDayOfTheWeek: null, //예약요일
        resveCourse: null, // 예약코스
        resveTime: null, // 예약시간
      },
    };
  },
  methods: {
    commonCodesGetComName: commonCodesGetComName,
    showReservationHistoryPopup(popupData) {
      this.searchConditions = popupData;
      this.searchConditions.resveDayOfTheWeek = commonCodesGetComName(
        "DW_CODE",
        String(new Date(popupData.resveDate).getDay() + 1)
      );

      this.$refs.reservationHistoryPopup.show();
      this.getReservationTimeHistoryPage();
    },
    closeReservationInfoPopup() {
      this.$refs.reservationHistoryPopup.hide();
    },
    onViewButtonClicked() {
      this.getReservationTimeHistoryPage();
    },
    onReservationHistoryPopupClosed() {
      this.$emit("popupClosed");
    },
    getReservationTimeHistoryPage() {
      getReservationTimeHistoryPage(this.searchConditions.timeId)
        .then((response) => {
          this.resveHistoryInfoList = response.value.reservationTimeHistoryList.map(data => ({
            ...data,
            contentContactTel: getFormattedPhoneNumber(data.contentContactTel)
          }));
        })
        .catch((error) => {
          console.error("getReservationHistoryPage.error ==>", error);
        });
    },
    resveHistoryQueryCellInfo(args) {
      const {
        cell,
        column: { field, type },
        data,
      } = args;
      if (field === "resveChangeDiv") {
        if (data.resveChangeDiv === "CANCEL") {
          cell.style.color = "#FF0000";
        } else {
          cell.style.color = "#000000";
        }
      }
      if (type.toLowerCase() === "number" && !data[field]) {
        cell.innerText = "-";
      }
    },
  },
};
</script>

<style scoped></style>

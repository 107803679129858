<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              {{ labels.bsnDate }}
            </div>
            <ul class="content">
              <li class="item date">
                <input-date
                    ref="searchConditionsDatePicker"
                    v-model="now"
                    format="YYYY-MM"
                    depth="Year"
                    type="lookup-condition"
                    :notClear="true"
                    @change="onChangeInputDate"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.caddieName }}
            </div>
            <ul class="content">
              <li class="item input">
                <input-text
                    v-model.trim="searchConditions.caddieName"
                    @focus="searchValueClear"
                    @keydown.native="searchValueKeyDown"
                />
              </li>
            </ul>
            <ul class="content">
              <li class="item button">
                <ul class="button">
                  <li class="search">
                    <erp-button
                        button-div="GET"
                        @click.native="onSearchCaddieInfo"
                    >
                      검색
                    </erp-button>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              @click.native="onViewCaddieIncomeAttendStatus"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              button-div="GET"
              :is-icon-custom="true"
              @click.native="searchDetailPopupOpen"
          >
            상세검색
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">캐디{{ isIncomePage ? '수입' : '근태' }} 현황</div>
              <div class="header-caption">[{{ count }}건]</div>
              <ul class="header-label">
                <li class="field">
                  <div class="title">출발시간</div>
                  <ul class="content">
                    <li class="item">
                      <i style="background-color: red;"
                      ></i>
                      <div class="label">9홀 이하</div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="excel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid caddieIncomeAttendStatusGrid">
              <ejs-grid-wrapper
                  ref="caddieIncomeAttendStatusGrid"
                  :allowExcelExport="true"
                  :allowPaging="true"
                  :allowGrouping="true"
                  :allowResizing="true"
                  :provides="grid"
                  :pageSettings="pageSettings"
                  :groupSettings="groupSettings"
                  :columns="getColumns"
                  :dataSource="caddieIncomeAttendStatusList"
                  @headerCellInfo="caddieIncomeAttendStatusGridHeaderCellInfo"
                  @queryCellInfo="caddieIncomeAttendStatusGridQueryCellInfo"
                  @actionComplete="caddieIncomeAttendStatusGridActionComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <ejs-dialog
        ref="searchDetailPopup"
        :header="`상세검색`"
        :allowDragging="true"
        :showCloseIcon="true"
        width="387"
        :animationSettings="{ effect: 'None' }"
        :isModal="false"
        :visible="false"
        enableResize="true"
        v-show="isSearchDetailPopupOpen"
    >
      <div class="window lookupDetail-caddieInfoRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li
                    class="field"
                    v-for="(item,index) in searchDetailList" :key="index"
                >
                  <div class="title">{{ labels[item] }}</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-multiselect
                          :ref="`${item}MultiSelect`"
                          cssClass="lookupDetail-condition-multiselect"
                          placeHolder="전체"
                          v-model="searchConditions[item]"
                          :dataSource="searchOptions[item]"
                          :fields="commonCodeFields"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  button-div="GET"
                  :ignore="isPopupOpened"
                  :is-shortcut-button="true"
                  @click.native="onViewCaddieIncomeAttendStatus">
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="searchDetailInit">
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="searchDetailPopupClose">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <caddie-select-popup
        v-if="isCaddieSelectPopupOpen"
        ref="caddieSelectPopup"
        @popupClosed="closeCaddieSelectPopup"
        @popupConfirmed="caddieSelectPopupConfirmed"
    />
  </div>
</template>

<style scoped>
body .lookup-condition .item.date {width: 100px;}
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>
import moment from 'moment';
import {mapGetters} from 'vuex';
import {
  DATE_FORMAT_YYYY_MM,
  DATE_FORMAT_YYYY_MM_DD,
  getDayOfWeekCaptionColor,
} from '@/utils/date';
import {numberWithCommas} from '@/utils/number';
import {ExcelExport, Filter, ForeignKey, Page, Group, Resize} from '@syncfusion/ej2-vue-grids';
import ejsGridWrapper from '@/components/common/EjsGridWrapper.vue';
import InputText from '@/components/common/text/InputText';
import InputDate from '@/components/common/datetime/InputDate';
import ErpButton from "@/components/button/ErpButton.vue";
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';
import {
  commonCodesGetColorValue,
  commonCodesGetCommonCode,
  commonCodesGetCommonCodeAttrbByCodeAndIdx,
  commonCodesGetComName
} from '@/utils/commonCodes';
import CaddieSelectPopup from '../popup/CaddieSelectPopup';

export default {
  name: 'CaddieIncomeAttendStatus',
  components: {
    ejsGridWrapper,
    InputText,
    InputDate,
    ErpButton,
    CaddieSelectPopup,
  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  props: {
    isIncomePage: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      count: 0,
      labels: {
        bsnDate: '년월',
        caddieName: '캐디명',
        caddieEmployDiv: '재직구분',
        caddieGroupDiv: '조구분',
        caddieWorkDiv: '근무구분',
        caddieGrade: '캐디등급',
      },
      searchConditions: {
        caddieName: null,
        caddieId: null,
        caddieEmployDiv: ['EMPLOY'],
        caddieGroupDiv: null,
        caddieWorkDiv: null,
        caddieGrade: null,
      },
      searchOptions: {
        caddieEmployDiv: commonCodesGetCommonCode('CADDIE_EMPLOY_DIV', true),
        caddieGroupDiv: commonCodesGetCommonCode('CADDIE_GROUP_DIV', true),
        caddieWorkDiv: commonCodesGetCommonCode('CADDIE_WORK_DIV', true),
        caddieGrade: commonCodesGetCommonCode('CADDIE_GRADE', true),
      },
      searchDetailList: [
        'caddieEmployDiv',
        'caddieGroupDiv',
        'caddieWorkDiv',
        'caddieGrade',
      ],
      commonCodeFields: {text: 'comName', value: 'comCode'},
      now: null,
      bsnDate: {
        from: null,
        to: null,
      },
      endOfMonth: null,
      grid: [
        Filter,
        Resize,
        Page,
        Group,
        ExcelExport,
        ForeignKey,
      ],
      caddieIncomeAttendStatusGridColumn: [
        {
          field: '_rid',
          type: 'number',
          isPrimaryKey: true,
          visible: false,
        },
        {
          field: 'caddieName',
          headerText: '캐디명',
          width: 90,
          type: 'string',
        },
        {
          field: 'caddieGroupDiv',
          headerText: '조번호',
          textAlign: 'center',
          width: 70,
          type: 'string',
        },
        {
          field: 'caddieNo',
          headerText: '캐디번호',
          textAlign: 'center',
          width: 70,
          type: 'number',
        },
        {
          field: 'sexCode',
          headerText: '성별',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'SEX_CODE',
          width: 70,
          type: 'string',
        },
        {
          field: 'caddieWorkDiv',
          headerText: '근무구분',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'CADDIE_WORK_DIV',
          width: 90,
          type: 'string',
        },
        {
          field: 'caddieEmployDiv',
          headerText: '재직구분',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'CADDIE_EMPLOY_DIV',
          width: 90,
          type: 'string',
        },
        {
          field: 'entryDate',
          headerText: '입사일자',
          textAlign: 'center',
          width: 90,
          type: 'string',
        },
        {
          field: 'workCnt',
          headerText: '근무일수',
          textAlign: 'center',
          width: 90,
          type: 'string',
        },
        {
          field: 'remarks',
          visible: false,
          headerText: '비고',
          textAlign: 'left',
          width: 150,
          type: 'string',
        },
        {
          field: 'roundCnt',
          headerText: '라운드횟수',
          textAlign: 'right',
          isNumericType: true,
          width: 90,
          type: 'number',
          visible: this.isIncomePage,
        },
        {
          field: 'caddieFee',
          headerText: '수입금액',
          textAlign: 'right',
          isNumericType: true,
          width: 90,
          type: 'number',
          visible: this.isIncomePage,
        },
        {
          isAttendField: true,
          columns: [],
        },
      ],
      calenderList: [],
      caddieIncomeAttendStatusList: [],
      tempHeaderCellInfoList: [],
      pageSettings: {pageSize: 50},
      groupSettings: {
        columns: [],
        showDropArea: false,
      },
      isCaddieSelectPopupOpen: false,
      isSearchDetailPopupOpen: false,
    };
  },
  watch: {
    $route() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
  },
  async created() {
    await this.initialize();
  },
  computed: {
    ...mapGetters(['username']),
    isPopupOpened() {
      return (
          this.isCaddieSelectPopupOpen ||
          this.isSearchDetailPopupOpen
      );
    },
    getAuth() {
      return this.isIncomePage
          ? 'gameProgressCaddiIncomStatusGet'
          : 'gameProgressCaddiAttendStatusGet';
    },
    getColumns() {
      const gridColumns = JSON.parse(JSON.stringify(this.caddieIncomeAttendStatusGridColumn));
      const attendCol = gridColumns
          .find(item => item.isAttendField);
      const endOfMonth = moment(this.endOfMonth || this.now).endOf('month');
      attendCol.headerText = endOfMonth.format('YYYY년 MM월');
      for (let i = 1; i <= endOfMonth.format('D'); i++) {
        const block = {
          field: `days${i}`,
          headerText: `${i}`,
          textAlign: 'center',
          width: 55,
          type: 'string',
          allowFiltering: false,
          allowSorting: false,
        };
        attendCol.columns.push(block);
      }
      return gridColumns;
    },
  },
  methods: {
    getDayOfWeekCaptionColor,
    commonCodesGetComName,
    commonCodesGetColorValue,
    numberWithCommas,
    async initialize() {
      this.now = moment().format(DATE_FORMAT_YYYY_MM);
      this.bsnDate = {
        from: moment().set('date', 1).format(DATE_FORMAT_YYYY_MM_DD),
        to: moment().endOf('month').format(DATE_FORMAT_YYYY_MM_DD),
      };
      await this.fetch();
    },
    async onViewCaddieIncomeAttendStatus() {
      await this.fetch();
    },
    onChangeInputDate(args) {
      const date = moment(args.data);
      this.bsnDate = {
        from: date.set('date', 1).format(DATE_FORMAT_YYYY_MM_DD),
        to: date.endOf('month').format(DATE_FORMAT_YYYY_MM_DD),
      };
    },
    searchValueClear() {
      this.searchConditions.caddieName = null;
      this.searchConditions.caddieId = null;
    },
    searchValueKeyDown(event) {
      this.searchConditions.caddieId = null; //동명이인 팝업을 거치지 않고 검색할시 기존에 셋팅된 Id를 제거
      if (event.key === 'Enter') {
        this.fetch();
      }
    },
    onSearchCaddieInfo() {
      this.openCaddieSelectPopup(this.searchConditions.caddieName, 'textField');
    },
    searchDetailInit() {
      this.searchDetailList.forEach(item => this.searchConditions[item] = null);
      this.searchConditions.caddieEmployDiv = ['EMPLOY'];
    },
    searchDetailPopupOpen() {
      this.isSearchDetailPopupOpen = true;
      this.$refs.searchDetailPopup.show();
    },
    searchDetailPopupClose() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
    openCaddieSelectPopup(caddieName, field, rowIndex) {
      this.isCaddieSelectPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.caddieSelectPopup.showPopup(caddieName, field, rowIndex);
      });
    },
    closeCaddieSelectPopup() {
      this.isCaddieSelectPopupOpen = false;
    },
    caddieSelectPopupConfirmed(data) {
      this.isCaddieSelectPopupOpen = false;
      if (data.selectedRowData) {
        if (data.field === 'textField') {
          this.searchConditions.caddieName = data.selectedRowData.caddieName;
          this.searchConditions.caddieId = data.selectedRowData.caddieId;
        }
      }
    },
    caddieIncomeAttendStatusGridHeaderCellInfo(args) {
      const {
        cell: {column: {field, isAttendField}},
        node,
      } = args;
      if (field === 'days1' || isAttendField) {
        node.classList.add(this.$t('className.grid.devVerticalLine'));
      }
      if (field?.includes('days')) {
        this.tempHeaderCellInfoList.push(args);
      }
    },
    caddieIncomeAttendStatusGridHeaderCellInfoAfter() {
      this.tempHeaderCellInfoList.forEach(item => {
        const {
          cell: {column: {headerText}},
          node,
        } = item;
        const getDayColorValue = (dwCode, bsnCode) => {
          return dwCode === '1' || dwCode === '7'
              ? commonCodesGetColorValue('DW_CODE', dwCode.toString())
              : bsnCode === 'WEEKEND'
                  ? commonCodesGetColorValue('BSN_CODE', bsnCode.toString())
                  : commonCodesGetColorValue('DW_CODE', dwCode.toString());
        };
        const date = this.calenderList
            .find(item => item.bsnDe === Number(headerText));
        const color = date?.dwCode && date?.bsnCode && getDayColorValue(date?.dwCode, date?.bsnCode);
        node.innerHTML = `${headerText}일<br><span style="color: ${color}">${date?.dwName}</span>`;
        node.style.textAlign = 'center';
      });
    },
    caddieIncomeAttendStatusGridQueryCellInfo(args) {
      const {
        cell,
        column: {field},
        data,
      } = args;
      const findIndex = this.caddieIncomeAttendStatusList
          .findIndex(item => item._rid === data._rid);
      if (findIndex > 0) {
        if (
            this.caddieIncomeAttendStatusList[findIndex].caddieGroupDiv !==
            this.caddieIncomeAttendStatusList[findIndex - 1].caddieGroupDiv
        ) {
          cell.classList.add(this.$t('className.grid.devReservationPartDivLine'));
        }
      }
      if ([
        'roundCnt',
        'caddieFee',
      ].includes(field) && data[field] < 1) {
        cell.innerText = '-';
      }
      if (field === 'days1') {
        cell.classList.add(this.$t('className.grid.devVerticalLine'));
      }
      if (field?.includes('days')) {
        const workTime = data[`${field}wt`];
        if (this.isIncomePage && workTime) {
          const teamHoleDiv = data[`${field}hd`] ? data[`${field}hd`].split("\n") : [];
          cell.innerHTML = workTime.split('\n').map((item, idx) => {
            const style = commonCodesGetCommonCodeAttrbByCodeAndIdx("HOLE_DIV", teamHoleDiv[idx], 3) <= 9 ? "font-weight: bold; color: red;" : "";
            return `<p style="${style}">${item}</p>`;
          }).join('');
          cell.style.fontSize = '12px';
        } else {
          const attend = data[`${field}cd`];
          const {fontColor, backgroundColor} = this.getFontColorValue(attend);
          cell.style.color = fontColor;
          cell.style.backgroundColor = backgroundColor;
          cell.innerText = commonCodesGetComName('CADDIE_ATTEND_DIV', attend);
        }
      }
    },
    caddieIncomeAttendStatusGridActionComplete() {
      this.count = numberWithCommas(
          this.$refs.caddieIncomeAttendStatusGrid?.getGridBatchCount() || 0,
      );
      this.caddieIncomeAttendStatusGridHeaderCellInfoAfter();
    },
    excel() {
      this.$refs.caddieIncomeAttendStatusGrid.excelExport();
    },

    // API
    async fetch() {
      let result = [], block = {}, _rid = 0, workCnt = 0, roundCnt = 0, caddieFee = 0;
      const {calenderList, caddieIncomeAttendStatementList} = await GolfErpAPI.fetchCaddieIncomeAttendStatus({
        bsnDateFrom: this.bsnDate.from,
        bsnDateTo: this.bsnDate.to,
        caddieName: this.searchConditions.caddieId && this.searchConditions.caddieId !== ''
            ? this.searchConditions.caddieId
            : this.searchConditions.caddieName && this.searchConditions.caddieName !== ''
                ? this.searchConditions.caddieName
                : undefined,
        caddieEmployDiv: this.searchConditions.caddieEmployDiv !== null
            ? this.searchConditions.caddieEmployDiv
            : undefined,
        caddieGroupDiv: this.searchConditions.caddieGroupDiv !== null
            ? this.searchConditions.caddieGroupDiv
            : undefined,
        caddieWorkDiv: this.searchConditions.caddieWorkDiv !== null
            ? this.searchConditions.caddieWorkDiv
            : undefined,
        caddieGrade: this.searchConditions.caddieGrade !== null
            ? this.searchConditions.caddieGrade
            : undefined,
      });
      this.endOfMonth = moment(this.bsnDate.to);
      caddieIncomeAttendStatementList.forEach(item => {
        if (item.caddieGroupDiv) {
          item.caddieGroupDiv = `${item.caddieGroupDiv}조`;
        }
        if (item.workCnt) {
          workCnt += item.workCnt;
        }
        if (item.roundCnt) {
          roundCnt += item.roundCnt;
        }
        if (item.caddieFee) {
          caddieFee += item.caddieFee;
        }
        block[`days${item.bsnDe}`] = this.isIncomePage && item.workTime
            ? item.workTime.replace('\n', ', ')
            : commonCodesGetComName('CADDIE_ATTEND_DIV', item.caddieAttendDiv);
        block[`days${item.bsnDe}cd`] = item.caddieAttendDiv;
        block[`days${item.bsnDe}wt`] = item.workTime;
        block[`days${item.bsnDe}hd`] = item.teamHoleDiv;
        if (item.bsnDe === this.endOfMonth.format('D')) {
          result.push({
            _rid: ++_rid,
            ...item,
            workCnt: workCnt
                ? `${workCnt}일`
                : null,
            roundCnt,
            caddieFee,
            ...block,
          });
          workCnt = roundCnt = caddieFee = 0;
          block = {};
        }
      });
      this.calenderList = calenderList;
      this.caddieIncomeAttendStatusList = result;
    },
    getFontColorValue(data) {
      const backgroundColor = commonCodesGetColorValue('CADDIE_ATTEND_DIV', data);
      let colorCount = 0;
      const addColorCount = v => colorCount += v < 128 ? 1 : 0;
      const {r, g, b} = this.hexToRgb(backgroundColor);
      [r, g, b].forEach(i => addColorCount(i));
      return {
        fontColor: backgroundColor && colorCount >= 2
            ? '#FFF'
            : '#000',
        backgroundColor,
      };
    },
    hexToRgb(hex) {
      if (!hex) {
        return {
          r: 0,
          g: 0,
          b: 0,
        };
      }
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
          ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
          : null;
    },
  },
};
</script>

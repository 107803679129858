<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">년도</div>
            <ul class="content">
              <li class="item" style="width: 90px;">
                <input-number
                  ref="year"
                  v-model="searchOptions.year"
                  :allowDot="false"
                  :allowMinus="false"
                  :displayComma="false"
                  :propMaxLength="4"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">구분</div>
            <ul class="content">
              <div class="item" style="width: 80px">
                <ejs-dropdownlist
                  v-model="searchOptions.salesSumDiv"
                  :dataSource="searchOptions.salesSumDivOptions"
                  :fields="commonCodeFields"
                  :allowFiltering="false"
                  cssClass="lookup-condition-dropdown"
                />
              </div>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        v-model="searchOptions.halfHoleFlag"
                      />
                      <i></i>
                      <div class="label">9홀 이하 0.5팀 적용</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              v-on:click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true"/>
      <article class="body-article">
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">월별 매출/객단가 현황</div>
              <div v-show="title.year" class="header-caption">[영업년도 : {{title.year}}년도]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      @click.native="print"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      >인쇄
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="bodyGrid"
                v-bind="bodyGridProps"
                :dataSource="monthlySalesAndUnitPrices"
                :aggregates="bodyGridAggregates"
                @headerCellInfo="onBodyGridHeaderCellInfo"
                @queryCellInfo="onBodyGridQueryCellInfo"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputNumber from "@/components/common/InputNumber";
import ReportView from '@/components/common/report/ReportView';
import ErpButton from "@/components/button/ErpButton.vue";
import {
  Aggregate,
  Resize,
  ForeignKey,
  ExcelExport,
  Page,
  Edit,
  Group,
  Filter,
} from "@syncfusion/ej2-vue-grids";

import {
  getEndOfMonth,
  getTodayNavigationDate,
  getFormattedDate,
} from "@/utils/date";
import {mapGetters} from "vuex";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {
  commonCodeGetComCodeDefaultValue,
  commonCodesGetCommonCode,
  commonCodesGetCommonCodeByIdx
} from "@/utils/commonCodes";
import { orderBy as _orderBy } from "lodash";

export default {
  name: "MonthlySalesAndUnitPrice",
  components: {
    EjsGridWrapper,
    InputNumber,
    ReportView,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      commonCodeFields: {text: 'comName', value: 'comCode'},
      searchOptions: {
        year: null,
        halfHoleFlag: false,
        salesSumDiv: commonCodeGetComCodeDefaultValue("SALES_SUM_DIV"),
        salesSumDivOptions: commonCodesGetCommonCode('SALES_SUM_DIV', true),
      },
      title: {
        year: null,
      },
      monthlySalesAndUnitPrices: [],
      monthlySalesAndUnitPricesTotal: {},
      reportSearchOptions: {}
    };
  },
  async created() {
    this.searchOptions.year = await getTodayNavigationDate("YYYY");
  },
  destroyed() {},
  computed: {
    ...mapGetters(['username']),
    isPopupOpened() {
      return false;
    },
    bodyGridProps() {
      return {
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        selectionSettings: {
          type: "Single", mode: "Both", enableToggle: false
        },
        provides: [
          Aggregate,
          Edit,
          ForeignKey,
          Group,
          Resize,
          Filter,
          Page,
          ExcelExport,
        ],
        allowSorting: false,
        allowFiltering: false,
        allowGrouping: false,
        allowExcelExport: true,
        noColumnWidth: 34,
        columns: [
          {
            field: "title",
            headerText: "구분",
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center",
          },
          {
            field: "name",
            headerText: "구분",
            type: "string",
            minWidth: 16,
            width: 98,
            textAlign: "center",
          },
          {
            field: "data01",
            headerText: "1월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data02",
            headerText: "2월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data03",
            headerText: "3월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data04",
            headerText: "4월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data05",
            headerText: "5월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data06",
            headerText: "6월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "firstHalf",
            headerText: "상반기",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 110,
            textAlign: "right",
          },
          {
            field: "data07",
            headerText: "7월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data08",
            headerText: "8월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data09",
            headerText: "9월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data10",
            headerText: "10월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data11",
            headerText: "11월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data12",
            headerText: "12월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "secondHalf",
            headerText: "하반기",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 110,
            textAlign: "right",
          },
          {
            field: "total",
            headerText: "합계",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 110,
            textAlign: "right",
          },
        ],
      };
    },
    bodyGridAggregates() {
      return [
        {
          columns: [
            {
              field: "name",
              aggregationType: "TotalCaption",
              customAggregate: "총매출",
            },
            {
              field: "data01",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.data01ByTotal,
            },
            {
              field: "data02",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.data02ByTotal,
            },
            {
              field: "data03",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.data03ByTotal,
            },
            {
              field: "data04",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.data04ByTotal,
            },
            {
              field: "data05",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.data05ByTotal,
            },
            {
              field: "data06",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.data06ByTotal,
            },
            {
              field: "data07",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.data07ByTotal,
            },
            {
              field: "data08",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.data08ByTotal,
            },
            {
              field: "data09",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.data09ByTotal,
            },
            {
              field: "data10",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.data10ByTotal,
            },
            {
              field: "data11",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.data11ByTotal,
            },
            {
              field: "data12",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.data12ByTotal,
            },
            {
              field: "firstHalf",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.firstHalfByTotal,
            },
            {
              field: "secondHalf",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.secondHalfByTotal,
            },
            {
              field: "total",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.totalByTotal,
            },
          ],
        },
        {
          columns: [
            {
              field: "name",
              aggregationType: "TotalCaption",
              customAggregate: "객단가",
            },
            {
              field: "data01",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.data01ByUnitPrice,
            },
            {
              field: "data02",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.data02ByUnitPrice,
            },
            {
              field: "data03",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.data03ByUnitPrice,
            },
            {
              field: "data04",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.data04ByUnitPrice,
            },
            {
              field: "data05",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.data05ByUnitPrice,
            },
            {
              field: "data06",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.data06ByUnitPrice,
            },
            {
              field: "data07",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.data07ByUnitPrice,
            },
            {
              field: "data08",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.data08ByUnitPrice,
            },
            {
              field: "data09",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.data09ByUnitPrice,
            },
            {
              field: "data10",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.data10ByUnitPrice,
            },
            {
              field: "data11",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.data11ByUnitPrice,
            },
            {
              field: "data12",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.data12ByUnitPrice,
            },
            {
              field: "firstHalf",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.firstHalfByUnitPrice,
            },
            {
              field: "secondHalf",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.secondHalfByUnitPrice,
            },
            {
              field: "total",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlySalesAndUnitPricesTotal?.totalByUnitPrice,
            },
          ],
        }
      ];
    },
  },
  mounted() {},
  methods: {
    async onViewButtonClicked() {
      const fromDate = this.searchOptions.year + "-01-01";
      const monthLastDay = await getEndOfMonth(Number(this.searchOptions.year), 12);
      const toDate = this.searchOptions.year + "-12-" + monthLastDay;

      const {
        tgCloseAcmtlBasics,
        tgClosePartDivs,
        tgCloseSalesStores,
      } = await GolfErpAPI.fetchMonthlySalesAndUnitPrice({
        fromDate: fromDate,
        toDate: toDate,
      });

      const monthList = ["01","02","03","04","05","06","07","08","09","10","11","12"];

      let storeCodeAttrbIdx3 = [];
      commonCodesGetCommonCodeByIdx("STORE_CODE", 3).map(item => {
        item.comCodeAttrbList.map(attrb => {
          attrb.comName = item.comName;
          storeCodeAttrbIdx3.push(attrb);
        });
      });

      const loopInfos = [
        {code: "BUSINESS_DAYS", name: "영업일수", title: "내장팀", isTitleVisible: true, isBottomDoubleLine: true, isBottomSolidLine: false, isRowSpan: true, rowSpan: 8},
        {code: "RESVE_ABLE_TEAM", name: "예약가능팀수", title: "내장팀", isTitleVisible: false, isBottomDoubleLine: false, isBottomSolidLine: false, isRowSpan: false, rowSpan: 0},
        {code: "RESVE_TEAM", name: "예약팀수", title: "내장팀", isTitleVisible: false, isBottomDoubleLine: false, isBottomSolidLine: false, isRowSpan: false, rowSpan: 0},
        {code: "RESVE_RATE", name: "예약율(%)", title: "내장팀", isTitleVisible: false, isBottomDoubleLine: true, isBottomSolidLine: false, isRowSpan: false, rowSpan: 0},
        {code: "VISIT_TEAM", name: "내장팀수", title: "내장팀", isTitleVisible: false, isBottomDoubleLine: false, isBottomSolidLine: false, isRowSpan: false, rowSpan: 0},
        {code: "VISIT_PER", name: "내장인원수", title: "내장팀", isTitleVisible: false, isBottomDoubleLine: true, isBottomSolidLine: false, isRowSpan: false, rowSpan: 0},
        {code: "TEAM_AVG_PER", name: "팀평균인원수", title: "내장팀", isTitleVisible: false, isBottomDoubleLine: false, isBottomSolidLine: false, isRowSpan: false, rowSpan: 0},
        {code: "VISIT_RATE", name: "가동율(%)", title: "내장팀", isTitleVisible: false, isBottomDoubleLine: false, isBottomSolidLine: true, isRowSpan: false, rowSpan: 0},
        {code: "GOLF_SALES_FEE", name: "입장료", title: "골프매출", isTitleVisible: true, isBottomDoubleLine: false, isBottomSolidLine: false, isRowSpan: true, rowSpan: 4},
        {code: "GOLF_SALES_RENT", name: "대여료", title: "골프매출", isTitleVisible: false, isBottomDoubleLine: true, isBottomSolidLine: false, isRowSpan: false, rowSpan: 0},
        {code: "GOLF_SALES_TOTAL", name: "합계", title: "골프매출", isTitleVisible: false, isBottomDoubleLine: true, isBottomSolidLine: false, isRowSpan: false, rowSpan: 0},
        {code: "GOLF_SALES_UNIT_PRICE", name: "객단가", title: "골프매출", isTitleVisible: false, isBottomDoubleLine: false, isBottomSolidLine: true, isRowSpan: false, rowSpan: 0},
      ];

      storeCodeAttrbIdx3
        .filter(item => item.attrb === "FOOD")
        .map((item, idx) => {
          loopInfos.push({
            code: "FOOD_SALES_".concat(item.comCode),
            name: item.comName,
            title: "식음매출",
            storeCode: item.comCode,
            isTitleVisible: idx === 0,
            isBottomDoubleLine: (idx + 1) === storeCodeAttrbIdx3.filter(item => item.attrb === "FOOD").length,
            isBottomSolidLine: false,
            isRowSpan: idx === 0,
            rowSpan: idx === 0 ? storeCodeAttrbIdx3.filter(item => item.attrb === "FOOD").length + 2 : 0
          });
        });

      loopInfos.push({code: "FOOD_SALES_TOTAL", name: "합계", title: "식음매출", isTitleVisible: false, isBottomDoubleLine: false, isBottomSolidLine: false, isRowSpan: false, rowSpan: 0});
      loopInfos.push({code: "FOOD_SALES_UNIT_PRICE", name: "객단가", title: "식음매출", isTitleVisible: false, isBottomDoubleLine: false, isBottomSolidLine: true, isRowSpan: false, rowSpan: 0});
      loopInfos.push({code: "SHOP_SALES_TOTAL", name: "상품매출", title: "프로샵", isTitleVisible: true, isBottomDoubleLine: true, isBottomSolidLine: false, isRowSpan: true, rowSpan: 2});
      loopInfos.push({code: "SHOP_SALES_UNIT_PRICE", name: "객단가", title: "프로샵", isTitleVisible: false, isBottomDoubleLine: false, isBottomSolidLine: true, isRowSpan: false, rowSpan: 0});
      loopInfos.push({code: "ETC_SALES_TOTAL", name: "기타매출", title: "기타매출", isTitleVisible: true, isBottomDoubleLine: true, isBottomSolidLine: false, isRowSpan: true, rowSpan: 2});
      loopInfos.push({code: "ETC_SALES_UNIT_PRICE", name: "객단가", title: "기타매출", isTitleVisible: false, isBottomDoubleLine: false, isBottomSolidLine: true, isRowSpan: false, rowSpan: 0});

      let monthlySalesAndUnitPrices = [];

      loopInfos.forEach(loopInfo => {
        let addData = {
          title: loopInfo.title,
          name: loopInfo.name,
          code: loopInfo.code,
        };
        const firstHalfMonth = ["01","02","03","04","05","06"];
        const secondHalfMonth = ["07","08","09","10","11","12"];
        let firstHalf = 0;
        let secondHalf = 0;
        let total = 0;

        monthList.forEach(month => {
          const tgCloseAcmtlBasic = _orderBy(tgCloseAcmtlBasics.filter(item => month === getFormattedDate(item.bsnDate, "MM")), ["bsnDate"], ["desc"])[0];
          const bsnDate = tgCloseAcmtlBasic?.bsnDate;
          const tgClosePartDivList = tgClosePartDivs.filter(item => getFormattedDate(item.bsnDate, "MM") === month);

          if (loopInfo.code === "BUSINESS_DAYS") {
            addData["data".concat(month)] = tgCloseAcmtlBasic?.totBsnDay || 0;

            if (firstHalfMonth.includes(getFormattedDate(bsnDate, "MM"))) {
              firstHalf += addData["data".concat(month)];
            } else if (secondHalfMonth.includes(getFormattedDate(bsnDate, "MM"))) {
              secondHalf += addData["data".concat(month)];
            }

            total += tgCloseAcmtlBasic?.totBsnDay || 0;
          } else if (loopInfo.code === "RESVE_ABLE_TEAM") {
            addData["data".concat(month)] = tgClosePartDivList.map(item => this.searchOptions.halfHoleFlag ? (item.resveAbleTeamHoleDiv - item.resveAditTeamHoleDiv) : (item.resveAbleTeam - item.resveAditTeam)).reduce((acc, cur) => acc + cur, 0);

            if (firstHalfMonth.includes(getFormattedDate(bsnDate, "MM"))) {
              firstHalf += addData["data".concat(month)];
            } else if (secondHalfMonth.includes(getFormattedDate(bsnDate, "MM"))) {
              secondHalf += addData["data".concat(month)];
            }

            total += addData["data".concat(month)];
          } else if (loopInfo.code === "RESVE_TEAM") {
            addData["data".concat(month)] = tgClosePartDivList.map(item => this.searchOptions.halfHoleFlag ? (item.resveTeamHoleDiv - item.resveAditTeamHoleDiv) : (item.resveTeam - item.resveAditTeam)).reduce((acc, cur) => acc + cur, 0);

            if (firstHalfMonth.includes(getFormattedDate(bsnDate, "MM"))) {
              firstHalf += addData["data".concat(month)];
            } else if (secondHalfMonth.includes(getFormattedDate(bsnDate, "MM"))) {
              secondHalf += addData["data".concat(month)];
            }

            total += addData["data".concat(month)];
          } else if (loopInfo.code === "RESVE_RATE") {
            const resveTeam = monthlySalesAndUnitPrices.filter(item => item.code === "RESVE_TEAM")[0]["data".concat(month)];
            const resveAbleTeam = monthlySalesAndUnitPrices.filter(item => item.code === "RESVE_ABLE_TEAM")[0]["data".concat(month)];

            addData["data".concat(month)] = Math.round((resveAbleTeam > 0 ? resveTeam / resveAbleTeam : 0) * 100);
          } else if (loopInfo.code === "VISIT_TEAM") {
            addData["data".concat(month)] = tgClosePartDivList.map(item => this.searchOptions.halfHoleFlag ? item.visitTeamHoleDiv : item.visitTeam).reduce((acc, cur) => acc + cur, 0);

            if (firstHalfMonth.includes(getFormattedDate(bsnDate, "MM"))) {
              firstHalf += addData["data".concat(month)];
            } else if (secondHalfMonth.includes(getFormattedDate(bsnDate, "MM"))) {
              secondHalf += addData["data".concat(month)];
            }

            total += addData["data".concat(month)];
          } else if (loopInfo.code === "VISIT_PER") {
            addData["data".concat(month)] = tgClosePartDivList.map(item => this.searchOptions.halfHoleFlag ? item.visitPerHoleDiv : item.visitPer).reduce((acc, cur) => acc + cur, 0);

            if (firstHalfMonth.includes(getFormattedDate(bsnDate, "MM"))) {
              firstHalf += addData["data".concat(month)];
            } else if (secondHalfMonth.includes(getFormattedDate(bsnDate, "MM"))) {
              secondHalf += addData["data".concat(month)];
            }

            total += addData["data".concat(month)];
          } else if (loopInfo.code === "TEAM_AVG_PER") {
            const visitTeam = monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_TEAM")[0]["data".concat(month)];
            const visitPer = monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]["data".concat(month)];

            addData["data".concat(month)] = Math.round((visitTeam > 0 ? visitPer / visitTeam : 0) * 100) / 100;
          } else if (loopInfo.code === "VISIT_RATE") {
            const visitTeam = monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_TEAM")[0]["data".concat(month)];
            const resveAbleTeam = monthlySalesAndUnitPrices.filter(item => item.code === "RESVE_ABLE_TEAM")[0]["data".concat(month)];

            addData["data".concat(month)] = Math.round((resveAbleTeam > 0 ? visitTeam / resveAbleTeam : 0) * 100);
          } else if (loopInfo.code === "GOLF_SALES_FEE") {
            const storeCodes = storeCodeAttrbIdx3.filter(item => item.attrb === "FEE").map(item => item.comCode);

            addData["data".concat(month)] =
              tgCloseSalesStores
                .filter(item => getFormattedDate(item.bsnDate, "MM") === month && storeCodes.includes(item.storeCode))
                .map(item => this.searchOptions.salesSumDiv === "NET" ? (item.netAmt + item.notaxAmt) : item.totSalesAmt)
                .reduce((acc, cur) => acc + cur, 0);

            if (firstHalfMonth.includes(getFormattedDate(bsnDate, "MM"))) {
              firstHalf += addData["data".concat(month)];
            } else if (secondHalfMonth.includes(getFormattedDate(bsnDate, "MM"))) {
              secondHalf += addData["data".concat(month)];
            }

            total += addData["data".concat(month)];
          } else if (loopInfo.code === "GOLF_SALES_RENT") {
            const storeCodes = storeCodeAttrbIdx3.filter(item => item.attrb === "RENT").map(item => item.comCode);

            addData["data".concat(month)] =
              tgCloseSalesStores
                .filter(item => getFormattedDate(item.bsnDate, "MM") === month && storeCodes.includes(item.storeCode))
                .map(item => this.searchOptions.salesSumDiv === "NET" ? (item.netAmt + item.notaxAmt) : item.totSalesAmt)
                .reduce((acc, cur) => acc + cur, 0);

            if (firstHalfMonth.includes(getFormattedDate(bsnDate, "MM"))) {
              firstHalf += addData["data".concat(month)];
            } else if (secondHalfMonth.includes(getFormattedDate(bsnDate, "MM"))) {
              secondHalf += addData["data".concat(month)];
            }

            total += addData["data".concat(month)];
          } else if (loopInfo.code === "GOLF_SALES_TOTAL") {
            const storeCodes = storeCodeAttrbIdx3.filter(item => ["FEE","RENT"].includes(item.attrb)).map(item => item.comCode);

            addData["data".concat(month)] =
              tgCloseSalesStores
                .filter(item => getFormattedDate(item.bsnDate, "MM") === month && storeCodes.includes(item.storeCode))
                .map(item => this.searchOptions.salesSumDiv === "NET" ? (item.netAmt + item.notaxAmt) : item.totSalesAmt)
                .reduce((acc, cur) => acc + cur, 0);

            if (firstHalfMonth.includes(getFormattedDate(bsnDate, "MM"))) {
              firstHalf += addData["data".concat(month)];
            } else if (secondHalfMonth.includes(getFormattedDate(bsnDate, "MM"))) {
              secondHalf += addData["data".concat(month)];
            }

            total += addData["data".concat(month)];
          } else if (loopInfo.code === "GOLF_SALES_UNIT_PRICE") {
            const golfSalesTotalData = monthlySalesAndUnitPrices.filter(item => item.code === "GOLF_SALES_TOTAL")[0];

            const golfSalesTotal = golfSalesTotalData["data".concat(month)];
            const visitPer = monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]["data".concat(month)];

            addData["data".concat(month)] = Math.round(visitPer > 0 ? golfSalesTotal / visitPer : 0);
          } else if (
            loopInfo.code.toString().startsWith("FOOD_SALES_") &&
            !loopInfo.code.toString().endsWith("_TOTAL") &&
            !loopInfo.code.toString().endsWith("_UNIT_PRICE")
          ) {
            addData["data".concat(month)] =
              tgCloseSalesStores
                .filter(item => getFormattedDate(item.bsnDate, "MM") === month && item.storeCode === loopInfo.storeCode)
                .map(item => this.searchOptions.salesSumDiv === "NET" ? (item.netAmt + item.notaxAmt) : item.totSalesAmt)
                .reduce((acc, cur) => acc + cur, 0);

            if (firstHalfMonth.includes(getFormattedDate(bsnDate, "MM"))) {
              firstHalf += addData["data".concat(month)];
            } else if (secondHalfMonth.includes(getFormattedDate(bsnDate, "MM"))) {
              secondHalf += addData["data".concat(month)];
            }

            total += addData["data".concat(month)];
          } else if (loopInfo.code === "FOOD_SALES_TOTAL") {
            const storeCodes = storeCodeAttrbIdx3.filter(item => item.attrb === "FOOD").map(item => item.comCode);

            addData["data".concat(month)] =
              tgCloseSalesStores
                .filter(item => getFormattedDate(item.bsnDate, "MM") === month && storeCodes.includes(item.storeCode))
                .map(item => this.searchOptions.salesSumDiv === "NET" ? (item.netAmt + item.notaxAmt) : item.totSalesAmt)
                .reduce((acc, cur) => acc + cur, 0);

            if (firstHalfMonth.includes(getFormattedDate(bsnDate, "MM"))) {
              firstHalf += addData["data".concat(month)];
            } else if (secondHalfMonth.includes(getFormattedDate(bsnDate, "MM"))) {
              secondHalf += addData["data".concat(month)];
            }

            total += addData["data".concat(month)];
          } else if (loopInfo.code === "FOOD_SALES_UNIT_PRICE") {
            const foodSalesTotalData = monthlySalesAndUnitPrices.filter(item => item.code === "FOOD_SALES_TOTAL")[0];

            const foodSalesTotal = foodSalesTotalData["data".concat(month)];
            const visitPer = monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]["data".concat(month)];

            addData["data".concat(month)] = Math.round(visitPer > 0 ? foodSalesTotal / visitPer : 0);
          } else if (loopInfo.code === "SHOP_SALES_TOTAL") {
            const storeCodes = storeCodeAttrbIdx3.filter(item => item.attrb === "SHOP").map(item => item.comCode);

            addData["data".concat(month)] =
              tgCloseSalesStores
                .filter(item => getFormattedDate(item.bsnDate, "MM") === month && storeCodes.includes(item.storeCode))
                .map(item => this.searchOptions.salesSumDiv === "NET" ? (item.netAmt + item.notaxAmt) : item.totSalesAmt)
                .reduce((acc, cur) => acc + cur, 0);

            if (firstHalfMonth.includes(getFormattedDate(bsnDate, "MM"))) {
              firstHalf += addData["data".concat(month)];
            } else if (secondHalfMonth.includes(getFormattedDate(bsnDate, "MM"))) {
              secondHalf += addData["data".concat(month)];
            }

            total += addData["data".concat(month)];
          } else if (loopInfo.code === "SHOP_SALES_UNIT_PRICE") {
            const shopSalesTotalData = monthlySalesAndUnitPrices.filter(item => item.code === "SHOP_SALES_TOTAL")[0];

            const shopSalesTotal = shopSalesTotalData["data".concat(month)];
            const visitPer = monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]["data".concat(month)];

            addData["data".concat(month)] = Math.round(visitPer > 0 ? shopSalesTotal / visitPer : 0);
          } else if (loopInfo.code === "ETC_SALES_TOTAL") {
            const storeCodes = storeCodeAttrbIdx3.filter(item => item.attrb === "ETC").map(item => item.comCode);

            addData["data".concat(month)] =
              tgCloseSalesStores
                .filter(item => getFormattedDate(item.bsnDate, "MM") === month && storeCodes.includes(item.storeCode))
                .map(item => this.searchOptions.salesSumDiv === "NET" ? (item.netAmt + item.notaxAmt) : item.totSalesAmt)
                .reduce((acc, cur) => acc + cur, 0);

            if (firstHalfMonth.includes(getFormattedDate(bsnDate, "MM"))) {
              firstHalf += addData["data".concat(month)];
            } else if (secondHalfMonth.includes(getFormattedDate(bsnDate, "MM"))) {
              secondHalf += addData["data".concat(month)];
            }

            total += addData["data".concat(month)];
          } else if (loopInfo.code === "ETC_SALES_UNIT_PRICE") {
            const shopSalesTotalData = monthlySalesAndUnitPrices.filter(item => item.code === "ETC_SALES_TOTAL")[0];

            const shopSalesTotal = shopSalesTotalData["data".concat(month)];
            const visitPer = monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]["data".concat(month)];

            addData["data".concat(month)] = Math.round(visitPer > 0 ? shopSalesTotal / visitPer : 0);
          }
        });

        if (loopInfo.code === "RESVE_RATE") {
          const resveTeamData = monthlySalesAndUnitPrices.filter(item => item.code === "RESVE_TEAM")[0];
          const resveAbleTeamData = monthlySalesAndUnitPrices.filter(item => item.code === "RESVE_ABLE_TEAM")[0];
          const resveTeamByFirstHalf = resveTeamData.firstHalf;
          const resveTeamBySecondHalf = resveTeamData.secondHalf;
          const resveTeamByTotal = resveTeamData.total;
          const resveAbleTeamByFirstHalf = resveAbleTeamData.firstHalf;
          const resveAbleTeamBySecondHalf = resveAbleTeamData.secondHalf;
          const resveAbleTeamByTotal = resveAbleTeamData.total;

          addData.firstHalf = Math.round((resveAbleTeamByFirstHalf > 0 ? resveTeamByFirstHalf / resveAbleTeamByFirstHalf : 0) * 100);
          addData.secondHalf = Math.round((resveAbleTeamBySecondHalf > 0 ? resveTeamBySecondHalf / resveAbleTeamBySecondHalf : 0) * 100);
          addData.total = Math.round((resveAbleTeamByTotal > 0 ? resveTeamByTotal / resveAbleTeamByTotal : 0) * 100);
        } else if (loopInfo.code === "TEAM_AVG_PER") {
          const visitTeamData = monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_TEAM")[0];
          const visitPerData = monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0];
          const visitTeamByFirstHalf = visitTeamData.firstHalf;
          const visitTeamBySecondHalf = visitTeamData.secondHalf;
          const visitTeamByTotal = visitTeamData.total;
          const visitPerByFirstHalf = visitPerData.firstHalf;
          const visitPerBySecondHalf = visitPerData.secondHalf;
          const visitPerByTotal = visitPerData.total;

          addData.firstHalf = Math.round((visitTeamByFirstHalf > 0 ? visitPerByFirstHalf / visitTeamByFirstHalf : 0) * 100) / 100;
          addData.secondHalf = Math.round((visitTeamBySecondHalf > 0 ? visitPerBySecondHalf / visitTeamBySecondHalf : 0) * 100) / 100;
          addData.total = Math.round((visitTeamByTotal > 0 ? visitPerByTotal / visitTeamByTotal : 0) * 100) / 100;
        } else if (loopInfo.code === "VISIT_RATE") {
          const visitTeamData = monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_TEAM")[0];
          const resveAbleTeamData = monthlySalesAndUnitPrices.filter(item => item.code === "RESVE_ABLE_TEAM")[0];
          const visitTeamByFirstHalf = visitTeamData.firstHalf;
          const visitTeamBySecondHalf = visitTeamData.secondHalf;
          const visitTeamByTotal = visitTeamData.total;
          const resveAbleTeamByFirstHalf = resveAbleTeamData.firstHalf;
          const resveAbleTeamBySecondHalf = resveAbleTeamData.secondHalf;
          const resveAbleTeamByTotal = resveAbleTeamData.total;

          addData.firstHalf = Math.round((resveAbleTeamByFirstHalf > 0 ? visitTeamByFirstHalf / resveAbleTeamByFirstHalf : 0) * 100);
          addData.secondHalf = Math.round((resveAbleTeamBySecondHalf > 0 ? visitTeamBySecondHalf / resveAbleTeamBySecondHalf : 0) * 100);
          addData.total = Math.round((resveAbleTeamByTotal > 0 ? visitTeamByTotal / resveAbleTeamByTotal : 0) * 100);
        } else if (loopInfo.code === "GOLF_SALES_UNIT_PRICE") {
          const visitPerData = monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0];
          const golfSalesTotalData = monthlySalesAndUnitPrices.filter(item => item.code === "GOLF_SALES_TOTAL")[0];
          const visitPerByFirstHalf = visitPerData.firstHalf;
          const visitPerBySecondHalf = visitPerData.secondHalf;
          const visitPerByTotal = visitPerData.total;
          const golfSalesTotalByFirstHalf = golfSalesTotalData.firstHalf;
          const golfSalesTotalBySecondHalf = golfSalesTotalData.secondHalf;
          const golfSalesTotalByTotal = golfSalesTotalData.total;

          addData.firstHalf = Math.round((visitPerByFirstHalf > 0 ? golfSalesTotalByFirstHalf / visitPerByFirstHalf : 0));
          addData.secondHalf = Math.round((visitPerBySecondHalf > 0 ? golfSalesTotalBySecondHalf / visitPerBySecondHalf : 0));
          addData.total = Math.round((visitPerByTotal > 0 ? golfSalesTotalByTotal / visitPerByTotal : 0));
        } else if (loopInfo.code === "FOOD_SALES_UNIT_PRICE") {
          const visitPerData = monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0];
          const salesTotalData = monthlySalesAndUnitPrices.filter(item => item.code === "FOOD_SALES_TOTAL")[0];
          const visitPerByFirstHalf = visitPerData.firstHalf;
          const visitPerBySecondHalf = visitPerData.secondHalf;
          const visitPerByTotal = visitPerData.total;
          const salesTotalByFirstHalf = salesTotalData.firstHalf;
          const salesTotalBySecondHalf = salesTotalData.secondHalf;
          const salesTotalByTotal = salesTotalData.total;

          addData.firstHalf = Math.round((visitPerByFirstHalf > 0 ? salesTotalByFirstHalf / visitPerByFirstHalf : 0));
          addData.secondHalf = Math.round((visitPerBySecondHalf > 0 ? salesTotalBySecondHalf / visitPerBySecondHalf : 0));
          addData.total = Math.round((visitPerByTotal > 0 ? salesTotalByTotal / visitPerByTotal : 0));
        } else if (loopInfo.code === "SHOP_SALES_UNIT_PRICE") {
          const visitPerData = monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0];
          const salesTotalData = monthlySalesAndUnitPrices.filter(item => item.code === "SHOP_SALES_TOTAL")[0];
          const visitPerByFirstHalf = visitPerData.firstHalf;
          const visitPerBySecondHalf = visitPerData.secondHalf;
          const visitPerByTotal = visitPerData.total;
          const salesTotalByFirstHalf = salesTotalData.firstHalf;
          const salesTotalBySecondHalf = salesTotalData.secondHalf;
          const salesTotalByTotal = salesTotalData.total;

          addData.firstHalf = Math.round((visitPerByFirstHalf > 0 ? salesTotalByFirstHalf / visitPerByFirstHalf : 0));
          addData.secondHalf = Math.round((visitPerBySecondHalf > 0 ? salesTotalBySecondHalf / visitPerBySecondHalf : 0));
          addData.total = Math.round((visitPerByTotal > 0 ? salesTotalByTotal / visitPerByTotal : 0));
        } else {
          addData.firstHalf = firstHalf;
          addData.secondHalf = secondHalf;
          addData.total = total;
        }

        addData.isTitleVisible = loopInfo.isTitleVisible;
        addData.isBottomDoubleLine = loopInfo.isBottomDoubleLine;
        addData.isBottomSolidLine = loopInfo.isBottomSolidLine;
        addData.isRowSpan = loopInfo.isRowSpan;
        addData.rowSpan = loopInfo.rowSpan;

        monthlySalesAndUnitPrices.push(addData);
      });

      this.monthlySalesAndUnitPrices = monthlySalesAndUnitPrices;
      this.monthlySalesAndUnitPricesTotal = {
        data01ByTotal: monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.data01).reduce((acc, cur) => acc + cur, 0),
        data02ByTotal: monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.data02).reduce((acc, cur) => acc + cur, 0),
        data03ByTotal: monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.data03).reduce((acc, cur) => acc + cur, 0),
        data04ByTotal: monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.data04).reduce((acc, cur) => acc + cur, 0),
        data05ByTotal: monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.data05).reduce((acc, cur) => acc + cur, 0),
        data06ByTotal: monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.data06).reduce((acc, cur) => acc + cur, 0),
        data07ByTotal: monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.data07).reduce((acc, cur) => acc + cur, 0),
        data08ByTotal: monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.data08).reduce((acc, cur) => acc + cur, 0),
        data09ByTotal: monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.data09).reduce((acc, cur) => acc + cur, 0),
        data10ByTotal: monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.data10).reduce((acc, cur) => acc + cur, 0),
        data11ByTotal: monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.data11).reduce((acc, cur) => acc + cur, 0),
        data12ByTotal: monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.data12).reduce((acc, cur) => acc + cur, 0),
        firstHalfByTotal: monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.firstHalf).reduce((acc, cur) => acc + cur, 0),
        secondHalfByTotal: monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.secondHalf).reduce((acc, cur) => acc + cur, 0),
        totalByTotal: monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.total).reduce((acc, cur) => acc + cur, 0),
        data01ByUnitPrice: monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.data01 ? Math.round(monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.data01).reduce((acc, cur) => acc + cur, 0) / monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.data01) : 0,
        data02ByUnitPrice: monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.data02 ? Math.round(monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.data02).reduce((acc, cur) => acc + cur, 0) / monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.data02) : 0,
        data03ByUnitPrice: monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.data03 ? Math.round(monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.data03).reduce((acc, cur) => acc + cur, 0) / monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.data03) : 0,
        data04ByUnitPrice: monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.data04 ? Math.round(monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.data04).reduce((acc, cur) => acc + cur, 0) / monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.data04) : 0,
        data05ByUnitPrice: monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.data05 ? Math.round(monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.data05).reduce((acc, cur) => acc + cur, 0) / monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.data05) : 0,
        data06ByUnitPrice: monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.data06 ? Math.round(monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.data06).reduce((acc, cur) => acc + cur, 0) / monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.data06) : 0,
        data07ByUnitPrice: monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.data07 ? Math.round(monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.data07).reduce((acc, cur) => acc + cur, 0) / monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.data07) : 0,
        data08ByUnitPrice: monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.data08 ? Math.round(monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.data08).reduce((acc, cur) => acc + cur, 0) / monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.data08) : 0,
        data09ByUnitPrice: monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.data09 ? Math.round(monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.data09).reduce((acc, cur) => acc + cur, 0) / monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.data09) : 0,
        data10ByUnitPrice: monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.data10 ? Math.round(monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.data10).reduce((acc, cur) => acc + cur, 0) / monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.data10) : 0,
        data11ByUnitPrice: monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.data11 ? Math.round(monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.data11).reduce((acc, cur) => acc + cur, 0) / monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.data11) : 0,
        data12ByUnitPrice: monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.data12 ? Math.round(monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.data12).reduce((acc, cur) => acc + cur, 0) / monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.data12) : 0,
        firstHalfByUnitPrice: monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.firstHalf ? Math.round(monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.firstHalf).reduce((acc, cur) => acc + cur, 0) / monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.firstHalf) : 0,
        secondHalfByUnitPrice: monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.secondHalf ? Math.round(monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.secondHalf).reduce((acc, cur) => acc + cur, 0) / monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.secondHalf) : 0,
        totalByUnitPrice: monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.total ? Math.round(monthlySalesAndUnitPrices.filter(item => item.code.endsWith("_SALES_TOTAL")).map(item => item.total).reduce((acc, cur) => acc + cur, 0) / monthlySalesAndUnitPrices.filter(item => item.code === "VISIT_PER")[0]?.total) : 0,
      };

      this.reportSearchOptions = JSON.parse(JSON.stringify(this.searchOptions));
    },
    onClickExcel() {
      this.$refs.bodyGrid.excelExport();
    },
    print() {
      if (this.monthlySalesAndUnitPrices.length < 1) {
        return this.errorToast(this.$t("report.popupMessage.noData"));
      }

      const reportData = {
        reportJson: {
          jsonData: {
            monthlySalesAndUnitPrice: this.monthlySalesAndUnitPrices,
            monthlySalesAndUnitPricesTotal: this.monthlySalesAndUnitPricesTotal
          },
          username: this.username,
          uniqueCode: this.$options.name,
          searchOptions:
            "▣ 영업년도 : " + this.reportSearchOptions.year + "년   ▣ 매출구분 : " +
            this.reportSearchOptions.salesSumDivOptions.filter(item => item.comCode === this.reportSearchOptions.salesSumDiv).map(item => item.comName).join("") +
            (this.reportSearchOptions.halfHoleFlag ? "   ▣ 9홀이하 : 0.5 팀/명" : ""),
        },
      };
      console.log('reportData.===>', reportData);
      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    onBodyGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field
          }
        }
      } = args;

      if (field === "title") {
        args.node.colSpan = 2;
      } else if (field === "name") {
        args.cell.isSpanned = true;
      }
    },
    onBodyGridQueryCellInfo(args) {
      const {
        cell,
        column: {
          field,
          headerText,
        },
        data,
      } = args;

      if (data[field] === 0) {
        cell.innerText = "-";
      }

      if (field === "title") {
        if (data.isRowSpan) {
          args.rowSpan = data.rowSpan;
        }
      }

      if (data.isBottomDoubleLine && field !== "title" && !(field === undefined && headerText === "NO")) {
        cell.style.borderBottom = "2px solid #e0e0e0";
      }
      if (data.isBottomSolidLine || field === "title") {
        cell.style.borderBottom = "2px solid";
      }

      if (
        field &&
        (field.startsWith("firstHalf") ||
        field.startsWith("secondHalf") ||
        field.endsWith("total") ||
        field.endsWith("Total"))
      ) {
        cell.style.backgroundColor = "#fef2cb";
      }
    },
  }
};
</script>

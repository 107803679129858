<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field cartDivision">
            <div class="title">대행사 코드</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  ref="agencyCodeDiv"
                  v-model="searchConditions.agncyKind"
                  :dataSource="searchConditionOptions.agencyType"
                  :allowFiltering="false"
                  :fields="searchConditionOptions.fields"
                  cssClass="lookup-condition-dropdown"
                >
                </ejs-dropdownlist>
              </li>
            </ul>
          </li>
          <li class="item">
            <ul class="check">
              <li>
                <label>
                  <input
                      type="checkbox"
                      v-model="searchConditions.useFlag"
                      value="true"
                  />
                  <i></i>
                  <div class="label">사용여부</div>
                </label>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              :disabled="
              commonMixinIsButtonDisableByAuth('agencyRegistrationGet')
            "
              :ignore="isPopupOpened"
              type="button"
              @click.native="onViewAgencyCode"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">대행사코드 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="save keyColor">
                  <erp-button
                      :disabled="
                      commonMixinIsButtonDisableByAuth('agencyRegistrationReg')
                      "
                      :ignore="isPopupOpened"
                      @click.native="onSaveAgencyCode"
                  >
                    저장
                  </erp-button>
                </li>
                <li class="add">
                  <erp-button
                      :disabled="
                        commonMixinIsButtonDisableByAuth('agencyRegistrationReg')
                      "
                      :ignore="isPopupOpened"
                      @click.native="onAddAgncyCode"
                  >
                    추가
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <ejs-grid-wrapper
                ref="agncyCodeGrid"
                id="agncyCodeGrid"
                v-bind="agncyGridOption"
                :provides="agncyGridOption.provides"
                :columns="agncyGridOption.columns"
                :allowResizing="true"
                :allowSelection="true"
                :allowPaging="true"
                :pageSettings="agncyGridOption.pageSettings"
                :editSettings="agncyGridOption.editSettings"
                @headerCellInfo="headerCellInfo"
                @queryCellInfo="queryCellInfo"
                @cellSaved="handleCellSaved"
                @recordClick="recordClick"
                @cellEdit="onGridCellEdit"
            />
          </div>
        </section>
      </article>
    </div>
    <agency-code-detail-popup
        v-if="isVisibleAgencyCodeDetailPopup"
        ref="agencyCodeDetailPopup"
        @popupClosed="closeAgencyCodeDetailPopup"
        @popupEvent="onAgencyCodeDetailPopupEvent"
        :position="{ x: 'center', y: 'center' }"
    ></agency-code-detail-popup>
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.cartDivision .content .item {
  width: 120px;
}

body .appContent .lookup-condition .field.cartNumber .content .item {
  width: 60px;
}

body .appContent .body-article .section-body {
  overflow: hidden;
  border: none;
}
</style>

<script>
import {maxBy as _maxBy} from "lodash";
import commonMixin from "@/views/layout/mixin/commonMixin";
import {commonCodesGetCommonCodeByAttrb, commonCodesGetCommonCodeByIdxAttrb} from "@/utils/commonCodes";
import {Edit, ForeignKey, Page, ExcelExport, Resize, Selection} from "@syncfusion/ej2-vue-grids";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {getAgencyCode, putAgencyCode, getAgency} from "@/api/agencyCodeRegistration";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import agencyCodeDetailPopup from "@/views/code-management/popup/AgencyCodeDetailPopup.vue";
import ErpButton from "@/components/button/ErpButton.vue";
import {getFormattedDate} from "@/utils/date";

export default {
  name: "AgencyCodeRegistration",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    agencyCodeDetailPopup,
    ErpButton,
  },
  async created() {
    this.searchConditionOptions.agencyType.unshift({
      comCode: "%",
      comName: "전체",
    });
  },
  async mounted() {
    await this.getAgencyCode();
    this.searchConditions.isInit = false;
    this.count = this.agncyCodeInfo.length>0?this.agncyCodeInfo.length:0;
    //await this.getAgency();
  },
  data() {
    return {
      count: 0,
      grid: [Edit, ForeignKey, Selection, Resize],
      showCartInfoDetail: false,
      isVisibleAgencyCodeDetailPopup: false,
      searchConditionOptions: {
        agencyType: commonCodesGetCommonCodeByAttrb("RESVE_CHANNEL", "AGENT", false).map(data => ({
          ...data,
          jsonData: JSON.parse(data.jsonData),
        })).concat(
          commonCodesGetCommonCodeByAttrb("RESVE_CHANNEL", "COPRTN", false).map(data => ({
            ...data,
            jsonData: JSON.parse(data.jsonData),
          }))
        ),
        fields: {text: "comName", value: "comCode"},
      },
      searchConditions: {
        agncyKind: "%",
        useFlag: true,
        isInit: true,
      },
      selectionSettings: {
        type: "Multiple",
        mode: "Both",
        enableToggle: false,
      },
      agncyCodeInfo: [],
      agncyCodeNameInfo: [],
      agncyCodeInfoLength: 0,
      agncyCheck: [],
    };
  },
  computed: {
    isPopupOpened() {
      return this.isVisibleAgencyCodeDetailPopup;
    },
    agncyGridOption() {
      return {
        provides: [Edit, ForeignKey, Page, ExcelExport, Resize],
        dataSource: this.agncyCodeInfo,
        allowResizing: true,
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: 'Bottom',
        },
        pageSettings: {pageSize: 50},
        columns: [
          {
            allowEditing: false,
            field: "_rid",
            visible: false,
            isPrimaryKey: true,
          },
          {
            field: "agncyCode",
            headerText: "대행사명",
            type: "string",
            minWidth: 16,
            width: 100,
            textAlign: "center",
            editType: "dropdownedit",
            dataSource: commonCodesGetCommonCodeByIdxAttrb("RESVE_CHANNEL", 1, "AGENT").concat(commonCodesGetCommonCodeByIdxAttrb("RESVE_CHANNEL", 1, "COPRTN")),
            //dataSource: this.agncyCodeList,
            isCommonCodeField: true,
            foreignKeyField: "comName",
            foreignKeyValue: "comCode",
            allowEditing: true,


          },
          // {
          //   field: "agncyName",
          //   headerText: "대행사명",
          //   type: "string",
          //   minWidth: 16,
          //   width: 150,
          //   textAlign: "left",
          //   allowEditing: false,
          // },
          {
            field: "openMethod",
            headerText: "대행사오픈방법",
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "center",
            editType: "dropdownedit",
            isCommonCodeField: true,
            groupCode: "AGNCY_OPEN_METHOD",
            allowEditing: false,
          },
          {
            field: "applyDate",
            headerText: "적용일자",
            type: "string",
            minWidth: 16,
            width: 100,
            textAlign: "center",
            isDateType: true,
            allowEditing: false,
            format: "##",
          },
          {
            field: "webOpenTime",
            headerText: "웹오픈시간",
            type: "integer",
            isNumericType: true,
            inputNumberProperty: { maxLength: 10 },
            minWidth: 16,
            width: 80,
            textAlign: "center",
            allowEditing: false,
          },
          {
            field: "agncyId",
            headerText: "대행사아이디",
            type: "string",
            minWidth: 16,
            width: 100,
            textAlign: "center",
            isCommonCodeField: true,
            dataSource: this.searchConditionOptions.cartKindCodes,
            allowEditing: true,
          },
          {
            field: "remarks",
            headerText: "비고",
            type: "string",
            minWidth: 16,
            width: 170,
            textAlign: "left",
            allowEditing: true,
          },
          {
            field: "useFlag",
            headerText: "사용",
            type: "boolean",
            editType: "booleanedit",
            minWidth: 10,
            width: 50,
            displayAsCheckBox: true,
            textAlign: "center",
            allowEditing: true,
          },
          {
            field: "insertId",
            headerText: "등록자",
            allowEditing: false,
            type: "string",
            format: "N",
            minWidth: 16,
            width: 80,
            textAlign: "center",
          },
          {
            field: "insertDt",
            headerText: "등록일시",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 110,
            textAlign: "center",
          },
          {
            field: "updateId",
            headerText: "수정자",
            allowEditing: false,
            type: "integer",
            minWidth: 16,
            width: 80,
            textAlign: "center",
          },
          {
            field: "updateDt",
            headerText: "수정일시",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 110,
            textAlign: "center",
          }
        ],
      };
    },
  },
  methods: {
    // agncyCodeInit(args) {
    // },
    headerCellInfo(args) {
      if (
          args.cell.column.field === "agncyCode"   ||
          args.cell.column.field === "agncyId"
      ) {
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }

      if (args.cell.column.field === "agncyCode") {
        args.node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    queryCellInfo(args) {
      const {
        cell,
        column: {field},
      } = args;

      if (field === "agncyCode") {
        cell.classList.add(this.$t("className.grid.clickArea"));
      }

      if (
          field === "remarks" ||
          field === "useFlag"
      ) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    // handleCellSaved(args) { // 대행사코드 클릭 시 대행사명 자동 표출
    //   const {
    //     columnName,
    //     value,
    //   } = args;
    //
    //   if (columnName.startsWith("agncyCode")) {
    //     const currentRowIndex = this.$refs.agncyCodeGrid.currentSelectedRowIndex;
    //
    //     const gridRef = this.$refs["agncyCodeGrid"];
    //     const batchCurrentViewRecords = gridRef.getBatchCurrentViewRecords();
    //     const data = {
    //       ...batchCurrentViewRecords[currentRowIndex],
    //     };
    //
    //     const agncyCodeName = commonCodesGetCommonCodeByIdxAttrb("RESVE_CHANNEL", 1, "AGENT");
    //     const currentDropdownIndex = agncyCodeName.findIndex((object) => object.comCode === `${value}`);
    //
    //     data['agncyName'] = agncyCodeName[currentDropdownIndex].comName;
    //
    //     gridRef.updateRow(currentRowIndex, data);
    //   }
    // },
    handleCellSaved(args) { // 대행사코드 클릭 시 대행사아이디로 포커싱
      const {
        columnName,
      } = args;

      if (columnName.startsWith("agncyCode")) {
        const currentRowIndex = this.$refs.agncyCodeGrid.currentSelectedRowIndex;

        const gridRef = this.$refs["agncyCodeGrid"];

        gridRef.editCell(currentRowIndex, "agncyId");
      }
    },
    recordClick(args) {
      this.closeAgencyCodeDetailPopup();
      if (args.column && args.column.field === "agncyCode" && args.rowData.insertDt && args.cancel === false) {
        const popupData = {
          agncyCode: args.rowData.agncyCode,
        };
        this.openAgencyCodeDetailPopup(popupData);
      }
    },
    onGridCellEdit(args) {
      if (args.rowData.agncyCode && args.rowData.agncyId && args.rowData.insertDt) {
        args.cancel = true;
      }
      if(args.columnName === "remarks" || args.columnName === "useFlag") {
        args.cancel = false;
      }
    },
    openAgencyCodeDetailPopup(popupData) {
      this.isVisibleAgencyCodeDetailPopup = true;
      this.$nextTick(() => {
        this.$refs.agencyCodeDetailPopup.showAgencyCodeDetailPopup(popupData);
      });
    },
    closeAgencyCodeDetailPopup() {
      this.isVisibleAgencyCodeDetailPopup = false;
    },
    async onViewAgencyCode() {
      const batchChanges = this.$refs.agncyCodeGrid.getBatchChanges();
      if (
          batchChanges.addedRecords.length > 0 ||
          batchChanges.changedRecords.length > 0
      ) {
        if (!(await this.confirm("수정 내역이 있습니다. 계속 진행하시겠습니까?"))) {
          return;
        }
      }
      await this.getAgencyCode();
    },
    onAddAgncyCode() {  //추가버튼 클릭 시 레코드 추가
      let _rid;
      if (this.agncyCodeInfo.length > 0) {
        const currentViewRecords = this.$refs.agncyCodeGrid.getBatchCurrentViewRecords();
        _rid = _maxBy(currentViewRecords, "_rid")._rid;
      } else {
        const {addedRecords} = this.$refs.agncyCodeGrid.getBatchChanges();
        _rid = addedRecords.length;
      }

      const addRecordObject = {
        _rid: _rid + 1,
        agncyCode: null,
        agncyId: null,
        openMethod: null,
        applyDate: null,
        insertDt: null,
        insertId: null,
        remarks: null,
        updateDt: null,
        updateId: null,
        useFlag: true
      };
      this.$refs.agncyCodeGrid.addRecord(addRecordObject);

      const currentviewRecords = this.$refs.agncyCodeGrid.getBatchCurrentViewRecords();
      const idxToSelect = currentviewRecords.findIndex(
          (record) => record._rid === addRecordObject._rid
      );

      this.$refs.agncyCodeGrid.editCell(idxToSelect, "agncyCode");
    },
    async onSaveAgencyCode() {
      const {
        addedRecords,
        changedRecords,
      } = this.$refs.agncyCodeGrid.getBatchChanges();
      if (!this.$refs["agncyCodeGrid"].validate()) {
        return;
      }
      await this.getAgency();

      const agncy = this.agncyCheck;

      //대행사 코드 중복 체크
      const agncyCodeValidationResult = agncy.every(
        (item) => {
          const currentViewagncyCodeInfo = this.$refs.agncyCodeGrid.getBatchCurrentViewRecords();

          let duplicateAgncyCode = false;

          this.agncyCheck.forEach((data, idx) => {
            if (currentViewagncyCodeInfo.filter((info, infoIdx) => idx !== infoIdx && info.agncyCode === data.agncyCode).length > 0) {
              duplicateAgncyCode = true;
            }
          });

          if (duplicateAgncyCode) {
            this.errorToast("이미 등록된 대행사입니다.");
            return false;
          }

          const value = item.agncyCode;
          if (value === null || value === undefined || value === "") {
            return false;
          }

          return true;
        }
      );

      if (!agncyCodeValidationResult) {
        return;
      }

      await putAgencyCode(
        addedRecords.map((r) => ({
          ...r,
        })),
        changedRecords.map((r) => ({
          ...r,
        })),
      );

      this.infoToast(this.$t("main.popupMessage.saved"));
      await this.getAgencyCode();
      this.count = this.agncyCodeInfo.length;
    },
    async getAgencyCode() {
      const agncyKind = this.searchConditions.agncyKind === "%" ? null : this.searchConditions.agncyKind;
      const isInit = this.searchConditions.isInit; // 첫 진입 시에만 true 설정
      const useFlag = this.searchConditions.useFlag; //사용 여부
      const response = await getAgencyCode(agncyKind, isInit, useFlag);
      const agncyCodeInfoList = response.value.agencyCodeInfoList;

      this.agncyCodeInfo = agncyCodeInfoList.map((item, index) => {
        item["agncyCode"  ] || (item["agncyCode"  ] = null);
        item["agncyId"    ] || (item["agncyId"    ] = null);
        item["openMethod" ] || (item["openMethod" ] = null);
        item["applyDate"  ] || (item["applyDate"  ] = null);
        item["webOpenTime"] || (item["webOpenTime"] = null);
        item["remarks"    ] || (item["remarks"    ] = null);
        item["insertId"   ] || (item["insertId"   ] = null);
        item["insertDt"   ] || (item["insertDt"   ] = null);
        item["updateId"   ] || (item["updateId"   ] = null);
        item["updateDt"   ] || (item["updateDt"   ] = null);

        return {
          ...item,
          applyDate: getFormattedDate(item.applyDate, "YYYY-MM-DD"),
          _rid: index + 1,
        };
      });
      this.agncyCodeInfoLength = this.agncyCodeInfo.length;
      //this.agncyCodeInit();
    },
    async getAgency() {
      const response = await getAgency();
      const agncyInfoList = response.value.agencyInfoList;

      // this.agncyCheck = agncyInfoList.map((item) => {
      //   item["agncyCode"] || (item["agncyCode"] = null);
      //   return {
      //     ...item,
      //   };

      this.agncyCheck = agncyInfoList.map(data => ({
              ...data,
            }));
    },
    async onAgencyCodeDetailPopupEvent(eventId) {
      if (eventId === "popupReload") {
        await this.getAgencyCode();
      }
    },
  },
};
</script>

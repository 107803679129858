import VanProcessError from "@/error/van/VanProcessError";
import NotImplementError from "@/error/NotImplementError";

/** @type WebSocket */
let ws = null;
let strStatus = "";
let strOUTPUT = "";

const ERROR_CODE = {
  "100": "응답 없는 거래",
  "-1": "일반 ERROR",
  "-50": "DATA없음",
  "-51": "DATA LENGTH ZERO",
  "-52": "QR Barcode Input Data없음",
  "-53": "QR Barcode Pattern 없음",
  "-500": "CLASS CREATE 안됨",
  "-501": "LIST없음",
  "-502": "PEERNAME 없음",
  "-700": "통신 연결 실패",
  "-701": "통신 종료 실패",
  "-702": "없는 METHOD",
  "-703": "SEND FAIL",
  "-704": "TIMEOUT",
  "-705": "연결정보 없음",
  "-706": "CRC CHECK ERROR",
  "-707": "HEADER DATA ERROR",
  "-709": "Network Disconnect",
  "-713": "ASYNC THREAD STOP",
  "-3000": "TCP IP ADDRESS 없음",
  "-3001": "TCP IP 연결 실패",
  "-3002": "TCP RCV ERROR",
  "-3003": "TCP RCV DATA DEC ERROR",
  "-5013": "서버NACK 전송오류",
  "-5016": "서버 전송오류",
  "-6000": "응답코드 오류",
  "-7107": "거래 길이 틀림",
  "-7018": "전문관리번호 오류",
  "-7500": "송신실패",
  "-7501": "수신실패",
  "-7502": "STX수신 실패",
  "-9999": "현재 지원 안됨(미개발)",
};

export function requestToWebSocket(url, requestBody) {
  return new Promise((resolve, reject) => {
    const webSocketUrl = url;

    let JsonFullData = new Object();
    let strTMP = new Object();
    let strInputData = new Object();
    let InputData = new Object();
    let Jsondata;

    JsonFullData.CHANNEL_NAME = "SMTCatAgent_WEB";	// Channel
    JsonFullData.METHOD = "SMTCONNECT";			            // Method

    InputData['DESTINATION'] = "127.0.0.1:13855";
    InputData['CONN_TYPE'] = "0";

    // Param
    strTMP.KEY_TYPE = "VNUMBER";
    JsonFullData.PARAM = strTMP;
    JsonFullData.INPUT = InputData;

    if ("WebSocket" in window) {
      ws = new WebSocket(webSocketUrl);

      //연결성공
      ws.onopen = function (evt) {
        Jsondata = JSON.stringify(JsonFullData);
        ws.send(Jsondata);
        strStatus = "OPEN";
      };

      //연결종료
      ws.onclose = function(evt) {
        console.log('close!!!');
      };

      //연결실패
      ws.onerror = function (evt) {
        reject(new VanProcessError('모듈 서버가 닫겨있습니다(VCAT을 다시 실행해주세요)'));
      };

      //응답수신
      ws.onmessage = function (evt) {
        if (JSON.parse(evt.data)['RET_CODE'] < 0 ) {
          console.log('에러쓰');
          console.log(evt);
          strStatus = "ERROR";
          JsonFullData.METHOD = "SMTDISCONNECT";	// Method
          JsonFullData.PARAM = strTMP;
          Jsondata = JSON.stringify(JsonFullData);
          ws.send(Jsondata);

          closeWebSocket(); //웹소컷 close
          const retCode =  (JSON.parse(evt.data)["RET_CODE"].toString()) || "-1";
          reject(new VanProcessError(`결제 중 문제가 발생하였습니다. <br/>(${ERROR_CODE[retCode]})`));

        }

        if (strStatus == "OPEN") {
          strStatus = "INIT";
          JsonFullData.METHOD = "SMTINITDATA";	// Method
          JsonFullData.PARAM = strTMP;
          Jsondata = JSON.stringify(JsonFullData);
          ws.send(Jsondata);
        } else if (strStatus == "INIT") {
          strStatus = "TIMEOUT";
          strInputData["SENDTIME"] = "120";
          strInputData["RCVTIME"]  = "120";
          JsonFullData.METHOD = "SMTTIMEOUTSET";	// Method
          JsonFullData.INPUT = strInputData;
          JsonFullData.PARAM = strTMP;
          Jsondata = JSON.stringify(JsonFullData);
          ws.send(Jsondata);
        } else if (strStatus == "TIMEOUT") {
          strStatus = "TRADE";
          JsonFullData.METHOD = "SMTTRADE";	// Method
          JsonFullData.PARAM = strTMP;
          JsonFullData.INPUT = requestBody; // DATA
          Jsondata = JSON.stringify(JsonFullData);
          ws.send(Jsondata);
        } else if (strStatus == "TRADE") {
          strOUTPUT = JSON.parse(evt.data)['OUTPUT'];
          console.log(strOUTPUT);
          strStatus = "CLOSE";
          JsonFullData.METHOD = "SMTDISCONNECT";	// Method
          JsonFullData.PARAM = strTMP;
          Jsondata = JSON.stringify(JsonFullData);
          ws.send(Jsondata);

          closeWebSocket(); //웹소컷 close

          if (strOUTPUT) {
            resolve({data: strOUTPUT});
          } else {
            reject(strOUTPUT);
          }
        } else if (strStatus == "CLOSE") {
          console.log('비정상적인 값');
          reject(new VanProcessError('비정상적인 결제 입니다. 관리자에게 문의 부탁드립니다.'));
        }
      };

    } else {
      //브라우저가 webSocket을 지원하지 않음.
      reject(new VanProcessError('해당 브라우저에서 결제 할 수 없습니다'));

    }

  });
}

/**
 * @param {WebSocket} websocket
 */
export function closeWebSocket() {
  if (ws.readyState !== WebSocket.CLOSED) {
    ws.close();
  }
}

import request from "@/utils/request";
const qs = require("qs");

/**
 * 매장별 매출 현황 조회
 * @return Promise
 */
export function getStatusStores(
  bsnCode,
  bsnEndDate,
  bsnStartDate,
  classCode01,
  classCode02,
  classCode03,
  delFlag,
  dwCodeList,
  grpNo,
  grpName,
  taxFlag,
  payFlag,
  priceDiv,
  productId,
  productName,
  slipNo,
  storeCodes,
  tableName,
  partDivs,
  gcDiv
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/sales_management/report/stores",
    method: "get",
    params: {
      bsnCode,
      bsnEndDate,
      bsnStartDate,
      classCode01,
      classCode02,
      classCode03,
      delFlag,
      dwCodeList,
      grpNo,
      grpName,
      taxFlag,
      payFlag,
      priceDiv,
      productId,
      productName,
      slipNo,
      storeCodes,
      tableName,
      partDivs,
      gcDiv
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

/**
 * 팀별 매출 현황 조회
 * @return Promise
 */
export function getStatusByTeamInfo(
  bsnDate,
  chkinId,
  grpName,
  grpNo,
  selFlag,
  storeCodes,
  visitId
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/sales_management/report/by-team-info",
    method: "get",
    params: {
      bsnDate,
      chkinId,
      grpName,
      grpNo,
      selFlag,
      storeCodes,
      visitId,
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    }
  });
}

/**
 * 단체 매출 현황 조회
 * @return Promise
 */
export function getStatusByGroupInfo(
  bsnCode,
  bsnEndDate,
  bsnStartDate,
  dwCodeList,
  grpKind,
  grpName,
  grpNo,
  halfHoleFlag,
  tempGrpCnt
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/sales_management/report/by-group-info",
    method: "get",
    params: {
      bsnCode,
      bsnEndDate,
      bsnStartDate,
      dwCodeList,
      grpKind,
      grpName,
      grpNo,
      halfHoleFlag,
      tempGrpCnt,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

export function getByGroupInfoProductDetailList(bsnDate, storeCode, grpNo) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/sales_management/report/by-group-info/product-detail-list",
    method: "get",
    params: {
      bsnDate,
      storeCode,
      grpNo
    }
  });
}
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ejs-dialog',{ref:"mileageAccrualUsageStatusDetailPopup",attrs:{"header":"마일리지 정보","width":"832","allowDragging":true,"showCloseIcon":true,"isModal":true,"cssClass":_vm.mileage.mileageId
        ? _vm.$t('className.popup.productCodePopupModify')
        : _vm.$t('className.popup.productCodePopupAdd')},on:{"close":_vm.onPopupClosed}},[_c('div',{staticClass:"window reservationInformation"},[_c('div',{staticClass:"windowContent"},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"content-body"},[_c('article',{staticClass:"body-article"},[_c('section',{staticClass:"article-section section-01"},[_c('div',{staticClass:"section-header"},[_c('div',{staticClass:"header-left"},[_c('div',{staticClass:"header-title"},[_vm._v("마일리지 정보")])])]),_c('div',{staticClass:"section-body"},[_c('article',{staticClass:"body-article"},[_c('section',{staticClass:"article-section section-0101"},[_c('div',{staticClass:"section-body"},[_c('div',{staticClass:"body-data"},[_c('div',{staticClass:"data-outer"},[_c('div',{staticClass:"data-inner"},[_c('ul',{staticClass:"data-content"},[_c('li',{staticClass:"field memberNameNumber"},[_c('div',{staticClass:"title required"},[_vm._v(" 회원명/번호 ")]),_c('div',{staticClass:"content"},[_c('ul',{staticClass:"row"},[_c('li',{staticClass:"item form-group input memberName"},[_c('div',{staticClass:"form"},[_c('input-text',{ref:"memberName",attrs:{"id":"memberName","placeholder":"회원명"},on:{"change":_vm.onMemberNameChanged,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.memberNameInputKeyEnter.apply(null, arguments)}},nativeOn:{"keydown":function($event){return _vm.preventEnterEvent.apply(null, arguments)}},model:{value:(
                                              _vm.mileage.memberName
                                            ),callback:function ($$v) {_vm.$set(_vm.mileage, "memberName", $$v)},expression:"\n                                              mileage.memberName\n                                            "}})],1)]),_c('li',{staticClass:"item form-group text memberNo"},[_vm._v(" "+_vm._s(_vm.mileage.memberNo)+" ")]),_c('li',{staticClass:"item form-group button"},[_c('ul',{staticClass:"button"},[_c('li',{staticClass:"search"},[_c('erp-button',{ref:"memberPopupOpenButton",attrs:{"button-div":"GET","is-icon-custom":true},nativeOn:{"click":function($event){return _vm.onMemberPopupOpen(true,_vm.memberPopupType.RESV, _vm.mileage.memberName,null)}}},[_vm._v(" 조회 ")])],1)])])])])]),_c('li',{staticClass:"field"},[_c('div',{staticClass:"title"},[_vm._v("연락처")]),_c('div',{staticClass:"content"},[_c('ul',{staticClass:"row"},[_c('li',{staticClass:"item form-group"},[_c('div',{staticClass:"form"},[_c('component-telephone',{ref:"hpNo",attrs:{"id":"hpNo","name":"hpNo","max-length":11,"disabled":true},nativeOn:{"keydown":function($event){return _vm.preventEnterEvent.apply(null, arguments)}},model:{value:(
                                              _vm.mileage.hpNo
                                            ),callback:function ($$v) {_vm.$set(_vm.mileage, "hpNo", $$v)},expression:"\n                                              mileage.hpNo\n                                            "}})],1)])])])]),_c('li',{staticClass:"field"},[_c('div',{staticClass:"title required"},[_vm._v("회원구분")]),_c('div',{staticClass:"content"},[_c('ul',{staticClass:"row"},[_c('li',{staticClass:"item form-group"},[_c('div',{staticClass:"form"},[_c('ejs-dropdownlist',{ref:"memberDiv",attrs:{"dataSource":_vm.mileageOptions.memberDivOptions,"allowFiltering":false,"fields":_vm.commonCodeFields,"enabled":false,"cssClass":"body-data-dropdown"},on:{"change":_vm.onMemberDivDropDownListChanged},model:{value:(
                                              _vm.mileage.memberDiv
                                            ),callback:function ($$v) {_vm.$set(_vm.mileage, "memberDiv", $$v)},expression:"\n                                              mileage.memberDiv\n                                            "}})],1)])])])]),_c('li',{staticClass:"field"},[_c('div',{staticClass:"title required"},[_vm._v("회원등급")]),_c('div',{staticClass:"content"},[_c('ul',{staticClass:"row"},[_c('li',{staticClass:"item form-group"},[_c('div',{staticClass:"form"},[_c('ejs-dropdownlist',{ref:"memberGrade",attrs:{"dataSource":_vm.mileageOptions.memberGradeOptions,"allowFiltering":false,"fields":_vm.commonCodeFields,"enabled":false,"cssClass":"body-data-dropdown"},model:{value:(
                                              _vm.mileage.memberGrade
                                            ),callback:function ($$v) {_vm.$set(_vm.mileage, "memberGrade", $$v)},expression:"\n                                              mileage.memberGrade\n                                            "}})],1)])])])]),_c('li',{staticClass:"field"}),_c('li',{staticClass:"field"},[_c('div',{staticClass:"title required"},[_vm._v("발생일자")]),_c('div',{staticClass:"content"},[_c('ul',{staticClass:"row"},[_c('li',{staticClass:"item form-group"},[_c('div',{staticClass:"form"},[_c('input-date',{ref:"occurDate",attrs:{"id":"occurDate","type":"body-data","format":"YYYY-MM-DD"},nativeOn:{"keydown":function($event){return _vm.preventEnterEvent.apply(null, arguments)}},model:{value:(
                                              _vm.mileage.occurDate
                                            ),callback:function ($$v) {_vm.$set(_vm.mileage, "occurDate", $$v)},expression:"\n                                              mileage.occurDate\n                                            "}})],1)])])])]),_c('li',{staticClass:"field"},[_c('div',{staticClass:"title required"},[_vm._v("적립/사용")]),_c('div',{staticClass:"content"},[_c('ul',{staticClass:"row"},[_c('li',{staticClass:"item form-group"},[_c('div',{staticClass:"form"},[_c('ejs-dropdownlist',{ref:"accmlUseDiv",attrs:{"dataSource":_vm.mileageOptions.accmlUseDivOptions,"allowFiltering":false,"fields":_vm.commonCodeFields,"cssClass":"body-data-dropdown"},on:{"change":_vm.onAccmlUseDivChange},model:{value:(
                                              _vm.mileage.accmlUseDiv
                                            ),callback:function ($$v) {_vm.$set(_vm.mileage, "accmlUseDiv", $$v)},expression:"\n                                              mileage.accmlUseDiv\n                                            "}})],1)])])])]),_c('li',{staticClass:"field"},[_c('div',{staticClass:"title required"},[_vm._v("마일리지구분")]),_c('div',{staticClass:"content"},[_c('ul',{staticClass:"row"},[_c('li',{staticClass:"item form-group"},[_c('div',{staticClass:"form"},[_c('ejs-dropdownlist',{ref:"mileageDiv",attrs:{"dataSource":_vm.mileageOptions.mileageDivOptions,"allowFiltering":false,"fields":_vm.commonCodeFields,"cssClass":"body-data-dropdown"},model:{value:(
                                              _vm.mileage.mileageDiv
                                            ),callback:function ($$v) {_vm.$set(_vm.mileage, "mileageDiv", $$v)},expression:"\n                                              mileage.mileageDiv\n                                            "}})],1)])])])]),_c('li',{staticClass:"field"},[_c('div',{staticClass:"title required"},[_vm._v("마일리지")]),_c('div',{staticClass:"content"},[_c('ul',{staticClass:"row"},[_c('li',{staticClass:"item form-group"},[_c('div',{staticClass:"form"},[_c('input-number',{ref:"mileage",attrs:{"id":"mileage","allowMinus":_vm.mileage.accmlUseDiv === 'USE',"propMaxLength":9},nativeOn:{"keydown":function($event){return _vm.preventEnterEvent.apply(null, arguments)}},model:{value:(
                                              _vm.mileage.mileage
                                            ),callback:function ($$v) {_vm.$set(_vm.mileage, "mileage", $$v)},expression:"\n                                              mileage.mileage\n                                            "}})],1)])])])]),_c('li',{staticClass:"field stdInfo"},[_c('div',{staticClass:"title required"},[_vm._v("적립기준정보")]),_c('div',{staticClass:"content"},[_c('ul',{staticClass:"row"},[_c('li',{staticClass:"item form-group"},[_c('div',{staticClass:"form"},[_c('input-text',{ref:"stdInfo",attrs:{"id":"stdInfo","maxlength":100},nativeOn:{"keydown":function($event){return _vm.preventEnterEvent.apply(null, arguments)}},model:{value:(
                                                      _vm.mileage.stdInfo
                                                    ),callback:function ($$v) {_vm.$set(_vm.mileage, "stdInfo", $$v)},expression:"\n                                                      mileage.stdInfo\n                                                    "}})],1)])])])]),_c('li',{staticClass:"field remarks"},[_c('div',{staticClass:"title"},[_vm._v("비고")]),_c('div',{staticClass:"content"},[_c('ul',{staticClass:"row"},[_c('li',{staticClass:"item form-group"},[_c('div',{staticClass:"form"},[_c('input-text',{ref:"remarks",attrs:{"id":"remarks","maxlength":100},nativeOn:{"keydown":function($event){return _vm.preventEnterEvent.apply(null, arguments)}},model:{value:(
                                                      _vm.mileage.remarks
                                                    ),callback:function ($$v) {_vm.$set(_vm.mileage, "remarks", $$v)},expression:"\n                                                      mileage.remarks\n                                                    "}})],1)])])])])]),_c('div',{staticClass:"border-left"}),_c('div',{staticClass:"border-right"}),_c('div',{staticClass:"border-top"}),_c('div',{staticClass:"border-bottom"})])]),_c('div',{staticClass:"border-left"}),_c('div',{staticClass:"border-right"}),_c('div',{staticClass:"border-top"}),_c('div',{staticClass:"border-bottom"})])])])])])])])])])]),_c('div',{staticClass:"windowFooter"},[_c('ul',{staticClass:"button"},[_c('li',{staticClass:"save keyColor"},[_c('erp-button',{attrs:{"button-div":"SAVE","is-key-color":true},nativeOn:{"click":function($event){return _vm.onSaveButtonClicked.apply(null, arguments)}}},[_vm._v(" 저장 ")])],1),_c('li',[_c('erp-button',{attrs:{"button-div":"DELETE","is-icon-custom":true},nativeOn:{"click":function($event){return _vm.onDeleteButtonClicked.apply(null, arguments)}}},[_vm._v(" 삭제 ")])],1),_c('li',{staticClass:"close"},[_c('erp-button',{attrs:{"button-div":"CLOSE"},nativeOn:{"click":function($event){return _vm.onPopupCloseButtonClicked.apply(null, arguments)}}},[_vm._v(" 닫기 ")])],1)])])])]),(_vm.isMemberSelectPopupOpen)?_c('member-select-popup',{ref:"memberSelectPopup",attrs:{"position":{ x: 'center', y: 'center' },"isModal":true},on:{"popupClosed":_vm.memberSelectPopupClosed,"popupConfirmed":_vm.memberSelectPopupConfirmed}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
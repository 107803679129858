<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">예약일자</div>
            <ul class="content">
              <li class="item date">
                <input-date
                  ref="priceUnitDatePicker"
                  format="YYYY-MM-DD"
                  v-model="priceUnitOptions.date"
                />
              </li>
              <li class="item text">
                (<span :style="{ color: getDayOfWeekCaptionColor( new Date(priceUnitOptions.date) ), }">
                {{ getDayOfWeekCaption(new Date(priceUnitOptions.date)) }}</span>)
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">요금구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  ref="bsnCodeDiv"
                  v-model="priceUnitOptions.paymtDiv"
                  :dataSource="searchConditionOptions.paymtCodes"
                  :allowFiltering="false"
                  :fields="searchConditionOptions.fields"
                  cssClass="lookup-condition-dropdown"
                >
                </ejs-dropdownlist>
              </li>
            </ul>
          </li>
          <li class="field sales">
            <div class="title">영업구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  ref="bsnCodeDiv"
                  v-model="priceUnitOptions.bsnCodeDiv"
                  :dataSource="searchConditionOptions.bsnCodes"
                  :allowFiltering="false"
                  :fields="searchConditionOptions.fields"
                  cssClass="lookup-condition-dropdown"
                >
                </ejs-dropdownlist>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
            button-div="GET"
            :is-shortcut-button="true"
            :ignore="isPopupOpened"
            @click.native="onClickPriceUnitGet"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">요금 단가 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button" style="margin-right: 140px">
                <li class="save keyColor">
                  <erp-button
                      button-div="SAVE"
                      :is-key-color="true"
                      :is-shortcut-button="true"
                      :ignore="isPopupOpened"
                      @click.native="onClickPriceUnitSave"
                  >
                    저장
                  </erp-button>
                </li>
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="priceUnit.shortcuts.addButton"
                      :shortcut="{key: 'F3'}"
                      :disabled="isModifyMode"
                      @click.native="onClickPriceUnitAdd"
                      :ignore="isPopupOpened"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="delete">
                  <erp-button
                      button-div="DELETE"
                      :is-shortcut-button="true"
                      :disabled="isModifyMode"
                      :ignore="isPopupOpened"
                      @click.native="onClickPriceUnitDelete"
                  >
                    삭제
                  </erp-button>
                </li>
                <li class="copy">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      :disabled="isModifyMode"
                      @click.native="onClickPriceCopyPopup"
                  >
                    요금복사
                  </erp-button>
                </li>
                <li class="setting">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      :disabled="isModifyMode"
                      @click.native="onClickPriceByHoleDividerPopup"
                  >
                    홀별 요금 분할설정
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
              <div class="header-switch">
                <div class="title">
                  멀티 편집모드
                </div>
                <div class="switch">
                  <ejs-switch
                    v-model="isModifyMode"
                    :checked="isModifyMode"
                    @change="onModifySwitchChanged"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="section-body">
            <article class="body-article">
              <div class="article-left">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-0101">
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="priceUnitDateGrid"
                        :provides="priceUnitDateGridOptions.provides"
                        :columns="priceUnitDateGridOptions.columns"
                        :dataSource="priceUnitDateList"
                        :editSettings="priceUnitDateGridOptions.provides"
                        :isNOColumnDisplay="false"
                        :isSelectedRowRetain="false"
                        @rowSelected="priceUnitDateSelect"
                        @dataBound="dataBoundEvent"
                      />
                    </div>
                  </div>
                </section>
              </div>
              <div class="article-right">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-0201">
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="priceUnitGrid"
                        :provides="priceUnitGridOptions.provides"
                        :columns="priceUnitGridOptions.columns"
                        :height="`100%`"
                        :dataSource="priceUnitDataList"
                        :editSettings="priceUnitGridOptions.editSettings"
                        :selectionSettings="selectionSettings"
                        :allowPaging="true"
                        :allowExcelExport="true"
                        :allowFiltering="!isModifyMode"
                        :allowSorting="!isModifyMode"
                        :isAutoSelectCell="!isModifyMode"
                        :noColumnWidth="40"
                        :pageSettings="priceUnitGridOptions.pageSettings"
                        @headerCellInfo="priceUnitGridHeaderCellInfo"
                        @queryCellInfo="priceUnitGridCustomiseCell"
                        @recordClick="priceUnitClick"
                        @actionComplete="priceUnitGridActionComplete"
                        @cellSelected="cellSelected"
                      />
                    </div>
                  </div>
                </section>
              </div>
            </article>
          </div>
        </section>
      </article>
    </div>
    <price-unit-copy-popup
      v-if="isPriceUnitCopyPopup"
      ref="priceUnitCopyPopup"
      @popupClosed="onPriceUnitCopyPopupClosed"
      @copy="onCopyPriceUnit"
    />
    <price-unit-add-popup
      v-if="isPriceUnitAddPopup"
      ref="priceUnitAddPopup"
      @popupClosed="onPriceUnitAddPopupClosed"
      @add="onAddPriceUnit"
    />
    <price-by-hole-divider-popup
      v-if="isPriceByHoleDividerPopup"
      ref="priceByHoleDividerPopup"
      @popupClosed="onPriceByHoleDividerPopupClosed"
      @setPriceDivData="onSetPriceDivData"
    />
    <edit-multiple-columns-popup
      v-if="isEditMultipleColumnsPopupOpen"
      ref="editMultipleColumnsPopup"
      @popupConfirm="onEditMultipleColumnsPopupConfirm"
      @popupClosed="onEditMultipleColumnsPopupClose"
    />
    <edit-multiple-number-popup
      v-if="isEditMultipleNumberPopupOpen"
      ref="editMultipleNumberPopup"
      @popupConfirm="onEditMultipleNumberPopupConfirm"
      @popupClosed="onEditMultipleNumberPopupClose"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.sales .content .item {width: 90px;}
body .appContent .body-article .article-left {width: 150px;}
body .appContent .body-article .article-right {width: calc(100% - 150px);}
body .appContent .body-article .section-body .section-body {overflow: hidden;border: none;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  getDayOfWeekCaptionColor,
  getDayOfWeekCaption,
  getFormattedDate,
} from "@/utils/date";
import {commonCodesGetColorValue, commonCodesGetCommonCode} from '@/utils/commonCodes';
import { numberWithCommas } from "@/utils/number";
import PriceUnitCopyPopup from "@/views/code-management/popup/PriceUnitCopyPopup";
import PriceUnitAddPopup from "@/views/code-management/popup/PriceUnitAddPopup";
import PriceByHoleDividerPopup from "@/views/code-management/popup/PriceByHoleDividerPopup";
import editMultipleColumnsPopup from '@/views/common/EditMultipleColumnsPopup';
import editMultipleNumberPopup from '@/views/common/EditMultipleNumberPopup';
import {
  Edit,
  ForeignKey,
  Resize,
  Page,
  ExcelExport,
} from "@syncfusion/ej2-vue-grids";
import {
  getPriceUnitDate,
  getPriceUnitDatas,
  getProductCodes,
  addPriceUnitDatas,
  deletePriceUnitDatas,
  savePriceUnitDatas,
  copyPriceUnit,
  getPriceUnitConf,
  putPriceUnitConf,
  getDetailPriceDivHole,
} from "@/api/priceUnit";
import Vue from "vue";
import InputDate from "@/components/common/datetime/InputDate";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "PriceUnit",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    InputDate,
    PriceUnitCopyPopup,
    PriceUnitAddPopup,
    PriceByHoleDividerPopup,
    editMultipleColumnsPopup,
    editMultipleNumberPopup,
    ErpButton,
  },
  created() {
    this.priceUnitOptions.date = getFormattedDate(new Date());
    this.searchConditionOptions.bsnCodes.unshift({
      comCode: "%",
      comName: "전체",
    });
    this.searchConditionOptions.paymtCodes.unshift({
      comCode: "%",
      comName: "전체",
    });
  },
  async mounted() {
    await Promise.all([this.getProductCodes(), this.fetchPriceUnitDate()]);
  },
  data() {
    return {
      count: 0,
      priceUnitDateList: [],
      totalCnt: 0,
      isRefresh: true,
      priceUnitDataList: [],
      originPriceUnitDataList: [],
      isPriceUnitCopyPopup: false,
      isPriceUnitAddPopup: false,
      isPriceByHoleDividerPopup: false,
      isEditMultipleColumnsPopupOpen: false,
      isEditMultipleNumberPopupOpen: false,
      priceUnitOptions: {
        date: null,
        bsnCodeDiv: "%",
        paymtDiv: "%",
      },
      productCodes: [
        { comName: "입장료(대중제)", comCode: "GFEE-00000002" },
      ],
      searchConditionOptions: {
        bsnCodes: commonCodesGetCommonCode("BSN_CODE"),
        paymtCodes: commonCodesGetCommonCode("PAYMT_CODE"),
        fields: { text: "comName", value: "comCode" },
      },
      customAttributes: { class: "customcss" },
      selectedDatas: {
        selectedDate: null,
      },
      selectionSettings: { mode: 'Both' },
      editparams: {
        params: {
          fields: { text: "productName", value: "productCode" },
          actionComplete: () => true,
        },
      },
      numericParams: {
        params: {
          validateDecimalOnType: true,
          decimals: 0,
          min: 0,
          step: 1000,
          format: "N",
        },
      },
      isModifyMode: false,
    };
  },
  computed: {
    isPopupOpened() {
      return (
        this.isPriceUnitCopyPopup ||
        this.isPriceUnitAddPopup ||
        this.isPriceByHoleDividerPopup ||
        this.isEditMultipleColumnsPopupOpen ||
        this.isEditMultipleNumberPopupOpen
      );
    },
    priceUnitDateGridOptions() {
      return {
        provides: [Edit, ForeignKey],
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
        },
        columns: [
          {
            field: "applyDate",
            headerText: "적용일자",
            type: "date",
            width: 90,
            textAlign: "center",
            format: { type: "date", format: "yyyy-MM-dd" },
          },
        ],
      };
    },
    priceUnitGridOptions() {
      return {
        provides: [Edit, ForeignKey, Resize, Page, ExcelExport],
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
        pageSettings: { pageSize: 50 },
        columns: [
          {
            field: "paymentPk",
            allowEditing: false,
            type: "string",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "paymtCode",
            headerText: "요금구분",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 100,
            textAlign: "Left",
            isCommonCodeField: true,
            groupCode: 'PAYMT_CODE',
          },
          {
            field: "bsnCode",
            headerText: "구분",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "Center",
            isCommonCodeField: true,
            groupCode: 'BSN_CODE',
          },
          {
            field: "dcContents",
            headerText: "할인내용",
            allowEditing: true,
            type: "string",
            minWidth: 16,
            width: 180,
            isRemarks: true,
          },
          {
            field: "productCode",
            headerText: "상품코드",
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "Left",
            editType: "dropdownedit",
            dataSource: this.productCodes,
            isCommonCodeField: true,
          },
          {
            field: "hole18",
            headerText: "18홀",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "right",
            isHorizontalEdit: true,
            isNumericType: true,
            allowFiltering: false,
            inputNumberProperty: { maxLength: 9 },
          },
          {
            field: "divider",
            headerText: "홀별분할",
            allowEditing: false,
            allowFiltering: false,
            type: "string",
            minWidth: 16,
            width: 55,
            textAlign: "Center",
            visible: !this.isModifyMode
          },
          {
            field: "hole18Plus",
            headerText: "18홀+",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "right",
            isHorizontalEdit: true,
            isNumericType: true,
            allowFiltering: false,
            inputNumberProperty: { maxLength: 9 },
          },
          {
            field: "hole17",
            headerText: "17홀",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "right",
            isHorizontalEdit: true,
            isNumericType: true,
            allowFiltering: false,
            inputNumberProperty: { maxLength: 9 },
          },
          {
            field: "hole16",
            headerText: "16홀",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "right",
            isHorizontalEdit: true,
            isNumericType: true,
            allowFiltering: false,
            inputNumberProperty: { maxLength: 9 },
          },
          {
            field: "hole15",
            headerText: "15홀",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "right",
            isHorizontalEdit: true,
            isNumericType: true,
            allowFiltering: false,
            inputNumberProperty: { maxLength: 9 },
          },
          {
            field: "hole14",
            headerText: "14홀",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "right",
            isHorizontalEdit: true,
            isNumericType: true,
            allowFiltering: false,
            inputNumberProperty: { maxLength: 9 },
          },
          {
            field: "hole13",
            headerText: "13홀",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "right",
            isHorizontalEdit: true,
            isNumericType: true,
            allowFiltering: false,
            inputNumberProperty: { maxLength: 9 },
          },
          {
            field: "hole12",
            headerText: "12홀",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "right",
            isHorizontalEdit: true,
            isNumericType: true,
            allowFiltering: false,
            inputNumberProperty: { maxLength: 9 },
          },
          {
            field: "hole11",
            headerText: "11홀",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "right",
            isHorizontalEdit: true,
            isNumericType: true,
            allowFiltering: false,
            inputNumberProperty: { maxLength: 9 },
          },
          {
            field: "hole10",
            headerText: "10홀",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "right",
            isHorizontalEdit: true,
            isNumericType: true,
            allowFiltering: false,
            inputNumberProperty: { maxLength: 9 },
          },
          {
            field: "hole9",
            headerText: "9홀",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "right",
            isHorizontalEdit: true,
            isNumericType: true,
            allowFiltering: false,
            inputNumberProperty: { maxLength: 9 },
          },
          {
            field: "hole9Plus",
            headerText: "9홀+",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "right",
            isHorizontalEdit: true,
            isNumericType: true,
            allowFiltering: false,
            inputNumberProperty: { maxLength: 9 },
          },
          {
            field: "hole8",
            headerText: "8홀",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "right",
            isHorizontalEdit: true,
            isNumericType: true,
            allowFiltering: false,
            inputNumberProperty: { maxLength: 9 },
          },
          {
            field: "hole7",
            headerText: "7홀",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "right",
            isHorizontalEdit: true,
            isNumericType: true,
            allowFiltering: false,
            inputNumberProperty: { maxLength: 9 },
          },
          {
            field: "hole6",
            headerText: "6홀",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "right",
            isHorizontalEdit: true,
            isNumericType: true,
            allowFiltering: false,
            inputNumberProperty: { maxLength: 9 },
          },
          {
            field: "hole5",
            headerText: "5홀",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "right",
            isHorizontalEdit: true,
            isNumericType: true,
            allowFiltering: false,
            inputNumberProperty: { maxLength: 9 },
          },
          {
            field: "hole4",
            headerText: "4홀",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "right",
            isHorizontalEdit: true,
            isNumericType: true,
            allowFiltering: false,
            inputNumberProperty: { maxLength: 9 },
          },
          {
            field: "hole3",
            headerText: "3홀",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "right",
            isHorizontalEdit: true,
            isNumericType: true,
            allowFiltering: false,
            inputNumberProperty: { maxLength: 9 },
          },
          {
            field: "hole2",
            headerText: "2홀",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "right",
            isHorizontalEdit: true,
            isNumericType: true,
            allowFiltering: false,
            inputNumberProperty: { maxLength: 9 },
          },
          {
            field: "hole1",
            headerText: "1홀",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "right",
            isHorizontalEdit: true,
            isNumericType: true,
            allowFiltering: false,
            inputNumberProperty: { maxLength: 9 },
          },
        ],
      };
    },
  },
  methods: {
    numberWithCommas,
    getDayOfWeekCaptionColor,
    getDayOfWeekCaption,
    formatDate(date) {
      if (date) {
        let d = new Date(date);
        let month = "" + (d.getMonth() + 1);
        let day = "" + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) {
          month = "0" + month;
        }
        if (day.length < 2) {
          day = "0" + day;
        }

        return [year, month, day].join("-");
      } else {
        return null;
      }
    },
    numberTemplate() {
      return {
        template: Vue.component("editOption", {
          template: "<span>{{parseInt(data.index) + 1}}</span>",
          data() {
            return {
              data: {
                data: {},
              },
            };
          },
        }),
      };
    },
    async priceUnitDateSelect(args) {
      if (this.isPriceUnitUpdate()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      this.selectedDatas.selectedDate = args.data.applyDate;
      await this.fetchPriceUnitData();
    },
    async getProductCodes() {
      const {
        value: { paymentProductList },
      } = await getProductCodes();
      this.productCodes = paymentProductList.map(item => ({
        ...item,
        comCode: item.productCode,
        comName: item.productName,
      }));
    },
    async fetchPriceUnitData() {
      const {
        value: { paymentCnt, paymentInfoList },
      } = await getPriceUnitDatas(
          this.selectedDatas.selectedDate,
          this.priceUnitOptions.bsnCodeDiv !== "%"
              ? this.priceUnitOptions.bsnCodeDiv
              : undefined,
          this.priceUnitOptions.paymtDiv !== "%"
              ? this.priceUnitOptions.paymtDiv
              : undefined
      );
      this.priceUnitDataList = paymentInfoList;
      this.originPriceUnitDataList = JSON.parse(
          JSON.stringify(this.priceUnitDataList)
      );
      this.totalCnt = paymentCnt;
    },
    async fetchPriceUnitDate() {
      if (!this.priceUnitOptions.date) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["예약일자"])
        );
      }

      const {
        value: { applyDateList },
      } = await getPriceUnitDate(this.priceUnitOptions.date);
      this.priceUnitDateList = applyDateList;
    },
    onPriceUnitCopyPopupClosed() {
      this.isPriceUnitCopyPopup = false;
    },
    async onCopyPriceUnit(applyDate, copyDate) {
      await copyPriceUnit(
          this.formatDate(applyDate),
          this.formatDate(copyDate)
      );
      this.$refs.priceUnitCopyPopup.onPriceUnitCopyPopupClosed();
      await this.fetchPriceUnitDate();
    },
    async onClickPriceCopyPopup() {
      if (this.selectedDatas.selectedDate === null) {
        return;
      }
      if (this.isPriceUnitUpdate()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      this.isPriceUnitCopyPopup = true;
      this.$nextTick(() => {
        this.$refs.priceUnitCopyPopup.onShowPriceUnitCopy(
          this.selectedDatas.selectedDate
        );
      });
    },
    async onClickPriceUnitGet() {
      await this.fetchPriceUnitDate();
    },
    async onClickPriceUnitAdd() {
      if (this.isPriceUnitUpdate()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      this.isPriceUnitAddPopup = true;
      this.$nextTick(() => {
        this.$refs.priceUnitAddPopup.onShowPriceUnitAdd();
      });
    },
    async onClickPriceUnitDelete() {
      if (this.selectedDatas.selectedDate === null) {
        return;
      }
      if (this.isPriceUnitUpdate()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      if (
        await this.confirm(
          `적용일자 : ${this.selectedDatas.selectedDate} 삭제하시겠습니까?`
        )
      ) {
        await deletePriceUnitDatas(this.selectedDatas.selectedDate);
        await this.fetchPriceUnitDate();
      }
    },
    async onClickPriceUnitSave() {
      if (this.selectedDatas.selectedDate === null) {
        return;
      }
      const batchChangesData = this.$refs.priceUnitGrid.getBatchChanges();

      if (batchChangesData.changedRecords.length > 0) {
        await savePriceUnitDatas({
          changedRecords: batchChangesData.changedRecords,
        });
        await this.fetchPriceUnitData();

        this.infoToast(this.$t("main.popupMessage.saved"));
      }
    },
    onPriceUnitAddPopupClosed() {
      this.isPriceUnitAddPopup = false;
    },
    async onAddPriceUnit(addDate) {
      await addPriceUnitDatas(this.formatDate(addDate));
      this.$refs.priceUnitAddPopup.onPriceUnitAddPopupClosed();
      await this.fetchPriceUnitDate();
    },
    onPriceByHoleDividerPopupClosed() {
      this.isPriceByHoleDividerPopup = false;
    },
    async onClickPriceByHoleDividerPopup() {
      const {
        value: { confCom },
      } = await getPriceUnitConf();
      if (confCom) {
        this.isPriceByHoleDividerPopup = true;
        this.$nextTick(() => {
          this.$refs.priceByHoleDividerPopup.onShowPriceByHoleDivider(
              confCom
          );
        });
      }
    },
    async onSetPriceDivData(data) {
      await putPriceUnitConf(data);
      this.$refs.priceByHoleDividerPopup.onPriceByHoleDividerPopupClosed();
    },
    onEditMultipleColumnsPopupConfirm(args) {
      const {
        field,
        value,
        rowIndexes,
      } = args;
      this.isEditMultipleColumnsPopupOpen = false;
      if (rowIndexes.length < 1) {
        return;
      }
      rowIndexes.forEach(index => {
        this.$refs.priceUnitGrid.updateCell(
          index,
          field,
          value,
        );
      });
    },
    onEditMultipleColumnsPopupClose() {
      this.isEditMultipleColumnsPopupOpen = false;
    },
    onEditMultipleNumberPopupConfirm(args) {
      const {
        fields,
        value,
        rowIndexes,
      } = args;
      this.isEditMultipleNumberPopupOpen = false;
      if (rowIndexes.length < 1) {
        return;
      }
      for (const index of rowIndexes) {
        for (const field of fields) {
          this.$refs.priceUnitGrid.updateCell(
            index,
            field,
            value,
          );
        }
      }
    },
    onEditMultipleNumberPopupClose() {
      this.isEditMultipleNumberPopupOpen = false;
    },
    priceUnitClick(args) {
      if (args.column && args.column.field === "divider") {
        const batchChangesData = this.$refs.priceUnitGrid;
        // rowData 의 경우 변경전 데이터임에 따라 최초 기존 hole18 데이터 입력 후
        // 변경된 데이터가 있을 경우 변경된 데이터로 치환
        let requestData = args.rowData["hole18"];
        let bsnCode = args.rowData["bsnCode"];
        console.log(args);
        batchChangesData.getBatchChanges().changedRecords.some((val) => {
          if (val.paymtId === args.rowData["paymtId"]) {
            requestData = val.hole18;
            return true;
          }
        });
        if (requestData !== 0) {
          const tmpData = Object.assign({}, args.rowData);
          getDetailPriceDivHole(requestData,bsnCode).then((result) => {
            Object.keys(result.value).forEach((key) => {
              tmpData[key] = result.value[key];
            });
            Object.keys(tmpData).forEach((key) => {
              if (
                batchChangesData.columns.some((column) => column.field === key)
              ) {
                batchChangesData.updateCell(args.rowIndex, key, tmpData[key]);
              }
            });
          });
        }
      }
    },
    isPriceUnitUpdate() {
      let isModified = false;
      const batchChangesData = this.$refs.priceUnitGrid.getBatchChanges();
      if (batchChangesData.changedRecords.length > 0) {
        isModified = true;
      }
      if (
        JSON.stringify(this.priceUnitDataList) !==
        JSON.stringify(this.originPriceUnitDataList)
      ) {
        isModified = true;
      }
      return isModified;
    },
    dataBoundEvent() {
      if (this.priceUnitDateList.length > 0) {
        this.$refs.priceUnitDateGrid.selectRow(0);
      }
    },
    priceUnitGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field,
            headerText,
          },
        },
        node,
      } = args;
      if (
        field === "productCode" ||
        field === "hole18"
      ) {
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      } else if (field === "divider") {
        node.classList.add(this.$t("className.grid.nonePadding"));
      }
      if (this.isModifyMode && headerText !== 'NO') {
        const allowedEditColumns = this.priceUnitGridOptions.columns
          ?.filter(item => item.allowEditing)
          ?.map(item => item.field);
        if (allowedEditColumns.includes(field)) {
          node.style.backgroundColor = 'rgb(237, 246, 250)';
          node.addEventListener('click', async () => await this.onGridHeaderClicked(args.cell.column), false);
        }
      }
    },
    async onGridHeaderClicked(column) {
      if (!column?.field) {
        return;
      }
      const gridRefs = this.$refs.priceUnitGrid;
      const selectedRowIndexes = [...new Array(gridRefs?.getBatchCurrentViewRecords().length).keys()];
      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
        column,
        selectedRowIndexes
      );
    },
    priceUnitGridCustomiseCell(args) {
      const {
        column: {field},
        cell,
        data,
      } = args;
      if (
        field === "productCode" ||
        field === "hole18Plus" ||
        field === "hole9Plus" ||
        field === "hole18" ||
        field === "hole17" ||
        field === "hole16" ||
        field === "hole15" ||
        field === "hole14" ||
        field === "hole13" ||
        field === "hole12" ||
        field === "hole11" ||
        field === "hole10" ||
        field === "hole9" ||
        field === "hole8" ||
        field === "hole7" ||
        field === "hole6" ||
        field === "hole5" ||
        field === "hole4" ||
        field === "hole3" ||
        field === "hole2" ||
        field === "hole1" ||
        field === "dcContents"
      ) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (
        !this.isModifyMode &&
        (field === "hole18" || field === "hole9")
      ) {
        cell.style.backgroundColor = "rgba(46, 116, 88, .1)";
      }
      if (field === "divider") {
        cell.classList.add(this.$t("className.grid.divideArea"));
      }
      if (field === "bsnCode") {
        cell.style.color = commonCodesGetColorValue(
          "BSN_CODE",
          data.bsnCode
        );
      }
      if (this.isModifyMode) {
        const allowedEditColumns = this.priceUnitGridOptions.columns
          ?.filter(item => item.allowEditing)
          ?.map(item => item.field);
        if (!allowedEditColumns.includes(field)) {
          cell.style.backgroundColor = '#f9f9f9';
          cell.style.pointerEvents = 'none';
        }
      }
    },
    priceUnitGridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.priceUnitDateGrid?.getGridBatchCount() || 0
      );
    },
    onClickExcel() {
      this.$refs.priceUnitGrid.excelExport();
    },
    onModifySwitchChanged(args) {
      const isChecked = args.checked;
      this.selectionSettings = isChecked
        ? {cellSelectionMode: 'Box', type: 'Multiple', mode: 'Cell'}
        : {mode: 'Both'};
      this.$refs.priceUnitGrid.refresh();
      if (isChecked) {
        this.infoToast('멀티 편집할 셀 하나와 여러 로우를 드래그하세요.');
      }
    },
    async cellSelected(args) {
      if (!this.isModifyMode) {
        return;
      }
      const {
        cellIndex: {cellIndex},
        selectedRowCellIndex
      } = args;
      const cellIndexes = selectedRowCellIndex[0]?.cellIndexes;
      let columns = [];
      this.priceUnitGridOptions.columns
        ?.forEach(column => {
          if (column?.columns) {
            column.columns
              ?.forEach(c => columns.push(c));
          } else {
            columns.push(column);
          }
        });
      const selectedRowIndexes = selectedRowCellIndex.map(item => item.rowIndex);
      if (selectedRowIndexes.length < 1) {
        return;
      }
      if (cellIndexes.length > 1) {
        const allowedEditColumns = columns
          ?.filter(item => item.allowEditing && item.isHorizontalEdit)
          ?.map(item => item.field);
        let editColumns = [];
        cellIndexes?.forEach(c => editColumns.push(columns[c - 1]?.field));
        editColumns = editColumns?.filter(field => field !== 'divider');
        const hasNotHorizontalEdit = editColumns
          ?.filter(field => !allowedEditColumns?.includes(field));
        if (hasNotHorizontalEdit.length > 0) {
          this.errorToast('할인내용 또는 상품코드는 홀별 요금과 멀티 편집할 수 없습니다.');
          return;
        }
        this.isEditMultipleNumberPopupOpen = true;
        await this.$nextTick();
        this.$refs.editMultipleNumberPopup.showPopup(
          editColumns,
          selectedRowIndexes
        );
      } else {
        const allowedEditColumns = columns
          ?.filter(item => item.allowEditing)
          ?.map(item => item.field);
        const column = columns[cellIndex - 1];
        if (!allowedEditColumns.includes(column?.field)) {
          this.errorToast('편집할 수 있는 셀이 아닙니다.');
          return;
        }
        this.isEditMultipleColumnsPopupOpen = true;
        await this.$nextTick();
        this.$refs.editMultipleColumnsPopup.showPopup(
          column,
          selectedRowIndexes
        );
      }
    },
  },
};
</script>

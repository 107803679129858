import qs from "qs";

export const PATH_RESERVATION = `/reservation`;

export function fetchReservationGuestBooks(args) {
  return this.http.get(`${PATH_RESERVATION}/reservation-guest-books`, {
    params: {
      resveDate: args.resveDate,
      resveCourseList: args.resveCourseList,
      resveTimeFrom: args.resveTimeFrom,
      resveTimeTo: args.resveTimeTo,
      grpKindList: args.grpKindList,
      partDivList: args.partDivList,
      resveRemarks: args.resveRemarks,
      resveName: args.resveName,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

export function fetchReservationHistory(id) {
  return this.http.get(`/reservations/${id}/history`);
}

export function fetchReservationStatus(resveDate) {
  return this.http.get(`${PATH_RESERVATION}/reservation-status`, {
    params: {
      resveDate: resveDate,
    },
  });
}

export function fetchGroupReservationStatus(args) {
  return this.http.get(`${PATH_RESERVATION}/group-reservation-status`, {
    params: {
      resveDateFrom: args.resveDateStart,
      resveDateTo: args.resveDateEnd,
      grpKindList: args.grpKindList,
      grpName: args.resveGrpName,
      grpNo: args.resveGrpNo,
      aditTeamFlag: args.aditTeamFlag,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

export function fetchResvePromtnAll() {
  return this.http.get(`/reservations/resve-promtn/all`);
}

export function postRegisterWalkIn(
  reservationTime,
  visitDate,
  reservationKind,
  reservationChannel,
  reservationName,
  reservationRemarks,
  membershipId,
  memberNumber,
  memberDivision,
  memberGrade,
  contactName,
  contactTel,
  groupNumber,
  groupKind,
  groupName,
  posNo
) {
  return this.http.post(`/reservations/walk-in`, {
    reservationTime,
    visitDate,
    reservationKind,
    reservationChannel,
    reservationName,
    reservationRemarks,
    membershipId,
    memberNumber,
    memberDivision,
    memberGrade,
    contactName,
    contactTel,
    groupNumber,
    groupKind,
    groupName,
    posNo
  });
}

export function postAdditionalHole(
  groupNumber,
  checkInId,
  holeDivision,
  visitDate,
  reservationTime,
  posNo,
  groupKind,
  groupName,
) {
  return this.http.post(`/reservations/add-hole`, {
    groupNumber,
    checkInId,
    holeDivision,
    visitDate,
    reservationTime,
    posNo,
    groupKind,
    groupName,
  });
}

export function patchTeamOrVisitors(
  teamInfo,
  addedRecords,
  changedRecords,
  deletedRecords,
  reservationId,
  delReason,
  posNo
) {
  return this.http.patch(`/reservations/${reservationId}/team`, {
    teamInfo,
    addedRecords,
    changedRecords,
    deletedRecords,
    delReason,
    posNo
  });
}

<template>
  <div class="content-wrapper">
    <div class="content-lookup">  <!-- 필터 -->
      <div class="lookup-left">
        <ul class="lookup-condition">
          <!-- 범위 날짜 검색 -->
          <li class="field">
            <div class="title">마감년</div>
            <ul class="content">
              <li class="item date">
                <input-date
                    ref = "closeYear"
                    :format="DATE_FORMAT_YYYY"
                    :notClear="true"
                    depth="Decade"
                    v-model="searchFilter.closeYear"
                    type="lookup-condition"
                    @change="onYearChange"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button button-div="GET"  :ignore="isPopupOpened" :is-shortcut-button="true" @click.native="onClickSearch()">조회</erp-button>
        </div>
      </div>
      <div class="lookup-right"> <!-- 사용시 좌측이 없을 경우 생략-->
        <ul class="lookup-button"></ul>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article detail-view-opened" >
        <div class="article-left">
          <section class="article-section">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">{{ gridField.title }}</div>
                <div class="header-caption">[{{ gridField.count }} 건]</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                </ul>
              </div>
            </div>
            <div class="section-body">
              <div class="body-grid">
                <ejs-grid-wrapper
                    ref="grid"
                    v-bind="initGrid"
                    @actionComplete="gridComplete"
                    @recordClick="onGridClick"
                />
              </div>
            </div>
          </section>
        </div>
        <div class="article-right">
          <section class="article-section">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">{{ gridField2.title }}</div>
                <div class="header-caption">{{getCurrentMonth}}</div>
                <div class="header-caption">[{{ gridField2.count }} 건]</div>
                <ul class="lookup-lookup">
                  <erp-button button-div="SAVE" v-if="isGetButton" :is-key-color="false" :is-shortcut-button="false" :ignore="isPopupOpened" @click.native="onClickLoadData()">마감품목 불러오기</erp-button>
                </ul>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="save keyColor">
                    <erp-button button-div="SAVE" v-if="isSaveButton" :is-key-color="false" :is-shortcut-button="false" :ignore="isPopupOpened" @click.native="onClickSave">마감확정</erp-button>
                  </li>
                  <li class="delete keyColor">
                    <erp-button button-div="SAVE" v-if="isGeDelButton" :is-key-color="false" :is-shortcut-button="false" :ignore="isPopupOpened" @click.native="onClickDelete">마감취소</erp-button>
                  </li>
                  <li class="print">
                    <erp-button button-div="FILE" @click.native="onClickExcel">Excel</erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <div class="body-grid table-side">
                <ejs-grid-wrapper
                    ref="grid2" v-bind="initGrid2"
                    @headerCellInfo="onHeaderCellInfo2"
                    @queryCellInfo="onQueryCellInfo2"
                    @cellSaved="onCellSaved2"
                    @actionComplete="gridComplete2"/>
              </div>
            </div>
          </section>
        </div>
      </article>

    </div>
    <!-- 각종팝업 -->
  </div>
</template>
<!-- ##################################### CUSTOM STYLE ########################################* -->
<script>


import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import moment from "moment";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import { Edit, ExcelExport, Filter, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import {
  DATE_FORMAT_YYYY, DATE_FORMAT_YYYY_MM,
  getFormattedDate,
} from "@/utils/date";
import {mapGetters} from "vuex";
import { validateFormRefs} from "@/utils/formUtil";
import InputDate from "@/components/common/datetime/InputDate.vue";
import StockCommon from "@/api/v2/StockManage/StockCommon";
import StockSuppliesStat from "@/api/v2/StockManage/StockSuppliesStat";



export default {
  name: "SuppliesStockMonthlyClosing",
  mixins: [commonMixin, messagePopupDialogMixin],
  components: { InputDate,  ejsGridWrapper, ErpButton, },

  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      DATE_FORMAT_YYYY,
      DATE_FORMAT_YYYY_MM,
      searchFilter: {
        closeYear: null,
      },
      //최근 마감월
      lastCloseYm : null,
      //버튼 상태
      isGetButton : false,
      isSaveButton : false,
      isGeDelButton : false,

      //드롭다운 코드
      commonCodeOptions: {
        wrhousData:[],
        fields: { text: "comName", value: "comCode" },
      },
      //detail
      detailData: {
        closeYm: null,
        dtlListD:[],
        dtlListU:[],
        dtlListI:[],
      },
      gridField: {
        title: "재고 월마감 목록",
        count: 0,
        dataSource: [],
      },
      gridField2: {
        title: "월마감 상세",
        count: 0,
        dataSource: [],
      },
    };
  },
  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    ...mapGetters(["userIp","userId","username"]),
    isPopupOpened() {
      return false;
    },
    //선택된 마감월 포멧
    getCurrentMonth(){
      return  getFormattedDate(this.detailData.closeYm,DATE_FORMAT_YYYY_MM);
    },

    initGrid() {
      return {
        columns: [
          {field: 'closeYm',        textAlign: 'center', width: 80,   visible: true,    allowEditing : false,  type: 'string',   headerText: '마감월', isPrimaryKey: true,
            valueAccessor: (field,data) => getFormattedDate(data[field],DATE_FORMAT_YYYY_MM) },
          {field: 'closingFlag',    textAlign: 'center', width: 80,   visible: true,    allowEditing : false,  type: 'boolean',  headerText: '마감확정', displayAsCheckBox: true,},
          {field: 'closingName',    textAlign: 'center', width: 80,   visible: true,    allowEditing : false,  type: 'string',   headerText: '마감자', },
          {field: 'closingDt',      textAlign: 'center', width: 100,  visible: true,    allowEditing : false,  type: 'string',   headerText: '마감일', },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:false,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        pageSettings: { pageSize: 50 },
      };
    },
    initGrid2() {
      return {
        columns: [
          { field: "dtlId",         textAlign: 'center', allowEditing: false, width: 70,   visible: false, type: "string",  headerText: "마감번호", },
          { field: "closeYm",       textAlign: 'center', allowEditing: false, width: 70,   visible: false, type: "string",  headerText: "마감년월", },
          { field: "pathDesc",      textAlign: 'left',   allowEditing: false, width: 250,  visible: true,  type: "string",  headerText: "분류", },
          { field: "suppliesCode",  textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "저장품코드" },
          { field: "suppliesName",  textAlign: 'left',   allowEditing: false, width: 200,  visible: true,  type: "string",  headerText: "저장품", },
          { field: "unit",          textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "단위", },
          { field: "wrhousCode",    textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "창고", isCommonCodeField: true, dataSource: this.commonCodeOptions.wrhousData,},
          { field: "lsmthStock",    textAlign: 'right',  allowEditing: false, width: 100,  visible: true,  type: "number",  headerText: "전월", isNumericType: true,},
          { field: "wrhousngQty",   textAlign: 'right',  allowEditing: false, width: 100,  visible: true,  type: "number",  headerText: "입고", isNumericType: true,},
          { field: "rtngudQty",     textAlign: 'right',  allowEditing: false, width: 100,  visible: true,  type: "number",  headerText: "반품", isNumericType: true,},
          { field: "dlivyQty",      textAlign: 'right',  allowEditing: false, width: 100,  visible: true,  type: "number",  headerText: "출고", isNumericType: true,},
          { field: "stockQty",      textAlign: 'right',  allowEditing: false, width: 100,  visible: true,  type: "number",  headerText: "재고", isNumericType: true,},
          { field: "closeStock",    textAlign: 'right',  allowEditing: true,  width: 100,  visible: true,  type: "number",  headerText: "실사(당월)", isNumericType: true,
            inputNumberProperty: {allowDot: true,maxUnderDotLength: 3,allowMinus: false}},
          { field: "irdsQty",       textAlign: 'right',  allowEditing: false, width: 80,  visible: true,  type: "number",  headerText: "증감", isNumericType: true,},
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField2.dataSource,

        allowResizing: true,
        allowSorting: false,
        allowFiltering: false,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        pageSettings: { pageSize: 50 },
        //그리드 정합성 체크
        validationRules: {
          closeYm:    {required: true},
          wrhousCode:    {required: true},
          suppliesCode:    {required: true},
          closeStock : {
            required: true,
            // min:0, // 입고없이 출고를 할 경우(입고는 다음달에 옴) - 재고가 발생할 수 있어서 월마감도 - 임. 그러므로 0제한 삭제함 2023.11.08 TOMAS
            custom: {
              method: (record) => {
                if(record.irdsQty > 0){
                  return;
                }
                return true;
              },
              message: "증감 수량은 0이거나 0보다 작아야 합니다.실사 수량을 조정하거나 입,출고 등록을 처리하세요.",
            },
          }
        },
      };

    },
  },

  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
  },
  async mounted() {
    //기초데이터 세팅
    await this.initData();
  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
    //region ******************************* 공통 function *******************************
    //폼정합성 체크
    validateFormRefs,
    //기초데이터 세팅
    async initData() {
      //this.detailData.closeYm = moment().format("YYYYMM");  //금월 설정
      this.searchFilter.closeYear = moment().format(DATE_FORMAT_YYYY);  //마감년 기본 값
      this.commonCodeOptions.wrhousData  = await StockCommon.getWrhous({useFlag:'N',authoFlag:'N',suppliesFlag:'Y'});  //저장품 창고
    },
    //데이터 초기화
    clearDetailData() {
      this.detailData = {
        closeYm: null,
        dtlListD: [],
        dtlListU: [],
        dtlListI: [],
      };
      this.gridField2.dataSource = [];
      this.isGetButton = false;
      this.isSaveButton = false;
      this.isGeDelButton = false;
    },
    //endregion
    //region******************************* 버튼 및 컨트롤 이벤트 *******************************
    //마감년 변경시
    onYearChange() {
      this.onClickSearch();
    },
    //조회
    async onClickSearch(){
      //마지막 마감월 가져오기
      this.lastCloseYm =  await StockCommon.getLastSuppliesClosingYm();
      //이번년도 마감이력 조회
      this.gridField.dataSource = await StockSuppliesStat.getSuppliesClosingList(this.searchFilter);
      //기 선택된 행이 있다면 찾아서 자동 선택
      setTimeout(() => {
        //최종마감월로 선택해주자
        let index = this.lastCloseYm ? this.$refs.grid.getRowIndexByPrimaryKey(this.lastCloseYm) : 0;
        //해당년에 마감월이 없으면 첫번째 행
        if(index == -1){
          index = 0;
        }
        this.$refs.grid.selectRow(index);
        this.$refs.grid.getRowByIndex(index)?.click();
      }, 50);
    },
    //마감자료 불러오기(이력으로 데이터 생성)
    onClickLoadData(){
      this.getDetailItemApi({closeYm:this.detailData.closeYm, closingFlag:"N"});
    },
    //상세조회 API
    async getDetailItemApi(args){
      this.clearDetailData();
      this.detailData.closeYm = args.closeYm;
      if(args.closeYm == null) { return;}
      //마감자료 조회
      const dataSource  = await StockSuppliesStat.getSuppliesClosingDetail(args);
      //그리드 세팅( 신규면 신규행으로)
      if(args.closingFlag == "Y"){
        this.gridField2.dataSource =  dataSource;
      }else{
        if(dataSource !== null){
          for (const data of dataSource) {
            this.$refs.grid2.addRecord(data);
          }
        }
        //ROW- COUNT(추가행만, 일반 조회는 gridComplete2에서 수행
        this.gridField2.count = this.$refs.grid2.getBatchCurrentViewRecords().length;
      }

      //마감데이터 불러오기 활성화( 마감된 데이터가 없거나 마지막 마감월 +1 만 활성, 현재월까지만 마감가능)
      this.isGetButton = this.lastCloseYm == null || (args.closeYm == moment(this.lastCloseYm).add(1,"months").format("YYYYMM")  && args.closeYm <= moment().format("YYYYMM")) ;
      //마감확정 활성화( 마감불러오기 활성화, 마감데이터 있음,마지막 마감월 +1)
      this.isSaveButton = this.isGetButton && dataSource.length > 0 ;
      //마감삭제 홝성화( 마감완료된 데이터, 마감데이터 있음, 마지막 마감월)
      this.isGeDelButton = args.closingFlag == "Y" && dataSource.length > 0 && this.lastCloseYm == args.closeYm ;

      //실사항목 활성(신규만), 비활성화
      setTimeout(() => {
        this.$refs.grid2.getColumnObject("closeStock").allowEditing = args.closingFlag != "Y";
      }, 50);
    },
    //저장(마감확정)
    async onClickSave() {
      //1. 그리드 필수 입력체크
      if (this.$refs.grid2.isGridModified() && !this.$refs.grid2.validate()) {
        return;
      }
      const {addedRecords, changedRecords, deletedRecords,} = this.$refs.grid2.getBatchChanges();
      this.detailData.dtlListD = deletedRecords;
      this.detailData.dtlListU = changedRecords;
      this.detailData.dtlListI = addedRecords;
      //2.저장
      //await this.apiSave();
      await StockSuppliesStat.postSuppliesClosingDetail(this.detailData);
      //4. 메세지 호출
      this.infoToast(this.$t('main.popupMessage.saved'));
      await this.onClickSearch();

    },
    //삭제(마감해제)
    async onClickDelete(){
      //1. 확인
      if (!(await this.confirm(`[${this.getCurrentMonth}] 마감 해제하시겠습니까?`))) {
        return;
      }
      //2. 삭제처리
      const args = {deleteKey:this.detailData.closeYm};
      await StockSuppliesStat.deleteSuppliesClosingDetail(args);
      //3. 메세지 호출
      this.infoToast(`마감 해제되었습니다.`);
      await this.onClickSearch();
    },
    //엑셀
    onClickExcel(){
      this.$refs.grid2.excelExport({fileName: this.gridField2.title+".xlsx"});
    },
    //endregion

    //region ******************************* 그리드 이벤트 *******************************
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    gridComplete2(){
      this.gridField2.count = this.$refs.grid2.getGridBatchCount();
    },
    onGridClick(args){
      const {rowData} = args;
      //마감완료자료 조회
      this.getDetailItemApi({closeYm:rowData.closeYm, closingFlag:"Y"});
    },
    onHeaderCellInfo2(args){
      const {cell: {column:{field}}, node} = args;
      if(this.$refs.grid2.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    onQueryCellInfo2(args) {
      const {column:{field,allowEditing}, cell,} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },
    //실사수량 입력시 증감수량 계산
    onCellSaved2(args){
      let data= args.rowData;
      //저장된 행이 아닌 추가행에서만 수정이 발생하므로  index가 포함되어 있음. 저장된행은 키값으로 찾아야함
      let index= this.$refs.grid2.getRowIndexByPrimaryKey(data._rid);
      //let index = data.index;
      if(args.columnName ===  "closeStock"){
        this.$refs.grid2.updateCell(index, "irdsQty", Number((args.value - data.stockQty).toFixed(3)));
      }
      /*if(args.columnName ===  "closeStock"){
        data.closeStock = args.value;
        data.irdsQty =  (args.value - data.stockQty);
        this.$refs.grid2.updateRow(index,data);
      }*/
    },
    //endregion
  },

};

</script>
<style scoped>
body .appContent .body-article .article-left,body .appContent .body-article .article-right{transition:all .3s ease-out}
body .appContent .body-article .article-left{width:100%;}
/*body .appContent .body-article .article-right{margin-right: calc(-70% - 0px)}*/
body .appContent .body-article.detail-view-opened .article-left{width:calc(20% - 0px)}
body .appContent .body-article.detail-view-opened .article-right{width:calc(80% - 0px); margin:0}
body .body-footer .section-header {
  display: block;
  position: relative;
  margin: 0px 0px;
}
.voc-section {
  width: 100% !important;
}

</style>
<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              {{ labels.bsnDate }}
            </div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                  v-model="inputDateRangeValue"
                  type="lookup-condition"
                />
              </li>
            </ul>
          </li>
          <li class="field groupName">
            <div class="title">
              {{ labels.grpName }}
            </div>
            <ul class="content">
              <li class="item input">
                <input-text
                  v-model="searchConditions.grpName"
                  @focus="onGrpNameClear"
                  @keydown.native="preventEnterEvent"
                />
              </li>
            </ul>
            <ul class="content">
              <li class="item button">
                <ul class="button">
                  <li class="search">
                    <erp-button
                        button-div="GET"
                        @click.native="onGroupPopupSearchDetailOpen"
                    >
                      검색
                    </erp-button>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item check">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="radio"
                        value="true"
                        v-model="searchConditions.amtFlag"
                        name="amtFlag"
                      />
                      <i></i>
                      <div class="label">총매출</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="radio"
                        value="false"
                        v-model="searchConditions.amtFlag"
                        name="amtFlag"
                      />
                      <i></i>
                      <div class="label">순매출</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        value="true"
                        v-model="subTotSumFlag"
                      />
                      <i></i>
                      <div class="label">소계 안보이기</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              v-on:click.native="onViewTeamSales"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              button-div="GET"
              :is-icon-custom="true"
              @click.native="onSearchDetailPopupOpen"
          >
            상세검색
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true" />
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">단체 매출 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="excel"
                  >
                    Excel
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      @click.native="print"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                  >인쇄
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="teamSalesGrid"
                :provides="grid"
                :columns="teamSalesGridColumn"
                :dataSource="groupSalesInfoList"
                :groupSettings="groupSettings"
                :pageSettings="groupSalesPageSettings"
                :aggregates="teamSalesGridColumnAggregates"
                :allowResizing="true"
                :allowExcelExport="true"
                :allowPdfExport="true"
                :allowGrouping="true"
                :allowPaging="true"
                @headerCellInfo="gridHeaderCellInfo"
                @queryCellInfo="queryCellInfo"
                @recordClick="gridRecordClick"
                @actionComplete="teamSalesGridActionComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <ejs-dialog
      ref="searchDetailPopup"
      :header="`상세검색`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="387"
      :isModal="false"
      :visible="false"
      v-show="isSearchDetailPopupOpen"
    >
      <div class="window lookupDetail-groupSalesStatus">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">
                    {{ labels.bsnCode }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchConditions.bsnCode"
                        :dataSource="searchOptions.bsnCodeOptions"
                        :fields="commonCodeFields"
                        :allowFiltering="false"
                        cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.dwCode }}
                  </div>
                  <ul class="content">
                    <li class="item form-group">
                      <ul class="check">
                        <li>
                          <label>
                            <input
                              type="checkbox"
                              :checked="
                                searchOptions.dwCodeOptions.length ===
                                searchConditions.dwCode.length
                              "
                              @change="dwCodeAllChecked"
                              v-model="searchConditions.dwCodeAllList"
                            />
                            <i></i>
                            <div class="label">전체</div>
                          </label>
                        </li>
                        <li
                          v-for="(dwCode, idx) in searchOptions.dwCodeOptions"
                          :key="idx"
                        >
                          <label>
                            <input
                              type="checkbox"
                              v-model="searchConditions.dwCode"
                              :value="dwCode.comCode"
                              @change="dwCodeChecked"
                            />
                            <i></i>
                            <div class="label">{{ dwCode.comName }}</div>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.grpKind }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchConditions.grpKind"
                        :dataSource="searchOptions.grpKindOptions"
                        :fields="commonCodeFields"
                        :allowFiltering="false"
                        cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field temporaryGroup">
                  <div class="title">
                    {{ labels.tempGrpCnt }}
                  </div>
                  <ul class="content">
                    <li class="item input">
                      <input-number
                        v-model="searchConditions.tempGrpCnt"
                        :propMaxLength="4"
                      />
                    </li>
                    <li class="item text">팀 이상</li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title"></div>
                  <ul class="content">
                    <li class="item">
                      <ul class="check">
                        <li>
                          <label>
                            <input
                              type="checkbox"
                              v-model="searchConditions.halfHoleFlag"
                              value="true"
                            />
                            <i></i>
                            <div class="label">
                              {{ labels.halfHoleFlag }}
                            </div>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  button-div="GET"
                  :ignore="isPopupOpened"
                  :is-shortcut-button="true"
                  v-on:click.native="onViewButtonClicked"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  button-div="DELETE"
                  :is-icon-custom="true"
                  @click.native="onInitButtonClicked">
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onSearchDetailPopupClose">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <group-popup
      v-if="isGroupPopupSearchDetailOpen"
      ref="groupPopupSearchDetail"
      @popupClosed="onGroupPopupSearchDetailClosed"
    ></group-popup>
    <group-sales-product-detail-list-popup
      v-if="isGroupSalesProductDetailListPopup"
      ref="groupSalesProductDetailListPopup"
      @popupClosed="onGroupSalesProductDetailListPopupClosed"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.groupName .content .item.input {width: 180px;}
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import {
  DATE_FORMAT_YYYY_MM_DD,
  getDayColorValue,
} from '@/utils/date';
import {
  commonCodesGetColorValue,
  commonCodesGetCommonCode,
  commonCodesGetComName,
} from '@/utils/commonCodes';
import { getStatusByGroupInfo } from "@/api/salesManagement";
import { formPreventEnterEvent } from "@/utils/formUtil";
import { numberWithCommas } from "@/utils/number";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import {
  Aggregate,
  Filter,
  Resize,
  Group,
  ExcelExport,
  PdfExport,
  Page,
  ForeignKey,
} from "@syncfusion/ej2-vue-grids";
import commonMixin from "@/views/layout/mixin/commonMixin";
import groupPopup from "@/views/golf-reservation/popup/GroupPopup";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import GolfERPService from "@/service/GolfERPService";
import moment from "moment";
import { mapGetters } from "vuex";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import InputNumber from "@/components/common/InputNumber";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ReportView from "@/components/common/report/ReportView";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import groupSalesProductDetailListPopup from "@/views/store-management/popup/GroupSalesProductDetailListPopup";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "GroupSalesStatus",
  components: {
    InputText,
    InputNumber,
    groupPopup,
    InputDateRange,
    ejsGridWrapper,
    ReportView,
    groupSalesProductDetailListPopup,
    ErpButton
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  computed: {
    ...mapGetters(["username"]),
    isPopupOpened() {
      return (
        this.isGroupPopupSearchDetailOpen ||
        this.isGroupSalesProductDetailListPopup
      );
    },
    datetimeString() {
      return moment(this.now).format(`YYYY-MM-DD HH:mm:ss`);
    },
    teamSalesGridColumnAggregates() {
      return [
        {
          columns: this.subTotSumFlag
            ? []
            : [
                {
                  field: "bsnDate",
                  aggregationType: "GroupCaption",
                  customAggregate: (aggregates) => {
                    const sampleItem = aggregates.items[0];
                    return sampleItem.grpInfo;
                  },
                },
                {
                  field: "teamCnt",
                  aggregationType: "GroupSum",
                },
                {
                  field: "aditTeam",
                  aggregationType: "GroupSum",
                },
                {
                  field: "memberPer",
                  aggregationType: "GroupSum",
                },
                {
                  field: "nomemberPer",
                  aggregationType: "GroupSum",
                },
                {
                  field: "totPer",
                  aggregationType: "GroupSum",
                },
                {
                  field: "totSalesAmt",
                  aggregationType: "GroupSum",
                },
                {
                  field: "totSalesUnitPrice",
                  aggregationType: "GroupSum",
                },
                {
                  field: "feeAmt",
                  aggregationType: "GroupSum",
                },
                {
                  field: "cartAmt",
                  aggregationType: "GroupSum",
                },
                {
                  field: "feeCartTotAmt",
                  aggregationType: "GroupSum",
                },
                {
                  field: "feeCartTotUnitPrice",
                  aggregationType: "GroupSum",
                },
                {
                  field: "rentAmt",
                  aggregationType: "GroupSum",
                },
                {
                  field: "rentUnitPrice",
                  aggregationType: "GroupSum",
                },
                {
                  field: "grillAmt",
                  aggregationType: "GroupSum",
                },
                {
                  field: "teeAmt",
                  aggregationType: "GroupSum",
                },
                {
                  field: "grillteeTotAmt",
                  aggregationType: "GroupSum",
                },
                {
                  field: "grillteeTotUnitPrice",
                  aggregationType: "GroupSum",
                },
                {
                  field: "shopAmt",
                  aggregationType: "GroupSum",
                },
                {
                  field: "shopUnitPrice",
                  aggregationType: "GroupSum",
                },
                {
                  field: "etcAmt",
                  aggregationType: "GroupSum",
                },
                {
                  field: "etcUnitPrice",
                  aggregationType: "GroupSum",
                },
              ],
        },
        {
          columns: [
            {
              field: "bsnDate",
              aggregationType: "TotalCaption",
              customAggregate: "합계",
            },
            {
              field: "teamCnt",
              aggregationType: "TotalSum",
            },
            {
              field: "aditTeam",
              aggregationType: "TotalSum",
            },
            {
              field: "memberPer",
              aggregationType: "TotalSum",
            },
            {
              field: "nomemberPer",
              aggregationType: "TotalSum",
            },
            {
              field: "totPer",
              aggregationType: "TotalSum",
            },
            {
              field: "totSalesAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "totSalesUnitPrice",
              aggregationType: "TotalSum",
            },
            {
              field: "cartAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "feeCartTotAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "feeCartTotUnitPrice",
              aggregationType: "TotalSum",
            },
            {
              field: "feeAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "rentAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "rentUnitPrice",
              aggregationType: "TotalSum",
            },
            {
              field: "grillAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "teeAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "grillteeTotAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "grillteeTotUnitPrice",
              aggregationType: "TotalSum",
            },
            {
              field: "shopAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "shopUnitPrice",
              aggregationType: "TotalSum",
            },
            {
              field: "etcAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "etcUnitPrice",
              aggregationType: "TotalSum",
            },
          ],
        },
      ];
    },
  },
  watch: {
    $route() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
    deep: true,
    subTotSumFlag() {
      console.log(this.teamSalesGridColumnAggregates);
      //this.$refs.teamSalesGrid.refresh();
    },
  },
  async created() {
    const nowMoment = await GolfERPService.fetchNow();
    this.now = nowMoment.toDate();
    const from = nowMoment.format(DATE_FORMAT_YYYY_MM_DD);
    const to = from;

    this.inputDateRangeValue.from = from;
    this.inputDateRangeValue.to = to;
    this.searchConditions.bsnStartDayOfTheWeek = commonCodesGetComName(
      "DW_CODE",
      String(new Date().getDay() + 1)
    );
    this.searchConditions.bsnEndDayOfTheWeek = commonCodesGetComName(
      "DW_CODE",
      String(new Date().getDay() + 1)
    );

    //영업구분 CLOSE 제외
    this.searchOptions.bsnCodeOptions = this.searchOptions.bsnCodeOptions.filter(
      (element) => element.comCode !== "CLOSE"
    );
    this.searchOptions.bsnCodeOptions.unshift({ comCode: "", comName: "전체" });
    this.searchOptions.grpKindOptions.unshift({ comCode: "", comName: "전체" });

    this.getStatusByGroupInfo();
  },
  data() {
    return {
      count: 0,
      groupSalesInfoListLength: 0,
      now: null,
      grid: [Aggregate, Filter, Resize, Group, ExcelExport, PdfExport, Page, ForeignKey],
      labels: {
        bsnDate: "영업일자",
        grpName: "단체명",
        amtFlag: "매출구분",
        bsnCode: "영업구분",
        dwCode: "요일",
        grpKind: "단체종류",
        tempGrpCnt: "임시단체",
        halfHoleFlag: "9홀 이하 0.5팀 적용",
      },
      groupSalesPageSettings: { pageSize: 50 },
      teamSalesGridColumn: [
        { field: "_rid", visible: false, isPrimaryKey: true },
        {
          field: "grpInfo",
          headerText: "구분",
          visible: false
        },
        {
          field: "grpKind",
          headerText: "단체구분",
          width: "90",
          textAlign: "center",
          type: "string",
          isCommonCodeField: true,
          groupCode: "GRP_KIND"
        },
        {
          field: "grpNo",
          headerText: "단체번호",
          width: "90",
          type: "string"
        },
        {
          field: "grpName",
          headerText: "단체명",
          width: "90",
          type: "string"
        },
        {
          headerText: "라운드 정보",
          columns: [
            {
              headerText: "영업일자",
              field: "bsnDate",
              width: "120",
              textAlign: "center",
              type: "string",
            },
            {
              headerText: "요일",
              field: "dwCode",
              width: "90",
              textAlign: "center",
              type: "string",
            },
            {
              headerText: "구분",
              field: "bsnCode",
              width: "90",
              textAlign: "center",
              type: "string",
            },
            {
              headerText: "시간",
              field: "startTime",
              width: "90",
              textAlign: "center",
              type: "string",
            },
          ],
          allowEditing: false,
          textAlign: "Center",
        },
        {
          headerText: "팀 수",
          columns: [
            // 9홀이하 0.5팀 적용 클릭하지 않음
            {
              headerText: "팀수",
              field: "teamCnt",
              width: "90",
              textAlign: "right",
              type: "number",
              format: "N",
            },
            {
              headerText: "(추가)",
              field: "aditTeam",
              width: "90",
              textAlign: "right",
              type: "number",
              format: "N",
            },
          ],
          allowEditing: false,
          textAlign: "Center",
        },
        {
          headerText: "회원 수",
          columns: [
            // 9홀이하 0.5팀 적용 클릭하지 않음
            {
              headerText: "회원",
              field: "memberPer",
              width: "90",
              textAlign: "right",
              type: "number",
              format: "N",
            },
            {
              headerText: "비회원",
              field: "nomemberPer",
              width: "90",
              textAlign: "right",
              type: "number",
              format: "N",
            },
            {
              headerText: "합계",
              field: "totPer",
              width: "90",
              textAlign: "right",
              type: "number",
              format: "N",
            },
          ],
          allowEditing: false,
          textAlign: "Center",
        },
        {
          headerText: "매출 합계",
          columns: [
            // 총매출 클릭
            {
              headerText: "매출금액",
              field: "totSalesAmt",
              width: "100",
              textAlign: "right",
              type: "number",
              format: "N",
            },
            {
              headerText: "객단가",
              field: "totSalesUnitPrice",
              width: "100",
              textAlign: "right",
              type: "number",
              format: "N",
            },
          ],
          allowEditing: false,
          textAlign: "Center",
        },
        {
          headerText: "입장료",
          columns: [
            // 총매출 클릭
            {
              headerText: "입장료",
              field: "feeAmt",
              width: "100",
              textAlign: "right",
              type: "number",
              format: "N",
            },
            {
              headerText: "카트대여",
              field: "cartAmt",
              width: "100",
              textAlign: "right",
              type: "number",
              format: "N",
            },
            {
              headerText: "합계",
              field: "feeCartTotAmt",
              width: "100",
              textAlign: "right",
              type: "number",
              format: "N",
            },
            {
              headerText: "객단가",
              field: "feeCartTotUnitPrice",
              width: "100",
              textAlign: "right",
              type: "number",
              format: "N",
            },
          ],
          allowEditing: false,
          textAlign: "Center",
        },
        {
          headerText: "일반대여",
          columns: [
            // 총매출 클릭
            {
              headerText: "매출금액",
              field: "rentAmt",
              width: "100",
              textAlign: "right",
              type: "number",
              format: "N",
            },
            {
              headerText: "객단가",
              field: "rentUnitPrice",
              width: "100",
              textAlign: "right",
              type: "number",
              format: "N",
            },
          ],
          allowEditing: false,
          textAlign: "Center",
        },
        {
          headerText: "식음",
          columns: [
            // 총매출 클릭
            {
              headerText: "대식당",
              field: "grillAmt",
              width: "100",
              textAlign: "right",
              type: "number",
              format: "N",
            },
            {
              headerText: "그늘집",
              field: "teeAmt",
              width: "100",
              textAlign: "right",
              type: "number",
              format: "N",
            },
            {
              headerText: "합계",
              field: "grillteeTotAmt",
              width: "100",
              textAlign: "right",
              type: "number",
              format: "N",
            },
            {
              headerText: "객단가",
              field: "grillteeTotUnitPrice",
              width: "100",
              textAlign: "right",
              type: "number",
              format: "N",
            },
          ],
          allowEditing: false,
          textAlign: "Center",
        },
          // 올림픽 프로샵 없음
        // {
        //   headerText: "프로샵",
        //   columns: [
        //     // 총매출 클릭
        //     {
        //       headerText: "매출금액",
        //       field: "shopAmt",
        //       width: "100",
        //       textAlign: "right",
        //       type: "number",
        //       format: "N",
        //     },
        //     {
        //       headerText: "객단가",
        //       field: "shopUnitPrice",
        //       width: "100",
        //       textAlign: "right",
        //       type: "number",
        //       format: "N",
        //     },
        //   ],
        //   allowEditing: false,
        //   textAlign: "Center",
        // },
        {
          headerText: "기타매출",
          columns: [
            // 총매출 클릭
            {
              headerText: "매출금액",
              field: "etcAmt",
              width: "100",
              textAlign: "right",
              type: "number",
              format: "N",
            },
            {
              headerText: "객단가",
              field: "etcUnitPrice",
              width: "100",
              textAlign: "right",
              type: "number",
              format: "N",
            },
          ],
          allowEditing: false,
          textAlign: "Center",
        },
      ],
      inputDateRangeValue: { from: null, to: null },
      commonCodeFields: { text: "comName", value: "comCode" },
      groupSalesInfoList: [],
      tabSelectIndex: 0,
      subTotSumFlag: true, // 소계 안보이기 버튼
      searchOptions: {
        bsnCodeOptions: commonCodesGetCommonCode("BSN_CODE", true),
        dwCodeOptions: commonCodesGetCommonCode("DW_CODE", true),
        grpKindOptions: commonCodesGetCommonCode("GRP_KIND", true),
      },
      isSearchDetailPopupOpen: false,
      isGroupPopupSearchDetailOpen: false,
      isGroupSalesProductDetailListPopup: false,
      searchConditions: {
        amtFlag: "true", // 총매출 순매출 버튼
        bsnCode: "", // 영업구분
        bsnStartDayOfTheWeek: null, // 영업일자 시작 요일
        bsnEndDayOfTheWeek: null, // 영업일자 종료 요일
        grpName: null, // 단체명
        dwCodeAllList: true, // 요일 전체
        dwCode: commonCodesGetCommonCode("DW_CODE", true).map(
          (item) => item.comCode
        ), //요일 개별
        grpKind: "", // 단체종류
        grpNo: "", // 단체번호
        tempGrpCnt: 3, // 임시단체팀수 default값 3
        halfHoleFlag: true, // 9홀 이하 0.5팀 적용 여부
      },
      roundColumns: [
        {
          headerText: "영업일자",
          field: "bsnDate",
          width: "180",
          textAlign: "right",
          type: "string",
        },
        {
          headerText: "요일",
          field: "dwCode",
          width: "70",
          textAlign: "right",
          isCommonCodeField: true,
          groupCode: "DW_CODE",
          type: "string",
        },
        {
          headerText: "구분",
          field: "bsnCode",
          width: "90",
          textAlign: "right",
          isCommonCodeField: true,
          groupCode: "BSN_CODE",
          type: "string",
        },
        {
          headerText: "시간",
          field: "startTime",
          width: "100",
          textAlign: "right",
          type: "string",
        },
      ],
      teamCountColumns: [
        // 9홀이하 0.5팀 적용 클릭하지 않음
        {
          headerText: "팀수",
          field: "teamCnt",
          width: "70",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "(추가)",
          field: "aditTeam",
          width: "80",
          textAlign: "right",
          type: "number",
          format: "N",
        },
      ],
      teamCountHoleColumns: [
        // 9홀이하 0.5팀 적용 클릭 todo
        {
          headerText: "팀수",
          field: "teamCntHole",
          width: "70",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "(추가)",
          field: "aditTeamHole",
          width: "80",
          textAlign: "right",
          type: "number",
          format: "N",
        },
      ],
      memberCountColumns: [
        // 9홀이하 0.5팀 적용 클릭하지 않음
        {
          headerText: "회원",
          field: "memberPer",
          width: "70",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "비회원",
          field: "nomemberPer",
          width: "90",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "합계",
          field: "totPer",
          width: "70",
          textAlign: "right",
        },
      ],
      memberCountHoleColumns: [
        // 9홀이하 0.5팀 적용 클릭하지 않음 todo
        {
          headerText: "회원",
          field: "memberPerHole",
          width: "70",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "비회원",
          field: "nomemberPerHole",
          width: "90",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "합계",
          field: "totPerHole",
          width: "70",
          textAlign: "right",
          type: "number",
          format: "N",
        },
      ],
      totSalesSumColumns: [
        // 총매출 클릭
        {
          headerText: "매출금액",
          field: "totSalesAmt",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "객단가",
          field: "totSalesUnitPrice",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
      ],
      netSalesSumColumns: [
        // 순매출 클릭
        {
          headerText: "매출금액",
          field: "netSalesAmt",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "객단가",
          field: "netSalesUnitPrice",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
      ],
      feeAmtColumns: [
        // 총매출 클릭
        {
          headerText: "입장료",
          field: "feeAmt",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "카트대여",
          field: "cartAmt",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "합계",
          field: "feeCartTotAmt",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "객단가",
          field: "feeCartTotUnitPrice",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
      ],
      feeNetAmtColumns: [
        // 순매출 클릭
        {
          headerText: "입장료",
          field: "feeNetAmt",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "카트대여",
          field: "cartNetAmt",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "합계",
          field: "feeCartTotNetAmt",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "객단가",
          field: "feeCartTotNetUnitPrice",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
      ],
      rentAmtColumns: [
        // 총매출 클릭
        {
          headerText: "매출금액",
          field: "rentAmt",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "객단가",
          field: "rentUnitPrice",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
      ],
      rentNetAmtColumns: [
        // 순매출 클릭
        {
          headerText: "매출금액",
          field: "rentNetAmt",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "객단가",
          field: "rentNetUnitPrice",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
      ],
      foodAmtColumns: [
        // 총매출 클릭
        {
          headerText: "대식당",
          field: "grillAmt",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "그늘집",
          field: "teeAmt",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "합계",
          field: "grillteeTotAmt",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "객단가",
          field: "grillteeTotUnitPrice",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
      ],
      foodNetAmtColumns: [
        // 순매출 클릭
        {
          headerText: "대식당",
          field: "grillNetAmt",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "그늘집",
          field: "teeNetAmt",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "합계",
          field: "grillteeTotNetAmt",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "객단가",
          field: "grillteeTotNetUnitPrice",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
      ],
      shopAmtColumns: [
        // 총매출 클릭
        {
          headerText: "매출금액",
          field: "shopAmt",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "객단가",
          field: "shopUnitPrice",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
      ],
      shopNetAmtColumns: [
        // 순매출 클릭
        {
          headerText: "매출금액",
          field: "shopNetAmt",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "객단가",
          field: "shopNetUnitPrice",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
      ],
      etcAmtColumns: [
        // 총매출 클릭
        {
          headerText: "매출금액",
          field: "etcAmt",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "객단가",
          field: "etcUnitPrice",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
      ],
      etcNetAmtColumns: [
        // 순매출 클릭
        {
          headerText: "매출금액",
          field: "etcNetAmt",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "객단가",
          field: "etcNetUnitPrice",
          width: "100",
          textAlign: "right",
          type: "number",
          format: "N",
        },
      ],
      groupSettings: { showDropArea: false, columns: ["grpInfo"] },

      visiblePrintPaper: false,
    };
  },
  methods: {
    numberWithCommas,
    preventEnterEvent: formPreventEnterEvent,
    gridHeaderCellInfo(args) {
      const {
        cell: {
          column: { field },
        },
        node,
      } = args;
      if (
        field === "feeAmt" ||
        field === "cartAmt" ||
        field === "rentAmt" ||
        field === "grillAmt" ||
        field === "teeAmt" ||
        field === "shopAmt" ||
        field === "etcAmt"
      ) {
        node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    queryCellInfo(args) {
      const {
        cell,
        column: { field, type },
        data,
      } = args;
      if (field === "dwCode") {
        cell.style.color = getDayColorValue(data.pureDwCode, data.pureBsnCode);
      }
      if (field === "bsnCode") {
        cell.style.color = commonCodesGetColorValue(
          "BSN_CODE",
          data.pureBsnCode
        );
      }
      if (
        field === "totSalesAmt" ||
        field === "totSalesUnitPrice" ||
        field === "netSalesAmt" ||
        field === "netSalesUnitPrice"
      ) {
        cell.style.fontWeight = "bold"; // 매출 합계 폰트굵기 css지정
      }
      if (
        field === "totSalesAmt" ||
        field === "totSalesUnitPrice" ||
        field === "netSalesAmt" ||
        field === "netSalesUnitPrice"
      ) {
        cell.style.backgroundColor = "#FFCC99"; // 매출 합계 폰트색상 css지정
      }
      if (
        field === "feeAmt" ||
        field === "cartAmt" ||
        field === "rentAmt" ||
        field === "grillAmt" ||
        field === "teeAmt" ||
        field === "shopAmt" ||
        field === "etcAmt"
      ) {
        args.cell.classList.add(this.$t("className.grid.clickArea"));
      }
      if (type?.toLowerCase() === "number" && !data[field]) {
        cell.innerText = "-";
      }
    },
    isValidBsnDate() {
      if (!this.inputDateRangeValue.from) {
        this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["영업일자"])
        );
        return false;
      }
      if (!this.inputDateRangeValue.to) {
        this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["종료일자"])
        );
        return false;
      }

      return true;
    },
    isValidDwcode() {
      if (
        !this.searchConditions.dwCodeAllList &&
        this.searchConditions.dwCode.length < 1
      ) {
        this.errorToast("요일을 하나 이상 선택해 주세요");
        return false;
      }
      return true;
    },
    onViewButtonClicked() {
      if (!this.isValidBsnDate()) {
        return;
      }
      if (!this.isValidDwcode()) {
        return;
      }
      this.getStatusByGroupInfo();
    },
    onInitButtonClicked() {
      this.searchConditions.bsnCode = "";
      this.searchConditions.dwCodeAllList = true;
      this.searchConditions.dwCode = commonCodesGetCommonCode(
        "DW_CODE",
        true
      ).map((item) => item.comCode);
      this.searchConditions.grpKind = "";
      this.searchConditions.grpName = null;
      this.searchConditions.taxFlag = null;
      this.searchConditions.tempGrpCnt = 3;
      this.searchConditions.halfHoleFlag = true;
    },
    onSearchDetailPopupClose() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
    onViewTeamSales() {
      if (!this.isValidBsnDate()) {
        return;
      }
      this.getStatusByGroupInfo();
    },
    onGrpNameClear() {
      this.searchConditions.grpName = null;
    },
    onSearchPopupClicked() {
      this.openCheckinVisitsFindPopup();
    },
    onSearchDetailPopupOpen() {
      this.isSearchDetailPopupOpen = true;
      this.$refs.searchDetailPopup.show();
    },
    onGroupPopupSearchDetailOpen() {
      this.isGroupPopupSearchDetailOpen = true;
      this.$nextTick(() => {
        this.$refs.groupPopupSearchDetail.openPopup({
          grpName: this.searchConditions.grpName,
        });
      });
    },
    onPrintPaperClose() {
      this.visiblePrintPaper = false;
    },
    onGroupPopupSearchDetailClosed(event) {
      this.isGroupPopupSearchDetailOpen = false;
      if (event.selectedGroup) {
        this.searchConditions.grpNo = event.selectedGroup.grpNo;
        this.searchConditions.grpName = event.selectedGroup.grpName;
      }
    },
    onGroupSalesProductDetailListPopupClosed() {
      this.isGroupSalesProductDetailListPopup = false;
    },
    dwCodeValueAccess(field, data) {
      let dwCode = data[field];
      if (dwCode != null) {
        if (dwCode === "2") {
          dwCode = "월";
        } else if (dwCode === "3") {
          dwCode = "화";
        } else if (dwCode === "4") {
          dwCode = "수";
        } else if (dwCode === "5") {
          dwCode = "목";
        } else if (dwCode === "6") {
          dwCode = "금";
        } else if (dwCode === "7") {
          dwCode = "토";
        } else if (dwCode === "1") {
          dwCode = "일";
        }
      }
      return dwCode;
    },
    bsnCodeValueAccess(field, data) {
      let bsnCode = data[field];
      if (bsnCode != null) {
        bsnCode = commonCodesGetComName("BSN_CODE", bsnCode);
      }
      return bsnCode;
    },
    dwCodeAllChecked(args) {
      if (args.target.checked) {
        this.searchConditions.dwCode = commonCodesGetCommonCode(
          "DW_CODE",
          true
        ).map((item) => item.comCode);
      } else {
        this.searchConditions.dwCode = [];
      }
    },
    dwCodeChecked() {
      if (
        this.searchConditions.dwCode.length ===
        this.searchOptions.dwCodeOptions.length
      ) {
        this.searchConditions.dwCodeAllList = true;
      } else {
        this.searchConditions.dwCodeAllList = false;
      }
    },
    setGroupInfo(salesReportByGroupList) {
      //그룹별 데이터 출력을 위해 groupInfo 생성
      this.groupSalesInfoList = salesReportByGroupList.map((item, idx) => {
        // TODO : 추후 소스코드 정리 필요
        item.pureDwCode = item.dwCode;
        item.pureBsnCode = item.bsnCode;
        item.dwCode = commonCodesGetComName("DW_CODE", item.dwCode);
        item.bsnCode = commonCodesGetComName("BSN_CODE", item.bsnCode);
        item.groupInfo = `${item.bsnCode} (${item.grpNo}) ${item.grpName}`;
        item._rid = idx;

        item.totSalesAmt = this.searchConditions.amtFlag === "false" ? item.netSalesAmt : item.totSalesAmt;
        item.totSalesUnitPrice = this.searchConditions.amtFlag === "false" ? item.netSalesUnitPrice : item.totSalesUnitPrice;
        item.feeAmt = this.searchConditions.amtFlag === "false" ? item.feeNetAmt : item.feeAmt;
        item.cartAmt = this.searchConditions.amtFlag === "false" ? item.cartNetAmt : item.cartAmt;
        item.feeCartTotAmt = this.searchConditions.amtFlag === "false" ? item.feeCartTotNetAmt : item.feeCartTotAmt;
        item.feeCartTotUnitPrice = this.searchConditions.amtFlag === "false" ? item.feeCartTotNetUnitPrice : item.feeCartTotUnitPrice;
        item.rentAmt = this.searchConditions.amtFlag === "false" ? item.rentNetAmt : item.rentAmt;
        item.rentUnitPrice = this.searchConditions.amtFlag === "false" ? item.rentNetUnitPrice : item.rentUnitPrice;
        item.grillAmt = this.searchConditions.amtFlag === "false" ? item.grillNetAmt : item.grillAmt;
        item.teeAmt = this.searchConditions.amtFlag === "false" ? item.teeNetAmt : item.teeAmt;
        item.grillteeTotAmt = this.searchConditions.amtFlag === "false" ? item.grillteeTotNetAmt : item.grillteeTotAmt;
        item.grillteeTotUnitPrice = this.searchConditions.amtFlag === "false" ? item.grillteeTotNetUnitPrice : item.grillteeTotUnitPrice;
        item.shopAmt = this.searchConditions.amtFlag === "false" ? item.shopNetAmt : item.shopAmt;
        item.shopUnitPrice = this.searchConditions.amtFlag === "false" ? item.shopNetUnitPrice : item.shopUnitPrice;
        item.etcAmt = this.searchConditions.amtFlag === "false" ? item.etcNetAmt : item.etcAmt;
        item.etcUnitPrice = this.searchConditions.amtFlag === "false" ? item.etcNetUnitPrice : item.etcUnitPrice;

        return item;
      });
    },
    async getStatusByGroupInfo() {
      const bsnCode = this.searchConditions.bsnCode || undefined;
      const grpKind = this.searchConditions.grpKind || undefined;
      const grpName = this.searchConditions.grpName || undefined;
      const grpNo = this.searchConditions.grpNo || undefined;
      const tempGrpCnt = this.searchConditions.tempGrpCnt || undefined;

      await getStatusByGroupInfo(
        bsnCode,
        this.inputDateRangeValue.to,
        this.inputDateRangeValue.from,
        this.searchConditions.dwCode,
        grpKind,
        grpName,
        grpNo,
        this.searchConditions.halfHoleFlag,
        tempGrpCnt
      )
        .then((response) => {
          this.setGroupInfo(response.value.salesReportByGroupList);

          this.groupSalesInfoListLength =
            response.value.salesReportByGroupList.length;
        })
        .catch((error) => {
          console.error("getStatusByGroupInfo.err ===>", error);
        });

      if (!(this.groupSalesInfoList.length > 0)) {
        const fromMomentObj = moment(this.inputDateRangeValue.from);
        const toMomentObj = moment(this.inputDateRangeValue.to);

        const dateDiff = toMomentObj.diff(fromMomentObj, "days");

        let request = [];

        for (let i = 0; i <= dateDiff; i++) {
          let inParams = [];

          const bsnDate = moment(this.inputDateRangeValue.from)
            .add(i, "d")
            .format("YYYYMMDD");

          inParams.push(bsnDate);

          request.push({ name: "SP_CLOSE_SALES_TEAM", inParams: inParams });
        }

        await GolfErpAPI.putStoredProcedureExecute({
          storedProcedureInfos: request,
        });

        await getStatusByGroupInfo(
          bsnCode,
          this.inputDateRangeValue.to,
          this.inputDateRangeValue.from,
          this.searchConditions.dwCode,
          grpKind,
          grpName,
          grpNo,
          this.searchConditions.halfHoleFlag,
          tempGrpCnt
        )
          .then((response) => {
            this.setGroupInfo(response.value.salesReportByGroupList);

            this.groupSalesInfoListLength =
              response.value.salesReportByGroupList.length;
          })
          .catch((error) => {
            console.error("getStatusByGroupInfo.err ===>", error);
          });
      }
    },
    teamSalesGridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.teamSalesGrid?.getGridBatchCount() || 0
      );
    },
    excel() {
      this.$refs.teamSalesGrid.excelExport();
    },
    print: function () {
      const result = this.$refs.teamSalesGrid.getGridBatchData();
      console.log('result.===>', result);
      if (result.length < 1) {
        return this.errorToast(this.$t("report.popupMessage.noData"));
      }

      const searchOptionsList = [
        {
          key: this.labels.bsnDate,
          value:
            this.inputDateRangeValue.from + " ~ " + this.inputDateRangeValue.to,
        },
        {
          key: this.labels.grpName,
          value: this.searchConditions.grpName,
        },
        {
          key: this.labels.amtFlag,
          value: this.searchConditions.amtFlag === "true" ? "총매출" : "순매출",
        },
        {
          key: this.labels.bsnCode,
          value: commonCodesGetComName(
            "BSN_CODE",
            this.searchConditions.bsnCode
          ),
        },
        {
          key: this.labels.dwCode,
          value:
            this.searchConditions.dwCode.length < 7
              ? this.searchConditions.dwCode
                  .map((item) => commonCodesGetComName("DW_CODE", item))
                  .join(", ")
              : null,
        },
        {
          key: this.labels.grpKind,
          value: commonCodesGetComName(
            "GRP_KIND",
            this.searchConditions.grpKind
          ),
        },
        {
          key: this.labels.tempGrpCnt,
          value: `${this.searchConditions.tempGrpCnt}팀 이상`,
        },
        {
          key: this.labels.halfHoleFlag,
          value: this.searchConditions.halfHoleFlag ? "예" : null,
        },
      ];

      const searchOptions = searchOptionsList
        .filter((item) => !!item.value)
        .map((item) => item.key + ": " + item.value)
        .join(", ");

      const reportData = {
        reportJson: {
          jsonData: result,
          username: this.username,
          uniqueCode: this.$options.name,
          searchOptions,
          amtFlag: this.searchConditions.amtFlag,
          subTotSumFlag: this.subTotSumFlag ? "true" : "false",
        },
      };

      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    gridRecordClick(args) {
      const {
        rowData,
        column: { field }
      } = args;

      if (
        field === "feeAmt" ||
        field === "cartAmt" ||
        field === "rentAmt" ||
        field === "grillAmt" ||
        field === "teeAmt" ||
        field === "shopAmt" ||
        field === "etcAmt"
      ) {
        let storeCode = null;
        switch (field) {
          case "feeAmt":
            storeCode = "FRONT";
            break;
          case "cartAmt":
            storeCode = "CART";
            break;
          case "rentAmt":
            storeCode = "RENT";
            break;
          case "grillAmt":
            storeCode = "GRILL";
            break;
          case "teeAmt":
            storeCode = "TEE";
            break;
          case "shopAmt":
            storeCode = "SHOP";
            break;
          case "etcAmt":
            storeCode = "ETC";
            break;
          default:
            storeCode = null;
        }

        this.isGroupSalesProductDetailListPopup = true;
        this.$nextTick(() => {
          this.$refs.groupSalesProductDetailListPopup.showPopup({
            bsnDate: rowData.bsnDate,
            storeCode: storeCode,
            grpNo: rowData.grpNo,
            grpName: rowData.grpName
          });
        });
      }
    }
  },
};
</script>

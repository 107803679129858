import AbstractVanModule from "@/van/AbstractVanModule";
import VanProcessError from "@/error/van/VanProcessError";
import moment from "moment";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {requestToWebSocket} from "@/van/smartro/RequestSmartro";
import {approveApi} from "@/van/bestpay/RequestBestPay";

const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

//정상 코드
const SUCCESS_CODE = "00";

// 서비스유형
const SERVICE_CODE = {
  APPROVAL: '0101',     // 승인
  CANCEL: '2101',       // 취소
  VIEWCHECK: '0401',    // 수표
};
//거래구분
const PROCESS_CODE = {
  CARD_PROCESS_CODE: '01', // 카드
  CASH_PROCESS_CODE: '02', // 현금
  VIEWCHECK_PROCESS_CODE: '00', // 자기앞수표
};
//서명여부
const SIGNATURE_CODE = {
  CARD_SIGNATURE_CODE: '3', //카드
  CASH_SIGNATURE_CODE: '0', //현금
};

// 현금영수증 발급 구분
const PROOF_KIND = {
  FIT: '03', //소득공제
  CPR: '13', //사업자 지출
  VOL: '23', //자진발급
};

export default class SmartroVanModule extends AbstractVanModule {
  responseReady
  failLogPrefix

  constructor(port) {
    super(port);
    this.responseReady = true;
  }

  _getRequestUrl() {
    return `ws://${this.host}:${this.port}/`;
  }

  /* *
   * 카드 결제
   * */
  async cardApprove(payInfo) {
    this.failLogPrefix = "[결제요청실패]";
    if (!this.responseReady) {
      throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
    }
    this.responseReady = false;

    try {
      return await this._cardApprovalRequest(payInfo);
    } catch (e) {
      if (e instanceof VanProcessError) {
        throw e;
      } else if (e instanceof Error) {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` ,e.message);
      } else {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` , e);
      }
    } finally {
      this.responseReady = true;
    }
  }

  async cardCancel(payInfo) {
    this.failLogPrefix = "[결제취소요청실패]";
    if (!this.responseReady) {
      throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
    }
    this.responseReady = false;

    try {
      return await this._cardCancelRequest(payInfo);
    } catch (e) {
      if (e instanceof VanProcessError) {
        throw e;
      } else if (e instanceof Error) {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e.message);
      } else {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e);
      }
    } finally {
      this.responseReady = true;
    }
  }

  async _cardApprovalRequest(payInfo) {
    const requestBody = this._createCardApprovalRequestBody(payInfo);
    await this._updateTransactionBeforeRequest(payInfo.id, JSON.stringify(requestBody));
    let response;

    response = await requestToWebSocket(this._getRequestUrl(), requestBody);

    if(response.data["0073"] === SUCCESS_CODE){
      return this._createCardApprovalResult(response.data, payInfo);
    } else {
      throw new VanProcessError(
          `[${this.failLogPrefix}] ${response?.data["0101"].trim()}`,
          "FAIL",
          JSON.stringify(response, null, 2),
          JSON.stringify(response, null, 2));
    }
  }

  async _cardCancelRequest(payInfo) {
    const requestBody = this._createCardCancelRequestBody(payInfo);
    await this._updateTransactionBeforeRequest(payInfo.id, JSON.stringify(requestBody));
    const response = await requestToWebSocket(this._getRequestUrl(), requestBody);
    if(response.data["0073"] === SUCCESS_CODE){
      return this._createCardApprovalResult(response.data, payInfo);
    } else {
      throw new VanProcessError(`[${this.failLogPrefix}] 결제 오류`,response.data["0101"].trim());
    }
  }

  _createCardApprovalRequestBody(payInfo) {
    return {
      "0062":SERVICE_CODE.APPROVAL,                           //서비스유형
      "0021":PROCESS_CODE.CARD_PROCESS_CODE,                  //거래구분
      "0094":payInfo.divideTerm.toString().padStart(2, "0"),  //할부개월
      "0058":SIGNATURE_CODE.CARD_SIGNATURE_CODE,              //서명여부
      "0064":payInfo.totalAmount.toString(),                  //금액
      "0063":payInfo.vatAmount.toString(),                    //세금
      "0051":payInfo.serviceAmount.toString(),                //봉사료
    };
  }

  _createCardCancelRequestBody(payInfo) {
    return {
      "0062":SERVICE_CODE.CANCEL,                              //서비스유형
      "0021":PROCESS_CODE.CARD_PROCESS_CODE,                  //거래구분
      "0094":payInfo.divideTerm.toString().padStart(2, "0"),  //할부개월
      "0058":SIGNATURE_CODE.CARD_SIGNATURE_CODE,              //서명여부
      "0064":payInfo.totalAmount.toString(),                  //금액
      "0063":payInfo.vatAmount.toString(),                    //세금
      "0051":payInfo.serviceAmount.toString(),                //봉사료
      "0065":payInfo.approvalNo.toString(),                   // 승인번호
      "0071":moment(payInfo.approvalDateTime.toString(), DATE_TIME_FORMAT).format("YYYYMMDD") // 원거래일자
    };
  }

  async _createCardApprovalResult(response, payInfo) {
    const responseToString = JSON.stringify(response, null, 2);
    const purchaseInfo = await this._convertPurchaseCodeToPurchaseInfo(response["0033"].substr(0,4));
    const dateTime = response["0036"]+response["0035"];
    if (!purchaseInfo) {
      return {
        tid: response["0029"].substr(0,10),
        status: this.STATUS.APPROVED,
        approvalDateTime: moment(dateTime, "YYYYMMDDHHmmss").format(DATE_TIME_FORMAT),
        approvalNo: response["0065"] ? response["0065"].trim() : payInfo.approvalNo.trim(),
        totalAmount: Number(payInfo.totalAmount),
        taxAmount: Number(payInfo.taxAmount),
        vatAmount: Number(payInfo.vatAmount),
        notaxAmount: Number(payInfo.notaxAmount),
        serviceAmount: Number(payInfo.serviceAmount),
        cardNo: response["0091"],
        divideTerm: Number(response["0094"]),
        purchaseName: response["0033"],
        issueCompanyName: response["0039"],
        merchantNo: response["0015"],
        saleNo: response["0019"],
        message: `${response.Issue_name}  매입처 코드를 확인바랍니다(전산팀 문의)!`,
        vanResponseString: responseToString,
        vanResponseData: responseToString,
        termId: response["0029"].substr(0,10)
      };
    }
    return {
      tid: response["0029"].substr(0,10),
      status: this.STATUS.APPROVED,
      approvalDateTime: moment(dateTime, "YYYYMMDDHHmmss").format(DATE_TIME_FORMAT),   //승인일시
      approvalNo: response["0065"] ? response["0065"].trim() : payInfo.approvalNo.trim(),         //승인번호
      totalAmount: Number(payInfo.totalAmount),
      taxAmount: Number(payInfo.taxAmount),
      vatAmount: Number(payInfo.vatAmount),
      notaxAmount: Number(payInfo.notaxAmount),
      serviceAmount: Number(payInfo.serviceAmount),
      cardNo: response["0091"],                      //카드번호
      divideTerm: Number(response["0094"]),
      purchaseId: purchaseInfo.purchaseId,
      purchaseName: purchaseInfo.purchaseName,         //매입처
      issueCompanyName: response["0039"],          //발급사명
      merchantNo: response["0015"],             //가맹번호
      saleNo: response["0019"],              //거래번호
      vanResponseString: responseToString,
      vanResponseData: responseToString,
      termId: response["0029"].substr(0,10)
    };
  }

  /* *
   * 현금 결제
   * */
  async cashReceiptApprove(payInfo) {
    this.failLogPrefix = "[결제요청실패]";
    if (!this.responseReady) {
      throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
    }
    this.responseReady = false;

    try {
      return await this._cashReceiptApprovalRequest(payInfo);
    } catch (e) {
      if (e instanceof VanProcessError) {
        throw e;
      } else if (e instanceof Error) {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e.message);
      } else {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e);
      }
    } finally {
      this.responseReady = true;
    }
  }

  async cashReceiptCancel(payInfo) {
    this.failLogPrefix = "[결제취소요청실패]";
    if (!this.responseReady) {
      throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
    }
    this.responseReady = false;

    try {
      return await this._cashReceiptCancelRequest(payInfo);
    } catch (e) {
      if (e instanceof VanProcessError) {
        throw e;
      } else if (e instanceof Error) {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e.message);
      } else {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e);
      }
    } finally {
      this.responseReady = true;
    }
  }

  async _cashReceiptApprovalRequest(payInfo) {
    const requestBody = this._createCashReceiptApprovalRequestBody(payInfo);
    await this._updateTransactionBeforeRequest(payInfo.id, JSON.stringify(requestBody));
    const response = await requestToWebSocket(this._getRequestUrl(), requestBody);
    if(response.data["0073"] === SUCCESS_CODE){
      return this._createCashReceiptApprovalResult(response.data, payInfo);
    } else {
      throw new VanProcessError(
          `[${this.failLogPrefix}] ${response?.data["0101"].trim()}`,
          "FAIL",
          JSON.stringify(response, null, 2),
          JSON.stringify(response, null, 2));
    }
  }

  async _cashReceiptCancelRequest(payInfo) {
    const requestBody = this._createCashReceiptCancelRequestBody(payInfo);
    await this._updateTransactionBeforeRequest(payInfo.id, JSON.stringify(requestBody));
    const response = await requestToWebSocket(this._getRequestUrl(), requestBody);
    if(response.data["0073"] === SUCCESS_CODE){
      return this._createCashReceiptApprovalResult(response.data, payInfo);
    } else {
      throw new VanProcessError(
          `[${this.failLogPrefix}] ${response?.data["0101"].trim()}`,
          "FAIL",
          JSON.stringify(response, null, 2),
          JSON.stringify(response, null, 2));
    }
  }

  _createCashReceiptApprovalRequestBody(payInfo) {
    const proof = this._convertToProof(payInfo.proofNo, payInfo.proofKind);
    return {
      "0062":SERVICE_CODE.APPROVAL,                           //서비스유형
      "0021":PROCESS_CODE.CASH_PROCESS_CODE,                  //거래구분
      "0094":proof.proofKind,  //사용구분
      "0058":SIGNATURE_CODE.CASH_SIGNATURE_CODE,              //서명여부
      "0064":payInfo.totalAmount.toString(),                  //금액
      "0063":payInfo.vatAmount.toString(),                    //세금
      "0051":payInfo.serviceAmount.toString(),                //봉사료
    };
  }

  _createCashReceiptCancelRequestBody(payInfo) {
    const proof = this._convertToProof(payInfo.proofNo, payInfo.proofKind);
    return {
      "0062":SERVICE_CODE.CANCEL,                             //서비스유형
      "0021":PROCESS_CODE.CASH_PROCESS_CODE,                  //거래구분
      "0094":proof.proofKind,                                 //사용구분
      "0058":SIGNATURE_CODE.CASH_SIGNATURE_CODE,              //서명여부
      "0064":payInfo.totalAmount.toString(),                  //금액
      "0063":payInfo.vatAmount.toString(),                    //세금
      "0051":payInfo.serviceAmount.toString(),                //봉사료
      "0065":payInfo.approvalNo.toString(),                   //승인번호
      "0071":moment(payInfo.approvalDateTime.toString(), DATE_TIME_FORMAT).format("YYYYMMDD"), // 원거래일자
      "0090":payInfo.proofCancelCode,                         //취소사유
    };
  }

  async _createCashReceiptApprovalResult(response, payInfo) {
    const responseToString = JSON.stringify(response, null, 2);
    const dateTime = response["0036"]+response["0035"];
    return {
      tid: response["0029"].substr(0,10),
      status: this.STATUS.APPROVED,
      approvalDateTime: moment(dateTime, "YYYYMMDDHHmmss").format(DATE_TIME_FORMAT),
      approvalNo: response["0065"] ? response["0065"].trim() : payInfo.approvalNo,         //승인번호
      totalAmount: Number(payInfo.totalAmount),
      taxAmount: Number(payInfo.taxAmount),
      vatAmount: Number(payInfo.vatAmount),
      notaxAmount: Number(payInfo.notaxAmount),
      serviceAmount: Number(payInfo.serviceAmount),
      proofKind: payInfo.proofKind,
      proofNo: response["0091"],
      saleNo: response["0019"],              //거래번호
      vanResponseString: responseToString,
      vanResponseData: responseToString,
      termId: response["0029"].substr(0,10)
    };
  }

  /* *
     * 수표 조회
     * */
  async viewCheck(payInfo) {
    this.failLogPrefix = "[수표조회 실패]";
    const requestBody = await this._createViewCheckRequestBody(payInfo);
    await this._updateTransactionBeforeRequest(payInfo.id, JSON.stringify(requestBody));
    const response = await requestToWebSocket(this._getRequestUrl(), requestBody);
    if(response.data["0073"] === SUCCESS_CODE){
      return this._createViewCheckApprovalResult(response.data, payInfo);
    } else {
      throw new VanProcessError(
          `[${this.failLogPrefix}] ${response?.data["0101"].trim()}`,
          "FAIL",
          JSON.stringify(response, null, 2),
          JSON.stringify(response, null, 2));
    }
  }

  async _createViewCheckRequestBody(payInfo) {
    return {
      "0062":SERVICE_CODE.VIEWCHECK,                          //서비스유형
      "0501":PROCESS_CODE.VIEWCHECK_PROCESS_CODE,             //수표구분
      "0502":payInfo.checkNo.padEnd(14, " ")+(payInfo.checkAccount ? payInfo.checkAccount.padEnd(6, " ") : "".padEnd(6, " ")),                                 //수표정보
      "0503":payInfo.checkPubDate.padEnd(6, " "),              //수표발생일자
      "0504":payInfo.checkType,                               //권종코드
      "0505":payInfo.totalAmount.toString(),                    //수표금액
    };
  }

  async _createViewCheckApprovalResult(response, payInfo) {
    const responseToString = JSON.stringify(response, null, 2);
    const dateTime = response["0036"]+response["0035"];
    return {
      tid: payInfo.id,
      status: this.STATUS.OK,
      approvalDateTime: moment(dateTime, "YYYYMMDDHHmmss").format(DATE_TIME_FORMAT),
      saleNo: response["0019"],              //거래번호
      vanResponseString: responseToString,
      vanResponseData: responseToString,
      termId: response["0029"]
    };
  }


  /**
   * VAN 사 매입처 코드를 ERP 내 매입처 정보로 변환
   * @param purchaseCode
   * @return {Promise<*>}
   * @private
   */
  async _convertPurchaseCodeToPurchaseInfo(purchaseCode) {
    const placesOfCardPurchase = await GolfErpAPI.getPlacesOfCardPurchase("SMARTRO");
    const cardList = placesOfCardPurchase.map(
        ({ purchaseId, purchaseName, vanMappingList }) => {
          return {
            purchaseId: purchaseId,
            purchaseName: purchaseName,
            vanMapCode: vanMappingList[0].vanMapCode
          };
        });
    return cardList.find(confCard => confCard.vanMapCode === purchaseCode);
  }

  _convertToProof(proofNo, proofKind) {
    switch (proofKind) {
      case "FIT" : // 소비자소득공제
        return { proofNo : proofNo, proofKind: PROOF_KIND.FIT };
      case "CPR" : // 사업자지출증빙
        return { proofNo : proofNo, proofKind: PROOF_KIND.CPR };
      case "VOL" : // 자진발급
        return { proofNo : this.DEFAULT_PROOF_NO, proofKind: PROOF_KIND.VOL };
      default:
        throw new VanProcessError('현금영수증 증빙구분을 확인할 수 없습니다.');
    }
  }

}
<template>
  <div>
    <ejs-dialog
      ref="reservationInfoPopup"
      :header="'예약정보 조회'"
      :allowDragging="true"
      :showCloseIcon="true"
      width="1300"
      height="800"
      :animationSettings="{ effect: 'None' }"
      enableResize="true"
      :isModal="true"
      :close="onReservationInfoPopupClosed"
    >
      <div class="window reservationInformationLookup">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field conditionalSearch">
                    <div class="title">조건검색</div>
                    <ul class="content">
                      <li class="item" style="width: 350px;">
                        <input-text
                          id="searchValueTextBox"
                          v-model="reservationInfoOption.searchValue"
                          placeholder="예약자명, 연락자명, 예약번호, 단체명, 비고, 연락처 4자리"
                          :tabindex="-1"
                          @keydown.enter="onSearchValueTextBoxKeyDownEnter"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">예약일자</div>
                    <ul class="content">
                      <li class="item dateRange">
                        <input-date-range
                          v-model="reservationDateRange"
                          type="lookup-condition"
                          :tabindex="-1"
                          @change="onReservationDateRange"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field businessCategory">
                    <div class="title">영업구분</div>
                    <ul class="content">
                      <li class="item">
                        <ejs-dropdownlist
                          ref="bsnCodeDropdown"
                          v-model="reservationInfoOption.bsnCode"
                          :dataSource="reservationInfoOption.bsnCodeOptions"
                          :allowFiltering="false"
                          :fields="{ text: 'comName', value: 'comCode' }"
                          :tabindex="-1"
                          cssClass="lookup-condition-dropdown"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field division">
                    <div class="title">부구분</div>
                    <ul class="content">
                      <li class="item">
                        <ejs-dropdownlist
                          ref="partDivDropdown"
                          v-model="reservationInfoOption.partDiv"
                          :dataSource="reservationInfoOption.partDivOptions"
                          :allowFiltering="false"
                          :fields="{ text: 'comName', value: 'comCode' }"
                          :tabindex="-1"
                          cssClass="lookup-condition-dropdown"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field division">
                    <div class="title">인원</div>
                    <ul class="content">
                      <li class="item">
                        <ejs-dropdownlist
                          ref="partDivDropdown"
                          v-model="reservationInfoOption.visitCnt"
                          :dataSource="reservationInfoOption.perDivOptions"
                          :allowFiltering="false"
                          :fields="{ text: 'comName', value: 'comCode' }"
                          :tabindex="-1"
                          cssClass="lookup-condition-dropdown"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      button-div="GET"
                      :tabindex="-1"
                      :is-shortcut-button="true"
                      :ignore="isPopupOpened"
                      @click.native="getReservationInfoTable()"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <ejs-grid-wrapper
                      ref="reservationInfoTableGrid"
                      :provides="reservationInfoTableGridOptions.provides"
                      :columns="reservationInfoTableGridOptions.columns"
                      :dataSource="reservationInfoList"
                      :rowHeight="24"
                      :width="'100%'"
                      :height="'100%'"
                      :gridLines="'Both'"
                      :allowResizing="true"
                      :editSettings="
                        reservationInfoTableGridOptions.editSettings
                      "
                      :isSelectedRowRetain="false"
                      :isInPopup="true"
                      :selectionSettings="
                        reservationInfoTableGridOptions.selectionSettings
                      "
                      @queryCellInfo="reservationInfoTableQueryCellInfo"
                      @headerCellInfo="reservationInfoTableHeaderCellInfo"
                      @rowSelected="reservationGridRowSelected"
                      @dataBound="onReservationInfoTableGridDataBound"
                      @onGridDialogDoubleClickedOrEnterKeyed="
                        onReservationInfoTableGridDoubleClick
                      "
                    />
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  button-div="SAVE"
                  is-icon-custom="true"
                  @click.native="confirmReservationInfoPopup()"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeReservationInfoPopup()"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <member-select-popup
      v-if="isMemberSelectPopupOpen"
      ref="memberSelectPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClosed="memberSelectPopupClosed"
      @popupConfirmed="memberSelectPopupConfirmed"
    />
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import numberTemplate from "@/views/common/template/GridNumberTemplate";
import { Resize, ForeignKey } from "@syncfusion/ej2-vue-grids";

import { MultiSelect, CheckBoxSelection } from "@syncfusion/ej2-dropdowns";

import { gridUtilGetTelColumnAccess } from "@/utils/gridUtil";
import {
  commonCodesGetCommonCode,
  commonCodesGetColorValue,
  commonCodeGetComCodeByCurrentBizCode,
} from "@/utils/commonCodes";
import { getFormattedDate } from "@/utils/date";
import commonMixin from "@/views/layout/mixin/commonMixin";
import memberSelectPopup from "@/views/common/MemberSelectPopup";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import moment from "moment";
import { getReservationInfoTable } from "@/api/reservation";
import InputText from "@/components/common/text/InputText";
import {gridUtilGetMemberNoColumnAccess} from "@/utils/gridUtil";
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

MultiSelect.Inject(CheckBoxSelection);

export default {
  name: "reservationInfoPopup",
  mixins: [commonMixin, messagePopupDialogMixin],
  components: {
    InputText,
    memberSelectPopup,
    InputDateRange,
    EjsGridWrapper,
    ErpButton
  },
  created() {
    this.reservationInfoOption.resveDateFrom = getFormattedDate(new Date());
    this.reservationInfoOption.resveDateTo = getFormattedDate(
        moment().add(3, "M").subtract(1, "days")
    );
  },
  mounted() {
    this.reservationInfoOption.partDivOptions = commonCodesGetCommonCode(
        "PART_DIV"
    );
    if (
        this.reservationInfoOption.partDivOptions.findIndex(
            (obj) => obj.comCode === "ALL"
        ) === -1
    ) {
      this.reservationInfoOption.partDivOptions.unshift({
        comCode: "%",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
    this.reservationInfoOption.bsnCodeOptions = commonCodesGetCommonCode(
        "BSN_CODE"
    );
    if (
        this.reservationInfoOption.bsnCodeOptions.findIndex(
            (obj) => obj.comCode === "ALL"
        ) === -1
    ) {
      this.reservationInfoOption.bsnCodeOptions.unshift({
        comCode: "%",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
    if (
      this.reservationInfoOption.perDivOptions.findIndex(
        (obj) => obj.comCode === "%"
      ) === -1
    ) {
      this.reservationInfoOption.perDivOptions.unshift({
        comCode: "%",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    const bsnCodeIndex = this.reservationInfoOption.bsnCodeOptions.findIndex(
        (obj) => obj.comCode === "CLOSE"
    );

    if (bsnCodeIndex !== -1) {
      this.reservationInfoOption.bsnCodeOptions.splice(bsnCodeIndex, 1);
    }
  },
  data() {
    return {
      isMemberSelectPopupOpen: false,
      reservationInfoList: [],
      reservationInfoOption: {
        resveDateFrom: null,
        resveDateTo: null,
        searchValue: null,
        resveName: null,
        memberNo: null,
        bsnCodeOptions: commonCodesGetCommonCode("BSN_CODE"),
        bsnCode: "%",
        partDivOptions: commonCodesGetCommonCode("PART_DIV"),
        partDiv: "%",
        membershipId: null,
        isLinkedMember: false,
        visitCnt: "%",
        perDivOptions: commonCodesGetCommonCode("PER_DIV", true),
      },
      numberTemplate() {
        return {
          template: numberTemplate,
        };
      },
      selectedRowData: {},
    };
  },
  computed: {
    isPopupOpened() {
      return this.isMemberSelectPopupOpen;
    },
    reservationDateRange: {
      get() {
        return {
          from: this.reservationInfoOption.resveDateFrom,
          to: this.reservationInfoOption.resveDateTo,
        };
      },
      set(reservationDateRange) {
        this.reservationInfoOption.resveDateFrom = reservationDateRange.from;
        this.reservationInfoOption.resveDateTo = reservationDateRange.to;
      },
    },
    reservationInfoTableGridOptions() {
      return {
        provides: [Resize, ForeignKey],
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
        columns: [
          {
            field: "resveDate",
            headerText: "예약일자",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "left",
            isDateType: true,
          },
          {
            field: "dwName",
            headerText: "요일",
            type: "string",
            minWidth: 16,
            width: 66,
            textAlign: "center",
          },
          {
            field: "bsnCode",
            headerText: "영업구분",
            type: "string",
            minWidth: 16,
            width: 89,
            textAlign: "center",
            editType: "dropdownedit",
            isCommonCodeField: true,
            groupCode: "BSN_CODE",
          },
          {
            field: "resveTime",
            headerText: "시간",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "left",
          },
          {
            field: "resveCourse",
            headerText: "예약코스",
            type: "string",
            minWidth: 16,
            width: 89,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "COURSE_CODE",
          },
          {
            field: "resveName",
            headerText: "예약자명",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "left",
          },
          {
            field: "memberNo",
            headerText: "회원번호",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            valueAccessor: (field, data) =>
              gridUtilGetMemberNoColumnAccess(field, data),
          },
          {
            field: "resveKind",
            headerText: "예약종류",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "left",
            isCommonCodeField: true,
            groupCode: "RESVE_KIND",
          },
          {
            field: "resveStatus",
            headerText: "예약상태",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "left",
            isCommonCodeField: true,
            groupCode: "RESVE_STATUS",
          },
          {
            field: "memberDiv",
            headerText: "회원구분",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "left",
            editType: "dropdownedit",
            isCommonCodeField: true,
            dataSource: commonCodeGetComCodeByCurrentBizCode("MEMBER_DIV"),
          },
          {
            field: "memberGrade",
            headerText: "회원등급",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "left",
            editType: "dropdownedit",
            isCommonCodeField: true,
            dataSource: commonCodeGetComCodeByCurrentBizCode("MEMBER_GRADE"),
          },
          {
            field: "contactName",
            headerText: "연락자명",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "left",
          },
          {
            field: "contactTel",
            headerText: "연락처",
            type: "string",
            minWidth: 16,
            width: 109,
            textAlign: "left",
            valueAccessor: gridUtilGetTelColumnAccess,
          },
          {
            field: "transName",
            headerText: "위임자명",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "left",
          },
          {
            field: "transContactTel",
            headerText: "위임자 연락처",
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "left",
            valueAccessor: gridUtilGetTelColumnAccess,
          },
          {
            field: "grpName",
            headerText: "단체명",
            type: "string",
            minWidth: 16,
            width: 180,
            textAlign: "left",
          },
          {
            field: "resveChannel",
            headerText: "예약채널",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "left",
            editType: "dropdownedit",
            isCommonCodeField: true,
            groupCode: "RESVE_CHANNEL",
          },
          {
            field: "visitCnt",
            headerText: "라운드 인원",
            type: "string",
            minWidth: 16,
            width: 110,
            textAlign: "center",
          },
          {
            field: "selfFlag",
            headerText: "셀프여부",
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "resveRemarks",
            headerText: "비고",
            type: "string",
            minWidth: 16,
            width: 240,
            textAlign: "left",
          },
          {
            field: "insertName",
            headerText: "접수자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "left",
          },
          {
            field: "insertDt",
            headerText: "접수일시",
            type: "string",
            minWidth: 16,
            width: 150,
            textAlign: "left",
          },
        ],
        selectionSettings: { type: "Single", mode: "Row", enableToggle: false },
      };
    },
  },
  methods: {
    gridUtilGetTelColumnAccess,
    commonCodesGetColorValue,
    onReservationInfoPopupClosed() {
      this.$emit("popupClosed");
    },
    async onReservationDateRange() {
      // 예약일자 변경시 자동조회 안되도록 주석처리.
      // await this.getReservationInfoTable();
    },
    onMemberPopupOpen(resveName, memberNo) {
      this.isMemberSelectPopupOpen = true;

      let memberData = {
        memberNameNo: resveName ? resveName : memberNo,
      };
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(memberData);
      });
    },
    memberSelectPopupClosed() {
      this.isMemberSelectPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기
    },
    async memberSelectPopupConfirmed(data) {
      this.isMemberSelectPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기

      if (data.selectedRowData) {
        this.reservationInfoOption.resveName = data.selectedRowData.memberName;
        this.reservationInfoOption.memberNo = data.selectedRowData.memberNo;
        this.reservationInfoOption.membershipId =
          data.selectedRowData.membershipId;

        await this.getReservationInfoTable();
      }
    },
    async showReservationInfoPopup(
      resveDateFrom,
      resveDateTo,
      resveName = null,
      memberNo = null,
      resveNo = null
    ) {
      this.$refs.reservationInfoPopup.show();
      this.reservationInfoOption.resveDateFrom = resveDateFrom;
      this.reservationInfoOption.resveDateTo = moment(resveDateFrom)
        .add(3, "M")
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      if (resveName) {
        this.reservationInfoOption.searchValue = resveName;
      }
      if (memberNo) {
        this.reservationInfoOption.memberNo = memberNo;
      }
      if (resveNo) {
        this.reservationInfoOption.searchValue = resveNo;
      }

      if (!!resveName || !!memberNo || !!resveNo) {
        await this.getReservationInfoTable();
      }
    },
    confirmReservationInfoPopup() {
      if(this.selectedRowData.resveStatus != 'RESVE') {
        this.errorToast("상세조회는 '예약'상태인 예약건만 가능합니다.");
        return;
      }
      this.$emit("popupConfirmed", this.selectedRowData);
      this.$refs.reservationInfoPopup.hide();
    },
    closeReservationInfoPopup() {
      this.$refs.reservationInfoPopup.hide();
    },
    reservationInfoTableQueryCellInfo(args) {
      const {
        column: {field},
        cell,
        data,
      } = args;
      if (
        field === "webOpenFlag" ||
        field === "agncyOpenFlag" ||
        field === "resveChannel"
      ) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      } else if (field === "dwName") {
        cell.style.color = this.commonCodesGetColorValue(
          "DW_CODE",
          data.dwCode
        );
      } else if (field === "bsnCode") {
        cell.style.color = this.commonCodesGetColorValue(
          "BSN_CODE",
          data.bsnCode
        );
      }
    },
    reservationInfoTableHeaderCellInfo(args) {
      if (args.cell.column.headerText === "NO") {
        args.node.classList.add(this.$t("className.grid.noSortFilter"));
      }
    },
    async getReservationInfoTable() {
      if (this.reservationInfoOption.searchValue) {
        this.reservationInfoOption.memberNo = null;
      }
      if (this.reservationInfoOption.bsnCode === "ALL") {
        this.reservationInfoOption.bsnCode = null;
      }
      if (this.reservationInfoOption.partDiv === "ALL") {
        this.reservationInfoOption.partDiv = null;
      }
      const bsnCode = this.reservationInfoOption.bsnCode;
      const partDiv = this.reservationInfoOption.partDiv;
      const resveName = this.reservationInfoOption.resveName;
      const resveDateFrom = this.reservationInfoOption.resveDateFrom;
      const resveDateTo = this.reservationInfoOption.resveDateTo;
      const memberNo = this.reservationInfoOption.memberNo;
      const searchValue = this.reservationInfoOption.searchValue
        ? this.reservationInfoOption.searchValue
        : null;
      const membershipId = this.reservationInfoOption.isLinkedMember
        ? this.reservationInfoOption.membershipId
        : null;
      const visitCnt = this.reservationInfoOption.visitCnt === "%" ? null : this.reservationInfoOption.visitCnt;

      const response = await getReservationInfoTable(
        resveDateFrom,
        resveDateTo,
        bsnCode,
        partDiv,
        resveName,
        memberNo,
        searchValue,
        membershipId,
        visitCnt,
      );

      this.reservationInfoList = response.value.reservationInfoLists;
    },
    resveNameClear() {
      this.reservationInfoOption.resveName = null;
      this.reservationInfoOption.membershipId = null;
      this.reservationInfoOption.isLinkedMember = false;
    },
    memberNoClear() {
      this.reservationInfoOption.memberNo = null;
      this.reservationInfoOption.membershipId = null;
      this.reservationInfoOption.isLinkedMember = false;
    },
    reservationGridRowSelected(args) {
      this.selectedRowData = args.data;
    },
    async onSearchValueTextBoxKeyDownEnter() {
      await this.getReservationInfoTable();
    },
    onReservationInfoTableGridDataBound() {
      if (this.reservationInfoList.length > 0) {
        this.$refs.reservationInfoTableGrid.selectRow(0);
      } else {
        document.getElementById("searchValueTextBox").select();
      }
    },
    async onResveNameTextBoxKeyDownEnter() {
      if (this.reservationInfoOption.resveName) {
        this.onMemberPopupOpen(
          this.reservationInfoOption.resveName,
          this.reservationInfoOption.memberNo
        );
      } else {
        await this.getReservationInfoTable();
      }
    },
    async onMemberNoTextBoxKeyDownEnter() {
      if (this.reservationInfoOption.memberNo) {
        this.onMemberPopupOpen(
          this.reservationInfoOption.resveName,
          this.reservationInfoOption.memberNo
        );
      } else {
        await this.getReservationInfoTable();
      }
    },
    onReservationInfoTableGridDoubleClick() {
      this.confirmReservationInfoPopup();
    },
  },
};
</script>

<template>
  <div>
    <ejs-dialog
      ref="saleInfoPopup"
      :header="`일반대여 등록`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="833"
      height="600"
      :animationSettings="{ effect: 'None' }"
      :isModal="true"
      :close="onSaleInfoPopupClosed"
    >
      <div class="window generalRentalRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article article-01">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-box">
                      <ul>
                        <li>시간: {{ popupData.startTime }}</li>
                        <li>
                          코스:
                          {{
                            commonCodesGetComName(
                              "COURSE_CODE",
                              popupData.startCourse
                            )
                          }}
                        </li>
                        <li>내장객: {{ popupData.visitName }}</li>
                        <li>락카번호: {{ popupData.lockerNo }}</li>
                      </ul>
                    </div>
                  </div>
                </section>
              </article>
              <article class="body-article article-02">
                <div class="article-left">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-02">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">일반대여 상품 목록</div>
                      </div>
                    </div>
                    <div class="section-body">
                      <ejs-grid-wrapper
                        ref="productListGrid"
                        :dataSource="productList"
                        v-bind="productListGridOptions"
                      />
                    </div>
                  </section>
                </div>
                <div class="article-center">
                  <section class="article-section section-03">
                    <div class="section-body">
                      <ul class="body-button">
                        <li class="add">
                          <erp-button
                              button-div="SAVE"
                              :is-icon-custom="true"
                              @click.native="rentalProductAdd"
                          >
                            추가
                          </erp-button>
                        </li>
                        <li class="delete">
                          <erp-button
                              button-div="SAVE"
                              :is-icon-custom="true"
                              @click.native="rentalProductDelete"
                          >
                            삭제
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </section>
                </div>
                <div class="article-right">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-04">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">일반대여 등록</div>
                      </div>
                    </div>
                    <div class="section-body">
                      <ejs-grid-wrapper
                        ref="rentalProductListGrid"
                        :dataSource="saleInfo.rentalProductList"
                        v-bind="rentalProductListGridOptions"
                        @headerCellInfo="rentalProductListGridHeaderCellInfo"
                        @queryCellInfo="rentalProductListGridQueryCellInfo"
                        @cellSaved="rentalProductListGridCellSaved"
                        @batchDelete="rentalProductListGridBatchDelete"
                      />
                    </div>
                  </section>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-shortcut-button="true"
                  :is-icon-custom="true"
                  @click.native="saveSaleInfoPopup"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeSaleInfoPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import { Edit, Resize } from "@syncfusion/ej2-vue-grids";

import { commonCodesGetComName } from "@/utils/commonCodes";
import { getGridNextNoOfGridElement } from "@/utils/gridUtil";

import {
  getProductListSaleInfo,
  saveProductListSaleInfo,
} from "@/api/frontManagementCheckin";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

export default {
  name: "saleInfoPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    ErpButton
  },
  data: function () {
    return {
      popupData: {},
      productList: [],
      saleInfo: {},
    };
  },
  computed: {
    productListGridOptions() {
      return {
        provides: [Edit, Resize],
        enableHover: false,
        columns: [
          {
            field: "productCode",
            allowEditing: false,
            type: "string",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "productName",
            headerText: "품목명",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 180,
            textAlign: "left",
          },
          {
            field: "salePrice",
            headerText: "단가",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "right",
            isNumericType: true,
          },
        ],
      };
    },
    rentalProductListGridOptions() {
      return {
        provides: [Edit, Resize],
        enableHover: false,
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        columns: [
          {
            field: "productCode",
            allowEditing: false,
            type: "string",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "productName",
            headerText: "품목명",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 140,
            textAlign: "left",
          },
          {
            field: "saleQty",
            headerText: "수량",
            allowEditing: true,
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "right",
            isNumericType: true,
          },
          {
            field: "saleAmt",
            headerText: "금액",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "right",
            isNumericType: true,
          },
        ],
      };
    },
  },
  methods: {
    commonCodesGetComName,
    rentalProductAdd() {
      // 일반대여 상품 목록에서 선택된 데이터 가져오기
      const selectedProductCodes = this.$refs.productListGrid.getSelectedRecords();
      if (selectedProductCodes.length === 0) {
        this.errorToast("일반대여 상품 목록에서 추가할 상품을 선택해 주세요");
        return;
      }

      // 일반대여 등록 그리드에 추가
      const selectedProduct = selectedProductCodes[0];
      // const dataRows = this.$refs.rentalProductListGrid.getSelectedRows();
      // let addIndex = 0;
      // if (dataRows.length > 0) {
      //   addIndex = getGridNextNoOfGridElement(dataRows, 0);
      // }

      this.$refs.rentalProductListGrid.addRecord({
        // index: addIndex,
        productCode: selectedProduct.productCode,
        productName: selectedProduct.productName,
        saleQty: 1, // 판매수량
        salePrice: selectedProduct.salePrice,
        saleAmt: selectedProduct.salePrice, // 판매금액
        notaxCode: selectedProduct.notaxCode,
      });

      // 일반대여 상품 목록 그리드에서 제거
      this.productList.splice(
        this.productList.findIndex(
          (product) => product.productCode === selectedProduct.productCode
        ),
        1
      );
      this.$refs.productListGrid.refresh();

      // 현재 '일반대여 등록' 그리드의 추가/수정/datasource 에 이미 있는지 여부 확인
      /* const selectedProduct = selectedProductCodes[0]
      const existingRentalProduct = getCurrentDataByGridId(this.$refs.rentalProductListGrid, selectedProduct.productCode, 'productCode')
      if (existingRentalProduct) {
        this.errorToast('이미 추가하신 상품입니다')
      } */
    },
    rentalProductDelete() {
      const selectedRentalProductCodes = this.$refs.rentalProductListGrid.getSelectedRecords();
      const selectedRentalProduct = selectedRentalProductCodes[0];

      // 선택 체크
      if (selectedRentalProductCodes.length === 0) {
        this.errorToast("일반대여 등록 목록에서 삭제할 상품을 선택해 주세요");
        return;
      }

      // 일반대여 등록 그리드에 데이터 존재 여부 체크 - TODO :getSelectedRecords() 버그로 인해 체크하는 것임. 버그 수정 후 해당 코드 삭제
      if (this.$refs.rentalProductListGrid.getDataRows().length === 0) {
        this.errorToast("삭제할 데이터가 없습니다");
        return;
      }

      // 일반대여 상품 목록 그리드에 데이터가 존재하는지 여부 체크 - TODO :getSelectedRecords() 버그로 인해 체크하는 것임. 버그 수정 후 해당 코드 삭제
      if (
        this.productList.findIndex(
          (product) => product.productCode === selectedRentalProduct.productCode
        ) !== -1
      ) {
        console.log("### 일반대여 상품 목록에 이미 존재하는 데이터 ###");
        return;
      }

      // 일반대여 등록에서 삭제 후 일반대여 상품 목록에 추가 & Ordering & 일반대여 상품 그리드 refresh
      this.$refs.rentalProductListGrid.deleteRecord();
      this.$refs.rentalProductListGrid.clearSelection();

      this.productList.push({
        productCode: selectedRentalProduct.productCode,
        productName: selectedRentalProduct.productName,
        salePrice: selectedRentalProduct.salePrice, // 단가
      });
      this.reOrderProductList();
      this.$refs.productListGrid.refresh();
    },
    rentalProductListGridHeaderCellInfo(args) {
      if (args.cell.column.field === "saleQty") {
        // 헤더에 필수영역 표시 클래스 추가
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    rentalProductListGridQueryCellInfo(args) {
      // 수정영역 셀 처리
      if (args.column.field === "saleQty") {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    rentalProductListGridBatchDelete() {
      // 일반대여 등록 그리드 clear selection
      this.$refs.rentalProductListGrid.clearSelection();
    },
    closeSaleInfoPopup() {
      this.$refs.saleInfoPopup.hide();
    },
    async saveSaleInfoPopup() {
      // 일반대여 등록의 데이터 저장 API 호출
      const rentalProductListGridBatchChanges = this.$refs.rentalProductListGrid.getBatchChanges();
      let saveObject = {
        salesId: this.saleInfo.salesId,
        saleDate: this.saleInfo.saleDate,
        storeCode: this.saleInfo.storeCode,
        payerId: this.popupData.visitId,
        addedRecords: rentalProductListGridBatchChanges.addedRecords,
        changedRecords: rentalProductListGridBatchChanges.changedRecords,
        deletedRecords: rentalProductListGridBatchChanges.deletedRecords,
      };
      if (!saveObject.saleDate) {
        // saleDate가 없으면, 넘어온 영업일자로 설정
        saveObject.saleDate = this.popupData.bsnDate;
      }
      if (
        saveObject.addedRecords.length === 0 &&
        saveObject.changedRecords.length === 0 &&
        saveObject.deletedRecords.length === 0
      ) {
        this.errorToast("등록할 상품을 추가하거나 삭제해 주십시오");
        return;
      }

      await saveProductListSaleInfo(saveObject);
      this.infoToast(this.$t("main.popupMessage.saved"));
      this.$emit("popupClosed", { isReload: true });
    },
    onSaleInfoPopupClosed() {
      this.$emit("popupClosed");
    },
    async getSaleInfo() {
      const response = await getProductListSaleInfo(this.popupData.bsnDate, this.popupData.visitId);
      this.productList = response.value.salesProductList;
      this.saleInfo.salesId = response.value.salesId;
      this.saleInfo.saleDate = response.value.saleDate;
      this.saleInfo.storeCode = response.value.storeCode;
      this.saleInfo.rentalProductList = response.value.rentProductList;

      // productList의 항목 중 saleInfo.rentalProductList의 항목 제외
      this.saleInfo.rentalProductList.forEach((rentalProduct) => {
        this.productList.splice(
          this.productList.findIndex(
            (product) => product.productCode === rentalProduct.productCode
          ),
          1
        );
      });
      /*
      setTimeout(() => {
        this.productList = [
          {
            'productCode': 'PROD-0001', // 상품코드
            'productName': '1번 상품', // 상품이름
            'price': 10000 // 단가
          },
          {
            'productCode': 'PROD-0002', // 상품코드
            'productName': '2번 상품', // 상품이름
            'price': 30000 // 단가
          },
          {
            'productCode': 'PROD-0003', // 상품코드
            'productName': '3번 상품', // 상품이름
            'price': 50000 // 단가
          }
        ]

        this.saleInfo = {
          'salesId': 1, // 매출ID
          'saleDate': '2019-04-19', // 매출일자
          'storeCode': 'RENT', // 매장코드
          'rentalProductList': [
            {
              'productCode': 'PROD-0002', // 상품코드
              'productName': '2번 상품', // 상품이름
              'saleQty': 2, // 판매수량
              'salePrice': 30000, // 판매단가
              'saleAmt': 60000 // 판매금액
            },
            {
              'productCode': 'PROD-0003', // 상품코드
              'productName': '3번 상품', // 상품이름
              'saleQty': 3, // 판매수량
              'salePrice': 50000, // 판매단가
              'saleAmt': 150000 // 판매금액
            }
          ]
        }
      }, 10)
      */
    },
    /**
     * productList를 productCode로 재정렬
     */
    reOrderProductList() {
      this.productList.sort((product1, product2) => {
        if (product1.productCode > product2.productCode) {
          return 1;
        } else if (product1.productCode < product2.productCode) {
          return -1;
        } else {
          return 0;
        }
      });
    },
    async showSaleInfoPopup(popupData) {
      this.popupData = popupData;

      await this.getSaleInfo();
    },
    rentalProductListGridCellSaved(args) {
      if (args.columnName === "saleQty") {
        // 내장객 정보
        const selectedGridIdx = this.$refs.rentalProductListGrid
          .getDataRows()
          .findIndex((dataRow) => dataRow === args.cell.closest("tr"));
        if (args.value === null) {
          // 빈 칸이 입력되면 예전 값으로 돌림
          this.$refs.rentalProductListGrid.updateCell(
            selectedGridIdx,
            "saleQty",
            args.previousValue
          );
        } else {
          this.$refs.rentalProductListGrid.updateCell(
            selectedGridIdx,
            "saleAmt",
            args.rowData.salePrice * args.value
          );
        }
      }
    },
  },
};
</script>

<template>
  <div>
    <ejs-dialog
      ref="changePayerPopup"
      :header="`지불자 변경`"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :animationSettings="animationSettings"
      :close="onChangePayerPopupClosed"
      width="1180"
      height="800"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <ul class="content">
                      <li class="item">
                        <ejs-dropdownlist
                          v-model.trim="searchDefaultOption"
                          :dataSource="searchOptions"
                          :allowFiltering="false"
                          :fields="{ text: 'comName', value: 'comCode' }"
                          cssClass="lookup-condition-dropdown"
                          style="width: 120px"
                        />
                      </li>
                      <li class="item">
                        <input-text
                          ref="searchKeywordTextBox"
                          id="searchKeywordTextBox"
                          v-model="searchKeyword"
                          @focus="onChangePayerAllClear"
                          @keydown.enter="onChangePayerKeyDownEnter"
                          style="width: 120px"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      button-div="GET"
                      :is-shortcut-button="true"
                      @click.native="onChangePayerView"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">타임 LIST</div>
                      <div class="header-caption">
                        [{{ numberWithCommas(changePayerInfo.length) }}건]
                      </div>
                    </div>
                  </div>
                  <div
                    class="section-body"
                    style="border: none; overflow: hidden"
                  >
                    <div class="body-grid">
                      <template>
                        <ejs-grid-wrapper
                          ref="changePayerGrid"
                          id="changePayerGrid"
                          :allowResizing="true"
                          :dataSource="changePayerInfo"
                          :selectionSettings="selectionSettings"
                          :allowExcelExport="true"
                          :columns="changePayerGridColumn"
                          :provides="grid"
                          :groupSettings="groupSettings"
                          :allowGrouping="true"
                          :isSelectedRowRetain="false"
                          :isInPopup="true"
                          @queryCellInfo="queryCellInfo"
                          @onGridDialogDoubleClickedOrEnterKeyed="
                            onChangePayerGridDoubleClick
                          "
                          @dataBound="onChangePayerGridDataBound"
                        />
                      </template>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  @click.native="onChangePayerConfirm"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeChangePayerPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import { getPayerChange } from "@/api/frontManagement";
import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
} from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";
import { Filter, ForeignKey, Selection } from "@syncfusion/ej2-vue-grids";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

export default {
  name: "ChangePayerPopup",
  components: {
    InputText,
    EjsGridWrapper,
    ErpButton
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      grid: [ForeignKey, Filter, Selection],
      selectionSettings: {
        type: "Single",
        mode: "Both",
        enableToggle: false,
        persistSelection: false,
      },
      searchDefaultOption: "visitName",
      searchOptions: [
        { comCode: "visitName", comName: "내장객명" },
        { comCode: "lockerNo", comName: "락카번호" },
      ],
      searchKeyword: null, // 검색어
      visitId: null, // 내장객Id
      visitName: null, // 내장객명
      lockerNo: null, // 락커번호
      chkinId: null, // 체크인아이디
      bsnDate: null, // 영업일자
      grpNo: null, // 단체명. 단체를 클릭하고 팝업 오픈한 경우 기본값 null 아님
      slipId: null, // 전표번호
      courseCode: commonCodesGetCommonCode("COURSE_CODE"), //코스
      changePayerInfo: [],
      animationSettings: { effect: "None" },
      groupSettings: { showDropArea: false, columns: ["groupInfo"] },
      changePayerGridColumn: [
        {
          field: "groupInfo",
          headerText: "구분",
          visible: false,
        },
        {
          field: "partDiv",
          headerText: "부",
          textAlign: "center",
          visible: false,
        },
        {
          field: "startTime",
          headerText: "시간",
          textAlign: "center",
          visible: false,
        },
        {
          field: "startCourse",
          headerText: "코스",
          textAlign: "center",
          visible: false,
          comCode: "DC_KIND",
          valueAccessor: this.valueAccessor,
        },
        {
          field: "resveName",
          headerText: "예약자",
          textAlign: "center",
          visible: false,
        },
        {
          field: "grpName",
          headerText: "단체명",
          textAlign: "center",
          visible: false,
        },
        {
          field: "visitName",
          headerText: "내장객",
          textAlign: "center",
        },
        {
          field: "lockerNo",
          headerText: "락카",
          textAlign: "center",
        },
        {
          field: "chkoutTime",
          headerText: "C/O",
          textAlign: "center",
        },
      ],
    };
  },
  methods: {
    numberWithCommas,
    valueAccessor(field, data, column) {
      let code = data[field];
      let returnCode = null;
      if (code != null) {
        returnCode = commonCodesGetComName(column.comCode, code);
      }
      return returnCode;
    },
    queryCellInfo(args) {
      if (args.column.field === "visitName") {
        args.cell.style.fontWeight = "bold";
      }
      if (args.column.field === "chkoutTime") {
        if (!args.cell.innerText) {
          args.cell.innerText = "--:--";
        } else {
          args.cell.style.backgroundColor = "#00FFFF"; // C/O한 경우 색상 css설정
        }
      }
    },
    async showChangePayerPopup(popupData) {
      this.chkinId = popupData.chkinId;
      this.bsnDate = popupData.bsnDate;
      this.grpNo = popupData.grpNo;
      this.slipId = popupData.slipId;

      await this.getPayerChange();
    },
    closeChangePayerPopup() {
      this.$refs.changePayerPopup.hide();
    },
    onChangePayerPopupClosed() {
      this.$emit("popupClosed");
    },
    onChangePayerAllClear() {
      this.searchKeyword = null;
    },
    async onChangePayerView() {
      await this.getPayerChange();
    },
    onChangePayerConfirm() {
      const selectedRowData = this.$refs.changePayerGrid.getSelectedRecords();
      if (selectedRowData.length === 0) {
        this.errorToast("변경할 지불자를 선택해 주세요");
        return;
      }

      if (selectedRowData[0].chkoutTime) {
        this.errorToast("체크아웃된 지불자는 선택할 수 없습니다");
        return;
      }
      this.$emit("popupConfirmed", selectedRowData[0], this.slipId);
    },
    setGroupInfo(visitList) {
      //그룹별 데이터 출력을 위해 groupInfo 생성
      this.changePayerInfo = visitList.map((item) => {
        const partDiv = commonCodesGetComName("PART_DIV", item.partDiv);
        const startCourse = commonCodesGetComName(
          "COURSE_CODE",
          item.startCourse
        );
        const startTime =
          item.startTime.substring(0, 2) + ":" + item.startTime.substring(2);
        item.groupInfo = `${partDiv} ${startTime} ${startCourse} ${item.resveName}`;
        return item;
      });
    },
    async getPayerChange() {
      const bsnDate = this.bsnDate; //영업일자
      const chkinId = this.chkinId; //체크인ID
      const visitName =
        this.searchDefaultOption === "visitName" ? this.searchKeyword : null; //내장객명
      const lockerNo =
        this.searchDefaultOption === "lockerNo" ? this.searchKeyword : null; //락카번호
      const grpNo = this.grpNo; //단체번호

      const response = await getPayerChange(
        bsnDate,
        chkinId,
        grpNo,
        lockerNo,
        visitName
      );
      this.setGroupInfo(response.value.visitList);
    },
    onChangePayerKeyDownEnter() {
      this.onChangePayerView();
    },
    onChangePayerGridDataBound() {
      if (this.changePayerInfo.length > 0) {
        this.$refs.changePayerGrid.selectRow(0);
      } else {
        document.getElementById("searchKeywordTextBox").select();
      }
    },
    onChangePayerGridDoubleClick() {
      this.onChangePayerConfirm();
    },
  },
};
</script>

<style scoped></style>

<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field memberNameNumber">
            <div class="title">
              {{ labels.MemberName }}
            </div>
            <ul class="content">
              <li class="item">
                <input-text
                  ref="searchMemberName"
                  v-model="q"
                  @keydown.enter="onSearchClick"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              @click.native="onSearchClick"
              :disabled="isLoading || commonMixinIsButtonDisableByAuth('memberMngWebGet')"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              button-div="GET"
              :is-icon-custom="true"
              @click.native="openSearchMembersFilterOptionsPopup"
          >
            상세검색
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true" />
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">웹회원 목록</div>
              <div class="header-caption">
                [ {{ totalMemberships | numberWithCommas }} 건 ]
              </div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="refresh">
                  <erp-button
                      button-div="DELETE"
                      :is-icon-custom="true"
                      @click.native="onClickRefresh">
                    새로고침
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      :disabled="!isSearched || isWebMemberRegistrationExcelDownloading"
                      @click.native="onExcelClick"
                  >
                    Excel
                  </erp-button>
                </li>
                <li class="add">
                  <erp-button
                      button-div="GET"
                      :ignore="isPopupOpened"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="webMemberInfoRegistration.shortcuts.add"
                      :shortcut="{key: 'F3'}"
                    @click.native="onMemberAddClicked"
                    :disabled=" commonMixinIsButtonDisableByAuth('memberMngWebReg') ||!commonMixinHasCiperTextGet"
                  >
                    추가
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <grid
              ref="memberGrid"
              id="memberGrid"
              v-bind="memberGridProps"
              @cellClick="onMemberGridCellClick"
              @filtered="onMemberGridDataFiltered"
              @sorted="onMemberGridDataFiltered"
              @paged="onMemberGridDataFiltered"
            />
          </div>
        </section>
      </article>
    </div>
    <search-web-members-filter-options-popup
      v-if="visibleSearchMembersFilterOptionsPopup"
      ref="search-members-filter-options-popup"
      :q="q"
      :filterOptions="searchMembersFilterOptions"
      :labels="labels"
      @submit="onSearchMembersFilterOptionsPopupSubmit"
      @close="onSearchMembersFilterOptionsPopupClose"
    />
    <web-member-info-popup
      v-if="visibleMemberInfoPopupOpen"
      ref="webMemberInputPopup"
      :member-id="webMemberInfoMemberId"
      :membership-id="webMemberInfoMembershipId"
      @close="onCloseMemberInfoPopup"
    />
    <!--<BasePopupWrapper
      ref="webMemberInfoPopupWrapper"
      :componentPath="`/member-management/WebMemberInfoPopup.vue`"
      @basePopupEvent="onBasePopupEvent"
    />-->
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.memberNameNumber .content .item {width: 120px;}
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import commonMixin from "@/views/layout/mixin/commonMixin";
import { numberWithCommas } from "@/utils/number";
import WebMemberInfoPopup from "./WebMemberInfoPopup";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ReportView from "@/components/common/report/ReportView";
import SearchWebMembersFilterOptionsPopup from "@/views/member-management/popup/SearchWebMembersFilterOptionsPopup";
import ErpButton from "@/components/button/ErpButton.vue";
import Grid from "@/components/grid/Grid";
import {
  FORMAT_MEMBER_NUMBER,
  FORMAT_TEL_CELLPHONE_NUMBER,
} from "@/components/grid/GridCellDataFormatUtil";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

export default {
  name: "webMemberInfoRegistration",
  components: {
    Grid,
    SearchWebMembersFilterOptionsPopup,
    InputText,
    WebMemberInfoPopup,
    ReportView,
    ErpButton
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  filters: {
    numberWithCommas,
  },
  mounted() {
    // 기본 포커싱
    this.$refs.searchMemberName.focusIn();
  },
  data() {
    return {
      isLoading: false,
      isSearched: false,

      visibleSearchMembersFilterOptionsPopup: false,
      visibleMemberInfoPopupOpen: false,

      q: "",
      searchMembersFilterOptions: {
        endOfHpNumber: "",
        webMemberId: "",
        acquireDateFrom: "",
        acquireDateTo: "",
        gender: "",
      },

      labels: {
        MemberName: "회원명/번호",
        webMemberId: "웹 ID",
        endOfHpNumber: "핸드폰",
        acquireDate: "취득일자",
        gender: "성별",
      },

      totalMemberships: 0,
      memberships: [],

      webMemberInfoMemberId: null,
      webMemberInfoMembershipId: null,
    };
  },
  computed: {
    ...mapGetters(["username"]),
    ...mapGetters("documents", ["isWebMemberRegistrationExcelDownloading"]),
    isPopupOpened() {
      return (
        this.visibleSearchMembersFilterOptionsPopup ||
        this.visibleMemberInfoPopupOpen
      );
    },
    datetimeString() {
      return moment().format(`YYYY-MM-DD HH:mm:ss`);
    },
    memberGridProps() {
      return {
        columns: [
          {
            minWidth: 16,
            name: "회원번호",
            width: 100,
            field: "memberNumber",
            type: String,
            class: () => [this.$t("className.grid.clickArea")],
            cellClass: () => [this.$t("className.grid.clickArea")],
            format: FORMAT_MEMBER_NUMBER,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "회원명",
            width: 90,
            field: "memberName",
            type: String,
            class: () => [this.$t("className.grid.clickArea")],
            cellClass: () => [this.$t("className.grid.clickArea")],
          },
          {
            minWidth: 16,
            name: "웹 ID",
            width: 120,
            field: "memberWebIdInformationId",
            type: String,
            format: (memberWebIdInformationId) => {
              if (["KAKAO", "NAVER"].includes(memberWebIdInformationId?.split(":")?.[0])) {
                return memberWebIdInformationId?.split(":")?.[0];
              } else {
                return memberWebIdInformationId;
              }
            },
            class: () => [this.$t("className.grid.clickArea")],
            cellClass: () => [this.$t("className.grid.clickArea")],
          },
          {
            minWidth: 16,
            name: "성별",
            width: 65,
            field: "memberGenderComName",
            type: String,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "생년월일",
            width: 90,
            field: "memberBirthday",
            type: Date,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "연령",
            width: 60,
            field: "memberAge",
            type: Number,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "핸드폰번호",
            width: 100,
            sortable: false,
            filterable: false,
            field: "memberPhoneNumber",
            type: String,
            format: FORMAT_TEL_CELLPHONE_NUMBER,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "취득일자",
            width: 90,
            field: "acquireDate",
            type: Date,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "등록일시",
            width: 140,
            field: "memberWebIdInformationInsertDateTime",
            type: "datetime",
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "접속차단",
            width: 90,
            field: "memberWebIdInformationIsIntercepted",
            type: Boolean,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "임시발급",
            width: 90,
            field: "memberWebIdInformationIsTemporary",
            type: Boolean,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "최근 로그인",
            width: 140,
            field: "memberWebIdInformationLoginDateTime",
            type: "datetime",
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "최근 접속 IP",
            width: 120,
            field: "memberWebIdInformationConnectIp",
            type: String,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "로그인 유형",
            width: 120,
            field: "memberWebIdInformationLoginKind",
            type: String,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "운영체제",
            width: 120,
            field: "memberWebIdInformationOperatingSystem",
            type: String,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "브라우저",
            width: 120,
            field: "memberWebIdInformationBrowser",
            type: String,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "주소",
            width: 250,
            field: "homeAddr1",
            type: String,
          },
          {
            minWidth: 16,
            name: "상세주소",
            width: 200,
            field: "homeAddr2",
            type: String,
          },
        ],
        records: this.memberships,
        visibleRowNumberColumn: true,
        sortable: this.isSearched,
        filterable: this.isSearched,
        sizeOfRecordPerPage: 50,
        countOfAllRecords: this.totalMemberships,
        rangeOfPages: 10,
      };
    },
  },
  methods: {
    ...mapActions("documents", ["downloadExcelWebMemberRegistration"]),
    openSearchMembersFilterOptionsPopup() {
      this.visibleSearchMembersFilterOptionsPopup = true;
      this.$nextTick(() => {
        this.$refs["search-members-filter-options-popup"].show();
      });
    },
    onClickRefresh() {
      if (!this.isSearched) {
        return;
      }

      this.searchMemberships();
    },
    onMemberAddClicked() {
      this.webMemberInfoMemberId = null;
      this.webMemberInfoMembershipId = null;

      this.visibleMemberInfoPopupOpen = true; // 팝업을 띄울 때 생성되도록 하기 위함
      this.$nextTick(() => {
        this.$refs.webMemberInputPopup.showPopup();
      });
    },
    onMemberGridCellClick(
      event,
      { column: { field }, record: { id, memberId } }
    ) {
      if (
        field === "memberName" ||
        field === "memberWebIdInformationId" ||
        field === "memberNumber"
      ) {
        this.visibleMemberInfoPopupOpen = true;
        this.webMemberInfoMemberId = memberId;
        this.webMemberInfoMembershipId = id;
        this.$nextTick(() => {
          this.$refs.webMemberInputPopup.showPopup();
        });
      }
    },
    onMemberGridDataFiltered(event, { page, filters, orders }) {
      if (!event) {
        return;
      }

      this.searchMemberships({
        offset: (page - 1) * 50,
        filters,
        orders,
      });
    },
    onSearchMembersFilterOptionsPopupSubmit({ filterOptions }) {
      this.searchMembersFilterOptions = filterOptions;
      this.searchMemberships();
    },
    onSearchMembersFilterOptionsPopupClose() {
      this.visibleSearchMembersFilterOptionsPopup = false;
    },
    onCloseMemberInfoPopup({ mutated }) {
      this.visibleMemberInfoPopupOpen = false;
      if (mutated) {
        this.searchMemberships();
      }
    },
    /*onBasePopupEvent(eventId, popupKey, value) {
      if (eventId === "webMemberCodeManagementSaved" || eventId === "memberSaved") {
        this.visibleMemberInfoPopupOpen = false;
        this.searchMemberships();
      }
    },*/
    onWebMemberInfoPopupEvent(eventId) {
      if (eventId === "popupClosed") {
        this.visibleMemberInfoPopupOpen = false; // 추가 팝업 dom 제거
      } else if (this.isSearched && eventId === "memberSaved") {
        // 한 번이라도 검색을 한 경우에만 재 조회
        this.searchMemberships();
      }
    },

    async onSearchClick() {
      this.$refs["memberGrid"].resetPage();
      await this.searchMemberships();

      if (this.$refs["memberGrid"].getSelectedRows().length < 1) {
        this.$refs["memberGrid"].selectRow(0);
      }
    },

    async searchMemberships({ offset, filters, orders } = {}) {
      const {
        orders: gridOrders,
        filters: gridFilters,
        page: gridPage,
      } = this.$refs["memberGrid"].getOrdersAndFiltersAndPage();

      const limit = this.memberGridProps.sizeOfRecordPerPage;

      if (this.isLoading) {
        this.errorToast("데이터를 불러오는 중입니다.");
        return;
      }

      const _orders = JSON.parse(JSON.stringify(orders || gridOrders || []));

      if (_orders.findIndex(({ field }) => field === "acquireDate") < 0) {
        _orders.push({
          field: "acquireDate",
          direction: "desc",
        });
      }
      console.log(_orders);
      this.isLoading = true;
      try {
        const {
          total: totalMemberships,
          records: memberships,
        } = await GolfErpAPI.fetchRecordsAndCountWebMemberRegistration({
          q: this.q,
          webMemberId: this.searchMembersFilterOptions.webMemberId || undefined,
          endOfHpNumber:
            this.searchMembersFilterOptions.endOfHpNumber || undefined,
          acquireDateFrom:
            this.searchMembersFilterOptions.acquireDateFrom || undefined,
          acquireDateTo:
            this.searchMembersFilterOptions.acquireDateTo || undefined,
          gender: this.searchMembersFilterOptions.gender || undefined,
          limit: limit,
          offset: offset || (gridPage - 1) * limit,
          filters: filters || gridFilters,
          orders: _orders,
        });
        this.totalMemberships = totalMemberships;
        this.memberships = memberships;
        this.isSearched = true;
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async onExcelClick() {
      if (50000 < this.totalMemberships) {
        this.errorToast("데이터가 너무 많습니다. 5만건 이하로 시도해주세요.");
        return;
      }
      const { orders: gridOrders, filters: gridFilters } = this.$refs[
        "memberGrid"
      ].getOrdersAndFiltersAndPage();
      const columns = this.$refs["memberGrid"].getColumns();

      const _orders = JSON.parse(JSON.stringify(gridOrders || []));
      if (_orders.findIndex(({ field }) => field === "memberName") < 0) {
        _orders.push({
          field: "memberName",
          direction: "asc",
        });
      }

      this.downloadExcelWebMemberRegistration({
        payload: {
          q: this.q,
          webMemberId: this.searchMembersFilterOptions.webMemberId || undefined,
          endOfHpNumber:
            this.searchMembersFilterOptions.endOfHpNumber || undefined,
          acquireDateFrom:
            this.searchMembersFilterOptions.acquireDateFrom || undefined,
          acquireDateTo:
            this.searchMembersFilterOptions.acquireDateTo || undefined,
          gender: this.searchMembersFilterOptions.gender || undefined,
          filters: gridFilters,
          orders: _orders,
          columns,
        },
      });
    },
    // print() {
    //   const searchOptionsList = [
    //     {
    //       key: this.labels.MemberName,
    //       value: this.q
    //     },
    //     {
    //       key: this.labels.webMemberId,
    //       value: this.searchMembersFilterOptions.webMemberId
    //     },
    //     {
    //       key: this.labels.endOfHpNumber,
    //       value: this.searchMembersFilterOptions.endOfHpNumber
    //     },
    //     {
    //       key: this.labels.acquireDate,
    //       value:
    //         this.searchMembersFilterOptions.acquireDateFrom &&
    //         this.searchMembersFilterOptions.acquireDateTo
    //           ? this.searchMembersFilterOptions.acquireDateFrom +
    //           " ~ " +
    //           this.searchMembersFilterOptions.acquireDateTo
    //           : null
    //     },
    //     {
    //       key: this.labels.gender,
    //       value: this.searchMembersFilterOptions.gender
    //         .replace("M", "남")
    //         .replace("F", "여")
    //     }
    //   ];
    //
    //   const searchOptions = searchOptionsList
    //     .filter(item => !!item.value)
    //     .map(item => item.key + ": " + item.value)
    //     .join(", ");
    //
    //   const {
    //     orders: gridOrders,
    //     filters: gridFilters
    //   } = this.$refs["memberGrid"].getOrdersAndFiltersAndPage();
    //
    //   const reportData = {
    //     reportJson: {
    //       username: this.username,
    //       uniqueCode: this.$options.name,
    //       searchOptions
    //     },
    //     filterSort: {
    //       q: this.q,
    //       webMemberId: this.searchMembersFilterOptions.webMemberId || undefined,
    //       endOfHpNumber: this.searchMembersFilterOptions.endOfHpNumber || undefined,
    //       acquireDateFrom: this.searchMembersFilterOptions.acquireDateFrom || undefined,
    //       acquireDateTo: this.searchMembersFilterOptions.acquireDateTo || undefined,
    //       gender: this.searchMembersFilterOptions.gender || undefined,
    //       filters: gridFilters,
    //       orders: gridOrders
    //     }
    //   };
    //   this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    // }
  },
};
</script>

<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              {{ labels.bsnDate }}
            </div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                    type="lookup-condition"
                    v-model="dateRange"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.salesDiv }}
            </div>
            <ul class="content">
              <div class="item">
                <ejs-multiselect
                    v-model="searchConditions.salesDiv"
                    cssClass="lookup-condition-multiselect"
                    :dataSource="searchOptions.salesDiv"
                    :fields="commonCodeFields"
                    placeHolder="전체"
                    width="240"
                />
              </div>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.storeCode }}
            </div>
            <ul class="content">
              <div class="item">
                <ejs-multiselect
                    v-model="searchConditions.storeCode"
                    cssClass="lookup-condition-multiselect"
                    :dataSource="searchOptions.storeCode"
                    :fields="commonCodeFields"
                    placeHolder="전체"
                    width="240"
                />
              </div>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.payDiv }}
            </div>
            <ul class="content">
              <div class="item">
                <ejs-multiselect
                    v-model="searchConditions.payDiv"
                    cssClass="lookup-condition-multiselect"
                    :dataSource="searchOptions.payDiv"
                    :fields="commonCodeFields"
                    placeHolder="전체"
                    width="240"
                />
              </div>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.insertName }}
            </div>
            <ul class="content">
              <li class="item input">
                <input-text
                    v-model="searchConditions.insertName"
                    @click.native="searchConditions.insertName = ''"
                    @keydown.enter="fetch"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              @click.native="onViewPaymentHistory"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true"/>
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">정산 현황</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      :is-shortcut-button="true"
                      :ignore="isPopupOpened"
                      @click.native="print"
                  >
                    인쇄
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      :ignore="isPopupOpened"
                      @click.native="excel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="paymentHistoryGrid"
                  :allowExcelExport="true"
                  :allowPaging="true"
                  :allowGrouping="true"
                  :allowResizing="true"
                  :provides="grid"
                  :pageSettings="pageSettings"
                  :groupSettings="groupSettings"
                  :columns="paymentHistoryGridColumn"
                  :dataSource="paymentHistoryList"
                  :aggregates="paymentHistoryGridAggregates"
                  @cellSelected="paymentHistoryGridCellSelected"
                  @headerCellInfo="paymentHistoryGridHeaderCellInfo"
                  @queryCellInfo="paymentHistoryGridQueryCellInfo"
                  @actionComplete="paymentHistoryGridActionComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import {mapGetters} from 'vuex';
import {DATE_FORMAT_YYYY_MM_DD, getDayOfWeekCaptionColor} from '@/utils/date';
import {numberWithCommas} from '@/utils/number';
import {Aggregate, ExcelExport, Filter, ForeignKey, Page, Group, Resize} from '@syncfusion/ej2-vue-grids';
import ejsGridWrapper from '@/components/common/EjsGridWrapper.vue';
import InputText from '@/components/common/text/InputText';
import InputDateRange from '@/components/common/datetime/InputDateRange';
import ReportView from '@/components/common/report/ReportView';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';
import {commonCodesGetColorValue, commonCodesGetCommonCode, commonCodesGetComName} from '@/utils/commonCodes';
import GolfERPService from '@/service/GolfERPService';
import {openNewWindow} from '@/utils/appInfo';

export default {
  name: 'PaymentHistory',
  components: {
    ReportView,
    ejsGridWrapper,
    InputText,
    InputDateRange,
    ErpButton
  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  data() {
    return {
      count: 0,
      labels: {
        bsnDate: '영업일자',
        salesDiv: '매출구분',
        storeCode: '매장',
        payDiv: '정산구분',
        insertName: '정산자',
      },
      searchConditions: {
        salesDiv: ['SALES'],
        storeCode: null,
        payDiv: null,
        insertName: '',
      },
      searchOptions: {
        salesDiv: commonCodesGetCommonCode('SALES_DIV', true),
        storeCode: commonCodesGetCommonCode('STORE_CODE', true),
        payDiv: commonCodesGetCommonCode('PAY_DIV', true),
      },
      commonCodeFields: {text: 'comName', value: 'comCode'},
      now: null,
      dateRange: {
        from: null,
        to: null,
      },
      grid: [
        Aggregate,
        Filter,
        Resize,
        Page,
        Group,
        ExcelExport,
        ForeignKey,
      ],
      paymentHistoryGridColumn: [
        {
          field: 'payId',
          type: 'number',
          isPrimaryKey: true,
          visible: false,
        },
        {
          field: 'payDateKey',
          type: 'string',
          visible: false,
        },
        {
          field: 'payDate',
          headerText: '영업일자',
          textAlign: 'center',
          width: 90,
          type: 'string',
        },
        {
          field: 'salesDiv',
          headerText: '매출구분',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'SALES_DIV',
          width: 90,
          type: 'string',
        },
        {
          field: 'storeCode',
          headerText: '매장',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'STORE_CODE',
          width: 90,
          type: 'string',
        },
        {
          field: 'prpayFrpyDiv',
          headerText: '선/후불',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'PRPAY_FRPY_DIV',
          width: 90,
          type: 'string',
        },
        {
          field: 'payDiv',
          headerText: '정산구분',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'PAY_DIV',
          width: 90,
          type: 'string',
          visible: false
        },
        {
          field: 'payDivName',
          headerText: '정산구분',
          textAlign: 'center',
          width: 90,
          type: 'string',
        },
        {
          field: 'approvalStatus',
          headerText: '승인상태',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'APPROVAL_DIV',
          width: 90,
          type: 'string',
        },
        {
          field: 'purcName',
          headerText: '매입처',
          width: 90,
          type: 'string',
        },
        {
          field: 'approvalNo',
          headerText: '승인번호',
          textAlign: 'center',
          width: 90,
          type: 'string',
        },
        {
          field: 'cardNo',
          headerText: '카드/증빙번호',
          textAlign: 'center',
          width: 120,
          type: 'string',
        },
        {
          field: 'payAmt',
          headerText: '정산금액',
          textAlign: 'right',
          isNumericType: true,
          width: 90,
          type: 'number',
        },
        {
          field: 'visitName',
          headerText: '내장객',
          width: 90,
          type: 'string',
        },
        {
          field: 'visitInfo',
          headerText: '내장객 정보',
          width: 120,
          type: 'string',
        },
        {
          field: 'insertName',
          headerText: '정산자',
          width: 90,
          type: 'string',
        },
        {
          field: 'insertDt',
          headerText: '정산일시',
          width: 130,
          type: 'string',
        },
        {
          field: 'posNo',
          headerText: 'POS번호',
          width: 90,
          type: 'string',
        },
        {
          field: 'remarks',
          headerText: '미수비고',
          textAlign: 'left',
          type: 'string',
        },
      ],
      paymentHistoryGridAggregates: [
        {
          columns: [
            {
              field: 'cardNo',
              aggregationType: 'GroupCaption',
              textAlign: 'right',
              customAggregate: '일자별 소계',
            },
            {
              field: 'payAmt',
              aggregationType: 'GroupSum',
            },
          ],
        },
        {
          columns: [
            {
              field: 'cardNo',
              aggregationType: 'TotalCaption',
              textAlign: 'right',
              customAggregate: '합계',
            },
            {
              field: 'payAmt',
              aggregationType: 'TotalSum',
            },
          ],
        },
      ],
      paymentHistoryList: [],
      pageSettings: {pageSize: 50},
      groupSettings: {
        columns: ['payDateKey'],
        showDropArea: false,
      },
    };
  },
  async created() {
    await this.initialize();
  },
  computed: {
    ...mapGetters(['username']),
    isPopupOpened() {
      return false; // TODO : 팝업 있을 경우, 삽입
    },
  },
  methods: {
    getDayOfWeekCaptionColor,
    commonCodesGetComName,
    commonCodesGetColorValue,
    numberWithCommas,
    async initialize() {
      const nowMoment = await GolfERPService.fetchNow();
      this.now = nowMoment.toDate();
      const from = nowMoment.format(DATE_FORMAT_YYYY_MM_DD);
      const to = nowMoment.format(DATE_FORMAT_YYYY_MM_DD);
      this.dateRange = {
        from,
        to,
      };
      await this.fetch();
    },
    async onViewPaymentHistory() {
      await this.fetch();
    },
    paymentHistoryGridCellSelected(args) {
      const { cellIndex: {cellIndex}, data } = args;
      if (cellIndex === 14 || cellIndex === 15) {
        const { payDate, timeId, visitId, storeCode, slipId } = data;
        if (payDate && timeId && visitId) {
          openNewWindow(this.$router.resolve({
            name: "frontCheckinVisit",
            query: {
              bsnDate: payDate,
              timeId,
              visitId,
            },
          }));
        } else if (storeCode && slipId) {
          openNewWindow(this.$router.resolve({
            name: "PosView",
            query: {
              salesDate: payDate,
              storeCode,
              slipId,
            },
          }));
        }
      }
    },
    paymentHistoryGridHeaderCellInfo(args) {
      const {
        cell: {column: {field}},
        node,
      } = args;
      if ([
        'visitName',
        'visitInfo',
      ].includes(field)) {
        node.classList.add(this.$t("className.grid.movePageArea"));
      }
    },
    paymentHistoryGridQueryCellInfo(args) {
      const {
        cell,
        column: {field},
        data,
      } = args;
      if ([
        'approvalDiv',
        'payAmt',
      ].includes(field) && ((data.payDiv !== "DEPOSIT" && data.approvalDiv === 'NA') || (data.payDiv === "DEPOSIT" && data.approvalDiv === "NA" && !data.isIncomDivCard))) {
        cell.style.color = 'red';
      }
      if ([
        'visitName',
        'visitInfo',
      ].includes(field) && ((data.chkinId && data.visitId) || data.slipId)) {
        cell.classList.add(this.$t("className.grid.movePageArea"));
      }
    },
    paymentHistoryGridActionComplete() {
      this.count = numberWithCommas(
          this.$refs.paymentHistoryGrid?.getGridBatchCount() || 0,
      );
    },
    async print() {
      const options = [
        {
          field: 'salesDiv',
          comCode: 'SALES_DIV',
          format: 'comCode',
        },
        {
          field: 'storeCode',
          comCode: 'STORE_CODE',
          format: 'comCode',
        },
        {
          field: 'prpayFrpyDiv',
          comCode: 'PRPAY_FRPY_DIV',
          format: 'comCode',
        },
        {
          field: 'payDiv',
          comCode: 'PAY_DIV',
          format: 'comCode',
        },
        {
          field: 'approvalDiv',
          comCode: 'APPROVAL_DIV',
          format: 'comCode',
        },
        {
          field: 'approvalStatus',
          comCode: 'APPROVAL_DIV',
          format: 'comCode',
        },
      ];
      const result = this.$refs.paymentHistoryGrid?.getGridBatchData(options);
      if (result.length < 1) {
        return this.errorToast(this.$t('report.popupMessage.noData'));
      }
      const searchOptionsList = [
        {
          key: this.labels.bsnDate,
          value: this.dateRange.from + ' ~ ' + this.dateRange.to,
        },
        {
          key: this.labels.salesDiv,
          value: this.searchConditions.salesDiv?.length > 0
              ? this.searchConditions.salesDiv?.map(item => commonCodesGetComName('SALES_DIV', item))?.join(', ')
              : '전체',
        },
        {
          key: this.labels.storeCode,
          value: this.searchConditions.storeCode?.length > 0
              ? this.searchConditions.storeCode?.map(item => commonCodesGetComName('STORE_CODE', item))?.join(', ')
              : '전체',
        },
        {
          key: this.labels.payDiv,
          value: this.searchConditions.payDiv?.length > 0
              ? this.searchConditions.payDiv?.map(item => commonCodesGetComName('PAY_DIV', item))?.join(', ')
              : '전체',
        },
        {
          key: this.labels.insertName,
          value: this.searchConditions.insertName !== ''
              ? this.searchConditions.insertName
              : undefined,
        },
      ];
      const searchOptions = searchOptionsList
          .filter(item => !!item.value)
          .map(item => item.key + ': ' + item.value)
          .join(', ');
      const reportData = {
        reportJson: {
          jsonData: result,
          username: this.username,
          uniqueCode: this.$options.name,
          searchOptions,
        },
      };
      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    excel() {
      this.$refs.paymentHistoryGrid.excelExport();
    },

    // API
    async fetch() {
      this.paymentHistoryList = (await GolfErpAPI.fetchPaymentHistory({
        bsnDateFrom: this.dateRange.from,
        bsnDateTo: this.dateRange.to,
        salesDiv: this.searchConditions.salesDiv,
        storeCode: this.searchConditions.storeCode,
        payDiv: this.searchConditions.payDiv,
        insertName: this.searchConditions.insertName,
      })).map(item => {
        item.payDateKey = item.payDate;
        return item;
      }) || [];
    },
  },
};
</script>

<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="variableLockerDivChangePopup"
      header="가변락카 성별변경"
      width="700"
      height="600"
      :animationSettings="{ effect: 'None' }"
      allowDragging="true"
      showCloseIcon="true"
      isModal="false"
      @close="onVariableLockerDivChangePopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">락카키</div>
                    <ul class="content">
                      <li class="item input-01 lockerNo">
                        <input-text
                          ref="lockerNoFrom"
                          v-model="searchConditions.lockerNoFrom"
                          :digit-only="true"
                        />
                      </li>
                      <li class="item dash">~</li>
                      <li class="item input-02 lockerNo">
                        <input-text
                          ref="lockerNoTo"
                          v-model="searchConditions.lockerNoTo"
                          :digit-only="true"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field sector">
                    <div class="title">섹터</div>
                    <ul class="content">
                      <li class="item">
                        <input-text
                          ref="sectorDropDown"
                          v-model="searchConditions.sector"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      button-div="GET"
                      :is-shortcut-button="true"
                      @click.native="onVariableLockerViewButtonClicked"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
              <div class="lookup-right">
                <ul class="lookup-condition">
                  <li class="field lockerDiv">
                    <div class="title">적용락카구분</div>
                    <ul class="content">
                      <li class="item">
                        <div class="form">
                          <ejs-dropdownlist
                            ref="sectorDropDown"
                            v-model="lockerDiv"
                            :dataSource="searchOptions.lockerDivOptions"
                            :fields="commonCodeFields"
                            :allowFiltering="false"
                            cssClass="body-data-dropdown"
                          />
                        </div>
                        <div class="validation">
                          <div class="e-tip-content"></div>
                          <div class="e-tip-top"></div>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <ejs-grid-wrapper
                      ref="variableLockerGrid"
                      :allowResizing="true"
                      :dataSource="variableLockerList"
                      :selectionSettings="selectionSettings"
                      :allowExcelExport="true"
                      :columns="variableLockerGridColumn"
                      :provides="grid"
                      :isSelectedRowRetain="false"
                    />
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="apply keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  @click.native="onVariableLockerDivChangeButtonClicked"
              >
                적용
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeVariableLockerDivChangePopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .lookup-condition .field .content .item.lockerNo {width: 50px;}

body .lookup-condition .field.sector .content .item,
body .lookup-condition .field.lockerDiv .content .item {width: 80px;}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {
  Aggregate,
  ExcelExport,
  Filter,
  ForeignKey,
  Group,
  Resize,
} from "@syncfusion/ej2-vue-grids";

import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
} from "@/utils/commonCodes";
import { getLockerInfoList } from "@/api/lockerInfoList";
import { validateFormRefs } from "@/utils/formUtil";
import InputText from "@/components/common/text/InputText";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

export default {
  name: "VariableLockerDivChangePopup",
  components: {
    InputText,
    EjsGridWrapper,
    ErpButton
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      grid: [Aggregate, Filter, Resize, Group, ExcelExport, ForeignKey],
      selectionSettings: {
        type: "Single",
        mode: "Both",
        enableToggle: false,
        persistSelection: false,
      },
      searchConditions: {
        bsnDate: null,
        lockerNoFrom: null,
        lockerNoTo: null,
        sector: null,
      },
      searchOptions: {
        lockerDivOptions: commonCodesGetCommonCode("LOCKER_DIV"),
      },
      lockerDiv: null,
      commonCodeFields: { text: "comName", value: "comCode" },
      variableLockerList: [],
      variableLockerGridColumn: [
        {
          field: "lockerNo",
          isPrimaryKey: true,
          headerText: "락카번호",
          width: 90,
        },
        {
          field: "lockerStatus",
          headerText: "락카상태",
          width: 90,
          comCode: "LOCKER_STATUS",
          valueAccessor: this.valueAccessor,
        },
        {
          field: "lockerDiv",
          headerText: "락카구분",
          width: 90,
          comCode: "LOCKER_DIV",
          valueAccessor: this.valueAccessor,
        },
        {
          field: "sector",
          headerText: "섹터",
          width: 90,
        },
        {
          field: "variableFlag",
          headerText: "가변여부",
          width: 90,
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          textAlign: "center",
        },
      ],
      lockerDivValidationRules: {
        sectorDropDown: {
          required: true,
        },
      },
    };
  },
  methods: {
    validateFormRefs,
    showVariableLockerDivChangePopup(popupData) {
      this.searchConditions.bsnDate = popupData;
    },
    onVariableLockerDivChangePopupClosed() {
      this.$emit("popupClosed");
    },
    closeVariableLockerDivChangePopup() {
      this.$refs.variableLockerDivChangePopup.hide();
    },
    valueAccessor(field, data, column) {
      let code = data[field];
      let returnCode = null;
      if (code != null) {
        returnCode = commonCodesGetComName(column.comCode, code);
      }
      return returnCode;
    },
    async onVariableLockerViewButtonClicked() {
      this.$refs.variableLockerGrid.clearFiltering();

      const {
        value: { lockerInfoList: data },
      } = await getLockerInfoList(
        this.searchConditions.bsnDate,
        "A",
        this.searchConditions.lockerNoFrom,
        this.searchConditions.lockerNoTo,
        null,
        this.searchConditions.sector
      );

      this.variableLockerList = data;
    },
    async onVariableLockerDivChangeButtonClicked() {
      if (!this.validateFormRefs(this.lockerDivValidationRules)) {
        return;
      }

      const data = this.$refs.variableLockerGrid.getBatchCurrentViewRecords();

      if (data.length <= 0) {
        this.errorToast("조회된 락카정보가 없습니다");
        return;
      } else {
        if (
          !(await this.confirm(
            data.length +
              "개 데이터의 락카구분을 " +
              commonCodesGetComName("LOCKER_DIV", this.lockerDiv) +
              "로 변경하시겠습니까?"
          ))
        ) {
          return;
        }
      }

      let changedRecords = [];

      data.forEach((record) => {
        const changedRecord = {
          pkLockerNo: record.lockerNo,
          lockerDiv: this.lockerDiv,
        };

        changedRecords.push(changedRecord);
      });

      if (changedRecords.length <= 0) {
        this.errorToast("가변락카 성별변경 작업중 오류가 발생하였습니다");
      }

      await GolfErpAPI.putLockerDiv(changedRecords);

      await this.onVariableLockerViewButtonClicked();
    },
  },
};
</script>

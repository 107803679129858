<template>
  <ejs-grid-wrapper
    ref="grid"
    :allowResizing="true"
    :allowExcelExport="true"
    :allowFiltering="!isModifyMode"
    :allowSorting="!isModifyMode"
    :isAutoSelectCell="!isModifyMode"
    :dataSource="dataSource"
    :selectionSettings="selectionOptions"
    :editSettings="editSettings"
    :columns="lockerInfoColumn"
    :provides="grid"
    :validationRules="gridValidationRules"
    @headerCellInfo="headerCellInfo"
    @queryCellInfo="queryCellInfo"
    @actionComplete="gridActionComplete"
    @cellSelected="gridCellSelected"
  />
</template>

<script>
import {
  commonCodesGetCommonCode,
  commonCodesGetColorValue,
} from "@/utils/commonCodes";
import { Edit, ForeignKey, Resize, Selection, ExcelExport } from "@syncfusion/ej2-vue-grids";
import numberTemplate from "@/views/common/template/GridNumberTemplate";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

export default {
  name: "LockerInformationGrid",
  props: ["dataSource", "lockerInformationGridDataSourceLength", "isModifyMode"],
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {ejsGridWrapper},
  data() {
    return {
      gridLength: 0,
      grid: [Edit, Resize, ForeignKey, Selection, ExcelExport],
      pageSettings: { pageSize: 50 },
      lockerInfoColumn: [
        {
          isPrimaryKey: true,
          width: 90,
          headerText: "락카번호",
          textAlign: "Center",
          type: "string",
          maxLength: 10,
          displayAsCheckBox: false,
          field: "lockerNo",
          allowEditing: true,
          required: true,
        },
        {
          width: 120,
          headerText: "배치순번(남)",
          textAlign: "Center",
          type: "number",
          format: "N",
          displayAsCheckBox: false,
          field: "argmtSno",
          allowEditing: true,
          multiEdit: {
            propMaxLength: 10,
            allowDot: false,
            allowMinus: false,
            displayComma: false,
          },
        },
        {
          width: 120,
          headerText: "배치순번(여)",
          textAlign: "Center",
          type: "number",
          format: "N",
          displayAsCheckBox: false,
          field: "argmtSnoFemale",
          allowEditing: true,
          multiEdit: {
            propMaxLength: 10,
            allowDot: false,
            allowMinus: false,
            displayComma: false,
          },
        },
        {
          width: 90,
          headerText: "락카상태",
          textAlign: "Center",
          type: "string",
          displayAsCheckBox: false,
          field: "lockerStatus",
          isCommonCodeField: true,
          allowEditing: false,
          groupCode: "LOCKER_STATUS",
        },
        {
          width: 90,
          headerText: "락카구분",
          textAlign: "Center",
          type: "boolean",
          field: "lockerDiv",
          editType: "dropdownedit",
          isCommonCodeField: true,
          allowEditing: true,
          required: true,
          groupCode: "LOCKER_DIV",
        },
        {
          width: 120,
          headerText: "정렬순서",
          textAlign: "Center",
          type: "number",
          format: "N",
          displayAsCheckBox: false,
          field: "sortNo",
          allowEditing: true,
          required: true,
          multiEdit: {
            propMaxLength: 10,
            allowDot: false,
            allowMinus: false,
            displayComma: false,
          },
        },
        {
          width: 120,
          headerText: "정렬순서(최소)",
          textAlign: "Center",
          type: "number",
          format: "N",
          displayAsCheckBox: false,
          field: "sortNoMin",
          allowEditing: true,
          required: true,
          multiEdit: {
            propMaxLength: 10,
            allowDot: false,
            allowMinus: false,
            displayComma: false,
          },
        },
        {
          width: 90,
          headerText: "섹터",
          textAlign: "Center",
          type: "string",
          displayAsCheckBox: false,
          field: "sector",
          allowEditing: true,
        },
        {
          width: 110,
          headerText: "섹터출력(남)",
          textAlign: "Center",
          type: "string",
          displayAsCheckBox: false,
          field: "sectorPrtMale",
          allowEditing: true,
        },
        {
          width: 110,
          headerText: "섹터출력(여)",
          textAlign: "Center",
          type: "string",
          displayAsCheckBox: false,
          field: "sectorPrtFemale",
          allowEditing: true,
        },
        {
          width: 90,
          headerText: "층",
          textAlign: "Center",
          type: "number",
          format: "N",
          displayAsCheckBox: false,
          field: "floor",
          allowEditing: true,
          multiEdit: {
            propMaxLength: 10,
            allowDot: false,
            allowMinus: false,
            displayComma: false,
          },
        },
        {
          width: 90,
          headerText: "가변여부",
          textAlign: "Center",
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          field: "variableFlag",
          allowEditing: true,
        },
        {
          width: 90,
          headerText: "출력번호",
          textAlign: "Center",
          type: "string",
          maxLength: 10,
          displayAsCheckBox: false,
          field: "lockerPrtNo",
          allowEditing: true,
          required: true,
          multiEdit: {
            propMaxLength: 10,
            allowDot: false,
            allowMinus: false,
            displayComma: false,
          },
        },
        {
          width: 90,
          headerText: "회원여부",
          textAlign: "Center",
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          field: "memberFlag",
          allowEditing: true,
        },
        {
          width: 90,
          headerText: "VIP여부",
          textAlign: "Center",
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          field: "vipFlag",
          allowEditing: true,
        },
        {
          width: 90,
          headerText: "지정락카",
          textAlign: "Center",
          displayAsCheckBox: false,
          field: "appnLocker",
          type: "string",
          allowEditing: true,
        },
        {
          width: 200,
          headerText: "비고",
          textAlign: "Center",
          displayAsCheckBox: false,
          field: "remarks",
          type: "string",
          maxLength: 400,
          allowEditing: true,
          isRemarks: true,
        },
      ],
      numberTemplate() {
        return {
          template: numberTemplate,
        };
      },
      div: commonCodesGetCommonCode("LOCKER_DIV"),
      editableFields: {},
      selectionOptions: {
        type: "Multiple",
        mode: "Both",
        enableToggle: false,
      },
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Batch",
        showConfirmDialog: false,
        newRowPosition: "Bottom",
      },
      gridValidationRules: {
        argmtSnoFemale: {
          maxLength: 9
        },
        argmtSno: {
          maxLength: 9
        },
        sortNo: {
          maxLength: 9
        }
      }
    };
  },
  watch: {
    isModifyMode(isChecked) {
      this.selectionOptions = isChecked
        ? {cellSelectionMode: 'Box', type: 'Multiple', mode: 'Cell'}
        : {type: 'Multiple', mode: 'Both', enableToggle: false};
      this.$refs.grid.refresh();
      if (isChecked) {
        this.infoToast('멀티 편집할 셀 하나와 여러 로우를 드래그하세요.');
      }
    },
  },
  async created() {
    this.lockerInfoColumn.forEach((column) => {
      if (column.required) {
        column["customAttributes"] = {
          class: this.$t("className.grid.requiredInputHeader"),
        };
      }
      this.editableFields[column.field] = !!column.allowEditing;
    });
  },
  methods: {
    headerCellInfo(args) {
      const {
        cell: {
          column: {
            field,
            headerText,
          },
        },
        node,
      } = args;
      if (this.isModifyMode && headerText !== 'NO') {
        const allowedEditColumns = this.lockerInfoColumn
          ?.filter(item => item.allowEditing && item.field !== 'lockerNo')
          ?.map(item => item.field);
        if (allowedEditColumns.includes(field)) {
          node.style.backgroundColor = 'rgb(237, 246, 250)';
          node.addEventListener('click', async () => await this.onGridHeaderClicked(args.cell.column), false);
        }
      }
    },
    async onGridHeaderClicked(column) {
      if (!column?.field) {
        return;
      }
      const gridRefs = this.$refs.grid;
      const selectedRowIndexes = [...new Array(gridRefs?.getBatchCurrentViewRecords().length).keys()];
      this.$emit('editMultipleColumnsPopupOpen', {
        column,
        selectedRowIndexes
      });
    },
    queryCellInfo(args) {
      const {
        column: {field},
        cell,
        data,
      } = args;
      if (
        this.editableFields[field] &&
        !(this.isModifyMode && field === 'lockerNo')
      ) {
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
      if (field === 'lockerStatus') {
        cell.style.backgroundColor = commonCodesGetColorValue(
          'LOCKER_STATUS',
          data.lockerStatus
        );
      } else {
        if (this.isModifyMode) {
          const allowedEditColumns = this.lockerInfoColumn
            ?.filter(item => item.allowEditing && item.field !== 'lockerNo')
            ?.map(item => item.field);
          if (!allowedEditColumns.includes(field)) {
            cell.style.backgroundColor = '#f9f9f9';
            cell.style.pointerEvents = 'none';
          }
        }
      }
    },
    addRecord(record) {
      this.$refs.grid.addRecord({
        variableFlag: false,
        ...record,
      });
    },
    deleteRecord() {
      return this.$refs.grid.deleteRecord();
    },
    setPostOrderByOrderUnit(orderUnit) {
      for (let lockerDivIndex = 0; lockerDivIndex < 2; lockerDivIndex ++) {
        let lockerDiv = null;
        switch (lockerDivIndex) {
          case 0:
            lockerDiv = "M";
            break;
          case 1:
            lockerDiv = "F";
            break;
          default:
            lockerDiv = "M";
            break;
        }

        const currentViewRecords = this.$refs.grid.getBatchCurrentViewRecords().filter(data => data.lockerDiv === lockerDiv || data.variableFlag);
        const totalSize = currentViewRecords.length;

        if (!(totalSize > 0)) {
          continue;
        }

        let startIndex = 0;
        let postOrderNo = 1;
        let inputArray = [];
        for (let i = startIndex; ; i = i + 1) {
          if (i >= totalSize) {
            i = i - totalSize;
            if (startIndex > orderUnit) {
              break;
            }

            startIndex++;
          }

          if (!(inputArray.filter(data => data === i).length > 0)) {
            const rowIndex = this.$refs.grid.getRowIndexByPrimaryKey(currentViewRecords[i].lockerNo);

            this.$refs.grid.updateCell(rowIndex, lockerDiv !== "M" ? "argmtSnoFemale" : "argmtSno", postOrderNo);
            postOrderNo++;
            inputArray.push(i);
            i = i + orderUnit;
          }
        }
      }
    },
    resetPostOrder() {
      const currentViewRecords = this.$refs.grid.getCurrentViewRecords();
      const { addedRecords, deletedRecords } = this.getChangedData();
      const totalSize =
        currentViewRecords.length + addedRecords.length - deletedRecords.length;
      for (let i = 0; i < totalSize; i++) {
        this.$refs.grid.updateCell(i, "argmtSno", null);
        this.$refs.grid.updateCell(i, "argmtSnoFemale", null);
      }
    },
    getChangedData() {
      return this.$refs.grid.getBatchChanges();
    },
    gridActionComplete(args) {
      if (args.requestType === "filtering" && args.action === "clearFilter") {
        this.gridLength = this.dataSource.length;
        this.$emit("gridActionComplete", this.gridLength);
      } else if (args.requestType === "filtering") {
        this.gridLength = this.$refs.grid.getFilteredRecords().length;
        this.$emit("gridActionComplete", this.gridLength);
      }
    },
    gridClearFiltering() {
      this.$refs.grid.clearFiltering();
    },
    gridExcelExport() {
      this.$refs.grid.excelExport();
    },
    onUpdateCell({index, field, value}) {
      this.$refs.grid.updateCell(
        index,
        field,
        value,
      );
    },
    async gridCellSelected(args) {
      if (!this.isModifyMode) {
        return;
      }
      const {
        cellIndex: {cellIndex},
        selectedRowCellIndex
      } = args;
      if (selectedRowCellIndex[0]?.cellIndexes.length > 1) {
        this.errorToast('편집할 셀 하나만 선택해주세요.');
        return;
      }
      let columns = [];
      this.lockerInfoColumn
        ?.forEach(column => {
          if (column?.columns) {
            column.columns
              ?.forEach(c => columns.push(c));
          } else {
            columns.push(column);
          }
        });
      const allowedEditColumns = columns
        ?.filter(item => item.allowEditing && item.field !== 'lockerNo')
        ?.map(item => item.field);
      const column = columns[cellIndex - 1];
      if (!allowedEditColumns.includes(column?.field)) {
        this.errorToast('편집할 수 있는 셀이 아닙니다.');
        return;
      }
      const selectedRowIndexes = selectedRowCellIndex.map(item => item.rowIndex);
      if (selectedRowIndexes.length < 1) {
        return;
      }
      this.$emit('editMultipleColumnsPopupOpen', {
        column,
        selectedRowIndexes
      });
    },
  },
};
</script>

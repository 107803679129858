<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">영업일자</div>
            <ul class="content">
              <li class="item date">
                <input-date
                    :format="DATE_FORMAT_YYYY_MM_DD"
                    v-model="dailyCloseSearchOptions.bsnDate"
                    @change="onDateChange"
                />
              </li>
              <li class="item text">
                (<span
                  :style="{
                    color: getDayOfWeekCaptionColor(
                      new Date(dailyCloseSearchOptions.bsnDate)
                    ),
                  }"
              >{{
                  getDayOfWeekCaption(
                      new Date(dailyCloseSearchOptions.bsnDate)
                  )
                }}</span
              >) (<span
                  :style="{
                    color: getWeekdayWeekendCaptionColor(
                      new Date(dailyCloseSearchOptions.bsnDate)
                    ),
                  }"
              >{{
                  getWeekdayWeekendCaption(
                      new Date(dailyCloseSearchOptions.bsnDate)
                  )
                }}</span
              >)
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              v-on:click.native="onSearchButtonClick"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <ul class="lookup-button">
          <li>
            <erp-button
                button-div="GET"
                :is-icon-custom="true"
                @click.native="onDailyCloseSmsSendButtonClick"
            >
              마감 SMS 발송
            </erp-button>
          </li>
          <li class="keyColor">
            <erp-button
                button-div="SAVE"
                :is-icon-custom="true"
                @click.native="onDailyCloseExecuteButtonClick"
            >
              마감 실행
            </erp-button>
          </li>
          <li class="cancel">
            <erp-button
                button-div="DELETE"
                :is-icon-custom="true"
                @click.native="onDailyCloseCancelPopupOpen"
            >
              마감 취소
            </erp-button>
          </li>
          <li class="cancel">
            <erp-button
                button-div="DELETE"
                :is-icon-custom="true"
                :disabled="storeList.filter(store => store.storeCode === 'FRONT' && store.closeDiv !== 'Y' && store.closeDiv !== 'A').length === 0"
                @click.native="onDailyClosePenltyCancelPopupOpen"
            >
              위약 취소
            </erp-button>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <splitpanes class="default-theme" style="height: 100%">
          <pane min-size="10">
            <div class="article-left" style="width: 100%">
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section class="article-section section-01">
              <div class="section-header">
                <div class="header-left">
                  <div class="header-title">매장 목록</div>
                  <div class="header-caption">
                    [{{ numberWithCommas(storeList.length) }}건]
                  </div>
                </div>
              </div>
              <div class="section-body">
                <ejs-grid-wrapper
                    ref="storeListGrid"
                    :provides="storeListGridProvides"
                    :columns="storeListGridColumns"
                    :aggregates="storeListGridAggrigates"
                    :dataSource="storeList"
                    :enableVirtualization="false"
                    :rowHeight="24"
                    gridLines="Both"
                    :allowSorting="false"
                    :allowFiltering="false"
                    :allowSelection="true"
                    :editSettings="storeListGridEditSettings"
                    :allowResizing="true"
                    @rowSelected="storeListGridRowSelected"
                    @queryCellInfo="storeListGridQueryCellInfo"
                >
                </ejs-grid-wrapper>
              </div>
            </section>
            </div>
          </pane>
          <pane min-size="10">
            <div class="article-right" style="width: 100%">
              <splitpanes horizontal>
                <pane min-size="10">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-02">
                    <div class="section-caption" style="overflow: hidden;">
                      <div class="caption-navigation">
                        <ejs-tab
                            :showCloseButton="false"
                            heightAdjustMode="Auto"
                            :selected="onTabSelected"
                            overflowMode="Popup"
                        >
                          <e-tabitems>
                            <e-tabitem :header="{ text: '체크항목' }"></e-tabitem>
                            <e-tabitem :header="{ text: '마감/취소 이력' }"></e-tabitem>
                            <e-tabitem :header="{ text: '월별마감현황' }"></e-tabitem>
                          </e-tabitems>
                        </ejs-tab>
                      </div>
                    </div>
                    <div class="section-body">
                      <article class="body-article">
                        <!-- 아코디언 : accordion / 닫힘 : close -->
                        <section class="article-section section-0201">
                          <div class="section-body" id="gridBox">
                            <!-- visible-show / visible-hide -->
                            <ejs-grid-wrapper
                                ref="checkItemListGrid"
                                :provides="provides"
                                :columns="checkItemListGridOptions.columns"
                                :dataSource="checkItemList"
                                :enableVirtualization="false"
                                :allowResizing="true"
                                :allowSelection="true"
                                :editSettings="checkItemListGridOptions.editSettings"
                                v-if="tabSelectIndex === 0"
                            />
                            <ejs-grid-wrapper
                                ref="closeCancelHistoryListGrid"
                                :provides="provides"
                                :columns="closeCancelHistoryListGridOptions.columns"
                                :dataSource="closeCancelHistoryList"
                                :enableVirtualization="false"
                                :allowResizing="true"
                                :allowSelection="true"
                                :editSettings="closeCancelHistoryListGridOptions.editSettings"
                                v-if="tabSelectIndex === 1"
                            />
                            <ejs-grid-wrapper
                                ref="monthlyCloseStatusListGrid"
                                :provides="provides"
                                :columns="monthlyCloseStatusListGridOptions.columns"
                                :dataSource="monthlyCloseStatusList"
                                :enableVirtualization="false"
                                :allowResizing="true"
                                :allowSelection="true"
                                :editSettings="monthlyCloseStatusListGridOptions.editSettings"
                                v-if="tabSelectIndex === 2"
                            />
                          </div>
                        </section>
                      </article>
                    </div>
                  </section>
                </pane>
                <pane min-size="10">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-03">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">미정산 목록</div>
                        <div class="header-caption">
                          [{{ numberWithCommas(unsettledListLength) }}건]
                        </div>
                      </div>
                    </div>
                    <div class="section-body">
                      <ejs-grid-wrapper
                          ref="unsettledListGrid"
                          :provides="provides"
                          :columns="unsettledListGridOptions.columns"
                          :aggregates="unsettledListGridOptions.aggregates"
                          :dataSource="unsettledList"
                          :enableVirtualization="false"
                          :editSettings="unsettledListGridOptions.editSettings"
                          @actionComplete="unsettledListGridActionComplete"
                          :allowResizing="true"
                      />
                    </div>
                  </section>
                </pane>
              </splitpanes>
            </div>
          </pane>
        </splitpanes>
      </article>
    </div>
    <ejs-dialog
        ref="dailyCloseCancelPopup"
        :header="`마감 취소`"
        :allowDragging="true"
        :showCloseIcon="true"
        width="380"
        :isModal="true"
        :visible="false"
        :enableResize="false"
    >
      <div class="window dailyCloseCancel">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">취소사유</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-textarea
                                          id="cancelResn"
                                          ref="cancelResn"
                                          v-model="cancelReason"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  button-div="SAVE"
                  :ignore="isPopupOpened"
                  :is-icon-custom="true"
                  @click.native="onDailyCloseCancelButtonClick"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onDailyCloseCancelPopupClose">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <daily-close-send-sms-popup
        v-if="showDailyCloseSendSmsPopup"
        ref="dailyCloseSendSmsPopup"
        @popupClosed="dailyCloseSendSmsPopupClosed"
    />
  </div>
</template>

<style scoped>
body .appContent .body-article .article-left {width: 900px;}
body .appContent .body-article .article-right {width: calc(100% - 900px);height: 100%;}
body .appContent .article-section.section-01 .section-body {overflow: hidden;border: none;}
body .appContent .article-section.section-0201 .section-body {overflow: hidden;border: none;}
body .appContent .article-section.section-03 .section-body {overflow: hidden;border: none;}
</style>

<script>
import { Aggregate, Edit, ForeignKey, Resize } from "@syncfusion/ej2-vue-grids";
import commonMixin from "@/views/layout/mixin/commonMixin";
import DailyCloseSendSmsPopup from "@/views/daily-close/popup/DailyCloseSendSmsPopup";
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

import {
  DATE_FORMAT_YYYY_MM_DD,
  getDayOfWeekCaption,
  getDayOfWeekCaptionColor,
  getWeekdayWeekendCaption,
  getWeekdayWeekendCaptionColor,
} from "@/utils/date";
import { numberWithCommas } from "@/utils/number";

import {
  getDailyCloseCancelHistory,
  getDailyCloseCheckList,
  getDailyCloseMonthlyStatus,
  getDailyCloseStoreList,
  getDailyCloseUnsettledList,
  putDailyCloseCancel,
  putDailyCloseExecute,
} from "@/api/frontDailyClose";

import { validateFormRefs } from "@/utils/formUtil";

import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import InputDate from "@/components/common/datetime/InputDate";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputTextarea from "@/components/common/text/InputTextarea";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import moment from "moment";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "frontDailyClose",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Splitpanes,
    // eslint-disable-next-line vue/no-unused-components
    Pane,
    DailyCloseSendSmsPopup,
    InputTextarea,
    ejsGridWrapper,
    InputDate,
    ErpButton
  },
  async created() {
    // 영업일자 설정.
    // this.dailyCloseSearchOptions.bsnDate = getFormattedDate(new Date());
    this.dailyCloseSearchOptions.bsnDate = await GolfErpAPI.fetchMaxBsnDate("FRONT");
  },
  async mounted() {
  },
  data() {
    return {
      DATE_FORMAT_YYYY_MM_DD,
      unsettledListLength: 0,
      storeListGridProvides: [Resize, ForeignKey, Aggregate],
      storeListGridColumns: [
        {
          field: "bsnName",
          allowEditing: false,
          headerText: "영업장명",
          minWidth: 16,
          width: 90,
          textAlign: "left",
        },
        {
          field: "storeCode",
          headerText: "매장명",
          isPrimaryKey: true,
          minWidth: 16,
          width: 90,
          textAlign: "left",
          isCommonCodeField: true,
          groupCode: "STORE_CODE",
        },
        {
          field: "closeDiv",
          headerText: "마감구분",
          minWidth: 16,
          width: 90,
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "CLOSE_DIV",
        },
        {
          headerText: "매출상세",
          columns: [
            {
              field: "salesAmt",
              headerText: "매출금액",
              type: "number",
              format: "N",
              minWidth: 16,
              width: 90,
              textAlign: "right",
            },
            {
              field: "salesIncomAmt",
              headerText: "매출이입",
              type: "number",
              format: "N",
              minWidth: 16,
              width: 90,
              textAlign: "right",
            },
            {
              field: "salesCreditDefAmt",
              headerText: "대내후불이입",
              type: "number",
              format: "N",
              minWidth: 16,
              width: 90,
              textAlign: "right",
            },
          ],
        },
        {
          field: "salesTotAmt",
          headerText: "매출합계",
          type: "number",
          format: "N",
          minWidth: 16,
          width: 90,
          textAlign: "right",
        },
        {
          field: "payTotAmt",
          headerText: "정산합계",
          type: "number",
          format: "N",
          minWidth: 16,
          width: 90,
          textAlign: "right",
        },
        {
          field: "unPayAmt",
          headerText: "미정산",
          type: "number",
          format: "N",
          minWidth: 16,
          width: 90,
          textAlign: "right",
        },
        {
          headerText: "정산상세",
          columns: [
            {
              field: "payCash",
              headerText: "현금",
              type: "number",
              format: "N",
              minWidth: 16,
              width: 90,
              textAlign: "right",
            },
            {
              field: "payCard",
              headerText: "카드",
              type: "number",
              format: "N",
              minWidth: 16,
              width: 90,
              textAlign: "right",
            },
            {
              field: "payDeposit",
              headerText: "선수금",
              type: "number",
              format: "N",
              minWidth: 16,
              width: 90,
              textAlign: "right",
            },
            {
              field: "payPrecard",
              headerText: "선불카드",
              type: "number",
              format: "N",
              minWidth: 16,
              width: 90,
              textAlign: "right",
            },
            {
              field: "payGiftTotal",
              headerText: "상품권",
              type: "number",
              format: "N",
              minWidth: 16,
              width: 90,
              textAlign: "right",
            },
            {
              field: "payTrans",
              headerText: "계좌이체",
              type: "number",
              format: "N",
              minWidth: 16,
              width: 90,
              textAlign: "right",
            },
            {
              field: "payCredit",
              headerText: "미수",
              type: "number",
              format: "N",
              minWidth: 16,
              width: 90,
              textAlign: "right",
            },
            {
              field: "payCreditDef",
              headerText: "대내후불",
              type: "number",
              format: "N",
              minWidth: 16,
              width: 90,
              textAlign: "right",
            },
          ],
        },
      ],
      storeListGridAggrigates: [
        {
          columns: [
            {
              field: "storeName",
              aggregationType: "TotalCaption",
              customAggregate: "합계",
            },
            {
              field: "totSalesAmt",
              aggregationType: "TotalSum",
            },
          ],
        },
      ],
      dailyCloseSearchOptions: {
        bsnDate: null,
      },
      storeList: [],
      checkItemList: [],
      closeCancelHistoryList: [],
      monthlyCloseStatusList: [],
      unsettledList: [],
      storeListGridEditSettings: {
        allowEditing: false,
        allowAdding: false,
        allowDeleting: false,
        mode: "Batch",
        showConfirmDialog: false,
      },
      tabSelectIndex: 0,
      cancelReason: null,
      showDailyCloseSendSmsPopup: false,
      provides: [Edit, Resize, ForeignKey, Aggregate],
      dailyCloseCancelValidationRules: {
        cancelResn: {
          required: true,
        },
      },
    };
  },
  computed: {
    isPopupOpened() {
      return (
          this.showDailyCloseSendSmsPopup
      );
    },
    checkItemListGridOptions() {
      return {
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
        columns: [
          {
            field: "checkItem",
            headerText: "체크항목",
            allowEditing: false,
            minWidth: 16,
            width: 180,
            textAlign: "left",
            type: "string",
          },
          {
            field: "returnResultName",
            headerText: "결과",
            allowEditing: false,
            minWidth: 16,
            width: 90,
            textAlign: "left",
            type: "string",
          },
          {
            field: "returnMessage",
            headerText: "메시지",
            allowEditing: false,
            minWidth: 16,
            textAlign: "left",
            type: "string",
          },
        ],
      };
    },
    closeCancelHistoryListGridOptions() {
      return {
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
        columns: [
          {
            field: "closeDivName",
            headerText: "마감구분",
            allowEditing: false,
            minWidth: 16,
            width: 90,
            textAlign: "left",
            type: "string",
          },
          {
            field: "rank",
            headerText: "순번",
            allowEditing: false,
            minWidth: 16,
            width: 60,
            textAlign: "left",
            type: "string",
          },
          {
            field: "insertName",
            headerText: "작업자",
            allowEditing: false,
            minWidth: 16,
            width: 90,
            textAlign: "left",
            type: "string",
          },
          {
            field: "insertDt",
            headerText: "작업일시",
            allowEditing: false,
            minWidth: 16,
            width: 150,
            textAlign: "left",
            type: "string",
          },
          {
            field: "cancelResn",
            headerText: "취소사유",
            allowEditing: false,
            minWidth: 16,
            textAlign: "left",
            type: "string",
          },
        ],
      };
    },
    monthlyCloseStatusListGridOptions() {
      return {
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
        columns: [
          {
            field: "bsnDate",
            headerText: "영업일자",
            allowEditing: false,
            minWidth: 16,
            width: 90,
            textAlign: "center",
            type: "string",
          },
          {
            field: "dwName",
            headerText: "요일",
            allowEditing: false,
            minWidth: 16,
            width: 60,
            textAlign: "center",
            type: "string",
          },
          {
            field: "bsnName",
            headerText: "골프 영업구분",
            allowEditing: false,
            minWidth: 16,
            width: 100,
            textAlign: "center",
            type: "string",
          },
          {
            field: "roomBsnCode",
            headerText: "객실 영업구분",
            allowEditing: false,
            minWidth: 16,
            width: 100,
            textAlign: "center",
            type: "string",
            isCommonCodeField: true,
            groupCode: "ROOM_BSN_CODE",
          },
          {
            field: "closeDiv",
            headerText: "마감여부",
            allowEditing: false,
            type: "boolean",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            editType: "booleanedit",
            displayAsCheckBox: true,
          },
          {
            field: "insertName",
            headerText: "작업자",
            allowEditing: false,
            minWidth: 16,
            width: 90,
            textAlign: "left",
            type: "string",
          },
          {
            field: "insertDt",
            headerText: "마감일시",
            allowEditing: false,
            minWidth: 16,
            width: 150,
            textAlign: "left",
            type: "string",
          },
        ],
      };
    },
    unsettledListGridOptions() {
      return {
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
        columns: [
          {
            field: "bsnName",
            headerText: "영업장명",
            allowEditing: false,
            minWidth: 16,
            width: 90,
            textAlign: "left",
            type: "string",
          },
          {
            field: "storeName",
            headerText: "매장명",
            allowEditing: false,
            minWidth: 16,
            width: 90,
            textAlign: "left",
            type: "string",
          },
          {
            field: "slipNo",
            headerText: "전표번호",
            allowEditing: false,
            minWidth: 16,
            width: 90,
            textAlign: "left",
            type: "string",
          },
          {
            field: "productName",
            headerText: "상품명",
            allowEditing: false,
            minWidth: 16,
            width: 180,
            textAlign: "left",
            type: "string",
          },
          {
            field: "saleQty",
            headerText: "수량",
            allowEditing: false,
            type: "number",
            format: "N",
            minWidth: 16,
            width: 60,
            textAlign: "right",
          },
          {
            field: "totAmt",
            headerText: "매출금액",
            allowEditing: false,
            minWidth: 16,
            width: 90,
            textAlign: "right",
            type: "number",
            format: "N",
          },
          {
            field: "visitName",
            headerText: "지불자명",
            allowEditing: false,
            minWidth: 16,
            width: 90,
            textAlign: "left",
            type: "string",
          },
          {
            field: "lockerNo",
            headerText: "락카",
            allowEditing: false,
            minWidth: 16,
            width: 60,
            textAlign: "left",
            type: "string",
          },
          {
            field: "slipMemo",
            headerText: "전표메모",
            allowEditing: false,
            minWidth: 16,
            width: 240,
            textAlign: "left",
            type: "string",
          },
          {
            field: "payFlag",
            headerText: "정산",
            allowEditing: false,
            minWidth: 16,
            width: 90,
            textAlign: "left",
            type: "string",
          },
          {
            field: "insertDt",
            headerText: "등록일시",
            allowEditing: false,
            minWidth: 16,
            width: 150,
            textAlign: "left",
            type: "string",
          },
          {
            field: "insertName",
            headerText: "등록자",
            allowEditing: false,
            minWidth: 16,
            width: 90,
            textAlign: "left",
            type: "string",
          },
        ],
        aggregates: [
          {
            columns: [
              {
                field: "saleQty",
                aggregationType: "TotalCaption",
                customAggregate: "합계",
              },
              {
                field: "totAmt",
                aggregationType: "TotalSum",
              },
            ],
          },
        ],
      };
    },
  },
  methods: {
    validateFormRefs,
    numberWithCommas,
    getDayOfWeekCaption,
    getDayOfWeekCaptionColor,
    getWeekdayWeekendCaption,
    getWeekdayWeekendCaptionColor,
    onDailyCloseCancelPopupOpen() {
      this.cancelReason = null;
      this.$refs.dailyCloseCancelPopup.show();
    },
    onDailyCloseCancelPopupClose() {
      this.$refs.dailyCloseCancelPopup.hide();
    },
    onTabSelected(args) {
      this.tabSelectIndex = args.selectedIndex;
    },
    totSalesAmtValueAccessor(field, data) {
      if (data.totSalesAmt) {
        return Number(data.totSalesAmt).toLocaleString();
      } else {
        return "-";
      }
    },
    async onDateChange(args) {
      if (args.data) {
        await this.onSearchButtonClick();
      }
    },
    storeListGridQueryCellInfo(args) {
      const {
        cell,
        column: {
          field,
        },
        data,
      } = args;

      if (data[field] === 0) {
        cell.innerText = "-";
      }

      if (field === "unPayAmt") {
        if (data[field] !== 0) {
          cell.style.color = "red";
        }
      }
    },
    async storeListGridRowSelected(args) {
      const {
        data: {
          closeId,
          bsnDate,
          storeCode,
        },
      } = args;
      console.log("closeId===>",closeId);
      if (closeId === null || closeId === undefined) {
        this.closeCancelHistoryList = [];
      } else {
        await this.getDailyCloseCancelHistory(closeId);
      }

      if (
          storeCode === null ||
          storeCode === undefined ||
          bsnDate === null ||
          bsnDate === undefined
      ) {
        this.monthlyCloseStatusList = [];
      } else {
        await this.getDailyCloseMonthlyStatus(bsnDate, storeCode);
      }
    },
    async onSearchButtonClick() {
      if (!this.dailyCloseSearchOptions.bsnDate) {
        return this.errorToast(
            this.$t("main.validationMessage.requiredMessage", ["영업일자"])
        );
      }

      await Promise.all([
        this.getDailyCloseStoreList(),
        this.getDailyCloseCheckList(),
        this.getDailyCloseUnsettledList(this.dailyCloseSearchOptions.bsnDate)
      ]);
    },
    async onDailyCloseCancelButtonClick() {
      for (let i = 0; i < this.storeList.length; i++) {
        if (
            this.storeList[i].closeDiv === "N" ||
            this.storeList[i].closeDiv === "C"
        ) {
          this.errorToast(
              this.dailyCloseSearchOptions.bsnDate +
              " (" +
              getDayOfWeekCaption(
                  new Date(this.dailyCloseSearchOptions.bsnDate)
              ) +
              ")" +
              " 프론트 일마감이 되어 있지 않습니다."
          );
          return;
        }
      }

      if (!this.validateFormRefs(this.dailyCloseCancelValidationRules)) {
        return;
      }

      if (
          (await this.confirm(
              this.dailyCloseSearchOptions.bsnDate +
              " (" +
              getDayOfWeekCaption(
                  new Date(this.dailyCloseSearchOptions.bsnDate)
              ) +
              ")" +
              " 프론트 일마감을 취소 하시겠습니까?"
          )) === false
      ) {
        return;
      }

      await this.putDailyCloseCancel({
        bsnDate: this.dailyCloseSearchOptions.bsnDate,
        cancelResn: this.cancelReason,
      });
    },
    async onDailyCloseExecuteButtonClick() {
      if (this.unsettledList.length > 0) {
        this.errorToast("미정산 전표가 존재합니다. 마감이 불가능합니다");
        return;
      }

      // let totSalesAmt = 0;

      for (let i = 0; i < this.storeList.length; i++) {
        if (
            this.storeList[i].closeDiv === "Y" ||
            this.storeList[i].closeDiv === "A"
        ) {
          this.errorToast(
              this.dailyCloseSearchOptions.bsnDate +
              " (" +
              getDayOfWeekCaption(
                  new Date(this.dailyCloseSearchOptions.bsnDate)
              ) +
              ")" +
              " 프론트 일마감이 이미 완료되었습니다."
          );
          return;
        }

        // totSalesAmt += this.storeList[i].totSalesAmt;
      }

      if (
          (await this.confirm(
              "마감일자 : " + this.dailyCloseSearchOptions.bsnDate +
              " (" +
              getDayOfWeekCaption(
                  new Date(this.dailyCloseSearchOptions.bsnDate)
              ) +
              ")<br>" +
              // "매출금액 : " + (totSalesAmt === 0 ? "<div style='font-weight: bold; color: red; display: inline;'>" : "") + numberWithCommas(totSalesAmt) + "원" + (totSalesAmt === 0 ? "</div>" : "") + "<br><br>" +
              " 프론트 일마감을 진행 하시겠습니까?"
          )) === false
      ) {
        return;
      }

      await this.putDailyCloseExecute();
    },
    async getDailyCloseUnsettledList(bsnDate) {
      this.$refs.unsettledListGrid.clearFiltering();

      const response = await getDailyCloseUnsettledList(bsnDate);
      this.unsettledList = response.value.unsettledList;

      this.unsettledListLength = response.value.unsettledList.length;
    },
    async getDailyCloseMonthlyStatus(bsnDate, storeCode) {
      const response = await getDailyCloseMonthlyStatus(bsnDate, storeCode);
      this.monthlyCloseStatusList = response.value.monthlyStatusList;
    },
    async getDailyCloseCancelHistory(closeId) {
      const response = await getDailyCloseCancelHistory(closeId);
      this.closeCancelHistoryList = response.value.cancelHistoryList;
      console.log(this.closeCancelHistoryList);
    },
    async getDailyCloseCheckList() {
      const response = await getDailyCloseCheckList();
      this.checkItemList = response.value.checkList;
    },
    async getDailyCloseStoreList() {
      const bsnDate = this.dailyCloseSearchOptions.bsnDate;

      const response = await getDailyCloseStoreList(bsnDate);
      this.storeList = response.value.storeList.map(item => ({
        ...item,
        payGiftTotal: item.payGift + item.payGroupGift,
      }));

      if (!(this.storeList.length > 0)) {
        this.monthlyCloseStatusList = [];
        this.closeCancelHistoryList = [];
      }
    },
    async putDailyCloseExecute() {
      const response = await putDailyCloseExecute(this.dailyCloseSearchOptions.bsnDate);
      if (response.value.status === "FAIL") {
        this.checkItemList = response.value.checkList;
        this.errorToast(
            '"체크항목" 검사중 문제가 발생했습니다. 확인후 진행바랍니다'
        );
      } else {
        this.checkItemList = response.value.checkList;

        await Promise.all([
          this.getDailyCloseStoreList(),
          // this.getDailyCloseCheckList(),
          this.getDailyCloseUnsettledList(
              this.dailyCloseSearchOptions.bsnDate
          )
        ]);

        this.monthlyCloseStatusList = [];
        this.closeCancelHistoryList = [];

        this.infoToast("마감이 정상적으로 완료되었습니다");
      }
    },
    async putDailyCloseCancel(data) {
      await putDailyCloseCancel(data);

      await Promise.all([
        this.getDailyCloseStoreList(),
        this.getDailyCloseCheckList(),
        this.getDailyCloseUnsettledList(this.dailyCloseSearchOptions.bsnDate),
      ]);

      this.monthlyCloseStatusList = [];
      this.closeCancelHistoryList = [];

      this.infoToast("마감이 정상적으로 취소되었습니다");

      this.onDailyCloseCancelPopupClose();
    },
    unsettledListGridActionComplete(args) {
      if (args.requestType === "filtering" && args.action === "clearFilter") {
        this.unsettledListLength = this.unsettledList.length;
      } else if (args.requestType === "filtering") {
        this.unsettledListLength = this.$refs.unsettledListGrid.getFilteredRecords().length;
      }
    },
    async onDailyCloseSmsSendButtonClick() {
      if (this.showDailyCloseSendSmsPopup) {
        return;
      }
      const { data: closeDiv } = await GolfErpAPI.getCloseDiv({
        bsnDate: this.dailyCloseSearchOptions.bsnDate,
        storeCode: "FRONT",
      });
      if (closeDiv !== "Y") {
        const bsnDate = moment(this.dailyCloseSearchOptions.bsnDate).format("YYYYMMDD");
        const requestList = [
          "SP_CLOSE_PART_DIV",
          "SP_CLOSE_SALES_STORE",
          "SP_CLOSE_ACMTL_BASIC",
          "SP_CLOSE_ACMTL_SALES",
          "SP_CLOSE_CARD",
          "SP_CLOSE_PAYMT",
        ];
        const inParams = [bsnDate];

        let request = [];
        requestList.map((item) => request.push({ name: item, inParams }));

        await GolfErpAPI.putStoredProcedureExecute({
          storedProcedureInfos: request,
        });
      }
      this.showDailyCloseSendSmsPopup = true;
      this.$nextTick(() => {
        this.$refs.dailyCloseSendSmsPopup.showPopup(
            this.dailyCloseSearchOptions.bsnDate,
            closeDiv
        );
      });
    },
    dailyCloseSendSmsPopupClosed() {
      this.showDailyCloseSendSmsPopup = false;
    },
    async onDailyClosePenltyCancelPopupOpen() {
      if (!(await this.confirm("일마감시 생성된 위약이 삭제됩니다.<br>" + this.dailyCloseSearchOptions.bsnDate + " 일자의 위약 취소를 실행하시겠습니까?"))) {
        return;
      }

      await GolfErpAPI.deleteDailyCloseCreatePenlty(this.dailyCloseSearchOptions.bsnDate);
    },
    async putCloseExecuteByBsnDate() {
      // 일마감 v2 API 테스트용.(작업 보류)
      await GolfErpAPI.putCloseExecuteByBsnDate(this.dailyCloseSearchOptions.bsnDate);
    }
  },
};
</script>

<template>
  <div>
    <ejs-dialog
        ref="payPopup"
        :header="`정산`"
        :allowDragging="true"
        :showCloseIcon="true"
        width="1180"
        height="600"
        :animationSettings="{ effect: 'None' }"
        :isModal="true"
        :closeOnEscape="!this.payProcessing"
        @open="handleDialogOpen"
        @close="handleDialogClose"
        @overlayClick="handleDialogOverlayClick"
    >
      <div id="payPopup" class="window pay">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <div class="article-left">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-01">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">결제 정보</div>
                      </div>
                    </div>
                    <div class="section-body">
                      <div class="body-data">
                        <div class="data-outer">
                          <div class="data-inner">
                            <div v-if="salesDiv === 'SALES'">
                              <ul class="data-content">
                                <li class="field">
                                  <!-- 필수 : required -->
                                  <div class="title">매출구분</div>
                                  <div class="content">
                                    <ul class="row">
                                      <li class="item form-group">
                                        {{
                                          commonCodesGetComName(
                                              "SALES_DIV",
                                              salesDiv
                                          )
                                        }}
                                        <div class="validation">
                                          <div class="e-tip-content"></div>
                                          <div class="e-tip-top"></div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                                <li class="field">
                                  <!-- 필수 : required -->
                                  <div class="title">지불자</div>
                                  <div class="content">
                                    <ul class="row">
                                      <li class="item form-group">
                                        {{ popupData.visitName }} ({{ popupData.lockerNo }})
                                        <div class="validation">
                                          <div class="e-tip-content"></div>
                                          <div class="e-tip-top"></div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                                <li class="field">
                                  <!-- 필수 : required -->
                                  <div class="title">선불/후불</div>
                                  <div class="content">
                                    <ul class="row">
                                      <li class="item form-group">
                                        {{
                                          commonCodesGetComName(
                                              "PRPAY_FRPY_DIV",
                                              popupData.prpayFrpyDiv
                                          )
                                        }}
                                        <div class="validation">
                                          <div class="e-tip-content"></div>
                                          <div class="e-tip-top"></div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                                <li class="field">
                                  <!-- 필수 : required -->
                                  <div class="title">매출금액</div>
                                  <div class="content">
                                    <ul class="row">
                                      <li
                                          class="item form-group"
                                          style="text-align: right"
                                      >
                                        {{
                                          payPopupInfo.saleAmt !== undefined
                                              ? payPopupInfo.saleAmt.toLocaleString()
                                              : ""
                                        }}
                                        <div class="validation">
                                          <div class="e-tip-content"></div>
                                          <div class="e-tip-top"></div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                                <li class="field">
                                  <!-- 필수 : required -->
                                  <div class="title">할인금액</div>
                                  <div class="content">
                                    <ul class="row">
                                      <li
                                          class="item form-group"
                                          style="text-align: right"
                                      >
                                        {{
                                          payPopupInfo.dcAmt !== undefined
                                              ? payPopupInfo.dcAmt.toLocaleString()
                                              : ""
                                        }}
                                        <div class="validation">
                                          <div class="e-tip-content"></div>
                                          <div class="e-tip-top"></div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                                <li class="field">
                                  <!-- 필수 : required -->
                                  <div class="title">정산금액</div>
                                  <div class="content">
                                    <ul class="row">
                                      <li
                                          class="item form-group"
                                          style="text-align: right"
                                      >
                                        {{
                                          payPopupInfo.calcuAmt !== undefined
                                              ? payPopupInfo.calcuAmt.toLocaleString()
                                              : ""
                                        }}
                                        <div class="validation">
                                          <div class="e-tip-content"></div>
                                          <div class="e-tip-top"></div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                                <li class="field" v-if="storeCode === 'CFRONT'">
                                  <!-- 필수 : required -->
                                  <div class="title" style="background-color: yellow;">정산일자</div>
                                  <div class="content" style="background-color: yellow;">
                                    <ul class="row">
                                      <li class="item form-group date" style="width: 150px;">
                                        <div class="form">
                                          <input-date
                                              ref="payDatePicker"
                                              format="YYYY-MM-DD"
                                              type="lookup-condition"
                                              v-model="payDate"
                                              :notClear="true"
                                              :customStyle="{
                                              fontSize: '16px',
                                              fontFamily: 'NanumSquare-Regular',
                                            }"
                                          />
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div v-if="salesDiv === 'CREDIT'">
                              <ul class="data-content">
                                <li class="field">
                                  <!-- 필수 : required -->
                                  <div class="title">매출구분</div>
                                  <div class="content">
                                    <ul class="row">
                                      <li class="item form-group">
                                        {{
                                          commonCodesGetComName(
                                              "SALES_DIV",
                                              salesDiv
                                          )
                                        }}
                                        <div class="validation">
                                          <div class="e-tip-content"></div>
                                          <div class="e-tip-top"></div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                                <li class="field">
                                  <!-- 필수 : required -->
                                  <div class="title">지불자</div>
                                  <div class="content">
                                    <ul class="row">
                                      <li class="item form-group">
                                        {{ popupData.visitName }} /
                                        {{ popupData.lockerNo }}
                                        <div class="validation">
                                          <div class="e-tip-content"></div>
                                          <div class="e-tip-top"></div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                                <li class="field">
                                  <!-- 필수 : required -->
                                  <div class="title">선불/후불</div>
                                  <div class="content">
                                    <ul class="row">
                                      <li class="item form-group">
                                        {{
                                          commonCodesGetComName(
                                              "PRPAY_FRPY_DIV",
                                              popupData.prpayFrpyDiv
                                          )
                                        }}
                                        <div class="validation">
                                          <div class="e-tip-content"></div>
                                          <div class="e-tip-top"></div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                                <li class="field">
                                  <!-- 필수 : required -->
                                  <div class="title">미수금액</div>
                                  <div class="content">
                                    <ul class="row">
                                      <li
                                          class="item form-group"
                                          style="text-align: right"
                                      >
                                        {{
                                          payPopupInfo.saleAmt !== undefined
                                              ? payPopupInfo.saleAmt.toLocaleString()
                                              : ""
                                        }}
                                        <div class="validation">
                                          <div class="e-tip-content"></div>
                                          <div class="e-tip-top"></div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                                <li class="field">
                                  <!-- 필수 : required -->
                                  <div class="title">정산금액</div>
                                  <div class="content">
                                    <ul class="row">
                                      <li
                                          class="item form-group"
                                          style="text-align: right"
                                      >
                                        {{
                                          payPopupInfo.calcuAmt !== undefined
                                              ? payPopupInfo.calcuAmt.toLocaleString()
                                              : ""
                                        }}
                                        <div class="validation">
                                          <div class="e-tip-content"></div>
                                          <div class="e-tip-top"></div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                                <li class="field">
                                  <!-- 필수 : required -->
                                  <div class="title">회입일자</div>
                                  <div class="content">
                                    <ul class="row">
                                      <li class="item form-group date">
                                        <div class="form">
                                          <input-date
                                              ref="returnDatePicker"
                                              format="YYYY-MM-DD"
                                              type="lookup-condition"
                                              v-model="returnDateValueModel"
                                              :notClear="true"
                                          />
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                                <li class="field">
                                  <!-- 필수 : required -->
                                  <div class="title">비고</div>
                                  <div class="content">
                                    <ul class="row">
                                      <li class="item form-group textarea">
                                        <div class="form">
                                          <input-textarea
                                              ref="payCreditReturnRemarks"
                                              v-model="creditReturnRemarks"
                                              placeholder="비고 내용을 입력해 주세요"
                                          />
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div v-if="salesDiv === 'DEPOSIT'">
                              <ul class="data-content">
                                <li class="field">
                                  <!-- 필수 : required -->
                                  <div class="title">매출구분</div>
                                  <div class="content">
                                    <ul class="row">
                                      <li class="item form-group">
                                        {{
                                          commonCodesGetComName(
                                              "SALES_DIV",
                                              salesDiv
                                          )
                                        }}
                                        <div class="validation">
                                          <div class="e-tip-content"></div>
                                          <div class="e-tip-top"></div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                                <li class="field">
                                  <!-- 필수 : required -->
                                  <div class="title">지불자</div>
                                  <div class="content">
                                    <ul class="row">
                                      <li class="item form-group">
                                        {{ payerName }}
                                        <div class="validation">
                                          <div class="e-tip-content"></div>
                                          <div class="e-tip-top"></div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                                <li class="field">
                                  <!-- 필수 : required -->
                                  <div class="title">면/과세</div>
                                  <div class="content">
                                    <ul class="row">
                                      <li class="item form-group">
                                        {{
                                          commonCodesGetComName(
                                              "NOTAX_CODE",
                                              popupData.notaxCode
                                          )
                                        }}
                                        <div class="validation">
                                          <div class="e-tip-content"></div>
                                          <div class="e-tip-top"></div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                  </section>
                </div>
                <div class="article-right">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-02">
                    <div class="section-caption">
                      <div class="caption-navigation">
                        <ejs-tab
                            ref="payDivTap"
                            :items="payDivTabList"
                            :showCloseButton="false"
                            heightAdjustMode="Auto"
                            overflowMode="Popup"
                            @selected="tabSelected"
                        />
                      </div>
                    </div>
                    <div class="section-body">
                      <article class="body-article">
                        <section class="article-section section-0201">
                          <div class="section-header">
                            <div class="header-right">
                              <ul class="ui-check">
                                <li style="padding: 0 13px 0 0">
                                  <label>
                                    <input
                                        type="checkbox"
                                        v-model="printFlag"
                                        :disabled="['CREDIT','CREDIT_DEF','SELF'].includes(payDiv)"
                                    />
                                    <div class="i-item">고객용 영수증 발행</div>
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="section-body">
                            <article class="body-article">
                              <div
                                  class="article-left"
                                  style="width: calc(100% - 87px)"
                              >
                                <div id="card" class="newPay" v-show="payDiv === 'CARD'">
                                  <div
                                      class="item"
                                      v-if="salesDiv !== 'DEPOSIT'"
                                  >
                                    <div class="title">미정산액</div>
                                    <div class="content">
                                      <div class="item">
                                        <input-number
                                            v-model="payPopupInfo.noPayAmt"
                                            :disabled="true"
                                            :allow-minus="true"
                                            :useCommonStyles="true"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="item">
                                    <div class="title" style="font-weight: bold">결제금액</div>
                                    <div class="content">
                                      <div class="item">
                                        <input-number
                                            ref="payCardInfoPayAmt"
                                            v-model="payAmt"
                                            :allow-minus="true"
                                            :disabled="salesDiv === 'DEPOSIT' || (salesDiv === 'CREDIT' && (popupData.payIds || []).length > 0)"
                                            :propMaxLength="13"
                                            :useCommonStyles="true"
                                            @keydown.enter.native="focusToCardDivideTerm"
                                        />
                                      </div>
                                      <erp-button
                                          button-div="GET"
                                          class="takeTotAmtButton"
                                          :disabled="payProcessing"
                                          :use-syncfusion-component-style="false"
                                          @click="rePayInfoSetting"
                                          v-if="salesDiv === 'SALES'"
                                      >
                                        전체금액
                                      </erp-button>
                                    </div>
                                  </div>
                                  <div class="item">
                                    <div class="title">할부개월</div>
                                    <div class="content">
                                      <div class="item">
                                        <input-number
                                            ref="payCardInfoDivideTerm"
                                            v-model="payCardInfo.divideTerm"
                                            :min="0"
                                            :useCommonStyles="true"
                                            @keydown.enter.native="pay(payDiv)"
                                        />
                                      </div>
                                      <div class="item">
                                        개월
                                      </div>
                                    </div>
                                  </div>
                                  <div class="item">
                                    <div class="title">
                                      기승인<br />
                                      (단말기승인)
                                    </div>
                                    <div class="content">
                                      <ul class="ui-check">
                                        <li>
                                          <label>
                                            <input
                                                ref="payCardInfoApprovalFlag"
                                                type="checkbox"
                                                v-model="payCardInfo.approvalFlag"
                                                :useCommonStyles="true"
                                                @change="payCardInfoApprovalFlagChanged"
                                            />
                                            <div class="i-item">사용</div>
                                          </label>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="item dropdown">
                                    <div class="title">카드번호</div>
                                    <div class="content">
                                      <div class="item">
                                        <input-text
                                            ref="payCardInfoCardNo"
                                            v-model="payCardInfo.cardNo"
                                            placeholder="입력하세요"
                                            :disabled="!payCardInfo.approvalFlag"
                                            :useCommonStyles="true"
                                            @keypress.enter.native="focusToCardPurchase"
                                        />
                                      </div>
                                      <div class="item">
                                        <ejs-dropdownlist
                                            ref="payCardInfoPurcId"
                                            v-model="payCardInfo.purchaseId"
                                            placeholder="선택"
                                            :enabled="payCardInfo.approvalFlag"
                                            :dataSource="confCardList"
                                            :allowFiltering="false"
                                            :fields="{
                                            text: 'purchaseName',
                                            value: 'purchaseId',
                                          }"
                                            cssClass="body-data-dropdown"
                                            @change="onPayCardInfoCardNoChanged"
                                            @keypress.enter="focusToCardValidTerm"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="item" style="width: 290px">
                                    <div class="title">유효기간</div>
                                    <div class="content">
                                      <div class="item">
                                        <ejs-maskedtextbox
                                            ref="payCardInfoValidTerm"
                                            class="e-input"
                                            type="text"
                                            v-model="payCardInfo.validTerm"
                                            placeholder="MM/YY"
                                            mask="00/00"
                                            :enabled="payCardInfo.approvalFlag"
                                            @keypress.enter.native="focusToCardApprovalNo"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="item" style="width: 290px">
                                    <div class="title">승인번호</div>
                                    <div class="content">
                                      <div class="item">
                                        <input-text
                                            ref="payCardInfoApprovalNo"
                                            v-model="payCardInfo.approvalNo"
                                            placeholder="입력하세요"
                                            :disabled="!payCardInfo.approvalFlag"
                                            :useCommonStyles="true"
                                            @keypress.enter.native="pay(payDiv)"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div id="cash" class="newPay" v-show="payDiv === 'CASH'">
                                  <div
                                      class="item"
                                      v-if="salesDiv !== 'DEPOSIT'"
                                  >
                                    <div class="title">미정산액</div>
                                    <div class="content">
                                      <div class="item">
                                        <input-number
                                            v-model="payPopupInfo.noPayAmt"
                                            :disabled="true"
                                            :allow-minus="true"
                                            :useCommonStyles="true"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="item">
                                    <div class="title" style="font-weight: bold">결제금액</div>
                                    <div class="content">
                                      <div class="item">
                                        <input-number
                                            ref="payCashInfoPayAmt"
                                            v-model="payAmt"
                                            :allow-minus="true"
                                            :disabled="salesDiv === 'DEPOSIT' || (salesDiv === 'CREDIT' && (popupData.payIds || []).length > 0)"
                                            :propMaxLength="13"
                                            :useCommonStyles="true"
                                            @keydown.enter.native="pay(payDiv)"
                                        />
                                      </div>
                                      <erp-button
                                          button-div="GET"
                                          class="takeTotAmtButton"
                                          :use-syncfusion-component-style="false"
                                          :disabled="payProcessing"
                                          @click="rePayInfoSetting"
                                          v-if="salesDiv === 'SALES'"
                                      >
                                        전체금액
                                      </erp-button>
                                    </div>
                                  </div>
                                  <div class="item">
                                    <div class="title">
                                      현금영수증<br />
                                      발행
                                    </div>
                                    <div class="content">
                                      <ul class="ui-check">
                                        <li>
                                          <label>
                                            <input
                                                ref="payCashInfoProofKind"
                                                type="radio"
                                                v-model="payCashInfo.proofKind"
                                                value=""
                                                :useCommonStyles="true"
                                                :disabled="salesDiv === 'DEPOSIT'"
                                            />
                                            <div class="i-item">미발급</div>
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                                ref="payCashInfoProofKind"
                                                type="radio"
                                                v-model="payCashInfo.proofKind"
                                                value="VOL"
                                                :useCommonStyles="true"
                                            />
                                            <div class="i-item">자진발급</div>
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                                ref="payCashInfoProofKind"
                                                type="radio"
                                                v-model="payCashInfo.proofKind"
                                                value="FIT"
                                                :useCommonStyles="true"
                                            />
                                            <div class="i-item">소득공제용</div>
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                                ref="payCashInfoProofKind"
                                                type="radio"
                                                v-model="payCashInfo.proofKind"
                                                value="CPR"
                                                :useCommonStyles="true"
                                            />
                                            <div class="i-item">사업자 지출증빙</div>
                                          </label>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="item">
                                    <div class="title">
                                      현금영수증<br />
                                      증빙번호
                                    </div>
                                    <div class="content">
                                      <div class="item">
                                        <input-text
                                            ref="payCashInfoProofNo"
                                            v-model="payCashInfo.proofNo"
                                            placeholder="입력하세요"
                                            :disabled="
                                            van === 'SMARTRO' ||
                                            !payCashInfo.proofKind ||
                                            payCashInfo.proofKind === 'VOL' ||
                                            payCashInfo.cardProof"
                                            :useCommonStyles="true"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="item" v-if="van === 'NICE'">
                                    <div class="title">카드증빙</div>
                                    <div class="content">
                                      <ul class="ui-check">
                                        <li>
                                          <label>
                                            <input
                                                ref="payCardInfoApprovalFlag"
                                                type="checkbox"
                                                :disabled="!payCashInfo.proofKind || payCashInfo.proofKind === 'VOL'"
                                                v-model="payCashInfo.cardProof"
                                                :useCommonStyles="true"
                                                @change="payCashReceiptProofCardFlagChanged"
                                            />
                                            <div class="i-item">사용</div>
                                          </label>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="item">
                                    <div class="title">받은금액</div>
                                    <div class="content">
                                      <div class="item">
                                        <input-number
                                            id="payCashInfoCashReceived"
                                            ref="payCashInfoCashReceived"
                                            v-model="cashReceived"
                                            :allow-minus="true"
                                            :min="0"
                                            :propMaxLength="13"
                                            :useCommonStyles="true"
                                            :disabled="salesDiv === 'DEPOSIT'"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="item">
                                    <div class="title">거스름돈</div>
                                    <div class="content">
                                      <div class="item">
                                        <input-number
                                            :value="
                                            cashReceived - payAmt < 0
                                              ? 0
                                              : cashReceived - payAmt
                                          "
                                            :disabled="true"
                                            :useCommonStyles="true"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

<!--                                -->
                                <div id="easy" class="newPay" v-show="payDiv === 'EASY_PAY'">
                                  <div
                                      class="item"
                                      v-if="salesDiv !== 'DEPOSIT'"
                                  >
                                    <div class="title">미정산액</div>
                                    <div class="content">
                                      <div class="item">
                                        <input-number
                                            v-model="payPopupInfo.noPayAmt"
                                            :disabled="true"
                                            :allow-minus="true"
                                            :useCommonStyles="true"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="item">
                                    <div class="title" style="font-weight: bold">결제금액</div>
                                    <div class="content">
                                      <div class="item">
                                        <input-number
                                            ref="payEasyInfoPayAmt"
                                            v-model="payAmt"
                                            :allow-minus="true"
                                            :disabled="salesDiv === 'DEPOSIT' || (salesDiv === 'CREDIT' && (popupData.payIds || []).length > 0)"
                                            :propMaxLength="13"
                                            :useCommonStyles="true"
                                            @keydown.enter.native="focusToEasyDivideTerm"
                                        />
                                      </div>
                                      <erp-button
                                          button-div="GET"
                                          class="takeTotAmtButton"
                                          :disabled="payProcessing"
                                          :use-syncfusion-component-style="false"
                                          @click="rePayInfoSetting"
                                          v-if="salesDiv === 'SALES'"
                                      >
                                        전체금액
                                      </erp-button>
                                    </div>
                                  </div>
                                  <div class="item">
                                    <div class="title">할부개월</div>
                                    <div class="content">
                                      <div class="item">
                                        <input-number
                                            ref="payEasyInfoDivideTerm"
                                            v-model="payEasyInfo.divideTerm"
                                            :min="0"
                                            :useCommonStyles="true"
                                            @keydown.enter.native="pay(payDiv)"
                                        />
                                      </div>
                                      <div class="item">
                                        개월
                                      </div>
                                    </div>
                                  </div>
                                </div>
<!--                                -->
                                <section class="article-section section-020101" v-show="payDiv !== 'CARD' && payDiv !== 'CASH' && payDiv !== 'EASY_PAY'">
                                  <div
                                      class="section-body"
                                      style="border: none; margin: -18px"
                                  >
                                    <div id="credit" v-show="payDiv === 'CREDIT'">
                                      <ul class="lookupDetail-condition">
                                        <li class="field">
                                          <div class="title">미정산액</div>
                                          <ul class="content">
                                            <li
                                                class="item"
                                                style="width: 126px"
                                            >
                                              <input-number
                                                  v-model="payPopupInfo.noPayAmt"
                                                  :disabled="true"
                                                  :allow-minus="true"
                                              />
                                            </li>
                                          </ul>
                                        </li>
                                        <li class="field">
                                          <div class="title">결제금액</div>
                                          <ul class="content">
                                            <li
                                                class="item"
                                                style="width: 126px"
                                            >
                                              <input-number
                                                  ref="payCreditInfoPayAmt"
                                                  v-model="payAmt"
                                                  :allow-minus="true"
                                                  :disabled="salesDiv === 'DEPOSIT' || (salesDiv === 'CREDIT' && (popupData.payIds || []).length > 0)"
                                                  :propMaxLength="13"
                                                  @keypress.enter.native="
                                                  focusToCreditRemarks
                                                "
                                              />
                                            </li>
                                            <li
                                                v-if="salesDiv === 'SALES'"
                                                class="item"
                                                style="width: calc(100% - 126px);"
                                            >
                                              <erp-button
                                                  button-div="GET"
                                                  style="
                                                  box-sizing: border-box;
                                                  width: 70px;
                                                  height: 23px;
                                                  border-radius: 3px;
                                                  border: none;
                                                  cursor: pointer;
                                                  background-color: #2e7458;
                                                  font-size: 12px;
                                                  font-family: 'NanumSquare-Bold',
                                                    Arial, Verdana, sans-serif;
                                                  color: #fff;
                                                "
                                                  :use-syncfusion-component-style="false"
                                                  :disabled="payProcessing"
                                                  @click="rePayInfoSetting"
                                              >
                                                전체금액
                                              </erp-button>
                                            </li>
                                          </ul>
                                        </li>
                                        <li class="field">
                                          <div class="title">타이틀</div>
                                          <ul class="content">
                                            <li class="item" style="width: 150px;">
                                              <ejs-dropdownlist
                                                  ref="creditTitle1"
                                                  cssClass="body-data-dropdown"
                                                  :dataSource="creditTitle1DropDownList"
                                                  :fields="ejsFields"
                                                  v-model="payCreditInfo.creditTitle1"
                                                  @change="creditTitleChange"
                                              ></ejs-dropdownlist>
                                            <li
                                                class="item"
                                                style="width: 150px;"
                                            >
                                            <ejs-dropdownlist
                                                ref="creditTitle2"
                                                cssClass="body-data-dropdown"
                                                :dataSource="creditTitle2DropDownList"
                                                :fields="ejsFields"
                                                :enabled="payCreditInfo.creditTitle1 !== 'CREDIT'"
                                                v-model="payCreditInfo.creditTitle2"
                                            ></ejs-dropdownlist>
                                            </li>
                                          </ul>
                                        </li>
                                        <li class="field">
                                          <div class="title">계정</div>
                                          <ul class="content">
                                            <li
                                                class="item"
                                                style="width: 150px;"
                                            >
                                              <ejs-dropdownlist
                                                  ref="creditAccount"
                                                  cssClass="body-data-dropdown"
                                                  :dataSource="creditAccountDropDownList"
                                                  :fields="ejsFields"
                                                  v-model="payCreditInfo.creditAccount"
                                              ></ejs-dropdownlist>
                                            </li>
                                          </ul>
                                        </li>
                                        <!-- TODO : validation을 위한 임시 방편 -->
                                        <li class="field">
                                          <div class="title">비고</div>
                                          <ul class="content">
                                            <li class="item textarea">
                                              <input-textarea
                                                  ref="payCreditInfoRemarks"
                                                  v-model="payCreditInfo.remarks"
                                                  placeholder="비고 내용을 입력해 주세요"
                                              />
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                    <div id="deposit" v-show="payDiv === 'DEPOSIT'">
                                      <ul class="lookupDetail-condition">
                                        <li class="field">
                                          <div class="title required">
                                            미정산액
                                          </div>
                                          <div
                                              class="content"
                                              style="width: 126px"
                                          >
                                            <ul class="row">
                                              <li
                                                  class="item form-group"
                                                  style="width: 126px"
                                              >
                                                <div class="form">
                                                  <input-number
                                                      v-model="
                                                      payPopupInfo.noPayAmt
                                                    "
                                                      :disabled="true"
                                                      :allow-minus="true"
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <div class="title required">
                                            결제금액
                                          </div>
                                          <div
                                              class="content"
                                          >
                                            <ul class="row">
                                              <li
                                                  class="item form-group"
                                                  style="width: 126px"
                                              >
                                                <div class="form">
                                                  <input-number
                                                      ref="payDepositInfoPayAmt"
                                                      v-model="payAmt"
                                                      :allow-minus="true"
                                                      :disabled="salesDiv === 'DEPOSIT' || (salesDiv === 'CREDIT' && (popupData.payIds || []).length > 0)"
                                                      :propMaxLength="13"
                                                      @keypress.enter.native="
                                                      focusToDepositSearchInput
                                                    "
                                                  />
                                                </div>
                                              </li>
                                              <li
                                                  v-if="salesDiv === 'SALES'"
                                                  class="item"
                                                  style="width: calc(100% - 126px);"
                                              >
                                                <erp-button
                                                    button-div="GET"
                                                    style="
                                                    box-sizing: border-box;
                                                    width: 70px;
                                                    height: 23px;
                                                    border-radius: 3px;
                                                    border: none;
                                                    cursor: pointer;
                                                    background-color: #2e7458;
                                                    font-size: 12px;
                                                    font-family: 'NanumSquare-Bold',
                                                      Arial, Verdana, sans-serif;
                                                    color: #fff;
                                                  "
                                                    :use-syncfusion-component-style="false"
                                                    :disabled="payProcessing"
                                                    @click="rePayInfoSetting"
                                                >
                                                  전체금액
                                                </erp-button>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <!--                                        start-->
                                        <li class="field">
                                          <div class="title">
                                            현금영수증<br />
                                            발행
                                          </div>
                                          <ul
                                              class="content"
                                              style="
                                              padding-top: 9px;
                                              padding-bottom: 9px;
                                            "
                                          >
                                            <li class="item">
                                              <ul class="check">
                                                <li>
                                                  <label>
                                                    <input
                                                        ref="payDepositInfoProofKind"
                                                        type="radio"
                                                        value=""
                                                        v-model="
                                                        payDepositInfo.proofKind
                                                      "
                                                    />
                                                    <i></i>
                                                    <div class="label">
                                                      미발급
                                                    </div>
                                                  </label>
                                                </li>
                                                <li>
                                                  <label>
                                                    <input
                                                        ref="payDepositInfoProofKind"
                                                        type="radio"
                                                        value="VOL"
                                                        v-model="
                                                        payDepositInfo.proofKind
                                                      "
                                                    />
                                                    <i></i>
                                                    <div class="label">
                                                      자진발급
                                                    </div>
                                                  </label>
                                                </li>
                                                <li>
                                                  <label>
                                                    <input
                                                        ref="payDepositInfoProofKind"
                                                        type="radio"
                                                        value="FIT"
                                                        v-model="
                                                        payDepositInfo.proofKind
                                                      "
                                                    />
                                                    <i></i>
                                                    <div class="label">
                                                      소득공제용
                                                    </div>
                                                  </label>
                                                </li>
                                                <li>
                                                  <label>
                                                    <input
                                                        ref="payDepositInfoProofKind"
                                                        type="radio"
                                                        value="CPR"
                                                        v-model="
                                                        payDepositInfo.proofKind
                                                      "
                                                    />
                                                    <i></i>
                                                    <div class="label">
                                                      사업자 지출증빙
                                                    </div>
                                                  </label>
                                                </li>
                                              </ul>
                                            </li>
                                          </ul>
                                        </li>
                                        <li class="field">
                                          <div class="title">
                                            현금영수증<br />
                                            증빙번호
                                          </div>
                                          <ul class="content">
                                            <li
                                                class="item"
                                                style="width: 126px"
                                            >
                                              <input-text
                                                  ref="payDepositInfoProofNo"
                                                  :disabled="
                                                  van === 'SMARTRO' ||
                                                  !payCashInfo.proofKind ||
                                                  payCashInfo.proofKind === 'VOL' ||
                                                  payCashInfo.cardProof"
                                                  v-model="payDepositInfo.proofNo"
                                                  placeholder="입력하세요"
                                              />
                                            </li>
                                          </ul>
                                        </li>
                                        <li
                                            class="field"
                                            v-if="van === 'NICE'"
                                        >
                                          <div class="title">카드증빙</div>
                                          <ul class="content">
                                            <li class="item">
                                              <ul class="check">
                                                <li>
                                                  <label>
                                                    <input
                                                        ref="payDepositInfoApprovalFlag"
                                                        type="checkbox"
                                                        :disabled="
                                                        !payDepositInfo.proofKind ||
                                                        payDepositInfo.proofKind ===
                                                          'VOL'
                                                      "
                                                        v-model="
                                                        payDepositInfo.cardProof
                                                      "
                                                        @change="
                                                        payDepositReceiptProofCardFlagChanged
                                                      "
                                                    />
                                                    <i></i>
                                                    <div class="label">
                                                      사용
                                                    </div>
                                                  </label>
                                                </li>
                                              </ul>
                                            </li>
                                          </ul>
                                        </li>
                                        <!--                                        end-->
                                        <li class="field">
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item">
                                                <div style="display: flex">
                                                  <div style="flex: 1">
                                                    <input-text
                                                        ref="payDepositInfoPaySearch"
                                                        v-model="
                                                        depositBalanceSearchCondition.condition
                                                      "
                                                        @keypress.enter.native="
                                                        searchDepositBalanceStatus
                                                      "
                                                        placeholder="입금자, 카드번호, 비고"
                                                    />
                                                  </div>
                                                  <div style="flex: 1">
                                                    <erp-button
                                                        button-div="GET"
                                                        class="tmpBtn"
                                                        :use-syncfusion-component-style="false"
                                                        @click="
                                                        searchDepositBalanceStatus
                                                      "
                                                    >
                                                      조회
                                                    </erp-button>
                                                  </div>
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                            type="checkbox"
                                                            id="autoInquiryFlag"
                                                            v-model="
                                                          depositBalanceSearchCondition.showEmptyBalanceAmount
                                                        "
                                                        />
                                                        <i></i>
                                                        <div
                                                            class="label"
                                                            for="autoInquiryFlag"
                                                        >
                                                          잔액없는 자료 모두보기
                                                        </div>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <div
                                              class="title required"
                                              style="display: none"
                                          >
                                            선수금
                                          </div>
                                          <div class="content" style="width: 100%">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input
                                                      type="hidden"
                                                      ref="payDepositInfoDepositId"
                                                      v-model="
                                                      payDepositInfo.depositId
                                                    "
                                                  />
                                                </div>
                                              </li>
                                              <li
                                                  class="item"
                                                  :style="van === 'NICE' ? 'height: 130px' : 'height: 165px'"
                                              >
                                                <ejs-grid-wrapper
                                                    class="depositGrid"
                                                    ref="depositGrid"
                                                    v-bind="depositGridOptions"
                                                    @rowSelected="
                                                    depositGridRowSelected
                                                  "
                                                    @actionComplete="
                                                    depositGridOnActionComplete
                                                  "
                                                    @keyPressed="
                                                    depositGridOnKeyPressed
                                                  "
                                                />
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                    <div id="gift" v-if="payDiv === 'GIFT'">
                                      <ul class="lookupDetail-condition">
                                        <li class="field">
                                          <div class="title required">
                                            미정산액
                                          </div>
                                          <div
                                              class="content"
                                              style="width: 126px"
                                          >
                                            <ul class="row">
                                              <li
                                                  class="item form-group"
                                                  style="width: 126px"
                                              >
                                                <div class="form">
                                                  <input-number
                                                      v-model="
                                                      payPopupInfo.noPayAmt
                                                    "
                                                      :disabled="true"
                                                      :allow-minus="true"
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <div class="title required">
                                            결제금액
                                          </div>
                                          <div
                                              class="content"
                                          >
                                            <ul class="row">
                                              <li
                                                  class="item form-group"
                                                  style="width: 126px"
                                              >
                                                <div class="form">
                                                  <input-number
                                                      ref="payGiftInfoPayAmt"
                                                      v-model="payAmt"
                                                      :allow-minus="true"
                                                      :disabled="salesDiv === 'DEPOSIT' || (salesDiv === 'CREDIT' && (popupData.payIds || []).length > 0)"
                                                      :propMaxLength="13"
                                                      @keypress.enter.native="
                                                      focusToDepositSearchInput
                                                    "
                                                  />
                                                </div>
                                              </li>
                                              <li
                                                  v-if="salesDiv === 'SALES'"
                                                  class="item"
                                                  style="width: calc(100% - 126px);"
                                              >
                                                <erp-button
                                                    style="
                                                    box-sizing: border-box;
                                                    width: 70px;
                                                    height: 23px;
                                                    border-radius: 3px;
                                                    border: none;
                                                    cursor: pointer;
                                                    background-color: #2e7458;
                                                    font-size: 12px;
                                                    font-family: 'NanumSquare-Bold',
                                                      Arial, Verdana, sans-serif;
                                                    color: #fff;
                                                  "
                                                    :use-syncfusion-component-style="false"
                                                    :disabled="payProcessing"
                                                    @click="rePayInfoSetting"
                                                >
                                                  전체금액
                                                </erp-button>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <div class="title">
                                            상품권 구분
                                          </div>
                                          <ul class="content">
                                            <li class="item">
                                              <ul class="check">
                                                <li>
                                                  <label>
                                                    <input
                                                        type="radio"
                                                        value="GIFT"
                                                        v-model="payGiftInfo.giftDiv"
                                                    />
                                                    <i></i>
                                                    <div class="label">
                                                      {{commonCodesGetComName("PAY_DIV", "GIFT")}}
                                                    </div>
                                                  </label>
                                                </li>
                                                <li>
                                                  <label>
                                                    <input
                                                        type="radio"
                                                        value="GROUP_GIFT"
                                                        v-model="payGiftInfo.giftDiv"
                                                    />
                                                    <i></i>
                                                    <div class="label">
                                                      {{commonCodesGetComName("PAY_DIV", "GROUP_GIFT")}}
                                                    </div>
                                                  </label>
                                                </li>
                                              </ul>
                                            </li>
                                          </ul>
                                        </li>
                                        <li class="field">
                                          <div class="title">
                                            상품권번호
                                          </div>
                                          <ul class="content">
                                            <li
                                                class="item"
                                                style="width: 252px;"
                                            >
                                              <input-textarea
                                                  v-model="payGiftInfo.giftNo"
                                                  placeholder="입력하세요"
                                              />
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                    <div id="trans" v-if="payDiv === 'TRANS'">
                                      <ul class="lookupDetail-condition">
                                        <li class="field" v-if="salesDiv !== 'DEPOSIT'">
                                          <div class="title">미정산액</div>
                                          <ul class="content">
                                            <li
                                                class="item"
                                                style="width: 126px"
                                            >
                                              <input-number
                                                  v-model="payPopupInfo.noPayAmt"
                                                  :disabled="true"
                                                  :allow-minus="true"
                                              />
                                            </li>
                                          </ul>
                                        </li>
                                        <li class="field">
                                          <div class="title">결제금액</div>
                                          <ul class="content">
                                            <li
                                                class="item"
                                                style="width: 126px"
                                            >
                                              <input-number
                                                  ref="payTransInfoPayAmt"
                                                  v-model="payAmt"
                                                  :allow-minus="true"
                                                  :disabled="salesDiv === 'DEPOSIT' || (salesDiv === 'CREDIT' && (popupData.payIds || []).length > 0)"
                                                  :propMaxLength="13"
                                                  @keypress.enter.native="
                                                  pay(payDiv)
                                                "
                                              />
                                            </li>
                                            <li
                                                v-if="salesDiv === 'SALES'"
                                                class="item"
                                                style="width: calc(100% - 126px);"
                                            >
                                              <erp-button
                                                  button-div="GET"
                                                  style="
                                                  box-sizing: border-box;
                                                  width: 70px;
                                                  height: 23px;
                                                  border-radius: 3px;
                                                  border: none;
                                                  cursor: pointer;
                                                  background-color: #2e7458;
                                                  font-size: 12px;
                                                  font-family: 'NanumSquare-Bold',
                                                    Arial, Verdana, sans-serif;
                                                  color: #fff;
                                                "
                                                  :use-syncfusion-component-style="false"
                                                  :disabled="payProcessing"
                                                  @click="rePayInfoSetting"
                                              >
                                                전체금액
                                              </erp-button>
                                            </li>
                                          </ul>
                                        </li>
                                        <li class="field">
                                          <div class="title">
                                            현금영수증<br />
                                            발행
                                          </div>
                                          <ul
                                              class="content"
                                              style="
                                              padding-top: 9px;
                                              padding-bottom: 9px;
                                            "
                                          >
                                            <li class="item">
                                              <ul class="check">
                                                <li>
                                                  <label>
                                                    <input
                                                        ref="payTransInfoProofKind"
                                                        type="radio"
                                                        value=""
                                                        v-model="
                                                        payTransInfo.proofKind
                                                      "
                                                        :disabled="salesDiv === 'DEPOSIT'"
                                                    />
                                                    <i></i>
                                                    <div class="label">
                                                      미발급
                                                    </div>
                                                  </label>
                                                </li>
                                                <li>
                                                  <label>
                                                    <input
                                                        ref="payTransInfoProofKind"
                                                        type="radio"
                                                        value="VOL"
                                                        v-model="
                                                        payTransInfo.proofKind
                                                      "
                                                    />
                                                    <i></i>
                                                    <div class="label">
                                                      자진발급
                                                    </div>
                                                  </label>
                                                </li>
                                                <li>
                                                  <label>
                                                    <input
                                                        ref="payTransInfoProofKind"
                                                        type="radio"
                                                        value="FIT"
                                                        v-model="
                                                        payTransInfo.proofKind
                                                      "
                                                    />
                                                    <i></i>
                                                    <div class="label">
                                                      소득공제용
                                                    </div>
                                                  </label>
                                                </li>
                                                <li>
                                                  <label>
                                                    <input
                                                        ref="payTransInfoProofKind"
                                                        type="radio"
                                                        value="CPR"
                                                        v-model="
                                                        payTransInfo.proofKind
                                                      "
                                                    />
                                                    <i></i>
                                                    <div class="label">
                                                      사업자 지출증빙
                                                    </div>
                                                  </label>
                                                </li>
                                              </ul>
                                            </li>
                                          </ul>
                                        </li>
                                        <li class="field">
                                          <div class="title">
                                            현금영수증<br />
                                            증빙번호
                                          </div>
                                          <ul class="content">
                                            <li
                                                class="item"
                                                style="width: 126px"
                                            >
                                              <input-text
                                                  ref="payTransInfoProofNo"
                                                  :disabled="true"
                                                  v-model="payTransInfo.proofNo"
                                                  placeholder="입력하세요"
                                              />
                                            </li>
                                          </ul>
                                        </li>
                                        <li
                                            class="field"
                                            v-if="van === 'NICE'"
                                        >
                                          <div class="title">카드증빙</div>
                                          <ul class="content">
                                            <li class="item">
                                              <ul class="check">
                                                <li>
                                                  <label>
                                                    <input
                                                        ref="payTransInfoApprovalFlag"
                                                        type="checkbox"
                                                        :disabled="
                                                        !payTransInfo.proofKind ||
                                                        payTransInfo.proofKind ===
                                                          'VOL'
                                                      "
                                                        v-model="
                                                        payTransInfo.cardProof
                                                      "
                                                        @change="
                                                        payTransReceiptProofCardFlagChanged
                                                      "
                                                    />
                                                    <i></i>
                                                    <div class="label">
                                                      사용
                                                    </div>
                                                  </label>
                                                </li>
                                              </ul>
                                            </li>
                                          </ul>
                                        </li>
                                        <li v-if="salesDiv !== 'CREDIT'" class="field">
                                          <div class="title">비고</div>
                                          <ul class="content">
                                            <li class="item textarea">
                                              <input-textarea
                                                  ref="payTransInfoRemarks"
                                                  v-model="payTransInfo.remarks"
                                                  placeholder="비고 내용을 입력해 주세요"
                                              />
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                    <div id="creditDef" v-if="payDiv === 'CREDIT_DEF'">
                                      <ul
                                          class="lookupDetail-condition"
                                          style="width: 35%; float: left; padding-bottom: 0px;"
                                      >
                                        <li class="field">
                                          <div class="title required">
                                            미정산액
                                          </div>
                                          <div
                                              class="content"
                                              style="width: 126px"
                                          >
                                            <ul class="row">
                                              <li
                                                  class="item form-group"
                                                  style="width: 126px"
                                              >
                                                <div class="form">
                                                  <input-number
                                                      v-model="
                                                      payPopupInfo.noPayAmt
                                                    "
                                                      :disabled="true"
                                                      :allow-minus="true"
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <div class="title required">
                                            결제금액
                                          </div>
                                          <div
                                              class="content"
                                          >
                                            <ul class="row">
                                              <li
                                                  class="item form-group"
                                                  style="width: 126px"
                                              >
                                                <div class="form">
                                                  <input-number
                                                      ref="payCreditDefInfoPayAmt"
                                                      v-model="payCreditDefInfo.payAmt"
                                                      :allow-minus="true"
                                                      :disabled="true"
                                                      :propMaxLength="13"
                                                      @keypress.enter.native="
                                                      focusToDepositSearchInput
                                                    "
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                      </ul>
                                      <ul
                                          class="lookupDetail-condition"
                                          style="width: 65%; padding-bottom: 0px;"
                                      >
                                        <li class="field">
                                          <div class="title" style="width: 50px;">
                                            비고
                                          </div>
                                          <ul class="content" style="width: calc(100% - 50px);">
                                            <li class="item textarea">
                                              <input-textarea
                                                  ref="payCreditDefInfoRemarks"
                                                  v-model="payCreditDefInfo.remarks"
                                                  placeholder="비고 내용을 입력해 주세요"
                                                  style="height: 55px;"
                                              />
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                      <ul class="lookupDetail-condition" style="padding-top: 4px;">
                                        <li class="field">
                                          <div class="title">
                                            매출목록
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group button">
                                                <ul class="button">
                                                  <li>
                                                    <erp-button
                                                        button-div="GET"
                                                        @click.native="rePayInfoSetting"
                                                    >
                                                      전체매출
                                                    </erp-button>
                                                  </li>
                                                </ul>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <div class="content" style="width: 100%;">
                                            <ul class="row">
                                              <li class="item" style="height: 120px;">
                                                <ejs-grid-wrapper
                                                    ref="creditDefSalesGrid"
                                                    v-bind="creditDefSalesGridOptions"
                                                    :dataSource="creditDefSalesList"
                                                    @queryCellInfo="onCreditDefSalesGridQueryCellInfo"
                                                    @headerSelectAllCheckboxClicked="onCreditDefSalesGridHeaderSelectAllCheckboxClicked"
                                                    @gridCheckboxChanged="onCreditDefSalesGridCheckboxChanged"
                                                />
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <div class="title">
                                            이월 대상
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item" style="width: 180px;">
                                                <ul class="check">
                                                  <li>
                                                    <label>
                                                      <input
                                                          ref="creditDefDiv"
                                                          type="radio"
                                                          value="G"
                                                          v-model="payCreditDefInfo.defDiv"
                                                          @change="onCreditDefDivChange"
                                                      />
                                                      <i></i>
                                                      <div class="label">
                                                        골프
                                                      </div>
                                                    </label>
                                                  </li>
                                                  <li style="margin-right: 12px;">
                                                    <label>
                                                      <input
                                                          ref="creditDefDiv"
                                                          type="radio"
                                                          value="C"
                                                          v-model="payCreditDefInfo.defDiv"
                                                          @change="onCreditDefDivChange"
                                                      />
                                                      <i></i>
                                                      <div class="label">
                                                        객실
                                                      </div>
                                                    </label>
                                                  </li>
                                                  <li>
                                                    <label>
                                                      <input
                                                          ref="creditDefisGolfLink"
                                                          type="checkbox"
                                                          :disabled="
                                                          storeCode === 'FRONT' ||
                                                          payCreditDefInfo.defDiv === 'C'
                                                        "
                                                          v-model="payCreditDefInfo.isGolfLink"
                                                      />
                                                      <i></i>
                                                      <div class="label">
                                                        골프 연결
                                                      </div>
                                                    </label>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li class="item form-group text">
                                                <div class="label">
                                                  {{ payCreditDefInfo.defDiv === "C" ? "재실일자" : "내장일자" }}
                                                </div>
                                              </li>
                                              <li class="item form-group date">
                                                <div class="form">
                                                  <input-date
                                                      :disabled="payCreditDefInfo.isGolfLink"
                                                      v-model="payCreditDefInfo.visitDate"
                                                  />
                                                </div>
                                              </li>
                                              <li class="item form-group input" style="width: 160px;">
                                                <div class="form">
                                                  <input-text
                                                      :disabled="payCreditDefInfo.isGolfLink"
                                                      v-model="payCreditDefInfo.visitName"
                                                      :placeholder="payCreditDefInfo.defDiv === 'C' ? '투숙객명' : '내장객명'"
                                                  />
                                                </div>
                                              </li>
                                              <li class="item form-group button">
                                                <ul class="button">
                                                  <li>
                                                    <erp-button
                                                        button-div="GET"
                                                        @click.native="onCreditDefByTransButtonClicked"
                                                    >
                                                      조회
                                                    </erp-button>
                                                  </li>
                                                </ul>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <div class="content" style="width: 100%;">
                                            <ul class="row">
                                              <li class="item" style="height: 120px;">
                                                <ejs-grid-wrapper
                                                    ref="creditDefTransGrid"
                                                    v-bind="creditDefTransGridOptions"
                                                    :dataSource="creditDefTransList"
                                                />
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                    <div id="ticket" v-if="payDiv === 'TICKET'">
                                      <ul class="lookupDetail-condition">
                                        <li class="field">
                                          <div class="title">미정산액</div>
                                          <ul class="content">
                                            <li
                                                class="item"
                                                style="width: 126px"
                                            >
                                              <input-number
                                                  v-model="payPopupInfo.noPayAmt"
                                                  :disabled="true"
                                                  :allow-minus="true"
                                              />
                                            </li>
                                          </ul>
                                        </li>
                                        <li class="field">
                                          <div class="title">결제금액</div>
                                          <ul class="content">
                                            <li
                                                class="item"
                                                style="width: 126px"
                                            >
                                              <input-number
                                                  ref="payTicketInfoPayAmt"
                                                  v-model="payAmt"
                                                  :allow-minus="true"
                                                  :disabled="salesDiv === 'DEPOSIT' || (salesDiv === 'CREDIT' && (popupData.payIds || []).length > 0)"
                                                  :propMaxLength="13"
                                                  @keypress.enter.native="
                                                  focusToTicketNumber
                                                "
                                              />
                                            </li>
                                            <li
                                                v-if="salesDiv === 'SALES'"
                                                class="item"
                                                style="width: calc(100% - 126px);"
                                            >
                                              <erp-button
                                                  button-div="GET"
                                                  style="
                                                  box-sizing: border-box;
                                                  width: 70px;
                                                  height: 23px;
                                                  border-radius: 3px;
                                                  border: none;
                                                  cursor: pointer;
                                                  background-color: #2e7458;
                                                  font-size: 12px;
                                                  font-family: 'NanumSquare-Bold',
                                                    Arial, Verdana, sans-serif;
                                                  color: #fff;
                                                "
                                                  :use-syncfusion-component-style="false"
                                                  :disabled="payProcessing"
                                                  @click="rePayInfoSetting"
                                              >
                                                전체금액
                                              </erp-button>
                                            </li>
                                          </ul>
                                        </li>
                                        <li class="field">
                                          <div class="title">이용권 번호</div>
                                          <ul class="content">
                                            <li
                                                class="item"
                                                style="width: 126px"
                                            >
                                              <input-text
                                                  ref="payTicketInfoTicketNumber"
                                                  v-model="payTicketInfo.ticketNumber"
                                                  @keypress.enter.native="pay(payDiv)"
                                              />
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                    <div id="point" v-if="payDiv === 'POINT'">
                                      <ul class="lookupDetail-condition">
                                        <li class="field">
                                          <div class="title required">
                                            미정산액
                                          </div>
                                          <div
                                              class="content"
                                              style="width: 126px"
                                          >
                                            <ul class="row">
                                              <li
                                                  class="item form-group"
                                                  style="width: 126px"
                                              >
                                                <div class="form">
                                                  <input-number
                                                      v-model="
                                                      payPopupInfo.noPayAmt
                                                    "
                                                      :disabled="true"
                                                      :allow-minus="true"
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <div class="title required">
                                            잔여 포인트
                                          </div>
                                          <div
                                              class="content"
                                              style="width: 126px"
                                          >
                                            <ul class="row">
                                              <li
                                                  class="item form-group"
                                                  style="width: 126px"
                                              >
                                                <div class="form">
                                                  <input-number
                                                      v-model="
                                                      payPopupInfo.remainPoint
                                                    "
                                                      :disabled="true"
                                                      :allow-minus="true"
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <div class="title required">
                                            사용 포인트
                                          </div>
                                          <div
                                              class="content"
                                          >
                                            <ul class="row">
                                              <li
                                                  class="item form-group"
                                                  style="width: 126px"
                                              >
                                                <div class="form">
                                                  <input-number
                                                      ref="payGiftInfoPayAmt"
                                                      v-model="payAmt"
                                                      :allow-minus="true"
                                                      :disabled="salesDiv === 'DEPOSIT' || (salesDiv === 'CREDIT' && (popupData.payIds || []).length > 0)"
                                                      :propMaxLength="13"
                                                      @keypress.enter.native="
                                                      focusToDepositSearchInput
                                                    "
                                                  />
                                                </div>
                                              </li>
                                              <li
                                                  v-if="salesDiv === 'SALES'"
                                                  class="item"
                                                  style="width: calc(100% - 126px);"
                                              >
                                                <erp-button
                                                    style="
                                                    box-sizing: border-box;
                                                    width: 70px;
                                                    height: 23px;
                                                    border-radius: 3px;
                                                    border: none;
                                                    cursor: pointer;
                                                    background-color: #2e7458;
                                                    font-size: 12px;
                                                    font-family: 'NanumSquare-Bold',
                                                      Arial, Verdana, sans-serif;
                                                    color: #fff;
                                                  "
                                                    :use-syncfusion-component-style="false"
                                                    :disabled="payProcessing"
                                                    @click="rePayInfoSetting"
                                                >
                                                  전체금액
                                                </erp-button>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                    <div id="self" v-if="payDiv === 'SELF'">
                                      <ul class="lookupDetail-condition">
                                        <li class="field">
                                          <div class="title required">
                                            미정산액
                                          </div>
                                          <div
                                              class="content"
                                              style="width: 126px"
                                          >
                                            <ul class="row">
                                              <li
                                                  class="item form-group"
                                                  style="width: 126px"
                                              >
                                                <div class="form">
                                                  <input-number
                                                      v-model="
                                                      payPopupInfo.noPayAmt
                                                    "
                                                      :disabled="true"
                                                      :allow-minus="true"
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <div class="title required">
                                            결제금액
                                          </div>
                                          <div
                                              class="content"
                                          >
                                            <ul class="row">
                                              <li
                                                  class="item form-group"
                                                  style="width: 126px"
                                              >
                                                <div class="form">
                                                  <input-number
                                                      ref="paySelfInfoPayAmt"
                                                      v-model="payAmt"
                                                      :allow-minus="true"
                                                      :disabled="salesDiv === 'DEPOSIT' || (salesDiv === 'CREDIT' && (popupData.payIds || []).length > 0)"
                                                      :propMaxLength="13"
                                                      @keypress.enter.native="
                                                      focusToSelfSearchInput
                                                    "
                                                  />
                                                </div>
                                              </li>
                                              <li
                                                  v-if="salesDiv === 'SALES'"
                                                  class="item"
                                                  style="width: calc(100% - 126px);"
                                              >
                                                <erp-button
                                                    style="
                                                    box-sizing: border-box;
                                                    width: 70px;
                                                    height: 23px;
                                                    border-radius: 3px;
                                                    border: none;
                                                    cursor: pointer;
                                                    background-color: #2e7458;
                                                    font-size: 12px;
                                                    font-family: 'NanumSquare-Bold',
                                                      Arial, Verdana, sans-serif;
                                                    color: #fff;
                                                  "
                                                    :use-syncfusion-component-style="false"
                                                    :disabled="payProcessing"
                                                    @click="rePayInfoSetting"
                                                >
                                                  전체금액
                                                </erp-button>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <div class="title">
                                            지시자
                                          </div>
                                          <ul class="content">
                                            <li class="item" style="width: 126px">
                                              <input-text
                                                  ref="paySelfInfoPayIndicator"
                                                  v-model="paySelfInfo.paySelfInfoPayIndicator"
                                                  @keypress.enter.native="pay(payDiv)"
                                              />
                                            </li>
                                          </ul>
                                        </li>
                                        <li class="field">
                                          <div class="title">
                                            비고
                                          </div>
                                          <ul class="content">
                                            <li
                                                class="item"
                                                style="width: 252px;"
                                            >
                                              <input-textarea
                                                  v-model="paySelfInfo.remarks"
                                                  placeholder="비고 내용을 입력해 주세요"
                                              />
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </section>
                              </div>
                              <div class="article-right" style="width: 87px">
                                <section class="article-section section-020102">
                                  <div
                                      class="section-body"
                                      style="border: none; overflow: visible"
                                  >
                                    <div
                                        class="body-box"
                                        style="
                                        position: relative;
                                        box-sizing: border-box;
                                        width: calc(100% + 18px);
                                        height: calc(100% + 36px);
                                        margin: -18px -18px -18px 0;
                                        background-color: #fafafa;
                                        border-left: 1px solid #e0e0e0;
                                        padding: 18px;
                                      "
                                    >
                                      <div>
                                        <erp-button
                                            button-div="GET"
                                            class="insertButton"
                                            :use-syncfusion-component-style="false"
                                            @click="initFocusing(payDiv)"
                                            :disabled="payProcessing"
                                        >
                                          입력
                                        </erp-button>
                                      </div>
                                      <div style="padding-top: 6px">
                                        <erp-button
                                            button-div="SAVE"
                                            class="payButton"
                                            :use-syncfusion-component-style="false"
                                            @click="pay(payDiv)"
                                            :disabled="payProcessing"
                                            @keypress.enter="payButtonEnterKeyPressEvent"
                                        >
                                          정산
                                        </erp-button>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </article>
                          </div>
                        </section>
                      </article>
                    </div>
                  </section>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <!-- 메시지 상태 영역 -->
          <div class="operationMessage">
            {{ operationMessage }}
          </div>
          <ul class="button">
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="handleCloseButtonClick">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
  commonCodesGetBizMstInfo,
  commonCodesGetCommonCodeByIdxAttrb,
  commonCodesGetStandardInfo,
  commonCodeGetComCodeDefaultValue,
} from "@/utils/commonCodes";
import { getFormattedTelNumber } from "@/utils/string";
import {
  getFormattedDate,
  getTodayNavigationDate,
  getTodayDate,
  DATE_FORMAT_YYYY_MM_DD,
} from "@/utils/date";
import {getCreditDefTrans, getPayInfo} from "@/api/common";
import moment from "moment";
import { validateFormRefs } from "@/utils/formUtil";
import { getStoreBsnMstInfo } from "@/api/bizCodeManagement";
import { getReceiptInfo } from "@/api/common";
import BillPrintUtil from "@/utils/billPrintUtil";
import { numberWithCommas } from "@/utils/number";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import GolfERPService from "@/service/GolfERPService";
import VanModuleCreateError from "@/error/van/VanModuleCreateError";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { getDeviceInfo } from "@/utils/device";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { Edit, ForeignKey, Resize, Selection } from "@syncfusion/ej2-vue-grids";
import { openERPPay } from "@/utils/Pay";
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import InputNumber from "@/components/common/InputNumber";
import InputDate from "@/components/common/datetime/InputDate";
import orderBy from "lodash/orderBy";
import BaseError from "@/error/BaseError";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "PayPopup",
  components: {
    InputTextarea,
    InputText,
    InputDate,
    EjsGridWrapper,
    InputNumber,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      ejsFields: { text: "comName", value: "comCode" },
      payDate: null,
      returnDate: null,
      creditReturnRemarks: null,
      printFlag: getDeviceInfo().isBillPrinterUse,
      receiptInfo: {},
      operationMessage: null,
      confCardList: null,
      payPopupInfo: {},
      payAmt: 0,
      payCardInfo: {
        approvalFlag: false,
        approvalNo: null,
        cardNo: null,
        divideTerm: 0,
        purchaseId: null,
        purchaseName: null,
        validTerm: null,
      },
      payCashInfo: {
        approvalNo: "", // 승인번호
        proofKind: getDeviceInfo().isCardUse ? "VOL" : "", // 현금영수증 발행 구분. 기본값 : 자진발급(VAN사 사용시), 미발급(VAN 미사용시)
        proofNo: null,
        cardProof: false,
      },
      payEasyInfo: {
        divideTerm: 0,
      },
      payCreditInfo: {
        remarks: null,
        creditTitle1: commonCodeGetComCodeDefaultValue('CREDIT_TITLE'),
        creditTitle2: null,
        creditAccount: commonCodeGetComCodeDefaultValue('CREDIT_ACCOUNT'),
      },
      payDepositInfo: {
        depositId: null,
        incomId: null,
        depositBalanceAmount: null,
        useDate: null,
        proofKind: getDeviceInfo().isCardUse ? "VOL" : "", // 현금영수증 발행 구분. 기본값 : 자진발급(VAN사 사용시), 미발급(VAN 미사용시)
        proofNo: null,
        cardProof: false,
        remarks: null,
        cardNo: null,
      },
      payTransInfo: {
        approvalNo: "", // 승인번호
        proofKind: getDeviceInfo().isCardUse ? "VOL" : "", // 현금영수증 발행 구분. 기본값 : 자진발급(VAN사 사용시), 미발급(VAN 미사용시)
        proofNo: null,
        cardProof: false,
        remarks: null,
      },
      payGiftInfo: {
        giftDiv: "GIFT",
        giftNo: null,
      },
      payTicketInfo: {
        ticketNumber:null
      },
      paySelfInfo: {
        paySelfInfoPayIndicator: "",
        remarks: null,
      },
      payCreditDefInfo: {
        defDiv: "G",
        isGolfLink: true,
        payAmt: 0,
        visitDate: null,
        visitName: null,
        remarks: null,
      },
      cashReceived: 0, // 현금결제 받은금액. 서버로 전송하지는 않음. 표시를 위함
      isPaid: false, // 한번이라도 정산을 진행했을 경우 true
      vanModule: null,
      bsnInfo: null,
      bsnDate: null,
      storeCode: "",
      popupData: {},
      payDiv: "CARD",
      payProcessing: false,
      salesDiv: null,
      depositBalanceStatusList: [],
      depositBalanceSearchCondition: {
        condition: null,
        showEmptyBalanceAmount: false,
      },
      reprsntRoomInfo: null,
      creditDefSalesList: [],
      creditDefTransList: [],
      creditTitle1DropDownList: [],
      creditTitle2DropDownList: [],
      creditAccountDropDownList:[],
      payerName: null, // todo 좌측상단 지불자 정보로 처리
      /** @type String */
      van: null,
    };
  },
  computed: {
    payDivTabList() {
      // 공통코드(PAY_DIV)에서 사용 중인 데이터만 셋팅
      const payDivs = commonCodesGetCommonCode("PAY_DIV", true);
      const payDivTabs = [
        { header: { text: "카드" }, payDiv: "CARD", },
        { header: { text: "현금" }, payDiv: "CASH", },
      ];
      switch (this.salesDiv) {
        case "SALES":
          payDivs.find(payDiv => payDiv.comCode === "EASY_PAY") && payDivTabs.push({ header: { text: "간편결제" }, payDiv: "EASY_PAY", });
          payDivs.find(payDiv => payDiv.comCode === "CREDIT") && payDivTabs.push({ header: { text: "미수 (C/L)" }, payDiv: "CREDIT", });
          payDivs.find(payDiv => payDiv.comCode === "DEPOSIT") && payDivTabs.push({ header: { text: "선수금" }, payDiv: "DEPOSIT", });
          payDivs.find(payDiv => payDiv.comCode === "GIFT") && payDivTabs.push({ header: { text: "상품권" }, payDiv: "GIFT", });
          payDivs.find(payDiv => payDiv.comCode === "TRANS") && payDivTabs.push({ header: { text: "계좌이체" }, payDiv: "TRANS", });
          payDivs.find(payDiv => payDiv.comCode === "CREDIT_DEF") && payDivTabs.push({ header: { text: "대내후불" }, payDiv: "CREDIT_DEF", });
          payDivs.find(payDiv => payDiv.comCode === "TICKET") && payDivTabs.push({ header: { text: "이용권" }, payDiv: "TICKET", });
          payDivs.find(payDiv => payDiv.comCode === "POINT") && payDivTabs.push({header: {text: "포인트"}, payDiv: "POINT"});
          payDivs.find(payDiv => payDiv.comCode === "SELF") && payDivTabs.push({ header: { text: "자가처리" }, payDiv: "SELF" });
          break;
        case "CREDIT":
          payDivs.find(payDiv => payDiv.comCode === "EASY_PAY") && payDivTabs.push({ header: { text: "간편결제" }, payDiv: "EASY_PAY", });
          payDivs.find(payDiv => payDiv.comCode === "DEPOSIT") && payDivTabs.push({ header: { text: "선수금" }, payDiv: "DEPOSIT", });
          payDivs.find(payDiv => payDiv.comCode === "TRANS") && payDivTabs.push({ header: { text: "계좌이체" }, payDiv: "TRANS", });
          break;
        case "DEPOSIT":
          payDivs.find(payDiv => payDiv.comCode === "TRANS") && payDivTabs.push({ header: { text: "계좌이체" }, payDiv: "TRANS", });
          // 선수금 입금화면에서 접근시 선택한 입금구분만 표시되도록 적용
          return payDivTabs.filter(value => value.payDiv === this.payDiv);
      }

      return payDivTabs;
    },
    payCardInfoValidateRefList() {
      let validateRef = {
        payCardInfoPayAmt: {
          required: true,
          custom: {
            method: (value) => {
              if (value !== 0) {
                return true;
              }
            },
            message: "main.validationMessage.requiredMessage",
          },
        },
        payCardInfoDivideTerm: { required: true },
      };

      // 기승인 건일 경우 카드 번호, 매입사, 유효 번호, 승인 번호도 검증이 필요
      if (this.payCardInfo.approvalFlag) {
        Object.assign(validateRef, {
          payCardInfoCardNo: { required: true },
          payCardInfoPurcId: { required: true },
          payCardInfoValidTerm: { required: true },
          payCardInfoApprovalNo: { required: true },
        });
      }

      return validateRef;
    },
    payEasyInfoValidateRefList() {
      let validateRef = {
        payEasyInfoPayAmt: {
          required: true,
          custom: {
            method: (value) => {
              if (value !== 0) {
                return true;
              }
            },
            message: "main.validationMessage.requiredMessage",
          },
        },
        payEasyInfoDivideTerm: { required: true },
      };

      return validateRef;
    },
    payCashInfoValidateRefList() {
      return {
        payCashInfoPayAmt: {
          required: true,
          custom: {
            method: (value) => {
              if (value !== 0) {
                return true;
              } else if (value === 0 && this.payCashInfo.proofKind === "") {
                return true;
              }
            },
            message: "main.validationMessage.requiredMessage",
          },
        },
        payCashInfoCashReceived: { required: true },
      };
    },
    payCreditInfoValidateRefList() {
      return {
        payCreditInfoPayAmt: {
          required: true,
          custom: {
            method: (value) => {
              if (value !== 0) {
                return true;
              }
            },
            message: "main.validationMessage.requiredMessage",
          },
        },
        creditTitle1: {
          required: true
        },
        creditTitle2: {
          required: this.payCreditInfo.creditTitle1 !== 'CREDIT',
        },
        creditAccount: {
          required: true,
        },
        payCreditInfoRemarks: {
          required: true,
        },
      };
    },
    payDepositInfoValidateRefList() {
      return {
        payDepositInfoPayAmt: {
          required: true,
          custom: {
            method: (value) => {
              if (value !== 0) {
                return true;
              }
            },
            message: "main.validationMessage.requiredMessage",
          },
        },
        payDepositInfoDepositId: {
          required: true,
        },
      };
    },
    payGiftInfoValidateRefList() {
      return {
        payGiftInfoPayAmt: {
          required: true,
          custom: {
            method: (value) => {
              if (value !== 0) {
                return true;
              }
            },
            message: "main.validationMessage.requiredMessage",
          },
        }
      };
    },
    payTransInfoValidateRefList() {
      return {
        payTransInfoPayAmt: {
          required: true,
          custom: {
            method: (value) => {
              if (value !== 0) {
                return true;
              }
            },
            message: "main.validationMessage.requiredMessage",
          },
        },
      };
    },
    paySelfInfoValidateRefList() {
      return {
        paySelfInfoPayAmt: {
          required: true,
          custom: {
            method: (value) => {
              if (value !== 0) {
                return true;
              }
            },
            message: "main.validationMessage.requiredMessage",
          },
        },
        paySelfInfoPayIndicator: {
          required: true,
        },
      };
    },
    payTicketInfoValidateRefList() {
      return {
        paySelfInfoPayAmt: {
          required: true,
          custom: {
            method: (value) => {
              if (value !== 0) {
                return true;
              }
            },
            message: "main.validationMessage.requiredMessage",
          },
        },
        payTicketInfoTicketNumber: {
          required: true,
        },
      };
    },
    payDivName() {
      return this.payDivTabList.find((value) => value.payDiv === this.payDiv)
          .header.text;
    },
    depositKindList() {
      return commonCodesGetCommonCode("DEPOSIT_KIND");
    },
    creditDefSalesGridOptions() {
      return {
        provides: [Edit, ForeignKey, Selection, Resize],
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
          persistSelection: false,
        },
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        noColumnWidth: 40,
        allowSorting: false,
        allowFiltering: false,
        columns: [
          {
            field: "slipId",
            visible: false,
          },
          {
            field: "isSelect",
            headerText: "",
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            isSelectAllColumn: true,
            minWidth: 16,
            width: 40,
            textAlign: "Center",
          },
          {
            field: "salesDate",
            headerText: "일자",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
            allowEditing: false,
          },
          {
            field: "storeCode",
            headerText: "매장",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "STORE_CODE",
            allowEditing: false,
          },
          {
            field: "slipNo",
            headerText: "전표번호",
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center",
            allowEditing: false,
          },
          {
            field: "productName",
            headerText: "상품명",
            type: "string",
            minWidth: 16,
            width: 110,
            allowEditing: false,
          },
          {
            field: "salePrice",
            headerText: "단가",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 80,
            textAlign: "right",
            allowEditing: false,
          },
          {
            field: "saleQty",
            headerText: "수량",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 50,
            textAlign: "right",
            allowEditing: false,
          },
          {
            field: "totAmt",
            headerText: "총액",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 80,
            textAlign: "right",
            allowEditing: false,
          },
          {
            field: "isCreditDef",
            headerText: "이월",
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            textAlign: "center",
            allowEditing: false,
          },
        ],
      };
    },
    creditDefTransGridColumns() {
      return this.payCreditDefInfo.defDiv === "G" ?
          [
            {
              field: "visitDate",
              headerText: "내장일자",
              type: "string",
              minWidth: 16,
              width: 80,
              textAlign: "center",
            },
            {
              field: "startTime",
              headerText: "시간",
              type: "string",
              minWidth: 16,
              width: 60,
              textAlign: "center",
            },
            {
              field: "startCourse",
              headerText: "코스",
              type: "string",
              minWidth: 16,
              width: 90,
              textAlign: "center",
              isCommonCodeField: true,
              groupCode: "COURSE_CODE",
            },
            {
              field: "holeDiv",
              headerText: "홀",
              type: "string",
              minWidth: 16,
              width: 60,
              textAlign: "center",
              isCommonCodeField: true,
              groupCode: "HOLE_DIV",
            },
            {
              field: "resveName",
              headerText: "예약자",
              type: "string",
              minWidth: 16,
              width: 90,
              textAlign: "center",
            },
            {
              field: "groupName",
              headerText: "단체명",
              type: "string",
              minWidth: 16,
              width: 120,
              textAlign: "center",
            },
            {
              field: "visitName",
              headerText: "내장객명",
              type: "string",
              minWidth: 16,
              width: 90,
              textAlign: "center",
            },
            {
              field: "isVisitorCheckout",
              headerText: "C/O",
              type: "boolean",
              editType: "booleanedit",
              displayAsCheckBox: true,
              // minWidth: 16,
              // width: 70,
              textAlign: "center",
            },
          ] :
          [
            {
              field: "roomNo",
              headerText: "객실번호",
              type: "string",
              minWidth: 16,
              width: 70,
              textAlign: "center",
            },
            {
              field: "roomType",
              headerText: "객실타입",
              type: "string",
              minWidth: 16,
              width: 70,
              textAlign: "center",
              isCommonCodeField: true,
              groupCode: "ROOM_TYPE",
            },
            {
              field: "guestName",
              headerText: "투숙객",
              type: "string",
              minWidth: 16,
              width: 90,
            },
            {
              field: "arrivalDate",
              headerText: "입실일자",
              type: "string",
              minWidth: 16,
              width: 90,
              textAlign: "center",
            },
            {
              field: "departureDate",
              headerText: "퇴실일자",
              type: "string",
              minWidth: 16,
              width: 90,
              textAlign: "center",
            },
            {
              field: "groupName",
              headerText: "그룹명",
              type: "string",
              minWidth: 16,
              width: 90,
            },
            {
              field: "resveName",
              headerText: "예약자명",
              type: "string",
              minWidth: 16,
              width: 90,
            },
            {
              field: "isRoomCheckout",
              headerText: "C/O",
              type: "boolean",
              editType: "booleanedit",
              displayAsCheckBox: true,
              minWidth: 16,
              width: 50,
              textAlign: "center",
            },
          ];
    },
    creditDefTransGridOptions() {
      return {
        provides: [ForeignKey, Selection, Resize],
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
          persistSelection: false,
        },
        noColumnWidth: 40,
        allowSorting: false,
        allowFiltering: false,
        columns: this.creditDefTransGridColumns,
      };
    },
    depositGridOptions() {
      return {
        provides: [ForeignKey, Selection, Resize],
        dataSource: this.depositBalanceStatusList,
        isSelectedRowRetain: false,
        allowResizing: true,
        selectionSettings: { type: "Single", enableToggle: false },
        isNOColumnDisplay: true,
        noColumnWidth: 40,
        columns: [
          { field: "depositId", visible: false },
          { field: "incomId", isPrimaryKey: true, visible: false },
          {
            field: "incomDate",
            headerText: "입금일자",
            type: "string",
            textAlign: "center",
            width: 90
          },
          {
            field: "depositKind",
            headerText: "종류",
            textAlign: "Left",
            isCommonCodeField: true,
            editType: "dropdownedit",
            type: "string",
            dataSource: this.depositKindList,
            width: 80
          },
          {
            field: "memberName",
            headerText: "입금자",
            type: "string",
            allowEditing: true,
            textAlign: "Left",
            width: 80
          },
          {
            field: "cardNo",
            headerText: "카드번호",
            type: "string",
            allowEditing: true,
            textAlign: "Left",
            width: 110
          },
          {
            field: "incomDiv",
            headerText: "입금구분",
            groupCode: "INCOM_DIV",
            isCommonCodeField: true,
            width: 90
          },
          {
            field: "indctTrgetFlag",
            headerText: "소득공제",
            textAlign: "center",
            type: "boolean",
            displayAsCheckBox: true,
            width: 90
          },
          {
            field: "balanceAmount",
            headerText: "선수금잔액",
            textAlign: "right",
            type: "string",
            isNumericType: true,
            width: 100
          },
          {
            field: "remarks",
            headerText: "비고",
            type: "string",
            textAlign: "Left",
            width: 200
          },
        ],
        depositUseReceipt: {
          useId: null,
          depositId: null,
          cardId: null,
          printDt: null,
          cardNo: null,
          depositAmt: null,
          sumUseAmt: null,
          useAmt: null,
          balanceAmt: null,
          useDate: null,
          startCourse: null,
          startTime: null,
          depositUseDiv: null,
        },
        bizMstInfo: {},
      };
    },
    returnDateValueModel: {
      get() {
        return getFormattedDate(this.returnDate);
      },
      set(returnDateValueModel) {
        const returnDate = moment(returnDateValueModel, DATE_FORMAT_YYYY_MM_DD);
        if (returnDate.isValid()) {
          this.returnDate = returnDate.toDate();
        }
      },
    },
  },
  created: function () {
    this.bizMstInfo = commonCodesGetBizMstInfo();

    this.bizMstInfo.bizNo =
        this.bizMstInfo.bizNo.substr(0, 3) +
        "-" +
        this.bizMstInfo.bizNo.substr(3, 2) +
        "-" +
        this.bizMstInfo.bizNo.substring(5);

    getTodayNavigationDate("YYYY-MM-DD").then(async (date) => {
      this.returnDate = moment(date, "YYYY-MM-DD").toDate();
      this.payDate = date;
    });

    this.payCreditDefInfo.visitDate = getTodayDate();
  },
  mounted() {
    this.creditTitle1DropDownList = commonCodesGetCommonCode('CREDIT_TITLE');
    this.creditAccountDropDownList = commonCodesGetCommonCode('CREDIT_ACCOUNT');

  },
  methods: {
    creditTitleChange({value}) {
      switch (value) {
        case "ADVERT":
          this.creditTitle2DropDownList = commonCodesGetCommonCode('ADVERT_CODE');
        break;
        case "AGENT":
          this.creditTitle2DropDownList = commonCodesGetCommonCodeByIdxAttrb('RESVE_CHANNEL',1,'AGENT');
          break;
        case "CREDIT":
          this.payCreditInfo.creditTitle2 = null;
          break;
      }
    },
    validateFormRefs,
    commonCodesGetComName,
    missingRequireValue(paramName) {
      throw new Error(`Require Value [${paramName}] is missing`);
    },
    async showPopup({
                      bsnDate = this.missingRequireValue("bsnDate"),
                      storeCode = this.missingRequireValue("storeCode"),
                      salesDiv = this.missingRequireValue("salesDiv"),
                      payDiv = "CARD",
                      ...popupData
                    }) {
      this.bsnDate = bsnDate;
      this.storeCode = storeCode;
      this.salesDiv = salesDiv;
      this.popupData = popupData;
      this.payDiv = payDiv;
      await this.initialize();

      this.$nextTick(() => {
        this.$refs["payDivTap"].select(
            this.payDivTabList.findIndex((value) => value.payDiv === payDiv)
        );
        this.initFocusing(this.payDiv);
      });

      if (this.salesDiv === "SALES" || this.salesDiv === "CREDIT") {
        // this.searchDepositBalanceStatus();
      } else if (this.salesDiv === "DEPOSIT") {
        if (!popupData.depositId) {
          this.payerName = popupData.deposit.memberName;
        } else {
          ({ memberName: this.payerName } = await GolfERPService.fetchDeposit({
            depositId: popupData.depositId
          }));
        }
        this.payAmt = popupData.depositAmt;

        if (this.payDiv === "CASH") {
          this.cashReceived = Number(
              JSON.parse(JSON.stringify(this.payAmt.toString()))
          );
        }
      }
    },
    closePopup() {
      this.$emit("popupClosed", { isPaid: this.isPaid });
    },
    handleDialogOpen() {},
    handleDialogClose() {
      this.closePopup();
    },
    handleDialogOverlayClick() {},
    handleCloseButtonClick() {
      this.closePopup();
    },
    tabSelected(args) {
      this.payDiv = this.payDivTabList[args.selectedIndex].payDiv;

      if (this.payDiv === "CASH") {
        this.cashReceived = Number(
            JSON.parse(JSON.stringify(this.payAmt.toString()))
        );
      } else if (this.payDiv === "DEPOSIT") {
        this.$refs.depositGrid.$refs.grid.$el.children[2].style.height = "calc(100% - 26px)";
      }
      this.$nextTick(() => {
        this.initFocusing(this.payDiv);
      });
    },
    startPay() {
      if (this.payProcessing) {
        return;
      }
      this.payProcessing = true;
      this.$refs["payPopup"].$el.nextElementSibling.style.zIndex = `${
          parseInt(this.$refs["payPopup"].$el.nextElementSibling.style.zIndex) + 1
      }`;
    },
    endPay() {
      if (!this.payProcessing) {
        return;
      }
      this.$refs["payPopup"].$el.nextElementSibling.style.zIndex = `${
          parseInt(this.$refs["payPopup"].$el.nextElementSibling.style.zIndex) - 1
      }`;
      this.payProcessing = false;
      this.initFocusing(this.payDiv);
    },
    payCardInfoApprovalFlagChanged(args) {
      this.focusToCardNo();
      if (!args.target.checked) {
        // 초기화
        this.clearCardInfo();
      } else {
        this.payCardInfo.validTerm = "0000";
      }
    },
    payCashReceiptProofCardFlagChanged(args) {
      if (!args.target.checked) {
        this.payCashInfo.proofNo = null;
      }
    },
    payDepositReceiptProofCardFlagChanged(args) {
      if (!args.target.checked) {
        this.payDepositInfo.proofNo = null;
      }
    },
    payTransReceiptProofCardFlagChanged(args) {
      if (!args.target.checked) {
        this.payTransInfo.proofNo = null;
      }
    },
    async reFetchData() {
      if (this.salesDiv === "DEPOSIT") {
        return;
      }

      let data = {
        bsnDate: this.bsnDate, // 영업일자
        salesDiv: this.salesDiv, // 매출구분
        storeCode: this.storeCode, // 매장코드
        salesId: this.popupData.salesId, // 매출ID (매장코드가 FRONT 일 경우엔 null)
        personFlag: this.popupData.personFlag, // 개인정산여부
        chkinId: this.popupData.chkinId, // 체크인ID
        grpNo: this.popupData.grpNo, // 단체번호
        visitId: this.popupData.visitId, // 내장객ID
        payId: this.popupData.payId,
        payIds: this.popupData.payIds,
        isPrepayment: this.popupData.prpayFrpyDiv === "0" ? true : false,
        rresveNo: this.popupData.rresveNo,
        defDiv: this.payCreditDefInfo.defDiv,
        isGolfLink: this.payCreditDefInfo.isGolfLink,
      };
      try {
        ({
          value: {
            payPopupInfo: this.payPopupInfo
          },
        } = await getPayInfo(data));
        // this.initFocusing(this.payDiv);
      } catch (error) {
        console.error("getPayInfoApi ERROR =====>", error);
        throw new Error(error);
      }
    },
    async fetchData() {
      if (this.salesDiv === "DEPOSIT") {
        return;
      }

      let data = {
        bsnDate: this.bsnDate, // 영업일자
        salesDiv: this.salesDiv, // 매출구분
        storeCode: this.storeCode, // 매장코드
        salesId: this.popupData.salesId, // 매출ID (매장코드가 FRONT 일 경우엔 null)
        personFlag: this.popupData.personFlag, // 개인정산여부
        chkinId: this.popupData.chkinId, // 체크인ID
        grpNo: this.popupData.grpNo, // 단체번호
        visitId: this.popupData.visitId, // 내장객ID
        payId: this.popupData.payId,
        payIds: this.popupData.payIds,
        isPrepayment: this.popupData.prpayFrpyDiv === "0" ? true : false,
        rresveNo: this.popupData.rresveNo,
        defDiv: this.payCreditDefInfo.defDiv,
        isGolfLink: this.payCreditDefInfo.isGolfLink,
        creditDefByVisitDate: this.payCreditDefInfo.visitDate,
        creditDefByVisitName: this.payCreditDefInfo.visitName,
      };
      try {
        ({
          value: {
            payPopupInfo: this.payPopupInfo,
            payPopupInfo: { noPayAmt: this.payAmt },
            creditDefBySalesRecords: this.creditDefSalesList,
            creditDefByTransRecords: this.creditDefTransList,
            reprsntRoomInfo: this.reprsntRoomInfo,
          },
        } = await getPayInfo(data));

        if (this.reprsntRoomInfo) {
          this.popupData.visitName = this.reprsntRoomInfo.guestName;
          this.popupData.lockerNo = this.reprsntRoomInfo.roomNo + "호";
        }
        // TODO : 이미 대내후불 처리된 매출 선택 안되도록 하는 부분 (헤더 전체 Select 버그) 처리되면 filter 제거.
        this.creditDefSalesList = this.creditDefSalesList ? orderBy(this.creditDefSalesList.filter(item => !item.isCreditDef).map(item => ({
          ...item,
          isSelect: false,
        })) || [], ["isCreditDef", "salesDate", "storeCode", "slipNo"]) : [];
        this.creditDefTransList =
            this.creditDefTransList ?
                orderBy(this.creditDefTransList.map(item => ({
                      ...item,
                      isCheckout: this.payCreditDefInfo.defDiv === "G" ? item.isTeamChkout : item.rresveStatus === "CHECKOUT",
                    })),
                    ["visitDate", "startTime", "startCourse", "visitSno"],
                    ["desc"]
                ).filter(item => !item.isCheckout) :
                [];
        this.payCreditDefInfo.payAmt = 0;
        this.initFocusing(this.payDiv);
      } catch (error) {
        console.error("getPayInfoApi ERROR =====>", error);
        throw new Error(error);
      }
    },
    async onCreditDefByTransButtonClicked() {
      const {
        value: data
      } = await getCreditDefTrans({
        defDiv: this.payCreditDefInfo.defDiv,
        isGolfLink: this.payCreditDefInfo.isGolfLink,
        rresveNo: this.popupData.rresveNo,
        visitDate: this.payCreditDefInfo.visitDate,
        visitName: this.payCreditDefInfo.visitName,
      });
      this.creditDefTransList =
          orderBy(
              // 같은 팀 또는 같은 객실 예약은 대내후불 이월 대상이 안되도록 Filter.
              data
                  .map(item => ({
                    ...item,
                    isCheckout: this.payCreditDefInfo.defDiv === "G" ? item.isTeamChkout : item.rresveStatus === "CHECKOUT",
                  }))
                  .filter(item =>
                      (this.popupData?.chkinId ? item.chkinId !== this.popupData.chkinId : true) &&
                      (this.popupData?.rresveNo ? item.rresveNo !== this.popupData.rresveNo : true) &&
                      !item.isCheckout),
              ["visitDate", "startTime", "startCourse", "visitSno"], ["desc"]);
    },
    initialize: async function () {
      try {
        if (this.storeCode === "FRONT") {
          this.payCreditDefInfo.isGolfLink = false;
        }
        await this.fetchData();

        ({ value: this.bsnInfo } = await getStoreBsnMstInfo(this.storeCode));

        const { vanKind } = this.bsnInfo;

        this.van = vanKind;

        const placesOfCardPurchase = await GolfERPService.getPlacesOfCardPurchase(
            vanKind
        );

        this.confCardList = placesOfCardPurchase.map(
            ({ purchaseId, purchaseName, vanMappingList }) => {
              return {
                purchaseId: purchaseId,
                purchaseName: purchaseName,
                vanMapCode: vanMappingList[0].vanMapCode,
              };
            }
        );

        if (this.payDiv === "CASH") {
          this.cashReceived = Number(
              JSON.parse(JSON.stringify(this.payAmt.toString()))
          );
        }
      } catch (e) {
        if (e instanceof VanModuleCreateError) {
          // Van사 호출 모듈 생성에 실패하였습니다 팝업오픈
          await this.errorPopup(
              this.$t("vanModule.popupMessage.initializeVanModuleFail")
          );
        } else if (e instanceof Error) {
          await this.errorPopup(e.message);
        } else {
          await this.errorPopup(e);
        }
        console.error("initialize.error ====>", e);
        // 초기화 실패에 따라 정산 팝업의 기능이 사용 불가함으로 취소
        this.closePopup();
      }
    },
    clearCardInfo() {
      this.payCardInfo.approvalNo = null;
      this.payCardInfo.cardNo = null;
      this.payCardInfo.divideTerm = 0;
      this.payCardInfo.purchaseId = null;
      this.payCardInfo.purchaseName = null;
      this.payCardInfo.validTerm = null;
    },
    /**
     * payDiv(정산구분)에 따라 결제 방법 변경
     * @param {string} payDiv
     */
    pay: async function (payDiv) {
      try {

        this.initFocusOut(payDiv);
        // 정산 중일 진행 불가
        if (this.payProcessing) {
          return;
        }
        // 정산 진행중 flag 전환
        this.startPay();

        await this.reFetchData();

        // validate
        if (!this.validate(payDiv)) {
          this.endPay();
          return;
        }

        // 정산 진행 중에 값이 변하더라도 액션중에는 값이 변하지 않도록 DATA COPY
        const bsnDate = this.storeCode === "CFRONT" ? this.payDate : this.bsnDate;
        const salesDiv = this.salesDiv;
        const popupData = Object.assign({}, this.popupData);
        const storeCode = this.storeCode;
        const payCardInfo = Object.assign({}, this.payCardInfo);
        const payEasyInfo = Object.assign({}, this.payEasyInfo);
        const payCashInfo = Object.assign({}, this.payCashInfo);
        const payCreditInfo = Object.assign({}, this.payCreditInfo);
        const payDepositInfo = Object.assign({}, this.payDepositInfo);
        const payGiftInfo = Object.assign({}, this.payGiftInfo);
        const payTransInfo = Object.assign({}, this.payTransInfo);
        const paySelfInfo =  Object.assign({}, this.paySelfInfo);
        const payTicketInfo = Object.assign({}, this.payTicketInfo);
        let payAmt = this.payAmt;
        const { totalNoPayAmt, noPayAmt, remainPoint, ...totalTaxInfo } = Object.assign(
            {},
            this.payPopupInfo
        );

        // 대내후불 정산
        if (payDiv === "CREDIT_DEF") {
          const creditDefSalesGrid = this.$refs.creditDefSalesGrid;
          const creditDefTransGrid = this.$refs.creditDefTransGrid;

          creditDefSalesGrid.saveCell();

          const salesRecords = creditDefSalesGrid.getBatchCurrentViewRecords().filter(item => item.isSelect);
          const selectedRecords = creditDefTransGrid.getSelectedRecords();

          if (!(salesRecords.length > 0)) {
            this.errorToast("선택된 매출이 존재하지 않습니다");
            this.endPay();
            return;
          }

          if (salesRecords.filter(item => item.isCreditDef).length > 0) {
            this.errorToast("이미 대내후불 처리된 매출이 선택되었습니다");
            this.endPay();
            return;
          }

          if (selectedRecords.length > 1) {
            this.errorToast("이월 대상을 하나만 선택해 주시기 바랍니다");
            this.endPay();
            return;
          } else if (!(selectedRecords.length > 0)) {
            this.errorToast("이월 대상을 선택해 주시기 바랍니다");
            this.endPay();
            return;
          }

          if (selectedRecords[0].visitId && selectedRecords[0].isVisitorCheckout) {
            this.errorToast("해당 내장객은 체크아웃된 상태입니다");
            return;
          }

          if (this.storeCode === "CFRONT" && this.payCreditDefInfo.defDiv === "G") {
            if (selectedRecords[0]?.visitDate && this.payDate !== selectedRecords[0]?.visitDate) {
              this.errorToast("정산일자와 내장일자가 일치하지 않습니다");
              return;
            }
          }

          if (this.payCreditDefInfo.defDiv === "C") {
            if (
                !(await this.confirm(`${selectedRecords[0].roomNo}호 객실에 대내후불로 정산하시겠습니까?`))
            ) {
              return;
            }
          } else {
            if (
                !(await this.confirm(`
              일자: ${selectedRecords[0].visitDate}<br>
              시간: ${selectedRecords[0].startTime}<br>
              코스: ${commonCodesGetComName("COURSE_CODE", selectedRecords[0].startCourse)}<br>
              내장객: ${selectedRecords[0].visitName} 님에게 대내후불로 정산하시겠습니까?
            `))
            ) {
              return;
            }
          }

          const payRequest = {
            payDate: bsnDate,
            storeCode: storeCode,
            salesDivision: salesDiv,
            golfCabinDivision: this.bsnInfo.gcDiv,
            payPreOrFutureDivision: popupData.prpayFrpyDiv,
            payDivision: payDiv,
            payAmount: 0,
            taxAmount: 0,
            notaxAmount: 0,
            vatAmount: 0,
            serviceAmount: 0,
            remarks: this.payCreditDefInfo.remarks,
            businessId: this.bsnInfo.bsnId,
            posNo: getDeviceInfo().posNo,
            termId: this.bsnInfo.termId,
            approvedCardFlag: false,
            creditDefSlips: salesRecords.map(item => ({
              ...item,
              id: item.slipId,
              slipId: item.salesId,
            })),
            creditDefDiv:
                this.bsnInfo.gcDiv === "C" && this.payCreditDefInfo.defDiv === "G" ?
                    "ROOM_GOLF" :
                    this.bsnInfo.gcDiv === "C" && this.payCreditDefInfo.defDiv === "C" ?
                        "ROOM_ROOM" :
                        this.bsnInfo.gcDiv === "G" && this.payCreditDefInfo.defDiv === "C" ?
                            "GOLF_ROOM" :
                            this.bsnInfo.gcDiv === "G" && this.payCreditDefInfo.defDiv === "G" ?
                                "GOLF_GOLF" :
                                null
            ,
            visitId: this.bsnInfo.gcDiv === "G" ? popupData.visitId : null,
            stayId: this.bsnInfo.gcDiv === "C" ? this.reprsntRoomInfo.stayId : null,
            transVisitId: this.payCreditDefInfo.defDiv === "G" ? selectedRecords[0].visitId : null,
            transStayId: this.payCreditDefInfo.defDiv === "C" ? selectedRecords[0].stayId : null,
          };

          if (payRequest.creditDefDiv === null) {
            this.errorToast("대내후불구분을 확인해 주시기 바랍니다");
            this.endPay();
            return;
          }

          await this.sendPayResultToServer(payRequest);
          this.isPaid = true;
          this.operationMessage = "정산 처리되었습니다.";
          await this.paySuccess(null, salesDiv, payDiv);
          return;
        }

        // 오버정산
        // 영업매출일때만 가능
        // 영업매출일때도 Front 일때만 가능

        // 영업매출때 선수 정산시 선수금 보다 더 사용시
        // 선수금 전액까지만 사용 가능하다는 팝업
        if (salesDiv !== "DEPOSIT") {
          if (salesDiv === "CREDIT") {
            if ((this.popupData.payIds || []).length > 0 && payAmt !== noPayAmt) {
              this.errorPopup("미수회입은 미정산액 전체만 정산 가능합니다");
              return;
            }
          }
          if (payAmt > totalNoPayAmt) {
            switch (salesDiv) {
              case "SALES":
                if (storeCode !== "FRONT") {
                  await this.errorPopup(
                      this.$t("vanModule.popupMessage.cannotPayOver")
                  );
                  this.endPay();
                  return;
                }
                break;
              case "CREDIT":
                await this.errorPopup(
                    this.$t("vanModule.popupMessage.cannotPayOver")
                );
                this.endPay();
                return;
            }

            if (this.popupData.prpayFrpyDiv === "0") {
              if (
                  !(await this.confirm(
                      "결제금액이 미정산액보다 큽니다. Over 정산 하시겠습니까?"
                  ))
              ) {
                this.endPay();
                return;
              }
            } else {
              this.infoToast("결제금액이 이용금액보다 큽니다!!<br>(OVER 정산할 수 없습니다.)");
              this.endPay();
              return;
            }

            if (payDiv === "DEPOSIT") {
              if (payAmt > payDepositInfo.depositBalanceAmount) {
                if (
                    await this.confirm(
                        `남은 선수금 잔액(` +
                        numberWithCommas(payDepositInfo.depositBalanceAmount) +
                        `)을 모두 사용하시겠습니까?`
                    )
                ) {
                  payAmt = payDepositInfo.depositBalanceAmount;
                } else {
                  this.endPay();
                  return;
                }
              }
            }
          } else {
            if (payDiv === "DEPOSIT") {
              if (payAmt > payDepositInfo.depositBalanceAmount) {
                if (
                    await this.confirm(
                        `남은 선수금 잔액(` +
                        numberWithCommas(payDepositInfo.depositBalanceAmount) +
                        `)을 모두 사용하시겠습니까?`
                    )
                ) {
                  payAmt = payDepositInfo.depositBalanceAmount;
                } else {
                  this.endPay();
                  return;
                }
              }
            }
            if (payDiv === 'POINT') {
              const isUsePointOverRemain = payAmt > remainPoint;
              if(isUsePointOverRemain) {
                this.infoToast("사용 포인트가 잔여 포인트보다 큽니다!!<br>(OVER 정산할 수 없습니다.)");
                this.endPay();
                return;
              }
            }
          }
        }
        // 지불 금액과 현재 총 세금액(남은 세금액 아님)을 이용하여
        // 현재 계산할 세금액 계산
        const calculatedTaxInfo =
            this.salesDiv !== "DEPOSIT"
                ? await GolfERPService.fetchPayTax({
                  currentPayAmount: payAmt,
                  totalTaxAmount: totalTaxInfo.taxAmt,
                  totalNoTaxAmount: totalTaxInfo.freeAmt,
                  totalVatAmount: totalTaxInfo.vatAmt,
                  totalServiceAmount: totalTaxInfo.svcAmt,
                })
                : await GolfERPService.fetchDepositIncomeTax(
                    popupData.notaxCode,
                    payAmt
                );

        const payRequest = {
          payId: popupData.payId,
          payIds: popupData.payIds,
          depositId: popupData.depositId,
          payDate:
              salesDiv === "CREDIT"
                  ? moment(this.returnDate).format("YYYY-MM-DD")
                  : bsnDate,
          storeCode: storeCode,
          salesDivision: salesDiv,
          visitId: this.bsnInfo.gcDiv === "G" ? popupData.visitId : null,
          stayId: this.bsnInfo.gcDiv === "C" ? (salesDiv === "CREDIT" ? popupData.stayId : this.reprsntRoomInfo.stayId) : null,
          salesId: popupData.salesId,
          golfCabinDivision: this.bsnInfo.gcDiv, // 객실 구분 미 개발에 따른 하드코딩
          payPreOrFutureDivision: popupData.prpayFrpyDiv,
          payDivision: payDiv,
          approvalNo: null,
          payAmount: payAmt,
          taxAmount: calculatedTaxInfo.taxAmount,
          notaxAmount: calculatedTaxInfo.notaxAmount,
          vatAmount: calculatedTaxInfo.vatAmount,
          serviceAmount: calculatedTaxInfo.serviceAmount,
          remarks: null,
          /** @type {{businessId: Number, posNo: String, termId: String} | null} */
          businessId: this.bsnInfo.bsnId,
          posNo: getDeviceInfo().posNo,
          termId: this.bsnInfo.termId,
          tid: null,
          notaxCode: popupData.notaxCode,
          /** @type {{depositId: String, useDate: String} | null} */
          depositUse: null,
          approvedCardFlag: payCardInfo.approvalFlag,
          approvedCard: null,
          deposit: popupData.deposit,
          indctTrgetFlag: popupData.indctTrgetFlag,
          creditReturnRemarks: this.creditReturnRemarks,
        };

        const addTransactionRequest = {
          van: this.van,
          payDivision: null,
          approvalDivision: "OK",
          totalAmount: payAmt,
          taxAmount: calculatedTaxInfo.taxAmount,
          vatAmount: calculatedTaxInfo.vatAmount,
          notaxAmount: calculatedTaxInfo.notaxAmount,
          serviceAmount: calculatedTaxInfo.serviceAmount,
          divideTerm: null,
          cardProof: null,
          proofKind: null,
          proofNo: null,
          approvalNo: null,
          approvalDateTime: null,
          cardNo: null,
          purchaseId: null,
          purchaseName: null,
          issueCompanyName: null,
          validTerm: null,
          storeCode: this.storeCode,
          businessId: this.bsnInfo.bsnId,
          posNo: getDeviceInfo().posNo,
          termId: this.bsnInfo.termId,
          posBsnCode: this.bsnInfo.posBsnCode,
          posSerialNo: this.bsnInfo.posSerialNo,
          payDate:
              salesDiv === "CREDIT"
                  ? moment(this.returnDate).format("YYYY-MM-DD")
                  : bsnDate,
        };

        // VAN 사를 이용한 결제
        switch (payDiv) {
          case "CARD":
            // 기정산 거래의 경우 VAN 사 이용하지 않음에 따라 직접 입력
            if (payCardInfo.approvalFlag) {
              // 서버의 현재 일시 호출
              const now = await getTodayNavigationDate("YYYY-MM-DD hh:mm:ss");
              payRequest.approvedCard = {
                approvalNo: payCardInfo.approvalNo,
                approvalDateTime: now,
                cardNo: payCardInfo.cardNo,
                divideTerm: payCardInfo.divideTerm,
                purchaseId: payCardInfo.purchaseId,
                purchaseName: payCardInfo.purchaseName,
                issueCompanyName: "Easy Check",
                validTerm: payCardInfo.validTerm,
              };
            } else {
              addTransactionRequest.divideTerm = payCardInfo.divideTerm;
              addTransactionRequest.payDivision = "CARD";

              const { tid, message, status } = await this.payVan(
                  addTransactionRequest
              );
              if (status !== "APPROVED") {
                this.operationMessage = message;
                this.endPay();
                return;
              }
              payRequest.tid = tid;
            }
            break;
          case "EASY_PAY":
            addTransactionRequest.divideTerm = payEasyInfo.divideTerm;
            addTransactionRequest.payDivision = "EASY_PAY";
            try {
              const { tid, message, status } = await this.payVan(
                  addTransactionRequest
              );
              if (status !== "APPROVED") {
                this.operationMessage = message;
                this.endPay();
                return;
              }
              payRequest.tid = tid;
            } catch (e) {
              console.error(e);
            }
            break;
          case "CASH":
          case "POINT":
            break;
          case "CREDIT":
            payRequest.remarks = payCreditInfo.remarks;
            payRequest.creditTitle1 = payCreditInfo.creditTitle1;
            payRequest.creditTitle2 = payCreditInfo.creditTitle2;
            payRequest.creditAccount = payCreditInfo.creditAccount;
            break;
          case "DEPOSIT":
            payRequest.giftNo = this.payDepositInfo.cardNo;
            payRequest.depositUse = {
              depositId: payDepositInfo.depositId,
              incomId: payDepositInfo.incomId,
              useDate:
                  salesDiv === "CREDIT"
                      ? moment(this.returnDate).format("YYYY-MM-DD")
                      : this.storeCode === "CFRONT" ? this.payDate : this.bsnDate,
            };

            break;
          case "GIFT":
            payRequest.payDivision = payGiftInfo.giftDiv;
            payRequest.giftNo = payGiftInfo.giftNo;
            break;
          case "TRANS":
            payRequest.remarks = payTransInfo.remarks;
            break;
          case "SELF":
            payRequest.remarks = paySelfInfo.remarks;
            payRequest.drctr = paySelfInfo.paySelfInfoPayIndicator;
            break;
          case "TICKET":
            payRequest.couponNo = payTicketInfo.ticketNumber;
            break;
          default:
            await this.errorPopup(
                this.$t("vanModule.popupMessage.invalidPayDiv")
            );
            this.endPay();
            return;
        }

        this.isPaid = true;
        this.operationMessage = "정산 처리되었습니다.";

        let cashReceiptRequest = {};

        // 현금영수증 발급
        if (payRequest.payDivision === "CASH" && payCashInfo.proofKind) {
          // 현금영수증 증빙 구분이 자진발급이 아니거나 자진발급이고 자진발급 거부시 VAN 결제모듈 호출
          this.updateAddTransactionRequestForCashReceipt(
              addTransactionRequest,
              payCashInfo
          );
          try {
            const { tid, message, status } = await this.payVan(
                addTransactionRequest
            );
            if (status === "SELFCANCEL") {
              this.operationMessage = message;
              this.endPay();
              return;
            }
            cashReceiptRequest = {
              tid: tid,
              referenceId: null,
              salesDivision: salesDiv,
            };

            if(salesDiv === "DEPOSIT") {
                const { approvalNo } = await GolfERPService.getPayTransaction(tid);
                payRequest.approvalNo = approvalNo;
            }

            // switch (salesDiv) {
            //   case "SALES":
            //     cashReceiptRequest.referenceId = payment.pay.id;
            //     break;
            //   case "CREDIT":
            //     cashReceiptRequest.referenceId = payment.creditReturn.id;
            //     break;
            // }
            //
            // await GolfERPService.postPayCashReceipt(cashReceiptRequest);
          } catch (e) {
            if (e instanceof Error) {
              if (e.message !== "CONFIRM") {
                await this.errorPopup(
                    `${e.message}<br/>현금영수증 발급에 실패하였습니다.`
                );

                this.operationMessage = e.message;
              }
            } else {
              await this.errorPopup(
                  `${e}<br/>현금영수증 발급에 실패하였습니다.`
              );

              this.operationMessage = e;
            }

            console.error(e);
            this.endPay();
            return;
          }
        } else if (
            payRequest.payDivision === "TRANS" &&
            payTransInfo.proofKind
        ) {
          // 현금영수증 증빙 구분이 자진발급이 아니거나 자진발급이고 자진발급 거부시 VAN 결제모듈 호출
          this.updateAddTransactionRequestForCashReceipt(
              addTransactionRequest,
              payTransInfo
          );
          try {
            const { tid, message, status } = await this.payVan(
                addTransactionRequest
            );
            if (status === "SELFCANCEL") {
              this.operationMessage = message;
              this.endPay();
              return;
            }
            cashReceiptRequest = {
              tid: tid,
              referenceId: null,
              salesDivision: salesDiv,
            };

            if(salesDiv === "DEPOSIT") {
              const { approvalNo } = await GolfERPService.getPayTransaction(tid);
              payRequest.approvalNo = approvalNo;
            }

            // switch (salesDiv) {
            //   case "SALES":
            //     cashReceiptRequest.referenceId = payment.pay.id;
            //     break;
            //   case "CREDIT":
            //     cashReceiptRequest.referenceId = payment.creditReturn.id;
            //     break;
            // }
            //
            // await GolfERPService.postPayCashReceipt(cashReceiptRequest);
          } catch (e) {
            if (e instanceof Error) {
              if (e.message !== "CONFIRM") {
                await this.errorPopup(
                    `${e.message}<br/>현금영수증 발급에 실패하였습니다.`
                );
              }

              this.operationMessage = e.message;
            } else {
              await this.errorPopup(
                  `${e}<br/>현금영수증 발급에 실패하였습니다.`
              );

              this.operationMessage = e;
            }

            console.error(e);
            this.endPay();

            return;
          }
        } else if (
            payRequest.payDivision === "DEPOSIT" &&
            payDepositInfo.proofKind
        ) {
          // 현금영수증 증빙 구분이 자진발급이 아니거나 자진발급이고 자진발급 거부시 VAN 결제모듈 호출
          this.updateAddTransactionRequestForCashReceipt(
              addTransactionRequest,
              payDepositInfo
          );
          try {
            const { tid, message, status } = await this.payVan(
                addTransactionRequest
            );
            if (status === "SELFCANCEL") {
              this.operationMessage = message;
              this.endPay();
              return;
            }
            cashReceiptRequest = {
              tid: tid,
              referenceId: null,
              salesDivision: salesDiv,
            };

            // switch (salesDiv) {
            //   case "SALES":
            //     cashReceiptRequest.referenceId = payment.pay.id;
            //     break;
            //   case "CREDIT":
            //     cashReceiptRequest.referenceId = payment.creditReturn.id;
            //     break;
            // }
            //
            // await GolfERPService.postPayCashReceipt(cashReceiptRequest);
            //
            // payment.payMethod.depositUse.proofKind = payDepositInfo.proofKind;
          } catch (e) {
            if (e instanceof Error) {
              if (e.message !== "CONFIRM") {
                await this.errorPopup(
                    `${e.message}<br/>현금영수증 발급에 실패하였습니다.`
                );
              }

              this.operationMessage = e.message;
            } else {
              await this.errorPopup(
                  `${e}<br/>현금영수증 발급에 실패하였습니다.`
              );

              this.operationMessage = e;
            }

            console.error(e);
            this.endPay();

            return;
          }
        }
        // 정산 API 호출
        const payment = await this.sendPayResultToServer(payRequest);

        // 현금영수증 발급후 처리.
        // 현금영수증 발급 실패시 현금 정산 안되도록 하기 위한 처리.
        if (payRequest.payDivision === "CASH" && payCashInfo.proofKind) {
          switch (salesDiv) {
            case "SALES":
              cashReceiptRequest.referenceId = payment.pay.id;
              break;
            case "CREDIT":
              cashReceiptRequest.referenceId = payment.creditReturn.id;
              break;
          }

          await GolfERPService.postPayCashReceipt(cashReceiptRequest);
        } else if (
            payRequest.payDivision === "TRANS" &&
            payTransInfo.proofKind
        ) {
          switch (salesDiv) {
            case "SALES":
              cashReceiptRequest.referenceId = payment.pay.id;
              break;
            case "CREDIT":
              cashReceiptRequest.referenceId = payment.creditReturn.id;
              break;
          }

          await GolfERPService.postPayCashReceipt(cashReceiptRequest);
        } else if (payRequest.payDivision === "DEPOSIT" && payDepositInfo.proofKind) {
          switch (salesDiv) {
            case "SALES":
              cashReceiptRequest.referenceId = payment.pay.id;
              break;
            case "CREDIT":
              cashReceiptRequest.referenceId = payment.creditReturn.id;
              break;
          }

          await GolfERPService.postPayCashReceipt(cashReceiptRequest);

          payment.payMethod.depositUse.proofKind = payDepositInfo.proofKind;
        }

        await this.paySuccess(payment, salesDiv, payDiv);
      } catch (e) {
        if (e instanceof BaseError) {
          this.operationMessage = e?.getData() ? e.getData() : e.message;
          console.error(e);
        } else {
          this.operationMessage = e?.message ? e?.message : e;
        }

        if (this.operationMessage !== "CONFIRM") {
          await this.errorPopup(
              "정산에 실패하였습니다.<br/>" + this.operationMessage
          );
        }
      } finally {
        if (this.payProcessing) {
          this.endPay();
        }
      }
    },

    updateAddTransactionRequestForCashReceipt(
        addTransactionRequest,
        payCashInfo
    ) {
      addTransactionRequest.cardProof = payCashInfo.cardProof;
      addTransactionRequest.proofKind = payCashInfo.proofKind;
      addTransactionRequest.proofNo = payCashInfo.proofNo;
      addTransactionRequest.payDivision = "CASH";
    },

    onPayCardInfoCardNoChanged(args) {
      if (args.itemData) {
        this.payCardInfo.purchaseName = args.itemData.purchaseName;
      }
    },

    async sendPayResultToServer(payRequest) {
      try {
        return await GolfERPService.postPay(payRequest);
      } catch (e) {
        if (payRequest.payDivision === "CARD" && !!payRequest.tid) {
          const approvedPayTransaction = await GolfERPService.getPayTransaction(
              payRequest.tid
          );

          // 일반 승일일 경우만 결제 취소
          if (
              approvedPayTransaction.approvalDivision === "OK"
          ) {
            await this.errorPopup(
                (approvedPayTransaction.message !== null && approvedPayTransaction.message.trim() ? approvedPayTransaction.message.trim() : "서버 요청중 에러가 발생하였습니다.") +
                "<br/> 결제 취소를 진행합니다."
            );
            const addTransactionRequest = this.getCancelTransactionRequestByApprovedPayTransaction(
                approvedPayTransaction
            );
            await this.payVan(addTransactionRequest);
            return;
          }
        }

        if (payRequest.payDivision === "EASY_PAY" && !!payRequest.tid) {
          const approvedPayTransaction = await GolfERPService.getPayTransaction(
              payRequest.tid
          );

          // 일반 승일일 경우만 결제 취소
          if (
              approvedPayTransaction.approvalDivision === "OK"
          ) {
            await this.errorPopup(
                (approvedPayTransaction.message !== null && approvedPayTransaction.message.trim() ? approvedPayTransaction.message.trim() : "서버 요청중 에러가 발생하였습니다.") +
                "<br/> 결제 취소를 진행합니다."
            );
            const addTransactionRequest = this.getCancelTransactionRequestByApprovedPayTransaction(
                approvedPayTransaction
            );
            await this.payVan(addTransactionRequest);
            return;
          }
        }

        if (e instanceof Error) {
          this.operationMessage = e.message;
          console.error(e);
        } else {
          this.operationMessage = e;
        }

        throw e;
      }
    },

    getLatestCancelTransactionRequestByApprovedPayTransaction(approvedPayTransaction) {
      return {
        van: approvedPayTransaction.van,
        payDivision: approvedPayTransaction.payDivision,
        approvalDivision: "LATEST_CANCEL",
        totalAmount: approvedPayTransaction.totalAmount,
        taxAmount: approvedPayTransaction.taxAmount,
        vatAmount: approvedPayTransaction.vatAmount,
        notaxAmount: approvedPayTransaction.notaxAmount,
        serviceAmount: approvedPayTransaction.serviceAmount,
        proofKind: approvedPayTransaction.proofKind,
        proofNo: this.payCashInfo.proofNo,
        divideTerm: approvedPayTransaction.divideTerm,
        approvalNo: approvedPayTransaction.approvalNo,
        approvalDateTime: approvedPayTransaction.insertDateTime,
        saleNo: approvedPayTransaction.saleNo,
        storeCode: this.storeCode,
        businessId: approvedPayTransaction.businessId,
        posNo: getDeviceInfo().posNo,
        termId: approvedPayTransaction.termId,
        payDate: this.storeCode === "CFRONT" ? this.payDate : this.bsnDate,
      };
    },
    getCancelTransactionRequestByApprovedPayTransaction(
        approvedPayTransaction
    ) {
      return {
        van: approvedPayTransaction.van,
        payDivision: approvedPayTransaction.payDivision,
        approvalDivision: "CANCEL",
        totalAmount: approvedPayTransaction.totalAmount,
        taxAmount: approvedPayTransaction.taxAmount,
        vatAmount: approvedPayTransaction.vatAmount,
        notaxAmount: approvedPayTransaction.notaxAmount,
        serviceAmount: approvedPayTransaction.serviceAmount,
        proofKind: approvedPayTransaction.proofKind,
        proofNo: this.payCashInfo.proofNo,
        divideTerm: approvedPayTransaction.divideTerm,
        approvalNo: approvedPayTransaction.approvalNo,
        approvalDateTime: approvedPayTransaction.approvalDateTime,
        saleNo: approvedPayTransaction.saleNo,
        storeCode: this.storeCode,
        businessId: approvedPayTransaction.businessId,
        posNo: getDeviceInfo().posNo,
        termId: approvedPayTransaction.termId,
        payDate: moment(approvedPayTransaction.approvalDateTime).format("YYYY-MM-DD")
      };
    },

    async payVan(addTransactionRequest) {

      const { tid, status, message } = await openERPPay(addTransactionRequest);

      switch (status) {
        case "APPROVED":
        case "CANCELED":
        case "SELFCANCEL":
          return { tid, status, message };
        case "READY":
        case "REQUEST":
          throw new Error("결제가 취소되었습니다.");
        case "FAIL":
          throw new Error(message);
        case "LATEST_CANCEL": // 망상취소
          await this.errorPopup(message + "<br/>망상 취소를 진행합니다.");
          // eslint-disable-next-line no-case-declarations
          const approvedPayTransactionByLatestCancel = await GolfERPService.getPayTransaction(
              tid
          );
          // eslint-disable-next-line no-case-declarations
          const addTransactionRequestByLatestCancel = this.getLatestCancelTransactionRequestByApprovedPayTransaction(
              approvedPayTransactionByLatestCancel
          );
          await this.payVan(addTransactionRequestByLatestCancel);
          return { tid, status, message };
        case "PURCHASE_CODE_INVALID":
          await this.errorPopup(message + "<br/>결제 취소를 진행합니다.");
          // eslint-disable-next-line no-case-declarations
          const approvedPayTransaction = await GolfERPService.getPayTransaction(
              tid
          );
          // eslint-disable-next-line no-case-declarations
          const addTransactionRequest = this.getCancelTransactionRequestByApprovedPayTransaction(
              approvedPayTransaction
          );
          await this.payVan(addTransactionRequest);
          return { tid, status, message };
      }
    },
    async paySuccess(payment, salesDiv, payDiv) {
      // this.endPay();

      let id = null;
      let prtFlag = false;

      switch (payDiv) {
        case "CARD":
        case "EASY_PAY":
          id = payment?.payMethod?.payCard?.id;

          break;
        case "CASH":
        case "TRANS":
          if (salesDiv === "SALES") {
            id = payment.pay.id;
          } else if (salesDiv === "CREDIT") {
            id = payment.creditReturn.id;
          }

          break;

        case "CREDIT":
          id = payment.pay.id;

          break;

        case "DEPOSIT":
          id = payment.payMethod.depositUse.useId;
          this.searchDepositBalanceStatus();

          break;

        case "GIFT":
        case "POINT":
        case "TICKET":
          id = payment.pay.id;

          break;
      }

      if (id) {
        if(this.printFlag){
          if(commonCodesGetStandardInfo('payReceiptPrtFlag')){
            if (await this.confirm("영수증 출력 하시겠습니까?")) {
              prtFlag = true;
            }
          }
          else {
            prtFlag = true;
          }
        }
      }

      if (prtFlag) {
        this.print(id, payDiv === "EASY_PAY" ? "CARD" : payDiv, salesDiv);
      }

      // if (payment?.payMethod?.depositUse?.proofKind) {
      //   this.print(salesDiv === "SALES" ? payment.pay.id : (salesDiv === "CREDIT" ? payment.creditReturn.id : null), "CASH");
      // }

      this.clearCardInfo();
      await this.fetchData();
      if (this.payPopupInfo.noPayAmt === 0) {
        this.closePopup();
      }
    },
    /**
     *
     * @param {string} payDiv - 정산구분
     */
    validate(payDiv) {
      switch (payDiv) {
        case "CARD":
          return this.validateFormRefs(this.payCardInfoValidateRefList);
        case "EASY_PAY":
          return this.validateFormRefs(this.payEasyInfoValidateRefList);
        case "CASH":
          return this.validateFormRefs(this.payCashInfoValidateRefList);
        case "CREDIT":
          return this.validateFormRefs(this.payCreditInfoValidateRefList);
        case "DEPOSIT":
          return this.validateFormRefs(this.payDepositInfoValidateRefList);
        case "GIFT":
          return this.validateFormRefs(this.payGiftInfoValidateRefList);
        case "TRANS":
          return this.validateFormRefs(this.payTransInfoValidateRefList);
        case "CREDIT_DEF":
        case "POINT":
          return true;
        case "SELF":
          return this.validateFormRefs(this.paySelfInfoValidateRefList);
        case "TICKET":
          return this.validateFormRefs(this.payTicketInfoValidateRefList);
        default:
          this.errorToast(this.$t("vanModule.popupMessage.invalidPayDiv"));
          return false;
      }
    },
    async getReceiptInfo(payId, payDiv) {
      try {
        const {
          value: { receiptInfo },
        } = await getReceiptInfo(payId, payDiv, this.salesDiv);

        if (payDiv === "CASH" || payDiv === "TRANS") {
          receiptInfo.proofName = commonCodesGetComName(
              "PROOF_KIND",
              receiptInfo.proofKind
          );
        }

        this.receiptInfo = receiptInfo;
      } catch (e) {
        console.error("getReceiptInfo.err.===>", e);
        throw e;
      }
    },
    async getDeposituseReceipt(id) {
      this.depositUseReceipt = await GolfErpAPI.fetchDepositUseReceipt(id);
    },
    async print(id, payDiv, salesDiv, signYN) {
      try {
        if (
            !this.printFlag ||
            payDiv === "CREDIT" ||
            (payDiv === "TRANS" && !this.payTransInfo.proofKind)
        ) {
          return;
        }

        if (id === null) {
          return;
        }

        // 디바이스 설정의 Bill 프린터 사용여부가 false이면 Bill 프린터 출력 안됨.
        let receiptCode = payDiv.toUpperCase() + "_RECEIPT";


        let printSetData = null;

        if (payDiv.toUpperCase() === "DEPOSIT") {
          await this.getDeposituseReceipt(id);

          let depositUseList = JSON.parse(
              JSON.stringify(this.depositUseReceipt.depositUseList)
          );

          depositUseList.forEach((data) => {
            data.depositUseDiv =
                data.depositUseDiv === undefined || data.depositUseDiv === null
                    ? ""
                    : commonCodesGetComName("DEPOSIT_USE_DIV", data.depositUseDiv);
          });

          let payReturnId = null;

          if (salesDiv === "SALES") {
            payReturnId = this.depositUseReceipt.payId;
          } else if (salesDiv === "CREDIT") {
            payReturnId = this.depositUseReceipt.returnId;
          }

          if (payReturnId) {

            receiptCode = "DEPOSIT_CASH_RECEIPT",
                await this.getReceiptInfo(payReturnId, "CASH");
          }

          printSetData = {
            printDt: this.depositUseReceipt.printDt,
            cardNo:
                this.depositUseReceipt.cardNo === undefined ||
                this.depositUseReceipt.cardNo === null
                    ? ""
                    : this.depositUseReceipt.cardNo,
            depositAmt: this.depositUseReceipt.depositAmt,
            sumUseAmt: this.depositUseReceipt.sumUseAmt,
            useAmt: this.depositUseReceipt.useAmt,
            balanceAmt: this.depositUseReceipt.balanceAmt,
            totalUseAmt: this.depositUseReceipt.totalUseAmt,
            useDate: this.depositUseReceipt.useDate,
            startCourse:
                this.depositUseReceipt.startCourse === undefined ||
                this.depositUseReceipt.startCourse === null
                    ? ""
                    : commonCodesGetComName(
                        "COURSE_CODE",
                        this.depositUseReceipt.startCourse
                    ),
            startTime:
                this.depositUseReceipt.startTime === undefined ||
                this.depositUseReceipt.startTime === null
                    ? ""
                    : this.depositUseReceipt.startTime,
            depositUseDiv:
                this.depositUseReceipt.depositUseDiv === undefined ||
                this.depositUseReceipt.depositUseDiv === null
                    ? ""
                    : commonCodesGetComName(
                        "DEPOSIT_USE_DIV",
                        this.depositUseReceipt.depositUseDiv
                    ),
            ccName: this.bizMstInfo.ccName,
            bizNo: this.bizMstInfo.bizNo,
            rprsntvName: this.bizMstInfo.rprsntvName,
            telNo: getFormattedTelNumber(this.bizMstInfo.telNo, ")"),
            faxNo: getFormattedTelNumber(this.bizMstInfo.faxNo, ")"),
            depositUseList: depositUseList,
            memberName: this.depositUseReceipt.memberName,
            contactTel: getFormattedTelNumber(
                this.depositUseReceipt.contactTel
            ),
            addr: this.depositUseReceipt?.addr,
            approvalDt: this.receiptInfo?.approvalDt,
            approvalNo: this.receiptInfo?.approvalNo,
            cashTitle:
                (this.receiptInfo?.approvalDiv === "OK"
                    ? "현금승인 "
                    : "현금취소 ") +
                (this.receiptInfo?.proofName
                    ? "(" + this.receiptInfo?.proofName + ")"
                    : "(미발급)"),
            merchantName: this.depositUseReceipt?.bizName,
            proofNo: this.receiptInfo?.proofNo,
            rePrintDt: null,
            totAmt: this.receiptInfo?.approvalAmt,
            taxAmt: this.receiptInfo?.taxAmt,
            vatAmt: this.receiptInfo?.vatAmt
          };
        } else {
          await this.getReceiptInfo(id, payDiv);

          printSetData = {
            storeName: this.commonCodesGetComName(
                "STORE_CODE",
                this.receiptInfo.storeCode
            ),
            bsnDate: this.receiptInfo.payDate,
            lockerNo: this.receiptInfo.lockerNo,
            slipNo: this.receiptInfo.slipNo,
            cardKind: this.receiptInfo.issuCompanyName,
            cardNo: this.receiptInfo.cardNo,
            validTerm: this.receiptInfo.validTerm,
            approvalDt: this.receiptInfo.approvalDt,
            purcName: this.receiptInfo.purcName,
            rePrintDt: null,
            totAmt: this.receiptInfo.approvalAmt,
            taxAmt: this.receiptInfo.taxAmt,
            noTaxAmt: this.receiptInfo.notaxAmt,
            svcAmt:
                this.receiptInfo.svcAmt === "0" ? null : this.receiptInfo.svcAmt,
            vatAmt: this.receiptInfo.vatAmt,
            approvalNo: this.receiptInfo.approvalNo,
            divideTerm: this.receiptInfo.divideTerm,
            merchantNo: this.receiptInfo.merchantNo,
            merchantName: this.receiptInfo.bizName,
            bizNo: this.receiptInfo.bizNo,
            rprsntvName: this.receiptInfo.rprsntvName,
            addr: this.receiptInfo.addr,
            telNo: getFormattedTelNumber(this.receiptInfo.telNo),
            faxNo: getFormattedTelNumber(this.receiptInfo.faxNo),
            proofName: this.receiptInfo.proofName,
            proofNo: this.receiptInfo.proofNo,
            cardTitle:
                this.receiptInfo.approvalDiv === "OK" ||
                this.receiptInfo.approvalDiv === "T_OK"
                    ? "매출전표 (신용승인)"
                    : "매출전표 (신용취소)",
            cashTitle:
                (this.receiptInfo.approvalDiv === "OK"
                    ? "현금승인 "
                    : "현금취소 ") +
                (this.receiptInfo.proofName
                    ? "(" + this.receiptInfo.proofName + ")"
                    : "(미발급)"),
            transTitle:
                (this.receiptInfo.approvalDiv === "OK"
                    ? "계좌이체승인 "
                    : "계좌이체취소 ") +
                (this.receiptInfo.proofName
                    ? "(" + this.receiptInfo.proofName + ")"
                    : "(미발급)"),
            signYn: signYN,
            giftNo: this.receiptInfo.giftNo,
            payAmt: this.receiptInfo.payAmt,
            memberName: this.receiptInfo.memberName,
            contactTel: getFormattedTelNumber(
                this.receiptInfo.contactTel
            ),
            printDt: this.receiptInfo.printDt,
            giftTitle: this.receiptInfo.approvalDiv === "OK" ? "상품권 사용 내역서" : "상품권 취소 내역서",
          };
        }

        const config = {
          "useBillPrinter": true,
          "useKitchenPrinter": false,
        };
        await BillPrintUtil.getTsConfReceiptInfo.call(this, receiptCode, true,printSetData, config);

      } catch (e) {
        if (e instanceof Error) {
          this.operationMessage += `\n[영수증 발급 에러] ${e.message}`;
        } else {
          this.operationMessage += `\n[영수증 발급 에러] ${e}`;
        }
        this.errorToast(
            this.$t("vanModule.popupMessage.printReceiptFail")
        );
      }
    },
    async searchDepositBalanceStatus() {
      if (!this.depositBalanceSearchCondition.condition) {
        return this.errorToast(this.$t("main.popupMessage.noSearchKeyword"));
      }

      await GolfERPService.fetchDepositsBalanceStatus(
          this.depositBalanceSearchCondition.condition,
          this.depositBalanceSearchCondition.showEmptyBalanceAmount
      ).then((data) => {
        this.depositBalanceStatusList = data;

        if (this.depositBalanceStatusList.length === 0) {
          this.focusToDepositSearchInput(); // TODO : focus 자등으로 안 잡힘.
          this.errorToast(this.$t("main.popupMessage.noSearchResult"));
        }
      }).catch((e) => {
        console.error(e);
        this.depositBalanceStatusList = [];
      });
    },

    depositGridRowSelected(args) {
      this.payDepositInfo.depositId = args.data.depositId;
      this.payDepositInfo.incomId = args.data.incomId;
      this.payDepositInfo.depositBalanceAmount = args.data.balanceAmount;
      this.payDepositInfo.cardNo = args.data.cardNo;

      if (args.data.indctTrgetFlag) {
        this.payDepositInfo.proofKind = "VOL";
        this.payDepositInfo.proofNo = null;
        this.payDepositInfo.cardProof = false;
      } else {
        this.payDepositInfo.proofKind = "";
        this.payDepositInfo.proofNo = null;
        this.payDepositInfo.cardProof = false;
      }
    },
    depositGridOnActionComplete() {
      this.$refs["depositGrid"].selectRow(0);
    },
    depositGridOnKeyPressed(args) {
      if (args.key === "Enter") {
        this.pay(this.payDiv);
      }
    },
    initFocusOut(payDiv) {
      switch (payDiv) {
        case "CARD":
          this.$refs["payCardInfoDivideTerm"].blur();
          return;
        case "EASY_PAY":
          this.$refs["payEasyInfoDivideTerm"].blur();
          return;
        case "CASH":
          this.$refs["payCashInfoPayAmt"].blur();
          return;
        case "CREDIT":
          this.$refs["payCreditInfoPayAmt"].blur();
          return;
        case "DEPOSIT":
          this.$refs["payDepositInfoPayAmt"].blur();
          return;
        case "CREDIT_DEF":
          this.$refs["payCreditDefInfoPayAmt"].blur();
          return;
        case "GIFT":
          this.$refs["payGiftInfoPayAmt"].blur();
          return;
        case "TICKET":
          this.$refs["payTicketInfoPayAmt"].blur();
          return;
        case "TRANS":
          this.$refs["payTransInfoPayAmt"].blur();
          return;
        case "SELF":
          this.$refs["paySelfInfoPayAmt"].blur();
          return;
      }
    },
    initFocusing(payDiv) {
      switch (payDiv) {
        case "CARD":
          if (["DEPOSIT","CREDIT"].includes(this.salesDiv)) {
            this.$refs["payCardInfoDivideTerm"].focus();
          } else {
            this.$refs["payCardInfoPayAmt"].focus();
          }
          return;
        case "EASY_PAY":
          this.$refs["payEasyInfoPayAmt"].focus();
          return;
        case "CASH":
          this.$refs["payCashInfoPayAmt"].focus();
          return;
        case "CREDIT":
          this.$refs["payCreditInfoPayAmt"].focus();
          return;
        case "DEPOSIT":
          this.$refs["payDepositInfoPayAmt"].focus();
          return;
        case "CREDIT_DEF":
          this.$refs["payCreditDefInfoPayAmt"].focus();
          return;
        case "GIFT":
          this.$refs["payGiftInfoPayAmt"].focus();
          return;
        case "TICKET":
          this.$refs["payTicketInfoTicketNumber"].focus();
          return;
        case "TRANS":
          this.$refs["payTransInfoPayAmt"].focus();
          return;
        case "SELF":
          this.$refs["paySelfInfoPayAmt"].focus();
          return;
      }
    },
    focusToEasyDivideTerm() {
      this.$refs["payEasyInfoDivideTerm"].focus();
    },
    focusToCardDivideTerm() {
      this.$refs["payCardInfoDivideTerm"].focus();
    },
    focusToCardNo() {
      setTimeout(() => {
        this.$refs["payCardInfoCardNo"].focusIn();
      }, 0);
    },
    focusToCardPurchase() {
      this.$refs["payCardInfoPurcId"].focusIn();
    },
    focusToCardValidTerm() {
      setTimeout(() => {
        this.$refs["payCardInfoValidTerm"].focusIn();
      }, 0);
    },
    focusToCardApprovalNo() {
      this.$refs["payCardInfoApprovalNo"].focusIn();
    },
    focusToCreditRemarks() {
      this.$refs["payCreditInfoRemarks"].focusIn();
    },
    focusToTicketNumber() {
      this.$refs["payTicketInfoTicketNumber"].focusIn();
    },
    focusToDepositSearchInput() {
      this.$refs["payDepositInfoPaySearch"].focusIn();
    },
    focusToSelfSearchInput() {
      this.$refs["paySelfInfoPayIndicator"].focusIn();
    },
    payButtonEnterKeyPressEvent(e) {
      if (this.payProcessing) {
        e.preventDefault();
      }
    },
    async rePayInfoSetting() {
      this.popupData.personFlag = false;

      await this.initialize();
    },
    onCreditDefSalesGridQueryCellInfo(args) {
      const {
        cell,
        column: {
          field,
        },
      } = args;

      if (["isSelect"].includes(field)) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    onCreditDefSalesGridHeaderSelectAllCheckboxClicked(args) {
      const {
        field,
        value,
      } = args;

      const grid = this.$refs.creditDefSalesGrid;
      if (field === "isSelect") {
        if (value) {
          const records = grid.getBatchCurrentViewRecords();

          // 전체 Select 체크시 이미 대내후불 처리된 매출은 선택 안되도록 하는 부분
          // 버그 : 아래와 같이 체크 해제시 헤더의 전체 Select는 계속 체크 상태이고 다시 클릭시 전체 해제가 안됨.
          // TODO : 헤더의 전체 Select 부분 해결시 아래 주석 해제.
          // if (records.length > 0) {
          //   records.forEach((record, idx) => {
          //     if (record.isCreditDef) {
          //       grid.updateCell(idx, field, false);
          //     }
          //   });
          // }

          this.payCreditDefInfo.payAmt = records.filter(item => !item.isCreditDef).map(item => item.totAmt).reduce((acc, cur) => acc + cur);
        } else {
          this.payCreditDefInfo.payAmt = 0;
        }
      }
    },
    async onCreditDefSalesGridCheckboxChanged(args) {
      const {
        columnName,
        rowData,
        // rowIndex,
        value,
      } = args;

      if (columnName === "isSelect") {
        if (value) {
          // 이미 대내후불 처리된 매출은 체크박스 선택 안되도록 하는 부분
          // TODO : 헤더의 전체 Select 부분 해결시 아래 주석 해제.
          // if (rowData["isCreditDef"]) {
          //   await this.$nextTick();
          //   this.$refs.creditDefSalesGrid.saveCell();
          //   this.$refs.creditDefSalesGrid.updateCell(rowIndex, columnName, false);
          //   return;
          // }
          this.payCreditDefInfo.payAmt += rowData["totAmt"] || 0;
        } else {
          this.payCreditDefInfo.payAmt -= rowData["totAmt"] || 0;
        }
      }
    },
    onCreditDefDivChange() {
      this.creditDefTransList = [];

      if (this.payCreditDefInfo.defDiv === "C") {
        this.payCreditDefInfo.isGolfLink = false;
      }
    },
  },
};
</script>

<style scoped>
.textarea >>> .e-input-group{box-sizing:border-box;width:100%!important;height:82px;padding:3px 6px 2px;margin:0;border:1px solid #ccc!important;background:none}
.textarea >>> .e-input-group:before,.textarea >>> .e-input-group:after{display:none}
.textarea >>> .e-input-group .e-input::selection{color: #FFF; background-color: #3390FF;}
.textarea >>> .e-input-group .e-input::placeholder{color:#bbb}
.textarea >>> .e-input-group.e-disabled .e-input{color:#bbb}
.textarea >>> .e-input-group textarea.e-input{padding:3px 6px 2px!important;resize:none}
.field > .content > .row{list-style-type:none;padding:0}
.tmpBtn{display:inline-block;margin-left:.5rem;padding:5px 8px 3px 23px;border:0;border-radius:3px;background:#2e7458 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAVCAYAAACzK0UYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA25pVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpkNzMyMmRmNi1jNmQ5LTQxNDUtYjNlMy1jODYzZWMzY2VkMDAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RUIyN0IyNERBRUI5MTFFOUI5MEY4RDJDNEI4NTYzRkQiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RUIyN0IyNENBRUI5MTFFOUI5MEY4RDJDNEI4NTYzRkQiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTggKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NDI2MThBMjVBREY5MTFFOTlDRkVFNDA2MjhEN0IyMTYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NDI2MThBMjZBREY5MTFFOTlDRkVFNDA2MjhEN0IyMTYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5dDdpUAAAAnUlEQVR42mL8//8/A60BEwMdwKglg88SFiLVBQOxKxD/AGIuIN4LxCuJtYSRiCQcBMTCQDwbSSwLiN8A8SqibAFZQgBPwyE+gwi9YExMnPzEIf6dmhHPjkOcjZqWHAXiODQxEP8qNSMeFvmg1PUViHmA+AgQuwNxMRC/okbE48JCQLwIiEUJqaXEEhAWBuKZhNQxjhb1o5bQDAAEGAAzWf0sqrQyqgAAAABJRU5ErkJggg==) no-repeat left center;color:#fff;font-family:돋움;font-size:12px;cursor:pointer}
.tmpBtn:hover,.tmpBtn:focus{background-color:#174e35}

body .body-data .data-content .title {width: 92px; padding-bottom: 0; font-size: 13px; font-family: 'NanumSquare-Regular'}
body .body-data .data-content .item {min-height: 29px; font-size: 16px; font-family: 'NanumSquare-Regular'}
body .body-data .data-content .content {width: calc((100% - 92px) + 1px)}

body .operationMessage {width: calc(100% - 100px)}

/* 정산 팝업 뉴 퍼블리싱 */
body .body-article .section-body > .body-article > .article-left > .newPay {display: flex; flex-direction: column; font-size: 13px; font-family: 'NanumSquare-Regular'}
body .body-article .section-body > .body-article > .article-left > .newPay > .item {display: flex; align-items: center; width: calc(100% - 20px); height: 50px; padding: 0 10px}
body .body-article .section-body > .body-article > .article-left > .newPay > .item > .title {width: 80px; color: #666}
body .body-article .section-body > .body-article > .article-left > .newPay > .item > .content {display: flex; align-items: center; flex: 1}
body .body-article .section-body > .body-article > .article-left > .newPay > .item > .content > .item {padding: 3px;}
body .body-article .section-body > .body-article > .article-left > .newPay > .item > .content > .item:not(:last-child) {margin-right: 10px}
body .body-article .section-body > .body-article > .article-left > .newPay > .item > .content > .takeTotAmtButton {box-sizing: border-box;width: 95px;height: 40px;padding-left: 25px;color: #fff;font-size: 13px;border: 0;border-radius: 3px;background-color: #2e7458;background-image: url('../../assets/images/common/money.png');background-repeat: no-repeat;background-position: 10px calc(50% + 1px);box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .05);cursor: pointer;}
body .body-article .section-body > .body-article > .article-left > .newPay > .item > .content > .takeTotAmtButton:hover {background-color: #174e35}
body .body-article .section-body > .body-article > .article-left > .newPay > .item > .content >>> input {box-sizing: border-box;box-shadow: none;min-width: 0;height: 40px;padding: 0 15px;margin: 0;border: 1px solid #e0e0e0;border-radius: 4px;background-color: #fff;color: #222;font-size: 16px;font-family: 'NanumSquare-Regular';}
body .body-article .section-body > .body-article > .article-left > .newPay > .item > .content >>> input::placeholder {font-size: 16px}
body .body-article .section-body > .body-article > .article-left > .newPay > .item > .content >>> input::selection {background-color: #3390FF !important; color: #fff !important}
body .body-article .section-body > .body-article > .article-left > .newPay > .item > .content >>> input[disabled],
body .body-article .section-body > .body-article > .article-left > .newPay > .item > .content > .disabled >>> input {color: #999 !important;background-color: #fafafa !important}
body .body-article .section-body > .body-article > .article-right .insertButton {box-sizing: border-box;width: 50px;height: 50px;border-radius: 3px;border: none;cursor: pointer;background-color: #444;font-size: 16px;font-family: 'NanumSquare-Bold';color: #fff;}
body .body-article .section-body > .body-article > .article-right .payButton {box-sizing: border-box;width: 50px;height: 50px;border-radius: 3px;border: none;cursor: pointer;background-color: #2e7458;font-size: 16px;font-family: 'NanumSquare-Bold';color: #fff;}

/* 체크박스 */
.ui-check {list-style: none; padding: 3px 0 2px 0; margin: 0 -10px; text-align: left;}
.ui-check::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.ui-check > li {float: left; padding: 7px 10px 8px 10px;}
.ui-check > li label {display: block; position: relative;}
.ui-check > li label input {box-shadow: none; position: absolute; top: 0; left: 0; width: 100%; height: 100%; padding: 0; margin: 0; border-radius: 0; opacity: 0; z-index: 10;}
.ui-check > li label input + .i-item {min-height: 19px; padding: 1px 0 0 30px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.ui-check > li label input + .i-item::before {display: block; box-sizing: border-box; position: absolute; top: 50%; left: 0; width: 20px; height: 20px; margin-top: -10px; border: 1px solid #e0e0e0; border-radius: 50%; background-color: #fff; content: '';}
.ui-check > li label input:checked + .i-item::before {border-color: #2e7458; background: #2e7458 url('../../assets/images/ui/check-type01.png') no-repeat center top;}
.ui-check > li label input:disabled + .i-item::before {background-color: #eee;}
.ui-check > li label input:checked:disabled + .i-item::before {border-color: #e0e0e0; background-color: #eee; background-position-y: bottom;}
.ui-check > li label input[type="checkbox"] + .i-item::before {border-radius: 3px;}
.ui-check > li label input[type="checkbox"]:checked + .i-item::before {background-image: url('../../assets/images/ui/check-type02.png');}
</style>
<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              {{ labels.visitDate }}
            </div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                    type="lookup-condition"
                    v-model="dateRange"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.crtfCode }}
            </div>
            <ul class="content">
              <div class="item">
                <ejs-multiselect
                    v-model="searchConditions.crtfCode"
                    cssClass="lookup-condition-multiselect"
                    :dataSource="searchOptions.crtfCode"
                    :fields="commonCodeFields"
                    placeHolder="전체"
                    width="240"
                />
              </div>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.visitName }}
            </div>
            <ul class="content">
              <li class="item input">
                <input-text
                    v-model.trim="searchConditions.memberName"
                    @focus="memberNoClear"
                    @keydown.native="onMemberNameEntered"
                />
              </li>
            </ul>
            <ul class="content">
              <li class="item button">
                <ul class="button">
                  <li class="search">
                    <erp-button
                        button-div="GET"
                        :ignore="isPopupOpened"
                        @click.native="openMemberPopup"
                    >
                      검색
                    </erp-button>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              v-on:click.native="onViewAwardCertificate"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true"/>
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">수상증명서 등록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="awardCertificate.shortcuts.add"
                      :shortcut="{key: 'F3'}"
                      @click.native="onAddClick"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      @click.native="print($event)"
                  >
                    인쇄
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      :ignore="isPopupOpened"
                      @click.native="excel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="awardCertificateGrid"
                  :allowExcelExport="true"
                  :allowPaging="true"
                  :allowGrouping="true"
                  :allowResizing="true"
                  :provides="grid"
                  :pageSettings="pageSettings"
                  :groupSettings="groupSettings"
                  :columns="awardCertificateGridColumn"
                  :dataSource="awardCertificateList"
                  @headerCellInfo="awardCertificateGridHeaderCellInfo"
                  @queryCellInfo="awardCertificateGridQueryCellInfo"
                  @recordClick="awardCertificateGridRecordClick"
                  @actionComplete="awardCertificateGridActionComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <member-select-popup
        v-if="isMemberSelectPopupOpen"
        ref="memberSelectPopup"
        :position="{ x: 'center', y: 'center' }"
        :isModal="true"
        @popupClosed="memberSelectPopupClosed"
        @popupConfirmed="memberSelectPopupConfirmed"
    />
    <award-certificate-popup
        v-if="isAwardCertificatePopupOpen"
        ref="awardCertificatePopup"
        @popupClosed="closeAwardCertificatePopup"
        @popupPrint="awardCertificatePopupPrint"
        @popupConfirmed="awardCertificatePopupConfirmed"
    />
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>
import moment from 'moment';
import {mapGetters} from 'vuex';
import {DATE_FORMAT_YYYY_MM_DD} from '@/utils/date';
import {numberWithCommas} from '@/utils/number';
import {ExcelExport, Filter, ForeignKey, Page, Group, Resize} from '@syncfusion/ej2-vue-grids';
import ejsGridWrapper from '@/components/common/EjsGridWrapper.vue';
import InputText from '@/components/common/text/InputText';
import InputDateRange from '@/components/common/datetime/InputDateRange';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';
import ReportView from '@/components/common/report/ReportView';
import {commonCodesGetCommonCode, commonCodesGetComName, commonCodesGetSortNo} from '@/utils/commonCodes';
import MemberSelectPopup from '@/views/common/MemberSelectPopup';
import AwardCertificatePopup from '../popup/AwardCertificatePopup';
import GolfERPService from '@/service/GolfERPService';
import { orderBy as _orderBy } from "lodash";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: 'AwardCertificate',
  components: {
    ejsGridWrapper,
    InputText,
    InputDateRange,
    ReportView,
    MemberSelectPopup,
    AwardCertificatePopup,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  data() {
    return {
      count: 0,
      labels: {
        visitDate: '내장일자',
        crtfCode: '증명서구분',
        visitName: '내장객명',
      },
      searchConditions: {
        crtfCode: null,
        memberName: null,
        membershipId: null,
      },
      searchOptions: {
        crtfCode: commonCodesGetCommonCode('CRTF_CODE', true),
      },
      searchDetailList: ['crtfCode'],
      commonCodeFields: {text: 'comName', value: 'comCode'},
      now: null,
      dateRange: {
        from: null,
        to: null,
      },
      grid: [
        Filter,
        Resize,
        Page,
        Group,
        ExcelExport,
        ForeignKey,
      ],
      awardCertificateGridColumn: [
        {
          field: '_rid',
          type: 'number',
          isPrimaryKey: true,
          visible: false,
        },
        {
          field: 'visitDate',
          headerText: '내장일자',
          textAlign: 'center',
          width: 90,
          type: 'string',
        },
        {
          field: 'visitName',
          headerText: '내장객명',
          width: 90,
          type: 'string',
        },
        {
          field: 'memberDiv',
          headerText: '회원구분',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'MEMBER_DIV',
          width: 90,
          type: 'string',
        },
        {
          field: 'startCourse',
          headerText: '출발코스',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'COURSE_CODE',
          width: 80,
          type: 'string',
        },
        {
          field: 'startTime',
          headerText: '시간',
          textAlign: 'center',
          width: 60,
          type: 'string',
        },
        {
          field: 'rcordCourse',
          headerText: '기록코스',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'COURSE_CODE',
          width: 80,
          type: 'string',
        },
        {
          field: 'holeDiv',
          headerText: '홀구분',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'HOLE_DIV',
          width: 60,
          type: 'string',
        },
        {
          field: 'par',
          headerText: 'PAR',
          textAlign: 'center',
          width: 60,
          type: 'number',
        },
        {
          field: 'crtfCode',
          headerText: '증명서구분',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'CRTF_CODE',
          width: 90,
          type: 'string',
        },
        {
          field: 'useClub',
          headerText: '사용클럽',
          width: 120,
          type: 'string',
        },
        {
          field: 'useBall',
          headerText: '사용구',
          width: 120,
          type: 'string',
        },
        {
          field: 'cmpn',
          headerText: '동반자',
          width: 180,
          type: 'string',
        },
        {
          field: 'caddieName',
          headerText: '캐디명',
          width: 90,
          type: 'string',
        },
        {
          field: 'webOpenFlag',
          headerText: '웹오픈',
          textAlign: 'center',
          editType: 'booleanedit',
          width: 60,
          type: 'boolean',
          allowEditing: false,
          displayAsCheckBox: true,
        },
        {
          field: 'remarks',
          headerText: '비고',
          width: 150,
          type: 'string',
        },
        {
          field: 'insertName',
          headerText: '등록자',
          width: 90,
          type: 'string',
        },
        {
          field: 'insertDt',
          headerText: '등록일시',
          textAlign: 'center',
          width: 130,
          type: 'string',
        },
      ],
      awardCertificateList: [],
      pageSettings: {pageSize: 50},
      groupSettings: {
        columns: [],
        showDropArea: false,
      },
      isMemberSelectPopupOpen: false,
      isAwardCertificatePopupOpen: false,
    };
  },
  async created() {
    await this.initialize();
  },
  computed: {
    ...mapGetters(['username']),
    isPopupOpened() {
      return (
          this.isMemberSelectPopupOpen ||
          this.isAwardCertificatePopupOpen
      );
    },
  },
  methods: {
    numberWithCommas,
    async initialize() {
      const nowMoment = await GolfERPService.fetchNow();
      this.now = nowMoment.toDate();
      const from = moment(nowMoment).add(-1, 'months').format(DATE_FORMAT_YYYY_MM_DD);
      const to = nowMoment.format(DATE_FORMAT_YYYY_MM_DD);
      this.dateRange = {
        from,
        to,
      };
      await this.fetch();
    },
    async onViewAwardCertificate() {
      await this.fetch();
    },
    memberNoClear() {
      this.searchConditions.memberName = null;
      this.searchConditions.membershipId = null;
    },
    onMemberNameEntered(event) {
      this.searchConditions.membershipId = null;
      if (event.key === 'Enter') {
        this.fetch();
      }
    },
    async openMemberPopup() {
      this.isMemberSelectPopupOpen = true;
      const memberData = {
        memberNameNo: this.searchConditions.memberName,
      };
      await this.$nextTick();
      this.$refs.memberSelectPopup.showPopup(memberData);
    },
    memberSelectPopupClosed() {
      this.isMemberSelectPopupOpen = false;
    },
    memberSelectPopupConfirmed(data) {
      this.searchConditions.memberName = data.selectedRowData.memberName;
      this.searchConditions.membershipId = data.selectedRowData.membershipId;
      this.memberSelectPopupClosed();
    },
    closeAwardCertificatePopup() {
      this.isAwardCertificatePopupOpen = false;
    },
    awardCertificatePopupPrint(data) {
      this.print(null, data);
    },
    async awardCertificatePopupConfirmed() {
      this.closeAwardCertificatePopup();
      await this.fetch();
    },
    awardCertificateGridHeaderCellInfo(args) {
      const {
        cell: {column: {field}},
        node,
      } = args;
      if (field === 'visitName') {
        node.classList.add(this.$t('className.grid.clickArea'));
      }
    },
    awardCertificateGridQueryCellInfo(args) {
      const {
        cell,
        column: {field},
        data,
      } = args;
      const findIndex = this.awardCertificateList
          .findIndex(item => item._rid === data._rid);
      if (findIndex > 0) {
        if (
            this.awardCertificateList[findIndex].caddieGroupDiv !==
            this.awardCertificateList[findIndex - 1].caddieGroupDiv
        ) {
          cell.classList.add(this.$t('className.grid.devReservationPartDivLine'));
        }
      }
      if (field === 'visitName') {
        cell.classList.add(this.$t('className.grid.clickArea'));
      }
    },
    async awardCertificateGridRecordClick(args) {
      const {
        cellIndex,
        rowData: data,
      } = args;
      if (cellIndex === 3) {
        this.isAwardCertificatePopupOpen = true;
        await this.$nextTick();
        this.$refs.awardCertificatePopup.showPopup(data.visitDate, this.courseHoleInfos, data);
      }
    },
    awardCertificateGridActionComplete() {
      this.count = numberWithCommas(
          this.$refs.awardCertificateGrid?.getGridBatchCount() || 0,
      );
    },
    async onAddClick() {
      this.isAwardCertificatePopupOpen = true;
      await this.$nextTick();
      const bsnDate = moment(this.now).format(DATE_FORMAT_YYYY_MM_DD);
      this.$refs.awardCertificatePopup.showPopup(bsnDate, this.courseHoleInfos);
    },
    format(data) {
      let jsonData = JSON.parse(JSON.stringify(data));
      jsonData.visitDate = moment(jsonData.visitDate).format('YYYY년 MMMM Do (dd)');
      jsonData.crtfCode = `${commonCodesGetComName('CRTF_CODE', jsonData.crtfCode)}인증서`
          .split('')
          .join(' ');
      jsonData.visitName = jsonData.visitName
          .split('')
          .join('  ');
      jsonData.cmpn =
        jsonData.cmpn ?
          jsonData.cmpn
            .split(',')
            .map(item => item.trim())
            .join(', ') :
          ""
      ;
      jsonData.caddieName = jsonData.caddieName
          .split(',')
          .map(item => item.trim())
          .join(', ');
      jsonData.holeDiv = commonCodesGetComName('HOLE_DIV', jsonData.holeDiv);
      jsonData.startCourse = commonCodesGetComName('COURSE_CODE', jsonData.startCourse || jsonData.course);
      return jsonData;
    },
    print(event, data = null) {
      if (!data) {
        const selectedRows = this.$refs.awardCertificateGrid.getSelectedRecords();
        if (selectedRows < 1) {
          this.errorToast('선택된 자료가 없습니다.');
          return;
        }
        data = selectedRows[0];
      }

      const reportData = {
        reportJson: {
          jsonData: this.format(data),
        },
      };

      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    excel() {
      this.$refs.awardCertificateGrid.excelExport();
    },

    // API
    async fetch() {
      const {courseHoleInfos, awardCertificates} = await GolfErpAPI.fetchAwardCertificate({
        visitDateFrom: this.dateRange.from,
        visitDateTo: this.dateRange.to,
        crtfCode: this.searchConditions.crtfCode !== null
            ? this.searchConditions.crtfCode
            : undefined,
        visitName: this.searchConditions.membershipId && this.searchConditions.membershipId !== ''
            ? this.searchConditions.membershipId
            : this.searchConditions.memberName && this.searchConditions.memberName !== ''
                ? this.searchConditions.memberName
                : undefined,
      });
      this.courseHoleInfos = courseHoleInfos;
      this.awardCertificateList = _orderBy(awardCertificates.map((item, index) => {
        item._rid = index + 1;
        item.courseCodeSortNo = commonCodesGetSortNo("COURSE_CODE", item.startCourse);
        return item;
      }), ["visitDate", "startTime", "courseCodeSortNo", "visitName"]) || [];
    },
  },
};
</script>

<template>
  <div class="content-wrapper">
    <!-- REPORT -->
    <report-view ref="reportViewComponent" :isPopup="true"/>
    <!-- 필터영역 -->
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">영업일자</div>
            <ul class="content">
              <li class="item date">
                <input-date ref="searchBsnDatePicker" format="YYYY-MM-DD" type="lookup-condition" v-model="searchFilter.bsnDate"/>
              </li>
              <li class="item text">
                (<span
                  :style="{ color: getDayOfWeekCaptionColor( new Date(searchFilter.bsnDate) ), }"
              >{{
                  getDayOfWeekCaption(new Date(searchFilter.bsnDate))
                }}</span
              >)(<span
                  :style="{ color: getWeekdayWeekendCaptionColor( new Date(searchFilter.bsnDate) ), }"
              >{{
                  getWeekdayWeekendCaption(new Date(searchFilter.bsnDate))
                }}</span
              >)
              </li>
            </ul>
            <ul class="content">
              <li class="item button">
                <ul class="button">
                  <li class="prev"><erp-button button-div="GET" v-on:click.native="onClickPreDate"/></li>
                  <li class="next"><erp-button button-div="GET" v-on:click.native="onClickNextDate"/></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              v-on:click.native="onClickSearch"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">

      </div>
    </div>
    <!-- 본문 -->
    <div class="content-body">
      <article class="body-article">
        <!-- ######## 목록 리스트 ########-->
        <section class="article-section">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">{{gridField.title}}</div>
<!--              <div class="header-caption">[ {{ gridField.count }} 건 ]</div>-->
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print"><erp-button button-div="FILE" @click.native="onClickExcel">Excel</erp-button></li>
              </ul>
              <ul class="lookup-button">
                <li class="print"><erp-button button-div="PRINT" @click.native="onClickPrint" :is-shortcut-button="true">인쇄</erp-button>
                </li>
              </ul>
            </div>
          </div>

          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="grid"
                  v-bind ="initGrid"
                  @queryCellInfo="onQueryCellInfo"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import AsyncComputed from "vue-async-computed";
import InputDate from "@/components/common/datetime/InputDate";
import {sumBy as _sumBy, groupBy as _groupBy, orderBy as _orderBy, sortBy as _sortBy} from "lodash";
import {
  getDateOfNext,
  getDateOfPrevious,
  getDayOfWeekCaption,
  getDayOfWeekCaptionColor,
  getFormattedDate,
  getWeekdayWeekendCaption,
  getWeekdayWeekendCaptionColor,
} from "@/utils/date";
import commonMixin from "@/views/layout/mixin/commonMixin";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton.vue";
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {Aggregate, ExcelExport, Filter, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import SalesPlan from "@/api/v2/management/SalesPlan";
import {numberWithCommas} from "@/utils/number";
import ReportView from "@/components/common/report/ReportView.vue";
import moment from "moment/moment";

Vue.use(AsyncComputed);

export default {
  name: "SalesPlanResult",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {ReportView, EjsGridWrapper, InputDate, ErpButton,},
  async created() {
    this.searchFilter.bsnDate = getFormattedDate(new Date());
  },
  async mounted() {
    await this.onViewButtonClicked();
  },
  computed: {
    initGrid() {
      return {
        columns: [
          {field: 'planStdNm',  textAlign: 'center', width: 120, type: 'string', headerText: '구분',},
          {field: 'today', textAlign: 'right', width: 120, type: 'string', headerText: '일계', isNumericType: true,},
          {headerText: '월간',
            columns: [{field: 'monthPlanValue',   textAlign: 'right', width: 120,  type: 'string', headerText: '계획',isNumericType: true,},
                      {field: 'monthValue',   textAlign: 'right', width: 120,  type: 'string', headerText: '실적',isNumericType: true,},
                      {field: 'monthRate',   textAlign: 'right', width: 120,  type: 'string', headerText: '달성율',},],
          },
          {headerText: '전기대비(월)',
            columns: [{field: 'preMonthValue', textAlign: 'right',  width: 120, type: 'string', headerText: '실적', isNumericType: true,},
                      {field: 'preMonthRate', textAlign: 'right',  width: 120, type: 'string', headerText: '성장율', }],},
          {headerText: '년간(누계)',
            columns: [{field: 'yearPlanValue',   textAlign: 'right', width: 120,  type: 'string', headerText: '계획',isNumericType: true,},
                      {field: 'yearValue',   textAlign: 'right', width: 120,  type: 'string', headerText: '실적',isNumericType: true,},
                      {field: 'yearRate',   textAlign: 'right', width: 120,  type: 'string', headerText: '달성율',},],
          },
          {headerText: '전기대비(년)',
            columns: [{field: 'preYearValue', textAlign: 'right',  width: 120, type: 'string', headerText: '전년동기', isNumericType: true,},
                      {field: 'preYearRate', textAlign: 'right',  width: 120, type: 'string', headerText: '성장율', }],},
        ],
        provides: [Aggregate, ExcelExport, Filter, ForeignKey, Page, Resize],
        dataSource: this.gridField.dataSource,
        allowFiltering: false,
        allowSorting: false,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:false,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", morde: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        pageSettings: { pageSize: 50 },
        resizeSettings: {mode:"Normal"},
        validationModification: true,
      };
    },
  },
  data() {
    return {
      searchFilter: {
        bsnDate: getFormattedDate(new Date()),
      },
      //그리드 필드
      gridField: {
        title: "영업분석",
        dataSource: [],
      },

    };
  },
  methods: {
    getDayOfWeekCaptionColor,
    getDayOfWeekCaption,
    getWeekdayWeekendCaptionColor,
    getWeekdayWeekendCaption,
    //region API ==================================================
    async apiSearch() {
      let data =  await SalesPlan.getSalesPlanResult(this.searchFilter);
      let totalPer={
        planStdNm:"내장객",
        today: _sumBy(data.filter(item=> item.planStdType =="PERWEEKEND"||item.planStdType =="PERWEEKDAY").map(item=> item.today)),
        monthPlanValue:_sumBy(data.filter(item=> item.planStdType =="PERWEEKEND"||item.planStdType =="PERWEEKDAY").map(item=> item.monthPlanValue)),
        monthValue:_sumBy(data.filter(item=> item.planStdType =="PERWEEKEND"||item.planStdType =="PERWEEKDAY").map(item=> item.monthValue)),
        monthRate:"0%",
        preMonthValue:_sumBy(data.filter(item=> item.planStdType =="PERWEEKEND"||item.planStdType =="PERWEEKDAY").map(item=> item.preMonthValue)),
        preMonthRate:"0%",
        yearPlanValue:_sumBy(data.filter(item=> item.planStdType =="PERWEEKEND"||item.planStdType =="PERWEEKDAY").map(item=> item.yearPlanValue)),
        yearValue:_sumBy(data.filter(item=> item.planStdType =="PERWEEKEND"||item.planStdType =="PERWEEKDAY").map(item=> item.yearValue)),
        yearRate:"0%",
        preYearValue:_sumBy(data.filter(item=> item.planStdType =="PERWEEKEND"||item.planStdType =="PERWEEKDAY").map(item=> item.preYearValue)),
        preYearRate:"0%",
      };

      if(totalPer.monthValue != 0 && totalPer.monthPlanValue != 0) {
        totalPer.monthRate = numberWithCommas(((totalPer.monthValue / totalPer.monthPlanValue) * 100).toFixed(1)) + "%";
      }
      if(totalPer.preMonthValue != 0) {
        totalPer.preMonthRate = numberWithCommas((((totalPer.monthValue - totalPer.preMonthValue) / totalPer.preMonthValue) * 100).toFixed(1)) + "%";
      }
      if(totalPer.yearValue != 0 && totalPer.yearPlanValue != 0) {
        totalPer.yearRate = numberWithCommas(((totalPer.yearValue / totalPer.yearPlanValue) * 100).toFixed(1)) + "%";
      }
      if(totalPer.preYearValue != 0) {
        totalPer.preYearRate = numberWithCommas((((totalPer.yearValue - totalPer.preYearValue) / totalPer.preYearValue) * 100).toFixed(1)) + "%";
      }
      data.unshift(totalPer);

      let totalSales={
        planStdNm:"매출계",
        today: _sumBy(data.filter(item=> item.planStdType =="SALES").map(item=> item.today)),
        monthPlanValue:_sumBy(data.filter(item=> item.planStdType =="SALES").map(item=> item.monthPlanValue)),
        monthValue:_sumBy(data.filter(item=> item.planStdType =="SALES").map(item=> item.monthValue)),
        monthRate:"0%",
        preMonthValue:_sumBy(data.filter(item=> item.planStdType =="SALES").map(item=> item.preMonthValue)),
        preMonthRate:"0%",
        yearPlanValue:_sumBy(data.filter(item=> item.planStdType =="SALES").map(item=> item.yearPlanValue)),
        yearValue:_sumBy(data.filter(item=> item.planStdType =="SALES").map(item=> item.yearValue)),
        yearRate:"0%",
        preYearValue:_sumBy(data.filter(item=> item.planStdType =="SALES").map(item=> item.preYearValue)),
        preYearRate:"0%",
      };
      if(totalSales.monthValue != 0 && totalSales.monthPlanValue != 0) {
        totalSales.monthRate = numberWithCommas(((totalSales.monthValue / totalSales.monthPlanValue) * 100).toFixed(1)) + "%";
      }
      if(totalSales.preMonthValue != 0) {
        totalSales.preMonthRate = numberWithCommas((((totalSales.monthValue - totalSales.preMonthValue) / totalSales.preMonthValue) * 100).toFixed(1)) + "%";
      }
      if(totalSales.yearValue != 0 && totalSales.yearPlanValue != 0) {
        totalSales.yearRate = numberWithCommas(((totalSales.yearValue / totalSales.yearPlanValue) * 100).toFixed(1)) + "%";
      }
      if(totalSales.preYearValue != 0) {
        totalSales.preYearRate = numberWithCommas((((totalSales.yearValue - totalSales.preYearValue) / totalSales.preYearValue) * 100).toFixed(1)) + "%";
      }

      data.splice(3,0,totalSales);

      let totalUnitPer={
        planStdNm:"객단가",
        today: this.replaceValidNumber((totalSales.today/totalPer.today).toFixed(0)),
        monthPlanValue:this.replaceValidNumber((totalSales.monthPlanValue/totalPer.monthPlanValue).toFixed(0)),
        monthValue:this.replaceValidNumber((totalSales.monthValue/totalPer.monthValue).toFixed(0)),
        monthRate:"0%",
        preMonthValue:this.replaceValidNumber((totalSales.preMonthValue/totalPer.preMonthValue).toFixed(0)),
        preMonthRate:"0%",
        yearPlanValue:this.replaceValidNumber((totalSales.yearPlanValue/totalPer.yearPlanValue).toFixed(0)),
        yearValue:this.replaceValidNumber((totalSales.yearValue/totalPer.yearValue).toFixed(0)),
        yearRate:"0%",
        preYearValue:this.replaceValidNumber((totalSales.preYearValue/totalPer.preYearValue).toFixed(0)),
        preYearRate:"0%",
      };
      if(totalUnitPer.monthValue != 0 && totalUnitPer.monthPlanValue != 0) {
        totalUnitPer.monthRate = numberWithCommas(((totalUnitPer.monthValue / totalUnitPer.monthPlanValue) * 100).toFixed(1)) + "%";
      }
      if(totalUnitPer.preMonthValue != 0) {
        totalUnitPer.preMonthRate = numberWithCommas((((totalUnitPer.monthValue - totalUnitPer.preMonthValue) / totalUnitPer.preMonthValue) * 100).toFixed(1)) + "%";
      }
      if(totalUnitPer.yearValue != 0 && totalUnitPer.yearPlanValue != 0) {
        totalUnitPer.yearRate = numberWithCommas(((totalUnitPer.yearValue / totalUnitPer.yearPlanValue) * 100).toFixed(1)) + "%";
      }
      if(totalUnitPer.preYearValue != 0) {
        totalUnitPer.preYearRate = numberWithCommas((((totalUnitPer.yearValue - totalUnitPer.preYearValue) / totalUnitPer.preYearValue) * 100).toFixed(1)) + "%";
      }

      data.splice(4,0,totalUnitPer);

      //금액은 천원단위 데이타 만들기
      data.forEach((item,index)=>{
        if(index>2){
          item.today =   Math.round(item.today/1000);
          item.monthPlanValue =   Math.round(item.monthPlanValue/1000);
          item.monthValue =   Math.round(item.monthValue/1000);
          item.preMonthValue =   Math.round(item.preMonthValue/1000);
          item.yearPlanValue =   Math.round(item.yearPlanValue/1000);
          item.yearValue =   Math.round(item.yearValue/1000);
          item.preYearValue =   Math.round(item.preYearValue/1000);
        }
      });
      this.gridField.dataSource = data;

    },
    async apiSearchChart() {
      return  await SalesPlan.getSalesPlanResultChart(this.searchFilter);
    },
    //endregion
    //region Button Clidck ==================================================
    async onClickPreDate() {
      this.searchFilter.bsnDate = getDateOfPrevious(
          this.searchFilter.bsnDate
      );
      await this.onClickSearch();
    },
    async onClickNextDate() {
      this.searchFilter.bsnDate = getDateOfNext(
          this.searchFilter.bsnDate
      );
      await this.onClickSearch();
    },
    async onClickSearch() {
      await this.apiSearch();
    },
    //엑셀 출력
    onClickExcel() {
      this.$refs.grid.excelExport({fileName: this.gridField.title+".xlsx"});
    },
    async onClickPrint() {
      if(this.gridField.dataSource.length <1){
        this.errorToast("출력할 자료가 없습니다.");
        return;
      }
      const  chartMonData  =await this.apiSearchChart();
      const year = this.searchFilter.bsnDate.substring(2,4);
      const month = this.searchFilter.bsnDate.substring(5,7);
      const  chartYearData  = [
        {
          name: `${year-1}년 실적`,
          resultPer:  chartMonData.map(item => item.preResultPer).reduce((acc, cur) => cur + acc, 0),
          resultSales: chartMonData.map(item => item.preResultSales).reduce((acc, cur) => cur + acc, 0),
        },
        {
          name: `${year}년 계획`,
          resultPer: chartMonData.filter((it,index)=> index < month).map(item => item.planPer).reduce((acc, cur) => cur + acc, 0),
          resultSales: chartMonData.filter((it,index)=> index < month).map(item => item.planSales).reduce((acc, cur) => cur + acc, 0),
        },
        {
          name: `${year}년 실적`,
          resultPer: chartMonData.map(item => item.resultPer).reduce((acc, cur) => cur + acc, 0),
          resultSales: chartMonData.map(item => item.resultSales).reduce((acc, cur) => cur + acc, 0),
        },
      ];
      const reportData = {
        reportJson: {
          jsonData: {
            salesPlanResult: this.$refs.grid.getBatchCurrentViewRecords(),
            chartMonData: chartMonData,
            chartYearData: chartYearData,
          },
          username: this.username,
          uniqueCode: this.$options.name,
          searchOptions:
              '라데나 영업일보 : ' + moment(this.searchFilter.bsnDate).format("yyyy년 MM월 DD일 ") + `(${getDayOfWeekCaption(new Date(this.searchFilter.bsnDate))})`,
        },
      };

      await this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    //endregionb
    onQueryCellInfo(args) {
      const {cell, column: { field }, data: { planStdNm },} = args;
        if (["내장객" , "매출계" , "객단가"].includes(planStdNm)) {
          cell.style.backgroundColor = "#dadcdc";
        }
    },
    replaceValidNumber(val){
      // NaN And Infinity to 0
      return (isNaN(parseInt(val)) || !isFinite(val)) ? '0' : val;
    }
  },
};
</script>

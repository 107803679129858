<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="depositUseHistoryPopup"
      header="사용내역"
      allowDragging="true"
      showCloseIcon="true"
      width="1180"
      height="800"
      isModal="true"
      @close="onDepositUseHistoryPopupClosed"
    >
      <div class="window depositUseHistory">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">
                      입금일자 : {{ depositInfo.incomDate }}(<span
                        :style="{
                          color: getDayOfWeekCaptionColor(
                            new Date(depositInfo.incomDate)
                          ),
                        }"
                        >{{
                          getDayOfWeekCaption(new Date(depositInfo.incomDate))
                        }}</span
                      >)
                    </div>
                  </li>
                  <li class="field">
                    <div class="title">
                      매장 :
                      {{
                        commonCodesGetComName(
                          "STORE_CODE",
                          depositInfo.storeCode
                        )
                      }}
                    </div>
                  </li>
                  <li class="field">
                    <div class="title">성명 : {{ depositInfo.memberName }}</div>
                  </li>
                  <li class="field">
                    <div class="title">
                      사용금액 : {{ numberWithCommas(depositInfo.depositAmt) }}
                    </div>
                  </li>
                  <li class="field">
                    <div class="title">
                      잔액 : {{ numberWithCommas(depositInfo.balanceAmt) }}
                    </div>
                  </li>
                  <li class="field">
                    <ul class="content">
                      <li class="item">
                        <ul class="check">
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                v-model="useCancelViewFlag"
                              />
                              <i></i>
                              <div class="label">사용 취소 보기</div>
                            </label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      buttonDiv="GET"
                      :isShortcutButton="true"
                      :ignore="isPopupOpened"
                      @click.native="onDepositUseHistoryViewClicked"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">선수금 사용 목록</div>
                      <div class="header-caption">
                        [{{ numberWithCommas(depositUseHistoryListLength) }}건]
                      </div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li>
                          <erp-button
                              buttonDiv="GET"
                              @click.native="onChangeHistoryButtonClicked"
                              :isIconCustom="true"
                          >
                            변경이력
                          </erp-button>
                        </li>
                        <li>
                          <erp-button
                              buttonDiv="SAVE"
                              @click.native="onDepositReturnRegistrationButtonClicked"
                              :isIconCustom="true"
                              :disabled="isDeleted"
                          >
                            환불/기타 사용 등록
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="depositUseHistoryGrid"
                        :allowResizing="true"
                        :dataSource="depositUseHistoryList"
                        :selectionSettings="selectionSettings"
                        :allowExcelExport="true"
                        :columns="depositUseHistoryGridColumn"
                        :provides="grid"
                        :allowGrouping="false"
                        :aggregates="depositUseHistoryGridAggregates"
                        @queryCellInfo="queryCellInfo"
                        @actionComplete="depositUseHistoryGridActionComplete"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="closeDepositUseHistoryPopup"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <deposit-return-registration-popup
      v-if="isDepositReturnRegistrationPopupOpen"
      ref="depositReturnRegistrationPopup"
      @popupClosed="onDepositReturnRegistrationPopupClose"
    />
    <pay-cancel-popup
      v-if="isPayCancelPopupOpen"
      ref="payCancelPopup"
      @popupClosed="payCancelPopupClosed"
    />
    <deposit-use-receipt-popup
      v-if="isDepositUseReceiptPopupOpen"
      ref="depositUseReceiptPopup"
      @popupClosed="depositUseReceiptPopupClosed"
    />
    <history-popup
      v-if="isHistoryPopupOpen"
      ref="historyPopup"
      @popupClosed="historyPopupClosed"
    />
  </div>
</template>

<script>
import Vue from "vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import ErpButton from "@/components/button/ErpButton.vue";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {
  Aggregate,
  Filter,
  Resize,
  Group,
  ExcelExport,
  ForeignKey,
} from "@syncfusion/ej2-vue-grids";
import payCancelPopup from "@/components/popups/PayCancelPopup";
import depositReturnRegistrationPopup from "./DepositReturnRegistrationPopup";
import depositUseReceiptPopup from "./DepositUseReceiptPopup";

import {
  commonCodesGetComName,
  commonCodesGetCommonCodeAttrbByCodeAndIdx, commonCodesGetSortNo,
} from "@/utils/commonCodes";
import { getDayOfWeekCaption, getDayOfWeekCaptionColor } from "@/utils/date";
import { numberWithCommas } from "@/utils/number";
import { orderBy as _orderBy } from "lodash";

import GolfErpAPI from "@/api/v2/GolfErpAPI";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import HistoryPopup from "../../common/HistoryPopup";

const PAY_INFO_GRID_MENU_CANCEL = "cancel";
const PAY_INFO_GRID_MENU_REPUBLIC = "republic";

export default {
  name: "DepositUseHistoryPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ejsGridWrapper,
    depositReturnRegistrationPopup,
    payCancelPopup,
    depositUseReceiptPopup,
    HistoryPopup,
    ErpButton,
  },
  data() {
    return {
      isHistoryPopupOpen: false,
      isDepositUseReceiptPopupOpen: false,
      depositUseHistoryListLength: 0,
      isPayCancelPopupOpen: false,
      isDepositReturnRegistrationPopupOpen: false,
      grid: [Aggregate, Filter, Resize, Group, ExcelExport, ForeignKey],
      selectionSettings: { type: "Single", mode: "Both", enableToggle: false },
      depositInfo: {
        depositId: null,
        incomDate: null,
        storeCode: null,
        memberName: null,
        depositAmt: null,
        balanceAmt: null,
      },
      useCancelViewFlag: false,
      isDeleted: true,
      depositUseHistoryList: [],
      depositUseHistoryGridColumn: [
        {
          field: "useId",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "delFlag",
          visible: false,
        },
        {
          field: "storeCode",
          headerText: "매장",
          width: 80,
          isCommonCodeField: true,
          groupCode: "STORE_CODE",
          type: "string",
        },
        {
          field: "memberName",
          headerText: "사용자",
          width: 120,
          type: "string",
        },
        {
          field: "useDate",
          headerText: "사용일자",
          width: 80,
          textAlign: "center",
          type: "string",
        },
        {
          field: "startCourse",
          headerText: "코스",
          width: 80,
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "COURSE_CODE",
          type: "string",
        },
        {
          field: "startTime",
          headerText: "시간",
          width: 80,
          textAlign: "center",
          type: "string",
        },
        {
          field: "useAmt",
          headerText: "사용금액",
          width: 120,
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          field: "depositUseDiv",
          headerText: "사용구분",
          width: 120,
          isCommonCodeField: true,
          groupCode: "DEPOSIT_USE_DIV",
          type: "string",
          textAlign: "center",
        },
        {
          field: "insertName",
          headerText: "등록자",
          type: "string",
          width: 80,
        },
        {
          field: "insertDt",
          headerText: "등록일시",
          width: 120,
          type: "string",
          textAlign: "center",
        },
        {
          field: "dropdownMenu",
          headerText: "",
          width: 24,
          template: () => {
            return {
              template: {
                extends: Vue.component("payInfoGridDropdownTemplate", {
                  template:
                    "<div class='dropdownbutton'><ejs-dropdownbutton :items='meunItems' :select='onMenuSelect'></ejs-dropdownbutton></div>",
                  props: ["payInfoGridMenuSelectedCallback"],
                  data() {
                    return {
                      meunItems: [],
                      data: {
                        data: {},
                      },
                    };
                  },
                  mounted() {
                    if (
                      commonCodesGetCommonCodeAttrbByCodeAndIdx(
                        "DEPOSIT_USE_DIV",
                        this.data.depositUseDiv,
                        1
                      ) === "Y"
                    ) {
                      this.meunItems = this.data.delFlag
                        ? [
                            {
                              text: "영수증 재발행",
                              value: PAY_INFO_GRID_MENU_REPUBLIC,
                            },
                          ]
                        : [
                            {
                              text: "환불/기타 사용 취소",
                              value: PAY_INFO_GRID_MENU_CANCEL,
                            },
                            {
                              text: "영수증 재발행",
                              value: PAY_INFO_GRID_MENU_REPUBLIC,
                            },
                          ];
                    } else {
                      this.meunItems = [
                        {
                          text: "영수증 재발행",
                          value: PAY_INFO_GRID_MENU_REPUBLIC,
                        },
                      ];
                    }
                  },
                  methods: {
                    onMenuSelect: function (args) {
                      let eventParam = {
                        menu: args.item.value,
                        data: this.data,
                      };
                      this.payInfoGridMenuSelectedCallback(eventParam);
                      // this.$EventBus.$emit('payInfoGridMenuSelected', eventParam);
                    },
                  },
                }),
                propsData: {
                  payInfoGridMenuSelectedCallback: this
                    .onPayInfoGridMenuSelected,
                },
              },
            };
          },
          type: "string",
          allowFiltering: false,
        },
        {
          field: "closeDiv",
          headerText: "마감",
          width: 70,
          textAlign: "center",
          type: "boolean",
          displayAsCheckBox: true,
        },
        {
          field: "remarks",
          headerText: "비고",
          type: "string",
          width: 300,
        },
        {
          field: "delReason",
          headerText: "취소사유",
          type: "string",
          width: 300,
        },
      ],
    };
  },
  computed: {
    isPopupOpened() {
      return (
        this.isDepositReturnRegistrationPopupOpen ||
        this.isPayCancelPopupOpen ||
        this.isDepositUseReceiptPopupOpen
      );
    },
    depositUseHistoryGridAggregates() {
      return [
        {
          columns: [
            {
              field: "useAmt",
              aggregationType: "TotalCaption",
              customAggregate: () => {
                // 환불이 아닌 모든 금액
                const notReturnedUseAmt = this.$refs.depositUseHistoryGrid
                  .getGridBatchData()
                  .filter(
                    (data) => !data.delFlag && data.depositUseDiv !== "RETURN"
                  )
                  .reduce((acc, cur) => acc + cur.useAmt, 0);

                return notReturnedUseAmt.toLocaleString();
              },
              stringFormat: "사용 : ${value}",
            },
            {
              field: "depositUseDiv",
              aggregationType: "TotalCaption",
              customAggregate: () => {
                // 환불인 모든 금액
                const returnedUseAmt = this.$refs.depositUseHistoryGrid
                  .getGridBatchData()
                  .filter(
                    (data) => !data.delFlag && data.depositUseDiv === "RETURN"
                  )
                  .reduce((acc, cur) => acc + cur.useAmt, 0);

                return returnedUseAmt.toLocaleString();
              },
              stringFormat: "환불 : ${value}",
              textColor: "red",
            },
          ],
        },
      ];
    },
  },
  methods: {
    commonCodesGetComName,
    getDayOfWeekCaptionColor,
    getDayOfWeekCaption,
    numberWithCommas,
    async showDepositUseHistoryPopup(popupData) {
      this.depositInfo.depositId = popupData.depositId;
      this.isDeleted = popupData.isDeleted;

      await this.onDepositUseHistoryViewClicked();
    },
    onDepositUseHistoryPopupClosed() {
      this.$emit("popupClosed");
    },
    closeDepositUseHistoryPopup() {
      this.$refs.depositUseHistoryPopup.hide();
    },
    queryCellInfo(args) {
      if (args.column && args.data.delFlag) {
        args.cell.style.textDecoration = "line-through";
        args.cell.style.textDecorationColor = "red";
      }
      if (
        args.column &&
        args.column.field === "dropdownMenu" &&
        args.data.delFlag
      ) {
        args.cell.innerText = null;
      }
    },
    onPayInfoGridMenuSelected(args) {
      if (args.menu === PAY_INFO_GRID_MENU_CANCEL) {
        this.isPayCancelPopupOpen = true;

        let popupData = {
          salesDiv: "DEPOSIT",
          payDiv: args.data.depositUseDiv,
          depositUseDiv: args.data.depositUseDiv,
          useId: args.data.useId,
          payDate: args.data.useDate,
          bsnDate: args.data.useDate,
          payAmt: args.data.useAmt,
          storeCode: args.data.storeCode,
        };

        this.$nextTick(() => {
          this.$refs.payCancelPopup.showPayCancelPopup(popupData);
        });
      } else if (args.menu === PAY_INFO_GRID_MENU_REPUBLIC) {
        const useId = args.data.useId;

        if (useId === null || useId === undefined) {
          this.infoToast("선수금 사용 ID가 존재하지 않습니다");
          return;
        }

        this.isDepositUseReceiptPopupOpen = true;

        this.$nextTick(() => {
          this.$refs.depositUseReceiptPopup.showDepositUseReceiptPopup(useId);
        });
      }
    },
    async onDepositUseHistoryViewClicked() {
      await this.fetchDepositUses(this.depositInfo.depositId, this.useCancelViewFlag, this.isDeleted);
    },
    async fetchDepositUses(depositId, useCancelViewFlag, isDeleted) {
      this.$refs.depositUseHistoryGrid.clearFiltering();

      const data = await GolfErpAPI.fetchDepositUses({
        depositId,
        useCancelViewFlag,
        isDeleted
      });

      this.depositInfo = data.depositStatus;
      this.depositUseHistoryList = _orderBy(data.depositUseList.map(item => ({
        ...item,
        courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE", item.startCourse),
      })), ["useDate", "startTime", "courseCodeSortNo"]);
      this.depositUseHistoryListLength = data.depositUseList.length;
    },
    onDepositReturnRegistrationButtonClicked() {
      if (!(this.depositInfo.balanceAmt > 0)) {
        this.errorToast("사용할 수 있는 잔액이 없습니다.");
        return;
      }

      this.isDepositReturnRegistrationPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.depositReturnRegistrationPopup.showDepositReturnRegistrationPopup(
          {
            depositId: this.depositInfo.depositId,
            balanceAmt: this.depositInfo.balanceAmt,
          }
        );
      });
    },
    async onDepositReturnRegistrationPopupClose() {
      this.isDepositReturnRegistrationPopupOpen = false;

      await this.onDepositUseHistoryViewClicked();
    },
    depositUseReceiptPopupClosed() {
      this.isDepositUseReceiptPopupOpen = false;
    },
    onChangeHistoryButtonClicked() {
      // alert("변경이력 개발 예정");

      const selectedRecords = this.$refs.depositUseHistoryGrid.getSelectedRecords();

      if (!(selectedRecords.length > 0)) {
        return;
      }

      if (!selectedRecords[0].useId) {
        return;
      }

      GolfErpAPI.fetchDepositUseHistory(selectedRecords[0].useId).then((rawHistories) => {
        console.log('rawHistories.===>', rawHistories);
        this.isHistoryPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.historyPopup.show({
            rawHistories,
            fields: {
              DepositUse: {
                __name__: "선수금 사용 정보",
                useDate: {
                  name: "사용일자",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                storeCode: {
                  name: "매장코드",
                  type: "commonCode",
                  groupCode: "STORE_CODE",
                  methods: ["create", "update", "delete"],
                },
                depositId: {
                  name: "선수금ID",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                incomId: {
                  name: "입금ID",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                payId: {
                  name: "정산ID",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                returnId: {
                  name: "회입ID",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                depositUseDiv: {
                  name: "선수금사용구분",
                  type: "commonCode",
                  groupCode: "DEPOSIT_USE_DIV",
                  methods: ["create", "update", "delete"],
                },
                useAmt: {
                  name: "사용금액",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                memberName: {
                  name: "회원명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                grpNo: {
                  name: "단체번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                grpName: {
                  name: "단체명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                startCourse: {
                  name: "출발코스",
                  type: "commonCode",
                  groupCode: "COURSE_CODE",
                  methods: ["create", "update", "delete"],
                },
                startTime: {
                  name: "출발시간",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                remarks: {
                  name: "비고",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
              }
            }
          });
        });
      });
    },
    historyPopupClosed() {
      this.isHistoryPopupOpen = false;
    },
    async payCancelPopupClosed() {
      this.isPayCancelPopupOpen = false;

      await this.onDepositUseHistoryViewClicked();
    },
    depositUseHistoryGridActionComplete(args) {
      if (args.requestType === "filtering" && args.action === "clearFilter") {
        this.depositUseHistoryListLength = this.depositUseHistoryList.length;
      } else if (args.requestType === "filtering") {
        this.depositUseHistoryListLength = this.$refs.depositUseHistoryGrid.getFilteredRecords().length;
      }
    },
  },
};
</script>

<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              {{ labels.bizCode }}
            </div>
            <ul class="content">
              <li class="item select">
                <ejs-dropdownlist
                  v-model="searchConditions.bizCode"
                  :dataSource="reservationOptions.bizCode"
                  :allowFiltering="false"
                  :fields="bizCodeFields"
                  cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field reservationDate">
            <ul class="content">
              <li class="item select">
                <ejs-dropdownlist
                  v-model="searchConditions.dateDiv"
                  :dataSource="reservationOptions.dateDiv"
                  :allowFiltering="false"
                  :fields="commonCodeFields"
                  cssClass="lookup-condition-dropdown"
                />
              </li>
              <li class="item dateRange">
                <input-date-range
                  type="lookup-condition"
                  v-model="searchDateRange"
                />
              </li>
            </ul>
          </li>
          <li class="field reservationTime">
            <div class="title">
              {{ labels.resveTime }}
            </div>
            <ul class="content">
              <li class="item time">
                <input-time v-model="searchConditions.resveTime" />
              </li>
            </ul>
          </li>
          <li class="field reservationName">
            <div class="title">
              {{ labels.resveName }}
            </div>
            <ul class="content">
              <li class="item input">
                <input-text
                  v-model="searchConditions.resveName"
                  @focus="memberNoClear"
                />
              </li>
            </ul>
            <ul class="content">
              <li class="item button">
                <ul class="button">
                  <li class="search">
                    <erp-button
                      button-div="GET"
                      @click.native="openMemberPopup"
                    >
                      검색
                    </erp-button>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.resveChangeDiv }}
            </div>
            <ul class="content">
              <li class="item select">
                <ejs-dropdownlist
                    v-model="searchConditions.resveChangeDiv"
                    :dataSource="reservationOptions.resveChangeDiv"
                    :allowFiltering="false"
                    :fields="commonCodeFields"
                    cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              @click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              button-div="GET"
              :is-icon-custom="true"
              @click.native="searchDetail"
          >
            상세검색
          </erp-button>
        </div>
        <!--
        <ul class="lookup-button">
          <li class="shortcut">
            <ejs-dropdownbutton
              ref='shortcutMenuDropdownButton'
              cssClass='lookup-button-dropdown'
              :items='shortcutMenuItems'>
              바로가기
            </ejs-dropdownbutton>
          </li>
          <li class="etc">
            <ejs-dropdownbutton
              ref='etcMenuDropdownButton'
              cssClass='lookup-button-dropdown'
              :items='etcMenuItems'>
              기타
            </ejs-dropdownbutton>
          </li>
        </ul>
        -->
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true" />
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">예약 히스토리 목록</div>
              <div class="header-caption">
                [{{ numberWithCommas(reservationHistorysLength) }}건]
              </div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      :is-shortcut-button="true"
                      :ignore="isPopupOpened"
                      @click.native="print"
                  >
                    인쇄
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <grid
                ref="reservationHistoryGrid"
                v-bind="reservationHistoryGridProps"
                @paged="onGridDataFiltered"
                @sorted="onGridDataFiltered"
                @filtered="onGridDataFiltered"
              />
              <ejs-grid-wrapper
                ref="groupReservationStatusListGrid"
                style="display: none;"
                :allowResizing="true"
                :dataSource="resveHistoryInfoList"
                :selectionSettings="reservationHistorySelectionSettings"
                :allowExcelExport="true"
                :columns="groupReservationStatusListGridColumn"
                :provides="grid"
                :allowPaging="true"
                :pageSettings="reservationHistoryPageSettings"
                @queryCellInfo="reservationHistoryGridCustomiseCell"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <ejs-dialog
      ref="searchDetailPopup"
      :header="`상세검색`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="387"
      :animationSettings="{ effect: 'None' }"
      :isModal="false"
      :visible="false"
      enableResize="true"
      v-show="isSearchDetailPopupOpen"
    >
      <div class="window lookupDetail-reservationHistoryLookup">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">
                    {{ labels.courseCode }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchConditions.resveCourse"
                        :dataSource="reservationOptions.courseCode"
                        :allowFiltering="false"
                        :fields="commonCodeFields"
                        cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.bsnCode }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchConditions.bsnCode"
                        :dataSource="reservationOptions.bsnCode"
                        :allowFiltering="false"
                        :fields="commonCodeFields"
                        cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.partDiv }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchConditions.partDiv"
                        :dataSource="reservationOptions.partDiv"
                        :allowFiltering="false"
                        :fields="commonCodeFields"
                        cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.dwCode }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchConditions.dwCode"
                        :dataSource="reservationOptions.dwCode"
                        :allowFiltering="false"
                        :fields="commonCodeFields"
                        cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.memberDiv }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchConditions.memberDiv"
                        :dataSource="reservationOptions.memberDiv"
                        :allowFiltering="false"
                        :fields="commonCodeFields"
                        cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field groupName">
                  <div class="title">
                    {{ labels.grpNo }}
                  </div>
                  <ul class="content">
                    <li class="item input">
                      <input-text
                        ref="grpName"
                        v-model="grpName"
                        @focus="grpNoClear"
                      />
                    </li>
                    <li class="item button">
                      <ul class="button">
                        <li class="search">
                          <erp-button
                            button-div="GET"
                            @click.native="openGroupPopup"
                          >
                            검색
                          </erp-button>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.contactTelIdx }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <input-text
                        v-model="searchConditions.contactTelIdx"
                        placeholder="연락처 뒤 4자리"
                        maxlength="4"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.resveRemarks }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <input-text v-model="searchConditions.resveRemarks" />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  button-div="GET"
                  :is-shortcut-button="true"
                  @click.native="onDialogViewButtonClicked"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="onDialogSearchConditionInitClicked"
              >
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onDialogCloseButtonClicked"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <member-select-popup
      v-if="isMemberSelectPopupOpen"
      ref="memberSelectPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClosed="memberSelectPopupClosed"
      @popupConfirmed="memberSelectPopupConfirmed"
    />
    <group-popup
      v-if="isGroupPopupOpen"
      ref="groupPopup"
      @popupClosed="onGroupPopupClosed"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.reservationDate .content .item.select {width: 120px;}
body .appContent .lookup-condition .field.reservationTime .content .item {width: 42px;}
body .appContent .lookup-condition .field.reservationName .content .item.input {width: 90px;}
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import numberTemplate from "@/views/common/template/GridNumberTemplate";
import ReportView from "@/components/common/report/ReportView";

import {
  VirtualScroll,
  Resize,
  Page,
  ExcelExport,
  PdfExport,
  Aggregate,
  Filter,
  Group,
  ForeignKey,
} from "@syncfusion/ej2-vue-grids";

import memberSelectPopup from "@/views/common/MemberSelectPopup";
import groupPopup from "./popup/GroupPopup";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import Grid from "@/components/grid/Grid";
import {gridUtilGetMemberNoColumnAccess, gridUtilGetTelColumnAccess} from "@/utils/gridUtil";
import { numberWithCommas } from "@/utils/number";
import { getBizCodesObject, getCurrentBizCode } from "@/utils/pageKeyUtil";
import {
  getFormattedDate,
  getFormattedDateTimePicker,
  getDayOfWeekCaption,
  getDayOfWeekCaptionColor,
  getDayColorValue,
} from "@/utils/date";
import {
  commonCodesGetColorValue,
  commonCodesGetCommonCode,
  commonCodesGetComName,
} from '@/utils/commonCodes';
import commonMixin from "@/views/layout/mixin/commonMixin";
import InputDateRange from "@/components/common/datetime/InputDateRange";

import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import InputTime from "@/components/common/datetime/InputTime";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { mapGetters } from "vuex";

import GolfErpAPI from "@/api/v2/GolfErpAPI";
import { FORMAT_TEL_CELLPHONE_NUMBER, FORMAT_MEMBER_NUMBER } from "@/components/grid/GridCellDataFormatUtil";

export default {
  name: "ReservationHistory",
  components: {
    InputText,
    InputTime,
    InputDateRange,
    memberSelectPopup,
    groupPopup,
    ejsGridWrapper,
    ReportView,
    Grid,
    ErpButton
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  data() {
    return {
      reservationHistorys: [],
      reservationHistorysLength: 0,
      grid: [
        Aggregate,
        Filter,
        Resize,
        Group,
        ExcelExport,
        ForeignKey,
        VirtualScroll,
        Page,
      ],
      groupReservationStatusListGridColumn: [
        {
          field: "logId",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "resveDate",
          headerText: "예약일자",
          textAlign: "center",
          width: 100,
          type: "string",
        },
        {
          field: "dwName",
          headerText: "요일",
          textAlign: "center",
          width: 80,
          type: "string",
        },
        {
          field: "bsnName",
          headerText: "영업구분",
          textAlign: "center",
          width: 100,
          type: "string",
        },
        {
          field: "resveTime",
          headerText: "시간",
          textAlign: "center",
          width: 80,
          type: "string",
        },
        {
          field: "contentHoleDiv",
          headerText: "홀구분",
          textAlign: "center",
          width: 80,
          type: "string",
        },
        {
          field: "contentResveNo",
          headerText: "예약번호",
          width: 110,
          type: "string",
        },
        {
          field: "resveName",
          headerText: "예약자명",
          width: 100,
          type: "string",
        },
        {
          field: "contentMemberNo",
          headerText: "회원번호",
          textAlign: "center",
          width: 100,
          type: "string",
          valueAccessor: (field, data) =>
            gridUtilGetMemberNoColumnAccess(field, data),
        },
        {
          field: "contentResveChannel",
          headerText: "예약채널",
          textAlign: "center",
          width: 100,
          type: "string",
        },
        {
          field: "contentGrpName",
          headerText: "단체명",
          width: 100,
          type: "string",
        },
        {
          field: "contentMemberDiv",
          headerText: "회원구분",
          width: 120,
          type: "string",
        },
        {
          field: "contentContactTel",
          headerText: "연락처",
          textAlign: "center",
          valueAccessor: this.contactTelValueAccess,
          width: 120,
          type: "string",
        },
        {
          field: "contentTimeDiv",
          headerText: "타임구분",
          width: 100,
          type: "string",
        },
        {
          field: "resveChangeDiv",
          headerText: "변경구분",
          textAlign: "center",
          width: 110,
          type: "string",
        },
        {
          field: "insertName",
          headerText: "변경자",
          width: 110,
          type: "string",
        },
        {
          field: "insertIp",
          headerText: "변경자 IP",
          width: 130,
          type: "string",
        },
        {
          field: "insertDt",
          headerText: "변경일시",
          textAlign: "center",
          width: 150,
          type: "string",
        },
        {
          field: "resveChangeResn",
          headerText: "변경사유",
          width: 240,
          type: "string",
        },
        {
          field: "contentPromtnName",
          headerText: "프로모션",
          width: 100,
          type: "string",
        },
        {
          field: "contentTeamGreenFee",
          headerText: "팀그린피",
          width: 100,
          type: "number",
          format: "N0",
          textAlign: "right"
        },
        {
          field: "contentResveRemarks",
          headerText: "예약비고",
          width: 240,
          type: "string",
        },
        {
          field: "contentSmsSendFlag",
          allowEditing: false,
          type: "boolean",
          headerText: "SMS전송",
          minWidth: 16,
          width: 110,
          textAlign: "center",
          editType: "booleanedit",
          displayAsCheckBox: true,
        },
        {
          field: "contentWebOpenFlag",
          allowEditing: false,
          type: "boolean",
          headerText: "웹오픈",
          minWidth: 16,
          width: 90,
          textAlign: "center",
          editType: "booleanedit",
          displayAsCheckBox: true,
        },
        {
          field: "contentAgncyOpenFlag",
          allowEditing: false,
          type: "boolean",
          headerText: "대행사오픈",
          minWidth: 16,
          width: 90,
          textAlign: "center",
          editType: "booleanedit",
          displayAsCheckBox: true,
        }
      ],
      isSearchDetailPopupOpen: false,
      isMemberSelectPopupOpen: false,
      isGroupPopupOpen: false,
      searchConditionsOrigin: {
        bizCode: getCurrentBizCode(),
        dateDiv: "RESVE_DATE",
        dateFrom: getFormattedDate(new Date()),
        dateTo: getFormattedDate(new Date()),
        resveTime: null,
        resveName: null,
        membershipId: null,
        resveCourse: "%",
        bsnCode: "%",
        partDiv: "%",
        dwCode: "%",
        memberDiv: "%",
        resveChangeDiv: "%",
        grpNo: null,
        contactTelIdx: null,
        resveRemarks: null,
      },
      searchConditions: {
        resveTime: null,
      },
      savedTimeId: 0,
      grpName: null,
      labels: {
        bizCode: "골프장",
        resveTime: "예약시간",
        resveName: "예약자명",
        courseCode: "코스",
        bsnCode: "영업구분",
        partDiv: "부구분",
        dwCode: "요일",
        memberDiv: "회원구분",
        resveChangeDiv: "변경구분",
        grpNo: "단체명",
        contactTelIdx: "연락처",
        resveRemarks: "비고",
      },
      reservationOptions: {
        bizCode: getBizCodesObject(),
        dateDiv: [
          { comCode: "RESVE_DATE", comName: "예약일자" },
          { comCode: "RESVE_CHANGE_DATE", comName: "변경일자" },
        ],
        courseCode: commonCodesGetCommonCode("COURSE_CODE", true),
        bsnCode: commonCodesGetCommonCode("BSN_CODE", true),
        partDiv: commonCodesGetCommonCode("PART_DIV", true),
        dwCode: commonCodesGetCommonCode("DW_CODE", true),
        memberDiv: commonCodesGetCommonCode("MEMBER_DIV", true),
        resveChangeDiv: commonCodesGetCommonCode("RESVE_CHANGE_DIV", true).filter(item => item.comCode === 'RESVE' || item.comCode === 'CHANGE' || item.comCode === 'CANCEL'),
      },
      bizCodeFields: { text: "bizName", value: "bizCode" },
      commonCodeFields: { text: "comName", value: "comCode" },
      reservationHistoryPageSettings: { pageSize: 50 },
      numberTemplate: function () {
        return {
          template: numberTemplate,
        };
      },
      resveHistoryInfoList: [],
      reservationHistorySelectionSettings: {
        type: "Multiple",
        mode: "Both",
        enableToggle: false,
      },
      reservationHistoryEditSettings: {
        allowEditing: true,
        allowAdding: false,
        allowDeleting: false,
        mode: "Batch",
        showConfirmDialog: false,
      },
    };
  },
  provide: {
    grid: [VirtualScroll, Resize, Page, ExcelExport, PdfExport],
  },
  computed: {
    ...mapGetters(["username"]),
    isPopupOpened() {
      return (
        this.isSearchDetailPopupOpen ||
        this.isMemberSelectPopupOpen ||
        this.isGroupPopupOpen
      );
    },
    searchDateRange: {
      get() {
        return {
          from: this.searchConditions.dateFrom,
          to: this.searchConditions.dateTo,
        };
      },
      set(searchDateRange) {
        this.searchConditions.dateFrom = searchDateRange.from;
        this.searchConditions.dateTo = searchDateRange.to;
      },
    },
    reservationHistoryGridProps(){
      return {
        columns: [
          {
            field: "logId",
            visible: false,
            name: "log",
            isPrimaryKey: true,
            sortable: false,
            filterable: false,
            type: Number
          },
          {
            field: "resveDate",
            name: "예약일자",
            textAlign: "center",
            width: 100,
            type: String,
            sortable: false,
            filterable: false,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            field: "dwName",
            name: "요일",
            textAlign: "center",
            width: 80,
            type: String,
            sortable: false,
            filterable: false,
            cellStyle: (data) => {
              return {
                textAlign: "center",
                color: getDayColorValue(data.dwCode, data.bsnCode),
              };
            },
          },
          {
            field: "bsnName",
            name: "영업구분",
            textAlign: "center",
            width: 100,
            type: String,
            sortable: false,
            filterable: false,
            cellStyle: (data) => {
              return {
                textAlign: "center",
                color: commonCodesGetColorValue("BSN_CODE", data.bsnCode),
              };
            },
          },
          {
            field: "resveTime",
            name: "시간",
            textAlign: "center",
            width: 80,
            type: String,
            sortable: false,
            filterable: false,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            field: "contentHoleDiv",
            name: "홀구분",
            textAlign: "center",
            width: 80,
            type: String,
            sortable: false,
            filterable: false,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            field: "resveName",
            name: "예약자명",
            width: 100,
            sortable: false,
            filterable: false,
            type: String,
          },
          {
            field: "contentContactTel",
            name: "연락처",
            textAlign: "center",
            sortable: false,
            filterable: false,
            width: 120,
            type: String,
            format: FORMAT_TEL_CELLPHONE_NUMBER,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            field: "contentResveChannel",
            name: "예약채널",
            textAlign: "center",
            width: 100,
            type: String,
            sortable: false,
            filterable: false,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            field: "contentResveNo",
            name: "예약번호",
            width: 110,
            sortable: false,
            filterable: false,
            type: String,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            field: "contentMemberDiv",
            name: "회원구분",
            width: 120,
            type: String,
            sortable: false,
            filterable: false,
          },
          {
            field: "contentMemberNo",
            name: "회원번호",
            width: 100,
            sortable: false,
            filterable: false,
            type: String,
            format: FORMAT_MEMBER_NUMBER,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            field: "contentGrpName",
            name: "단체명",
            sortable: false,
            filterable: false,
            width: 100,
            type: String,
          },
          {
            field: "contentTimeDiv",
            name: "타임구분",
            width: 100,
            sortable: false,
            filterable: false,
            type: String,
          },
          {
            field: "resveChangeDiv",
            name: "변경구분",
            textAlign: "center",
            width: 110,
            type: String,
            sortable: false,
            filterable: false,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            field: "insertName",
            name: "변경자",
            width: 110,
            type: String,
            sortable: false,
            filterable: false,
          },
          {
            field: "insertIp",
            name: "변경자 IP",
            width: 130,
            type: String,
            sortable: false,
            filterable: false,
          },
          {
            field: "insertDt",
            name: "변경일시",
            textAlign: "center",
            width: 150,
            type: String,
            sortable: false,
            filterable: false,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            field: "resveChangeResn",
            name: "변경사유",
            width: 240,
            type: String,
            sortable: false,
            filterable: false,
          },
          {
            field: "contentPromtnName",
            name: "프로모션",
            width: 100,
            type: String,
            sortable: false,
            filterable: false,
          },
          {
            field: "contentTeamGreenFee",
            name: "팀그린피",
            width: 100,
            type: Number,
            sortable: false,
            filterable: false,
            cellStyle: {
              textAlign: "right",
            },
          },
          {
            field: "contentResveRemarks",
            name: "예약비고",
            sortable: false,
            filterable: false,
            width: 240,
            type: String,
          },
          {
            field: "contentSmsSendFlag",
            type: Boolean,
            name: "SMS전송",
            minWidth: 16,
            width: 110,
            sortable: false,
            filterable: false,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            field: "contentWebOpenFlag",
            type: Boolean,
            name: "웹오픈",
            minWidth: 16,
            width: 90,
            sortable: false,
            filterable: false,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            field: "contentAgncyOpenFlag",
            type: Boolean,
            name: "대행사오픈",
            minWidth: 16,
            width: 90,
            sortable: false,
            filterable: false,
            cellStyle: {
              textAlign: "center",
            },
          }
        ],
        records: this.reservationHistorys,
        sizeOfRecordPerPage: 50,
        countOfAllRecords: this.reservationHistorysLength,
      };
    }
  },
  beforeMount() {
    this.searchConditions = Object.assign({}, this.searchConditionsOrigin);

    // 검색 조건 설정
    this.reservationOptions.bizCode.unshift({ bizCode: "%", bizName: "전체" });
    this.reservationOptions.courseCode.unshift({
      comCode: "%",
      comName: "전체",
    });
    this.reservationOptions.bsnCode.unshift({ comCode: "%", comName: "전체" });
    this.reservationOptions.partDiv.unshift({ comCode: "%", comName: "전체" });
    this.reservationOptions.dwCode.unshift({ comCode: "%", comName: "전체" });
    this.reservationOptions.memberDiv.unshift({
      comCode: "%",
      comName: "전체",
    });
    this.reservationOptions.resveChangeDiv.unshift({
      comCode: "%",
      comName: "전체",
    });

    this.getReservationHistory();
  },
  watch: {
    $route() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
  },
  methods: {
    numberWithCommas,
    valueAccessor(field, data, column) {
      let code = data[field];
      let returnCode = null;
      if (code != null) {
        returnCode = commonCodesGetComName(column.comCode, code);
      }
      return returnCode;
    },
    getDayOfWeekCaption: getDayOfWeekCaption,
    getDayOfWeekCaptionColor: getDayOfWeekCaptionColor,
    contactTelValueAccess: gridUtilGetTelColumnAccess,
    openMemberPopup() {
      this.isMemberSelectPopupOpen = true;

      let memberData = {
        memberNameNo: this.searchConditions.resveName,
      };
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(memberData);
      });
    },
    openGroupPopup() {
      this.isGroupPopupOpen = true;

      let groupPopupData = {
        grpName: this.grpName,
      };

      this.$nextTick(() => {
        this.$refs.groupPopup.openPopup(groupPopupData);
      });
    },
    memberSelectPopupClosed() {
      this.isMemberSelectPopupOpen = false;
    },
    onGroupPopupClosed(event) {
      this.isGroupPopupOpen = false;

      if (event.selectedGroup) {
        this.grpName = event.selectedGroup.grpName;
        this.searchConditions.grpNo = event.selectedGroup.grpNo;
      }
    },
    memberSelectPopupConfirmed(data) {
      this.isMemberSelectPopupOpen = false;

      this.searchConditions.resveName = data.selectedRowData.memberName;
      this.searchConditions.membershipId = data.selectedRowData.membershipId;
    },
    memberNoClear() {
      this.searchConditions.resveName = null;
      this.searchConditions.membershipId = null;
    },
    onOccurDeFromChange(args) {
      if (args.value === null) {
        this.searchConditions.occurDeFrom = null;
      } else {
        this.searchConditions.occurDeFrom = getFormattedDateTimePicker(
          args,
          "yyyy-MM-dd"
        );
      }
    },
    grpNoClear() {
      this.grpName = null;
      this.searchConditions.grpNo = null;
    },
    reservationHistoryGridCustomiseCell(args) {
      if (args.data.timeIdLineFlag) {
        args.cell.classList.add(
          this.$t("className.grid.devReservationPartDivLine")
        );
      }

      if (args.column.field === "bsnCodeName") {
        args.cell.style.color = args.data.bsnCodeColor;
      } else if (args.column.field === "dwName") {
        args.cell.style.color = getDayColorValue(args.data.dwCode, args.data.bsnCode);
      } else if (args.column.field === "bsnCode") {
        args.cell.style.color = commonCodesGetColorValue(
          "BSN_CODE",
          args.data.bsnCode
        );
      } else if (args.column.field === "resveChangeName") {
        args.cell.style.color = args.data.resveChangeColor;
      } else if (
        args.column.field === "norPaymt" &&
        args.data.norPaymt > args.data.applyPaymt
      ) {
        // 정상 요금 > 적용요금 이면 취소 라인 표시
        args.cell.style.textDecoration = "line-through";
        args.cell.style.textDecorationColor = "red";
      }

      if ([
        "contentTeamGreenFee",
      ].includes(args.column.field) && args.data[args.column.field] === 0) {
        args.cell.innerText = "-";
      }
    },
    searchDetail() {
      this.isSearchDetailPopupOpen = true;
      this.$refs.searchDetailPopup.show();
    },
    onDialogViewButtonClicked() {
      this.$refs["reservationHistoryGrid"].resetPage();
      this.getReservationHistory();
    },
    onDialogCloseButtonClicked() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
    onDialogSearchConditionInitClicked() {
      this.searchConditions = Object.assign({}, this.searchConditionsOrigin);
      this.grpName = null;
    },
    onViewButtonClicked() {
      this.$refs["reservationHistoryGrid"].resetPage();
      this.getReservationHistory();
    },
    async getReservationHistory({ offset,filters,orders } = {}) {
      if (!this.searchConditions.dateFrom) {
        const columnName = this.reservationOptions.dateDiv.find(
          (item) => item.comCode === this.searchConditions.dateDiv
        );

        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", [
            columnName.comName,
          ])
        );
      }
      if (!this.searchConditions.dateTo) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["종료일자"])
        );
      }

      const {
        orders: gridOrders,
        filters: gridFilters,
        page: gridPage,
      } = this.$refs["reservationHistoryGrid"].getOrdersAndFiltersAndPage();

      const limit = this.reservationHistoryGridProps.sizeOfRecordPerPage;

      const _orders = JSON.parse(JSON.stringify(orders || gridOrders || []));
      if (_orders.findIndex(({ field }) => field === "memberName") < 0) {
        _orders.push({
          field: "resveDate",
          direction: "asc",
        });
      }
      if (_orders.findIndex(({ field }) => field === "resveTime") < 0) {
        _orders.push({
          field: "resveTime",
          direction: "asc",
        });
      }
      if (_orders.findIndex(({ field }) => field === "contentResveCourse") < 0) {
        _orders.push({
          field: "contentResveCourse",
          direction: "asc",
        });
      }

      let params = {
        dateDiv: this.searchConditions.dateDiv,
        dateFrom: this.searchConditions.dateFrom,
        dateTo: this.searchConditions.dateTo,
        resveTime:
          this.searchConditions.resveTime &&
          this.searchConditions.resveTime !== "00:00"
            ? this.searchConditions.resveTime
            : undefined,
        resveName: this.searchConditions.resveName
          ? this.searchConditions.resveName
          : undefined,
        membershipId: this.searchConditions.membershipId
          ? this.searchConditions.membershipId
          : undefined,
        resveCourse:
          this.searchConditions.resveCourse === "%"
            ? undefined
            : this.searchConditions.resveCourse,
        bsnCode:
          this.searchConditions.bsnCode === "%"
            ? undefined
            : this.searchConditions.bsnCode,
        partDiv:
          this.searchConditions.partDiv === "%"
            ? undefined
            : this.searchConditions.partDiv,
        dwCode:
          this.searchConditions.dwCode === "%"
            ? undefined
            : this.searchConditions.dwCode,
        memberDiv:
          this.searchConditions.memberDiv === "%"
            ? undefined
            : this.searchConditions.memberDiv,
        resveChangeDiv:
          this.searchConditions.resveChangeDiv === "%"
            ? undefined
            : this.searchConditions.resveChangeDiv,
        grpNo:
          this.searchConditions.grpNo === null
            ? undefined
            : this.searchConditions.grpNo,
        contactTelIdx:
          !this.searchConditions.contactTelIdx ||
          this.searchConditions.contactTelIdx.trim() === ""
            ? undefined
            : this.searchConditions.contactTelIdx,
        resveRemarks:
          !this.searchConditions.resveRemarks ||
          this.searchConditions.resveRemarks.trim() === ""
            ? undefined
            : this.searchConditions.resveRemarks,
        isOut: false,
        limit,
        offset: offset || (gridPage - 1) * limit,
        filters: filters || gridFilters,
        orders: _orders.length > 0 ? _orders : undefined,
      };

      let bizCodeList = [];
      if (this.searchConditions.bizCode === "%") {
        bizCodeList = this.reservationOptions.bizCode
          .filter((code) => code.bizCode !== "%")
          .map((code) => code.bizCode);
      } else {
        bizCodeList.push(this.searchConditions.bizCode);
      }
      params.bizCodeList = bizCodeList;


      const {
        total,
        records
      } = await GolfErpAPI.postReservationHistory(params);

      const dataSource = records.map((data)=>{
        if(data.contentResveCourse) {
          data.contentResveCourse = commonCodesGetComName("COURSE_CODE",data.contentResveCourse);
        }
        if(data.contentHoleDiv) {
          data.contentHoleDiv = commonCodesGetComName("HOLE_DIV",data.contentHoleDiv);
        }
        if(data.contentResveChannel) {
          data.contentResveChannel = commonCodesGetComName("RESVE_CHANNEL",data.contentResveChannel);
        }
        if(data.contentMemberDiv) {
          data.contentMemberDiv = commonCodesGetComName("MEMBER_DIV",data.contentMemberDiv);
        }
        if(data.contentTimeDiv) {
          data.contentTimeDiv = commonCodesGetComName("TIME_DIV",data.contentTimeDiv);
        }
        if(data.contentTeamGreenFee === 0) {
          data.contentTeamGreenFee = "-";
        }
        if(data.resveDate) {
          data.resveDate = getFormattedDate(data.resveDate);
        }
        if(data.resveTime) {
          data.resveTime = data.resveTime.substring(0, 2) + ":" + data.resveTime.substring(2, 4);
        }
        if(data.insertDt) {
          data.insertDt = getFormattedDate(data.insertDt,"YYYY-MM-DD HH:mm:ss");
        }
        if(data.resveChangeDiv) {
          data.resveChangeDiv = commonCodesGetComName("RESVE_CHANGE_DIV",data.resveChangeDiv);
        }
        return data;
      });
      this.reservationHistorys = dataSource;
      this.reservationHistorysLength = total;
    },
    print: function () {
      const result = this.$refs.reservationHistoryGrid.getRecords();
      if (result.length < 1) {
        return this.errorToast(this.$t("report.popupMessage.noData"));
      }

      const bizCode = this.reservationOptions.bizCode.find(
        (item) => item.bizCode === this.searchConditions.bizCode
      );
      const dateDiv = this.reservationOptions.dateDiv.find(
        (item) => item.comCode === this.searchConditions.dateDiv
      );

      const searchOptionsList = [
        {
          key: this.labels.bizCode,
          value: bizCode ? bizCode.bizName : undefined,
        },
        {
          key: dateDiv.comName,
          value:
            this.searchConditions.dateFrom +
            " ~ " +
            this.searchConditions.dateTo,
        },
        {
          key: this.labels.resveTime,
          value: this.searchConditions.resveTime,
        },
        {
          key: this.labels.resveName,
          value: this.searchConditions.resveName,
        },
        {
          key: this.labels.courseCode,
          value: commonCodesGetComName(
            "COURSE_CODE",
            this.searchConditions.resveCourse
          ),
        },
        {
          key: this.labels.bsnCode,
          value: commonCodesGetComName(
            "BSN_CODE",
            this.searchConditions.bsnCode
          ),
        },
        {
          key: this.labels.partDiv,
          value: commonCodesGetComName(
            "PART_DIV",
            this.searchConditions.partDiv
          ),
        },
        {
          key: this.labels.dwCode,
          value: commonCodesGetComName("DW_CODE", this.searchConditions.dwCode),
        },
        {
          key: this.labels.memberDiv,
          value: commonCodesGetComName(
            "MEMBER_DIV",
            this.searchConditions.memberDiv
          ),
        },
        {
          key: this.labels.resveChangeDiv,
          value: commonCodesGetComName(
            "RESVE_CHANGE_DIV",
            this.searchConditions.resveChangeDiv
          ),
        },
        {
          key: this.labels.grpNo,
          value: this.searchConditions.grpNo,
        },
        {
          key: this.labels.contactTelIdx,
          value:
            !this.searchConditions.contactTelIdx ||
            this.searchConditions.contactTelIdx.trim() === ""
              ? undefined
              : this.searchConditions.contactTelIdx,
        },
        {
          key: this.labels.resveRemarks,
          value:
            !this.searchConditions.resveRemarks ||
            this.searchConditions.resveRemarks.trim() === ""
              ? undefined
              : this.searchConditions.resveRemarks,
        },
      ];

      const searchOptions = searchOptionsList
        .filter((item) => !!item.value)
        .map((item) => item.key + ": " + item.value)
        .join(", ");

      const reportData = {
        reportJson: {
          jsonData: result,
          username: this.username,
          uniqueCode: this.$options.name,
          searchOptions,
        },
      };

      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    onClickExcel() {
      this.$refs.groupReservationStatusListGrid.dataSource = this.reservationHistorys;
      this.$nextTick(() => {
        this.$refs.groupReservationStatusListGrid.excelExport();
      });
    },
    onGridDataFiltered(event, { page, filters, orders }) {
      if (!event) {
        return;
      }

      this.getReservationHistory({
        offset: (page - 1) * 50,
        filters,
        orders,
      });
    },
  },
};
</script>

<template>
  <div>
    <ejs-dialog
      ref="calendarWeatherPopup"
      :header="`기상 등록 (${bsnDate})`"
      width="380"
      :animationSettings="{ effect: 'None' }"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :close="closePopup"
    >
      <div class="window calendarWeather">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <div class="title required">날씨구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group select weather">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        id="weatherDiv"
                                        ref="weatherDiv"
                                        v-model="weatherDiv"
                                        :dataSource="weatherCodeOptions"
                                        :fields="fields"
                                        :allowFiltering="false"
                                        cssClass="body-data-dropdown"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">
                                    <div class="weather">
                                      <img
                                        :src="weather"
                                        alt="날씨 아이콘"
                                        v-if="weather"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">최저 온도</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group number">
                                    <div class="form">
                                      <input-number
                                        ref="lwetTp"
                                        v-model="lwetTp"
                                        :allowDot="true"
                                        :allowMinus="true"
                                        :propMaxLength="3"
                                        :propMaxUnderDotLength="1"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">℃</li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">최고 온도</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group number">
                                    <div class="form">
                                      <input-number
                                        v-model="topTp"
                                        :allowDot="true"
                                        :allowMinus="true"
                                        :propMaxLength="3"
                                        :propMaxUnderDotLength="1"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">℃</li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">강수량</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group number">
                                    <div class="form">
                                      <input-number
                                        v-model="prcptQy"
                                        :allowDot="true"
                                        :allowMinus="true"
                                        :propMaxLength="3"
                                        :propMaxUnderDotLength="1"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">mm</li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">적설량</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group number">
                                    <div class="form">
                                      <input-number
                                        v-model="snowdQy"
                                        :allowDot="true"
                                        :allowMinus="true"
                                        :propMaxLength="3"
                                        :propMaxUnderDotLength="1"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">mm</li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">최저 습도</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group number">
                                    <div class="form">
                                      <input-number
                                        v-model="lwetHd"
                                        :allowDot="true"
                                        :allowMinus="true"
                                        :propMaxLength="3"
                                        :propMaxUnderDotLength="1"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">%</li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">최고 습도</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group number">
                                    <div class="form">
                                      <input-number
                                        v-model="topHd"
                                        :allowDot="true"
                                        :allowMinus="true"
                                        :propMaxLength="3"
                                        :propMaxUnderDotLength="1"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">%</li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">풍향구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        id="windDirectionDiv"
                                        ref="windDirectionDiv"
                                        v-model="windDirectionDiv"
                                        :dataSource="windDirectionOptions"
                                        :fields="fields"
                                        :allowFiltering="false"
                                        cssClass="body-data-dropdown"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text" />
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">풍속</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group number">
                                    <div class="form">
                                      <input-number
                                        v-model="windSpeed"
                                        :allowDot="true"
                                        :allowMinus="true"
                                        :propMaxLength="3"
                                        :propMaxUnderDotLength="1"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">m/s</li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">비고</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-textarea v-model="remarks" />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="apply keyColor">
              <erp-button
                  id="refreshWeather"
                  buttonDiv="GET"
                  @click.native="onRefreshClick"
                  :isIconCustom="true"
                  :is-shortcut-button="true"

              >
                새로고침
              </erp-button>
            </li>
            <li class="save keyColor">
              <erp-button
                  buttonDiv="SAVE"
                  @click.native="onSaveClick"
                  :isIconCustom="true"
                  :is-shortcut-button="true"
              >
                저장
              </erp-button>
            </li>
            <li>
              <erp-button
                  buttonDiv="DELETE"
                  @click.native="onDeleteClick"
                  :isIconCustom="true"
                  :is-shortcut-button="true"
              >
                삭제
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="closePopup"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  commonCodesGetCommonCode,
  commonCodesGetCodeDesc,
  commonCodeGetComCodeDefaultValue,
} from "@/utils/commonCodes";
import { validateFormRefs } from "@/utils/formUtil";
import InputNumber from "@/components/common/InputNumber";
import InputTextarea from "@/components/common/text/InputTextarea";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

export default {
  name: "CalendarWeatherPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputNumber,
    InputTextarea,
    ErpButton
  },
  data() {
    return {
      bsnDate: null,
      weather: null,
      weatherDiv: commonCodeGetComCodeDefaultValue("WEATHER_CODE"),
      lwetTp: 0,
      topTp: 0,
      prcptQy: 0,
      snowdQy: 0,
      lwetHd: 0,
      topHd: 0,
      windDirectionDiv: commonCodeGetComCodeDefaultValue("WIND_DIRECTION_DIV"),
      windSpeed: 0,
      remarks: "",
      weatherCodeOptions: commonCodesGetCommonCode("WEATHER_CODE"),
      windDirectionOptions: commonCodesGetCommonCode("WIND_DIRECTION_DIV"),
      fields: { text: "comName", value: "comCode" },
      updateList: {},
      validateRefList: {
        weatherDiv: { required: true },
      },
    };
  },
  watch: {
    weatherDiv() {
      this.changeWeather();
    },
  },
  async mounted() {
    await this.$nextTick();
    const $el = document.querySelector("#refreshWeather");
    if ($el) {
      setTimeout(function () {
        document.activeElement.blur();
        $el.focus();
      });
    }
  },
  methods: {
    validateFormRefs,
    showPopup(bsnDate) {
      this.bsnDate = bsnDate;
      this.fetch();
    },
    closePopup() {
      this.$emit("popupClosed");
    },
    changeWeather() {
      if (!this.weatherDiv) {
        return;
      }
      const weatherDiv = commonCodesGetCodeDesc("WEATHER_CODE", this.weatherDiv);
      this.weather = require(`../../../assets/images/weather/${weatherDiv}`);
    },
    async onSaveClick() {
      if (!this.validate()) {
        return;
      }
      const params = {
        bsnDate: this.bsnDate,
        weatherDiv: this.weatherDiv,
        lwetTp: this.lwetTp,
        topTp: this.topTp,
        prcptQy: this.prcptQy,
        snowdQy: this.snowdQy,
        lwetHd: this.lwetHd,
        topHd: this.topHd,
        windDirectionDiv: this.windDirectionDiv,
        windSpeed: this.windSpeed,
        remarks: this.remarks,
      };
      try {
        await GolfErpAPI.patchWeather(params);
        this.infoToast(this.$t("main.popupMessage.saved"));
        this.closePopup();
      } catch (error) {
        this.errorToast(error);
      }
    },
    async onDeleteClick() {
      try {
        await GolfErpAPI.deleteWeather({ bsnDate: this.bsnDate });
        this.infoToast(this.$t("main.popupMessage.deleted"));
        this.closePopup();
      } catch (error) {
        this.errorToast(error);
      }
    },
    onRefreshClick() {
      this.fetch();
    },
    async fetch() {
      const { data } = await GolfErpAPI.fetchWeatherInfo({
        bsnDate: this.bsnDate,
      });
      if (data) {
        this.weatherDiv = data.weatherDiv || commonCodeGetComCodeDefaultValue("WEATHER_CODE");
        this.lwetTp = data.lwetTp || 0;
        this.topTp = data.topTp || 0;
        this.prcptQy = data.prcptQy || 0;
        this.snowdQy = data.snowdQy || 0;
        this.lwetHd = data.lwetHd || 0;
        this.topHd = data.topHd || 0;
        this.windDirectionDiv = data.windDirectionDiv || commonCodeGetComCodeDefaultValue("WIND_DIRECTION_DIV");
        this.windSpeed = data.windSpeed || 0;
        this.remarks = data.remarks;
      }
      this.changeWeather();
    },
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
  },
};
</script>

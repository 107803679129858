<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="dialog"
      v-bind="dialogProps"
      @close="onDialogClose"
    >
      <div
        class="typePos"
        :style="{
          minWidth: `${dialogProps.width}px`,
          minHeight: `${dialogProps.height - 66}px`,
        }"
      >
        <div class="typePos-wrapper-header">
          <div class="typePos-body">
            본 시스템에서는 비밀번호를<br />
            최소 1개월마다 변경하기를 권하고 있습니다.<br />
            <div v-if="systemConfigInfo.pwdScrtyLevel === 'L'">
              ㆍ 8~15자의 영문, 숫자, 특수문자만 가능.<br />
              ㆍ 영문, 숫자 혼용.<br />
              ㆍ 공백문자 사용불가.<br />
              ㆍ 일부 특수문자 사용가능. (! @@ # $ % ^ & * ( ) _ + |
              { } &lt; &gt; : ; [ ] - = , . ?)
            </div>
            <div v-else>
              ㆍ 쉬움 설정의 제약 포함.<br />
              ㆍ 본인 Email ID 포함 불가.<br />
              ㆍ 3자리 이상 반복문자 사용불가.<br />
              ㆍ 3회 이상 연속 된 알파벳 사용 불가.<br />
              ㆍ 3회 이상 연속 된 숫자 사용 불가.
            </div>
          </div>
        </div>
        <div class="typePos-wrapper-main">
          <ul class="typePos-list">
            <li>
              <div class="typePos-title">이전 비밀번호</div>
              <div class="typePos-content">
                <div class="ui-input">
                  <!-- 비활성(Attribute) : disabled -->
                  <input
                    ref="input-old-password"
                    type="password"
                    placeholder="입력하세요."
                    v-model="oldPassword"
                  />
                </div>
              </div>
            </li>
            <li>
              <div class="typePos-title">신규 비밀번호</div>
              <div class="typePos-content">
                <div class="ui-input">
                  <!-- 비활성(Attribute) : disabled -->
                  <input
                    ref="input-new-password"
                    type="password"
                    placeholder="입력하세요."
                    v-model="newPassword"
                  />
                </div>
              </div>
            </li>
            <li>
              <div class="typePos-title">비밀번호 확인</div>
              <div class="typePos-content">
                <div class="ui-input">
                  <!-- 비활성(Attribute) : disabled -->
                  <input
                    ref="input-repeat-password"
                    type="password"
                    placeholder="입력하세요."
                    v-model="repeatPassword"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li class="typePos-save">
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="SAVE"
                class="ui-button i-typePrimary"
                :is-ignore-disabled="true"
                :is-shortcut-button="true"
                :visible-shortcut-string="false"
                :use-syncfusion-component-style="false"
                @click.native="onSaveClick"
              >
                <div class="i-item">
                  저장 ({{ saveShortcutButtonShortcutString }})
                </div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click.native="onCloseClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-header {padding: 17px 20px 0 20px; margin-bottom: -4px;}
.typePos .typePos-wrapper-header .typePos-body {color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px; text-align: center;}

.typePos .typePos-wrapper-main {flex: 1;}
.typePos .typePos-wrapper-main .typePos-list {list-style: none; padding: 15px 13px; margin: 0;}
.typePos .typePos-wrapper-main .typePos-list > li {padding: 5px 0;}
.typePos .typePos-wrapper-main .typePos-list > li::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-list > li .typePos-title,
.typePos .typePos-wrapper-main .typePos-list > li .typePos-content {padding: 0 7px;}
.typePos .typePos-wrapper-main .typePos-list > li .typePos-title {position: relative; float: left; padding-top: 10px; padding-bottom: 11px; color: #222; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-list > li .typePos-title::before {display: none; position: absolute; top: 9px; left: 0; width: 4px; height: 4px; border-radius: 50%; background-color: #df2929; content: '';}
.typePos .typePos-wrapper-main .typePos-list > li.typePos-required .typePos-title::before {display: block;}
.typePos .typePos-wrapper-main .typePos-list > li .typePos-content {box-sizing: border-box; width: 314px; float: right;}
.typePos .typePos-wrapper-main .typePos-list > li .typePos-content >>> .ejs-number input {width: 100%;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
.typePos .typePos-wrapper-footer .typePos-button > li.typePos-save .ui-button .i-item::before {background-position-x: calc(-20px * 8);}
</style>

<script>
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton.vue";
import { getSaveButtonShortcut, toShortcutString } from "@/utils/KeyboardUtil";
import {getSystemConfigInfo} from "@/api/systemConfig";

export default {
  name: "ChangePasswordPopup",
  components: { ErpButton },
  mixins: [messagePopupDialogMixin],
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      repeatPassword: "",
      systemConfigInfo: {}
    };
  },
  computed: {
    saveShortcutButtonShortcutString() {
      return toShortcutString(getSaveButtonShortcut());
    },
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: "비밀번호 변경",
        width: 450,
        height: 450,
        allowDragging: true,
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
  },
  mounted() {
    this.fetchSystemConfigInfo();
  },
  methods: {
    show() {
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.$emit("close");
    },
    onSaveClick() {
      if (!this.oldPassword) {
        this.errorToast("이전 비밀번호를 입력해주세요.");
        this.$refs["input-old-password"].focus();
        return;
      }
      if (!this.newPassword) {
        this.errorToast("신규 비밀번호를 입력해주세요.");
        this.$refs["input-new-password"].focus();
        return;
      }
      if (!this.repeatPassword) {
        this.errorToast("비밀번호를 한번 더 입력해주세요.");
        this.$refs["input-repeat-password"].focus();
        return;
      }
      if (this.newPassword !== this.repeatPassword) {
        this.errorToast(
          "비밀번호 확인이 맞지 않습니다. 비밀번호를 다시 확인해주세요."
        );
        this.$refs["input-repeat-password"].focus();
        return;
      }

      this.updatePassword().then(() => {
        this.infoToast("변경되었습니다.");
        this.hide();
      });
    },
    onCloseClick() {
      this.hide();
    },

    // API
    async fetchSystemConfigInfo() {
      const {
        value: { confCom },
      } = await getSystemConfigInfo();
      this.systemConfigInfo = confCom;
    },
    async updatePassword() {
      await GolfErpAPI.updatePassword(this.oldPassword, this.newPassword);
    },
  },
};
</script>

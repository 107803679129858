<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">사업장</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  v-model="bizCode"
                  :dataSource="businessPlaceDropdownListDatasource"
                  :allowFiltering="false"
                  :enabled="isReady"
                  :fields="{ text: 'text', value: 'value' }"
                  cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">그룹권한</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  v-model="authGroupId"
                  :dataSource="authorityGroupDropdownListDatasource"
                  :allowFiltering="false"
                  :enabled="isReady"
                  :fields="{ text: 'text', value: 'value' }"
                  cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">사용자</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  v-model="searchQueryDivision"
                  :dataSource="searchQueryDivisionDropdownListDatasource"
                  :allowFiltering="false"
                  :enabled="isReady"
                  :fields="{ text: 'text', value: 'value' }"
                  cssClass="lookup-condition-dropdown"
                />
              </li>
              <li class="item">
                <input-text
                  :disabled="!isReady"
                  v-model="q"
                  placeholder="내용을 입력하세요."
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">사용여부</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  v-model="isIntercept"
                  :dataSource="isInterceptDropdownListDatasource"
                  :allowFiltering="false"
                  :enabled="isReady"
                  :fields="{ text: 'text', value: 'value' }"
                  cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :disabled="
              !isReady ||
              isLoading
            "
              :ignore="isPopupOpened"
              @click.native="fetchUsers"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">사용자 목록</div>
              <div class="header-caption">
                [ {{ users.length | numberWithCommas }} 건 ]
              </div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :disabled="
                      !isReady
                    "
                      :ignore="isPopupOpened"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="userManagement.shortcuts.addUser"
                      :shortcut="{key: 'F3'}"
                      @click="onCreateUserClick"
                  >
                    추가
                  </erp-button>

                </li>
                <li class="delete">
                  <erp-button
                      button-div="DELETE"
                      :is-shortcut-button="true"
                      :disabled="
                      !isReady
                    "
                      :ignore="isPopupOpened"
                      @click="onDeleteUserClick"
                  >
                    삭제
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <grid
              ref="userGrid"
              v-bind="userGridProps"
              @cellClick="onUserGridCellClick"
              @filtered="onUserGridDataFiltered"
              @sorted="onUserGridDataFiltered"
              @paged="onUserGridDataFiltered"
              @cellEnter="onUserGridCellClick"
            />
          </div>
        </section>
      </article>
    </div>
    <user-details-popup
      ref="user-details-popup"
      v-if="visibleUserDetailsPopup"
      @close="onUserDetailsPopupClose"
    />
  </div>
</template>

<script>
import { numberWithCommas } from "@/utils/number";
import commonMixin from "@/views/layout/mixin/commonMixin";
import Grid from "@/components/grid/Grid";
import InputText from "@/components/common/text/InputText";
import { getBizCodesObject } from "@/utils/pageKeyUtil";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import UserDetailsPopup from "@/views/authority-management/popup/UserDetailsPopup";
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";
import {mapGetters} from "vuex";

export default {
  name: "UserManagement",
  components: {
    UserDetailsPopup,
    InputText,
    Grid,
    ErpButton,
  },
  mixins: [commonMixin, messagePopupDialogMixin],
  filters: { numberWithCommas },
  async mounted() {
    await this.initialize();
  },
  data() {
    return {
      isReady: false,
      isLoading: false,

      bizCode: "",
      authGroups: [],
      authGroupId: "",
      searchQueryDivision: "name",
      q: "",
      isIntercept: "true",

      users: [],
      loginUser:{},
      visibleUserDetailsPopup: false,
    };
  },
  computed: {
    ...mapGetters(["userId"]),
    isPopupOpened() {
      return this.visibleUserDetailsPopup;
    },
    businessPlaceDropdownListDatasource() {
      const businessPlaceDropdownListDatasource = (
        getBizCodesObject() || []
      ).map(({ bizName, bizCode }) => ({
        text: bizName,
        value: bizCode,
      }));

      businessPlaceDropdownListDatasource.unshift({ text: "ALL", value: "" });
      return businessPlaceDropdownListDatasource;
    },
    authorityGroupDropdownListDatasource() {

      const authorityGroupDropdownListDatasource = this.authGroups.map(
        ({ id, name }) => ({
          text: name,
          value: id,
        })
      );
      authorityGroupDropdownListDatasource.unshift({ text: "ALL", value: "" });
      return authorityGroupDropdownListDatasource;
    },
    searchQueryDivisionDropdownListDatasource() {
      return [
        {
          text: "사용자명",
          value: "name",
        },
        {
          text: "사용자 ID",
          value: "id",
        },
        {
          text: "업무/부서",
          value: "remarks",
        },
      ];
    },
    isInterceptDropdownListDatasource() {
      return [
        {
          text: "ALL",
          value: "",
        },
        {
          text: "사용",
          value: "true",
        },
        {
          text: "제한",
          value: "false",
        },
      ];
    },
    userGridProps() {
      return {
        columns: [
          {
            field: "name",
            type: String,
            name: "사용자명",
            width: 150,
            cellStyle: {
              textAlign: "center",
            },
            cellClass: () => [this.$t("className.grid.clickArea")],
          },
          {
            field: "email",
            type: String,
            minWidth: 80,
            name: "이메일",
            width: 200,
            cellClass: () => [this.$t("className.grid.clickArea")],
          },
          {
            field: "remarks",
            type: String,
            name: "업무 / 부서",
          },
          {
            field: "deptAdminFlag",
            type: Boolean,
            minWidth: 100,
            name: "부서 관리자",
            width: 100,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            field: "strBusinessPlaces",
            filterable: false,
            sortable: false,
            iteratee: ({ businessPlaces }) =>
              businessPlaces?.map(({ name }) => name)?.join(", ") || "",
            type: String,
            name: "사업장권한",
            width: 250,
          },
          {
            field: "strAuthGroups",
            filterable: false,
            sortable: false,
            iteratee: ({ authGroups }) =>
              authGroups?.map(({ name }) => name)?.join(", ") || "",
            type: String,
            name: "그룹권한",
            width: 250,
          },
          {
            field: "isBoardManager",
            type: Boolean,
            minWidth: 120,
            name: "게시판관리자",
            width: 120,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            field: "isBlocked",
            type: Boolean,
            minWidth: 90,
            name: "접속차단",
            width: 90,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            field: "useLimit",
            type: Boolean,
            minWidth: 90,
            name: "사용제한",
            width: 90,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            field: "updatePasswordDate",
            type: String,
            name: "비밀번호 변경일시",
            width: 140,
            cellStyle: {
              textAlign: "center",
            },
          },
        ],
        pageable: false,
        records: this.users,
      };
    },
  },
  methods: {
    async initialize() {
      if (this.isReady) {
        return;
      }
      this.loginUser = await GolfErpAPI.fetchUser(this.userId);
      let [authGroups] = await Promise.all([
        GolfErpAPI.fetchAuthorityGroups(),
        this.fetchUsers(),
      ]);
      if(!this.loginUser.authGroups.map(it=> it.id.toString()).includes('SYSTEM')){
        authGroups = authGroups.filter(it=>it.id!='SYSTEM');
      }
      this.authGroups = authGroups;

      this.isReady = true;
    },
    onCreateUserClick() {
      this.visibleUserDetailsPopup = true;
      this.$nextTick(() => {
        this.$refs["user-details-popup"].create();
      });
    },
    async onDeleteUserClick() {
      const [{ id }] = this.$refs["userGrid"].getSelectedRows();

      await this.deleteUser(id);
      await this.fetchUsers();
    },
    onUserGridCellClick(event, { column: { field }, record: { id } }) {
      if (
        (field === "name" || field === "email") &&
        !this.commonMixinIsButtonDisableByAuth("userGet")
      ) {
        event.preventDefault();

        this.visibleUserDetailsPopup = true;
        this.$nextTick(() => {
          this.$refs["user-details-popup"].modify({ id });
        });
      }
    },
    async onUserDetailsPopupClose() {
      this.visibleUserDetailsPopup = false;
      await this.fetchUsers();
    },
    async onUserGridDataFiltered() {
      await this.fetchUsers();
    },

    async fetchUsers() {
      const {
        bizCode,
        authGroupId,
        searchQueryDivision,
        q,
        isIntercept,
      } = this;
      const { filters, orders } = this.$refs[
        "userGrid"
      ].getOrdersAndFiltersAndPage();

      if (q) {
        switch (searchQueryDivision) {
          case "name":
          case "id":
          case "remarks":
            filters.unshift({
              field: searchQueryDivision,
              condition: "contains",
              values: [q],
            });
        }
      }
      if (isIntercept) {
        switch (isIntercept) {
          case "true":
          case "false":
            filters.unshift({
              field: "useLimit",
              condition: isIntercept === "true" ? "false" : "true",
            });
        }
      }
      if (!orders.find(({ field }) => field === "name")) {
        orders.push({
          field: "name",
          direction: "asc",
        });
      }

      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      try {
        const dataUsers = await GolfErpAPI.fetchUsers({bizCode, authGroupId, filters, orders,});
        if(!this.loginUser.authGroups.map(it=> it.id.toString()).includes('SYSTEM')){
          this.users = dataUsers.filter(it=> !it.authGroups.map(item=> item.id).includes('SYSTEM'));
        }else {
          this.users = dataUsers;
        }
      } finally {
        this.isLoading = false;
      }
    },
    async deleteUser(id) {
      if (!(await this.confirm("선택한 사용자를 삭제하시겠습니까?"))) {
        return;
      }

      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      try {
        await GolfErpAPI.deleteUser(id);
        this.infoToast("삭제되었습니다.");
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>

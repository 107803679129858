import request from "@/utils/request";

/**
 * @return Promise
 */
export function getCaddieInfoList(
  caddieNameNo,
  caddieEmployDiv,
  caddieGroupDiv,
  caddieWorkDiv,
  caddieGrade,
  caddieFeeDiv,
  appnCart,
  birthMonth,
  sexCodes,
  birthMonthFlag,
  employStatementFlag,
  lflyStatementFlag
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/caddie/info/list",
    method: "get",
    params: {
      caddieNameNo: caddieNameNo,
      caddieEmployDiv: caddieEmployDiv,
      caddieGroupDiv: caddieGroupDiv,
      caddieWorkDiv: caddieWorkDiv,
      caddieGrade: caddieGrade,
      caddieFeeDiv: caddieFeeDiv,
      appnCart: appnCart,
      birthMonth: birthMonth,
      sexCodes: sexCodes,
      birthMonthFlag: birthMonthFlag,
      employStatementFlag: employStatementFlag,
      lflyStatementFlag: lflyStatementFlag
    },
  });
}

/**
 * @return Promise
 */
export function getCaddieInfoDetail(caddieId) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/caddie/info/detail",
    method: "get",
    params: {
      caddieId: caddieId,
    },
  });
}

/**
 * @return Promise
 */
export function getCaddieNameCheck(caddieName, caddieId, isBackground = false) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/caddie/info/detail/name-check",
    isBackground,
    method: "get",
    params: {
      caddieName: caddieName,
      caddieId: caddieId,
    },
  });
}

/**
 * @return Promise
 */
export function getCaddieHpNoCheck(hpNo, caddieId, isBackground = false) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/caddie/info/detail/tel-check",
    isBackground,
    method: "get",
    params: {
      hpNo: hpNo,
      caddieId: caddieId,
    },
  });
}

/**
 * @return Promise
 */
export function getCaddieNoCheck(caddieNo, caddieId) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/caddie/info/detail/num-check",
    method: "get",
    params: {
      caddieNo: caddieNo,
      caddieId: caddieId,
    },
  });
}

/**
 * @return Promise
 */
export function getMaxCaddieNo() {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/caddie/info/detail/caddie-no-max",
    method: "get",
  });
}

/**
 * @return Promise
 */
export function saveCaddieInfoDetail(data, bsnDate) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/game_management/caddie/info/detail/${bsnDate}`,
    method: "put",
    data,
  });
}

/**
 * @return Promise
 */
export function deleteCaddieInfoDetail(caddieId) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/caddie/info/detail",
    method: "delete",
    params: {
      caddieId: caddieId,
    },
  });
}

/**
 * @return Promise
 */
export function getVisitorInfoByCaddieArrangeMent(visitDate, timeId, grpNo) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V2,
    url: "caddie/arrangement/visitor",
    method: "get",
    params: {
      visitDate: visitDate,
      timeId: timeId,
      grpNo: grpNo,
    },
  });
}

<template>
  <div>
    <ejs-dialog
        ref="calendarWeatherPopup"
        :header="`수상증명서 ${crtfId ? '상세' : '등록'}`"
        width="380"
        :animationSettings="{ effect: 'None' }"
        :allowDragging="true"
        :showCloseIcon="true"
        :isModal="true"
        :close="closePopup"
    >
      <div class="window calendarWeather">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <div class="title required">내장일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group date">
                                    <div class="form">
                                      <input-date
                                          id="visitDate"
                                          ref="visitDate"
                                          format="YYYY-MM-DD"
                                          cssClass="lookup-condition-datepicker"
                                          v-model="visitDate"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">
                                    ({{ getDayLabel }})
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field memberNameNumber">
                              <!-- 필수 : required -->
                              <div class="title required">회원명/번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group date">
                                    <div class="form">
                                      <input-text
                                          class="validate"
                                          ref="memberName"
                                          v-model="memberName"
                                          @change="onMemberNameChanged"
                                          @keydown.native="formPreventEnterEvent"
                                          @keydown.enter.native="openVisitFindPopup"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">
                                    {{ memberNo }}
                                  </li>
                                  <li class="item form-group button">
                                    <ul class="button">
                                      <li class="search">
                                        <erp-button
                                          button-div="GET"
                                          ref="visitPopupOpenButton"
                                          @click.native="openVisitFindPopup"
                                        >
                                          조회
                                        </erp-button>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">회원구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          id="memberDiv"
                                          ref="memberDiv"
                                          v-model="memberDiv"
                                          :dataSource="memberDivOptions"
                                          :fields="fields"
                                          :allowFiltering="false"
                                          cssClass="body-data-dropdown"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">출발코스/시간</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          id="course"
                                          ref="course"
                                          v-model="course"
                                          :dataSource="courseCodeOptions"
                                          :fields="fields"
                                          :allowFiltering="false"
                                          cssClass="body-data-dropdown"
                                          @change="onCourseCodeOrHoleDivChange"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">
                                    <div class="form">
                                      <input-time
                                          id="time"
                                          ref="time"
                                          v-model="time"
                                          format="HH:mm"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">기록코스</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          id="rcordCourse"
                                          ref="rcordCourse"
                                          v-model="rcordCourse"
                                          :dataSource="courseCodeOptions"
                                          :fields="fields"
                                          :allowFiltering="false"
                                          cssClass="body-data-dropdown"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">기록홀/PAR</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          id="holeDiv"
                                          ref="holeDiv"
                                          v-model="holeDiv"
                                          :dataSource="holeDivOptions"
                                          :fields="fields"
                                          :allowFiltering="false"
                                          cssClass="body-data-dropdown"
                                          @change="onCourseCodeOrHoleDivChange"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">
                                    <div class="form">
                                      <input-number
                                          id="par"
                                          ref="par"
                                          v-model="par"
                                          :allowDot="false"
                                          :allowMinus="true"
                                          :propMaxLength="2"
                                          :propMaxUnderDotLength="1"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">증명서구분/번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          id="crtfCode"
                                          ref="crtfCode"
                                          v-model="crtfCode"
                                          :dataSource="crtfCodeOptions"
                                          :fields="fields"
                                          :allowFiltering="false"
                                          cssClass="body-data-dropdown"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">
                                    <div class="form">
                                      <input-text
                                          id="crtfNo"
                                          ref="crtfNo"
                                          v-model="crtfNo"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">사용클럽</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                          id="useClub"
                                          ref="useClub"
                                          v-model="useClub"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">사용구</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                          id="useBall"
                                          ref="useBall"
                                          v-model="useBall"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">동반자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                          id="cmpn"
                                          ref="cmpn"
                                          v-model="cmpn"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">캐디명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                          id="caddieName"
                                          ref="caddieName"
                                          v-model="caddieName"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">웹오픈여부</div>
                              <div class="content">
                                <div class="item check">
                                  <ul class="check">
                                    <li>
                                      <label>
                                        <input
                                            id="webOpenFlag"
                                            ref="webOpenFlag"
                                            type="checkbox"
                                            v-model="webOpenFlag"
                                        />
                                        <i/>
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">비고</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-textarea v-model="remarks"/>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-shortcut-button="true"
                  :is-key-color="true"
                  v-on:click.native="onSaveClick"
              >
                저장
              </erp-button>
            </li>
            <li class="print">
              <erp-button
                  button-div="PRINT"
                  :is-shortcut-button="true"
                  @click.native="onPrintClick"
              >
                인쇄
              </erp-button>
            </li>
            <li class="delete" v-if="crtfId">
              <erp-button
                  button-div="DELETE"
                  :is-shortcut-button="true"
                  @click.native="onDeleteClick"
              >
                삭제
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closePopup">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <find-visit-popup
        ref="findVisitPopup"
        v-if="isFindVisitPopupOpen"
        @popupClosed="onFindVisitPopupClosed"
    />
  </div>
</template>

<script>
import moment from 'moment';
import commonMixin from '@/views/layout/mixin/commonMixin';
import {getFormattedDate} from '@/utils/date';
import {commonCodesGetCommonCode} from '@/utils/commonCodes';
import {formPreventEnterEvent, validateFormRefs} from '@/utils/formUtil';
import InputText from '@/components/common/text/InputText';
import InputTextarea from '@/components/common/text/InputTextarea';
import InputDate from '@/components/common/datetime/InputDate';
import InputTime from '@/components/common/datetime/InputTime';
import InputNumber from '@/components/common/InputNumber';
import FindVisitPopup from '@/views/common/FindVisitPopup';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: 'AwardCertificatePopup',
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputText,
    InputTextarea,
    InputDate,
    InputTime,
    InputNumber,
    FindVisitPopup,
    ErpButton,
  },
  data() {
    return {
      crtfId: null,
      visitDate: null,
      memberName: null,
      memberId: null,
      memberNo: null,
      memberDiv: null,
      course: null,
      rcordCourse:null,
      time: null,
      holeDiv: null,
      par: 0,
      crtfCode: null,
      crtfNo: null,
      useClub: null,
      useBall: null,
      cmpn: null,
      caddieName: null,
      webOpenFlag: false,
      remarks: null,
      memberDivOptions: commonCodesGetCommonCode('MEMBER_DIV', true),
      courseCodeOptions: commonCodesGetCommonCode('COURSE_CODE', true),
      holeDivOptions: commonCodesGetCommonCode('HOLE_DIV', true)
          ?.filter(item => ['1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(item.comCode)),
      crtfCodeOptions: commonCodesGetCommonCode('CRTF_CODE', true),
      fields: {text: 'comName', value: 'comCode'},
      courseHoleInfos: [],
      isFindVisitPopupOpen: false,
      validateRefList: {
        visitDate: {required: true},
        memberName: {required: true},
        course: {required: true},
        time: {required: true},
        holeDiv: {required: true},
        par: {required: true},
        crtfCode: {required: true},
        crtfNo: {required: true},
        caddieName: {required: true},
      },
    };
  },
  computed: {
    getDayLabel() {
      return moment(this.visitDate).format('ddd');
    },
    getPar() {
      return this.courseHoleInfos
          ?.find(item => item.courseCode === this.course && item.holeDiv === this.holeDiv)
          ?.par || 0;
    },
  },
  methods: {
    formPreventEnterEvent,
    validateFormRefs,
    showPopup(visitDate, courseHoleInfos, visitInfo = null) {
      this.visitDate = visitDate;
      this.courseHoleInfos = courseHoleInfos;
      if (visitInfo) {
        this.crtfId = visitInfo.crtfId;
        this.memberName = visitInfo.visitName;
        this.memberId = visitInfo.memberId;
        this.memberNo = visitInfo.memberNo;
        this.memberDiv = visitInfo.memberDiv;
        this.course = visitInfo.startCourse;
        this.time = visitInfo.startTime;
        this.rcordCourse = visitInfo.rcordCourse;
        this.holeDiv = visitInfo.holeDiv;
        this.par = visitInfo.par;
        this.crtfCode = visitInfo.crtfCode;
        this.crtfNo = visitInfo.crtfNo;
        this.useClub = visitInfo.useClub;
        this.useBall = visitInfo.useBall;
        this.cmpn = visitInfo.cmpn;
        this.caddieName = visitInfo.caddieName;
        this.webOpenFlag = visitInfo.webOpenFlag;
        this.remarks = visitInfo.remarks;
      }
    },
    closePopup() {
      this.$emit('popupClosed');
    },
    async onMemberNameChanged(args) {
      if (args.event && args.previousValue !== args.value) {
        if (args.value.trim() === '') {
          this.setIdAndNoMember();
          return;
        }
        await this.onMemberPopupOpen(false, args.value);
      }
    },
    memberNameInputKeyEnter() {
      this.$refs.visitPopupOpenButton.focusIn();
    },
    async openVisitFindPopup() {
      this.isFindVisitPopupOpen = true;
      await this.$nextTick();
      await this.$refs.findVisitPopup.showFindVisitPopup({
        bsnDate: getFormattedDate(this.visitDate),
        resveVisitName: this.memberName,
        resveVisitFlag: false,
        isGetTeamData: true,
      });
    },
    onFindVisitPopupClosed(args) {
      this.isFindVisitPopupOpen = false;
      if (args && args.selectRowData && args.teamData) {
        this.memberName = args.selectRowData.visitName;
        this.memberNo = args.selectRowData.memberNo;
        this.memberDiv = args.selectRowData.memberDiv;
        this.course = args.selectRowData.startCourse;
        this.time = args.selectRowData.startTime;
        this.cmpn = args.teamData
            ?.filter(item => item.visitName !== args.selectRowData.visitName)
            ?.map(item => item.visitName)
            ?.join(', ');
        this.caddieName = args.selectRowData.caddieName;
      }
    },
    setIdAndNoMember() {
      this.memberId = null;
      this.memberNo = null;
    },
    onCourseCodeOrHoleDivChange() {
      this.par = this.getPar;
    },
    async onSaveClick() {
      if (!this.validate()) {
        return;
      }
      const params = {
        crtfId: this.crtfId,
        visitDate: this.visitDate,
        visitName: this.memberName,
        memberId: this.memberId,
        memberNo: this.memberNo,
        memberDiv: this.memberDiv,
        startCourse: this.course,
        startTime: this.time,
        rcordCourse: this.rcordCourse,
        holeDiv: this.holeDiv,
        par: this.par,
        crtfCode: this.crtfCode,
        crtfNo: this.crtfNo,
        useClub: this.useClub,
        useBall: this.useBall,
        cmpn: this.cmpn,
        caddieName: this.caddieName,
        webOpenFlag: this.webOpenFlag,
        remarks: this.remarks,
      };
      try {
        await GolfErpAPI.patchAwardCertificate(params);
        this.infoToast(this.$t('main.popupMessage.saved'));
        this.$emit('popupConfirmed');
      } catch (error) {
        this.errorToast(error);
      }
    },
    onPrintClick() {
      const data = {
        visitDate: this.visitDate,
        visitName: this.memberName,
        startCourse: this.course,
        rcordCourse: this.rcordCourse,
        holeDiv: this.holeDiv,
        crtfCode: this.crtfCode,
        useClub: this.useClub,
        useBall: this.useBall,
        cmpn: this.cmpn,
        caddieName: this.caddieName,
      };
      this.$emit('popupPrint', data);
    },
    async onDeleteClick() {
      if (!(await this.confirm(this.$t('main.popupMessage.confirmDelete')))) {
        return;
      }
      try {
        await GolfErpAPI.deleteAwardCertificate({crtfId: this.crtfId});
        this.infoToast(this.$t('main.popupMessage.deleted'));
        this.$emit('popupConfirmed');
      } catch (error) {
        this.errorToast(error);
      }
    },
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
  },
};
</script>

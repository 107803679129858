import layout from "@/views/layout/layout";

const stockManageRoute = {
    path: "/stock",
    component: layout,
    redirect: "/stock/product-wrhousng-status",

    children:[
        //region Product routor
        {
            path: "wrhousng-registration",
            name: "ProductWrhousngRegistration",
            component: () => import("@/views/stock-management/product/ProductWrhousngRegistration"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "product-dlivy-registration",
            name: "ProductDlivyRegistration",
            component: () => import("@/views/stock-management/product/ProductDlivyRegistration"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "product-wrhousng-status",
            name: "ProductWrhousngStatus",
            component: () => import("@/views/stock-management/product/ProductWrhousngStatus"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "product-dlivy-status",
            name: "ProductDlivyStatus",
            component: () => import("@/views/stock-management/product/ProductDlivyStatus"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "product-wrhsdlvr-status",
            name: "ProductWrhsdlvrStatus",
            component: () => import("@/views/stock-management/product/ProductWrhsdlvrStatus"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "product-stock-status",
            name: "ProductStockStatus",
            component: () => import("@/views/stock-management/product/ProductStockStatus"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "product-stockmonthly-closing",
            name: "ProductStockMonthlyClosing",
            component: () => import("@/views/stock-management/product/ProductStockMonthlyClosing"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "product-monthly-invntry",
            name: "ProductMonthlyInvntry",
            component: () => import("@/views/stock-management/product/ProductMonthlyInvntry"),
            meta: {
                useUsualStyles: true,
            },
        },
        //endregion

        //region Supplies 저장품관련 라우터
        {
            path: "wrhouscode-registration",
            name: "WrhousCodeRegistration",
            component: () => import("@/views/stock-management/supplies/WrhousCodeRegistration.vue"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "supplies-class-registration",
            name: "SuppliesClassRegistration",
            component: () => import("@/views/stock-management/supplies/SuppliesClassRegistration.vue"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "supplies-code-registration",
            name: "SuppliesCodeRegistration",
            component: () => import("@/views/stock-management/supplies/SuppliesCodeRegistration.vue"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "supplies-wrhousng-registration",
            name: "SuppliesWrhousngRegistration",
            component: () => import("@/views/stock-management/supplies/SuppliesWrhousngRegistration.vue"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "Supplies-Wrhousng-Status",
            name: "SuppliesWrhousngStatus",
            component: () => import("@/views/stock-management/supplies/SuppliesWrhousngStatus.vue"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "supplies-dlivy-status",
            name: "SuppliesDlivyStatus",
            component: () => import("@/views/stock-management/supplies/SuppliesDlivyStatus.vue"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "supplies-wrhsdlvr-status",
            name: "SuppliesWrhsdlvrStatus",
            component: () => import("@/views/stock-management/supplies/SuppliesWrhsdlvrStatus.vue"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "supplies-stock-status",
            name: "SuppliesStockStatus",
            component: () => import("@/views/stock-management/supplies/SuppliesStockStatus.vue"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "supplies-stockmonthly-closing",
            name: "SuppliesStockMonthlyClosing",
            component: () => import("@/views/stock-management/supplies/SuppliesStockMonthlyClosing.vue"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "supplies-monthly-invntry",
            name: "SuppliesMonthlyInvntry",
            component: () => import("@/views/stock-management/supplies/SuppliesMonthlyInvntry.vue"),
            meta: {
                useUsualStyles: true,
            },
        },
        //endregion

        // region equipment routor
        {
            path: "equipment-code-registration",
            name: "EquipmentCodeRegistration",
            component: () => import("@/views/stock-management/equipment/EquipmentCodeRegistration.vue"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "facility-code-registration",
            name: "FacilityCodeRegistration",
            component: () => import("@/views/stock-management/equipment/FacilityCodeRegistration"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "work-code-registration",
            name: "WorkCodeRegistration",
            component: () => import("@/views/stock-management/equipment/WorkCodeRegistration"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "equipment-work-registration",
            name: "EquipmentWorkRegistration",
            component: () => import("@/views/stock-management/equipment/EquipmentWorkRegistration"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "equipment-work-status",
            name: "EquipmentWorkStatus",
            component: () => import("@/views/stock-management/equipment/EquipmentWorkStatus"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "equipment-repair-registration",
            name: "EquipmentRepairRegistration",
            component: () => import("@/views/stock-management/equipment/EquipmentRepairRegistration"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "equipment-repair-status",
            name: "EquipmentRepairStatus",
            component: () => import("@/views/stock-management/equipment/EquipmentRepairStatus"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "oil-use-registration",
            name: "OilUseRegistration",
            component: () => import("@/views/stock-management/equipment/OilUseRegistration"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "oil-use-status",
            name: "OilUseStatus",
            component: () => import("@/views/stock-management/equipment/OilUseStatus"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "oil-stock-status",
            name: "OilStockStatus",
            component: () => import("@/views/stock-management/equipment/OilStockStatus"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "facility-repair-registration",
            name: "FacilityRepairRegistration",
            component: () => import("@/views/stock-management/equipment/FacilityRepairRegistration"),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "facility-repair-status",
            name: "FacilityRepairStatus",
            component: () => import("@/views/stock-management/equipment/FacilityRepairStatus"),
            meta: {
                useUsualStyles: true,
            },
        },
        //endregion

        //region 카드관리 라우터
        {
            path: 'cart-management-cart-info-registration',
            name: 'CartManagementCartInfoRegistration',
            component: () =>
              import('@/views/game-progress/cart-management/CartInfoRegistration'),
            meta: {
                useUsualStyles: true,
            },
        },
        {
            path: "cart-history-card",
            name: "CartHistoryCard",
            component: () => import("@/views/stock-management/cart/CartHistoryCard"),
            meta: {useUsualStyles: true,},
        },
        {
            path: "cart-repair-registration",
            name: "CartRepairRegistration",
            component: () => import("@/views/stock-management/cart/CartRepairRegistration"),
            meta: {useUsualStyles: true,},
        },
        {
            path: "cart-repair-status",
            name: "CartRepairStatus",
            component: () => import("@/views/stock-management/cart/CartRepairStatus"),
            meta: {useUsualStyles: true,},
        },
        //endregion

    ],
};

export default stockManageRoute;
<template>
  <div>
    <ejs-dialog
      ref="pgCancelPopup"
      :header="`정산 취소`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="402"
      :isModal="true"
      :close="closePayCancelPopup"
    >
      <div class="window payCancel">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content" v-if="pgIncomInfo.incomDiv === 'CARD'" >
                            <li class="field">
                              <div class="title">거래종류</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">PG결제취소</li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">영업일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ this.pgIncomInfo.incomDate }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">취소일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ today }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">매입처</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ pgIncomInfo.purcName }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">거래금액</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ pgIncomInfo.depositAmt | numberWithCommas }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">승인번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ pgIncomInfo.approvalNo }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>

                          <ul class="data-content" v-if="pgIncomInfo.incomDiv === 'TRANS'">
                            <li class="field">
                              <div class="title">거래종류</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">PG결제취소</li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">입금구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{
                                      commonCodesGetComName(
                                        "INCOM_DIV",
                                        pgIncomInfo.incomDiv
                                      )
                                    }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">입금일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ pgIncomInfo.incomDate }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <!--<li class="field">
                              <div class="title">은행</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{
                                      commonCodesGetComName(
                                          "BANK_CODE",
                                          pgIncomInfo.bankCode
                                      )
                                    }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">계좌번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ pgIncomInfo.acnutNo }}
                                  </li>
                                </ul>
                              </div>
                            </li>-->
                            <li class="field">
                              <div class="title">취소일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ today }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">거래금액</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ pgIncomInfo.depositAmt | numberWithCommas }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
<!--                <section class="article-section section-02">
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              &lt;!&ndash; 필수 : required &ndash;&gt;
                              <div class="title required">취소사유</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-textarea
                                        ref="cancelReason"
                                        v-model="cancelReason"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>-->
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-03">
                  <div class="section-body">
                    <div class="body-text">
                      {{ payTitle }}금액
                      {{ pgIncomInfo.depositAmt | numberWithCommas }}원이 정산
                      취소처리 됩니다.<br />
                      계속하시겠습니까?
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <!-- 메시지 상태 영역 -->
          <div class="operationMessage">
            {{ message }}
          </div>
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  ref="payCancelButton"
                  button-div="DELETE"
                  :is-icon-custom="true"
                  @click.native="payCancel"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closePayCancelPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style>
body .window.payCancel .windowContent .article-section.section-01 .body-data .field {width: calc(100% + 1px);}
body .window.payCancel .windowContent .article-section.section-01 .body-data .field .content.bottom-check-box {width: 100%;}

body .operationMessage {width: calc(100% - 160px)}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  getTgPayCardByCardId,
} from "@/api/common";
import {
  commonCodesGetBizMstInfo,
  commonCodesGetCommonCode,
  commonCodesGetComName,
} from "@/utils/commonCodes";
import { getTodayNavigationDate } from "@/utils/date";
import { validateFormRefs } from "@/utils/formUtil";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import { getDeviceInfo } from "@/utils/device";
import InputTextarea from "@/components/common/text/InputTextarea";

export default {
  name: "PGCancelPopup",
  components: { ErpButton },
  mixins: [commonMixin, confirmDialogMixin],
  data: function () {
    return {
      resveNo: null,
      receiptInfo: {},
      printFlag: getDeviceInfo().isBillPrinterUse,
      telCancel: false,
      today: null,
      cancelReason: ".",
      pgIncomInfo: {},
      payCashProofCancelCode: "1",
      proofCancelCodeList: commonCodesGetCommonCode("PROOF_CANCEL_CODE"),
      message: "",
      cancelProcessing: false,
      payTitle: null,
      van: null,
      proofNo: null,
      cardProof: false,
      bsnInfo: null,
      storeCode: null,
      bizMstInfo: {},
    };
  },
  computed: {
    validateRefList() {
      return {
        cancelReason: {
          required: true,
        },
      };
    },
  },
  filters: {
    numberWithCommas(value) {
      if (!value) {
        return "0";
      }

      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  async created() {
    this.today = await getTodayNavigationDate("YYYY-MM-DD");

    this.bizMstInfo = commonCodesGetBizMstInfo();

    this.bizMstInfo.bizNo =
      this.bizMstInfo.bizNo.substr(0, 3) +
      "-" +
      this.bizMstInfo.bizNo.substr(3, 2) +
      "-" +
      this.bizMstInfo.bizNo.substring(5);
  },
  methods: {
    validateFormRefs,
    commonCodesGetComName,
    async showPGCancelPopup(popupData) {
      console.log("popupData.===>", popupData);
      try {
        const { resveNo } = popupData;
        this.resveNo = resveNo;
        await this.initialize(popupData);
      } catch (e) {
        console.error(e);
        if (e instanceof Error) {
          this.errorToast(e.message);
        } else {
          this.errorToast(e);
        }
        this.closePayCancelPopup();
      }
    },
    closePayCancelPopup() {
      this.$emit("popupClosed", { isReload: false });
    },
    async initialize(popupData) {
      // PG 결제 정보 조회
      let depositIncoms = await GolfErpAPI.fetchDepositIncomsByResveNo(popupData.resveNo);

      if(popupData.incomId) {
        depositIncoms = depositIncoms.filter(depositIncom => depositIncom.incomId == popupData.incomId);
      }

      let msg;
      if(depositIncoms.length < 1) {
        msg = "PG 결제 정산 정보가 존재하지 않습니다.";
      } else if(depositIncoms[0].delFlag) {
        msg = "이미 정산 취소 처리된 정보입니다.";
      } else {
        this.pgIncomInfo = depositIncoms[0];
      }

      if(msg) {
        this.errorToast(msg);
        this.closePayCancelPopup();
      }

      this.payTitle = "정산";
    },
    payCancel: async function () {
      this.$refs.payCancelButton.blur();
      // 정산 중일 진행 불가
      if (this.cancelProcessing) {
        return;
      }

      // 일마감 여부 확인.
      /*try {
        await GolfErpAPI.fetchCloseCheck({
          bsnDateFrom: this.popupData.incomDate,
          bsnDateTo: this.popupData.incomDate,
          // storeCode: this.popupData.storeCode,
        });
      } catch (e) {
        console.error(e);
        return;
      }*/


      if (!this.validate()) {
        this.endCancel();
        return;
      }

      if (this.telCancel) {
        if (!(await this.confirm("전화취소! 처리 하시겠습니까?"))) {
          this.endCancel();
          return;
        }
      } else {
        if (!(await this.confirm("정산을 취소하시겠습니까?"))) {
          this.endCancel();
          return;
        }
      }

      await GolfErpAPI.cancelDepositByResveNo({
        resveNo: this.resveNo,
        incomId: this.pgIncomInfo.incomId
      });

      this.infoToast(this.$t("vanModule.popupMessage.cancelSuccess"));
      this.$emit("popupClosed", { isReload: true });
    },
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
  },
};
</script>

<template>
  <div>
    <ejs-dialog
      ref="dialog"
      v-bind="dialogProps"
      @open="onDialogOpen"
      @beforeClose="onDialogBeforeClose"
    >
      <div
        class="typePos"
        :style="{
          minWidth: `${dialogProps.width}px`,
          minHeight: `${dialogProps.height - 66}px`,
        }"
      >
        <div class="typePos-wrapper-main">
          <div class="typePos-main-header">
            <div class="typePos-header-header">
              <div class="typePos-header">
                <div class="typePos-title">결제 정보</div>
              </div>
            </div>
            <div class="typePos-header-body">
              <ul class="typePos-list">
                <li>
                  <div class="typePos-title">매출구분</div>
                  <div class="typePos-content">
                    {{ salesDivisionName }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">지불자</div>
                  <div class="typePos-content">
                    {{ payerName }} / {{ payerLockerNumber }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">선불 / 후불</div>
                  <div class="typePos-content">
                    {{ payPreOrFutureDivisionName }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">매출금액</div>
                  <div class="typePos-content typePos-number">
                    {{ salesAmount | numberWithCommas }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">할인금액</div>
                  <div class="typePos-content typePos-number">
                    {{ discountAmount | numberWithCommas }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">정산금액</div>
                  <div class="typePos-content typePos-number">
                    {{ totalAmount | numberWithCommas }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- 카드 활성 : typePos-card / 현금 활성 : typePos-cash / 미수(C/L) 활성 : typePos-attempt / 선수금 활성 : typePos-advance -->
          <div
            class="typePos-main-footer"
            :class="{
              'typePos-card': paymentMethod === 'CARD',
              'typePos-cash': paymentMethod === 'CASH',
              'typePos-attempt': paymentMethod === 'CREDIT',
              'typePos-advance': paymentMethod === 'DEPOSIT',
              'typePos-gift': paymentMethod === 'GIFT',
              'typePos-trans': paymentMethod === 'TRANS',
              'typePos-easy': paymentMethod === 'EASY_PAY',
            }"
          >
            <div
              class="typePos-footer-box"
              :class="{
                'typePos-card': paymentMethod === 'CARD',
                'typePos-cash': paymentMethod === 'CASH',
                'typePos-attempt': paymentMethod === 'CREDIT',
                'typePos-advance': paymentMethod === 'DEPOSIT',
                'typePos-gift': paymentMethod === 'GIFT',
                'typePos-trans': paymentMethod === 'TRANS',
                'typePos-easy': paymentMethod === 'EASY_PAY',
              }"
            >
              <div class="typePos-footer-header">
                <ul class="typePos-body">
                  <li class="typePos-card" @click="paymentMethod = 'CARD'">
                    <div class="typePos-item">카드</div>
                  </li>
                  <li class="typePos-cash" @click="paymentMethod = 'CASH'">
                    <div class="typePos-item">현금</div>
                  </li>
                  <li
                    v-if="payDivs.find(payDiv => payDiv.comCode === 'EASY_PAY')"
                    class="typePos-easy"
                    @click="paymentMethod = 'EASY_PAY'"
                  >
                    <div class="typePos-item">간편결제</div>
                  </li>
                  <li
                    v-if="payDivs.find(payDiv => payDiv.comCode === 'CREDIT')"
                    class="typePos-attempt"
                    @click="paymentMethod = 'CREDIT'"
                  >
                    <div class="typePos-item">미수(C/L)</div>
                  </li>
                  <li
                    v-if="payDivs.find(payDiv => payDiv.comCode === 'DEPOSIT')"
                    class="typePos-advance"
                    @click="paymentMethod = 'DEPOSIT'"
                  >
                    <div class="typePos-item">선수금</div>
                  </li>
                  <li
                    v-if="payDivs.find(payDiv => payDiv.comCode === 'GIFT')"
                    class="typePos-gift"
                    @click="paymentMethod = 'GIFT'"
                  >
                    <div class="typePos-item">상품권</div>
                  </li>
                  <li
                    v-if="payDivs.find(payDiv => payDiv.comCode === 'TRANS')"
                    class="typePos-trans"
                    @click="paymentMethod = 'TRANS'"
                  >
                    <div class="typePos-item">계좌이체</div>
                  </li>
                </ul>
              </div>
              <div class="typePos-footer-caption">
                <div class="typePos-header">
                  <div class="typePos-title">
                    {{ paymentMethodName }}
                  </div>
                </div>
                <div v-if="paymentMethod !== 'CREDIT'" class="typePos-footer">
                  <div class="typePos-check">
                    <ul class="ui-check">
                      <li>
                        <label>
                          <!-- 비활성(Attribute) : disabled -->
                          <input type="checkbox" v-model="printFlag" />
                          <div class="i-item">고객용 영수증 발행</div>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="typePos-footer-body">
                <div class="typePos-body-header">
                  <div class="typePos-body">
                    <ul class="typePos-list">
                      <li>
                        <div class="typePos-title">미정산액</div>
                        <div class="typePos-content">
                          <input-number-unusual
                            :align="'right'"
                            :value="notPaidAmount"
                            disabled
                          />
                        </div>
                      </li>
                      <li>
                        <div class="typePos-title">결제금액</div>
                        <div class="typePos-content">
                          <div class="typePos-item" id="input-pay-amount">
                            <input-number-unusual
                              ref="input-pay-amount"
                              :align="'right'"
                              :allowMinus="
                                (paymentMethod === 'CASH' &&
                                  !cashPaymentParams.proofKind) ||
                                paymentMethod === 'CREDIT' ||
                                paymentMethod === 'DEPOSIT'
                                  ? true
                                  : false
                              "
                              v-model="payAmount"
                              @focus="onInputFocus('input-pay-amount')"
                              @keydownEnter="onInputPayAmountKeydown"
                            />
                          </div>
                          <div class="typePos-item typePos-lookup">
                            <erp-button
                              button-div="GET"
                              class="ui-button i-typePrimary"
                              :use-syncfusion-component-style="false"
                              @click="setPayAmount"
                            >
                              <div class="i-item">
                                전체금액
                              </div>
                            </erp-button>
                          </div>
                        </div>
                      </li>
                      <template v-if="paymentMethod === 'CARD'">
                        <li>
                          <div class="typePos-title">할부개월</div>
                          <div class="typePos-content">
                            <div class="typePos-item">
                              <input-number-unusual
                                ref="input-divide-term"
                                :align="'right'"
                                v-model="cardPaymentParams.divideTerm"
                                @focus="onInputFocus('input-divide-term')"
                                @keydownEnter="onSubmitClick"
                              />
                            </div>
                            <div class="typePos-item typePos-text">개월</div>
                          </div>
                        </li>
                        <li class="typePos-approval">
                          <div class="typePos-title">
                            기승인<br />
                            (단말기승인)
                          </div>
                          <div class="typePos-content">
                            <ul class="ui-check">
                              <li>
                                <label>
                                  <!-- 비활성(Attribute) : disabled -->
                                  <input
                                    type="checkbox"
                                    v-model="cardPaymentParams.approvalFlag"
                                    @change="approvalFlagChanged"
                                  />
                                  <div class="i-item">사용</div>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div class="typePos-title">카드번호</div>
                          <div class="typePos-content">
                            <div class="typePos-item">
                              <div class="ui-input">
                                <!-- 비활성(Attribute) : disabled -->
                                <input
                                  ref="input-card-number"
                                  type="text"
                                  placeholder="입력하세요."
                                  v-model="cardPaymentParams.cardNo"
                                  :disabled="!cardPaymentParams.approvalFlag"
                                  @focus="onInputFocus('input-card-number')"
                                />
                              </div>
                            </div>
                            <div class="typePos-item typePos-select">
                              <f-select
                                class="ui-select"
                                :disabled="!cardPaymentParams.approvalFlag"
                                :items="cardList"
                                :value="cardPaymentParamsPurchaseId"
                                @select="onCardPaymentChange"
                              />
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="typePos-title">유효기간</div>
                          <div class="typePos-content">
                            <div class="ui-input">
                              <!-- 비활성(Attribute) : disabled -->
                              <input
                                ref="input-valid-term"
                                type="text"
                                placeholder="MM/YY"
                                v-model="cardPaymentParams.validTerm"
                                :disabled="!cardPaymentParams.approvalFlag"
                                @focus="onInputFocus('input-valid-term')"
                              />
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="typePos-title">승인번호</div>
                          <div class="typePos-content">
                            <div class="ui-input">
                              <!-- 비활성(Attribute) : disabled -->
                              <input
                                ref="input-approval-number"
                                type="text"
                                placeholder="입력하세요."
                                v-model="cardPaymentParams.approvalNo"
                                :disabled="!cardPaymentParams.approvalFlag"
                                @focus="onInputFocus('input-approval-number')"
                              />
                            </div>
                          </div>
                        </li>
                      </template>
                      <template v-else-if="paymentMethod === 'CASH'">
                        <li>
                          <div class="typePos-title">
                            현금영수증<br />
                            발행
                          </div>
                          <div class="typePos-content">
                            <ul class="ui-check">
                              <li>
                                <label>
                                  <!-- 비활성(Attribute) : disabled -->
                                  <input
                                    type="radio"
                                    :value="undefined"
                                    v-model="cashPaymentParams.proofKind"
                                  />
                                  <div class="i-item">미발급</div>
                                </label>
                              </li>
                              <li>
                                <label>
                                  <!-- 비활성(Attribute) : disabled -->
                                  <input
                                    type="radio"
                                    :value="'VOL'"
                                    v-model="cashPaymentParams.proofKind"
                                  />
                                  <div class="i-item">자진발급</div>
                                </label>
                              </li>
                              <li>
                                <label>
                                  <!-- 비활성(Attribute) : disabled -->
                                  <input
                                    type="radio"
                                    :value="'FIT'"
                                    v-model="cashPaymentParams.proofKind"
                                  />
                                  <div class="i-item">소득공제용</div>
                                </label>
                              </li>
                              <li>
                                <label>
                                  <!-- 비활성(Attribute) : disabled -->
                                  <input
                                    type="radio"
                                    :value="'CPR'"
                                    v-model="cashPaymentParams.proofKind"
                                  />
                                  <div class="i-item">사업자 지출증빙</div>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="typePos-proof">
                          <div class="typePos-title">
                            현금영수증<br />
                            증빙번호
                          </div>
                          <div class="typePos-content">
                            <div class="ui-input">
                              <!-- 비활성(Attribute) : disabled -->
                              <input
                                ref="input-proof-number"
                                type="text"
                                placeholder="입력하세요."
                                v-model="cashPaymentParams.proofNo"
                                :disabled="
                                  !cashPaymentParams.proofKind ||
                                  cashPaymentParams.proofKind === 'VOL' ||
                                  cashPaymentParams.cardProof
                                "
                                @focus="onInputFocus('input-proof-number')"
                              />
                            </div>
                          </div>
                        </li>
                        <li v-if="businessInfo.vanKind === 'NICE'">
                          <div class="typePos-title">카드증빙</div>
                          <div class="typePos-content">
                            <ul class="ui-check">
                              <li>
                                <label>
                                  <!-- 비활성(Attribute) : disabled -->
                                  <input
                                    type="checkbox"
                                    :disabled="
                                      !cashPaymentParams.proofKind ||
                                      cashPaymentParams.proofKind === 'VOL'
                                    "
                                    v-model="cashPaymentParams.cardProof"
                                    @change="onCardProofChange"
                                  />
                                  <div class="i-item">사용</div>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div class="typePos-title">받은금액</div>
                          <div class="typePos-content">
                            <input-number-unusual
                              ref="input-cash-received"
                              :align="'right'"
                              v-model="cashReceived"
                              @focus="onInputFocus('input-cash-received')"
                            />
                          </div>
                        </li>
                        <li>
                          <div class="typePos-title">거스름돈</div>
                          <div class="typePos-content">
                            <input-number-unusual
                              :align="'right'"
                              :value="changeAmount"
                              :disabled="true"
                            />
                          </div>
                        </li>
                      </template>

                      <template v-if="paymentMethod === 'EASY_PAY'">
                        <li>
                          <div class="typePos-title">할부개월</div>
                          <div class="typePos-content">
                            <div class="typePos-item">
                              <input-number-unusual
                                  ref="input-divide-term"
                                  :align="'right'"
                                  v-model="cardPaymentParams.divideTerm"
                                  @focus="onInputFocus('input-divide-term')"
                                  @keydownEnter="onSubmitClick"
                              />
                            </div>
                            <div class="typePos-item typePos-text">개월</div>
                          </div>
                        </li>
                      </template>

                      <template v-else-if="paymentMethod === 'CREDIT'">
                        <li>
                          <div class="typePos-title">비고</div>
                          <div class="typePos-content">
                            <div class="ui-input">
                              <input
                                ref="input-remarks"
                                type="text"
                                placeholder="비고를 입력하세요."
                                v-model="creditPaymentParams.remarks"
                                @focus="onInputFocus('input-remarks')"
                                @keydown.enter.prevent="onSubmitClick"
                              />
                            </div>
                          </div>
                        </li>
                      </template>
                      <template v-else-if="paymentMethod === 'DEPOSIT'">
                        <li>
                          <div class="typePos-title">
                            현금영수증<br />
                            발행
                          </div>
                          <div class="typePos-content">
                            <ul class="ui-check">
                              <li>
                                <label>
                                  <!-- 비활성(Attribute) : disabled -->
                                  <input
                                    type="radio"
                                    :value="undefined"
                                    v-model="depositPaymentParams.proofKind"
                                  />
                                  <div class="i-item">미발급</div>
                                </label>
                              </li>
                              <li>
                                <label>
                                  <!-- 비활성(Attribute) : disabled -->
                                  <input
                                    type="radio"
                                    :value="'VOL'"
                                    v-model="depositPaymentParams.proofKind"
                                  />
                                  <div class="i-item">자진발급</div>
                                </label>
                              </li>
                              <li>
                                <label>
                                  <!-- 비활성(Attribute) : disabled -->
                                  <input
                                    type="radio"
                                    :value="'FIT'"
                                    v-model="depositPaymentParams.proofKind"
                                  />
                                  <div class="i-item">소득공제용</div>
                                </label>
                              </li>
                              <li>
                                <label>
                                  <!-- 비활성(Attribute) : disabled -->
                                  <input
                                    type="radio"
                                    :value="'CPR'"
                                    v-model="depositPaymentParams.proofKind"
                                  />
                                  <div class="i-item">사업자 지출증빙</div>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="typePos-proof">
                          <div class="typePos-title">
                            현금영수증<br />
                            증빙번호
                          </div>
                          <div class="typePos-content">
                            <div class="ui-input">
                              <!-- 비활성(Attribute) : disabled -->
                              <input
                                ref="input-proof-number"
                                type="text"
                                placeholder="입력하세요."
                                v-model="depositPaymentParams.proofNo"
                                :disabled="
                                  !depositPaymentParams.proofKind ||
                                  depositPaymentParams.proofKind === 'VOL' ||
                                  depositPaymentParams.cardProof
                                "
                                @focus="onInputFocus('input-proof-number')"
                              />
                            </div>
                          </div>
                        </li>
                        <li v-if="businessInfo.vanKind === 'NICE'">
                          <div class="typePos-title">카드증빙</div>
                          <div class="typePos-content">
                            <ul class="ui-check">
                              <li>
                                <label>
                                  <!-- 비활성(Attribute) : disabled -->
                                  <input
                                    type="checkbox"
                                    :disabled="
                                      !depositPaymentParams.proofKind ||
                                      depositPaymentParams.proofKind === 'VOL'
                                    "
                                    v-model="depositPaymentParams.cardProof"
                                    @change="onDepositCardProofChange"
                                  />
                                  <div class="i-item">사용</div>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="typePos-search">
                          <div class="typePos-title">검색</div>
                          <div class="typePos-content">
                            <div class="typePos-item typePos-input">
                              <div class="ui-input">
                                <!-- 비활성(Attribute) : disabled -->
                                <input
                                  ref="input-search-query"
                                  type="text"
                                  placeholder="입금자, 카드번호, 비고"
                                  v-model="
                                    depositBalanceSearchCondition.condition
                                  "
                                  @keypress.enter="onSearchDepositBalancesClick"
                                  @focus="onInputFocus('input-search-query')"
                                />
                              </div>
                            </div>
                            <div class="typePos-hr"></div>
                            <div class="typePos-item typePos-check">
                              <ul class="ui-check">
                                <li>
                                  <label>
                                    <!-- 비활성(Attribute) : disabled -->
                                    <input
                                      type="checkbox"
                                      v-model="
                                        depositBalanceSearchCondition.showEmptyBalanceAmount
                                      "
                                    />
                                    <div class="i-item">
                                      잔액없는 자료 모두보기
                                    </div>
                                  </label>
                                </li>
                              </ul>
                            </div>
                            <div class="typePos-item typePos-lookup">
                              <!-- 비활성(Attribute) : disabled -->
                              <erp-button
                                button-div="GET"
                                class="ui-button i-typePrimary"
                                :ignore="paymentMethod !== 'DEPOSIT'"
                                :use-syncfusion-component-style="false"
                                :is-shortcut-button="true"
                                :visible-shortcut-string="false"
                                @click.native="onSearchDepositBalancesClick"
                              >
                                <div class="i-item">
                                  조회 ({{
                                    searchShortcutButtonShortcutString
                                  }})
                                </div>
                              </erp-button>
                            </div>
                          </div>
                        </li>
                      </template>
                      <template v-else-if="paymentMethod === 'GIFT'">
                        <li>
                          <div class="typePos-title">
                            상품권구분
                          </div>
                          <div class="typePos-content">
                            <ul class="ui-check">
                              <li>
                                <label>
                                  <input
                                    type="radio"
                                    value="GIFT"
                                    v-model="giftPaymentParams.giftDiv"
                                  />
                                  <div class="i-item">{{commonCodesGetComName("PAY_DIV", "GIFT")}}</div>
                                </label>
                              </li>
                              <li>
                                <label>
                                  <input
                                    type="radio"
                                    value="GROUP_GIFT"
                                    v-model="giftPaymentParams.giftDiv"
                                  />
                                  <div class="i-item">{{commonCodesGetComName("PAY_DIV", "GROUP_GIFT")}}</div>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div class="typePos-title">
                            상품권번호
                          </div>
                          <div class="typePos-content">
                            <div class="ui-input" style="width: 360px;">
                              <textarea
                                ref="input-gift-number"
                                placeholder="입력하세요."
                                v-model="giftPaymentParams.giftNo"
                                @focus="onInputFocus('input-gift-number')"
                              />
                            </div>
                          </div>
                        </li>
                      </template>
                      <template v-else-if="paymentMethod === 'TRANS'">
                        <li>
                          <div class="typePos-title">
                            현금영수증<br />
                            발행
                          </div>
                          <div class="typePos-content">
                            <ul class="ui-check">
                              <li>
                                <label>
                                  <!-- 비활성(Attribute) : disabled -->
                                  <input
                                    type="radio"
                                    :value="undefined"
                                    v-model="transPaymentParams.proofKind"
                                  />
                                  <div class="i-item">미발급</div>
                                </label>
                              </li>
                              <li>
                                <label>
                                  <!-- 비활성(Attribute) : disabled -->
                                  <input
                                    type="radio"
                                    :value="'VOL'"
                                    v-model="transPaymentParams.proofKind"
                                  />
                                  <div class="i-item">자진발급</div>
                                </label>
                              </li>
                              <li>
                                <label>
                                  <!-- 비활성(Attribute) : disabled -->
                                  <input
                                    type="radio"
                                    :value="'FIT'"
                                    v-model="transPaymentParams.proofKind"
                                  />
                                  <div class="i-item">소득공제용</div>
                                </label>
                              </li>
                              <li>
                                <label>
                                  <!-- 비활성(Attribute) : disabled -->
                                  <input
                                    type="radio"
                                    :value="'CPR'"
                                    v-model="transPaymentParams.proofKind"
                                  />
                                  <div class="i-item">사업자 지출증빙</div>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="typePos-proof">
                          <div class="typePos-title">
                            현금영수증<br />
                            증빙번호
                          </div>
                          <div class="typePos-content">
                            <div class="ui-input">
                              <!-- 비활성(Attribute) : disabled -->
                              <input
                                ref="input-trans-proof-number"
                                type="text"
                                placeholder="입력하세요."
                                v-model="transPaymentParams.proofNo"
                                :disabled="
                                  !transPaymentParams.proofKind ||
                                  transPaymentParams.proofKind === 'VOL' ||
                                  transPaymentParams.cardProof
                                "
                                @focus="
                                  onInputFocus('input-trans-proof-number')
                                "
                              />
                            </div>
                          </div>
                        </li>
                        <li v-if="businessInfo.vanKind === 'NICE'">
                          <div class="typePos-title">카드증빙</div>
                          <div class="typePos-content">
                            <ul class="ui-check">
                              <li>
                                <label>
                                  <!-- 비활성(Attribute) : disabled -->
                                  <input
                                    type="checkbox"
                                    :disabled="
                                      !transPaymentParams.proofKind ||
                                      transPaymentParams.proofKind === 'VOL'
                                    "
                                    v-model="transPaymentParams.cardProof"
                                    @change="onTransCardProofChange"
                                  />
                                  <div class="i-item">사용</div>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div class="typePos-title">비고</div>
                          <div class="typePos-content">
                            <div class="ui-input">
                              <input
                                ref="input-trans-remarks"
                                type="text"
                                placeholder="비고를 입력하세요."
                                v-model="transPaymentParams.remarks"
                                @focus="onInputFocus('input-trans-remarks')"
                                @keydown.enter.prevent="onSubmitClick"
                              />
                            </div>
                          </div>
                        </li>
                      </template>
                    </ul>
                  </div>
                  <div v-if="paymentMethod === 'DEPOSIT'" class="typePos-aside">
                    <ejs-grid-wrapper
                      ref="depositBalanceStatusGrid"
                      v-bind="depositBalanceStatusGridProps"
                      :noColumnWidth="40"
                      @rowSelected="depositGridRowSelected"
                      @actionComplete="depositGridOnActionComplete"
                      @keyPressed="depositGridOnKeyPressed"
                    />
                  </div>
                </div>
                <div class="typePos-body-footer">
                  <ul class="typePos-list">
                    <li class="typePos-1">
                      <erp-button
                        button-div="GET"
                        :use-syncfusion-component-style="false"
                        @click="onNumpadButtonClick($event, { value: '1' })"
                      >
                        1
                      </erp-button>
                    </li>
                    <li class="typePos-2">
                      <erp-button
                        button-div="GET"
                        :use-syncfusion-component-style="false"
                        @click="onNumpadButtonClick($event, { value: '2' })"
                      >
                        2
                      </erp-button>
                    </li>
                    <li class="typePos-3">
                      <erp-button
                        button-div="GET"
                        :use-syncfusion-component-style="false"
                        @click="onNumpadButtonClick($event, { value: '3' })"
                      >
                        3
                      </erp-button>
                    </li>
                    <li class="typePos-4">
                      <erp-button
                        button-div="GET"
                        :use-syncfusion-component-style="false"
                        @click="onNumpadButtonClick($event, { value: '4' })"
                      >
                        4
                      </erp-button>
                    </li>
                    <li class="typePos-5">
                      <erp-button
                        button-div="GET"
                        :use-syncfusion-component-style="false"
                        @click="onNumpadButtonClick($event, { value: '5' })"
                      >
                        5
                      </erp-button>
                    </li>
                    <li class="typePos-6">
                      <erp-button
                        button-div="GET"
                        :use-syncfusion-component-style="false"
                        @click="onNumpadButtonClick($event, { value: '6' })"
                      >
                        6
                      </erp-button>
                    </li>
                    <li class="typePos-7">
                      <erp-button
                        button-div="GET"
                        :use-syncfusion-component-style="false"
                        @click="onNumpadButtonClick($event, { value: '7' })"
                      >
                        7
                      </erp-button>
                    </li>
                    <li class="typePos-8">
                      <erp-button
                        button-div="GET"
                        :use-syncfusion-component-style="false"
                        @click="onNumpadButtonClick($event, { value: '8' })"
                      >
                        8
                      </erp-button>
                    </li>
                    <li class="typePos-9">
                      <erp-button
                        button-div="GET"
                        :use-syncfusion-component-style="false"
                        @click="onNumpadButtonClick($event, { value: '9' })"
                      >
                        9
                      </erp-button>
                    </li>
                    <li class="typePos-0">
                      <erp-button
                        button-div="GET"
                        :use-syncfusion-component-style="false"
                        @click="onNumpadButtonClick($event, { value: '0' })"
                      >
                        0
                      </erp-button>
                    </li>
                    <li class="typePos-00">
                      <erp-button
                        button-div="GET"
                        :use-syncfusion-component-style="false"
                        @click="onNumpadButtonClick($event, { value: '00' })"
                      >
                        00
                      </erp-button>
                    </li>
                    <li class="typePos-clear">
                      <erp-button
                        button-div="GET"
                        :use-syncfusion-component-style="false"
                        @click="onNumpadButtonClick($event, { value: 'Clear' })"
                      >
                        CL
                      </erp-button>
                    </li>
                    <li class="typePos-back">
                      <erp-button
                        button-div="GET"
                        :use-syncfusion-component-style="false"
                        @click="
                          onNumpadButtonClick($event, { value: 'Backspace' })
                        "
                      >
                        뒤로
                      </erp-button>
                    </li>
                    <li class="typePos-enter">
                      <erp-button
                        button-div="SAVE"
                        :use-syncfusion-component-style="false"
                        @click="onNumpadButtonClick($event, { value: 'Enter' })"
                      >
                        Enter
                      </erp-button>
                    </li>
                    <li class="typePos-input">
                      <erp-button
                        button-div="GET"
                        :use-syncfusion-component-style="false"
                        @click="onInputClick"
                      >
                        입력
                      </erp-button>
                    </li>
                    <li class="typePos-calculate">
                      <erp-button
                        button-div="SAVE"
                        :use-syncfusion-component-style="false"
                        @click="onSubmitClick"
                      >
                        정산
                      </erp-button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click="onHideClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-main {display: flex; flex-direction: row; flex: 1; overflow: hidden;}
.typePos .typePos-wrapper-main::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}

.typePos .typePos-wrapper-main .typePos-main-header {display: flex; flex-direction: column; float: left; width: 250px; border-right: 1px solid #bebebe;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header {padding: 10px 20px 2px 20px; border-bottom: 1px solid #e0e0e0;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header .typePos-header {position: relative; float: left;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header .typePos-header::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header .typePos-header .typePos-title {float: left; padding: 6px 0 7px 0; color: #222; font-size: 18px; font-family: 'NanumSquare-Regular'; line-height: 27px;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header .typePos-header .typePos-caption {float: left; padding: 12px 0 9px 10px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header .typePos-header .typePos-caption strong {color: #18b3c9; font-weight: normal;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header .typePos-footer {float: right;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-header .typePos-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-body {flex: 1; overflow: auto;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-body .typePos-list {list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-body .typePos-list > li {border-bottom: 1px solid #e0e0e0;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-body .typePos-list > li::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-body .typePos-list > li .typePos-title {box-sizing: border-box; float: left; width: 120px; padding: 10px 15px 9px 20px; color: #222; font-size: 13px; font-family: 'NanumSquare-Bold'; line-height: 19px; background-color: #f5f5f5;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-body .typePos-list > li .typePos-content {box-sizing: border-box; float: left; width: calc(100% - 120px); padding: 10px 20px 9px 15px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-body .typePos-list > li .typePos-content.typePos-number {text-align: right;}

.typePos .typePos-wrapper-main .typePos-main-footer {flex: 1; float: left; overflow: hidden;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box {display: none; flex-direction: column; height: 100%;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-header {border-bottom: 1px solid #e0e0e0; line-height: 0; text-align: center;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-header .typePos-body {display: inline-block; list-style: none; padding: 0; margin: 0; line-height: 1.5;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-header .typePos-body::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-header .typePos-body > li {float: left; padding: 0 15px; cursor: pointer;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-header .typePos-body > li .typePos-item {position: relative; padding: 14px 0; color: #aaa; font-size: 16px; font-family: 'NanumSquare-Bold'; line-height: 24px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-header .typePos-body > li:hover .typePos-item {color: #18b3c9;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-header .typePos-body > li .typePos-item::before {display: none; position: absolute; bottom: -1px; left: 0; width: 100%; height: 3px; background-color: #18b3c9; content: '';}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-caption {padding: 7px 20px 2px 20px; border-bottom: 1px solid #e0e0e0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-caption::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-caption .typePos-header {position: relative; float: left;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-caption .typePos-header::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-caption .typePos-header .typePos-title {float: left; padding: 8px 0; color: #222; font-size: 16px; font-family: 'NanumSquare-Regular'; line-height: 24px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-caption .typePos-header .typePos-caption {float: left; padding: 12px 0 9px 10px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-caption .typePos-header .typePos-caption strong {color: #18b3c9; font-weight: normal;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-caption .typePos-footer {float: right;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-caption .typePos-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body {display: flex; flex-direction: row; flex: 1; overflow: hidden;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header {display: flex; flex-direction: column; display: flex; flex: 1; overflow: auto; float: left;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list {list-style: none; padding: 15px 13px; margin: 0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li {padding: 5px 0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li .typePos-title,
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li .typePos-content {padding: 0 7px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li .typePos-title {position: relative; box-sizing: border-box; float: left; width: 92px; padding-top: 10px; padding-bottom: 11px; color: #222; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li .typePos-title::before {display: none; position: absolute; top: 9px; left: 0; width: 4px; height: 4px; border-radius: 50%; background-color: #df2929; content: '';}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li.typePos-required .typePos-title::before {display: block;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li .typePos-content {box-sizing: border-box; float: left; width: calc(100% - 92px);}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li .typePos-content::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li .typePos-content .typePos-item {box-sizing: border-box; float: left; padding-left: 10px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li .typePos-content .typePos-item:first-child {padding-left: 0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li .typePos-content .typePos-hr {display: block; clear: both; height: 10px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li .typePos-content .typePos-item.typePos-text {padding-top: 10px; padding-bottom: 11px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li .typePos-content .typePos-item.typePos-select .ui-select {width: 160px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li .typePos-content .ui-input {width: 180px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li .typePos-content .ui-input input {width: 180px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li .typePos-content >>> .ejs-number input {width: 180px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li .typePos-content textarea {box-sizing: border-box; box-shadow: none; width: 100%; height: 82px; padding: 12px 15px 11px 15px; margin: 0; border: 1px solid #e0e0e0; border-radius: 4px; background-color: #fff; color: #222; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px; resize: none;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li .typePos-content textarea::-ms-clear {display: none;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li .typePos-content textarea::placeholder {color: #aaa;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li .typePos-content textarea::-webkit-input-placeholder {color: #aaa;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li .typePos-content textarea:-ms-input-placeholder {color: #aaa;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li .typePos-content textarea:focus {border-color: #18b3c9;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li .typePos-content textarea:disabled,
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li .typePos-content textarea:disabled:focus {background-color: #eee; color: #aaa; cursor: default;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li.typePos-proof .typePos-title {padding-top: 1px; padding-bottom: 1px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li.typePos-approval .typePos-content {padding: 9px 7px 10px 7px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li.typePos-search .typePos-content .typePos-item.typePos-input {width: 100%;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li.typePos-search .typePos-content .typePos-item.typePos-input .ui-input {width: 100%;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li.typePos-search .typePos-content .typePos-item.typePos-input .ui-input input {width: 100%;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li.typePos-search .typePos-content .typePos-item.typePos-check {padding-left: 0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li.typePos-search .typePos-content .typePos-item.typePos-lookup {padding-left: 20px; float: right;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-body .typePos-list > li.typePos-search .typePos-content .typePos-item.typePos-lookup .ui-button .i-item::before {background-position-x: calc(-20px * 7);}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-header .typePos-aside {flex: 1; border-top: 1px solid #e0e0e0; overflow: hidden;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer {position: relative; float: left; width: 325px; border-left: 1px solid #e0e0e0; background-color: #f5f5f5;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list {list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li {position: absolute;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li button {display: block; box-sizing: border-box; box-shadow: 0 2px 2px 0 rgba(0, 0 , 0, 0.05); width: 72px; height: 45px; padding: 5px; margin: 0; border: none; border-radius: 4px; background-color: #fff; color: #666; font-size: 16px; font-family: 'NanumSquare-Bold'; line-height: 24px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li button:hover {background-color: #f9f9f9;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li.typePos-1 {top: 116px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li.typePos-2 {top: 116px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li.typePos-3 {top: 116px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li.typePos-4 {top: 68px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li.typePos-5 {top: 68px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li.typePos-6 {top: 68px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li.typePos-7 {top: 20px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li.typePos-8 {top: 20px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li.typePos-9 {top: 20px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li.typePos-0 {top: 164px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li.typePos-00 {top: 164px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li.typePos-clear {top: 164px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li.typePos-back {top: 212px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li.typePos-enter {top: 212px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li.typePos-input {top: 20px; left: 245px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li.typePos-calculate {top: 116px; left: 245px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li.typePos-clear button {font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li.typePos-back button {overflow: hidden; text-indent: -1000px; background-image: url('../../../../../assets/images/ui/icon-type09.png'); background-repeat: no-repeat; background-position: center center;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li.typePos-enter button {width: 147px; font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li.typePos-input button {width: 60px; height: 93px; background-color: #4f5963; color: #fff; font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li.typePos-input button:hover {background-color: #333c44;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li.typePos-calculate button {width: 60px; height: 141px; background-color: #18b3c9; color: #fff; font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-footer-box .typePos-footer-body .typePos-body-footer .typePos-list > li.typePos-calculate button:hover {background-color: #0f9eb8;}
.typePos .typePos-wrapper-main .typePos-main-footer.typePos-card .typePos-footer-box.typePos-card {display: flex;}
.typePos .typePos-wrapper-main .typePos-main-footer.typePos-card .typePos-footer-box.typePos-card .typePos-footer-header .typePos-body > li.typePos-card .typePos-item {color: #18b3c9; cursor: default;}
.typePos .typePos-wrapper-main .typePos-main-footer.typePos-card .typePos-footer-box.typePos-card .typePos-footer-header .typePos-body > li.typePos-card .typePos-item::before {display: block;}
.typePos .typePos-wrapper-main .typePos-main-footer.typePos-easy .typePos-footer-box.typePos-easy {display: flex;}
.typePos .typePos-wrapper-main .typePos-main-footer.typePos-easy .typePos-footer-box.typePos-easy .typePos-footer-header .typePos-body > li.typePos-easy .typePos-item {color: #18b3c9; cursor: default;}
.typePos .typePos-wrapper-main .typePos-main-footer.typePos-easy .typePos-footer-box.typePos-easy .typePos-footer-header .typePos-body > li.typePos-easy .typePos-item::before {display: block;}
.typePos .typePos-wrapper-main .typePos-main-footer.typePos-cash .typePos-footer-box.typePos-cash {display: flex;}
.typePos .typePos-wrapper-main .typePos-main-footer.typePos-cash .typePos-footer-box.typePos-cash .typePos-footer-header .typePos-body > li.typePos-cash .typePos-item {color: #18b3c9; cursor: default;}
.typePos .typePos-wrapper-main .typePos-main-footer.typePos-cash .typePos-footer-box.typePos-cash .typePos-footer-header .typePos-body > li.typePos-cash .typePos-item::before {display: block;}
.typePos .typePos-wrapper-main .typePos-main-footer.typePos-attempt .typePos-footer-box.typePos-attempt {display: flex;}
.typePos .typePos-wrapper-main .typePos-main-footer.typePos-attempt .typePos-footer-box.typePos-attempt .typePos-footer-header .typePos-body > li.typePos-attempt .typePos-item {color: #18b3c9; cursor: default;}
.typePos .typePos-wrapper-main .typePos-main-footer.typePos-attempt .typePos-footer-box.typePos-attempt .typePos-footer-header .typePos-body > li.typePos-attempt .typePos-item::before {display: block;}
.typePos .typePos-wrapper-main .typePos-main-footer.typePos-advance .typePos-footer-box.typePos-advance {display: flex;}
.typePos .typePos-wrapper-main .typePos-main-footer.typePos-advance .typePos-footer-box.typePos-advance .typePos-footer-header .typePos-body > li.typePos-advance .typePos-item {color: #18b3c9; cursor: default;}
.typePos .typePos-wrapper-main .typePos-main-footer.typePos-advance .typePos-footer-box.typePos-advance .typePos-footer-header .typePos-body > li.typePos-advance .typePos-item::before {display: block;}
.typePos .typePos-wrapper-main .typePos-main-footer.typePos-gift .typePos-footer-box.typePos-gift {display: flex;}
.typePos .typePos-wrapper-main .typePos-main-footer.typePos-gift .typePos-footer-box.typePos-gift .typePos-footer-header .typePos-body > li.typePos-gift .typePos-item {color: #18b3c9; cursor: default;}
.typePos .typePos-wrapper-main .typePos-main-footer.typePos-gift .typePos-footer-box.typePos-gift .typePos-footer-header .typePos-body > li.typePos-gift .typePos-item::before {display: block;}
.typePos .typePos-wrapper-main .typePos-main-footer.typePos-trans .typePos-footer-box.typePos-trans {display: flex;}
.typePos .typePos-wrapper-main .typePos-main-footer.typePos-trans .typePos-footer-box.typePos-trans .typePos-footer-header .typePos-body > li.typePos-trans .typePos-item {color: #18b3c9; cursor: default;}
.typePos .typePos-wrapper-main .typePos-main-footer.typePos-trans .typePos-footer-box.typePos-trans .typePos-footer-header .typePos-body > li.typePos-trans .typePos-item::before {display: block;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
.typePos .typePos-wrapper-footer .typePos-button > li.typePos-save .ui-button .i-item::before {background-position-x: calc(-20px * 8);}
</style>

<script>
import moment from "moment";
import InputNumberUnusual from "@/components/common/InputNumberUnusual";
import {
  commonCodesGetBizMstInfo,
  commonCodesGetComName,
  commonCodesGetCommonCode,
} from "@/utils/commonCodes";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { numberWithCommas } from "@/utils/number";
import { getPayInfo, getReceiptInfo } from "@/api/common";
import { DATE_FORMAT_YYYY_MM_DD } from "@/utils/date";
import { getDeviceInfo } from "@/utils/device";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import { getStoreBsnMstInfo } from "@/api/bizCodeManagement";
import GolfERPService from "@/service/GolfERPService";
import { TIME_FORMAT_hh_mm_ss } from "@/utils/time";
import { openERPPay } from "@/utils/Pay";
import { getFormattedTelNumber } from "@/utils/string";
import BillPrintUtil from "@/utils/billPrintUtil";
import BadRequestError from "@/error/BadRequestError";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { ForeignKey, Resize, Selection } from "@syncfusion/ej2-vue-grids";
import FSelect from "@/views/store-management/sales-registration/store-pos-renew/components/FSelect";
import {
  getSearchButtonShortcut,
  toShortcutString,
} from "@/utils/KeyboardUtil";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "SettleAmountPopup",
  components: {
    FSelect,
    EjsGridWrapper,
    InputNumberUnusual,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  filters: {
    numberWithCommas,
  },
  watch: {
    payAmount(payAmount) {
      this.cashReceived = payAmount;
    },
    paymentMethod() {
      this.$refs["input-pay-amount"].focus();
    },
  },
  data() {
    return {
      payDivs: [],
      inProcessing: false,
      isPaidOnceOrMore: false,

      printFlag: getDeviceInfo().isBillPrinterUse || false,

      salesDate: null,
      businessInfo: {},

      type: null,
      data: null,

      salesDivision: null,
      payPreOrFutureDivision: null,
      paymentMethod: null,

      depositBalanceSearchCondition: {
        condition: null,
        showEmptyBalanceAmount: false,
      },

      cardPaymentParamsPurchaseId: "",

      salesAmount: 0,
      discountAmount: 0,
      totalAmount: 0,
      notPaidAmount: 0,
      taxAmount: 0,
      freeAmount: 0,
      vatAmount: 0,
      svcAmount: 0,

      cardList: [],
      depositBalanceStatusList: [],

      payAmount: 0,
      cashReceived: 0,

      cardPaymentParams: {
        divideTerm: 0, // 할부개월
        approvalFlag: false, // 기승인 (단말기승인)
      },
      cashPaymentParams: {
        proofKind: getDeviceInfo().isCardUse ? "VOL" : undefined, // 현금영수증 발행 구분. 기본값 : 자진발급(VAN사 사용시), 미발급(VAN 미사용시)
        proofNo: null,
        cardProof: null
      },
      creditPaymentParams: {},
      depositPaymentParams: {
        proofKind: undefined, // 현금영수증 발행 구분. 기본값 : 자진발급(VAN사 사용시), 미발급(VAN 미사용시)
        proofNo: null,
        cardProof: null,
        cardNo: null,
      },
      giftPaymentParams: {
        giftDiv: "GIFT",
        giftNo: null,
      },
      transPaymentParams: {
        proofKind: getDeviceInfo().isCardUse ? "VOL" : undefined, // 현금영수증 발행 구분. 기본값 : 자진발급(VAN사 사용시), 미발급(VAN 미사용시)
      },

      latestInputRefName: "",
      bizMstInfo: {},
    };
  },
  computed: {
    searchShortcutButtonShortcutString() {
      return toShortcutString(getSearchButtonShortcut());
    },
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: "정산",
        width: 1160,
        height: 760,
        allowDragging: true,
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },

    deviceInfo() {
      return getDeviceInfo();
    },

    salesDivisionName() {
      return commonCodesGetComName("SALES_DIV", this.salesDivision);
    },
    payer() {
      // TODO this is only for slip
      return this.data?.visitor || {};
    },
    payerName() {
      // TODO this is only for slip
      return this.payer.name || "일반고객";
    },
    payerLockerNumber() {
      // TODO this is only for slip
      return this.payer.lockerNumber || "000";
    },
    payPreOrFutureDivisionName() {
      return commonCodesGetComName(
        "PRPAY_FRPY_DIV",
        this.payPreOrFutureDivision
      );
    },
    paymentMethodName() {
      return commonCodesGetComName("PAY_DIV", this.paymentMethod);
    },
    changeAmount() {
      const changeAmount = this.cashReceived - this.payAmount;
      return changeAmount < 0 ? 0 : changeAmount;
    },
    depositBalanceStatusGridProps() {
      return {
        allowFiltering: true,
        allowResizing: true,
        allowSelection: true,
        allowSorting: true,
        columns: [
          { field: "depositId", visible: false },
          { field: "incomId", isPrimaryKey: true, visible: false },
          {
            field: "incomDate",
            headerText: "입금일자",
            type: "string",
            textAlign: "center",
            width: 90
          },
          {
            field: "depositKind",
            headerText: "종류",
            textAlign: "Left",
            isCommonCodeField: true,
            editType: "dropdownedit",
            groupCode: "DEPOSIT_KIND",
            width: 80,
          },
          {
            field: "memberName",
            headerText: "입금자",
            type: "string",
            allowEditing: true,
            textAlign: "Left",
            width: 70,
          },
          {
            field: "cardNo",
            headerText: "카드번호",
            type: "string",
            allowEditing: true,
            textAlign: "Left",
            width: 90
          },
          {
            field: "incomDiv",
            headerText: "입금구분",
            groupCode: "INCOM_DIV",
            isCommonCodeField: true,
            width: 90
          },
          {
            field: "indctTrgetFlag",
            headerText: "소득공제",
            textAlign: "center",
            type: "boolean",
            displayAsCheckBox: true,
            width: 90
          },
          {
            field: "balanceAmount",
            headerText: "선수금잔액",
            textAlign: "Right",
            type: "string",
            isNumericType: true,
            width: 100,
          },
          {
            field: "remarks",
            headerText: "비고",
            type: "string",
            textAlign: "Left",
            width: 104,
          },
        ],
        dataSource: this.depositBalanceStatusList,
        isNOColumnDisplay: true,
        isSelectedRowRetain: false,
        provides: [ForeignKey, Selection, Resize],
        selectionSettings: { type: "Single", enableToggle: false },
        useCommonStyles: false,
      };
    },
  },
  created() {
    this.bizMstInfo = commonCodesGetBizMstInfo();

    this.bizMstInfo.bizNo =
      this.bizMstInfo.bizNo.substr(0, 3) +
      "-" +
      this.bizMstInfo.bizNo.substr(3, 2) +
      "-" +
      this.bizMstInfo.bizNo.substring(5);

    this.payDivs = commonCodesGetCommonCode("PAY_DIV", true);
  },
  methods: {
    commonCodesGetComName,
    show({
      salesDate,
      type,
      data,
      salesDivision,
      payPreOrFutureDivision,
      paymentMethod,
    }) {
      this.salesDate = salesDate;
      this.type = type;
      this.data = data;
      this.salesDivision = salesDivision;
      this.payPreOrFutureDivision = payPreOrFutureDivision;
      this.paymentMethod = paymentMethod;

      this.initialize();

      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    appendValueByLatestInputRefName(value) {
      function append(v1, v2) {
        if (!v1 && !v2) {
          return "";
        }
        if (!v1) {
          return v2;
        }
        if (!v2) {
          return v1;
        }
        return `${v1}${v2}`;
      }
      const targetRefName = !this.$refs[this.latestInputRefName]
        ? "input-pay-amount"
        : this.latestInputRefName;
      const isValueNumber = !isNaN(Number(value));
      switch (targetRefName) {
        default:
        case "input-pay-amount":
          if (isValueNumber) {
            const getSelection = document.getSelection();
            if (
                getSelection?.focusNode?.parentElement?.id === 'input-pay-amount' &&
                getSelection?.toString() !== ''
            ) {
              const pureSelectionNumber = getSelection?.toString().replace(/,/g, '');
              this.payAmount = Number(this.payAmount.toString().replace(pureSelectionNumber, value));
            } else {
              this.payAmount = Number(append(this.payAmount, value));
            }
          }
          break;
        case "input-divide-term":
          if (isValueNumber) {
            this.cardPaymentParams.divideTerm = Number(
              append(this.cardPaymentParams.divideTerm, value)
            );
          }
          break;
        case "input-card-number":
          this.cardPaymentParams.cardNo = append(
            this.cardPaymentParams.cardNo,
            value
          );
          break;
        case "input-valid-term":
          this.cardPaymentParams.validTerm = append(
            this.cardPaymentParams.validTerm,
            value
          );
          break;
        case "input-approval-number":
          this.cardPaymentParams.approvalNo = append(
            this.cardPaymentParams.approvalNo,
            value
          );
          break;
        case "input-proof-number":
          this.cashPaymentParams.proofNo = append(
            this.cashPaymentParams.proofNo,
            value
          );
          break;
        case "input-cash-received":
          if (isValueNumber) {
            this.cashReceived = Number(append(this.cashReceived, value));
          }
          break;
        case "input-remarks":
          this.creditPaymentParams.remarks = append(
            this.creditPaymentParams.remarks,
            value
          );
          break;
        case "input-search-query":
          this.depositBalanceSearchCondition.condition = append(
            this.depositBalanceSearchCondition.condition,
            value
          );
          break;
        case "input-trans-proof-number":
          this.transPaymentParams.proofNo = append(
            this.transPaymentParams.proofNo,
            value
          );
          break;
        case "input-trans-remarks":
          this.transPaymentParams.remarks = append(
            this.transPaymentParams.remarks,
            value
          );
          break;
        case "input-gift-number":
          this.giftPaymentParams.giftNo = append(
            this.giftPaymentParams.giftNo,
            value
          );
      }
    },
    clearValueByLatestInputRefName() {
      const targetRefName = !this.$refs[this.latestInputRefName]
        ? "input-pay-amount"
        : this.latestInputRefName;
      switch (targetRefName) {
        default:
        case "input-pay-amount":
          this.payAmount = 0;
          break;
        case "input-divide-term":
          this.cardPaymentParams.divideTerm = 0;
          break;
        case "input-card-number":
          this.cardPaymentParams.cardNo = null;
          break;
        case "input-valid-term":
          this.cardPaymentParams.validTerm = null;
          break;
        case "input-approval-number":
          this.cardPaymentParams.approvalNo = null;
          break;
        case "input-proof-number":
          this.cashPaymentParams.proofNo = null;
          break;
        case "input-cash-received":
          this.cashReceived = 0;
          break;
        case "input-remarks":
          this.creditPaymentParams.remarks = null;
          break;
        case "input-search-query":
          this.depositBalanceSearchCondition.condition = null;
          break;
        case "input-trans-proof-number":
          this.transPaymentParams.proofNo = null;
          break;
        case "input-trans-remarks":
          this.transPaymentParams.remarks = null;
          break;
        case "input-gift-number":
          this.giftPaymentParams.giftNo = null;
          break;
      }
    },
    backspaceValueByLatestInputRefName() {
      const targetRefName = !this.$refs[this.latestInputRefName]
        ? "input-pay-amount"
        : this.latestInputRefName;
      switch (targetRefName) {
        default:
        case "input-pay-amount":
          if (0 < this.payAmount) {
            const getSelection = document.getSelection();
            if (
                getSelection?.focusNode?.parentElement?.id === 'input-pay-amount' &&
                getSelection?.toString() !== ''
            ) {
              const pureSelectionNumber = getSelection?.toString().replace(/,/g, '');
              this.payAmount = Number(this.payAmount.toString().replace(pureSelectionNumber, ''));
            } else {
              this.payAmount = Number(
                  `${this.payAmount}`.substr(0, `${this.payAmount}`.length - 1)
              );
            }
          }
          break;
        case "input-divide-term":
          if (0 < this.cardPaymentParams.divideTerm) {
            this.payAmount = Number(
              `${this.cardPaymentParams.divideTerm}`.substr(
                0,
                `${this.cardPaymentParams.divideTerm}`.length - 1
              )
            );
          }
          break;
        case "input-card-number":
          if (this.cardPaymentParams.cardNo) {
            this.cardPaymentParams.cardNo = `${this.cardPaymentParams.cardNo}`.substr(
              0,
              `${this.cardPaymentParams.cardNo}`.length - 1
            );
          }
          break;
        case "input-valid-term":
          if (this.cardPaymentParams.validTerm) {
            this.cardPaymentParams.validTerm = `${this.cardPaymentParams.validTerm}`.substr(
              0,
              `${this.cardPaymentParams.validTerm}`.length - 1
            );
          }
          break;
        case "input-approval-number":
          if (this.cardPaymentParams.approvalNo) {
            this.cardPaymentParams.approvalNo = `${this.cardPaymentParams.approvalNo}`.substr(
              0,
              `${this.cardPaymentParams.approvalNo}`.length - 1
            );
          }
          break;
        case "input-proof-number":
          if (this.cashPaymentParams.proofNo) {
            this.cashPaymentParams.proofNo = `${this.cashPaymentParams.proofNo}`.substr(
              0,
              `${this.cashPaymentParams.proofNo}`.length - 1
            );
          }
          break;
        case "input-cash-received":
          if (0 < this.cashReceived) {
            this.cashReceived = Number(
              `${this.cashReceived}`.substr(
                0,
                `${this.cashReceived}`.length - 1
              )
            );
          }
          break;
        case "input-remarks":
          if (this.creditPaymentParams.remarks) {
            this.creditPaymentParams.remarks = `${this.creditPaymentParams.remarks}`.substr(
              0,
              `${this.creditPaymentParams.remarks}`.length - 1
            );
          }
          break;
        case "input-search-query":
          if (this.depositBalanceSearchCondition.condition) {
            this.depositBalanceSearchCondition.condition = `${this.depositBalanceSearchCondition.condition}`.substr(
              0,
              `${this.depositBalanceSearchCondition.condition}`.length - 1
            );
          }
          break;
        case "input-trans-proof-number":
          if (this.transPaymentParams.proofNo) {
            this.transPaymentParams.proofNo = `${this.transPaymentParams.proofNo}`.substr(
              0,
              `${this.transPaymentParams.proofNo}`.length - 1
            );
          }
          break;
        case "input-trans-remarks":
          if (this.transPaymentParams.remarks) {
            this.transPaymentParams.remarks = `${this.transPaymentParams.remarks}`.substr(
              0,
              `${this.transPaymentParams.remarks}`.length - 1
            );
          }
          break;
        case "input-gift-number":
          if (this.giftPaymentParams.giftNo) {
            this.giftPaymentParams.giftNo = `${this.giftPaymentParams.giftNo}`.substr(
              0,
              `${this.giftPaymentParams.giftNo}`.length - 1
            );
          }
          break;
      }
    },
    focusLatestInputRef() {
      const targetRef = this.$refs[
        this.latestInputRefName || "input-pay-amount"
      ];
      if (targetRef) {
        targetRef.focus();
      } else {
        this.$refs["input-pay-amount"]?.focus();
      }
    },
    getCardName(cardId) {
      return this.cardList.find(({ id }) => id === cardId)?.name || "";
    },
    getCancelTransactionRequestByApprovedPayTransaction(
      approvedPayTransaction
    ) {
      return {
        van: approvedPayTransaction.van,
        payDivision: approvedPayTransaction.payDivision,
        approvalDivision: "CANCEL",
        totalAmount: approvedPayTransaction.totalAmount,
        taxAmount: approvedPayTransaction.taxAmount,
        vatAmount: approvedPayTransaction.vatAmount,
        notaxAmount: approvedPayTransaction.notaxAmount,
        serviceAmount: approvedPayTransaction.serviceAmount,
        proofKind: approvedPayTransaction.proofKind,
        proofNo: approvedPayTransaction.proofNo,
        divideTerm: approvedPayTransaction.divideTerm,
        approvalNo: approvedPayTransaction.approvalNo,
        approvalDateTime: approvedPayTransaction.approvalDateTime,
        saleNo: approvedPayTransaction.saleNo,
        storeCode: approvedPayTransaction.storeCode,
        businessId: approvedPayTransaction.businessId,
        posNo: getDeviceInfo().posNo,
        termId: approvedPayTransaction.termId,
        payDate: moment(approvedPayTransaction.approvalDateTime).format("YYYY-MM-DD")
      };
    },
    resetCardPaymentParams() {
      this.cardPaymentParams.approvalNo = null;
      this.cardPaymentParams.cardNo = null;
      this.cardPaymentParams.divideTerm = 0;
      this.cardPaymentParams.purcId = null;
      this.cardPaymentParams.purcName = null;
      this.cardPaymentParams.validTerm = null;
    },
    updateAddTransactionRequestForCashReceipt(
      addTransactionRequest,
      payCashInfo
    ) {
      addTransactionRequest.cardProof = payCashInfo.cardProof;
      addTransactionRequest.proofKind = payCashInfo.proofKind;
      addTransactionRequest.proofNo = payCashInfo.proofNo;
      addTransactionRequest.payDivision = "CASH";
    },

    onCardProofChange(event) {
      if (!event.target.checked) {
        this.cashPaymentParams.proofNo = null;
      }
    },
    onDepositCardProofChange(event) {
      if (!event.target.checked) {
        this.depositPaymentParams.proofNo = null;
      }
    },
    onTransCardProofChange(event) {
      if (!event.target.checked) {
        this.transPaymentParams.proofNo = null;
      }
    },
    onSearchDepositBalancesClick() {
      this.fetchDepositsBalanceStatus();
    },
    depositGridRowSelected(args) {
      this.depositPaymentParams.depositId = args.data.depositId;
      this.depositPaymentParams.incomId = args.data.incomId;
      this.depositPaymentParams.depositBalanceAmount = args.data.balanceAmount;
      this.depositPaymentParams.cardNo = args.data.cardNo;

      if (args.data.indctTrgetFlag) {
        this.depositPaymentParams.proofKind = "VOL";
        this.depositPaymentParams.proofNo = null;
        this.depositPaymentParams.cardProof = false;
      } else {
        this.depositPaymentParams.proofKind = undefined;
        this.depositPaymentParams.proofNo = null;
        this.depositPaymentParams.cardProof = false;
      }
    },
    depositGridOnActionComplete() {
      this.$refs["depositBalanceStatusGrid"].selectRow(0);
    },
    depositGridOnKeyPressed(args) {
      if (args.key === "Enter") {
        this.pay();
      }
    },
    onDialogOpen() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs["input-pay-amount"].focus();
        }, 500);
      });
    },
    async onDialogBeforeClose(args) {
      if (this.totalAmount > 0 && this.notPaidAmount) {
        args.cancel = true;
        this.errorToast("미정산액이 남아있습니다. 정산 완료 후 창을 닫아주십시요");
      } else {
        this.close();
      }
    },
    close() {
      this.$emit("close", {
        isPaid: this.isPaidOnceOrMore,
        notPaidAmount: this.notPaidAmount,
      });
    },
    onInputFocus(refName) {
      this.latestInputRefName = refName;
    },
    onCardPaymentChange(event, { item }) {
      console.log(item);
      this.cardPaymentParamsPurchaseId = item.id;
    },
    onInputPayAmountKeydown() {
      switch (this.paymentMethod) {
        case "EASY_PAY":
        case "CARD":
          this.$refs["input-divide-term"].focus();
          break;
        case "CASH":
        case "TRANS":
          this.onSubmitClick();
          break;
        case "CREDIT":
          this.$refs["input-remarks"].focus();
          break;
        case "DEPOSIT":
          this.$refs["input-search-query"].focus();
      }
    },
    onNumpadButtonClick(event, { value }) {
      switch (value) {
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
        case "0":
        case "00":
          this.appendValueByLatestInputRefName(value);
          break;
        case "Clear":
          this.clearValueByLatestInputRefName();
          break;
        case "Backspace":
          this.backspaceValueByLatestInputRefName();
          break;
        case "Enter":
          this.pay();
          break;
      }
    },
    onInputClick() {
      this.focusLatestInputRef();
    },
    onSubmitClick() {
      this.pay();
    },
    onHideClick() {
      this.hide();
    },

    async initialize() {
      await Promise.all([
        this.fetchStoreBsnMstInfo().then(async () => {
          await this.fetchPlacesOfCardPurchase();
        }),
        this.fetchPayInfo(),
        // this.fetchDepositsBalanceStatus()
      ]);
    },
    startPay() {
      this.$refs["dialog"].$el.nextElementSibling.style.zIndex = `${
        parseInt(this.$refs["dialog"].$el.nextElementSibling.style.zIndex) + 1
      }`;
    },
    endPay() {
      this.$refs["dialog"].$el.nextElementSibling.style.zIndex = `${
        parseInt(this.$refs["dialog"].$el.nextElementSibling.style.zIndex) - 1
      }`;
    },
    async pay() {
      if (this.inProcessing) {
        return;
      }

      this.startPay();

      this.inProcessing = true;

      let id = null;

      try {
        let payment = null;
        switch (this.paymentMethod) {
          case "CARD":
            payment = await this.payWithCard();
            break;
          case "CASH":
            payment = await this.payWithCash();
            break;
          case "CREDIT":
            payment = await this.payWithCredit();
            break;
          case "DEPOSIT":
            payment = await this.payWithDeposit();
            break;
          case "GIFT":
            payment = await this.payWithGift();
            break;
          case "TRANS":
            payment = await this.payWithTrans();
            break;
          case "EASY_PAY":
            payment = await this.payWithEasy();
            break;
        }

        if (!payment) {
          this.inProcessing = false;
          return;
        }

        switch (this.paymentMethod) {
          case "EASY_PAY":
          case "CARD":
            id = payment.payMethod.payCard.id;
            break;
          case "CASH":
          case "TRANS":
            if (this.salesDivision === "SALES") {
              id = payment.pay.id;
            } else if (this.salesDivision === "CREDIT") {
              id = payment.creditReturn.id;
            }
            break;
          case "CREDIT":
            id = payment.pay.id;
            break;
          case "DEPOSIT":
            id = payment.payMethod.depositUse.useId;
            break;
          case "GIFT":
            id = payment.pay.id;
            break;
        }

        this.infoToast("정산 처리되었습니다.");
        this.isPaidOnceOrMore = true;

        this.resetCardPaymentParams();
        await this.initialize();
        if (this.notPaidAmount === 0) {
          this.hide();
        }
      } catch (e) {
        console.error(e);
        if (e.message !== "CONFIRM") {
          this.errorToast(e.message);
        }
      } finally {
        this.inProcessing = false;
        this.endPay();
      }

      if (id) {
        if (this.printFlag && this.paymentMethod !== "CREDIT") {
          this.print(id);
        }
      }
    },
    async print(id, signYN) {
      try {
        if (id === null) {
          return;
        }

        if (
          this.paymentMethod === "TRANS" &&
          !this.transPaymentParams.proofKind
        ) {
          return;
        }

        let payDivPrint = this.paymentMethod === "EASY_PAY" ? "CARD" : this.paymentMethod;

        // 디바이스 설정의 Bill 프린터 사용여부가 false이면 Bill 프린터 출력 안됨.
        if (!this.printFlag) {
          throw Error(
            this.$t("billPrinter.validationMessage.deviceBillPrintSet")
          );
        }
        let receiptCode = payDivPrint.toUpperCase() + "_RECEIPT";



        let printSetData = null;

        if (payDivPrint.toUpperCase() === "DEPOSIT") {
          const depositUseReceipt = await this.getDepositUseReceipt(id);

          let depositUseList = JSON.parse(
            JSON.stringify(depositUseReceipt.depositUseList)
          );

          depositUseList.forEach((data) => {
            data.depositUseDiv =
              data.depositUseDiv === undefined || data.depositUseDiv === null
                ? ""
                : commonCodesGetComName("DEPOSIT_USE_DIV", data.depositUseDiv);
          });

          let payReturnId = null;

          if (this.salesDivision === "SALES") {
            payReturnId = depositUseReceipt.payId;
          } else if (this.salesDivision === "CREDIT") {
            payReturnId = depositUseReceipt.returnId;
          }

          let receiptInfo = {};
          if (payReturnId) {
            receiptCode = "DEPOSIT_CASH_RECEIPT",
            receiptInfo = await this.getReceiptInfo(payReturnId, "CASH");
          }

          printSetData = {
            printDt: depositUseReceipt.printDt,
            cardNo:
              depositUseReceipt.cardNo === undefined ||
              depositUseReceipt.cardNo === null
                ? ""
                : depositUseReceipt.cardNo,
            depositAmt: depositUseReceipt.depositAmt,
            sumUseAmt: depositUseReceipt.sumUseAmt,
            useAmt: depositUseReceipt.useAmt,
            balanceAmt: depositUseReceipt.balanceAmt,
            totalUseAmt: depositUseReceipt.totalUseAmt,
            useDate: depositUseReceipt.useDate,
            startCourse:
              depositUseReceipt.startCourse === undefined ||
              depositUseReceipt.startCourse === null
                ? ""
                : commonCodesGetComName(
                    "COURSE_CODE",
                    depositUseReceipt.startCourse
                  ),
            startTime:
              depositUseReceipt.startTime === undefined ||
              depositUseReceipt.startTime === null
                ? ""
                : depositUseReceipt.startTime,
            depositUseDiv:
              depositUseReceipt.depositUseDiv === undefined ||
              depositUseReceipt.depositUseDiv === null
                ? ""
                : commonCodesGetComName(
                    "DEPOSIT_USE_DIV",
                    depositUseReceipt.depositUseDiv
                  ),
            ccName: this.bizMstInfo.ccName,
            bizNo: this.bizMstInfo.bizNo,
            rprsntvName: this.bizMstInfo.rprsntvName,
            telNo: getFormattedTelNumber(this.bizMstInfo.telNo, ")"),
            faxNo: getFormattedTelNumber(this.bizMstInfo.faxNo, ")"),
            depositUseList: depositUseList,
            memberName: depositUseReceipt.memberName,
            contactTel: getFormattedTelNumber(depositUseReceipt.contactTel),
            addr: receiptInfo?.addr,
            approvalDt: receiptInfo?.approvalDt,
            approvalNo: receiptInfo?.approvalNo,
            cashTitle:
              (receiptInfo?.approvalDiv === "OK"
                ? "현금승인 "
                : "현금취소 ") +
              (receiptInfo?.proofName
                ? "(" + receiptInfo?.proofName + ")"
                : "(미발급)"),
            merchantName: receiptInfo?.bizName,
            proofNo: receiptInfo?.proofNo,
            rePrintDt: null,
            totAmt: receiptInfo?.approvalAmt,
            taxAmt: receiptInfo?.taxAmt,
            vatAmt: receiptInfo?.vatAmt
          };
        } else {
          const receiptInfo = await this.getReceiptInfo(id, payDivPrint);

          printSetData = {
            storeName: commonCodesGetComName(
              "STORE_CODE",
              receiptInfo.storeCode
            ),
            bsnDate: receiptInfo.payDate,
            lockerNo: receiptInfo.lockerNo,
            slipNo: receiptInfo.slipNo,
            cardKind: receiptInfo.issuCompanyName,
            cardNo: receiptInfo.cardNo,
            validTerm: receiptInfo.validTerm,
            approvalDt: receiptInfo.approvalDt,
            purcName: receiptInfo.purcName,
            rePrintDt: null,
            totAmt: receiptInfo.approvalAmt,
            taxAmt: receiptInfo.taxAmt,
            noTaxAmt: receiptInfo.notaxAmt,
            svcAmt: receiptInfo.svcAmt === "0" ? null : receiptInfo.svcAmt,
            vatAmt: receiptInfo.vatAmt,
            approvalNo: receiptInfo.approvalNo,
            divideTerm: receiptInfo.divideTerm,
            merchantNo: receiptInfo.merchantNo,
            merchantName: receiptInfo.bizName,
            bizNo: receiptInfo.bizNo,
            rprsntvName: receiptInfo.rprsntvName,
            addr: receiptInfo.addr,
            telNo: getFormattedTelNumber(receiptInfo.telNo),
            faxNo: getFormattedTelNumber(receiptInfo.faxNo),
            proofName: receiptInfo.proofName,
            proofNo: receiptInfo.proofNo,
            cardTitle:
              receiptInfo.approvalDiv === "OK"
                ? "매출전표 (신용승인)"
                : "매출전표 (신용취소)",
            cashTitle:
              (receiptInfo.approvalDiv === "OK" ? "현금승인 " : "현금취소 ") +
              (receiptInfo.proofName
                ? "(" + receiptInfo.proofName + ")"
                : "(미발급)"),
            transTitle:
              (receiptInfo.approvalDiv === "OK"
                ? "계좌이체승인 "
                : "계좌이체취소 ") +
              (receiptInfo.proofName
                ? "(" + receiptInfo.proofName + ")"
                : "(미발급)"),
            signYn: signYN,
            giftNo: receiptInfo.giftNo,
            payAmt: receiptInfo.payAmt,
            memberName: receiptInfo.memberName,
            contactTel: getFormattedTelNumber(
              receiptInfo.contactTel
            ),
            printDt: receiptInfo.printDt,
            giftTitle: receiptInfo.approvalDiv === "OK" ? "상품권 사용 내역서" : "상품권 취소 내역서",
          };
        }

        const config = {
          "useBillPrinter": true,
          "useKitchenPrinter": false,
        };
        await BillPrintUtil.getTsConfReceiptInfo.call(this, receiptCode , true, printSetData , config);


      } catch (e) {
        if (e.message) {
          this.errorToast(e);
        } else {
          this.errorToast(this.$t("vanModule.popupMessage.printReceiptFail"));
        }
        // if (e.message) {
        //   throw e;
        // } else {
        //   throw Error(this.$t("vanModule.popupMessage.printReceiptFail"));
        // }
      }
    },

    // TODO deprecate
    async fetchStoreBsnMstInfo() {
      const { value: bsnInfo } = await getStoreBsnMstInfo(this.data.storeCode);

      this.businessInfo = bsnInfo;
    },
    // TODO deprecate
    async fetchPlacesOfCardPurchase() {
      this.cardList = (
        await GolfERPService.getPlacesOfCardPurchase(this.businessInfo.vanKind)
      ).map(({ purchaseId, purchaseName, vanMappingList }) => ({
        id: purchaseId,
        name: purchaseName,
        value: purchaseId,
        vanMapCode: vanMappingList[0].vanMapCode,
      }));
    },
    // TODO deprecate
    async fetchPayInfo() {
      const data = {
        salesDiv: this.salesDivision, // 매출구분
        gcDiv: this.data.golfCabinDivision,
      };

      if (this.type === "SLIP") {
        data.bsnDate = moment(this.salesDate).format(DATE_FORMAT_YYYY_MM_DD); // TODO why?
        data.storeCode = this.data.storeCode;
        data.salesId = data.storeCode === "FRONT" ? null : this.data.id;
        data.personFlag = false;
        data.visitId = this.data.visitorId;
        data.chkinId = this.data.visitor?.team?.id;
        data.grpNo = this.data.visitor?.team?.groupNumber;
      }
      // TODO else if

      const {
        value: {
          payPopupInfo: {
            calcuAmt,
            dcAmt,
            freeAmt,
            noPayAmt,
            saleAmt,
            svcAmt,
            taxAmt,
            vatAmt,
          },
        },
      } = await getPayInfo(data);

      this.salesAmount = saleAmt || 0;
      this.discountAmount = dcAmt || 0;
      this.totalAmount = calcuAmt || 0;
      this.notPaidAmount = noPayAmt || 0;
      this.taxAmount = taxAmt || 0;
      this.freeAmount = freeAmt || 0;
      this.vatAmount = vatAmt || 0;
      this.svcAmount = svcAmt || 0;
      this.payAmount = noPayAmt || 0;
    },
    // TODO deprecate
    async getDepositUseReceipt(id) {
      return await GolfErpAPI.fetchDepositUseReceipt(id);
    },
    // TODO deprecate
    async getReceiptInfo(payId, payDiv) {
      const {
        value: { receiptInfo },
      } = await getReceiptInfo(payId, payDiv, this.salesDiv);

      if (payDiv === "CASH" || payDiv === "TRANS") {
        receiptInfo.proofName = commonCodesGetComName(
          "PROOF_KIND",
          receiptInfo.proofKind
        );
      }

      return receiptInfo;
    },
    // TODO deprecate
    async fetchDepositsBalanceStatus() {
      if (!this.depositBalanceSearchCondition.condition) {
        this.errorToast(this.$t("main.popupMessage.noSearchKeyword"));
        return;
      }

      await GolfERPService.fetchDepositsBalanceStatus(
        this.depositBalanceSearchCondition.condition,
        this.depositBalanceSearchCondition.showEmptyBalanceAmount
      ).then((data) => {
        this.depositBalanceStatusList = data;

        if (this.depositBalanceStatusList.length === 0) {
          this.errorToast(this.$t("main.popupMessage.noSearchResult"));
        }
      }).catch((e) => {
        console.error(e);
        this.depositBalanceStatusList = [];
      });
    },

    async payWithCard() {
      if (!(0 < this.payAmount)) {
        throw Error("결제금액을 입력해주세요.");
      }
      if (this.cardPaymentParams.approvalFlag) {
        if (!this.cardPaymentParams.cardNo) {
          throw Error("카드 번호를 입력해주세요.");
        }
        if (!this.cardPaymentParamsPurchaseId) {
          throw Error("카드사를 선택해주세요.");
        }
        if (!this.cardPaymentParams.validTerm) {
          throw Error("유효기간을 입력해주세요.");
        }
        if (!this.cardPaymentParams.approvalNo) {
          throw Error("승인번호를 입력해주세요.");
        }
      }
      if (this.payAmount > this.notPaidAmount) {
        throw Error(this.$t("vanModule.popupMessage.cannotPayOver"));
      }

      const {
        taxAmount,
        notaxAmount,
        vatAmount,
        serviceAmount,
      } = await GolfERPService.fetchPayTax({
        currentPayAmount: this.payAmount,
        totalTaxAmount: this.taxAmount,
        totalNoTaxAmount: this.freeAmount,
        totalVatAmount: this.vatAmount,
        totalServiceAmount: this.svcAmount,
      });

      const payRequest = {
        payDate: this.data.date,
        storeCode: this.data.storeCode,
        salesDivision: this.salesDivision,
        visitId: this.data.visitorId,
        salesId: this.data.id,
        golfCabinDivision: this.businessInfo.gcDiv, // 객실 구분 미 개발에 따른 하드코딩
        payPreOrFutureDivision: this.payPreOrFutureDivision,
        payDivision: this.paymentMethod,
        payAmount: this.payAmount,
        taxAmount,
        notaxAmount,
        vatAmount,
        serviceAmount,
        businessId: this.businessInfo.bsnId,
        posNo: this.deviceInfo.posNo,
        termId: this.businessInfo.termId,
        approvedCardFlag: this.cardPaymentParams.approvalFlag,
        approvedCard: !this.cardPaymentParams.approvalFlag
          ? null
          : {
              approvalNo: this.cardPaymentParams.approvalNo,
              approvalDateTime: moment(await GolfErpAPI.fetchNow()).format(
                `${DATE_FORMAT_YYYY_MM_DD} ${TIME_FORMAT_hh_mm_ss}`
              ),
              cardNo: this.cardPaymentParams.cardNo,
              divideTerm: this.cardPaymentParams.divideTerm,
              purchaseId: this.cardPaymentParamsPurchaseId,
              purchaseName: this.getCardName(this.cardPaymentParamsPurchaseId),
              issueCompanyName: "Easy Check",
              validTerm: this.cardPaymentParams.validTerm,
            },
      };

      const addTransactionRequest = {
        van: this.businessInfo.vanKind,
        payDivision: this.cardPaymentParams.approvalFlag ? null : "CARD",
        approvalDivision: "OK",
        totalAmount: this.payAmount,
        taxAmount,
        vatAmount,
        notaxAmount,
        serviceAmount,
        divideTerm: this.cardPaymentParams.approvalFlag
          ? null
          : this.cardPaymentParams.divideTerm,
        storeCode: this.data.storeCode,
        businessId: this.businessInfo.bsnId,
        posNo: this.deviceInfo.posNo,
        termId: this.businessInfo.termId,
        payDate: this.data.date,
      };

      if (!this.cardPaymentParams.approvalFlag) {
        const { tid, message, status } = await this.payVan(
          addTransactionRequest
        );

        if (status !== "APPROVED") {
          if (status === "SELFCANCEL") {
            this.infoToast(message);
            return;
          } else {
            throw Error(message);
          }
        }

        payRequest.tid = tid;
      }

      return await this.sendPayResultToServer(payRequest);
    },
    async payWithCash() {
      if (!!this.cashPaymentParams.proofKind && this.payAmount < 0) {
        throw Error("현금영수증 발급시 결제금액이 마이너스일 수 없습니다.");
      }

      if (0 === this.payAmount && this.cashPaymentParams.proofKind) {
        throw Error("결제금액을 입력해주세요.");
      }

      if (this.payAmount > this.notPaidAmount) {
        throw Error(this.$t("vanModule.popupMessage.cannotPayOver"));
      }

      const {
        taxAmount,
        notaxAmount,
        vatAmount,
        serviceAmount,
      } = await GolfERPService.fetchPayTax({
        currentPayAmount: this.payAmount,
        totalTaxAmount: this.taxAmount,
        totalNoTaxAmount: this.freeAmount,
        totalVatAmount: this.vatAmount,
        totalServiceAmount: this.svcAmount,
      });

      const payRequest = {
        payDate: this.data.date,
        storeCode: this.data.storeCode,
        salesDivision: this.salesDivision,
        visitId: this.data.visitorId,
        salesId: this.data.id,
        golfCabinDivision: this.businessInfo.gcDiv, // 객실 구분 미 개발에 따른 하드코딩
        payPreOrFutureDivision: this.payPreOrFutureDivision,
        payDivision: this.paymentMethod,
        payAmount: this.payAmount,
        taxAmount,
        notaxAmount,
        vatAmount,
        serviceAmount,
        businessId: this.businessInfo.bsnId,
        posNo: this.deviceInfo.posNo,
        termId: this.businessInfo.termId,
        approvedCardFlag: false,
      };

      const addTransactionRequest = {
        van: this.businessInfo.vanKind,
        approvalDivision: "OK",
        totalAmount: this.payAmount,
        taxAmount,
        vatAmount,
        notaxAmount,
        serviceAmount,
        storeCode: this.data.storeCode,
        businessId: this.businessInfo.bsnId,
        posNo: this.deviceInfo.posNo,
        termId: this.businessInfo.termId,
        payDate: this.data.date,
      };

      // const payment = await this.sendPayResultToServer(payRequest);

      let cashReceiptRequest = {};

      // 현금영수증 발급
      if (
        payRequest.payDivision === "CASH" &&
        this.cashPaymentParams.proofKind
      ) {
        // 현금영수증 증빙 구분이 자진발급이 아니거나 자진발급이고 자진발급 거부시 VAN 결제모듈 호출
        this.updateAddTransactionRequestForCashReceipt(
          addTransactionRequest,
          this.cashPaymentParams
        );
        try {
          const { tid, status, message } = await this.payVan(
            addTransactionRequest
          );

          if (status === "SELFCANCEL") {
            this.infoToast(message);
            return;
          }

          cashReceiptRequest = {
            tid: tid,
            referenceId: null,
            salesDivision: this.salesDivision,
          };

          // switch (this.salesDivision) {
          //   case "SALES":
          //     cashReceiptRequest.referenceId = payment.pay.id;
          //     break;
          //   case "CREDIT":
          //     cashReceiptRequest.referenceId = payment.creditReturn.id;
          //     break;
          // }
          //
          // await GolfERPService.postPayCashReceipt(cashReceiptRequest);
        } catch (e) {
          // 현금영수증 발급 실패시에도 현금정산은 미발급인 상태로 완료되므로 현금영수증 발급 실패 ERROR후 정상진행되어야함.
          this.isPaidOnceOrMore = true;
          await this.initialize();
          if (this.notPaidAmount === 0) {
            this.hide();
          }
          if (e instanceof Error) {
            throw Error(`${e.message}<br/>현금영수증 발급에 실패하였습니다.`);
          } else {
            throw Error(`${e}<br/>현금영수증 발급에 실패하였습니다.`);
          }
        }
      }

      const payment = await this.sendPayResultToServer(payRequest);

      // 현금영수증 발급후 처리.
      // 현금영수증 발급 실패시 현금 정산 안되도록 하기 위한 처리.
      if (
        payRequest.payDivision === "CASH" &&
        this.cashPaymentParams.proofKind
      ) {
        switch (this.salesDivision) {
          case "SALES":
            cashReceiptRequest.referenceId = payment.pay.id;
            break;
          case "CREDIT":
            cashReceiptRequest.referenceId = payment.creditReturn.id;
            break;
        }

        await GolfERPService.postPayCashReceipt(cashReceiptRequest);
      }

      return payment;
    },
    async payWithEasy() {
      if (!(0 < this.payAmount)) {
        throw Error("결제금액을 입력해주세요.");
      }
      if (this.payAmount > this.notPaidAmount) {
        throw Error(this.$t("vanModule.popupMessage.cannotPayOver"));
      }

      const {
        taxAmount,
        notaxAmount,
        vatAmount,
        serviceAmount,
      } = await GolfERPService.fetchPayTax({
        currentPayAmount: this.payAmount,
        totalTaxAmount: this.taxAmount,
        totalNoTaxAmount: this.freeAmount,
        totalVatAmount: this.vatAmount,
        totalServiceAmount: this.svcAmount,
      });

      const payRequest = {
        payDate: this.data.date,
        storeCode: this.data.storeCode,
        salesDivision: this.salesDivision,
        visitId: this.data.visitorId,
        salesId: this.data.id,
        golfCabinDivision: this.businessInfo.gcDiv, // 객실 구분 미 개발에 따른 하드코딩
        payPreOrFutureDivision: this.payPreOrFutureDivision,
        payDivision: this.paymentMethod,
        payAmount: this.payAmount,
        taxAmount,
        notaxAmount,
        vatAmount,
        serviceAmount,
        businessId: this.businessInfo.bsnId,
        posNo: this.deviceInfo.posNo,
        termId: this.businessInfo.termId,
        approvedCardFlag: this.cardPaymentParams.approvalFlag,
        approvedCard: !this.cardPaymentParams.approvalFlag
            ? null
            : {
              approvalNo: this.cardPaymentParams.approvalNo,
              approvalDateTime: moment(await GolfErpAPI.fetchNow()).format(
                  `${DATE_FORMAT_YYYY_MM_DD} ${TIME_FORMAT_hh_mm_ss}`
              ),
              cardNo: this.cardPaymentParams.cardNo,
              divideTerm: this.cardPaymentParams.divideTerm,
              purchaseId: this.cardPaymentParamsPurchaseId,
              purchaseName: this.getCardName(this.cardPaymentParamsPurchaseId),
              issueCompanyName: "Easy Check",
              validTerm: this.cardPaymentParams.validTerm,
            },
      };

      const addTransactionRequest = {
        van: this.businessInfo.vanKind,
        payDivision: "EASY_PAY",
        approvalDivision: "OK",
        totalAmount: this.payAmount,
        taxAmount,
        vatAmount,
        notaxAmount,
        serviceAmount,
        divideTerm: this.cardPaymentParams.divideTerm,
        storeCode: this.data.storeCode,
        businessId: this.businessInfo.bsnId,
        posNo: this.deviceInfo.posNo,
        termId: this.businessInfo.termId,
        payDate: this.data.date,
      };

      const { tid, message, status } = await this.payVan(
          addTransactionRequest
      );

      if (status !== "APPROVED") {
        if (status === "SELFCANCEL") {
          this.infoToast(message);
          return;
        } else {
          throw Error(message);
        }
      }

      payRequest.tid = tid;

      return await this.sendPayResultToServer(payRequest);
    },
    async payWithTrans() {
      if (!!this.transPaymentParams.proofKind && this.payAmount < 0) {
        throw Error("현금영수증 발급시 결제금액이 마이너스일 수 없습니다.");
      }

      if (0 === this.payAmount) {
        throw Error("결제금액을 입력해주세요.");
      }

      if (this.payAmount > this.notPaidAmount) {
        throw Error(this.$t("vanModule.popupMessage.cannotPayOver"));
      }

      const {
        taxAmount,
        notaxAmount,
        vatAmount,
        serviceAmount,
      } = await GolfERPService.fetchPayTax({
        currentPayAmount: this.payAmount,
        totalTaxAmount: this.taxAmount,
        totalNoTaxAmount: this.freeAmount,
        totalVatAmount: this.vatAmount,
        totalServiceAmount: this.svcAmount,
      });

      const payRequest = {
        payDate: this.data.date,
        storeCode: this.data.storeCode,
        salesDivision: this.salesDivision,
        visitId: this.data.visitorId,
        salesId: this.data.id,
        golfCabinDivision: this.businessInfo.gcDiv, // 객실 구분 미 개발에 따른 하드코딩
        payPreOrFutureDivision: this.payPreOrFutureDivision,
        payDivision: this.paymentMethod,
        payAmount: this.payAmount,
        taxAmount,
        notaxAmount,
        vatAmount,
        serviceAmount,
        businessId: this.businessInfo.bsnId,
        posNo: this.deviceInfo.posNo,
        termId: this.businessInfo.termId,
        approvedCardFlag: false,
        remarks: this.transPaymentParams.remarks,
      };

      const addTransactionRequest = {
        van: this.businessInfo.vanKind,
        approvalDivision: "OK",
        totalAmount: this.payAmount,
        taxAmount,
        vatAmount,
        notaxAmount,
        serviceAmount,
        storeCode: this.data.storeCode,
        businessId: this.businessInfo.bsnId,
        posNo: this.deviceInfo.posNo,
        termId: this.businessInfo.termId,
        payDate: this.data.date,
      };

      // const payment = await this.sendPayResultToServer(payRequest);

      let cashReceiptRequest = {};

      // 현금영수증 발급
      if (
        payRequest.payDivision === "TRANS" &&
        this.transPaymentParams.proofKind
      ) {
        // 현금영수증 증빙 구분이 자진발급이 아니거나 자진발급이고 자진발급 거부시 VAN 결제모듈 호출
        this.updateAddTransactionRequestForCashReceipt(
          addTransactionRequest,
          this.transPaymentParams
        );
        try {
          const { tid, status, message } = await this.payVan(
            addTransactionRequest
          );

          if (status === "SELFCANCEL") {
            this.infoToast(message);
            return;
          }

          cashReceiptRequest = {
            tid: tid,
            referenceId: null,
            salesDivision: this.salesDivision,
          };

          // switch (this.salesDivision) {
          //   case "SALES":
          //     cashReceiptRequest.referenceId = payment.pay.id;
          //     break;
          //   case "CREDIT":
          //     cashReceiptRequest.referenceId = payment.creditReturn.id;
          //     break;
          // }
          //
          // await GolfERPService.postPayCashReceipt(cashReceiptRequest);
        } catch (e) {
          // 현금영수증 발급 실패시에도 현금정산은 미발급인 상태로 완료되므로 현금영수증 발급 실패 ERROR후 정상진행되어야함.
          this.isPaidOnceOrMore = true;
          await this.initialize();
          if (this.notPaidAmount === 0) {
            this.hide();
          }
          if (e instanceof Error) {
            throw Error(`${e.message}<br/>현금영수증 발급에 실패하였습니다.`);
          } else {
            throw Error(`${e}<br/>현금영수증 발급에 실패하였습니다.`);
          }
        }
      }

      const payment = await this.sendPayResultToServer(payRequest);

      // 현금영수증 발급후 처리.
      // 현금영수증 발급 실패시 현금 정산 안되도록 하기 위한 처리.
      if (
        payRequest.payDivision === "TRANS" &&
        this.transPaymentParams.proofKind
      ) {
        switch (this.salesDivision) {
          case "SALES":
            cashReceiptRequest.referenceId = payment.pay.id;
            break;
          case "CREDIT":
            cashReceiptRequest.referenceId = payment.creditReturn.id;
            break;
        }

        await GolfERPService.postPayCashReceipt(cashReceiptRequest);
      }

      return payment;
    },
    async payWithCredit() {
      if (0 === this.payAmount) {
        throw Error("결제금액을 입력해주세요.");
      }
      if (!this.creditPaymentParams.remarks) {
        throw Error("비고를 입력해주세요.");
      }
      if (this.payAmount > this.notPaidAmount) {
        throw Error(this.$t("vanModule.popupMessage.cannotPayOver"));
      }

      const {
        taxAmount,
        notaxAmount,
        vatAmount,
        serviceAmount,
      } = await GolfERPService.fetchPayTax({
        currentPayAmount: this.payAmount,
        totalTaxAmount: this.taxAmount,
        totalNoTaxAmount: this.freeAmount,
        totalVatAmount: this.vatAmount,
        totalServiceAmount: this.svcAmount,
      });

      const payRequest = {
        payDate: this.data.date,
        storeCode: this.data.storeCode,
        salesDivision: this.salesDivision,
        visitId: this.data.visitorId,
        salesId: this.data.id,
        golfCabinDivision: this.businessInfo.gcDiv, // 객실 구분 미 개발에 따른 하드코딩
        payPreOrFutureDivision: this.payPreOrFutureDivision,
        payDivision: this.paymentMethod,
        payAmount: this.payAmount,
        taxAmount,
        notaxAmount,
        vatAmount,
        serviceAmount,
        remarks: this.creditPaymentParams.remarks,
        businessId: this.businessInfo.bsnId,
        posNo: this.deviceInfo.posNo,
        termId: this.businessInfo.termId,
        approvedCardFlag: false,
      };

      return await this.sendPayResultToServer(payRequest);
    },
    async payWithDeposit() {
      if (0 === this.payAmount) {
        throw Error("결제금액을 입력해주세요.");
      }
      if (!this.depositPaymentParams.depositId) {
        throw Error("청구대상을 선택해주세요.");
      }
      // if (this.depositPaymentParams.depositBalanceAmount < this.payAmount) {
      //   throw Error("선수금 잔액이 부족합니다.");
      // }
      if (this.payAmount > this.notPaidAmount) {
        throw Error(this.$t("vanModule.popupMessage.cannotPayOver"));
      } else {
        if (this.paymentMethod === "DEPOSIT") {
          if (this.payAmount > this.depositPaymentParams.depositBalanceAmount) {
            if (
              await this.confirm(
                `남은 선수금 잔액(` +
                  numberWithCommas(
                    this.depositPaymentParams.depositBalanceAmount
                  ) +
                  `)을 모두 사용하시겠습니까?`
              )
            ) {
              this.payAmount = this.depositPaymentParams.depositBalanceAmount;
            } else {
              throw Error("");
            }
          }
        }
      }

      const {
        taxAmount,
        notaxAmount,
        vatAmount,
        serviceAmount,
      } = await GolfERPService.fetchPayTax({
        currentPayAmount: this.payAmount,
        totalTaxAmount: this.taxAmount,
        totalNoTaxAmount: this.freeAmount,
        totalVatAmount: this.vatAmount,
        totalServiceAmount: this.svcAmount,
      });

      const payRequest = {
        payDate: this.data.date,
        storeCode: this.data.storeCode,
        salesDivision: this.salesDivision,
        visitId: this.data.visitorId,
        salesId: this.data.id,
        golfCabinDivision: this.businessInfo.gcDiv, // 객실 구분 미 개발에 따른 하드코딩
        payPreOrFutureDivision: this.payPreOrFutureDivision,
        payDivision: this.paymentMethod,
        payAmount: this.payAmount,
        taxAmount,
        notaxAmount,
        vatAmount,
        serviceAmount,
        businessId: this.businessInfo.bsnId,
        posNo: this.deviceInfo.posNo,
        termId: this.businessInfo.termId,
        approvedCardFlag: false,
        giftNo: this.depositPaymentParams.cardNo,
        depositUse: {
          depositId: this.depositPaymentParams.depositId,
          incomId: this.depositPaymentParams.incomId,
          useDate:
            this.salesDivision === "CREDIT"
              ? moment(this.data.date)
              : moment(await GolfErpAPI.fetchNow()).format(
                  DATE_FORMAT_YYYY_MM_DD
                ),
        },
      };

      const addTransactionRequest = {
        van: this.businessInfo.vanKind,
        approvalDivision: "OK",
        totalAmount: this.payAmount,
        taxAmount,
        vatAmount,
        notaxAmount,
        serviceAmount,
        storeCode: this.data.storeCode,
        businessId: this.businessInfo.bsnId,
        posNo: this.deviceInfo.posNo,
        termId: this.businessInfo.termId,
        payDate: this.data.date,
      };

      let cashReceiptRequest = {};

      // 현금영수증 발급
      if (
        payRequest.payDivision === "DEPOSIT" &&
        this.depositPaymentParams.proofKind
      ) {
        // 현금영수증 증빙 구분이 자진발급이 아니거나 자진발급이고 자진발급 거부시 VAN 결제모듈 호출
        this.updateAddTransactionRequestForCashReceipt(
          addTransactionRequest,
          this.depositPaymentParams
        );
        try {
          const { tid, status, message } = await this.payVan(
            addTransactionRequest
          );

          if (status === "SELFCANCEL") {
            this.infoToast(message);
            return;
          }

          cashReceiptRequest = {
            tid: tid,
            referenceId: null,
            salesDivision: this.salesDivision,
          };
        } catch (e) {
          // 현금영수증 발급 실패시에도 현금정산은 미발급인 상태로 완료되므로 현금영수증 발급 실패 ERROR후 정상진행되어야함.
          this.isPaidOnceOrMore = true;
          await this.initialize();
          if (this.notPaidAmount === 0) {
            this.hide();
          }
          if (e instanceof Error) {
            throw Error(`${e.message}<br/>현금영수증 발급에 실패하였습니다.`);
          } else {
            throw Error(`${e}<br/>현금영수증 발급에 실패하였습니다.`);
          }
        }
      }

      const payment = await this.sendPayResultToServer(payRequest);

      // 현금영수증 발급후 처리.
      // 현금영수증 발급 실패시 현금 정산 안되도록 하기 위한 처리.
      if (
        payRequest.payDivision === "DEPOSIT" &&
        this.depositPaymentParams.proofKind
      ) {
        switch (this.salesDivision) {
          case "SALES":
            cashReceiptRequest.referenceId = payment.pay.id;
            break;
          case "CREDIT":
            cashReceiptRequest.referenceId = payment.creditReturn.id;
            break;
        }

        await GolfERPService.postPayCashReceipt(cashReceiptRequest);
      }

      this.onSearchDepositBalancesClick();

      return payment;
    },
    async payWithGift() {
      if (0 === this.payAmount) {
        throw Error("결제금액을 입력해주세요.");
      }
      if (this.payAmount > this.notPaidAmount) {
        throw Error(this.$t("vanModule.popupMessage.cannotPayOver"));
      }

      const {
        taxAmount,
        notaxAmount,
        vatAmount,
        serviceAmount,
      } = await GolfERPService.fetchPayTax({
        currentPayAmount: this.payAmount,
        totalTaxAmount: this.taxAmount,
        totalNoTaxAmount: this.freeAmount,
        totalVatAmount: this.vatAmount,
        totalServiceAmount: this.svcAmount,
      });

      const payRequest = {
        payDate: this.data.date,
        storeCode: this.data.storeCode,
        salesDivision: this.salesDivision,
        visitId: this.data.visitorId,
        salesId: this.data.id,
        golfCabinDivision: this.businessInfo.gcDiv, // 객실 구분 미 개발에 따른 하드코딩
        payPreOrFutureDivision: this.payPreOrFutureDivision,
        payDivision: this.giftPaymentParams.giftDiv,
        giftNo: this.giftPaymentParams.giftNo,
        payAmount: this.payAmount,
        taxAmount,
        notaxAmount,
        vatAmount,
        serviceAmount,
        businessId: this.businessInfo.bsnId,
        posNo: this.deviceInfo.posNo,
        termId: this.businessInfo.termId,
        approvedCardFlag: false,
      };

      const payment = await this.sendPayResultToServer(payRequest);

      return payment;
    },
    async payVan(addTransactionRequest) {
      const { tid, status, message } = await openERPPay(addTransactionRequest);

      switch (status) {
        case "APPROVED":
        case "CANCELED":
        case "SELFCANCEL":
          return { tid, status, message };
        case "READY":
        case "REQUEST":
          throw new Error("결제가 취소되었습니다.");
        case "FAIL":
          throw new Error(message);
        case "PURCHASE_CODE_INVALID":
          await this.errorToast(message + "<br/>결제 취소를 진행합니다.");
          // eslint-disable-next-line no-case-declarations
          const approvedPayTransaction = await GolfERPService.getPayTransaction(
            tid
          );
          // eslint-disable-next-line no-case-declarations
          const addTransactionRequest = this.getCancelTransactionRequestByApprovedPayTransaction(
            approvedPayTransaction
          );
          await this.payVan(addTransactionRequest);
          return { tid, status, message };
      }
    },
    async sendPayResultToServer(payRequest) {
      try {
        return await GolfERPService.postPay(payRequest);
      } catch (e) {
        if (!(e instanceof BadRequestError)) {
          if (payRequest.payDivision === "CARD") {
            const approvedPayTransaction = await GolfERPService.getPayTransaction(
              payRequest.tid
            );

            // 일반 승일일 경우만 결제 취소
            if (
              approvedPayTransaction.approvalDivision === "OK"
            ) {
              await this.errorToast(
                (approvedPayTransaction.message.trim() ? approvedPayTransaction.message.trim() : "서버 요청중 에러가 발생하였습니다.") +
                "<br/> 결제 취소를 진행합니다."
              );
              const addTransactionRequest = this.getCancelTransactionRequestByApprovedPayTransaction(
                approvedPayTransaction
              );
              await this.payVan(addTransactionRequest);
            }
          }


          if (payRequest.payDivision === "EASY_PAY") {
            const approvedPayTransaction = await GolfERPService.getPayTransaction(
                payRequest.tid
            );

            await this.errorToast(
                (approvedPayTransaction.message.trim() ? approvedPayTransaction.message.trim() : "서버 요청중 에러가 발생하였습니다.") +
                "<br/> 결제 취소를 진행합니다."
            );
            const addTransactionRequest = this.getCancelTransactionRequestByApprovedPayTransaction(
                approvedPayTransaction
            );

            await this.payVan(addTransactionRequest);

          }
        } else {
          throw e;
        }
      }
    },
    approvalFlagChanged(args) {
      if (!args.target.checked) {
        // 초기화
        this.resetCardPaymentParams();
      } else {
        this.cardPaymentParams.validTerm = "00/00";
      }
    },
    setPayAmount() {
      this.payAmount = this.notPaidAmount;

      this.$refs["input-pay-amount"].focus();
    }
  },
};
</script>

<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">년도</div>
            <ul class="content">
              <li class="item" style="width: 90px;">
                <input-number
                  ref="year"
                  v-model="searchOptions.year"
                  :allowDot="false"
                  :allowMinus="false"
                  :displayComma="false"
                  :propMaxLength="4"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              v-on:click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true"/>
      <article class="body-article">
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">월별 회원 종류별 매출현황</div>
              <div v-show="title.year" class="header-caption">[영업년도 : {{title.year}}년도]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      @click.native="print"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      >인쇄
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="bodyGrid"
                v-bind="bodyGridProps"
                :dataSource="monthlySalesStatusByMemberTypes"
                :aggregates="bodyGridAggregates"
                @headerCellInfo="onBodyGridHeaderCellInfo"
                @queryCellInfo="onBodyGridQueryCellInfo"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import ErpButton from "@/components/button/ErpButton.vue";
import InputNumber from "@/components/common/InputNumber";
import ReportView from '@/components/common/report/ReportView';
import {
  Aggregate,
  Resize,
  ForeignKey,
  ExcelExport,
  Page,
  Edit,
  Group,
  Filter,
} from "@syncfusion/ej2-vue-grids";

import {
  getEndOfMonth, getFormattedDate,
  getTodayNavigationDate,
} from "@/utils/date";
import {mapGetters} from "vuex";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {commonCodesGetCommonCodeByIdx} from "@/utils/commonCodes";
import { groupBy as _groupBy } from "lodash";

export default {
  name: "MonthlySalesStatusByMemberType",
  components: {
    EjsGridWrapper,
    InputNumber,
    ReportView,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      commonCodeFields: {text: 'comName', value: 'comCode'},
      searchOptions: {
        year: null,
      },
      title: {
        year: null,
      },
      monthlySalesStatusByMemberTypes: [],
      reportSearchOptions: {}
    };
  },
  async created() {
    this.searchOptions.year = await getTodayNavigationDate("YYYY");
  },
  destroyed() {},
  computed: {
    ...mapGetters(['username']),
    isPopupOpened() {
      return false;
    },
    bodyGridProps() {
      return {
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        selectionSettings: {
          type: "Single", mode: "Both", enableToggle: false
        },
        provides: [
          Aggregate,
          Edit,
          ForeignKey,
          Group,
          Resize,
          Filter,
          Page,
          ExcelExport,
        ],
        allowSorting: false,
        allowFiltering: false,
        allowGrouping: false,
        allowExcelExport: true,
        noColumnWidth: 34,
        columns: [
          {
            field: "title",
            headerText: "구분",
            type: "string",
            minWidth: 16,
            width: 128,
            textAlign: "center",
          },
          {
            field: "name",
            headerText: "구분",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "center",
          },
          {
            field: "data01",
            headerText: "1월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data02",
            headerText: "2월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data03",
            headerText: "3월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data04",
            headerText: "4월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data05",
            headerText: "5월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data06",
            headerText: "6월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "firstHalf",
            headerText: "상반기",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 100,
            textAlign: "right",
          },
          {
            field: "data07",
            headerText: "7월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data08",
            headerText: "8월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data09",
            headerText: "9월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data10",
            headerText: "10월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data11",
            headerText: "11월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data12",
            headerText: "12월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "secondHalf",
            headerText: "하반기",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 100,
            textAlign: "right",
          },
          {
            field: "total",
            headerText: "합계",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 110,
            textAlign: "right",
          },
        ],
      };
    },
    bodyGridAggregates() {
      return [];
    },
  },
  mounted() {},
  methods: {
    async onViewButtonClicked() {
      const fromDate = this.searchOptions.year + "-01-01";
      const monthLastDay = await getEndOfMonth(Number(this.searchOptions.year), 12);
      const toDate = this.searchOptions.year + "-12-" + monthLastDay;

      const {
        tgClosePaymts
      } = await GolfErpAPI.fetchMonthlySalesStatusByMemberType({
        fromDate: fromDate,
        toDate: toDate,
      });

      let memberGradeIdx1 = [];
      commonCodesGetCommonCodeByIdx("MEMBER_GRADE", 1).map(item => {
        item.comCodeAttrbList.map(attrb => {
          memberGradeIdx1.push(attrb);
        });
      });
      let memberGradeIdx1AttrbGroupBy = [];
      for (let [key, value] of Object.entries(_groupBy(memberGradeIdx1, "attrb"))) {
        memberGradeIdx1AttrbGroupBy.push({attrb: key, attrbName: value[0]?.attrbName, isTotal: false});
      }
      memberGradeIdx1AttrbGroupBy.push({attrb: "TOTAL", attrbName: "합계", isTotal: true});

      const months = ["01","02","03","04","05","06","07","08","09","10","11","12"];
      const loopDatas = [
        {code: "VISIT_PER", name: "내장객수"},
        {code: "SALES_FEE_CART_RENT", name: "매출(입장료+카트+대여료)"},
        {code: "UNIT_PRICE", name: "객단가"}
      ];

      let monthlySalesStatusByMemberTypes = [];

      loopDatas.forEach(loopData => {
        memberGradeIdx1AttrbGroupBy.forEach((attrb, idx) => {
          let obj = {
            title: loopData.name,
            name: attrb.attrbName,
            isTotal: attrb.isTotal,
            isRowSpan: idx === 0,
            rowSpan: idx === 0 ? memberGradeIdx1AttrbGroupBy.length - 1 : 0,
          };
          months.forEach(month => {
            if (loopData.code !== "UNIT_PRICE") {
              obj["data".concat(month)] =
                tgClosePaymts
                  .filter(item =>
                    getFormattedDate(item.bsnDate, "MM") === month &&
                    (attrb.isTotal ? true : memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === attrb.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv)))
                  .map(item =>
                    loopData.code === "VISIT_PER" ?
                      item.visitTotPer :
                      loopData.code === "SALES_FEE_CART_RENT" ?
                        (item.totAmt + item.cartTotAmt + item.rentTotAmt) :
                        0)
                  .reduce((acc, cur) => acc + cur, 0);
            } else {
              const visitPer =
                tgClosePaymts
                  .filter(item =>
                    getFormattedDate(item.bsnDate, "MM") === month &&
                    (attrb.isTotal ? true : memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === attrb.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv)))
                  .map(item => item.visitTotPer)
                  .reduce((acc, cur) => acc + cur, 0);
              const salesTotal =
                tgClosePaymts
                  .filter(item =>
                    getFormattedDate(item.bsnDate, "MM") === month &&
                    (attrb.isTotal ? true : memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === attrb.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv)))
                  .map(item => item.totAmt + item.cartTotAmt + item.rentTotAmt)
                  .reduce((acc, cur) => acc + cur, 0);

              obj["data".concat(month)] = Math.round(visitPer > 0 ? salesTotal / visitPer : 0);
            }
          });

          if (loopData.code !== "UNIT_PRICE") {
            obj.firstHalf =
              tgClosePaymts
                .filter(item =>
                  ["01","02","03","04","05","06"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                  (attrb.isTotal ? true : memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === attrb.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv)))
                .map(item =>
                  loopData.code === "VISIT_PER" ?
                    item.visitTotPer :
                    loopData.code === "SALES_FEE_CART_RENT" ?
                      (item.totAmt + item.cartTotAmt + item.rentTotAmt) :
                      0)
                .reduce((acc, cur) => acc + cur, 0);
            obj.secondHalf =
              tgClosePaymts
                .filter(item =>
                  ["07","08","09","10","11","12"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                  (attrb.isTotal ? true : memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === attrb.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv)))
                .map(item =>
                  loopData.code === "VISIT_PER" ?
                    item.visitTotPer :
                    loopData.code === "SALES_FEE_CART_RENT" ?
                      (item.totAmt + item.cartTotAmt + item.rentTotAmt) :
                      0)
                .reduce((acc, cur) => acc + cur, 0);
            obj.total =
              tgClosePaymts
                .filter(item =>
                  (attrb.isTotal ? true : memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === attrb.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv)))
                .map(item =>
                  loopData.code === "VISIT_PER" ?
                    item.visitTotPer :
                    loopData.code === "SALES_FEE_CART_RENT" ?
                      (item.totAmt + item.cartTotAmt + item.rentTotAmt) :
                      0)
                .reduce((acc, cur) => acc + cur, 0);
          } else {
            const visitPerByFirstHalf =
              tgClosePaymts
                .filter(item =>
                  ["01","02","03","04","05","06"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                  (attrb.isTotal ? true : memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === attrb.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv)))
                .map(item => item.visitTotPer)
                .reduce((acc, cur) => acc + cur, 0);
            const salesTotalByFirstHalf =
              tgClosePaymts
                .filter(item =>
                  ["01","02","03","04","05","06"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                  (attrb.isTotal ? true : memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === attrb.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv)))
                .map(item => item.totAmt + item.cartTotAmt + item.rentTotAmt)
                .reduce((acc, cur) => acc + cur, 0);

            obj.firstHalf = Math.round(visitPerByFirstHalf > 0 ? salesTotalByFirstHalf / visitPerByFirstHalf : 0);

            const visitPerBySecondHalf =
              tgClosePaymts
                .filter(item =>
                  ["07","08","09","10","11","12"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                  (attrb.isTotal ? true : memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === attrb.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv)))
                .map(item => item.visitTotPer)
                .reduce((acc, cur) => acc + cur, 0);
            const salesTotalBySecondHalf =
              tgClosePaymts
                .filter(item =>
                  ["07","08","09","10","11","12"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                  (attrb.isTotal ? true : memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === attrb.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv)))
                .map(item => item.totAmt + item.cartTotAmt + item.rentTotAmt)
                .reduce((acc, cur) => acc + cur, 0);

            obj.secondHalf = Math.round(visitPerBySecondHalf > 0 ? salesTotalBySecondHalf / visitPerBySecondHalf : 0);

            const visitPerByTotal =
              tgClosePaymts
                .filter(item =>
                  (attrb.isTotal ? true : memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === attrb.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv)))
                .map(item => item.visitTotPer)
                .reduce((acc, cur) => acc + cur, 0);
            const salesTotalByTotal =
              tgClosePaymts
                .filter(item =>
                  (attrb.isTotal ? true : memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === attrb.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv)))
                .map(item => item.totAmt + item.cartTotAmt + item.rentTotAmt)
                .reduce((acc, cur) => acc + cur, 0);

            obj.total = Math.round(visitPerByTotal > 0 ? salesTotalByTotal / visitPerByTotal : 0);
          }
          monthlySalesStatusByMemberTypes.push(obj);
        });
      });

      console.log('monthlySalesStatusByMemberTypes.===>', monthlySalesStatusByMemberTypes);
      this.monthlySalesStatusByMemberTypes = monthlySalesStatusByMemberTypes;
      this.reportSearchOptions = JSON.parse(JSON.stringify(this.searchOptions));
    },
    print() {
      if (this.monthlySalesStatusByMemberTypes.length < 1) {
        return this.errorToast(this.$t("report.popupMessage.noData"));
      }

      const reportData = {
        reportJson: {
          jsonData: {
            monthlySalesStatusByMemberTypes: this.monthlySalesStatusByMemberTypes,
          },
          username: this.username,
          uniqueCode: this.$options.name,
          searchOptions:
            "▣ 영업년도 : " + this.reportSearchOptions.year + "년",
        },
      };
      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    onClickExcel() {
      this.$refs.bodyGrid.excelExport();
    },
    onBodyGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field
          }
        }
      } = args;

      if (field === "title") {
        args.node.colSpan = 2;
      } else if (field === "name") {
        args.cell.isSpanned = true;
      }
    },
    onBodyGridQueryCellInfo(args) {
      const {
        cell,
        column: {
          field,
        },
        data,
      } = args;

      if (data[field] === 0) {
        cell.innerText = "-";
      }

      if (field === "title") {
        if (data.isRowSpan) {
          args.rowSpan = data.rowSpan;

          if (data.title.indexOf("(") >= 0) {
            const title = "<div style='height: 24px;'>" + data.title.substring(0, data.title.indexOf("(")).concat("</div><div>").concat(data.title.substring(data.title.indexOf("("))) + "</div>";
            cell.innerHTML = title;
          }
        }

        if (data.isTotal) {
          args.colSpan = 2;
          cell.innerText = "합계";
        }
      }

      if (data.isTotal) {
        cell.style.backgroundColor = "#ececec";
      }

      if (
        field &&
        (field.startsWith("firstHalf") ||
          field.startsWith("secondHalf") ||
          field.endsWith("total") ||
          field.endsWith("Total"))
      ) {
        cell.style.backgroundColor = "#fef2cb";
      }
    },
  }
};
</script>

<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="dialog"
      v-bind="dialogProps"
      @close="onDialogClose"
    >
      <div
        class="typePos"
        :style="{
          minWidth: `${dialogProps.width}px`,
          minHeight: `${dialogProps.height - 66}px`,
        }"
      >
        <div class="typePos-wrapper-caption">
          <div class="typePos-header">
            <div class="typePos-filed typePos-date">
              <div class="typePos-filed-title">영업일자</div>
              <div class="typePos-filed-content">
                <div class="typePos-item">
                  <input-date-unusual
                    :value="readOnlyStringSalesDate"
                    :required="true"
                    :notClear="true"
                    :isGetDateInfo="true"
                    :disabled="true"
                  />
                </div>
                <div class="typePos-item">
                  <div class="typePos-content">
                    (<span
                      :style="{
                        color: dayOfWeekCaptionColor,
                      }"
                      >{{ dayOfWeekCaption }}</span
                    >) (<span
                      :style="{
                        color: weekdayWeekendCaptionColor,
                      }"
                      >{{ weekdayWeekendCaption }}</span
                    >)
                  </div>
                </div>
              </div>
            </div>
            <div class="typePos-filed typePos-store">
              <div class="typePos-filed-title">매장</div>
              <div class="typePos-filed-content">
                <div class="typePos-item">
                  <f-select
                    class="ui-select"
                    :items="storeCodes"
                    :value="store.code"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="typePos-filed typePos-slipNumber">
              <div class="typePos-filed-title">전표번호</div>
              <div class="typePos-filed-content">
                <div class="typePos-item">
                  <div class="ui-input">
                    <!-- 비활성(Attribute) : disabled -->
                    <input
                      ref="input-slip-number"
                      type="text"
                      placeholder="입력하세요."
                      v-model="slipNumber"
                      @keypress.enter="onKeypressEnter"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="typePos-filed typePos-tableNumber">
              <div class="typePos-filed-title">테이블번호</div>
              <div class="typePos-filed-content">
                <div class="typePos-item">
                  <div class="ui-input">
                    <!-- 비활성(Attribute) : disabled -->
                    <input
                      type="text"
                      placeholder="입력하세요."
                      v-model="tableName"
                      @keypress.enter="onKeypressEnter"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="typePos-filed typePos-tableNumber">
              <div class="typePos-filed-content">
                <div class="typePos-item">
                  <div class="typePos-check">
                    <ul class="ui-check">
                      <li>
                        <label>
                          <!-- 비활성(Attribute) : disabled -->
                          <input type="checkbox" v-model="isDeletedSlip"/>
                          <div class="i-item">삭제전표제외</div>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="typePos-lookup">
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="GET"
                class="ui-button i-typePrimary"
                :ignore="isPopupOpened"
                :use-syncfusion-component-style="false"
                :is-shortcut-button="true"
                :visible-shortcut-string="false"
                @click.native="onSearchClick"
              >
                <div class="i-item">
                  조회 ({{ searchShortcutButtonShortcutString }})
                </div>
              </erp-button>
            </div>
          </div>
        </div>
        <div class="typePos-wrapper-main">
          <div class="typePos-main-header">
            <div class="typePos-header">
              <div class="typePos-title">전표 목록</div>
              <div class="typePos-caption">
                [<strong>{{ getSlipsCount }}</strong> 건]
              </div>
            </div>
          </div>
          <div class="typePos-main-body">
            <ejs-grid-wrapper
              ref="slipsGrid"
              v-bind="slipsGridProps"
              @queryCellInfo="onSalesSlipsGridQueryCellInfo"
              @rowSelected="onSalesSlipsGridRowSelected"
              @onGridDialogDoubleClickedOrEnterKeyed="
                onSalesSlipsGridDoubleClick
              "
              @dataBound="onSalesSlipsGridDataBound"
            />
          </div>
        </div>
        <!-- 열기 : typePos-open -->
        <div
          class="typePos-wrapper-aside"
          :class="{ 'typePos-open': visiblePaymentsLayer }"
        >
          <div
            class="typePos-aside-header"
            @click="visiblePaymentsLayer = !visiblePaymentsLayer"
          >
            <div class="typePos-header">
              <div class="typePos-title">정산 내역</div>
              <div class="typePos-accordion">열기 / 닫기</div>
            </div>
          </div>
          <div class="typePos-aside-body">
            <ejs-grid-wrapper
              ref="paymentsGrid"
              v-bind="paymentsGridProps"
              @queryCellInfo="onPaymentsGridQueryCellInfo"
            />
          </div>
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="GET"
                class="ui-button i-typePrimary"
                :use-syncfusion-component-style="false"
                @click="onSubmitClick"
              >
                <div class="i-item">전표선택</div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click="onCloseClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <!-- 정산취소 팝업 -->
    <cancel-payment-popup
      ref="cancel-payment-popup"
      v-if="visibleCancelPaymentPopup"
      @close="onCancelPaymentPopupClose"
    />
    <!-- 영수증 재발행 팝업 -->
    <re-issue-receipt-popup
      ref="re-issue-receipt-popup"
      v-if="visibleReIssueReceiptPopup"
      @close="onReIssueReceiptPopupClose"
    />
    <deposit-use-receipt-popup
      ref="deposit-use-receipt-popup"
      v-if="visibleDepositUseReceiptPopup"
      @close="onDepositUseReceiptPopupClose"
    />
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-caption {padding: 10px; border-bottom: 1px solid #bebebe;}
.typePos .typePos-wrapper-caption::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed,
.typePos .typePos-wrapper-caption .typePos-lookup {float: left;}
.typePos .typePos-wrapper-caption .typePos-filed {padding: 0 3px;}
.typePos .typePos-wrapper-caption .typePos-lookup {padding: 0 10px;}
.typePos .typePos-wrapper-caption .typePos-filed::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-title,
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content {float: left; padding: 0 7px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-title {padding-top: 10px; padding-bottom: 11px; color: #222; font-family: 'NanumSquare-Bold';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item {float: left; padding-left: 10px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item:first-child {padding-left: 0;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-title,
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content {float: left; padding-top: 10px; padding-bottom: 11px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-title {padding-right: 4px; color: #222; font-family: 'NanumSquare-Bold';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content strong {color: #18b3c9; font-weight: normal;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content em {color: #df2929; font-style: normal;}
.typePos .typePos-wrapper-caption .typePos-lookup >>> .ui-button .i-item::before {background-position-x: calc(-20px * 7);}
.typePos .typePos-wrapper-caption .typePos-header {float: left;}
.typePos .typePos-wrapper-caption .typePos-header::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-date .typePos-filed-content .typePos-item .ejs-date {width: 160px;}
.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-store .typePos-filed-content .typePos-item .ui-select {width: 145px;}
.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-slipNumber .typePos-filed-content .typePos-item .ui-input {width: 100px;}
.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-tableNumber .typePos-filed-content .typePos-item .ui-input {width: 100px;}
.typePos .typePos-wrapper-caption .typePos-footer {float: right;}
.typePos .typePos-wrapper-caption .typePos-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}

.typePos .typePos-wrapper-main {display: flex; flex-direction: column; flex: 1; overflow: hidden;}
.typePos .typePos-wrapper-main .typePos-main-header {padding: 10px 20px 2px 20px;}
.typePos .typePos-wrapper-main .typePos-main-header::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header {position: relative; float: left;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header .typePos-title {float: left; padding: 6px 0 7px 0; color: #222; font-size: 18px; font-family: 'NanumSquare-Regular'; line-height: 27px;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header .typePos-caption {float: left; padding: 12px 0 9px 10px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header .typePos-caption strong {color: #18b3c9; font-weight: normal;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-footer {float: right;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-body {flex: 1; overflow: hidden; border-top: 1px solid #e0e0e0;}

.typePos .typePos-wrapper-aside {border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-aside .typePos-aside-header {padding: 10px 20px 2px 20px;}
.typePos .typePos-wrapper-aside .typePos-aside-header::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-aside .typePos-aside-header .typePos-header {position: relative; float: left; padding-right: 19px; cursor: pointer;}
.typePos .typePos-wrapper-aside .typePos-aside-header .typePos-header::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-aside .typePos-aside-header .typePos-header .typePos-title {float: left; padding: 6px 0 7px 0; color: #222; font-size: 18px; font-family: 'NanumSquare-Regular'; line-height: 27px;}
.typePos .typePos-wrapper-aside .typePos-aside-header .typePos-header .typePos-caption {float: left; padding: 12px 0 9px 10px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-aside .typePos-aside-header .typePos-header .typePos-caption strong {color: #18b3c9; font-weight: normal;}
.typePos .typePos-wrapper-aside .typePos-aside-header .typePos-header .typePos-accordion {overflow: hidden; position: absolute; top: 50%; right: 0; width: 9px; height: 5px; margin-top: -4px; background: transparent url('../../../../../assets/images/ui/arrow-type08.png') no-repeat left top; text-indent: -1000px;}
.typePos .typePos-wrapper-aside .typePos-aside-header .typePos-header:hover .typePos-accordion {background-position-y: bottom;}
.typePos .typePos-wrapper-aside .typePos-aside-header .typePos-footer {float: right;}
.typePos .typePos-wrapper-aside .typePos-aside-header .typePos-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-aside .typePos-aside-body {display: none; height: 155px; border-top: 1px solid #e0e0e0;}
.typePos .typePos-wrapper-aside.typePos-open .typePos-aside-header .typePos-header .typePos-accordion {background-position-x: right;}
.typePos .typePos-wrapper-aside.typePos-open .typePos-aside-body {display: block;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
</style>

<script>
import { orderBy as _orderBy } from "lodash";
import InputDateUnusual from "@/components/common/datetime/InputDateUnusual";
import {
  commonCodesGetColorValue,
  commonCodesGetCommonCode,
  commonCodesGetComName,
} from "@/utils/commonCodes";
import {
  DATE_FORMAT_YYYY_MM_DD,
  getDayOfWeekCaption,
  getDayOfWeekCaptionColor,
} from "@/utils/date";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import moment from "moment";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { Aggregate, ForeignKey, Resize } from "@syncfusion/ej2-vue-grids";
import GolfERPService from "@/service/GolfERPService";
import EjsGridDropdownButtonColumn from "@/components/common/EjsGridDropdownButtonColumn";
import CancelPaymentPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/CancelPaymentPopup";
import ReIssueReceiptPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/ReIssueReceiptPopup";
import DepositUseReceiptPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/DepositUseReceiptPopup";
import Vue from "vue";
import { numberWithCommas } from "@/utils/number";
import { sumBy as _sumBy, maxBy as _maxBy } from "lodash";
import FSelect from "@/views/store-management/sales-registration/store-pos-renew/components/FSelect";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {
  getSearchButtonShortcut,
  toShortcutString,
} from "@/utils/KeyboardUtil";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "PaymentListPopup",
  components: {
    FSelect,
    ReIssueReceiptPopup,
    CancelPaymentPopup,
    EjsGridWrapper,
    InputDateUnusual,
    DepositUseReceiptPopup,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  props: {
    salesDate: {
      type: Date,
      required: true,
    },
    store: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.fetchCalenderInfo({ salesDate: this.salesDate });
  },
  watch: {
    selectSlip(selectSlip) {
      if (selectSlip) {
        this.fetchSalesSlipPayments();
      }
    },
  },
  data() {
    return {
      slips: [],
      payments: [],
      selectSlip: {},

      calendarInfo: {
        bsnCode: null,
        dwCode: null,
      },

      slipNumber: "",
      tableName: "",

      isDeletedSlip: true, // 삭제전표제외
      isFilteredDeleteStatus: false,

      visiblePaymentsLayer: true,
      visibleCancelPaymentPopup: false,
      visibleReIssueReceiptPopup: false,
      visibleDepositUseReceiptPopup: false,

      isReload: false
    };
  },
  computed: {
    isPopupOpened() {
      return (
        this.visibleCancelPaymentPopup ||
        this.visibleReIssueReceiptPopup ||
        this.visibleDepositUseReceiptPopup
      );
    },
    searchShortcutButtonShortcutString() {
      return toShortcutString(getSearchButtonShortcut());
    },
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: "정산내역",
        width: 1160,
        height: 760,
        allowDragging: true,
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
    readOnlyStringSalesDate() {
      return moment(this.salesDate).format(DATE_FORMAT_YYYY_MM_DD);
    },
    dayOfWeekCaption() {
      return getDayOfWeekCaption(this.salesDate);
    },
    dayOfWeekCaptionColor() {
      const { bsnCode, dwCode } = this.calendarInfo;

      return getDayOfWeekCaptionColor(null, bsnCode, dwCode);
    },
    weekdayWeekendCaption() {
      const { bsnCode } = this.calendarInfo;

      return commonCodesGetComName("BSN_CODE", bsnCode);
    },
    weekdayWeekendCaptionColor() {
      const { bsnCode } = this.calendarInfo;

      return commonCodesGetColorValue("BSN_CODE", bsnCode);
    },
    storeCodes() {
      return commonCodesGetCommonCode("STORE_CODE").map((commonCode) => ({
        ...commonCode,
        name: commonCode.comName,
        value: commonCode.comCode,
      }));
    },
    slipsGridDataSource() {
      return this.slips.map(
        (
          {
            id,
            number,
            storeTableUses,
            sales,
            payments,
            isPaid,
            payPreOrFutureDivision,
            visitor,
            isDeleted,
            deleteReason,
          },
          index
        ) => {
          const totalSalesAmount =
            sales?.reduce((acc, { totalAmount = 0 }) => acc + totalAmount, 0) ||
            0;
          const totalPaymentsAmount =
            payments?.reduce((acc, { amount = 0 }) => acc + amount, 0) || 0;

          return {
            _rid: index + 1,
            id,
            number,
            storeTableName:
              _maxBy(storeTableUses || [], "insertDateTime")?.storeTable
                ?.name || "N/A",
            groupProductName:
              !sales || sales.length === 0
                ? ""
                : sales.length === 1
                ? `${sales[0].productName}`
                : `${sales[0].productName} 외 ${sales.length - 1}건`,
            totalSalesAmount,
            paid: isPaid,
            totalPaymentsAmount,
            totalRemainAmount: totalSalesAmount - totalPaymentsAmount,
            payPreOrFutureDivision,
            visitorName: visitor?.name || "일반고객",
            visitorLockerNumber: visitor?.lockerNumber || "000",
            groupName: visitor?.team?.groupName || "",
            checkOut: visitor?.team?.isCheckOut,
            checkOutDateTime: moment(visitor?.team?.checkOutDateTime).format("HH:mm"),
            deleted: isDeleted,
            reasonDeleted: deleteReason,
          };
        }
      );
    },
    slipsGridProps() {
      return {
        aggregates: [
          {
            columns: [
              {
                field: "number",
                type: "Custom",
                footerTemplate() {
                  return {
                    template: Vue.component("footerTemplate", {
                      template: `<div style="text-align: center">합계</div>`,
                    }),
                  };
                },
              },
              {
                field: "totalSalesAmount",
                type: "Custom",
                customAggregate: (data) => {
                  return _sumBy(
                    data.result,
                    ({ totalSalesAmount = 0, deleted = false }) =>
                      deleted ? 0 : totalSalesAmount
                  );
                },
                footerTemplate() {
                  return {
                    template: Vue.component("footerTemplate", {
                      template: `<div style="text-align: right">{{ data.Custom | numberWithCommas }}</div>`,
                      filters: {
                        numberWithCommas,
                      },
                      data() {
                        return {
                          data: {},
                        };
                      },
                    }),
                  };
                },
              },
            ],
          },
        ],
        allowFiltering: true,
        allowResizing: true,
        allowSelection: true,
        allowSorting: true,
        columns: [
          {
            field: "id",
            visible: false,
            isPrimaryKey: true,
          },
          {
            field: "number",
            headerText: "전표번호",
            textAlign: "Center",
            width: 117,
          },
          {
            field: "storeTableName",
            headerText: "테이블",
            textAlign: "Center",
            width: 105,
          },
          {
            field: "groupProductName",
            headerText: "상품명",
            textAlign: "Left",
            width: 155,
          },
          {
            allowEditing: false,
            headerText: "",
            width: 50,
            template: () => {
              const onSlipsGridButtonClicked = this.onSlipsGridButtonClicked;
              return {
                template: {
                  components: { ErpButton },
                  template:
                    "<erp-button class='ui-button' :use-syncfusion-component-style='false' @click='onButtonClicked'/>",
                  data() {
                    return {
                      data: {}
                    };
                  },
                  methods: {
                    onButtonClicked(args) {
                      onSlipsGridButtonClicked({
                        args,
                        data: this.data
                      });
                    }
                  }
                }
              };
            }
          },
          {
            displayAsCheckBox: true,
            editType: "booleanedit",
            field: "paid",
            headerText: "정산",
            textAlign: "Center",
            type: "boolean",
            width: 84,
          },
          {
            field: "totalSalesAmount",
            headerText: "매출금액",
            textAlign: "Right",
            type: "string",
            isNumericType: true,
            width: 108,
          },
          {
            field: "totalPaymentsAmount",
            headerText: "정산금액",
            textAlign: "Right",
            type: "string",
            isNumericType: true,
            width: 108,
          },
          {
            field: "totalRemainAmount",
            headerText: "미정산금액",
            textAlign: "Right",
            type: "string",
            isNumericType: true,
            width: 108,
          },
          {
            allowEditing: true,
            groupCode: "PRPAY_FRPY_DIV",
            editType: "dropdownedit",
            field: "payPreOrFutureDivision",
            isCommonCodeField: true,
            headerText: "선/후불",
            textAlign: "Center",
            width: 120,
          },
          {
            field: "visitorName",
            headerText: "내장객",
            textAlign: "Left",
            width: 115,
          },
          {
            field: "visitorLockerNumber",
            headerText: "락카",
            textAlign: "Center",
            width: 104,
          },
          {
            field: "groupName",
            headerText: "단체명",
            textAlign: "Center",
            width: 115,
          },
          {
            displayAsCheckBox: true,
            editType: "booleanedit",
            field: "deleted",
            headerText: "삭제",
            textAlign: "Center",
            type: "boolean",
            width: 104,
          },
          {
            field: "reasonDeleted",
            headerText: "삭제사유",
            textAlign: "Left",
            width: 127,
          },
        ],
        dataSource: this.isDeletedSlip
          ? this.slipsGridDataSource
              ?.filter(item => item.deleted !== this.isDeletedSlip)
          : this.slipsGridDataSource,
        isInPopup: true,
        provides: [Aggregate, ForeignKey, Resize],
        selectionSettings: { type: "Single", enableToggle: false },
        useCommonStyles: false,
      };
    },
    paymentsGridProps() {
      const dataSource = _orderBy(
        this.payments.map(
          (
            {
              id,
              slipId,
              division,
              method,
              cardName,
              deleted,
              cashApprovalNumber,
              cardApprovalNumber,
              cashApprovalDivision,
              cardApprovalDivision,
              cashSalesDivision,
              cardSalesDivision,
              cashReceiptNumber,
              cardNumber,
              amount,
              insertUser,
              insertDate,
              cardId,
              cashId,
              depositUseId,
            },
            index
          ) => ({
            _rid: index + 1,
            id,
            slipId,
            division,
            method,
            cardName,
            cashSalesDivision,
            cardSalesDivision,
            deleted,
            deleteStatus: deleted ? "취소" : "정상",
            approvalStatus:
              method === "CARD" || method === "EASY_PAY"
                ? commonCodesGetComName("APPROVAL_DIV", cardApprovalDivision)
                : method === "CASH" || method === "TRANS"
                ? cashId
                  ? commonCodesGetComName("APPROVAL_DIV", cashApprovalDivision)
                  : deleted
                  ? "승인취소"
                  : "정상승인"
                : deleted
                ? "승인취소"
                : "정상승인",
            approvalNumber:
              method === "CARD" || method === "EASY_PAY" ? cardApprovalNumber : cashApprovalNumber,
            receiptNumber: ["CARD", "EASY_PAY", "PRECARD", "GIFT", "GROUP_GIFT"].includes(method) ? cardNumber : cashReceiptNumber,
            amount,
            insertUserName: insertUser ? insertUser.name : "",
            date: insertDate,
            cardId,
            cashId,
            depositUseId,
          })
        ),
        ["date"],
        "desc"
      );

      return {
        allowFiltering: true,
        allowResizing: true,
        allowSelection: true,
        allowSorting: true,
        columns: [
          {
            field: "id",
            visible: false,
            isPrimaryKey: true,
          },
          {
            field: "slipId",
            visible: false,
          },
          {
            field: "cashSalesDivision",
            visible: false,
          },
          {
            field: "cardSalesDivision",
            visible: false,
          },
          {
            field: "deleted",
            visible: false,
          },
          {
            groupCode: "PRPAY_FRPY_DIV",
            field: "division",
            isCommonCodeField: true,
            headerText: "선/후불",
            textAlign: "Center",
            type: "String",
            width: 120,
          },
          {
            groupCode: "PAY_DIV",
            field: "method",
            isCommonCodeField: true,
            headerText: "정산구분",
            textAlign: "Center",
            type: "String",
            width: 127,
          },
          {
            field: "deleteStatus",
            headerText: "상태",
            textAlign: "Center",
            type: "String",
            width: 127,
          },
          {
            field: "cardName",
            headerText: "카드사",
            textAlign: "Left",
            type: "String",
            width: 115,
          },
          {
            field: "approvalStatus",
            headerText: "승인상태",
            textAlign: "Center",
            type: "String",
            width: 127,
          },
          {
            field: "approvalNumber",
            headerText: "승인번호",
            textAlign: "Center",
            type: "String",
            width: 127,
          },
          {
            field: "receiptNumber",
            headerText: "카드번호",
            textAlign: "Center",
            type: "String",
            width: 127,
          },
          {
            field: "amount",
            headerText: "정산금액",
            textAlign: "Right",
            type: "string",
            isNumericType: true,
            width: 128,
          },
          {
            allowEditing: false,
            headerText: "",
            textAlign: "Left",
            width: 78,
            template: () => {
              const onReportRecordDropdownButtonSelect = this
                .onReportRecordDropdownButtonSelect;
              return {
                template: {
                  components: { EjsGridDropdownButtonColumn },
                  template:
                    "<ejs-grid-dropdown-button-column :dropdownButtonItems='dropdownButtonItems' @select='onDropdownButtonSelect'/>",
                  data() {
                    return {
                      data: {},
                    };
                  },
                  computed: {
                    dropdownButtonItems() {
                      return this.data.deleted
                        ? [
                          {
                            text: "영수증 재발행",
                            value: "republic",
                          },
                        ]
                        : [
                          {
                            text: "정산취소",
                            value: "cancel",
                          },
                          {
                            text: "영수증 재발행",
                            value: "republic",
                          },
                        ];
                    },
                  },
                  methods: {
                    onDropdownButtonSelect(args) {
                      onReportRecordDropdownButtonSelect({
                        args,
                        data: this.data,
                      });
                    },
                  },
                },
              };
            },
          },
          {
            field: "insertUserName",
            headerText: "정산자",
            textAlign: "Center",
            type: "String",
            width: 115,
          },
          {
            field: "date",
            headerText: "정산일시",
            textAlign: "Center",
            type: "String",
            width: 127,
          },
        ],
        dataSource,
        isInPopup: true,
        provides: [ForeignKey, Resize],
        selectionSettings: { type: "Single", enableToggle: false },
        useCommonStyles: false,
      };
    },
    getSlipsCount() {
      return this.isDeletedSlip
          ? this.slips.filter(item => item.isDeleted !== this.isDeletedSlip).length
          : this.slips.length;
    },
  },
  methods: {
    show(args) {
      this.$refs["dialog"].show();
      if (args) {
        this.tableName = args.tableName;
      }
      this.onSearchClick();
    },
    hide() {
      this.$refs["dialog"].hide();
    },

    async showCancelPaymentPopup(data) {
      const slip = this.slips.find(({ id }) => id === data.slipId);
      const payment = this.payments.find(({ id }) => id === data.id);
      const visitor = slip.visitor;

      const popupData = {
        salesDiv: "SALES",
        chkinId: visitor?.team?.id,
        visitId: visitor?.id,
        payId: payment.id,
        payDate: moment(this.salesDate).format(DATE_FORMAT_YYYY_MM_DD),
        cardId: payment.cardId,
        cashId: payment.cashApprovalDivision !== "CANCEL" ? payment.cashId : null,
        useId: payment.depositUseId, // ??depositUseId
        payDiv: payment.method,
        bsnDate: this.salesDate,
        payAmt: payment.amount,
        visitName: visitor?.name || "일반고객",
        teamGrpFlag: !!visitor?.team?.group,
        grpNo: visitor?.team?.group?.name,
        storeCode: this.store.code,
        isPos: true,
        isOnlyPayDataCancel:
          payment.cardApprovalDivision !== "OK" &&
          payment.cardApprovalDivision !== "T_OK"
            ? true
            : false,
        callback: () => {
          this.fetchSalesSlipPayments();
        },
      };

      this.visibleCancelPaymentPopup = true;
      this.$nextTick(() => {
        this.$refs["cancel-payment-popup"].showPayCancelPopup(popupData);
      });
    },

    onDialogClose() {
      this.$emit("close", {
        isReload: this.isReload
      });
    },
    onKeypressEnter() {
      this.onSearchClick();
    },
    onSearchClick() {
      this.fetchSlips();
    },
    onSalesSlipsGridQueryCellInfo(args) {
      const {
        cell,
        column: { field },
        data,
      } = args;

      if (field === "paid") {
        if (data[field]) {
          cell.style.backgroundColor = commonCodesGetColorValue(
            "PAY_FLAG",
            data[field] ? "1" : "0"
          );
        }
      } else if (field === "visitorName") {
        if (data.checkOut) {
          cell.style.backgroundColor = commonCodesGetColorValue(
              "CHKIN_STATUS",
              "CHKOUT"
          );
          cell.style.color = "#ffffff";
          cell.innerText = `${data.visitorName} (${data.checkOutDateTime})`;
        }
      }

      if (data["deleted"]) {
        cell.style.color = "red";
      }
    },
    onSalesSlipsGridRowSelected(args) {
      this.selectSlip = args.data;
      this.visiblePaymentsLayer = true;
    },
    onSalesSlipsGridDoubleClick() {
      this.onSubmitClick();
    },
    onSalesSlipsGridDataBound() {
      if (this.slips.length > 0) {
        this.$refs.slipsGrid.selectRow(0);
      } else {
        this.$refs["input-slip-number"].focus();
      }
    },
    onPaymentsGridQueryCellInfo(args) {
      const { cell, data } = args;

      if (!!data["deleted"] || data["deleteStatus"].endsWith("취소")) {
        cell.style.color = "red";
      }

      if (
        !!data["cashId"] &&
        args.column.field === "method" &&
        !data["approvalStatus"].endsWith("취소")
      ) {
        args.cell.classList.add(this.$t("className.grid.incomeDeduction"));
      }

      if (args.column.field === "approvalStatus") {
        if (data["approvalStatus"].endsWith("취소")) {
          cell.style.color = "red";
        }
      }
    },
    onSubmitClick() {
      this.$emit("submit", { slip: this.selectSlip });
      this.hide();
    },
    onCloseClick() {
      this.isReload = true;
      this.hide();
    },
    onCancelPaymentPopupClose(args) {
      this.visibleCancelPaymentPopup = false;

      if (args !== undefined && args.isReload) {
        this.fetchSalesSlipPayments();
      }
    },
    onReIssueReceiptPopupClose() {
      this.visibleReIssueReceiptPopup = false;
    },
    onDepositUseReceiptPopupClose() {
      this.visibleDepositUseReceiptPopup = false;
    },

    onReportRecordDropdownButtonSelect(event) {
      const {
        args: {
          item: { value: menu },
        },
        data,
      } = event;
      
      const {
        id,
        method,
        cardSalesDivision,
        cashSalesDivision,
        deleted,
        cardId,
        depositUseId,
        approvalStatus
      } = data;
      if (menu === "cancel") {
        if (deleted) {
          this.errorToast("이미 승인 취소된 건입니다");
          return;
        }

        this.showCancelPaymentPopup(event.data);
      } else if (menu === "republic") {
        if (method === "DEPOSIT" || method === "PRECARD" || (method === "GIFT" && depositUseId)) {
          this.visibleDepositUseReceiptPopup = true;
          this.$nextTick(() => {
            this.$refs["deposit-use-receipt-popup"].showDepositUseReceiptPopup(
              depositUseId
            );
          });
        } else {
          this.visibleReIssueReceiptPopup = true;
          this.$nextTick(() => {
            this.$refs["re-issue-receipt-popup"].show({
              paymentId: method === "CARD" || method === "EASY_PAY" ? cardId : id,
              paymentMethod: method === "EASY_PAY" ? "CARD" : method,
              salesDivision:
                method === "CASH" ? cashSalesDivision : cardSalesDivision,
              isPayCash: false,
              isCashCancel: method === "CASH" && !deleted && approvalStatus.endsWith("취소") ? true : false
            });
          });
        }
      }
    },

    async fetchCalenderInfo({ salesDate }) {
      if (!salesDate) {
        return;
      }

      const { data } = await GolfErpAPI.fetchCalenderInfo(
        moment(salesDate).format(DATE_FORMAT_YYYY_MM_DD),
        false
      );

      this.calendarInfo = data;
    },
    async fetchSlips() {
      const filters = [];
      if (this.slipNumber) {
        filters.push({
          field: "number",
          condition: "contains",
          values: [this.slipNumber],
        });
      }
      if (this.tableName) {
        filters.push({
          field: "storeTableUses.storeTable.name",
          condition: "contains",
          values: [this.tableName],
        });
      }
      this.slips =
        (await GolfERPService.fetchSlips(
          this.store.code,
          moment(this.salesDate).format(DATE_FORMAT_YYYY_MM_DD),
          this.store.golfCabinDivision,
          filters
        )) || [];

      this.selectSlip = null;
    },
    async fetchSalesSlipPayments() {
      this.payments = (
        (await GolfERPService.fetchSalesSlipPayments(this.selectSlip.id, this.store.golfCabinDivision)) || []
      ).map((payment) => ({ ...payment, slipId: this.selectSlip.id }));
    },
    onSlipsGridButtonClicked() {
      this.onSubmitClick();
    }
  },
};
</script>

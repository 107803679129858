<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field brandNameCord">
            <div class="title">상품명/코드</div>
            <ul class="content">
              <li class="item">
                <input-text
                  ref="searchProductCodeName"
                  v-model="searchConditions.productCodeName"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              v-on:click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              button-div="GET"
              :is-icon-custom="true"
              v-on:click.native="searchDetail"
          >
            상세검색
          </erp-button>
        </div>
        <!--
        <ul class="lookup-button">
          <li class="shortcut">
            <ejs-dropdownbutton
              ref='shortcutMenuDropdownButton'
              cssClass='lookup-button-dropdown'
              :items='shortcutMenuItems'>
              바로가기
            </ejs-dropdownbutton>
          </li>
          <li class="etc">
            <ejs-dropdownbutton
              ref='etcMenuDropdownButton'
              cssClass='lookup-button-dropdown'
              :items='etcMenuItems'>
              기타
            </ejs-dropdownbutton>
          </li>
        </ul>
        -->
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">상품 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button" style="margin-right: 140px">
                <li class="save keyColor">
                  <erp-button
                      button-div="SAVE"
                      :is-key-color="true"
                      :is-shortcut-button="true"
                      :ignore="isPopupOpened"
                      v-on:click.native="onProductSaveClicked"
                  >
                    저장
                  </erp-button>
                </li>
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="productCodeRegistration.shortcuts.addProductCode"
                      :shortcut="{key: 'F3'}"
                      v-on:click.native="onProductAddClicked"
                      :ignore="isPopupOpened"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="reset">
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      v-on:click.native="cancelProductGrid"
                  >
                    초기화
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
              <div class="header-switch">
                <div class="title">
                  멀티 편집모드
                </div>
                <div class="switch">
                  <ejs-switch
                    v-model="isModifyMode"
                    :checked="isModifyMode"
                    @change="onModifySwitchChanged"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="section-body">
            <ejs-grid-wrapper
              ref="productGrid"
              id="productGrid"
              :provides="gridProvides"
              :dataSource="productList"
              :columns="productGridColumns"
              :selectionSettings="productGridSelectionSettings"
              :editSettings="productGridEditSettings"
              :allowPaging="true"
              :allowExcelExport="true"
              :allowFiltering="!isModifyMode"
              :allowSorting="!isModifyMode"
              :isAutoSelectCell="!isModifyMode"
              :pageSettings="productGridPageSettings"
              :validationRules="productGridValidationRules"
              @headerCellInfo="onProductGridHeaderCellInfo"
              @queryCellInfo="productGridCustomiseCell"
              @recordClick="productGridRecordClicked"
              @actionComplete="productGridActionComplete"
              @cellSelected="productGridCellSelected"
            >
            </ejs-grid-wrapper>
          </div>
        </section>
      </article>
    </div>
    <ejs-dialog
      ref="searchDetailPopup"
      :header="`상세검색`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="387"
      :animationSettings="{ effect: 'None' }"
      :isModal="false"
      :visible="false"
      enableResize="true"
      v-show="isSearchDetailPopupOpen"
    >
      <div class="window lookupDetail-productCodeRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">대분류</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        ref="classCode01Dropdown"
                        v-model="searchConditions.classCode01"
                        :dataSource="searchConditions.classCode01Options"
                        :change="onClassCode01Changed"
                        :allowFiltering="false"
                        :fields="classCodeFields"
                        cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">중분류</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        ref="classCode02Dropdown"
                        v-model="searchConditions.classCode02"
                        :query="searchConditions.classCode02Query"
                        :dataSource="searchConditions.classCode02Options"
                        :change="onClassCode02Changed"
                        :allowFiltering="false"
                        :fields="classCodeFields"
                        cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">소분류</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        ref="classCode03Dropdown"
                        v-model="searchConditions.classCode03"
                        :query="searchConditions.classCode03Query"
                        :dataSource="searchConditions.classCode03Options"
                        :allowFiltering="false"
                        :fields="classCodeFields"
                        cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">상품명/코드</div>
                  <ul class="content">
                    <li class="item">
                      <input-text v-model="searchConditions.productCodeName" />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">거래처</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-multiselect
                        ref="bcncMultiSelect"
                        :allowFiltering="true"
                        :filtering="bcncFiltering"
                        id="multiselect"
                        :dataSource="bcncData"
                        :fields="bcncFields"
                        cssClass="lookupDetail-condition-multiselect"
                      ></ejs-multiselect>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">면/과세</div>
                  <ul class="content">
                    <li class="item check">
                      <ul class="check">
                        <li>
                          <label>
                            <input
                              type="radio"
                              v-model="searchConditions.taxNotaxCodes"
                            />
                            <i></i>
                            <div class="label">ALL</div>
                          </label>
                        </li>
                        <li
                          :key="code.comCode"
                          v-for="code in searchConditions.taxNotaxCodesOptions"
                        >
                          <label>
                            <input
                              type="radio"
                              v-model="searchConditions.taxNotaxCodes"
                              :value="code.comCode"
                            />
                            <i></i>
                            <div class="label">
                              {{ code.comName }}
                            </div>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">사용여부</div>
                  <ul class="content">
                    <li class="item check">
                      <ul class="check">
                        <li>
                          <label>
                            <input
                              type="radio"
                              id="useFlagAll"
                              v-model="searchConditions.useFlag"
                            />
                            <i></i>
                            <div class="label">ALL</div>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type="radio"
                              id="useFlagUse"
                              value="true"
                              v-model="searchConditions.useFlag"
                            />
                            <i></i>
                            <div class="label">사용</div>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type="radio"
                              id="useFlagNotUse"
                              value="false"
                              v-model="searchConditions.useFlag"
                            />
                            <i></i>
                            <div class="label">미사용</div>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  button-div="GET"
                  :is-shortcut-button="true"
                  v-on:click.native="onDialogViewButtonClicked"
                  :ignore="isPopupOpened"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  v-on:click.native="onDialogSearchConditionInitClicked"
              >
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  v-on:click.native="onDialogCloseButtonClicked"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <product-code-popup
      v-if="isProductAddPopupOpen"
      ref="productInputPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupEvent="onProductAddPopupEvent"
    />
    <edit-multiple-columns-popup
      v-if="isEditMultipleColumnsPopupOpen"
      ref="editMultipleColumnsPopup"
      @popupConfirm="onEditMultipleColumnsPopupConfirm"
      @popupClosed="onEditMultipleColumnsPopupClose"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.brandNameCord .content .item {width: 180px;}
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import {
  Edit,
  VirtualScroll,
  Resize,
  ForeignKey,
  ExcelExport,
  Page,
} from "@syncfusion/ej2-vue-grids";
import { Query } from "@syncfusion/ej2-data";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";
import commonMixin from "@/views/layout/mixin/commonMixin";
import { getProductList, putProductList } from "@/api/product";
import ProductCodePopup from "../ProductCodePopup";
import editMultipleColumnsPopup from '@/views/common/EditMultipleColumnsPopup';
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "ProductCodeRegistration",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    InputText,
    ProductCodePopup,
    editMultipleColumnsPopup,
    ejsGridWrapper,
    ErpButton,
  },
  async created() {
    await this.init();
  },
  async mounted() {
    // 기본 포커싱
    this.$refs.searchProductCodeName.focus();

    // 대중소분류 dropdown 초기화
    this.searchConditions.classCode02Query = new Query().where(
        "parentCode",
        "equal",
        ""
    );
    this.searchConditions.classCode03Query = new Query().where(
        "parentCode",
        "equal",
        ""
    );

    this.searchConditions.taxNotaxCodesOptions = commonCodesGetCommonCode(
        "NOTAX_CODE",
        true
    );

    await this.getProductList();
  },
  data() {
    return {
      count: 0,
      isSearchDetailPopupOpen: false,
      isProductAddPopupOpen: false,
      isEditMultipleColumnsPopupOpen: false,
      productCodePopupValues: {
        classCode01Options: [],
        classCode02Options: [],
        classCode03Options: [],
        bcncData: [],
      },
      searchConditions: {
        productCodeName: null, // 상품명/상품코드
        classCode01: null, // 선택한 대분류값
        classCode01Options: [], // 대분류 selectbox 목록 - API에서 조회
        classCode02: null, // 선택한 중분류값
        classCode02Options: [], // 중분류 selectbox 목록 - API에서 조회
        classCode02Query: null,
        classCode03: null, // 선택한 소분류값
        classCode03Options: [], // 소분류 selectbox 목록 - API에서 조회
        classCode03Query: null,
        taxNotaxCodesOptions: [], // 과세,면세 선택 Options
        taxNotaxCodes: null, // 선택한 과세 면세 값
        useFlag: true, // 사용/미사용 선택여부
      },
      classCodeFields: { text: "className", value: "classCode" },
      productGridPageSettings: { pageSize: 50 },
      notaxCodeElem: {},
      notaxCodeObj: {},
      boolParams: { params: { checked: true } },
      isInit: true,
      productItemMaxLengthRules: {
        maxLength: [50, "최대 50자까지 입력하실 수 있습니다"], // TODO : 추후 정리 및 공통모듈화 필요
      },
      productList: [],
      productGridSelectionSettings: { mode: "Both" },
      productGridEditSettings: {
        allowEditing: true,
        allowAdding: false,
        allowDeleting: false,
        mode: "Batch",
        showConfirmDialog: false,
      },
      productLength: 0,
      productCode: "",
      productName: "",
      notaxCode: "",
      useFlag: "",
      bcncData: [],
      bcncFields: { text: "bcncName", value: "bcncId" },
      gridProvides: [
        Edit,
        VirtualScroll,
        Resize,
        ForeignKey,
        ExcelExport,
        Page,
      ],
      productGridColumns: [
        {
          field: "className01",
          allowEditing: false,
          headerText: "대분류",
          type: "string",
          minWidth: 16,
          width: 90,
        },
        {
          field: "className02",
          allowEditing: false,
          headerText: "중분류",
          type: "string",
          minWidth: 16,
          width: 90,
        },
        {
          field: "className03",
          allowEditing: false,
          headerText: "소분류",
          type: "string",
          minWidth: 16,
          width: 90,
        },
        {
          field: "productName",
          allowEditing: false,
          headerText: "상품명",
          type: "string",
          minWidth: 16,
          width: 180,
        },
        {
          field: "productCode",
          isPrimaryKey: true,
          allowEditing: false,
          headerText: "상품코드",
          type: "string",
          minWidth: 16,
          width: 120,
        },
        {
          field: "notaxCode",
          headerText: "면/과세",
          minWidth: 16,
          width: 90,
          isCommonCodeField: true,
          editType: "dropdownedit",
          groupCode: "NOTAX_CODE",
          type: "string",
        },
        {
          field: "price1",
          allowEditing: false,
          headerText: "판매금액",
          minWidth: 16,
          width: 90,
          type: "number",
          format: "N",
          textAlign: "Right",
        },
        {
          field: "purchsCost",
          headerText: "구입원가",
          minWidth: 16,
          width: 90,
          type: "number",
          format: "N",
          textAlign: "Right",
          multiEdit: {
            allowDot: false,
            allowMinus: true,
            displayComma: false,
          },
        },
        {
          field: "invntryFlag",
          headerText: "재고여부",
          minWidth: 16,
          width: 90,
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          textAlign: "Center",
        },
        {
          field: "useFlag",
          headerText: "사용여부",
          minWidth: 16,
          width: 90,
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          textAlign: "Center",
        },
        {
          field: "kitchenPrtFlag",
          headerText: "주방출력",
          minWidth: 16,
          width: 90,
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          textAlign: "Center",
        },
        {
          field: "autoIeFlag",
          headerText: "자동입출여부",
          minWidth: 16,
          width: 110,
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          textAlign: "Center",
        },
        {
          field: "standard",
          headerText: "규격",
          minWidth: 16,
          width: 90,
          type: "string",
        },
        {
          field: "unit",
          headerText: "단위",
          minWidth: 16,
          width: 90,
          type: "string",
        },
        {
          field: "brcd",
          headerText: "바코드",
          minWidth: 16,
          width: 120,
          type: "string",
        },
      ],
      productGridValidationRules: {
        notaxCode: {
          required: true,
        },
        purchsCost: {
          maxLength: 10,
          min: 0,
        },
        standard: {
          maxLength: 50,
        },
        unit: {
          maxLength: 50,
        },
        brcd: {
          maxLength: 50,
        },
      },
      isModifyMode: false,
    };
  },
  computed: {
    isPopupOpened() {
      return (
        this.isProductAddPopupOpen ||
        this.isEditMultipleColumnsPopupOpen
      );
    },
    shortcutMenuItems() {
      return [
        {
          text: "바로가기 01",
        },
        {
          text: "바로가기 02",
        },
        {
          text: "바로가기 03",
        },
      ];
    },
    etcMenuItems() {
      return [
        {
          text: "다운로드",
        },
        {
          text: "인쇄하기",
        },
      ];
    },
  },
  methods: {
    numberWithCommas,
    cancelProductGrid() {
      this.$refs.productGrid.batchCancel();
    },
    productGridCustomiseCell(args) {
      const {
        column: {field, type},
        cell,
        data,
      } = args;
      if (
        field === "notaxCode" ||
        field === "purchsCost" ||
        field === "invntryFlag" ||
        field === "useFlag" ||
        field === "kitchenPrtFlag" ||
        field === "autoIeFlag" ||
        field === "standard" ||
        field === "unit" ||
        field === "brcd"
      ) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (field === "productName") {
        cell.classList.add(this.$t("className.grid.clickArea")); // 클릭영역 셀 처리
        cell.style.color = args.data.colorValue; // 상품명 text color 설정
      }
      if (args.column && !args.data.useFlag) {
        cell.style.textDecoration = "line-through";
        cell.style.textDecorationColor = "red";
      }
      if (type?.toLowerCase() === "number" && !data[field] && field !== "purchsCost") {
        cell.innerText = "-";
      }
      if (this.isModifyMode) {
        const allowedEditColumns = this.productGridColumns
          ?.filter(item => item.allowEditing)
          ?.map(item => item.field);
        if (!allowedEditColumns.includes(field)) {
          cell.style.backgroundColor = '#f9f9f9';
          cell.style.pointerEvents = 'none';
        }
      }
    },
    productGridRecordClicked(args) {
      if (args.column && args.column.field === "productName") {
        this.isProductAddPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.productInputPopup.showPopup(
            args.rowData.productCode,
            this.productCodePopupValues
          );
        });
      }
    },
    searchDetail() {
      this.isSearchDetailPopupOpen = true;
      this.$refs.searchDetailPopup.show();
    },
    onProductAddClicked() {
      if (this.productCodePopupValues.classCode01Options.length === 0) {
        this.errorToast("데이터 로딩 완료 후 추가하실 수 있습니다");
        return;
      }
      this.isProductAddPopupOpen = true; // 팝업을 띄울 때 생성되도록 하기 위함
      this.$nextTick(() => {
        this.$refs.productInputPopup.showPopup(
          null,
          this.productCodePopupValues
        );
      });
    },
    async onProductSaveClicked() {
      if (!this.$refs.productGrid.validate()) {
        return;
      }
      const productChangedRecords = this.$refs.productGrid.getBatchChanges()
          .changedRecords; // validate() 이후에 가져와야 함
      await putProductList(productChangedRecords);
      this.infoToast(this.$t("main.popupMessage.saved"));
      await this.getProductList();
    },
    onClassCode01Changed() {
      this.searchConditions.classCode02Query = new Query().where(
        "parentCode",
        "equal",
        this.$refs.classCode01Dropdown.ej2Instances.value
      );
    },
    onClassCode02Changed() {
      this.searchConditions.classCode03Query = new Query().where(
        "parentCode",
        "equal",
        this.$refs.classCode02Dropdown.ej2Instances.value
      );
    },
    async onDialogViewButtonClicked() {
      await this.getProductList();
    },
    onDialogCloseButtonClicked() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
    onDialogSearchConditionInitClicked() {
      // 대중소분류 초기화 및 원래값으로 돌리기
      this.searchConditions.classCode01 = null;

      // 상품명/상품코드 초기화
      this.searchConditions.productCodeName = null;

      // 거래처 초기화
      this.$refs.bcncMultiSelect.ej2Instances.value = null;

      // 과세/면세 초기화 - 'ALL' 에 체크
      this.searchConditions.taxNotaxCodes = null;

      // 사용여부 초기화 - '사용' 에 체크
      this.searchConditions.useFlag = true;
    },
    async onProductAddPopupEvent(eventId) {
      if (eventId === "popupClosed") {
        this.isProductAddPopupOpen = false;
      } else if (eventId === "popupReload") {
        await this.getProductList();
      }
    },
    onEditMultipleColumnsPopupConfirm(args) {
      const {
        field,
        value,
        rowIndexes,
      } = args;
      this.isEditMultipleColumnsPopupOpen = false;
      if (rowIndexes.length < 1) {
        return;
      }
      rowIndexes.forEach(index => {
        this.$refs.productGrid.updateCell(
          index,
          field,
          value,
        );
      });
    },
    onEditMultipleColumnsPopupClose() {
      this.isEditMultipleColumnsPopupOpen = false;
    },
    async onViewButtonClicked() {
      await this.getProductList();
    },
    async init() {
      if (!this.isInit) {
        return;
      }

      const { value } = await getProductList({
        isInit: true,
      });

      if (this.isInit) {
        // 처음 호출한 경우는 값들 설정
        this.searchConditions.classCode01Options = value.productClasses01;
        this.searchConditions.classCode02Options = value.productClasses02;
        this.searchConditions.classCode03Options = value.productClasses03;
        this.bcncData = value.bcncList;

        // 아래 값들은 팝업과 공유할 값임. 이 화면 또는 팝업에서 수정하지 않을 값들이기 때문에 reference 방식으로 보냄
        this.productCodePopupValues.classCode01Options = this.searchConditions.classCode01Options;
        this.productCodePopupValues.classCode02Options = this.searchConditions.classCode02Options;
        this.productCodePopupValues.classCode03Options = this.searchConditions.classCode03Options;
        this.productCodePopupValues.bcncData = this.bcncData;

        this.isInit = false;
      }
    },
    async getProductList() {
      // 거래처 설정
      let bcncId = this.$refs.bcncMultiSelect.ej2Instances.value; // 초기값 null. 한 건이라도 세팅한 후엔 array
      if (bcncId === null) {
        bcncId = [];
      }

      let param = {
        classCode1: this.searchConditions.classCode01,
        classCode2: this.searchConditions.classCode02,
        classCode3: this.searchConditions.classCode03,
        productCodeName: this.searchConditions.productCodeName,
        bcncId: bcncId,
        notaxCode: this.searchConditions.taxNotaxCodes,
        useFlag: this.searchConditions.useFlag,
      };

      if (param.classCode1 === null) {
        delete param.classCode1;
      }
      if (param.classCode2 === null) {
        delete param.classCode2;
      }
      if (param.classCode3 === null) {
        delete param.classCode3;
      }
      if (
        param.productCodeName === null ||
        param.productCodeName.trim() === ""
      ) {
        delete param.productCodeName;
      }
      if (param.notaxCode === null) {
        delete param.notaxCode;
      }
      if (param.useFlag === null) {
        delete param.useFlag;
      }

      const { value } = await getProductList(param);

      this.productList = value.productCodes;
      this.productLength = value.totalCount;

      // 규격, 단위, 바코드 없는 경우 추가해주기
      this.productList = this.productList.map((product) => {
        if (!product.standard) {
          product = {
            ...product,
            standard: null,
          };
        }
        if (!product.unit) {
          product = {
            ...product,
            unit: null,
          };
        }
        if (!product.brcd) {
          product = {
            ...product,
            brcd: null,
          };
        }
        return product;
      });
    },
    bcncFiltering(e) {
      // TODO : 공통 모듈화
      let searchData = this.bcncData;
      if (e.text === "") {
        e.updateData(searchData);
      } else {
        let query = new Query().select(["bcncId", "bcncName"]);
        query =
          e.text !== ""
            ? query.where("bcncName", "contains", e.text, true)
            : query;
        e.updateData(searchData, query);
      }
    },
    onProductGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field,
            headerText,
          },
        },
        node,
      } = args;
      if (field === "notaxCode") {
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      } else if (
        field === "productName" &&
        !this.isModifyMode
      ) {
        node.classList.add(this.$t("className.grid.clickArea"));
      }
      if (this.isModifyMode && headerText !== 'NO') {
        const allowedEditColumns = this.productGridColumns
          ?.filter(item => item.allowEditing)
          ?.map(item => item.field);
        if (allowedEditColumns.includes(field)) {
          node.style.backgroundColor = 'rgb(237, 246, 250)';
          node.addEventListener('click', async () => await this.onGridHeaderClicked(args.cell.column), false);
        }
      }
    },
    async onGridHeaderClicked(column) {
      if (!column?.field) {
        return;
      }
      const gridRefs = this.$refs.productGrid;
      const selectedRowIndexes = [...new Array(gridRefs?.getBatchCurrentViewRecords().length).keys()];
      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
        column,
        selectedRowIndexes
      );
    },
    productGridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.productGrid?.getGridBatchCount() || 0
      );
    },
    onClickExcel() {
      this.$refs.productGrid.excelExport();
    },
    onModifySwitchChanged(args) {
      const isChecked = args.checked;
      this.productGridSelectionSettings = isChecked
        ? {cellSelectionMode: 'Box', type: 'Multiple', mode: 'Cell'}
        : {mode: 'Both'};
      this.$refs.productGrid.refresh();
      if (isChecked) {
        this.infoToast('멀티 편집할 셀 하나와 여러 로우를 드래그하세요.');
      }
    },
    async productGridCellSelected(args) {
      if (!this.isModifyMode) {
        return;
      }
      const {
        cellIndex: {cellIndex},
        selectedRowCellIndex
      } = args;
      if (selectedRowCellIndex[0]?.cellIndexes.length > 1) {
        this.errorToast('편집할 셀 하나만 선택해주세요.');
        return;
      }
      let columns = [];
      this.productGridColumns
        ?.forEach(column => {
          if (column?.columns) {
            column.columns
              ?.forEach(c => columns.push(c));
          } else {
            columns.push(column);
          }
        });
      const allowedEditColumns = columns
        ?.filter(item => item.allowEditing)
        ?.map(item => item.field);
      const column = columns[cellIndex - 1];
      if (!allowedEditColumns.includes(column?.field)) {
        this.errorToast('편집할 수 있는 셀이 아닙니다.');
        return;
      }
      const selectedRowIndexes = selectedRowCellIndex.map(item => item.rowIndex);
      if (selectedRowIndexes.length < 1) {
        return;
      }
      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
        column,
        selectedRowIndexes
      );
    },
  },
};
</script>

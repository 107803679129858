import request from "@/utils/request";
const qs = require("qs");

/**
 * @return Promise
 */
export function getReservationCheck(
  contactTel,
  grpNo,
  membershipId,
  resveDate,
  resveKind,
  resveNo,
  isVisit,
  isBackground = false
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/reservation/reservation-check",
    method: "get",
    isBackground: isBackground,
    params: {
      contactTel: contactTel,
      grpNo: grpNo,
      membershipId: membershipId,
      resveDate: resveDate,
      resveKind: resveKind,
      resveNo: resveNo,
      isVisit: isVisit,
    },
  });
}

/**
 * @return Promise
 */
export function getReservationPossibleTimeList(resveDateFrom, resveDateTo,visitCnt) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/reservation/possible-time-list",
    method: "get",
    params: {
      resveDateFrom: resveDateFrom,
      resveDateTo: resveDateTo,
      visitCnt : visitCnt
    },
  });
}

/**
 * @return Promise
 */
export function getDailyReservationStatus(
  resveDate,
  aditTeamFlag,
  courseCode,
  bsnCode,
  partDiv
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/reservation/status",
    method: "get",
    params: {
      resveDate,
      aditTeamFlag,
      courseCode,
      bsnCode,
      partDiv,
    },
  });
}

/**
 * @return Promise
 */
export function getReservationInfoTable(
  resveDateFrom,
  resveDateTo,
  bsnCode,
  partDiv,
  resveName,
  memberNo,
  searchValue,
  membershipId = null,
  visitCnt = null,
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/reservation/info-list",
    method: "get",
    params: {
      resveDateFrom: resveDateFrom,
      resveDateTo: resveDateTo,
      bsnCode: bsnCode,
      partDiv: partDiv,
      resveName: resveName,
      memberNo: memberNo,
      searchValue: searchValue,
      membershipId: membershipId,
      visitCnt: visitCnt,
    },
  });
}

/**
 * @return Promise
 */
export function getReservationHistoryPage(params) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/reservation_history/info",
    method: "get",
    params: params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

/**
 * 예약접수 등록의 예약 히스토리 조회 API 호출
 * @return Promise
 **/
export function getReservationTimeHistoryPage(timeId) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/reservation/time-history",
    method: "get",
    params: {
      timeId,
    },
  });
}

/**
 * @return Promise
 */
export function getReservationAllSMSSend(params) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/reservation-sms/all-send-info",
    method: "get",
    params: params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

/**
 * @return Promise
 */
export function getHoldTimeInfo(timeId) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/reservation/hold-time-info",
    method: "get",
    params: {
      timeId: timeId,
    },
  });
}

<template>
  <div>
    <ejs-dialog
      ref="reservationTimePromotionPopup"
      header="프로모션 팝업"
      width="1400"
      height="600"
      :animationSettings="{ effect: 'None' }"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :enableResize="true"
      :close="onPopupClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">예약일자</div>
                    <ul class="content">
                      <li class="item dateRange">
                        <input-date-range
                          type="lookup-condition"
                          v-model="resveDateRange"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">영업구분</div>
                    <ul class="content">
                      <li class="item" style="width: 100px">
                        <ejs-dropdownlist
                          v-model="searchOptions.bsnCode"
                          :fields="commonCodeFields"
                          :dataSource="commonCodeOptions.bsnCodes"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <ul class="content">
                      <li class="item">
                        <ul class="check">
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                value="true"
                                v-model="searchOptions.isBlank"
                              />
                              <i></i>
                              <div class="label">숨김</div>
                            </label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">출발코스</div>
                    <ul class="content">
                      <li class="item">
                        <ul class="check">
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                id="resveCourseAll"
                                v-model="resveCourseAllValueModel"
                              />
                              <i></i>
                              <div class="label">전체</div>
                            </label>
                          </li>
                          <li
                            v-for="code in commonCodeOptions.courseCodes"
                            :key="code.comCode"
                          >
                            <label>
                              <input
                                :ref="`courseCode_${code.comCode}`"
                                type="checkbox"
                                :key="`checkbox_${code.comCode}`"
                                :id="code.comCode"
                                v-model="searchOptions.resveCourseCodes"
                                :value="code.comCode"
                              />
                              <i></i>
                              <div
                                class="label"
                                :key="`label_checkbox_${code.comCode}`"
                                :for="code.comCode"
                              >
                                {{ code.comName }}
                              </div>
                            </label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">시간</div>
                    <ul class="content">
                      <li class="item time">
                        <input-time
                          ref="resveTimeFrom"
                          v-model="searchOptions.resveTimeFrom"
                          format="HH:mm"
                        />
                      </li>
                      <li class="item dash">~</li>
                      <li class="item time">
                        <input-time
                          ref="resveTimeTo"
                          v-model="searchOptions.resveTimeTo"
                          format="HH:mm"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">부구분</div>
                    <ul class="content">
                      <li class="item" style="width: 100px">
                        <ejs-dropdownlist
                          v-model="searchOptions.partDiv"
                          :fields="commonCodeFields"
                          :dataSource="commonCodeOptions.partDivs"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">타임상태</div>
                    <ul class="content">
                      <li class="item" style="width: 100px">
                        <ejs-dropdownlist
                          v-model="searchOptions.timeStatus"
                          :fields="commonCodeFields"
                          :dataSource="commonCodeOptions.timeStatusCodes"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">프로모션명</div>
                    <ul class="content">
                      <li class="item input">
                        <input-text
                          v-model="searchOptions.promtnName"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <ul class="content">
                      <li class="item">
                        <ul class="check">
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                value="true"
                                v-model="searchOptions.isPromotionApply"
                              />
                              <i></i>
                              <div class="label">프로모션 적용</div>
                            </label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      button-div="GET"
                      :is-shortcut-button="true"
                      :ignore="isPopupOpened"
                      @click.native="onViewButtonClicked"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">Tee-Off 목록</div>
                      <div class="header-caption">
                        [{{ numberWithCommas(resveTimesCount) }} 건]
                      </div>
                      <ul class="header-label">
                        <li class="field">
                          <div class="title">NO</div>
                          <ul class="content">
                            <li class="item">
                              <i
                                v-bind:style="{
                                  'background-color': commonCodesGetColorValue(
                                    'COLOR_TIME_STATUS',
                                    'WEB_OPEN_FLAG'
                                  ),
                                }"
                              ></i>
                              <div class="label">웹</div>
                            </li>
                            <li class="item">
                              <i
                                v-bind:style="{
                                  'background-color': commonCodesGetColorValue(
                                    'COLOR_TIME_STATUS',
                                    'WEB_OCLUB_TIME'
                                  ),
                                }"
                              ></i>
                              <div class="label">이용권</div>
                            </li>
                            <li class="item">
                              <i
                                v-bind:style="{
                                  'background-color': commonCodesGetColorValue(
                                    'COLOR_TIME_STATUS',
                                    'AGNCY_OPEN_FLAG'
                                  ),
                                }"
                              ></i>
                              <div class="label">대행</div>
                            </li>
                          </ul>
                        </li>
                        <li class="field">
                          <div class="title">시간</div>
                          <ul class="content">
                            <li class="item">
                              <i
                                v-bind:style="{
                                  'background-color': commonCodesGetColorValue(
                                    'TIME_STATUS',
                                    'BLOCK'
                                  ),
                                }"
                              ></i>
                              <div class="label">블럭</div>
                            </li>
                            <li class="item">
                              <i
                                v-bind:style="{
                                  'background-color': commonCodesGetColorValue(
                                    'TIME_STATUS',
                                    'HOLD'
                                  ),
                                }"
                              ></i>
                              <div class="label">홀딩</div>
                            </li>
                            <li class="item">
                              <i
                                v-bind:style="{
                                  'background-color': commonCodesGetColorValue(
                                    'CHKIN_STATUS',
                                    'CHKIN'
                                  ),
                                }"
                              ></i>
                              <div class="label">체크인</div>
                            </li>
                            <li class="item">
                              <i
                                v-bind:style="{
                                  'background-color': commonCodesGetColorValue(
                                    'CANCEL_DIV',
                                    'NOSHOW'
                                  ),
                                }"
                              ></i>
                              <div class="label">노쇼</div>
                            </li>
                            <li class="item">
                              <i
                                v-bind:style="{
                                  'background-color': commonCodesGetColorValue(
                                    'CANCEL_DIV',
                                    'RAIN'
                                  ),
                                }"
                              ></i>
                              <div class="label">우천</div>
                            </li>
                          </ul>
                        </li>
<!--                        <li class="field">-->
<!--                          <div class="title">예약자</div>-->
<!--                          <ul class="content">-->
<!--                            <li class="item">-->
<!--                              <i-->
<!--                                v-bind:style="{-->
<!--                                  'background-color': commonCodesGetColorValue(-->
<!--                                    'COLOR_MEMBER_DIV',-->
<!--                                    'MEMBER'-->
<!--                                  ),-->
<!--                                }"-->
<!--                              ></i>-->
<!--                              <div class="label">회원</div>-->
<!--                            </li>-->
<!--                            <li class="item">-->
<!--                              <i-->
<!--                                v-bind:style="{-->
<!--                                  'background-color': commonCodesGetColorValue(-->
<!--                                    'COLOR_MEMBER_DIV',-->
<!--                                    'NOM'-->
<!--                                  ),-->
<!--                                }"-->
<!--                              ></i>-->
<!--                              <div class="label">비회원</div>-->
<!--                            </li>-->
<!--                            <li class="item">-->
<!--                              <i-->
<!--                                v-bind:style="{-->
<!--                                  'background-color': commonCodesGetColorValue(-->
<!--                                    'GRP_KIND',-->
<!--                                    'YEAR'-->
<!--                                  ),-->
<!--                                }"-->
<!--                              ></i>-->
<!--                              <div class="label">연단체</div>-->
<!--                            </li>-->
<!--                            <li class="item">-->
<!--                              <i-->
<!--                                v-bind:style="{-->
<!--                                  'background-color': commonCodesGetColorValue(-->
<!--                                    'GRP_KIND',-->
<!--                                    'NOR'-->
<!--                                  ),-->
<!--                                }"-->
<!--                              ></i>-->
<!--                              <div class="label">일반</div>-->
<!--                            </li>-->
<!--                            <li class="item">-->
<!--                              <i-->
<!--                                v-bind:style="{-->
<!--                                  'background-color': commonCodesGetColorValue(-->
<!--                                    'GRP_KIND',-->
<!--                                    'TEMP'-->
<!--                                  ),-->
<!--                                }"-->
<!--                              ></i>-->
<!--                              <div class="label">임시</div>-->
<!--                            </li>-->
<!--                          </ul>-->
<!--                        </li>-->
                      </ul>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li class="add">
                          <erp-button
                              button-div="SAVE"
                              :is-icon-custom="true"
                              @click.native="onPromotionRegButtonClicked"
                          >
                            프로모션 등록
                          </erp-button>
                        </li>
                        <li class="delete">
                          <erp-button
                              button-div="DELETE"
                              @click.native="onPromotionDelButtonClicked"
                          >
                            프로모션 삭제
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body" style="border: none">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="grid"
                        v-bind="gridProps"
                        :dataSource="resveTimes"
                        :isInPopup="true"
                        @queryCellInfo="onGridQueryCellInfo"
                        @recordClick="onGridRecordClick"
                        @actionComplete="onGridActionComplete"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button
                button-div="CLOSE"
                @click.native="onCloseButtonClicked"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <promotion-search-popup
      v-if="isPromotionSearchPopupOpen"
      ref="promotionSearchPopup"
      @popupClosed="onPromotionSearchPopupClose"
    />
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import {Edit, ExcelExport, ForeignKey, Page, Resize, Selection} from "@syncfusion/ej2-vue-grids";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import InputText from "@/components/common/text/InputText";
import InputTime from "@/components/common/datetime/InputTime";
import PromotionSearchPopup from "@/views/golf-reservation/popup/PromotionSearchPopup";

import {numberWithCommas} from "@/utils/number";
import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
  commonCodesGetSortNo,
  commonCodesGetColorValue,
  commonCodesGetCommonCodeAttrbByCodeAndIdx
} from "@/utils/commonCodes";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {getFormattedDate} from "@/utils/date";
import { sortBy as _sortBy } from "lodash";

export default {
  name: 'ReservationTimePromotionPopup',
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputDateRange,
    EjsGridWrapper,
    InputText,
    InputTime,
    PromotionSearchPopup,
    ErpButton
  },
  data() {
    return {
      isPromotionSearchPopupOpen: false,
      rowSelectBuffer: null,
      searchOptions: {
        resveDateFrom: null,
        resveDateTo: null,
        bsnCode: null,
        isBlank: false,
        resveCourseCodes: [],
        resveTimeFrom: "00:00",
        resveTimeTo: "00:00",
        partDiv: null,
        timeStatus: null,
        promtnName: null,
        isPromotionApply: false,
      },
      commonCodeOptions: {
        bsnCodes: commonCodesGetCommonCode("BSN_CODE", true),
        courseCodes: commonCodesGetCommonCode("COURSE_CODE", true),
        partDivs: commonCodesGetCommonCode("PART_DIV", true),
        timeStatusCodes: commonCodesGetCommonCode("TIME_STATUS", true),
      },
      commonCodeFields: { text: "comName", value: "comCode" },
      resveTimes: [],
      resveTimesCount: 0,
    };
  },
  created() {
    if (this.commonCodeOptions.bsnCodes.findIndex((obj) => obj.comCode === "%") === -1) {
      this.commonCodeOptions.bsnCodes.unshift({
        comCode: "%",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
    this.searchOptions.bsnCode = "%";

    if (
      this.commonCodeOptions.partDivs.findIndex(
        (obj) => obj.comCode === "%"
      ) === -1
    ) {
      this.commonCodeOptions.partDivs.unshift({
        comCode: "%",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
    this.searchOptions.partDiv = "%";

    if (this.commonCodeOptions.timeStatusCodes.findIndex((obj) => obj.comCode === "%") === -1) {
      this.commonCodeOptions.timeStatusCodes.unshift({
        comCode: "%",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
    this.searchOptions.timeStatus = "%";
  },
  mounted() {},
  computed: {
    isPopupOpened() {
      return (
        this.isPromotionSearchPopupOpen
      );
    },
    resveDateRange: {
      get() {
        return {
          from: this.searchOptions.resveDateFrom,
          to: this.searchOptions.resveDateTo,
        };
      },
      set(dateRange) {
        this.searchOptions.resveDateFrom = dateRange.from;
        this.searchOptions.resveDateTo = dateRange.to;
      },
    },
    resveCourseAllValueModel: {
      get() {
        return (
          this.searchOptions.resveCourseCodes.length === this.commonCodeOptions.courseCodes.length
        );
      },
      set(value) {
        this.searchOptions.resveCourseCodes = value ? this.commonCodeOptions.courseCodes.map(({comCode}) => comCode) : [];
      }
    },
    gridProps() {
      return {
        provides: [Edit, ForeignKey, Selection, Resize, ExcelExport, Page],
        allowSorting: false,
        allowPaging: true,
        isAutoSelectRow: false,
        isAutoSelectCell: false,
        isSelectedRowRetain: false,
        selectionSettings: {
          persistSelection: true,
          type: "Single",
          checkboxOnly: true,
          checkboxMode: "Default",
          enableToggle: false,
        },
        editSettings: {
          allowEditing: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
        pageSettings: { pageSize: 50 },
        columns: [
          {
            field: "isSelect",
            minWidth: 16,
            width: 40,
            type: "checkbox",
            textAlign: "Center",
            allowFiltering: false,
          },
          {
            field: "resveDateByMD",
            headerText: "일자",
            minWidth: 16,
            width: 60,
            type:"string",
            textAlign: "Center",
            allowEditing: false
          },
          {
            field: "dwCode",
            headerText: "요일",
            minWidth: 16,
            width: 60,
            type:"string",
            textAlign: "Center",
            allowEditing: false,
            isCommonCodeField: true,
            groupCode:"DW_CODE",
          },
          {
            field: "resveCourse",
            headerText: "코스",
            minWidth: 16,
            width: 80,
            type:"string",
            textAlign: "Center",
            allowEditing: false,
            isCommonCodeField: true,
            groupCode:"COURSE_CODE",
          },
          {
            field: "resveTime",
            headerText: "시간",
            minWidth: 16,
            width: 60,
            type:"string",
            textAlign: "Center",
            allowEditing: false
          },
          {
            field: "partDiv",
            headerText: "부",
            minWidth: 16,
            width: 60,
            type:"string",
            textAlign: "Center",
            allowEditing: false,
            isCommonCodeField: true,
            groupCode:"PART_DIV",
          },
          {
            field: "holeDiv",
            headerText: "홀",
            minWidth: 16,
            width: 60,
            type:"string",
            textAlign: "Center",
            allowEditing: false,
            isCommonCodeField: true,
            groupCode:"HOLE_DIV",
          },
          {
            field: "timeStatus",
            headerText: "타임상태",
            minWidth: 16,
            width: 90,
            type:"string",
            textAlign: "Center",
            allowEditing: false,
            isCommonCodeField: true,
            groupCode:"TIME_STATUS",
          },
          {
            field: "webOpenFlag",
            headerText: "W",
            minWidth: 16,
            width: 80,
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            textAlign: "Center",
            allowEditing: false
          },
          {
            field: "agncyOpenFlag",
            headerText: "A",
            minWidth: 16,
            width: 80,
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            textAlign: "Center",
            allowEditing: false
          },
          {
            field: "promtnName",
            headerText: "프로모션",
            minWidth: 16,
            width: 150,
            type:"string",
            allowEditing: false
          },
          {
            field: "promtnMemberGrade",
            headerText: "회원등급",
            minWidth: 16,
            width: 150,
            type:"string",
            allowEditing: false
          },
          {
            field: "promtnPrice",
            headerText: "프로모션",
            minWidth: 16,
            width: 80,
            type: "number",
            format: "N",
            textAlign: "right",
            allowEditing: false
          },
          {
            field: "eventPrice",
            headerText: "이벤트",
            minWidth: 16,
            width: 80,
            type: "number",
            format: "N",
            textAlign: "right",
            allowEditing: false
          },
          {
            field: "noPrice",
            headerText: "정상가",
            minWidth: 16,
            width: 80,
            type: "number",
            format: "N",
            textAlign: "right",
            allowEditing: false
          },
          {
            field: "applyPrice",
            headerText: "입장료",
            minWidth: 16,
            width: 80,
            type: "number",
            format: "N",
            textAlign: "right",
            allowEditing: false
          },
        ],
      };
    },
  },
  methods: {
    numberWithCommas,
    commonCodesGetColorValue,
    showPopup(args) {
      this.searchOptions.resveDateFrom = args.resveDate;
      this.searchOptions.resveDateTo = args.resveDate;
    },
    onViewButtonClicked() {
      this.$refs.grid.clearSelection();
      this.initAllSelectionCells();
      this.fetchReservationTimes();
    },
    async fetchReservationTimes() {
      const data = await GolfErpAPI.fetchReservationTimes(({
        ...this.searchOptions,
        resveDate: this.searchOptions.resveDateFrom,
        blankFlag: this.searchOptions.isBlank,
        isMemoSelect: false,
        bsnCode: this.searchOptions.bsnCode === "%" ? null : this.searchOptions.bsnCode,
        partDiv: this.searchOptions.partDiv === "%" ? null : this.searchOptions.partDiv,
        timeStatus: this.searchOptions.timeStatus === "%" ? null : this.searchOptions.timeStatus,
        jsonDiv: "JSON",
        resveTimeFrom: this.searchOptions.resveTimeFrom === "00:00" && this.searchOptions.resveTimeTo === "00:00" ? null : this.searchOptions.resveTimeFrom,
        resveTimeTo: this.searchOptions.resveTimeFrom === "00:00" && this.searchOptions.resveTimeTo === "00:00" ? null : this.searchOptions.resveTimeTo,
        isPromotionInfoView: true,
      }));

      let partDiv = null;
      this.resveTimes = _sortBy(data.filter(it=>it.timeStatus=='EMPTY').map(resveTime => ({
        ...resveTime,
        timeId : resveTime.reservationConfirm?.timeId || resveTime.id,
        resveDateByMD: getFormattedDate(resveTime.resveDate, "MM-DD"),
        promtnName: resveTime?.reservationPromotion?.promtnName || null,
        promtnMemberGrade: resveTime?.reservationPromotion?.tgResvePromtnMembers?.map(item => commonCodesGetComName("MEMBER_GRADE", item.memberGrade)).join(),
        promtnPrice: Number(JSON.parse(resveTime.paymtPriceJson)?.PROMTN_PRICE || 0),
        eventPrice: Number(JSON.parse(resveTime.paymtPriceJson)?.EVENT_PRICE || 0),
        noPrice: Number(JSON.parse(resveTime.paymtPriceJson)?.NO_PRICE || 0),
        applyPrice: Number(JSON.parse(resveTime.paymtPriceJson)?.APPLY_PRICE || 0),
        resveCourseSortNo: commonCodesGetSortNo("COURSE_CODE", resveTime.resveCourse),
      })),
          ["resveDate","resveCourseSortNo","resveTime"]).map(resveTime => {
        const partDivLineFlag = partDiv === null ? false : partDiv !== resveTime.partDiv;
        partDiv = resveTime.partDiv;
        return {
          ...resveTime,
          partDivLineFlag: partDivLineFlag,
        };
      });
      console.log(this.resveTimes);
    },
    onGridQueryCellInfo(args) {
      const {
        cell,
        column: {
          field,
          type,
        },
        data,
      } = args;

      if (field === "isSelect") {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (data.partDivLineFlag) {
        cell.classList.add(
          this.$t("className.grid.devReservationPartDivLine")
        );
      }
      if (type === "number") {
        if (data[field] === 0) {
          cell.innerText = '-';
        }
      }
      if (field === "dwCode") {
        if (data[field] === "7") {
          cell.style.color = commonCodesGetColorValue("DW_CODE", data[field]);
        } else {
          cell.style.color = commonCodesGetColorValue("BSN_CODE", data["bsnCode"]);
        }
      }
      if (["promtnPrice","promtnName"].includes(field)) {
        if (data["promtnPrice"] > 0 && data["eventPrice"] > 0) {
          cell.style.color = "#ff0000";
        }
      }
      if (field === undefined) {
        if (data.agncyOpenFlag) {
          cell.style.backgroundColor = this.commonCodesGetColorValue(
            "COLOR_TIME_STATUS",
            "AGNCY_OPEN_FLAG"
          );
        } else if (data.webOpenFlag) {
          if (data.timeDiv === "OCLUB") {
            cell.style.backgroundColor = this.commonCodesGetColorValue(
              "COLOR_TIME_STATUS",
              "WEB_OCLUB_TIME"
            );
          } else {
            cell.style.backgroundColor = this.commonCodesGetColorValue(
              "COLOR_TIME_STATUS",
              "WEB_OPEN_FLAG"
            );
          }
        }
      } else if (field === "resveTime") {
        // 시간 cell style
        // cell.classList.add(this.$t("className.grid.selectedCellBold"));

        // timeStatus 값이 Black/Block/Holding이면 background 설정 / text color는 흰색으로 설정
        if (
          data.timeStatus === "BLANK" ||
          data.timeStatus === "BLOCK" ||
          data.timeStatus === "HOLD"
        ) {
          cell.style.backgroundColor = this.commonCodesGetColorValue(
            "TIME_STATUS",
            data.timeStatus
          );
          cell.style.color = "#FFFFFF";
        } else if (
          data.cancelDiv === "RAIN" ||
          data.cancelDiv === "NOSHOW"
        ) {
          cell.style.backgroundColor = this.commonCodesGetColorValue(
            "CANCEL_DIV",
            data.cancelDiv
          );
          cell.style.color = "#FFFFFF";
        } else if (
          data.chkinStatus === "CHKIN" ||
          data.chkinStatus === "PAYING" ||
          data.chkinStatus === "CHKOUT"
        ) {
          cell.style.backgroundColor = commonCodesGetColorValue(
            "CHKIN_STATUS",
            "CHKIN" //args.data.chkinStatus
          );
        }
      } else if (field === "holeDiv") {
        // 홀 column
        cell.style.backgroundColor = commonCodesGetColorValue(
          "HOLE_DIV",
          data.holeDiv
        );
      } else if (field === "resveName") {
        // 예약자 백그라운드 설정
        if (!!data.resveId && data.webOpenFlag) {
          cell.style.backgroundColor = this.commonCodesGetColorValue(
            "COLOR_TIME_STATUS",
            "WEB_OPEN_FLAG"
          );
        } else if (!!data.grpKind && data.grpKind !== "FIT") {
          cell.style.backgroundColor = this.commonCodesGetColorValue(
            "GRP_KIND",
            data.grpKind
          );
        } else if (
          data.memberDiv !== null &&
          data.memberDiv !== undefined
        ) {
          // MEMBER_DIV 의 3번째 index Attribute 로 COLOR_MEMBER_DIV의 colorValue 조회 후 백그라운드 등록
          const attrMemberDiv = commonCodesGetCommonCodeAttrbByCodeAndIdx(
            "MEMBER_DIV",
            data.memberDiv,
            3
          );

          cell.style.backgroundColor = this.commonCodesGetColorValue(
            "COLOR_MEMBER_DIV",
            attrMemberDiv
          );

          // 예약자 글자색 설정
          cell.style.color = this.commonCodesGetColorValue(
            "MEMBER_DIV",
            data.memberDiv
          );
        }

        if (data.resveId) {
          cell.classList.add(this.$t("className.grid.clickAreaLeft"));
        } else {
          if (data.timeStatus === "EMPTY") {
            if (!this.commonMixinIsButtonDisableByAuth('resveReceiptResveReg')) {
              cell.classList.add(this.$t("className.grid.reservArea"));
              cell.innerText = "예약";
            }
          } else if (data.timeStatus === "HOLD") {
            if (!data.resveRemarks) {
              cell.classList.remove(this.$t("className.grid.reservArea"));
              cell.style.textAlign = "center";
              cell.innerHTML =
                '<div class="e-checkbox-wrapper e-css e-checkbox-disabled"><span class="e-frame e-icons e-check"></span><span class="e-label"></span></div>';
            }
          }
        }

        if (data.resveCmpnFlag) {
          cell.classList.add(this.$t("className.grid.incomeDeduction"));
        }

        if (data.vipFlag) {
          cell.classList.add(this.$t("className.grid.vipArea"));
          cell.innerHTML = `<div class="vip">VIP</div>${data.resveName}`;
        }
      }
    },
    onGridRecordClick(args) {
      const {
        column: {
          field
        },
      } = args;

      if(args.column){
        this.removeSelectionCells();
        if(field !== "isSelect"){
          this.rowSelectBuffer = args.rowIndex;
          this.createFocusedCells();
        }
      }
    },
    removeSelectionCells() {
      Array.from(document.querySelectorAll(".e-selectionbackground"))
        .filter((el) => !el.hasAttribute("aria-selected"))
        .forEach((el) => {
          el.classList.remove("e-selectionbackground");
          el.classList.remove("e-active");
        });
    },
    createFocusedCells() {
      const focusedRowCells =
        this.$refs.grid.getRows()[this.rowSelectBuffer]
          ?.cells;
      if (focusedRowCells && focusedRowCells?.length > 0) {
        Array.from(focusedRowCells).forEach((el) => {
          el.classList.add("e-selectionbackground");
          el.classList.add("e-active");
        });
      }
    },
    initAllSelectionCells() {
      this.rowSelectBuffer = null;
      Array.from(document.querySelectorAll(".e-selectionbackground")).forEach(
        (el) => {
          el.classList.remove("e-selectionbackground");
          el.classList.remove("e-active");
        }
      );
    },
    async onPromotionRegButtonClicked() {
      const selectedRecords = this.$refs.grid.getSelectedRecords();

      if (!(selectedRecords.length > 0)) {
        this.errorToast("선택된 정보가 없습니다");
        return;
      }

      const timeIds = selectedRecords.map(item => item.timeId);

      this.isPromotionSearchPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.promotionSearchPopup.showPromotionSearchPopup({timeIds});
      });
    },
    async onPromotionSearchPopupClose(args) {
      const {selectedPromotion, popupData: {timeIds,}} = args;
      this.isPromotionSearchPopupOpen = false;
      if (selectedPromotion) {
        if (!selectedPromotion.promtnId) {
          this.errorToast("선택된 프로모션 ID가 없습니다");
          return;
        }

        if (!(await this.confirm(`선택하신 ${timeIds.length}건의 타임에 [${selectedPromotion.promtnName}] 프로모션을 등록하시겠습니까?`))) {
          return;
        }

        try {
          await GolfErpAPI.batchPromotionRegistration(selectedPromotion.promtnId, timeIds);

          this.infoToast("저장되었습니다");

          this.onViewButtonClicked();
        } catch (e) {
          this.errorToast("에러가 발생하였습니다.<br>" + e.message);
        }
      }
    },
    async onPromotionDelButtonClicked() {
      const selectedRecords = this.$refs.grid.getSelectedRecords();

      if (!(selectedRecords.length > 0)) {
        this.errorToast("선택된 정보가 없습니다");
        return;
      }

      if (!(await this.confirm(`선택하신 ${selectedRecords.length}건의 타임에서 프로모션을 삭제하시겠습니까?`))) {
        return;
      }

      const timeIds = selectedRecords.map(item => item.timeId);

      try {
        await GolfErpAPI.batchPromotionDelete(timeIds);

        this.infoToast("삭제되었습니다");

        this.onViewButtonClicked();
      } catch (e) {
        this.errorToast("에러가 발생하였습니다.<br>" + e.message);
      }
    },
    onGridActionComplete(args) {
      const {
        requestType
      } = args;

      if (["refresh", "filtering"].includes(requestType)) {
        this.resveTimesCount = this.$refs.grid.getFilteredRecords().length || this.resveTimes.length;
      }
    },
    onCloseButtonClicked() {
      this.$refs.reservationTimePromotionPopup.hide();
    },
    onPopupClose() {
      this.$emit("popupClosed");
    },
  },
};
</script>

<template>
  <div>
    <ejs-dialog
      ref="payReceiptPopup"
      :header="`정산 내역서 - ${popupData.visitName}(${popupData.lockerNo})`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="740"
      height="800"
      :isModal="true"
      :close="onPayReceiptPopupClosed"
    >
      <div class="window payReceipt">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article article-01">
                <div class="article-left">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-02">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">
                          단체명: {{ popupData.grpName || '개인팀' }}
                        </div>
                      </div>
                    </div>
                    <div class="section-body">
                      <div class="body-data header">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul class="data-content">
                              <li class="field checkbox">
                                <!-- 필수 : required -->
                                <div class="title">
                                  <ul class="row">
                                    <li class="item form-group check">
                                      <!-- 포커스 : focus -->
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              :checked="
                                                allStoreGroups.length ===
                                                storeCodes.length
                                              "
                                              @change="storeCheckAllChecked"
                                            />
                                            <i></i>
                                          </label>
                                        </li>
                                      </ul>
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field store">
                                <!-- 필수 : required -->
                                <div class="title">매장</div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                      <div class="body-data body">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul
                              class="data-content"
                              v-for="store in allStoreGroups"
                              :key="store.comCode"
                            >
                              <li class="field checkbox">
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group check">
                                      <!-- 포커스 : focus -->
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              :checked=" storeCodes.includes( store.comCode )"
                                              :value="store.comCode"
                                              @change=" storeCheckboxChecked( $event, store.comCode )"
                                            />
                                            <i></i>
                                          </label>
                                        </li>
                                      </ul>
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field store">
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group text">
                                      {{ store.comName }}
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                  </section>
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-03">
                    <div class="section-body">
                      <div class="body-data">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul class="data-content">
                              <li class="field">
                                <!-- 필수 : required -->
                                <div class="title">팀 선택</div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group check">
                                      <!-- 포커스 : focus -->
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="radio"
                                              v-model="selectDiv"
                                              name="selectDiv"
                                              :disabled="!popupData.grpNo"
                                              value="GRP"
                                              @change=" payReceiptSearchValueChanged"
                                            />
                                            <i></i>
                                            <div class="label">단체</div>
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                              type="radio"
                                              v-model="selectDiv"
                                              name="selectDiv"
                                              value="TEAM"
                                              @change=" payReceiptSearchValueChanged"
                                            />
                                            <i></i>
                                            <div class="label">팀</div>
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                              type="radio"
                                              v-model="selectDiv"
                                              name="selectDiv"
                                              value="FIT"
                                              @change=" payReceiptSearchValueChanged"
                                            />
                                            <i></i>
                                            <div class="label">개인</div>
                                          </label>
                                        </li>
                                      </ul>
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field">
                                <!-- 필수 : required -->
                                <div class="title">출력 옵션</div>
                                <div class="content">
                                  <ul class="row">
                                    <li
                                      class="item form-group check"
                                      style="width: 100%; border-right: none; border-bottom: 1px solid #e0e0e0;"
                                    >
                                      <!-- 포커스 : focus -->
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="radio"
                                              v-model="printFlag"
                                              name="printFlag"
                                              value="TOT"
                                              @change=" payReceiptSearchValueChanged"
                                            />
                                            <i></i>
                                            <div class="label">매장 집계</div>
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                              type="radio"
                                              v-model="printFlag"
                                              name="printFlag"
                                              value="PROD"
                                              @change=" payReceiptSearchValueChanged"
                                            />
                                            <i></i>
                                            <div class="label">매장-상품</div>
                                          </label>
                                        </li>
                                      </ul>
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                    <li
                                      class="item form-group check"
                                      style="width: 100%; border-right: none; border-bottom: 1px solid #e0e0e0;"
                                    >
                                      <!-- 포커스 : focus -->
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              v-model="isHideCancelSlip"
                                              name="isHideCancelSlip"
                                              @change="onIsHideCancelSlipChanged"
                                            />
                                            <i></i>
                                            <div class="label">취소 전표 숨김</div>
                                          </label>
                                        </li>
                                      </ul>
                                    </li>
                                    <li
                                      class="item form-group check"
                                      style="width: 100%; border-right: none; border-bottom: 1px solid #e0e0e0;"
                                    >
                                      <!-- 포커스 : focus -->
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              v-model="isDcListView"
                                              name="isDcListView"
                                              @change="onIsDcListViewChanged"
                                            />
                                            <i></i>
                                            <div class="label">할인 내용 표시</div>
                                          </label>
                                        </li>
                                      </ul>
                                    </li>
                                    <li class="item form-group check">
                                      <!-- 포커스 : focus -->
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              v-model="isDateGroup"
                                              name="isDateGroup"
                                              @change="onIsDcListViewChanged"
                                            />
                                            <i></i>
                                            <div class="label">일자별 출력</div>
                                          </label>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                  </section>
                </div>
                <div class="article-right">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-04">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">
                          시간: {{ popupData.startTime }} [{{ popupData.startCourse }}]
                        </div>
                      </div>
                    </div>
                    <div class="section-body">
                      <div class="body-box" style="color: #000">
                        <div v-if="printFlag === 'TOT'">
                          <div
                            style="
                              position: relative;
                              border-bottom: 1px dashed #000;
                              padding: 0 0 7px 0;
                            "
                          >
                            <div
                              style="
                                font-size: 16px;
                                font-weight: bold;
                                text-align: center;
                              "
                            >
                              COUNTRY CLUB
                            </div>
                            <div style="padding-top: 9px">
                              <div
                                style="
                                  box-sizing: border-box;
                                  float: left;
                                  width: 50%;
                                  padding-right: 6px;
                                "
                              >
                                계 산 서 ({{ viewContent.teamGrpPersName }})
                              </div>
                              <div
                                style="
                                  box-sizing: border-box;
                                  float: right;
                                  width: 50%;
                                  padding-left: 6px;
                                  text-align: right;
                                "
                              >
                                {{ viewContent.visitName }} 귀하
                              </div>
                              <div style="clear: both; height: 0"></div>
                            </div>
                            <div style="padding-top: 11px">
                              <div
                                style="
                                  box-sizing: border-box;
                                  float: left;
                                  width: 50%;
                                  padding-right: 6px;
                                "
                              >
                                TEE-OFF : {{ viewContent.startTime }}
                              </div>
                              <div
                                style="
                                  box-sizing: border-box;
                                  float: right;
                                  width: 50%;
                                  padding-left: 6px;
                                  text-align: right;
                                "
                              >
                                {{ viewContent.visitDate }}
                              </div>
                              <div style="clear: both; height: 0"></div>
                            </div>
                          </div>
                          <div
                            v-if="
                              !!viewContent.greenfeeInfoList &&
                              viewContent.greenfeeInfoList.length > 0
                            "
                          >
                            <div
                              style="
                                position: relative;
                                border-bottom: 1px dashed #ccc;
                                padding: 9px 0 8px 0;
                              "
                            >
                              <div
                                v-for="data in viewContent.greenfeeInfoList"
                                :key="data.idx"
                              >
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: left;
                                    width: calc(100% - 110px);
                                    padding-right: 6px;
                                  "
                                >
                                  {{ data.productName }}
                                </div>
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: right;
                                    width: 75px;
                                    padding-left: 6px;
                                    text-align: right;
                                  "
                                >
                                  {{ getFormattedNumber(data.totAmt) }}
                                </div>
                                <div style="clear: both; height: 0"></div>
                              </div>
                            </div>
                            <div
                              style="
                                position: relative;
                                border-bottom: 1px dashed #000;
                                padding: 9px 0 8px 0;
                              "
                            >
                              <div
                                v-for="data in viewContent.frontInfoList"
                                :key="data.idx"
                              >
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: left;
                                    width: calc(100% - 110px);
                                    padding-right: 6px;
                                  "
                                >
                                  {{ data.productName }}
                                </div>
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: left;
                                    width: 35px;
                                    padding-left: 6px;
                                    padding-right: 6px;
                                    text-align: right;
                                  "
                                >
                                  {{ data.saleQty }}
                                </div>
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: right;
                                    width: 75px;
                                    padding-left: 6px;
                                    text-align: right;
                                  "
                                >
                                  {{ getFormattedNumber(data.totAmt) }}
                                </div>
                                <div style="clear: both; height: 0"></div>
                              </div>
                            </div>
                            <div
                              style="
                                position: relative;
                                border-bottom: 1px dashed #000;
                                padding: 9px 0 8px 0;
                              "
                            >
                              <div>
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: left;
                                    width: calc(100% - 110px);
                                    padding-right: 6px;
                                  "
                                >
                                  입 장 료
                                </div>
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: right;
                                    width: 75px;
                                    padding-left: 6px;
                                    text-align: right;
                                  "
                                >
                                  {{ getFormattedNumber(viewContent.greenFee) }}
                                </div>
                                <div style="clear: both; height: 0"></div>
                              </div>
                              <div v-if="courseClass=='R'">
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: left;
                                    width: calc(100% - 110px);
                                    padding-right: 6px;
                                  "
                                >
                                  특 소 세
                                </div>
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: right;
                                    width: 75px;
                                    padding-left: 6px;
                                    text-align: right;
                                  "
                                >
                                  {{
                                    getFormattedNumber(viewContent.specialTax)
                                  }}
                                </div>
                                <div style="clear: both; height: 0"></div>
                              </div>
                              <div v-if="courseClass=='R'">
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: left;
                                    width: calc(100% - 110px);
                                    padding-right: 6px;
                                  "
                                >
                                  교 육 세
                                </div>
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: right;
                                    width: 75px;
                                    padding-left: 6px;
                                    text-align: right;
                                  "
                                >
                                  {{ getFormattedNumber(viewContent.eduTax) }}
                                </div>
                                <div style="clear: both; height: 0"></div>
                              </div>
                              <div v-if="courseClass=='R'">
                                <div style=" box-sizing: border-box; float: left; width: calc(100% - 110px); padding-right: 6px;">
                                  농 특 세
                                </div>
                                <div style=" box-sizing: border-box; float: right; width: 75px; padding-left: 6px; text-align: right;">
                                  {{ getFormattedNumber(viewContent.vilTax) }}
                                </div>
                                <div style="clear: both; height: 0"></div>
                              </div>
                              <div>
                                <div style=" box-sizing: border-box; float: left; width: calc(100% - 110px); padding-right: 6px;">
                                  부 가 세
                                </div>
                                <div style=" box-sizing: border-box; float: right; width: 75px; padding-left: 6px; text-align: right;">
                                  {{ getFormattedNumber(viewContent.vatAmt) }}
                                </div>
                                <div style="clear: both; height: 0"></div>
                              </div>
                              <div v-if="courseClass=='R'">
                                <div style=" box-sizing: border-box; float: left; width: calc(100% - 110px); padding-right: 6px;">
                                  체육기금
                                </div>
                                <div style=" box-sizing: border-box; float: right; width: 75px; padding-left: 6px; text-align: right;">
                                  {{ getFormattedNumber(viewContent.fund) }}
                                </div>
                                <div style="clear: both; height: 0"></div>
                              </div>
                            </div>
                          </div>
                          <div v-if=" !!viewContent.golfInfoList && viewContent.golfInfoList.length > 0">
                            <div
                              style="
                                position: relative;
                                border-bottom: 1px dashed #000;
                                padding: 9px 0 8px 0;
                              "
                            >
                              <div
                                v-for="data in viewContent.golfInfoList"
                                :key="data.idx"
                              >
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: left;
                                    width: calc(100% - 110px);
                                    padding-right: 6px;
                                  "
                                >
                                  {{ data.storeName }}
                                </div>
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: right;
                                    width: 75px;
                                    padding-left: 6px;
                                    text-align: right;
                                  "
                                >
                                  {{ getFormattedNumber(data.totAmt) }}
                                </div>
                                <div style="clear: both; height: 0"></div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="
                              !!viewContent.foodInfoList &&
                              viewContent.foodInfoList.length > 0
                            "
                          >
                            <div
                              style="
                                position: relative;
                                border-bottom: 1px dashed #000;
                                padding: 9px 0 8px 0;
                              "
                            >
                              <div
                                v-for="data in viewContent.foodInfoList"
                                :key="data.idx"
                              >
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: left;
                                    width: calc(100% - 110px);
                                    padding-right: 6px;
                                  "
                                >
                                  {{ data.storeName }}
                                </div>
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: right;
                                    width: 75px;
                                    padding-left: 6px;
                                    text-align: right;
                                  "
                                >
                                  {{ getFormattedNumber(data.totAmt) }}
                                </div>
                                <div style="clear: both; height: 0"></div>
                              </div>
                              <div
                                v-for="data in viewContent.foodTotalInfoList"
                                :key="data.idx"
                              >
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: left;
                                    width: calc(100% - 110px);
                                    padding-right: 6px;
                                  "
                                >
                                  {{ data.storeName }}
                                </div>
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: right;
                                    width: 75px;
                                    padding-left: 6px;
                                    text-align: right;
                                  "
                                >
                                  {{ getFormattedNumber(data.totAmt) }}
                                </div>
                                <div style="clear: both; height: 0"></div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="
                              !!viewContent.shopInfoList &&
                              viewContent.shopInfoList.length > 0
                            "
                          >
                            <div
                              style="
                                position: relative;
                                border-bottom: 1px dashed #000;
                                padding: 9px 0 8px 0;
                              "
                            >
                              <div
                                v-for="data in viewContent.shopInfoList"
                                :key="data.idx"
                              >
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: left;
                                    width: calc(100% - 110px);
                                    padding-right: 6px;
                                  "
                                >
                                  {{ data.storeName }}
                                </div>
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: right;
                                    width: 75px;
                                    padding-left: 6px;
                                    text-align: right;
                                  "
                                >
                                  {{ getFormattedNumber(data.totAmt) }}
                                </div>
                                <div style="clear: both; height: 0"></div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="
                              !!viewContent.cfrontInfoList &&
                              viewContent.cfrontInfoList.length > 0
                            "
                          >
                            <div
                              style="
                                position: relative;
                                border-bottom: 1px dashed #000;
                                padding: 9px 0 8px 0;
                              "
                            >
                              <div
                                v-for="data in viewContent.cfrontInfoList"
                                :key="data.idx"
                              >
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: left;
                                    width: calc(100% - 110px);
                                    padding-right: 6px;
                                  "
                                >
                                  {{ data.storeName }}
                                </div>
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: right;
                                    width: 75px;
                                    padding-left: 6px;
                                    text-align: right;
                                  "
                                >
                                  {{ getFormattedNumber(data.totAmt) }}
                                </div>
                                <div style="clear: both; height: 0"></div>
                              </div>
                              <div
                                v-for="data in viewContent.cfrontTotalInfoList"
                                :key="data.idx"
                              >
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: left;
                                    width: calc(100% - 110px);
                                    padding-right: 6px;
                                  "
                                >
                                  {{ data.storeName }}
                                </div>
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: right;
                                    width: 75px;
                                    padding-left: 6px;
                                    text-align: right;
                                  "
                                >
                                  {{ getFormattedNumber(data.totAmt) }}
                                </div>
                                <div style="clear: both; height: 0"></div>
                              </div>
                            </div>
                          </div>
                          <div
                            style="
                              position: relative;
                              border-bottom: 1px dashed #000;
                              padding: 9px 0 8px 0;
                            "
                          >
                            <div
                              v-for="data in viewContent.totalInfoList"
                              :key="data.idx"
                            >
                              <div
                                style="
                                  box-sizing: border-box;
                                  float: left;
                                  width: calc(100% - 110px);
                                  padding-right: 6px;
                                "
                              >
                                결제금액
                              </div>
                              <div
                                style="
                                  box-sizing: border-box;
                                  float: right;
                                  width: 75px;
                                  padding-left: 6px;
                                  text-align: right;
                                "
                              >
                                {{ getFormattedNumber(data.totAmt) }}
                              </div>
                              <div style="clear: both; height: 0"></div>
                            </div>
                          </div>
                          <div style="position: relative; padding: 15px 0 0 0">
                            <div>
                              계산상의 착오나 의문사항이 있으시면<br />
                              연락주십시오.
                            </div>
                            <div style="padding-top: 11px">
                              TEL : {{ viewContent.telNo }} / FAX :
                              {{ viewContent.faxNo }}
                            </div>
                          </div>
                        </div>
                        <div v-if="printFlag === 'PROD'">
                          <div
                            style="
                              position: relative;
                              border-bottom: 1px dashed #000;
                              padding: 0 0 7px 0;
                            "
                          >
                            <div
                              style="
                                font-size: 16px;
                                font-weight: bold;
                                text-align: center;
                              "
                            >
                              COUNTRY CLUB
                            </div>
                            <div style="padding-top: 9px">
                              <div
                                style="
                                  box-sizing: border-box;
                                  float: left;
                                  width: 50%;
                                  padding-right: 6px;
                                "
                              >
                                정 산 내 역 ({{ viewContent.teamGrpPersName }})
                              </div>
                              <div
                                style="
                                  box-sizing: border-box;
                                  float: right;
                                  width: 50%;
                                  padding-left: 6px;
                                  text-align: right;
                                "
                              >
                                {{ viewContent.visitName }} 귀하
                              </div>
                              <div style="clear: both; height: 0"></div>
                            </div>
                            <div style="padding-top: 11px">
                              <div
                                style="
                                  box-sizing: border-box;
                                  float: right;
                                  width: 50%;
                                  padding-left: 6px;
                                  text-align: right;
                                "
                              >
                                {{ viewContent.visitDate }}
                              </div>
                              <div style="clear: both; height: 0"></div>
                            </div>
                          </div>
                          <div v-for="arrIdx in [0, 1]" :key="arrIdx">
                            <div
                              v-if="isDateGroup"
                              style="
                                position: relative;
                                border-bottom: 1px dashed #000;
                                padding: 9px 0 8px 0;
                              "
                            >[{{ arrIdx + 1 + "일차" }}]</div>
                            <div
                              v-if="
                                !!viewContent[`frontInfoListBy${arrIdx}`] &&
                                viewContent[`frontInfoListBy${arrIdx}`].length > 0
                              "
                            >
                              <div
                                style="
                                  position: relative;
                                  border-bottom: 1px dashed #ccc;
                                  padding: 9px 0 8px 0;
                                "
                              >
                                <div>[{{ viewContent[`frontTitleBy${arrIdx}`] }}]</div>
                                <div
                                  v-for="data in viewContent[`frontInfoListBy${arrIdx}`]"
                                  :key="data.idx"
                                >
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: calc(100% - 180px);
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.productName }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 70px;
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.subProductName ? data.subProductName : "&nbsp;" }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 35px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ data.isDc ? data.saleQty : getFormattedNumber(data.saleQty) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 75px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.totAmt) }}
                                  </div>
                                  <div style="clear: both; height: 0"></div>
                                </div>
                              </div>
                              <div
                                style="
                                  position: relative;
                                  border-bottom: 1px dashed #000;
                                  padding: 9px 0 8px 0;
                                "
                              >
                                <div
                                  v-for="data in viewContent[`frontSubTotalListBy${arrIdx}`]"
                                  :key="data.idx"
                                >
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: calc(100% - 110px);
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.productName }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 35px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.saleQty) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 75px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.totAmt) }}
                                  </div>
                                  <div style="clear: both; height: 0"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="
                                !!viewContent[`cartInfoListBy${arrIdx}`] &&
                                viewContent[`cartInfoListBy${arrIdx}`].length > 0
                              "
                            >
                              <div
                                style="
                                  position: relative;
                                  border-bottom: 1px dashed #ccc;
                                  padding: 9px 0 8px 0;
                                "
                              >
                                <div>[{{ viewContent[`cartTitleBy${arrIdx}`] }}]</div>
                                <div
                                  v-for="data in viewContent[`cartInfoListBy${arrIdx}`]"
                                  :key="data.idx"
                                >
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: calc(100% - 110px);
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.productName }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 35px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ data.isDc ? data.saleQty : getFormattedNumber(data.saleQty) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 75px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.totAmt) }}
                                  </div>
                                  <div style="clear: both; height: 0"></div>
                                </div>
                              </div>
                              <div
                                style="
                                  position: relative;
                                  border-bottom: 1px dashed #000;
                                  padding: 9px 0 8px 0;
                                "
                              >
                                <div
                                  v-for="data in viewContent[`cartSubTotalListBy${arrIdx}`]"
                                  :key="data.idx"
                                >
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: calc(100% - 110px);
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.productName }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 35px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.saleQty) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 75px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.totAmt) }}
                                  </div>
                                  <div style="clear: both; height: 0"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="
                                !!viewContent[`rentInfoListBy${arrIdx}`] &&
                                viewContent[`rentInfoListBy${arrIdx}`].length > 0
                              "
                            >
                              <div
                                style="
                                  position: relative;
                                  border-bottom: 1px dashed #ccc;
                                  padding: 9px 0 8px 0;
                                "
                              >
                                <div>[{{ viewContent[`rentTitleBy${arrIdx}`] }}]</div>
                                <div
                                  v-for="data in viewContent[`rentInfoListBy${arrIdx}`]"
                                  :key="data.idx"
                                >
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: calc(100% - 180px);
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.productName }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 70px;
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.subProductName ? data.subProductName : "&nbsp;" }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 35px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ data.isDc ? data.saleQty : getFormattedNumber(data.saleQty) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 75px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.totAmt) }}
                                  </div>
                                  <div style="clear: both; height: 0"></div>
                                </div>
                              </div>
                              <div
                                style="
                                  position: relative;
                                  border-bottom: 1px dashed #000;
                                  padding: 9px 0 8px 0;
                                "
                              >
                                <div
                                  v-for="data in viewContent[`rentSubTotalListBy${arrIdx}`]"
                                  :key="data.idx"
                                >
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: calc(100% - 110px);
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.productName }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 35px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.saleQty) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 75px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.totAmt) }}
                                  </div>
                                  <div style="clear: both; height: 0"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="
                                !!viewContent[`grillInfoListBy${arrIdx}`] &&
                                viewContent[`grillInfoListBy${arrIdx}`].length > 0
                              "
                            >
                              <div
                                style="
                                  position: relative;
                                  border-bottom: 1px dashed #ccc;
                                  padding: 9px 0 8px 0;
                                "
                              >
                                <div>[{{ viewContent[`grillTitleBy${arrIdx}`] }}]</div>
                                <div
                                  v-for="data in viewContent[`grillInfoListBy${arrIdx}`]"
                                  :key="data.idx"
                                >
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: calc(100% - 190px);
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.productName }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 55px;
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.subProductName ? data.subProductName : "&nbsp;" }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 30px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ data.isDc ? data.saleQty : getFormattedNumber(data.saleQty) }}
                                  </div>
                                  <div
                                      style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 40px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getInsertDtMinute(data.insertDt) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 65px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.totAmt) }}
                                  </div>
                                  <div style="clear: both; height: 0"></div>
                                </div>
                              </div>
                              <div
                                style="
                                  position: relative;
                                  border-bottom: 1px dashed #000;
                                  padding: 9px 0 8px 0;
                                "
                              >
                                <div
                                  v-for="data in viewContent[`grillSubTotalListBy${arrIdx}`]"
                                  :key="data.idx"
                                >
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: calc(100% - 110px);
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.productName }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 35px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.saleQty) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 75px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.totAmt) }}
                                  </div>
                                  <div style="clear: both; height: 0"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="
                                !!viewContent[`startInfoListBy${arrIdx}`] &&
                                viewContent[`startInfoListBy${arrIdx}`].length > 0
                              "
                            >
                              <div
                                style="
                                  position: relative;
                                  border-bottom: 1px dashed #ccc;
                                  padding: 9px 0 8px 0;
                                "
                              >
                                <div>[{{ viewContent[`startTitleBy${arrIdx}`] }}]</div>
                                <div
                                  v-for="data in viewContent[`startInfoListBy${arrIdx}`]"
                                  :key="data.idx"
                                >
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: calc(100% - 190px);
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.productName }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 55px;
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.subProductName ? data.subProductName : "&nbsp;" }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 30px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ data.isDc ? data.saleQty : getFormattedNumber(data.saleQty) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 40px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getInsertDtMinute(data.insertDt) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 65px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.totAmt) }}
                                  </div>
                                  <div style="clear: both; height: 0"></div>
                                </div>
                              </div>
                              <div
                                style="
                                  position: relative;
                                  border-bottom: 1px dashed #000;
                                  padding: 9px 0 8px 0;
                                "
                              >
                                <div
                                  v-for="data in viewContent[`startSubTotalListBy${arrIdx}`]"
                                  :key="data.idx"
                                >
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: calc(100% - 110px);
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.productName }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 35px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.saleQty) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 75px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.totAmt) }}
                                  </div>
                                  <div style="clear: both; height: 0"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="
                                !!viewContent[`tee1InfoListBy${arrIdx}`] &&
                                viewContent[`tee1InfoListBy${arrIdx}`].length > 0
                              "
                            >
                              <div
                                style="
                                  position: relative;
                                  border-bottom: 1px dashed #ccc;
                                  padding: 9px 0 8px 0;
                                "
                              >
                                <div>[{{ viewContent[`tee1TitleBy${arrIdx}`] }}]</div>
                                <div
                                  v-for="data in viewContent[`tee1InfoListBy${arrIdx}`]"
                                  :key="data.idx"
                                >
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: calc(100% - 190px);
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.productName }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 55px;
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.subProductName ? data.subProductName : "&nbsp;" }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 30px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ data.isDc ? data.saleQty : getFormattedNumber(data.saleQty) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 40px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getInsertDtMinute(data.insertDt) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 65px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.totAmt) }}
                                  </div>
                                  <div style="clear: both; height: 0"></div>
                                </div>
                              </div>
                              <div
                                style="
                                  position: relative;
                                  border-bottom: 1px dashed #000;
                                  padding: 9px 0 8px 0;
                                "
                              >
                                <div
                                  v-for="data in viewContent[`tee1SubTotalListBy${arrIdx}`]"
                                  :key="data.idx"
                                >
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: calc(100% - 110px);
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.productName }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 35px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.saleQty) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 75px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.totAmt) }}
                                  </div>
                                  <div style="clear: both; height: 0"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="
                                !!viewContent[`tee2InfoListBy${arrIdx}`] &&
                                viewContent[`tee2InfoListBy${arrIdx}`].length > 0
                              "
                            >
                              <div
                                style="
                                  position: relative;
                                  border-bottom: 1px dashed #ccc;
                                  padding: 9px 0 8px 0;
                                "
                              >
                                <div>[{{ viewContent[`tee2TitleBy${arrIdx}`] }}]</div>
                                <div
                                  v-for="data in viewContent[`tee2InfoListBy${arrIdx}`]"
                                  :key="data.idx"
                                >
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: calc(100% - 190px);
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.productName }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 55px;
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.subProductName ? data.subProductName : "&nbsp;" }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 30px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ data.isDc ? data.saleQty : getFormattedNumber(data.saleQty) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 40px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getInsertDtMinute(data.insertDt) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 65px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.totAmt) }}
                                  </div>
                                  <div style="clear: both; height: 0"></div>
                                </div>
                              </div>
                              <div
                                style="
                                  position: relative;
                                  border-bottom: 1px dashed #000;
                                  padding: 9px 0 8px 0;
                                "
                              >
                                <div
                                  v-for="data in viewContent[`tee2SubTotalListBy${arrIdx}`]"
                                  :key="data.idx"
                                >
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: calc(100% - 110px);
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.productName }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 35px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.saleQty) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 75px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.totAmt) }}
                                  </div>
                                  <div style="clear: both; height: 0"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="
                                !!viewContent[`tee3InfoListBy${arrIdx}`] &&
                                viewContent[`tee3InfoListBy${arrIdx}`].length > 0
                              "
                            >
                              <div
                                style="
                                  position: relative;
                                  border-bottom: 1px dashed #ccc;
                                  padding: 9px 0 8px 0;
                                "
                              >
                                <div>[{{ viewContent[`tee3TitleBy${arrIdx}`] }}]</div>
                                <div
                                  v-for="data in viewContent[`tee3InfoListBy${arrIdx}`]"
                                  :key="data.idx"
                                >
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: calc(100% - 190px);
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.productName }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 55px;
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.subProductName ? data.subProductName : "&nbsp;" }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 30px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ data.isDc ? data.saleQty : getFormattedNumber(data.saleQty) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 40px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getInsertDtMinute(data.insertDt) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 65px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.totAmt) }}
                                  </div>
                                  <div style="clear: both; height: 0"></div>
                                </div>
                              </div>
                              <div
                                style="
                                  position: relative;
                                  border-bottom: 1px dashed #000;
                                  padding: 9px 0 8px 0;
                                "
                              >
                                <div
                                  v-for="data in viewContent[`tee3SubTotalListBy${arrIdx}`]"
                                  :key="data.idx"
                                >
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: calc(100% - 110px);
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.productName }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 35px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.saleQty) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 75px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.totAmt) }}
                                  </div>
                                  <div style="clear: both; height: 0"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="
                                !!viewContent[`shopInfoListBy${arrIdx}`] &&
                                viewContent[`shopInfoListBy${arrIdx}`].length > 0
                              "
                            >
                              <div
                                style="
                                  position: relative;
                                  border-bottom: 1px dashed #ccc;
                                  padding: 9px 0 8px 0;
                                "
                              >
                                <div>[{{ viewContent[`shopTitleBy${arrIdx}`] }}]</div>
                                <div
                                  v-for="data in viewContent[`shopInfoListBy${arrIdx}`]"
                                  :key="data.idx"
                                >
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: calc(100% - 190px);
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.productName }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 55px;
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.subProductName ? data.subProductName : "&nbsp;" }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 30px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ data.isDc ? data.saleQty : getFormattedNumber(data.saleQty) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 40px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getInsertDtMinute(data.insertDt) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 65px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.totAmt) }}
                                  </div>
                                  <div style="clear: both; height: 0"></div>
                                </div>
                              </div>
                              <div
                                style="
                                  position: relative;
                                  border-bottom: 1px dashed #000;
                                  padding: 9px 0 8px 0;
                                "
                              >
                                <div
                                  v-for="data in viewContent[`shopSubTotalListBy${arrIdx}`]"
                                  :key="data.idx"
                                >
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: calc(100% - 110px);
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.productName }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 35px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.saleQty) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 75px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.totAmt) }}
                                  </div>
                                  <div style="clear: both; height: 0"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="
                                !!viewContent[`cfrontInfoListBy${arrIdx}`] &&
                                viewContent[`cfrontInfoListBy${arrIdx}`].length > 0
                              "
                            >
                              <div
                                style="
                                  position: relative;
                                  border-bottom: 1px dashed #ccc;
                                  padding: 9px 0 8px 0;
                                "
                              >
                                <div>[{{ viewContent[`cfrontTitleBy${arrIdx}`] }}]</div>
                                <div
                                  v-for="data in viewContent[`cfrontInfoListBy${arrIdx}`]"
                                  :key="data.idx"
                                >
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: calc(100% - 190px);
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.productName }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 55px;
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.subProductName ? data.subProductName : "&nbsp;" }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 30px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ data.isDc ? data.saleQty : getFormattedNumber(data.saleQty) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 40px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getInsertDtMinute(data.insertDt) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 65px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.totAmt) }}
                                  </div>
                                  <div style="clear: both; height: 0"></div>
                                </div>
                              </div>
                              <div
                                style="
                                  position: relative;
                                  border-bottom: 1px dashed #000;
                                  padding: 9px 0 8px 0;
                                "
                              >
                                <div
                                  v-for="data in viewContent[`cfrontSubTotalListBy${arrIdx}`]"
                                  :key="data.idx"
                                >
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: calc(100% - 110px);
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.productName }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 35px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.saleQty) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 75px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.totAmt) }}
                                  </div>
                                  <div style="clear: both; height: 0"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="
                                !!viewContent[`corderInfoListBy${arrIdx}`] &&
                                viewContent[`corderInfoListBy${arrIdx}`].length > 0
                              "
                            >
                              <div
                                style="
                                  position: relative;
                                  border-bottom: 1px dashed #ccc;
                                  padding: 9px 0 8px 0;
                                "
                              >
                                <div>[{{ viewContent[`corderTitleBy${arrIdx}`] }}]</div>
                                <div
                                  v-for="data in viewContent[`corderInfoListBy${arrIdx}`]"
                                  :key="data.idx"
                                >
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: calc(100% - 190px);
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.productName }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 55px;
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.subProductName ? data.subProductName : "&nbsp;" }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 30px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ data.isDc ? data.saleQty : getFormattedNumber(data.saleQty) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 40px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getInsertDtMinute(data.insertDt) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 65px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.totAmt) }}
                                  </div>
                                  <div style="clear: both; height: 0"></div>
                                </div>
                              </div>
                              <div
                                style="
                                  position: relative;
                                  border-bottom: 1px dashed #000;
                                  padding: 9px 0 8px 0;
                                "
                              >
                                <div
                                  v-for="data in viewContent[`corderSubTotalListBy${arrIdx}`]"
                                  :key="data.idx"
                                >
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: calc(100% - 110px);
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.productName }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 35px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.saleQty) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 75px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.totAmt) }}
                                  </div>
                                  <div style="clear: both; height: 0"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="
                                !!viewContent[`crentInfoListBy${arrIdx}`] &&
                                viewContent[`crentInfoListBy${arrIdx}`].length > 0
                              "
                            >
                              <div
                                style="
                                  position: relative;
                                  border-bottom: 1px dashed #ccc;
                                  padding: 9px 0 8px 0;
                                "
                              >
                                <div>[{{ viewContent[`crentTitleBy${arrIdx}`] }}]</div>
                                <div
                                  v-for="data in viewContent[`crentInfoListBy${arrIdx}`]"
                                  :key="data.idx"
                                >
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: calc(100% - 190px);
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.productName }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 55px;
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.subProductName ? data.subProductName : "&nbsp;" }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 30px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ data.isDc ? data.saleQty : getFormattedNumber(data.saleQty) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 40px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getInsertDtMinute(data.insertDt) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 65px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.totAmt) }}
                                  </div>
                                  <div style="clear: both; height: 0"></div>
                                </div>
                              </div>
                              <div
                                style="
                                  position: relative;
                                  border-bottom: 1px dashed #000;
                                  padding: 9px 0 8px 0;
                                "
                              >
                                <div
                                  v-for="data in viewContent[`crentSubTotalListBy${arrIdx}`]"
                                  :key="data.idx"
                                >
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: calc(100% - 110px);
                                      padding-right: 6px;
                                    "
                                  >
                                    {{ data.productName }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: left;
                                      width: 35px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.saleQty) }}
                                  </div>
                                  <div
                                    style="
                                      box-sizing: border-box;
                                      float: right;
                                      width: 75px;
                                      padding-left: 6px;
                                      text-align: right;
                                    "
                                  >
                                    {{ getFormattedNumber(data.totAmt) }}
                                  </div>
                                  <div style="clear: both; height: 0"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            style="
                              position: relative;
                              padding: 9px 0 0 0;
                            "
                          >
                            <div v-if="isDcListView && viewContent.dcAmt">
                              <div
                                style="
                                  box-sizing: border-box;
                                  float: left;
                                  width: calc(100% - 110px);
                                  padding-right: 6px;
                                "
                              >
                                할인금액
                              </div>
                              <div
                                style="
                                  box-sizing: border-box;
                                  float: right;
                                  width: 75px;
                                  padding-left: 6px;
                                  text-align: right;
                                "
                              >
                                {{ getFormattedNumber(viewContent.dcAmt) }}
                              </div>
                              <div style="clear: both; height: 0"></div>
                            </div>
                          </div>
                          <div
                            style="
                              position: relative;
                              border-bottom: 1px dashed #000;
                              padding: 0 0 8px 0;
                            "
                          >
                            <div
                              v-for="data in viewContent.storeTotalInfoList"
                              :key="data.idx"
                            >
                              <div
                                style="
                                  box-sizing: border-box;
                                  float: left;
                                  width: calc(100% - 110px);
                                  padding-right: 6px;
                                "
                              >
                                결제금액
                              </div>
                              <div
                                style="
                                  box-sizing: border-box;
                                  float: right;
                                  width: 75px;
                                  padding-left: 6px;
                                  text-align: right;
                                "
                              >
                                {{ getFormattedNumber(data.totAmt) }}
                              </div>
                              <div style="clear: both; height: 0"></div>
                            </div>
                          </div>
                          <div style="position: relative; padding: 15px 0 0 0">
                            <div>
                              <div
                                style="
                                  box-sizing: border-box;
                                  float: left;
                                  width: 103px;
                                  padding-right: 6px;
                                "
                              >
                                사업자 명
                              </div>
                              <div
                                style="
                                  box-sizing: border-box;
                                  float: left;
                                  width: calc(100% - 103px);
                                  padding-left: 6px;
                                "
                              >
                                {{ viewContent.bizName }}
                              </div>
                              <div style="clear: both; height: 0"></div>
                            </div>
                            <div>
                              <div
                                style="
                                  box-sizing: border-box;
                                  float: left;
                                  width: 103px;
                                  padding-right: 6px;
                                "
                              >
                                사업자번호
                              </div>
                              <div
                                style="
                                  box-sizing: border-box;
                                  float: left;
                                  width: calc(100% - 103px);
                                  padding-left: 6px;
                                "
                              >
                                {{ viewContent.bizNo }}
                              </div>
                              <div style="clear: both; height: 0"></div>
                            </div>
                            <div style="padding-top: 11px">
                              TEL : {{ viewContent.telNo }} / FAX :
                              {{ viewContent.faxNo }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="print keyColor">
              <erp-button
                  button-div="PRINT"
                  :is-icon-custom="true"
                  :is-custom-shortcut-button="true"
                  shortcut-key="payReceiptPopup.shortcuts.print"
                  :shortcut="{key: 'Enter'}"
                  @click.native="print"
              >출력
              </erp-button>
            </li>
            <li v-if="isClientMonitorUse">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  @click.native="clientMonitor
              ">
                고객 모니터
                <span
                    v-if="clientMonitorState"
                    style="color: blue; font-weight: bold"
                >
                  OFF
                </span>
                  <span
                      v-else
                      style="color: red; font-weight: bold"
                  >
                  ON
                </span>
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closePayReceiptPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import {
  commonCodesGetComName,
  commonCodesGetCommonCodeByIdxAttrb,
  commonCodesGetCommonCode, commonCodesGetStandardInfo,
} from "@/utils/commonCodes";
import BillPrintUtil from "@/utils/billPrintUtil";
import { getPayReceipt } from "@/api/common";
import { commonCodesGetBizMstInfo } from "@/utils/commonCodes";
import { getFormattedDate } from "@/utils/date";
import { getFormattedTelNumber } from "@/utils/string";
import { getDeviceInfo } from "@/utils/device";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { sortBy as _sortBy, groupBy as _groupBy } from "lodash";
import {getStatusByTeamInfo} from '@/api/salesManagement';
import {SHORTCUT_KEYS} from "@/utils/KeyboardUtil";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "payReceiptPopup",
  mixins: [confirmDialogMixin],
  components:{  ErpButton },
  async created() {
    await this.getContents();
  },
  data() {
    return {
      popupData: {},
      selectDiv: "",
      printFlag: "PROD",
      isHideCancelSlip: true,
      isDcListView: false,
      isDateGroup: false,
      allStoreGroups: commonCodesGetCommonCodeByIdxAttrb("STORE_GROUP", 1, "Y"),
      storeCodes: [],
      receiptInfo: [],
      greenFeeTaxInfo: {},
      allStoreCode: commonCodesGetCommonCode("STORE_CODE"),
      bizMstInfo: commonCodesGetBizMstInfo(),
      viewContent: null,
      lineContent: "--------------------------------------",
      paddingLineContent: "------------------------------",
      clientMonitorState: false,
      asyncShortcutButton: false
    };
  },
  computed: {
    isClientMonitorUse() {
      return getDeviceInfo().isClientMonitorUse;
    },
    payReceiptButtonProps() {
      return {
        shortcutKey: "BatchSmsCouponSendPopup.shortcuts.sendSms",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.Enter,
        },
      };
    },
    courseClass(){
      return commonCodesGetStandardInfo("courseClass");
    },

  },
  methods: {
    commonCodesGetComName,
    async showPayReceiptPopup(popupData) {
      this.popupData = popupData;

      // 팀 선택 radiobutton 초기값 설정
      if (this.popupData.divideDiv === "G") {
        this.selectDiv = "GRP";
      } else {
        this.selectDiv = "TEAM";
      }

      // 매장은 모두 선택
      this.storeCodes = this.allStoreGroups.map((group) => group.comCode).filter(item => !["FRONT","CART","RENT"].includes(item));

      await this.getPayReceiptApi();
    },
    onPayReceiptPopupClosed() {
      if (this.isClientMonitorUse) {
        this.clientMonitorState = false;
        this.sendWebSocket('mode', false);
      }
      this.$emit("popupClosed");
    },
    async clientMonitor(isAutoChange = true) {
      if (isAutoChange) {
        this.clientMonitorState = !this.clientMonitorState;
        this.sendWebSocket('mode', this.clientMonitorState);
      }
      if (!this.clientMonitorState) {
        return;
      }
      const {
        bsnDate,
        chkinId,
        grpName,
        grpNo,
        visitId,
      } = this.popupData;
      const selFlag = this.selectDiv === 'GRP'
          ? 1
          : this.selectDiv === 'FIT'
              ? 2
              : 3;
      const storeCodes = this.allStoreGroups.length !== this.storeCodes.length
        ? this.storeCodes
        : null;
      const {value: {salesReportByTeamList}} = await getStatusByTeamInfo(
          bsnDate,
          chkinId,
          grpName,
          grpNo,
          selFlag,
          storeCodes,
          visitId,
      );
      const getStoreList = attrb => commonCodesGetCommonCodeByIdxAttrb('STORE_CODE', 3, attrb)
          ?.map(item => item.comCode);
      const feeList = getStoreList('FEE');
      const rentList = getStoreList('RENT');
      const foodList = getStoreList('FOOD');
      const shopList = getStoreList('SHOP');
      const roomList = getStoreList('ROOM');
      const outputList = [].concat(foodList, shopList, feeList, rentList, roomList);
      const result = {};
      result.feeAmt = result.rentAmt = result.foodAmt = result.shopAmt = result.roomAmt = 0;
      const getStoreAmt = (arr, key) => arr?.forEach(item => result[key] += (salesReportByTeamList.filter(i => i.storeCode === item)
          ?.reduce((acc, cur) => acc + cur.totAmt, 0) || 0));
      getStoreAmt(feeList, 'feeAmt');
      getStoreAmt(rentList, 'rentAmt');
      getStoreAmt(foodList, 'foodAmt');
      getStoreAmt(shopList, 'shopAmt');
      getStoreAmt(roomList, 'roomAmt');
      result.totAmt = result.feeAmt + result.rentAmt + result.foodAmt + result.shopAmt + result.roomAmt;
      const grpLabel = this.selectDiv === 'GRP'
          ? '단체 ('
          : this.selectDiv === 'FIT'
              ? '개인 ('
              : '팀 (';
      result.visitInfo = {
        grpName: this.popupData.grpName
            ? `${grpLabel}${this.popupData.grpName})`
            : '',
        visitName: this.popupData.visitName,
        time: this.popupData.startTime,
        course: this.popupData.startCourse,
      };
      result.output = outputList;
      result.detail = salesReportByTeamList
          ?.filter(item => outputList.includes(item.storeCode))
          ?.map(item => {
            item.storeName = commonCodesGetComName('STORE_CODE', item.storeCode);
            item.productName = item.productName + (item.caddieUseFlag ? '(캐디)' : '');
            return item;
          });
      this.sendWebSocket('bill', result);
    },
    async print() {
      this.asyncShortcutButton = true;
      // 디바이스 설정의 Bill 프린터 사용여부가 false이면 Bill 프린터 출력 안됨.
      let receiptCode = null;
      this.printFlag === "PROD" ? receiptCode = "PAY_RECEIPT" : receiptCode = "STORE_RECEIPT";

      let printSetData = null;

      if (this.printFlag === "PROD") {
        const dcAmt = this.receiptInfo.filter(item => item.isDc).map(item => item.totAmt).reduce((acc, cur) => cur + acc, 0);
        printSetData = {
          teamGrpPersName:
            this.selectDiv === "GRP"
              ? "단체"
              : this.selectDiv === "FIT"
              ? "개인"
              : "팀",
          visitDate: this.popupData.bsnDate,
          visitName: this.popupData.visitName,
          storeTotalInfoList: this.receiptInfo.filter(
            (item) => item.sortNo === 999 && item.visitSno === 999
          ),
          bizNo: this.bizMstInfo.bizNo,
          bizName: this.bizMstInfo.bizName,
          telNo: this.bizMstInfo.telNo,
          faxNo: this.bizMstInfo.faxNo,
          dcAmt: dcAmt ? dcAmt : null,
          date1: this.isDateGroup ? "[1일차]" : null,
          date2: this.isDateGroup ? "[2일차]" : null,
        };

        let cartData = {
          classCode1: "CART",
          idx: null,
          productCode: null,
          productName: "카트료",
          saleQty: 0,
          sortNo: null,
          storeCode: "CART",
          totAmt: 0,
          visitSno: null,
        };

        const groupByDate =
          this.isDateGroup ?
            Object.keys(_groupBy(this.receiptInfo.filter(item => item.salesDate), "salesDate")) :
            [0];

        for (let a = 0; a < groupByDate.length; a++) {
          const salesDate = groupByDate[a];
          for (let i = 0; i < this.allStoreCode.length; i++) {
            let storeCode = this.allStoreCode[i].comCode;
            let colName = storeCode.toLowerCase() + `InfoListBy${a}`;
            let subTotalColName = storeCode.toLowerCase() + `SubTotalListBy${a}`;
            let titleColName = storeCode.toLowerCase() + "Title";

            let storeData = this.receiptInfo.filter(
              (item) => (this.isDateGroup ? item.salesDate === salesDate : true) && item.storeCode === storeCode && item.visitSno !== 999
            );
            let storeSubTotal = this.receiptInfo.filter(
              (item) => (this.isDateGroup ? item.salesDate === salesDate : true) && item.storeCode === storeCode && item.visitSno === 999
            );

            // 정산내역서의 카트대여 부분을 하나로 표시하기 위한 작업.
            if (storeCode === "CART") {
              storeData.forEach((data) => {
                if (data.classCode1 === "CART" && !data.isDc) {
                  data.productName = cartData.productName;
                }
              });

              if (cartData.idx !== null) {
                storeData = [cartData];
              }
            }

            printSetData[colName] = storeData;
            if (storeData !== null && storeData.length > 0) {
              printSetData[subTotalColName] = storeSubTotal;
              printSetData[
                titleColName
                ] = commonCodesGetComName(
                "STORE_CODE",
                storeCode
              );
            }
          }
        }
      } else if (this.printFlag === "TOT") {
        printSetData = {
          teamGrpPersName:
            this.selectDiv === "GRP"
              ? "단체"
              : this.selectDiv === "FIT"
              ? "개인"
              : "팀",
          startTime: this.popupData.startTime,
          visitDate: this.popupData.bsnDate,
          visitName: this.popupData.visitName,
          storeTotalInfoList: this.receiptInfo.filter(
            (item) => item.sortNo === 999 && item.visitSno === 999
          ),
          greenfeeInfoList: this.receiptInfo.filter(
            (item) => item.storeCode === "FRONT" && item.sortNo === 999
          ),
          frontInfoList: this.receiptInfo.filter(
            (item) =>
              item.storeCode === "FRONT" &&
              item.sortNo !== 999 &&
              item.visitSno !== 999
          ),
          golfInfoList: this.receiptInfo.filter(
            (item) =>
              item.storeCode !== "FRONT" &&
              item.bsnDiv === "GOLF" &&
              item.visitSno !== 999
          ),
          foodInfoList: this.receiptInfo.filter(
            (item) => item.bsnDiv === "FOOD" && item.sortNo !== 999
          ),
          foodTotalInfoList: this.receiptInfo.filter(
            (item) => item.bsnDiv === "FOOD" && item.sortNo === 999
          ),
          shopInfoList: this.receiptInfo.filter(
            (item) => item.bsnDiv === "SHOP" && item.sortNo !== 999
          ),
          cfrontInfoList: this.receiptInfo.filter(
            (item) => item.bsnDiv === "ROOM" && item.sortNo !== 999
          ),
          cfrontTotalInfoList: this.receiptInfo.filter(
            (item) => item.bsnDiv === "ROOM" && item.sortNo === 999
          ),
          totalInfoList: this.receiptInfo.filter(
            (item) => item.sortNo === 999 && item.visitSno === 999
          ),
          specialTax: !!this.greenFeeTaxInfo && this.greenFeeTaxInfo.specialTax !== null && this.greenFeeTaxInfo.specialTax !== undefined ? this.greenFeeTaxInfo.specialTax : 0,
          eduTax: !!this.greenFeeTaxInfo && this.greenFeeTaxInfo.eduTax !== null && this.greenFeeTaxInfo.eduTax !== undefined ? this.greenFeeTaxInfo.eduTax : 0,
          vilTax: !!this.greenFeeTaxInfo && this.greenFeeTaxInfo.vilTax !== null && this.greenFeeTaxInfo.vilTax !== undefined ? this.greenFeeTaxInfo.vilTax : 0,
          vatAmt: !!this.greenFeeTaxInfo && this.greenFeeTaxInfo.vatAmt !== null && this.greenFeeTaxInfo.vatAmt !== undefined ? this.greenFeeTaxInfo.vatAmt : 0,
          fund: !!this.greenFeeTaxInfo && this.greenFeeTaxInfo.fund !== null && this.greenFeeTaxInfo.fund !== undefined ? this.greenFeeTaxInfo.fund : 0,
          greenFee: !!this.greenFeeTaxInfo && this.greenFeeTaxInfo.greenFee !== null && this.greenFeeTaxInfo.greenFee !== undefined ? this.greenFeeTaxInfo.greenFee : 0,
          bizNo: this.bizMstInfo.bizNo,
          bizName: this.bizMstInfo.bizName,
          telNo: this.bizMstInfo.telNo,
          faxNo: this.bizMstInfo.faxNo,
        };
      }
      const config = {
        "useBillPrinter": true,
        "useKitchenPrinter": false,
      };
      await BillPrintUtil.getTsConfReceiptInfo.call(this, receiptCode, true,printSetData, config);
      this.asyncShortcutButton = false;

    },
    async getContents() {
      if (this.printFlag === "PROD") {
        this.viewContent = {
          teamGrpPersName:
            this.selectDiv === "GRP"
              ? "단체"
              : this.selectDiv === "FIT"
              ? "개인"
              : "팀",
          visitDate: getFormattedDate(this.popupData.bsnDate, "YYYY/MM/DD"),
          visitName: this.popupData.visitName,
          storeTotalInfoList: this.receiptInfo.filter(
            (item) => item.sortNo === 999 && item.visitSno === 999
          ),
          bizNo: this.bizMstInfo.bizNo,
          bizName: this.bizMstInfo.bizName,
          telNo: getFormattedTelNumber(this.bizMstInfo.telNo, ")"),
          faxNo: getFormattedTelNumber(this.bizMstInfo.faxNo, ")"),
          dcAmt: this.receiptInfo.filter(item => item.isDc).map(item => item.totAmt).reduce((acc, cur) => cur + acc, 0),
        };

        let cartData = {
          classCode1: "CART",
          idx: null,
          productCode: null,
          productName: "카트료",
          saleQty: 0,
          sortNo: null,
          storeCode: "CART",
          totAmt: 0,
          visitSno: null,
        };

        const groupByDate =
          this.isDateGroup ?
            Object.keys(_groupBy(this.receiptInfo.filter(item => item.salesDate), "salesDate")) :
            [0];

        for (let a = 0; a < groupByDate.length; a++) {
          const salesDate = groupByDate[a];
          for (let i = 0; i < this.allStoreCode.length; i++) {
            let storeCode = this.allStoreCode[i].comCode;
            let colName = storeCode.toLowerCase() + `InfoListBy${a}`;
            let subTotalColName = storeCode.toLowerCase() + `SubTotalListBy${a}`;
            let titleColName = storeCode.toLowerCase() + `TitleBy${a}`;

            let storeData = this.receiptInfo.filter(
              (item) => (this.isDateGroup ? item.salesDate === salesDate : true) && item.storeCode === storeCode && item.visitSno !== 999
            );
            let storeSubTotal = this.receiptInfo.filter(
              (item) => (this.isDateGroup ? item.salesDate === salesDate : true) && item.storeCode === storeCode && item.visitSno === 999
            );

            // 정산내역서의 카트대여 부분을 하나로 표시하기 위한 작업.
            if (storeCode === "CART") {
              storeData.forEach((data) => {
                if (data.classCode1 === "CART" && !data.isDc) {
                  data.productName = cartData.productName;
                }
              });
            }

            this.viewContent[colName] = storeData;
            if (storeData !== null && storeData.length > 0) {
              this.viewContent[subTotalColName] = storeSubTotal;
              this.viewContent[titleColName] = commonCodesGetComName("STORE_CODE", storeCode);
            }
          }
        }
      } else if (this.printFlag === "TOT") {
        this.viewContent = {
          teamGrpPersName: this.selectDiv === "GRP" ? "단체" : this.selectDiv === "FIT" ? "개인" : "팀",
          startTime: this.popupData.startTime,
          visitDate: getFormattedDate(this.popupData.bsnDate, "YYYY/MM/DD"),
          visitName: this.popupData.visitName,
          storeTotalInfoList: this.receiptInfo.filter(
            (item) => item.sortNo === 999 && item.visitSno === 999
          ),
          greenfeeInfoList: this.receiptInfo.filter(
            (item) => item.storeCode === "FRONT" && item.sortNo === 999
          ),
          frontInfoList: this.receiptInfo.filter(
            (item) =>
              item.storeCode === "FRONT" &&
              item.sortNo !== 999 &&
              item.visitSno !== 999
          ),
          golfInfoList: this.receiptInfo.filter(
            (item) =>
              item.storeCode !== "FRONT" &&
              item.bsnDiv === "GOLF" &&
              item.visitSno !== 999
          ),
          foodInfoList: this.receiptInfo.filter(
            (item) => item.bsnDiv === "FOOD" && item.sortNo !== 999
          ),
          foodTotalInfoList: this.receiptInfo.filter(
            (item) => item.bsnDiv === "FOOD" && item.sortNo === 999
          ),
          shopInfoList: this.receiptInfo.filter(
            (item) => item.bsnDiv === "SHOP" && item.sortNo !== 999
          ),
          cfrontInfoList: this.receiptInfo.filter(
            (item) => item.bsnDiv === "ROOM" && item.sortNo !== 999
          ),
          cfrontTotalInfoList: this.receiptInfo.filter(
            (item) => item.bsnDiv === "ROOM" && item.sortNo === 999
          ),
          totalInfoList: this.receiptInfo.filter(
            (item) => item.sortNo === 999 && item.visitSno === 999
          ),
          dcAmt: this.receiptInfo.filter(item => item.isDc).map(item => item.dcAmt).reduce((acc, cur) => cur + acc, 0),
          specialTax: !!this.greenFeeTaxInfo && this.greenFeeTaxInfo.specialTax !== null && this.greenFeeTaxInfo.specialTax !== undefined ? this.greenFeeTaxInfo.specialTax : 0,
          eduTax: !!this.greenFeeTaxInfo && this.greenFeeTaxInfo.eduTax !== null && this.greenFeeTaxInfo.eduTax !== undefined       ? this.greenFeeTaxInfo.eduTax : 0,
          vilTax: !!this.greenFeeTaxInfo && this.greenFeeTaxInfo.vilTax !== null && this.greenFeeTaxInfo.vilTax !== undefined       ? this.greenFeeTaxInfo.vilTax : 0,
          vatAmt: !!this.greenFeeTaxInfo && this.greenFeeTaxInfo.vatAmt !== null && this.greenFeeTaxInfo.vatAmt !== undefined       ? this.greenFeeTaxInfo.vatAmt : 0,
          fund: !!this.greenFeeTaxInfo && this.greenFeeTaxInfo.fund !== null && this.greenFeeTaxInfo.fund !== undefined             ? this.greenFeeTaxInfo.fund : 0,
          greenFee: !!this.greenFeeTaxInfo && this.greenFeeTaxInfo.greenFee !== null && this.greenFeeTaxInfo.greenFee !== undefined ? this.greenFeeTaxInfo.greenFee : 0,
          bizNo: this.bizMstInfo.bizNo,
          bizName: this.bizMstInfo.bizName,
          telNo: getFormattedTelNumber(this.bizMstInfo.telNo, ")"),
          faxNo: getFormattedTelNumber(this.bizMstInfo.faxNo, ")"),
        };
      }
      if (this.clientMonitorState) {
        await this.clientMonitor(false);
      }
    },
    closePayReceiptPopup() {
      if (this.isClientMonitorUse) {
        this.sendWebSocket('mode', false);
      }
      this.$refs.payReceiptPopup.hide();
    },
    async getPayReceiptApi() {
      let data = {
        bsnDate: this.popupData.bsnDate,
        chkinId: this.popupData.chkinId,
        grpNo: this.selectDiv === "GRP" ? this.popupData.grpNo : null,
        visitId: this.selectDiv === "FIT" ? this.popupData.visitId : null,
        printFlag: this.printFlag,
        storeGroup: this.storeCodes.join(),
        isHideCancelSlip: this.isHideCancelSlip,
        isDcListView: this.isDcListView,
        isDateGroup: this.isDateGroup,
      };

      const response = await getPayReceipt(data);
      let cartData = null;
      this.receiptInfo = _sortBy(response.value.payReceiptInfoList.map(item => {
        if (item.isDc && item.classCode1 === "CART") {
          if (cartData) {
            item.saleQty = Math.abs(item.totAmt) / cartData.totAmt * 100;
          }
        } else if (!item.isDc && item.classCode1 === "CART" && item.visitSno === 1) {
          cartData = JSON.parse(JSON.stringify(item));
        }
        return ({
          ...item,
          saleQty: item.isDc ? item.saleQty + "%" : item.saleQty,
        });
      }), [
        "salesDate",
        "startTime",
        "startCourse",
        "visitSno",
      ]);
      this.greenFeeTaxInfo = response.value.greenfeeTax;
      console.log('receiptInfo.===>', this.receiptInfo);

      await this.getContents();
    },
    async payReceiptSearchValueChanged() {
      await this.getPayReceiptApi();
    },
    async onIsHideCancelSlipChanged() {
      await this.getPayReceiptApi();
    },
    async onIsDcListViewChanged() {
      await this.getPayReceiptApi();
    },
    async storeCheckAllChecked(args) {
      if (args.target.checked) {
        this.storeCodes = this.allStoreGroups.map((group) => group.comCode);
      } else {
        this.storeCodes = [];
      }
      await this.getPayReceiptApi();
    },
    async storeCheckboxChecked(args, comCode) {
      if (args.target.checked) {
        this.storeCodes.push(comCode);
      } else {
        this.storeCodes.splice(
          this.storeCodes.findIndex((storeCode) => storeCode === comCode),
          1
        );
      }
      await this.getPayReceiptApi();
    },
    getFormattedNumber(number) {
      const result = new Intl.NumberFormat().format(number);
      return result === "NaN" ? 0 : result;
    },
    getInsertDtMinute(datetime) {
      return moment(datetime).format('HH:mm');
    }
  },
};
</script>

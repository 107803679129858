<template>
  <div>
    <ejs-dialog
      ref="dialog"
      header="회원정보 상세"
      allowDragging="true"
      enableResize="true"
      showCloseIcon="true"
      width="1180"
      height="850"
      v-bind="dialogProps"
      @beforeClose="onDialogBeforeClose"
    >
      <!-- TODO syncfusion dialog bug... -->
      <erp-button
        button-div="CLOSE"
        style="
          visibility: hidden;
          width: 0;
          height: 0;
          display: inline-block;
          position: fixed;
        "
      />
      <div class="window memberInformation">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">회원정보 상세</div>
                    </div>
                    <div class="header-right" v-if="!isCreateForm">
                      <ul class="header-button">
                        <li v-if="false" class="print">
                          <erp-button
                              button-div="PRINT"
                              :ignore="isPopupOpened"
                              @click.native="onClickMembershipCardPrint"
                          >
                            회원증 발급
                          </erp-button>
                        </li>
                        <li class="refresh">
                          <erp-button
                              button-div="GET"
                              :ignore="isPopupOpened"
                              :is-icon-custom="true"
                              :is-custom-shortcut-button="true"
                              shortcut-key="calendarRegistration.shortcuts.addCalendar"
                              :shortcut="{key: 'F2'}"
                            @click.native="onRefreshClick"
                          >
                            새로고침
                          </erp-button>
                        </li>
                        <li>
                          <erp-button
                              button-div="GET"
                              :ignore="isPopupOpened"
                              :is-icon-custom="true"
                              @click.native="onHistoryChangesClick"
                          >
                            변경이력
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <article class="body-article">
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0101" style="height: 60%">
<!--                        <div class="section-header">-->
<!--                          <div class="header-left">-->
<!--                            <div class="header-title">회원정보</div>-->
<!--                          </div>-->
<!--                        </div>-->
                        <div class="section-body">
                          <article class="body-article">
                            <div class="article-left">
                              <!-- 아코디언 : accordion / 닫힘 : close -->
                              <section :class="[ 'article-section', 'section-010101', 'accordion', { close: !visibleMemberInputs }, ]">
                                <div class="section-header">
                                  <div class="header-left">
                                    <div class="header-title" @click=" visibleMemberInputs = !visibleMemberInputs">
                                      회원 기본정보
                                    </div>
                                  </div>
                                </div>
                                <div class="section-body">
                                  <div class="body-data">
                                    <div class="data-outer">
                                      <div class="data-inner">
                                        <ul class="data-content">
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title required">
                                              회원명
                                            </div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-text
                                                      ref="input-name"
                                                      id="input-name"
                                                      name="name"
                                                      v-model="
                                                        memberInputs.name
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field dateOfBirth">
                                            <!-- 필수 : required -->
                                            <div class="title required">
                                              생년월일/성별
                                            </div>
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group birthday"
                                                  style="width: 144px; height: 23px;"
                                                >
                                                  <div class="form">
                                                    <input-birthday
                                                      ref="input-birthday"
                                                      name="birthday"
                                                      type="body-data"
                                                      v-model="
                                                        memberInputs.birthday
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                                <li
                                                  class="item form-group yang-yin"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          ref="input-lunarCode"
                                                          name="lunarCode"
                                                          value="SOLAR"
                                                          v-model="
                                                            memberInputs.lunarCode
                                                          "
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          양력
                                                        </div>
                                                      </label>
                                                    </li>
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          ref="input-lunarCode"
                                                          name="lunarCode"
                                                          value="LUNAR"
                                                          v-model="
                                                            memberInputs.lunarCode
                                                          "
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          음력
                                                        </div>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                </li>
                                                <li
                                                    class="item form-group sex"
                                                    style="width: 144px;"
                                                >
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                        ref="input-gender"
                                                        name="gender"
                                                        v-model="
                                                        memberInputs.gender
                                                      "
                                                        :dataSource="
                                                        commonCodesOfGenders
                                                      "
                                                        :allowFiltering="false"
                                                        :fields="commonCodeFields"
                                                        cssClass="body-data-dropdown"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">근무처</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-text
                                                        ref="workPlace"
                                                        name="workPlace"
                                                        v-model="
                                                        memberInputs.workPlace
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title required">
                                              핸드폰번호
                                            </div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div
                                                    v-if="
                                                      commonMixinHasCiperTextGet
                                                    "
                                                    class="form"
                                                  >
                                                    <component-telephone
                                                      ref="input-phoneNumber"
                                                      v-model="
                                                        memberInputs.phoneNumber
                                                      "
                                                      :max-length="11"
                                                      name="phoneNumber"
                                                    />
                                                  </div>
                                                  <template v-else>
                                                    {{ member.phoneNumber }}
                                                  </template>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title required">
                                              SMS 수신동의
                                            </div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                      ref="input-smsReceiptDivision"
                                                      name="smsReceiptDivision"
                                                      v-model="
                                                        memberInputs.smsReceiptDivision
                                                      "
                                                      :dataSource="
                                                        commonCodesOfSmsReceiptDivisions
                                                      "
                                                      :allowFiltering="false"
                                                      :fields="commonCodeFields"
                                                      cssClass="body-data-dropdown"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">웹 ID</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <span
                                                      v-if="
                                                      member.webIdInformation
                                                    "
                                                      style="
                                                      text-decoration: underline;
                                                      cursor: pointer;
                                                    "
                                                      @click="onCreateWebIdClick"
                                                  >
                                                    {{ member | getWebId }}
                                                  </span>
                                                  <span
                                                      v-else
                                                      style="
                                                      text-decoration: underline;
                                                      cursor: pointer;
                                                    "
                                                      @click="onCreateWebIdClick"
                                                  >
                                                    신규회원
                                                  </span>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">
                                              예약담당 휴대폰
                                            </div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div
                                                    v-if="
                                                      commonMixinHasCiperTextGet
                                                    "
                                                    class="form"
                                                  >
                                                    <component-telephone
                                                      ref="input-reserveManagerPhoneNumber"
                                                      v-model="
                                                        memberInputs.reserveManagerPhoneNumber
                                                      "
                                                      :max-length="11"
                                                      name="reserveManagerPhoneNumber"
                                                    />
                                                  </div>
                                                  <template v-else>
                                                    {{
                                                      member.reserveManagerPhoneNumber
                                                    }}
                                                  </template>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">
                                              예약담당자명
                                            </div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-text
                                                      ref="input-reserveManagerName"
                                                      name="reserveManagerName"
                                                      v-model="
                                                        memberInputs.reserveManagerName
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">지역민 구분</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                        ref="input-nearbyCode"
                                                        name="nearbyCode"
                                                        v-model="
                                                        memberInputs.nearbyCode
                                                      "
                                                        :dataSource="
                                                        commonCodesOfNearByCodes
                                                      "
                                                        :allowFiltering="false"
                                                        :fields="commonCodeFields"
                                                        cssClass="body-data-dropdown"
                                                        @change="onNearbyCodeChange"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                        </ul>
                                        <div class="border-left"></div>
                                        <div class="border-right"></div>
                                        <div class="border-top"></div>
                                        <div class="border-bottom"></div>
                                      </div>
                                    </div>
                                    <div class="border-left"></div>
                                    <div class="border-right"></div>
                                    <div class="border-top"></div>
                                    <div class="border-bottom"></div>
                                  </div>
                                </div>
                              </section>
                              <!-- 아코디언 : accordion / 닫힘 : close -->
                              <section
                                :class="[
                                  'article-section',
                                  'section-010103',
                                  'accordion',
                                  { close: !visibleMemberDetailsInputs },
                                ]"
                              >
                                <div class="section-header">
                                  <div class="header-left">
                                    <div
                                      class="header-title"
                                      @click="
                                        visibleMemberDetailsInputs = !visibleMemberDetailsInputs
                                      "
                                    >
                                      회원 부가정보
                                    </div>
                                  </div>
                                </div>
                                <div class="section-body">
                                  <div class="body-data">
                                    <div class="data-outer">
                                      <div class="data-inner">
                                        <ul class="data-content">
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">백네임</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-text
                                                      ref="input-bagName"
                                                      name="bagName"
                                                      v-model="
                                                        memberInputs.details
                                                          .bagName
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">영문명</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-text
                                                      ref="input-engName"
                                                      name="engName"
                                                      v-model="
                                                        memberInputs.details
                                                          .engName
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">한자명</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-text
                                                      id="input-chnName"
                                                      name="chnName"
                                                      v-model="
                                                        memberInputs.details
                                                          .chnName
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">
                                              VIP 여부
                                            </div>
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input type="radio" v-model=" memberInputs.details.vipFlag" name="vipFlag" :value="true"/>
                                                        <i></i>
                                                        <div class="label">예</div>
                                                      </label>
                                                    </li>
                                                    <li>
                                                      <label>
                                                        <input type="radio" v-model=" memberInputs.details.vipFlag" name="vipFlag" :value="false"/>
                                                        <i></i>
                                                        <div class="label">아니오</div>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">
                                              셀프캐디 가능
                                            </div>
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                    class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                            ref="input-isSelfCaddie"
                                                            type="radio"
                                                            v-model="
                                                            memberInputs.details
                                                              .isSelfCaddie
                                                          "
                                                            name="isSelfCaddie"
                                                            :value="true"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          예
                                                        </div>
                                                      </label>
                                                    </li>
                                                    <li>
                                                      <label>
                                                        <input
                                                            ref="isSelfCaddie"
                                                            type="radio"
                                                            v-model="
                                                            memberInputs.details
                                                              .isSelfCaddie
                                                          "
                                                            name="isSelfCaddie"
                                                            :value="false"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          아니오
                                                        </div>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">직위명</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-text
                                                      ref="input-levelName"
                                                      name="levelName"
                                                      v-model="
                                                        memberInputs.details
                                                          .levelName
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">직업구분</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                      ref="input-workDivision"
                                                      name="workDivision"
                                                      v-model="
                                                        memberInputs.details
                                                          .workDivision
                                                      "
                                                      :dataSource="
                                                        commonCodesOfWorkDivisions
                                                      "
                                                      :allowFiltering="false"
                                                      :fields="commonCodeFields"
                                                      cssClass="body-data-dropdown"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">차량종류</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-text
                                                      ref="input-vehicleKind"
                                                      name="vehicleKind"
                                                      v-model="
                                                        memberInputs.details
                                                          .vehicleKind
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">차량번호</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-text
                                                      ref="input-vehicleNumber"
                                                      name="vehicleNumber"
                                                      v-model="
                                                        memberInputs.details
                                                          .vehicleNumber
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">핸디</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-number
                                                      ref="input-handicap"
                                                      v-model="
                                                        memberInputs.details
                                                          .handicap
                                                      "
                                                      :prop-max-length="3"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">집 전화번호</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <component-telephone
                                                      ref="input-homeTelephoneNumber"
                                                      name="homeTelephoneNumber"
                                                      v-model="
                                                        memberInputs.details
                                                          .homeTelephoneNumber
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">
                                              회사 전화번호
                                            </div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <component-telephone
                                                      ref="input-companyTelephoneNumber"
                                                      name="companyTelephoneNumber"
                                                      v-model="
                                                        memberInputs.details
                                                          .companyTelephoneNumber
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">팩스번호</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <component-telephone
                                                      ref="input-faxNumber"
                                                      name="faxNumber"
                                                      v-model="
                                                        memberInputs.details
                                                          .faxNumber
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">지역구분</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                      ref="input-areaCode"
                                                      name="areaCode"
                                                      v-model="
                                                        memberInputs.details
                                                          .areaCode
                                                      "
                                                      :dataSource="
                                                        commonCodesOfAreaCodes
                                                      "
                                                      :allowFiltering="false"
                                                      :fields="commonCodeFields"
                                                      cssClass="body-data-dropdown"
                                                      @change="onAreaCodeChanged"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">시군구</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                        ref="input-areaCode"
                                                        name="areaCode"
                                                        v-model="
                                                        memberInputs.details
                                                          .sigunCode
                                                      "
                                                        :dataSource="
                                                        postSigunsByComputed
                                                      "
                                                        :allowFiltering="false"
                                                        :fields="commonCodeFields"
                                                        cssClass="body-data-dropdown"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">내국인 구분</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                      ref="input-nativeDivision"
                                                      name="nativeDivision"
                                                      v-model="
                                                        memberInputs.details
                                                          .nativeDivision
                                                      "
                                                      :dataSource="
                                                        commonCodesOfNativeDivisions
                                                      "
                                                      :allowFiltering="false"
                                                      :fields="commonCodeFields"
                                                      cssClass="body-data-dropdown"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">국가구분</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                      ref="input-nationCode"
                                                      name="nationCode"
                                                      v-model="
                                                        memberInputs.details
                                                          .nationCode
                                                      "
                                                      :dataSource="
                                                        commonCodesOfNationCodes
                                                      "
                                                      :allowFiltering="false"
                                                      :fields="commonCodeFields"
                                                      cssClass="body-data-dropdown"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">
                                              우편물 수신동의
                                            </div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                      ref="input-postReceiptDivision"
                                                      name="postReceiptDivision"
                                                      v-model="
                                                        memberInputs.details
                                                          .postReceiptDivision
                                                      "
                                                      :dataSource="
                                                        commonCodesOfPostReceiptDivisions
                                                      "
                                                      :allowFiltering="false"
                                                      :fields="commonCodeFields"
                                                      cssClass="body-data-dropdown"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field eMail">
                                            <!-- 필수 : required -->
                                            <div class="title">이메일</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div
                                                    v-if="
                                                      commonMixinHasCiperTextGet
                                                    "
                                                    class="form"
                                                  >
                                                    <input-text
                                                      ref="input-email"
                                                      name="email"
                                                      v-model="
                                                        memberInputs.details
                                                          .email
                                                      "
                                                    />
                                                  </div>
                                                  <template v-else>
                                                    {{ member.details.email }}
                                                  </template>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">
                                              이메일 수신동의
                                            </div>
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                          ref="input-allowReceiptEmail"
                                                          type="radio"
                                                          v-model="
                                                            memberInputs.details
                                                              .allowReceiptEmail
                                                          "
                                                          name="allowReceiptEmail"
                                                          :value="true"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          예
                                                        </div>
                                                      </label>
                                                    </li>
                                                    <li>
                                                      <label>
                                                        <input
                                                          ref="input-allowReceiptEmail"
                                                          type="radio"
                                                          v-model="
                                                            memberInputs.details
                                                              .allowReceiptEmail
                                                          "
                                                          name="allowReceiptEmail"
                                                          :value="false"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          아니오
                                                        </div>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">
                                              제 3자 정보제공
                                            </div>
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                          ref="input-allowProvideThirdParties"
                                                          type="radio"
                                                          v-model="
                                                            memberInputs.details
                                                              .allowProvideThirdParties
                                                          "
                                                          name="allowProvideThirdParties"
                                                          :value="true"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          예
                                                        </div>
                                                      </label>
                                                    </li>
                                                    <li>
                                                      <label>
                                                        <input
                                                          ref="input-allowProvideThirdParties"
                                                          type="radio"
                                                          v-model="
                                                            memberInputs.details
                                                              .allowProvideThirdParties
                                                          "
                                                          name="allowProvideThirdParties"
                                                          :value="false"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          아니오
                                                        </div>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">
                                              마케팅 수신동의
                                            </div>
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                          ref="input-allowReceiptMarketing"
                                                          type="radio"
                                                          v-model="
                                                            memberInputs.details
                                                              .allowReceiptMarketing
                                                          "
                                                          name="allowReceiptMarketing"
                                                          :value="true"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          예
                                                        </div>
                                                      </label>
                                                    </li>
                                                    <li>
                                                      <label>
                                                        <input
                                                          ref="input-allowReceiptMarketing"
                                                          type="radio"
                                                          v-model="
                                                            memberInputs.details
                                                              .allowReceiptMarketing
                                                          "
                                                          name="allowReceiptMarketing"
                                                          :value="false"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          아니오
                                                        </div>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <div class="title">휴면전환코드</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                      ref="input-drmncyCnvrsCode"
                                                      name="drmncyCnvrsCode"
                                                      v-model="
                                                        memberInputs.details
                                                          .drmncyCnvrsCode
                                                      "
                                                      :dataSource="
                                                        commonCodesOfDrmncyCnvrsCodes
                                                      "
                                                      :allowFiltering="false"
                                                      :fields="commonCodeFields"
                                                      cssClass="body-data-dropdown"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field address">
                                            <!-- 필수 : required -->
                                            <div class="title">집 주소</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group post"
                                                >
                                                  <div class="form">
                                                    <input-text
                                                      ref="input-homePostalCode"
                                                      name="homePostalCode"
                                                      v-model="
                                                        memberInputs.details
                                                          .homePostalCode
                                                      "
                                                      maxlength="10"
                                                    />
                                                  </div>
                                                </li>
                                                <li
                                                  class="item form-group button"
                                                >
                                                  <ul class="button">
                                                    <li>
                                                      <erp-button
                                                          button-div="GET"
                                                          :is-icon-custom="true"
                                                          @click.native="onPostCodeClick('home')"
                                                      >
                                                        우편번호
                                                      </erp-button>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                              <ul class="row">
                                                <li
                                                  class="item form-group input"
                                                >
                                                  <div class="form">
                                                    <input-text
                                                      ref="input-homeAddress"
                                                      name="homeAddress"
                                                      v-model="
                                                        memberInputs.details
                                                          .homeAddress
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                                <li
                                                  class="item form-group input"
                                                >
                                                  <div class="form">
                                                    <input-text
                                                      ref="input-homeAdditionalAddress"
                                                      name="homeAdditionalAddress"
                                                      v-model="
                                                        memberInputs.details
                                                          .homeAdditionalAddress
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field address">
                                            <!-- 필수 : required -->
                                            <div class="title">회사 주소</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group post"
                                                >
                                                  <div class="form">
                                                    <input-text
                                                      ref="input-companyPostalCode"
                                                      name="companyPostalCode"
                                                      v-model="
                                                        memberInputs.details
                                                          .companyPostalCode
                                                      "
                                                      maxlength="10"
                                                    />
                                                  </div>
                                                </li>
                                                <li
                                                  class="item form-group button"
                                                >
                                                  <ul class="button">
                                                    <li>
                                                      <erp-button
                                                          button-div="GET"
                                                          :is-icon-custom="true"
                                                          @click.native="onPostCodeClick('company' )"
                                                      >
                                                        우편번호
                                                      </erp-button>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                              <ul class="row">
                                                <li
                                                  class="item form-group input"
                                                >
                                                  <div class="form">
                                                    <input-text
                                                      ref="input-companyAddress"
                                                      name="companyAddress"
                                                      v-model="
                                                        memberInputs.details
                                                          .companyAddress
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                                <li
                                                  class="item form-group input"
                                                >
                                                  <div class="form">
                                                    <input-text
                                                      ref="input-companyAdditionalAddress"
                                                      name="companyAdditionalAddress"
                                                      v-model="
                                                        memberInputs.details
                                                          .companyAdditionalAddress
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field address">
                                            <!-- 필수 : required -->
                                            <div class="title">
                                              예약담당자 주소
                                            </div>
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group post"
                                                >
                                                  <div class="form">
                                                    <input-text
                                                      ref="input-reserveManagerPostalCode"
                                                      name="reserveManagerPostalCode"
                                                      v-model="
                                                        memberInputs.details
                                                          .reserveManagerPostalCode
                                                      "
                                                      maxlength="10"
                                                    />
                                                  </div>
                                                </li>
                                                <li
                                                  class="item form-group button"
                                                >
                                                  <ul class="button">
                                                    <li>
                                                      <erp-button
                                                          button-div="GET"
                                                          :is-icon-custom="true"
                                                          @click.native="onPostCodeClick( 'resveMngr' )"
                                                      >
                                                        우편번호
                                                      </erp-button>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                              <ul class="row">
                                                <li
                                                  class="item form-group input"
                                                >
                                                  <div class="form">
                                                    <input-text
                                                      ref="input-reserveManagerAddress"
                                                      name="reserveManagerAddress"
                                                      v-model="
                                                        memberInputs.details
                                                          .reserveManagerAddress
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                                <li
                                                  class="item form-group input"
                                                >
                                                  <div class="form">
                                                    <input-text
                                                      ref="input-reserveManagerAdditionalAddress"
                                                      name="reserveManagerAdditionalAddress"
                                                      v-model="
                                                        memberInputs.details
                                                          .reserveManagerAdditionalAddress
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field remarks">
                                            <!-- 필수 : required -->
                                            <div class="title">비고</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <!-- 비고는 validator가 잘 작동되지 않아서 maxlength로 처리함 -->
                                                    <input-textarea
                                                      ref="input-remarks"
                                                      name="remarks"
                                                      v-model="
                                                        memberInputs.details
                                                          .remarks
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                        </ul>
                                        <div class="border-left"></div>
                                        <div class="border-right"></div>
                                        <div class="border-top"></div>
                                        <div class="border-bottom"></div>
                                      </div>
                                    </div>
                                    <div class="border-left"></div>
                                    <div class="border-right"></div>
                                    <div class="border-top"></div>
                                    <div class="border-bottom"></div>
                                  </div>
                                </div>
                              </section>
                            </div>
                            <div class="article-right">
                              <!-- 아코디언 : accordion / 닫힘 : close -->
                              <section class="article-section section-010104">
                                <div class="section-header">
                                  <div class="header-left">
                                    <div class="header-title">회원사진</div>
                                  </div>
                                  <div class="header-right">
                                    <ul class="header-button">
                                      <li class="registration">
                                        <div
                                          class="e-upload e-control-wrapper e-lib e-keyboard"
                                        >
                                          <div class="e-file-select-wrap">
                                            <label
                                              class="e-css e-btn"
                                              for="memberFileUploader"
                                            >
                                              {{
                                                !!originProfileImage ||
                                                originProfileImagePath !==
                                                  inputProfileImagePath
                                                  ? "변경"
                                                  : "등록"
                                              }}
                                            </label>
                                            <span class="e-file-select">
                                              <input
                                                id="memberFileUploader"
                                                name="memberFileUploader"
                                                ref="memberFileUploader"
                                                class="e-control e-uploader e-lib"
                                                type="file"
                                                aria-label="Uploader"
                                                accept=".png, .jpg, .jpeg, .gif"
                                                maxlength="10000000"
                                                hidden
                                                @change="onFileChange"
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        v-if=" originProfileImagePath !== inputProfileImagePath"
                                        class="delete"
                                        @click="onCancelProfileImageClick"
                                      >
                                        <erp-button button-div="DELETE" :is-icon-custom="true">취소</erp-button>
                                      </li>
                                      <li
                                        v-else-if="!!originProfileImagePath"
                                        class="delete"
                                        @click="onDeleteProfileImageClick"
                                      >
                                        <erp-button button-div="DELETE" :is-icon-custom="true" :disabled="isLoading">삭제</erp-button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="section-body">
                                  <div class="body-photo">
                                    <!-- 업로드 이미지 container -->
                                    <div
                                      v-if="profileImagePath"
                                      class="item"
                                      :style="{
                                        backgroundImage: `url(${profileImagePath})`,
                                      }"
                                    ></div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </article>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0102" style="height: 40%;">
                        <div class="section-header">
                          <div class="header-right">
                            <ul class="header-button">
                              <li class="add" v-if="!isCreateForm && systemConfigCourseClass !== 'P'">
                                <erp-button button-div="SAVE" :disabled="!enableManageMembership" @click.native="onAddMembershipClick">
                                  회원권 추가
                                </erp-button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="section-caption">
                          <!-- 활성 : active -->
                          <div class="caption-navigation" :class="[{ inactive: isCreateForm }]">
                            <ejs-tab
                              v-if="!isCreateForm"
                              ref="appMembershipTab"
                              id="tab_membership"
                              :items="membershipTabs"
                              :showCloseButton="false"
                              heightAdjustMode="Auto"
                              overflowMode="Popup"
                              @selected="onMembershipTabSelected"
                            />
                          </div>
                          <ul class="caption-button" v-if=" !isCreateForm && 1 < memberInputs.memberships.length">
                            <li class="delete">
                              <erp-button button-div="DELETE" :disabled="!enableManageMembership" @click.native="onDeleteMembershipClick">
                                회원권 삭제
                              </erp-button>
                            </li>
                          </ul>
                        </div>
                        <div class="section-body" ref="membershipSectionBody">
                          <article class="body-article">
                            <!-- 아코디언 : accordion / 닫힘 : close -->
                            <section :class="[ 'article-section', 'section-010201', 'accordion', { close: !visibleMembershipInputs }, ]">
                              <div class="section-header">
                                <div class="header-left">
                                  <div class="header-title" @click=" visibleMembershipInputs = !visibleMembershipInputs">
                                    회원권 기본정보
                                  </div>
                                </div>
                                <div class="header-right">
                                  <ul class="header-button">
                                    <li class="add">
                                      <erp-button
                                          button-div="GET"
                                          :ignore="isPopupOpened"
                                          :is-icon-custom="true"
                                          @click.native="onClickAnnualFeeRegistration"
                                      >
                                        회원권 등록
                                      </erp-button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="section-body">
                                <div class="body-data">
                                  <div class="data-outer">
                                    <div class="data-inner">
                                      <ul class="data-content">
                                        <!-- 활성 : active -->
                                        <li class="field membershipNumber">
                                          <!-- 필수 : required -->
                                          <div class="title required">
                                            회원번호
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group input">
                                                <div class="form">
                                                  <ejs-maskedtextbox
                                                    ref="input-memberNumber"
                                                    name="memberNumber"
                                                    :enabled=" !membershipInputs.isAutoAssignmentMemberNumber && !membershipInputs.id"
                                                    v-model=" membershipInputs.memberNumber"
                                                    mask="00-0000-00"
                                                  />
                                                </div>
                                              </li>
                                              <li class="item form-group check" v-if="!membershipInputs.id">
                                                <!-- 포커스 : focus -->
                                                <ul class="check">
                                                  <li>
                                                    <label>
                                                      <input
                                                        type="checkbox"
                                                        v-model=" membershipInputs.isAutoAssignmentMemberNumber"
                                                        @change=" onIsAutoAssignmentMemberNumberChange"
                                                      />
                                                      <i></i>
                                                      <div class="label">
                                                        자동
                                                      </div>
                                                    </label>
                                                  </li>
                                                </ul>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title required">
                                            회원구분
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <ejs-dropdownlist
                                                      ref="input-memberDivision"
                                                      name="memberDivision"
                                                      v-model=" membershipInputs.memberDivision"
                                                      :dataSource=" commonCodesOfMemberDivisions"
                                                      :allowFiltering="false"
                                                      :fields="commonCodeFields"
                                                      cssClass="body-data-dropdown"
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title required">
                                            회원등급
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <ejs-dropdownlist
                                                    ref="input-memberGrade"
                                                    name="memberGrade"
                                                    v-model="
                                                      membershipInputs.memberGrade
                                                    "
                                                    :dataSource="
                                                      commonCodesOfMemberGrades
                                                    "
                                                    :allowFiltering="false"
                                                    :fields="commonCodeFields"
                                                    cssClass="body-data-dropdown"
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">회원권 ID</div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                {{ membershipInputs.id }}
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title required">
                                            대표회원
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group check">
                                                <!-- 포커스 : focus -->
                                                <ul class="check">
                                                  <li>
                                                    <label>
                                                      <input
                                                          type="radio"
                                                          ref="input-isRepresentative"
                                                          v-model="
                                                          membershipInputs.isRepresentative
                                                        "
                                                          name="isRepresentative"
                                                          :value="true"
                                                      />
                                                      <i></i>
                                                      <div class="label">
                                                        예
                                                      </div>
                                                    </label>
                                                  </li>
                                                  <li>
                                                    <label>
                                                      <input
                                                          type="radio"
                                                          ref="input-isRepresentative"
                                                          v-model="
                                                          membershipInputs.isRepresentative
                                                        "
                                                          name="isRepresentative"
                                                          :value="false"
                                                      />
                                                      <i></i>
                                                      <div class="label">
                                                        아니오
                                                      </div>
                                                    </label>
                                                  </li>
                                                </ul>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title required">
                                            가입차수
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <ejs-dropdownlist
                                                    ref="input-entryOrder"
                                                    name="entryOrder"
                                                    v-model="
                                                      membershipInputs.entryOrder
                                                    "
                                                    :dataSource="
                                                      commonCodesOfEntryOrders
                                                    "
                                                    :allowFiltering="false"
                                                    :fields="commonCodeFields"
                                                    cssClass="body-data-dropdown"
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title required">
                                            법인구분
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <ejs-dropdownlist
                                                    ref="input-corporateDivision"
                                                    name="corporateDivision"
                                                    v-model="
                                                      membershipInputs.corporateDivision
                                                    "
                                                    :dataSource="
                                                      commonCodesOfCorporationDivisions
                                                    "
                                                    :allowFiltering="false"
                                                    :fields="commonCodeFields"
                                                    cssClass="body-data-dropdown"
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">명의변경 구분</div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                {{
                                                  commonCodesGetComName(
                                                    "RENAME_DIV",
                                                    membershipInputs.renameDivision
                                                  )
                                                }}
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title required">
                                            회원 상태
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <ejs-dropdownlist
                                                    ref="input-membershipStatus"
                                                    name="membershipStatus"
                                                    :enabled="
                                                      !isMembershipCreateForm
                                                    "
                                                    v-model="
                                                      membershipInputs.membershipStatus
                                                    "
                                                    :dataSource="
                                                      commonCodesOfMembershipStatuses
                                                    "
                                                    :allowFiltering="false"
                                                    :fields="commonCodeFields"
                                                    cssClass="body-data-dropdown"
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">탈회여부</div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group check">
                                                <!-- 포커스 : focus -->
                                                <ul class="check">
                                                  <li>
                                                    <label>
                                                      <input
                                                        ref="input-isOut"
                                                        type="radio"
                                                        v-model="
                                                          membershipInputs.isOut
                                                        "
                                                        name="isOut"
                                                        :value="true"
                                                        :disabled="
                                                          isMembershipCreateForm
                                                        "
                                                      />
                                                      <i></i>
                                                      <div class="label">
                                                        예
                                                      </div>
                                                    </label>
                                                  </li>
                                                  <li>
                                                    <label>
                                                      <input
                                                        ref="input-isOut"
                                                        type="radio"
                                                        v-model="
                                                          membershipInputs.isOut
                                                        "
                                                        name="isOut"
                                                        :value="false"
                                                        :disabled="
                                                          isMembershipCreateForm
                                                        "
                                                      />
                                                      <i></i>
                                                      <div class="label">
                                                        아니오
                                                      </div>
                                                    </label>
                                                  </li>
                                                </ul>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">탈회일자</div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                {{ membershipInputs.outDate }}
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            회원권 증서번호
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-text
                                                    id="input-memberCertificateNumber"
                                                    name="memberCertificateNumber"
                                                    v-model="
                                                      membershipInputs.memberCertificateNumber
                                                    "
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">그룹사</div>
                                          <div class="content">
                                            <ul class="row" style="height: 100%;">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <ejs-dropdownlist
                                                    ref="input-groupCompanyCode"
                                                    name="groupCompanyCode"
                                                    v-model="
                                                      membershipInputs.groupCompanyCode
                                                    "
                                                    :dataSource="
                                                      commonCodesOfGroupCompanyCodes
                                                    "
                                                    :allowFiltering="false"
                                                    :fields="commonCodeFields"
                                                    cssClass="body-data-dropdown"
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">계열사</div>
                                          <div class="content">
                                            <ul class="row" style="height: 100%;">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <ejs-dropdownlist
                                                      ref="input-affltsCompanyCode"
                                                      name="affltsCompanyCode"
                                                      v-model="
                                                      membershipInputs.affltsCompanyCode
                                                    "
                                                      :dataSource="
                                                      commonCodesOfAffltsCompanyCodes
                                                    "
                                                      :allowFiltering="false"
                                                      :fields="commonCodeFields"
                                                      cssClass="body-data-dropdown"
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">골프예약특전</div>
                                          <div class="content">
                                            <ul class="row" style="height: 100%;">
                                              <li class="item form-group check">
                                                <!-- 포커스 : focus -->
                                                <ul class="check">
                                                  <li>
                                                    <label>
                                                      <input
                                                          ref="input-hasBenefit"
                                                          type="radio"
                                                          v-model="
                                                            membershipInputs.hasBenefit
                                                          "
                                                          name="hasBenefit"
                                                          :value="true"
                                                          :disabled="
                                                            !membershipInputs.id
                                                          "
                                                          @change="onReservationBenefitButtonClick"
                                                      />
                                                      <i></i>
                                                      <div class="label">
                                                        예
                                                      </div>
                                                    </label>
                                                  </li>
                                                  <li>
                                                    <label>
                                                      <input
                                                          ref="input-hasBenefit"
                                                          type="radio"
                                                          v-model="
                                                            membershipInputs.hasBenefit
                                                          "
                                                          name="hasBenefit"
                                                          :value="false"
                                                          :disabled="
                                                            !membershipInputs.id
                                                          "
                                                      />
                                                      <i></i>
                                                      <div class="label">
                                                        아니오
                                                      </div>
                                                    </label>
                                                  </li>
                                                </ul>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <div class="content" style="width: 263px">
                                            <ul class="row">
                                              <li
                                                  class="item form-group button"
                                              >
                                                <ul class="button">
                                                  <li>
                                                    <erp-button
                                                        button-div="GET"
                                                        :is-icon-custom="true"
                                                        :disabled="!membershipInputs.hasBenefit"
                                                        @click.native="onReservationBenefitButtonClick"
                                                    >
                                                      예약특전
                                                    </erp-button>
                                                  </li>
                                                  <li>
                                                    <erp-button
                                                        button-div="GET"
                                                        :is-icon-custom="true"
                                                        :disabled="!membershipInputs.hasBenefit"
                                                        @click.native="onPaymentBenefitButtonClick"
                                                    >
                                                      요금특전
                                                    </erp-button>
                                                  </li>
                                                </ul>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field membershipGroup">
                                          <!-- 필수 : required -->
                                          <div class="title">회원 그룹</div>
                                          <div class="content">
                                            <ul class="row" style="height: 100%;">
                                              <li class="item form-group text">
                                                {{
                                                  selectedMembershipGroupCode
                                                }}
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field"></li>
                                        <li class="field"></li>
                                        <li class="field privilegeContents">
                                          <!-- 필수 : required -->
                                          <div class="title">특전 내용</div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-textarea
                                                      ref="input-benefitDescription"
                                                      name="benefitDescription"
                                                      v-model="
                                                      membershipInputs.benefitDescription
                                                    "
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field benefitReservation">
                                          <!-- 필수 : required -->
                                          <div class="title">예약비고</div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-textarea
                                                    ref="input-benefitDescription"
                                                    name="benefitDescription"
                                                    v-model="
                                                      membershipInputs.benefitReservation
                                                    "
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                      </ul>
                                      <div class="border-left"></div>
                                      <div class="border-right"></div>
                                      <div class="border-top"></div>
                                      <div class="border-bottom"></div>
                                    </div>
                                  </div>
                                  <div class="border-left"></div>
                                  <div class="border-right"></div>
                                  <div class="border-top"></div>
                                  <div class="border-bottom"></div>
                                </div>
                              </div>
                            </section>
                            <!-- 아코디언 : accordion / 닫힘 : close -->
                            <section
                              :class="[
                                'article-section',
                                'section-010202',
                                'accordion',
                                { close: !visibleMembershipDetailsInputs },
                              ]"
                            >
                              <div class="section-header">
                                <div class="header-left">
                                  <div
                                    class="header-title"
                                    @click="
                                      visibleMembershipDetailsInputs = !visibleMembershipDetailsInputs
                                    "
                                  >
                                    회원권 부가정보
                                  </div>
                                </div>
                              </div>
                              <div class="section-body">
                                <div class="section-body">
                                  <div class="body-data">
                                    <div class="data-outer">
                                      <div class="data-inner">
                                        <ul class="data-content">
                                          <li class="field businessNumber">
                                            <!-- 필수 : required -->
                                            <div class="title">사업자번호</div>
                                            <div class="content">
                                              <ul class="row">
                                                <!-- 활성 : active -->
                                                <li
                                                  class="item form-group select"
                                                  :class="[
                                                    {
                                                      active: !membershipInputs.isDirectInputBusinessNumber,
                                                    },
                                                  ]"
                                                >
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                      ref="businessNumbers"
                                                      :dataSource="
                                                        businessNumbers
                                                      "
                                                      :fields="{
                                                        text: 'name',
                                                        value: 'id',
                                                      }"
                                                      :filtering="
                                                        businessNumbersFiltering
                                                      "
                                                      :allowFiltering="true"
                                                      cssClass="body-data-dropdown"
                                                      @change="
                                                        businessNumberChanged
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                                <!-- 활성 : active -->
                                                <li
                                                  class="item form-group input"
                                                  :class="[
                                                    {
                                                      active:
                                                        membershipInputs.isDirectInputBusinessNumber,
                                                    },
                                                  ]"
                                                >
                                                  <div class="form">
                                                    <ejs-maskedtextbox
                                                      ref="input-businessNumber"
                                                      v-model="
                                                        membershipInputs.businessNumber
                                                      "
                                                      name="bizNoInput"
                                                      mask="000-00-00000"
                                                      @change="
                                                        inputBusinessNumberChanged
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="checkbox"
                                                          v-model="
                                                            membershipInputs.isDirectInputBusinessNumber
                                                          "
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          직접입력
                                                        </div>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">법인번호</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-maskedtextbox
                                                      ref="input-corporationNumber"
                                                      v-model="
                                                        membershipInputs.corporationNumber
                                                      "
                                                      name="cprNoInput"
                                                      mask="000000-0000000"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">법인명</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-text
                                                      ref="input-corporationName"
                                                      name="corporationName"
                                                      v-model="
                                                        membershipInputs.corporationName
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">업태</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-text
                                                      ref="input-businessForm"
                                                      name="businessForm"
                                                      v-model="
                                                        membershipInputs.businessForm
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">업종</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-text
                                                      ref="input-businessSector"
                                                      name="businessSector"
                                                      v-model="
                                                        membershipInputs.businessSector
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title required">
                                              취득일자
                                            </div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-date
                                                      ref="input-acquireDate"
                                                      name="acquireDate"
                                                      :isBindingEmptyString="
                                                        true
                                                      "
                                                      v-model="
                                                        membershipInputs.acquireDate
                                                      "
                                                      type="body-data"
                                                      @change="onAcquireDateChange"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">분양일자</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-date
                                                      ref="input-issueDate"
                                                      name="issueDate"
                                                      :isBindingEmptyString="
                                                        true
                                                      "
                                                      v-model="
                                                        membershipInputs.issueDate
                                                      "
                                                      type="body-data"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">개서일자</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-date
                                                      ref="input-rwrtDate"
                                                      name="rwrtDate"
                                                      :isBindingEmptyString="
                                                        true
                                                      "
                                                      v-model="
                                                        membershipInputs.rwrtDate
                                                      "
                                                      type="body-data"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">만기일자</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-date
                                                      ref="input-expireDate"
                                                      name="expireDate"
                                                      :isBindingEmptyString="
                                                        true
                                                      "
                                                      v-model="
                                                        membershipInputs.expireDate
                                                      "
                                                      type="body-data"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">취득금액</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-number
                                                      ref="input-acquireAmount"
                                                      name="acquireAmount"
                                                      v-model="
                                                        membershipInputs.acquireAmount
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">분양금액</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-number
                                                      ref="input-issueAmount"
                                                      name="issueAmount"
                                                      v-model="
                                                        membershipInputs.issueAmount
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">납입금액</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-number
                                                      ref="input-paidAmount"
                                                      name="paidAmount"
                                                      v-model="
                                                        membershipInputs.paidAmount
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">미납금액</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-number
                                                      ref="input-unpaidAmount"
                                                      name="unpaidAmount"
                                                      v-model="
                                                        membershipInputs.unpaidAmount
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                        </ul>
                                        <div class="border-left"></div>
                                        <div class="border-right"></div>
                                        <div class="border-top"></div>
                                        <div class="border-bottom"></div>
                                      </div>
                                    </div>
                                    <div class="border-left"></div>
                                    <div class="border-right"></div>
                                    <div class="border-top"></div>
                                    <div class="border-bottom"></div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <!-- 아코디언 : accordion / 닫힘 : close -->
                            <section
                              v-if="
                                !isCreateForm &&
                                visibleLinkMemberGrid &&
                                membershipInputs.linkId
                              "
                              :class="[
                                'article-section',
                                'section-010203',
                                'accordion',
                                { close: !visibleLinkMemberInputs },
                              ]"
                            >
                              <div class="section-header">
                                <div class="header-left">
                                  <div
                                    class="header-title"
                                    @click="visibleLinkMemberInputs = !visibleLinkMemberInputs"
                                  >
                                    연계회원 목록
                                  </div>
                                </div>
                                <div class="header-right">
                                  <ul class="header-button">
                                    <li class="add">
                                      <erp-button
                                          button-div="SAVE"
                                          :is-icon-custom="true"
                                        @click.native="onAddLinkMemberClick"
                                      >
                                        연계회원 추가
                                      </erp-button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="section-body">
                                <div class="body-grid">
                                  <!--
                                  dataSource를 computed 안에 두면, 회원권 정보 변경 시 그리드가 refresh되는 문제가 발생
                                  따라서, dataSource를 data 항목으로 두고, 탭 선택 시 설정하도록 변경
                                  -->
                                  <ejs-grid-wrapper
                                    ref="linkMemberGrid"
                                    :dataSource="linkMembershipsDataSource"
                                    v-bind="linkMemberGridProps"
                                  >
                                  </ejs-grid-wrapper>
                                </div>
                              </div>
                            </section>
                          </article>
                        </div>
                      </section>
                    </article>
                  </div>
                </section>
              </article>
              <ReportView ref="reportViewComponent" :isPopup="false" />
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <div class="lookup-condition">
            <ul class="button">
              <li class="delete">
                <erp-button
                    button-div="DELETE"
                    :ignore="isPopupOpened"
                    :is-custom-shortcut-button="true"
                    shortcut-key="MemberInfoPopup.shortcuts.delete"
                    :shortcut="{key: 'F4'}"
                    :disabled="isLoading"
                    @click.native="onDeleteMemberClick"
                >
                  삭제
                </erp-button>
              </li>
            </ul>
          </div>
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :disabled="isLoading ||(!isCreateForm ? !isModified : false) ||isSaveButtonDisabled"
                  :is-icon-custom="true"
                  :ignore="isPopupOpened"
                  :is-custom-shortcut-button="true"
                  shortcut-key="MemberInfoPopup.shortcuts.save"
                  :shortcut="{key: 'F6'}"
                  @click.native="onSaveClick"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  :is-icon-custom="true"
                  @click.native="onCloseClick">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <web-password-reset-popup
      v-if="visibleWebMemberInfoPopup"
      ref="webMemberInputPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClosed="onWebMemberInfoPopupEvent('popupClosed')"
      @popupConfirmed="onWebMemberInfoPopupEvent('memberSaved')"
    />
    <link-member-popup
      v-if="visibleLinkMemberPopup"
      ref="linkMemberPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClosed="linkMemberPopupClosed"
      @popupConfirmed="linkMemberPopupConfirmed"
    />
    <daum-postcode-popup
      v-if="visibleDaumPostcodePopup"
      ref="daumPostcodePopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClose="daumPostcodePopupClosed"
      @popupConfirmed="daumPostcodePopupConfirmed"
    />
    <history-popup
      v-if="visibleHistoryPopup"
      ref="historyPopup"
      @popupClosed="historyPopupClosed"
    />
    <membership-fit-resve-popup
      v-if="visibleMembershipFitResvePopup"
      ref="membershipFitResvePopup"
      @popupClosed="membershipFitResvePopupClosed"
    />
    <membership-fit-paymt-popup
      v-if="visibleMembershipFitPaymtPopup"
      ref="membershipFitPaymtPopup"
      @popupClosed="membershipFitPaymtPopupClosed"
    />
    <member-annual-fee-registration-popup
        v-if="visibleMemberAnnualFeeRegistrationPopupOpen"
        ref="memberAnnualFeeRegistrationPopup"
        @popupClosed="onCloseMemberAnnualFeeRegistrationPopup('popupClosed')"
    />
  </div>
</template>

<script>
import moment from "moment";
import { debounce as _debounce, sortBy as _sortBy, orderBy as _orderBy } from "lodash";
import { ForeignKey } from "@syncfusion/ej2-vue-grids";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import LinkMemberPopup from "./LinkMemberPopup";
import HistoryPopup from "../common/HistoryPopup";
import DaumPostcodePopup from "@/views/common/DaumPostcodePopup.vue";
import MembershipFitResvePopup from "@/views/member-management/popup/MembershipFitResvePopup";
import MembershipFitPaymtPopup from "@/views/member-management/popup/MembershipFitPaymtPopup";
import MemberAnnualFeeRegistrationPopup from "@/views/member-management/popup/MemberAnnualFeeRegistrationPopup.vue";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import InputBirthday from "@/components/common/datetime/InputBirthday";
import InputNumber from "@/components/common/InputNumber";
import InputDate from "@/components/common/datetime/InputDate";
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import {
  commonCodesGetCommonCode,
  commonCodeGetComCodeDefaultValue,
  commonCodesGetComName,
  commonCodesGetCommonCodeByIdx, commonCodesGetStandardInfo,
  commonCodesGetCommonCodeByIdxAttrb, commonCodesGetBizMstInfo,
} from '@/utils/commonCodes';
import { DATE_FORMAT_YYYY_MM_DD } from "@/utils/date";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import { encodeBase64 } from "@/utils/ImageUtil";
import { compactObjectValues, deepDiffs } from "@/utils/ObjectUtil";
import Validator from "@/utils/validator/Validator";
import WebPasswordResetPopup from "@/views/member-management/WebPasswordResetPopup";
import NotFoundError from "@/error/NotFoundError";
import ComponentTelephone from "@/components/ComponentTelephone";
import {getHpNoCheck} from "@/api/webMember";
import ErpButton from "@/components/button/ErpButton.vue";
import ReportView from "@/components/common/report/ReportView.vue";

const MEMBER_INPUT_LABELS = {
  name: "회원명",
  gender: "성별",
  birthday: "생년월일",
  lunarCode: "생일기준",
  nearbyCode: "지역민구분",
  phoneNumber: "핸드폰번호",
  smsReceiptDivision: "SMS 수신동의",
  reserveManagerPhoneNumber: "예약담당 휴대폰",
  details: "회원 부가정보",
  memberships: "회원권 정보",
};

const MEMBER_DETAILS_INPUT_LABELS = {
  vipFlag: "VIP 여부",
  isSelfCaddie: "셀피캐디 가능",
  workDivision: "직업구분",
  nativeDivision: "내국인 구분",
  nationCode: "국가구분",
  handicap: "핸디캡",
  email: "이메일",
  allowReceiptEmail: "이메일 수신동의",
  postReceiptDivision: "우편물 수신동의",
  drmncyCnvrsCode: "휴면전환코드",
  allowProvideThirdParties: "제 3자 정보제공",
  allowReceiptMarketing: "마케팅 수신동의",
  areaCode: "지역구분",
};

const MEMBERSHIP_INPUT_LABELS = {
  memberNumber: "회원번호",
  memberDivision: "회원구분",
  isRepresentative: "대표회원",
  memberGrade: "회원등급",
  entryOrder: "가입차수",
  corporateDivision: "법인구분",
  renameDivision: "명의변경 구분",
  isOut: "탈회여부",
  membershipStatus: "회원 상태",
  memberCertificateNumber: "회원권 증서번호",
  groupCompanyCode: "그룹사",
  affltsCompanyCode: "계열사",
  benefitDescription: "특전 내용",
  hasBenefit: "골프예약특전",
  corporationName: "법인명",
  businessNumber: "사업자번호",
  corporationNumber: "법인번호",
  businessForm: "업태",
  businessSector: "업종",
  issueDate: "분양일자",
  acquireDate: "취득일자",
  acquireAmount: "취득금액",
  paidAmount: "납입금액",
  unpaidAmount: "미납금액",
  rwrtDate: "개서일자",
  expireDate: "만기일자",
};

function getDefaultMemberInputs() {
  return {
    id: null,
    nearbyCode: "",
    phoneNumber: "",
    reserveManagerPhoneNumber: "",
    gender: commonCodeGetComCodeDefaultValue("SEX_CODE"),
    birthday: moment("20010101").format(DATE_FORMAT_YYYY_MM_DD),
    lunarCode: commonCodeGetComCodeDefaultValue("LUNAR_CODE"),
    smsReceiptDivision: commonCodeGetComCodeDefaultValue("SMS_RECPTN_DIV"),
    details: getDefaultDetailInputs(),
    memberships: [getDefaultMembershipInputs()],
  };
}

function getDefaultDetailInputs() {
  return {
    handicap: 0,
    workDivision: "",
    areaCode: commonCodeGetComCodeDefaultValue("AREA_CODE"),
    sigunCode: "",
    nativeDivision: commonCodeGetComCodeDefaultValue("NATIVE_DIV"),
    nationCode: commonCodeGetComCodeDefaultValue("NATION_CODE"),
    homePostalCode: "",
    homeAddress: "",
    companyPostalCode: "",
    companyAddress: "",
    reserveManagerPostalCode: "",
    reserveManagerAddress: "",
    postReceiptDivision: commonCodeGetComCodeDefaultValue("POST_RECPTN_DIV"),
    drmncyCnvrsCode: commonCodeGetComCodeDefaultValue("DRMNCY_CNVRS_CODE"),
    allowReceiptEmail: false,
    allowProvideThirdParties: false,
    allowReceiptMarketing: false,
    isSelfCaddie: false,
    vipFlag: false,
  };
}

function getDefaultMembershipInputs() {
  return {
    memberNumber: "",
    isAutoAssignmentMemberNumber: commonCodesGetStandardInfo("courseClass") !== "R", // TODO : 추가되는 탭에는 자동 세팅 안 됨
    isRepresentative: true,
    memberDivision: _sortBy(
      commonCodesGetCommonCode("MEMBER_DIV", true).filter(
        ({ comCode }) => !["NOM", "NOT"].includes(comCode)
      ),
      "sortNo"
    )[0].comCode,
    memberGrade: commonCodeGetComCodeDefaultValue("MEMBER_GRADE"),
    entryOrder: commonCodeGetComCodeDefaultValue("ENTRY_ORDER"),
    corporateDivision: commonCodeGetComCodeDefaultValue("CPR_DIV"),
    renameDivision: commonCodeGetComCodeDefaultValue("RENAME_DIV"),
    isOut: false,
    membershipStatus: commonCodeGetComCodeDefaultValue("MEMBERSHIP_STATUS"),
    hasBenefit: false,
    acquireDate: moment().format(DATE_FORMAT_YYYY_MM_DD),
    expireDate: null,
    // expireDate: moment().format(DATE_FORMAT_YYYY_MM_DD),
    isDirectInputBusinessNumber: false, // 탭 별로 다른 값을 가져야 한다
    groupCompanyCode: null, // 회원권 추가 시, New 탭의 그룹사가 기존 탭과 같아지는(단, 저장은 되지 않음) 문제 때문에 초기화
    affltsCompanyCode: null,
    // businessCustomerId: null, // 상동
    businessNumber: null, // 상동
    corporationNumber: null, // 상동
  };
}

export default {
  name: "MemberInfoPopup",
  components: {
    ReportView,
    ComponentTelephone,
    WebPasswordResetPopup,
    InputTextarea,
    InputText,
    LinkMemberPopup,
    DaumPostcodePopup,
    MemberAnnualFeeRegistrationPopup,
    ejsGridWrapper,
    InputBirthday,
    InputNumber,
    InputDate,
    HistoryPopup,
    MembershipFitResvePopup,
    MembershipFitPaymtPopup,
    ErpButton,
  },
  props: ["memberId", "memberNo", "isOut"],
  mixins: [commonMixin, confirmDialogMixin],
  filters: {
    getWebId(member) {
      return member?.webIdInformation?.id?.split(":")?.[0] || "";
    }
  },
  async created() {
    this.postSiguns = await GolfErpAPI.fetchPostSigun();
  },
  mounted() {
    if (this.isCreateForm) {
      this.$refs["input-name"].focus();
    }
  },
  data() {
    return {
      // familyMemberDivCodes: commonCodesGetCommonCodeByIdxAttrb("MEMBER_DIV", 4, "1248").map(({ comCode }) => comCode),
      postSiguns: [],
      currentPhoneNumber: null,
      isSaveButtonDisabled: false,
      isAcquireDateChange: false,
      isLoading: false,
      mutated: false,

      systemConfigCourseClass: null,
      enableManageMembership: true,

      visibleMemberInputs: true,
      visibleMemberDetailsInputs: true,
      visibleMembershipInputs: true,
      visibleMembershipDetailsInputs: false,
      visibleLinkMemberInputs: false,


      member: {
        ...getDefaultMemberInputs(),
        id: this.memberId,
      },
      memberInputs: getDefaultMemberInputs(),

      membershipTabs: [],
      membershipInputs: {
        ...getDefaultMembershipInputs(),
        memberId: this.memberId,
      },
      linkMembershipsDataSource: [],
      selectedMembershipIndex: 0,

      defaultIsAutoAssignmentMemberNumber: true,
      businessNumbers: [],

      postcodeTarget: null,

      visibleLinkMemberGrid: false,
      visibleWebMemberInfoPopup: false,
      visibleLinkMemberPopup: false,
      visibleWebPasswordResetPopup: false,
      visibleDaumPostcodePopup: false,
      visibleHistoryPopup: false,
      visibleMembershipFitResvePopup: false,
      visibleMembershipFitPaymtPopup: false,
      visibleMemberAnnualFeeRegistrationPopupOpen:false,

      createMemberValidator: new Validator({
        rules: {
          name: [
            {
              rule: "required",
              type: "string",
            },
            {
              rule: "min-length",
              type: "string",
              valid: 2,
            },
            {
              rule: "max-length",
              type: "string",
              valid: 50,
            },
          ],
          gender: [
            {
              rule: "required",
              type: "string",
            },
            {
              rule: "custom",
              type: "string",
              message: "not exist gender code.",
              valid: (value) =>
                this.commonCodesOfGenders
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          birthday: [
            {
              rule: "required",
              type: "string",
            },
            {
              rule: "date",
              type: "string",
            },
          ],
          lunarCode: [
            {
              rule: "required",
              type: "string",
            },
            {
              rule: "custom",
              type: "string",
              message: "not exist lunar code.",
              valid: (value) =>
                this.commonCodesOfLunarCodes
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          nearbyCode: [
            {
              rule: "custom",
              type: "string",
              message: "not exist nearby code.",
              valid: (value) =>
                this.commonCodesOfNearByCodes
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          phoneNumber: [
            {
              rule: "required",
              type: "string",
            },
            {
              rule: "telephone",
              type: "string",
            },
          ],
          smsReceiptDivision: [
            {
              rule: "required",
              type: "string",
            },
            {
              rule: "custom",
              type: "string",
              message: "not exist sms division code.",
              valid: (value) =>
                this.commonCodesOfSmsReceiptDivisions
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          reserveManagerPhoneNumber: [
            {
              rule: "telephone",
              type: "string",
            },
          ],
        },
      }),
      createMemberMemberDetailsValidator: new Validator({
        rules: {
          vipFlag: [
            {
              rule: "required",
              type: "boolean",
            },
          ],
          isSelfCaddie: [
            {
              rule: "required",
              type: "boolean",
            },
          ],
          workDivision: [
            {
              rule: "custom",
              type: "string",
              message: "not exist work division code.",
              valid: (value) =>
                this.commonCodesOfWorkDivisions
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          nativeDivision: [
            {
              rule: "custom",
              type: "string",
              message: "not exist work division code.",
              valid: (value) =>
                this.commonCodesOfNativeDivisions
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          nationCode: [
            {
              rule: "custom",
              type: "string",
              message: "not exist nation code.",
              valid: (value) =>
                this.commonCodesOfNationCodes
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          handicap: [
            {
              rule: "min",
              type: "number",
              valid: 0,
            },
          ],
          email: [
            {
              rule: "email",
              type: "string",
            },
          ],
          allowReceiptEmail: [
            {
              rule: "required",
              type: "boolean",
            },
          ],
          postReceiptDivision: [
            {
              rule: "required",
              type: "string",
            },
            {
              rule: "custom",
              type: "string",
              message: "not exist post receipt division code.",
              valid: (value) =>
                this.commonCodesOfPostReceiptDivisions
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          drmncyCnvrsCode: [
            {
              rule: "required",
              type: "string",
            },
            {
              rule: "custom",
              type: "string",
              message: "not exist drmncy cnvrs code.",
              valid: (value) =>
                this.commonCodesOfDrmncyCnvrsCodes
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          allowProvideThirdParties: [
            {
              rule: "required",
              type: "boolean",
            },
          ],
          allowReceiptMarketing: [
            {
              rule: "required",
              type: "boolean",
            },
          ],
          areaCode: [
            {
              rule: "custom",
              type: "string",
              message: "not exist area code.",
              valid: (value) =>
                this.commonCodesOfAreaCodes
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
        },
      }),
      createMemberMembershipValidator: new Validator({
        rules: {
          memberNumber: [
            {
              rule: "required",
              type: "string",
            },
            {
              rule: "length",
              type: "string",
              valid: 8,
            },
          ],
          memberDivision: [
            {
              rule: "required",
              type: "string",
            },
            {
              rule: "custom",
              type: "string",
              message: "not exist member division.",
              valid: (value) =>
                this.commonCodesOfMemberDivisions
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          isRepresentative: [
            {
              rule: "required",
              type: "boolean",
            },
          ],
          memberGrade: [
            {
              rule: "required",
              type: "string",
            },
            {
              rule: "custom",
              type: "string",
              message: "not exist member grade.",
              valid: (value) =>
                this.commonCodesOfMemberGrades
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          entryOrder: [
            {
              rule: "required",
              type: "string",
            },
            {
              rule: "custom",
              type: "string",
              message: "not exist entry order.",
              valid: (value) =>
                this.commonCodesOfEntryOrders
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          corporateDivision: [
            {
              rule: "required",
              type: "string",
            },
            {
              rule: "custom",
              type: "string",
              message: "not exist corporate division.",
              valid: (value) =>
                this.commonCodesOfCorporationDivisions
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          renameDivision: [
            {
              rule: "custom",
              type: "string",
              message: "Cannot change rename division.",
              valid: (value) =>
                value === commonCodeGetComCodeDefaultValue("RENAME_DIV"),
            },
          ],
          isOut: [
            {
              rule: "custom",
              type: "string",
              message: "Cannot change isOut flag.",
              valid: (value) => value === false,
            },
          ],
          membershipStatus: [
            {
              rule: "custom",
              type: "string",
              message: "Cannot change membership status.",
              valid: (value) =>
                value === commonCodeGetComCodeDefaultValue("MEMBERSHIP_STATUS"),
            },
          ],
          memberCertificateNumber: [
            {
              rule: "max-length",
              type: "string",
              valid: 50,
            },
          ],
          groupCompanyCode: [
            {
              rule: "custom",
              type: "string",
              message: "not exist group company code.",
              valid: (value) =>
                this.commonCodesOfGroupCompanyCodes
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          affltsCompanyCode: [
            {
              rule: "custom",
              type: "string",
              message: "not exist afflts company code.",
              valid: (value) =>
                  this.commonCodesOfAffltsCompanyCodes
                      .map(({ comCode }) => comCode)
                      .includes(value),
            },
          ],
          hasBenefit: [
            {
              rule: "custom",
              type: "string",
              message: "Cannot change benefit flag.",
              valid: (value) => value === false,
            },
          ],
          businessNumber: [
            {
              rule: "length",
              type: "string",
              valid: 10,
            },
          ],
          corporationNumber: [
            {
              rule: "length",
              type: "string",
              valid: 13,
            },
          ],
          issueDate: [
            {
              rule: "date",
              type: "string",
            },
          ],
          acquireDate: [
            {
              rule: "required",
              type: "string",
            },
            {
              rule: "date",
              type: "string",
            },
          ],
          rwrtDate: [
            {
              rule: "date",
              type: "string",
            },
          ],
          expireDate: [
            {
              rule: "date",
              type: "string",
            },
          ],
        },
      }),
      patchMemberValidator: new Validator({
        rules: {
          name: [
            {
              rule: "min-length",
              type: "string",
              valid: 2,
            },
            {
              rule: "max-length",
              type: "string",
              valid: 50,
            },
          ],
          gender: [
            {
              rule: "custom",
              type: "string",
              message: "not exist gender code.",
              valid: (value) =>
                this.commonCodesOfGenders
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          birthday: [
            {
              rule: "date",
              type: "string",
            },
          ],
          lunarCode: [
            {
              rule: "custom",
              type: "string",
              message: "not exist lunar code.",
              valid: (value) =>
                this.commonCodesOfLunarCodes
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          nearbyCode: [
            {
              rule: "custom",
              type: "string",
              message: "not exist nearby code.",
              valid: (value) =>
                this.commonCodesOfNearByCodes
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          phoneNumber: [
            {
              rule: "custom",
              type: "string",
              message: "핸드폰번호은(는) 필수값입니다",
              valid: (value) =>
                value === undefined || (value && value.trim() !== ""),
            },
            {
              rule: "telephone",
              type: "string",
            },
          ],
          smsReceiptDivision: [
            {
              rule: "custom",
              type: "string",
              message: "not exist sms division code.",
              valid: (value) =>
                this.commonCodesOfSmsReceiptDivisions
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          reserveManagerPhoneNumber: [
            {
              rule: "telephone",
              type: "string",
            },
          ],
        },
      }),
      patchMemberMemberDetailsValidator: new Validator({
        rules: {
          workDivision: [
            {
              rule: "custom",
              type: "string",
              message: "not exist work division code.",
              valid: (value) =>
                this.commonCodesOfWorkDivisions
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          nativeDivision: [
            {
              rule: "custom",
              type: "string",
              message: "not exist work division code.",
              valid: (value) =>
                this.commonCodesOfNativeDivisions
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          nationCode: [
            {
              rule: "custom",
              type: "string",
              message: "not exist nation code.",
              valid: (value) =>
                this.commonCodesOfNationCodes
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          handicap: [
            {
              rule: "min",
              type: "number",
              valid: 0,
            },
          ],
          email: [
            {
              rule: "email",
              type: "string",
            },
          ],
          postReceiptDivision: [
            {
              rule: "custom",
              type: "string",
              message: "not exist post receipt division code.",
              valid: (value) =>
                this.commonCodesOfPostReceiptDivisions
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          drmncyCnvrsCode: [
            {
              rule: "custom",
              type: "string",
              message: "not exist drmncy cnvrs code.",
              valid: (value) =>
                this.commonCodesOfDrmncyCnvrsCodes
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          areaCode: [
            {
              rule: "custom",
              type: "string",
              message: "not exist area code.",
              valid: (value) =>
                this.commonCodesOfAreaCodes
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
        },
      }),
      patchMemberMembershipValidator: new Validator({
        rules: {
          memberDivision: [
            {
              rule: "custom",
              type: "string",
              message: "not exist member division.",
              valid: (value) =>
                this.commonCodesOfMemberDivisions
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          memberGrade: [
            {
              rule: "custom",
              type: "string",
              message: "not exist member grade.",
              valid: (value) =>
                this.commonCodesOfMemberGrades
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          entryOrder: [
            {
              rule: "custom",
              type: "string",
              message: "not exist entry order.",
              valid: (value) =>
                this.commonCodesOfEntryOrders
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          corporateDivision: [
            {
              rule: "custom",
              type: "string",
              message: "not exist corporate division.",
              valid: (value) =>
                this.commonCodesOfCorporationDivisions
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          memberCertificateNumber: [
            {
              rule: "max-length",
              type: "string",
              valid: 50,
            },
          ],
          groupCompanyCode: [
            {
              rule: "custom",
              type: "string",
              message: "not exist group company code.",
              valid: (value) =>
                this.commonCodesOfGroupCompanyCodes
                  .map(({ comCode }) => comCode)
                  .includes(value),
            },
          ],
          affltsCompanyCode: [
            {
              rule: "custom",
              type: "string",
              message: "not exist afflts company code.",
              valid: (value) =>
                  this.commonCodesOfAffltsCompanyCodes
                      .map(({ comCode }) => comCode)
                      .includes(value),
            },
          ],
          businessNumber: [
            {
              rule: "length",
              type: "string",
              valid: 10,
            },
          ],
          corporationNumber: [
            {
              rule: "length",
              type: "string",
              valid: 13,
            },
          ],
          issueDate: [
            {
              rule: "date",
              type: "string",
            },
          ],
          acquireDate: [
            {
              rule: "required",
              type: "string",
            },
            {
              rule: "date",
              type: "string",
            },
          ],
          rwrtDate: [
            {
              rule: "date",
              type: "string",
            },
          ],
          expireDate: [
            {
              rule: "date",
              type: "string",
            },
          ],
        },
      }),
    };
  },
  computed: {
    isPopupOpened() {
      return (
          this.visibleWebMemberInfoPopup ||
          this.visibleLinkMemberPopup ||
          this.visibleDaumPostcodePopup ||
          this.visibleHistoryPopup ||
          this.visibleMembershipFitResvePopup ||
          this.visibleMembershipFitPaymtPopup ||
          this.visibleMemberAnnualFeeRegistrationPopupOpen
      );
    },
    /**
     * 변경 여부를 확인
     */
    isModified() {
      return deepDiffs(
        {
          ...getDefaultMemberInputs(),
          ...this.member,
          details: { ...getDefaultDetailInputs(), ...this.member.details },
          memberships: this.member.memberships.map((membership) => {
            let membershipElementWithDefault = {
              ...getDefaultMembershipInputs(),
              ...membership,
            };

            // 비교하기와 상관 없는 property 제거
            delete membershipElementWithDefault["isAutoAssignmentMemberNumber"];
            delete membershipElementWithDefault["isDirectInputBusinessNumber"];

            return membershipElementWithDefault;
          }),
        },
        {
          ...getDefaultMemberInputs(),
          ...this.memberInputs,
          details: {
            ...getDefaultDetailInputs(),
            ...this.memberInputs.details,
          },
          memberships: this.memberInputs.memberships.map((membership) => {
            let membershipElementWithDefault = {
              ...getDefaultMembershipInputs(),
              ...membership,
            };

            // 비교하기와 상관 없는 property 제거
            delete membershipElementWithDefault["isAutoAssignmentMemberNumber"];
            delete membershipElementWithDefault["isDirectInputBusinessNumber"];

            return membershipElementWithDefault;
          }),
        }
      );
    },
    isCreateForm() {
      return !this.member?.id;
    },
    isMembershipCreateForm() {
      return !this.membershipInputs?.id;
    },
    originProfileImage() {
      return this.member.imageFile;
    },
    inputProfileImage() {
      return this.memberInputs.imageFile;
    },
    originProfileImagePath() {
      return this.originProfileImage?.path || "";
    },
    inputProfileImagePath() {
      return this.inputProfileImage?.path || "";
    },
    profileImage() {
      return this.inputProfileImage || this.originProfileImage;
    },
    profileImagePath() {
      return this.profileImage?.path;
    },
    linkMemberGridProps() {
      return {
        columns: [
          {
            field: "memberNumber",
            allowEditing: false,
            headerText: "회원번호",
            type: "string",
            minWidth: 16,
            width: 120,
          },
          {
            field: "memberDivision",
            editType: "dropdownedit",
            foreignKeyField: "comCode",
            foreignKeyValue: "comName",
            allowEditing: false,
            headerText: "회원구분",
            type: "string",
            minWidth: 16,
            width: 60,
            groupCode: "MEMBER_DIV",
          },
          {
            field: "memberName",
            allowEditing: false,
            headerText: "회원명",
            type: "string",
            minWidth: 16,
            width: 90,
          },
        ],
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
        pageSettings: { pageSize: 15 },
        provides: [ForeignKey],
        isSelectedRowRetain: false,
        isAutoSelectRow: false,
        isAutoSelectCell: false,
      };
    },

    dialogProps() {
      return {
        animationSettings: { effect: "None" },
        cssClass: this.isCreateForm
          ? this.$t("className.popup.productCodePopupAdd")
          : this.$t("className.popup.productCodePopupModify"),
        isModal: true,
        position: { x: "center", y: "center" },
        visible: this.isVisible,
        zIndex: this.currentzIndex,
      };
    },

    commonCodeFields() {
      return { text: "comName", value: "comCode" };
    },
    commonCodesOfGenders() {
      return commonCodesGetCommonCode("SEX_CODE", true);
    },
    commonCodesOfLunarCodes() {
      return commonCodesGetCommonCode("LUNAR_CODE", true);
    },
    commonCodesOfSmsReceiptDivisions() {
      return commonCodesGetCommonCode("SMS_RECPTN_DIV", true);
    },
    commonCodesOfNearByCodes() {
      const nearByCodes = commonCodesGetCommonCode("NEARBY_CODE", true);

      nearByCodes.unshift({
        comCode: "",
        comName: "",
        codeAbrv: "",
        defaultFlag: true,
      });

      return nearByCodes;
    },
    commonCodesOfWorkDivisions() {
      const workDivisions = commonCodesGetCommonCode("WORK_DIV", true);

      workDivisions.unshift({
        comCode: "",
        comName: "",
        codeAbrv: "",
        defaultFlag: true,
      });

      return workDivisions;
    },
    commonCodesOfAreaCodes() {
      const areaCodes = commonCodesGetCommonCode("AREA_CODE", true);

      areaCodes.unshift({
        comCode: "",
        comName: "",
        codeAbrv: "",
        defaultFlag: true,
      });

      return areaCodes;
    },
    postSigunsByComputed() {
      const sigunCodes = this.postSiguns.filter(item => item.areaCode === this.memberInputs.details.areaCode).map(item => {
        return {
          comCode: item.sigunCode,
          comName: item.sigunName,
          codeAbrv: "",
          defaultFlag: false,
        };
      });

      sigunCodes.unshift({
        comCode: "",
        comName: "",
        codeAbrv: "",
        defaultFlag: true,
      });

      return sigunCodes;
    },
    commonCodesOfNativeDivisions() {
      const nativeDivisions = commonCodesGetCommonCode("NATIVE_DIV", true);

      nativeDivisions.unshift({
        comCode: "",
        comName: "",
        codeAbrv: "",
        defaultFlag: true,
      });

      return nativeDivisions;
    },
    commonCodesOfNationCodes() {
      const nationCodes = commonCodesGetCommonCode("NATION_CODE", true);

      nationCodes.unshift({
        comCode: "",
        comName: "",
        codeAbrv: "",
        defaultFlag: true,
      });

      return nationCodes;
    },
    commonCodesOfPostReceiptDivisions() {
      return commonCodesGetCommonCode("POST_RECPTN_DIV", true);
    },
    commonCodesOfDrmncyCnvrsCodes() {
      return commonCodesGetCommonCode("DRMNCY_CNVRS_CODE", true);
    },
    commonCodesOfMemberDivisions() {
      return commonCodesGetCommonCode("MEMBER_DIV", true).filter(
        ({ comCode }) => !["NOM", "NOT"].includes(comCode)
      );
    },
    commonCodesOfMemberGrades() {
      return commonCodesGetCommonCode("MEMBER_GRADE", true);
    },
    commonCodesOfGroupCompanyCodes() {
      const groupCompanyCodes = commonCodesGetCommonCode("GROUP_COMPANY_CODE", true);

      groupCompanyCodes.unshift({
        comCode: "",
        comName: "",
        codeAbrv: "",
        defaultFlag: true,
      });

      return groupCompanyCodes;
    },
    commonCodesOfAffltsCompanyCodes() {
      const affltsCompanyCode = commonCodesGetCommonCode("AFFLTS_COMPANY_CODE", true);

      affltsCompanyCode.unshift({
        comCode: "",
        comName: "",
        sortNo: 0,
      });
      return affltsCompanyCode;
    },
    commonCodesOfEntryOrders() {
      return commonCodesGetCommonCode("ENTRY_ORDER", true);
    },
    commonCodesOfCorporationDivisions() {
      return commonCodesGetCommonCode("CPR_DIV", true);
    },
    commonCodesOfMembershipStatuses() {
      const membershipStatuses = commonCodesGetCommonCode("MEMBERSHIP_STATUS", true);
      return membershipStatuses;
    },

    selectedMembershipGroupCode() {
      const membership = this.membershipInputs;
      if (!membership || !membership.id) {
        return "";
      }

      return `${membership.hasBenefit ? "FIT" : "GRP"}-${
        membership.membershipMembershipGroup?.membershipGroup?.name ||
        `특전 미등록-${membership.memberGrade}`
      }`;
    },
  },
  methods: {
    commonCodesGetComName: commonCodesGetComName,
    encodeBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (event) => {
          resolve(event.target.result);
        };
        reader.onerror = reject;
      });
    },
    show() {
      this.fetchInit()
        .catch((e) => {
          console.error(e);
          this.hide();
        })
        .finally(() => {
          this.$refs["dialog"].show();
          this.isAcquireDateChange = true;
        });
    },
    hide(args = { mutated: false }) {
      this.$nextTick(() => {
        this.$emit("close", args);
      });
    },
    openWebMemberInfoPopup() {
      this.visibleWebMemberInfoPopup = true;
      this.$nextTick(() => {
        this.$refs.webMemberInputPopup.showPopup({
          isCreate: !this.member.webIdInformation,
          webId: this.member.webIdInformation?.id,
          memberId: this.member.id,
        });
      });
    },
    async onDialogBeforeClose(args) {
      if (this.isModified) {
        args.cancel = true;

        if (
          !(await this.confirm(
            this.$t("main.popupMessage.thereIsModifiedData")
          ))
        ) {
          return;
        }
      }

      this.hide({ mutated: this.mutated });
    },
    async onCloseClick() {
      if (this.isModified) {
        if (
          !(await this.confirm(
            this.$t("main.popupMessage.thereIsModifiedData")
          ))
        ) {
          return;
        }
      }

      this.hide({ mutated: this.mutated });
    },
    async onDeleteMemberClick() {
      if (await this.confirm("회원을 삭제하시겠습니까?")) {
        await this.deleteMember();

        this.infoToast(this.$t("main.popupMessage.deleted"));
        this.hide({ mutated: true });
      }
    },
    async onCreateWebIdClick() {
      if (this.isCreateForm) {
        return;
      }

      if (this.isModified) {
        if (
          !(await this.confirm(
            "변경사항이 있습니다. 무시하고 진행하시겠습니까?"
          ))
        ) {
          return;
        }
      }

      this.openWebMemberInfoPopup();
    },
    onHistoryChangesClick() {
      if (this.isCreateForm) {
        return;
      }

      GolfErpAPI.fetchMemberHistory(this.member.id).then((rawHistories) => {
        this.visibleHistoryPopup = true;
        this.$nextTick(() => {
          this.$refs.historyPopup.show({
            rawHistories,
            fields: {
              Member: {
                __name__: "회원기본정보",
                name: {
                  name: "회원명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                gender: {
                  name: "성별",
                  type: "commonCode",
                  groupCode: "SEX_CODE",
                  methods: ["create", "update", "delete"],
                },
                birthday: {
                  name: "생일",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                lunarCode: {
                  name: "양력/음력",
                  type: "commonCode",
                  groupCode: "LUNAR_CODE",
                  methods: ["create", "update", "delete"],
                },
                nearbyCode: {
                  name: "지역민구분",
                  type: "commonCode",
                  groupCode: "NEARBY_CODE",
                  methods: ["create", "update", "delete"],
                },
                phoneNumber: {
                  name: "핸드폰번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                workPlace: {
                  name: "근무처",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                smsReceiptDivision: {
                  name: "SMS수신동의",
                  type: "commonCode",
                  groupCode: "SMS_RECPTN_DIV",
                  methods: ["create", "update", "delete"],
                },
                reserveManagerName: {
                  name: "예약담당자명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                reserveManagerPhoneNumber: {
                  name: "예약담당휴대폰",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                imageId: {
                  name: "회원사진",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
              },
              MemberDetail: {
                __name__: "회원부가정보",
                bagName: {
                  name: "백네임",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                engName: {
                  name: "영문명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                chnName: {
                  name: "한자명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                vipFlag: {
                  name: "VIP 여부",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
                isSelfCaddie: {
                  name: "셀프캐디가능",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
                levelName: {
                  name: "직위명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                workDivision: {
                  name: "직업구분",
                  type: "commonCode",
                  groupCode: "WORK_DIV",
                  methods: ["create", "update", "delete"],
                },
                nativeDivision: {
                  name: "내국인구분",
                  type: "commonCode",
                  groupCode: "NATIVE_DIV",
                  methods: ["create", "update", "delete"],
                },
                nationCode: {
                  name: "국가구분",
                  type: "commonCode",
                  groupCode: "NATION_CODE",
                  methods: ["create", "update", "delete"],
                },
                vehicleKind: {
                  name: "차량종류",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                vehicleNumber: {
                  name: "차량번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                handicap: {
                  name: "핸디캡",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                email: {
                  name: "이메일",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                allowReceiptEmail: {
                  name: "이메일수신동의",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
                postReceiptDivision: {
                  name: "우편물수신구분",
                  type: "commonCode",
                  groupCode: "POST_RECPTN_DIV",
                  methods: ["create", "update", "delete"],
                },
                drmncyCnvrsCode: {
                  name: "휴면전환코드",
                  type: "commonCode",
                  groupCode: "DRMNCY_CNVRS_CODE",
                  methods: ["create", "update", "delete"],
                },
                allowProvideThirdParties: {
                  name: "제3자정보제공동의",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
                allowReceiptMarketing: {
                  name: "마케팅정보수신동의",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
                homeTelephoneNumber: {
                  name: "자택전화번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                companyTelephoneNumber: {
                  name: "회사전화번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                faxNumber: {
                  name: "팩스번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                areaCode: {
                  name: "지역구분",
                  type: "commonCode",
                  groupCode: "AREA_CODE",
                  methods: ["create", "update", "delete"],
                },
                homePostalCode: {
                  name: "집우편번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                homeAddress: {
                  name: "집주소1",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                homeAdditionalAddress: {
                  name: "집주소2",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                companyPostalCode: {
                  name: "회사우편번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                companyAddress: {
                  name: "회사주소1",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                companyAdditionalAddress: {
                  name: "회사주소2",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                reserveManagerPostalCode: {
                  name: "예약담당자우편번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                reserveManagerAddress: {
                  name: "예약담당자주소1",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                reserveManagerAdditionalAddress: {
                  name: "예약담당자주소2",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                remarks: {
                  name: "비고",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
              },
              WebIdInformation: {
                __name__: "웹아이디정보",
                id: {
                  name: "아이디",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                isIntercepted: {
                  name: "차단여부",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
                isTemporary: {
                  name: "임시발급여부",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
              },
              Membership: {
                __name__: (type, history) =>
                  `회원권정보 (${history?.record?.id})`,
                memberNumber: {
                  name: "회원번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                memberDivision: {
                  name: "회원구분",
                  type: "commonCode",
                  groupCode: "MEMBER_DIV",
                  methods: ["create", "update", "delete"],
                },
                isRepresentative: {
                  name: "대표여부",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
                memberGrade: {
                  name: "회원등급",
                  type: "commonCode",
                  groupCode: "MEMBER_GRADE",
                  methods: ["create", "update", "delete"],
                },
                entryOrder: {
                  name: "가입차수",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                corporateDivision: {
                  name: "법인구분",
                  type: "commonCode",
                  groupCode: "CPR_DIV",
                  methods: ["create", "update", "delete"],
                },
                renameDivision: {
                  name: "명의변경구분",
                  type: "commonCode",
                  groupCode: "RENAME_DIV",
                  methods: ["create", "update", "delete"],
                },
                isOut: {
                  name: "탈회여부",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
                outDate: {
                  name: "탈회일자",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                membershipStatus: {
                  name: "회원상태",
                  type: "commonCode",
                  groupCode: "MEMBERSHIP_STATUS",
                  methods: ["create", "update", "delete"],
                },
                memberCertificateNumber: {
                  name: "회원증서번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                saleNumber: {
                  name: "입회번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                groupCompanyCode: {
                  name: "그룹사",
                  type: "commonCode",
                  groupCode: "GROUP_COMPANY_CODE",
                  methods: ["create", "update", "delete"],
                },
                affltsCompanyCode: {
                  name: "계열사",
                  type: "commonCode",
                  groupCode: "AFFLTS_COMPANY_CODE",
                  methods: ["create", "update", "delete"],
                },
                benefitDescription: {
                  name: "특전내용",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                benefitReservation: {
                  name: "특전예약",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                hasBenefit: {
                  name: "개인특전여부",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
                corporationName: {
                  name: "법인명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                businessNumber: {
                  name: "사업자번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                corporationNumber: {
                  name: "법인번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                businessForm: {
                  name: "업태",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                businessSector: {
                  name: "업종",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                issueAmount: {
                  name: "분양금액",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                acquireDate: {
                  name: "취득일자",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                acquireAmount: {
                  name: "취득금액",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                acquireTaxAmount: {
                  name: "취득세금액",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                paidAmount: {
                  name: "납입금액",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                unpaidAmount: {
                  name: "미납금액",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                rwrtDate: {
                  name: "개서일자",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                expireDate: {
                  name: "만기일자",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
              },
            },
          });
        });
      });
    },
    onClickMembershipCardPrint() {
      const currentDate = new Date();
      const reportData = {
        reportJson: {
          jsonData: {},
          year: currentDate.getFullYear(),
          month: currentDate.getMonth() + 1,
          date: currentDate.getDate(),
          memberName: this.memberInputs.name,
          memberNumber: this.getMemberNumberFormat(),
        },
        isDirectPrint: true,
      };

      this.$refs.reportViewComponent.postReport(reportData, "MemberCard");
    },
    getMemberNumberFormat() {
      const { ccNo } = commonCodesGetBizMstInfo();
      const middleNum = this.membershipInputs.memberNumber.substring(0, 2);
      const lastNum = this.membershipInputs.memberNumber.substring(2, this.membershipInputs.memberNumber.length - 2);

      return ccNo.concat("-", middleNum, "-", lastNum);
    },
    onRefreshClick() {
      if (this.isCreateForm) {
        return;
      }

      if (this.isModified) {
        this.confirm("변경된 내용이 있습니다. 무시하고 진행하시겠습니까?").then(
          (confirmed) => {
            if (confirmed) {
              this.fetchInit();
            }
          }
        );
      } else {
        this.fetchInit();
      }
    },
    onPostCodeClick(postcodeTarget) {
      this.postcodeTarget = postcodeTarget;
      this.visibleDaumPostcodePopup = true;
      this.$nextTick(() => {
        this.$refs.daumPostcodePopup.showPopup();
      });
    },
    async onFileChange(event) {
      const file = event.target.files[0];

      // TODO scaling

      try {
        this.memberInputs = Object.assign(
          {},
          {
            ...this.memberInputs,
            imageFile: { path: await encodeBase64(file), name: file.name },
          }
        );
      } catch (e) {
        this.memberInputs.imageFile = this.member.imageFile;
        console.error(e);
      }
    },
    onCancelProfileImageClick() {
      const canceled = Object.assign(
        {},
        { ...this.memberInputs, imageFile: this.member.imageFile }
      );
      if (!canceled.imageFile) {
        delete canceled.imageFile;
      }

      this.memberInputs = canceled;
      this.$refs["memberFileUploader"].value = "";
    },
    async onDeleteProfileImageClick() {
      if (!(await this.confirm("삭제하시겠습니까? (삭제 후 복구 불가)"))) {
        return;
      }

      await this.deleteImage();
      this.member.imageFile = null;
      this.memberInputs.imageFile = null;
      this.mutated = true;
      this.infoToast(this.$t("main.popupMessage.deleted"));
    },
    onAddMembershipClick() {
      if (!this.enableManageMembership) {
        return;
      }

      if (this.systemConfigCourseClass === 'P') {
        return;
      }

      this.enableManageMembership = false;

      this.memberInputs.memberships.push({
        ...getDefaultMembershipInputs(),
        isAutoAssignmentMemberNumber: this.defaultIsAutoAssignmentMemberNumber,
      });

      this.$refs["appMembershipTab"].addTab(
        [
          {
            header: {
              text: "New",
            },
            content: "",
          },
        ],
        this.memberInputs.memberships.length - 1
      );

      this.$nextTick(() => {
        this.$refs["appMembershipTab"].select(
          this.memberInputs.memberships.length - 1
        );
      });
    },
    async onMembershipTabSelected(args) {
      if (this.isCreateForm) {
        return;
      }

      this.selectedMembershipIndex = args.selectedIndex;
      this.membershipInputs = this.memberInputs.memberships[args.selectedIndex];

      // 연계회원 목록 그리드 datasource 설정
      this.setLinkMembershipsDataSource();

      // 거래처 ID가 있으면 사업장 정보 조회. 없으면 초기화
      this.refreshBusinessCustomerDropdownList(
        this.membershipInputs.businessCustomerId
      );

      this.enableManageMembership = true;

      await this.$nextTick();
      this.$refs.membershipSectionBody.scrollTo(0, 0);
    },
    /**
     * 연계회원 목록 그리드 datasource 설정
     * 본인 포함
     * 탭 클릭 시, 등록 후 조회 시 설정
     */
    setLinkMembershipsDataSource() {
      this.linkMembershipsDataSource = _orderBy((
        this.membershipInputs.linkMemberships || []
      ).map((linkMembership) => ({
        ...linkMembership,
        memberName: linkMembership.member?.name || "",
      })), "memberNumber");
    },
    /**
     * 사업자번호 선택 dropdown list refresh
     */
    refreshBusinessCustomerDropdownList(businessCustomerId) {
      this.businessNumbers = [];
      if (businessCustomerId) {
        GolfErpAPI.fetchBusinessPartners(null, businessCustomerId).then(
          (businessPartners) => {
            this.businessNumbers = businessPartners;
            this.$refs.businessNumbers.ej2Instances.value = businessCustomerId;
          }
        );
      }
    },
    onDeleteMembershipClick() {
      if (this.memberInputs.memberships.length === 1) {
        this.errorPopup("회원권은 최소 1개 이상 설정되어야 합니다.");
        return;
      }

      if (!this.enableManageMembership) {
        return;
      }

      this.enableManageMembership = false;

      const selectedMembershipIndex = this.selectedMembershipIndex;
      this.memberInputs.memberships = this.memberInputs.memberships.filter(
        (value, index) => index !== selectedMembershipIndex
      );

      this.$nextTick(() => {
        this.$refs["appMembershipTab"].removeTab(selectedMembershipIndex);
      });
    },
    onNearbyCodeChange(args) {
      if (this.isAcquireDateChange) {
        const jsonData = commonCodesGetCommonCodeByIdx('NEARBY_CODE', 1)
          .find(i => i.comCode === args.value)?.jsonData;
        if (jsonData && jsonData.length > 0) {
          const data = JSON.parse(jsonData);
          if (data && data.length > 0) {
            this.membershipInputs.expireDate = moment(this.membershipInputs.acquireDate).add(data[0].ATTRB, 'months').format(DATE_FORMAT_YYYY_MM_DD);
          }
        }
      }
    },
    onAcquireDateChange(args) {
      if (this.isAcquireDateChange) {
        if (args.data) {
          const jsonData = commonCodesGetCommonCodeByIdx('NEARBY_CODE', 1)
              .find(i => i.comCode === this.memberInputs.nearbyCode)?.jsonData;
          if (jsonData && jsonData.length > 0) {
            const data = JSON.parse(jsonData);
            if (data && data.length > 0) {
              this.membershipInputs.expireDate = moment(args.data)
                  .add(data[0].ATTRB, 'months')
                  .format(DATE_FORMAT_YYYY_MM_DD);
            }
          }
        } else {
          this.membershipInputs.expireDate = null;
        }
      }
    },
    onIsAutoAssignmentMemberNumberChange(event) {
      if (event.target.checked) {
        this.membershipInputs.memberNumber = null;
      }
    },
    // async onMemberDivChange(args) {
    //   this.isVisibleAnnualFeeButton = this.familyMemberDivCodes.includes(args.value);
    // },
    async beforeSaving(isReservationBenefit = false) {
      if (!this.membershipInputs.id) {
        return;
      }

      if (this.isModified) {
        if (
            !(await this.confirm(
                "회원정보에 변경사항이 있어 저장후 진행할 수 있습니다. 저장하시겠습니까?"
            ))
        ) {
          return;
        }

        await this.onSaveClick();
        if (isReservationBenefit) {
          await GolfErpAPI.postAutoIndividualBenefitSetting({
            membershipId: this.membershipInputs.id,
            memberGrade: this.membershipInputs.memberGrade,
          });
        }
      }
    },
    async onReservationBenefitButtonClick() {
      await this.beforeSaving(true);
      this.visibleMembershipFitResvePopup = true;
      await this.$nextTick();
      await this.$refs.membershipFitResvePopup.showMembershipFitResvePopup(
          this.membershipInputs.id
      );
    },
    async onPaymentBenefitButtonClick() {
      await this.beforeSaving();
      this.visibleMembershipFitPaymtPopup = true;
      await this.$nextTick();
      await this.$refs.membershipFitPaymtPopup.showMembershipFitPaymtPopup(
        this.membershipInputs.id
      );
    },
    businessNumbersFiltering: _debounce((args) => {
      const { text: q } = args;
      if (!q) {
        return;
      }

      GolfErpAPI.fetchBusinessPartners(q).then((businessPartners) => {
        args.updateData(businessPartners);
        this.businessNumbers = businessPartners;
      });
    }, 200),
    onAddLinkMemberClick() {
      if (this.isCreateForm) {
        return;
      }

      this.visibleLinkMemberPopup = true;
      this.$nextTick(() => {
        const memberNo = this.membershipInputs.memberNumber;
        const linkMemberId = this.membershipInputs.linkId;
        this.$refs["linkMemberPopup"].showPopup(memberNo, linkMemberId);
      });
    },
    validateToCreateMember() {
      const memberInputsErrors = this.createMemberValidator.validate(
        this.memberInputs
      );
      const [memberInputsFirstErrorKey] = Object.getOwnPropertyNames(
        memberInputsErrors
      ).filter((key) => 0 < memberInputsErrors[key].length);
      if (memberInputsFirstErrorKey) {
        const [{ rule, valid, message: _message }] = memberInputsErrors[
          memberInputsFirstErrorKey
        ];

        let message = null;
        switch (rule) {
          case "required":
            message = `${MEMBER_INPUT_LABELS[memberInputsFirstErrorKey]}은(는) 필수값입니다.`;
            break;
          case "length":
            message = `${MEMBER_INPUT_LABELS[memberInputsFirstErrorKey]}은(는) ${valid}자를 입력해야합니다.`;
            break;
          case "min-length":
            message = `${MEMBER_INPUT_LABELS[memberInputsFirstErrorKey]}은(는) 최소 ${valid}자 이상을 입력해야합니다.`;
            break;
          case "max-length":
            message = `${MEMBER_INPUT_LABELS[memberInputsFirstErrorKey]}은(는) 최대 ${valid}자 까지 입력해야합니다.`;
            break;
          case "telephone":
            message = `${MEMBER_INPUT_LABELS[memberInputsFirstErrorKey]}을(를) 핸드폰번호 형식에 맞게 입력해주세요.`;
            break;
          case "email":
            message = `'${MEMBER_INPUT_LABELS[memberInputsFirstErrorKey]}'이(가) 이메일 형식에 맞지 않습니다.`;
            break;
          case "date":
            message = `${MEMBER_INPUT_LABELS[memberInputsFirstErrorKey]}을(를) 날짜 형식에 맞게 입력해주세요.`;
            break;
          case "time":
            message = `${MEMBER_INPUT_LABELS[memberInputsFirstErrorKey]}을(를) 날짜 형식에 맞게 입력해주세요.`;
            break;
          case "min":
            message = `${MEMBER_INPUT_LABELS[memberInputsFirstErrorKey]}은(는) 최소 ${valid} 이상의 값을 입력해야합니다.`;
            break;
          case "max":
            message = `${MEMBER_INPUT_LABELS[memberInputsFirstErrorKey]}은(는) 최대 ${valid} 이하의 값을 입력해야합니다.`;
            break;
          default:
            message = _message;
        }

        this.errorToast(message);
        const refs = this.$refs[`input-${memberInputsFirstErrorKey}`];
        if (refs) {
          (Array.isArray(refs)
            ? refs[0].focus || refs[0].focusIn
            : refs.focus || refs.focusIn)();
        }

        return false;
      }

      const compactedMemberDetails = compactObjectValues(
        this.memberInputs?.details,
        false,
        true
      );
      if (compactedMemberDetails) {
        const memberDetails = {
          ...compactedMemberDetails,
          vipFlag: compactedMemberDetails.vipFlag ?? false,
          isSelfCaddie: compactedMemberDetails.isSelfCaddie ?? false,
          postReceiptDivision:
            compactedMemberDetails.postReceiptDivision ??
            commonCodeGetComCodeDefaultValue("POST_RECPTN_DIV"),
          drmncyCnvrsCode:
            compactedMemberDetails.drmncyCnvrsCode ??
            commonCodeGetComCodeDefaultValue("DRMNCY_CNVRS_CODE"),
          allowReceiptEmail: compactedMemberDetails.allowReceiptEmail ?? true,
          allowProvideThirdParties:
            compactedMemberDetails.allowProvideThirdParties ?? true,
          allowReceiptMarketing:
            compactedMemberDetails.allowReceiptMarketing ?? true,
        };

        if (memberDetails.handicap) {
          memberDetails.handicap = Number(memberDetails.handicap);
        }

        const memberDetailsInputsErrors = this.createMemberMemberDetailsValidator.validate(
          memberDetails
        );

        const [memberDetailsFirstErrorKey] = Object.getOwnPropertyNames(
          memberDetailsInputsErrors
        ).filter((key) => 0 < memberDetailsInputsErrors[key].length);
        if (memberDetailsFirstErrorKey) {
          const [
            { rule, valid, message: _message },
          ] = memberDetailsInputsErrors[memberDetailsFirstErrorKey];
          let message = null;
          switch (rule) {
            case "required":
              message = `${MEMBER_DETAILS_INPUT_LABELS[memberDetailsFirstErrorKey]}은(는) 필수값입니다.`;
              break;
            case "length":
              message = `${MEMBER_DETAILS_INPUT_LABELS[memberDetailsFirstErrorKey]}은(는) ${valid}자를 입력해야합니다.`;
              break;
            case "min-length":
              message = `${MEMBER_DETAILS_INPUT_LABELS[memberDetailsFirstErrorKey]}은(는) 최소 ${valid}자 이상을 입력해야합니다.`;
              break;
            case "max-length":
              message = `${MEMBER_DETAILS_INPUT_LABELS[memberDetailsFirstErrorKey]}은(는) 최대 ${valid}자 까지 입력해야합니다.`;
              break;
            case "telephone":
              message = `${MEMBER_DETAILS_INPUT_LABELS[memberDetailsFirstErrorKey]}을(를) 핸드폰번호 형식에 맞게 입력해주세요.`;
              break;
            case "email":
              message = `'${MEMBER_DETAILS_INPUT_LABELS[memberDetailsFirstErrorKey]}'이(가) 이메일 형식에 맞지 않습니다.`;
              break;
            case "date":
              message = `${MEMBER_DETAILS_INPUT_LABELS[memberDetailsFirstErrorKey]}을(를) 날짜 형식에 맞게 입력해주세요.`;
              break;
            case "time":
              message = `${MEMBER_DETAILS_INPUT_LABELS[memberDetailsFirstErrorKey]}을(를) 날짜 형식에 맞게 입력해주세요.`;
              break;
            case "min":
              message = `${MEMBER_DETAILS_INPUT_LABELS[memberDetailsFirstErrorKey]}은(는) 최소 ${valid} 이상의 값을 입력해야합니다.`;
              break;
            case "max":
              message = `${MEMBER_DETAILS_INPUT_LABELS[memberDetailsFirstErrorKey]}은(는) 최대 ${valid} 이하의 값을 입력해야합니다.`;
              break;
            default:
              message = _message;
          }

          this.visibleMemberDetailsInputs = true;
          this.errorToast(message);
          const refs = this.$refs[`input-${memberDetailsFirstErrorKey}`];
          if (refs) {
            this.$nextTick(() => {
              (Array.isArray(refs)
                ? refs[0].focus || refs[0].focusIn
                : refs.focus || refs.focusIn)();
            });
          }

          return false;
        }
      }

      const membershipInputsErrors = this.createMemberMembershipValidator.validate(
        this.membershipInputs
      );
      // 회원번호가 자동발급인 경우 validation 체크 제외.
      if (
        this.membershipInputs.isAutoAssignmentMemberNumber &&
        !!membershipInputsErrors.memberNumber &&
        membershipInputsErrors.memberNumber.length > 0
      ) {
        membershipInputsErrors.memberNumber = [];
      }

      const [membershipInputsFirstErrorKey] = Object.getOwnPropertyNames(
        membershipInputsErrors
      ).filter((key) => 0 < membershipInputsErrors[key].length);
      if (membershipInputsFirstErrorKey) {
        const [{ rule, valid, message: _message }] = membershipInputsErrors[
          membershipInputsFirstErrorKey
        ];
        let message = null;
        switch (rule) {
          case "required":
            message = `${MEMBERSHIP_INPUT_LABELS[membershipInputsFirstErrorKey]}은(는) 필수값입니다.`;
            break;
          case "length":
            message = `${MEMBERSHIP_INPUT_LABELS[membershipInputsFirstErrorKey]}은(는) ${valid}자를 입력해야합니다.`;
            break;
          case "min-length":
            message = `${MEMBERSHIP_INPUT_LABELS[membershipInputsFirstErrorKey]}은(는) 최소 ${valid}자 이상을 입력해야합니다.`;
            break;
          case "max-length":
            message = `${MEMBERSHIP_INPUT_LABELS[membershipInputsFirstErrorKey]}은(는) 최대 ${valid}자 까지 입력해야합니다.`;
            break;
          case "telephone":
            message = `${MEMBERSHIP_INPUT_LABELS[membershipInputsFirstErrorKey]}을(를) 핸드폰번호 형식에 맞게 입력해주세요.`;
            break;
          case "email":
            message = `'${MEMBERSHIP_INPUT_LABELS[membershipInputsFirstErrorKey]}'이(가) 이메일 형식에 맞지 않습니다.`;
            break;
          case "date":
            message = `${MEMBERSHIP_INPUT_LABELS[membershipInputsFirstErrorKey]}을(를) 날짜 형식에 맞게 입력해주세요.`;
            break;
          case "time":
            message = `${MEMBERSHIP_INPUT_LABELS[membershipInputsFirstErrorKey]}을(를) 날짜 형식에 맞게 입력해주세요.`;
            break;
          case "min":
            message = `${MEMBERSHIP_INPUT_LABELS[membershipInputsFirstErrorKey]}은(는) 최소 ${valid} 이상의 값을 입력해야합니다.`;
            break;
          case "max":
            message = `${MEMBERSHIP_INPUT_LABELS[membershipInputsFirstErrorKey]}은(는) 최대 ${valid} 이하의 값을 입력해야합니다.`;
            break;
          default:
            message = _message;
        }

        this.errorToast(message);
        const refs = this.$refs[`input-${membershipInputsFirstErrorKey}`];
        if (refs) {
          (Array.isArray(refs)
            ? refs[0].focus || refs[0].focusIn
            : refs.focus || refs.focusIn)();
        }

        return false;
      }

      return true;
    },
    validateToUpdateMember() {
      const compactedMember = compactObjectValues(
        this.getChanges(),
        false,
        true
      );
      if (compactedMember) {
        const memberInputsErrors = this.patchMemberValidator.validate(
          compactedMember
        );
        const [memberInputsFirstErrorKey] = Object.getOwnPropertyNames(
          memberInputsErrors
        ).filter((key) => 0 < memberInputsErrors[key].length);
        if (memberInputsFirstErrorKey) {
          const [{ rule, valid, message: _message }] = memberInputsErrors[
            memberInputsFirstErrorKey
          ];

          let message = null;
          switch (rule) {
            case "required":
              message = `${MEMBER_INPUT_LABELS[memberInputsFirstErrorKey]}은(는) 필수값입니다.`;
              break;
            case "length":
              message = `${MEMBER_INPUT_LABELS[memberInputsFirstErrorKey]}은(는) ${valid}자를 입력해야합니다.`;
              break;
            case "min-length":
              message = `${MEMBER_INPUT_LABELS[memberInputsFirstErrorKey]}은(는) 최소 ${valid}자 이상을 입력해야합니다.`;
              break;
            case "max-length":
              message = `${MEMBER_INPUT_LABELS[memberInputsFirstErrorKey]}은(는) 최대 ${valid}자 까지 입력해야합니다.`;
              break;
            case "telephone":
              message = `${MEMBER_INPUT_LABELS[memberInputsFirstErrorKey]}을(를) 핸드폰번호 형식에 맞게 입력해주세요.`;
              break;
            case "email":
              message = `'${MEMBER_INPUT_LABELS[memberInputsFirstErrorKey]}'이(가) 이메일 형식에 맞지 않습니다.`;
              break;
            case "date":
              message = `${MEMBER_INPUT_LABELS[memberInputsFirstErrorKey]}을(를) 날짜 형식에 맞게 입력해주세요.`;
              break;
            case "time":
              message = `${MEMBER_INPUT_LABELS[memberInputsFirstErrorKey]}을(를) 날짜 형식에 맞게 입력해주세요.`;
              break;
            case "min":
              message = `${MEMBER_INPUT_LABELS[memberInputsFirstErrorKey]}은(는) 최소 ${valid} 이상의 값을 입력해야합니다.`;
              break;
            case "max":
              message = `${MEMBER_INPUT_LABELS[memberInputsFirstErrorKey]}은(는) 최대 ${valid} 이하의 값을 입력해야합니다.`;
              break;
            default:
              message = _message;
          }

          this.errorToast(message);
          const refs = this.$refs[`input-${memberInputsFirstErrorKey}`];
          if (refs) {
            (Array.isArray(refs)
              ? refs[0].focus || refs[0].focusIn
              : refs.focus || refs.focusIn)();
          }

          return false;
        }

        const compactedMemberDetails = compactedMember.details;
        if (compactedMemberDetails) {
          if (compactedMemberDetails.handicap) {
            compactedMemberDetails.handicap = Number(
              compactedMemberDetails.handicap
            );
          }

          let memberDetailsInputsErrors = [];
          if (!this.member.details) {
            const memberDetails = {
              ...compactedMemberDetails,
              vipFlag: compactedMemberDetails.vipFlag ?? false,
              isSelfCaddie: compactedMemberDetails.isSelfCaddie ?? false,
              postReceiptDivision:
                compactedMemberDetails.postReceiptDivision ??
                commonCodeGetComCodeDefaultValue("POST_RECPTN_DIV"),
              drmncyCnvrsCode:
                compactedMemberDetails.drmncyCnvrsCode ??
                commonCodeGetComCodeDefaultValue("DRMNCY_CNVRS_CODE"),
              allowReceiptEmail:
                compactedMemberDetails.allowReceiptEmail ?? true,
              allowProvideThirdParties:
                compactedMemberDetails.allowProvideThirdParties ?? true,
              allowReceiptMarketing:
                compactedMemberDetails.allowReceiptMarketing ?? true,
            };

            memberDetailsInputsErrors.concat(
              this.createMemberMemberDetailsValidator.validate(memberDetails)
            );
          } else {
            memberDetailsInputsErrors.concat(
              this.patchMemberMemberDetailsValidator.validate(
                compactedMemberDetails
              )
            );
          }

          const [memberDetailsFirstErrorKey] = Object.getOwnPropertyNames(
            memberDetailsInputsErrors
          ).filter((key) => 0 < memberDetailsInputsErrors[key].length);
          if (memberDetailsFirstErrorKey) {
            const [
              { rule, valid, message: _message },
            ] = memberDetailsInputsErrors[memberDetailsFirstErrorKey];
            let message = null;
            switch (rule) {
              case "required":
                message = `${MEMBER_DETAILS_INPUT_LABELS[memberDetailsFirstErrorKey]}은(는) 필수값입니다.`;
                break;
              case "length":
                message = `${MEMBER_DETAILS_INPUT_LABELS[memberDetailsFirstErrorKey]}은(는) ${valid}자를 입력해야합니다.`;
                break;
              case "min-length":
                message = `${MEMBER_DETAILS_INPUT_LABELS[memberDetailsFirstErrorKey]}은(는) 최소 ${valid}자 이상을 입력해야합니다.`;
                break;
              case "max-length":
                message = `${MEMBER_DETAILS_INPUT_LABELS[memberDetailsFirstErrorKey]}은(는) 최대 ${valid}자 까지 입력해야합니다.`;
                break;
              case "telephone":
                message = `${MEMBER_DETAILS_INPUT_LABELS[memberDetailsFirstErrorKey]}을(를) 핸드폰번호 형식에 맞게 입력해주세요.`;
                break;
              case "email":
                message = `'${MEMBER_DETAILS_INPUT_LABELS[memberDetailsFirstErrorKey]}'이(가) 이메일 형식에 맞지 않습니다.`;
                break;
              case "date":
                message = `${MEMBER_DETAILS_INPUT_LABELS[memberDetailsFirstErrorKey]}을(를) 날짜 형식에 맞게 입력해주세요.`;
                break;
              case "time":
                message = `${MEMBER_DETAILS_INPUT_LABELS[memberDetailsFirstErrorKey]}을(를) 날짜 형식에 맞게 입력해주세요.`;
                break;
              case "min":
                message = `${MEMBER_DETAILS_INPUT_LABELS[memberDetailsFirstErrorKey]}은(는) 최소 ${valid} 이상의 값을 입력해야합니다.`;
                break;
              case "max":
                message = `${MEMBER_DETAILS_INPUT_LABELS[memberDetailsFirstErrorKey]}은(는) 최대 ${valid} 이하의 값을 입력해야합니다.`;
                break;
              default:
                message = _message;
            }

            this.visibleMemberDetailsInputs = true;
            this.errorToast(message);
            const refs = this.$refs[`input-${memberDetailsFirstErrorKey}`];
            if (refs) {
              this.$nextTick(() => {
                (Array.isArray(refs)
                  ? refs[0].focus || refs[0].focusIn
                  : refs.focus || refs.focusIn)();
              });
            }

            return false;
          }
        }

        const compactedMemberships = compactedMember.memberships;
        if (compactedMemberships) {
          if (compactedMemberships.length === 0) {
            this.errorToast("멤버쉽은 하나 이상 입력되어야 합니다.");
            return false;
          }

          for (let i = 0; i < compactedMemberships.length; i++) {
            const compactedMembership = compactedMemberships[i];
            const membershipInputsErrors = !compactedMembership?.id
              ? this.createMemberMembershipValidator.validate(
                  compactedMembership
                )
              : this.patchMemberMembershipValidator.validate(
                  compactedMembership
                );
            // 회원번호가 자동발급인 경우 validation 체크 제외.
            if (
              compactedMembership.isAutoAssignmentMemberNumber &&
              !!membershipInputsErrors.memberNumber &&
              membershipInputsErrors.memberNumber.length > 0
            ) {
              membershipInputsErrors.memberNumber = [];
            }

            // 사업자번호 및 법인번호가 미입력 상태이면 validation 체크 제외.
            if (
              !!membershipInputsErrors.businessNumber &&
              membershipInputsErrors.businessNumber.length > 0 &&
              !compactedMembership.businessNumber
            ) {
              membershipInputsErrors.businessNumber = [];
            }
            if (
              !!membershipInputsErrors.corporationNumber &&
              membershipInputsErrors.corporationNumber.length > 0 &&
              !compactedMembership.corporationNumber
            ) {
              membershipInputsErrors.corporationNumber = [];
            }
            const [membershipInputsFirstErrorKey] = Object.getOwnPropertyNames(
              membershipInputsErrors
            ).filter((key) => 0 < membershipInputsErrors[key].length);
            if (membershipInputsFirstErrorKey) {
              const [
                { rule, valid, message: _message },
              ] = membershipInputsErrors[membershipInputsFirstErrorKey];
              let message = null;
              switch (rule) {
                case "required":
                  message = `${MEMBERSHIP_INPUT_LABELS[membershipInputsFirstErrorKey]}은(는) 필수값입니다.`;
                  break;
                case "length":
                  message = `${MEMBERSHIP_INPUT_LABELS[membershipInputsFirstErrorKey]}은(는) ${valid}자를 입력해야합니다.`;
                  break;
                case "min-length":
                  message = `${MEMBERSHIP_INPUT_LABELS[membershipInputsFirstErrorKey]}은(는) 최소 ${valid}자 이상을 입력해야합니다.`;
                  break;
                case "max-length":
                  message = `${MEMBERSHIP_INPUT_LABELS[membershipInputsFirstErrorKey]}은(는) 최대 ${valid}자 까지 입력해야합니다.`;
                  break;
                case "telephone":
                  message = `${MEMBERSHIP_INPUT_LABELS[membershipInputsFirstErrorKey]}을(를) 핸드폰번호 형식에 맞게 입력해주세요.`;
                  break;
                case "email":
                  message = `'${MEMBERSHIP_INPUT_LABELS[membershipInputsFirstErrorKey]}'이(가) 이메일 형식에 맞지 않습니다.`;
                  break;
                case "date":
                  message = `${MEMBERSHIP_INPUT_LABELS[membershipInputsFirstErrorKey]}을(를) 날짜 형식에 맞게 입력해주세요.`;
                  break;
                case "time":
                  message = `${MEMBERSHIP_INPUT_LABELS[membershipInputsFirstErrorKey]}을(를) 날짜 형식에 맞게 입력해주세요.`;
                  break;
                case "min":
                  message = `${MEMBERSHIP_INPUT_LABELS[membershipInputsFirstErrorKey]}은(는) 최소 ${valid} 이상의 값을 입력해야합니다.`;
                  break;
                case "max":
                  message = `${MEMBERSHIP_INPUT_LABELS[membershipInputsFirstErrorKey]}은(는) 최대 ${valid} 이하의 값을 입력해야합니다.`;
                  break;
                default:
                  message = _message;
              }

              this.$refs["appMembershipTab"].select(i);
              this.$nextTick(() => {
                this.errorToast(message);
                const refs = this.$refs[
                  `input-${membershipInputsFirstErrorKey}`
                ];
                if (refs) {
                  (Array.isArray(refs)
                    ? refs[0].focus || refs[0].focusIn
                    : refs.focus || refs.focusIn)();
                }
              });

              return false;
            }
          }
        }
      }

      return true;
    },
    async onSaveClick() {
      try {
        if (this.isCreateForm) {
          if (!this.validateToCreateMember()) {
            throw new Error("Invalid Create Member Data");
          }

            await this.hpNoCheck();

            await this.createMember();
        } else {
          if (!this.validateToUpdateMember()) {
            throw new Error("Invalid Update Member Data");
          }

          await this.hpNoCheck();

          await this.patchMember();
        }

        this.mutated = true;
        this.infoToast(this.$t("main.popupMessage.saved"));
      } catch (e) {
        console.log("onSaveClick.err.===>", e);
        if (e?.getOriginData?.()?.data && (typeof e?.getOriginData?.()?.data) === "object") {
          this.errorToast(e?.getOriginData?.()?.data.map(item => item.code).join("<br>"));
        }
      }
    },

    onWebMemberInfoPopupEvent(eventId) {
      if (eventId === "popupClosed" || eventId === "memberSaved") {
        this.visibleWebMemberInfoPopup = false;
        this.fetchInit();
      }
    },
    linkMemberPopupClosed() {
      this.visibleLinkMemberPopup = false;
    },
    async linkMemberPopupConfirmed(args) {
      const {
        addMemberId,
        membershipList,
      } = args;

      if (addMemberId) {
        this.member.id = addMemberId;
        await this.fetchMember();
        const addMembershipList = membershipList.map(item => {
          const defaultMembershipInputs = getDefaultMembershipInputs();
          defaultMembershipInputs.memberNumber = item.memberNo;
          defaultMembershipInputs.linkId = item.linkMemberId;
          defaultMembershipInputs.corporateDivision = item.cprDiv;
          defaultMembershipInputs.entryOrder = item.entryOrder;
          defaultMembershipInputs.renameDivision = item.renameDiv;
          defaultMembershipInputs.membershipStatus = item.membershipStatus;
          defaultMembershipInputs.isOut = item.outFlag;
          defaultMembershipInputs.hasBenefit = item.fitBenFlag;

          return defaultMembershipInputs;
        });

        this.memberInputs.memberships.push(addMembershipList[0]);
        this.membershipInputs = addMembershipList[0];

        this.$refs["appMembershipTab"].addTab(
          [
            {
              header: {
                text: this.membershipInputs.memberNumber,
              },
              content: "",
            },
          ],
          this.memberInputs.memberships.length - 1
        );

        this.$nextTick(() => {
          this.$refs["appMembershipTab"].select(
            this.memberInputs.memberships.length - 1
          );
        });

        this.visibleLinkMemberInputs = !this.visibleLinkMemberInputs;
        this.$refs.membershipSectionBody.scrollTo(0, 0);
      } else {
        const member = getDefaultMemberInputs();
        member.memberships = membershipList?.map(
          ({
             memberNo,
             linkMemberId,
             cprDiv,
             entryOrder,
             renameDiv,
             membershipStatus,
             outFlag,
             fitBenFlag,
           }) => {
            const defaultMembershipInputs = getDefaultMembershipInputs();
            defaultMembershipInputs.memberNumber = memberNo;
            defaultMembershipInputs.linkId = linkMemberId;
            defaultMembershipInputs.corporateDivision = cprDiv;
            defaultMembershipInputs.entryOrder = entryOrder;
            defaultMembershipInputs.renameDivision = renameDiv;
            defaultMembershipInputs.membershipStatus = membershipStatus;
            defaultMembershipInputs.isOut = outFlag;
            defaultMembershipInputs.hasBenefit = fitBenFlag;

            return defaultMembershipInputs;
          }
        ) || [getDefaultMembershipInputs()];

        this.member = member;
        this.membershipTabs = member.memberships.map(({memberNumber}) => ({
          header: {
            text: memberNumber,
          },
          content: "",
        }));
        this.selectedMembershipIndex = 0;

        this.memberInputs = member;
        this.membershipInputs = member.memberships[0];
      }

      this.$nextTick(() => {
        this.$refs["input-name"].focus();
      });
    },
    daumPostcodePopupClosed() {
      this.visibleDaumPostcodePopup = false;
    },
    daumPostcodePopupConfirmed(data) {
      if (this.postcodeTarget === "home") {
        this.memberInputs.details.homePostalCode = data.zonecode;
        this.memberInputs.details.homeAddress = data.address;

        const newHomeAddressAreaCodeObj = this.commonCodesOfAreaCodes.find(
          (areaCode) => areaCode?.codeAbrv?.includes(data.sido)
        );
        if (newHomeAddressAreaCodeObj) {
          this.memberInputs.details.areaCode =
            newHomeAddressAreaCodeObj.comCode;
        }
      } else if (this.postcodeTarget === "company") {
        this.memberInputs.details.companyPostalCode = data.zonecode;
        this.memberInputs.details.companyAddress = data.address;
      } else if (this.postcodeTarget === "resveMngr") {
        this.memberInputs.details.reserveManagerPostalCode = data.zonecode;
        this.memberInputs.details.reserveManagerAddress = data.address;
      }

      this.daumPostcodePopupClosed();
    },
    historyPopupClosed() {
      this.visibleHistoryPopup = false;
    },
    membershipFitResvePopupClosed() {
      this.visibleMembershipFitResvePopup = false;
    },
    membershipFitPaymtPopupClosed() {
      this.visibleMembershipFitPaymtPopup = false;
    },

    initMember(member) {
      this.currentPhoneNumber = member.phoneNumber;

      this.member = member;

      const _member = JSON.parse(JSON.stringify(member));
      const defaultMemberInputs = getDefaultMemberInputs();

      this.memberInputs = {
        ...defaultMemberInputs,
        ..._member,
        details: {
          ...getDefaultDetailInputs(),
          ...defaultMemberInputs.details,
          ..._member.details,
        },
        memberships: _member.memberships.map((membership) => ({
          // TODO : membership 초기화 확인
          ...getDefaultMembershipInputs(),
          ...membership,
          isDirectInputBusinessNumber: !!membership.businessNumber, // 탭 별로 다른 값을 갖도록
        })),
      };

      this.membershipTabs = _member.memberships.map(({ memberNumber }) => ({
        header: {
          text: memberNumber,
        },
        content: "",
      }));
      this.selectedMembershipIndex = 0;
      this.membershipInputs = this.memberInputs.memberships[0];

      this.$refs["memberFileUploader"].value = "";
    },
    async fetchInit() {
      const systemConfigCourseClass = commonCodesGetStandardInfo("courseClass");
      this.systemConfigCourseClass = systemConfigCourseClass;
      this.visibleLinkMemberGrid = systemConfigCourseClass !== "P";
      this.defaultIsAutoAssignmentMemberNumber =
        systemConfigCourseClass === "P";

      if (this.member.id) {
        await this.fetchMember(this.member.id);
        await this.$nextTick();
        this.selectedMembershipIndex = this.membershipTabs.findIndex(item => item.header.text === this.memberNo);
        this.$refs.appMembershipTab.select(this.selectedMembershipIndex);
      } else {
        this.membershipInputs = this.memberInputs.memberships[0]; // 추가할 때 회원권 정보 추적을 위함
      }
    },
    async fetchSystemConfigCourseClass() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      try {
        return await GolfErpAPI.fetchSystemConfigCourseClass();
      } finally {
        this.isLoading = false;
      }
    },
    async fetchMember() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      try {
        this.initMember(await GolfErpAPI.fetchMember(this.member.id, this.isOut));
      } catch (e) {
        if (e instanceof NotFoundError) {
          this.errorToast("최신 정보를 갱신 후 다시 시도해주시기 바랍니다.");
        } else if (e.getData() === "Error") {
          // errortoast가 interceptor에서 이미 뜬 경우, 뜨지 않도록
          this.errorToast("조회 중 오류가 발생하였습니다");
        }
        throw e;
      } finally {
        this.isLoading = false;
      }
    },
    async createMember() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      try {
        const member = this.memberInputs;
        const compactedMemberDetails = compactObjectValues(
          this.memberInputs?.details,
          false,
          true
        );
        if (compactedMemberDetails) {
          this.memberInputs.details = {
            ...compactedMemberDetails,
            vipFlag: compactedMemberDetails.vipFlag ?? false,
            isSelfCaddie: compactedMemberDetails.isSelfCaddie ?? false,
            postReceiptDivision:
              compactedMemberDetails.postReceiptDivision ??
              commonCodeGetComCodeDefaultValue("POST_RECPTN_DIV"),
            drmncyCnvrsCode:
              compactedMemberDetails.drmncyCnvrsCode ??
              commonCodeGetComCodeDefaultValue("DRMNCY_CNVRS_CODE"),
            allowReceiptEmail: compactedMemberDetails.allowReceiptEmail ?? true,
            allowProvideThirdParties:
              compactedMemberDetails.allowProvideThirdParties ?? true,
            allowReceiptMarketing:
              compactedMemberDetails.allowReceiptMarketing ?? true,
          };
        }

        member.memberships = [this.membershipInputs];

        this.initMember(await GolfErpAPI.createMember(member));

        // 거래처 ID가 있으면 사업장 정보 조회. 없으면 초기화. this.membershipInputs는 this.initMember() 에서 초기화 되었음.
        // 등록후에만 호출하면 됨. 수정후에는 ejs-tab의 selected 이벤트에서 조회함
        this.refreshBusinessCustomerDropdownList(
          this.membershipInputs.businessCustomerId
        );

        // 연계회원 목록 그리드 datasource 설정 - 등록후에만 호출하면 됨. 수정후에는 ejs-tab의 selected 이벤트에서 조회함
        this.setLinkMembershipsDataSource();

        this.$nextTick(() => {
          if (this.systemConfigCourseClass === "P") {
            this.openWebMemberInfoPopup();
          }
        });
      } catch (e) {
        if (e.getData() === "Error") {
          // errortoast가 interceptor에서 이미 뜬 경우, 뜨지 않도록
          this.errorToast("저장 중 오류가 발생하였습니다");
        }
        throw e;
      } finally {
        this.isLoading = false;
      }
    },
    async patchMember() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      if (!this.isModified) {
        this.errorToast("변경한 내용이 없습니다");
        return;
      }

      try {
        if (!this.member.details && !!this.getChanges().details) {
          const details = JSON.parse(JSON.stringify(this.memberInputs.details));
          this.memberInputs.details = {
            ...details,
            vipFlag: details.vipFlag ?? false,
            isSelfCaddie: details.isSelfCaddie ?? false,
            postReceiptDivision:
              details.postReceiptDivision ??
              commonCodeGetComCodeDefaultValue("POST_RECPTN_DIV"),
            drmncyCnvrsCode:
              details.drmncyCnvrsCode ??
              commonCodeGetComCodeDefaultValue("DRMNCY_CNVRS_CODE"),
            allowReceiptEmail: details.allowReceiptEmail ?? true,
            allowProvideThirdParties: details.allowProvideThirdParties ?? true,
            allowReceiptMarketing: details.allowReceiptMarketing ?? true,
          };
        }

        this.initMember(
          await GolfErpAPI.patchMember(this.member.id, this.getChanges())
        );
      } catch (e) {
        if (e instanceof NotFoundError) {
          this.errorToast("최신 정보를 갱신 후 다시 시도해주시기 바랍니다.");
        } else if (e.getData() === "Error") {
          // errortoast가 interceptor에서 이미 뜬 경우, 뜨지 않도록
          this.errorToast("저장 중 오류가 발생하였습니다");
        }
        throw e;
      } finally {
        this.isLoading = false;
      }
    },
    async deleteMember() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      try {
        return await GolfErpAPI.deleteMember(this.member.id);
      } catch (e) {
        if (e instanceof NotFoundError) {
          this.errorToast("최신 정보를 갱신 후 다시 시도해주시기 바랍니다.");
        }
        throw e;
      } finally {
        this.isLoading = false;
      }
    },
    async deleteImage() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      try {
        return await GolfErpAPI.deleteMemberImageFile(this.member.id);
      } catch (e) {
        if (e instanceof NotFoundError) {
          this.errorToast("최신 정보를 갱신 후 다시 시도해주시기 바랍니다.");
          throw e;
        }
      } finally {
        this.isLoading = false;
      }
    },
    businessNumberChanged(args) {
      if (args.isInteracted) {
        // 키보드/마우스로 직접 수정시에만 값 변경
        const itemData = args.itemData;

        this.membershipInputs.businessCustomerId = itemData.id; // UI 변경을 위하여 설정. API 저장 데이터는 아님
        this.membershipInputs.businessNumber = itemData.businessNumber;
        this.membershipInputs.corporationNumber = itemData.corporationNumber;
        this.membershipInputs.corporationName = itemData.name;
        this.membershipInputs.businessForm = itemData.businessForm;
        this.membershipInputs.businessSector = itemData.businessSector;
      }
    },
    inputBusinessNumberChanged(args) {
      if (args.isInteracted) {
        this.membershipInputs.businessCustomerId = null; // UI 변경을 위하여 설정. API 저장 데이터는 아님
        this.businessNumbers = [];
      }
    },
    /**
     * PATCH API request 값을 얻어옴
     * member, details : 변경된 property들
     * memberships : 하나라도 변경되었으면 모든 memberships
     */
    getChanges() {
      if (!this.isModified) {
        return undefined;
      }

      // member, memberdetail 변경사항 얻어오기
      let changedValue = deepDiffs(
        {
          ...getDefaultMemberInputs(),
          ...this.member,
        },
        {
          ...getDefaultMemberInputs(),
          ...this.memberInputs,
        }
      );

      if (!this.isModified.memberships) {
        delete changedValue["memberships"];
      }

      return changedValue;
    },
    async hpNoCheck() {
      const phoneNumber = this.memberInputs.phoneNumber;

      if (!phoneNumber) {
        return;
      }
      if (this.currentPhoneNumber !== phoneNumber) {
        this.isSaveButtonDisabled = true;
        try {
          const { value: hpNoCheckResult } = await getHpNoCheck(
            phoneNumber,
            true
          );
          if (hpNoCheckResult > 0) {
            if (
              !(await this.confirm(
                "같은 핸드폰 번호가 존재합니다.<br>계속 진행하시겠습니까"
              ))
            ) {
              this.memberInputs.phoneNumber = "";
              this.$refs["input-phoneNumber"].focus();
              throw new Error;
            }
          }
        } catch (e) {
          console.log("getHpNoCheck.err.===>", e);
          throw new Error(e);
        } finally {
          this.isSaveButtonDisabled = false;
        }
      }
    },
    onAreaCodeChanged(args) {
      if (args?.e && this.memberInputs.details.sigunCode) {
        this.memberInputs.details.sigunCode = "";
      }
    },
    onClickAnnualFeeRegistration() {
      if (!this.membershipInputs.id) {
        this.errorToast("회원권 ID가 없습니다.");
        return;
      }
      this.visibleMemberAnnualFeeRegistrationPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.memberAnnualFeeRegistrationPopup.showPopup({
          membershipId: this.membershipInputs.id,
        });
      });
    },
    onCloseMemberAnnualFeeRegistrationPopup(eventId) {
      if (eventId === "popupClosed") {
        this.visibleMemberAnnualFeeRegistrationPopupOpen = false;
      }
    },
  },
};
</script>

<template>
  <div>
    <ejs-dialog
      ref="dailyReservationPopup"
      header="일자별 예약현황 목록"
      :width="w"
      :height="h"
      :animationSettings="{ effect: 'None' }"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :enableResize="true"
      :close="closePopup"
    >
      <div class="window reservationStatusByDate">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">예약일자</div>
                    <ul class="content">
                      <li class="item date">
                        <input-date
                          ref="resveDate"
                          v-model="resveDate"
                          type="lookup-condition"
                          format="YYYY-MM-DD"
                          :required="true"
                          @change="onResveDateChange"
                        />
                      </li>
                      <li class="field">
                        <div class="title">코스</div>
                        <ul class="content">
                          <li class="item">
                            <ejs-dropdownlist
                              ref="courseCodeDropdown"
                              v-model="searchConditions.courseCode"
                              :dataSource="searchOptions.courseCode"
                              :fields="fields"
                              :tabindex="-1"
                              :allowFiltering="false"
                              cssClass="lookup-condition-dropdown"
                              @change="getDailyReservationStatus"
                            />
                          </li>
                        </ul>
                      </li>
                      <li class="field">
                        <div class="title">영업구분</div>
                        <ul class="content">
                          <li class="item">
                            <ejs-dropdownlist
                              ref="bsnCodeDropdown"
                              v-model="searchConditions.bsnCode"
                              :dataSource="searchOptions.bsnCode"
                              :fields="fields"
                              :tabindex="-1"
                              :allowFiltering="false"
                              cssClass="lookup-condition-dropdown"
                              @change="getDailyReservationStatus"
                            />
                          </li>
                        </ul>
                      </li>
                      <li class="field">
                        <div class="title">부구분</div>
                        <ul class="content">
                          <li class="item">
                            <ejs-dropdownlist
                              ref="partDivDropdown"
                              v-model="searchConditions.partDiv"
                              :dataSource="searchOptions.partDiv"
                              :fields="fields"
                              :tabindex="-1"
                              :enabled="viewDiv === 'RESERVATION'"
                              :allowFiltering="false"
                              cssClass="lookup-condition-dropdown"
                              @change="getDailyReservationStatus"
                            />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul class="lookup-button">
                  <li class="lookup keyColor">
                    <erp-button
                        buttonDiv="GET"
                        :isShortcutButton="true"
                        @click.native="getDailyReservationStatus"
                    >
                      조회
                    </erp-button>
                  </li>
                </ul>
                <div class="section-header">
                  <div class="header-left">
                    <ul class="header-label">
                      <li class="field">
                        <div class="title">
                          집계
                        </div>
                        <ul class="content">
                          <li class="item">
                            <div class="label">일반 (웹)</div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="lookup-condition right">
                  <li class="field">
                    <div class="title">뷰 모드 (F9)</div>
                    <ul class="content">
                      <li class="item" style="width: 120px">
                        <ejs-dropdownlist
                          cssClass="lookup-condition-dropdown"
                          v-bind="viewModeDropdownListProps"
                          v-model="viewDiv"
                          @change="onChangeViewDiv"
                        />
                      </li>
                    </ul>
                  </li>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <ejs-grid-wrapper
                      ref="dailyReservationGrid"
                      :provides="provides"
                      :columns="columns"
                      :dataSource="dataSource"
                      :editSettings="editSettings"
                      :aggregates="aggregates"
                      :isInPopup="true"
                      :allowExcelExport="true"
                      :is-auto-select-cell="false"
                      @queryCellInfo="queryCellInfo"
                      @headerCellInfo="headerCellInfo"
                      @rowSelected="rowSelected"
                      @onGridDialogDoubleClickedOrEnterKeyed="onGridDialogDoubleClickedOrEnterKeyed"
                      @actionComplete="actionComplete"
                    />
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section
                  id="chart-area"
                  class="article-section section-02"
                >
                  <div
                    v-if="chartTypeToComponent"
                    :is="chartTypeToComponent"
                    :options="options"
                    :styles="{height: '220px'}"
                    :chart-data="chartDataSource"
                  />
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <div class="lookup-condition">
            <li class="field">
              <div class="title">차트 종류</div>
              <ul class="content">
                <li class="item" style="width: 120px">
                  <ejs-dropdownlist
                    cssClass="lookup-condition-dropdown"
                    v-bind="chartTypeDropdownListProps"
                    v-model="chartType"
                  />
                </li>
              </ul>
            </li>
          </div>
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  buttonDiv="GET"
                  :isIconCustom="true"
                  @click.native="confirmDailyReservationPopup"
              >
                확인
              </erp-button>
            </li>
            <li>
              <erp-button
                  buttonDiv="FILE"
                  @click.native="excel"
              >
                Excel · Chart
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="closeDailyReservationPopup"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .section-header {margin: 0}
body .section-header .header-label {margin: 0 -5px 0 5px;padding: 3px 0 0}
body .lookup-condition.right {display: flex;flex: 1;justify-content: flex-end}
body .article-section.section-01 {height: calc(100% - 220px)}
body .article-section.section-02 {height: 220px}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import moment from 'moment';
import {toPng} from 'html-to-image';
import {Resize, ForeignKey, Aggregate, Group, ExcelExport} from '@syncfusion/ej2-vue-grids';
import ejsGridWrapper from '@/components/common/EjsGridWrapper.vue';
import inputDate from '@/components/common/datetime/InputDate';
import commonMixin from '@/views/layout/mixin/commonMixin';
import LineChart from '@/utils/charts/LineChart.js';
import BarChart from '@/utils/charts/BarChart.js';
import { numberWithCommas } from '@/utils/number';
import {
  commonCodesGetCommonCode,
  commonCodesGetColorValue,
} from '@/utils/commonCodes';
import { getDayColorValue } from '@/utils/date';
import { getDailyReservationStatus } from '@/api/reservation';

export default {
  name: 'dailyReservationPopup',
  components: {
    ejsGridWrapper,
    inputDate,
    LineChart,
    BarChart,
    ErpButton
  },
  mixins: [commonMixin],
  data() {
    return {
      viewDiv: 'PART_AND_RESVE_CHANNEL',
      chartType: 'BAR',
      dataSource: [],
      resveDate: null,
      searchConditions: {
        courseCode: '',
        bsnCode: '',
        partDiv: '',
      },
      searchOptions: {
        courseCode: commonCodesGetCommonCode('COURSE_CODE', true),
        bsnCode: commonCodesGetCommonCode('BSN_CODE', true),
        partDiv: commonCodesGetCommonCode('PART_DIV', true),
      },
      fields: { text: 'comName', value: 'comCode' },
      aditTeamFlag: null,
      provides: [Resize, ForeignKey, Aggregate, Group, ExcelExport],
      editSettings: {
        allowEditing: false,
        allowAdding: false,
        allowDeleting: false,
        mode: 'Batch',
        showConfirmDialog: false,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        hover: {animationDuration: 0},
        animation: {
          duration: 1,
          onComplete: function() {
            const chartInstance = this.chart
                , ctx = chartInstance.ctx
                , legendItems = chartInstance.legend.legendItems;
            ctx.fillStyle = '#333';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            this.data.datasets.forEach(function (dataset, i) {
              const meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function (bar, index) {
                const data = dataset.data[index];
                if (data > 0 && !legendItems[i].hidden) {
                  ctx.fillText(numberWithCommas(data), bar._model.x, bar._model.y - 5);
                }
              });
            });
          }
        },
      },
      isFullscreen: true,
    };
  },
  async created() {
    this.searchOptions.courseCode.unshift({ comName: '전체', comCode: '' });
    this.searchOptions.bsnCode.unshift({ comName: '전체', comCode: '' });
    this.searchOptions.partDiv.unshift({ comName: '전체', comCode: '' });
    window.addEventListener('keydown', this.keyDownEvent);
  },
  async mounted() {
    await this.$nextTick();
    const $el = document.getElementById('_dialog-header');
    const $copyEl = $el.firstElementChild.cloneNode(true);
    $copyEl.id = 'custom-fullscreen';
    $copyEl.classList.add('fullscreen');
    $copyEl.setAttribute('title', 'Fullscreen');
    $copyEl.setAttribute('aria-title', 'Fullscreen');
    $copyEl.addEventListener('click', () => this.onToggleScreenSizeClick());
    $copyEl.removeEventListener('click', () => this.onToggleScreenSizeClick());
    $el.insertBefore($copyEl, $el.lastChild);
  },
  async beforeDestroy() {
    window.removeEventListener('keydown', this.keyDownEvent);
  },
  computed: {
    w() {
      return this.isFullscreen ? 'calc(100% - 20px)' : 1360;
    },
    h() {
      return this.isFullscreen ? '100%' : 760;
    },
    chartDataSource() {
      let datasets = [];
      if (this.viewDiv === 'RESERVATION') {
        const fields = [
          {
            field: 'allCount',
            name: '전체',
          }, {
            field: 'resveCount',
            name: '예약',
          }, {
            field: 'restCount',
            name: '잔여',
          }, {
            field: 'waitingCount',
            name: '대기',
          }];
        const colorsets = ["#CCC", "#005FAA", "#FFCD12", "#F15F5F", "#00A88F"];
        datasets = fields?.map((field, index) => ({
          data: this.dataSource?.map(item => item[field.field]),
          label: field.name,
          borderWidth: 1,
          borderColor: colorsets[index],
          backgroundColor: colorsets[index],
          tension: 0,
          fill: false,
          hidden: field.field === 'allCount' || field.field === 'waitingCount',
        }));
      } else {
        const fields = [
          {
            field: 'allCount',
            name: '전체',
          }, {
            field: 'part1ResveCount',
            name: '1부',
          }, {
            field: 'part2ResveCount',
            name: '2부',
          }, {
            field: 'part3ResveCount',
            name: '3부',
          }];
        const colorsets = ["#82C272", "#00A88F", "#005FAA", "#F15F5F", "#0087AC"];
        datasets = fields?.map((field, index) => ({
          data: this.dataSource?.map(item => item[field.field]),
          label: field.name,
          borderWidth: 1,
          borderColor: colorsets[index],
          backgroundColor: colorsets[index],
          tension: 0,
          fill: false,
          hidden: field.field === 'allCount',
        }));
      }
      return {
        labels: this.dataSource?.map(item => `${commonCodesGetColorValue('DW_CODE', item.dwCode) === '#000000' ? '' : '● '}${moment(item.resveDate).format('MM-DD')}`),
        datasets
      };
    },
    columns() {
      return this.viewDiv === 'RESERVATION'
        ? [{
            field: 'resveDate',
            headerText: '예약일자',
            isPrimaryKey: true,
            type: 'string',
            minWidth: 16,
            width: 90,
            textAlign: 'center',
          },
          {
            field: 'dwName',
            headerText: '요일',
            type: 'string',
            minWidth: 16,
            width: 60,
            textAlign: 'center',
          },
          {
            field: 'hldyName',
            headerText: '공휴일명',
            type: 'string',
            minWidth: 16,
            width: 90,
          },
          {
            field: 'allCount',
            headerText: '전체',
            type: 'string',
            minWidth: 16,
            width: 80,
            textAlign: 'Center',
          },
          {
            field: 'resveCount',
            headerText: '예약',
            type: 'string',
            minWidth: 16,
            width: 80,
            textAlign: 'Center',
          },
          {
            field: 'restCount',
            headerText: '잔여',
            type: 'string',
            minWidth: 16,
            width: 80,
            textAlign: 'Center',
          },
          {
            field: 'waitingCount',
            headerText: '대기',
            type: 'number',
            format: 'N',
            minWidth: 16,
            width: 80,
            textAlign: 'Center',
          },
          {
            field: 'resvePercent',
            headerText: '예약율',
            type: 'string',
            minWidth: 16,
            width: 80,
            textAlign: 'Center',
          }]
        : [{
            field: 'resveDate',
            headerText: '예약일자',
            isPrimaryKey: true,
            type: 'string',
            minWidth: 16,
            width: 90,
            textAlign: 'center',
          },
          {
            field: 'dwName',
            headerText: '요일',
            type: 'string',
            minWidth: 16,
            width: 60,
            textAlign: 'center',
          },
          {
            field: 'hldyName',
            headerText: '공휴일명',
            type: 'string',
            minWidth: 16,
            width: 90,
          },
          {
            headerText: '전체',
            columns: [{
              field: 'allCount',
              headerText: '총',
              type: 'string',
              minWidth: 16,
              width: 80,
              textAlign: 'Center',
            },
            {
              field: 'resveCount',
              headerText: '예약',
              type: 'string',
              minWidth: 16,
              width: 80,
              textAlign: 'Center',
            },
            {
              field: 'restCount',
              headerText: '잔여',
              type: 'string',
              minWidth: 16,
              width: 80,
              textAlign: 'Center',
            }],
          }, {
            headerText: '1부',
            columns: [{
              field: 'part1AllCount',
              headerText: '총',
              type: 'number',
              minWidth: 16,
              width: 80,
              textAlign: 'Center',
            },
            {
              field: 'part1ResveCount',
              headerText: '예약',
              type: 'number',
              minWidth: 16,
              width: 80,
              textAlign: 'Center',
            },
            {
              field: 'part1RestCount',
              headerText: '잔여',
              type: 'number',
              minWidth: 16,
              width: 80,
              textAlign: 'Center',
            }],
          }, {
            headerText: '2부',
            columns: [{
              field: 'part2AllCount',
              headerText: '총',
              type: 'number',
              minWidth: 16,
              width: 80,
              textAlign: 'Center',
            },
            {
              field: 'part2ResveCount',
              headerText: '예약',
              type: 'number',
              minWidth: 16,
              width: 80,
              textAlign: 'Center',
            },
            {
              field: 'part2RestCount',
              headerText: '잔여',
              type: 'number',
              minWidth: 16,
              width: 80,
              textAlign: 'Center',
            }],
          },
          {
            field: 'twoPersonResveCount',
            headerText: '2인팀수',
            type: 'number',
            minWidth: 16,
            width: 80,
            textAlign: 'Center',
          },
          {
            field: 'selfTimeCount',
            headerText: '셀프팀수',
            type: 'number',
            minWidth: 16,
            width: 80,
            textAlign: 'Center',
          },
          {
            headerText: '개인/단체',
            columns: [{
              field: 'fitCount',
              headerText: '개인',
              type: 'number',
              minWidth: 16,
              width: 80,
              textAlign: 'Center',
            },
            {
              field: 'groupCount',
              headerText: '단체',
              type: 'number',
              minWidth: 16,
              width: 80,
              textAlign: 'Center',
            },
            {
              field: 'tempCount',
              headerText: '임시',
              type: 'number',
              minWidth: 16,
              width: 80,
              textAlign: 'Center',
            }],
          }, {
            headerText: '예약채널',
            columns: [{
              field: 'webCount',
              headerText: '인터넷',
              type: 'number',
              minWidth: 16,
              width: 80,
              textAlign: 'Center',
            },
            {
              field: 'telCount',
              headerText: '전화',
              type: 'number',
              minWidth: 16,
              width: 80,
              textAlign: 'Center',
            },
            {
              field: 'lotteryCount',
              headerText: '추첨예약',
              type: 'number',
              minWidth: 16,
              width: 80,
              textAlign: 'Center',
            },
            {
              field: 'agentCount',
              headerText: '대행사',
              type: 'number',
              minWidth: 16,
              width: 80,
              textAlign: 'Center',
            }],
          }];
    },
    aggregates() {
      return [
        {
          columns: [
            {
              field: 'resveDate',
              aggregationType: 'TotalCaption',
              customAggregate: '합계',
            },
            {
              field: 'dwCode',
              aggregationType: 'TotalCount',
              stringFormat: '${value} 건',
            },
            {
              field: 'allCount',
              aggregationType: 'TotalSum',
            },
            {
              field: 'resveCount',
              aggregationType: 'TotalSum',
            },
            {
              field: 'restCount',
              aggregationType: 'TotalSum',
            },
            {
              field: 'waitingCount',
              aggregationType: 'TotalSum',
            },
            {
              field: 'part1AllCount',
              aggregationType: 'TotalSum',
            },
            {
              field: 'part1ResveCount',
              aggregationType: 'TotalSum',
            },
            {
              field: 'part1RestCount',
              aggregationType: 'TotalSum',
            },
            {
              field: 'part2AllCount',
              aggregationType: 'TotalSum',
            },
            {
              field: 'part2ResveCount',
              aggregationType: 'TotalSum',
            },
            {
              field: 'part2RestCount',
              aggregationType: 'TotalSum',
            },
            {
              field: 'part3AllCount',
              aggregationType: 'TotalSum',
            },
            {
              field: 'part3ResveCount',
              aggregationType: 'TotalSum',
            },
            {
              field: 'part3RestCount',
              aggregationType: 'TotalSum',
            },
            {
              field: 'fitCount',
              aggregationType: 'TotalSum',
            },
            {
              field: 'groupCount',
              aggregationType: 'TotalSum',
            },
            {
              field: 'tempCount',
              aggregationType: 'TotalSum',
            },
            {
              field: 'webCount',
              aggregationType: 'TotalSum',
            },
            {
              field: 'telCount',
              aggregationType: 'TotalSum',
            },
            {
              field: 'lotteryCount',
              aggregationType: 'TotalSum',
            },
            {
              field: 'agentCount',
              aggregationType: 'TotalSum',
            },
          ],
        },
      ];
    },
    viewModeDropdownListProps() {
      const dataSource = [
        {
          comCode: 'RESERVATION',
          comName: '예약일반',
        }, {
          comCode: 'PART_AND_RESVE_CHANNEL',
          comName: '부별/예약채널',
        }];
      return {
        allowFiltering: false,
        dataSource,
        fields: {text: 'comName', value: 'comCode'},
      };
    },
    chartTypeDropdownListProps() {
      const dataSource = [
        {
          comCode: 'LINE',
          comName: '선형',
        }, {
          comCode: 'BAR',
          comName: '막대형',
        }];
      return {
        allowFiltering: false,
        dataSource,
        fields: {text: 'comName', value: 'comCode'},
      };
    },
    chartTypeToComponent() {
      let component = null;
      switch (this.chartType) {
        case 'LINE':
          component = LineChart;
          break;
        case 'BAR':
          component = BarChart;
          break;
      }
      return component;
    },
  },
  methods: {
    async keyDownEvent(event) {
      const {key} = event;
      if (key === 'F9') {
        this.changeViewMode();
        await this.$nextTick();
        this.$refs.resveDate?.focus();
      }
    },
    async onToggleScreenSizeClick() {
      this.isFullscreen = !this.isFullscreen;
      await this.$nextTick();
      const $el = document.getElementById('custom-fullscreen');
      $el.classList.remove(!this.isFullscreen ? 'fullscreen' : 'notfullscreen');
      $el.classList.add(this.isFullscreen ? 'fullscreen' : 'notfullscreen');
    },
    closePopup() {
      this.$emit('popupClosed');
    },
    onResveDateChange() {
      if (this.resveDate) {
        this.getDailyReservationStatus();
      }
    },
    showDailyReservationPopup(resveDate, aditTeamFlag) {
      this.$refs.dailyReservationPopup.show();
      this.resveDate = resveDate;
      this.aditTeamFlag = aditTeamFlag;
    },
    closeDailyReservationPopup() {
      this.$refs.dailyReservationPopup.hide();
    },
    excel() {
      this.$refs.dailyReservationGrid.excelExport();
      const node = document.getElementById('chart-area');
      toPng(node, {backgroundColor: '#FFF'})
        .then(dataUrl => {
          const link = document.createElement('a');
          link.download = `chart_${this.resveDate}.png`;
          link.href = dataUrl;
          link.click();
        })
        .catch(err => {
          alert(err);
        });
    },
    confirmDailyReservationPopup() {
      this.$emit('popupConfirmed', { selectRowData: this.selectedRowData });
      this.$refs.dailyReservationPopup.hide();
    },
    queryCellInfo(args) {
      const {
        column: {field, type},
        cell,
        data,
      } = args;
      if (field === "resveDate" || field === "dwName") {
        if(data?.bsnCode === "CLOSE") {
          cell.style.textDecoration = "line-through";
        }
      }
      if (field === 'dwName') {
        if(data?.bsnCode === "CLOSE"){
          cell.style.color = commonCodesGetColorValue("BSN_CODE", data.bsnCode);
        } else {
          cell.style.color = getDayColorValue(data.dwCode, data.bsnCode);
        }
      }
      if (field === 'allCount') {
        cell.innerText = `${data.allCount > 0 ? numberWithCommas(data.allCount) : '-'} (${data.webAllCount > 0 ? numberWithCommas(data.webAllCount) : '-'})`;
      }
      if (field === 'resveCount') {
        cell.innerText = `${data.resveCount > 0 ? numberWithCommas(data.resveCount) : '-'} (${data.webResveCount > 0 ? numberWithCommas(data.webResveCount) : '-'})`;
      }
      if (field === 'restCount') {
        cell.innerText = `${data.restCount > 0 ? numberWithCommas(data.restCount) : '-'} (${data.webRestCount > 0 ? numberWithCommas(data.webRestCount) : '-'})`;
      }
      if (field === 'resvePercent') {
        cell.innerText = data.resvePercent > 0 ? `${data.resvePercent}%` : '-';
        cell.style.backgroundColor = '#EDF6FA';
      }
      if (type === 'number' && data[field] < 1) {
        cell.innerText = '-';
      }
    },
    headerCellInfo(args) {
      if (args.cell.column.headerText === 'NO') {
        args.node.classList.add(this.$t('className.grid.noSortFilter'));
      }
    },
    rowSelected(args) {
      this.selectedRowData = args.data;
    },
    onGridDialogDoubleClickedOrEnterKeyed() {
      this.confirmDailyReservationPopup();
    },
    actionComplete(args) {
      if (args.requestType === 'refresh') {
        if (
          this.$refs.dailyReservationGrid.getSelectedRecords().length === 0 &&
          this.dataSource.length > 0
        ) {
          this.$refs.dailyReservationGrid.selectRow(0);
        }
      }
    },
    changeViewMode() {
      switch (this.viewDiv) {
        case 'RESERVATION':
          this.viewDiv = 'PART_AND_RESVE_CHANNEL';
          this.chartType = 'LINE';
          this.searchConditions.partDiv = '';
          break;
        case 'PART_AND_RESVE_CHANNEL':
          this.viewDiv = 'RESERVATION';
          this.chartType = 'BAR';
          break;
      }
      this.getDailyReservationStatus();
    },
    onChangeViewDiv() {
      switch (this.viewDiv) {
        case 'RESERVATION':
          this.chartType = 'LINE';
          break;
        case 'PART_AND_RESVE_CHANNEL':
          this.chartType = 'BAR';
          break;
      }
      this.getDailyReservationStatus();
    },
    getDailyReservationStatus() {
      getDailyReservationStatus(
        this.resveDate,
        this.aditTeamFlag,
        this.searchConditions.courseCode,
        this.searchConditions.bsnCode,
        this.searchConditions.partDiv
      )
        .then(response => this.dataSource = response.value.reservationStatusList.map(reservationStatus => {
          if(reservationStatus.bsnCode === "CLOSE") {
            Object.keys(reservationStatus)
            .filter(key => key.toLowerCase().includes("count") || key.toLowerCase().includes("percent"))
            .map(key => {
              reservationStatus[key] = 0;
            });
          }
          return reservationStatus;
        }))
        .catch(error => console.log('getDailyReservationStatus.err.===>', error));
    },
  },
};
</script>

<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field creditReturnDate">
            <ul class="content">
              <div class="title">미수일자</div>
              <li class="item dateRange">
                <input-date-range
                  v-model="inputDateRangeValue"
                  type="lookup-condition"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.visitName }}
            </div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="searchConditions.visitName"
                  @keydown.native="preventEnterEvent"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              buttonDiv="GET"
              :isShortcutButton="true"
              :ignore="isPopupOpened"
              @click.native="onCreditReturnViewClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              buttonDiv="GET"
              :is-icon-custom="true"
              @click.native="onSearchDetailPopupOpen"
          >
            상세검색
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true" />
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">미수 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      buttonDiv="FILE"
                      :ignore="isPopupOpened"
                      @click.native="excel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="creditReturnsGrid"
                :allowResizing="true"
                :dataSource="creditReturnInfoList"
                :selectionSettings="selectionSettings"
                :allowExcelExport="true"
                :columns="creditReturnGridColumn"
                :provides="grid"
                :aggregates="creditReturnGridAggregates"
                :allowGrouping="true"
                :allowPaging="true"
                :allowSorting="false"
                :allowFiltering="false"
                :groupSettings="groupSettings"
                :pageSettings="pageSettings"
                @queryCellInfo="queryCellInfo"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <ejs-dialog
      ref="searchDetailPopup"
      :header="`상세검색`"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="false"
      :visible="false"
      width="387"
      v-show="isSearchDetailPopupOpen"
    >
      <div class="window lookupDetail-creditReturnRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">
                    {{ labels.creditReturnFlag }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchConditions.creditReturnFlag"
                        :dataSource="searchOptions.creditReturnFlagOptions"
                        :fields="commonCodeFields"
                        :allowFiltering="false"
                        cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.bsnId }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchConditions.bsnId"
                        :dataSource="searchOptions.tsBsnMstOptions"
                        :fields="commonCodeFields"
                        :allowFiltering="false"
                        cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.storeCode }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchConditions.storeCode"
                        :dataSource="searchOptions.tsStoreBsnOptions"
                        :fields="commonCodeFields"
                        :allowFiltering="false"
                        cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.remarks }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <input-text
                        v-model="searchConditions.remarks"
                        @keydown.native="preventEnterEvent"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  buttonDiv="GET"
                  :isShortcutButton="true"
                  :ignore="isPopupOpened"
                  @click.native="onCreditReturnDetailViewClicked"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  buttonDiv="GET"
                  @click.native="onCreditReturnDetailViewClicked"
              >
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="onSearchDetailPopupClose"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body
  .appContent
  .lookup-condition
  .field.creditReturnDate
  .content
  .item.select {
  width: 120px;
}
body .appContent .body-article .section-body {
  overflow: hidden;
  border: none;
}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {
  Aggregate,
  Filter,
  Resize,
  Group,
  ExcelExport,
  ForeignKey,
  Page,
} from "@syncfusion/ej2-vue-grids";

import { getDayColorValue, getFormattedDate } from "@/utils/date";
import { formPreventEnterEvent } from "@/utils/formUtil";
import { getCurrentBizCode } from "@/utils/pageKeyUtil";
import { numberWithCommas } from "@/utils/number";
import {
  commonCodesGetColorValue,
} from "@/utils/commonCodes";
import { getBsnInfo } from "@/api/bizCodeManagement";
import { getTableUseStore } from "@/api/storeTableRegistration";

import ReportView from "@/components/common/report/ReportView";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { mapGetters } from "vuex";


export default {
  name: "CreditReturnReport",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    InputText,
    InputDateRange,
    ejsGridWrapper,
    ReportView,
    ErpButton
  },
  created() {
    this.bsnDate = getFormattedDate(new Date());
    this.inputDateRangeValue.from = getFormattedDate(new Date());
    this.inputDateRangeValue.to = getFormattedDate(new Date());
    this.currentBizCode = getCurrentBizCode();
  },
  async mounted() {
    await Promise.all([this.getBsnInfo(), this.getTableUseStore()]);
    if (
      this.searchOptions.tsStoreBsnOptions.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchOptions.tsStoreBsnOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
    this.searchConditions.storeCode = "ALL";
  },
  watch: {
    $route() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
  },
  data() {
    return {
      count: 0,
      creditReturnInfoListLength: 0,
      grid: [Aggregate, Filter, Resize, Group, ExcelExport, ForeignKey, Page],
      selectionSettings: { type: "Single", mode: "Both", enableToggle: false },
      inputDateRangeValue: { to: null, from: null },

      //TODO : searchConditions
      searchConditions: {
        dateDiv: "CREDIT_DATE",
        bsnStartDate: null,
        bsnEndDate: null,
        visitName: null,
        creditReturnFlag: null,
        bsnId: "ALL",
        storeCode: null,
        remarks: null,
      },
      labels: {
        visitName: "성명",
        creditReturnFlag: "회입여부",
        bsnId: "영업장",
        storeCode: "매장",
        remarks: "비고",
      },
      searchOptions: {
        dateDiv: [
          { comCode: "CREDIT_DATE", comName: "미수일자" },
          //추후 회입일자로 데이터 조회 기능 추가 가능성을 위해 object 형식 유지함.
          //{ comCode: "RETURN_DATE", comName: "회입일자" },
        ],
        creditReturnFlagOptions: [
          { comCode: "ALL", comName: "전체" },
          { comCode: "FALSE", comName: "미회입" },
          { comCode: "TRUE", comName: "회입" },
        ],
        tsBsnMstOptions: [{ comCode: "ALL", comName: "전체" }],
        tsStoreBsnOptions: [],
      },
      commonCodeFields: { text: "comName", value: "comCode" },
      groupSettings: { showDropArea: false, columns: ["groupInfo"] },
      pageSettings: { pageSize: 50 },
      creditReturnGridColumn: [
        {
          field: "payId",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "groupInfo",
          headerText: "구분",
          visible: false,
        },
        {
          field: "bsnName",
          headerText: "영업장",
          width: 80,
          type: "string",
          textAlign: "center",
        },
        {
          headerText: "미수일자",
          field: "payDate",
          width: 80,
          textAlign: "center",
          type: "string",
        },
        {
          headerText: "미수번호",
          field: "payId",
          width: 70,
          textAlign: "center",
          type: "string",
        },
        {
          headerText: "회입여부",
          field: "creditReturnFlag",
          width: 70,
          textAlign: "center",
          type: "boolean",
        },
        {
          field: "storeCode",
          headerText: "매장",
          width: 80,
          textAlign: "center",
          type: "string",
          isCommonCodeField: true,
          groupCode: "STORE_CODE",
        },
        {
          field: "visitName",
          headerText: "내장객명",
          width: 80,
          type: "string",
          textAlign: "center",
        },
        {
          field: "payAmt",
          headerText: "미수금액",
          textAlign: "right",
          type: "number",
          format: "N",
          width: 100,
        },
        {
          field: "remarks",
          headerText: "미수비고",
          type: "string",
          width: 100,
          textAlign: "left",
        },
        {
          headerText: "회입일자",
          field: "returnDate",
          width: 80,
          textAlign: "center",
          type: "date",
          format: { type: "date", format: "yyyy-MM-dd" },
        },
        {
          headerText: "회입구분",
          field: "payDiv",
          width: 80,
          textAlign: "center",
          type: "string",
        },
        {
          field: "returnAmt",
          headerText: "회입금액",
          textAlign: "right",
          type: "number",
          format: "N",
          width: 100,
        },
        {
          field: "creditBalance",
          headerText: "미수잔액",
          textAlign: "right",
          type: "number",
          format: "N",
          width: 100,
        },
        {
          field: "returnRemarks",
          headerText: "회입비고",
          type: "string",
          width: 100,
          textAlign: "left",
        },
        {
          field: "insertName",
          headerText: "등록자",
          width: 90,
          type: "string",
          textAlign: "center",
        },
        {
          field: "insertDt",
          headerText: "등록일시",
          textAlign: "center",
          width: 80,
          type: "date",
          format: { type: "date", format: "yyyy-MM-dd" },
        },
      ],
      creditReturnGridAggregates: [
        {
          columns: [
            {
              field: "storeCode",
              aggregationType: "TotalCaption",
              customAggregate: "합계",
            },
            {
              field: "payAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "returnAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "creditBalance",
              aggregationType: "TotalCaption",
              customAggregate: () => {
                let sum = 0;
                const targetList = this.creditReturnInfoList
                  .filter((item) => !item.creditReturnFlag && item.payId)
                  .map((x) => x.payGroupNo);

                targetList.forEach((num) => {
                  const recordList = this.creditReturnInfoList.filter(
                    (record) => record.payGroupNo === num
                  );
                  const lastRecord =
                    recordList.length > 0
                      ? recordList[recordList.length - 1]
                      : null;
                  sum +=
                    lastRecord.creditBalance && lastRecord.creditBalance > 0
                      ? lastRecord.creditBalance
                      : lastRecord.payAmt;
                });
                return sum;
              },
            },
          ],
        },
      ],
      creditReturnInfoList: [],
      isSearchDetailPopupOpen: false,
      isCreditReturnPayHistoryPopupOpen: false,
      isPayPopupOpen: false,
    };
  },
  computed: {
    ...mapGetters(["username"]),
    isPopupOpened() {
      return this.isCreditReturnPayHistoryPopupOpen || this.isPayPopupOpen;
    },
  },
  methods: {
    numberWithCommas,
    preventEnterEvent: formPreventEnterEvent,
    // headerCellInfo(args) {
    //   //일단 기능 미오픈
    //   if (
    //     args.cell.column.field === "returnAmt" ||
    //     args.cell.column.field === "creditBalance"
    //   ) {
    //     args.node.classList.add(this.$t("className.grid.clickArea"));
    //   }
    // },
    queryCellInfo(args) {
      const {
        cell,
        column: { field },
        data,
      } = args;
      const value = data[field];
      if (field === "dwCode") {
        cell.style.color = getDayColorValue(data.dwCode, data.bsnCode);
      } else if (field === "bsnCode") {
        cell.style.color = commonCodesGetColorValue("BSN_CODE", value);
      }

      // 회입 상세 내역 보기 일단 미오픈
      // else if (field === "returnAmt") {
      //   if (value !== 0) {
      //     args.cell.classList.add(this.$t("className.grid.clickArea"));
      //   }
      // } else if (field === "creditBalance") {
      //   if (value !== 0) {
      //     args.cell.classList.add(this.$t("className.grid.clickArea"));
      //   }
      // }
      if (
        ["payAmt", "returnAmt", "creditBalance"].includes(field) &&
        data[field] === 0
      ) {
        cell.innerText = "-";
      }
    },
    onSearchDetailPopupOpen() {
      this.isSearchDetailPopupOpen = true;
      this.$refs.searchDetailPopup.show();
    },
    onSearchDetailPopupClose() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
    onInitButtonClicked() {
      this.searchConditions.visitName = null;
      this.searchConditions.creditReturnFlag = "ALL";
      this.searchConditions.bsnId = "ALL";
      this.searchConditions.storeCode = "ALL";
      this.searchConditions.remarks = null;
    },
    setGroupInfo(creditReturnInfoList) {
      this.creditReturnInfoList = creditReturnInfoList.reduce((acc, item) => {
        item.creditBalance = item.payAmt - item.paidAmt;
        item.creditReturnFlag = item.creditReturnFlag ? "✔" : "";
        item.payDiv = this.payDivMessageConverter(item.payDiv);
        item.payGroupNo = item.payId;

        if (acc.find((el) => item.payId === el.payId)) {
          delete item.bsnName;
          delete item.payDate;
          delete item.payId;
          delete item.creditReturnFlag;
          delete item.storeCode;
          delete item.visitName;
          delete item.payAmt;
          delete item.remarks;
        } else {
          this.count++;
        }
        return acc.concat([item]);
      }, []);
    },
    payDivMessageConverter(payDiv) {
      switch (payDiv) {
        case "CARD":
          return "카드";
        case "CASH":
          return "현금";
        case "CREDIT":
          return "미수 (C/L)";
        case "DEPOSIT":
          return "선수금";
        case "GIFT":
          return "상품권";
        case "TRANS":
          return "계좌이체";
        default:
          return "";
      }
    },
    async onCreditReturnDetailViewClicked() {
      await this.onCreditReturnViewClicked();
    },
    async onCreditReturnViewClicked() {
      this.count = 0;
      this.searchConditions.bsnStartDate = this.inputDateRangeValue.from;
      this.searchConditions.bsnEndDate = this.inputDateRangeValue.to;

      if (!this.searchConditions.bsnStartDate) {
        const columnName = this.searchOptions.dateDiv.find(
          (item) => item.comCode === this.searchConditions.dateDiv
        );

        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", [
            columnName.comName,
          ])
        );
      }
      if (!this.searchConditions.bsnEndDate) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["종료일자"])
        );
      }

      let data = Object.assign({}, this.searchConditions);

      data.bsnId = data.bsnId === "ALL" ? null : data.bsnId;
      data.storeCode = data.storeCode === "ALL" ? null : data.storeCode;
      data.creditReturnFlag =
        data.creditReturnFlag === "ALL" ? null : data.creditReturnFlag;
      data.visitName = data.visitName || null;

      this.creditReturnInfoList = await GolfErpAPI.fetchCreditReturnReports(
        data
      );
      this.creditReturnInfoListLength = this.creditReturnInfoList.length;

      this.setGroupInfo(this.creditReturnInfoList);
    },
    async getBsnInfo() {
      const response = await getBsnInfo(this.currentBizCode);
      response.value.tsBsnMstList.forEach((bsnInfo) => {
        const data = {
          comCode: bsnInfo.bsnId.toString(),
          comName: bsnInfo.bsnName,
        };
        this.searchOptions.tsBsnMstOptions.push(data);
      });
    },
    async getTableUseStore() {
      const response = await getTableUseStore(null, this.bsnDate);
      this.searchOptions.tsStoreBsnOptions = response.value.comCodeDtlList;
    },
    excel() {
      this.$refs.creditReturnsGrid.excelExport();
    },
  },
};
</script>
